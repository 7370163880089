import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TableEditDropdown from "../../../Common/TableEditDropdown";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import { setService } from "../../../../utils/localstorage";
import { useNavigate } from "react-router-dom";
import colors from "../../../../theme/colors";
import ServiceLinkingModal from "../ServiceLinkingModal";
import { ServiceData } from "../../../../types-definition/client";
import moment from "moment";
import { useAppDispatch } from "../../../../redux/hooks";
import { setShowDeleteServiceModal } from "../../../../redux/slice/client/serviceSlice";

const ServiceTableItem: React.FC<ServiceData> = ({
  id,
  name,
  from,
  grade,
  noOfEmployees,
  shiftEnd,
  shiftStart,
  to,
  workHours,
  invoiceInInfoId,
  payInfoId,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <tr className={styles.tr}>
        <td className={styles.td_mail}>
          <Typography textAlign="left" textColor={colors.primary3}>
            {name}
          </Typography>
        </td>
        <td className={styles.td}>
          <Typography textAlign="left">{grade}</Typography>
        </td>
        <td className={styles.td}>
          <Typography textAlign="left">
            {moment(from).format("DD/MM/YYYY")}
          </Typography>
        </td>
        <td className={styles.td}>
          <Typography textAlign="left">
            {moment(to).format("DD/MM/YYYY")}
          </Typography>
        </td>
        <td className={styles.td}>
          <Typography textAlign="left">{shiftStart}</Typography>
        </td>
        <td className={styles.td}>
          <Typography textAlign="left">{shiftEnd}</Typography>
        </td>
        <td className={styles.td}>
          <Typography textAlign="left">{workHours}</Typography>
        </td>
        <td className={styles.td}>
          <Typography textAlign="left">{noOfEmployees}</Typography>
        </td>
        <td
          className={`${styles.td} ${styles.td_relative}`}
          onBlur={() => setshowEditDropdown(false)}
          tabIndex={0}
        >
          {showEditDropdown && (
            <TableEditDropdown
              data={[
                {
                  label: "Edit",
                  action: () => {
                    setService(id);
                    navigate("/client/service/add");
                  },
                },
                {
                  label: "Delete",
                  action: () => {
                    setshowEditDropdown(false);
                    dispatch(setShowDeleteServiceModal({ id, show: true }));
                  },
                },
                {
                  label: "Link Service to Location",
                  action: () => {
                    setShowModal(true);
                    setshowEditDropdown(false);
                  },
                },
              ]}
            />
          )}
          <div
            className={styles.image_container}
            onClick={() => setshowEditDropdown((prevData) => !prevData)}
          >
            <img src={tableSelectorIcon} alt="selector-icon" />
          </div>
        </td>
      </tr>
      {showModal && (
        <ServiceLinkingModal
          showModal={() => setShowModal(false)}
          serviceId={id}
          serviceName={name}
        />
      )}
    </>
  );
};

export default ServiceTableItem;
