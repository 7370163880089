import React, { useState } from "react";
import Typography from "../../../Common/Typography";
import deleteIcon from "../../../../assets/svg/error-icon.svg";
import styles from "./index.module.css";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { deleteRequest } from "../../../../api/request";

import { toastError, toastSuccess } from "../../../../utils/toast";
import {
  getCurrentMailReportId,
  setShowDeleteReportModal,
  updateFetchReportCounter,
} from "../../../../redux/slice/client/emailReportSlice";

const DeleteMailReportModal: React.FC = () => {
  const [requestLoading, setRequestLoading] = useState(false);
  const dispatch = useAppDispatch();
  const id = useAppSelector(getCurrentMailReportId);
  const deleteAction = async () => {
    setRequestLoading(true);
    const response = await deleteRequest(`/client/location/email-report/${id}`);
    if (response?.success) {
      toastSuccess("Email Report Deleted Successfully");
    } else {
      toastError(response?.data);
    }
    setRequestLoading(false);
    dispatch(setShowDeleteReportModal({ show: false, id: "" }));
    // dispatch(updateEmployeStateCounter());
    dispatch(updateFetchReportCounter());
  };
  const cancelAction = () => {
    dispatch(setShowDeleteReportModal({ show: false, id: "" }));
  };
  return (
    <div className={styles.container}>
      <img src={deleteIcon} alt="cancel icon" className={styles.cancelImage} />
      <Typography textWeight="600" textSize="18px" textAlign="left">
        Delete Email Report
      </Typography>
      <div className={styles.mid_text}>
        <Typography textColor={colors.gray4} textAlign="left">
          Are you sure you want to delete this record?
        </Typography>
        <Typography
          textColor={colors.gray4}
          textAlign="left"
          marginVertical="-8px"
        >
          This action cannot be undone.
        </Typography>
      </div>
      <div className={styles.button_container}>
        <Button
          width="160px"
          text="Cancel"
          isPrimary={false}
          onClick={cancelAction}
          backgroundColor={colors.transparent}
          isPrimaryBorder={true}
        />
        <Button
          width="160px"
          text="Delete"
          backgroundColor={colors.error}
          textColor={colors.white}
          isPrimary={false}
          onClick={deleteAction}
          isLoading={requestLoading}
        />
      </div>
    </div>
  );
};

export default DeleteMailReportModal;
