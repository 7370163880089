import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../../Common/PageBackButton";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import EmployeeUploadedItem from "../../../../StaffHub/Employee/EmployeeUploadedItem";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import { getRequest } from "../../../../../api/request";
import { toastError } from "../../../../../utils/toast";
import capitalize from "../../../../../utils/capitalize";
import moment from "moment";
import PageLoader from "../../../../Common/PageLoader";
import ConfirmIdentityModal from "../../../../StaffHub/Employee/ViewProfile/ConfirmIdentityModal";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import { ActivityBasicInformationPropsUk } from "../../../../../types-definition/admin";

const ActivityBasicInformationUk = () => {
  const [showDOB, setShowDOB] = useState<boolean>(false);
  const [, setShowNIN] = useState<boolean>(false);
  const [, setShowVisaNumber] = useState<boolean>(false);
  const [, setShowDocumentNumber] = useState<boolean>(false);
  const [, setShowCertificateNumber] = useState<boolean>(false);
  const [dataToBeViewed, setDataToBeViewed] = useState<
    | "Password"
    | "TIN"
    | "NIN"
    | "Date Of Birth"
    | "Document Number"
    | "Visa Number"
    | "Certificate Number"
  >("Password");
  const [showConfirmIdentityModal, setShowConfirmIdentityModal] =
    useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [employeeData, setEmployeeData] =
    useState<ActivityBasicInformationPropsUk>({
      firstName: "",
      lastName: "",
      username: "",
      dob: "",
      gender: "",
      photo: "",
      proofOfId: "",
      startDate: "",
      nationality: "",
      citizenshipStatus: "",
      countryOfCitizenShip: "",
      documentType: "",
      documentNumber: "",
      issueDate: "",
      expiryDate: "",
      placeOfIssue: "",
      visaType: "",
      visaNumber: "",
      visaIssueDate: "",
      visaExpiryDate: "",
      updatedAt: "",
    });
  const [previousEmployeeData, setPreviousEmployeeData] =
    useState<ActivityBasicInformationPropsUk>({
      firstName: "",
      lastName: "",
      username: "",
      dob: "",
      gender: "",
      photo: "",
      proofOfId: "",
      startDate: "",
      nationality: "",
      citizenshipStatus: "",
      countryOfCitizenShip: "",
      documentType: "",
      documentNumber: "",
      issueDate: "",
      expiryDate: "",
      placeOfIssue: "",
      visaType: "",
      visaNumber: "",
      visaIssueDate: "",
      visaExpiryDate: "",
      updatedAt: "",
    });
  const id = getActivityBasicInformation();
  // const handleEditEmployee = () => {
  //   setCurrentLocation("/employee/view-profile");
  //   navigate("/employee/basic-info");
  // };
  const handleShowData = () => {
    if (dataToBeViewed === "Date Of Birth") {
      setShowDOB(true);
    } else if (dataToBeViewed === "NIN") {
      setShowNIN(true);
    } else if (dataToBeViewed === "Document Number") {
      setShowDocumentNumber(true);
    } else if (dataToBeViewed === "Visa Number") {
      setShowVisaNumber(true);
    } else if (dataToBeViewed === "Certificate Number") {
      setShowCertificateNumber(true);
    }
    setShowConfirmIdentityModal(false);
  };
  useEffect(() => {
    const getEmployeeData = async () => {
      // get employee data
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data.newRecord;
        const previousData = response.data.previousRecord;
        if (response.data.action === "POST") {
          setEmployeeData({
            firstName: data?.basicInfo?.firstName,
            lastName: data?.basicInfo?.lastName,
            username: data?.basicInfo?.username,
            startDate: data?.basicInfo?.startDate,
            nationality: data?.basicInfo?.nationality,
            dob: data?.basicInfo?.dob,
            photo: data?.photo,
            proofOfId: data?.proofOfId,
            gender: data?.basicInfo.gender,
            citizenshipStatus: data?.identificationDocument?.citizenshipStatus,
            countryOfCitizenShip:
              data?.identificationDocument?.countryOfCitizenship,
            documentType: data?.identificationDocument?.documentType,
            documentNumber: data?.identificationDocument?.documentNumber,
            issueDate: data?.identificationDocument?.issueDate,
            expiryDate: data?.identificationDocument?.expiryDate,
            placeOfIssue: data?.identificationDocument?.placeOfIssue,
            visaType: data?.visaInformation?.visaType,
            visaNumber: data?.visaInformation?.visaNumber,
            visaIssueDate: data?.visaInformation?.issueDate,
            visaExpiryDate: data?.visaInformation?.expiryDate,
            updatedAt: response?.data.updatedAt,
          });
          // setPreviousEmployeeData({
          //   firstName: previousData?.basicInfo?.firstName,
          //   lastName: previousData?.basicInfo?.lastName,
          //   username: previousData?.basicInfo?.username,
          //   startDate: previousData?.basicInfo?.startDate,
          //   nationality: previousData?.basicInfo?.nationality,
          //   dob: previousData?.basicInfo?.dob,
          //   photo: previousData?.employeePhoto,
          //   proofOfId: previousData?.employeeProofOfId,
          //   gender: previousData?.basicInfo?.gender,
          //   citizenshipStatus: data?.identificationDocument?.citizenshipStatus,
          //   countryOfCitizenShip:
          //     previousData?.identificationDocument?.countryOfCitizenship,
          //   documentType: previousData?.identificationDocument?.documentType,
          //   documentNumber:
          //     previousData?.identificationDocument?.documentNumber,
          //   issueDate: previousData?.identificationDocument?.issueDate,
          //   expiryDate: previousData?.identificationDocument?.expiryDate,
          //   placeOfIssue: previousData?.identificationDocument?.placeOfIssue,
          //   visaType: previousData?.visaInformation?.visaType,
          //   visaNumber: previousData?.visaInformation?.visaNumber,
          //   visaIssueDate: previousData?.visaInformation?.issueDate,
          //   visaExpiryDate: previousData?.visaInformation?.expiryDate,
          // });
        }
        if (response.data.action === "DELETE") {
          // setEmployeeData({
          //   firstName: data?.body?.firstName,
          //   lastName: data?.body?.lastName,
          //   username: data?.body?.username,
          //   startDate: data?.body?.startDate,
          //   nationality: data?.body?.nationality,
          //   dob: data?.body?.dob,
          //   photo: data?.files?.photo ? data?.files?.photo[0] : "",
          //   proofOfId: data?.files?.proofOfId ? data?.files?.proofOfId[0] : "",
          //   gender: data?.body?.gender,
          //   citizenshipStatus: data?.identificationDocument?.citizenshipStatus,
          //   countryOfCitizenShip:
          //     data?.identificationDocument?.countryOfCitizenship,
          //   documentType: data?.identificationDocument?.documentType,
          //   documentNumber: data?.identificationDocument?.documentNumber,
          //   issueDate: data?.identificationDocument?.issueDate,
          //   expiryDate: data?.identificationDocument?.expiryDate,
          //   placeOfIssue: data?.identificationDocument?.placeOfIssue,
          //   visaType: data?.visaInformation?.visaType,
          //   visaNumber: data?.visaInformation?.visaNumber,
          //   visaIssueDate: data?.visaInformation?.issueDate,
          //   visaExpiryDate: data?.visaInformation?.expiryDate,
          //   updatedAt: response?.data.updatedAt,
          // });
          setPreviousEmployeeData({
            firstName: previousData?.basicInformation?.firstName,
            lastName: previousData?.basicInformation?.lastName,
            username: previousData?.basicInformation?.username,
            startDate: previousData?.basicInformation?.startDate,
            nationality: previousData?.basicInfo?.nationality,
            dob: previousData?.basicInformation?.dob,
            photo: previousData?.employeePhoto,
            proofOfId: previousData?.employeeProofOfId,
            gender: previousData?.basicInformation?.gender,
            citizenshipStatus: data?.identificationDocument?.citizenshipStatus,
            countryOfCitizenShip:
              previousData?.identificationDocument?.countryOfCitizenship,
            documentType: previousData?.identificationDocument?.documentType,
            documentNumber:
              previousData?.identificationDocument?.documentNumber,
            issueDate: previousData?.identificationDocument?.issueDate,
            expiryDate: previousData?.identificationDocument?.expiryDate,
            placeOfIssue: previousData?.identificationDocument?.placeOfIssue,
            visaType: previousData?.visaInformation?.visaType,
            visaNumber: previousData?.visaInformation?.visaNumber,
            visaIssueDate: previousData?.visaInformation?.issueDate,
            visaExpiryDate: previousData?.visaInformation?.expiryDate,
            updatedAt: response?.data.updatedAt,
          });
        } else {
          setEmployeeData({
            firstName: data?.body?.firstName,
            lastName: data?.body?.lastName,
            username: data?.body?.username,
            startDate: data?.body?.startDate,
            nationality: data?.body?.nationality,
            dob: data?.body?.dob,
            photo: data?.files?.photo ? data?.files?.photo[0] : "",
            proofOfId: data?.files?.proofOfId ? data?.files?.proofOfId[0] : "",
            gender: data?.body?.gender,
            citizenshipStatus: data?.identificationDocument?.citizenshipStatus,
            countryOfCitizenShip:
              data?.identificationDocument?.countryOfCitizenship,
            documentType: data?.identificationDocument?.documentType,
            documentNumber: data?.identificationDocument?.documentNumber,
            issueDate: data?.identificationDocument?.issueDate,
            expiryDate: data?.identificationDocument?.expiryDate,
            placeOfIssue: data?.identificationDocument?.placeOfIssue,
            visaType: data?.visaInformation?.visaType,
            visaNumber: data?.visaInformation?.visaNumber,
            visaIssueDate: data?.visaInformation?.issueDate,
            visaExpiryDate: data?.visaInformation?.expiryDate,
            updatedAt: response?.data.updatedAt,
          });
          setPreviousEmployeeData({
            firstName: previousData?.basicInformation?.firstName,
            lastName: previousData?.basicInformation?.lastName,
            username: previousData?.basicInformation?.username,
            startDate: previousData?.basicInformation?.startDate,
            nationality: previousData?.basicInfo?.nationality,
            dob: previousData?.basicInformation?.dob,
            photo: previousData?.employeePhoto,
            proofOfId: previousData?.employeeProofOfId,
            gender: previousData?.basicInformation?.gender,
            citizenshipStatus: data?.identificationDocument?.citizenshipStatus,
            countryOfCitizenShip:
              previousData?.identificationDocument?.countryOfCitizenship,
            documentType: previousData?.identificationDocument?.documentType,
            documentNumber:
              previousData?.identificationDocument?.documentNumber,
            issueDate: previousData?.identificationDocument?.issueDate,
            expiryDate: previousData?.identificationDocument?.expiryDate,
            placeOfIssue: previousData?.identificationDocument?.placeOfIssue,
            visaType: previousData?.visaInformation?.visaType,
            visaNumber: previousData?.visaInformation?.visaNumber,
            visaIssueDate: previousData?.visaInformation?.issueDate,
            visaExpiryDate: previousData?.visaInformation?.expiryDate,
            updatedAt: response?.data.updatedAt,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getEmployeeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      {showConfirmIdentityModal && (
        <ConfirmIdentityModal
          dataViewed={dataToBeViewed}
          cancelFunction={() => setShowConfirmIdentityModal(false)}
          successfunction={handleShowData}
        />
      )}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Employee Details
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">First Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.firstName ? employeeData.firstName : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Last Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.lastName ? employeeData.lastName : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Username</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData?.username ?? "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Password</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  **************
                </Typography>
                {/* <div
                  className={styles.password_button}
                  onClick={() => {
                    dispatch(setShowConfirmResetPasswordModal(true));
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    Reset Password
                  </Typography>
                </div> */}
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Date Of Birth</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {showDOB && employeeData?.dob
                    ? moment(employeeData?.dob).format("DD/MM/YYYY")
                    : !employeeData?.dob && showDOB
                      ? "-"
                      : "**/**/****"}
                </Typography>
                <div
                  className={styles.view_dob_button}
                  onClick={() => {
                    // setShowDOB((prevData: boolean) => !prevData);
                    if (!showDOB) {
                      setShowConfirmIdentityModal(true);
                      setDataToBeViewed("Date Of Birth");
                    } else {
                      setShowDOB(false);
                    }
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    {showDOB ? "Hide" : "View"}
                  </Typography>
                </div>
              </div>
              <div>
                <Typography textAlign="left">Start Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData?.startDate
                    ? moment(new Date(employeeData?.startDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Gender</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.gender ? capitalize(employeeData.gender) : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Nationality</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.nationality ? employeeData.nationality : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <EmployeeUploadedItem
                label="Photo"
                value={{
                  originalName:
                    employeeData?.photo?.originalName ??
                    employeeData?.photo?.originalname,
                  lastModifiedDate: new Date(
                    employeeData?.photo?.updatedAt ?? employeeData.updatedAt,
                  ),
                  size: employeeData?.photo?.size ?? 10000000,
                  URL:
                    employeeData?.photo?.photo ?? employeeData?.photo?.location,
                }}
                width="350px"
              />
              <EmployeeUploadedItem
                label="Proof Of Id"
                value={{
                  originalName:
                    employeeData?.proofOfId?.originalName ??
                    employeeData?.proofOfId?.originalname,
                  lastModifiedDate: new Date(
                    employeeData?.proofOfId?.updatedAt ??
                      employeeData.updatedAt,
                  ),
                  size: employeeData?.proofOfId?.size ?? 10000000,
                  URL:
                    employeeData?.proofOfId?.proofOfId ??
                    employeeData?.proofOfId?.location,
                }}
                width="350px"
              />
            </div>
            {/* <HorizontalRule
              width="80%"
              marginTop="20px"
              borderColor={colors.gray2}
            /> */}
          </div>
          {/* <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Identification Document
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Citizenship Status</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.citizenshipStatus
                    ? employeeData.citizenshipStatus.toUpperCase()
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Country Of Citizenship</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.countryOfCitizenShip
                    ? employeeData.countryOfCitizenShip
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Document Type</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.documentType
                    ? capitalize(employeeData.documentType)
                    : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Document Number</Typography>
                <div className={styles.flex_container_button}>
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    {showDocumentNumber && employeeData.documentNumber
                      ? employeeData.documentNumber
                      : !showDocumentNumber && employeeData.documentNumber
                        ? showSelectedLetter(employeeData.documentNumber, 2, 2)
                        : "-"}
                  </Typography>
                  <div
                    className={styles.flex_container_button_item}
                    onClick={() => {
                      if (!showDocumentNumber) {
                        setShowConfirmIdentityModal(true);
                        setDataToBeViewed("Document Number");
                      } else {
                        setShowDocumentNumber(false);
                      }
                    }}
                  >
                    <Typography
                      textColor={colors.primary3}
                      paddingVertical="-5px"
                      marginVertical="0px"
                      textSize="12px"
                      textWeight="600"
                    >
                      {showDocumentNumber ? "Hide" : "View"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Issue Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.issueDate
                    ? moment(new Date(employeeData.issueDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Expiry Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.expiryDate
                    ? moment(new Date(employeeData.expiryDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Place of Issue</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.placeOfIssue ? employeeData.placeOfIssue : "-"}
                </Typography>
              </div>
            </div>
            <HorizontalRule
              width="80%"
              marginTop="20px"
              borderColor={colors.gray2}
            />
          </div>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Visa Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Visa Type</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.visaType ? employeeData.visaType : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Visa Number</Typography>
                <div className={styles.flex_container_button}>
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    {showVisaNumber && employeeData.visaNumber
                      ? employeeData.visaNumber
                      : !showVisaNumber && employeeData.visaNumber
                        ? showSelectedLetter(employeeData.visaNumber, 2, 2)
                        : "-"}
                  </Typography>
                  <div
                    className={styles.flex_container_button_item}
                    onClick={() => {
                      if (!showVisaNumber) {
                        setShowConfirmIdentityModal(true);
                        setDataToBeViewed("Visa Number");
                      } else {
                        setShowVisaNumber(false);
                      }
                    }}
                  >
                    <Typography
                      textColor={colors.primary3}
                      paddingVertical="-5px"
                      marginVertical="0px"
                      textSize="12px"
                      textWeight="600"
                    >
                      {showVisaNumber ? "Hide" : "View"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Issue Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.visaIssueDate
                    ? moment(new Date(employeeData.visaIssueDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Expiry Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.visaExpiryDate
                    ? moment(new Date(employeeData.visaExpiryDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* Previous Record */}

      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Basic Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">First Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.firstName
                    ? previousEmployeeData.firstName
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Last Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.lastName
                    ? previousEmployeeData.lastName
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Username</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData?.username
                    ? previousEmployeeData.username
                    : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Password</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData?.username ? "**************" : "-"}
                </Typography>
                {/* <div
                  className={styles.password_button}
                  onClick={() => {
                    dispatch(setShowConfirmResetPasswordModal(true));
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    Reset Password
                  </Typography>
                </div> */}
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Date Of Birth</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {showDOB && previousEmployeeData?.dob
                    ? moment(previousEmployeeData?.dob).format("DD/MM/YYYY")
                    : !previousEmployeeData?.dob && showDOB
                      ? "-"
                      : "**/**/****"}
                </Typography>
                {previousEmployeeData.dob && (
                  <div
                    className={styles.view_dob_button}
                    onClick={() => {
                      // setShowDOB((prevData: boolean) => !prevData);
                      if (!showDOB) {
                        setShowConfirmIdentityModal(true);
                        setDataToBeViewed("Date Of Birth");
                      } else {
                        setShowDOB(false);
                      }
                    }}
                  >
                    <Typography
                      textColor={colors.primary3}
                      paddingVertical="-5px"
                      marginVertical="0px"
                      textSize="12px"
                      textWeight="600"
                    >
                      {showDOB ? "Hide" : "View"}
                    </Typography>
                  </div>
                )}
              </div>
              <div>
                <Typography textAlign="left">Start Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData?.startDate
                    ? moment(new Date(previousEmployeeData?.startDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Gender</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.gender
                    ? capitalize(previousEmployeeData.gender)
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Nationality</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.nationality
                    ? previousEmployeeData.nationality
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <EmployeeUploadedItem
                label="Photo"
                value={{
                  originalName: previousEmployeeData?.photo?.originalName,
                  lastModifiedDate: new Date(
                    previousEmployeeData?.photo?.updatedAt,
                  ),
                  size: previousEmployeeData?.photo?.size ?? 10000000,
                  URL: previousEmployeeData?.photo?.photo,
                }}
                width="350px"
              />
              <EmployeeUploadedItem
                label="Proof Of Id"
                value={{
                  originalName: previousEmployeeData?.proofOfId?.originalName,
                  lastModifiedDate: new Date(
                    previousEmployeeData?.proofOfId?.updatedAt,
                  ),
                  size: previousEmployeeData?.proofOfId?.size ?? 10000000,
                  URL: previousEmployeeData?.proofOfId?.proofOfId,
                }}
                width="350px"
              />
            </div>
            {/* <HorizontalRule
              width="80%"
              marginTop="20px"
              borderColor={colors.gray2}
            /> */}
          </div>
          {/* <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Identification Document
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Citizenship Status</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.citizenshipStatus
                    ? previousEmployeeData.citizenshipStatus.toUpperCase()
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Country Of Citizenship</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.countryOfCitizenShip
                    ? previousEmployeeData.countryOfCitizenShip
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Document Type</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.documentType
                    ? capitalize(previousEmployeeData.documentType)
                    : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Document Number</Typography>
                <div className={styles.flex_container_button}>
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    {showDocumentNumber && previousEmployeeData.documentNumber
                      ? previousEmployeeData.documentNumber
                      : !showDocumentNumber &&
                          previousEmployeeData.documentNumber
                        ? showSelectedLetter(
                            previousEmployeeData.documentNumber,
                            2,
                            2,
                          )
                        : "-"}
                  </Typography>
                  <div
                    className={styles.flex_container_button_item}
                    onClick={() => {
                      if (!showDocumentNumber) {
                        setShowConfirmIdentityModal(true);
                        setDataToBeViewed("Document Number");
                      } else {
                        setShowDocumentNumber(false);
                      }
                    }}
                  >
                    <Typography
                      textColor={colors.primary3}
                      paddingVertical="-5px"
                      marginVertical="0px"
                      textSize="12px"
                      textWeight="600"
                    >
                      {showDocumentNumber ? "Hide" : "View"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Issue Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.issueDate
                    ? moment(new Date(previousEmployeeData.issueDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Expiry Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.expiryDate
                    ? moment(new Date(previousEmployeeData.expiryDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Place of Issue</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.placeOfIssue
                    ? previousEmployeeData.placeOfIssue
                    : "-"}
                </Typography>
              </div>
            </div>
            <HorizontalRule
              width="80%"
              marginTop="20px"
              borderColor={colors.gray2}
            />
          </div>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Visa Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Visa Type</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.visaType
                    ? previousEmployeeData.visaType
                    : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Visa Number</Typography>
                <div className={styles.flex_container_button}>
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    {showVisaNumber && previousEmployeeData.visaNumber
                      ? previousEmployeeData.visaNumber
                      : !showVisaNumber && previousEmployeeData.visaNumber
                        ? showSelectedLetter(
                            previousEmployeeData.visaNumber,
                            2,
                            2,
                          )
                        : "-"}
                  </Typography>
                  <div
                    className={styles.flex_container_button_item}
                    onClick={() => {
                      if (!showVisaNumber) {
                        setShowConfirmIdentityModal(true);
                        setDataToBeViewed("Visa Number");
                      } else {
                        setShowVisaNumber(false);
                      }
                    }}
                  >
                    <Typography
                      textColor={colors.primary3}
                      paddingVertical="-5px"
                      marginVertical="0px"
                      textSize="12px"
                      textWeight="600"
                    >
                      {showVisaNumber ? "Hide" : "View"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Issue Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.visaIssueDate
                    ? moment(
                        new Date(previousEmployeeData.visaIssueDate),
                      ).format("DD/MM/YYYY")
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Expiry Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.visaExpiryDate
                    ? moment(
                        new Date(previousEmployeeData.visaExpiryDate),
                      ).format("DD/MM/YYYY")
                    : "-"}
                </Typography>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ActivityBasicInformationUk;
