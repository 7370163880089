import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

interface initialStateInterface {
  showSelectDeliveryModeModal: boolean;
  deliveryMode: Array<"mail" | "app">;
  selectedEmployees: Array<string>;
  showSendMessageModal: boolean;
  employeeId: string;
  entryMode: "group" | "individual";
}

const initialState: initialStateInterface = {
  showSelectDeliveryModeModal: false,
  deliveryMode: [],
  selectedEmployees: [],
  showSendMessageModal: false,
  employeeId: "",
  entryMode: "group",
};

const employeeMessage = createSlice({
  name: "employeeMessage",
  initialState,
  reducers: {
    setShowSelectDeliveryModeModal: (state, action: PayloadAction<boolean>) => {
      state.showSelectDeliveryModeModal = action.payload;
    },
    setDeliveryMode: (state, action: PayloadAction<"mail" | "app">) => {
      const index = state.deliveryMode.indexOf(action.payload);
      if (index === -1) {
        state.deliveryMode.push(action.payload);
      } else {
        state.deliveryMode.splice(index, 1);
      }
    },
    setSelectedEmployees: (state, action: PayloadAction<string>) => {
      const index = state.selectedEmployees.indexOf(action.payload);
      if (index === -1) {
        state.selectedEmployees.push(action.payload);
      } else {
        state.selectedEmployees.splice(index, 1);
      }
    },
    setShowSendMessageModal: (state, action: PayloadAction<boolean>) => {
      state.showSendMessageModal = action.payload;
    },
    setEmployeeMessageIndividualId: (state, action: PayloadAction<string>) => {
      state.employeeId = action.payload;
    },
    setMessageEmployeeEntryMode: (
      state,
      action: PayloadAction<"group" | "individual">,
    ) => {
      state.entryMode = action.payload;
    },
  },
});
export const {
  setDeliveryMode,
  setShowSelectDeliveryModeModal,
  setSelectedEmployees,
  setShowSendMessageModal,
  setEmployeeMessageIndividualId,
  setMessageEmployeeEntryMode,
} = employeeMessage.actions;

export const getShowSelectDeliveryModeModal = (state: RootState) =>
  state.employeeMessage.showSelectDeliveryModeModal;
export const getDeliveryModeState = (state: RootState) =>
  state.employeeMessage.deliveryMode;
export const getSelectedEmployees = (state: RootState) =>
  state.employeeMessage.selectedEmployees;
export const getShowSendMessageModal = (state: RootState) =>
  state.employeeMessage.showSendMessageModal;
export const getEmployeeIndividualId = (state: RootState) =>
  state.employeeMessage.employeeId;
export const getMessageEmployeeEntryMode = (state: RootState) =>
  state.employeeMessage.entryMode;
export default employeeMessage.reducer;
