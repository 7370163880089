import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import ActiveState from "../../../Common/ActiveState";
import TableEditDropdown from "../../../Common/TableEditDropdown";
import RequirementState from "../../../Common/RequirementState";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import { CertificateTableData } from "../../../../types-definition/StaffHub/certificateType";
import { deleteRequest } from "../../../../api/request";
import { DELETE_CERTIFICATE_TYPE_URL } from "../../../../api/urls";
import { setTimeShiftGeneration } from "../../../../utils/localstorage";
import { updateCertificateState } from "../../../../redux/slice/staff-hub/certificateTypeSlice";
import { useAppDispatch } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { toastSuccess } from "../../../../utils/toast";

const CertificateTypeTableItem: React.FC<CertificateTableData> = ({
  id,
  name,
  requirementLevel,
  no,
  active,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showEditDropdown, setshowEditDropdown] = useState(false);

  const deleteHandler = async () => {
    const response = await deleteRequest(
      `${DELETE_CERTIFICATE_TYPE_URL}/${id}`,
    );
    if (response?.success) {
      toastSuccess("Certificate Type Deleted Successfully");
    } else {
    }

    dispatch(updateCertificateState({ newState: true }));
    navigate("/staff-hub/certificate-type");
    setshowEditDropdown(false);
  };

  const editHandler = () => {
    setTimeShiftGeneration(id);
    navigate("/staff-hub/add-certificate-type");
  };
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        {/* <CircularName name={`${name} ${name}`} /> */}
        <Typography textAlign="left" textWeight="600">
          {name}
        </Typography>
      </td>
      <td className={styles.requirementLevel}>
        <RequirementState
          text={
            requirementLevel === "ifRequiredByService"
              ? "If Required By Service"
              : "All Service"
          }
        />
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{no}</Typography>
      </td>
      <td className={styles.td}>
        <ActiveState active={active} />
      </td>
      <td
        className={`${styles.td} ${styles.td_relative}`}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        {showEditDropdown && (
          <TableEditDropdown
            data={[
              {
                label: "Edit",
                action: () => {
                  editHandler();
                },
              },
              {
                label: "Delete",
                action: () => {
                  deleteHandler();
                },
              },
            ]}
          />
        )}
        <div
          className={styles.image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default CertificateTypeTableItem;
