import axios from "axios";
import { BASE_URL } from "./urls";
import { deleteData, getData } from "../utils/localstorage";
import { toastError } from "../utils/toast";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

export const getRequest = async (url: string) => {
  try {
    const userData = JSON.parse(getData()!);
    let response;
    if (userData !== null) {
      response = await axiosInstance.get(url, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
    } else {
      response = await axiosInstance.get(url);
    }
    return response.data;
  } catch (error: any) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401) {
        toastError("Unauthotized! Re-login to continue");
        deleteData();
        window.location.href = "/login";
        // return error.response.data;
      }
      if (error.response.data) {
        return error.response.data;
      }
    } else if (error.request) {
      // toastError("Error in request");
      return { success: false, error: error.request };
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // Something happened in setting up the request that triggered an Error
      // toastError("Error in request");
      return { success: false, error: error };
    }
  }
};

export const postRequest = async (url: string, data?: any) => {
  try {
    const userData = JSON.parse(getData()!);
    let response;
    if (userData !== null) {
      response = await axiosInstance.post(url, data, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
    } else {
      response = await axiosInstance.post(url, data);
    }
    return response.data;
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        toastError("Unauthotized! Re-login to continue");
        deleteData();
        window.location.href = "/login";
        return;
      }
      if (error.response.data) {
        return error.response.data;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      toastError("Error in request");
      return { success: false, error: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      toastError("Error in request");
      return { success: false, error: error };
    }
  }
};

export const patchRequest = async (url: string, data?: any) => {
  try {
    const userData = JSON.parse(getData()!);
    let response;
    if (userData !== null) {
      response = await axiosInstance.patch(url, data, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
    } else {
      response = await axiosInstance.post(url, data);
    }
    return response.data;
  } catch (error: any) {
    // alert(error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401) {
        toastError("Error in request");
        deleteData();
        window.location.href = "/login";
        return;
      }
      if (error.response.data) {
        return error.response.data;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js

      toastError("Error in request");
      return { success: false, error: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error

      toastError("Error in request");
      return { success: false, error: error };
    }
  }
};

export const putRequest = async (url: string, data?: any) => {
  try {
    const userData = JSON.parse(getData()!);
    let response;
    if (userData !== null) {
      response = await axiosInstance.put(url, data, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
    } else {
      response = await axiosInstance.post(url, data);
    }
    return response.data;
  } catch (error: any) {
    // alert(error);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401) {
        toastError("Unauthotized! Re-login to continue");
        deleteData();
        window.location.href = "/login";
        return;
      }
      if (error.response.data) {
        return error.response.data;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      toastError("Error in request");
    } else {
      // Something happened in setting up the request that triggered an Error
      toastError("Error in request");
      return { success: false, error: error };
    }
  }
};

export const deleteRequest = async (url: string, data?: any) => {
  try {
    const userData = JSON.parse(getData()!);
    let response;
    if (userData !== null) {
      response = await axiosInstance.delete(url, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
    } else {
      response = await axiosInstance.post(url, data);
    }
    return response.data;
  } catch (error: any) {
    // alert(error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401) {
        toastError("Unauthotized! Re-login to continue");
        deleteData();
        window.location.href = "/login";
        return;
      }
      if (error.response.data) {
        return error.response.data;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      toastError("Error in request");
      return { success: false, error: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      toastError("Error in request");
      return { success: false, error: error };
    }
  }
};

