import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import ActiveState from "../../../Common/ActiveState";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import PermissionGroupTableDropdown from "../PermissionGroupTableDropDown";
import { PermissionGroupData } from "../../../../types-definition/admin";
const PermisionGroupTableItem: React.FC<PermissionGroupData> = ({
  id,
  active,
  description,
  name,
  defaultPermission,
  cloned,
}) => {
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  const handleHideModal = () => {
    setshowEditDropdown(false);
  };
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        <Typography textAlign="left">{name}</Typography>
      </td>
      <td className={styles.td_mail}>
        <Typography textAlign="left">{description ?? "N/A"}</Typography>
      </td>

      <td className={styles.td}>
        <ActiveState active={active} />
      </td>
      <td
        className={`${styles.td} ${styles.td_relative}`}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        {showEditDropdown && (
          <PermissionGroupTableDropdown
            id={id}
            cloned={cloned}
            handleHideModal={handleHideModal}
            defaultPermission={defaultPermission}
          />
        )}
        <div
          className={styles.image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default PermisionGroupTableItem;
