import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "components/Common/Typography";
import size from "theme/size";
import PasswordInput from "components/Form/PasswordInput";
import TextInput from "components/Form/TextInput";
import styles from "./index.module.css";
import Button from "components/Form/Button";
import SignInButton from "components/Form/SignInButton";
import Divider from "components/Common/Divider";
import colors from "theme/colors";
import Checkbox from "components/Form/Checkbox";
import { Link } from "react-router-dom";
import emailIcon from "assets/svg/Mail Icon.svg";
import googleIcon from "assets/svg/Google-Icon.svg";
import microsoftIcon from "assets/svg/Microsoft-svg.svg";
import glixLogo from "assets/svg/glix-logo.svg";
import { getRequest, postRequest } from "api/request";
import {
  BASE_URL,
  GOOGLE_AUTH_URL,
  LOGIN_USER_URL,
  LOGIN_WEB_USER,
  MICROSOFT_AUTH_URL,
} from "api/urls";
import {
  createSocialProvider,
  getData,
  getShowPasscode,
  saveData,
  saveUserTypes,
  setCountry,
  setLogoutIdle,
  setReloadCountToZero,
  setSettings,
  setShowPasscode,
  setUserIdle,
} from "utils/localstorage";
import ScrollToTop from "components/Common/ScrollToTop";
import { toastError } from "utils/toast";
import PageLoader from "components/Common/PageLoader";
import SelectInput from "components/Form/SelectInput";
import { ADMIN, EMAIL, EMPLOYEE, WEBUSER } from "types-definition/constants";
import { LoginFormRequiredData } from "types-definition/auth";
import fieldTextValidator from "utils/validateFieldsWithText";
import scrolltoView from "utils/scrollToView";
import { setSetupComplete } from "utils/localstorage";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setShowRequirePasscode,
  showRequirePasscode,
} from "redux/slice/auth/authSlice";
import RequirePasscode from "components/Auth/RequirePasscode";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    userType: ADMIN,
  });
  const [requiredFormData, setRequiredFormData] =
    useState<LoginFormRequiredData>({
      email: {
        status: false,
        text: "",
      },
      password: {
        status: false,
        text: "",
      },
      userType: {
        status: false,
        text: "",
      },
    });

  const [requestLoading, setRequestLoading] = useState(false);
  const [rememberUser, setRememberUser] = useState(false);
  const handleFormSubmission = async () => {
    try {
      setRequestLoading(true);
      const { data, status, id } = fieldTextValidator(
        formData,
        requiredFormData,
      );
      if (!status) {
        setRequestLoading(false);
        setRequiredFormData((prevData) => {
          return {
            ...prevData,
            ...data,
          };
        });
        if (id) {
          scrolltoView(id);
        }
        toastError("Fill all required fields");
        return;
      }
      if (formData.userType === "Admin") {
        const response = await postRequest(LOGIN_USER_URL, {
          email: formData.email,
          password: formData.password,
          rememberMe: rememberUser,
        });
        if (response?.success) {
          const dataToSave = {
            id: response.data.id,
            name: `${response.data.firstName} ${response.data.lastName}`,
            email: response.data.email,
            token: response.accessToken,
            refreshToken: response.refreshToken,
            settingsId: response?.data?.id,
          };
          console.log(dataToSave)
          saveData(JSON.stringify(dataToSave));
          setCountry(response.data.country);
          setUserIdle(String(false));
          setLogoutIdle(String(false));
          saveUserTypes(formData.userType);
          setReloadCountToZero();
          createSocialProvider(EMAIL);
          const subscriptionResponse = await getRequest("/auth/me");
          if (parseFloat(response?.data?.setupStep) === 0) {
            setSetupComplete(
              JSON.stringify({
                completed: response?.data?.setupCompleted,
                step: "1",
              }),
            );
            console.log("subscriptionResponse", subscriptionResponse);
            if (
              subscriptionResponse?.data?.subscription?.length === 0 ||
              !subscriptionResponse?.data?.subscription
            ) {
              return navigate("/subscribe");
            }
            navigate("/setup-wizard");
          } else if (parseFloat(response?.data?.setupStep) > 13) {
            navigate("/dashboard");
            setSetupComplete(
              JSON.stringify({
                completed: true,
                step: response?.data?.setupStep,
              }),
            );
          } else {
            navigate("/setup-wizard");
            setSetupComplete(
              JSON.stringify({
                completed: response?.data?.setupCompleted,
                step: response?.data?.setupStep,
              }),
            );
          }
        } else {
          if (response?.data === "Redirect to pricing") {
            navigate("/subscribe");
          } else {
            toastError(response?.data?.toString());
          }
        }
      } else if (formData.userType === "Web User") {
        const response = await postRequest(LOGIN_WEB_USER, {
          identifier: formData.email,
          password: formData.password,
          rememberMe: rememberUser,
        });
        if (response?.success) {
          createSocialProvider(EMAIL);
          const dataToSave = {
            id: response.data.id,
            name: `${response.data.firstName} ${response.data.lastName}`,
            email: response.data.email,
            token: response.accessToken,
            refreshToken: response.refreshToken,
            settingsId: response?.data?.userId,
          };
          setSetupComplete(
            JSON.stringify({
              completed: true,
              step: "14",
            }),
          );
          saveData(JSON.stringify(dataToSave));
          setCountry(response.data.adminCountry);
          setUserIdle(String(false));
          setLogoutIdle(String(false));
          setSettings(response.data.settingsId);
          saveUserTypes(formData.userType);
          setReloadCountToZero();
          const subscriptionResponse = await postRequest("/auth/admin", {
            adminId: response?.data?.userId,
          });
          if (
            subscriptionResponse?.data?.subscription?.length === 0 ||
            !subscriptionResponse?.data?.subscription
          ) {
            return navigate("/subscribe");
          }
          navigate("/dashboard");
        } else {
          if (response?.data) {
            toastError(response?.data.toString());
          }
        }
      }
    } finally {
      setRequestLoading(false);
    }
  };

  const openGoogleUrl = () => {
    window.open(`${BASE_URL}${GOOGLE_AUTH_URL}`, "_self");
  };

  const openMicrosoftUrl = () => {
    window.open(`${BASE_URL}${MICROSOFT_AUTH_URL}`, "_self");
  };

  useEffect(() => {
    const data = getData();
    if (data !== null) {
      navigate("/dashboard");
    }

    setIsPageLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Require Access Code
  const dispatch = useAppDispatch();
  const localShowPasscode: boolean = JSON.parse(getShowPasscode() || "true");
  const showRequirePasscodeComponent = useAppSelector(showRequirePasscode);

  useLayoutEffect(() => {
    dispatch(setShowRequirePasscode(localShowPasscode));
  }, []);

  return (
    <>
      {isPageLoading && <PageLoader />}
      <ScrollToTop />
      {showRequirePasscodeComponent ? (
        <RequirePasscode />
      ) : (
        <main>
          <section className={styles.container}>
            <div className={styles.body_container}>
              <div className={styles.image}>
                <img src={glixLogo} alt="logo" width={56} height={56} />
              </div>
              <Typography
                textAlign="center"
                textSize={size.lg}
                textWeight="600"
              >
                Log In
              </Typography>
              <Typography textAlign="center" textSize={size.md}>
                Enter your credentials to access your account
              </Typography>
              <div className={styles.form_container}>
                <TextInput
                  value={formData.email}
                  setValue={(data) => {
                    setFormData((prevData) => {
                      return { ...prevData, email: data };
                    });
                    setRequiredFormData((prevData) => {
                      return {
                        ...prevData,
                        email: {
                          status: false,
                          text: "",
                        },
                      };
                    });
                  }}
                  label="Username/Email Address"
                  placeholder="Enter Username/Email"
                  right={
                    <img src={emailIcon} alt="icon" width={20} height={20} />
                  }
                  error={requiredFormData.email.status}
                  helperText={requiredFormData.email.text}
                  id="email"
                />
                <PasswordInput
                  value={formData.password}
                  setValue={(data) => {
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        password: data,
                      };
                    });
                    setRequiredFormData((prevData) => {
                      return {
                        ...prevData,
                        password: {
                          status: false,
                          text: "",
                        },
                      };
                    });
                  }}
                  label="Password"
                  placeholder="Enter Password"
                  error={requiredFormData.password.status}
                  helperText={requiredFormData.password.text}
                  id="password"
                />
                <SelectInput
                  value={formData.userType}
                  placeholder="Select User Type"
                  label="User Type"
                  setValue={(data) => {
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        userType: data,
                      };
                    });
                    setRequiredFormData((prevData) => {
                      return {
                        ...prevData,
                        userType: {
                          status: false,
                          text: "",
                        },
                      };
                    });
                  }}
                  options={[ADMIN, WEBUSER, EMPLOYEE]}
                  datalistTop="83px"
                  dropdownTop="45px"
                  error={requiredFormData.userType.status}
                  helperText={requiredFormData.userType.text}
                />
                <section className={styles.flex_display}>
                  <Checkbox
                    setValue={(value) => {
                      setRememberUser(value);
                    }}
                    value={rememberUser}
                    label={<Typography>Remember me for 30 days</Typography>}
                  />
                  <div
                    className={styles.forgot_password_container}
                    onClick={() => navigate("/forgot-password")}
                  >
                    <Typography
                      textWeight="600"
                      textAlign="right"
                      textColor={colors.primary3}
                    >
                      Forgot Password
                    </Typography>
                  </div>
                </section>
                <Button
                  text="Log into Account"
                  isPrimary={true}
                  isLoading={requestLoading}
                  onClick={() => {
                    handleFormSubmission();
                  }}
                />
              </div>
              <section className={styles.bottom_container}>
                <div className={styles.divider_container}>
                  <Divider width="45%" />
                  <Typography textColor={colors.gray1}>Or</Typography>
                  <Divider width="45%" />
                </div>
                <div>
                  <SignInButton
                    text="Login with Google"
                    onClick={openGoogleUrl}
                    image={googleIcon}
                  />
                  <SignInButton
                    text="Login with Microsoft"
                    onClick={openMicrosoftUrl}
                    image={microsoftIcon}
                  />
                </div>
              </section>
              <div className={styles.bottomText}>
                <Typography>Are you new here? &nbsp;</Typography>
                <Link to="/create-account" style={{ textDecoration: "none" }}>
                  <Typography
                    textWeight="600"
                    // style={{
                    //   background: "linear-gradient(#006093, #00dcda)",
                    //   WebkitTextFillColor: "transparent",
                    //   WebkitBackgroundClip: "text",
                    // }}
                    textColor={colors.primary3}
                  >
                    Create Account
                  </Typography>
                </Link>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default Login;
