const capitalize = (word: string): string => {
  const eachWord = word?.split(" ");
  for (let i = 0; i < eachWord.length; i++) {
    if (eachWord[i][0]) {
      eachWord[i] = eachWord[i][0]?.toUpperCase() + eachWord[i].substr(1);
    }
  }
  return eachWord.join(" ");
};

export default capitalize;
