import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import Button from "../../../Form/Button";
import { useAppDispatch } from "../../../../redux/hooks";
import circleCancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import TextInput from "../../../Form/TextInput";
import {
  setShowAddHolidayMultiplierModal,
  updateFetchedHolidayMultiplierCounter,
} from "../../../../redux/slice/admin/holidayMultiplierSlice";
import { postRequest } from "../../../../api/request";
import DateInput from "../../../Form/DateInput";
import {
  AddHolidayMultiplierFormData,
  AddHolidayMultiplierRequiredFormData,
} from "../../../../types-definition/admin";
import fieldTextValidator from "../../../../utils/validateFieldsWithText";
import NumberInput from "../../../Form/NumberInput";
import {
  getCountry,
  getSettings,
  getSetupComplete,
} from "../../../../utils/localstorage";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { US } from "types-definition/constants";
import { showWizardModal } from "redux/slice/WizardSetup";
import { toastError, toastSuccess } from "utils/toast";

const AddHolidayRateMultiplier: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = getSettings();
  const [requestLoading, setRequestLoading] = useState(false);
  const [formData, setFormData] = useState<AddHolidayMultiplierFormData>({
    holidayName: "",
    holidayDate: "",
    chargeRateMultiplier: "1.00",
    payRateMultiplier: "1.00",
  });
  const [requiredFormData, setRequiredFormData] =
    useState<AddHolidayMultiplierRequiredFormData>({
      holidayName: {
        status: false,
        text: "",
      },
      holidayDate: {
        status: false,
        text: "",
      },
    });

  const handleFormSubmit = async () => {
    setRequestLoading(true);
    const { data, status } = fieldTextValidator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      setRequestLoading(false);
      return;
    }
    const response = await postRequest(`settings/${id}/holiday-multiplier`, {
      holidayName: formData.holidayName,
      // holidayDate: moment.utc(new Date(formData.holidayDate)).toISOString(),
      holidayDate: moment
        .utc(
          moment.utc(new Date(formData.holidayDate)).format("DD/MM/YYYY"),
          "DD/MM/YYYY",
        )
        .add(1, "days")
        .toISOString(),
      chargeRateMultiplier: formData.chargeRateMultiplier,
      payRateMultiplier: formData.payRateMultiplier,
    });
    if (response?.success) {
      toastSuccess("Holiday Multiplier Added Successfully");
      const setUpStep = getSetupComplete();

      if (setUpStep) {
        if (!JSON.parse(setUpStep)?.completed) {
          dispatch(showWizardModal({ showModal: true }));
          navigate("/setup-wizard");
        } else {
          setRequestLoading(false);
          dispatch(setShowAddHolidayMultiplierModal(false));
          dispatch(updateFetchedHolidayMultiplierCounter());
        }
      } else {
        setRequestLoading(false);
        dispatch(setShowAddHolidayMultiplierModal(false));
        dispatch(updateFetchedHolidayMultiplierCounter());
      }
    } else {
      toastError(response?.data);
      setRequestLoading(false);
      // dispatch(setShowAddHolidayMultiplierModal(false));
      dispatch(updateFetchedHolidayMultiplierCounter());
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.flex_container}>
        <Typography textWeight="600" textSize="20px">
          Add Missing Multiplier
        </Typography>
        <img
          src={circleCancelIcon}
          alt="cancel-icon"
          onClick={() => dispatch(setShowAddHolidayMultiplierModal(false))}
        />
      </div>
      <div className={styles.form_container}>
        <TextInput
          label="Holiday Name"
          placeholder="Enter Holiday Name"
          value={formData.holidayName}
          setValue={(data) => {
            setFormData({ ...formData, holidayName: data });
            setRequiredFormData((prevData) => {
              return { ...prevData, holidayName: { status: false, text: "" } };
            });
          }}
          error={requiredFormData.holidayName.status}
          helperText={requiredFormData.holidayName.text}
          required
        />
        <DateInput
          value={formData.holidayDate}
          setValue={(data) => {
            setFormData({
              ...formData,
              holidayDate: data,
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, holidayDate: { status: false, text: "" } };
            });
          }}
          placeholder="Select Date"
          label="Holiday Date"
          required
          error={requiredFormData.holidayDate.status}
          helperText={requiredFormData.holidayDate.text}
          style={{ marginTop: "25px", marginBottom: "25px" }}
        />
        <NumberInput
          label="Pay Rate Multiplier"
          placeholder="Enter Pay Rate Multiplier"
          value={formData.payRateMultiplier}
          setValue={(data) => {
            return setFormData({ ...formData, payRateMultiplier: data });
          }}
        />
        <NumberInput
          value={formData.chargeRateMultiplier}
          setValue={(data) => {
            return setFormData({
              ...formData,
              chargeRateMultiplier: data,
            });
          }}
          label="Charge Rate Multiplier"
          placeholder="Enter Charge Rate Multiplier"
          style={{ marginTop: "25px", marginBottom: "25px" }}
        />
      </div>
      <div className={styles.button_container}>
        <Button
          text="Cancel"
          isPrimary={false}
          isPrimaryBorder
          backgroundColor="transparent"
          onClick={() => dispatch(setShowAddHolidayMultiplierModal(false))}
          marginHorizontal="5px"
        />
        <Button
          text="Save"
          isLoading={requestLoading}
          isPrimary
          marginHorizontal="5px"
          onClick={handleFormSubmit}
        />
      </div>
    </section>
  );
};

export default AddHolidayRateMultiplier;
