import React, { useState, useEffect } from "react";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import Typography from "components/Common/Typography";
import styles from "./index.module.css";
import colors from "theme/colors";
import TextInput from "components/Form/TextInput";
import searchIcon from "assets/svg/search-icon.svg";
import EmployeeLocationTable from "components/StaffHub/Employee/LinkToLocation/LinkToLocationTable";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { getNewState } from "../../../../redux/slice/staff-hub/linkToLocationSlice";
import LInkToLocationHeader from "components/StaffHub/Employee/LinkToLocation/link-to-location-header";
import LinkToLocationModal from "components/StaffHub/Employee/LinkToLocation/LinkToLocationModal";
import { LinkToLocationData } from "types-definition/StaffHub/employee/linkToLocation";
import { getCurrentEmployee } from "utils/localstorage";
import { getRequest } from "api/request";
import EmptyItemState from "components/Common/EmptyItemState";
import { GET_LINK_EMPLOYEE_TO_LOCATION } from "api/urls";
import PaginatedItems from "components/Common/Pagination";
import { refetchState } from "../../../../redux/slice/staff-hub/linkToLocationSlice";
import { handleReload } from "../../../../redux/slice/staff-hub/linkToLocationSlice";
import { toastError } from "utils/toast";
import PageBackButton from "components/Common/PageBackButton";

const LinkToLocation = () => {
  const dispatch = useAppDispatch();
  const reload = useAppSelector(refetchState);
  const showModal = useAppSelector(getNewState);
  const [employeeDetails, setEmployeeDetails] = useState<any>({});
  const [requestLoading, setRequestLoading] = useState(false);
  const [LocationData, setLocationData] = useState<Array<LinkToLocationData>>(
    [],
  );
  const [totalPage, setTotalPage] = useState<number>(0);
  const [filterData, setFilterData] = useState<Array<LinkToLocationData>>([]);
  const [search, setSeach] = useState("");

  const handlePaginate = (start: number, end: number) => {
    const currentPage = LocationData.slice(start, end);
    setFilterData(currentPage);
  };

  useEffect(() => {
    const getCurrentEmployeeLocation = async () => {
      const checkForEmployee = getCurrentEmployee();
      if (checkForEmployee) {
        setEmployeeDetails({
          userName: JSON.parse(checkForEmployee).name,
          displayName: JSON.parse(checkForEmployee).displayName,
          employeeId: JSON.parse(checkForEmployee).employeeId,
        });
        setRequestLoading(true);
        const assignedlocationResponse = await getRequest(
          `${GET_LINK_EMPLOYEE_TO_LOCATION}/${
            JSON.parse(checkForEmployee).name
          }/assigned-location`,
        );
        const response = await getRequest(
          `${GET_LINK_EMPLOYEE_TO_LOCATION}/${
            JSON.parse(checkForEmployee).name
          }/location`,
        );
        if (response?.success) {
          const responseData: Array<LinkToLocationData> = [];
          const filterByReference = (arr1: any, arr2: any) => {
            let res = [];
            res = arr1.filter((el: any) => {
              return !arr2.find((element: any) => {
                return element?.location?.id === el.locationId;
              });
            });
            return res;
          };

          const different = filterByReference(
            response.data,
            assignedlocationResponse.data,
          );
          for (const index in different) {
            const data = {
              locationId: different[index].locationId,
              location: different[index].location,
              distance: `${(
                different[index].getDistanceMatrix.rows[0].elements[0].distance
                  .value * 0.000621
              ).toFixed(2)}`,
              employeeUserName: JSON.parse(checkForEmployee).name,
            };
            responseData.push(data);
          }
          setTotalPage(responseData.length);
          setRequestLoading(false);
          setLocationData(responseData);
          setFilterData(responseData.slice(0, 5));
        } else {
          toastError(response?.data);
        }
      }
    };
    dispatch(handleReload({ reload: false }));
    getCurrentEmployeeLocation();
  }, [reload, dispatch]);
  return (
    <>
      {requestLoading ? (
        <PageLoader />
      ) : (
        <section>
          <ScrollToTop />
          <DashboardHeader active="staff/hub" />
          <div className={styles.container}>
            <div className={styles.item_container}>
              <PageBackButton
                width="95%"
                paddingTop="0px"
                marginBottom="20px"
              />
              <div className={styles.flex_item}>
                <div className={styles.item}>
                  <div className={styles.sub_flex_item}>
                    <Typography
                      text="Link Employee To Location"
                      textAlign="left"
                      textWeight="600"
                      textSize="20px"
                    />
                    <Typography
                      textAlign="left"
                      textColor={colors.primary3}
                      textWeight="600"
                    >
                      {employeeDetails.displayName}
                    </Typography>
                  </div>
                  <LInkToLocationHeader location="Available Locations" />
                </div>
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={search}
                  setValue={(data) => {
                    setSeach(data);
                    if (data) {
                      setFilterData(
                        LocationData.filter((prevData) =>
                          prevData.location
                            .toLowerCase()
                            .startsWith(data.toLowerCase()),
                        ),
                      );
                    } else {
                      setFilterData(LocationData);
                    }
                  }}
                />
              </div>
              {filterData.length < 1 ? (
                <div className={styles.emptyspace}>
                  <EmptyItemState
                    text="No location avaliable for linking"
                    location="client/site"
                    label="Add Location"
                  />
                </div>
              ) : (
                <EmployeeLocationTable data={filterData} />
              )}
              {totalPage > 5 && (
                <div className={styles.pagination}>
                  <PaginatedItems
                    itemsPerPage={5}
                    items={LocationData}
                    handlePaginate={handlePaginate}
                  />
                </div>
              )}
              {showModal && <LinkToLocationModal />}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default LinkToLocation;
