import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import colors from "../../../theme/colors";
import { RequirementStateProps } from "../../../types-definition/common";
const RequirementState: React.FC<RequirementStateProps> = ({ text }) => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: colors.successBg,
      }}
    >
      <Typography
        textWeight="500"
        textColor={colors.successtext}
        marginVertical="0px"
      >
        {text}
      </Typography>
    </div>
  );
};

export default RequirementState;
