import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import { useEffect, useRef, useState } from "react";
import colors from "theme/colors";
import { DefaultModalProps } from "types-definition/common";
import styles from "./index.module.css";

const CancelSubscriptionModal: React.FC<DefaultModalProps> = ({
  width,
  headingText,
  descriptionText,
  cancelText = "Cancel",
  successText = "Accept",
  children,
  cancelFunction,
  successFunction,
}) => {
  const modalContainerRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const modalContainer = modalContainerRef.current;
    const handleCloseModal = (event: MouseEvent) => {
      if (event.target === modalContainer) {
        setShowModal(false);
        cancelFunction?.();
      }
    };

    modalContainer?.addEventListener("click", handleCloseModal);

    return () => modalContainer?.removeEventListener("click", handleCloseModal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showModal && (
        <section ref={modalContainerRef} className={styles.container}>
          <div
            className={styles.content_container}
            style={{
              width: width || "30%",
            }}
          >
            <Typography textAlign="center" textWeight="600" textSize="24px">
              Subscription Cancellation
            </Typography>
            <Typography
              textSize="14px"
              textAlign="center"
              marginVertical="15px"
              textColor={colors.gray1}
            >
              <strong>Note:</strong> Cancelling your subscription will result in
              loss of access to premium features and services. Please note our
              data retention policy: your data will be retained for 30 days
              post-cancellation.
            </Typography>

            {children}

            <div className={styles.button_container}>
              <Button
                paddingVertical="6px"
                marginVertical="10px"
                isPrimary={false}
                borderWidth="2px"
                textSize="14px"
                textColor={colors.primary3}
                borderColor={colors.primary3}
                text={"Cancel"}
                backgroundColor={colors.transparent}
                onClick={() => {
                  setShowModal(false);
                  cancelFunction?.();
                }}
              />
              <Button
                paddingVertical="6px"
                marginVertical="10px"
                borderWidth="2px"
                borderColor={colors.primary3}
                textSize="14px"
                textColor={colors.white}
                isPrimary={true}
                text={"Confirm Cancellation"}
                onClick={() => {
                  setShowModal(false);
                  successFunction?.();
                }}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CancelSubscriptionModal;
