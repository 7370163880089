import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import Button from "components/Form/Button";
import EmployeeAccrualTable from "components/StaffHub/Employee/EmployeeAcrrual/AcrrualTable";
import colors from "theme/colors";
import addIcon from "assets/svg/add-icon.svg";
import SuccessModal from "components/Common/SuccessModal";
import { getRequest } from "api/request";
import PageBackButton from "components/Common/PageBackButton";
import { GET_ALL_EMPLOYEE_URL, GET_USER_SETTINGS_URL } from "api/urls";
import EmployeeAccuralCurrentYearAcomplishment from "components/StaffHub/Employee/EmployeeAcrrual/CurrentYearAccomplishment";
import EmployeeAccuralHolidayAbsenceSummary from "components/StaffHub/Employee/EmployeeAcrrual/HolidayAbsenceSummary";
import PageLoader from "../../.././../components/Common/PageLoader";
import { getCurrentEmployee, getHolidayMethod } from "utils/localstorage";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { useAppSelector } from "../../../../redux/hooks";
import { EmployeeAccrualTrackingData } from "types-definition/StaffHub/employee";
import { getEmployeeId } from "utils/localstorage";
import { sumPropertyInAnArray } from "utils/calculateAccrual";
import { useNavigate } from "react-router-dom";
import DeleteAccrualModal from "components/StaffHub/Employee/EmployeeAcrrual/DeleteAccrualModal";
import { setShowDeleteAccrualState } from "redux/slice/staff-hub/employeeAccrualSettingSlice";
import AccrualTrackingFilter from "components/StaffHub/Employee/EmployeeAcrrual/AccrualFIlter";

const AccrualTracking = () => {
  const navigate = useNavigate();
  const showModalDeleteModal: boolean = useAppSelector(
    setShowDeleteAccrualState,
  );
  const [requestLoading, setRequestLoading] = useState(true);
  const [, setIsloading] = useState(false);
  const [, setEmployeeId] = useState("");
  const [holidayMethod, setHolidayMethod] = useState("");
  const [entitlement, setEntitlement] = useState("0");
  const [absence, setAbsence] = useState("0");
  const [accruadHour, setAccruadHour] = useState("0");
  const [employeeDetails, setEmployeeDetails] = useState<any>({});
  const [holidayTaken, setHolidayTaken] = useState("");
  const [holidayType, setHolidayType] = useState("");
  const [filterField, setFilterField] = useState("");
  const [sortField, setSortFiled] = useState({ name: "", data: "" });

  const [employeeAccrualTracking, setEmployeeACrualTracking] = useState<
    Array<EmployeeAccrualTrackingData>
  >([]);
  const [filteredEmployeeAccrualTracking, setFilteredEmployeeACrualTracking] =
    useState<Array<EmployeeAccrualTrackingData>>([]);

  // const [formData, setFormData] = useState<AddAccrualAdjustmentFormData>({
  //   accrualCharge: "",
  //   adjustmentReason: "",
  //   applyOn: "",
  //   HasExpiration: false,
  //   expirationDate: "",
  // });

  const [showSuccess, setShowSuccess] = useState(false);

  const getAllEmployeeAccrual = async () => {
    const id = getEmployeeId();
    if (id) {
      setEmployeeId(id);
    }
    try {
      const response = await getRequest(
        `${GET_ALL_EMPLOYEE_URL}/${id}/accrual`,
      );
      if (response?.success) {
        let accrualTrackingData: Array<EmployeeAccrualTrackingData> = [];
        response.data.length > 0 &&
          setAccruadHour(sumPropertyInAnArray(response.data, "accruedDays"));
        response.data.forEach((item: any) => {
          const accrualTrackingItem = {
            id: item.id,
            dateCreated: item?.createdAt ?? new Date(),
            accrualChange: item.accrualCharge,
            accruedDays: item.accruedDays,
            reason:
              item.reason === "WORKED_SHIFT" ? "Worked Shift" : item.reason,
            dateApplied: item?.createdAt ?? new Date(),
            applicationStatus:
              item.status === "NOT_APPLIED" ? "Not Applied" : "Applied",
            expirationDate: item?.expirationDate
              ? item?.expirationDate
              : "null",
            createdBy:
              item.createdBy.id === "SYSTEM" ? "System" : item.createdBy.id,
          };

          accrualTrackingData.push(accrualTrackingItem);
        });

        setEmployeeACrualTracking(accrualTrackingData);
        setFilteredEmployeeACrualTracking(accrualTrackingData);
      } else {
        if (response?.data)
          toast.error(response?.data, {
            duration: 4000,
            position: "top-center",
          });
      }
    } finally {
      setRequestLoading(false);
    }
  };

  const getSettings = async () => {
    try {
      const holidayMethod = getHolidayMethod();
      setIsloading(true);
      if (holidayMethod) {
        setHolidayType(holidayMethod);

        setHolidayMethod(holidayMethod);
        const settingRes = await getRequest(GET_USER_SETTINGS_URL);
        if (settingRes.success) {
        }
        setEntitlement(settingRes.data.holidaySetting.holidayEntitlement);
        setIsloading(false);
      }
    } catch (error) {}
  };
  const getEmployeeHoliday = async () => {
    const empoloyeeId = getEmployeeId();

    try {
      setHolidayMethod(holidayMethod);

      const response = await getRequest(
        `${GET_ALL_EMPLOYEE_URL}/${empoloyeeId}/holiday`,
      );
      setAbsence(
        `${
          response.data.filter((item: any) => item.holidayType === "NO_SHOW")
            .length
        }`,
      );

      if (response.data) {
        const apprvedHoliday = response.data.filter(
          (hol: any) => hol.status === "approved",
        );
        const holidayTaken = apprvedHoliday.length;
        setHolidayTaken(holidayTaken);
      }
    } catch (error) {}
  };

  const getEmployeeInfo = () => {
    const checkForEmployee = getCurrentEmployee();
    if (checkForEmployee) {
      setEmployeeDetails({
        userName: JSON.parse(checkForEmployee).name,
        displayName: JSON.parse(checkForEmployee).displayName,
        employeeId: JSON.parse(checkForEmployee).employeeId,
      });
    }
  };

  const handleFilteringByString = () => {
    setFilteredEmployeeACrualTracking(
      employeeAccrualTracking.filter(
        (item) =>
          item.reason.toLowerCase().includes(filterField.toLowerCase()) ||
          item.applicationStatus
            .toLowerCase()
            .includes(filterField.toLowerCase()),
      ),
    );
  };

  useEffect(() => {
    const sortAccrualData = () => {
      let sortedArray: EmployeeAccrualTrackingData[] = [];
      if (sortField.name === "Expiration_Date") {
        if (sortField.data === "Ascending Order") {
          sortedArray = employeeAccrualTracking.sort((a: any, b: any) => {
            return (
              new Date(a.expirationDate).getTime() -
              new Date(b.expirationDate).getTime()
            );
          });
        } else {
          sortedArray = employeeAccrualTracking.sort((a: any, b: any) => {
            return (
              new Date(b.expirationDate).getTime() -
              new Date(a.expirationDate).getTime()
            );
          });
        }
      } else if (sortField.name === "Date_applied") {
        if (sortField.data === "Ascending Order") {
          sortedArray = employeeAccrualTracking.sort((a: any, b: any) => {
            return (
              new Date(a.expirationDate).getTime() -
              new Date(b.expirationDate).getTime()
            );
          });
        } else {
          sortedArray = employeeAccrualTracking.sort((a: any, b: any) => {
            return (
              new Date(b.expirationDate).getTime() -
              new Date(a.expirationDate).getTime()
            );
          });
        }
      } else if (sortField.name === "Date_Created") {
        if (sortField.data === "Ascending Order") {
          sortedArray = employeeAccrualTracking.sort((a: any, b: any) => {
            return (
              new Date(a.expirationDate).getTime() -
              new Date(b.expirationDate).getTime()
            );
          });
        } else {
          sortedArray = employeeAccrualTracking.sort((a: any, b: any) => {
            return (
              new Date(b.expirationDate).getTime() -
              new Date(a.expirationDate).getTime()
            );
          });
        }
      } else if (sortField.name === "Accrual_Charge") {
        if (sortField.data === "Ascending Order") {
          sortedArray = employeeAccrualTracking.sort((a: any, b: any) => {
            return parseInt(a.accrualChange) - parseInt(b.accrualChange);
          });
        } else {
          sortedArray = employeeAccrualTracking.sort((a: any, b: any) => {
            return parseInt(b.accrualChange) - parseInt(a.accrualChange);
          });
        }
      } else if (sortField.name === "Application_status") {
        if (sortField.data === "Not Applied") {
          sortedArray = employeeAccrualTracking.filter(
            (item: any) => item.applicationStatus === "Not Applied",
          );
        } else {
          sortedArray = employeeAccrualTracking.filter(
            (item: any) => item.applicationStatus === "Applied",
          );
        }
      } else {
      }
      setFilteredEmployeeACrualTracking(sortedArray);
    };

    sortAccrualData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField.data, sortField.name]);

  useEffect(() => {
    getEmployeeInfo();
    getEmployeeHoliday();
    getAllEmployeeAccrual();
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestLoading]);
  useEffect(() => {
    let timeout: any;
    if (showSuccess) {
      timeout = setTimeout(() => setShowSuccess(false), 5000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [showSuccess]);

  return (
    <>
      <ScrollToTop />
      {requestLoading ? (
        <PageLoader />
      ) : (
        <section>
          <DashboardHeader active="staff/hub" />
          {showSuccess && (
            <SuccessModal
              text="Employee Added successfully"
              cancelFunction={() => {
                setShowSuccess(false);
              }}
            />
          )}
          <div className={styles.container}>
            <div
              className={`${styles.item_container} ${styles.item_container_fill}`}
            >
              <PageBackButton paddingTop="20px" width="90%" />
              <div className={styles.flex_item}>
                <div>
                  <Typography textAlign="left" textWeight="900" textSize="20px">
                    Accrual Tracking - {employeeDetails.displayName}
                  </Typography>
                  <Typography textAlign="left" textColor={colors.gray1}>
                    Easily monitor and manage employee holiday accruals
                  </Typography>
                </div>
                <div className={styles.sub_flex_item}>
                  <AccrualTrackingFilter
                    clearFilter={() => {}}
                    runFilter={(data, name) => {
                      setSortFiled({ name, data });
                    }}
                  />
                  <div className={styles.sub_sub_flex_item}>
                    <TextInput
                      //   left={<img src={searchIcon} alt="search" />}
                      placeholder="Search"
                      value={filterField}
                      setValue={(data) => {
                        setFilterField(data);
                        handleFilteringByString();
                      }}
                    />
                    <Button
                      left={<img src={addIcon} alt="add" />}
                      marginTop="8px"
                      paddingVertical="7px"
                      width="270px"
                      isPrimary
                      text="Set Manual Adjustment"
                      onClick={() => {
                        navigate("/employee/accrual-manual-adjustment");
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.top_flex_container}>
                {/* {employeeAccrualTracking[0]?.expirationDate && ( */}
                <EmployeeAccuralCurrentYearAcomplishment
                  entitled={
                    holidayMethod === "entitlement" ? entitlement : accruadHour
                  }
                  holidayType={holidayType}
                  taken={holidayTaken}
                  available={`${
                    holidayMethod === "entitlement"
                      ? Number(entitlement) - Number(holidayTaken)
                      : Math.round(Number(accruadHour) * 100) / 100 -
                        Number(holidayTaken)
                  }`}
                  date={employeeAccrualTracking[0]?.expirationDate}
                />
                {/* )} */}
                <EmployeeAccuralHolidayAbsenceSummary
                  holidayAccrued={
                    holidayMethod === "entitlement"
                      ? `${Number(entitlement)} days (${
                          Number(entitlement) * 24
                        })`
                      : `${Math.round(Number(accruadHour) * 100) / 100} days (${
                          (Math.round(Number(accruadHour) * 100) / 100) * 24
                        } hours)`
                  }
                  holidayUsed={`${holidayTaken} days (${
                    (Math.round(Number(accruadHour) * 100) / 100) * 24
                  } hours)`}
                  holidayCompleted={`${Number(holidayTaken)} days (${
                    Number(holidayTaken) * 24
                  } hours)`}
                  absence={`${absence}day`}
                  holidayMethod={holidayMethod}
                />
              </div>
              {employeeAccrualTracking?.length > 0 ? (
                <EmployeeAccrualTable data={filteredEmployeeAccrualTracking} />
              ) : (
                // <EmptyItemState
                //   text="You haven't added any employee yet"
                //   location="employee/basic-info"
                //   label="Add Employee"
                // />
                <div></div>
              )}
            </div>
            {showModalDeleteModal && <DeleteAccrualModal />}
          </div>
        </section>
      )}
    </>
  );
};

export default AccrualTracking;
