import React from "react";
import styles from "./index.module.css";
import SignUpHeader from "components/Common/SignUpHeader";
import Typography from "components/Common/Typography";
import colors from "theme/colors";
import arrowLeftIcon from "assets/svg/arrow-left-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "components/Common/ScrollToTop";

const TermsAndCondition = () => {
  const navigate = useNavigate();
  return (
    <section>
      <ScrollToTop />
      <SignUpHeader />
      <div className={styles.container}>
        <div
          className={styles.top_text_container}
          onClick={() => navigate("/")}
        >
          <img src={arrowLeftIcon} alt="arrow-left-icon" />
          <Typography textSize="24px" textWeight="600" marginHorizontal={10}>
            Back
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textColor={colors.primary3}
            textSize="16px"
            textWeight="500"
            style={{ marginLeft: "50px" }}
          >
            Last Updated: January 16, 2024
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textWeight="600"
            textSize="48px"
            style={{ marginBottom: "60px" }}
          >
            Terms & Conditions
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            Welcome to Glix, a SaaS product developed by Gyzer Technologies. By
            accessing or using Glix, you agree to be bound by these Terms and
            Conditions. If you do not agree to these Terms and Conditions,
            please do not use Glix.
          </Typography>
        </div>

        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Acceptance of Terms
          </Typography>
        </div>
        <div className={styles.information_container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            style={{ marginLeft: "-15px" }}
          >
            By using Glix, you acknowledge that you have read, understood, and
            agree to be bound by these Terms and Conditions, including any
            additional terms and policies referenced herein.
          </Typography>
        </div>

        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            User Registration
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            To use Glix, you must register for an account. You are responsible
            for maintaining the confidentiality of your account information and
            for all activities that occur under your account.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Subscription
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            Glix is provided on a subscription basis. You agree to pay all fees
            associated with your subscription in accordance with the pricing and
            payment terms provided on our website.
          </Typography>
        </div>

        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Use of Glix
          </Typography>
        </div>
        <div className={styles.information_container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            style={{ marginLeft: "-15px" }}
          >
            You agree to use Glix only for lawful purposes and in accordance
            with these Terms and Conditions. You may not use Glix in any way
            that violates applicable laws or regulations.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Intellectual Property
          </Typography>
        </div>
        <div className={styles.information_container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            style={{ marginLeft: "-15px" }}
          >
            All content, features, and functionality of Glix are owned by Gyzer
            Technologies and are protected by copyright and other intellectual
            property laws. You may not reproduce, distribute, or modify any part
            of Glix without our prior written consent.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Privacy
          </Typography>
        </div>
        <div className={styles.information_container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            style={{ marginLeft: "-15px" }}
          >
            Your use of Glix is also governed by our Privacy Policy, which is
            incorporated by reference into these Terms and Conditions. Please
            review our Privacy Policy to understand how we collect, use, and
            disclose your personal information.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Termination
          </Typography>
        </div>
        <div className={styles.information_container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            style={{ marginLeft: "-15px" }}
          >
            Gyzer Technologies reserves the right to terminate or suspend your
            access to Glix at any time, for any reason, without notice.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Disclaimer of Warranties
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            Glix is provided "as is" and "as available," without any warranties
            of any kind, either express or implied. We do not warrant that Glix
            will be uninterrupted or error-free.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Limitation of Liability
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            Gyzer Technologies shall not be liable for any indirect, incidental,
            special, consequential, or punitive damages arising out of or in
            connection with your use of Glix.
          </Typography>
        </div>

        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Governing Law
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            These Terms and Conditions are governed by and construed in
            accordance with the laws of [Your Jurisdiction], without regard to
            its conflict of law principles.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Contact Information
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            If you have any questions about these Terms and Conditions, please
            contact us at{" "}
            <Link to="mailto:terms@glixapp.com" style={{ color: colors.gray1 }}>
              terms@glixapp.com
            </Link>
          </Typography>
        </div>

        <div className={styles.information_container_text}>
          <Typography
            textColor={colors.primary3}
            textAlign="left"
            textSize="20px"
            marginVertical={30}
            style={{ marginLeft: "-15px" }}
          >
            By using Glix, you agree to these Terms and Conditions. If you do
            not agree, please discontinue your use of Glix.
          </Typography>
        </div>
      </div>
    </section>
  );
};

export default TermsAndCondition;
