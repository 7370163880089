import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  showAddReportModal: false,
  showDeleteReportModal: false,
  fetchReportCounter: 0,
  currentSelectedId: "",
};

export const emailReportSlice = createSlice({
  name: "emailReport",
  initialState,
  reducers: {
    setShowAddReportModal: (
      state,
      action: PayloadAction<{ show: boolean; id?: string }>,
    ) => {
      state.showAddReportModal = action.payload.show;
      if (action.payload.show === false) {
        state.currentSelectedId = "";
      } else {
        state.currentSelectedId = action.payload.id || "";
      }
    },
    setShowDeleteReportModal: (
      state,
      action: PayloadAction<{ show: boolean; id?: string }>,
    ) => {
      state.showDeleteReportModal = action.payload.show;
      if (action.payload.show === false) {
        state.currentSelectedId = "";
      } else {
        state.currentSelectedId = action.payload.id || "";
      }
    },
    updateFetchReportCounter: (state) => {
      state.fetchReportCounter += 1;
    },
  },
});

export const {
  setShowAddReportModal,
  updateFetchReportCounter,
  setShowDeleteReportModal,
} = emailReportSlice.actions;
export const getShowAddMailReportModal = (state: RootState) =>
  state.emailReport.showAddReportModal;

export const getFetchReportCounter = (state: RootState) =>
  state.emailReport.fetchReportCounter;

export const getCurrentMailReportId = (state: RootState) =>
  state.emailReport.currentSelectedId;

export const getShowDeleteMailReportModal = (state: RootState) =>
  state.emailReport.showDeleteReportModal;

export default emailReportSlice.reducer;
