import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import Checkbox from "../../../Form/Checkbox";
import { getRequest } from "../../../../api/request";
import {
  GET_ALL_CLIENTS_URL,
  GET_AREA_URL,
  GET_WEB_USER_URL,
} from "../../../../api/urls";
import SelectInput from "../../../Form/SelectInput";
import { SiteLocationGeneralFormProps } from "../../../../types-definition/client";
import { Country } from "country-state-city";
import ClientSelectInput from "../../../Form/ClientSelectInput";
import { getData, getUserTypes } from "../../../../utils/localstorage";
import { ADMIN } from "types-definition/constants";

const SiteLocationGeneralForm: React.FC<SiteLocationGeneralFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
  client,
  setClient,
  area,
  setArea,
  manager,
  setManager,
}) => {
  const [clientData, setClientData] = useState<Array<any>>([]);
  const [areaData, setAreaData] = useState<Array<any>>([]);
  const [managerData, setManagerData] = useState<Array<any>>([]);
  useEffect(() => {
    const rawData = getData();
    let parsedData: any;
    if (rawData) {
      parsedData = JSON.parse(rawData);
    }
    const getAllClients = async () => {
      const response = await getRequest(GET_ALL_CLIENTS_URL);
      if (response?.success) {
        setClientData(response.data.clients);
      }
    };
    const getAllAreas = async () => {
      const response = await getRequest(GET_AREA_URL);
      if (response?.success) {
        setAreaData(response.data);
      }
    };
    const getAllWebUsers = async () => {
      const userData = getUserTypes();
      const response = await getRequest(GET_WEB_USER_URL);
      if (response?.success) {
        const webUsers = response.data.webUsers.filter(
          (i: any) => i.clientPortalUser === false,
        );
        setManagerData([
          userData === ADMIN
            ? {
                name: `${parsedData?.name}`,
                id: parsedData?.id,
                email: parsedData?.email,
              }
            : {
                name: `${response?.admin?.firstName} ${response?.admin?.lastName}`,
                id: response?.admin?.id,
                email: response?.admin?.email,
              },
          ...webUsers?.map((i: any) => {
            return {
              name: `${i.firstName} ${i.lastName}`,
              id: i.id,
              email: i.email,
            };
          }),
          // .filter((user: any) => user.id !== parsedData.id),
        ]);
      }
    };
    getAllClients();
    getAllAreas();
    getAllWebUsers();
    setFormData((prevData) => {
      return { ...prevData, managedBy: parsedData?.id?.toString() };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Functions of this is to fetch display name of client and area because api didn't return it in the fetch
    if (formData.area && !area) {
      const index = areaData.findIndex(
        (data) => data.id.toString() === formData.area.toString(),
      );
      if (index !== -1) {
        setArea(areaData[index].areaName);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.area, area]);

  useEffect(() => {
    if (formData.client && !client) {
      const index = clientData.findIndex((data) => {
        return data.id.toString() === formData.client.toString();
      });
      if (index !== -1) {
        setClient(clientData[index].clientName);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.client, client]);

  useEffect(() => {
    if (formData.managedBy) {
      const index = managerData.findIndex((data) => {
        return data.id.toString() === formData.managedBy.toString();
      });
      if (index !== -1) {
        setManager({
          name: managerData[index].name,
          email: managerData[index].email,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.managedBy]);

  return (
    <>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.name}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, name: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, name: false };
            });
          }}
          label="Name"
          placeholder="Enter Name"
          required={true}
          error={requiredFormData.name}
          helperText={requiredFormData.name ? "Location Name is required" : ""}
        />
        <TextInput
          value={formData.shortName}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, shortName: data };
            })
          }
          label="Short Name/Abbreviation"
          placeholder="Enter Short Name / Abbreviation"
        />
        <TextInput
          value={formData.code}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, code: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, code: false };
            });
          }}
          label="Location Code"
          placeholder="Enter Location Code"
          required={true}
          error={requiredFormData.code}
          helperText={requiredFormData.code ? "Location Code is required" : ""}
        />
      </div>

      <div className={styles.form_flex_container}>
        <ClientSelectInput
          value={formData.client}
          setValue={(name, id) => {
            if (id) {
              setFormData((prevData) => {
                return { ...prevData, client: id.toString() };
              });
            }
            setClient(name);
            setRequiredFormData((prevData) => {
              return { ...prevData, client: false };
            });
          }}
          label="Client"
          placeholder="Select Client"
          options={clientData.map((data) => {
            return {
              name: data.clientName,
              id: data.id,
            };
          })}
          client={client}
          required={true}
          error={requiredFormData.client}
          helperText={requiredFormData.client ? "Client is required" : ""}
        />
        <ClientSelectInput
          value={formData.area}
          setValue={(name, id) => {
            if (id) {
              setFormData((prevData) => {
                return { ...prevData, area: id.toString() };
              });
            }
            setArea(name);
            setRequiredFormData((prevData) => {
              return { ...prevData, area: false };
            });
          }}
          label="Area"
          placeholder="Select Area"
          options={areaData.map((data) => {
            return {
              name: data.areaName,
              id: data.id,
            };
          })}
          client={area}
          required={true}
          error={requiredFormData.area}
          helperText={requiredFormData.area ? "Area is required" : ""}
        />
        <SelectInput
          value={formData.region}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, region: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, region: false };
            });
          }}
          label="Region"
          placeholder="Select Region"
          options={Country.getAllCountries().map((data) => data.name)}
          required={true}
          error={requiredFormData.region}
          helperText={requiredFormData.region ? "Region is required" : ""}
        />
      </div>

      <div className={styles.form_flex_container}>
        <ClientSelectInput
          value={formData.managedBy}
          setValue={(name, id, data) => {
            if (id) {
              setFormData((prevData) => {
                return { ...prevData, managedBy: id.toString() };
              });
            }
            setManager({ name: name, email: data ?? "" });
            setRequiredFormData((prevData) => {
              return { ...prevData, managedBy: false };
            });
          }}
          label="Managed By"
          placeholder="Select Manager"
          client={manager.name}
          options={managerData.map((data) => {
            return {
              name: data.name,
              id: data.id,
              email: data.email,
            };
          })}
          required={true}
          error={requiredFormData.managedBy}
          helperText={
            requiredFormData.managedBy ? "Managed By is required" : ""
          }
        />

        <Checkbox
          marginVertical="30px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Active
              </Typography>
            </div>
          }
          value={formData.active}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, active: data };
            })
          }
        />
      </div>
    </>
  );
};

export default SiteLocationGeneralForm;
