import React from "react";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, patchRequest } from "api/request";
import { CREATE_LOCATION_URL, GET_LOCATION_URL } from "api/urls";
import { useNavigate } from "react-router-dom";
import { getLocation, getOperation } from "utils/localstorage";
import SiteLocationNavigation from "components/Client/Site Location/SiteLocationNavigation";
import {
  SiteLocationOperationFormData,
  SiteLocationRequiredOperationFormData,
} from "types-definition/client";
import SiteLocationOperationForm from "components/Client/Site Location/SiteLocationOperationForm";
import toast from "react-hot-toast";
import validator from "utils/validateFields";
import ScrollToTop from "components/Common/ScrollToTop";
import { toastError, toastSuccess } from "utils/toast";

const SiteLocationOperation = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<SiteLocationOperationFormData>({
    smartFormAlert: "",
    allowAnyGrades: false,
    allowLogEntries: false,
    enableGeoFence: false,
    geoFenceDistance: "10",
  });
  const [requiredFormData, setRequiredFormData] =
    useState<SiteLocationRequiredOperationFormData>({
      geoFenceDistance: false,
    });
  const [requestLoading, setRequestLoading] = useState(false);
  const handleSubmitForm = async () => {
    const { status, data } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    setRequestLoading(true);
    try {
      const locationId = getLocation();
      const operationId = getOperation();
      const response = await patchRequest(
        `${CREATE_LOCATION_URL}/${locationId}/operation/${operationId}`,
        {
          ...formData,
          smartFormAlert: Array.isArray(formData.smartFormAlert)
            ? formData.smartFormAlert
            : formData.smartFormAlert.split(",").map((data) => data.trim()),
          geoFenceDistance: formData.geoFenceDistance
            ? formData.geoFenceDistance
            : "0",
        },
      );
      if (response?.success) {
        toastSuccess("Client Operation Updated");
        navigate(`/client/certificates`);
      } else {
        toastError(response?.data);
      }
    } finally {
      setRequestLoading(false);
    }
  };
  const handleFormCancelation = () => {
    navigate("/client/site");
  };
  useEffect(() => {
    const getOperationInformation = async () => {
      const locationId = getLocation();
      if (locationId) {
        const response = await getRequest(`${GET_LOCATION_URL}/${locationId}`);
        if (response?.success) {
          if (response.data.operation) {
            setFormData((prevData) => {
              return {
                ...prevData,
                allowAnyGrades: response.data.operation.allowAnyGrades ?? false,
                allowLogEntries:
                  response.data.operation.allowLogEntries ?? false,
                enableGeoFence: response.data.operation.enableGeoFence ?? false,
                geoFenceDistance:
                  response.data.operation.geoFenceDistance ?? "10",
                smartFormAlert: response.data.operation.smartFormAlert ?? "",
              };
            });
          }
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      }
    };
    getOperationInformation();
  }, []);
  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="clients" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography textAlign="left" textWeight="600" textSize="24px">
              Add Site Location
            </Typography>
          </div>
          <SiteLocationNavigation active="operation" />
          <div className={styles.content_container}>
            <SiteLocationOperationForm
              formData={formData}
              setFormData={setFormData}
              requiredFormData={requiredFormData}
              setRequiredFormData={setRequiredFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Cancel"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={true}
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SiteLocationOperation;
