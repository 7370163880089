const convertTimeTo12hours = (time: string) => {
  if (time.length > 5) {
    return time;
  }
  const [hours, minutes] = time.split(":");
  const format = parseInt(hours) >= 12 ? "PM" : "AM";
  const hour = parseInt(hours) % 12 || 12;
  return `${hour}:${minutes} ${format}`;
};

export default convertTimeTo12hours;
