import React from "react";
import styles from "./index.module.css";
import { SuccessModalProps } from "../../../types-definition/common";
import Typography from "../Typography";
import checkMarkIcon from "../../../assets/svg/ic_checkmark.svg";
import cancelIcon from "../../../assets/svg/cancel-circle.svg";

const SuccessModal: React.FC<SuccessModalProps> = ({
  text,
  cancelFunction,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <img
          src={checkMarkIcon}
          alt="check icon"
          className={styles.check_img}
        />
        <Typography>{text}</Typography>
      </div>
      <img
        src={cancelIcon}
        alt="cancel icon"
        onClick={cancelFunction}
        className={styles.cancel_img}
      />
    </div>
  );
};

export default SuccessModal;
