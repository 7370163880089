import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import binIcon from "../../../../assets/svg/bin-icon.svg";
import editProfileIcon from "../../../../assets/svg/edit-icon.svg";
import timesheetIcon from "../../../../assets/svg/timesheet.svg";
import viewIcon from "../../../../assets/svg/view.svg";
import { useNavigate } from "react-router-dom";
import { setClient, setClientName } from "../../../../utils/localstorage";
import { ClientDropDownProps } from "../../../../types-definition/client";
import { useAppDispatch } from "../../../../redux/hooks";
import { setShowClientDeletemodal } from "../../../../redux/slice/client/clientSlice";

const ClientDropdown: React.FC<ClientDropDownProps> = ({
  clientId,
  clientName,
  setShowDropDown,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const deleteClient = async () => {
    setShowDropDown(false);
    setClient(clientId);
    dispatch(setShowClientDeletemodal(true));
  };

  return (
    <div className={styles.dropdown_container}>
      <div
        className={styles.dropdown_item}
        onClick={() => {
          navigate("/client/add");
          setClient(clientId);
        }}
      >
        <img src={editProfileIcon} alt="edit-icon" />
        <Typography
          textAlign="left"
          hoverActive={true}
          hoverColors={colors.primary3}
          paddingVertical="10px"
        >
          Edit Cient
        </Typography>
      </div>
      <div
        className={styles.dropdown_item}
        onClick={() => {
          navigate("/client/active-board");
          setClient(clientId);
          setClientName(clientName ?? "");
        }}
      >
        <img src={viewIcon} alt="view-icon" />
        <Typography
          textAlign="left"
          hoverActive={true}
          hoverColors={colors.primary3}
          paddingVertical="10px"
        >
          View Active Board
        </Typography>
      </div>
      <div
        className={styles.dropdown_item}
        onClick={() => {
          navigate("/client/location/timesheet-report");
          setClient(clientId);
          setClientName(clientName ?? "");
        }}
      >
        <img src={timesheetIcon} alt="time-sheet-icon" />
        <Typography
          textAlign="left"
          hoverActive={true}
          hoverColors={colors.primary3}
          paddingVertical="10px"
        >
          Time Sheet Report
        </Typography>
      </div>
      <div
        className={styles.dropdown_item}
        onClick={() => {
          deleteClient();
        }}
      >
        <img src={binIcon} alt="bin-icon" />
        <Typography
          textAlign="left"
          hoverActive={true}
          hoverColors={colors.primary3}
          paddingVertical="10px"
        >
          Delete Client
        </Typography>
      </div>
    </div>
  );
};

export default ClientDropdown;
