import React from "react";
import { ColourPickerProps } from "../../../types-definition/common";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
const ColourInput: React.FC<ColourPickerProps> = ({
  value,
  label,
  setValue,
  height = "200px",
  width,
}) => {
  const [colour, setColor] = useColor(value || "#E89623");
  return (
    <section className={styles.container}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {label}
      </Typography>
      {colour.hex !== "#000000" && (
        <span
          className={styles.color_box}
          style={{
            backgroundColor: `${colour.hex}`,
          }}
        ></span>
      )}
      <div className={styles.react_colour_pallete_container}>
        <ColorPicker
          color={colour}
          onChange={(currentColor) => {
            setColor(currentColor);
            setValue(currentColor.hex);
          }}
        />
      </div>
    </section>
  );
};

export default ColourInput;
