import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import AdminSideBar from "components/Admin/SideBar";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import { GET_SERVICE_GROUP_URL } from "api/urls";
import { getRequest } from "api/request";
import addIcon from "assets/svg/add-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import {
  getFetchedServiceGroupCounter,
  getShowLinkServicesModal,
  getShowServiceGroupModal,
  setShowAddServiceGroupModal,
  getShowDeleteServiceGroupModal,
} from "../../../redux/slice/client/serviceGroupSlice";
import AddServiceGroupModal from "components/Admin/Service Group/AddServiceGroupModal";
import ServiceGroupTable from "components/Admin/Service Group/ServiceGroupTable";
import LinkServices from "components/Admin/Service Group/LinkServices";
import { deleteServiceGroup } from "utils/localstorage";
import DeleteServiceGroup from "components/Admin/Service Group/DeleteServiceGroup";
import AdminEmptyItemState from "components/Common/AdminEmptyStateItem";
import { toastError } from "utils/toast";

const AdminServiceGroup: React.FC = () => {
  const dispatch = useAppDispatch();
  const showAddServiceModal = useAppSelector(getShowServiceGroupModal);
  const showLinkServiceModal = useAppSelector(getShowLinkServicesModal);
  const showDeleteModal = useAppSelector(getShowDeleteServiceGroupModal);
  const counter = useAppSelector(getFetchedServiceGroupCounter);
  const [requestLoading, setRequestLoading] = useState(false);
  const [serviceGroupData, setServiceGroupData] = useState<Array<any>>([]);
  useEffect(() => {
    const getAllServiceGroup = async () => {
      setRequestLoading(true);
      const response = await getRequest(GET_SERVICE_GROUP_URL);

      if (response?.success) {
        const data: Array<any> = [];
        for (const index in response?.data) {
          data.push({
            ...response?.data[index],
          });
        }
        setServiceGroupData(data);
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
      setRequestLoading(false);
    };
    getAllServiceGroup();
    deleteServiceGroup();
  }, [counter]);

  return (
    <>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <AdminSideBar active="service group" />
        <div className={styles.item_container}>
          <div className={styles.flex_item}>
            <div>
              <Typography textAlign="left" textWeight="600" textSize="20px">
                Service Groups
              </Typography>
            </div>
            <Button
              left={<img src={addIcon} alt="add" />}
              marginHorizontal="10px"
              marginTop="8px"
              paddingVertical="10px"
              width="250px"
              isPrimary
              text="Add Service Group"
              onClick={() => dispatch(setShowAddServiceGroupModal(true))}
            />
          </div>
          {serviceGroupData.length === 0 ? (
            <AdminEmptyItemState
              text="You haven't added any service group yet"
              text2="The Service Group allows you to create different groups for categorizing and organizing services."
              action={() => dispatch(setShowAddServiceGroupModal(true))}
              location="admin/permission-groups"
              label="Add Service Group"
            />
          ) : (
            <ServiceGroupTable data={serviceGroupData} />
          )}
        </div>
      </div>

      {showAddServiceModal && <AddServiceGroupModal />}
      {showLinkServiceModal && <LinkServices />}
      {showDeleteModal && <DeleteServiceGroup />}
    </>
  );
};

export default AdminServiceGroup;
