import React, { useState } from "react";
import Typography from "../Typography";
import errorInfoIcon from "../../../assets/svg/error-icon.svg";
import styles from "./index.module.css";
import Button from "../../Form/Button";
import colors from "../../../theme/colors";
import { useAppDispatch } from "../../../redux/hooks";
import {
  setShowAuthSideBar,
  setShowSignUpModal,
} from "../../../redux/slice/common/sidebarSlice";
import signOutIcon from "../../../assets/svg/signout-icon.svg";
import { getRequest } from "../../../api/request";
import { LOGOUT_USER_URL } from "../../../api/urls";
import { logoutUser } from "../../../utils/localstorage";
import { useNavigate } from "react-router-dom";

const SignOutModal: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = async () => {
    setIsLoading(true);
    await getRequest(LOGOUT_USER_URL);
    dispatch(setShowSignUpModal(false));
    dispatch(setShowAuthSideBar(false));
    setIsLoading(false);
    logoutUser();
    navigate("/login");
  };
  return (
    <div className={styles.main_container}>
      <div className={styles.container}>
        <img
          src={errorInfoIcon}
          alt="cancel icon"
          className={styles.cancelImage}
        />
        <Typography textWeight="600" textSize="18px" textAlign="left">
          Confirm Sign Out!
        </Typography>
        <div className={styles.mid_text}>
          <Typography textColor={colors.gray4} textAlign="left">
            Are you sure you want to sign out?
          </Typography>
        </div>

        <div className={styles.button_container}>
          <Button
            width="100%"
            text="Cancel"
            textColor={colors.white}
            isPrimary={false}
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
            borderWidth="1px"
            textWeight="500"
            onClick={() => {
              dispatch(setShowSignUpModal(false));
            }}
            paddingVertical="6px"
          />
          <Button
            width="100%"
            text="Sign Out"
            textColor={colors.white}
            isPrimary={false}
            backgroundColor={colors.error}
            textWeight="500"
            paddingVertical="6px"
            left={<img src={signOutIcon} alt="signout icon" />}
            onClick={handleLogout}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default SignOutModal;
