import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import editProfileIcon from "../../../../assets/svg/edit-dark-icon.svg";
import calender_danger from "../../../../assets/svg/calender-danger-icon.svg";
import calender_success from "../../../../assets/svg/calender-success-icon.svg";
import { HolidayAbssenceDropdownProps } from "../../../../types-definition/StaffHub/holiday";
import { toastError, toastSuccess } from "../../../../utils/toast";
import { patchRequest } from "../../../../api/request";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  setCurrentSelectedAbsenceHolidayEmployeeId,
  setCurrentSelectedAbsenceHolidayId,
  setShowEditAbsenceHolidayModal,
  updateAbsenceHolidayCounter,
} from "../../../../redux/slice/HolidayAbsence/holidayAbsenceSlice";

const HolidayAbssenceDropdown: React.FC<HolidayAbssenceDropdownProps> = ({
  id,
  employeeId,
}) => {
  const dispatch = useAppDispatch();
  const handleApproveHoliday = async () => {
    const response = await patchRequest(
      `/employee/${employeeId}/holiday/segment/${id}/approve`,
    );
    if (response?.success) {
      toastSuccess("Holiday Approved");
      dispatch(updateAbsenceHolidayCounter());
    } else {
      toastError(response?.data);
    }
  };
  const handleRejectHoliday = async () => {
    const response = await patchRequest(
      `/employee/${employeeId}/holiday/segment/${id}/reject`,
    );
    if (response?.success) {
      toastSuccess("Holiday Rejected");
      dispatch(updateAbsenceHolidayCounter());
    } else {
      toastError(response?.data);
    }
  };
  const handleEditHoliday = () => {
    dispatch(setCurrentSelectedAbsenceHolidayId(id));
    dispatch(setCurrentSelectedAbsenceHolidayEmployeeId(employeeId));
    dispatch(setShowEditAbsenceHolidayModal(true));
  };
  return (
    <>
      <div className={styles.dropdown_container}>
        <div className={styles.dropdown_item} onClick={handleApproveHoliday}>
          <img
            src={calender_success}
            alt="view-profile"
            className={styles.dropdown_image_item}
          />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
            textColor="#0F973D"
          >
            Approve Request{" "}
          </Typography>
        </div>
        <div className={styles.dropdown_item} onClick={handleRejectHoliday}>
          <img
            src={calender_danger}
            alt="view-profile"
            className={styles.dropdown_image_item}
          />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
            textColor="#D42620"
          >
            Reject Request
          </Typography>
        </div>
        <div className={styles.dropdown_item} onClick={handleEditHoliday}>
          <img
            src={editProfileIcon}
            alt="view-profile"
            className={styles.dropdown_image_item}
          />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
            textColor="#292D32"
          >
            Edit Request
          </Typography>
        </div>
      </div>
    </>
  );
};

export default HolidayAbssenceDropdown;
