import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { ClientTableProps } from "../../../../types-definition/client";
import colors from "../../../../theme/colors";
import ClientTableItem from "../ClientTableItem";

const ClientTable: React.FC<ClientTableProps> = ({
  data = [
    {
      code: "88738",
      name: "Shoprite",
      description: "this is a description",
      locations: "0",
    },
  ],
}) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Client Name
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Client Code
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Description
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Total Locations
              </Typography>
            </th>
            <th className={styles.th_index}></th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return <ClientTableItem {...item} key={item.id} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ClientTable;
