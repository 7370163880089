import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageLoader from "components/Common/PageLoader";
import DashboardHeader from "components/Common/DashboardHeader";
import Typography from "components/Common/Typography";
import styles from "./index.module.css";
import Button from "components/Form/Button";
import ScrollToTop from "components/Common/ScrollToTop";
import { CertificateTableData } from "types-definition/StaffHub/certificateType";
import EmptyItemState from "components/Common/EmptyItemState";
import TextInput from "components/Form/TextInput";
import CertificateTypeTable from "components/StaffHub/Certificatetype/CertificateTable";
import searchIcon from "assets/svg/search-icon.svg";
import addIcon from "assets/svg/add-icon.svg";
import { CERTIFICATE_TYPE_URL } from "api/urls";
import { getRequest } from "api/request";
import colors from "theme/colors";
import {
  getCertificateTypeData,
  getCertificateTypeSearchEmpty,
  getCertificateTypeSearchFetching,
  getSearchedCertificateTypeValue,
  getUpdatedState,
  setCertificateTypeData,
  setSearchedCertificateTypeValue,
} from "../../../../redux/slice/staff-hub/certificateTypeSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import CertificateTypeTableFilter from "components/StaffHub/Certificatetype/CertificateTypeTableFilter";
import LoadingIcons from "react-loading-icons";

const CertificateType = () => {
  const dispatch = useAppDispatch();
  const newState = useAppSelector(getUpdatedState);
  // const [certificateTypeData, setCertificateTypeData] = useState<
  //   Array<CertificateTableData>
  // >([]);

  const [requestLoading, setRequestLoading] = useState(false);
  const certificateTypeData = useAppSelector(getCertificateTypeData);
  const searchedValue = useAppSelector(getSearchedCertificateTypeValue);
  const isSearchEmpty = useAppSelector(getCertificateTypeSearchEmpty);
  const searchLoading = useAppSelector(getCertificateTypeSearchFetching);
  const navigate = useNavigate();
  useEffect(() => {
    const getCertificateType = async () => {
      setRequestLoading(true);
      const response = await getRequest(CERTIFICATE_TYPE_URL);
      if (response?.success) {
        const responseData: Array<CertificateTableData> = [];
        for (const index in response?.data?.certificateTypes) {
          const data = {
            id: response?.data?.certificateTypes[index]?.id,
            name: response?.data?.certificateTypes[index]?.certificateName,
            no: "0",
            active: response?.data?.certificateTypes[index]?.active,
            requirementLevel:
              response?.data?.certificateTypes[index]?.requirementLevel,
          };
          responseData.push(data);
        }

        dispatch(setCertificateTypeData(responseData));
      }
      setRequestLoading(false);
    };
    getCertificateType();
    // removeTimeShiftGeneration();
  }, [newState, dispatch]);
  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="staff/hub" />
        <div className={styles.container}>
          <div className={`${styles.item_container} `}>
            <div className={styles.flex_item}>
              <div>
                <Typography textAlign="left" textWeight="600" textSize="20px">
                  Certificate Type
                </Typography>
                <Typography textAlign="left" textColor={colors.gray6}>
                  Add your certificate type to properly manage them
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <CertificateTypeTableFilter />
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={searchedValue}
                  setValue={(data) => {
                    dispatch(setSearchedCertificateTypeValue(data));
                  }}
                />
                <Button
                  left={<img src={addIcon} alt="add" />}
                  marginHorizontal="10px"
                  marginTop="8px"
                  paddingVertical="7px"
                  width="200px"
                  isPrimary
                  text="Certificate Type"
                  onClick={() => navigate("/staff-hub/add-certificate-type")}
                />
              </div>
            </div>
            {searchLoading ? (
              <div>
                <LoadingIcons.TailSpin
                  width={50}
                  height={50}
                  stroke={colors.primary1}
                  color={colors.primary1}
                  className={styles.loader_spinner}
                />
              </div>
            ) : isSearchEmpty ? (
              <></>
            ) : certificateTypeData.length < 1 ? (
              <EmptyItemState
                text="You haven't added any certificate type yet"
                location="staff-hub/add-certificate-type"
                label="Certificate Type"
              />
            ) : (
              <CertificateTypeTable data={certificateTypeData} />
            )}
          </div>
        </div>
      </section>
    </>
  );
};
export default CertificateType;
