const getNumberPosition = (number: number) => {
  if (isNaN(number)) return null;

  let lastDigit = number?.toString().slice(-1);
  switch (lastDigit) {
    case "1":
      return `${number}st`;
    case "2":
      return `${number}nd`;
    case "3":
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};
export default getNumberPosition;
