import { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import filterIcon from "../../../../assets/svg/filter-icon.svg";
import chevronDownIcon from "../../../../assets/svg/chevron-down.svg";
import colors from "../../../../theme/colors";
import { useAppSelector } from "../../../../redux/hooks";
import { getSearchedWebUserValue } from "../../../../redux/slice/admin/adminSlice";
import { ClientActiveBoardNameValue } from "../../../../types-definition/client";

const ClientActiveBoardFilter = () => {
  const searchedValue = useAppSelector(getSearchedWebUserValue);
  const [currentFilterOption, setcurrentFilterOption] = useState("Filter");
  const [areaFilterOptions] = useState<Array<ClientActiveBoardNameValue>>([
    {
      name: "All Areas",
      value: "all",
    },
    {
      name: "Area 1",
      value: "all",
    },
    {
      name: "Area 2",
      value: "all",
    },
    {
      name: "Area 3",
      value: "all",
    },
    {
      name: "Area 4",
      value: "all",
    },
    {
      name: "Area 5",
      value: "all",
    },
  ]);
  const [clientFilterOptions] = useState<Array<ClientActiveBoardNameValue>>([
    {
      name: "All Client",
      value: "all",
    },
    {
      name: "Client 1",
      value: "all",
    },
    {
      name: "Client 2",
      value: "all",
    },
    {
      name: "Client 3",
      value: "all",
    },
    {
      name: "Client 4",
      value: "all",
    },
    {
      name: "Client 5",
      value: "all",
    },
  ]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showSubDropDown, setShowSubDropDown] = useState(true);
  const [filterOptions, setFilterOptions] = useState("");
  const FILTEROPTIONS = {
    NEXT: { name: "Next", top: "70px" },
    AREA: { name: "Area", top: "130px" },
    CLIENT: { name: "Client", top: "170px" },
  };
  const NEXTFILTEROPTIONS = [
    {
      name: "1 hour",
      value: "1",
    },
    {
      name: "2 hours",
      value: "2",
    },
    {
      name: "3 hours",
      value: "3",
    },
    {
      name: "4 hours",
      value: "4",
    },
    {
      name: "5 hours",
      value: "5",
    },
    {
      name: "6 hours",
      value: "6",
    },
  ];

  const clearFilter = () => {
    setcurrentFilterOption("Filter");
  };
  const hideAllDropDown = () => {
    // containerRef.current?.blur();
    setShowDropDown(false);
    setShowSubDropDown(false);
  };

  useEffect(() => {}, [searchedValue]);
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.main}>
      <div
        className={styles.main_container}
        onFocus={() => {
          setShowDropDown(true);
          setShowSubDropDown(true);
        }}
        ref={containerRef}
        onClick={(e) => {
          // e.preventDefault();
          // containerRef.current?
          if (document.activeElement === containerRef.current) {
            containerRef.current?.focus();
          } else {
            containerRef.current?.blur();
            // containerRef.current?.focus();
          }
        }}
        tabIndex={0}
        onBlur={(e) => {
          const event = e;
          requestAnimationFrame(() => {
            if (!event.currentTarget?.contains(document.activeElement)) {
              setShowDropDown(false);
              setShowSubDropDown(false);
            }
          });
        }}
        // tabIndex={1}
      >
        <div className={styles.container}>
          <img src={filterIcon} alt="filter icon" />
          <Typography textColor={colors.gray1}>
            {currentFilterOption}
          </Typography>
        </div>
        {showDropDown && (
          <div className={styles.dropdown_container}>
            <div
              className={`${
                FILTEROPTIONS.NEXT.name === filterOptions
                  ? `${styles.sub_dropdown_item} ${styles.sub_dropdown_item_active}`
                  : styles.sub_dropdown_item
              }`}
              onClick={() => {
                setFilterOptions(FILTEROPTIONS.NEXT.name);
                setShowSubDropDown(true);
              }}
            >
              <Typography>Next</Typography>
              <img
                src={chevronDownIcon}
                alt="chevron-down"
                className={styles.dropdown_image_item}
              />
            </div>
            <div
              className={`${
                FILTEROPTIONS.AREA.name === filterOptions
                  ? `${styles.sub_dropdown_item} ${styles.sub_dropdown_item_active}`
                  : styles.sub_dropdown_item
              }`}
              onClick={() => {
                setFilterOptions(FILTEROPTIONS.AREA.name);
                setShowSubDropDown(true);
              }}
            >
              <Typography textAlign="left">Area</Typography>
              <img
                src={chevronDownIcon}
                alt="chevron-down"
                className={styles.dropdown_image_item}
              />
            </div>
            <div
              className={`${
                FILTEROPTIONS.CLIENT.name === filterOptions
                  ? `${styles.sub_dropdown_item} ${styles.sub_dropdown_item_active}`
                  : styles.sub_dropdown_item
              }`}
              onClick={() => {
                setFilterOptions(FILTEROPTIONS.CLIENT.name);
                setShowSubDropDown(true);
              }}
            >
              <Typography>Client</Typography>
              <img
                src={chevronDownIcon}
                alt="chevron-down"
                className={styles.dropdown_image_item}
              />
            </div>
          </div>
        )}
        {showSubDropDown && (
          <div>
            {filterOptions === FILTEROPTIONS.NEXT.name && (
              <div
                className={styles.sub_dropdown_container}
                style={{ top: FILTEROPTIONS.NEXT.top }}
              >
                {NEXTFILTEROPTIONS.map((item) => {
                  return (
                    <div
                      className={styles.sub_dropdown_item}
                      onClick={() => {
                        setcurrentFilterOption(item.name);
                        hideAllDropDown();
                        containerRef.current?.blur();
                      }}
                    >
                      <Typography>{item.name}</Typography>
                    </div>
                  );
                })}
              </div>
            )}
            {filterOptions === FILTEROPTIONS.AREA.name && (
              <div
                className={styles.sub_dropdown_container}
                style={{ top: FILTEROPTIONS.AREA.top }}
              >
                {areaFilterOptions.map((item) => {
                  return (
                    <div
                      className={styles.sub_dropdown_item}
                      onClick={() => {
                        setcurrentFilterOption(item.name);
                        hideAllDropDown();
                        containerRef.current?.blur();
                      }}
                    >
                      <Typography>{item.name}</Typography>
                    </div>
                  );
                })}
              </div>
            )}
            {filterOptions === FILTEROPTIONS.CLIENT.name && (
              <div
                className={styles.sub_dropdown_container}
                style={{ top: FILTEROPTIONS.CLIENT.top }}
              >
                {clientFilterOptions.map((item) => {
                  return (
                    <div
                      className={styles.sub_dropdown_item}
                      onClick={() => {
                        setcurrentFilterOption(item.name);
                        hideAllDropDown();
                        containerRef.current?.blur();
                      }}
                    >
                      <Typography>{item.name}</Typography>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
      {currentFilterOption !== "Filter" && (
        <div className={styles.clear_filter} onClick={() => clearFilter()}>
          <Typography
            textColor={colors.primary3}
            textWeight="600"
            textSize="12px"
          >
            Clear Filters
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ClientActiveBoardFilter;
