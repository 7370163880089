import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import colors from "../../../theme/colors";
import { ErrorProps } from "../../../types-definition/common";
const Error: React.FC<ErrorProps> = ({
  width = "90%",
  text = "Something went wrong please try again",
  marginTop = "0px",
  marginBottom = "0px",
}) => {
  return (
    <div
      className={styles.container}
      style={{ width, marginTop, marginBottom }}
    >
      <Typography fontFamily="nunito" textColor={colors.error}>
        {text}
      </Typography>
    </div>
  );
};

export default Error;
