import cancelIcon from "assets/svg/cancel-icon.svg";
import HorizontalRule from "components/Common/HorizontalRule";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import DateInput from "components/Form/DateInput";
import SelectInput from "components/Form/SelectInput";
import { useState } from "react";
import colors from "theme/colors";
import filterIcon from "../../../../assets/svg/filter-icon.svg";
import styles from "./index.module.css";
import { CheckcallLogFilterOptionsInterface } from "types-definition/report";

const CheckcallLogFilterOptions: React.FC<
  CheckcallLogFilterOptionsInterface
> = ({ services, filter, setFilter, applyFilter }) => {
  const initialFilter = {
    startDate: "",
    endDate: "",
    service: "",
    checkcallStatus: "",
    responseStatus: "",
    verificationMethod: "",
  };
  const [showFilterTab, setShowFilterTab] = useState(false);

  const clearFilter = (property: string) => {
    setFilter((n: any) => ({ ...n, [property]: "" }));
  };

  return (
    <>
      <div className={styles.container} onClick={() => setShowFilterTab(true)}>
        <img src={filterIcon} alt="filter icon" />
        <Typography textColor={colors.gray1}>{"Filter"}</Typography>
      </div>

      {showFilterTab && (
        <>
          <div
            className={styles.filter_backdrop}
            onClick={() => setShowFilterTab(false)}
          ></div>
          <div
            className={`${styles.filter_options_container} ${
              showFilterTab && styles.filter_options_show
            }`}
          >
            <div className={styles.filter_flex_column}>
              <div className={styles.filter_flex}>
                <Typography
                  textAlign="left"
                  textColor={colors.black}
                  textWeight="600"
                >
                  Filter
                </Typography>
                <img
                  style={{ cursor: "pointer", padding: ".5rem" }}
                  src={cancelIcon}
                  alt=""
                  onClick={() => setShowFilterTab(false)}
                />
              </div>
            </div>
            <HorizontalRule borderColor={colors.gray2} />

            <div className={styles.filter_sub_container}>
              <div className={styles.filter_flex_column}>
                <div className={styles.filter_flex}>
                  <Typography textAlign="left" textColor={colors.black}>
                    Date range
                  </Typography>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      clearFilter("endDate");
                      clearFilter("startDate");
                    }}
                  >
                    <Typography textAlign="left" textColor={colors.gray3}>
                      clear
                    </Typography>
                  </div>
                </div>
                <DateInput
                  placeholder="Start Date"
                  value={filter.startDate}
                  setValue={(value) =>
                    setFilter({ ...filter, startDate: value })
                  }
                />
                <DateInput
                  placeholder="End Date"
                  value={filter.endDate}
                  setValue={(value) => setFilter({ ...filter, endDate: value })}
                />
              </div>

              <HorizontalRule borderColor={colors.gray2} />

              <div className={styles.filter_flex_column}>
                <div className={styles.filter_flex}>
                  <Typography textAlign="left" textColor={colors.black}>
                    Service
                  </Typography>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      clearFilter("service");
                    }}
                  >
                    <Typography textAlign="left" textColor={colors.gray3}>
                      clear
                    </Typography>
                  </div>
                </div>
                <SelectInput
                  label=""
                  placeholder="Select Service"
                  value={filter.service}
                  dropdownTop="16px"
                  datalistTop="55px"
                  setValue={(value) => setFilter({ ...filter, service: value })}
                  options={services.map((service) => service.name)}
                />
              </div>

              <HorizontalRule borderColor={colors.gray2} />

              <div className={styles.filter_flex_column}>
                <div className={styles.filter_flex}>
                  <Typography textAlign="left" textColor={colors.black}>
                    Checkcall Status
                  </Typography>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      clearFilter("checkcallStatus");
                    }}
                  >
                    <Typography textAlign="left" textColor={colors.gray3}>
                      clear
                    </Typography>
                  </div>
                </div>
                <SelectInput
                  label=""
                  placeholder="Select Checkcall Status"
                  value={filter.checkcallStatus}
                  dropdownTop="16px"
                  datalistTop="55px"
                  setValue={(value) =>
                    setFilter({ ...filter, checkcallStatus: value })
                  }
                  options={["accepted", "rejected"]}
                />
              </div>

              <HorizontalRule borderColor={colors.gray2} />

              <div className={styles.filter_flex_column}>
                <div className={styles.filter_flex}>
                  <Typography textAlign="left" textColor={colors.black}>
                    Response Status
                  </Typography>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      clearFilter("responseStatus");
                    }}
                  >
                    <Typography textAlign="left" textColor={colors.gray3}>
                      clear
                    </Typography>
                  </div>
                </div>
                <SelectInput
                  label=""
                  placeholder="Select Response Status"
                  value={filter.responseStatus}
                  dropdownTop="16px"
                  datalistTop="55px"
                  setValue={(value) =>
                    setFilter({ ...filter, responseStatus: value })
                  }
                  options={["ok", "not ok"]}
                />
              </div>

              <HorizontalRule borderColor={colors.gray2} />

              <div className={styles.filter_flex_column}>
                <div className={styles.filter_flex}>
                  <Typography textAlign="left" textColor={colors.black}>
                    Verification Method
                  </Typography>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      clearFilter("verificationMethod");
                    }}
                  >
                    <Typography textAlign="left" textColor={colors.gray3}>
                      clear
                    </Typography>
                  </div>
                </div>
                <SelectInput
                  label=""
                  placeholder="Select Verification Method"
                  value={filter.verificationMethod}
                  dropdownTop="16px"
                  datalistTop="55px"
                  setValue={(value) =>
                    setFilter({ ...filter, verificationMethod: value })
                  }
                  options={["Facial Recognition", "Biometric", "Passcode"]}
                />
              </div>
            </div>

            <div className={styles.filter_button_continer}>
              <Button
                text="Clear all Filters"
                onClick={() => {
                  setFilter(initialFilter);
                  applyFilter(initialFilter);
                  setShowFilterTab(false);
                }}
                isPrimary={false}
                borderWidth="2px"
                borderColor={colors.primary3}
                backgroundColor={colors.white}
                textColor={colors.primary3}
              />
              <Button
                text="Apply Filter"
                onClick={() => {
                  applyFilter();
                  setShowFilterTab(false);
                }}
                isPrimary={false}
                borderWidth="2px"
                borderColor={colors.primary3}
                backgroundColor={colors.primary3}
                textColor={colors.white}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CheckcallLogFilterOptions;
