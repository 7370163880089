import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import Button from "../../Form/Button";
import emailIcon from "../../../assets/svg/Mail Icon.svg";
import { useNavigate } from "react-router-dom";
import size from "../../../theme/size";
import SelectInput from "../../Form/SelectInput";
import { getRequest, patchRequest } from "../../../api/request";
import { UPDATE_USER_COUNTRY_URL } from "../../../api/urls";
import { Country } from "country-state-city";
import TextInput from "../../Form/TextInput";
import {
  deleteData,
  getData,
  getSetupComplete,
  saveData,
  setCountry,
  setLogoutIdle,
  setSetupComplete,
  setUserIdle,
} from "../../../utils/localstorage";
import axios from "axios";
import toast from "react-hot-toast";

const UpdateAccountForm = () => {
  const setUpStep = getSetupComplete();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    country: "",
    countryCode: "",
  });
  const [email, setEmail] = useState();
  const [requestLoading, setRequestLoading] = useState(false);
  const handleFormSubmission = async () => {
    setRequestLoading(true);
    const response = await patchRequest(UPDATE_USER_COUNTRY_URL, {
      name: formData.firstName + " " + formData.lastName,
      country: formData.country,
      countryCode: formData.countryCode,
    });
    if (response?.success) {
      const dataToSave = {
        id: response.data.id,
        name: `${response.data.firstName} ${response.data.lastName}`,
        email: response.data.email,
        token: response.accessToken,
        refreshToken: response.refreshToken,
      };
      deleteData();
      saveData(JSON.stringify(dataToSave));
      setCountry(response.data.country);
      setUserIdle(String(false));
      setLogoutIdle(String(false));
      const subscriptionResponse = await getRequest("/auth/me");
      if (
        subscriptionResponse?.data?.subscription?.length === 0 ||
        !subscriptionResponse?.data?.subscription
      ) {
        return navigate("/subscribe");
      }
      if (setUpStep) {
        if (JSON.parse(setUpStep).step === "1") {
          setSetupComplete(
            JSON.stringify({
              completed: JSON.parse(setUpStep).completed,
              step: "1",
            }),
          );
          navigate("/setup-wizard");
        } else if (parseFloat(JSON.parse(setUpStep).step) > 13) {
          setSetupComplete(
            JSON.stringify({
              completed: true,
              step: JSON.parse(setUpStep)?.step,
            }),
          );
          navigate("/dashboard");
        } else {
          navigate("/setup-wizard");
          setSetupComplete(
            JSON.stringify({
              completed: JSON.parse(setUpStep)?.step,
              step: JSON.parse(setUpStep)?.step,
            }),
          );
        }
      }
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };
  useEffect(() => {
    const data = getData();
    if (data) {
      const parsedData = JSON.parse(data);
      setFormData((prevData) => {
        return {
          ...prevData,
          firstName: parsedData.name.split(" ")[0] ?? "",
          lastName: parsedData.name.split(" ")[1] ?? "",
        };
      });
      setEmail(parsedData?.email);
    }
    const getCountry = async () => {
      try {
        const { data } = await axios.get(
          "https://api.ipdata.co?api-key=d64e0dc37208fb34a2bac5286803f30446dd9eb6bca11bb337a10319&fields=ip,is_eu,city,region,region_code,country_name,country_code,continent_name,continent_code,latitude,longitude,postal,calling_code,flag,emoji_flag,emoji_unicode",
        );
        setFormData((prevData) => {
          return {
            ...prevData,
            country: data.country_name,
            countryCode: data.country_code,
          };
        });
      } catch (error) {}
    };
    getCountry();
  }, []);
  return (
    <section className={styles.container}>
      <div className={styles.content_container}>
        <Typography
          fontFamily="inter"
          textAlign="center"
          textSize={size.lg}
          textWeight="600"
        >
          Onboarding
        </Typography>
        <Typography textAlign="center" fontFamily="inter" textSize={size.md}>
          Enter the following credentials to complete onboarding
        </Typography>
        <div className={styles.form_container}>
          <TextInput
            value={formData.firstName}
            setValue={(data) =>
              setFormData((prevData) => {
                return { ...prevData, firstName: data };
              })
            }
            label="First Name"
            placeholder="Enter Name"
          />
          <TextInput
            value={formData.lastName}
            setValue={(data) =>
              setFormData((prevData) => {
                return { ...prevData, lastName: data };
              })
            }
            label="Last Name"
            placeholder="Enter Name"
          />
          <TextInput
            value={email || ""}
            setValue={(data) =>
              setFormData((prevData) => {
                return { ...prevData, email: data };
              })
            }
            disabled
            label="Email Address"
            placeholder="Enter Email"
            right={<img src={emailIcon} alt="icon" width={20} height={20} />}
          />
          <SelectInput
            value={formData.country}
            setValue={(data) =>
              setFormData((prevData) => {
                return {
                  ...prevData,
                  country: data,
                  countryCode:
                    Country.getAllCountries().find(
                      (country) => country.name === data,
                    )?.isoCode ?? "",
                };
              })
            }
            label="Country"
            placeholder="Select Country"
            datalistTop="84px"
            dropdownTop="47px"
            options={Country.getAllCountries().map((data) => data.name)}
            height="150px"
          />
          <Button
            text="Get Started"
            isPrimary={true}
            isLoading={requestLoading}
            onClick={() => {
              handleFormSubmission();
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default UpdateAccountForm;
