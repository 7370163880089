import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import AddCertificateForm from "components/StaffHub/Certificatetype/AddCertificateForm";
import colors from "theme/colors";
import { getRequest, postRequest, patchRequest } from "api/request";
import {
  CertificateRequiredFormData,
  CertificateFormData,
} from "types-definition/StaffHub/certificateType";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import validator from "utils/validateFields";
import { CERTIFICATE_TYPE_URL, EDIT_CERTIFICATE_TYPE_URL } from "api/urls";
import ScrollToTop from "components/Common/ScrollToTop";
import {
  getTimeShiftGeneration,
  getUserTypes,
  removeTimeShiftGeneration,
} from "utils/localstorage";
import { toastError, toastSuccess } from "utils/toast";
import { updateSetupComplete } from "utils/setupWizard";

const AddCertificateType = () => {
  const navigate = useNavigate();
  const certificateId = getTimeShiftGeneration();
  const [formData, setFormData] = useState<CertificateFormData>({
    name: "",
    requirementLevel: "ifRequiredByService",
    no: "0",
    active: true,
    ifRequiredByService: true,
    allService: false,
  });

  const [requiredFormData, setRequiredFormData] =
    useState<CertificateRequiredFormData>({
      name: false,
      requirement: true,
      no: false,
    });

  const [requestLoading, setRequestLoading] = useState(false);

  const handleSubmitForm = async () => {
    const { status, data } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData: any) => {
        return { ...prevData, ...data };
      });
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    setRequestLoading(true);
    try {
      const response = certificateId
        ? await patchRequest(`${EDIT_CERTIFICATE_TYPE_URL}/${certificateId}`, {
            certificateName: formData.name,
            active: formData.active,
            requirementLevel: formData.ifRequiredByService
              ? "ifRequiredByService"
              : "allService",
          })
        : await postRequest(CERTIFICATE_TYPE_URL, {
            certificateName: formData.name,
            active: formData.active,
            requirementLevel: formData.ifRequiredByService
              ? "ifRequiredByService"
              : "allService",
          });
      if (response?.success) {
        toastSuccess(
          `${
            certificateId
              ? "Certificate Type Edited Successfully"
              : "Certificate Type Added Successfully"
          }`,
        );
        const setUpComplete = await updateSetupComplete(
          "5",
          false,
          "certificatetype",
        );
        const userType = getUserTypes();
        if (!setUpComplete && userType !== "Web User") {
          navigate("/setup-wizard");
        } else {
          removeTimeShiftGeneration();
          navigate(`/staff-hub/certificate-type`);
        }
      } else {
        toastError(response?.data);
      }
    } finally {
      setRequestLoading(false);
    }
  };
  const handleFormCancelation = () => {
    setFormData({
      name: "",
      requirementLevel: "",
      no: "",
      active: true,
      ifRequiredByService: true,
      allService: false,
    });
    removeTimeShiftGeneration();
    navigate("/staff-hub/certificate-type");
  };

  useEffect(() => {
    const getGradeHandler = async () => {
      try {
        if (certificateId !== undefined && certificateId !== null) {
          const response =
            certificateId &&
            (await getRequest(`${EDIT_CERTIFICATE_TYPE_URL}/${certificateId}`));
          if (response?.success) {
            setFormData({
              // certificateName
              name: response.data?.certificateName,
              requirementLevel: response.data?.requirementLevel,
              //no will be updated later
              no: "5",
              active: response.data?.active,
              ifRequiredByService:
                response.data?.requirementLevel === "ifRequiredByService"
                  ? true
                  : false,
              allService:
                response.data?.requirementLevel === "allService" ? true : false,
            });
          } else {
            if (response?.data)
              toast.error(response?.data, {
                duration: 4000,
                position: "top-center",
              });
          }
        }
      } finally {
      }
    };
    getGradeHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="staff/hub" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.content_container}>
            <Typography textAlign="left" textWeight="600" textSize="24px">
              Add Certificate
            </Typography>
            <AddCertificateForm
              formData={formData}
              setFormData={setFormData}
              requiredFormData={requiredFormData}
              setRequiredFormData={setRequiredFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Cancel"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={true}
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddCertificateType;
