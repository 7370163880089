import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import cancelIcon from "../../../../assets/svg/cancel-icon.svg";
import { AdminAddServiceGroupFormData } from "../../../../types-definition/admin";
import Checkbox from "../../../Form/Checkbox";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch } from "../../../../redux/hooks";

import { getRequest, postRequest, putRequest } from "../../../../api/request";
import {
  CREATE_SERVICE_GROUP_URL,
  GET_SERVICE_GROUP_URL,
} from "../../../../api/urls";
import {
  setShowAddServiceGroupModal,
  updateFetchedServiceGroupCounter,
} from "../../../../redux/slice/client/serviceGroupSlice";
import { getServiceGroup, getUserTypes } from "../../../../utils/localstorage";
import { toastError, toastSuccess } from "../../../../utils/toast";
import validator from "utils/validateFields";
import { useNavigate } from "react-router-dom";
import { updateSetupComplete } from "utils/setupWizard";

const AddServiceGroupModal: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [requestLoading, setrequestLoading] = useState(false);
  const [formData, setFormData] = useState<AdminAddServiceGroupFormData>({
    name: "",
    active: true,
  });
  const [requiredFormData, setRequiredFormData] = useState({
    name: false,
  });
  const handleSubmitForm = async () => {
    const id = getServiceGroup();
    setrequestLoading(true);
    const { status, data } = validator(formData, requiredFormData);
    if (!status) {
      toastError("Fill all required fields");
      setrequestLoading(false);
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      return;
    }
    let response;
    if (id) {
      response = await putRequest(
        `${CREATE_SERVICE_GROUP_URL}/${id}`,
        formData,
      );
    } else {
      response = await postRequest(CREATE_SERVICE_GROUP_URL, formData);
    }
    if (response?.success) {
      toastSuccess(`Service Group ${id ? "Updated" : "Created"} Successfully`);

      dispatch(setShowAddServiceGroupModal(false));
      dispatch(updateFetchedServiceGroupCounter());
      const setUpComplete = await updateSetupComplete(
        "11",
        false,
        "servicegroup",
      );

      const userType = getUserTypes();
      if (!setUpComplete && userType !== "Web User") {
        navigate("/setup-wizard");
      } else {
        navigate("/admin/service-group");
      }
    } else {
      if (response?.data) toastError(response?.data);
    }
    setrequestLoading(false);
  };
  useEffect(() => {
    const data = getServiceGroup();
    const getServiceGroupInfo = async () => {
      if (data) {
        setrequestLoading(true);
        const response = await getRequest(`${GET_SERVICE_GROUP_URL}/${data}`);
        if (response?.success) {
          setFormData({
            name: response.data.name,
            active: response.data.active,
          });
          // dispatch(updateFetchedPermissionGroupCounter());
        } else {
          if (response?.data) toastError(response?.data);
        }
      }
      setrequestLoading(false);
    };
    getServiceGroupInfo();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600">New Service Group</Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() => dispatch(setShowAddServiceGroupModal(false))}
          />
        </div>
        <div className={styles.form_container}>
          <TextInput
            placeholder="Enter Service group name"
            label="Name"
            value={formData.name}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, name: data };
              });
              setRequiredFormData((prevData) => {
                return { ...prevData, name: false };
              });
            }}
            error={requiredFormData.name}
            helperText={
              requiredFormData.name ? "Service Group is required" : ""
            }
            style={{ marginBottom: "20px" }}
          />
          <Checkbox
            value={formData.active}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, active: data };
              });
            }}
            label={"Active"}
          />
        </div>
        <div className={styles.button_container}>
          <Button
            text="Cancel"
            isPrimary={false}
            onClick={() => dispatch(setShowAddServiceGroupModal(false))}
            marginHorizontal="10px"
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
          />
          <Button
            text="Save Service Group"
            isPrimary={true}
            onClick={handleSubmitForm}
            isLoading={requestLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddServiceGroupModal;
