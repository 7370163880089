import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import ActiveState from "../../../../Common/ActiveState";
import tableSelectorIcon from "../../../../../assets/svg/table-selector.svg";
import { EmployeeData } from "../../../../../types-definition/StaffHub/employee";
import EmployeeTableDropdown from "../EmployeeTableDropdown";

const EmployeeTableItem: React.FC<EmployeeData> = ({
  index,
  createdAt,
  email,
  firstName,
  id,
  isActive,
  lastName,
  portalAccess,
  pin,
  grade,
  postcode,
  town,
  employeeNumber,
  username,
  payRate,
  employeeId,
  holidayCalculationMethod,
}) => {
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        {/* <CircularName name={`${firstName} ${lastName}`} /> */}
        <Typography textAlign="left">{`${firstName} ${lastName}`}</Typography>
      </td>
      <td className={styles.td_employee_no}>
        <Typography textAlign="left">{employeeNumber}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{grade ? `${grade}` : "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{postcode ? postcode : "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{town ? town : "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <ActiveState active={isActive} />
      </td>
      <td className={styles.td}>
        <div
          className={`${styles.image_container} ${styles.td_relative}`}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
          onBlur={() => setshowEditDropdown(false)}
          tabIndex={0}
        >
          {showEditDropdown && (
            <EmployeeTableDropdown
              username={username}
              name={`${firstName} ${lastName}`}
              id={id.toString()}
              employeeId={`${employeeId}`}
              payRate={payRate ? payRate : "0"}
              holidayCalculationMethod={holidayCalculationMethod}
            />
          )}
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default EmployeeTableItem;
