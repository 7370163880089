import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { HolidayMultiplier } from "../../../types-definition/admin";
const initialState: HolidayMultiplier = {
  showHolidayMultiplierModal: false,
  reload: false,
};
const employeeHolidayMultiplier = createSlice({
  name: "employeeHolidayMultiplier",
  initialState,
  reducers: {
    toggleHolidayMultiplierModal: (state, action) => {
      state.showHolidayMultiplierModal =
        action.payload.showHolidayMultiplierModal;
    },
    reload: (state, action) => {
      state.reload = action.payload.reload;
    },
  },
});
export const { toggleHolidayMultiplierModal, reload } =
  employeeHolidayMultiplier.actions;
export const employeeHolidayMultiplierState = (state: RootState) =>
  state.employeeHolidayMultiplier.showHolidayMultiplierModal;
export const reloadState = (state: RootState) =>
  state.employeeHolidayMultiplier.reload;
export default employeeHolidayMultiplier.reducer;
