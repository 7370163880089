import React from "react";
import Typography from "../../../../Common/Typography";
import styles from "./index.module.css";
import { LInkToLocationHeaderProps } from "../../../../../types-definition/StaffHub/employee/linkToLocation";
import { useNavigate } from "react-router-dom";
const LInkToLocationHeader: React.FC<LInkToLocationHeaderProps> = ({
  location,
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.flex_item}>
      <div
        className={`${styles.location} ${
          location === "Available Locations" && styles.active_location
        }`}
        onClick={() => {
          navigate("/employee/link-to-location");
        }}
      >
        <Typography
          textColor={`${location === "Available Locations" && "#FFFFFF"}`}
          // text="Available Locations"
          textAlign="left"
          textWeight="500"
          textSize="14px"
          marginHorizontal="8px"
          style={{ cursor: "pointer" }}
        >
          Available Locations
        </Typography>
      </div>
      <div
        className={`${styles.location} ${
          location === "Assigned Locations" && styles.active_location
        }`}
        onClick={() => {
          navigate("/employee/linked-to-location");
        }}
      >
        <Typography
          textColor={`${location === "Assigned Locations" && "#FFFFFF"}`}
          text="Assigned Locations"
          textAlign="left"
          textWeight="500"
          textSize="14px"
          marginHorizontal="8px"
        />
      </div>
    </div>
  );
};

export default LInkToLocationHeader;
