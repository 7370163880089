import React from "react";
import Typography from "../Typography";
import styles from "./index.module.css";
import colors from "../../../theme/colors";
import elementDescriptionIcon from "../../../assets/svg/elements-empty.svg";
import primaryPlusIcon from "../../../assets/svg/primary-plus-icon.svg";
import { useNavigate } from "react-router-dom";
import { EmptyItemStateProps } from "../../../types-definition/common";

const EmptyItemState: React.FC<EmptyItemStateProps> = ({
  text2Width = "680px",
  textAlign,
  text,
  location,
  label,
  action,
  buttonDisabled = false,
  text2,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.content_empty}>
      <img src={elementDescriptionIcon} alt="icon" width={40} height={40} />

      <div className={styles.midcontent_container}>
        <Typography textColor={colors.gray6} textWeight="500">
          {text}
        </Typography>
        <div className={styles.text2}>
          <Typography
            textColor={colors.gray6}
            textWeight="500"
            style={{ width: text2Width, lineHeight: "20px" }}
            textAlign="left"
          >
            {text2}
          </Typography>
        </div>
      </div>
      <div
        className={
          buttonDisabled ? styles.disabled_cursor : styles.pointer_cursor
        }
        onClick={
          buttonDisabled
            ? () => {}
            : action
              ? () => action()
              : () => navigate(`/${location}`)
        }
      >
        <img src={primaryPlusIcon} alt="plus-icon" />
        <Typography
          textWeight="600"
          // style={{
          //   background: "linear-gradient(#006093, #00dcda)",
          //   WebkitTextFillColor: "transparent",
          //   WebkitBackgroundClip: "text",
          // }}
          textSize="14px"
          textColor={colors.primary3}
        >
          {label}
        </Typography>
      </div>
    </div>
  );
};

export default EmptyItemState;
