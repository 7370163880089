import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  showModal: false,
  showSkipPermissionModal: false,
};

export const wizardSlice = createSlice({
  name: "wizard",
  initialState,
  reducers: {
    showWizardModal: (state, action) => {
      state.showModal = action.payload.showModal;
    },
    handleShowSkipPermissionModal: (state, action) => {
      state.showSkipPermissionModal = action.payload.showSkipPermissionModal;
    },
  },
});
export const { showWizardModal, handleShowSkipPermissionModal } =
  wizardSlice.actions;

export const getShowModal = (state: RootState) => state.wizard.showModal;
export const getShowSkipPermissionModal = (state: RootState) =>
  state.wizard.showSkipPermissionModal;
export default wizardSlice.reducer;
