import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import { EmployeeTimeSheetTableProps } from "../../../../../types-definition/StaffHub/employee";
import colors from "../../../../../theme/colors";
import EmployeeTimeSheetTableItem from "../EmployeTimeSheetTableItem";
import moment from "moment";
import chevronLeftIcon from "../../../../../assets/svg/chevron-left.svg";
import chevronRightIcon from "../../../../../assets/svg/chevron-right.svg";

const EmployeeTimeSheetTable: React.FC<EmployeeTimeSheetTableProps> = ({
  data = [],
  monthFilterHandler,
  currentMonth,
  setCurrentMonth,
}) => {
  const handleDateUpdate = (action: "increase" | "decrease") => {
    if (action === "increase") {
      let nextMonth = moment(currentMonth.to).add(1, "month");

      setCurrentMonth({
        from: nextMonth.startOf("month").toISOString(),
        to: nextMonth.endOf("month").toISOString(),
      });
    } else {
      let previousMonth = moment(currentMonth.to).subtract(1, "month");

      setCurrentMonth({
        from: previousMonth.startOf("month").toISOString(),
        to: previousMonth.endOf("month").toISOString(),
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.toptext}>
        <img
          src={chevronLeftIcon}
          alt="left-icon"
          onClick={() => handleDateUpdate("decrease")}
        />
        <Typography textWeight="600" textSize="16px">
          {moment(currentMonth.to).format("MMMM YYYY")}
        </Typography>
        <img
          src={chevronRightIcon}
          alt="right-icon"
          onClick={() => handleDateUpdate("increase")}
        />
      </div>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Day
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Start Time
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Stop Time
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Total Time
              </Typography>
            </th>
            <th className={styles.th_status}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Status
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return <EmployeeTimeSheetTableItem {...item} key={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTimeSheetTable;
