import React, { useEffect, useState } from "react";
import {
  ClientSelectInputProps,
  ClientSelectInputData,
} from "../../../types-definition/form";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import dropDownIcon from "../../../assets/svg/select-dropdown.svg";
import colors from "../../../theme/colors";

const ClientSelectInput: React.FC<ClientSelectInputProps> = ({
  options = [],
  label,
  labelColor,
  placeholder,
  value,
  setValue,
  client,
  height = "200px",
  width,
  datalistTop = "91px",
  dropdownTop = "55px",
  required = false,
  error = false,
  helperText,
  readonly = false,
  loadingText,
  valueUpdatedCounter = 0,
  id,
  disabled = false,
}) => {
  const [localValueUpdatedCounter, setLocalValueUpdatedCounter] = useState(0);
  const [showDropDown, setShowDropDown] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [list, setList] = useState<Array<ClientSelectInputData>>([]);
  const handleListSelector = (item: ClientSelectInputData) => {
    setValue(item.name, item.id, item.data);
    setIsSelected(true);
    setShowDropDown(false);
  };
  const filterListFromString = (input: string) => {
    if (input !== "" && input !== undefined) {
      const newList = options.filter((item) =>
        item.name.toLowerCase().includes(input.toLowerCase()),
      );
      setValue(input);
      setList(newList);
      setIsSelected(false);
    } else {
      setIsSelected(false);
      setValue("");
      setList(options);
    }
  };
  useEffect(() => {
    if (!isSelected && !showDropDown && isActive) {
      setValue("");
      setList(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, showDropDown]);
  useEffect(() => {
    if (list.length === 0) {
      setList(options);
    } else if (localValueUpdatedCounter !== valueUpdatedCounter) {
      setLocalValueUpdatedCounter(valueUpdatedCounter);
      setList(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  return (
    <section className={styles.container} id={id}>
      <Typography
        textAlign="left"
        textSize={size.md}
        textWeight="600"
        textColor={labelColor}
      >
        {required ? `${label} *` : label}
      </Typography>
      <input
        type="text"
        value={client}
        placeholder={placeholder}
        className={styles.select}
        onChange={(e) => {
          if (!showDropDown) {
            setShowDropDown(true);
          }
          filterListFromString(e.target.value);
        }}
        onFocus={() => {
          setShowDropDown(true);
          setisActive(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 500);
        }}
        readOnly={readonly}
        disabled={disabled}
        style={{ width, borderColor: error ? colors.error : colors.gray3 }}
      />
      <div className={styles.helpertext_container}>
        <Typography
          textAlign="left"
          textSize={"10px"}
          textWeight="600"
          textColor={error ? colors.error : colors.primary3}
        >
          {helperText}
        </Typography>
      </div>
      <div
        className={styles.dropdown_container}
        style={{ top: dropdownTop }}
        tabIndex={0}
        onClick={() => {
          setShowDropDown(true);
          setisActive(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 400);
        }}
      >
        <img
          src={dropDownIcon}
          className={styles.dropdown}
          alt="dropdown icon"
        />
      </div>
      {showDropDown && (
        <div
          className={styles.datalist}
          style={{ maxHeight: height, top: datalistTop }}
        >
          {list.length === 0 ? (
            <p>{loadingText ?? "No data"}</p>
          ) : (
            list.map((item) => (
              <p onClick={() => handleListSelector(item)} key={item.id}>
                {item.name}
              </p>
            ))
          )}
        </div>
      )}
    </section>
  );
};

export default ClientSelectInput;
