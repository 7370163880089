import { getRequest, postRequest } from "api/request";
import searchIcon from "assets/svg/search-icon.svg";
import ActivityLogDateFilter from "components/Admin/Activity Logs/ActivityLogDateFilter";
import DashboardHeader from "components/Common/DashboardHeader";
import PageBackButton from "components/Common/PageBackButton";
import PaginatedItems from "components/Common/Pagination";
import ScrollToTop from "components/Common/ScrollToTop";
import Skeleton from "components/Common/Skeleton";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import ExportCheckcallLogs from "components/Report/CheckcallReportLog/ExportCheckcallLogs";
import CheckInsTable from "components/Report/CheckpointReport/CheckInsTable";
import moment from "moment";
import { useEffect, useState } from "react";
import colors from "theme/colors";
import {
  CheckpointDataReduxInteface,
  CheckpointReportShiftsInterface,
} from "types-definition/report";
import { getCheckpointReportData as getLocalStorageCheckpointData } from "utils/localstorage";
import { splitTimeAndConvertToMins } from "utils/timeConverter";
import { toastError } from "utils/toast";
import styles from "./index.module.css";

const CheckpointsCheckIns = () => {
  const checkpoint: CheckpointDataReduxInteface = JSON.parse(
    getLocalStorageCheckpointData() || "{}",
  );
  const [requestLoading, setRequestLoading] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [dateFilter, setDateFilter] = useState({
    from: moment().toISOString(),
    to: moment().toISOString(),
  });
  const [shifts, setShifts] = useState<Array<CheckpointReportShiftsInterface>>(
    [],
  );
  const [filteredShifts, setFilteredShifts] = useState<
    Array<CheckpointReportShiftsInterface>
  >([]);

  const [pagination, setPagination] = useState<any>({
    start: 0,
    end: 20,
    event: 1,
  });
  const handlePaginate = (start: number, end: number, event?: any) => {
    setPagination({
      start,
      end,
      event,
    });
  };

  /*

  - get all shifts
  - get all successful checkpoints checkins

  - map through all shifts
    - create a `checkins history` property
    - `successful checkin shiftId` === shift.id
    - push into the checkins history

  */

  const checkInsTime = Array(parseInt(checkpoint.frequency))
    .fill(false)
    .map((_, index) => {
      let minutes = splitTimeAndConvertToMins(checkpoint.interval);

      return moment(checkpoint.shiftStart, "HH:mm")
        .add(minutes * index, "minutes")
        .format("HH:mm");
    });

  useEffect(() => {
    const getShifts = async () => {
      setRequestLoading(true);
      try {
        const response: any = await getRequest(
          `/misc/active-board/service/${checkpoint.serviceId}`,
        );

        const checkinResponse = await postRequest(
          `/employee/checkpoint/details`,
          {
            locationId: checkpoint?.locationId,
            serviceId: checkpoint?.serviceId,
            serviceCheckpointId: checkpoint?.checkpointId,
          },
        );

        const allSuccessfulCheckins = checkinResponse?.data?.map(
          (checkIn: any) => ({
            shiftId: checkIn?.shift?.id,
            time: moment(checkIn?.time, "HH:mm A").format("HH:mm"),
            completedAt: checkIn?.createdAt,
          }),
        );

        let mappedShifts = response?.data
          ?.filter((shift: any) => shift?.locationId === checkpoint?.locationId)
          ?.map((shift: any) => {
            let successfulCheckinsForShift = allSuccessfulCheckins.filter(
              (checkin: any) => checkin?.shiftId === shift?.id,
            );

            const history = checkInsTime.map((period: string) => {
              const DATE_FORMAT = "DD-MM-YYYY";
              const today = moment(moment().format(DATE_FORMAT), DATE_FORMAT);
              const shiftDate = moment(
                moment(shift.date).format(DATE_FORMAT),
                DATE_FORMAT,
              );

              const completedCheckin = successfulCheckinsForShift.find(
                (checkin: any) => checkin.time === period,
              );

              if (completedCheckin) {
                return {
                  ...completedCheckin,
                  status: "COMPLETED",
                };
              }

              let intervalInMins = splitTimeAndConvertToMins(
                checkpoint.interval,
              );

              if (
                today.isSame(shiftDate) &&
                moment().isAfter(
                  moment(period, "HH:mm").add(intervalInMins, "minutes"),
                )
              ) {
                return {
                  status: "MISSED",
                  completedAt: null,
                };
              }

              if (today.isAfter(shiftDate)) {
                return {
                  status: "MISSED",
                  completedAt: null,
                };
              }

              return {
                status: "QUEUED",
                completedAt: null,
              };
            });

            return {
              id: shift.id,
              employee: {
                id: shift?.employee?.id,
                name: `${shift?.employee?.firstName} ${shift?.employee?.lastName}`,
              },
              date: shift?.date,
              grade: shift?.employee?.operation?.grade,
              startTime: shift?.startTime,
              endTime: shift?.endTime,
              checkinHistory: history,
            };
          });

        setShifts(mappedShifts);
      } catch (error) {
        toastError("Error fetching shifts");
      } finally {
        setRequestLoading(false);
      }
    };

    getShifts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FILTER FUNCTIONS
  useEffect(() => {
    let filter = shifts;

    if (dateFilter.from) {
      filter = filter.filter((shift) =>
        moment(shift?.date).isSameOrAfter(
          moment(dateFilter.from).startOf("day"),
        ),
      );
    }

    if (dateFilter.to) {
      filter = filter.filter((shift) =>
        moment(shift?.date).isSameOrBefore(moment(dateFilter.to).endOf("day")),
      );
    }

    if (search) {
      filter = filter.filter(
        (shift) =>
          shift?.employee?.name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    setFilteredShifts(
      filter.sort(
        (a: any, b: any) => moment(a?.date).unix() - moment(b?.date).unix(),
      ),
    );
  }, [dateFilter.from, dateFilter.to, search, shifts]);

  return (
    <>
      <ScrollToTop />
      {/* {requestLoading && <PageLoader />} */}
      <section>
        <DashboardHeader active="reports" />
        <div className={styles.container}>
          <div
            className={
              shifts?.length! <= 5
                ? `${styles.item_container} ${styles.item_container_empty}`
                : `${styles.item_container} ${styles.item_container_fill}`
            }
            style={{ overflow: "auto" }}
          >
            <PageBackButton
              width="100%"
              marginBottom="1rem"
              paddingTop="0px"
              to="/reports/checkpoint"
            />
            <div
              className={styles.flex_item}
              style={{ position: "sticky", left: 0 }}
            >
              <div>
                <Typography
                  textAlign="left"
                  textColor={colors.primary3}
                  textWeight="600"
                  textSize="20px"
                >
                  {checkpoint.checkpointName}
                </Typography>
                <Typography textAlign="left">
                  Total CheckIns: <strong>{checkpoint.frequency}</strong>
                </Typography>
                <Typography textAlign="left">
                  Service: <strong>{checkpoint.serviceName}</strong>
                </Typography>
                <Typography textAlign="left">
                  Location: <strong>{checkpoint.locationName}</strong>
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <ActivityLogDateFilter
                  setDate={(from, to) =>
                    setDateFilter({
                      from,
                      to,
                    })
                  }
                  clearFilterCallback={() =>
                    setDateFilter({
                      from: moment().toISOString(),
                      to: moment().toISOString(),
                    })
                  }
                />
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={search}
                  setValue={(value) => {
                    setSearch(value);
                  }}
                />
                <ExportCheckcallLogs />
              </div>
            </div>

            {requestLoading ? (
              <Skeleton.Table />
            ) : filteredShifts?.length === 0 ? (
              <div className={styles.empty_container}>
                <Typography marginVertical="5rem" paddingVertical="20px">
                  No shifts for <strong>{checkpoint.serviceName}</strong>
                </Typography>
              </div>
            ) : (
              <>
                <CheckInsTable
                  checkInsTime={checkInsTime}
                  data={
                    filteredShifts?.slice(pagination.start, pagination.end)!
                  }
                />

                <div
                  className={styles.pagination_container}
                  style={{ position: "sticky", left: 0 }}
                >
                  <PaginatedItems
                    items={Array(filteredShifts.length).fill(false)}
                    itemsPerPage={20}
                    handlePaginate={handlePaginate}
                    checkFirstPage={pagination.event}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckpointsCheckIns;
