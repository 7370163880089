const returnChangedValuesInObject = (
  mutableObject: any,
  immutableObject: any,
) => {
  // Shallow clone the mutableObject to avoid overwriting.
  let clonedMutableObject: { [key: string]: any } = Object.fromEntries(
    Object.entries(mutableObject),
  );

  for (const key in immutableObject) {
    if (typeof immutableObject[key] !== "object") {
      if (immutableObject[key] === clonedMutableObject[key]) {
        delete clonedMutableObject[key];
      }
    }

    // Handle if the data contains Arrays
    if (Array.isArray(immutableObject[key])) {
      if (immutableObject[key]?.length === clonedMutableObject[key]?.length) {
        let dataHasChanged = false;

        for (const index in immutableObject[key]) {
          if (immutableObject[key][index] !== clonedMutableObject[key][index]) {
            dataHasChanged = true;
            break;
          }
        }

        if (!dataHasChanged) {
          delete clonedMutableObject[key];
        }
      }
    }
    // Handle if the data contains Objects
    if (
      typeof immutableObject[key] === "object" &&
      !Array.isArray(immutableObject[key])
    ) {
      let imutableObjectEntries = Object.entries(immutableObject[key]);
      let mutableObjectEntries = Object.entries(mutableObject[key]);

      if (imutableObjectEntries.length === mutableObjectEntries.length) {
        let dataHasChanged = false;
        for (const innerKey in immutableObject[key]) {
          if (
            immutableObject[key][innerKey] !==
            clonedMutableObject[key][innerKey]
          ) {
            dataHasChanged = true;
            break;
          }
        }
        if (!dataHasChanged) {
          delete clonedMutableObject[key];
        }
      }
    }
  }

  return clonedMutableObject;
};

export default returnChangedValuesInObject;
