import React from "react";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import colors from "theme/colors";
import PayrollTableItem from "../PayrollTableItem";
import { PayrollReportProps } from "types-definition/report";

const PayrollTable: React.FC<PayrollReportProps> = ({ data = [] }) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Employee Name
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Grade
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Pay Option
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Worked Hours
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Amount earned
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return <PayrollTableItem {...item} key={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PayrollTable;
