import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { CertificateGroupData } from "types-definition/StaffHub/certificateGroup";

interface CertificateGroupSliceInterface {
  isFetching: boolean;
  isSearchEmpty: boolean;
  searchedValue: string;
  certificateGroup: Array<CertificateGroupData>;
  showAssignCertificateModal: boolean;
  showDeleteCertificateGroupModal: boolean;
  certificateGroupCounter: number;
}

const initialState: CertificateGroupSliceInterface = {
  showAssignCertificateModal: false,
  showDeleteCertificateGroupModal: false,
  certificateGroupCounter: 0,
  certificateGroup: [],
  searchedValue: "",
  isSearchEmpty: false,
  isFetching: false,
};

export const certificateGroupSlice = createSlice({
  name: "certificateGroup",
  initialState,
  reducers: {
    updateShowAssignCertificateModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showAssignCertificateModal = action.payload;
    },
    updateShowDeleteCertificateGroupModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showDeleteCertificateGroupModal = action.payload;
    },
    updateCertificateGroupCounter: (state) => {
      state.certificateGroupCounter += 1;
    },
    setCertificateGroupData: (
      state,
      action: PayloadAction<Array<CertificateGroupData>>,
    ) => {
      state.certificateGroup = action.payload;
    },
    updateCertificateGroupSearchFetching: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isFetching = action.payload;
    },
    updateCertificateGroupSearchEmpty: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isSearchEmpty = action.payload;
    },
    setSearchedCertificateGroupValue: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.searchedValue = action.payload;
    },
  },
});
export const {
  updateShowAssignCertificateModal,
  updateShowDeleteCertificateGroupModal,
  updateCertificateGroupCounter,
  setCertificateGroupData,
  setSearchedCertificateGroupValue,
  updateCertificateGroupSearchEmpty,
  updateCertificateGroupSearchFetching,
} = certificateGroupSlice.actions;

export const getShowAssignPermissionModal = (state: RootState) =>
  state.certificateGroup.showAssignCertificateModal;

export const getShowDeleteCertificateGroupModal = (state: RootState) =>
  state.certificateGroup.showDeleteCertificateGroupModal;

export const getCertificateGroupCounter = (state: RootState) =>
  state.certificateGroup.certificateGroupCounter;

export const getCertificateGroupData = (state: RootState) =>
  state.certificateGroup.certificateGroup;

export const getCertificateGroupSearchValue = (state: RootState) =>
  state.certificateGroup.searchedValue;

export const getCertificateGroupSearchEmpty = (state: RootState) =>
  state.certificateGroup.isSearchEmpty;

export const getCertificateGroupSearchFetching = (state: RootState) =>
  state.certificateGroup.isFetching;

export default certificateGroupSlice.reducer;
