import React from "react";
import Typography from "../../../../Common/Typography";
import styles from "./index.module.css";
import { EmployeeAccuralCurrentYearAcomplishmentProps } from "../../../../../types-definition/StaffHub/employee";
import moment from "moment";

const EmployeeAccuralCurrentYearAcomplishment: React.FC<
  EmployeeAccuralCurrentYearAcomplishmentProps
> = ({ entitled, available, taken, date, holidayType }) => {
  const firstDayOfYear = moment(date).startOf("year").format("MMM YYYY");
  const lastDayOfYear = moment(date).endOf("year").format("MMM YYYY");
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography textAlign="left" textWeight="600" textSize="14px">
          Current Year Entitlement
        </Typography>
        <Typography
          textAlign="left"
          textWeight="500"
          textSize="14px"
          textColor="#667185"
        >
          from {firstDayOfYear} to {lastDayOfYear}
        </Typography>
      </div>

      <div className={styles.body}>
        <div className={styles.flex_container}>
          <div className={styles.flex_item}>
            <Typography
              textAlign="center"
              textWeight="700"
              textSize="24px"
              marginVertical="1px"
            >
              {Math.round(Number(entitled) * 10) / 10}
            </Typography>
            <Typography textAlign="center" textWeight="600" textSize="18px">
              {holidayType === "entitlement" ? "ENTILTED" : "ACCRUED"}
            </Typography>
          </div>
          <div className={styles.flex_item}>
            <Typography
              textAlign="center"
              textWeight="700"
              textSize="24px"
              marginVertical="1px"
            >
              {available.includes("-") ? "0" : available}
            </Typography>
            <Typography textAlign="center" textWeight="600" textSize="18px">
              AVAILABLE
            </Typography>
          </div>
          <div className={styles.flex_item}>
            <Typography
              textAlign="center"
              textWeight="700"
              textSize="24px"
              marginVertical="1px"
            >
              {taken}
            </Typography>
            <Typography textAlign="center" textWeight="600" textSize="18px">
              TAKEN
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeAccuralCurrentYearAcomplishment;
