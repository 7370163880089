import React from "react";
import NumberInput from "../../../Form/NumberInput";
import { AdminTimeCheckSettingsProps } from "../../../../types-definition/admin";
import styles from "./index.module.css";

const SettingsTimeCheckForm: React.FC<AdminTimeCheckSettingsProps> = ({
  formData,
  setFormData,
  setRequiredFormData,
  requiredFormData,
}) => {
  return (
    <>
      <div className={styles.form_flex_container}>
        <NumberInput
          label="Before-Shift Start (Mins)"
          placeholder=""
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, beforeShiftStart: data };
            });
            setRequiredFormData((prevData) => {
              return {
                ...prevData,
                beforeShiftStart: { status: false, text: "" },
              };
            });
          }}
          value={formData.beforeShiftStart}
          error={requiredFormData.beforeShiftStart.status}
          helperText={
            requiredFormData.beforeShiftStart.text
              ? "Before-Shift Start is required"
              : ""
          }
          required={true}
          isToolTip
          toolTipText="Determines the time period before a scheduled shift during which employees can officially start their shift"
        />

        <NumberInput
          label="After-Shift Start (Mins)"
          placeholder=""
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, afterShiftStart: data };
            });
            setRequiredFormData((prevData) => {
              return {
                ...prevData,
                afterShiftStart: {
                  status: false,
                  text: "",
                },
              };
            });
          }}
          value={formData.afterShiftStart}
          error={requiredFormData.afterShiftStart.status}
          helperText={
            requiredFormData.afterShiftStart.text
              ? "Before-Shift Start is required"
              : ""
          }
          required={true}
          isToolTip
          toolTipText="Defines the time frame following the scheduled start time, after which a shift is classified as 'very late'"
        />
      </div>
      <div className={styles.form_flex_container}>
        <NumberInput
          label="Before-Shift End (Mins)"
          placeholder=""
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, beforeShiftEnd: data };
            });

            setRequiredFormData((prevData) => {
              return {
                ...prevData,
                beforeShiftEnd: {
                  status: false,
                  text: "",
                },
              };
            });
          }}
          error={requiredFormData.beforeShiftEnd.status}
          helperText={requiredFormData.beforeShiftEnd.text}
          value={formData.beforeShiftEnd}
          required={true}
          isToolTip
          toolTipText="Sets the time period before a shift's scheduled end time during which employees are allowed to end their shift."
        />
        <NumberInput
          label="After-Shift End (Mins)"
          placeholder=""
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, afterShiftEnd: data };
            });
            setRequiredFormData((prevData) => {
              return {
                ...prevData,
                afterShiftEnd: {
                  status: false,
                  text: "",
                },
              };
            });
          }}
          value={formData.afterShiftEnd}
          error={requiredFormData.afterShiftEnd.status}
          helperText={requiredFormData.afterShiftEnd.text}
          required={true}
          isToolTip
          toolTipText="Indicates the time frame after the scheduled end time, beyond which a shift is marked as 'very late'"
        />
      </div>
      <div className={styles.form_flex_container}>
        <NumberInput
          label="Pre Checkpoint Time (Mins)"
          placeholder=""
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, preCheckpointTime: data };
            });
            setRequiredFormData((prevData) => {
              return {
                ...prevData,
                preCheckpointTime: {
                  status: false,
                  text: "",
                },
              };
            });
          }}
          error={requiredFormData.preCheckpointTime.status}
          helperText={requiredFormData.preCheckpointTime.text}
          value={formData.preCheckpointTime}
          required={true}
          isToolTip
          toolTipText="Specifies the allowable time period before a scheduled checkpoint time for employees to scan the checkpoint"
        />
        <NumberInput
          label="Post Checkpoint Time (Mins)"
          placeholder=""
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, postCheckpointTime: data };
            });
            setRequiredFormData((prevData) => {
              return {
                ...prevData,
                postCheckpointTime: {
                  status: false,
                  text: "",
                },
              };
            });
          }}
          value={formData.postCheckpointTime}
          error={requiredFormData.postCheckpointTime.status}
          helperText={requiredFormData.postCheckpointTime.text}
          isToolTip
          toolTipText="Determines the duration after the expected checkpoint time, post which it is considered 'very late'"
        />
      </div>
    </>
  );
};

export default SettingsTimeCheckForm;
