import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import EmployeeNavigation from "components/StaffHub/Employee/EmployeeNavigation";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, patchRequest } from "api/request";
import { GET_ALL_EMPLOYEE_URL, UPDATE_MEDICAL_DETAILS } from "api/urls";
import {
  deleteCurrentEmployee,
  getCurrentEmployee,
  getInitialEmployeeAdd,
  getUserTypes,
} from "utils/localstorage";
import { useNavigate } from "react-router-dom";
import { EmployeeMedicalDetailsFormData } from "types-definition/StaffHub/employee";
import EmployeeMedicalDetailsForm from "components/StaffHub/Employee/MedicalDetails/EmployeeMedicalDetailsForm";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { toastError, toastSuccess } from "utils/toast";
import { updateSetupComplete } from "utils/setupWizard";

const EmployeeMedicalDetails = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<EmployeeMedicalDetailsFormData>({
    medicalDetails: "",
  });
  const [requestLoading, setRequestLoading] = useState(false);
  const handleSubmitForm = async () => {
    setRequestLoading(true);
    if (getCurrentEmployee() === null) {
      toast.error("Employee Record Not Found", {
        duration: 4000,
        position: "top-center",
      });
      navigate("/staff-hub");
      return;
    }
    const data = JSON.parse(getCurrentEmployee()!);
    const response = await patchRequest(
      `${UPDATE_MEDICAL_DETAILS}/${data.name}`,
      formData,
    );
    if (response?.success) {
      deleteCurrentEmployee();
      toastSuccess("Medical Details Updated");
      const setUpComplete = await updateSetupComplete("8", false, "employee");
      const userType = getUserTypes();
      if (!setUpComplete && userType !== "Web User") {
        navigate("/setup-wizard");
      } else {
        navigate(`/staff-hub?added=true`);
      }
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };
  const handleFormCancelation = () => {
    navigate("/staff-hub");
  };
  useEffect(() => {
    // prefill data if found

    const getEmployee = async () => {
      const data = getCurrentEmployee();
      if (data !== undefined && data !== null) {
        const username = JSON.parse(data).name;
        const response = await getRequest(
          `${GET_ALL_EMPLOYEE_URL}/${username}`,
        );
        if (response?.success) {
          setFormData({
            medicalDetails: response.data.medicalDetail.medicalDetails ?? "",
          });
        } else {
          if (response?.data) toastError(response?.data);
        }
      }
    };
    getEmployee();
  }, []);
  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="staff/hub" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography textAlign="left" textWeight="600" textSize="24px">
              Add Employee
            </Typography>
          </div>
          <EmployeeNavigation active="medical details" />
          <div className={styles.content_container}>
            <EmployeeMedicalDetailsForm
              formData={formData}
              setFormData={setFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Cancel"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={true}
              />
              <Button
                width="200px"
                text={
                  getInitialEmployeeAdd() ? "Add Employee" : "Update Employee"
                }
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmployeeMedicalDetails;
