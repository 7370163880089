import React from "react";
import styles from "./index.module.css";
import emptyState from "assets/svg/empty-state.svg";
import Typography from "components/Common/Typography";
import colors from "theme/colors";
import DashboardHeader from "components/Common/DashboardHeader";
import { NoPermissionPageProps } from "types-definition/auth";

const NoPermissionPage: React.FC<NoPermissionPageProps> = ({ active }) => {
  return (
    <div className={styles.container}>
      <DashboardHeader active={active} />
      <div className={styles.item_container}>
        <div className={styles.image_container}>
          <img src={emptyState} className={styles.image} alt="empty-search" />
        </div>
        <div className={styles.text_container}>
          <Typography textWeight="500" textColor={colors.gray3}>
            It seems you do not have any permissions to view the necessary page.
          </Typography>
          <Typography textWeight="500" textColor={colors.gray3}>
            Please contact your admin to assign you to the necessary permission
            group.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default NoPermissionPage;
