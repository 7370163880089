import React from "react";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import { UsernameInputProps } from "../../../types-definition/form";
import styles from "./index.module.css";
import colors from "../../../theme/colors";
import LoadingIcons from "react-loading-icons";

const UsernameInput: React.FC<UsernameInputProps> = ({
  placeholder,
  label,
  right,
  left,
  value,
  setValue,
  disabled = false,
  width,
  required = false,
  error = false,
  isLoading = false,
  onFocus,
  helperText,
  keyDown = () => {},
  onblur = () => {},
  id,
}) => {
  return (
    <section className={styles.container} id={id}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <div className={styles.inputContainer}>
        <div className={styles.left}>{left}</div>
        <div>
          <input
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onFocus={() => {
              if (!disabled) {
                onFocus && onFocus();
              }
            }}
            onKeyDown={keyDown}
            onBlur={onblur}
            name="name"
            className={
              right
                ? `${styles.input} ${styles.right_padding}`
                : left
                  ? `${styles.input} ${styles.left_padding}`
                  : styles.input
            }
            disabled={disabled}
            // type=""
            style={{
              width,
              borderColor: error ? colors.error : colors.gray3,
            }}
          />
          <div className={styles.helpertext_container}>
            <Typography
              textAlign="left"
              textSize={"10px"}
              textWeight="600"
              textColor={error ? colors.error : colors.primary3}
            >
              {helperText}
            </Typography>
          </div>
        </div>
        {isLoading && (
          <div className={styles.right}>
            <LoadingIcons.TailSpin
              width={20}
              height={20}
              stroke={colors.primary1}
              style={{ marginLeft: "10px" }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default UsernameInput;
