import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, patchRequest } from "api/request";
import { CREATE_LOCATION_URL, GET_LOCATION_URL } from "api/urls";
import { useNavigate } from "react-router-dom";
import {
  getAddress,
  getClientcontact,
  getCountry,
  getLocation,
} from "utils/localstorage";
import SiteLocationNavigation from "components/Client/Site Location/SiteLocationNavigation";
import {
  SiteLocationAddressFormData,
  SiteLocationRequiredAddressFormData,
  SiteLocationRequiredAddressFormDataUk,
} from "types-definition/client";
import SiteLocationAddressForm from "components/Client/Site Location/SiteLocationAddressForm";
import SiteLocationAddressFormUk from "components/Client/Site Location/SiteLocationAddressFormUk";
import toast from "react-hot-toast";
import { UK } from "types-definition/constants";
import validator from "utils/validateFields";
import ScrollToTop from "components/Common/ScrollToTop";
import PageLoader from "components/Common/PageLoader";
import TextInput from "components/Form/TextInput";
import emailIcon from "assets/svg/Mail Icon.svg";
import { toastError, toastSuccess } from "utils/toast";
import scrolltoView from "utils/scrollToView";

const SiteLocationAddress = () => {
  const navigate = useNavigate();
  const country = getCountry();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [formData, setFormData] = useState<SiteLocationAddressFormData>({
    address1: "",
    address2: "",
    state: "",
    city: "",
    postcode: "",
    postcode1: "",
    country: country!,
    headOffice: true,
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    phone: "",
    latitude: "",
    longitude: "",
    isPostCodeEntered: false,
  });
  const [requiredFormData, setRequiredFormData] =
    useState<SiteLocationRequiredAddressFormData>({
      address1: false,
      state: false,
      city: false,
      country: false,
    });
  const [requiredFormDataUk, setRequiredFormDataUk] =
    useState<SiteLocationRequiredAddressFormDataUk>({
      address1: false,
      country: false,
      city: false,
      postcode1: false,
    });
  const [requestLoading, setRequestLoading] = useState(false);
  const handleSubmitForm = async () => {
    if (country === UK) {
      const { status, data, id } = validator(formData, requiredFormDataUk);
      if (!status) {
        setRequiredFormDataUk((prevData) => {
          return { ...prevData, ...data };
        });
        if (id) {
          scrolltoView(id);
        }
        toast.error("Fill required fields", {
          duration: 4000,
          position: "top-center",
        });
        return;
      }
    } else {
      const { status, data, id } = validator(formData, requiredFormData);
      if (!status) {
        setRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        if (id) {
          scrolltoView(id);
        }
        toast.error("Fill required fields", {
          duration: 4000,
          position: "top-center",
        });
        return;
      }
    }
    setRequestLoading(true);
    try {
      const locationId = getLocation();
      const addressId = getAddress();
      const response = await patchRequest(
        `${CREATE_LOCATION_URL}/${locationId}/address/${addressId}`,
        country === UK
          ? {
              address1: formData.address1,
              address2: formData.address2,
              city: formData.city,
              latitude: formData.latitude.toString(),
              longitude: formData.longitude.toString(),
              postcode: formData.postcode1,
              country: formData.country,
              headOffice: formData.headOffice,
            }
          : {
              address1: formData.address1,
              address2: formData.address2,
              city: formData.city,
              state: formData.state,
              country: formData.country,
              headOffice: formData.headOffice,
            },
      );
      if (response?.success) {
        const contactId = getClientcontact();
        const response = await patchRequest(
          `${CREATE_LOCATION_URL}/${locationId}/contact-person/${contactId}`,
          {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            mobile: formData.mobile,
            phone: formData.phone,
          },
        );
        if (response?.success) {
          toastSuccess("Client Site Address Updated");
          navigate(`/client/operation`);
        } else {
          toastError(response?.data);
        }
      } else {
        toastError(response?.data);
      }
    } finally {
      setRequestLoading(false);
    }
  };
  const handleFormCancelation = () => {
    navigate("/client/site");
  };
  useEffect(() => {
    const getAddressInformation = async () => {
      try {
        const locationId = getLocation();
        if (locationId) {
          const response = await getRequest(
            `${GET_LOCATION_URL}/${locationId}`,
          );
          if (response?.success) {
            setFormData((prevData) => {
              return {
                ...prevData,
                address1: response.data.address.address1 ?? "",
                address2: response.data.address.address2 ?? "",
                state: response.data.address.state ?? "",
                postcode: response.data.address.postcode ?? "",
                postcode1: response.data.address.postcode ?? "",
                country: response.data.address.country ?? "",
                city: response.data.address.city ?? "",
                headOffice: Boolean(response.data.address.headOffice) ?? false,
                latitude: response.data.address.latitude ?? "",
                longitude: response.data.address.longitude ?? "",
                email: response.data.clientContactPerson.email ?? "",
                firstName: response.data.clientContactPerson.firstName ?? "",
                lastName: response.data.clientContactPerson.lastName ?? "",
                mobile: response.data.clientContactPerson.mobile ?? "",
                phone: response.data.clientContactPerson.phone ?? "",
                isPostCodeEntered: response.data.address.postcode
                  ? true
                  : false,
              };
            });
          } else {
            if (response?.data)
              toast.error(response?.data, {
                duration: 4000,
                position: "top-center",
              });
          }
        }
      } finally {
        setIsPageLoading(false);
      }
    };
    getAddressInformation();
  }, []);
  return (
    <>
      {isPageLoading && <PageLoader />}
      <section>
        <ScrollToTop />
        <DashboardHeader active="clients" />
        <div className={styles.container}>
          <div className={styles.item_container}>
            <div className={styles.item_container_text}>
              <Typography textAlign="left" textWeight="600" textSize="24px">
                Add Site Location
              </Typography>
            </div>
            <SiteLocationNavigation active="address" />
            <div className={styles.content_container}>
              {country !== UK ? (
                <SiteLocationAddressForm
                  formData={formData}
                  setFormData={setFormData}
                  requiredFormData={requiredFormData}
                  setRequiredFormData={setRequiredFormData}
                />
              ) : (
                <SiteLocationAddressFormUk
                  formData={formData}
                  setFormData={setFormData}
                  requiredFormData={requiredFormDataUk}
                  setRequiredFormData={setRequiredFormDataUk}
                />
              )}
              {country !== UK && (
                <div className={styles.button_container}>
                  <Button
                    width="200px"
                    text="Cancel"
                    isPrimary={false}
                    onClick={handleFormCancelation}
                    marginHorizontal="10px"
                    backgroundColor={colors.transparent}
                    isPrimaryBorder={true}
                  />
                  <Button
                    width="200px"
                    text="Save"
                    isPrimary={true}
                    onClick={handleSubmitForm}
                    isLoading={requestLoading}
                  />
                </div>
              )}
              {country === UK && formData.isPostCodeEntered && (
                <div className={styles.button_container}>
                  <Button
                    width="200px"
                    text="Cancel"
                    isPrimary={false}
                    onClick={handleFormCancelation}
                    marginHorizontal="10px"
                    backgroundColor={colors.transparent}
                    isPrimaryBorder={true}
                  />
                  <Button
                    width="200px"
                    text="Save"
                    isPrimary={true}
                    onClick={handleSubmitForm}
                    isLoading={requestLoading}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {country === UK && !formData.isPostCodeEntered && (
          <div className={styles.container}>
            <div className={styles.item_container}>
              <div className={styles.content_container}>
                <Typography textAlign="left" textWeight="600" textSize="24px">
                  Client Contact Information
                </Typography>
                <div className={styles.form_flex_container}>
                  <TextInput
                    value={formData.firstName}
                    setValue={(data) =>
                      setFormData((prevData) => {
                        return { ...prevData, firstName: data };
                      })
                    }
                    label="First Name"
                    placeholder="Enter Client First Name"
                  />
                  <TextInput
                    value={formData.lastName}
                    setValue={(data) =>
                      setFormData((prevData) => {
                        return { ...prevData, lastName: data };
                      })
                    }
                    label="Last Name"
                    placeholder="Enter Client Last Name"
                  />
                </div>
                <div className={styles.form_flex_container}>
                  <TextInput
                    value={formData.email}
                    setValue={(data) =>
                      setFormData((prevData) => {
                        return { ...prevData, email: data };
                      })
                    }
                    label="Email Address"
                    placeholder="Enter Email Address"
                    right={
                      <img src={emailIcon} alt="icon" width={20} height={20} />
                    }
                  />
                  <TextInput
                    value={formData.mobile}
                    setValue={(data) =>
                      setFormData((prevData) => {
                        return { ...prevData, mobile: data };
                      })
                    }
                    label="Mobile Number"
                    placeholder="Enter Mobile Number"
                  />
                </div>
                <div className={styles.form_flex_container}>
                  <TextInput
                    value={formData.phone}
                    setValue={(data) =>
                      setFormData((prevData) => {
                        return { ...prevData, phone: data };
                      })
                    }
                    label="Telephone Number"
                    placeholder="Enter Telephone Number"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default SiteLocationAddress;
