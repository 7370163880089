const convertFieldNameToText = (fieldName: string) => {
  const capitalizedFieldName = fieldName.match(/[A-Z]/g);
  let name;
  if (capitalizedFieldName !== null) {
    for (let i = 0; i < capitalizedFieldName.length; i++) {
      if (!name) {
        const index = fieldName.indexOf(capitalizedFieldName[i]);
        name =
          fieldName.slice(0, index) +
          " " +
          capitalizedFieldName[i] +
          fieldName.slice(index + 1);
      } else {
        const index = name.indexOf(capitalizedFieldName[i]);
        name =
          name.slice(0, index) +
          " " +
          capitalizedFieldName[i] +
          name.slice(index + 1);
      }
    }
  }
  if (name) {
    return name;
  } else {
    name = fieldName;
  }
  return name;
};

export default convertFieldNameToText;
