import styles from "./index.module.css";
import glixLogo from "../../../assets/svg/glix-logo.svg";
import LoadingIcons from "react-loading-icons";
import colors from "../../../theme/colors";

const PageLoader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.image_container}>
        <img
          className={styles.logo}
          src={glixLogo}
          alt="glix logo"
          width={50}
          height={50}
        />
        <LoadingIcons.TailSpin
          width={90}
          height={90}
          stroke={colors.primary1}
          className={styles.loader_spinner}
        />
      </div>
    </div>
  );
};

export default PageLoader;
