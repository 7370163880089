import React from "react";
import styles from "./index.module.css";
import SignUpHeader from "components/Common/SignUpHeader";
import Typography from "components/Common/Typography";
import colors from "theme/colors";
import arrowLeftIcon from "assets/svg/arrow-left-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "components/Common/ScrollToTop";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <section>
      <ScrollToTop />
      <SignUpHeader />
      <div className={styles.container}>
        <div
          className={styles.top_text_container}
          onClick={() => navigate("/")}
        >
          <img src={arrowLeftIcon} alt="arrow-left-icon" />
          <Typography textSize="24px" textWeight="600" marginHorizontal={10}>
            Back
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textColor={colors.primary3}
            textSize="15px"
            textWeight="500"
            style={{ marginLeft: "55px" }}
          >
            Last Updated: January 16, 2024
          </Typography>
        </div>
        <div className={`${styles.top_text_header}`}>
          <Typography
            textWeight="600"
            textSize="48px"
            style={{
              marginBottom: "60px",
              marginTop: "20px",
            }}
          >
            Privacy Policy
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            This Privacy Policy describes how Gyzer Technologies
            (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) collects, uses,
            and discloses your personal information when you use Glix, our SaaS
            product. Please read this Privacy Policy carefully to understand how
            we handle your personal information.
          </Typography>
        </div>

        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Information We Collect
          </Typography>
        </div>
        <div className={styles.information_container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            style={{ marginLeft: "-15px" }}
          >
            We collect information that you provide when you register for an
            account, use Glix, or communicate with us. This may include your
            name, email address, payment information, and any other information
            you choose to provide.
          </Typography>
        </div>

        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            How We Use Your Information
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            We use your personal information for the following purposes:
          </Typography>
          <ul className={styles.ul}>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>To provide and maintain Glix.</li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                To process payments and send invoices.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                To communicate with you about your account and Glix-related
                updates
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                To respond to your inquiries and provide customer support.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>To improve and enhance Glix.</li>
            </Typography>
          </ul>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Information Sharing
          </Typography>
        </div>
        <div className={styles.information_container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            style={{ marginLeft: "-15px" }}
          >
            We may share your personal information with third-party service
            providers who assist us in providing Glix or processing payments. We
            will not sell or rent your personal information to third parties.
          </Typography>
        </div>

        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Security
          </Typography>
        </div>
        <div className={styles.information_container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            style={{ marginLeft: "-15px" }}
          >
            We take reasonable measures to protect your personal information
            from unauthorized access or disclosure. However, no data
            transmission over the internet is completely secure, and we cannot
            guarantee the security of your information.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Data Retention
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            We will retain your personal information for as long as necessary to
            fulfil the purposes for which it was collected and as required by
            applicable laws.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Your Choices
          </Typography>
        </div>
        <div className={styles.information_container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            style={{ marginLeft: "-15px" }}
          >
            You can review and update your personal information by logging into
            your account. You may also contact us to request access to, correct,
            or delete your personal information.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Changes to this Privacy Policy
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. We will post any changes on this page and update
            the &quot;Last Updated&quot; date.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Contact Us
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at{" "}
            <Link
              to="mailto:privacy@glixapp.com"
              style={{ color: colors.gray1 }}
            >
              privacy@glixapp.com
            </Link>
          </Typography>
        </div>

        <div className={styles.container_text}>
          <Typography
            textColor={colors.primary3}
            textAlign="left"
            textSize="20px"
            marginVertical={30}
          >
            By using Glix, you consent to the practices described in this
            Privacy Policy.
          </Typography>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
