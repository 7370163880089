import React, { useState } from "react";
import Typography from "../../../Common/Typography";
import deleteIcon from "../../../../assets/svg/error-icon.svg";
import styles from "./index.module.css";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { deleteRequest } from "../../../../api/request";
import { TIME_SHIFT_GENERATION } from "../../../../api/urls";
import {
  getTimeShiftGeneration,
  removeTimeShiftGeneration,
} from "utils/localstorage";
import {
  updateStateHandler,
  deleteHandler,
  getUpdatedState,
} from "../../../../redux/slice/admin/timeShiftGeneration";

const DeleteShiftGeneration: React.FC = () => {
  const updateState = useAppSelector(getUpdatedState);
  const [requestLoading, setRequestLoading] = useState(false);
  const id = getTimeShiftGeneration();
  const dispatch = useAppDispatch();
  const handleDelete = async () => {
    try {
      const response = await deleteRequest(
        `${TIME_SHIFT_GENERATION}/time-shift/section/${id}`,
        setRequestLoading(true),
      );
      if (response?.success) {
        removeTimeShiftGeneration();
        setRequestLoading(false);
        dispatch(
          deleteHandler({
            delete: false,
          }),
        );
        dispatch(
          updateStateHandler({
            updateState: !updateState,
          }),
        );
      } else {
      }
    } catch (error) {}
  };
  const cancelAction = () => {
    dispatch(
      deleteHandler({
        delete: false,
      }),
    );
  };
  return (
    <div className={styles.container}>
      <img src={deleteIcon} alt="cancel icon" className={styles.cancelImage} />
      <Typography textWeight="600" textSize="18px" textAlign="left">
        Delete Time Shift
      </Typography>
      <div className={styles.mid_text}>
        <Typography textColor={colors.gray4} textAlign="left">
          Are you sure you want to delete this time shift?
        </Typography>
        <Typography
          textColor={colors.gray4}
          textAlign="left"
          marginVertical="-8px"
        >
          This action cannot be undone.
        </Typography>
      </div>
      <div className={styles.button_container}>
        <Button
          width="160px"
          text="Cancel"
          isPrimary={false}
          onClick={cancelAction}
          backgroundColor={colors.transparent}
          isPrimaryBorder={true}
        />
        <Button
          width="160px"
          text="Delete"
          isLoading={requestLoading}
          backgroundColor={colors.error}
          textColor={colors.white}
          isPrimary={false}
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};

export default DeleteShiftGeneration;
