import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  showEmployeeContactModal: false,
  showSendMessageModal: false,
  employeeContact: {
    id: "",
    name: "",
    mobileNumber: "",
    email: "",
  },
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    setShowEmployeeContactModal: (state, action: PayloadAction<boolean>) => {
      state.showEmployeeContactModal = action.payload;
    },
    setShowSendMessageModal: (state, action: PayloadAction<boolean>) => {
      state.showSendMessageModal = action.payload;
    },
    setEmployeeContact: (state, action: PayloadAction<any>) => {
      state.employeeContact = action.payload;
    },
  },
});
export const {
  setShowEmployeeContactModal,
  setShowSendMessageModal,
  setEmployeeContact,
} = statisticsSlice.actions;

export const getShowEmployeeContactModal = (state: RootState) =>
  state.statistics.showEmployeeContactModal;
export const getShowSendMessageModal = (state: RootState) =>
  state.statistics.showSendMessageModal;
export const getEmployeeContact = (state: RootState) =>
  state.statistics.employeeContact;

export default statisticsSlice.reducer;
