const filterKeysFromArray = (array: any[], keyName: string): Array<string> => {
  const keys: Array<string> = [];
  array.forEach((item) => {
    if (keys.includes(item[keyName])) return;
    keys.push(item[keyName]);
  });
  return keys;
};

export default filterKeysFromArray;
