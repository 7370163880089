import React from "react";
import Typography from "../Typography";
import warningIcon from "../../../assets/svg/warning-icon.svg";
import styles from "./index.module.css";
import Button from "../../Form/Button";
import colors from "../../../theme/colors";
import { useNavigate } from "react-router-dom";

const SessionExpiredModal: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.main_container}>
      <div className={styles.container}>
        <div className={styles.top_container}>
          <img
            src={warningIcon}
            alt="cancel icon"
            className={styles.cancelImage}
          />
          <Typography textWeight="600" textSize="18px" textAlign="left">
            Your Session Has Expired!
          </Typography>
        </div>
        <div className={styles.mid_text}>
          <Typography textColor={colors.gray4} textAlign="left">
            Oops! It seems your session has expired. No worries – to continue,
            please log in again.
          </Typography>
        </div>
        <div className={styles.button_container}>
          <Button
            width="100%"
            text="Log In"
            textColor={colors.white}
            isPrimary={true}
            textWeight="500"
            onClick={() => navigate("/login")}
          />
        </div>
      </div>
    </div>
  );
};

export default SessionExpiredModal;
