import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import Button from "../../Form/Button";
import { useNavigate } from "react-router-dom";

const UpdateSocialAccountModal = () => {
  const navigate = useNavigate();
  return (
    <section className={styles.container}>
      <div className={styles.content_container}>
        <Typography textWeight="600" textSize="18px">
          You've been Glixed!
        </Typography>
        <div className={styles.midtext_container}>
          <Typography textAlign="left">
            We're thrilled to have you on board! 🚀
          </Typography>
          <Typography textAlign="left" style={{ lineHeight: "5px" }}>
            Complete your profile to unlock the full Glix
          </Typography>
          <Typography textAlign="left">experience.</Typography>
        </div>

        <Button
          onClick={() => navigate("/update-account")}
          paddingVertical="5px"
          marginVertical="10px"
          text="Get Started"
          isPrimary={true}
        />
      </div>
    </section>
  );
};

export default UpdateSocialAccountModal;
