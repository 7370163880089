import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import { getRequest, patchRequest, postRequest, putRequest } from "api/request";
import {
  CREATE_EMPLOYEE_URL,
  GET_ALL_EMPLOYEE_URL,
  UPDATE_EMPLOYEE_BASICINFO_URL,
  UPDATE_EMPLOYEE_IDENTIFICATION_URL,
  UPDATE_EMPLOYEE_VISA_URL,
} from "api/urls";
import { useNavigate } from "react-router-dom";
import {
  getCountry,
  getCurrentEmployee,
  setCurrentEmployee,
} from "utils/localstorage";
import EmployeeBasicInfoFormUk from "components/StaffHub/Employee/BasicInformation/EmployeeBasicInfoFormUk";
import EmployeeBasicInfoForm from "components/StaffHub/Employee/BasicInformation/EmployeeBasicInfoForm";
import toast from "react-hot-toast";
import validator from "utils/validateFields";
import { NG, UK } from "types-definition/constants";
import generatePassword from "utils/generatePassword";
import capitalize from "utils/capitalize";
import ScrollToTop from "components/Common/ScrollToTop";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  basicInformationFormData,
  basicInformationIdentificationFormDataNonUk,
  basicInformationRequiredFormData,
  basicInformationRequiredIdentificationFormDataBirthCertificateUk,
  basicInformationRequiredIdentificationFormDataOthersNonUk,
  basicInformationRequiredIdentificationFormDataUk,
  setBasicInformationFormData,
  setBasicInformationRequiredFormData,
  setBasicInformationRequiredIdentificationFormDataNonUk,
  setBasicInformationRequiredIdentificationFormDataOthersNonUk,
  setBasicInformationRequiredIdentificationFormDataUk,
} from "../../../../redux/slice/staff-hub/employeeBasicInfoSlice";
import { toastError, toastSuccess } from "utils/toast";
import scrolltoView from "utils/scrollToView";
import { updateSetupComplete } from "utils/setupWizard";

const EmployeeBasicInfo = () => {
  const navigate = useNavigate();
  const country = getCountry();
  const dispatch = useAppDispatch();
  const formData = useAppSelector(basicInformationFormData);
  const requiredFormData = useAppSelector(basicInformationRequiredFormData);
  const requiredBirthCertificateIdentificationFormDataUk = useAppSelector(
    basicInformationRequiredIdentificationFormDataBirthCertificateUk,
  );
  const requiredIdentificationFormDataUk = useAppSelector(
    basicInformationRequiredIdentificationFormDataUk,
  );
  const requiredIdentificationFormDataNonUk = useAppSelector(
    basicInformationIdentificationFormDataNonUk,
  );
  const requiredIdentificationFormDataOthersNonUk = useAppSelector(
    basicInformationRequiredIdentificationFormDataOthersNonUk,
  );
  const [requestLoading, setRequestLoading] = useState(false);

  const handleSubmitForm = async () => {
    if (!formData.documentType && country === UK) {
      dispatch(
        setBasicInformationRequiredIdentificationFormDataUk({
          ...requiredIdentificationFormDataUk,
          documentType: true,
        }),
      );
      dispatch(
        setBasicInformationRequiredIdentificationFormDataNonUk({
          ...requiredIdentificationFormDataNonUk,
          documentType: true,
        }),
      );
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    // get current Employee
    const currentEmployee = getCurrentEmployee();
    // validate form for general required fields
    const { status, data, id } = validator(formData, requiredFormData);
    if (!status) {
      dispatch(
        setBasicInformationRequiredFormData({
          ...requiredFormData,
          ...data,
        }),
      );
      if (id) {
        scrolltoView(id);
      }
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    if (country !== NG) {
      // validate form for uk citizen required fields
      if (formData.ukCitizen) {
        if (formData.documentType !== "None") {
          if (formData.documentType === "Others") {
            const { status, data } = validator(
              formData,
              requiredIdentificationFormDataOthersNonUk,
            );
            if (!status) {
              dispatch(
                setBasicInformationRequiredIdentificationFormDataOthersNonUk({
                  ...requiredIdentificationFormDataOthersNonUk,
                  ...data,
                }),
              );
              toast.error("Fill required fields", {
                duration: 4000,
                position: "top-center",
              });
              return;
            }
          } else if (formData.documentType === "Birth Certificate") {
            const { status, data } = validator(
              formData,
              requiredBirthCertificateIdentificationFormDataUk,
            );
            if (!status) {
              dispatch(
                setBasicInformationRequiredIdentificationFormDataUk({
                  ...requiredIdentificationFormDataUk,
                  ...data,
                }),
              );
              toast.error("Fill required fields", {
                duration: 4000,
                position: "top-center",
              });
              return;
            }
          } else {
            const { status, data } = validator(
              formData,
              requiredIdentificationFormDataUk,
            );
            if (!status) {
              dispatch(
                setBasicInformationRequiredIdentificationFormDataUk({
                  ...requiredIdentificationFormDataUk,
                  ...data,
                }),
              );
              toast.error("Fill required fields", {
                duration: 4000,
                position: "top-center",
              });
              return;
            }
          }
        }
      } else {
        // validate form for non uk citizen required fields
        if (formData.documentType !== "None") {
          // Validate for others field
          const { status, data, id } = validator(
            formData,
            requiredIdentificationFormDataNonUk,
          );
          if (!status) {
            dispatch(
              setBasicInformationRequiredIdentificationFormDataNonUk({
                ...requiredIdentificationFormDataNonUk,
                ...data,
              }),
            );
            if (id) {
              scrolltoView(id);
            }
            toast.error("Fill required fields", {
              duration: 4000,
              position: "top-center",
            });
            return;
          }
        }
      }
    }
    // Start Request if validation is successful
    setRequestLoading(true);
    try {
      const data = new FormData();
      data.append("firstName", formData.firstName);
      data.append("lastName", formData.lastName);
      data.append("username", formData.username.toLowerCase());
      data.append("password", formData.password);
      data.append("nationality", formData.nationality);
      data.append("isActive", formData.active.toString());
      data.append("portalAccess", formData.portalAccess.toString());
      data.append("mobileNumber", formData.mobileNumber);
      data.append("email", formData.email);
      if (formData.gender) {
        data.append("gender", formData.gender.toLowerCase());
      }
      if (formData.dob) {
        data.append("dob", formData.dob);
      }
      if (formData.startDate) {
        data.append("startDate", formData.startDate);
      }
      if (formData.endDate) {
        data.append("endDate", formData.endDate);
      }
      if (formData.photo instanceof File) {
        data.append("photo", formData.photo);
      }
      if (formData.proofOfId instanceof File) {
        data.append("proofOfId", formData.proofOfId);
      }
      let response;
      if (currentEmployee) {
        response = await putRequest(
          `${UPDATE_EMPLOYEE_BASICINFO_URL}/${formData.username.toLowerCase()}`,
          data,
        );
      } else {
        response = await postRequest(CREATE_EMPLOYEE_URL, data);
      }

      if (response?.success) {
        setCurrentEmployee(
          JSON.stringify({
            name: formData.username.toLowerCase(),
            country: formData.nationality,
          }),
        );
        let response;

        if (country !== NG) {
          if (formData.ukCitizen) {
            if (
              formData.documentType === "None" ||
              formData.documentType === ""
            ) {
              response = await putRequest(
                `${UPDATE_EMPLOYEE_IDENTIFICATION_URL}/${formData.username.toLowerCase()}`,
                {
                  documentType: formData.documentType.toLowerCase(),
                  citizenshipStatus: "citizen",
                  documentName: "",
                  documentNumber: "",
                  issueDate: "",
                  expiryDate: "",
                  placeOfIssue: "",
                  // countryOfCitizenship: "",
                },
              );
            } else if (formData.documentType === "Others") {
              response = await putRequest(
                `${UPDATE_EMPLOYEE_IDENTIFICATION_URL}/${formData.username.toLowerCase()}`,
                {
                  documentType: formData.documentType.toLowerCase(),
                  citizenshipStatus: "citizen",
                  documentName: formData.documentName,
                  documentNumber: formData.documentNumber,
                  issueDate: formData.issueDate,
                  expiryDate: formData.expiryDate,
                  placeOfIssue: formData.placeOfIssue,
                  // countryOfCitizenship: "",
                },
              );
            } else {
              response = await putRequest(
                `${UPDATE_EMPLOYEE_IDENTIFICATION_URL}/${formData.username.toLowerCase()}`,
                {
                  documentType: formData.documentType.toLowerCase(),
                  citizenshipStatus: "citizen",
                  documentNumber: formData.documentNumber,
                  issueDate: formData.issueDate,
                  expiryDate: formData.expiryDate,
                  placeOfIssue: formData.placeOfIssue,
                  // countryOfCitizenship: "",
                },
              );
            }
          } else {
            if (
              formData.documentType === "None" ||
              formData.documentType === ""
            ) {
              response = await putRequest(
                `${UPDATE_EMPLOYEE_IDENTIFICATION_URL}/${formData.username.toLowerCase()}`,
                {
                  documentType: formData.documentType.toLowerCase(),
                  citizenshipStatus: "non citizen",
                  countryOfCitizenship: "",
                  documentName: "",
                  documentNumber: "",
                  issueDate: "",
                  expiryDate: "",
                  placeOfIssue: "",
                },
              );
            } else {
              response = await putRequest(
                `${UPDATE_EMPLOYEE_IDENTIFICATION_URL}/${formData.username.toLowerCase()}`,
                {
                  documentType: formData.documentType.toLowerCase(),
                  citizenshipStatus: "non citizen",
                  documentNumber: formData.documentNumber,
                  issueDate: formData.issueDate,
                  expiryDate: formData.expiryDate,
                  placeOfIssue: formData.placeOfIssue,
                  countryOfCitizenship: formData.countryOfCitizenship,
                },
              );
            }
          }
          if (response?.success) {
            // formData.ukCitizen
            //     ? {
            //         visaType: "",
            //         visaNumber: "",
            //         issueDate: "",
            //         expiryDate: "",
            //       }
            //     :
            if (formData.ukCitizen) {
              toastSuccess(
                `Employee Basic Information ${
                  currentEmployee ? "Updated" : "Created"
                }`,
              );
              await updateSetupComplete("8", false, "");
              navigate(`/employee/contact-info`);
              return;
            } else {
              const response = await patchRequest(
                `${UPDATE_EMPLOYEE_VISA_URL}/${formData.username.toLowerCase()}`,
                {
                  visaType: formData.visaType,
                  visaNumber: formData.visaNumber,
                  issueDate: formData.visaStartDate,
                  expiryDate: formData.visaExpiryDate,
                },
              );
              if (response?.success) {
                toastSuccess(
                  `Employee Basic Information ${
                    currentEmployee ? "Updated" : "Created"
                  }`,
                );
                await updateSetupComplete("8", false, "");
                navigate(`/employee/contact-info`);
                return;
              } else {
                toast.error(response?.data, {
                  duration: 4000,
                  position: "top-center",
                });
              }
            }
          } else {
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
          }
        } else {
          toastSuccess(
            `Employee Basic Information ${
              currentEmployee ? "Updated" : "Created"
            }`,
          );
          navigate(`/employee/contact-info`);
        }
      } else {
        toastError(response?.data);
      }
    } finally {
      setRequestLoading(false);
    }
  };
  const handleFormCancelation = () => {
    navigate("/staff-hub");
  };

  useEffect(() => {
    // prefill data if found in server
    const getEmployee = async () => {
      const data = getCurrentEmployee();
      if (data !== undefined && data !== null) {
        const username = JSON.parse(data).name;
        const response = await getRequest(
          `${GET_ALL_EMPLOYEE_URL}/${username}`,
        );
        if (response?.success) {
          dispatch(
            setBasicInformationFormData({
              ...formData,
              active: response.data.basicInformation.isActive ?? false,
              dob: response.data.basicInformation.dob ?? "",
              endDate: response.data.basicInformation.endDate ?? "",
              firstName: response.data.basicInformation.firstName ?? "",
              gender: capitalize(response.data.basicInformation.gender) ?? "",
              lastName: response.data.basicInformation.lastName ?? "",
              nationality: response.data.basicInformation.nationality ?? "",
              password:
                response.data.basicInformation.password ?? generatePassword(8),
              portalAccess:
                response.data.basicInformation.portalAccess ?? false,
              startDate: response.data.basicInformation.startDate ?? "",
              username: response.data.basicInformation.username ?? "",
              usernameSavedInDB: response.data.basicInformation.username
                ? true
                : false,
              mobileNumber: response.data.mobileNumber ?? "",
              email: response.data.email ?? "",
              photo: response.data.employeePhoto?.photo
                ? {
                    name: response.data.employeePhoto.originalName,
                    URL: response.data.employeePhoto.photo,
                    size: response.data.employeePhoto.size,
                    lastModifiedDate: response.data.employeePhoto.createdAt,
                  }
                : "",
              proofOfId: response.data.employeeProofOfId?.proofOfId
                ? {
                    name: response.data.employeeProofOfId.originalName,
                    URL: response.data.employeeProofOfId.proofOfId,
                    size: response.data.employeeProofOfId.size,
                    lastModifiedDate: response.data.employeeProofOfId.createdAt,
                  }
                : "",
              ukCitizen:
                response.data?.identificationDocument?.citizenshipStatus ===
                "citizen"
                  ? true
                  : false,
              documentType: response.data?.identificationDocument?.documentType
                ? capitalize(
                    response.data?.identificationDocument?.documentType,
                  )
                : "None",
              documentNumber:
                response.data?.identificationDocument?.documentNumber ?? "",
              issueDate: response.data?.identificationDocument?.issueDate ?? "",
              expiryDate:
                response.data?.identificationDocument?.expiryDate ?? "",
              placeOfIssue:
                response.data?.identificationDocument?.placeOfIssue ?? "",
              documentName:
                response.data?.identificationDocument?.documentName ?? "",
              countryOfCitizenship:
                response.data?.identificationDocument?.countryOfCitizenship ??
                "",
              visaStartDate: response.data?.visaInformation?.issueDate ?? "",
              visaExpiryDate: response.data?.visaInformation?.expiryDate ?? "",
              visaNumber: response.data?.visaInformation?.visaNumber ?? "",
              visaType: response.data?.visaInformation?.visaType ?? "",
            }),
          );
          setCurrentEmployee(
            JSON.stringify({
              name: username,
              country: response.data.contactDetail.country,
            }),
          );
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      }
    };
    getEmployee();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="staff/hub" />
      {country !== NG ? (
        <div className={styles.container}>
          <EmployeeBasicInfoFormUk
            handleFormCancelation={handleFormCancelation}
            requestLoading={requestLoading}
            handleSubmit={handleSubmitForm}
          />
        </div>
      ) : (
        <div className={styles.container}>
          <EmployeeBasicInfoForm
            handleFormCancelation={handleFormCancelation}
            requestLoading={requestLoading}
            handleSubmit={handleSubmitForm}
          />
        </div>
      )}
    </section>
  );
};

export default EmployeeBasicInfo;
