import React, { useEffect, useState } from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import { getRequest } from "../../../../api/request";
import { TimeShiftGenerationTableItemData } from "../../../../types-definition/admin";
import TableEditDropdown from "../../../Common/TableEditDropdown";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  deleteHandler,
  updateTimeShiftGenerationModal,
} from "../../../../redux/slice/admin/timeShiftGeneration";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import {
  getData,
  getUserTypes,
  setTimeShiftGeneration,
} from "../../../../utils/localstorage";
import moment from "moment";
import checkAdminPermission from "utils/permission";
import { SHIFT_DELETION } from "types-definition/constants";
const TimeShiftGenerationTableItem: React.FC<
  TimeShiftGenerationTableItemData
> = ({
  id,
  createdAt,
  from,
  to,
  location,
  service,
  requestedBy,
  type,
  sectionId,
}) => {
  const dispatch = useAppDispatch();
  const user = JSON.parse(getData() ?? "");
  const [deletePermission, setDeletePermission] = useState(true);

  const editHandler = () => {
    setTimeShiftGeneration(id);
    dispatch(
      updateTimeShiftGenerationModal({
        showTimeShiftGenerationModal: true,
      }),
    );
  };
  const deleteHandle = () => {
    setTimeShiftGeneration(sectionId);
    dispatch(deleteHandler({ delete: true }));
  };
  useEffect(() => {
    const getPermission = async () => {
      const data = checkAdminPermission(getUserTypes());
      if (data) {
        setDeletePermission(false);
      } else {
        const response = await getRequest(
          `/settings/user/${user?.id}/permission`,
        );
        const permissions: Array<string> = response?.data.map(
          (item: any) => item.name,
        );
        const deleteResult = permissions?.some((p) =>
          [SHIFT_DELETION].includes(p),
        );
        setDeletePermission(deleteResult ? false : true);
      }
    };
    getPermission();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showEditDropdown, setshowEditDropdown] = useState(false);
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left">
          {/* {moment(createdAt).format("DD/MM/YYYY")} */}
          {requestedBy}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {moment(createdAt).format("DD/MM/YYYY")}
        </Typography>
        <Typography textAlign="left">
          {moment(createdAt).format("LT")}
        </Typography>
      </td>
      <td className={styles.td_mail}>
        <Typography textAlign="left">
          {moment(from).format("DD/MM/YYYY")}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {moment(to).format("DD/MM/YYYY")}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">Generate Roster</Typography>
      </td>

      <td className={styles.td}>
        {/* {location?.map((item: any, index) => ( */}
        <Typography textAlign="left" marginVertical="1px">
          {type}
        </Typography>
        {/* ))} */}
      </td>
      <td className={styles.td}>
        {/* {service?.map((item: any, index) => ( */}
        <Typography textAlign="left" marginVertical="1px">
          {service}
        </Typography>
        {/* ))} */}
      </td>
      <td
        className={`${styles.td} ${styles.td_relative}`}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        {showEditDropdown && (
          <TableEditDropdown
            data={
              deletePermission
                ? [
                    {
                      label: "Edit",
                      action: () => {
                        editHandler();
                      },
                    },
                  ]
                : [
                    {
                      label: "Edit",
                      action: () => {
                        editHandler();
                      },
                    },
                    {
                      label: "Delete",
                      action: () => {
                        deleteHandle();
                      },
                    },
                  ]
            }
          />
        )}
        <div
          className={styles.image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};
export default TimeShiftGenerationTableItem;
