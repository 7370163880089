import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import Checkbox from "../../../Form/Checkbox";
import { CheckpointActionFormProps } from "../../../../types-definition/client";
import RadioInput from "components/Form/RadioInput";
import TextInput from "components/Form/TextInput";

const AddCheckpointActionForm: React.FC<CheckpointActionFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  return (
    <div>
      <Typography textWeight="600" textAlign="left">
        Action when a checkpoint is triggered
      </Typography>
      <RadioInput
        marginVertical="0px"
        label={
          <div>
            <Typography
              textAlign="left"
              textWeight="500"
              marginHorizontal="5px"
              marginVertical="0px"
            >
              No action
            </Typography>
          </div>
        }
        value={!formData.action}
        name="action"
        setValue={(data) =>
          setFormData((prevData) => {
            return { ...prevData, action: false };
          })
        }
      />
      <RadioInput
        marginVertical="0px"
        label={
          <div>
            <Typography
              textAlign="left"
              textWeight="500"
              marginHorizontal="5px"
              marginVertical="0px"
            >
              Answer question
            </Typography>
          </div>
        }
        value={formData.action}
        name="action"
        setValue={(data) =>
          setFormData((prevData) => {
            return {
              ...prevData,
              action: true,
              question: "Is everything Okay?",
            };
          })
        }
      />
      {formData.action && (
        <div className={styles.action_container}>
          <Checkbox
            marginVertical="0px"
            label={
              <div>
                <Typography
                  textAlign="left"
                  textWeight="500"
                  marginHorizontal="-2px"
                  marginVertical="0px"
                >
                  Action Mandatory
                </Typography>
              </div>
            }
            value={formData.actionMandatory}
            setValue={(data) =>
              setFormData((prevData) => {
                return {
                  ...prevData,
                  actionMandatory: !prevData.actionMandatory,
                };
              })
            }
          />
          <TextInput
            placeholder="is everything okay?"
            value={formData.question}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, question: data };
              });
              setRequiredFormData((prevData) => {
                return { ...prevData, question: { status: false, text: "" } };
              });
            }}
            label="Question"
            error={requiredFormData.question.status}
            helperText={requiredFormData.question.text}
          />
        </div>
      )}
    </div>
  );
};

export default AddCheckpointActionForm;
