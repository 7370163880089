import React from "react";
import Typography from "../../../../Common/Typography";
import styles from "./index.module.css";
import colors from "../../../../../theme/colors";
import LinkToLocationTableItem from "../LinkToLocationTableItem";
import { LinkToLocationProps } from "../../../../../types-definition/StaffHub/employee/linkToLocation";
const EmployeeLocationTable: React.FC<LinkToLocationProps> = ({ data }) => {
  return (
    <>
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.tr} ${styles.tr_head}`}>
              <th
                className={styles.th}
                style={{
                  width: "55%",
                }}
              >
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  marginHorizontal="20px"
                >
                  Location
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                  marginHorizontal="20px"
                >
                  Distance (miles)
                </Typography>
              </th>
              <th className={styles.th_index}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <LinkToLocationTableItem {...item} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EmployeeLocationTable;
