import React, { useEffect, useState } from "react";
import { SearchEmployeeProps } from "../../../../types-definition/StaffHub/employee";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import size from "../../../../theme/size";
import selectIcon from "../../../../assets/svg/select-dropdown.svg";
import colors from "../../../../theme/colors";
import { getRequest } from "../../../../api/request";
import { GET_ALL_EMPLOYEE_URL } from "../../../../api/urls";
import { toastError } from "../../../../utils/toast";
import { setEmployeeId } from "utils/localstorage";

const SearchEmployee: React.FC<SearchEmployeeProps> = ({
  label,
  placeholder,
  value,
  setValue,
  height = "200px",
  width,
  datalistTop = "91px",
  dropdownTop = "55px",
  required = false,
  error = false,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [options, setOptions] = useState<
    Array<{ name: string; username: string; id: string }>
  >([]);
  const [isSelected, setIsSelected] = useState(false);
  const [list, setList] = useState<
    Array<{ id: string; name: string; username: string }>
  >([]);
  const handleListSelector = (item: { name: string; username: string }) => {
    setValue({ ...item, sendRequest: true });
    setIsSelected(true);
    setShowDropDown(false);
  };
  const filterListFromString = (input: string) => {
    if (input !== "" && input !== undefined) {
      const newList = options.filter((item) =>
        item.name.toLowerCase().includes(input.toLowerCase()),
      );
      setValue({ name: input, username: "", sendRequest: false });
      setList(newList);
      setIsSelected(false);
    } else {
      setIsSelected(false);
      setValue({ name: "", username: "", sendRequest: false });
      setList(options);
    }
  };
  useEffect(() => {
    if (!isSelected && !showDropDown) {
      setValue("");
      setList(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, showDropDown]);
  useEffect(() => {
    setList(options);
  }, [options]);
  useEffect(() => {
    const getAllEmployees = async () => {
      const response = await getRequest(GET_ALL_EMPLOYEE_URL);
      if (response?.success) {
        const data = response?.data?.map((item: any) => {
          return {
            name: `${item?.basicInformation?.firstName} ${item?.lastName}`,
            username: item?.username,
            id: item?.id,
          };
        });
        setOptions(data);
      } else {
        if (response?.data) toastError(response?.data);
      }
    };
    getAllEmployees();
  }, []);
  return (
    <section className={styles.container}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        className={styles.select}
        onChange={(e) => {
          if (!showDropDown) {
            setShowDropDown(true);
          }
          filterListFromString(e.target.value);
        }}
        onFocus={() => setShowDropDown(true)}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 500);
        }}
        style={{ width, borderColor: error ? colors.error : colors.gray3 }}
      />
      <div
        className={styles.dropdown_container}
        style={{ top: dropdownTop }}
        tabIndex={0}
        onClick={() => setShowDropDown(true)}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 400);
        }}
      >
        <img src={selectIcon} className={styles.dropdown} alt="dropdown icon" />
      </div>
      {showDropDown && (
        <div
          className={styles.datalist}
          style={{ maxHeight: height, top: datalistTop }}
        >
          {list.length === 0 ? (
            <p>No data</p>
          ) : (
            list.map((item) => {
              return (
                <p
                  onClick={() => {
                    handleListSelector(item);
                    setEmployeeId(JSON.stringify({ id: item?.id }));
                  }}
                  key={item.username}
                >
                  {item.name}
                </p>
              );
            })
          )}
        </div>
      )}
    </section>
  );
};

export default SearchEmployee;
