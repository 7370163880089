import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import colors from "../../../theme/colors";
import Divider from "../Divider";
import elementDescriptionIcon from "../../../assets/svg/elements-empty.svg";

const DashboardItem: React.FC = () => {
  return (
    <section className={styles.container}>
      <Typography
        marginHorizontal="30px"
        textSize="16px"
        textWeight="600"
        textAlign="left"
        fontFamily="inter"
        textColor={colors.black}
        marginVertical="20px"
      >
        Client Management
      </Typography>
      <Divider width="100%" color="#D0D5DD" />
      <div className={styles.content}>
        <img src={elementDescriptionIcon} alt="icon" width={40} height={40} />
        <div className={styles.midcontent_container}>
          <Typography textColor={colors.gray1}>
            No Client Record Found
          </Typography>
        </div>
        <div className={styles.pointer_cursor}>
          <Typography
            textWeight="600"
            style={{
              background: "linear-gradient(#006093, #00dcda)",
              WebkitTextFillColor: "transparent",
              WebkitBackgroundClip: "text",
            }}
          >
            {" "}
            + Add Client{" "}
          </Typography>
        </div>
      </div>
    </section>
  );
};

export default DashboardItem;
