import { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import { Link, useParams } from "react-router-dom";
import glixLogo from "assets/svg/glix-logo.svg";
import { getRequest } from "api/request";
import { VERIFY_MAIL_URL } from "api/urls";
import PageLoader from "components/Common/PageLoader";

const VerifyMail = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  useEffect(() => {
    const getVerifyMailStatus = async () => {
      const response = await getRequest(
        `${VERIFY_MAIL_URL}/${params.id}/${params.token}`,
      );
      if (response?.success) {
        setIsSuccess(true);
        setIsLoading(false);
      } else {
        setIsSuccess(false);
        setIsLoading(false);
      }
    };
    getVerifyMailStatus();
  });
  return (
    <section>
      {isLoading && <PageLoader />}
      <div className={styles.top_container}>
        <img src={glixLogo} alt="glix logo" width={56} height={56} />
      </div>
      {isSuccess ? (
        <div className={styles.text_container}>
          <Typography textAlign="left" textWeight="600" textSize="20px">
            Congratulations! 🌟 Your email verification is complete!
          </Typography>
          <Typography
            textAlign="left"
            textSize="16px"
            style={{ lineHeight: "15px" }}
          >
            Granting you secure access to our platform's full range of awesome
            features
          </Typography>
          <Typography
            textAlign="left"
            textSize="16px"
            style={{ lineHeight: "15px" }}
          >
            Ready to get started?
          </Typography>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Typography
              textWeight="600"
              textAlign="left"
              textSize="18px"
              style={{
                background: "linear-gradient(#006093, #00dcda)",
                WebkitTextFillColor: "transparent",
                WebkitBackgroundClip: "text",
                marginTop: "20px",
              }}
            >
              Click here to sign in and explore
            </Typography>
          </Link>
        </div>
      ) : (
        <div className={styles.text_container}>
          <Typography textAlign="left" textWeight="600" textSize="20px">
            We're Sorry, Your Email Verification Failed
          </Typography>
          <Typography
            textAlign="left"
            textSize="16px"
            style={{ lineHeight: "15px" }}
          >
            We couldn't verify your email address, and your account remains
          </Typography>
          <Typography
            textAlign="left"
            textSize="16px"
            style={{ lineHeight: "15px" }}
          >
            unverified. Please double-check the verification link you used or
          </Typography>
          <Typography
            textAlign="left"
            textSize="16px"
            style={{ lineHeight: "15px" }}
          >
            request a new verification email.
          </Typography>
        </div>
      )}
    </section>
  );
};

export default VerifyMail;
