import React from "react";
import styles from "./index.module.css";
import TextInput from "../../../../Form/TextInput";
import SelectInput from "../../../../Form/SelectInput";
import { EmployeeHrFormProps } from "../../../../../types-definition/StaffHub/employee";
const EmployeeHrForm: React.FC<EmployeeHrFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.employeeNo}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, employeeNo: data };
            })
          }
          label="Employee Number"
          placeholder="Enter Employee Number"
        />
        <TextInput
          value={formData.tin}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, tin: data };
            })
          }
          label="Tax Identification Number"
          placeholder="Enter Tax Identification Number"
        />
      </div>

      <div className={styles.form_flex_container}>
        <SelectInput
          value={formData.payGrade}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, payGrade: data };
            });

            setRequiredFormData((prevData) => {
              return { ...prevData, payGrade: false };
            });
          }}
          label="Pay Grade"
          placeholder="Select Grade"
          options={["Grade 1", "Grade 2"]}
          required={true}
          error={requiredFormData.payGrade}
          helperText={requiredFormData.payGrade ? "Pay Grade is Required" : ""}
          readonly={true}
          id="payGrade"
        />
        <SelectInput
          value={formData.payrollGroup}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, payrollGroup: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, payrollGroup: false };
            });
          }}
          label="Payroll Group"
          placeholder="Select Group"
          options={["Group A", "Group B"]}
          required={true}
          error={requiredFormData.payrollGroup}
          helperText={
            requiredFormData.payrollGroup ? "Payroll Group is Required" : ""
          }
          readonly={true}
          id="payrollGroup"
        />
      </div>
      <div className={styles.form_flex_container}>
        <SelectInput
          value={formData.holidayMethod}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, holidayMethod: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, holidayMethod: false };
            });
          }}
          label="Holiday Calculation Method"
          placeholder="Select Holiday Calculation Method"
          options={["Entitlement Days", "Accrued Holidays"]}
          required={true}
          error={requiredFormData.holidayMethod}
          helperText={
            requiredFormData.holidayMethod ? "Holiday method is Required" : ""
          }
          id="holidayMethod"
        />
      </div>
    </div>
  );
};

export default EmployeeHrForm;
