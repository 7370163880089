import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import ActiveState from "../../../Common/ActiveState";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import { WebUserProfileData } from "../../../../types-definition/admin";
import WebUserTableDropdown from "../WebUserTableDropDown";
import ClientState from "../ClientState";
import colors from "../../../../theme/colors";
import capitalize from "../../../../utils/capitalize";

const WebUserProfileTableItem: React.FC<WebUserProfileData> = ({
  id,
  email,
  status,
  userName,
  clientPortalUser,
  fullName,
  clientName,
  clientId,
}) => {
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  const handleHideModal = () => {
    setshowEditDropdown(false);
  };

  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left" textSize="14px">
          {fullName ?? "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography
          textAlign="left"
          textColor={colors.primary3}
          textSize="14px"
        >
          {userName ? capitalize(userName) : "N/A"}
        </Typography>
      </td>
      <td className={styles.td_mail}>
        <Typography textAlign="left" textSize="14px">
          {email ?? "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        {clientName ? (
          <Typography textAlign="left" textSize="14px">
            {clientName}
          </Typography>
        ) : (
          <Typography textAlign="left" textSize="14px">
            N/A
          </Typography>
        )}
      </td>
      <td className={styles.td_image}>
        <ClientState active={clientPortalUser} />
      </td>
      <td className={styles.td_status}>
        <ActiveState active={status} />
      </td>
      <td
        className={styles.td}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        <div className={styles.td_relative}>
          {showEditDropdown && (
            <WebUserTableDropdown id={id} handleHideModal={handleHideModal} />
          )}
        </div>
        <div
          className={styles.image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default WebUserProfileTableItem;
