import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, patchRequest, postRequest } from "api/request";
import {
  CREATE_CERTIFICATE_GROUP_URL,
  GET_CERTIFICATE_GROUP_URL,
} from "api/urls";
import { useNavigate } from "react-router-dom";
import {
  CertificateGroupFormData,
  CertificateGroupRequiredFormData,
} from "types-definition/StaffHub/certificateGroup";
import toast from "react-hot-toast";
import validator from "utils/validateFields";
import ScrollToTop from "components/Common/ScrollToTop";
import AddCertificateGroupForm from "components/StaffHub/CertificateGroup/AddCertificateForm";
import { getCertificateGroup, getUserTypes } from "utils/localstorage";
import { toastError, toastSuccess } from "utils/toast";
import { updateSetupComplete } from "utils/setupWizard";

const AddCertificateGroup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<CertificateGroupFormData>({
    certificateName: "",
    description: "",
    active: true,
  });

  const [requiredFormData, setRequiredFormData] =
    useState<CertificateGroupRequiredFormData>({
      certificateName: false,
    });
  const [requestLoading, setRequestLoading] = useState(false);

  const handleSubmitForm = async () => {
    const { status, data } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    setRequestLoading(true);
    try {
      let response;
      const data = getCertificateGroup();
      if (data) {
        response = await patchRequest(
          `${CREATE_CERTIFICATE_GROUP_URL}/${data}`,
          formData,
        );
      } else {
        response = await postRequest(CREATE_CERTIFICATE_GROUP_URL, formData);
      }
      if (response?.success) {
        toastSuccess(`Certificate Group ${data ? "Updated" : "Created"}`);
        const setUpComplete = await updateSetupComplete(
          "6",
          false,
          "certificategroup",
        );

        const userType = getUserTypes();
        if (!setUpComplete && userType !== "Web User") {
          navigate("/setup-wizard");
        } else {
          navigate("/staff-hub/certificate-group");
        }
      } else {
        toastError(response?.data);
      }
    } finally {
      setRequestLoading(false);
    }
  };
  const handleFormCancelation = () => {
    navigate("/staff-hub/certificate-group");
  };
  useEffect(() => {
    // prefill data if found
    const getCurrentCertificateGroup = async () => {
      try {
        const data = getCertificateGroup();
        if (data !== undefined && data !== null) {
          const response = await getRequest(
            `${GET_CERTIFICATE_GROUP_URL}/${data}`,
          );
          if (response?.success) {
            setFormData({
              certificateName: response.data.certificateName,
              description: response.data.description,
              active: response.data.active,
            });
          } else {
            if (response?.data)
              toast.error(response?.data, {
                duration: 4000,
                position: "top-center",
              });
          }
        }
      } finally {
      }
    };
    getCurrentCertificateGroup();
  }, []);
  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="staff/hub" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.content_container}>
            <Typography textAlign="left" textWeight="600" textSize="24px">
              Add Certificate Group
            </Typography>
            <AddCertificateGroupForm
              formData={formData}
              setFormData={setFormData}
              requiredFormData={requiredFormData}
              setRequiredFormData={setRequiredFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Cancel"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={true}
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddCertificateGroup;
