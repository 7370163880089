import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import Button from "components/Form/Button";
import searchIcon from "assets/svg/search-icon.svg";
import colors from "theme/colors";
import addIcon from "assets/svg/add-icon.svg";
import { useNavigate } from "react-router-dom";
import EmptyItemState from "components/Common/EmptyItemState";
import SuccessModal from "components/Common/SuccessModal";
import { CertificateGroupData } from "types-definition/StaffHub/certificateGroup";
import { getRequest } from "api/request";
import { GET_CERTIFICATE_GROUP_URL } from "api/urls";
import PageLoader from "components/Common/PageLoader";
import { deleteCertificateGroup } from "utils/localstorage";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import CertificateGroupTable from "components/StaffHub/CertificateGroup/CertificateTable";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getCertificateGroupCounter,
  getCertificateGroupData,
  getCertificateGroupSearchEmpty,
  getCertificateGroupSearchFetching,
  getCertificateGroupSearchValue,
  getShowAssignPermissionModal,
  getShowDeleteCertificateGroupModal,
  setCertificateGroupData,
  setSearchedCertificateGroupValue,
} from "../../../../redux/slice/staff-hub/certificateGroupSlice";
import AssignCertificateModal from "components/StaffHub/CertificateGroup/AssignCertificateModal";
import DeleteCertificateGroup from "components/StaffHub/CertificateGroup/DeleteCertificateGroup";
import CertificateGroupTableFilter from "components/StaffHub/CertificateGroup/CertificateGroupTableFilter";
import LoadingIcons from "react-loading-icons";

const CertificateGroupDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const showAssignModal = useAppSelector(getShowAssignPermissionModal);
  const showDeleteModal = useAppSelector(getShowDeleteCertificateGroupModal);
  const certificateGroupCounter = useAppSelector(getCertificateGroupCounter);
  const searchedValue = useAppSelector(getCertificateGroupSearchValue);
  const isSearchEmpty = useAppSelector(getCertificateGroupSearchEmpty);
  const searchLoading = useAppSelector(getCertificateGroupSearchFetching);
  const certificateGroupData = useAppSelector(getCertificateGroupData);
  const [requestLoading, setRequestLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  useEffect(() => {
    const getAllCertificateGroups = async () => {
      try {
        const response = await getRequest(GET_CERTIFICATE_GROUP_URL);
        if (response?.success) {
          const certificateGroupDataArray: Array<CertificateGroupData> = [];
          for (const index in response.data?.certificateGroups) {
            // extract certificate group table data
            const certificateGroupDataObject = {
              id: response.data?.certificateGroups[index].id,
              name: response.data?.certificateGroups[index].certificateName,
              description: response.data?.certificateGroups[index].description,
              active: response.data?.certificateGroups[index].active,
            };
            certificateGroupDataArray.push(certificateGroupDataObject);
          }
          dispatch(setCertificateGroupData(certificateGroupDataArray));
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      } finally {
        setRequestLoading(false);
      }
    };
    getAllCertificateGroups();
    deleteCertificateGroup();
  }, [certificateGroupCounter, dispatch]);
  useEffect(() => {
    let timeout: any;
    if (showSuccess) {
      timeout = setTimeout(() => setShowSuccess(false), 5000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [showSuccess]);

  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="staff/hub" />
        {showSuccess && (
          <SuccessModal
            text="Employee Added successfully"
            cancelFunction={() => {
              setShowSuccess(false);
            }}
          />
        )}
        <div className={styles.container}>
          <div
            className={
              certificateGroupData.length <= 5
                ? `${styles.item_container} ${styles.item_container_empty}`
                : `${styles.item_container} ${styles.item_container_fill}`
            }
          >
            <div className={styles.flex_item}>
              <div>
                <Typography textAlign="left" textWeight="600" textSize="20px">
                  Certificate Group
                </Typography>
                <Typography textAlign="left" textColor={colors.gray6}>
                  Add your certificate group to properly manage them
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <CertificateGroupTableFilter />
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={searchedValue}
                  setValue={(data) => {
                    dispatch(setSearchedCertificateGroupValue(data));
                  }}
                />
                <Button
                  left={<img src={addIcon} alt="add" />}
                  marginHorizontal="10px"
                  marginTop="8px"
                  paddingVertical="7px"
                  width="200px"
                  isPrimary
                  text="Certificate Group"
                  onClick={() => navigate("/staff-hub/certificate-group/form")}
                />
              </div>
            </div>
            {searchLoading ? (
              <div>
                <LoadingIcons.TailSpin
                  width={50}
                  height={50}
                  stroke={colors.primary1}
                  color={colors.primary1}
                  className={styles.loader_spinner}
                />
              </div>
            ) : isSearchEmpty ? (
              <></>
            ) : certificateGroupData?.length === 0 ? (
              <EmptyItemState
                text="You haven't added any certificate group yet"
                location="staff-hub/certificate-group/form"
                label="Certificate Group"
              />
            ) : (
              <CertificateGroupTable data={certificateGroupData} />
            )}
          </div>
        </div>
      </section>
      {showAssignModal && <AssignCertificateModal />}
      {showDeleteModal && <DeleteCertificateGroup />}
    </>
  );
};

export default CertificateGroupDashboard;
