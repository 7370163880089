import moment from "moment";

const convertTo24Hours = (timeStr: string) => {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = (parseInt(hours, 10) + 12).toString();
  }
  return `${hours}:${minutes}`;
};

const addAndSubtractTime = (action: "add" | "subtract", time: string) => {
  const firstPart = parseInt(time.split(":")[0]);
  if (firstPart === 0 && action === "subtract") {
    return "23:00";
  } else if (firstPart === 23 && action === "add") {
    return "00:00";
  } else if (action === "add") {
    if (firstPart + 1 > 9) {
      return `${firstPart + 1}:00`;
    } else {
      return `0${firstPart + 1}:00`;
    }
  } else if (action === "subtract") {
    if (firstPart - 1 > 9) {
      return `${firstPart - 1}:00`;
    } else {
      return `0${firstPart - 1}:00`;
    }
  }
};

const addAndSubtractTimeThirtyMins = (
  action: "add" | "subtract",
  time: string,
) => {
  const firstPart = parseInt(time.split(":")[0]);
  const lastPart = parseInt(time.split(":")[1]);
  if (firstPart === 0 && lastPart === 0 && action === "subtract") {
    return "23:30";
  } else if (firstPart === 23 && lastPart === 30 && action === "add") {
    return "00:00";
  } else if (action === "add") {
    if (lastPart === 30 && firstPart + 1 > 9) {
      return `${firstPart + 1}:00`;
    } else if (lastPart === 0 && firstPart + 1 > 9) {
      return `${firstPart}:30`;
    } else if (lastPart === 0 && firstPart + 1 < 9) {
      return `0${firstPart + 1}:30`;
    } else {
      return `0${firstPart + 1}:00`;
    }
  } else if (action === "subtract") {
    if (lastPart === 30 && firstPart + 1 > 9) {
      return `${firstPart}:00`;
    } else if (lastPart === 0 && firstPart + 1 > 9) {
      return `${firstPart - 1}:30`;
    } else if (lastPart === 0 && firstPart + 1 < 9) {
      return `0${firstPart - 1}:30`;
    } else {
      return `0${firstPart}:00`;
    }
  }
};

export const getTimeLength = (startTime: string, endTime: string) => {
  const startTimeFirstPart = parseInt(startTime.split(":")[0]);
  const endTimeFirstPart = parseInt(endTime.split(":")[0]);
  const startTimeEndPart = parseInt(startTime.split(":")[1]);
  const endTimeLastPart = parseInt(endTime.split(":")[1]);
  const wholeNumber = endTimeFirstPart - startTimeFirstPart;
  const halfNumber = endTimeLastPart - startTimeEndPart;
  return wholeNumber * 53 + (halfNumber / 60) * 25;
};

export const getTimeTopLength = (startTime: string) => {
  const startTimeFirstPart = parseInt(startTime.split(":")[0]);
  const startTimeEndPart = parseInt(startTime.split(":")[1]);
  const wholeNumber = startTimeFirstPart;
  const halfNumber = startTimeEndPart;
  return wholeNumber * 55 + (halfNumber / 60) * 25;
};

const getFirstDayOfDate = (date: string | Date) => {
  let currentDate = new Date(date);
  if (currentDate.getDay() === 1) {
    return moment(currentDate).format("MM/DD/yyyy");
  } else {
    return moment(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay() + 1,
      ),
    ).format("MM/DD/yyyy");
  }
};

const getTimeDifference = (startTime: string, endTime: string) => {
  let finalWholeNumber = 0;
  let finalHalfNumber = 0;
  if (!startTime || !endTime) {
    return "0 hours";
  }
  const startTimeFirstPart = parseInt(startTime.split(":")[0]);
  const endTimeFirstPart = parseInt(endTime.split(":")[0]);
  const startTimeEndPart = parseInt(startTime.split(":")[1]);
  const endTimeLastPart = parseInt(endTime.split(":")[1]);
  const wholeNumber = endTimeFirstPart - startTimeFirstPart;
  const halfNumber = endTimeLastPart - startTimeEndPart;
  finalHalfNumber = halfNumber;
  if (halfNumber < 0) {
    finalWholeNumber = wholeNumber - 1;
    finalHalfNumber = 60 + halfNumber;
    // return `${wholeNumber - 1} hours ${60 + halfNumber} minutes`;
  }
  if (wholeNumber < 0) {
    finalWholeNumber = 24 + wholeNumber;
    // finalHalfNumber = halfNumber;
  }
  if (halfNumber === 0) {
    return `${wholeNumber} hours`;
  } else {
    return `${finalWholeNumber} hours ${finalHalfNumber} minutes`;
  }
};

const compareHoursWorked = (a: string, b: string) => {
  const aFirstPart = parseInt(a.split(" ")[0]);
  const bFirstPart = parseInt(b.split(" ")[0]);
  const aLastPart = parseInt(a.split(" ")[2]);
  const bLastPart = parseInt(b.split(" ")[2]);
  if (aFirstPart > bFirstPart) {
    return 1;
  } else if (aFirstPart < bFirstPart) {
    return -1;
  } else {
    if (aLastPart > bLastPart) {
      return 1;
    } else if (aLastPart < bLastPart) {
      return -1;
    } else {
      return 0;
    }
  }
};

const getCurrentDateWithoutTimeAdded = () => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return currentDate;
};

const isShiftTimeWithinRange = (
  time: string,
  timeAhead: string,
  shiftTime: string,
) => {
  switch (timeAhead) {
    case "1 hour":
      return moment(shiftTime).isBetween(
        moment(time),
        moment(time).add(1, "hour"),
      );
    case "2 hours":
      return moment(shiftTime).isBetween(
        moment(time),
        moment(time).add(2, "hours"),
      );
    case "3 hours":
      return moment(shiftTime).isBetween(
        moment(time),
        moment(time).add(3, "hours"),
      );
    case "4 hours":
      return moment(shiftTime).isBetween(
        moment(time),
        moment(time).add(4, "hours"),
      );
    case "5 hours":
      return moment(shiftTime).isBetween(
        moment(time),
        moment(time).add(5, "hours"),
      );
    case "6 hours":
      return moment(shiftTime).isBetween(
        moment(time),
        moment(time).add(6, "hours"),
      );
    case "7 hours":
      return moment(shiftTime).isBetween(
        moment(time),
        moment(time).add(7, "hours"),
      );
    default:
      return false;
  }
};

const getTimeDifferenceMultiplication = (
  startTime: string,
  endTime: string,
  multiplier: number,
) => {
  const [startHours, startMinutes] = startTime
    .trim()
    .split(":")
    .map((t) => parseInt(t));
  const [endHours, endMinutes] = endTime
    .trim()
    .split(":")
    .map((t) => parseInt(t));

  const date = new Date();
  const diffInMins =
    ((date.setHours(endHours, endMinutes) -
      date.setHours(startHours, startMinutes)) *
      multiplier) /
    (60 * 1000);

  let hours = parseInt((diffInMins / 60).toString());
  let mins = diffInMins - hours * 60;
  let result =
    hours && mins
      ? `${hours} hours ${mins} minutes`
      : hours
        ? `${hours} hours`
        : mins
          ? `${mins} minutes`
          : `0 hours`;
  return result;
};

const splitTimeAndConvertToMins = (time: string) => {
  let hours = 0,
    minutes = 0;
  let interval = time.split(" ");
  if (interval.length === 2) {
    if (["Hour", "Hours"].includes(interval[1])) {
      hours = parseInt(interval[0]);
    } else {
      minutes = parseInt(interval[0]);
    }
  }

  if (interval.length === 4) {
    hours = parseInt(interval[0]);
    minutes = parseInt(interval[2]);
  }

  return hours * 60 + minutes;
};

export {
  convertTo24Hours,
  addAndSubtractTime,
  getFirstDayOfDate,
  addAndSubtractTimeThirtyMins,
  getTimeDifference,
  compareHoursWorked,
  getCurrentDateWithoutTimeAdded,
  isShiftTimeWithinRange,
  getTimeDifferenceMultiplication,
  splitTimeAndConvertToMins,
};
