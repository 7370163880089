import React from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import { AbsenceStateProps } from "../../../../../types-definition/StaffHub/employee";

const AbsenceState: React.FC<AbsenceStateProps> = ({ state }) => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundColor:
          state === "Completed"
            ? colors.successBg
            : state === "Approved"
              ? colors.successBg
              : state === "Rejected"
                ? colors.errorBg
                : state === "In-Progress"
                  ? colors.progressBg
                  : colors.primary3,
      }}
    >
      <Typography
        textWeight="600"
        textColor={
          state === "Completed"
            ? colors.successtext
            : state === "Approved"
              ? colors.successtext
              : state === "Rejected"
                ? colors.error
                : state === "In-Progress"
                  ? colors.progressText
                  : colors.primary3
        }
        marginVertical="0px"
      >
        {state}
      </Typography>
    </div>
  );
};

export default AbsenceState;
