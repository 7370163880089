import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import Button from "../../Form/Button";
import { useAppDispatch } from "../../../redux/hooks";
import { setShowGlixed } from "../../../redux/slice/auth/authSlice";

const GetStartedModal = () => {
  const dispatch = useAppDispatch();
  return (
    <section className={styles.container}>
      <div className={styles.content_container}>
        <Typography textWeight="600" textSize="18px">
          You've been Glixed!
        </Typography>
        <div className={styles.midtext_container}>
          <Typography textAlign="left">
            We're thrilled to have you on board! 🚀
          </Typography>
        </div>

        <Button
          onClick={() => {
            dispatch(setShowGlixed(false));
          }}
          paddingVertical="5px"
          marginVertical="10px"
          text="Get Started"
          isPrimary={true}
        />
      </div>
    </section>
  );
};

export default GetStartedModal;
