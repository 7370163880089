import React, { useState } from "react";
import styles from "./index.module.css";
import dangerCircleicon from "../../../../../assets/svg/danger-circle-icon.svg";
import Typography from "../../../../Common/Typography";
import Button from "../../../../Form/Button";
import colors from "../../../../../theme/colors";
import { postRequest } from "../../../../../api/request";
import { getData } from "../../../../../utils/localstorage";
import { toastError } from "../../../../../utils/toast";
import PasswordInput from "../../../../Form/PasswordInput";
import capitalize from "../../../../../utils/capitalize";
import { ConfirmIdentityModalProps } from "../../../../../types-definition/StaffHub/employee";
import { LOGIN_USER_URL } from "../../../../../api/urls";

const ConfirmIdentityModal: React.FC<ConfirmIdentityModalProps> = ({
  dataViewed,
  cancelFunction,
  successfunction,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const user = JSON.parse(getData() ?? "{}");
  const handleValidatePassword = async () => {
    setIsLoading(true);
    const response = await postRequest(LOGIN_USER_URL, {
      email: user?.email,
      password: password,
      rememberMe: false,
    });
    if (response?.success) {
      successfunction();
    } else {
      toastError(response?.data);
    }
    setIsLoading(false);
  };
  return (
    <section className={styles.container}>
      <div className={styles.content_container}>
        <img src={dangerCircleicon} alt="success-icon" />
        <Typography textAlign="left" textWeight="600" textSize="18px">
          Hi, {capitalize(user?.name ?? "")}
        </Typography>
        <Typography textAlign="left" marginVertical="15px">
          To view this information, please confirm your identity by entering
          your password.
        </Typography>

        <PasswordInput
          value={password}
          setValue={(data) => {
            setPassword(data);
          }}
          placeholder="Enter Password"
          label="Password"
        />
        <div className={styles.button_container}>
          <Button
            onClick={handleValidatePassword}
            paddingVertical="6px"
            marginVertical="10px"
            text={`View ${dataViewed}`}
            isPrimary={true}
            isLoading={isLoading}
            textSize="10px"
          />
          <Button
            onClick={cancelFunction}
            paddingVertical="0px"
            marginVertical="10px"
            text="Cancel"
            borderWidth="2px"
            isPrimary={false}
            isPrimaryBorder={true}
            backgroundColor={colors.transparent}
            textSize="14px"
          />
        </div>
      </div>
    </section>
  );
};

export default ConfirmIdentityModal;
