import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import editProfileIcon from "../../../../assets/svg/edit-icon.svg";
import binIcon from "../../../../assets/svg/bin-icon.svg";
import { useAppDispatch } from "../../../../redux/hooks";
import { EmailReportDropdownProps } from "../../../../types-definition/client";
import {
  setShowAddReportModal,
  setShowDeleteReportModal,
} from "../../../../redux/slice/client/emailReportSlice";

const EmailReportTableDropdown: React.FC<EmailReportDropdownProps> = ({
  id,
}) => {
  const dispatch = useAppDispatch();
  const handleEditEmailReport = () => {
    dispatch(setShowAddReportModal({ show: true, id }));
  };

  const handleDeleteEmailReport = () => {
    dispatch(setShowDeleteReportModal({ show: true, id }));
  };

  return (
    <>
      <div className={styles.dropdown_container}>
        <div
          className={styles.dropdown_item}
          onClick={() => {
            handleEditEmailReport();
          }}
        >
          <img
            src={editProfileIcon}
            alt="view-profile"
            className={styles.dropdown_image_item}
          />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Edit Email Report
          </Typography>
        </div>
        <div
          className={styles.dropdown_item}
          onClick={() => {
            handleDeleteEmailReport();
          }}
        >
          <img
            src={binIcon}
            alt="view-profile"
            className={styles.dropdown_image_item}
          />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Delete Email Report
          </Typography>
        </div>
      </div>
    </>
  );
};

export default EmailReportTableDropdown;
