import { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, patchRequest, postRequest } from "api/request";
import { CREATE_LOCATION_URL, GET_LOCATION_URL } from "api/urls";
import { useNavigate } from "react-router-dom";
import {
  getCountry,
  getLocation,
  setAddress,
  setCertificate,
  setClientContact,
  setLocation,
  setOperation,
} from "utils/localstorage";
import SiteLocationNavigation from "components/Client/Site Location/SiteLocationNavigation";
import {
  SiteLocationGeneralFormData,
  SiteLocationRequiredGeneralFormData,
} from "types-definition/client";
import SiteLocationGeneralForm from "components/Client/Site Location/SiteLocationGeneralForm";
import toast from "react-hot-toast";
import validator from "utils/validateFields";
import ScrollToTop from "components/Common/ScrollToTop";
import { toastError, toastSuccess } from "utils/toast";
import PageBackButton from "components/Common/PageBackButton";
import { updateSetupComplete } from "utils/setupWizard";

const SiteLocationGeneral = () => {
  const navigate = useNavigate();
  const country = getCountry();
  const [formData, setFormData] = useState<SiteLocationGeneralFormData>({
    name: "",
    code: "",
    client: "",
    area: "",
    region: "",
    managedBy: "",
    shortName: "",
    active: true,
  });
  const [requiredFormData, setRequiredFormData] =
    useState<SiteLocationRequiredGeneralFormData>({
      name: false,
      code: false,
      client: false,
      area: false,
      region: false,
      managedBy: false,
    });
  const [client, setClient] = useState<string>("");
  const [area, setArea] = useState<string>("");
  const [manager, setManager] = useState<{ name: string; email: string }>({
    name: "",
    email: "",
  });
  const [requestLoading, setRequestLoading] = useState(false);
  const handleSubmitForm = async () => {
    const { status, data } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    const locationId = getLocation();
    setRequestLoading(true);
    try {
      let response;
      if (!locationId) {
        response = await postRequest(CREATE_LOCATION_URL, {
          name: formData.name,
          code: formData.code,
          clientId: formData.client,
          areaId: formData.area,
          region: formData.region,
          managedBy: JSON.stringify({
            id: formData.managedBy,
            name: manager.name,
            email: manager.email,
          }),
          shortName: formData.shortName,
          active: formData.active,
        });
        if (response?.success) {
          const getLocationData = await getRequest(
            `${GET_LOCATION_URL}/${response.data.id}`,
          );
          setLocation(getLocationData.data.id);
          setAddress(getLocationData.data.address.id);
          setOperation(getLocationData.data.operation.id);
          setCertificate(getLocationData.data.locationRequiredCertificate.id);
          setClientContact(getLocationData.data.clientContactPerson.id);
          toastSuccess("Client Site Location Created");
          navigate(`/client/address`);
        } else {
          toastError(response?.data);
        }
      } else {
        response = await patchRequest(`${CREATE_LOCATION_URL}/${locationId}`, {
          name: formData.name,
          code: formData.code,
          clientId: formData.client,
          areaId: formData.area,
          region: formData.region,
          managedBy: JSON.stringify({
            id: formData.managedBy,
            name: manager.name,
            email: manager.email,
          }),
          shortName: formData.shortName,
          active: formData.active,
        });
        if (response?.success) {
          const getLocationData = await getRequest(
            `${GET_LOCATION_URL}/${response.data.id}`,
          );
          setLocation(getLocationData.data.id);
          setAddress(getLocationData.data.address.id);
          setOperation(getLocationData.data.operation.id);
          setCertificate(getLocationData.data.locationRequiredCertificate.id);
          setClientContact(getLocationData.data.clientContactPerson.id);
          toastSuccess("Client Site Location Updated");
          await updateSetupComplete("12", false, "");
          navigate(`/client/address`);
        } else {
          toastError(response?.data);
        }
      }
    } finally {
      setRequestLoading(false);
    }
  };
  const handleFormCancelation = () => {
    navigate("/client/site");
  };
  useEffect(() => {
    const getLocationInformation = async () => {
      const data = getLocation();
      if (data) {
        const response = await getRequest(`${GET_LOCATION_URL}/${data}`);
        if (response?.success && response.data) {
          setAddress(response.data.address.id);
          setOperation(response.data.operation?.id);
          setCertificate(response.data.locationRequiredCertificate.id);
          setClientContact(response.data.clientContactPerson.id);
          setFormData({
            active: response.data.active ?? true,
            area: response.data.areaId ?? "",
            client: response.data.clientId ?? "",
            code: response.data.code ?? "",
            managedBy: JSON.parse(response.data.managedBy)?.id ?? "",
            name: response.data.name ?? "",
            region: response.data.region ?? "",
            shortName: response.data.shortName ?? "",
            clientName: response.data.clientName ?? "",
            areaName: response.data.areaName ?? "",
          });
          setManager({
            name: JSON.parse(response.data.managedBy)?.name ?? "",
            email: JSON.parse(response.data.managedBy)?.email ?? "",
          });
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      }
    };
    getLocationInformation();
  }, []);
  useEffect(() => {
    setFormData((prevData) => {
      return {
        ...prevData,
        name: "",
        code: "",
        client: "",
        area: "",
        region: country!,
        shortName: "",
        active: true,
      };
    });
    // setManager("Self");
  }, [country]);
  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="clients" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton
            width="90%"
            paddingTop="0"
            marginBottom="15px"
            to="/client/site"
          />
          <div className={styles.item_container_text}>
            <Typography textAlign="left" textWeight="600" textSize="24px">
              Add Site Location
            </Typography>
          </div>
          <SiteLocationNavigation active="general" />
          <div className={styles.content_container}>
            <SiteLocationGeneralForm
              formData={formData}
              setFormData={setFormData}
              requiredFormData={requiredFormData}
              setRequiredFormData={setRequiredFormData}
              client={client}
              setClient={setClient}
              area={area}
              setArea={setArea}
              manager={manager}
              setManager={setManager}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Cancel"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={true}
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SiteLocationGeneral;
