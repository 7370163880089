import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../../../components/Common/PageBackButton";
import Typography from "../../../../../components/Common/Typography";
import colors from "../../../../../theme/colors";
import HorizontalRule from "../../../../../components/Common/HorizontalRule";
import EmployeeUploadedItem from "../../../../../components/StaffHub/Employee/EmployeeUploadedItem";
import {
  getCurrentEmployee,
  getUserTypes,
  setCurrentLocation,
} from "../../../../../utils/localstorage";
import { getRequest } from "../../../../../api/request";
import { toastError } from "../../../../../utils/toast";
import capitalize from "../../../../../utils/capitalize";
import moment from "moment";
import PageLoader from "../../../../Common/PageLoader";
import Button from "../../../../Form/Button";
import { EmployeeUkData } from "../../../../../types-definition/StaffHub/employee";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../redux/hooks";
import { setShowConfirmResetPasswordModal } from "../../../../../redux/slice/staff-hub/employeeViewProfileSlice";
import showSelectedLetter from "../../../../../utils/showSelectedLetters";
import EmployeeCertificateView from "../EmployeeCertificateView";
import ConfirmIdentityModal from "../ConfirmIdentityModal";
import checkAdminPermission, { checkUserPermission } from "utils/permission";
import { SHOW_EMPLOYEE_PASSWORD } from "types-definition/constants";

const EmployeeViewProfileUk = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showDOB, setShowDOB] = useState<boolean>(false);
  const [showNIN, setShowNIN] = useState<boolean>(false);
  const [showVisaNumber, setShowVisaNumber] = useState<boolean>(false);
  const [showEmployeePasswordPermission, setshowEmployeePasswordPermission] =
    useState(true);
  const [showDocumentNumber, setShowDocumentNumber] = useState<boolean>(false);
  const [showCertificateNumber, setShowCertificateNumber] =
    useState<boolean>(false);
  const [dataToBeViewed, setDataToBeViewed] = useState<
    | "Password"
    | "TIN"
    | "NIN"
    | "Date Of Birth"
    | "Document Number"
    | "Visa Number"
    | "Certificate Number"
  >("Password");
  const [showConfirmIdentityModal, setShowConfirmIdentityModal] =
    useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [currentCertificateId, setCurrentCertificateId] = useState("");
  const [employeeData, setEmployeeData] = useState<EmployeeUkData>({
    firstName: "",
    lastName: "",
    username: "",
    dob: "",
    gender: "",
    photo: "",
    proofOfId: "",
    startDate: "",
    nationality: "",
    lastUpdatedBy: "",
    updatedAt: "",
    addressLine1: "",
    addressLine2: "",
    town: "",
    state: "",
    postCode: "",
    country: "",
    mobileNumber: "",
    email: "",
    pin: "",
    grade: "",
    employeeNumber: "",
    nin: "",
    payGrade: "",
    payrollGroup: "",
    bankName: "",
    accountNumber: "",
    accountName: "",
    contactName: "",
    relationship: "",
    emergencyPostCode: "",
    emergencyAddressLine1: "",
    emergencyAddressLine2: "",
    emergencyTown: "",
    emergencyMobileNumber: "",
    emergencyEmail: "",
    medicalDetails: "",
    citizenshipStatus: "",
    countryOfCitizenShip: "",
    documentType: "",
    documentNumber: "",
    issueDate: "",
    expiryDate: "",
    placeOfIssue: "",
    visaType: "",
    visaNumber: "",
    visaIssueDate: "",
    visaExpiryDate: "",
    certificate: [],
    sortCode: "",
    personnelRate: "",
    holidayRate: "",
    contractHours: "",
    contractedHoursPeriod: "-",
    holidayCalculationMethod: "-",
  });
  const employee = JSON.parse(getCurrentEmployee()!);
  const handleEditEmployee = () => {
    setCurrentLocation("/employee/view-profile");
    navigate("/employee/basic-info");
  };
  const handleShowData = () => {
    if (dataToBeViewed === "Date Of Birth") {
      setShowDOB(true);
    } else if (dataToBeViewed === "NIN") {
      setShowNIN(true);
    } else if (dataToBeViewed === "Document Number") {
      setShowDocumentNumber(true);
    } else if (dataToBeViewed === "Visa Number") {
      setShowVisaNumber(true);
    } else if (dataToBeViewed === "Certificate Number") {
      setShowCertificateNumber(true);
    }
    setShowConfirmIdentityModal(false);
  };
  useEffect(() => {
    const getEmployeeData = async () => {
      // get employee data
      const response = await getRequest(`/employee/${employee?.name}`);
      if (response?.success) {
        const data = response.data;
        const gradeResponse = await getRequest(
          `/settings/grade/${data.operation.grade}`,
        );
        setEmployeeData({
          firstName: data.basicInformation.firstName,
          lastName: data.basicInformation.lastName,
          username: data.basicInformation.username,
          startDate: data.basicInformation.startDate,
          nationality: data.basicInformation.nationality,
          dob: data.basicInformation.dob,
          accountName: data.bankDetail.accountName,
          accountNumber: data.bankDetail.accountNumber,
          addressLine1: data.contactDetail.address1,
          addressLine2: data.contactDetail.address2,
          bankName: data.bankDetail.bankName,
          contactName: data.emergencyContact.contactName,
          country: data.contactDetail.country,
          email: data.contactDetail.email,
          emergencyEmail: data.emergencyContact.email,
          emergencyMobileNumber: data.emergencyContact.telephone,
          emergencyPostCode: data.emergencyContact.postcode,
          medicalDetails: data.medicalDetail.medicalDetails,
          relationship: data.emergencyContact.relationship,
          state: data.contactDetail.county,
          town: data.contactDetail.town,
          postCode: data.contactDetail.postcode,
          payGrade: data.hr.payGrade,
          payrollGroup: data.hr.payrollGroup,
          photo: data.employeePhoto,
          proofOfId: data.employeeProofOfId,
          employeeNumber: data.hr.employeeNumber,
          holidayCalculationMethod:
            data.hr.holidayCalculationMethod === "entitlement"
              ? "Entitlement Days"
              : data.hr.holidayCalculationMethod === "--"
                ? ""
                : "Accrued Holidays",
          gender: data.basicInformation.gender,
          grade: gradeResponse?.data?.grade,
          pin: data.operation.pin,
          mobileNumber: data.contactDetail.mobileNumber,
          lastUpdatedBy: data.basicInformation.updateRecord?.newRecord.name,
          updatedAt: data.updatedAt,
          nin: data.hr.nin,

          // UK
          citizenshipStatus: data.identificationDocument.citizenshipStatus,
          countryOfCitizenShip:
            data.identificationDocument.countryOfCitizenship,
          documentType: data.identificationDocument.documentType,
          documentNumber: data.identificationDocument.documentNumber,
          issueDate: data.identificationDocument.issueDate,
          expiryDate: data.identificationDocument.expiryDate,
          placeOfIssue: data.identificationDocument.placeOfIssue,
          visaType: data.visaInformation.visaType,
          visaNumber: data.visaInformation.visaNumber,
          visaIssueDate: data.visaInformation.issueDate,
          visaExpiryDate: data.visaInformation.expiryDate,
          certificate: data.certifications.map((i: any) => {
            return {
              certificateType: i.certificateType,
              certificateNumber: i.certificateNumber,
              certificateStartDate: i.startDate,
              certificateExpiryDate: i.expiryDate,
              id: i.id,
            };
          }),
          sortCode: data.bankDetail.sortCode,
          personnelRate: data.hr.personnelRate,
          holidayRate: data.hr.holidayRate,
          contractHours: data.hr.contractHours,
          contractedHoursPeriod: data.hr.contractedHoursPeriod,
          emergencyAddressLine1: data.emergencyContact.address1,
          emergencyAddressLine2: data.emergencyContact.address2,
          emergencyTown: data.emergencyContact.town,
        });
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getEmployeeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const getPermission = async () => {
      const data = checkAdminPermission(getUserTypes());
      if (data) {
        setshowEmployeePasswordPermission(false);
      } else {
        const result = await checkUserPermission([SHOW_EMPLOYEE_PASSWORD]);
        setshowEmployeePasswordPermission(result ? false : true);
      }
    };
    getPermission();
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      {showConfirmIdentityModal && (
        <ConfirmIdentityModal
          dataViewed={dataToBeViewed}
          cancelFunction={() => setShowConfirmIdentityModal(false)}
          successfunction={handleShowData}
        />
      )}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/staff-hub" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Employee Details
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">First Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.firstName ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Last Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.lastName ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Username</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData?.username ?? "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Password</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  **************
                </Typography>
                <div
                  className={
                    !showEmployeePasswordPermission
                      ? styles.password_button
                      : styles.password_button_disabled
                  }
                  onClick={() => {
                    !showEmployeePasswordPermission &&
                      dispatch(setShowConfirmResetPasswordModal(true));
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    Reset Password
                  </Typography>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Date Of Birth</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {showDOB && employeeData?.dob
                    ? moment(employeeData?.dob).format("DD/MM/YYYY")
                    : !employeeData?.dob && showDOB
                      ? "-"
                      : "**/**/****"}
                </Typography>
                <div
                  className={styles.view_dob_button}
                  onClick={() => {
                    // setShowDOB((prevData: boolean) => !prevData);
                    if (!showDOB) {
                      setShowConfirmIdentityModal(true);
                      setDataToBeViewed("Date Of Birth");
                    } else {
                      setShowDOB(false);
                    }
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    {showDOB ? "Hide" : "View"}
                  </Typography>
                </div>
              </div>
              <div>
                <Typography textAlign="left">Start Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData?.startDate
                    ? moment(new Date(employeeData?.startDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Gender</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.gender ? capitalize(employeeData.gender) : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Nationality</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.nationality ? employeeData.nationality : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <EmployeeUploadedItem
                label="Photo"
                value={{
                  originalName: employeeData?.photo?.originalName,
                  lastModifiedDate: new Date(employeeData?.photo?.updatedAt),
                  size: employeeData?.photo?.size ?? 10000000,
                  URL: employeeData?.photo?.photo,
                }}
                width="350px"
              />
              <EmployeeUploadedItem
                label="Proof Of Id"
                value={{
                  originalName: employeeData?.proofOfId?.originalName,
                  lastModifiedDate: new Date(
                    employeeData?.proofOfId?.updatedAt,
                  ),
                  size: employeeData?.proofOfId?.size ?? 10000000,
                  URL: employeeData?.proofOfId?.proofOfId,
                }}
                width="350px"
              />
            </div>
            <HorizontalRule
              width="80%"
              marginTop="20px"
              borderColor={colors.gray2}
            />
          </div>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Identification Document
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Citizenship Status</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.citizenshipStatus
                    ? employeeData.citizenshipStatus.toUpperCase()
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Country Of Citizenship</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.countryOfCitizenShip
                    ? employeeData.countryOfCitizenShip
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Document Type</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.documentType
                    ? capitalize(employeeData.documentType)
                    : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Document Number</Typography>
                <div className={styles.flex_container_button}>
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    {showDocumentNumber && employeeData.documentNumber
                      ? employeeData.documentNumber
                      : !showDocumentNumber && employeeData.documentNumber
                        ? showSelectedLetter(employeeData.documentNumber, 2, 2)
                        : "-"}
                  </Typography>
                  <div
                    className={styles.flex_container_button_item}
                    onClick={() => {
                      if (!showDocumentNumber) {
                        setShowConfirmIdentityModal(true);
                        setDataToBeViewed("Document Number");
                      } else {
                        setShowDocumentNumber(false);
                      }
                    }}
                  >
                    <Typography
                      textColor={colors.primary3}
                      paddingVertical="-5px"
                      marginVertical="0px"
                      textSize="12px"
                      textWeight="600"
                    >
                      {showDocumentNumber ? "Hide" : "View"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Issue Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.issueDate
                    ? moment(new Date(employeeData.issueDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Expiry Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.expiryDate
                    ? moment(new Date(employeeData.expiryDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Place of Issue</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.placeOfIssue ? employeeData.placeOfIssue : "-"}
                </Typography>
              </div>
            </div>
            <HorizontalRule
              width="80%"
              marginTop="20px"
              borderColor={colors.gray2}
            />
          </div>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Visa Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Visa Type</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.visaType ? employeeData.visaType : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Visa Number</Typography>
                <div className={styles.flex_container_button}>
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    {showVisaNumber && employeeData.visaNumber
                      ? employeeData.visaNumber
                      : !showVisaNumber && employeeData.visaNumber
                        ? showSelectedLetter(employeeData.visaNumber, 2, 2)
                        : "-"}
                  </Typography>
                  <div
                    className={styles.flex_container_button_item}
                    onClick={() => {
                      if (!showVisaNumber) {
                        setShowConfirmIdentityModal(true);
                        setDataToBeViewed("Visa Number");
                      } else {
                        setShowVisaNumber(false);
                      }
                    }}
                  >
                    <Typography
                      textColor={colors.primary3}
                      paddingVertical="-5px"
                      marginVertical="0px"
                      textSize="12px"
                      textWeight="600"
                    >
                      {showVisaNumber ? "Hide" : "View"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Issue Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.visaIssueDate
                    ? moment(new Date(employeeData.visaIssueDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Expiry Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.visaExpiryDate
                    ? moment(new Date(employeeData.visaExpiryDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div className={styles.validationlist_container}>
                <ul className={styles.ul}>
                  <Typography
                    textAlign="left"
                    marginVertical="0px"
                    textColor={colors.gray1}
                  >
                    <li className={styles.li}>
                      Last Updated By{" "}
                      {employeeData.lastUpdatedBy
                        ? capitalize(employeeData?.lastUpdatedBy)
                        : ""}{" "}
                      {employeeData.lastUpdatedBy
                        ? moment(new Date(employeeData.updatedAt)).format(
                            "LL HH:MM",
                          )
                        : ""}
                    </li>
                  </Typography>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Contact Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Address Line 1</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.addressLine1 ? employeeData.addressLine1 : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Address Line 2</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.addressLine2 ? employeeData.addressLine2 : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Town</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.town ? employeeData.town : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">County</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.state ? employeeData.state : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Post Code</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.postCode ? employeeData.postCode : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Country</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.country ? employeeData.country : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Mobile Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.mobileNumber ? employeeData.mobileNumber : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Email</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.email ? employeeData.email : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Operations
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Pin</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.pin ? employeeData.pin : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.grade ? employeeData.grade : "-"}
                </Typography>
              </div>
            </div>
            <HorizontalRule marginTop="20px" width="70%" />
          </div>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Certification
            </Typography>
            {employeeData.certificate.map((item, index) => {
              return (
                <div key={index}>
                  <div className={styles.item_container_text_flex}>
                    <div>
                      <Typography textAlign="left">Certificate Type</Typography>
                      <Typography
                        textAlign="left"
                        textSize="20px"
                        textWeight="600"
                      >
                        {item.certificateType ? item.certificateType : "-"}
                      </Typography>
                    </div>
                    <div>
                      <Typography textAlign="left">
                        Certificate Number
                      </Typography>

                      <div className={styles.flex_container_button}>
                        <Typography
                          textAlign="left"
                          textSize="20px"
                          textWeight="600"
                        >
                          {/* {item.certificateNumber
                            ? item.certificateNumber
                            : "-"} */}
                          {showCertificateNumber &&
                          item.certificateNumber &&
                          currentCertificateId === item.id
                            ? item.certificateNumber
                            : item.certificateNumber
                              ? showSelectedLetter(item.certificateNumber, 2, 2)
                              : "-"}
                        </Typography>
                        <div
                          className={styles.flex_container_button_item}
                          onClick={() => {
                            if (
                              showCertificateNumber &&
                              currentCertificateId === item.id
                            ) {
                              setShowCertificateNumber(false);
                              setCurrentCertificateId("");
                            } else {
                              setShowConfirmIdentityModal(true);
                              setDataToBeViewed("Certificate Number");
                              setCurrentCertificateId(item.id);
                            }
                          }}
                        >
                          <Typography
                            textColor={colors.primary3}
                            paddingVertical="-5px"
                            marginVertical="0px"
                            textSize="12px"
                            textWeight="600"
                          >
                            {showVisaNumber ? "Hide" : "View"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.item_container_text_flex}>
                    <div>
                      <Typography textAlign="left">Issue Date</Typography>
                      <Typography
                        textAlign="left"
                        textSize="20px"
                        textWeight="600"
                      >
                        {item.certificateStartDate
                          ? moment(new Date(item.certificateStartDate)).format(
                              "DD/MM/YYYY",
                            )
                          : "-"}
                      </Typography>
                    </div>
                    <div>
                      <Typography textAlign="left">Expiry Date</Typography>
                      <Typography
                        textAlign="left"
                        textSize="20px"
                        textWeight="600"
                      >
                        {item.certificateExpiryDate
                          ? moment(new Date(item.certificateExpiryDate)).format(
                              "DD/MM/YYYY",
                            )
                          : "-"}
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.item_container_text_flex}>
                    <EmployeeCertificateView
                      certificateId={item.id}
                      label="Cerfiticate"
                      width="70%"
                    />
                  </div>
                  {index !== employeeData.certificate.length - 1 && (
                    <HorizontalRule
                      marginTop="20px"
                      borderColor={colors.gray2}
                      width="70%"
                      marginBottom="20px"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              HR
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Employee Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.employeeNumber
                    ? employeeData.employeeNumber
                    : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">
                  National Identification Number
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {showNIN && employeeData.nin
                    ? employeeData?.nin
                    : showNIN && !employeeData.nin
                      ? "-"
                      : employeeData.nin
                        ? showSelectedLetter(employeeData.nin, 3, 2)
                        : "-"}
                </Typography>
                <div
                  className={styles.tin_button}
                  onClick={() => {
                    if (!showNIN) {
                      setShowConfirmIdentityModal(true);
                      setDataToBeViewed("NIN");
                    } else {
                      setShowNIN(false);
                    }
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    {showNIN ? "Hide" : "View"}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Pay Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.payGrade ? employeeData.payGrade : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Payroll Group</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.payrollGroup ? employeeData.payrollGroup : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Personal Rate</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.personnelRate
                    ? employeeData.personnelRate
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Holiday Rate</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.holidayRate ? employeeData.holidayRate : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Contract Hours</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.contractHours
                    ? employeeData.contractHours
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Contracted Hours Period
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.contractedHoursPeriod
                    ? employeeData.contractedHoursPeriod
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Holiday Calculation Method
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.holidayCalculationMethod
                    ? employeeData.holidayCalculationMethod
                    : "-"}
                </Typography>
              </div>
            </div>
            <HorizontalRule
              width="65%"
              marginTop="20px"
              borderColor={colors.gray2}
            />
          </div>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Bank Details
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Bank Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.bankName ? employeeData.bankName : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Account Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.accountNumber
                    ? employeeData?.accountNumber
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Account Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.accountName ? employeeData.accountName : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Sort Code</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.sortCode ? employeeData.sortCode : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Emergency Contact
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Contact name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.contactName ? employeeData.contactName : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left"> Relationship</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.relationship ? employeeData.relationship : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Address Line 1</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.emergencyAddressLine1
                    ? employeeData.emergencyAddressLine1
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Address Line 2</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.emergencyAddressLine2
                    ? employeeData.emergencyAddressLine2
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Post Code</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.emergencyPostCode
                    ? employeeData.emergencyPostCode
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Town</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.emergencyTown
                    ? employeeData.emergencyTown
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Mobile Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.emergencyMobileNumber
                    ? employeeData.emergencyMobileNumber
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Email</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.emergencyEmail
                    ? employeeData?.emergencyEmail
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Medical Details
            </Typography>
            <div>
              <Typography textAlign="left">Medical Details</Typography>
              <Typography textAlign="left" textSize="20px" textWeight="600">
                {employeeData.medicalDetails
                  ? employeeData.medicalDetails
                  : "None"}
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.button_container}>
          <Button
            isPrimary={true}
            onClick={handleEditEmployee}
            text="Edit Employee"
            width="350px"
          />
        </div>
      </div>
    </>
  );
};

export default EmployeeViewProfileUk;
