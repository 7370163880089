import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import { getRequest, postRequest, putRequest } from "api/request";
import { SERVICE_CHECKPOINT } from "api/urls";
import { useNavigate } from "react-router-dom";
import {
  getCheckpointSection,
  setCheckpointData,
  setCheckpointSection,
} from "utils/localstorage";
import {
  CheckpointGeneralFormData,
  CheckpointGeneralRequiredFormData,
  CheckpointItemFormData,
} from "types-definition/client";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { toastError, toastSuccess } from "utils/toast";
import PageBackButton from "components/Common/PageBackButton";
import CheckpointNavigator from "components/Client/Checkpoint/CheckpointNavigator";
import AddCheckpointForm from "components/Client/Checkpoint/AddCheckpointForm";
import fieldTextValidator from "utils/validateFieldsWithText";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  clearCheckPoint,
  getCheckpointFormItem,
  getCheckpointInitialEmpty,
  getCheckpoints,
  getRequiredCheckpointFormItem,
  resetNumberofCheckpoints,
  setCheckpointInitialEmpty,
  setCheckpoints,
  setRequiredCheckpointItemFormData,
} from "../../../../redux/slice/client/checkpointSlice";
import PageLoader from "components/Common/PageLoader";
import scrolltoView from "utils/scrollToView";

const CheckpointGeneral = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const checkpoint = useAppSelector(getCheckpoints);
  const checkpointCurrentForm = useAppSelector(getCheckpointFormItem);
  const isFormEmpty = useAppSelector(getCheckpointInitialEmpty);
  const requiredCheckpointCurrentForm = useAppSelector(
    getRequiredCheckpointFormItem,
  );
  const [updateCheckPoint, setUpdateCheckPoint] = useState(false);
  const [currentCheckpointId, setCurrentCheckpointId] = useState("");
  const [formData, setFormData] = useState<CheckpointGeneralFormData>({
    active: false,
    client: "",
    clientId: "",
    location: "",
    locationId: "",
    service: "",
    serviceId: "",
  });
  const [requiredFormData, setRequiredFormData] =
    useState<CheckpointGeneralRequiredFormData>({
      client: {
        status: false,
        text: "",
      },
      location: {
        status: false,
        text: "",
      },
      service: {
        status: false,
        text: "",
      },
    });
  const [pageLoading, setPageLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const handleSubmitForm = async () => {
    const { data, status, id } = fieldTextValidator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...(data as any) };
      });
      if (id) {
        scrolltoView(id);
      }
      toastError("Please fill all required fields");
      return;
    }

    const checkpointFormData = fieldTextValidator(
      checkpointCurrentForm,
      requiredCheckpointCurrentForm,
    );

    if (!checkpointFormData.status && !isFormEmpty) {
      dispatch(
        setRequiredCheckpointItemFormData({
          ...(checkpointFormData.data as any),
        }),
      );
      if (checkpointFormData.id) {
        scrolltoView(checkpointFormData.id);
      }
      toastError("Please fill all the checkpoint details");
      return;
    }

    const allCheckpoints = [
      ...checkpoint.map((data: CheckpointItemFormData) => {
        return {
          checkpointName: data.name,
          longitude: data.longitude,
          latitude: data.latitude,
          time: data.time,
          frequency: data.frequency,
          displayTitle: data.title,
          displayDetails: data.details,
        };
      }),
      ...(checkpointCurrentForm.name &&
      checkpointCurrentForm.details &&
      checkpointCurrentForm.frequency &&
      checkpointCurrentForm.time &&
      checkpointCurrentForm.title
        ? [
            {
              checkpointName: checkpointCurrentForm.name,
              longitude: checkpointCurrentForm.longitude,
              latitude: checkpointCurrentForm.latitude,
              time: checkpointCurrentForm.time,
              frequency: checkpointCurrentForm.frequency,
              displayTitle: checkpointCurrentForm.title,
              displayDetails: checkpointCurrentForm.details,
            },
          ]
        : []),
    ];

    if (allCheckpoints.length === 0) {
      toastError("Please add atleast one checkpoint reason");
      return;
    }
    setRequestLoading(true);

    let response;

    if (updateCheckPoint) {
      response = await putRequest(
        `${SERVICE_CHECKPOINT}/section/${currentCheckpointId}`,
        {
          active: formData.active,
          client: formData.clientId,
          location: formData.locationId,
          service: formData.serviceId,
          checkpoint: allCheckpoints,
        },
      );
    } else {
      response = await postRequest(SERVICE_CHECKPOINT, {
        active: formData.active,
        client: formData.clientId,
        location: formData.locationId,
        service: formData.serviceId,
        checkpoint: allCheckpoints,
      });
    }

    if (response?.success) {
      toastSuccess(
        `Checkpoint General Details ${
          !updateCheckPoint ? "Added" : "Modified"
        } successfully`,
      );
      setCheckpointSection(response?.data?.section);
      setCheckpointData(response?.data?.id);
      navigate("/client/checkpoint/action");
    } else {
      toastError(response?.data);
    }
    setRequestLoading(false);
  };
  useEffect(() => {
    const getCheckpointGeneralData = async () => {
      const data = getCheckpointSection();
      if (data) {
        setPageLoading(true);
        dispatch(resetNumberofCheckpoints());
        dispatch(clearCheckPoint());
        const response = await getRequest(
          `${SERVICE_CHECKPOINT}/section/${data}`,
        );
        if (response?.success && response.data) {
          setFormData({
            active: response.data[0].active,
            client: response.data[0].client?.clientName,
            clientId: response.data[0].client?.id,
            location: response.data[0].location?.name,
            locationId: response.data[0].location?.id,
            service: response.data[0].service?.name,
            serviceId: response.data[0].service?.id,
          });
          response.data?.forEach((data: any) => {
            dispatch(
              setCheckpoints({
                details: data.displayDetails,
                frequency: data.frequency,
                latitude: data.latitude,
                longitude: data.longitude,
                name: data.checkpointName,
                time: data.time,
                title: data.displayTitle,
              }),
            );
          });
          // dispatch(addToNumberOfCheckpoints());
          dispatch(setCheckpointInitialEmpty(true));
          setUpdateCheckPoint(true);
          setCurrentCheckpointId(response?.data[0]?.section);
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
        setPageLoading(false);
      }
    };
    getCheckpointGeneralData();
  }, [dispatch]);

  return (
    <>
      {pageLoading && <PageLoader />}
      <section>
        <ScrollToTop />
        <DashboardHeader active="clients" />
        <div className={styles.container}>
          <div className={styles.item_container}>
            <PageBackButton
              width="90%"
              paddingTop="0"
              marginBottom="15px"
              to="/client/checkpoint"
            />
            <div className={styles.navigation_container}>
              <CheckpointNavigator active="general" />
            </div>
            <div className={styles.item_container_text}>
              <Typography textAlign="left" textWeight="600" textSize="20px">
                General
              </Typography>
            </div>
            <div className={styles.content_container}>
              <div className={styles.form_container}>
                <AddCheckpointForm
                  formData={formData}
                  setFormData={setFormData}
                  requiredFormData={requiredFormData}
                  setRequiredFormData={setRequiredFormData}
                />
                <div className={styles.button_container}>
                  <Button
                    width="200px"
                    text="Next"
                    isPrimary={true}
                    onClick={handleSubmitForm}
                    isLoading={requestLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckpointGeneral;
