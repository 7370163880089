import React from "react";
import Typography from "../../../Common/Typography";
import Checkbox from "../../../Form/Checkbox";
import NumberInput from "../../../Form/NumberInput";
import SelectInput from "../../../Form/SelectInput";
import { PayRollProps } from "../../../../types-definition/client";
import { getCurrencyFormat } from "utils/currencyFormatter";
import TextInput from "components/Form/TextInput";

const PayRollForm: React.FC<PayRollProps> = ({
  payRollFormData,
  setpayRollFormData,
  payrollRequiredFormData,
  setPayrollRequiredFormData,
}) => {
  return (
    <>
      <TextInput
        value={payRollFormData.payRate}
        setValue={(data) => {
          let formattedData = data ? data.replace(/,/g, "") : 0;
          const regex = /^[0-9.]*$/;
          if (!regex.test(formattedData)) {
            return;
          }
          let currencyFormat = getCurrencyFormat(formattedData);
          setpayRollFormData((prevData) => {
            return {
              ...prevData,
              payRate: currencyFormat,
            };
          });
          setPayrollRequiredFormData((prevData) => {
            return { ...prevData, payRate: false };
          });
        }}
        label="Pay Rate"
        placeholder="Enter Pay Rate"
        required={true}
        error={payrollRequiredFormData.payRate}
        helperText={
          payrollRequiredFormData.payRate ? "Pay Rate is required" : ""
        }
      />
      <NumberInput
        value={payRollFormData.minimumPaidHours}
        setValue={(data) => {
          setpayRollFormData((prevData) => {
            return { ...prevData, minimumPaidHours: data };
          });
          setPayrollRequiredFormData((prevData) => {
            return { ...prevData, minimumPaidHours: false };
          });
        }}
        label="Minimum Paid Hours"
        placeholder="Enter Minimum Paid Hours"
        required={true}
        error={payrollRequiredFormData.minimumPaidHours}
        helperText={
          payrollRequiredFormData.minimumPaidHours
            ? "Minimum Paid Hours is required"
            : ""
        }
      />
      <Checkbox
        marginVertical="10px"
        label={
          <div>
            <Typography
              textAlign="left"
              textWeight="600"
              marginHorizontal="-10px"
            >
              Break Time Paid
            </Typography>
          </div>
        }
        value={payRollFormData.breakTimePaid}
        setValue={(data) =>
          setpayRollFormData((prevData) => {
            return { ...prevData, breakTimePaid: data };
          })
        }
      />
      <SelectInput
        dropdownTop="45px"
        value={payRollFormData.breakTimeDuration}
        setValue={(data) => {
          setpayRollFormData((prevData) => {
            return { ...prevData, breakTimeDuration: data };
          });
          setPayrollRequiredFormData((prevData) => {
            return { ...prevData, breakTimeDuration: false };
          });
        }}
        label="Break Time Duration"
        placeholder="Select Break Time Duration"
        required={true}
        error={payrollRequiredFormData.breakTimeDuration}
        helperText={
          payrollRequiredFormData.breakTimeDuration
            ? "Break Time Duration is required"
            : ""
        }
        options={[
          "0",
          "5",
          "10",
          "15",
          "20",
          "25",
          "30",
          "35",
          "40",
          "45",
          "50",
          "55",
          "60",
          "65",
          "70",
          "75",
          "80",
          "85",
          "90",
          "95",
          "100",
          "105",
          "110",
          "115",
          "120",
          "125",
          "130",
          "135",
          "140",
          "145",
          "150",
          "155",
          "160",
          "165",
          "170",
          "175",
          "180",
        ]}
      />
      <Checkbox
        marginVertical="10px"
        label={
          <div>
            <Typography
              textAlign="left"
              textWeight="600"
              marginHorizontal="-10px"
            >
              Include in Payroll
            </Typography>
          </div>
        }
        value={payRollFormData.includeinPayroll}
        setValue={(data) =>
          setpayRollFormData((prevData) => {
            return { ...prevData, includeinPayroll: data };
          })
        }
      />
    </>
  );
};

export default PayRollForm;
