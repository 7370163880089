import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import viewProfileIcon from "../../../../assets/svg/user-profile-icon.svg";
import editProfileIcon from "../../../../assets/svg/edit-icon.svg";
import cloneIcon from "../../../../assets/svg/clone-icon.svg";
import binIcon from "../../../../assets/svg/bin-icon.svg";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  setShowClonePermissionGroupModal,
  setShowPermissionGroupDeleteModal,
  setShowPermissionGroupEditModal,
  setShowPermissionGroupViewModal,
} from "../../../../redux/slice/admin/adminSlice";
import { PermissionGroupTableDropdownProps } from "../../../../types-definition/admin";
const PermissionGroupTableDropdown: React.FC<
  PermissionGroupTableDropdownProps
> = ({ handleHideModal, id, cloned, defaultPermission }) => {
  const dispatch = useAppDispatch();
  return (
    <>
      <div className={styles.dropdown_container}>
        {!defaultPermission && (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              dispatch(
                setShowPermissionGroupViewModal({ show: true, id: id, cloned }),
              );
              handleHideModal();
            }}
          >
            <img
              src={viewProfileIcon}
              alt="view-profile"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              View Permission Group
            </Typography>
          </div>
        )}
        {!defaultPermission && (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              dispatch(setShowPermissionGroupEditModal({ show: true, id: id }));
              handleHideModal();
            }}
          >
            <img
              src={editProfileIcon}
              alt="view-profile"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              Edit Permission Group
            </Typography>
          </div>
        )}

        <div
          className={styles.dropdown_item}
          onClick={() => {
            dispatch(setShowClonePermissionGroupModal({ show: true, id: id }));
            handleHideModal();
          }}
        >
          <img
            src={cloneIcon}
            alt="view-profile"
            className={styles.dropdown_image_item}
          />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Clone Permission Group
          </Typography>
        </div>
        {!defaultPermission && (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              dispatch(
                setShowPermissionGroupDeleteModal({ show: true, id: id }),
              );
              handleHideModal();
            }}
          >
            <img
              src={binIcon}
              alt="view-profile"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              Delete Permission Group
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

export default PermissionGroupTableDropdown;
