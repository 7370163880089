import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { ShowIdInterface } from "../../../types-definition/common";
// Define the initial state using that type
const initialState = {
  showAddServiceGroupModal: false,
  showLinkServicesModal: false,
  showDeleteServiceGroupModal: false,
  fetchedServiceGroupCounter: 0,
  currentSelectedId: "",
};

export const serviceGroupSlice = createSlice({
  name: "serviceGroup",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setShowAddServiceGroupModal: (state, action: PayloadAction<boolean>) => {
      state.showAddServiceGroupModal = action.payload;
    },
    setShowLinkServicesModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showLinkServicesModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
    },
    setShowDeleteServiceGroupModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showDeleteServiceGroupModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
    },
    updateFetchedServiceGroupCounter: (state) => {
      state.fetchedServiceGroupCounter += 1;
    },
  },
});

export const {
  setShowAddServiceGroupModal,
  setShowLinkServicesModal,
  setShowDeleteServiceGroupModal,
  updateFetchedServiceGroupCounter,
} = serviceGroupSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;
// export const IsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const getShowServiceGroupModal = (state: RootState) =>
  state.serviceGroup.showAddServiceGroupModal;

export const getShowLinkServicesModal = (state: RootState) =>
  state.serviceGroup.showLinkServicesModal;

export const getFetchedServiceGroupCounter = (state: RootState) =>
  state.serviceGroup.fetchedServiceGroupCounter;

export const getShowDeleteServiceGroupModal = (state: RootState) =>
  state.serviceGroup.showDeleteServiceGroupModal;

export const getCurrentSelectedId = (state: RootState) =>
  state.serviceGroup.currentSelectedId;

export default serviceGroupSlice.reducer;
