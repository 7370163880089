import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import HorizontalRule from "../../../../Common/HorizontalRule";
import { useAppSelector } from "../../../../../redux/hooks";
import { getEmployeeAbsenceHolidays } from "../../../../../redux/slice/staff-hub/employeeSlice";

const EmployeeHolidayTimeOff = () => {
  const holidays = useAppSelector(getEmployeeAbsenceHolidays);
  const availableHolidays = holidays.filter((holiday) => {
    return new Date(holiday.date).getTime() > new Date().getTime();
  });
  return (
    <div className={styles.timeoff_container}>
      <div className={styles.timeoff_container_item_1}>
        <div>
          <Typography textWeight="600" textSize="14px" textAlign="left">
            Time Off Allowance
          </Typography>
          <Typography textSize="14px">
            From 1 Jan to 31 Dec {new Date().getFullYear()}
          </Typography>
        </div>
        <div className={styles.daystext_container}>
          <Typography textSize="14px" marginVertical="0px">
            Days
          </Typography>
        </div>
      </div>
      <HorizontalRule />
      <div className={styles.holiday_details_container}>
        <div>
          <Typography textWeight="600" textSize="18px">
            {holidays.length}
          </Typography>
          <Typography textWeight="600" textSize="14px">
            ASSIGNED
          </Typography>
        </div>
        <div>
          <Typography textWeight="600" textSize="18px">
            {availableHolidays.length}
          </Typography>
          <Typography textWeight="600" textSize="14px">
            AVAILABLE
          </Typography>
        </div>
        <div>
          <Typography textWeight="600" textSize="18px">
            {holidays.length - availableHolidays.length}
          </Typography>
          <Typography textWeight="600" textSize="14px">
            TAKEN
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default EmployeeHolidayTimeOff;
