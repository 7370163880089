import React, { useState } from "react";
import Typography from "components/Common/Typography";
import size from "theme/size";
import PasswordInput from "components/Form/PasswordInput";
import TextInput from "components/Form/TextInput";
import SelectInput from "components/Form/SelectInput";
import styles from "./index.module.css";
import Button from "components/Form/Button";
import SignInButton from "components/Form/SignInButton";
import Divider from "components/Common/Divider";
import colors from "theme/colors";
import SignUpHeader from "components/Common/SignUpHeader";
import Checkbox from "components/Form/Checkbox";
import countryList from "country-list";
import { Link } from "react-router-dom";
import emailIcon from "assets/svg/Mail Icon.svg";
import googleIcon from "assets/svg/Google-Icon.svg";
import microsoftIcon from "assets/svg/Microsoft-svg.svg";
import { postRequest } from "api/request";
import {
  BASE_URL,
  GOOGLE_AUTH_URL,
  MICROSOFT_AUTH_URL,
  REGISTER_USER_URL,
} from "api/urls";
import Error from "components/Common/Error";
import { toastError } from "utils/toast";

const AuthFailed: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    repeat_password: "",
    country: "",
  });
  const [, setShowSuccess] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const handleFormSubmission = async () => {
    try {
      setRequestLoading(true);
      const response = await postRequest(REGISTER_USER_URL, formData);
      if (response?.success) {
        setShowSuccess(true);
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    } finally {
      setRequestLoading(false);
    }
  };
  const openGoogleUrl = () => {
    window.open(`${BASE_URL}${GOOGLE_AUTH_URL}`, "_self");
  };
  const openMicrosoftUrl = () => {
    window.open(`${BASE_URL}${MICROSOFT_AUTH_URL}`, "_self");
  };
  // useEffect(() => {
  //   const getToken = getData();
  //   if (getToken !== null) {
  //     navigate("/dashboard");
  //   }
  // });
  return (
    <>
      <main>
        <SignUpHeader />
        <section className={styles.container}>
          <div className={styles.body_container}>
            <Typography
              fontFamily="inter"
              textAlign="center"
              textSize={size.lg}
              textWeight="600"
            >
              Create An Account
            </Typography>
            <Typography
              textAlign="center"
              fontFamily="inter"
              textSize={size.md}
            >
              Enter your credentials to create your account
            </Typography>
            <Error marginTop="40px" />
            <div className={styles.form_container}>
              <TextInput
                value={formData.name}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return { ...prevData, name: data };
                  })
                }
                label="Name"
                placeholder="Enter Name"
              />
              <TextInput
                value={formData.email}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return { ...prevData, email: data };
                  })
                }
                label="Email Address"
                placeholder="Enter Email"
                right={
                  <img src={emailIcon} alt="icon" width={20} height={20} />
                }
              />
              <PasswordInput
                value={formData.password}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      password: data,
                      repeat_password: data,
                    };
                  })
                }
                label="Password"
                placeholder="Enter Password"
              />
              <SelectInput
                value={formData.country}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return { ...prevData, country: data };
                  })
                }
                label="Country"
                placeholder="Select Country"
                options={countryList.getData().map((data) => data.name)}
              />
              <section>
                <Checkbox
                  value={false}
                  setValue={() => {}}
                  label={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        textAlign="left"
                        textSize="16px"
                        marginVertical={0}
                      >
                        By creating an account, I confirm that I have read
                      </Typography>
                      <Typography
                        textAlign="left"
                        textSize="16px"
                        marginVertical={0}
                      >
                        and understood the{" "}
                        <Link
                          to="/"
                          style={{ color: colors.black, fontWeight: "600" }}
                        >
                          Terms and Conditions
                        </Link>
                        &nbsp;and
                      </Typography>
                      <Typography
                        textAlign="left"
                        textSize="16px"
                        marginVertical={0}
                        textWeight="600"
                      >
                        <Link to="/" style={{ color: colors.black }}>
                          Privacy Policy.
                        </Link>
                      </Typography>
                    </div>
                  }
                />
              </section>
              <Button
                text="Get Started"
                isPrimary={true}
                isLoading={requestLoading}
                onClick={() => {
                  handleFormSubmission();
                }}
              />
            </div>
            <section className={styles.bottom_container}>
              <div className={styles.divider_container}>
                <Divider width="45%" />
                <Typography textColor={colors.gray1}>Or</Typography>
                <Divider width="45%" />
              </div>
              <div>
                <SignInButton
                  text="Sign up with Google"
                  onClick={openGoogleUrl}
                  image={googleIcon}
                />
                <SignInButton
                  text="Sign up with Microsoft"
                  onClick={openMicrosoftUrl}
                  image={microsoftIcon}
                />
              </div>
            </section>
            <div className={styles.bottomText}>
              <Typography>Already have an account? &nbsp;</Typography>
              <Link to="/login">
                <Typography textWeight="500">Log In</Typography>
              </Link>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AuthFailed;
