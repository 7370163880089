import { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import circleCancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch } from "../../../../redux/hooks";
import Button from "../../../Form/Button";
import SelectInput from "../../../Form/SelectInput";
import TextInput from "../../../Form/TextInput";
import fieldTextValidator from "../../../../utils/validateFieldsWithText";
import {
  IncidentReportRequiredFormData,
  IssueIncidentReportFormData,
  IssueReportRequiredFormData,
  IssueSpecifyReportRequiredFormData,
} from "../../../../types-definition/client";
import Checkbox from "../../../Form/Checkbox";
import { getRequest, postRequest } from "../../../../api/request";
import { toastError, toastSuccess } from "../../../../utils/toast";
import {
  setIncidentReportRequestCounter,
  setShowIncidentReportModal,
} from "../../../../redux/slice/client/incidentReport";
import RadioInput from "../../../Form/RadioInput";
import ClientSelectInput from "../../../Form/ClientSelectInput";
import LongTextInput from "../../../Form/LongTextInput";
import DateInput from "../../../Form/DateInput";
import TimeInput from "../../../Form/TimeInput";
import moment from "moment";
import {
  CLIENT_INCIDENT_REPORT,
  GET_ALL_CLIENTS_URL,
  GET_LOCATION_URL,
  GET_SERVICE_URL,
} from "../../../../api/urls";

const AddIncidentReportModal = () => {
  const dispatch = useAppDispatch();
  const [requestLoading, setRequestLoading] = useState(false);
  const [formData, setFormData] = useState<IssueIncidentReportFormData>({
    issueReport: true,
    client: "",
    location: "",
    service: "",
    issue: "",
    description: "",
    sendToClient: false,
    incidentDate: "",
    incidentTime: "12:00 AM",
    incidentType: "",
    clientName: "",
    locationName: "",
    serviceName: "",
    specify: "",
  });
  const [locationValueUpdatedCounter, setLocationValueUpdatedCounter] =
    useState(0);
  const [serviceValueUpdatedCounter, setServiceValueUpdatedCounter] =
    useState(0);

  const [issueReportRequiredFormData, setIssueReportRequiredFormData] =
    useState<IssueReportRequiredFormData>({
      client: {
        status: false,
        text: "",
      },
      location: {
        status: false,
        text: "",
      },
      service: {
        status: false,
        text: "",
      },
      issue: {
        status: false,
        text: "",
      },
    });

  const [
    issueSpecifyReportRequiredFormData,
    setIssueSpecifyReportRequiredFormData,
  ] = useState<IssueSpecifyReportRequiredFormData>({
    specify: {
      status: false,
      text: "",
    },
  });

  const [incidentRequiredFormData, setIncidentRequiredFormData] =
    useState<IncidentReportRequiredFormData>({
      client: {
        status: false,
        text: "",
      },
      location: {
        status: false,
        text: "",
      },
      incidentType: {
        status: false,
        text: "",
      },
      incidentTime: {
        status: false,
        text: "",
      },
    });
  const [clientOptions, setClientOptions] = useState<Array<any>>([]);
  const [locationOptions, setLocationOptions] = useState<Array<any>>([]);
  const [serviceOptions, setServiceOptions] = useState<Array<any>>([]);
  const handleFormSubmit = async () => {
    if (formData.issueReport) {
      const { status, data } = fieldTextValidator(
        formData,
        issueReportRequiredFormData,
      );
      if (!status) {
        setIssueReportRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        return;
      } else if (formData.issue === "Others") {
        const { status, data } = fieldTextValidator(
          formData,
          issueSpecifyReportRequiredFormData,
        );
        if (!status) {
          setIssueSpecifyReportRequiredFormData((prevData) => {
            return { ...prevData, ...data };
          });
          return;
        }
      }
    } else {
      const { status, data } = fieldTextValidator(
        formData,
        incidentRequiredFormData,
      );
      if (!status) {
        setIncidentRequiredFormData((prevData) => ({ ...prevData, ...data }));
        return;
      }
    }
    let payload;
    if (formData.issueReport) {
      payload = {
        type: "issue report",
        client: formData.client,
        location: formData.location,
        service: formData.service,
        issue: formData.issue,
        description: formData.description,
        specify: formData.specify,
        sendToClient: formData.sendToClient,
      };
    } else {
      payload = {
        type: "incident report",
        client: formData.client,
        location: formData.location,
        incidentType: formData.incidentType,
        incidentTime: `${moment(formData.incidentDate).format("DD/MM/YYYY")} ${
          formData.incidentTime
        }`,
        description: formData.description,
      };
    }
    setRequestLoading(true);
    const response = await postRequest(CLIENT_INCIDENT_REPORT, payload);
    if (response?.success) {
      toastSuccess("Incident Report Added Successfully");
      dispatch(setShowIncidentReportModal(false));
      dispatch(setIncidentReportRequestCounter());
    } else {
      toastError(response?.data);
    }
    setRequestLoading(false);
  };

  useEffect(() => {
    const getAllClients = async () => {
      const response = await getRequest(GET_ALL_CLIENTS_URL);
      if (response?.success) {
        const clientOptions = response.data?.clients?.map((client: any) => {
          return {
            name: client.clientName,
            id: client.id,
          };
        });
        setClientOptions(clientOptions);
      }
    };
    getAllClients();
  }, []);
  const getAllLocations = async (id: string) => {
    const response = await getRequest(GET_LOCATION_URL);
    setLocationValueUpdatedCounter((prev) => prev + 1);
    if (response?.success) {
      const locationOption = response.data
        .filter((item: any) => item.clientId === id)
        .map((location: any) => {
          return {
            name: location.name,
            id: location.id,
          };
        });
      setLocationOptions(locationOption);
    }
  };
  const getAllServices = async (id: string) => {
    const response = await getRequest(`${GET_SERVICE_URL}/location/${id}`);
    setServiceValueUpdatedCounter((prev) => prev + 1);
    if (response?.success) {
      const serviceOption = response.data.map((service: any) => {
        return {
          name: service.service.name,
          id: service.service.id,
        };
      });
      setServiceOptions(serviceOption);
    }
  };
  return (
    <>
      <section className={styles.container}>
        <div className={styles.flex_container}>
          <Typography textWeight="600" textSize="20px">
            Issue Reporting
          </Typography>
          <img
            src={circleCancelIcon}
            alt="cancel-icon"
            onClick={() => {
              dispatch(setShowIncidentReportModal(false));
            }}
            className={styles.cancel_icon}
          />
        </div>
        <div className={styles.form_container}>
          <Typography textAlign="left" textWeight="600">
            What do you want to report?
          </Typography>
          <div>
            <RadioInput
              label={
                <Typography marginVertical="0px">Issue Reporting</Typography>
              }
              name="issue"
              value={formData.issueReport}
              setValue={(value) => {
                setFormData({ ...formData, issueReport: true });
              }}
              marginVertical="0px"
            />
            <RadioInput
              label={
                <Typography marginVertical="0px">Incident Report</Typography>
              }
              name="issue"
              value={!formData.issueReport}
              setValue={(value) => {
                setFormData({ ...formData, issueReport: false });
              }}
              marginVertical="0px"
            />
          </div>
          <ClientSelectInput
            label="Client"
            value={formData.client}
            setValue={(data, id) => {
              setFormData({ ...formData, clientName: data, client: id ?? "" });
              setIssueReportRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  client: {
                    status: false,
                    text: "",
                  },
                };
              });
              setIncidentRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  client: {
                    status: false,
                    text: "",
                  },
                };
              });
              getAllLocations(id ?? "");
            }}
            placeholder="Select Client"
            client={formData.clientName}
            options={clientOptions}
            dropdownTop="46px"
            datalistTop="83px"
            error={
              issueReportRequiredFormData.client.status ||
              incidentRequiredFormData.client.status
            }
            helperText={
              issueReportRequiredFormData.client.text ||
              incidentRequiredFormData.client.text
            }
            loadingText=""
          />
          <ClientSelectInput
            label="Location"
            value={formData.location}
            setValue={(data, id) => {
              setFormData({
                ...formData,
                locationName: data,
                location: id ?? "",
              });
              setIssueReportRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  location: {
                    status: false,
                    text: "",
                  },
                };
              });
              setIncidentRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  location: {
                    status: false,
                    text: "",
                  },
                };
              });
              getAllServices(id ?? "");
            }}
            placeholder="Select Location"
            client={formData.locationName}
            options={locationOptions}
            dropdownTop="46px"
            datalistTop="83px"
            error={
              issueReportRequiredFormData.location.status ||
              incidentRequiredFormData.location.status
            }
            helperText={
              issueReportRequiredFormData.location.text ||
              incidentRequiredFormData.location.text
            }
            valueUpdatedCounter={locationValueUpdatedCounter}
          />
          {formData.issueReport && (
            <ClientSelectInput
              label="Service/Shift"
              value={formData.service}
              setValue={(data, id) => {
                setFormData({
                  ...formData,
                  serviceName: data,
                  service: id ?? "",
                });
                setIssueReportRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    service: {
                      status: false,
                      text: "",
                    },
                  };
                });
              }}
              placeholder="Select Service/Shift"
              client={formData.serviceName}
              options={serviceOptions.filter(
                (service: any) => service.name !== undefined,
              )}
              dropdownTop="46px"
              datalistTop="83px"
              error={issueReportRequiredFormData.service.status}
              helperText={issueReportRequiredFormData.service.text}
              valueUpdatedCounter={serviceValueUpdatedCounter}
            />
          )}
          {!formData.issueReport && (
            <TextInput
              label="Incident Type"
              value={formData.incidentType}
              setValue={(data) => {
                setFormData({ ...formData, incidentType: data });
                setIncidentRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    incidentType: {
                      status: false,
                      text: "",
                    },
                  };
                });
              }}
              placeholder="Enter Incident Type"
              error={incidentRequiredFormData.incidentType.status}
              helperText={incidentRequiredFormData.incidentType.text}
            />
          )}
          {!formData.issueReport && (
            <div className={styles.date_time_container}>
              <div className={styles.date_container}>
                <DateInput
                  label="Incident Time"
                  value={formData.incidentDate}
                  setValue={(data) => {
                    setFormData({ ...formData, incidentDate: data });
                    setIncidentRequiredFormData((prevData) => {
                      return {
                        ...prevData,
                        incidentTime: {
                          status: false,
                          text: "",
                        },
                      };
                    });
                  }}
                  error={incidentRequiredFormData.incidentTime.status}
                  helperText={incidentRequiredFormData.incidentTime.text}
                  placeholder={moment().format("DD/MM/YYYY")}
                  style={{ width: "auto" }}
                />
              </div>
              <div className={styles.time_container}>
                <TimeInput
                  label=""
                  placeholder="12:00 AM"
                  setValue={(data) => {
                    setFormData({ ...formData, incidentTime: data });
                  }}
                  // helperText={incidentRequiredFormData.time.text}
                  value={formData.incidentTime}
                  width="auto"
                />
              </div>
            </div>
          )}
          {formData.issueReport && (
            <SelectInput
              label="Issue"
              value={formData.issue}
              setValue={(data) => {
                setFormData({ ...formData, issue: data });
                setIssueReportRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    issue: {
                      status: false,
                      text: "",
                    },
                  };
                });
              }}
              placeholder="Select Issue"
              options={[
                "Unsecured Door/Window",
                "Suspicious Vehicle Parked Illegally",
                "Broken Fence/Gate",
                "Unauthorized Person on Premises",
                "Vandalism Graffiti",
                "Broken or Flickering Outdoor Light",
                "Suspicious Package/Item",
                "Damaged Lock",
                "Missing Signage",
                "Faulty Fire Alarm",
                "Broken Surveillance Camera",
                "Unattended Baggage",
                "Strange Odor",
                "Unusual Noise",
                "Broken Emergency Exit",
                "Hazardous Spill",
                "Damage to Property (e.g., Broken Glass)",
                "Presence of Wildlife (if applicable)",
                "Blocked Emergency Exit",
                "Safety Hazard (e.g., Loose Wiring)",
                "Spilled Liquid on Floor",
                "Trash Overflowing in Bins",
                "Broken Furniture",
                "Dirty Restroom",
                "Wet Floor Sign Needed",
                "Broken Hand Dryer",
                "Stains on Carpet",
                "Broken Window/Pane",
                "Graffiti on Walls",
                "Dust Accumulation on Surfaces",
                "Unflushed Toilet",
                "Broken or Missing Soap Dispenser",
                "Unkempt Lobby Area",
                "Overflowing Sink",
                "Missing or Damaged Toilet Paper Holder",
                "Mold Growth in Shower Area",
                "Broken Drinking Fountain",
                "Trash Left Outside Designated Areas",
                "Overgrown Grass in Walkways",
                "Broken Fence",
                "Pest Infestation in Plants",
                "Tree Branch Blocking Pathway",
                "Damaged Garden Equipment",
                "Soil Erosion",
                "Broken Sprinkler System",
                "Fungal Growth on Plants",
                "Damaged Garden Sculpture/Ornament",
                "Animal Droppings in Garden Area",
                "Uneven Paving Stones",
                "Exposed Tree Roots",
                "Untrimmed Bushes/Hedges",
                "Leaking Hosepipe",
                "Broken Garden Edging",
                "Others",
              ]}
              dropdownTop="46px"
              datalistTop="83px"
              error={issueReportRequiredFormData.issue.status}
              helperText={issueReportRequiredFormData.issue.text}
            />
          )}
          {formData.issue === "Others" && (
            <TextInput
              label="Specify"
              placeholder="Specify issue type"
              setValue={(data) => {
                setFormData({ ...formData, specify: data });
                setIssueSpecifyReportRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    specify: {
                      status: false,
                      text: "",
                    },
                  };
                });
              }}
              error={issueSpecifyReportRequiredFormData.specify.status}
              helperText={issueSpecifyReportRequiredFormData.specify.text}
              value={formData.specify}
            />
          )}
          <LongTextInput
            label="Description"
            placeholder="Enter Description"
            setValue={(data) => {
              setFormData({ ...formData, description: data });
            }}
            height="120px"
            value={formData.description}
          />
          {formData.issueReport && (
            <div className={styles.checkbox_container}>
              <Checkbox
                label={
                  <Typography marginVertical="0px">Send to Client</Typography>
                }
                value={formData.sendToClient}
                setValue={(value) => {
                  setFormData({ ...formData, sendToClient: value });
                }}
                marginVertical="0px"
              />
            </div>
          )}
        </div>
        <div className={styles.button_container}>
          <Button
            text="Send Report"
            isLoading={requestLoading}
            isPrimary
            marginHorizontal="5px"
            onClick={handleFormSubmit}
          />
        </div>
      </section>
    </>
  );
};

export default AddIncidentReportModal;
