import React, { useEffect, useState } from "react";
import Typography from "../../../Common/Typography";
import Checkbox from "../../../Form/Checkbox";
import { CheckpointGeneralFormProps } from "../../../../types-definition/client";
import ClientSelectInput from "components/Form/ClientSelectInput";
import { getRequest } from "api/request";
import {
  GET_ALL_CLIENTS_URL,
  GET_LOCATION_URL,
  GET_SERVICE_URL,
} from "api/urls";
import Button from "components/Form/Button";
import addIcon from "assets/svg/primary-plus-icon.svg";
import colors from "theme/colors";
import CheckpointItemForm from "../CheckpointItemForm";
import FilledCheckpointForm from "../FilledCheckpointForm";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  addToNumberOfCheckpoints,
  clearCheckpointItemFormData,
  clearCheckpointRequiredItemFormData,
  getCheckpointFormItem,
  getCheckpointInitialEmpty,
  getCheckpoints,
  getNumberOfCheckpoints,
  getRequiredCheckpointFormItem,
  setCheckpointInitialEmpty,
  setCheckpoints,
  setRequiredCheckpointItemFormData,
} from "../../../../redux/slice/client/checkpointSlice";
import fieldTextValidator from "utils/validateFieldsWithText";

const AddCheckpointForm: React.FC<CheckpointGeneralFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  const dispatch = useAppDispatch();
  const checkpointFormData = useAppSelector(getCheckpointFormItem);
  const checkpointRequiredFormData = useAppSelector(
    getRequiredCheckpointFormItem,
  );
  const isInitialEmpty = useAppSelector(getCheckpointInitialEmpty);
  const numberOfCheckpoints = useAppSelector(getNumberOfCheckpoints);
  const filledCheckpoints = useAppSelector(getCheckpoints);
  const [clientOptions, setClientOptions] = useState<Array<any>>([]);
  const [locationOptions, setLocationOptions] = useState<Array<any>>([]);
  const [serviceOptions, setServiceOptions] = useState<Array<any>>([]);
  const [locationValueUpdatedCounter, setLocationValueUpdatedCounter] =
    useState(0);
  const [serviceValueUpdatedCounter, setServiceValueUpdatedCounter] =
    useState(0);

  useEffect(() => {
    const getAllClients = async () => {
      const response = await getRequest(GET_ALL_CLIENTS_URL);
      if (response?.success) {
        const clientOptions = response.data?.clients?.map((client: any) => {
          return {
            name: client.clientName,
            id: client.id,
          };
        });
        setClientOptions(clientOptions);
      }
    };
    getAllClients();
  }, []);
  const getAllLocations = async (id: string) => {
    const response = await getRequest(GET_LOCATION_URL);
    setLocationValueUpdatedCounter((prev) => prev + 1);
    if (response?.success) {
      const locationOption = response.data
        .filter((item: any) => item.clientId === id)
        .map((location: any) => {
          return {
            name: location.name,
            id: location.id,
          };
        });
      setLocationOptions(locationOption);
    }
  };

  const getAllServices = async (id: string) => {
    const response = await getRequest(
      `${GET_SERVICE_URL}/location/${id}/assigned`,
    );
    setServiceValueUpdatedCounter((prev) => prev + 1);
    if (response?.success) {
      const serviceOption = response.data
        .filter((service: any) => Object.entries(service.service).length !== 0)
        .map((service: any) => {
          return {
            name: service.service?.name,
            id: service.service?.id,
          };
        });
      setServiceOptions(serviceOption);
    }
  };
  const handleAddCheckpoint = () => {
    if (isInitialEmpty) {
      dispatch(addToNumberOfCheckpoints());
      dispatch(setCheckpointInitialEmpty(false));
    } else {
      const { data, status } = fieldTextValidator(
        checkpointFormData,
        checkpointRequiredFormData,
      );
      if (!status) {
        dispatch(setRequiredCheckpointItemFormData({ ...(data as any) }));
        return;
      } else {
        dispatch(setCheckpoints(checkpointFormData));
        dispatch(clearCheckpointItemFormData());
        dispatch(clearCheckpointRequiredItemFormData());
      }
    }
  };
  return (
    <div>
      <Checkbox
        marginVertical="20px"
        label={
          <div>
            <Typography
              textAlign="left"
              textWeight="600"
              marginHorizontal="-10px"
            >
              Active
            </Typography>
          </div>
        }
        value={formData.active}
        setValue={(data) =>
          setFormData((prevData) => {
            return { ...prevData, active: data };
          })
        }
      />
      <ClientSelectInput
        value={formData.client}
        client={formData.client}
        label="Client"
        placeholder="Select Client"
        setValue={(name, id) => {
          setFormData((prevData) => {
            return {
              ...prevData,
              client: name,
              clientId: id ?? "",
              location: "",
              locationId: "",
              service: "",
              serviceId: "",
            };
          });
          setRequiredFormData((prevData) => {
            return { ...prevData, client: { status: false, text: "" } };
          });
          getAllLocations(id ?? "");
        }}
        error={requiredFormData.client.status}
        helperText={requiredFormData.client.text}
        options={clientOptions}
        dropdownTop="45px"
        datalistTop="83px"
        id="client"
      />
      <ClientSelectInput
        value={formData.location}
        client={formData.location}
        label="Location"
        placeholder="Select Location"
        setValue={(name, id) => {
          setFormData((prevData) => {
            return {
              ...prevData,
              location: name,
              locationId: id ?? "",
              service: "",
              serviceId: "",
            };
          });
          setRequiredFormData((prevData) => {
            return { ...prevData, location: { status: false, text: "" } };
          });
          getAllServices(id ?? "");
        }}
        error={requiredFormData.location.status}
        helperText={requiredFormData.location.text}
        options={locationOptions}
        valueUpdatedCounter={locationValueUpdatedCounter}
        dropdownTop="45px"
        datalistTop="83px"
        id="location"
      />
      <ClientSelectInput
        value={formData.service}
        client={formData.service}
        label="Shift/Service"
        placeholder="Select shift/service to add the checkpoint "
        setValue={(name, id) => {
          setFormData((prevData) => {
            return { ...prevData, service: name, serviceId: id ?? "" };
          });
          setRequiredFormData((prevData) => {
            return { ...prevData, service: { status: false, text: "" } };
          });
        }}
        error={requiredFormData.service.status}
        helperText={requiredFormData.service.text}
        options={serviceOptions}
        valueUpdatedCounter={serviceValueUpdatedCounter}
        dropdownTop="45px"
        datalistTop="83px"
        id="service"
      />
      {filledCheckpoints.map((checkpoint, index) => (
        <FilledCheckpointForm key={index} {...checkpoint} index={index} />
      ))}

      {/* <CheckpointItemForm /> */}
      {Array.from({ length: numberOfCheckpoints }).map((_, index) => (
        <CheckpointItemForm
          key={index}
          index={index + 1 + filledCheckpoints.length}
        />
      ))}
      <Button
        left={<img src={addIcon} alt="add" />}
        marginTop="8px"
        paddingVertical="1px"
        width={
          filledCheckpoints.length + numberOfCheckpoints > 0 ? "250px" : "190px"
        }
        isPrimary={false}
        textColor={colors.primary3}
        isPrimaryBorder={true}
        backgroundColor={colors.transparent}
        text={
          filledCheckpoints.length + numberOfCheckpoints > 0
            ? "Add More Checkpoint"
            : "Add Checkpoint"
        }
        textWeight="600"
        onClick={() => {
          handleAddCheckpoint();
        }}
      />
    </div>
  );
};

export default AddCheckpointForm;
