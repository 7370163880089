import { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import circleCancelIcon from "../../../../../assets/svg/cancel-circle-icon.svg";
import Checkbox from "../../../../Form/Checkbox";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import Button from "../../../../Form/Button";
import { postRequest } from "../../../../../api/request";
import { getEmployeeId } from "../../../../../utils/localstorage";
import DateInput from "../../../../Form/DateInput";
import TimeInput from "../../../../Form/TimeInput";
import {
  getAddHolidayModalMinDate,
  getAvalabilityType,
  setShowAvalabilityModal,
  updateEmployeeAvalabilityCounter,
} from "../../../../../redux/slice/staff-hub/employeeSlice";
import {
  EmployeeAvalabilityFormData,
  EmployeeAvalabilityRequiredFormData,
} from "../../../../../types-definition/StaffHub/employee";
import fieldTextValidator from "../../../../../utils/validateFieldsWithText";
import SelectInput from "../../../../Form/SelectInput";
import moment from "moment";
import { convertTo24Hours } from "../../../../../utils/timeConverter";
import { toastError, toastSuccess } from "../../../../../utils/toast";

const EmployeeAvalabilityModal = () => {
  const dispatch = useAppDispatch();
  const id = getEmployeeId();
  const minDate = useAppSelector(getAddHolidayModalMinDate);
  const avalabilityType = useAppSelector(getAvalabilityType);
  const [requestLoading, setRequestLoading] = useState(false);
  const [formData, setFormData] = useState<EmployeeAvalabilityFormData>({
    fromDate: minDate,
    toDate: "",
    frequency: "Daily",
    fromTime: "12:00 AM",
    displayedFromTime: "12:00 AM",
    toTime: "12:00 PM",
    displayedToTime: "12:00 PM",
    recurring: avalabilityType === "one-time" ? false : true,
    daysOfWeek: {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    },
  });
  const [requiredFormData, setRequiredFormData] =
    useState<EmployeeAvalabilityRequiredFormData>({
      fromDate: {
        status: false,
        text: "",
      },
      toDate: {
        status: false,
        text: "",
      },
    });
  const handleFormSubmit = async () => {
    setRequestLoading(true);
    const { data, status } = fieldTextValidator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return {
          ...prevData,
          ...data,
        };
      });
      setRequestLoading(false);
      return;
    }

    let requestData;
    if (formData.recurring) {
      if (formData.frequency === "Weekly") {
        requestData = {
          recurring: formData.recurring,
          fromDate: moment(new Date(formData.fromDate)).format("MM/DD/YYYY"),
          untilDate: moment(new Date(formData.toDate)).format("MM/DD/YYYY"),
          fromTime: convertTo24Hours(formData.fromTime),
          toTime: convertTo24Hours(formData.toTime),
          frequency: formData.frequency.toLowerCase(),
          daysOfTheWeek: [
            formData.daysOfWeek.sunday && "sunday",
            formData.daysOfWeek.monday && "monday",
            formData.daysOfWeek.tuesday && "tuesday",
            formData.daysOfWeek.wednesday && "wednesday",
            formData.daysOfWeek.thursday && "thursday",
            formData.daysOfWeek.friday && "friday",
            formData.daysOfWeek.saturday && "saturday",
          ].filter(Boolean),
        };
      } else if (formData.frequency === "Monthly") {
        requestData = {
          recurring: formData.recurring,
          fromDate: moment(new Date(formData.fromDate)).format("MM/DD/YYYY"),
          // untilDate: moment(new Date(formData.toDate))
          //   .add(100, "years")
          //   .format("MM/DD/YYYY"),
          untilDate: moment(new Date(formData.toDate)).format("MM/DD/YYYY"),
          fromTime: convertTo24Hours(formData.fromTime),
          toTime: convertTo24Hours(formData.toTime),
          frequency: formData.frequency.toLowerCase(),
        };
      } else {
        requestData = {
          recurring: formData.recurring,
          fromDate: moment(new Date(formData.fromDate)).format("MM/DD/YYYY"),
          untilDate: moment(new Date(formData.toDate)).format("MM/DD/YYYY"),
          fromTime: convertTo24Hours(formData.fromTime),
          toTime: convertTo24Hours(formData.toTime),
          frequency: formData.frequency.toLowerCase(),
        };
      }
    } else {
      requestData = {
        recurring: formData.recurring,
        fromDate: moment(new Date(formData.fromDate)).format("MM/DD/YYYY"),
        toDate: moment(new Date(formData.toDate)).format("MM/DD/YYYY"),
        fromTime: convertTo24Hours(formData.fromTime),
        toTime: convertTo24Hours(formData.toTime),
      };
    }
    try {
      const response = await postRequest(`/employee/${id}/unavailability`, {
        ...requestData,
      });
      if (response?.success) {
        toastSuccess("Unavailability Set Successfully");
        dispatch(setShowAvalabilityModal({ show: false, minDate: "" }));
        dispatch(updateEmployeeAvalabilityCounter());
      } else {
        toastError(response?.data);
      }
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.flex_container}>
        <Typography textWeight="600" textSize="20px">
          Set Availability Duration
        </Typography>
        <img
          src={circleCancelIcon}
          alt="cancel-icon"
          onClick={() =>
            dispatch(setShowAvalabilityModal({ show: false, minDate: "" }))
          }
        />
      </div>

      {!formData.recurring ? (
        <div className={styles.form_container}>
          <div className={styles.form_item}>
            <div className={styles.form_date_container}>
              <DateInput
                placeholder={new Date().toLocaleDateString()}
                value={formData.fromDate}
                label="From"
                required
                setValue={(data) => {
                  setFormData({ ...formData, fromDate: data });
                  setRequiredFormData((prevData) => {
                    return {
                      ...prevData,
                      fromDate: {
                        status: false,
                        text: "",
                      },
                    };
                  });
                }}
                monthMax={1}
                validateMin={true}
                min={minDate}
                error={requiredFormData.fromDate.status}
                helperText={requiredFormData.fromDate.text}
              />
            </div>

            <div className={styles.form_time_container}>
              <TimeInput
                placeholder="12:00 AM"
                value={formData.fromTime}
                label=""
                setValue={(data) => {
                  return setFormData({
                    ...formData,
                    fromTime: data,
                  });
                }}
              />
            </div>
          </div>
          <div className={styles.form_item}>
            <div className={styles.form_date_container}>
              <DateInput
                placeholder={new Date().toLocaleDateString()}
                value={formData.toDate}
                label="To"
                required
                setValue={(data) => {
                  setFormData({ ...formData, toDate: data });
                  setRequiredFormData((prevData) => {
                    return {
                      ...prevData,
                      toDate: {
                        status: false,
                        text: "",
                      },
                    };
                  });
                }}
                validateMin
                // min Date calculated for next day
                // min={`${new Date(
                //   new Date(formData.fromDate).getTime() + 86400000,
                // ).getFullYear()}-${
                //   new Date(
                //     new Date(formData.fromDate).getTime() + 86400000,
                //   ).getMonth() +
                //     1 >
                //   9
                //     ? new Date(
                //         new Date(formData.fromDate).getTime() + 86400000,
                //       ).getMonth() + 1
                //     : `0${
                //         new Date(
                //           new Date(formData.fromDate).getTime() + 86400000,
                //         ).getMonth() + 1
                //       }`
                // }-${
                //   new Date(
                //     new Date(formData.fromDate).getTime() + 86400000,
                //   ).getDate() > 9
                //     ? new Date(
                //         new Date(formData.fromDate).getTime() + 86400000,
                //       ).getDate()
                //     : `0${new Date(
                //         new Date(formData.fromDate).getTime() + 86400000,
                //       ).getDate()}`
                // }`}
                min={moment(formData.fromDate).format("L")}
                error={requiredFormData.toDate.status}
                helperText={requiredFormData.toDate.text}
              />
            </div>

            <div className={styles.form_time_container}>
              <TimeInput
                placeholder="12:00 PM"
                value={formData.toTime}
                label=""
                setValue={(data) => {
                  return setFormData({
                    ...formData,
                    toTime: data,
                  });
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.form_container}>
          <DateInput
            placeholder={new Date().toLocaleDateString()}
            value={formData.fromDate}
            label="From"
            required
            setValue={(data) => {
              setFormData({ ...formData, fromDate: data });
              setRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  fromDate: {
                    status: false,
                    text: "",
                  },
                };
              });
            }}
            monthMax={1}
            validateMin={true}
            min={minDate}
            error={requiredFormData.fromDate.status}
            helperText={requiredFormData.fromDate.text}
            style={{ marginBottom: "20px" }}
          />

          <Typography
            textAlign="left"
            marginVertical="0px"
            paddingVertical="0px"
            textWeight="600"
            textSize="15px"
          >
            Time *
          </Typography>
          <div className={styles.time_form}>
            <div className={styles.time_form_item}>
              <TimeInput
                placeholder="12:00 PM"
                value={formData.fromTime}
                label="From"
                setValue={(data) => {
                  return setFormData({
                    ...formData,
                    fromTime: data,
                  });
                }}
                dropdownTop="45px"
                datalistTop="83px"
              />
            </div>
            <div className={styles.time_form_item}>
              <TimeInput
                placeholder="12:00 PM"
                value={formData.toTime}
                label="To"
                setValue={(data) => {
                  return setFormData({
                    ...formData,
                    toTime: data,
                  });
                }}
                dropdownTop="45px"
                datalistTop="83px"
              />
            </div>
          </div>
          <DateInput
            placeholder={new Date().toLocaleDateString()}
            value={formData.toDate}
            label="Until"
            required
            setValue={(data) => {
              setFormData({ ...formData, toDate: data });
              setRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  toDate: {
                    status: false,
                    text: "",
                  },
                };
              });
            }}
            validateMin
            // min Date calculated for next day
            // min={`${new Date(
            //   new Date(formData.fromDate).getTime() + 86400000,
            // ).getFullYear()}-${
            //   new Date(
            //     new Date(formData.fromDate).getTime() + 86400000,
            //   ).getMonth() +
            //     1 >
            //   9
            //     ? new Date(
            //         new Date(formData.fromDate).getTime() + 86400000,
            //       ).getMonth() + 1
            //     : `0${
            //         new Date(
            //           new Date(formData.fromDate).getTime() + 86400000,
            //         ).getMonth() + 1
            //       }`
            // }-${
            //   new Date(
            //     new Date(formData.fromDate).getTime() + 86400000,
            //   ).getDate() > 9
            //     ? new Date(
            //         new Date(formData.fromDate).getTime() + 86400000,
            //       ).getDate()
            //     : `0${new Date(
            //         new Date(formData.fromDate).getTime() + 86400000,
            //       ).getDate()}`
            // }`}
            min={moment(formData.fromDate).add(1, "days").format("L")}
            error={requiredFormData.toDate.status}
            helperText={requiredFormData.toDate.text}
            style={{ marginBottom: "20px" }}
          />
          <SelectInput
            label="Recurring Frequency"
            value={formData.frequency}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, frequency: data };
              });
            }}
            options={["Daily", "Weekly", "Monthly"]}
            placeholder="Daily"
            dropdownTop="47px"
            datalistTop="83px"
            readonly
            // height="120px"
          />
          {formData.frequency === "Weekly" && (
            <div className={styles.checkbox_form_container}>
              <div>
                <Typography
                  textWeight="600"
                  textSize="17px"
                  marginVertical="20px"
                  textAlign="left"
                >
                  Days of Week
                </Typography>
              </div>
              <div className={styles.radio_container}>
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Monday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.monday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, monday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Tuesday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.tuesday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, tuesday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Wednesday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.wednesday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, wednesday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Thursday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.thursday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, thursday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Friday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.friday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, friday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Saturday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.saturday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, saturday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Sunday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.sunday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, sunday: data },
                      };
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}

      <div className={styles.button_container}>
        <Button
          text="Cancel"
          width="100%"
          isPrimary={false}
          isPrimaryBorder
          backgroundColor="transparent"
          onClick={() =>
            dispatch(setShowAvalabilityModal({ show: false, minDate: "" }))
          }
          marginHorizontal="5px"
        />
        <Button
          width="100%"
          text="Save"
          isLoading={requestLoading}
          isPrimary
          marginHorizontal="5px"
          onClick={handleFormSubmit}
        />
      </div>
    </section>
  );
};

export default EmployeeAvalabilityModal;
