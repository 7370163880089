import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import TextInput from "../../../Form/TextInput";
import { WebUserProfileFormProps } from "../../../../types-definition/admin";
import Checkbox from "../../../Form/Checkbox";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import ClientSelectInput from "../../../Form/ClientSelectInput";
import { getRequest } from "../../../../api/request";
import {
  GET_ALL_CLIENTS_URL,
  SEARCH_EMPLOYEE,
  SEARCH_WEB_USER_URL,
} from "../../../../api/urls";
import PasswordInput from "../../../Form/PasswordInput";
import SelectInput from "../../../Form/SelectInput";
import UsernameInput from "../../../Form/UsernameInput";
import { getWebUser } from "../../../../utils/localstorage";
import NumberInput from "components/Form/NumberInput";
import NumberInputWithoutIcon from "components/Form/NumberInputWithoutIcon";

const WebUserForm: React.FC<WebUserProfileFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
  clientRequiredFormData,
  setClientRequiredFormData,
  clientName,
}) => {
  const [clientData, setClientData] = useState<Array<any>>([]);
  const [client, setClient] = useState(clientName);
  const [usernameState, setUsernameState] = useState({
    error: false,
    loading: false,
    message: "",
  });
  // disabled browser auto filling
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReadOnly(false);
    }, 1000);
  }, []);
  useEffect(() => {
    const getAllClients = async () => {
      const response = await getRequest(GET_ALL_CLIENTS_URL);
      if (response?.success) {
        setClientData(response.data.clients);
      }
    };
    getAllClients();
  }, []);
  useEffect(() => {
    setClient(clientName);
  }, [clientName]);

  useEffect(() => {
    if (getWebUser()) return;
    const validateUsername = async () => {
      if (formData.userName) {
        setUsernameState((prevData) => {
          return {
            ...prevData,
            loading: true,
            message: "Checking avalability",
          };
        });
        const response = await getRequest(
          `${SEARCH_WEB_USER_URL}/${formData.userName}`,
        );
        if (response?.success === true) {
          setUsernameState((prevData) => {
            return {
              ...prevData,
              error: true,
              message: "Username already exists",
              loading: false,
            };
          });
        } else {
          const response = await getRequest(
            `${SEARCH_EMPLOYEE}/${formData.userName}`,
          );
          if (response?.data) {
            setUsernameState((prevData) => {
              return {
                ...prevData,
                error: true,
                message: "Username already exists",
                loading: false,
              };
            });
          } else {
            setUsernameState((prevData) => {
              return { ...prevData, error: false, message: "", loading: false };
            });
          }
        }
        setUsernameState((prevData) => {
          return { ...prevData, loading: false };
        });
      }
    };
    validateUsername();
  }, [formData.userName]);
  return (
    <>
      <div className={styles.checkbox_container}>
        <Checkbox
          marginVertical="20px"
          value={formData.clientPortalUser}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, clientPortalUser: data };
            })
          }
          label={
            <Typography
              textAlign="left"
              textWeight="500"
              marginHorizontal="-10px"
              textColor={colors.gray5}
            >
              Client Portal User
            </Typography>
          }
          // disabled={getWebUser() ? true : false}
          isToolTip
          toolTipText="Select if you want to create user profile for your client to access certain part of the system"
        />
      </div>
      {formData.clientPortalUser && (
        <div>
          <ClientSelectInput
            setValue={(name, id) => {
              if (id) {
                setFormData((prevData) => {
                  return { ...prevData, client: id.toString() };
                });
              }
              setClient(name);
              setClientRequiredFormData((prevData) => {
                return { ...prevData, client: false };
              });
            }}
            value={formData.client}
            client={client}
            label="Client"
            placeholder="Select Client"
            dropdownTop="46px"
            datalistTop="83px"
            required={true}
            error={clientRequiredFormData.client}
            helperText={
              clientRequiredFormData.client ? "Client is required" : ""
            }
            options={clientData.map((data) => {
              return {
                name: data.clientName,
                id: data.id,
              };
            })}
            id="client"
          />
        </div>
      )}
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.firstName}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, firstName: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, firstName: false };
            });
            setClientRequiredFormData((prevData) => {
              return { ...prevData, firstName: false };
            });
          }}
          required={true}
          error={requiredFormData.firstName || clientRequiredFormData.firstName}
          helperText={
            requiredFormData.firstName || clientRequiredFormData.firstName
              ? "First Name is required"
              : ""
          }
          label="First Name"
          placeholder="Enter First Name"
          id="firstName"
        />
        <TextInput
          value={formData.lastName}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, lastName: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, lastName: false };
            });
            setClientRequiredFormData((prevData) => {
              return { ...prevData, lastName: false };
            });
          }}
          required={true}
          error={requiredFormData.lastName || clientRequiredFormData.lastName}
          helperText={
            requiredFormData.lastName || clientRequiredFormData.lastName
              ? "Last Name is required"
              : ""
          }
          label="Last Name"
          placeholder="Enter Last Name"
          id="lastName"
        />
      </div>
      <div className={styles.form_flex_container}>
        <UsernameInput
          onFocus={() => {
            if (!formData.userName) {
              setFormData((prevData) => {
                return {
                  ...prevData,
                  userName: `${formData.firstName}
                    ${formData.lastName}`
                    .replace(/\s/g, "")
                    .toLowerCase(),
                };
              });
            }
          }}
          value={formData.userName}
          disabled={getWebUser() ? true : false}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, userName: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, userName: false };
            });
            setClientRequiredFormData((prevData) => {
              return { ...prevData, userName: false };
            });
          }}
          label="User Name"
          placeholder="Enter User Name"
          error={
            requiredFormData.userName ||
            clientRequiredFormData.userName ||
            usernameState.error
          }
          required={true}
          isLoading={usernameState.loading}
          helperText={
            usernameState.message
              ? usernameState.message
              : requiredFormData.userName || clientRequiredFormData.userName
                ? "Username is required"
                : ""
          }
          id="userName"
        />
        <TextInput
          value={formData.position}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, position: data };
            });
          }}
          label="Position"
          placeholder="Enter Position"
        />
      </div>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.email}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, email: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, email: false };
            });
            setClientRequiredFormData((prevData) => {
              return { ...prevData, email: false };
            });
          }}
          label="Email Address"
          placeholder="Enter Email Address"
          error={requiredFormData.email || clientRequiredFormData.email}
          helperText={
            requiredFormData.email || clientRequiredFormData.email
              ? "Email is required"
              : ""
          }
          required={true}
          id="email"
          readOnly={readOnly}
        />
        <NumberInputWithoutIcon
          value={formData.telephone}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, telephone: data };
            })
          }
          label="Telephone"
          placeholder="Enter Telephone"
          readOnly={readOnly}
        />
      </div>

      <div className={styles.form_flex_container}>
        <PasswordInput
          value={formData.password}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, password: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, password: false };
            });
            setClientRequiredFormData((prevData) => {
              return { ...prevData, password: false };
            });
          }}
          label="Password"
          placeholder="Enter Password"
          error={requiredFormData.password || clientRequiredFormData.password}
          helperText={
            requiredFormData.password || clientRequiredFormData.password
              ? "Password is required"
              : ""
          }
          required={true}
          useValidation={true}
          id="password"
          readOnly={readOnly}
        />
        <PasswordInput
          value={formData.confirmPassword}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, confirmPassword: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, confirmPassword: false };
            });
            setClientRequiredFormData((prevData) => {
              return { ...prevData, confirmPassword: false };
            });
          }}
          label="Confirm Password"
          placeholder="Confirm Password"
          error={
            requiredFormData.confirmPassword ||
            clientRequiredFormData.confirmPassword
          }
          helperText={
            requiredFormData.confirmPassword ||
            clientRequiredFormData.confirmPassword
              ? "Confirm Password is required"
              : ""
          }
          required={true}
          style={{ marginBottom: "110px" }}
          id="confirmPassword"
          readOnly={readOnly}
        />
      </div>

      {!formData.clientPortalUser && (
        <>
          <div>
            <SelectInput
              label="Viewable Locations"
              placeholder="Select Viewable Location"
              value={formData.viewableLocation}
              dropdownTop="46px"
              datalistTop="83px"
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, viewableLocation: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, viewableLocation: false };
                });
              }}
              options={["All", "Managed Only"]}
              error={requiredFormData.viewableLocation}
              helperText={
                requiredFormData.viewableLocation ? "Location is required" : ""
              }
              required={true}
              isToolTip
              id="viewableLocation"
              toolTipText="Choosing 'All' allows the user to view data for all locations. Opting for 'Managed Only' restricts visibility to data for locations managed by the user and any subordinates"
            />
          </div>
          <div className={styles.checkbox_form_container}>
            <div>
              <Typography
                textWeight="600"
                textSize="17px"
                marginVertical="30px"
              >
                Email Conditions
              </Typography>
            </div>
            <div className={styles.radio_container}>
              <Checkbox
                marginVertical="10px"
                label={
                  <div>
                    <Typography
                      textAlign="left"
                      textWeight="600"
                      marginHorizontal="-10px"
                    >
                      Proximity Shift Start
                    </Typography>
                  </div>
                }
                value={formData.emailConditions.proximityShiftStart}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      emailConditions: {
                        ...prevData.emailConditions,
                        proximityShiftStart: data,
                      },
                    };
                  })
                }
                isToolTip
                toolTipText="When activated, the user can receive email alerts for Proximity Shift Start events"
                width="500px"
              />
              <Checkbox
                marginVertical="10px"
                label={
                  <div>
                    <Typography
                      textAlign="left"
                      textWeight="600"
                      marginHorizontal="-10px"
                    >
                      Proximity Shift End
                    </Typography>
                  </div>
                }
                value={formData.emailConditions.proximityShiftEnd}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      emailConditions: {
                        ...prevData.emailConditions,
                        proximityShiftEnd: data,
                      },
                    };
                  })
                }
                isToolTip
                toolTipText="When activated, the user can receive email alerts for Proximity Shift End events."
              />
              <Checkbox
                marginVertical="10px"
                label={
                  <div>
                    <Typography
                      textAlign="left"
                      textWeight="600"
                      marginHorizontal="-10px"
                    >
                      Proximity Check Call
                    </Typography>
                  </div>
                }
                value={formData.emailConditions.proximityCheckCall}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      emailConditions: {
                        ...prevData.emailConditions,
                        proximityCheckCall: data,
                      },
                    };
                  })
                }
                isToolTip
                toolTipText="When activated, the web user will receive Proximity Checkcall email alerts when employees are outside the proximity distance"
              />
              <Checkbox
                marginVertical="10px"
                label={
                  <div>
                    <Typography
                      textAlign="left"
                      textWeight="600"
                      marginHorizontal="-10px"
                    >
                      Cancelled Shift
                    </Typography>
                  </div>
                }
                value={formData.emailConditions.cancelledShift}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      emailConditions: {
                        ...prevData.emailConditions,
                        cancelledShift: data,
                      },
                    };
                  })
                }
                isToolTip
                toolTipText="When activated, the user will receive email alerts for cancellations"
              />
            </div>
          </div>
        </>
      )}
      <div className={styles.form_flex_container}>
        <Checkbox
          marginVertical="20px"
          value={formData.active}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, active: data };
            })
          }
          border={true}
          label={
            <Typography
              textAlign="left"
              textWeight="500"
              marginHorizontal="-10px"
              textColor={colors.gray5}
            >
              Active
            </Typography>
          }
        />
        <Checkbox
          marginVertical="20px"
          value={formData.lock}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, lock: data };
            })
          }
          border={true}
          label={
            <Typography
              textAlign="left"
              textWeight="500"
              marginHorizontal="-10px"
              textColor={colors.gray5}
            >
              Lock
            </Typography>
          }
        />
      </div>
    </>
  );
};

export default WebUserForm;
