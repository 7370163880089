import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import cancelIcon from "../../../../assets/svg/circle-cancel-icon.svg";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  setCurrentSelectedAbsenceHolidayEmployeeId,
  setHolidayAbscenceSummaryApprove,
  setHolidayAbscenceSummaryDate,
  setHolidayAbscenceSummaryFromToDate,
  setHolidayAbsenceSummaryFormInformation,
  setShowAdd,
  setShowConflictModal,
  updateAbsenceHolidayCounter,
  getHolidayAbsenceSummaryDate,
  getHolidayAbsenceSummaryFormInformation,
  getHolidayAbsenceSummaryFromDate,
  getHolidayAbsenceSummaryToDate,
  getCurrentSelectedHolidayAbsenceEmployeeId,
} from "../../../../redux/slice/HolidayAbsence/holidayAbsenceSlice";
import DateInput from "../../../Form/DateInput";
import { getRequest, postRequest } from "../../../../api/request";
import { GET_ALL_EMPLOYEE_URL } from "../../../../api/urls";
import { toastError, toastSuccess } from "../../../../utils/toast";
import SelectInput from "../../../Form/SelectInput";
import RadioInput from "../../../Form/RadioInput";
import {
  AddHolidayAbsenceAllDayRequiredFormData,
  AddHolidayAbsenceFormData,
  AddHolidayAbsenceHalfDayRequiredFormData,
} from "../../../../types-definition/StaffHub/holiday";
import ClientSelectInput from "../../../Form/ClientSelectInput";
import fieldTextValidator from "../../../../utils/validateFieldsWithText";
import moment from "moment";

const AddHolidayAbsence: React.FC = () => {
  const dispatch = useAppDispatch();
  const [requestLoading, setRequestLoading] = useState(false);
  const [allEmployees, setAllEmployees] = useState<Array<any>>([]);
  const [employeeName, setEmployeeName] = useState("");
  const employeeId = useAppSelector(getCurrentSelectedHolidayAbsenceEmployeeId);
  const fromDate = useAppSelector(getHolidayAbsenceSummaryFromDate);
  const toDate = useAppSelector(getHolidayAbsenceSummaryToDate);
  const date = useAppSelector(getHolidayAbsenceSummaryDate);
  const recordedFormData = useAppSelector(
    getHolidayAbsenceSummaryFormInformation,
  );
  const [formData, setFormData] = useState<AddHolidayAbsenceFormData>({
    employee: "",
    holidayType: "",
    description: "",
    allDay: true,
    fromDate: "",
    toDate: "",
    date: "",
    preferredTime: "",
  });
  const [allDayRequiredFormData, setAllDayRequiredFormData] =
    useState<AddHolidayAbsenceAllDayRequiredFormData>({
      employee: {
        status: false,
        text: "",
      },
      fromDate: {
        status: false,
        text: "",
      },
      toDate: {
        status: false,
        text: "",
      },
      holidayType: {
        status: false,
        text: "",
      },
    });

  const [halfDayRequiredFormData, setHalfDayRequiredFormData] =
    useState<AddHolidayAbsenceHalfDayRequiredFormData>({
      employee: {
        status: false,
        text: "",
      },
      date: {
        status: false,
        text: "",
      },
      preferredTime: {
        status: false,
        text: "",
      },
      holidayType: {
        status: false,
        text: "",
      },
    });

  const handleSubmitForm = async () => {
    setRequestLoading(true);
    // validator
    if (formData.allDay) {
      const { data, status } = fieldTextValidator(
        formData,
        allDayRequiredFormData,
      );
      if (!status) {
        setAllDayRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        setRequestLoading(false);
        return;
      }
    } else {
      const { data, status } = fieldTextValidator(
        formData,
        halfDayRequiredFormData,
      );
      if (!status) {
        setHalfDayRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        setRequestLoading(false);
        return;
      }
    }
    // Request to the server
    const holidays: Array<any> = [];
    const fromDate = new Date(formData.fromDate);
    const toDate = new Date(formData.toDate);

    if (formData.allDay) {
      for (let i = fromDate; i <= toDate; i.setDate(i.getDate() + 1)) {
        const holiday = {
          // date: moment(new Date(i)).format("DD/MM/YYYY"),
          date: moment.utc(new Date(i)).toISOString(),
          holidayType: formData.holidayType,
          description: formData.description,
          timeOff: "allDay",
        };
        holidays.push(holiday);
      }
      const response = await postRequest(
        `employee/${formData.employee}/holiday`,
        holidays,
      );
      if (response?.success) {
        toastSuccess("Time Off request created successfully");
        dispatch(updateAbsenceHolidayCounter());
        dispatch(setShowAdd(false));
      } else {
        if (response?.data?.includes("Holiday shift clash at")) {
          dispatch(
            setCurrentSelectedAbsenceHolidayEmployeeId(formData.employee),
          );
          dispatch(setHolidayAbscenceSummaryApprove(false));
          dispatch(
            setHolidayAbscenceSummaryFromToDate({
              fromDate: formData.fromDate,
              toDate: formData.toDate,
            }),
          );
          dispatch(
            setHolidayAbsenceSummaryFormInformation({
              type: formData.holidayType,
              description: formData.description,
              preferredTime: formData.preferredTime,
              employeeName: employeeName,
            }),
          );
          dispatch(setShowConflictModal(true));
          dispatch(setShowAdd(false));
        } else if (response?.data) toastError(response?.data);
        setRequestLoading(false);
        return;
      }
    } else {
      const response = await postRequest(
        `employee/${formData.employee}/holiday`,
        [
          {
            holidayType: formData.holidayType,
            timeOff: "halfDay",
            // date: moment(new Date(formData.date)).format("DD/MM/YYYY"),
            preferredTime: formData.preferredTime,
            description: formData.description,
            date: moment.utc(new Date(formData.date)).toISOString(),
          },
        ],
      );
      if (response?.success) {
        toastSuccess("Time Off request created successfully");
        dispatch(updateAbsenceHolidayCounter());
        dispatch(setShowAdd(false));
      } else {
        if (response?.data?.includes("Holiday shift clash at")) {
          dispatch(
            setCurrentSelectedAbsenceHolidayEmployeeId(formData.employee),
          );
          dispatch(setHolidayAbscenceSummaryDate(formData.date));
          dispatch(setHolidayAbscenceSummaryApprove(false));
          dispatch(
            setHolidayAbsenceSummaryFormInformation({
              type: formData.holidayType,
              description: formData.description,
              preferredTime: formData.preferredTime,
              employeeName: employeeName,
            }),
          );
          dispatch(setShowConflictModal(true));
          dispatch(setShowAdd(false));
        } else if (response?.data) toastError(response?.data);
        setRequestLoading(false);
        return;
      }
    }
    setRequestLoading(false);
  };

  useEffect(() => {
    const getAllEmployees = async () => {
      const response = await getRequest(GET_ALL_EMPLOYEE_URL);
      if (response?.success) {
        setAllEmployees(
          response?.data?.map((item: any) => {
            return {
              name: `${item.firstName} ${item.lastName}`,
              id: item.id,
            };
          }),
        );
      }
    };
    getAllEmployees();
  }, []);
  useEffect(() => {
    if (recordedFormData.type) {
      setFormData((prevData) => {
        return {
          ...prevData,
          holidayType: recordedFormData.type,
          description: recordedFormData.description,
          preferredTime: recordedFormData.preferredTime,
          date: date,
          fromDate: fromDate,
          toDate: toDate,
          employee: employeeId,
        };
      });
      setEmployeeName(recordedFormData.employeeName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600">Add Holiday</Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() => dispatch(setShowAdd(false))}
          />
        </div>
        <div className={styles.form_container}>
          <div className={styles.form_container_input}>
            <ClientSelectInput
              dropdownTop="48px"
              datalistTop="83px"
              placeholder="
              Select Employee"
              label="Employee"
              value={formData.employee}
              setValue={(name, id) => {
                if (name) {
                  setEmployeeName(name);
                } else {
                  setEmployeeName("");
                }
                if (id) {
                  setFormData((prevData) => {
                    return { ...prevData, employee: id };
                  });
                  setAllDayRequiredFormData((prevData) => {
                    return {
                      ...prevData,
                      employee: { status: false, text: "" },
                    };
                  });
                  setHalfDayRequiredFormData((prevData) => {
                    return {
                      ...prevData,
                      employee: { status: false, text: "" },
                    };
                  });
                }
              }}
              helperText={
                allDayRequiredFormData.employee.text ||
                halfDayRequiredFormData.employee.text
              }
              error={
                allDayRequiredFormData.employee.status ||
                halfDayRequiredFormData.employee.status
              }
              client={employeeName}
              options={allEmployees}
            />
            <SelectInput
              placeholder="
              Select Holiday Type"
              dropdownTop="48px"
              label="Holiday Type"
              datalistTop="83px"
              value={formData.holidayType}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, holidayType: data };
                });
                setAllDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    holidayType: { status: false, text: "" },
                  };
                });
                setHalfDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    holidayType: { status: false, text: "" },
                  };
                });
              }}
              helperText={
                allDayRequiredFormData.holidayType.text ||
                halfDayRequiredFormData.holidayType.text
              }
              error={
                allDayRequiredFormData.holidayType.status ||
                halfDayRequiredFormData.holidayType.status
              }
              options={[
                "Leave",
                "Suspended",
                "Maternity",
                "Sickness",
                "AWOL",
                "Unpaid Leave",
                "Paternity",
                "COVID",
                "Other",
                "Compassionate",
              ]}
            />

            <TextInput
              placeholder="Enter Description"
              label="Description"
              value={formData.description}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, description: data };
                });
              }}
            />
          </div>
          <Typography
            textAlign="left"
            fontFamily="inter"
            textWeight="600"
            textColor={colors.tableText}
            marginVertical="0px"
          >
            Time Off
          </Typography>
          <div className={styles.form_container_checkbox_flex}>
            <div>
              {" "}
              <RadioInput
                label="All Day"
                name="plan-type"
                value={formData.allDay}
                setValue={() =>
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      allDay: true,
                    };
                  })
                }
              />
            </div>

            <RadioInput
              width="150px"
              label="Half Day"
              name=""
              value={!formData.allDay}
              setValue={() =>
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    allDay: false,
                  };
                })
              }
            />
          </div>
        </div>
        {formData.allDay ? (
          <div className={styles.flex_item}>
            <DateInput
              value={formData.fromDate}
              setValue={(data: any) => {
                setFormData((prevData) => {
                  return { ...prevData, fromDate: data };
                });
                setAllDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    fromDate: { status: false, text: "" },
                  };
                });
              }}
              label="From"
              placeholder=""
              helperText={allDayRequiredFormData.fromDate.text}
              error={allDayRequiredFormData.fromDate.status}
            />
            <DateInput
              value={formData.toDate}
              setValue={(data: any) => {
                setFormData((prevData) => {
                  return { ...prevData, toDate: data };
                });

                setAllDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    toDate: { status: false, text: "" },
                  };
                });
              }}
              validateMin
              min={formData.fromDate}
              label="To"
              placeholder=""
              width={"auto"}
              helperText={allDayRequiredFormData.toDate.text}
              error={allDayRequiredFormData.toDate.status}
            />
          </div>
        ) : (
          <div className={styles.form_item}>
            <SelectInput
              placeholder="Select Preferred Time"
              value={formData.preferredTime}
              label="Preferred Time"
              setValue={(data) => {
                setFormData({ ...formData, preferredTime: data });
                setHalfDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    preferredTime: { status: false, text: "" },
                  };
                });
              }}
              helperText={halfDayRequiredFormData.preferredTime.text}
              error={halfDayRequiredFormData.preferredTime.status}
              dropdownTop="46px"
              datalistTop="84px"
              options={["AM", "PM"]}
            />
            <DateInput
              placeholder=""
              value={formData.date}
              label="Date"
              setValue={(data) => {
                setFormData({ ...formData, date: data });
                setHalfDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    date: { status: false, text: "" },
                  };
                });
              }}
              helperText={halfDayRequiredFormData.date.text}
              error={halfDayRequiredFormData.date.status}
            />
          </div>
        )}
        <div className={styles.button_container}>
          <Button
            text="Cancel"
            isPrimary={false}
            onClick={() => dispatch(setShowAdd(false))}
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
          />
          <Button
            text="Save"
            isPrimary={true}
            onClick={handleSubmitForm}
            isLoading={requestLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddHolidayAbsence;
