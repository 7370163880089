import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { getRequest } from "../../../../../api/request";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import colors from "../../../../../theme/colors";
import Typography from "../../../../Common/Typography";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import PageBackButton from "../../../../Common/PageBackButton";

const ActivityEmployeeEmergencyContactInformationUk = () => {
  const id = getActivityBasicInformation();
  const [contactDetails, setContactDetails] = useState({
    contactName: "",
    contactRelationship: "",
    addressLine1: "",
    addressLine2: "",
    town: "",
    postCode: "",
    mobileNumber: "",
    email: "",
  });
  const [previousContactDetails, setPreviousContactDetails] = useState({
    contactName: "",
    contactRelationship: "",
    addressLine1: "",
    addressLine2: "",
    town: "",
    postCode: "",
    mobileNumber: "",
    email: "",
  });
  useEffect(() => {
    const getActivityEmployeeContactInformation = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        setContactDetails({
          contactName: response?.data?.newRecord?.emergencyContact?.contactName,
          contactRelationship:
            response?.data?.newRecord?.emergencyContact?.relationship,
          addressLine1: response?.data?.newRecord?.emergencyContact?.address1,
          addressLine2: response?.data?.newRecord?.emergencyContact?.address2,
          town: response?.data?.newRecord?.emergencyContact?.town,
          postCode: response?.data?.newRecord?.emergencyContact?.postcode,
          mobileNumber: response?.data?.newRecord?.emergencyContact?.telephone,
          email: response?.data?.newRecord?.emergencyContact?.email,
        });
        setPreviousContactDetails({
          contactName:
            response?.data?.previousRecord?.emergencyContact?.contactName,
          contactRelationship:
            response?.data?.previousRecord?.emergencyContact?.relationship,
          addressLine1:
            response?.data?.previousRecord?.emergencyContact?.address1,
          addressLine2:
            response?.data?.previousRecord?.emergencyContact?.address2,
          town: response?.data?.previousRecord?.emergencyContact?.town,
          postCode: response?.data?.previousRecord?.emergencyContact?.postcode,
          mobileNumber:
            response?.data?.previousRecord?.emergencyContact?.telephone,
          email: response?.data?.previousRecord?.emergencyContact?.email,
        });
      }
    };
    getActivityEmployeeContactInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Emergency Contact Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Contact Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.contactName
                    ? contactDetails.contactName
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Relationship</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.contactRelationship
                    ? contactDetails.contactRelationship
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Address Line 1</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.addressLine1
                    ? contactDetails.addressLine1
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Address Line 2</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.addressLine2
                    ? contactDetails.addressLine2
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Town</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.town ? contactDetails.town : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Postcode</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.postCode ? contactDetails.postCode : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Mobile Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.mobileNumber
                    ? contactDetails.mobileNumber
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Email</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.email ? contactDetails.email : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Contact Information */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Emergency Contact Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Contact Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.contactName
                    ? previousContactDetails.contactName
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Relationship</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.contactRelationship
                    ? previousContactDetails.contactRelationship
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Address Line 1</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.addressLine1
                    ? previousContactDetails.addressLine1
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Address Line 2</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.addressLine2
                    ? previousContactDetails.addressLine2
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Town</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.town
                    ? previousContactDetails.town
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Post Code</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.postCode
                    ? previousContactDetails.postCode
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Mobile Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.mobileNumber
                    ? previousContactDetails.mobileNumber
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Email</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.email
                    ? previousContactDetails.email
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityEmployeeEmergencyContactInformationUk;
