import React from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import colors from "../../../../theme/colors";
import { ClientEmployeeShiftNavigationProps } from "../../../../types-definition/client";

const ClientEmployeeShiftNavigation: React.FC<
  ClientEmployeeShiftNavigationProps
> = ({ active, setActive }) => {
  return (
    <div className={styles.container}>
      <div
        className={
          active === "expected-employee-shift"
            ? styles.active_container_item
            : styles.container_item_left
        }
        onClick={() => {
          setActive("expected-employee-shift");
        }}
      >
        <Typography
          textWeight="600"
          fontFamily="inter"
          textColor={
            active === "expected-employee-shift" ? colors.white : colors.black
          }
        >
          Expected Employee Shifts
        </Typography>
      </div>
      <div
        className={
          active === "recently-active-employee"
            ? styles.active_container_item_right
            : styles.container_item_right
        }
        onClick={() => {
          setActive("recently-active-employee");
        }}
      >
        <Typography
          textWeight="600"
          fontFamily="inter"
          textColor={
            active === "recently-active-employee" ? colors.white : colors.black
          }
        >
          Recently Active Employees
        </Typography>
      </div>
    </div>
  );
};

export default ClientEmployeeShiftNavigation;
