import React from "react";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import Checkbox from "../../../Form/Checkbox";
import styles from "./index.module.css";
import { CertificateGroupFormProps } from "../../../../types-definition/StaffHub/certificateGroup";

const AddCertificateGroupForm: React.FC<CertificateGroupFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  return (
    <>
      <div className={styles.form_input}>
        <TextInput
          value={formData.certificateName}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, certificateName: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, certificateName: false };
            });
          }}
          label="Certificate Name"
          placeholder="Enter Certificate Name"
          required={true}
          error={requiredFormData.certificateName}
          helperText={
            requiredFormData?.certificateName ? "Certificate Group Name" : ""
          }
        />
      </div>
      <div>
        <TextInput
          value={formData.description}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, description: data };
            })
          }
          label="Description"
          placeholder="Enter Description"
        />
        <Checkbox
          marginVertical="20px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Active
              </Typography>
            </div>
          }
          value={formData.active}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, active: data };
            })
          }
        />
      </div>
    </>
  );
};

export default AddCertificateGroupForm;
