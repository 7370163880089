import DashboardHeader from "components/Common/DashboardHeader";
import PageLoader from "components/Common/PageLoader";
import PaginatedItems from "components/Common/Pagination";
import ScrollToTop from "components/Common/ScrollToTop";
import Typography from "components/Common/Typography";
import SelectInput from "components/Form/SelectInput";
import CheckpointReportTable from "components/Report/CheckpointReport/CheckpointReportTable";
import { useEffect, useState } from "react";
import colors from "theme/colors";
import styles from "./index.module.css";
import { getRequest } from "api/request";
import { GET_SERVICE_URL, SERVICE_CHECKPOINT } from "api/urls";
import { CheckpointReportData } from "types-definition/report";

const CheckpointsByService = () => {
  const [requestLoading, setRequestLoading] = useState(false);

  const [services, setServices] = useState<any>([]);
  const [checkpoints, setCheckpoints] = useState<Array<CheckpointReportData>>(
    [],
  );
  const [filteredCheckpoints, setFilteredCheckpoints] = useState<
    Array<CheckpointReportData>
  >([]);

  const [currentService, setCurrentService] = useState("");

  const [pagination, setPagination] = useState<any>({
    start: 0,
    end: 10,
    event: 1,
  });
  const handlePaginate = (start: number, end: number, event?: any) => {
    setPagination({
      start,
      end,
      event,
    });
  };

  useEffect(() => {
    const getServices = async () => {
      const response = await getRequest(GET_SERVICE_URL);
      const mappedServices = response?.data?.map((service: any) => ({
        id: service?.id,
        name: service?.name,
      }));

      setServices(mappedServices);
    };

    const getCheckpoints = async () => {
      setRequestLoading(true);
      const response = await getRequest(SERVICE_CHECKPOINT);
      const checkpoints = response?.data;

      const mappedCheckpoints = checkpoints?.map((checkpoint: any) => ({
        id: checkpoint?.id,
        checkpointName: checkpoint?.checkpointName,
        section: checkpoint?.section,
        longitude: checkpoint?.longitude,
        latitude: checkpoint?.latitude,
        time: checkpoint?.time,
        frequency: checkpoint?.frequency,
        service: {
          id: checkpoint?.service?.id,
          name: checkpoint?.service?.name,
          grade: checkpoint?.service?.grade,
          shiftStart: checkpoint?.service?.shiftStart,
          shiftEnd: checkpoint?.service?.shiftEnd,
        },
        location: {
          id: checkpoint?.location?.id,
          name: checkpoint?.location?.name,
        },
      }));

      setCheckpoints(mappedCheckpoints);
      setRequestLoading(false);
    };

    getServices();
    getCheckpoints();
  }, []);

  useEffect(() => {
    setFilteredCheckpoints(
      currentService
        ? checkpoints.filter(
            (checkpoint) => checkpoint.service.name === currentService,
          )
        : checkpoints,
    );
  }, [checkpoints, currentService]);

  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="reports" />
        <div className={styles.container}>
          <div
            className={
              checkpoints?.length! <= 5
                ? `${styles.item_container} ${styles.item_container_empty}`
                : `${styles.item_container} ${styles.item_container_fill}`
            }
          >
            <div className={styles.flex_item}>
              <div className={styles.sub_flex_item} style={{ gap: "1rem" }}>
                <Typography textAlign="left" textWeight="600" textSize="20px">
                  Checkpoints
                </Typography>
                <Typography
                  textAlign="left"
                  textColor={colors.primary3}
                  textWeight="600"
                  textSize="20px"
                >
                  {currentService}
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <SelectInput
                  label=""
                  placeholder="Select Service"
                  value={currentService}
                  dropdownTop="27px"
                  datalistTop="65px"
                  width="300px"
                  setValue={(value) => setCurrentService(value)}
                  options={services.map((service: any) => service.name).sort()}
                />
              </div>
            </div>
            {filteredCheckpoints?.length === 0 ? (
              <div className={styles.empty_container}>
                <Typography marginVertical="5rem" paddingVertical="20px">
                  No checkpoints for <strong>{currentService}</strong>
                </Typography>
              </div>
            ) : (
              <>
                <CheckpointReportTable data={filteredCheckpoints} />

                <div className={styles.pagination_container}>
                  <PaginatedItems
                    items={Array(checkpoints.length).fill(null)}
                    itemsPerPage={10}
                    handlePaginate={handlePaginate}
                    checkFirstPage={pagination.event}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckpointsByService;
