import React, { useState } from "react";
import Typography from "../Typography";
import warningIcon from "../../../assets/svg/warning-icon.svg";
import styles from "./index.module.css";
import Button from "../../Form/Button";
import colors from "../../../theme/colors";

const ServiceConfirmationModal: React.FC<{
  service: string;
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ service, onConfirm, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={styles.main_container}>
      <div className={styles.container}>
        <img
          src={warningIcon}
          alt="cancel icon"
          className={styles.cancelImage}
        />
        <Typography textWeight="600" textSize="18px" textAlign="left">
          Confirm Service Update!
        </Typography>
        <div className={styles.mid_text}>
          <Typography textColor={colors.gray4} textAlign="left">
            Changes to <strong>"{service}"</strong> will affect already
            generated shifts. Do you want to proceed?
          </Typography>
        </div>

        <div className={styles.button_container}>
          <Button
            width="100%"
            text="Cancel"
            textColor={colors.white}
            isPrimary={false}
            backgroundColor={colors.primary3}
            borderWidth="1px"
            textWeight="500"
            onClick={onCancel}
            paddingVertical="6px"
          />
          <Button
            width="100%"
            text="Confirm"
            textColor={colors.white}
            isPrimary={false}
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
            borderWidth="1px"
            textWeight="500"
            paddingVertical="6px"
            onClick={onConfirm}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceConfirmationModal;
