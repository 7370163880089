import { useEffect, useState } from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import RosterViewNavigation from "components/Roster/RosterViewNavigation";
import RosterDateNavigation from "components/Roster/RosterDateNavigation";
import RosterTable from "components/Roster/RosterTable";
import AvaialableEmployeeRoster from "components/Roster/AvailableEmployeeRoster";
import EmployeeListRoster from "components/Roster/EmployeeListRoster";
import RosterColorIdentifier from "components/Roster/RosterColorIdentifier";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getActiveRosterSingleItem,
  getRosterAvailabilityDate,
  getRosterCalendarView,
  getRosterFromDate,
  getRosterRequestCounter,
  getRosterServiceId,
  getRosterView,
  setRosterLocationId,
  setRosterServiceData,
} from "../../../redux/slice/roster/rosterSlice";
import RosterCalendarViewNavigation from "components/Roster/RosterCalendarViewNavigation";
import RosterDayViewTable from "components/Roster/RosterDayViewTable";
import RosterWeekViewTable from "components/Roster/RosterWeekViewTable";
import RosterMonthView from "components/Roster/RosterMonthView";
import { getRequest, postRequest } from "api/request";
import { ACTIVE_BOARD, GET_LOCATION_URL } from "api/urls";
import ClientSelectInput from "components/Form/ClientSelectInput";
import filterKeysFromArray from "utils/filterKeyFromArray";
import PageLoader from "components/Common/PageLoader";
import { toastError } from "utils/toast";
import moment from "moment";

const Roster = () => {
  const dispatch = useAppDispatch();
  const view = useAppSelector(getRosterView);
  const calendarView = useAppSelector(getRosterCalendarView);
  const activeRoosterItem = useAppSelector(getActiveRosterSingleItem);
  const rosterAvailableDate = useAppSelector(getRosterAvailabilityDate);
  const rosterServiceId = useAppSelector(getRosterServiceId);
  const counter = useAppSelector(getRosterRequestCounter);
  const [requestLoading, setRequestLoading] = useState(true);
  const [isRosterLoading, setIsRosterLoading] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [sectionData, setSectionData] = useState<Array<any>>([]);
  const [availableEmployeeData, setAvailableEmployeeData] = useState<
    Array<{ employeeName: string; employeeId: string }>
  >([]);

  const fromDate = useAppSelector(getRosterFromDate);

  const getActiveBoardByLocation = async (id: string) => {
    setRequestLoading(true);

    let queryFromDate = moment(fromDate, "MM/DD/yyyy").toISOString();
    let queryToDate = moment(fromDate, "MM/DD/yyyy")
      .endOf("week")
      .add(1, "day")
      .toISOString();

    if (view === "service-view") {
      queryToDate = moment(fromDate, "MM/DD/yyyy")
        .endOf("week")
        .add(1, "day")
        .toISOString();
    } else if (calendarView === "day") {
      queryToDate = moment(fromDate, "MM/DD/yyyy").endOf("day").toISOString();
    } else if (calendarView === "month") {
      queryToDate = moment(fromDate, "MM/DD/yyyy").endOf("month").toISOString();
    }

    // console.log({ queryFromDate, queryToDate, from: moment(fromDate, "MM/DD/yyyy") });

    const response = await getRequest(
      `${ACTIVE_BOARD}/location/${id}?perPage=9999&from=${queryFromDate}&to=${queryToDate}`,
    );
    let serviceSegment: Array<any> = [];
    if (response?.success) {
      const filteredData = response?.data?.activeBoard
        .filter((i: any) => i?.employee?.id)
        .sort((a: any, b: any) => {
          let firstStartTime = moment(a?.startTime, "HH:mm").unix();
          let secondStartTime = moment(b?.startTime, "HH:mm").unix();
          return firstStartTime - secondStartTime;
        });
      dispatch(setRosterServiceData(filteredData));
      const serviceIds = filterKeysFromArray(filteredData, "serviceId");
      for (const serviceId of serviceIds) {
        const data = filteredData?.filter(
          (i: any) => i.serviceId === serviceId,
        );
        const serviceName = data[0]?.service?.name;
        serviceSegment = [
          ...serviceSegment,
          { shiftName: serviceName, data: data },
        ];
      }
      setSectionData(serviceSegment);
      setRequestLoading(false);
    } else {
      setRequestLoading(false);
      toastError(response?.data);
    }
  };
  const getSuggestedEmployee = async (id: string) => {
    try {
      // const fromDateItem = new Date();
      setIsRosterLoading(true);
      const response = await postRequest(
        `${ACTIVE_BOARD}/service/${id}/suggest`,
        {
          date: rosterAvailableDate,
        },
      );
      if (response?.success) {
        const removeDuplicatedEmployees = response?.data
          ?.filter(
            (item: any, index: number, array: any) =>
              array.findIndex((i: any) => i.employeeId === item.employeeId) ===
              index,
          )
          .map((i: any) => {
            return {
              employeeName: `${i?.employee?.firstName} ${i?.employee?.lastName}`,
              employeeId: i.employeeId,
            };
          });
        const allAvailableEmployees = [
          // ...availableEmployeeData,
          ...removeDuplicatedEmployees,
        ];

        setAvailableEmployeeData(
          allAvailableEmployees.filter(
            (item: any, index: number, array: any) =>
              array.findIndex((i: any) => i.employeeId === item.employeeId) ===
              index,
          ),
        );

        // setAvailableEmployeeData(
        //   response.data.map((item: any) => {
        //     return { employeeName: item.employeeName, employeeId: item.id };
        //   }),
        // );
      }

      setIsRosterLoading(false);
    } catch (error) {
      setIsRosterLoading(false);
    }
  };
  useEffect(() => {
    const getLocation = async () => {
      const response = await getRequest(GET_LOCATION_URL);
      if (response?.success) {
        setLocationId(response?.data[0]?.id ?? "");
        setLocationName(response?.data[0]?.name ?? "");
        setLocationData(
          response?.data.map((i: any) => {
            return {
              name: i.name,
              id: i.id,
            };
          }),
        );
      }
    };
    getLocation();
  }, []);

  useEffect(() => {
    if (locationId) {
      getActiveBoardByLocation(locationId);
      dispatch(setRosterLocationId(locationId));
    } else {
      // setRequestLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, locationId, counter]);
  useEffect(() => {
    if (rosterAvailableDate && activeRoosterItem && rosterServiceId) {
      getSuggestedEmployee(rosterServiceId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rosterServiceId, activeRoosterItem]);
  return (
    <>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="operations" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_sub_container}>
            <div className={styles.flex_item_container}>
              <div className={styles.roster_navigation_container}>
                <RosterViewNavigation />
                <div className={styles.roster_navigation_flex_container}>
                  <RosterDateNavigation />
                  {view === "calendar-view" && <RosterCalendarViewNavigation />}
                </div>
              </div>
              <div className={styles.select_container}>
                <ClientSelectInput
                  label="Location"
                  value=""
                  setValue={(name, id) => {
                    if (name) {
                      setLocationName(name);
                    }
                    if (id) {
                      setLocationId(id);
                    }
                  }}
                  client={locationName}
                  placeholder="Ebeanor"
                  dropdownTop="48px"
                  datalistTop="83px"
                  options={locationData}
                  readonly
                />
              </div>
            </div>
            <div className={styles.flex_item_container_1}>
              <div className={styles.roster_table}>
                {view === "service-view" ? (
                  <RosterTable data={sectionData} />
                ) : calendarView === "day" ? (
                  <RosterDayViewTable />
                ) : calendarView === "week" ? (
                  <RosterWeekViewTable />
                ) : (
                  <RosterMonthView />
                )}
              </div>

              <div className={styles.employee_table}>
                <AvaialableEmployeeRoster
                  isLoading={isRosterLoading}
                  data={availableEmployeeData}
                />

                <EmployeeListRoster />
              </div>
            </div>
            {view === "service-view" && <RosterColorIdentifier />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Roster;
