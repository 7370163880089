import React from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import EmployeeResetPasswordSuccessModal from "components/StaffHub/Employee/ViewProfile/ResetPasswordSuccessModal";
import { useAppSelector } from "../../../../redux/hooks";
// import {
//   getShowConfirmResetPasswordModal,
//   getShowSuccessResetPasswordModal,
// } from "redux/slice/staff-hub/employeeViewProfileSlice";
import {
  getShowConfirmResetPasswordModal,
  getShowSuccessResetPasswordModal,
} from "../../../../redux/slice/staff-hub/employeeViewProfileSlice";
import EmployeeResetPasswordConfirmModal from "components/StaffHub/Employee/ViewProfile/ResetPasswordConfirmModal";
import { getCountry } from "utils/localstorage";
import { UK } from "types-definition/constants";
import ActivityBasicInformationUk from "components/Admin/Activity Logs/EmployeeActivityLogs/ActivityBasicInformationUk";
import ActivityBasicInformationNonUk from "components/Admin/Activity Logs/EmployeeActivityLogs/ActivityBasicInformation";
const ActivityViewProfile = () => {
  const showSuccessModal = useAppSelector(getShowSuccessResetPasswordModal);
  const showConfirmModal = useAppSelector(getShowConfirmResetPasswordModal);
  const country = getCountry();
  return (
    <div className={styles.container}>
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      {country === UK ? (
        <div className={styles.container}>
          <ActivityBasicInformationUk />
        </div>
      ) : (
        <ActivityBasicInformationNonUk />
      )}
      {showSuccessModal && <EmployeeResetPasswordSuccessModal />}
      {showConfirmModal && <EmployeeResetPasswordConfirmModal />}
    </div>
  );
};

export default ActivityViewProfile;
