import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

interface InitialStateInterface {
  showAddModal: boolean;
  showEditModal: boolean;
  showConflictModal: boolean;
  counter: number;
  updateEditCounter: number;
  fromDate: string;
  toDate: string;
  date: string;
  currentSelectedId: string;
  currentSelectedEmployeeId: string;
  formInformation: {
    type: string;
    preferredTime: string;
    description: string;
    employeeName: string;
  };
  approve: boolean;
}

// Define the initial state using that type
const initialState: InitialStateInterface = {
  showAddModal: false,
  showEditModal: false,
  showConflictModal: false,
  counter: 0,
  updateEditCounter: 0,
  fromDate: "",
  toDate: "",
  date: "",
  currentSelectedId: "",
  currentSelectedEmployeeId: "",
  formInformation: {
    type: "",
    preferredTime: "",
    description: "",
    employeeName: "",
  },
  approve: false,
};

export const holidayAbsenceSlice = createSlice({
  name: "holidayAbsence",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setShowAdd: (state, action: PayloadAction<boolean>) => {
      state.showAddModal = action.payload;
    },
    setShowConflictModal: (state, action: PayloadAction<boolean>) => {
      state.showConflictModal = action.payload;
    },
    setShowEditAbsenceHolidayModal: (state, action: PayloadAction<boolean>) => {
      state.showEditModal = action.payload;
    },
    updateAbsenceHolidayCounter: (state) => {
      state.counter += 1;
    },
    updateAbsenceHolidayEditCounter: (state) => {
      state.updateEditCounter += 1;
    },
    setCurrentSelectedAbsenceHolidayId: (state, action) => {
      state.currentSelectedId = action.payload;
    },
    setCurrentSelectedAbsenceHolidayEmployeeId: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.currentSelectedEmployeeId = action.payload;
    },
    setHolidayAbscenceSummaryFromToDate: (
      state,
      action: PayloadAction<{ fromDate: string; toDate: string }>,
    ) => {
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
    setHolidayAbscenceSummaryDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setHolidayAbsenceSummaryFormInformation: (
      state,
      action: PayloadAction<{
        type: string;
        preferredTime: string;
        description: string;
        employeeName: string;
      }>,
    ) => {
      state.formInformation = action.payload;
    },
    setHolidayAbscenceSummaryApprove: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.approve = action.payload;
    },
  },
});

export const {
  setShowConflictModal,
  setShowAdd,
  updateAbsenceHolidayCounter,
  setCurrentSelectedAbsenceHolidayId,
  setShowEditAbsenceHolidayModal,
  setCurrentSelectedAbsenceHolidayEmployeeId,
  setHolidayAbscenceSummaryFromToDate,
  setHolidayAbscenceSummaryDate,
  setHolidayAbsenceSummaryFormInformation,
  setHolidayAbscenceSummaryApprove,
  updateAbsenceHolidayEditCounter,
} = holidayAbsenceSlice.actions;
// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;
// export const IsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const getShowAddModal = (state: RootState) =>
  state.holidayAbsence.showAddModal;
export const getShowConflictModal = (state: RootState) =>
  state.holidayAbsence.showConflictModal;
export const getCurrentSelectedHolidayAbsenceId = (state: RootState) =>
  state.holidayAbsence.currentSelectedId;
export const getCurrentSelectedHolidayAbsenceEmployeeId = (state: RootState) =>
  state.holidayAbsence.currentSelectedEmployeeId;
export const getEmployeeHolidayAbsenceSummaryCounter = (state: RootState) =>
  state.holidayAbsence.counter;
export const getEmployeeHolidayEditAbsenceSummaryCounter = (state: RootState) =>
  state.holidayAbsence.updateEditCounter;
export const getShowEditHolidayAbsenceModal = (state: RootState) =>
  state.holidayAbsence.showEditModal;
export const getHolidayAbsenceSummaryFromDate = (state: RootState) =>
  state.holidayAbsence.fromDate;
export const getHolidayAbsenceSummaryToDate = (state: RootState) =>
  state.holidayAbsence.toDate;
export const getHolidayAbsenceSummaryDate = (state: RootState) =>
  state.holidayAbsence.date;
export const getHolidayAbsenceSummaryFormInformation = (state: RootState) =>
  state.holidayAbsence.formInformation;
export const getHolidayAbsenceSummaryApprove = (state: RootState) =>
  state.holidayAbsence.approve;

export default holidayAbsenceSlice.reducer;
