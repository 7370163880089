import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import viewIcon from "../../../../assets/svg/view-icon.svg";
import moment from "moment";
import { EmployeeUploadedItemProps } from "../../../../types-definition/StaffHub/employee";
import EmployeeViewUploadedItem from "../EmployeeViewUploadedItem";

const EmployeeUploadedItem: React.FC<EmployeeUploadedItemProps> = ({
  label,
  value,
  width,
}) => {
  const [showFile, setShowFile] = useState(false);
  const [currentImage, setCurrentImage] = useState<any>(null);
  useEffect(() => {
    if (value) {
      setCurrentImage(value);
    } else {
      setCurrentImage(null);
    }
  }, [value]);

  return (
    <>
      <div className={styles.main_container}>
        <Typography textAlign="left" textWeight="600">
          {label}
        </Typography>
        <div className={styles.container} style={{ width }}>
          <div className={styles.content_filled_container}>
            <Typography
              fontFamily="inter"
              textAlign="center"
              textWeight="600"
              paddingVertical={currentImage?.originalName ? "3px" : "25px"}
            >
              {currentImage?.originalName ?? "No File Uploaded"}
            </Typography>
            {currentImage?.originalName && (
              <Typography
                fontFamily="inter"
                textSize="12px"
                marginVertical="-6px"
                style={{ width: "100%" }}
              >
                {currentImage?.size / 1000}KB .{" "}
                {moment(currentImage?.lastModifiedDate).format("DD MMM, YYYY")}
              </Typography>
            )}
          </div>
          {currentImage?.originalName && (
            <div className={styles.action_item_container}>
              <div
                className={styles.action_item}
                onClick={() => setShowFile(true)}
              >
                <img src={viewIcon} alt="delete icon" />
                <Typography textColor={colors.primary3} textWeight="500">
                  View Upload
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
      {showFile && currentImage.URL && (
        <EmployeeViewUploadedItem
          title={currentImage.originalName}
          imageURl={currentImage.URL}
          path={currentImage}
          cancelDisplay={() => setShowFile(false)}
        />
      )}
    </>
  );
};

export default EmployeeUploadedItem;
