import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../Common/PageBackButton";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../utils/localstorage";
import { getRequest } from "../../../../api/request";
import PageLoader from "../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../api/urls";
import { PermissionGroupActivityLog } from "../../../../types-definition/admin";
import DashboardHeader from "../../../Common/DashboardHeader";
import { toastError } from "utils/toast";

const ActivityPermissionTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentPermissionGroup, setCurrentPermissionGroup] =
    useState<PermissionGroupActivityLog>({
      name: "--",
      description: "--",
      active: false,
    });
  const [previousPermissionGroup, setPreviousPermissionGroup] =
    useState<PermissionGroupActivityLog>({
      name: "--",
      description: "--",
      active: false,
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getEmployeeData = async () => {
      // get employee data
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data.newRecord;
        const previousData = response.data.previousRecord;
        if (response.data.action === "POST") {
          setCurrentPermissionGroup({
            active: data?.permissionGroup?.active,
            description: data?.permissionGroup?.description,
            name: data?.permissionGroup?.name,
          });
          setPreviousPermissionGroup({
            active: previousData?.permissionGroup?.permissionGroup?.active,
            description:
              previousData?.permissionGroup?.permissionGroup?.description,
            name: previousData?.permissionGroup?.permissionGroup?.name,
          });
        } else {
          setCurrentPermissionGroup({
            active: data?.permissionGroup?.active?.toString(),
            description: data?.permissionGroup?.description,
            name: data?.permissionGroup?.name,
          });
          setPreviousPermissionGroup({
            active: previousData?.permissionGroup?.permissionGroup?.active,
            description:
              previousData?.permissionGroup?.permissionGroup?.description,
            name: previousData?.permissionGroup?.permissionGroup?.name,
          });
        }
      } else {
        toastError(response?.data);
      }

      setPageLoading(false);
    };
    getEmployeeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Permission Group
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentPermissionGroup.name ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Description</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentPermissionGroup.description ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Active</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentPermissionGroup?.active === true && "True"}
                  {currentPermissionGroup?.active === false && "False"}
                  {currentPermissionGroup?.active === undefined && "--"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Permission Group
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousPermissionGroup.name ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Description</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousPermissionGroup.description ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Active</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousPermissionGroup?.active === true && "True"}
                  {previousPermissionGroup?.active === false && "False"}
                  {previousPermissionGroup?.active === undefined && "--"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityPermissionTab;
