import React, { useState, useEffect } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import Button from "components/Form/Button";
import Typography from "components/Common/Typography";
import validator from "utils/validateFields";
import toast from "react-hot-toast";
import { GradeRequiredFormData, GradeFormData } from "types-definition/admin";
import { POST_GRADE_URL, GET_GRADE_URL, EDIT_GRADE_URL } from "api/urls";
import GradeForm from "components/StaffHub/Grade/GradeForm";
import colors from "theme/colors";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { postRequest, putRequest, getRequest } from "api/request";
import { getGrade, deleteGrade, getUserTypes } from "utils/localstorage";
import { toastSuccess } from "utils/toast";
import scrolltoView from "utils/scrollToView";
import { updateSetupComplete } from "utils/setupWizard";

const AdminGradeForm: React.FC = () => {
  const navigate = useNavigate();
  const [gradeId, setGradeId] = useState<any>("");
  const [formData, setFormData] = useState<GradeFormData>({
    grade: "",
    description: "",
    color: "#E89623",
    certificateGroup: "",
    certificateName: "",
    active: true,
  });

  const [requiredFormData, setRequiredFormData] =
    useState<GradeRequiredFormData>({
      grade: false,
      description: false,
      color: false,
      certificateGroup: false,
    });

  const [requestLoading, setRequestLoading] = useState(false);
  const handleFormCancelation = () => {
    deleteGrade();
    navigate("/staff-hub/grade");
  };

  const handleSubmitForm = async () => {
    const { status, data, id } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      if (id) {
        scrolltoView(id);
      }
      toast.error("Complete all required fields before saving", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    setRequestLoading(true);
    const response = !gradeId
      ? await postRequest(POST_GRADE_URL, {
          grade: formData.grade,
          description: formData.description,
          color: formData.color,
          certificateGroup: formData.certificateGroup,
          active: formData.active,
        })
      : await putRequest(`${EDIT_GRADE_URL}/${gradeId}`, {
          grade: formData.grade,
          description: formData.description,
          color: formData.color,
          certificateGroup: formData.certificateGroup,
          active: formData.active,
        });
    if (response?.success) {
      toastSuccess("Grade added");
      const setUpComplete = await updateSetupComplete("7", false, "grade");
      const userType = getUserTypes();
      if (!setUpComplete && userType !== "Web User") {
        navigate("/setup-wizard");
      } else {
        navigate("/staff-hub/grade");
      }
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
    deleteGrade();
  };

  useEffect(() => {
    const getGradeHandler = async () => {
      const gradeId = getGrade();
      setGradeId(gradeId);
      if (gradeId !== undefined && gradeId !== null) {
        const response = await getRequest(`${GET_GRADE_URL}/${gradeId}`);
        if (response?.success) {
          setFormData({
            grade: response?.data.grade,
            description: response?.data.description,
            color: response?.data.color,
            certificateGroup: response?.data.certificateGroup,
            certificateName: response?.data.certificateGroupName,
            active: true,
          });
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      }
    };
    getGradeHandler();
  }, []);

  return (
    <>
      <DashboardHeader active="staff/hub" />
      <div className={styles.container}>
        <div>
          <div className={styles.item_container}>
            <div className={styles.form_container}>
              <Typography
                textAlign="left"
                textWeight="600"
                textSize="20px"
                marginVertical="-10px"
                style={{
                  marginBottom: "15px",
                }}
              >
                Add Grade
              </Typography>
              <GradeForm
                formData={formData}
                setFormData={setFormData}
                requiredFormData={requiredFormData}
                setRequiredFormData={setRequiredFormData}
              />
              <div className={styles.button_container}>
                <Button
                  width="200px"
                  text="Cancel"
                  isPrimary={false}
                  onClick={handleFormCancelation}
                  marginHorizontal="10px"
                  backgroundColor={colors.transparent}
                  isPrimaryBorder={true}
                />
                <Button
                  width="200px"
                  text="Save Grade"
                  isPrimary={true}
                  onClick={handleSubmitForm}
                  isLoading={requestLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminGradeForm;
