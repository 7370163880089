import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Button from "../../../Form/Button";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { getRequest } from "../../../../api/request";
import { GET_ADDRESS } from "../../../../api/urls";
import { useAppDispatch } from "../../../../redux/hooks";
import { setShowFindAddressDropdown } from "../../../../redux/slice/staff-hub/employeeSlice";
import UkAddressSearchInput from "../../../Form/UkAddressInputSearch";
import { EmptySiteLocationAddressFormPropsUk } from "../../../../types-definition/client";
import { getCountry } from "../../../../utils/localstorage";

const EmptySiteLocationAddressFormUk: React.FC<
  EmptySiteLocationAddressFormPropsUk
> = ({ formData, setFormData }) => {
  const [address, setAddress] = useState<Array<any>>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const getPostCodeDetails = async () => {
      const response = await getRequest(
        `${GET_ADDRESS}?postcode=${formData.postcode}`,
      );
      setAddress(
        response?.data?.suggestions
          ? response?.data?.suggestions?.map((i: any) => {
              return { name: i.address, id: i.id };
            })
          : [],
      );
    };
    getPostCodeDetails();
  }, [formData.postcode]);
  const getAddressDetails = async (id: string) => {
    const response = await getRequest(`${GET_ADDRESS}/${id}`);
    setFormData((prevData) => {
      return {
        ...prevData,
        address1: response?.data?.line_1,
        address2: response?.data?.line_2,
        city: response?.data?.town_or_city,
        latitude: response?.data?.latitude,
        longitude: response?.data.longitude,
        country: getCountry()!,
        postcode: response?.data?.postcode,
        postcode1: response?.data?.postcode,
        isPostCodeEntered: true,
      };
    });
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.input_container}>
          <UkAddressSearchInput
            value={formData.postcode}
            setValue={(data, id, address) => {
              if (id) {
                getAddressDetails(id);
              }
              setFormData((prevData) => {
                return {
                  ...prevData,
                  postcode: data,
                  addressId: id ?? "",
                };
              });
            }}
            label="Postcode"
            placeholder="ABC - 1234"
            dropdownTop="45px"
            datalistTop="83px"
            emptyOptionText="Search Postcode"
            options={address}
          />
        </div>
        <div className={styles.button_container}>
          <Button
            isPrimary={true}
            text="Find Address"
            onClick={() => {
              dispatch(setShowFindAddressDropdown(true));
            }}
            onBlur={() => {
              setTimeout(() => {
                dispatch(setShowFindAddressDropdown(false));
              }, 300);
            }}
            width="150px"
          />
        </div>
      </div>
      <div
        className={styles.manual_button}
        onClick={() => {
          setFormData((prevData) => {
            return { ...prevData, isPostCodeEntered: true };
          });
        }}
      >
        <Typography
          textColor={colors.primary3}
          textAlign="left"
          marginVertical="0px"
          textWeight="600"
        >
          Enter Manually
        </Typography>
      </div>
    </>
  );
};

export default EmptySiteLocationAddressFormUk;
