import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { CheckpointDataReduxInteface } from "types-definition/report";

const initialState = {
  checkpointId: "",
  checkpointName: "",
  serviceId: "",
  serviceName: "",
  shiftStart: "",
  locationId: "",
  locationName: "",
  frequency: "",
  interval: "",
};

export const checkpointReportSlice = createSlice({
  name: "checkpointReport",
  initialState,
  reducers: {
    setCheckpointData: (
      state,
      action: PayloadAction<CheckpointDataReduxInteface>,
    ) => {
      state = action.payload;
    },
  },
});

export const { setCheckpointData } = checkpointReportSlice.actions;

export const getCheckpointData = (state: RootState) =>
  state.checkcallLog.activeCheckcallGroupIds;

export default checkpointReportSlice.reducer;
