import React from "react";
import { ButtonProps } from "../../../types-definition/form";
import styles from "./index.module.css";
import colors from "../../../theme/colors";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import LoadingIcons from "react-loading-icons";

const Button: React.FC<ButtonProps> = ({
  text,
  borderColor = colors.transparent,
  borderWidth = 0,
  textColor = colors.white,
  borderRadius,
  onClick,
  onBlur,
  isPrimary = true,
  backgroundColor = colors.primary1,
  disabled = false,
  marginVertical = 0,
  marginTop,
  paddingVertical,
  marginHorizontal = 0,
  isLoading = false,
  width,
  left,
  right,
  isPrimaryBorder = false,
  textWeight,
  textSize,
}) => {
  return (
    <button
      className={
        isPrimaryBorder
          ? `${styles.button} ${styles.primary_border}`
          : styles.button
      }
      disabled={disabled || isLoading}
      style={{
        backgroundColor: disabled
          ? colors.gray1
          : isLoading
            ? colors.gray1
            : isPrimary
              ? colors.primary3
              : backgroundColor,
        borderColor,
        borderWidth: isPrimaryBorder ? "2px" : borderWidth,
        marginTop: marginTop || marginVertical,
        marginBottom: marginVertical,
        paddingTop: paddingVertical,
        paddingBottom: paddingVertical,
        marginRight: marginHorizontal,
        marginLeft: marginHorizontal,
        borderRadius,
        // backgroundImage: isLoading
        //   ? "none"
        //   : disabled
        //   ? "none"
        // : isPrimary
        // ? colors.primary3
        // : // ? "linear-gradient(#006093, #00dcda)"
        // "none",
        width,
      }}
      onClick={onClick}
      onBlur={onBlur}
    >
      {left ? (
        <div className={styles.left}>
          {left}{" "}
          <Typography
            textColor={textColor}
            textSize={textSize ?? size.sm}
            marginHorizontal="10px"
            textWeight={textWeight}
          >
            {text}
          </Typography>
        </div>
      ) : right ? (
        <div className={styles.right}>
          <Typography
            textColor={textColor}
            textSize={textSize ?? size.sm}
            marginHorizontal="10px"
            textWeight={textWeight}
          >
            {text}
          </Typography>
          {right}{" "}
        </div>
      ) : isPrimaryBorder ? (
        <Typography
          textWeight="600"
          textColor={colors.primary3}
          textSize={textSize}
          // style={{
          //   background: "linear-gradient(#006093, #00dcda)",
          //   WebkitTextFillColor: "transparent",
          //   WebkitBackgroundClip: "text",
          // }}
        >
          {text}
        </Typography>
      ) : (
        <Typography
          textWeight={textWeight ?? "500"}
          textColor={textColor}
          textSize={textSize ?? size.sm}
        >
          {text}
        </Typography>
      )}

      {isLoading && (
        <LoadingIcons.TailSpin
          width={20}
          height={20}
          color={colors.white}
          style={{ marginLeft: "10px" }}
        />
      )}
    </button>
  );
};

export default Button;
