import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../Common/PageBackButton";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../utils/localstorage";
import { getRequest } from "../../../../api/request";
import PageLoader from "../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../api/urls";
import { GradeActivityLogsProps } from "../../../../types-definition/admin";
import DashboardHeader from "../../../Common/DashboardHeader";
import { toastError } from "utils/toast";
import ColorStroke from "components/Common/ProgressBar";
const ActivityGrade = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentGrade, setCurrentGrade] = useState<GradeActivityLogsProps>({
    grade: "hello",
    description: "hello",
    certificateGroup: "hello",
    colour: "hello",
  });
  const [previousGrade, setPreviousGrade] = useState<GradeActivityLogsProps>({
    grade: "-",
    description: "-",
    certificateGroup: "-",
    colour: "-",
  });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getGrade = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data;
        const previousData = response.data;
        if (response.data.action === "POST") {
          setCurrentGrade({
            grade: data.newRecord.grade.grade,
            description: data.newRecord.grade.description,
            certificateGroup: data.newRecord.grade.certificateGroupName,
            colour: data.newRecord.grade.color,
          });

          setPreviousGrade({
            grade: previousData.previousRecord?.grade.grade,
            description: previousData.previousRecord?.grade.description,
            certificateGroup:
              previousData.previousRecord?.grade.certificateGroupName,
            colour: previousData.previousRecord?.grade.color,
          });
        } else {
          setCurrentGrade({
            grade: data.newRecord.grade.grade,
            description: data.newRecord.grade.description,
            certificateGroup: data.newRecord.grade.certificateGroupName,
            colour: data.newRecord.grade.color,
          });
          setPreviousGrade({
            grade: previousData.previousRecord.grade.grade,
            description: previousData.previousRecord.grade.description,
            certificateGroup:
              previousData.previousRecord.grade.certificateGroupName,
            colour: previousData.previousRecord.grade.color,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getGrade();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Grade
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGrade.grade ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Description</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGrade.description ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Colour</Typography>
                {/* <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGrade?.colour ?? "-"}
                </Typography> */}
                <ColorStroke color={currentGrade.colour} />
              </div>
              <div>
                <Typography textAlign="left">Certificate Group</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGrade?.certificateGroup ?? "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Grade
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGrade.grade ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Description</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGrade.description ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Colour</Typography>
                {/* <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGrade?.colour ?? "-"}
                </Typography> */}
                {previousGrade.colour ? (
                  <ColorStroke color={previousGrade.colour} />
                ) : (
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    -
                  </Typography>
                )}
              </div>
              <div>
                <Typography textAlign="left">Certificate Group</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGrade?.certificateGroup ?? "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityGrade;
