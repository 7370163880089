import { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import TextInput from "../../../../Form/TextInput";
import NumberInput from "../../../../Form/NumberInput";
import cancelIcon from "../../../../../assets/svg/circle-cancel-icon.svg";
import SelectInput from "../../../../Form/SelectInput";
import ClientSelectInput from "../../../../Form/ClientSelectInput";
import {
  LinkToLocationRequiredData,
  LinkToLocationFormData,
} from "../../../../../types-definition/StaffHub/employee/linkToLocation";
import Button from "../../../../Form/Button";
import { useAppDispatch } from "../../../../../redux/hooks";
import validator from "../../../../../utils/validateFields";
import { getRequest } from "../../../../../api/request";
import { GET_SERVICE_URL } from "../../.././../../api/urls";
import {
  handleReload,
  handleShowModal,
} from "../../../../../redux/slice/staff-hub/linkToLocationSlice";
import toast from "react-hot-toast";
import { postRequest } from "../../../../../api/request";
import {
  getEmployeeUserName,
  getCurrentEmployee,
} from "../../../../../utils/localstorage";
import { POST_LINK_EMPLOYEE_TO_LOCATION } from "../../.././../../api/urls";
import { getLocationDetails } from "../../../../../utils/localstorage";
import { toastSuccess } from "../../../../../utils/toast";

const LinkToLocationModal = () => {
  const dispatch = useAppDispatch();
  const [requestLoading, setrequestLoading] = useState(false);
  const [serviceLinkedToLocation, setServiceLinkedToLocation] = useState<
    Array<any>
  >([]);
  const [allService, setAllService] = useState<Array<any>>([]);
  const [employeePay, setEmployeePay] = useState<string>("");
  const [employeeDetails, setEmployeeDetails] = useState<any>({
    location: "",
  });

  const [locationDetails, setLocationDetails] = useState<any>({
    location: "",
    distance: "",
  });

  const [formData, setFormData] = useState<LinkToLocationFormData>({
    location: "",
    payOption: "",
    payValue: "",
    service: "",
  });

  const [requiredFormData, setRequiredFormData] =
    useState<LinkToLocationRequiredData>({
      location: false,
      payOption: false,
      payValue: false,
    });
  const filterByReference = (arr1: any, arr2: any) => {
    let res = [];
    res = arr1.filter((el: any) => {
      return !arr2.find((element: any) => {
        return element.locationId === el.id;
      });
    });
    return res;
  };
  const handleSubmitForm = async () => {
    let { status, data } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      toast.error("Complete all required fields before saving", {
        duration: 4000,
        position: "top-center",
      });
    } else {
      try {
        setrequestLoading(true);
        const response = await postRequest(
          `${POST_LINK_EMPLOYEE_TO_LOCATION}/${employeeDetails.userName}/location/${locationDetails.locationId}`,
          {
            payOption:
              formData.payOption.charAt(0).toLowerCase() +
              formData.payOption.slice(1).replace(/\s/g, ""),
            payValue: formData.payValue,
            distance: locationDetails.distance,
          },
        );
        dispatch(handleReload({ reload: true }));
        if (response?.success) {
          toastSuccess("Employee Linked Successfully");
        } else {
          toast.error(response.data, {
            duration: 4000,
            position: "top-center",
          });
        }
        setrequestLoading(false);
        dispatch(handleShowModal({ showModal: false }));
      } finally {
      }
    }
  };

  useEffect(() => {
    const checkForEmployee = getCurrentEmployee();
    const checkLocation = getLocationDetails();
    if (checkForEmployee) {
    }
    if (checkForEmployee && checkLocation) {
      setEmployeeDetails({
        userName: JSON.parse(checkForEmployee).name,
        displayName: JSON.parse(checkForEmployee).displayName,
        employeeId: JSON.parse(checkForEmployee).employeeId,
        payRate: JSON.parse(checkForEmployee).payRate,
      });
      setLocationDetails({
        distance: JSON.parse(checkLocation).distance,
        location: JSON.parse(checkLocation).location,
        locationId: JSON.parse(checkLocation).locationId,
      });
      setEmployeePay(JSON.parse(checkForEmployee).payRate);
      setFormData({
        location: JSON.parse(checkLocation).location,
        payValue: JSON.parse(checkForEmployee).payRate,
        payOption: "",
        service: "",
      });

      const getServiceInLocation = async () => {
        const response = await getRequest(
          `${GET_SERVICE_URL}/location/${JSON.parse(checkLocation).locationId}`,
        );
        if (response?.success) {
          const seviceArray = response.data.map((item: any) => {
            return { id: item.serviceId, name: item.serviceName };
          });
          return seviceArray;
        } else {
          toast.error(response.data, {
            duration: 4000,
            position: "top-center",
          });
          return;
        }
      };
      const getAllService = async () => {
        const response = await getRequest(`${GET_SERVICE_URL}`);
        if (response?.success) {
          setAllService(response.data);
          const serviceInLocation = await getServiceInLocation();
          const serviceWithSameGradeAsEmployee = filterByReference(
            response.data,
            serviceInLocation,
          ).map((item: any) => {
            return { name: item.name, id: item.id };
          });
          setServiceLinkedToLocation(serviceWithSameGradeAsEmployee);
        } else {
          toast.error(response.data, {
            duration: 4000,
            position: "top-center",
          });
        }
      };
      getAllService();
    }

    const checkEmployee = getEmployeeUserName();
    if (checkEmployee) {
      setEmployeeDetails({
        userName: JSON.parse(checkEmployee).employeeUserName,
        distance: JSON.parse(checkEmployee).distance,
        location: JSON.parse(checkEmployee).location,
      });
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600">Link Employee To Location</Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() => dispatch(handleShowModal({ showModal: false }))}
          />
        </div>
        <TextInput
          value={locationDetails.location}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, location: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, location: false };
            });
          }}
          label="Location"
          placeholder="Select Location"
          error={requiredFormData.location}
          right={
            <span
              className={styles.right_placeholder}
              style={{
                color: "#667185",
              }}
            >
              {locationDetails.distance}
            </span>
          }
          disabled
        />
        <SelectInput
          value={formData.payOption}
          setValue={(data) => {
            if (data === "Custom Pay") {
              setFormData((prevData) => {
                return { ...prevData, payOption: data, payValue: "" };
              });
            }
            if (data === "Personal Pay") {
              setFormData((prevData) => {
                return { ...prevData, payOption: data, payValue: employeePay };
              });
            } else {
              setFormData((prevData) => {
                return { ...prevData, payOption: data };
              });
            }
            setRequiredFormData((prevData) => {
              return { ...prevData, payOption: false };
            });
          }}
          label="Pay Option"
          placeholder="Select Pay Option"
          dropdownTop="46px"
          datalistTop="83px"
          options={["Personal Pay", "Custom Pay", "Service Pay"]}
          error={requiredFormData.payOption}
        />
        {formData.payOption === "Custom Pay" && (
          <NumberInput
            value={formData.payValue}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, payValue: data };
              });

              setRequiredFormData((prevData) => {
                return { ...prevData, payValue: false };
              });
            }}
            label="Custom Pay"
            placeholder=""
            error={requiredFormData.payValue}
          />
        )}
        {formData.payOption === "Service Pay" && (
          <ClientSelectInput
            value={formData.service}
            setValue={(name, id) => {
              if (name) {
                const servicePay = allService.filter(
                  (item: any) => item.id === id,
                )[0];

                setFormData((prevData) => {
                  return {
                    ...prevData,
                    service: name,
                    payValue: servicePay.payInfo.payRate,
                  };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, service: false };
                });
              }
            }}
            label="Select Service"
            placeholder="Select Service "
            dropdownTop="46px"
            datalistTop="83px"
            options={serviceLinkedToLocation}
            client={formData.service}
            // Im using payValue to check if a service was selected. for future preference
            error={requiredFormData.payValue}
          />
        )}
        {formData.payOption === "Service Pay" && formData.service !== "" && (
          <NumberInput
            value={formData.payValue}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, payValue: data };
              });
              setRequiredFormData((prevData) => {
                return { ...prevData, payValue: false };
              });
            }}
            label="Service Pay"
            placeholder=""
            error={requiredFormData.payValue}
            disabled={true}
          />
        )}
        {formData.payOption === "Personal Pay" && (
          <NumberInput
            value={`${formData.payValue}.00`}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, payValue: data };
              });

              setRequiredFormData((prevData) => {
                return { ...prevData, payValue: false };
              });
            }}
            label="Personal Pay"
            placeholder=""
            error={requiredFormData.payValue}
            disabled={formData.payOption === "Personal Pay" ? true : false}
          />
        )}

        <Button
          text="Link To Location"
          isPrimary={true}
          onClick={() => {
            handleSubmitForm();
          }}
          isLoading={requestLoading}
        />
      </div>
    </div>
  );
};

export default LinkToLocationModal;
