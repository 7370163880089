import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import ScrollToTop from "components/Common/ScrollToTop";
import MonthCalendar from "components/StaffHub/Employee/Avalability/MonthCalendar";
import EmployeeAvalabilityModal from "components/StaffHub/Employee/Avalability/EmployeAvalabilityModal";
import { useAppSelector } from "../../../../redux/hooks";
import {
  getShowAvalabilityModal,
  getShowAvalabilityTypeModal,
  getShowDeleteAvailabilityModal,
} from "../../../../redux/slice/staff-hub/employeeSlice";
import { getCurrentEmployee } from "utils/localstorage";
import capitalize from "utils/capitalize";
import EmployeeAvalabilityTypeModal from "components/StaffHub/Employee/Avalability/EmployeeAvalabilityTypeModal";
import EmployeeDeleteAvalabilityTypeModal from "components/StaffHub/Employee/Avalability/EmployeeDeleteAvalabilityModal";
import PageBackButton from "components/Common/PageBackButton";
const EmployeeAvalability = () => {
  const showAvalabilityModal = useAppSelector(getShowAvalabilityModal);
  const showAvalabilityTypeModal = useAppSelector(getShowAvalabilityTypeModal);
  const [username, setUsername] = useState("");
  const showDeleteAvailabilityModal = useAppSelector(
    getShowDeleteAvailabilityModal,
  );

  useEffect(() => {
    const employee = JSON.parse(getCurrentEmployee()!);
    setUsername(employee?.displayName ?? "");
  }, []);

  return (
    <>
      <ScrollToTop />
      <section>
        <DashboardHeader active="staff/hub" />
        <div className={styles.container}>
          <div
            className={`${styles.item_container} ${styles.item_container_fill}`}
          >
            <PageBackButton width="95%" paddingTop="20px" marginBottom="10px" />
            <div className={styles.toptext_container}>
              <Typography textAlign="left" textWeight="600" textSize="20px">
                Duration when {username ?? capitalize(username)} will be
                unavailable to work
              </Typography>
            </div>
            <div className={styles.calendar_container}>
              <MonthCalendar />
            </div>
          </div>
        </div>
      </section>
      {showAvalabilityTypeModal && <EmployeeAvalabilityTypeModal />}
      {showAvalabilityModal && <EmployeeAvalabilityModal />}
      {showDeleteAvailabilityModal && <EmployeeDeleteAvalabilityTypeModal />}
    </>
  );
};

export default EmployeeAvalability;
