import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import AdminSideBar from "components/Admin/SideBar";
import Typography from "components/Common/Typography";
import {
  WebUserProfileRequiredFormData,
  WebUserProfileFormData,
  WebUserProfileClientRequiredFormData,
} from "types-definition/admin";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { CREATE_WEB_USER_URL, GET_WEB_USER_URL } from "api/urls";
import { getRequest, postRequest, putRequest } from "api/request";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import WebUserForm from "components/Admin/Web User/WebUserForm";
import validator from "utils/validateFields";
import generatePassword from "utils/generatePassword";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getShowConfirmMailModal,
  setShowConfirmmailModal,
} from "../../../redux/slice/admin/webuserSlice";
import ConfirmMailModal from "components/Admin/Web User/ConfirmMailModal";
import { getUserTypes, getWebUser } from "utils/localstorage";
import PageLoader from "components/Common/PageLoader";
import { toastError, toastSuccess } from "utils/toast";
import scrolltoView from "utils/scrollToView";
import { updateSetupComplete } from "utils/setupWizard";

const WebUserProfileForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showConfirmMailModal = useAppSelector(getShowConfirmMailModal);
  const [isLoading, setIsLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [formData, setFormData] = useState<WebUserProfileFormData>({
    clientPortalUser: false,
    active: true,
    client: "",
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    telephone: "",
    lock: false,
    viewableLocation: "",
    position: "",
    emailConditions: {
      cancelledShift: false,
      proximityCheckCall: false,
      proximityShiftEnd: false,
      proximityShiftStart: false,
    },
  });
  const [clientRequiredFormData, setClientRequiredFormData] =
    useState<WebUserProfileClientRequiredFormData>({
      client: false,
      firstName: false,
      lastName: false,
      userName: false,
      password: false,
      confirmPassword: false,
      email: false,
    });
  const [requiredFormData, setRequiredFormData] =
    useState<WebUserProfileRequiredFormData>({
      viewableLocation: false,
      firstName: false,
      lastName: false,
      userName: false,
      password: false,
      confirmPassword: false,
      email: false,
    });
  const [requestLoading, setRequestLoading] = useState(false);
  const handleFormCancelation = () => {
    navigate("/admin/web-user-profile");
  };
  const handleSubmitForm = async () => {
    // validate required fields
    if (formData.clientPortalUser) {
      const { status, data, id } = validator(formData, clientRequiredFormData);
      if (!status) {
        setClientRequiredFormData((prevData) => {
          return {
            ...prevData,
            ...data,
          };
        });
        if (id) {
          scrolltoView(id);
        }
        toast.error("Please fill all required fields", {
          duration: 4000,
          position: "top-center",
        });
        return;
      }
    } else {
      const { status, data, id } = validator(formData, requiredFormData);
      if (!status) {
        setRequiredFormData((prevData) => {
          return {
            ...prevData,
            ...data,
          };
        });
        if (id) {
          scrolltoView(id);
        }
        toast.error("Please fill all required fields", {
          duration: 4000,
          position: "top-center",
        });
        return;
      }
    }
    // Validate Password
    if (formData.password !== formData.confirmPassword) {
      setRequiredFormData((prevData) => {
        return {
          ...prevData,
          confirmPassword: true,
        };
      });
      toast.error("Password does not match", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    setRequestLoading(true);
    let data: any = {
      clientPortalUser: formData.clientPortalUser,
      active: formData.active,
      client: formData.client,
      firstName: formData.firstName,
      lastName: formData.lastName,
      username: formData.userName,
      email: formData.email,
      password: formData.password,
      repeat_password: formData.confirmPassword,
      phone: formData.telephone,
      lock: formData.lock,
      viewableLocations: formData.viewableLocation,
      position: formData.position,
      emailConditions: [
        formData.emailConditions.cancelledShift && "Cancelled Shift",
        formData.emailConditions.proximityCheckCall && "Proximity Check Call",
        formData.emailConditions.proximityShiftEnd && "Proximity Shift End",
        formData.emailConditions.proximityShiftStart && "Proximity Shift Start",
      ].filter(Boolean),
    };

    if (!formData.clientPortalUser) {
      delete data?.client;
    } else {
      delete data?.viewableLocations;
      delete data?.emailConditions;
    }
    let response;
    if (getWebUser()) {
      response = await putRequest(
        `${CREATE_WEB_USER_URL}/${getWebUser()}`,
        data,
      );
    } else response = await postRequest(CREATE_WEB_USER_URL, data);
    if (response?.success) {
      // const settingsId = getSettings();
      toastSuccess(`Web User ${getWebUser() ? "Updated" : "Created"}`);
      const setUpComplete = await updateSetupComplete("4", false, "webuser");

      const userType = getUserTypes();
      if (!setUpComplete && userType !== "Web User") {
        navigate("/setup-wizard");
      } else {
        navigate("/admin/web-user-profile");
      }
    } else {
      toastError(response?.data);
    }
    setRequestLoading(false);
  };
  useEffect(() => {
    const id = getWebUser();
    if (id) return;
    const password = generatePassword(8);
    setFormData((prevData) => {
      return {
        ...prevData,
        password,
        confirmPassword: password,
      };
    });
  }, []);
  useEffect(() => {
    const getWebUserDetails = async () => {
      setIsLoading(true);
      const id = getWebUser();
      if (id) {
        const response = await getRequest(`${GET_WEB_USER_URL}/${id}`);
        if (response?.success) {
          const data = response?.data;
          setFormData((prevData) => {
            return {
              ...prevData,
              clientPortalUser: data?.clientPortalUser ?? "",
              active: data?.active ?? "",
              client: data?.clientId ?? "",
              firstName: data?.firstName ?? "",
              lastName: data?.lastName ?? "",
              userName: data?.username ?? "",
              email: data?.email ?? "",
              telephone: data?.phone ?? "",
              password: data?.password,
              confirmPassword: data?.password,
              lock: data?.lock ?? "",
              viewableLocation: data?.viewableLocations ?? "",
              position: data?.position ?? "",
              emailConditions: {
                cancelledShift:
                  data?.emailConditions?.includes("Cancelled Shift"),
                proximityCheckCall: data?.emailConditions?.includes(
                  "Proximity Check Call",
                ),
                proximityShiftEnd: data?.emailConditions?.includes(
                  "Proximity Shift End",
                ),
                proximityShiftStart: data?.emailConditions?.includes(
                  "Proximity Shift Start",
                ),
              },
            };
          });
          setClientName(data?.clientName ?? "");
        } else {
          toastError(response?.data);
        }
      }
      setIsLoading(false);
    };
    getWebUserDetails();
  }, []);
  return (
    <>
      {isLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <AdminSideBar active="web user profile" />
        <div className={styles.item_container}>
          <div className={styles.form_container}>
            <Typography textWeight="600" textSize="20px" textAlign="left">
              New Web User
            </Typography>
            <WebUserForm
              formData={formData}
              setFormData={setFormData}
              clientRequiredFormData={clientRequiredFormData}
              setClientRequiredFormData={setClientRequiredFormData}
              requiredFormData={requiredFormData}
              setRequiredFormData={setRequiredFormData}
              clientName={clientName}
            />
            <div className={styles.button_container}>
              <Button
                width="230px"
                text="Cancel"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={true}
              />
              <Button
                width="230px"
                text={getWebUser() ? "Update Web User" : "Add New Web User"}
                isPrimary={true}
                onClick={() => {
                  if (formData.email) {
                    dispatch(setShowConfirmmailModal(true));
                  } else {
                    handleSubmitForm();
                  }
                }}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
      {showConfirmMailModal && (
        <ConfirmMailModal
          handleSubmit={handleSubmitForm}
          email={formData.email}
        />
      )}
    </>
  );
};

export default WebUserProfileForm;
