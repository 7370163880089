import React from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import AvailableEmployeeRosterItem from "../AvailableEmployeeRosterItem";
import { AvailableEmployeeRosterProps } from "../../../types-definition/roster";
import { postRequest } from "../../../api/request";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getActiveRosterSingleItem,
  getIsRosterFocused,
  getRosterServiceData,
  getRosterServiceId,
  setRosterRequestCounter,
} from "../../../redux/slice/roster/rosterSlice";
import { toastError, toastSuccess } from "../../../utils/toast";
import colors from "../../../theme/colors";
import LoadingIcons from "react-loading-icons";

const AvaialableEmployeeRoster: React.FC<AvailableEmployeeRosterProps> = ({
  isLoading,
  data,
}) => {
  const dispatch = useAppDispatch();
  const rosterId = useAppSelector(getActiveRosterSingleItem);
  const focused = useAppSelector(getIsRosterFocused);
  const serviceId = useAppSelector(getRosterServiceId);
  const serviceData = useAppSelector(getRosterServiceData);
  // const [availableForShift, setAvailableForShift] = useState<any>(data);
  const scheduleEmployee = async (id: string) => {
    toastSuccess("Employee allocation process initiated");
    const service = serviceData.find((item: any) => {
      return item.serviceId === serviceId && item.id === rosterId;
    });
    if (!service) {
      return toastError("Please Select a shift");
    }
    const response = await postRequest("/misc/active-board/start", {
      expectedTime: service.expectedTime,
      clientName: service.clientName,
      clientId: service.clientId,
      startTime: service.startTime,
      endTime: service.endTime,
      location: service.locationId,
      locationId: service.locationId,
      service: service.service,
      serviceId: service.serviceId,
      employeeId: data.find((item) => item.employeeId === id)?.employeeId,
      employeeName: data.find((item) => item.employeeId === id)?.employeeName,
      confirmed: true,
      date: service.isoDate,
      // isoDate: service.isoDate,
    });
    if (!response?.success) {
      toastError(response?.data);
    }
    toastSuccess("Employee allocation process completed");
    dispatch(setRosterRequestCounter());
  };

  return (
    <div className={styles.flex_container}>
      <div className={styles.flex_item}>
        <div className={styles.flex_item_header}>
          <Typography textWeight="600" textAlign="left">
            Available Employee for Empty Shifts
          </Typography>
        </div>
        {isLoading ? (
          <LoadingIcons.TailSpin
            width={20}
            height={20}
            stroke={colors.primary1}
            className={styles.loader_spinner}
          />
        ) : (
          <div className={styles.permission_container}>
            {focused &&
              data?.map((item: any, index: any) => {
                return (
                  <AvailableEmployeeRosterItem
                    key={index}
                    action={async (id) => {
                      await scheduleEmployee(id);
                    }}
                    text={item.employeeName}
                    id={item.employeeId}
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AvaialableEmployeeRoster;
