import React from "react";
import Typography from "../../Common/Typography";
import styles from "./index.module.css";
import colors from "../../../theme/colors";

const RosterColorIdentifier: React.FC = () => {
  return (
    <div className={styles.container}>
      <div
        className={styles.container_item}
        style={{ backgroundColor: colors.error }}
      >
        <Typography
          textWeight={"500"}
          fontFamily="inter"
          textColor={colors.white}
        >
          Dropped Shift
        </Typography>
      </div>
      <div
        className={styles.container_item}
        style={{ backgroundColor: colors.successtext }}
      >
        <Typography
          textWeight={"500"}
          fontFamily="inter"
          textColor={colors.white}
        >
          Completed Shift
        </Typography>
      </div>
      <div
        className={styles.container_item}
        style={{ backgroundColor: colors.rosterWarning }}
      >
        <Typography
          textWeight={"500"}
          fontFamily="inter"
          textColor={colors.white}
        >
          Unconfirmed Shift
        </Typography>
      </div>
      <div
        className={styles.container_item}
        style={{ backgroundColor: colors.primary3 }}
      >
        <Typography
          textWeight={"500"}
          fontFamily="inter"
          textColor={colors.white}
        >
          Confirmed Shift
        </Typography>
      </div>
      <div
        className={styles.container_item}
        style={{
          backgroundColor: colors.rosterSchedule,
        }}
      >
        <Typography
          textWeight={"500"}
          fontFamily="inter"
          textColor={colors.white}
        >
          Scheduled Shift
        </Typography>
      </div>
      <div
        className={styles.container_item}
        style={{ backgroundColor: colors.rosterCancelled }}
      >
        <Typography
          textWeight={"500"}
          fontFamily="inter"
          textColor={colors.white}
        >
          Cancelled Shift
        </Typography>
      </div>
      <div
        className={styles.container_item_right}
        style={{ backgroundColor: colors.rosterInpogress }}
      >
        <Typography
          textWeight={"500"}
          fontFamily="inter"
          textColor={colors.white}
        >
          In-Progress
        </Typography>
      </div>
    </div>
  );
};

export default RosterColorIdentifier;
