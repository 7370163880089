import { useEffect, useState } from "react";
import styles from "./index.module.css";
import cancelIcon from "../../../assets/svg/cancel-icon.svg";
import settingsIcon from "../../../assets/svg/setting-01.svg";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getShowCancelSubscriptionModal,
  getShowCancellationFeedbackModal,
  getShowSignUpModal,
  setShowAuthSideBar,
  setShowCancelSubscriptionModal,
  setShowCancellationFeedbackModal,
  setShowSignUpModal,
} from "../../../redux/slice/common/sidebarSlice";
import Typography from "../Typography";
import LinkComponent from "../LinkComponent";
import colors from "../../../theme/colors";
import HorizontalRule from "../HorizontalRule";
import profileIcon from "../../../assets/svg/profile-icon.svg";
import primarySettings from "../../../assets/svg/settings-primary.svg";
import markIcon from "../../../assets/svg/mark-icon.svg";
import { getData } from "../../../utils/localstorage";
import capitalize from "../../../utils/capitalize";
import { getRequest } from "../../../api/request";
import { GET_USER_DETAILS_URL } from "../../../api/urls";
import SignOutModal from "../SignOutModal";
import useComponentVisible from "utils/hooks";
import CancellationFeedbackModal from "../CancellationFeedbackModal";
import CancelSubscriptionModal from "../CancelSubscriptionModal";

const AuthSidebar = () => {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector(getShowSignUpModal);
  const showCancelSubscriptionModal = useAppSelector(
    getShowCancelSubscriptionModal,
  );
  const showCancellationFeedbackModal = useAppSelector(
    getShowCancellationFeedbackModal,
  );
  const user = JSON.parse(getData() ?? "");
  const [companyDetails, setCompanyDetails] = useState({
    name: "",
    id: "",
  });
  const getUserDetails = async () => {
    const response = await getRequest(GET_USER_DETAILS_URL);
    if (response?.success) {
      setCompanyDetails({
        name: response?.data?.user?.company?.companyName,
        id: response?.data?.user?.company?.id,
      });
    }
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const { ref, isComponentVisible } = useComponentVisible(true);
  useEffect(() => {
    if (!isComponentVisible) {
      dispatch(setShowAuthSideBar(false));
    }
  }, [isComponentVisible, dispatch]);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.item_container} ref={ref}>
          <div className={styles.cancel_image_container}>
            <img
              src={cancelIcon}
              alt="cancel icon"
              className={styles.cancel_image}
              onClick={() => dispatch(setShowAuthSideBar(false))}
            />
          </div>
          <div>
            <div className={styles.circle_name_container}>
              <Typography textWeight="600" textSize="24px">
                {user?.name
                  ? `${
                      user?.name && user?.name?.split(" ")[0][0]?.toUpperCase()
                    } ${
                      user?.name && user?.name?.split(" ")[1][0]?.toUpperCase()
                    }`
                  : "N/A"}
              </Typography>
            </div>
            <Typography textWeight="600" textSize="24px">
              {user?.name ? capitalize(user.name) : ""}
            </Typography>
            <Typography textSize="16px" textColor={colors.gray6}>
              {user?.email}
            </Typography>
            <div className={styles.link_component}>
              <LinkComponent
                showTextDecoration={false}
                textColor={colors.primary3}
                link="/"
              >
                <Typography textColor={colors.primary3} textWeight="600">
                  My Account
                </Typography>
              </LinkComponent>
              <div className={styles.divider}></div>
              <div
                className={styles.link_component_item}
                onClick={() => {
                  dispatch(setShowSignUpModal(true));
                }}
              >
                <Typography textColor={colors.error} textWeight="600">
                  Sign Out
                </Typography>
              </div>
            </div>
          </div>
          <div className={styles.item_details_container}>
            <HorizontalRule />
            <div className={styles.icon_text_container}>
              <img src={profileIcon} alt="avatar-icon" />
              <Typography>My Profile</Typography>
            </div>
            <div className={styles.icon_text_container}>
              <img src={settingsIcon} alt="avatar-icon" />
              <Typography>Notification Settings</Typography>
            </div>
          </div>
          <div className={styles.item_details_container}>
            <HorizontalRule />
            <Typography
              textAlign="left"
              textColor={colors.black}
              textSize="16px"
              marginVertical="15px"
              textWeight="600"
            >
              SUBSCRIPTION
            </Typography>
            <Typography
              textAlign="left"
              textSize="16px"
              textWeight="500"
              marginVertical="15px"
              textColor={colors.gray1}
            >
              Current Plan: STANDARD
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="15px"
              textColor={colors.gray1}
            >
              Your trial ends in 30 days
            </Typography>
            <div className={styles.subscription__actions}>
              <LinkComponent link="/">
                <Typography
                  textColor={colors.primary3}
                  textWeight="600"
                  textAlign="left"
                >
                  Upgrade Subscription
                </Typography>
              </LinkComponent>

              <div
                className=""
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(setShowCancelSubscriptionModal(true));
                }}
              >
                <Typography
                  textColor={colors.error}
                  textWeight="600"
                  textAlign="left"
                >
                  Cancel Subscription
                </Typography>
              </div>
            </div>
          </div>

          {showCancelSubscriptionModal && (
            <CancelSubscriptionModal
              cancelFunction={() =>
                dispatch(setShowCancelSubscriptionModal(false))
              }
              successFunction={() => {
                dispatch(setShowCancelSubscriptionModal(false));
                dispatch(setShowCancellationFeedbackModal(true));
              }}
            />
          )}

          {showCancellationFeedbackModal && (
            <CancellationFeedbackModal
              cancelFunction={() =>
                dispatch(setShowCancellationFeedbackModal(false))
              }
              successFunction={() =>
                dispatch(setShowCancellationFeedbackModal(false))
              }
            />
          )}

          <div className={styles.item_details_container}>
            <HorizontalRule />
            <div className={styles.organization_flex_item}>
              <Typography textAlign="left" textWeight="600" textSize="16px">
                MY ORGANIZATIONS
              </Typography>
              <div className={styles.sub_organization_flex_item}>
                <img src={primarySettings} alt="primary-settings-icon" />
                <Typography textColor={colors.primary3} textWeight="600">
                  My Account
                </Typography>
              </div>
            </div>
            <div className={styles.organization_details_flex_item}>
              <div>
                <Typography
                  textAlign="left"
                  textWeight="600"
                  textColor={colors.black}
                  textSize="16px"
                >
                  {companyDetails?.name ? companyDetails?.name : "N/A"}
                </Typography>
                <Typography
                  textAlign="left"
                  textColor={colors.gray6}
                  textSize="12px"
                >
                  Organization ID:{" "}
                  {companyDetails?.id ? companyDetails?.id : "N/A"}
                </Typography>
              </div>
              <img src={markIcon} alt="mark-icon" />
            </div>
          </div>
          {showModal && <SignOutModal />}
        </div>
      </div>
    </>
  );
};

export default AuthSidebar;
