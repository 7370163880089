import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import ScrollToTop from "components/Common/ScrollToTop";
import PageLoader from "components/Common/PageLoader";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import EmptyItemState from "components/Common/EmptyItemState";
import { useNavigate } from "react-router-dom";
import addIcon from "assets/svg/add-icon.svg";
import { CheckpointData } from "types-definition/client";
import CheckpointTable from "components/Client/Checkpoint/CheckpointTable";
import { SERVICE_CHECKPOINT } from "api/urls";
import { toastError } from "utils/toast";
import { getRequest } from "api/request";
import filterKeysFromArray from "utils/filterKeyFromArray";
import {
  deleteCheckpointData,
  deleteCheckpointSection,
} from "utils/localstorage";
import {
  clearCheckPoint,
  clearCheckpointItemFormData,
  getCheckpointRequestCounter,
  getShowDeleteCheckpointModal,
  resetNumberofCheckpoints,
  setCheckpointInitialEmpty,
} from "../../../../redux/slice/client/checkpointSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import DeleteCheckpointModal from "components/Client/Checkpoint/DeleteCheckpointModal";

const ClientCheckPoint = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showDeleteModal = useAppSelector(getShowDeleteCheckpointModal);
  const counter = useAppSelector(getCheckpointRequestCounter);
  const [checkpointData, setCheckpointData] = useState<Array<CheckpointData>>(
    [],
  );
  const [requestLoading, setRequestLoading] = useState(false);
  useEffect(() => {
    const getCheckpointData = async () => {
      setRequestLoading(true);

      const response = await getRequest(SERVICE_CHECKPOINT);
      if (response?.success) {
        const localCheckpointData = [];
        const sectionedData = filterKeysFromArray(response?.data, "section");
        for (const section in sectionedData) {
          const findSection = response?.data.filter((item: any) => {
            return item.section === sectionedData[section];
          });
          localCheckpointData.push({
            id: findSection[0]?.id,
            location: findSection[0]?.location?.name,
            totalCheckpoint: findSection.length,
            service: findSection[0]?.service?.name,
            client: findSection[0]?.client?.clientName,
            sectionId: sectionedData[section],
          });
        }
        setCheckpointData(localCheckpointData);
      } else {
        toastError(response?.data);
      }
      setRequestLoading(false);
    };
    getCheckpointData();
    deleteCheckpointData();
    deleteCheckpointSection();
    dispatch(clearCheckPoint());
    dispatch(clearCheckpointItemFormData());
    dispatch(setCheckpointInitialEmpty(true));
    dispatch(resetNumberofCheckpoints());
  }, [counter, dispatch]);
  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="clients" />
        <div className={styles.container}>
          <div
            className={
              checkpointData.length <= 5
                ? `${styles.item_container} ${styles.item_container_empty}`
                : `${styles.item_container} ${styles.item_container_fill}`
            }
          >
            <div className={styles.flex_item}>
              <div>
                <Typography textAlign="left" textWeight="600" textSize="20px">
                  Checkpoint
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <Button
                  left={<img src={addIcon} alt="add" />}
                  marginHorizontal="10px"
                  marginTop="8px"
                  paddingVertical="7px"
                  width="210px"
                  isPrimary
                  text="Add Checkpoint"
                  onClick={() => navigate("/client/checkpoint/general")}
                />
              </div>
            </div>
            {checkpointData.length === 0 ? (
              <EmptyItemState
                text="You haven't added any checkpoint yet"
                location="client/checkpoint/general"
                label="Add Checkpoint"
              />
            ) : (
              <CheckpointTable data={checkpointData} />
            )}
          </div>
        </div>
      </section>
      {showDeleteModal && <DeleteCheckpointModal />}
    </>
  );
};

export default ClientCheckPoint;
