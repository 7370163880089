import React, { useState } from "react";
import styles from "./index.module.css";
import SelectInput from "../../../../Form/SelectInput";
import { EmployeeHrFormPropsUk } from "../../../../../types-definition/StaffHub/employee";
import TextInput from "../../../../Form/TextInput";
import InsuranceNumberTextInput from "../../../../Form/InsuranceNumberTextInput";
import validateInsuranceNumber from "../../../../../utils/validateInsuranceNumber";
import { getCountry } from "utils/localstorage";
import { US } from "types-definition/constants";

const EmployeeHrFormUk: React.FC<EmployeeHrFormPropsUk> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  const [insuranceNumberState, setShowInsuranceNumberState] = useState({
    error: false,
    message: "",
  });
  const country = getCountry();
  return (
    <>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.employeeNo}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, employeeNo: data };
            })
          }
          label="Employee Number"
          placeholder="Enter Employee Number"
        />
        {country === US ? (
          <TextInput
            value={formData.ssn}
            setValue={(data) =>
              setFormData((prevData) => {
                return { ...prevData, ssn: data };
              })
            }
            label="Social Security Number"
            placeholder="Enter Social Security Number"
          />
        ) : (
          <InsuranceNumberTextInput
            value={formData.nin}
            setValue={(data) => {
              if (data.length <= 9) {
                setFormData((prevData) => {
                  return { ...prevData, nin: data };
                });
                const result = validateInsuranceNumber(data);
                setShowInsuranceNumberState(result);
              }
            }}
            helperText={insuranceNumberState.message}
            label="National Insurance Number"
            placeholder="AB123456C"
            error={insuranceNumberState.error}
          />
        )}
      </div>

      <div className={styles.form_flex_container}>
        <SelectInput
          value={formData.payGrade}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, payGrade: data };
            });

            setRequiredFormData((prevData) => {
              return { ...prevData, payGrade: false };
            });
          }}
          label="Pay Grade"
          placeholder="Select Pay Grade"
          options={["Grade 1", "Grade 2"]}
          required={true}
          error={requiredFormData.payGrade}
          helperText={requiredFormData.payGrade ? "Pay Grade is Required" : ""}
          id="payGrade"
          readonly={true}
        />
        <SelectInput
          value={formData.payrollGroup}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, payrollGroup: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, payrollGroup: false };
            });
          }}
          label="Payroll Group"
          placeholder="Select Payroll Group"
          options={["Group A", "Group B"]}
          required={true}
          error={requiredFormData.payrollGroup}
          helperText={
            requiredFormData.payrollGroup ? "Payroll Group is Required" : ""
          }
          id="payrollGroup"
        />
      </div>

      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.personnelRate}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, personnelRate: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, personnelRate: false };
            });
          }}
          label="Personal Rate"
          placeholder="Enter Personal Rate"
          required={true}
          error={requiredFormData.personnelRate}
          helperText={
            requiredFormData.personnelRate ? "Personal Rate is Required" : ""
          }
          id="personnelRate"
        />
        <TextInput
          value={formData.holidayRate}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, holidayRate: data };
            })
          }
          label="Holiday Rate"
          placeholder="Enter Holiday Rate"
        />
      </div>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.contractHours}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, contractHours: data };
            })
          }
          label="Contract Hours"
          placeholder="Enter Contract Hours"
        />
        <SelectInput
          value={formData.contractedHoursPeriod}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, contractedHoursPeriod: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, contractedHoursPeriod: false };
            });
          }}
          label="Contracted Hours Period"
          options={["Daily", "Weekly", "Monthly"]}
          placeholder="Select Contracted hours period"
          required={true}
          error={requiredFormData.contractedHoursPeriod}
          helperText={
            requiredFormData.contractedHoursPeriod
              ? "Contracted Hours Period is Required"
              : ""
          }
          readonly={true}
          id="contractedHoursPeriod"
        />
      </div>
      <div className={styles.form_flex_container}>
        <SelectInput
          value={formData.holidayMethod}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, holidayMethod: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, holidayMethod: false };
            });
          }}
          label="Holiday Calculation Method"
          placeholder="Select Holiday Calculation Method"
          options={["Entitlement Days", "Accrued Holidays"]}
          required={true}
          error={requiredFormData.holidayMethod}
          helperText={
            requiredFormData.holidayMethod ? "Holiday method is Required" : ""
          }
          id="holidayMethod"
        />
      </div>
    </>
  );
};

export default EmployeeHrFormUk;
