import React, { useRef, useState } from "react";
import styles from "./index.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import { DateInputProps } from "../../../types-definition/form";
import calendarIcon from "../../../assets/svg/calendar-icon.svg";
import colors from "../../../theme/colors";
import moment from "moment";

const DateInput: React.FC<DateInputProps> = ({
  placeholder,
  label,
  labelColor,
  right,
  value,
  setValue,
  width,
  validateMin = false,
  max,
  min,
  required = false,
  error = false,
  disabled = false,
  style,
  helperText,
  id,
}) => {
  const ref = useRef<any>(null);
  const getMaxAge = (year: number) => {
    const presentDate = Date.now();
    const maxDateInNum = presentDate - 1000 * 60 * 60 * 24 * 365 * year;
    const date = new Date(maxDateInNum);
    const formattedDate = `${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${
      date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    }/${date.getFullYear()}`;
    return formattedDate;
  };
  const [isFocused, setIsFocused] = useState(false);

  return (
    <section className={styles.container} style={style} id={id}>
      <Typography
        textAlign="left"
        textSize={size.md}
        textWeight="600"
        textColor={labelColor}
      >
        {required ? `${label} *` : label}
      </Typography>
      <div className={styles.inputContainer} style={{ width }}>
        {value ? (
          <div className={styles.datepicker_container}>
            <DatePicker
              selected={value ? new Date(value) : new Date()}
              // value={new Date(value!).toDateString()}
              placeholderText="dd/mm/yyyy"
              onChange={(date: any) => {
                setValue(new Date(date!).toDateString());
              }}
              dateFormat="dd/MM/yyyy"
              className={`${styles.input} ${styles.right_padding}`}
              minDate={min ? new Date(min) : undefined}
              maxDate={max ? new Date(getMaxAge(max)) : undefined}
              startDate={max ? new Date(getMaxAge(max)) : undefined}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              ref={ref}
            />
          </div>
        ) : (
          <div className={styles.datepicker_container}>
            <DatePicker
              placeholderText={isFocused ? "dd/mm/yyyy" : placeholder}
              onFocus={() => {
                setIsFocused(true);
              }}
              onBlur={() => {
                setIsFocused(false);
              }}
              onChange={(date: any) => {
                setValue(new Date(date!).toDateString());
              }}
              dateFormat="dd/MM/yyyy"
              className={
                error
                  ? `${styles.error_border} ${styles.input} ${styles.right_padding}`
                  : `${styles.input} ${styles.right_padding} ${styles.width}`
              }
              disabled={
                disabled
                  ? true
                  : validateMin && !min
                    ? true
                    : validateMin && min
                      ? false
                      : false
              }
              minDate={min ? new Date(min) : undefined}
              maxDate={max ? new Date(getMaxAge(max)) : undefined}
              startDate={max ? new Date(getMaxAge(max)) : undefined}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              ref={ref}
            />
          </div>
        )}
        <div className={styles.helpertext_container}>
          <Typography
            textAlign="left"
            textSize={"10px"}
            textWeight="600"
            textColor={error ? colors.error : colors.primary3}
          >
            {helperText}
          </Typography>
        </div>

        <div className={styles.right} tabIndex={0} onClick={() => {}}>
          <img
            src={calendarIcon}
            alt="calendaricon"
            width={20}
            height={20}
            tabIndex={0}
            onClick={() => {
              ref.current.setFocus();
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default DateInput;
