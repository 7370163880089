import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../Common/PageBackButton";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../utils/localstorage";
import { getRequest } from "../../../../api/request";
import PageLoader from "../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../api/urls";
import { ServiceGroupActivityLog } from "../../../../types-definition/admin";
import DashboardHeader from "../../../Common/DashboardHeader";
import { toastError } from "utils/toast";

const ActivityServiceGroupTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentServiceGroup, setCurrentServiceGroup] =
    useState<ServiceGroupActivityLog>({
      name: "",
      active: null,
    });
  const [previousServiceGroup, setPreviousServiceGroup] =
    useState<ServiceGroupActivityLog>({
      name: "",
      active: null,
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getEmployeeData = async () => {
      // get employee data
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data.newRecord;
        // const previousData = response.data.previousRecord;
        if (response.data.action === "POST") {
          setCurrentServiceGroup({
            name: data?.serviceGroup?.name,
            active: data?.serviceGroup?.active,
          });
          // Previous Data is not returned for POST
          setPreviousServiceGroup({
            name: "-",
            active: null,
          });
        } else {
          setCurrentServiceGroup({
            name: response?.data?.newRecord?.serviceGroup?.name,
            active: response?.data?.newRecord?.serviceGroup?.active,
          });
          setPreviousServiceGroup({
            name: response?.data?.previousRecord?.serviceGroup?.name,
            active: response?.data?.previousRecord?.serviceGroup?.active,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getEmployeeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Service Group
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGroup.name}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Active</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGroup?.active === false && "False"}
                  {currentServiceGroup?.active === true && "True"}
                  {currentServiceGroup?.active === null && "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Service Group
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGroup.name ? previousServiceGroup.name : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Active</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGroup?.active === false && "False"}
                  {previousServiceGroup?.active === true && "True"}
                  {previousServiceGroup?.active === null && "-"}{" "}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityServiceGroupTab;
