import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import CertificateTypeTableItem from "../CertificateTypeTableItem";
import colors from "../../../../theme/colors";
import { CertificateTableProps } from "../../../../types-definition/StaffHub/certificateType";

const CertificateTypeTable: React.FC<CertificateTableProps> = ({
  data = [],
}) => {
  return (
    <>
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.tr} ${styles.tr_head}`}>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textSize="12px"
                  textColor={colors.tableText}
                >
                  Certificate Name
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textSize="12px"
                  textColor={colors.tableText}
                >
                  Requirement Level
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textSize="12px"
                  textColor={colors.tableText}
                >
                  No of Services
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textSize="12px"
                  textColor={colors.tableText}
                >
                  Status
                </Typography>
              </th>
              <th className={styles.th_index}></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return <CertificateTypeTableItem {...item} key={index} />;
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CertificateTypeTable;
