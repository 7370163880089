import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import TextInput from "../../../../Form/TextInput";
import emailIcon from "../../../../../assets/svg/Mail Icon.svg";
import { Country, State } from "country-state-city";
import { getCountry } from "../../../../../utils/localstorage";
import AddressSearchInput from "../../../../Form/AddressInputSearch";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import LocationSelectInput from "../../../../Form/LocationSelectInput";
import { EmployeeContactInfoFormProps } from "../../../../../types-definition/StaffHub/employee";

const EmployeeContactInfoForm: React.FC<EmployeeContactInfoFormProps> = ({
  formData,
  setFormData,
  currentCountryCode,
  setCurrentCountryCode,
  postCodeOptions,
  handlePostCodeAutoComplete,
  requiredFormData,
  setRequiredFormData,
}) => {
  const [address, setAddress] = useState<Array<any>>([]);
  const country = getCountry();
  const isoCode = Country.getAllCountries().find((i) => i.name === country)
    ?.isoCode;

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: "AIzaSyAM8qnCnyXYgNppoFnjGxQrV-dYo5_1OpQ",
      options: {
        componentRestrictions: {
          country: isoCode?.toLowerCase(),
        },
      },
    });

  useEffect(() => {
    setAddress(placePredictions.map((i) => i.description));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlacePredictionsLoading]);
  return (
    <div className={styles.container}>
      <div className={styles.form_flex_container}>
        <AddressSearchInput
          value={formData.address1}
          setValue={(data) => {
            getPlacePredictions({ input: data.value });
            if (data.action) {
              setFormData((prevData) => {
                const length = data.value.split(",").length;
                return {
                  ...prevData,
                  town: data.value.split(",")[length - 2],
                  state: data.value.split(",")[length - 2],
                  country: data.value.split(",")[length - 1],
                };
              });
              setRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  town: false,
                  state: false,
                  country: false,
                  address1: false,
                };
              });
            }
            setFormData((prevData) => {
              return { ...prevData, address1: data.value };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, address1: false };
            });
          }}
          label="Address Line 1"
          placeholder="Enter Address"
          required={true}
          error={requiredFormData.address1}
          helperText={
            requiredFormData.address1 ? "Address Line 1 is required" : ""
          }
          options={address}
          id="address1"
        />

        <TextInput
          value={formData.address2}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, address2: data };
            })
          }
          label="Address Line 2"
          placeholder="Enter Employee Address"
        />
      </div>

      <div className={styles.form_flex_container}>
        <LocationSelectInput
          value={formData.state}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, state: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, state: false };
            });
          }}
          code={currentCountryCode}
          label="State"
          placeholder="Enter State"
          setCode={(code) => {}}
          options={State.getStatesOfCountry(currentCountryCode)}
          required={true}
          error={requiredFormData.state}
          id="state"
        />
        <TextInput
          value={formData.town}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, town: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, town: false };
            });
          }}
          label="Town"
          placeholder="Enter Town"
          required={true}
          error={requiredFormData.town}
          helperText={requiredFormData.town ? "Town is required" : ""}
          id="town"
        />
      </div>
      <div className={styles.form_flex_container}>
        <LocationSelectInput
          value={formData.country}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, country: data };
            });

            setRequiredFormData((prevData) => {
              return { ...prevData, country: false };
            });
          }}
          setCode={(code) => {
            setCurrentCountryCode(code);
          }}
          label="Country"
          placeholder="Select Country"
          options={Country.getAllCountries()}
          disabled={true}
          required={true}
          error={requiredFormData.country}
          helperText={requiredFormData.country ? "Country is required" : ""}
          id="country"
        />
        <TextInput
          value={formData.zipCode}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, zipCode: data };
            });
          }}
          label="Zip Code"
          placeholder="Enter Zip Code"
        />
      </div>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.mobileNumber}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, mobileNumber: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, mobileNumber: false };
            });
          }}
          label="Mobile Number"
          placeholder="Enter Mobile Number"
          required={true}
          error={requiredFormData.mobileNumber}
          helperText={
            requiredFormData.mobileNumber ? "Mobile Number is required" : ""
          }
          id="mobileNumber"
          disabled={true}
        />
        <TextInput
          value={formData.email}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, email: data };
            });

            setRequiredFormData((prevData) => {
              return { ...prevData, address1: false };
            });
          }}
          label="Email"
          placeholder="Enter Email"
          right={<img src={emailIcon} alt="icon" width={20} height={20} />}
          required={true}
          error={requiredFormData.email}
          helperText={requiredFormData.email ? "Email is required" : ""}
          id="email"
        />
      </div>
    </div>
  );
};

export default EmployeeContactInfoForm;
