import React, { useEffect, useState } from "react";
import Typography from "../Typography";
import styles from "./index.module.css";
import { StatisticLocationTableData } from "../../../types-definition/common";
import tableSelectorIcon from "../../../assets/svg/table-selector.svg";
import moment from "moment";
import colors from "theme/colors";
import StatisticTableDropdown from "../StatisticTableDropdown";
import { setCurrentEmployee, setEmployeeId } from "utils/localstorage";
import { useNavigate } from "react-router-dom";
import { getRequest } from "api/request";
import { GET_GRADE_URL } from "api/urls";

const StatisticalTableItem: React.FC<StatisticLocationTableData> = ({
  employee,
  grade,
  arrivalTime,
  scheduledTime,
  indicator,
  serviceName
}) => {
  const navigate = useNavigate();
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  const [gradeName, setGradeName] = useState("N/A");
  const getGradeName = async () => {
    const response = await getRequest(`${GET_GRADE_URL}/${grade}`);
    if (response?.success) {
      setGradeName(response?.data?.grade);
    }
  };
  useEffect(() => {
    getGradeName();
  }, []);
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        <Typography textAlign="left" textColor={colors.primary3}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentEmployee(
                JSON.stringify({
                  name: employee.username,
                  displayName: `${employee.firstName} ${employee.lastName}`,
                }),
              );
              setEmployeeId(employee.id);
              navigate("/employee/view-profile");
            }}
          >
            {`${employee.firstName} ${employee.lastName}`}
          </span>
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {gradeName}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {serviceName}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {arrivalTime ? moment(new Date(arrivalTime)).format("h:mm A") : "-"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {/* {convertTimeTo12hours(scheduledTime)} */}
          {moment(scheduledTime, "hh:mm").format("h:mm A")}
        </Typography>
      </td>
      <td className={styles.td}>
        <div className={styles.sub_right_flex_container}>
          <div
            className={styles.indicator}
            style={{
              backgroundColor: indicator,
            }}
          ></div>
        </div>
      </td>
      <td className={styles.td}>
        <div
          className={`${styles.image_container} ${styles.td_relative}`}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
          onBlur={() => setshowEditDropdown(false)}
          tabIndex={0}
        >
          {showEditDropdown && <StatisticTableDropdown {...employee} />}
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default StatisticalTableItem;
