import React from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import { EmployeeAuditProps } from "../../../../../types-definition/StaffHub/employee";
import moment from "moment";
import colors from "../../../../../theme/colors";
import capitalize from "../../../../../utils/capitalize";

const EmployeeAuditTableItem: React.FC<EmployeeAuditProps> = ({
  action,
  category,
  changedAt,
}) => {
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left">{capitalize(category)}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {moment(new Date(changedAt)).format("MMMM DD, YYYY, hh:mm")}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {action === "POST"
            ? "Create"
            : action === "DELETE"
              ? "Delete"
              : "Update"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography
          textAlign="left"
          textWeight="600"
          textColor={colors.primary3}
          style={{ cursor: "pointer" }}
        >
          View details
        </Typography>
      </td>
    </tr>
  );
};

export default EmployeeAuditTableItem;
