import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import colors from "../../../theme/colors";
import { ActiveStateProps } from "../../../types-definition/common";

const ActiveState: React.FC<ActiveStateProps> = ({ active }) => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: active ? colors.successBg : colors.errorBg,
      }}
    >
      <Typography
        textWeight="550"
        textColor={active ? colors.successtext : colors.error}
        marginVertical="0px"
      >
        {active ? "Active" : "Inactive"}
      </Typography>
    </div>
  );
};

export default ActiveState;
