import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../../Common/PageBackButton";
import Typography from "components/Common/Typography";
import colors from "../../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import { getRequest } from "../../../../../api/request";
import PageLoader from "../../../../Common/PageLoader";
import {
  GET_SYSTEM_ACTIVITY,
  GET_SYSTEM_USER_ACTIVITY,
} from "../../../../../api/urls";
import { ServiceFinancialActivityLogsProps } from "../../../../../types-definition/admin";
import DashboardHeader from "../../../../Common/DashboardHeader";
import { toastError } from "utils/toast";
import { getData } from "../../../../../utils/localstorage";

const ActivityGeneralFinancialTab = () => {
  const data = JSON.parse(getData()! ?? "{}");
  const [pageLoading, setPageLoading] = useState(true);
  const [currentServiceFinancial, setCurrentServiceFinancial] =
    useState<ServiceFinancialActivityLogsProps>({
      payRate: "",
      minimumPaidHours: "",
      breakTimePaid: false,
      breakTimeDuration: "",
      IncludeinPayroll: false,
      fixedCost: false,
      fixedCostCharge: "",
      chargeRate: "",
      minimumChargeHours: "",
      breakTimeCharged: false,
      includeInInvoice: false,
    });
  const [previousServiceFinancial, setPreviousServiceFinancial] =
    useState<ServiceFinancialActivityLogsProps>({
      payRate: "",
      minimumPaidHours: "",
      breakTimePaid: false,
      breakTimeDuration: "",
      IncludeinPayroll: false,
      fixedCost: false,
      fixedCostCharge: "",
      chargeRate: "",
      minimumChargeHours: "",
      includeInInvoice: false,
      breakTimeCharged: false,
    });
  const id = getActivityBasicInformation();

  function findIndexById(array: any, id: string) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return i; // Return the index if found
      }
    }
    return -1; // Return -1 if not found
  }
  useEffect(() => {
    const getServiceFinanciaData = async () => {
      // NoTE All this logic was written because the invioce and pay info for service are on different object in activity log
      const activityResponse = await getRequest(
        `${GET_SYSTEM_USER_ACTIVITY}/${data.id}`,
      );

      let otherServiceFinancialInfo;
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const getFinancialIndex = await findIndexById(
          activityResponse.data,
          response.data.id,
        );
        if (
          activityResponse.data[getFinancialIndex]?.category === "INVOICE INFO"
        ) {
          otherServiceFinancialInfo =
            activityResponse.data[getFinancialIndex - 1];
        }
        if (activityResponse.data[getFinancialIndex]?.category === "PAY INFO") {
          otherServiceFinancialInfo =
            activityResponse.data[getFinancialIndex + 1];
        } else {
          // do nothing
        }
        const data = response.data;
        // const previousData = response.data;
        if (response.data.action === "POST") {
        } else {
          if (
            activityResponse.data[getFinancialIndex]?.category === "PAY INFO"
          ) {
            setCurrentServiceFinancial({
              payRate: data?.newRecord?.payInfo?.payRate,
              minimumPaidHours: data?.newRecord?.payInfo?.minPaidHours,
              breakTimePaid: data?.newRecord?.payInfo?.breakTimePaid,
              breakTimeDuration: data?.newRecord?.payInfo?.breakTimeDuration,
              IncludeinPayroll: data?.newRecord?.payInfo?.includeInPayroll,
              fixedCost:
                otherServiceFinancialInfo?.newRecord?.invoiceInfo?.fixedCost,
              fixedCostCharge:
                otherServiceFinancialInfo?.newRecord?.invoiceInfo
                  ?.fixedCostCharge,
              chargeRate:
                otherServiceFinancialInfo?.newRecord?.invoiceInfo?.chargeRate,
              minimumChargeHours:
                otherServiceFinancialInfo?.newRecord?.invoiceInfo
                  ?.minChargeHours,
              breakTimeCharged:
                otherServiceFinancialInfo?.newRecord?.invoiceInfo
                  ?.breakTimeCharged,
              includeInInvoice:
                otherServiceFinancialInfo?.newRecord?.invoiceInfo
                  ?.includeInInvoice,
            });
            setPreviousServiceFinancial({
              payRate: data?.previousRecord?.payInfo?.payRate,
              minimumPaidHours: data?.previousRecord?.payInfo?.minPaidHours,
              breakTimePaid: data?.previousRecord?.payInfo?.breakTimePaid,
              breakTimeDuration:
                data?.previousRecord?.payInfo?.breakTimeDuration,
              IncludeinPayroll: data?.previousRecord?.payInfo?.includeInPayroll,
              fixedCost:
                otherServiceFinancialInfo?.previousRecord?.invoiceInfo
                  ?.fixedCost,
              fixedCostCharge:
                otherServiceFinancialInfo?.previousRecord?.invoiceInfo
                  ?.fixedCostCharge,
              chargeRate:
                otherServiceFinancialInfo?.previousRecord?.invoiceInfo
                  ?.chargeRate,
              minimumChargeHours:
                otherServiceFinancialInfo?.previousRecord?.invoiceInfo
                  ?.minChargeHours,
              breakTimeCharged:
                otherServiceFinancialInfo?.previousRecord?.invoiceInfo
                  ?.breakTimeCharged,
              includeInInvoice:
                otherServiceFinancialInfo?.previousRecord?.invoiceInfo
                  ?.includeInInvoice,
            });
          } else {
            setCurrentServiceFinancial({
              payRate: otherServiceFinancialInfo?.newRecord?.payInfo?.payRate,
              minimumPaidHours:
                otherServiceFinancialInfo?.newRecord?.payInfo?.minPaidHours,
              breakTimePaid:
                otherServiceFinancialInfo?.newRecord?.payInfo?.breakTimePaid,
              breakTimeDuration:
                otherServiceFinancialInfo?.newRecord?.payInfo
                  ?.breakTimeDuration,
              IncludeinPayroll:
                otherServiceFinancialInfo?.newRecord?.payInfo?.includeInPayroll,
              fixedCost: data?.newRecord?.invoiceInfo?.fixedCost,
              fixedCostCharge: data?.newRecord?.invoiceInfo?.fixedCostCharge,
              chargeRate: data?.newRecord?.invoiceInfo?.chargeRate,
              minimumChargeHours: data?.newRecord?.invoiceInfo?.minChargeHours,
              breakTimeCharged: data?.newRecord?.invoiceInfo?.breakTimeCharged,
              includeInInvoice: data?.newRecord?.invoiceInfo?.includeInInvoice,
            });

            setPreviousServiceFinancial({
              payRate:
                otherServiceFinancialInfo?.previousRecord?.payInfo?.payRate,
              minimumPaidHours:
                otherServiceFinancialInfo?.previousRecord?.payInfo
                  ?.minPaidHours,
              breakTimePaid:
                otherServiceFinancialInfo?.previousRecord?.payInfo
                  ?.breakTimePaid,
              breakTimeDuration:
                otherServiceFinancialInfo?.previousRecord?.payInfo
                  ?.breakTimeDuration,
              IncludeinPayroll:
                otherServiceFinancialInfo?.previousRecord?.payInfo
                  ?.includeInPayroll,
              fixedCost: data?.previousRecord?.invoiceInfo?.fixedCost,
              fixedCostCharge:
                data?.previousRecord?.invoiceInfo?.fixedCostCharge,
              chargeRate: data?.previousRecord?.invoiceInfo?.chargeRate,
              minimumChargeHours:
                data?.previousRecord?.invoiceInfo?.minChargeHours,
              breakTimeCharged:
                data?.previousRecord?.invoiceInfo?.breakTimeCharged,
              includeInInvoice:
                data?.previousRecord?.invoiceInfo?.includeInInvoice,
            });
          }
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getServiceFinanciaData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Service Financial.
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Pay Rate</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceFinancial.payRate ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Minimum Paid Hours</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceFinancial.minimumPaidHours ?? "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Break Time Duration</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceFinancial.breakTimeDuration ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Include in Payroll</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceFinancial.IncludeinPayroll === true && "True"}
                  {currentServiceFinancial.IncludeinPayroll === false &&
                    "False"}
                  {currentServiceFinancial.IncludeinPayroll === null && "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}></div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Charge Rate</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceFinancial.chargeRate ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Minimum Charge Hours</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceFinancial.minimumChargeHours ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Break Time Charged</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceFinancial.breakTimeCharged === true && "True"}
                  {currentServiceFinancial.breakTimeCharged === false &&
                    "False"}
                  {currentServiceFinancial.breakTimeCharged === null && "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Fixed Cost Charge</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceFinancial.fixedCostCharge ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Break Time Paid</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceFinancial.breakTimePaid === true && "True"}
                  {currentServiceFinancial.breakTimePaid === false && "False"}
                  {currentServiceFinancial.breakTimePaid === null && "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Include in Invoice</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceFinancial.includeInInvoice === true && "True"}
                  {currentServiceFinancial.includeInInvoice === false &&
                    "False"}
                  {currentServiceFinancial.includeInInvoice === null && "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Service Financial.
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Pay Rate</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceFinancial.payRate ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Minimum Paid Hours</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceFinancial.minimumPaidHours ?? "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Break Time Duration</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceFinancial.breakTimeDuration ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Include in Payroll</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceFinancial.IncludeinPayroll === true && "True"}
                  {previousServiceFinancial.IncludeinPayroll === false &&
                    "False"}
                  {previousServiceFinancial.IncludeinPayroll === null && "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}></div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Charge Rate</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceFinancial.chargeRate ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Minimum Charge Hours</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceFinancial.minimumChargeHours ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Break Time Charged</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceFinancial.breakTimeCharged ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Fixed Cost Charge</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceFinancial.fixedCostCharge ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                {" "}
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceFinancial.breakTimePaid === true && "True"}
                  {previousServiceFinancial.breakTimePaid === false && "False"}
                  {previousServiceFinancial.breakTimePaid === null && "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Include in Invoice</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceFinancial.includeInInvoice === true && "True"}
                  {previousServiceFinancial.includeInInvoice === false &&
                    "False"}
                  {previousServiceFinancial.includeInInvoice === null && "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityGeneralFinancialTab;
