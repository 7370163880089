import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import FullWidthSelectInput from "../../../../Form/FullWidthSelectInput";
import {
  CertificationData,
  EmployeeCertificationFormProps,
} from "../../../../../types-definition/StaffHub/employee";
import { CertificationRequiredData } from "../../../../../types-definition/StaffHub/certificateGroup";
import TextInput from "../../../../Form/TextInput";
import DateInput from "../../../../Form/DateInput";
import Button from "../../../../Form/Button";
import colors from "../../../../../theme/colors";
import UploadItem from "../../../../Form/UploadItem";
import { deleteRequest, getRequest } from "../../../../../api/request";
import { GET_CERTIFICATE_URL } from "../../../../../api/urls";
import {
  getCurrentEmployee,
  getUserTypes,
} from "../../../../../utils/localstorage";
import toast from "react-hot-toast";
import DeleteCertificate from "../../../../Form/DeleteCertificateModal";
import EditEmployeeCertification from "../EditEmployeeCertificationForm";
import { useAppDispatch } from "../../../../../redux/hooks";
import { deleteCertification } from "../../../../../redux/slice/staff-hub/employeeSlice";
import { toastSuccess } from "../../../../../utils/toast";
import checkAdminPermission, { checkUserPermission } from "utils/permission";
import { CERTIFICATE_EMPLOYEE_DELETE } from "types-definition/constants";
import ClientSelectInput from "components/Form/ClientSelectInput";

const EmployeeCertification: React.FC<EmployeeCertificationFormProps> = ({
  cerficateData,
  certificateType,
}) => {
  const dispatch = useAppDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [isDeletingPermission, setIsDeletingPermission] = useState(true);
  const [localCertificateData, setLocalCertificateData] =
    useState<CertificationData>({
      certificate: "",
      certificateNumber: "",
      certificateTypeName: "",
      certificateType: "",
      id: "",
      expiryDate: "",
      startDate: "",
    });
  const [requiredLocalCertificateData, setRequiredLocalCertificateData] =
    useState<CertificationRequiredData>({
      certificateType: false,
      expiryDate: false,
      startDate: false,
    });
  useEffect(() => {
    const getData = async () => {
      if (cerficateData) {
        setLocalCertificateData(cerficateData);
        const response = await getRequest(
          `${GET_CERTIFICATE_URL}/${cerficateData?.id}`,
        );
        if (response?.success) {
          setLocalCertificateData((prevData) => {
            return {
              ...prevData,
              certificate: response.data?.certificate
                ? {
                    URL: response.data.certificate,
                    size: response.data.size,
                    lastModifiedDate: response.data.createdAt,
                    name: response.data.originalName,
                  }
                : "",
              certificateType: cerficateData?.certificateType ?? "None",
              certificateTypeName:
                certificateType.find(
                  (item) => item.id === cerficateData?.certificateType,
                )?.name ?? "None",
            };
          });
        } else {
          setLocalCertificateData((prevData) => {
            return { ...prevData, certificateType: "None" };
          });
        }
      }
    };
    getData();
  }, [cerficateData]);
  const handleDelete = async () => {
    const userData = JSON.parse(getCurrentEmployee()!);
    const response = await deleteRequest(
      `employee/${userData.name}/certification/${localCertificateData.id}`,
    );
    if (response?.success) {
      dispatch(deleteCertification(localCertificateData.id));
      toastSuccess("Certificate Deleted");
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    const getPermission = async () => {
      const data = checkAdminPermission(getUserTypes());
      if (data) {
        setIsDeletingPermission(false);
      } else {
        const result = await checkUserPermission([CERTIFICATE_EMPLOYEE_DELETE]);
        setIsDeletingPermission(result ? false : true);
      }
    };
    getPermission();
  }, []);
  // const data: CertificationData = {};
  return (
    <>
      <div>
        <ClientSelectInput
          value={localCertificateData.certificateTypeName}
          client={localCertificateData.certificateTypeName}
          setValue={(name, id) => {
            setLocalCertificateData((prevData) => {
              return {
                ...prevData,
                certificateType: id ?? "",
                certificateTypeName: name,
              };
            });
            setRequiredLocalCertificateData((prevData) => {
              return { ...prevData, certificateType: false };
            });
          }}
          label="Certificate Type"
          placeholder="Select Certificate"
          options={certificateType}
          required={true}
          error={requiredLocalCertificateData.certificateType}
          disabled={true}
        />
        <UploadItem
          label="Certificate"
          value={localCertificateData.certificate}
          setValue={(data) =>
            setLocalCertificateData((prevData) => {
              return { ...prevData, certificate: data };
            })
          }
          disabled={true}
        />
      </div>
      <div className={styles.form_flex_container}>
        <TextInput
          value={localCertificateData.certificateNumber}
          setValue={(data) =>
            setLocalCertificateData((prevData) => {
              return { ...prevData, certificateNumber: data };
            })
          }
          label="Certificate Number"
          placeholder="Enter Certificate Number"
          disabled={true}
        />
        <DateInput
          value={localCertificateData.startDate}
          setValue={(data) => {
            setLocalCertificateData((prevData) => {
              return { ...prevData, startDate: data };
            });
            setRequiredLocalCertificateData((prevData) => {
              return { ...prevData, startDate: false };
            });
          }}
          label="Start Date"
          placeholder="Select Date"
          required={true}
          disabled={true}
          error={requiredLocalCertificateData.startDate}
        />
        <DateInput
          value={localCertificateData.expiryDate}
          setValue={(data) => {
            setLocalCertificateData((prevData) => {
              return { ...prevData, expiryDate: data };
            });
            setRequiredLocalCertificateData((prevData) => {
              return { ...prevData, expiryDate: false };
            });
          }}
          disabled={true}
          min={localCertificateData.startDate}
          validateMin={true}
          label="Expiry Date"
          placeholder="Select Date"
          required={true}
          error={requiredLocalCertificateData.expiryDate}
        />
      </div>
      <div className={styles.button_container}>
        <Button
          width="200px"
          text="Delete Certificate"
          isPrimary={false}
          disabled={isDeletingPermission}
          onClick={() => {
            if (isDeletingPermission) {
              return;
            }
            setShowDelete(true);
          }}
          marginHorizontal="10px"
          backgroundColor={colors.transparent}
          isPrimaryBorder={false}
          textColor={isDeletingPermission ? colors.white : colors.error}
          borderColor={isDeletingPermission ? colors.transparent : colors.error}
          borderWidth="2px"
        />
        <Button
          width="200px"
          text="Edit Certificate"
          isPrimary={true}
          onClick={() => setShowEdit(true)}
          isLoading={false}
        />
      </div>

      {showDelete && (
        <DeleteCertificate
          cancelAction={() => setShowDelete(false)}
          deleteAction={handleDelete}
        />
      )}
      {showEdit && (
        <EditEmployeeCertification
          certificateData={cerficateData}
          certificateType={certificateType}
          cancelFunction={() => setShowEdit(false)}
        />
      )}
    </>
  );
};

export default EmployeeCertification;
