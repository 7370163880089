import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import AdminSideBar from "components/Admin/SideBar";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import {
  GET_DEFAULT_PERMISSION_GROUP_URL,
  GET_PERMISSION_GROUP_URL,
} from "api/urls";
import { getRequest } from "api/request";
import addIcon from "assets/svg/add-icon.svg";
import AddPermissionGroupModal from "components/Admin/Permission Group/AddPermissionGroupModal";
import PermissionGroupTable from "components/Admin/Permission Group/PermissionTable";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getFetchedPermissionGroupCounter,
  getShowPermissionCloneModal,
  getShowPermissionDeleteModal,
  getShowPermissionEditModal,
  getShowPermissionModal,
  getShowPermissionViewModal,
  getShowViewClonePermissionGroupModal,
  setShowPermissionGroup,
} from "../../../redux/slice/admin/adminSlice";
import DeletePermissionGroup from "components/Admin/Permission Group/DeletePermissionGroup";
import EditPermissionGroupModal from "components/Admin/Permission Group/EditPermissionGroupModal";
import UpdatePermissionGroupModal from "components/Admin/Permission Group/UpdatePermissionGroupModal";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import ClonePermissionGroupModal from "components/Admin/Permission Group/ClonePermissionGroupModal";
import ViewClonePermissionGroupModal from "components/Admin/Permission Group/ViewClonePermissionModal";
import AdminEmptyItemState from "components/Common/AdminEmptyStateItem";
import { toastError } from "utils/toast";
import PermissionInformationModal from "components/Admin/Permission Group/PermissionInfomationModal";

const AdminPermissionGroup: React.FC = () => {
  const dispatch = useAppDispatch();
  const showAddPermissionModal = useAppSelector(getShowPermissionModal);
  const showDeleteModal = useAppSelector(getShowPermissionDeleteModal);
  const showEditModal = useAppSelector(getShowPermissionEditModal);
  const showViewModal = useAppSelector(getShowPermissionViewModal);
  const showCloneModal = useAppSelector(getShowPermissionCloneModal);
  const [showDependentPermisionModal, setShowDependentPermisionModal] =
    useState(false);
  const showViewCloneModal = useAppSelector(
    getShowViewClonePermissionGroupModal,
  );
  const counter = useAppSelector(getFetchedPermissionGroupCounter);
  const [requestLoading, setRequestLoading] = useState(false);
  const [dependentPermission, setDependentPermission] = useState<any>({
    dependentPermission: [],
    selectedPermission: "",
  });
  const [permissionGroupData, setPermissionGroupData] = useState<Array<any>>(
    [],
  );
  const handleShowDependentPermissionModal = (
    data: any,
    selectedPermission: string,
  ) => {
    setDependentPermission({
      dependentPermission: data,
      selectedPermission: selectedPermission,
    });
    setShowDependentPermisionModal(!showDependentPermisionModal);
  };

  useEffect(() => {
    const getUserDetails = async () => {
      setRequestLoading(true);
      const response = await getRequest(GET_PERMISSION_GROUP_URL);
      const defaultPermissionResponse = await getRequest(
        GET_DEFAULT_PERMISSION_GROUP_URL,
      );
      if (response?.success) {
        const data: Array<any> = [];
        for (const index in response?.data) {
          data.push({
            ...response?.data[index],
            cloned: response?.data[index].cloneId ? true : false,
            defaultPermission: false,
          });
        }
        const defaultPermissionArray = defaultPermissionResponse.data.map(
          (item: any) => {
            return { ...item, defaultPermission: true };
          },
        );
        // setPermissionGroupData(data);
        setPermissionGroupData([...defaultPermissionArray, ...data]);
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
      setRequestLoading(false);
    };
    getUserDetails();
  }, [counter]);

  return (
    <>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <AdminSideBar active="permission groups" />
        <div className={styles.item_container}>
          <div className={styles.flex_item}>
            <div>
              <Typography textAlign="left" textWeight="600" textSize="20px">
                Permission Groups
              </Typography>
            </div>
            <Button
              left={<img src={addIcon} alt="add" />}
              marginHorizontal="10px"
              marginTop="8px"
              paddingVertical="10px"
              width="250px"
              isPrimary
              text="Add Permission Group"
              onClick={() => dispatch(setShowPermissionGroup(true))}
            />
          </div>
          {permissionGroupData.length === 0 ? (
            <AdminEmptyItemState
              text="You haven't added any permission group yet"
              text2="Permission Groups enable you to consolidate various page permissions or accesses into a designated grouping. This grouping, referred to as a Permission Group, can be assigned to a web user, automatically granting them the individual permissions associated with the group"
              action={() => dispatch(setShowPermissionGroup(true))}
              location="admin/permission-groups"
              label="Add Permission Group"
            />
          ) : (
            <PermissionGroupTable data={permissionGroupData} />
          )}
        </div>
      </div>

      {showAddPermissionModal && <AddPermissionGroupModal />}
      {showDeleteModal && <DeletePermissionGroup />}
      {showCloneModal && <ClonePermissionGroupModal />}
      {showEditModal && <UpdatePermissionGroupModal />}
      {showDependentPermisionModal && (
        <PermissionInformationModal
          dependentPermission={dependentPermission.dependentPermission}
          closeModal={() => {
            setShowDependentPermisionModal(false);
          }}
          permissionId={dependentPermission.selectedPermission}
        />
      )}
      {!showDependentPermisionModal && showViewModal && (
        <EditPermissionGroupModal
          showDependentPermissionModal={handleShowDependentPermissionModal}
        />
      )}
      {showViewCloneModal && <ViewClonePermissionGroupModal />}
    </>
  );
};

export default AdminPermissionGroup;
