import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import ActiveState from "../../../Common/ActiveState";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import { SiteLocationData } from "../../../../types-definition/client";

import SitLocationDropDown from "../SiteLocationDropDown";
import LinkComponent from "../../../Common/LinkComponent";

const SiteLocationTableItem: React.FC<SiteLocationData> = ({
  id,
  name,
  address,
  managedBy,
  areaName,
  clientName,
  active,
  client,
  services,
}) => {
  const [showEditDropdown, setshowEditDropdown] = useState(false);

  useEffect(() => {}, []);
  const handleCloseDropDown = () => {
    setshowEditDropdown(false);
  };

  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        <Typography textAlign="left" textColor="#148A9B">
          {name}
        </Typography>
      </td>
      <td className={styles.td_address}>
        <Typography textAlign="left">{address ?? "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{clientName}</Typography>
      </td>
      <td className={styles.td}>
        <LinkComponent link="/client/service" showTextDecoration>
          <Typography textAlign="left" textColor="#1371FD">
            {parseInt(services) === 0 ? "Create" : services}
          </Typography>
        </LinkComponent>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{managedBy}</Typography>
      </td>
      <td className={styles.td}>
        <ActiveState active={active} />
      </td>
      <td
        className={`${styles.td} ${styles.td_relative}`}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        {showEditDropdown && (
          <SitLocationDropDown
            handleCloseDropDown={handleCloseDropDown}
            locationId={id ? id : ""}
            locationName={name ?? ""}
          />
        )}
        <div
          className={styles.image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default SiteLocationTableItem;
