import React, { useState } from "react";
import styles from "./index.module.css";
import cautionIcon from "../../../../../assets/svg/caution-icon.svg";
import Typography from "../../../../Common/Typography";
import Button from "../../../../Form/Button";
import { useAppDispatch } from "../../../../../redux/hooks";
import colors from "../../../../../theme/colors";
import {
  setShowConfirmResetPasswordModal,
  setShowSuccessResetPasswordModal,
} from "../../../../../redux/slice/staff-hub/employeeViewProfileSlice";
import { patchRequest } from "../../../../../api/request";
import { getCurrentEmployee } from "../../../../../utils/localstorage";
import generatePassword from "../../../../../utils/generatePassword";
import { toastError } from "../../../../../utils/toast";

const EmployeeResetPasswordConfirmModal = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const employee = JSON.parse(getCurrentEmployee() ?? "");
  const handleResetPassword = async () => {
    setIsLoading(true);
    const response = await patchRequest(
      `employee/${employee.name}/reset-password`,
      {
        password: generatePassword(8),
      },
    );
    if (response?.success) {
      dispatch(setShowSuccessResetPasswordModal(true));
      dispatch(setShowConfirmResetPasswordModal(false));
    } else {
      toastError(response?.data);
    }
    setIsLoading(false);
  };
  return (
    <section className={styles.container}>
      <div className={styles.content_container}>
        <img src={cautionIcon} alt="success-icon" />
        <Typography textAlign="left" textWeight="600" textSize="18px">
          Confirm Password Reset
        </Typography>
        <Typography textAlign="left" marginVertical="15px">
          Are you sure you want to reset {employee?.displayName}'s password?
        </Typography>
        <div className={styles.button_container}>
          <Button
            onClick={handleResetPassword}
            paddingVertical="5px"
            marginVertical="10px"
            text="Yes, Reset"
            isPrimary={true}
            isLoading={isLoading}
          />
          <Button
            onClick={() => {
              dispatch(setShowConfirmResetPasswordModal(false));
            }}
            paddingVertical="5px"
            marginVertical="10px"
            text="No, Cancel"
            borderWidth="2px"
            isPrimary={false}
            isPrimaryBorder={true}
            backgroundColor={colors.transparent}
          />
        </div>
      </div>
    </section>
  );
};

export default EmployeeResetPasswordConfirmModal;
