import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { ClientEmployeeShiftTableProps } from "../../../../types-definition/client";
import colors from "../../../../theme/colors";
import ClientEmployeeShiftTableItem from "../ClientEmployeeShiftTableItem";

const ClientEmployeeShiftTable: React.FC<ClientEmployeeShiftTableProps> = ({
  data = [],
}) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th_index}></th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Expected Time
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                End Time
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Location
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Services
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Name
              </Typography>
            </th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return <ClientEmployeeShiftTableItem {...item} key={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ClientEmployeeShiftTable;
