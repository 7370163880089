import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { SiteLocationTableProps } from "../../../../types-definition/client";
import colors from "../../../../theme/colors";
import SiteLocationTableItem from "../SiteLocationTableItem";
import ShiftNoteModal from "../ShiftNote";
import { useAppSelector } from "../../../../redux/hooks";
import { getShowModal } from "../../../../redux/slice/client/shiftNote";

const SiteLocationTable: React.FC<SiteLocationTableProps> = ({ data = [] }) => {
  const showAddModal = useAppSelector(getShowModal);
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Site Location Name
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Address
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Client
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Services
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Managed By
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Status
              </Typography>
            </th>
            <th className={styles.th_index}></th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return <SiteLocationTableItem {...item} key={item.id} />;
          })}
        </tbody>
      </table>
      {showAddModal && <ShiftNoteModal />}
    </div>
  );
};

export default SiteLocationTable;
