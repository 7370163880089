import React from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import colors from "../../../../theme/colors";
import { ClientShiftNavigationProps } from "../../../../types-definition/client";

const ClientShiftNavigation: React.FC<ClientShiftNavigationProps> = ({
  active,
  setActive,
}) => {
  return (
    <div className={styles.container}>
      <div
        className={
          active === "unconfirmed-shift"
            ? styles.active_container_item
            : styles.container_item_left
        }
        onClick={() => {
          setActive("unconfirmed-shift");
        }}
      >
        <Typography
          textWeight={active === "unconfirmed-shift" ? "600" : "500"}
          fontFamily="inter"
          textColor={
            active === "unconfirmed-shift" ? colors.white : colors.black
          }
        >
          Unconfirmed Shifts
        </Typography>
      </div>
      <div
        className={
          active === "canceled-shift"
            ? styles.active_container_item_right
            : styles.container_item_right
        }
        onClick={() => {
          setActive("canceled-shift");
        }}
      >
        <Typography
          textWeight={active === "canceled-shift" ? "600" : "500"}
          fontFamily="inter"
          textColor={active === "canceled-shift" ? colors.white : colors.black}
        >
          Cancelled Shifts
        </Typography>
      </div>
    </div>
  );
};

export default ClientShiftNavigation;
