import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { CertificationData } from "../../../types-definition/StaffHub/employee";
interface EmployeeState {
  certification: Array<CertificationData>;
  showAvalabilityModal: boolean;
  showAddHolidayModal: boolean;
  showAvalabilityTypeModal: boolean;
  showDeleteAvailabilityModal: boolean;
  showApproveHolidayModal: boolean;
  showDeleteEmployeeModal: boolean;
  employeeAvalabilityCounter: number;
  employeeHolidayAbsenceCounter: number;
  employeeStateCounter: number;
  employeeAbsenceHolidays: Array<any>;
  showFindAddressDropdown: boolean;
  avalabilityType: "one-time" | "recurring";
  availabilityDeleteType: "all-events" | "this-event" | "this-and-following";
  availabilityDeleteId: string;
  availabilitySegmentDeleteId: string;
  currentMinDate: string;
}

// Define the initial state using that type
const initialState: EmployeeState = {
  certification: [],
  showAvalabilityModal: false,
  showAddHolidayModal: false,
  showApproveHolidayModal: false,
  employeeAvalabilityCounter: 0,
  employeeHolidayAbsenceCounter: 0,
  employeeStateCounter: 0,
  employeeAbsenceHolidays: [],
  showFindAddressDropdown: false,
  showAvalabilityTypeModal: false,
  showDeleteAvailabilityModal: false,
  showDeleteEmployeeModal: false,
  avalabilityType: "one-time",
  availabilityDeleteType: "all-events",
  availabilityDeleteId: "",
  availabilitySegmentDeleteId: "",
  currentMinDate: "",
};

export const employeeSlice = createSlice({
  name: "employee",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCertification: (state, action) => {
      state.certification = action.payload;
    },
    updateCertification: (state, action) => {
      const { id, data } = action.payload;
      const index = state.certification.findIndex((item) => item.id === id);
      state.certification[index] = data;
    },
    deleteCertification: (state, action) => {
      const { id } = action.payload;
      const index = state.certification.findIndex((item) => item.id === id);
      state.certification.splice(index, 1);
    },
    addNewCertification: (state, action) => {
      state.certification.push(action.payload);
    },
    setShowAvalabilityModal: (
      state,
      action: PayloadAction<{ show: boolean; minDate: string }>,
    ) => {
      if (action.payload.show) {
        state.showAvalabilityTypeModal = action.payload.show;
        state.currentMinDate = action.payload.minDate;
      } else {
        state.showAvalabilityModal = action.payload.show;
        state.currentMinDate = action.payload.minDate;
      }
    },
    setShowAvalabilityTypeModal: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.showAvalabilityModal = action.payload;
        state.showAvalabilityTypeModal = !action.payload;
      } else {
        state.showAvalabilityTypeModal = action.payload;
      }
    },
    setShowDeleteAvailabilityModal: (
      state,
      action: PayloadAction<{ show: boolean; id: string; segmentId: string }>,
    ) => {
      state.showDeleteAvailabilityModal = action.payload.show;
      state.availabilityDeleteId = action.payload.id;
      state.availabilitySegmentDeleteId = action.payload.segmentId;
    },
    setAvalabilityType: (
      state,
      action: PayloadAction<"one-time" | "recurring">,
    ) => {
      state.avalabilityType = action.payload;
    },
    setDeleteAvailabilityType: (
      state,
      action: PayloadAction<"all-events" | "this-event" | "this-and-following">,
    ) => {
      state.availabilityDeleteType = action.payload;
    },
    setShowAddHolidayModal: (
      state,
      action: PayloadAction<{ show: boolean; minDate: string }>,
    ) => {
      state.showAddHolidayModal = action.payload.show;
      state.currentMinDate = action.payload.minDate;
    },
    setShowApproveHolidayModal: (state, action) => {
      state.showApproveHolidayModal = action.payload;
    },
    setShowFindAddressDropdown: (state, action) => {
      state.showFindAddressDropdown = action.payload;
    },
    setShowDeleteEmployeeModal: (state, action) => {
      state.showDeleteEmployeeModal = action.payload;
    },
    setEmployeeAbsenceHolidays: (state, action) => {
      state.employeeAbsenceHolidays = [];
      state.employeeAbsenceHolidays = action.payload;
    },
    updateEmployeeAvalabilityCounter: (state) => {
      state.employeeAvalabilityCounter += 1;
    },
    updateEmployeeHolidayAbsenceCounter: (state) => {
      state.employeeHolidayAbsenceCounter += 1;
    },
    updateEmployeStateCounter: (state) => {
      state.employeeStateCounter += 1;
    },
  },
});

export const {
  setCertification,
  updateCertification,
  deleteCertification,
  addNewCertification,
  setShowAvalabilityModal,
  setAvalabilityType,
  setShowDeleteAvailabilityModal,
  setDeleteAvailabilityType,
  setShowAvalabilityTypeModal,
  setShowAddHolidayModal,
  setShowApproveHolidayModal,
  setEmployeeAbsenceHolidays,
  setShowFindAddressDropdown,
  setShowDeleteEmployeeModal,
  updateEmployeeAvalabilityCounter,
  updateEmployeeHolidayAbsenceCounter,
  updateEmployeStateCounter,
} = employeeSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;
// export const IsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const getCertification = (state: RootState) =>
  state.employee.certification;
export const getEmployeeAbsenceHolidays = (state: RootState) =>
  state.employee.employeeAbsenceHolidays;
export const getShowAvalabilityModal = (state: RootState) =>
  state.employee.showAvalabilityModal;
export const getShowAddHolidayModal = (state: RootState) =>
  state.employee.showAddHolidayModal;
export const getShowApproveHolidayModal = (state: RootState) =>
  state.employee.showApproveHolidayModal;
export const getEmployeeAvalabilityCounter = (state: RootState) =>
  state.employee.employeeAvalabilityCounter;
export const getEmployeeholidayAbsenceCounter = (state: RootState) =>
  state.employee.employeeHolidayAbsenceCounter;
export const getEmployeeFindAddressDropdown = (state: RootState) =>
  state.employee.showFindAddressDropdown;
export const getAddHolidayModalMinDate = (state: RootState) =>
  state.employee.currentMinDate;
export const getShowAvalabilityTypeModal = (state: RootState) =>
  state.employee.showAvalabilityTypeModal;
export const getAvalabilityType = (state: RootState) =>
  state.employee.avalabilityType;
export const getDeleteAvailabilityType = (state: RootState) =>
  state.employee.availabilityDeleteType;
export const getShowDeleteAvailabilityModal = (state: RootState) =>
  state.employee.showDeleteAvailabilityModal;
export const getShowDeleteEmployeeModal = (state: RootState) =>
  state.employee.showDeleteEmployeeModal;
export const getDeleteAvailabilityId = (state: RootState) =>
  state.employee.availabilityDeleteId;
export const getDeleteAvailabilitySegmentId = (state: RootState) =>
  state.employee.availabilitySegmentDeleteId;
export const getEmployeStateCounter = (state: RootState) =>
  state.employee.employeeStateCounter;

export default employeeSlice.reducer;
