import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import AdminSideBar from "components/Admin/SideBar";
import Typography from "components/Common/Typography";
import SettingsNavigation from "components/Admin/Settings/SettingsNavigation";
import {
  ShiftGenerationRequiredFormData,
  ShiftGenerationFormData,
} from "types-definition/admin";
import Button from "components/Form/Button";
import colors from "theme/colors";
import {
  ADMIN_SHIFT_GENERATION,
  GET_USER_ADMIN_SETTINGS_URL,
  GET_USER_SETTINGS_URL,
} from "api/urls";
import { getRequest, patchRequest } from "api/request";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ShiftGenerationForm from "components/Admin/Settings/ShiftGeneration";
import validator from "utils/validateFields";
import { toastError, toastSuccess } from "utils/toast";
import { ADMIN } from "types-definition/constants";
import { getUserTypes } from "utils/localstorage";

const ShiftGeneration: React.FC = () => {
  const navigate = useNavigate();
  const user = getUserTypes();
  const [id, setId] = useState<number>();
  const [formData, setFormData] = useState<ShiftGenerationFormData>({
    daysToGenerateAhead: "",
    emailNotification: false,
  });

  const [requiredFormData, setRequiredFormData] =
    useState<ShiftGenerationRequiredFormData>({
      daysToGenerateAhead: false,
    });

  const [requestLoading, setRequestLoading] = useState(false);
  const handleFormCancelation = () => {
    navigate("/admin/settings/holiday-settings");
  };

  const handleSubmitForm = async () => {
    String(formData.emailNotification);
    const { status, data } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }

    try {
      setRequestLoading(true);
      const response = await patchRequest(`${ADMIN_SHIFT_GENERATION}/${id}`, {
        daysToGenerateAhead: formData.daysToGenerateAhead,
        emailNotification: String(formData.emailNotification),
      });
      if (response?.success) {
        toastSuccess("Shift Generation  Set Successfully");
        navigate("/admin/settings/manual-check-reason");
      } else {
        toastError(response?.data);
      }
    } finally {
      setRequestLoading(false);
    }
  };

  useEffect(() => {
    const getUserDetails = async () => {
      let response: any;
      if (user === ADMIN) {
        response = await getRequest(GET_USER_SETTINGS_URL);
      } else {
        response = await getRequest(GET_USER_ADMIN_SETTINGS_URL);
      }
      if (response?.success) {
        setId(response.data?.shiftGeneration.id);
        setFormData((prevData) => {
          return {
            ...prevData,
            daysToGenerateAhead:
              response.data?.shiftGeneration.daysToGenerateAhead,
            generationTime: response.data?.shiftGeneration,
            emailNotification:
              response.data?.shiftGeneration.emailNotification ?? false,
          };
        });
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    };
    getUserDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <AdminSideBar active="settings" />
        <div className={styles.item_container}>
          <SettingsNavigation active="shift generation" />
          <div className={styles.content_container}>
            <Typography textAlign="left" textWeight="600" textSize="18px">
              Shift Generation
            </Typography>
            <ShiftGenerationForm
              formData={formData}
              setFormData={setFormData}
              requiredFormData={requiredFormData}
              setRequiredFormData={setRequiredFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Back"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={false}
                borderColor="#148A9B"
                borderWidth="2px"
                textColor="#148A9B"
                textWeight="600"
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShiftGeneration;
