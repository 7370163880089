import React from "react";
import Typography from "../../../../Common/Typography";
import styles from "./index.module.css";
import moment from "moment";
import { EmployeeAccuralHolidayAbsenceSummaryProps } from "../../../../../types-definition/StaffHub/employee";
const EmployeeAccuralHolidayAbsenceSummary: React.FC<
  EmployeeAccuralHolidayAbsenceSummaryProps
> = ({
  holidayAccrued,
  holidayUsed,
  holidayCompleted,
  absence,
  holidayMethod,
}) => {
  const currentDate = moment();
  const firstDayOfYear = moment().startOf("year");
  const lastDayOfYear = currentDate.endOf("year");

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography textAlign="left" textWeight="600" textSize="14px">
          Holiday & Absence Summary
        </Typography>
        <Typography
          textAlign="left"
          textWeight="500"
          textSize="14px"
          textColor="#667185"
        >
          {`From ${firstDayOfYear.format("D MMM")} to ${lastDayOfYear.format(
            "D MMM YYYY",
          )}`}
        </Typography>
      </div>

      <div className={styles.body}>
        <div className={styles.flex_container}>
          <div className={styles.flex_item}>
            <Typography
              textAlign="left"
              textWeight="500"
              textSize="14px"
              marginVertical="1px"
            >
              {holidayMethod === "entitlement"
                ? "Entitled Holiday:"
                : "Holiday Accrued:"}
            </Typography>
            <Typography
              textAlign="center"
              textWeight="500"
              textSize="14px"
              textColor="#667185"
              marginVertical="1px"
              marginHorizontal="5px"
            >
              {holidayAccrued}
            </Typography>
          </div>
          <div className={styles.flex_item}>
            <Typography
              textAlign="left"
              textWeight="500"
              textSize="14px"
              marginVertical="1px"
            >
              Holiday Used:
            </Typography>
            <Typography
              textAlign="center"
              textWeight="500"
              textSize="14px"
              textColor="#667185"
              marginVertical="1px"
              marginHorizontal="5px"
            >
              {holidayUsed}
            </Typography>
          </div>
          <div className={styles.flex_item}>
            <Typography
              textAlign="left"
              textWeight="500"
              textSize="14px"
              marginVertical="1px"
            >
              Holiday Completed:
            </Typography>
            <Typography
              textAlign="center"
              textWeight="500"
              textSize="14px"
              textColor="#667185"
              marginVertical="1px"
              marginHorizontal="5px"
            >
              {holidayCompleted}
            </Typography>
          </div>
          <div className={styles.flex_item}>
            <Typography
              textAlign="left"
              textWeight="500"
              textSize="14px"
              marginVertical="1px"
            >
              Absence:
            </Typography>
            <Typography
              textAlign="center"
              textWeight="500"
              textSize="14px"
              textColor="#667185"
              marginVertical="1px"
              marginHorizontal="5px"
            >
              {absence}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeAccuralHolidayAbsenceSummary;
