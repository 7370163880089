import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../Common/PageBackButton";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../utils/localstorage";
import { getRequest } from "../../../../api/request";
import PageLoader from "../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../api/urls";
import { CertificateTypeActivityLog } from "../../../../types-definition/admin";
import DashboardHeader from "../../../Common/DashboardHeader";
import { toastError } from "utils/toast";

const CertificateTypeTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentCertificateType, setCurrentCertificateType] =
    useState<CertificateTypeActivityLog>({
      active: false,
      certificateName: "",
      requirementLevel: "",
    });
  const [previousCertificateType, setPreviousCertificateType] =
    useState<CertificateTypeActivityLog>({
      active: false,
      certificateName: "",
      requirementLevel: "",
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getCertificateData = async () => {
      // get employee data
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data?.newRecord;
        const previousData = response.data?.previousRecord;
        if (response.data.action === "POST") {
          setCurrentCertificateType({
            active: data.certificateType.active,
            certificateName: data?.certificateType.certificateName,
            requirementLevel: data?.certificateType.requirementLevel,
          });
          setPreviousCertificateType({
            active: previousData?.certificateType?.active,
            certificateName: previousData?.certificateType?.certificateName,
            requirementLevel: previousData?.certificateType?.requirementLevel,
          });
        } else {
          setCurrentCertificateType({
            active: previousData?.certificateType.active,
            certificateName: data?.certificateType.certificateName,
            requirementLevel: data?.certificateType.requirementLevel,
          });
          setPreviousCertificateType({
            active: data?.certificateType?.active,
            certificateName: previousData?.certificateType?.certificateName,
            requirementLevel: previousData?.certificateType?.requirementLevel,
          });
        }
      } else {
        toastError(response?.data);
      }

      setPageLoading(false);
    };
    getCertificateData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Certificate Type
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Certificate Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentCertificateType.certificateName ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Requirement Level</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentCertificateType.requirementLevel ===
                    "ifRequiredByService" && "Required By Service"}
                  {currentCertificateType.requirementLevel === "allService" &&
                    "All Services"}
                  {currentCertificateType?.requirementLevel === undefined &&
                    "Required By Service"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Active</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentCertificateType?.active === true && "True"}
                  {currentCertificateType?.active === false && "False"}
                  {currentCertificateType?.active === undefined && "--"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Certificate Type
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Certificate Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousCertificateType.certificateName ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Requirement Level</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousCertificateType.requirementLevel ===
                    "ifRequiredByService" && "Required By Service"}
                  {previousCertificateType.requirementLevel === "allService" &&
                    "All Services"}
                  {previousCertificateType?.requirementLevel === undefined &&
                    "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Active</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousCertificateType?.active === true && "True"}
                  {previousCertificateType?.active === false && "False"}
                  {previousCertificateType?.active === undefined && "--"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateTypeTab;
