import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  showModal: false,
};

export const ShiftNote = createSlice({
  name: "shiftNote",
  initialState,
  reducers: {
    handleModal: (state, action) => {
      state.showModal = action.payload.showModal;
    },
  },
});

export const { handleModal } = ShiftNote.actions;
export const getShowModal = (state: RootState) => state.shiftNote.showModal;
export default ShiftNote.reducer;
