import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import { useAppDispatch } from "../../../../../redux/hooks";
import { handleShowModal } from "../../../../../redux/slice/staff-hub/linkToLocationSlice";
import { LinkToLocationData } from "../../../../../types-definition/StaffHub/employee/linkToLocation";
import { setLocationDetails } from "../../../../../utils/localstorage";

const LinkToLocationTableItem: React.FC<LinkToLocationData> = ({
  locationId,
  location,
  distance,
}) => {
  const dispatch = useAppDispatch();
  // const restrictLocation = async () => {
  //   try {
  //     const response = await patchRequest(
  //       `${PATCH_EMPLOYEE_TO_LOCATION}/${employeeId}/location/${locationId}/restrict`,
  //     );
  //     if (response?.success) {
  //       toastSuccess("Location Restricted Successfully");
  //     } else {
  //       toastError(response?.data);
  //     }
  //   } finally {
  //   }
  // };
  return (
    <>
      <tr className={styles.tr}>
        <td className={`${styles.td} ${styles.td_name}`}>
          <Typography
            textAlign="left"
            marginHorizontal="20px"
            marginVertical="12px"
            textWeight="600"
          >
            {location}
          </Typography>
        </td>
        <td className={styles.td}>
          <Typography
            textAlign="left"
            marginHorizontal="20px"
            marginVertical="12px"
          >
            {parseFloat(distance) < 1 ? "less than 1 mile" : distance}
          </Typography>
        </td>
        <td className={styles.td}>
          <div className={styles.flex_item}>
            <div
              onClick={() => {
                setLocationDetails(
                  JSON.stringify({
                    distance: distance,
                    location: location,
                    locationId: locationId,
                  }),
                );
                dispatch(handleShowModal({ showModal: true }));
              }}
            >
              <Typography
                textWeight="600"
                textColor="#148A9b"
                style={{
                  cursor: "pointer",
                }}
              >
                Link
              </Typography>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default LinkToLocationTableItem;
