import React, { useState } from "react";
import Typography from "components/Common/Typography";
import deleteIcon from "../../../../../assets/svg/error-icon.svg";
import styles from "./index.module.css";
import Button from "components/Form/Button";
import colors from "../../../../../theme/colors";
import { useAppDispatch } from "../../../../../redux/hooks";
import { deleteRequest } from "../../../../../api/request";
import { toastError, toastSuccess } from "../../../../../utils/toast";
import { getManualAdjustmentId } from "utils/localstorage";
import { EMPLOYEE } from "types-definition/constants";
import { togglemanualAdjustmentDeleteModal } from "redux/slice/staff-hub/employeeAccrualSettingSlice";

const DeleteManualAdjustmentModal: React.FC = () => {
  const [requestLoading, setRequestLoading] = useState(false);
  const dispatch = useAppDispatch();
  const id = getManualAdjustmentId();
  const deleteAction = async () => {
    if (id) {
      setRequestLoading(true);
      const response = await deleteRequest(
        `${EMPLOYEE}/manual-adjustment/${id}`,
      );
      if (response?.success) {
        toastSuccess("Adjustment Deleted");
      } else {
        toastError(response?.data);
      }
      setRequestLoading(false);
      dispatch(
        togglemanualAdjustmentDeleteModal({
          showDeleteManualadjustmentModal: false,
        }),
      );
    }
  };
  const cancelAction = () => {
    togglemanualAdjustmentDeleteModal({
      showDeleteManualadjustmentModal: false,
    });
  };
  return (
    <div className={styles.container}>
      <img src={deleteIcon} alt="cancel icon" className={styles.cancelImage} />
      <Typography textWeight="600" textSize="18px" textAlign="left">
        Delete Record
      </Typography>
      <div className={styles.mid_text}>
        <Typography textColor={colors.gray4} textAlign="left">
          Are you sure you want to delete this record?
        </Typography>
        <Typography
          textColor={colors.gray4}
          textAlign="left"
          marginVertical="-8px"
        >
          This action cannot be undone.
        </Typography>
      </div>
      <div className={styles.button_container}>
        <Button
          width="160px"
          text="Cancel"
          isPrimary={false}
          onClick={cancelAction}
          backgroundColor={colors.transparent}
          isPrimaryBorder={true}
        />
        <Button
          width="160px"
          text="Delete"
          backgroundColor={colors.error}
          textColor={colors.white}
          isPrimary={false}
          onClick={deleteAction}
          isLoading={requestLoading}
        />
      </div>
    </div>
  );
};

export default DeleteManualAdjustmentModal;
