import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { ClientCancelledShiftData } from "../../../../types-definition/client";

import colors from "../../../../theme/colors";
import convertTimeTo12hours from "utils/convertTime";

const ClientCancelledShiftTableItem: React.FC<ClientCancelledShiftData> = ({
  id,
  date,
  duration,
  location,
  startTime,
  cancelledBy,
}) => {
  return (
    <tr className={styles.tr}>
      <td className={styles.td_index}></td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {date}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {startTime ? convertTimeTo12hours(startTime) : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {location}
        </Typography>
      </td>

      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {cancelledBy}
        </Typography>
      </td>

      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {duration}
        </Typography>
      </td>
    </tr>
  );
};

export default ClientCancelledShiftTableItem;
