import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
// import { postRequest } from "../../api/request";

// Define a type for the slice stat

// Define the initial state using that type
const initialState = {
  showGlixed: false,
  showRequirePasscode: true,
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setShowGlixed: (state, action: PayloadAction<boolean>) => {
      state.showGlixed = action.payload;
    },
    setShowRequirePasscode: (state, action: PayloadAction<boolean>) => {
      state.showRequirePasscode = action.payload;
    },
  },
});

export const { setShowGlixed, setShowRequirePasscode } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;
export const showGlixed = (state: RootState) => state.auth.showGlixed;
export const showRequirePasscode = (state: RootState) =>
  state.auth.showRequirePasscode;

export default authSlice.reducer;
