import React, { useState } from "react";
import styles from "./index.module.css";
import { AvailablePermissionItemProps } from "../../../../types-definition/admin";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import plusIcon from "../../../../assets/svg/plus-icon.svg";

const AvailablePermissionItem: React.FC<AvailablePermissionItemProps> = ({
  action,
  text,
  id,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={styles.container}>
      <Typography textColor={colors.primary3}>{text}</Typography>
      <div>
        <img
          style={{ filter: isLoading ? "opacity(0.5)" : "opacity(1)" }}
          src={plusIcon}
          alt="plus-icon"
          className={styles.plusIcon}
          onClick={async () => {
            if (isLoading) return null;

            setIsLoading(true);
            await action(id);
            setIsLoading(false);
          }}
        />
      </div>
    </div>
  );
};

export default AvailablePermissionItem;
