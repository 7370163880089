import {
  ACTIVE_BOARD,
  ADD_EMPLOYEE,
  ADMIN,
  AUTO_ROSTER_GROUP,
  BROADCAST_ROSTER,
  CERTIFICATE_EMPLOYEE_DELETE,
  CERTIFICATE_TYPES,
  CLIENT,
  EMPLOYEE_ABSENCE,
  EMPLOYEE_ADJUST_ACCRUAL_BALANCE,
  EMPLOYEE_HOLIDAY,
  EMPLOYEE_HOLIDAY_MULTIPLIER,
  EMPLOYEE_LOCATION_LINKING,
  EMPLOYEE_READ_ONLY,
  EMPLOYEE_UNAVAILABILITY,
  EMPLOYEES,
  EMPLOYEES_ABSENCE_READ,
  GRADES,
  HOLIDAY_MULTIPLIER,
  LOCATION_AUDIT,
  LOCATION_EMAIL_REPORT,
  LOCATION_SERVICES_ADD_UPDATE,
  LOCATION_TIMESHEET,
  LOCATION_UPDATE_AND_ADD,
  LOCATION_VIEW,
  MESSAGE_EMPLOYEES,
  REPORT_INCIDENT_LOCATION,
  ROSTER_READ_ONLY,
  ROSTER_READ_ONLY_GDCPR,
  ROSTER_SCHEDULE_CONFIRM,
  ROSTER_SUMMARY,
  SERVICE_GROUPS,
  SERVICES,
  SETTINGS,
  SHIFT_GENERATION,
} from "types-definition/constants";

export const filterDependentPermission = (
  array: any,
  permissionName: string,
) => {
  let dependentPermistion = [];
  if (permissionName === ACTIVE_BOARD) {
    dependentPermistion = array.filter(
      (item: any) => item.name === CLIENT || item.name === ACTIVE_BOARD,
    );
  } else if (permissionName === EMPLOYEES) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_ABSENCE ||
        item.name === EMPLOYEE_HOLIDAY ||
        item.name === EMPLOYEE_READ_ONLY ||
        item.name === EMPLOYEES_ABSENCE_READ ||
        item.name === ADD_EMPLOYEE ||
        item.name === GRADES ||
        item.name === EMPLOYEES,
    );
  } else if (permissionName === ADD_EMPLOYEE) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_READ_ONLY ||
        item.name === ADD_EMPLOYEE ||
        item.name === GRADES ||
        item.name === EMPLOYEES,
    );
  } else if (permissionName === EMPLOYEE_HOLIDAY_MULTIPLIER) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_READ_ONLY ||
        item.name === EMPLOYEES ||
        item.name === EMPLOYEE_HOLIDAY_MULTIPLIER,
    );
  } else if (permissionName === EMPLOYEE_HOLIDAY) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_ABSENCE ||
        item.name === EMPLOYEE_HOLIDAY ||
        item.name === EMPLOYEES,
    );
  } else if (permissionName === ROSTER_READ_ONLY) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === ACTIVE_BOARD ||
        item.name === ROSTER_SUMMARY ||
        item.name === ROSTER_READ_ONLY ||
        item.name === AUTO_ROSTER_GROUP ||
        item.name === ROSTER_SCHEDULE_CONFIRM ||
        item.name === ROSTER_READ_ONLY_GDCPR ||
        item.name === BROADCAST_ROSTER,
    );
  } else if (permissionName === CERTIFICATE_EMPLOYEE_DELETE) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_READ_ONLY ||
        item.name === EMPLOYEES_ABSENCE_READ ||
        item.name === ADD_EMPLOYEE ||
        item.name === CERTIFICATE_EMPLOYEE_DELETE ||
        item.name === EMPLOYEES ||
        item.name === GRADES ||
        item.name === CERTIFICATE_TYPES,
    );
  } else if (permissionName === EMPLOYEE_LOCATION_LINKING) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_READ_ONLY ||
        item.name === EMPLOYEES ||
        item.name === SERVICES ||
        item.name === EMPLOYEE_LOCATION_LINKING,
    );
  } else if (permissionName === SERVICE_GROUPS) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === SERVICES ||
        item.name === SERVICE_GROUPS ||
        item.name === SETTINGS ||
        item.name === ADMIN,
    );
  } else if (permissionName === EMPLOYEE_UNAVAILABILITY) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_READ_ONLY ||
        item.name === EMPLOYEES ||
        item.name === EMPLOYEE_UNAVAILABILITY,
    );
  } else if (permissionName === EMPLOYEE_ABSENCE) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_READ_ONLY ||
        item.name === EMPLOYEE_HOLIDAY ||
        item.name === EMPLOYEE_ABSENCE ||
        item.name === EMPLOYEES_ABSENCE_READ,
    );
  } else if (permissionName === EMPLOYEE_ABSENCE) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_READ_ONLY ||
        item.name === EMPLOYEE_HOLIDAY ||
        item.name === EMPLOYEE_ABSENCE ||
        item.name === EMPLOYEES_ABSENCE_READ,
    );
  } else if (permissionName === EMPLOYEES_ABSENCE_READ) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_READ_ONLY ||
        item.name === EMPLOYEE_HOLIDAY ||
        item.name === EMPLOYEES_ABSENCE_READ,
    );
  } else if (permissionName === EMPLOYEE_HOLIDAY_MULTIPLIER) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_READ_ONLY ||
        item.name === EMPLOYEE_HOLIDAY ||
        item.name === EMPLOYEES_ABSENCE_READ ||
        item.name === EMPLOYEES,
    );
  } else if (permissionName === EMPLOYEE_READ_ONLY) {
    dependentPermistion = array.filter(
      (item: any) => item.name === EMPLOYEE_READ_ONLY,
    );
  } else if (permissionName === LOCATION_AUDIT) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === LOCATION_AUDIT || item.name === LOCATION_VIEW,
    );
  } else if (permissionName === LOCATION_EMAIL_REPORT) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === LOCATION_AUDIT ||
        item.name === LOCATION_VIEW ||
        item.name === LOCATION_EMAIL_REPORT,
    );
  } else if (permissionName === LOCATION_SERVICES_ADD_UPDATE) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === SERVICES ||
        item.name === CLIENT ||
        item.name === GRADES ||
        item.name === CLIENT ||
        item.name === LOCATION_VIEW ||
        item.name === LOCATION_SERVICES_ADD_UPDATE,
    );
  } else if (permissionName === LOCATION_TIMESHEET) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === LOCATION_VIEW ||
        item.name === ACTIVE_BOARD ||
        item.name === EMPLOYEES ||
        item.name === LOCATION_TIMESHEET,
    );
  } else if (permissionName === LOCATION_UPDATE_AND_ADD) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === LOCATION_VIEW ||
        item.name === LOCATION_UPDATE_AND_ADD ||
        item.name === CLIENT,
    );
  } else if (permissionName === MESSAGE_EMPLOYEES) {
    dependentPermistion = array.filter(
      (item: any) => item.name === EMPLOYEES || item.name === MESSAGE_EMPLOYEES,
    );
  } else if (permissionName === SERVICES) {
    dependentPermistion = array.filter(
      (item: any) => item.name === SERVICES || item.name === GRADES,
    );
  } else if (permissionName === REPORT_INCIDENT_LOCATION) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === LOCATION_VIEW || item.name === REPORT_INCIDENT_LOCATION,
    );
  } else if (permissionName === HOLIDAY_MULTIPLIER) {
    dependentPermistion = array.filter(
      (item: any) => item.name === HOLIDAY_MULTIPLIER || item.name === SETTINGS,
    );
  } else if (permissionName === SETTINGS) {
    dependentPermistion = array.filter(
      (item: any) => item.name === SETTINGS || item.name === ADMIN,
    );
  } else if (permissionName === SHIFT_GENERATION) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === ADMIN ||
        item.name === SHIFT_GENERATION ||
        item.name === SETTINGS,
    );
  } else if (permissionName === EMPLOYEE_ADJUST_ACCRUAL_BALANCE) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === EMPLOYEE_READ_ONLY ||
        item.name === EMPLOYEES ||
        item.name === EMPLOYEE_ADJUST_ACCRUAL_BALANCE,
    );
  } else if (permissionName === LOCATION_TIMESHEET) {
    dependentPermistion = array.filter(
      (item: any) =>
        item.name === CLIENT ||
        item.name === LOCATION_TIMESHEET ||
        item.name === LOCATION_TIMESHEET,
    );
  }
  return dependentPermistion;
};
