import React from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import EmployeeAccrualTableItem from "../AcrrualTableItem";
import { EmployeeAccrualTrackingTable } from "../../../../../types-definition/StaffHub/employee";
const EmployeeAccrualTable: React.FC<EmployeeAccrualTrackingTable> = ({
  data = [],
}) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Date Created
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Accrual Charge
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Reason
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Date Applied
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Application Status
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Expiration Date
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Created By
              </Typography>
            </th>
            <th></th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return <EmployeeAccrualTableItem {...item} key={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeAccrualTable;
