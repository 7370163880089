import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, postRequest, putRequest } from "api/request";
import { CREATE_AREA_URL, GET_AREA_URL } from "api/urls";
import { useNavigate } from "react-router-dom";
import { AreaFormData, AreaRequiredFormData } from "types-definition/client";
import AddAreaForm from "components/Client/Area/AddAreaForm";
import { getArea, getUserTypes } from "utils/localstorage";
import toast from "react-hot-toast";
import validator from "utils/validateFields";
import ScrollToTop from "components/Common/ScrollToTop";
import { toastError, toastSuccess } from "utils/toast";
import PageBackButton from "components/Common/PageBackButton";
import { updateSetupComplete } from "utils/setupWizard";

const AddArea = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<AreaFormData>({
    areaName: "",
    description: "",
    active: true,
  });
  const [requiredFormData, setRequiredFormData] =
    useState<AreaRequiredFormData>({
      areaName: false,
    });
  const [areaId, setAreaId] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);

  const handleSubmitForm = async () => {
    const { status, data } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    setRequestLoading(true);
    try {
      const response = areaId
        ? await putRequest(`${CREATE_AREA_URL}/${areaId}`, formData)
        : await postRequest(CREATE_AREA_URL, formData);
      if (response?.success) {
        toastSuccess("Area Created");
        const setUpComplete = await updateSetupComplete("10", false, "area");

        const userType = getUserTypes();
        if (!setUpComplete && userType !== "Web User") {
          navigate("/setup-wizard");
        } else {
          navigate(`/client/area`);
        }
      } else {
        toastError(response?.data);
      }
    } finally {
      setRequestLoading(false);
    }
  };
  const handleFormCancelation = () => {
    navigate("/client/area");
  };
  useEffect(() => {
    // prefill data if found
    const getEmployee = async () => {
      const data = getArea();
      if (data !== undefined && data !== null) {
        const response = await getRequest(`${GET_AREA_URL}/${data}`);
        if (response?.success) {
          setAreaId(response.data.id);
          setFormData({
            areaName: response.data.areaName,
            description: response.data.description,
            active: response.data.active ?? true,
          });
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      }
    };
    getEmployee();
  }, []);
  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="clients" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton width="90%" paddingTop="20px" marginBottom="0px" />
          <div className={styles.content_container}>
            <Typography textAlign="left" textWeight="600" textSize="24px">
              Add Area
            </Typography>
            <AddAreaForm
              formData={formData}
              setFormData={setFormData}
              requiredFormData={requiredFormData}
              setRequiredFormData={setRequiredFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Cancel"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={true}
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddArea;
