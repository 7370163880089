import React, { useState } from "react";
import Typography from "../../../Common/Typography";
import notificationIcon from "../../../../assets/svg/notificationicon.svg";
import styles from "./index.module.css";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { postRequest } from "api/request";
import { useAppSelector } from "redux/hooks";
import { getCurrentSelectedId } from "redux/slice/admin/adminSlice";

const PermissionInformationModal: React.FC<any> = ({
  dependentPermission,
  selectedPermission,
  closeModal,
}: any) => {
  const id = useAppSelector(getCurrentSelectedId);

  const [requestLoading, setRequestLoading] = useState(false);
  const SubmiteHandler = async () => {
    setRequestLoading(true);
    let response = await postRequest(
      `settings/permission/${selectedPermission}/permission-group/${id}`,
    );
    dependentPermission
      .filter((item: any) => item.id !== selectedPermission)
      .map(async (item: any) => {
        try {
          response = await postRequest(
            `settings/permission/${item.id}/permission-group/${id}`,
          );
          setRequestLoading(true);
          closeModal();
        } catch (error) {
          setRequestLoading(true);
        }
      });
  };
  const cancelAction = () => {
    closeModal();
  };
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <img
          src={notificationIcon}
          alt="cancel icon"
          className={styles.cancelImage}
        />
        <Typography textWeight="600" textSize="18px" textAlign="left">
          Permission Information!
        </Typography>

        <div className={styles.mid_text}>
          <Typography textColor={colors.gray4} textAlign="left">
            This permission depends on the following permissions and will be
            added automatically
          </Typography>
          {dependentPermission.map((item: any, index: number) => {
            if (item) {
              return (
                <div className={styles.list} key={index}>
                  <div className={styles.dot}></div>
                  <Typography textAlign="left" marginVertical="0px" key={index}>
                    {item.name}
                  </Typography>
                </div>
              );
            }
          })}

          <Typography textColor={colors.gray4} textAlign="left">
            Do you want to proceed?
          </Typography>
        </div>
        <div className={styles.button_container}>
          <Button
            width="160px"
            text="Cancel"
            isPrimary={false}
            onClick={cancelAction}
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
          />
          <Button
            width="160px"
            text="Proceed"
            isLoading={requestLoading}
            backgroundColor={colors.primary3}
            textColor={colors.white}
            isPrimary={false}
            onClick={SubmiteHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default PermissionInformationModal;
