import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import circleCancelIcon from "../../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import Button from "../../../../Form/Button";
import {
  getDeleteAvailabilityId,
  getDeleteAvailabilitySegmentId,
  getDeleteAvailabilityType,
  setDeleteAvailabilityType,
  setShowDeleteAvailabilityModal,
  updateEmployeeAvalabilityCounter,
} from "../../../../../redux/slice/staff-hub/employeeSlice";
import RadioInput from "../../../../Form/RadioInput";
import colors from "../../../../../theme/colors";
import { deleteRequest } from "../../../../../api/request";
import { toastError, toastSuccess } from "../../../../../utils/toast";

const EmployeeDeleteAvalabilityTypeModal = () => {
  const dispatch = useAppDispatch();
  const [requestLoading, setrequestLoading] = useState(false);
  const avalabilityType = useAppSelector(getDeleteAvailabilityType);
  const id = useAppSelector(getDeleteAvailabilityId);
  const segmentId = useAppSelector(getDeleteAvailabilitySegmentId);
  const handleDelete = async () => {
    setrequestLoading(true);
    if (avalabilityType === "this-event") {
      const response = await deleteRequest(`/employee/unavailability/${id}`);
      if (response?.success) {
        dispatch(updateEmployeeAvalabilityCounter());
        dispatch(
          setShowDeleteAvailabilityModal({
            show: false,
            id: "",
            segmentId: "",
          }),
        );
        toastSuccess(
          "You have successfully deleted the recurring unavailability event for the selected day.",
        );
      } else {
        toastError(response?.data);
      }
    } else if (avalabilityType === "all-events") {
      const response = await deleteRequest(
        `/employee/unavailability/event/${segmentId}`,
      );
      if (response?.success) {
        dispatch(updateEmployeeAvalabilityCounter());
        dispatch(
          setShowDeleteAvailabilityModal({
            show: false,
            id: "",
            segmentId: "",
          }),
        );
        toastSuccess(
          "You have successfully deleted all recurring unavailability events",
        );
      } else {
        toastError(response?.data);
      }
    } else if (avalabilityType === "this-and-following") {
      const response = await deleteRequest(
        `/employee/unavailability/${id}/event`,
      );
      if (response?.success) {
        // setUnavaialableDates(response.data);
        dispatch(updateEmployeeAvalabilityCounter());
        dispatch(
          setShowDeleteAvailabilityModal({
            show: false,
            id: "",
            segmentId: "",
          }),
        );
        toastSuccess(
          "You have successfully deleted all recurring unavailability events",
        );
      } else {
        toastError(response?.data);
      }
    }
    setrequestLoading(true);
  };

  return (
    <section className={styles.container}>
      <div className={styles.flex_container}>
        <Typography textWeight="500" textSize="20px">
          Delete Recurring Unavailability
        </Typography>
        <img
          src={circleCancelIcon}
          alt="cancel-icon"
          onClick={() =>
            dispatch(
              setShowDeleteAvailabilityModal({
                show: false,
                id: "",
                segmentId: "",
              }),
            )
          }
        />
      </div>
      <div className={styles.form_container}>
        <RadioInput
          label="All Events"
          value={avalabilityType === "all-events" ? true : false}
          setValue={(data) => {
            if (data) {
              dispatch(setDeleteAvailabilityType("all-events"));
            }
          }}
          name="delete-avalability-type"
        />
        <RadioInput
          label="This Event"
          value={avalabilityType === "this-event" ? true : false}
          setValue={(data) => {
            if (data) {
              dispatch(setDeleteAvailabilityType("this-event"));
            }
          }}
          name="delete-avalability-type"
        />
        <RadioInput
          label="This and following"
          value={avalabilityType === "this-and-following" ? true : false}
          setValue={(data) => {
            if (data) {
              dispatch(setDeleteAvailabilityType("this-and-following"));
            }
          }}
          name="delete-avalability-type"
        />
      </div>

      <div className={styles.button_container}>
        <Button
          text="Cancel"
          isPrimary={false}
          isPrimaryBorder
          backgroundColor="transparent"
          onClick={() =>
            dispatch(
              setShowDeleteAvailabilityModal({
                show: false,
                id: "",
                segmentId: "",
              }),
            )
          }
          marginHorizontal="5px"
        />
        <Button
          text="Delete"
          isLoading={requestLoading}
          backgroundColor={colors.error}
          isPrimaryBorder={false}
          marginHorizontal="5px"
          isPrimary={false}
          onClick={handleDelete}
        />
      </div>
    </section>
  );
};

export default EmployeeDeleteAvalabilityTypeModal;
