import ScrollToTop from "components/Common/ScrollToTop";
import SignUpHeader from "components/Common/SignUpHeader";
import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import basicPlanIcon from "assets/svg/basic-plan.svg";
import colors from "theme/colors";
import CustomList from "components/Common/CustomList";
import Button from "components/Form/Button";
import { getRequest, postRequest } from "api/request";
import { toastError } from "utils/toast";
import PageLoader from "components/Common/PageLoader";
import axios from "axios";
import { formatCurrency } from "utils/currencyFormatter";
import { createPaystackId, getCountry } from "utils/localstorage";

const Subscribe = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [priceId, setPriceId] = useState("");
  const [country, setCountry] = useState("");
  const subscribe = async () => {
    setButtonLoading(true);
    const subscriptionResponse = await getRequest("/auth/me");
    const country = getCountry();
    if (country === "Nigeria") {
      const response = await getRequest(
        "/billing/subscription/paystack/initialize",
      );
      if (response?.success) {
        setButtonLoading(false);
        console.log(response?.data);
        window.open(response?.data?.authorization_url, "_self");
      }
      return;
    }
    if (subscriptionResponse?.data?.subscription?.length === 0) {
      const response = await postRequest(
        "/billing/subscription/stripe/checkout",
        { priceId },
      );
      if (response?.success) {
        // console.log(response?.data);
        setButtonLoading(false);

        window.open(response?.data?.url, "_self");
      } else {
        setButtonLoading(false);
        toastError(response?.data);
        // console.log(response?.data);
      }
    } else {

      const response = await postRequest("/billing/stripe/portal", {
        customerId: subscriptionResponse?.data?.user?.paymentProviderId,
      });
      if (response?.success) {
        setButtonLoading(false);
        console.log(response?.data);
        window.open(response?.data?.url, "_self");
      } else {
        setButtonLoading(false);
        toastError(response?.data);
        // console.log(response?.data);
      }
    }
  };
  const [price, setPrice] = useState({
    gbp: 0,
    usd: 0,
    ngn: 0,
  });
  const [priceAdditional, setPriceAdditional] = useState({
    gbp: 0,
    usd: 0,
    ngn: 0,
  });

  useEffect(() => {
    const getProducts = async () => {
      try {
        const countryResponse = await axios.get("https://ipapi.co/json/");
        setCountry(countryResponse?.data?.country_name);
        const response = await getRequest("/billing/product");
        if (response?.success) {
          console.log(response?.data);
          const data = response?.data?.filter(
            (item: any) => !item.baseProductId && item.active,
          );
          console.log(data);
          const additionalData = response?.data?.filter(
            (item: any) => item.baseProductId && item.active,
          );
          createPaystackId(data[0]?.paystackBaseProductId);
          setPriceId(data[0]?.price?.id);
          // console.log(data[0]?.price?.currency_options);
          setPrice({
            gbp: data[0]?.price?.currency_options?.gbp.unit_amount / 100,
            usd: data[0]?.price?.currency_options?.usd.unit_amount / 100,
            ngn: data[0]?.price?.currency_options?.ngn.unit_amount / 100,
          });
          setPriceAdditional({
            gbp:
              additionalData[0]?.price?.currency_options?.gbp.unit_amount / 100,
            usd:
              additionalData[0]?.price?.currency_options?.usd.unit_amount / 100,
            ngn:
              additionalData[0]?.price?.currency_options?.ngn.unit_amount / 100,
          });
          console.log({
            gbp: data[0]?.price?.currency_options?.gbp.unit_amount / 100,
            usd: data[0]?.price?.currency_options?.usd.unit_amount / 100,
            ngn: data[0]?.price?.currency_options?.ngn.unit_amount / 100,
            gbpA:
              additionalData[0]?.price?.currency_options?.gbp.unit_amount / 100,
            usdA:
              additionalData[0]?.price?.currency_options?.usd.unit_amount / 100,
            ngnA:
              additionalData[0]?.price?.currency_options?.ngn.unit_amount / 100,
          });
        } else {
          toastError("Error in Fetching Pricing");
        }
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        toastError(error?.toString());
      }
    };
    getProducts();
  }, []);

  return (
    <>
      {isLoading && <PageLoader />}
      <ScrollToTop />
      <SignUpHeader showLogin={false} />
      <div className={styles.container}>
        <Typography textSize="24px" textWeight="500">
          Subscribe to Glix in 3 easy steps
        </Typography>
        <div className={styles.subscription_container}>
          <div className={styles.subscription_text_container}>
            {/* <div className={styles.flex_1}> */}
            <Typography textSize="16px">Plan</Typography>
            {/* </div> */}
            {/* <div className={styles.flex_1}> */}
            <Typography textSize="16px" style={{ marginLeft: "40px" }}>
              Pay
            </Typography>
            {/* </div> */}
            {/* <div className={styles.flex_1}> */}
            <Typography textSize="16px">Confirmation</Typography>
            {/* </div> */}
          </div>
          <div className={styles.subscription_bar_container}>
            <div className={styles.subscription_bar}></div>
            <div className={styles.subscription_item_container}>
              <div className={styles.subscription_rounded_item_active}></div>
              <div className={styles.subscription_rounded_item}></div>
              <div className={styles.subscription_rounded_item}></div>
            </div>
            {/* <div className={styles.subscription_bar_active_1}></div> */}
          </div>
        </div>
        <div className={styles.main_container}>
          <div className={styles.main_container_item_1}>
            <img src={basicPlanIcon} alt="plan icon" />
            <Typography textWeight="600" textAlign="left" textSize="18px">
              Package
            </Typography>
            <div className={styles.price}>
              <Typography
                textColor={colors.primary3}
                textWeight="700"
                textSize="36px"
                textAlign="left"
              >
                {country === "United Kingdom"
                  ? `£${formatCurrency(price.gbp)}`
                  : country === "Nigeria"
                    ? `₦${formatCurrency(price.ngn)}`
                    : `$${price.usd}`}
              </Typography>
            </div>
            <div>
              <Typography textColor="#21212199" textAlign="left">
                Billed Monthly
              </Typography>
            </div>
            <div className={styles.middle_price}>
              <CustomList width="100px" text="All Employees" />
              <CustomList
                width="330px"
                text={
                  country === "United Kingdom"
                    ? `Monthly Price: £${formatCurrency(
                        price.gbp,
                      )} + £${formatCurrency(
                        priceAdditional.gbp,
                      )} additional user`
                    : country === "Nigeria"
                      ? `Monthly Price: ₦${formatCurrency(
                          price.ngn,
                        )} + ₦${formatCurrency(
                          priceAdditional.ngn,
                        )} additional user`
                      : `Monthly Price: $${formatCurrency(
                          price.usd,
                        )} + $${formatCurrency(
                          priceAdditional.usd,
                        )} additional user`
                }
              />
            </div>
            <div>
              <Typography textWeight="600" textAlign="left" textSize="18px">
                Features:
              </Typography>
              <CustomList width="150px" text="All Essential Features" />
            </div>
            <div>
              <Button
                paddingVertical="5px"
                text="Subscribe"
                isPrimary
                onClick={subscribe}
                borderRadius="30px"
                width="200px"
                marginTop="20px"
                isLoading={buttonLoading}
              />
            </div>
          </div>
          <div className={styles.main_container_item_2}>
            <Typography textWeight="600" textAlign="left" textSize="18px">
              Benefits:
            </Typography>
            <CustomList
              width="220px"
              text="Efficiently manage a growing
                team, optimize resource
                allocation based on client site
                activities, and ensure 
                employee well-being with 
                check calls"
            />
            <CustomList
              width="220px"
              text="Simplify onboarding, manage 
                    Schedules, track attendance,
                    and enhance communication
                    With an intuitive platform."
            />
          </div>
          <div className={styles.main_container_item_3}>
            <CustomList
              width="220px"
              text="Gain deeper insights with 
                    automated reports 
                    generated from location 
                    data, optimize scheduling 
                    with predefined rules, and 
                    enhance geolocation
                    tracking"
            />
            <CustomList
              width="220px"
              text="Simplify onboarding, manage 
                    Schedules, track attendance,
                    and enhance communication
                    With an intuitive platform."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
