import DashboardHeader from "components/Common/DashboardHeader";
import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardLongItem from "components/Common/DashboardLongItem";
import { showGlixed } from "../../redux/slice/auth/authSlice";
import { useAppSelector } from "../../redux/hooks";
import ScrollToTop from "components/Common/ScrollToTop";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../api/request";
import {
  GET_USER_DETAILS_URL,
  GET_ALL_EMPLOYEE_URL,
  GET_ALL_CLIENTS_URL,
  GET_LOCATION_URL,
  ACTIVE_BOARD,
  GET_EMPLOYEE_COUNT,
  GET_CLIENT_COUNT,
  GET_LOCATION_COUNT_URL,
} from "api/urls";
import GetStartedModal from "components/Auth/GetStartedModal";
import { getData, setCompanyName } from "utils/localstorage";
import emplpoyeeIcon from "../../assets/svg/employeeManagementIcon.svg";
import clientIcon from "../../assets/svg/customerManagement.svg";
import locationtIcon from "../../assets/svg/locationManagementIcon.svg";
import serviceIcon from "../../assets/svg/serviceManagementIcon.svg";
import rosterIcon from "../../assets/svg/schedule.svg";
import checpointIcon from "../../assets/svg/checkpointIcon.svg";
import { DashboardItemProps } from "types-definition/common";
import DashboardCard from "components/Common/DashboardCard";
import DashboardTopCard from "components/Common/DashboardTopCard";
import toast from "react-hot-toast";
import PageLoader from "components/Common/PageLoader";
import filterKeysFromArray from "utils/filterKeyFromArray";
import { ENDED, STARTED } from "types-definition/constants";
import moment from "moment";
// import stringToDate from "utils/formatDate";

const Dashboard: React.FC = () => {
  const [requestLoading, setRequestLoading] = useState<boolean>(true);
  const [employeeCount, setEmployeeCount] = useState<number>(0);
  const [clientCount, setClientCount] = useState<number>(0);
  const [locationCount, setLocationCount] = useState<number>(0);
  const [liveLocationData, setLiveLocationData] = useState<Array<any>>([]);
  const data: Array<DashboardItemProps> = [
    { label: "Employee Management", icon: emplpoyeeIcon, link: "/staff-hub" },
    { label: "Client Management", icon: clientIcon, link: "/client/dashboard" },
    { label: "Location Management", icon: locationtIcon, link: "/client/site" },
    { label: "Service Management", icon: serviceIcon, link: "/client/service" },
    { label: "Roster Management", icon: rosterIcon, link: "/roster" },
    {
      label: "Checkpoint Management",
      icon: checpointIcon,
      link: "/client/checkpoint",
    },
  ];
  const showGlixedModal = useAppSelector(showGlixed);
  const navigate = useNavigate();
  useEffect(() => {
    const verifyInfoCompletion = async () => {
      const response = await getRequest(GET_USER_DETAILS_URL);
      if (response?.success) {
        if (response.data?.user?.country === null) {
          navigate("/update-account");
        }
        if (
          response.data?.user?.company?.companyName === null ||
          response.data?.user?.company?.companyName === undefined
        ) {
          navigate("/create-company");
        }
        setCompanyName(response?.data?.company?.companyName);
      }
    };
    verifyInfoCompletion();
  }, []);
  useEffect(() => {
    const getCount = async () => {
      const employerCountResponse = await getRequest(GET_EMPLOYEE_COUNT);
      const locationCountResponse = await getRequest(GET_LOCATION_COUNT_URL);
      const clientCountResponse = await getRequest(GET_CLIENT_COUNT);
      if (
        employerCountResponse?.success &&
        locationCountResponse?.success &&
        clientCountResponse?.success
      ) {
        setRequestLoading(false);
        setClientCount(clientCountResponse.data);
        setEmployeeCount(employerCountResponse.data);
        setLocationCount(locationCountResponse.data);
      } else {
        toast.error("An Error Occured");
        setRequestLoading(false);
      }
    };
    getCount();
  }, []);

  useEffect(() => {
    const date = moment().toISOString();
    const getLiveMonitoringInformation = async () => {
      const userData = getData();
      if (userData) {
        const response = await getRequest(`${ACTIVE_BOARD}?date=${date}`);
        if (response?.success) {
          const locationData: Array<any> = [];
          const locationIds = filterKeysFromArray(response?.data, "locationId");
          for (const locationId of locationIds) {
            const location = response?.data.filter(
              (item: any) => item.locationId === locationId,
            );

            const totalEmployeeData = location.filter((item: any) => {
              return (
                moment
                  .utc(moment.utc(item.date).format("DD/MM/YYYY"), "DD/MM/YYYY")
                  .valueOf() ===
                moment
                  .utc(
                    moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
                      "DD/MM/YYYY",
                    ),
                    "DD/MM/YYYY",
                  )
                  .valueOf()
              );
            });
            const employeeClockedIn = totalEmployeeData.filter((item: any) => {
              return item.status === STARTED || item.status === ENDED;
            });

            locationData.push({
              id: locationId,
              siteName: location[0]?.location?.name,
              AssignedEmployee: totalEmployeeData.length,
              active: employeeClockedIn.length,
              inActive: totalEmployeeData.length - employeeClockedIn.length,
              location: location,
            });
          }
          setLiveLocationData(locationData);
        }
      }
    };
    getLiveMonitoringInformation();
    const interval = setInterval(() => {
      getLiveMonitoringInformation();
    }, 20000);
    return () => clearInterval(interval);
  }, []);
  return (
    <main className={styles.container}>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="dashboard" />
      <section className={styles.item_container}>
        <div>
          <div className={styles.item_1}>
            <DashboardTopCard
              label="Total Employees"
              total={employeeCount}
              name={employeeCount > 1 ? "Employees" : "Employee"}
              link="/staff-hub"
            />
            <DashboardTopCard
              label="Total Clients"
              total={clientCount}
              name={clientCount > 1 ? "Clients" : "Client"}
              link="/client/dashboard"
            />
            <DashboardTopCard
              label="Total Locations"
              total={locationCount}
              name={locationCount > 1 ? "Locations" : "Location"}
              link="/client/site"
            />
          </div>

          <div className={styles.item_2}>
            {data.map((item, index) => (
              <DashboardCard {...item} key={index} />
            ))}
          </div>
        </div>
        <div className={styles.item2}>
          <DashboardLongItem data={liveLocationData} />
        </div>
      </section>
      {showGlixedModal && <GetStartedModal />}
    </main>
  );
};

export default Dashboard;
