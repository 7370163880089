import ActivityWebUserTab from "components/Admin/Activity Logs/AdminActivityLogs/WebUser";
import ActivityAreaTab from "components/Admin/Activity Logs/AreaActivityLogs";
import CertificateGroupTab from "components/Admin/Activity Logs/CertificateGroup";
import CertificateTypeTab from "components/Admin/Activity Logs/CertificateType";
import ActivityClient from "components/Admin/Activity Logs/Client";
import ActivityHolidayRate from "components/Admin/Activity Logs/HolidayRate";
import ActivityLocationAddress from "components/Admin/Activity Logs/LocationAddress";
import ActivityLocationOperation from "components/Admin/Activity Logs/LocationOperation";
import ActivityLocationRequiredCertificate from "components/Admin/Activity Logs/LocationRequiredCertificate";
import ActivityGeneralFinancialTab from "components/Admin/Activity Logs/ServiceActivityLogs/Financial";
import ActivityGeneralServiceTab from "components/Admin/Activity Logs/ServiceActivityLogs/General";
import ActivityTimeShift from "components/Admin/Activity Logs/TimeShiftGeneration";
import { createBrowserRouter } from "react-router-dom";
import LinkEmployee from "screens/Client/Location/site-location-link-employee";
import LinkedEmployee from "screens/Client/Location/site-location-linked-employee";
import CheckpointAction from "screens/Client/checkpoint/add-checkpoint-action";
import CheckpointGeneral from "screens/Client/checkpoint/add-checkpoint-general";
import ClientCheckPoint from "screens/Client/checkpoint/checkpoint-dashboard";
import CheckcallLogInfo from "screens/Report/CheckcallReportLog/checkcall-log-details";
import CheckcallReportDashboard from "screens/Report/CheckcallReportLog/checkcall-report-dashboard";
import PayrollReport from "screens/Report/CheckcallReportLog/payroll-report";
import CheckpointsByService from "screens/Report/CheckpointReport/checkpoints-by-service";
import CheckpointsCheckIns from "screens/Report/CheckpointReport/checkpoints-checkins";
import SetUpWizard from "screens/SetupWizard";
import SetAccrualManualAdjustment from "screens/StaffHub/Employee/employee-acrrual-manual-adjustment";
import AdminGrade from "screens/StaffHub/grade";
import AdminGradeForm from "screens/StaffHub/grade-form";
import StaffHubDashbaord from "screens/StaffHub/staff-hub-dashboard";
import {
  ACTIVE_BOARD,
  ACTIVITY_LOG,
  // ACTIVE_BOARD_CUSTOM_TIMES,
  // ACTIVE_BOARD_OVERRIDE,
  ADD_EMPLOYEE,
  ADMIN_WEBUSER,
  AREA,
  ASSIGN_PERMISSION_GROUP,
  AUTO_ROSTER_GROUP,
  // AUTO_ROSTER_GROUP,
  BROADCAST_ROSTER,
  CERTIFICATE_GROUPS,
  CERTIFICATE_TYPES,
  CHECK_CALL_REPORT,
  CLIENT,
  EMPLOYEES,
  EMPLOYEES_ABSENCE_READ,
  EMPLOYEE_ABSENCE,
  EMPLOYEE_ADJUST_ACCRUAL_BALANCE,
  EMPLOYEE_HOLIDAY,
  EMPLOYEE_HOLIDAY_MULTIPLIER,
  // EMPLOYEE_HR_DEFAULT,
  // EMPLOYEE_HR_TAB,
  EMPLOYEE_LOCATION_LINKING,
  EMPLOYEE_READ_ONLY,
  EMPLOYEE_UNAVAILABILITY,
  GRADES,
  HOLIDAY_MULTIPLIER,
  LOCATION_AUDIT,
  LOCATION_CHECKPOINT,
  LOCATION_EMAIL_REPORT,
  LOCATION_SERVICES_ADD_UPDATE,
  LOCATION_SERVICES_VIEW,
  LOCATION_UPDATE_AND_ADD,
  LOCATION_VIEW,
  MESSAGE_EMPLOYEES,
  PERMISSION_GROUP,
  REPORT_INCIDENT_LOCATION,
  ROSTER_READ_ONLY,
  ROSTER_SCHEDULE_CONFIRM,
  // ROSTER_READ_ONLY,
  // ROSTER_READ_ONLY_GDCPR,
  // ROSTER_SCHEDULE_CONFIRM,
  ROSTER_SUMMARY,
  SERVICES,
  SERVICE_GROUPS,
  SETTINGS,
  SHIFT_GENERATION,
  SHOW_EMPLOYEE_PASSWORD,
  TIME_SHEET
} from "types-definition/constants";
import "./App.css";
import ActivityGeneralSettingsTab from "./components/Admin/Activity Logs/AdminActivityLogs/GeneralTab";
import ActivityHolidaySettingsTab from "./components/Admin/Activity Logs/AdminActivityLogs/HolidaySettingActivity";
import ActivityManualCheckpointSettingsTab from "./components/Admin/Activity Logs/AdminActivityLogs/ManualCheckpoint";
import ActivityShiftGenerationSettingsTab from "./components/Admin/Activity Logs/AdminActivityLogs/ShiftGenerationActivityLog";
import ActivityTimeCheckSettingsTab from "./components/Admin/Activity Logs/AdminActivityLogs/TimeCheckActivityLog";
import ActivityGrade from "./components/Admin/Activity Logs/Grade";
import ActivityPermissionTab from "./components/Admin/Activity Logs/PermissionGroup";
import ActivityServiceGroupTab from "./components/Admin/Activity Logs/ServiceGroup";
import ActivityEmployeeBankDetails from "./screens/Admin/Activity/activity-employee-bank-details";
import ActivityEmployeeContactInformation from "./screens/Admin/Activity/activity-employee-contact-info";
import ActivityEmployeeEmergencyInformation from "./screens/Admin/Activity/activity-employee-emergency-info";
import ActivityEmployeeHr from "./screens/Admin/Activity/activity-employee-hr";
import ActivityEmployeeMedicalDetailsPage from "./screens/Admin/Activity/activity-employee-medical-details";
import ActivityEmployeeOperationsPage from "./screens/Admin/Activity/activity-employee-operations";
import ActivityViewProfile from "./screens/Admin/Activity/activity-employee-view-profile";
import ActivityLocationAuditGeneral from "./screens/Admin/Activity/activity-location-audit-general";
import ActivityLocationAuditLinkLocation from "./screens/Admin/Activity/activity-location-audit-link-location";
import ActivityLogs from "./screens/Admin/Activity/activity-logs";
import LiveMonitoringDasboard from "./screens/Admin/LiveMonitoringDashboard";
import StatisticalSiteLocation from "./screens/Admin/StatisticalSiteLocation";
import AdminGeneralSettings from "./screens/Admin/general-settings";
import HolidayMultiplier from "./screens/Admin/holiday-rate";
import HolidaySettings from "./screens/Admin/holiday-settings-form";
import ManualCheckpointReason from "./screens/Admin/manual-checkpoint-reason";
import AdminPermissionGroup from "./screens/Admin/permission-groups";
import AdminServiceGroup from "./screens/Admin/service-group";
import ShiftGeneration from "./screens/Admin/shift-generation";
import AdminTimeCheckSettings from "./screens/Admin/time-check-settings";
import TimeShiftGeneration from "./screens/Admin/time-shift-generation";
import WebUserProfile from "./screens/Admin/web-user-profile";
import WebUserProfileForm from "./screens/Admin/web-user-profile-form";
import AuthFailed from "./screens/Authenticaion/auth-failed";
import CreateAccount from "./screens/Authenticaion/create-account";
import CreateCompany from "./screens/Authenticaion/create-company";
import CreateSocialAccount from "./screens/Authenticaion/create-social-account";
import ForgotPassword from "./screens/Authenticaion/forgot-password";
import GoogleSuccess from "./screens/Authenticaion/google-success";
import Login from "./screens/Authenticaion/login";
import MicrosoftSuccess from "./screens/Authenticaion/microsft-success";
import NoPermissionPage from "./screens/Authenticaion/no-permission";
import PrivacyPolicy from "./screens/Authenticaion/privacy-policy";
import RenderPage from "./screens/Authenticaion/render-page";
import ResetPassword from "./screens/Authenticaion/reset-password";
import TermsAndCondition from "./screens/Authenticaion/terms-condition";
import UpdateSocialAccount from "./screens/Authenticaion/update-account";
import VerifyMail from "./screens/Authenticaion/verify-email";
import AddArea from "./screens/Client/Area/add-area";
import AreaDashbaord from "./screens/Client/Area/area-dashboard";
import AddClient from "./screens/Client/Client/add-client";
import ClientActiveBoard from "./screens/Client/Client/client-active-baord";
import ClientDashbaord from "./screens/Client/Client/client-dashboard";
import ClientRoster from "./screens/Client/Client/client-roster";
import ClientTimesheetReport from "./screens/Client/Client/client-timesheet-report";
import LocationTimeshift from "./screens/Client/Location/location-timeshift";
import SiteLocationAddress from "./screens/Client/Location/site-location-address";
import SiteLocationAudit from "./screens/Client/Location/site-location-audit";
import SiteLocationAuditAddress from "./screens/Client/Location/site-location-audit-address";
import SiteLocationAuditCertificates from "./screens/Client/Location/site-location-audit-certificates";
import SiteLocationAuditGeneral from "./screens/Client/Location/site-location-audit-general";
import SiteLocationAuditLinkLocation from "./screens/Client/Location/site-location-audit-link-location";
import SiteLocationAuditOperations from "./screens/Client/Location/site-location-audit-operations";
import SiteLocationCertificate from "./screens/Client/Location/site-location-certificates";
import SiteLocationDashbaord from "./screens/Client/Location/site-location-dashboard";
import SiteLocationGeneral from "./screens/Client/Location/site-location-general";
import SiteLocationOperation from "./screens/Client/Location/site-location-operation";
import SiteLocationIncidentReport from "./screens/Client/Location/site-locaton-incident-report";
import AddService from "./screens/Client/Service/add-service";
import ServiceDashbaord from "./screens/Client/Service/service-dashboard";
import ServiceFinacial from "./screens/Client/Service/service-finacial";
import ClientEmailReport from "./screens/Client/client-email-report";
import Roster from "./screens/Operations/roster";
import AddCertificateGroup from "./screens/StaffHub/CertificateGroup/add-certificate-group";
import CertificateGroupDashboard from "./screens/StaffHub/CertificateGroup/certificate-group-dashboard";
import AddCertificateType from "./screens/StaffHub/CertificateType/add-certificate-type-form";
import CertificateType from "./screens/StaffHub/CertificateType/certificate-type-dashboard";
import AccrualTracking from "./screens/StaffHub/Employee/employee-accrual-tracking";
import EmployeeAudit from "./screens/StaffHub/Employee/employee-audit";
import EmployeeAvalability from "./screens/StaffHub/Employee/employee-avalability";
import EmployeeBasicInfo from "./screens/StaffHub/Employee/employee-basic-info";
import EmployeeContactInfo from "./screens/StaffHub/Employee/employee-contact-info";
import EmployeeEmergencyContact from "./screens/StaffHub/Employee/employee-emergency";
import EmployeeHolidayAbsence from "./screens/StaffHub/Employee/employee-holiday-absence";
import EmployeeHolidayMultiplier from "./screens/StaffHub/Employee/employee-holiday-multiplier";
import LinkToLocation from "./screens/StaffHub/Employee/employee-link-to-location";
import LinkedDashboard from "./screens/StaffHub/Employee/employee-location-linked-dashboard";
import EmployeeMedicalDetails from "./screens/StaffHub/Employee/employee-medical-details";
import EmployeeMessage from "./screens/StaffHub/Employee/employee-message";
import EmployeeOperations from "./screens/StaffHub/Employee/employee-operations";
import EmployeeTimeSheet from "./screens/StaffHub/Employee/employee-timesheet";
import EmployeeViewProfile from "./screens/StaffHub/Employee/employee-view-profile";
import EmployeeHr from "./screens/StaffHub/Employee/empoyee-hr";
import HolidayAbsenceSummary from "./screens/StaffHub/holiday-absence-summary";
import Dashboard from "./screens/dashboard";
import Subscribe from "screens/Subscription/subscribe";
import SubscribeSuccessful from "screens/Subscription/success";
// Note that the handle Timer is in the Dashboard Header Component

const router = createBrowserRouter([
  {
    path: "/",
    element: <CreateAccount />,
  },
  {
    path: "/create-account",
    element: <CreateAccount />,
  },
  {
    path: "/create-company",
    element: <CreateCompany />,
  },

  {
    path: "/dashboard",
    element: (
      <RenderPage
        active="dashboard"
        page={<Dashboard />}
        // permissions={[ADMIN]}
      />
    ),
  },
  {
    path: "/setup-wizard",
    element: <RenderPage active="dashboard" page={<SetUpWizard />} />,
  },
  {
    path: "/create-social-account",
    element: <CreateSocialAccount />,
  },
  {
    path: "/verify/:id/:token",
    element: <VerifyMail />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/google-success",
    element: <GoogleSuccess />,
  },
  {
    path: "/google-failed",
    element: <AuthFailed />,
  },
  {
    path: "/microsoft-success",
    element: <MicrosoftSuccess />,
  },
  {
    path: "/microsoft-failed",
    element: <AuthFailed />,
  },
  {
    path: "/update-account",
    element: <UpdateSocialAccount />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:token",
    element: <ResetPassword />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-condition",
    element: <TermsAndCondition />,
  },
  {
    path: "/staff-hub",
    element: (
      <RenderPage
        active="staff/hub"
        page={<StaffHubDashbaord />}
        permissions={[
          // ADD_EMPLOYEE,
          EMPLOYEE_READ_ONLY,
          EMPLOYEES,
          // CERTIFICATE_EMPLOYEE_DELETE,
          // EMPLOYEE_LOCATION_LINKING,
          // EMPLOYEE_HOLIDAY_MULTIPLIER,
          // TIME_SHEET,
        ]}
      />
    ),
  },
  {
    path: "/staff-hub/certificate-group",
    element: (
      <RenderPage
        active="staff/hub"
        page={<CertificateGroupDashboard />}
        permissions={[CERTIFICATE_GROUPS]}
      />
    ),
  },
  {
    path: "/staff-hub/certificate-group/form",
    element: (
      <RenderPage
        active="staff/hub"
        page={<AddCertificateGroup />}
        permissions={[CERTIFICATE_GROUPS]}
      />
    ),
  },
  {
    path: "/staff-hub/certificate-type",
    element: (
      <RenderPage
        active="staff/hub"
        page={<CertificateType />}
        permissions={[CERTIFICATE_TYPES]}
      />
    ),
  },
  {
    path: "/staff-hub/add-certificate-type",
    element: (
      <RenderPage
        active="staff/hub"
        page={<AddCertificateType />}
        permissions={[CERTIFICATE_TYPES]}
      />
    ),
  },
  {
    path: "/employee/basic-info",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeBasicInfo />}
        permissions={[ADD_EMPLOYEE, EMPLOYEES]}
      />
    ),
  },
  {
    path: "/employee/contact-info",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeContactInfo />}
        permissions={[ADD_EMPLOYEE, EMPLOYEES]}
      />
    ),
  },
  {
    path: "/employee/operation",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeOperations />}
        permissions={[ADD_EMPLOYEE, EMPLOYEES]}
      />
    ),
  },
  {
    path: "/employee/hr",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeHr />}
        permissions={[
          ADD_EMPLOYEE,
          EMPLOYEES,
          // EMPLOYEE_HR_TAB
        ]}
      />
    ),
  },
  {
    path: "/employee/emergency-contact",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeEmergencyContact />}
        permissions={[ADD_EMPLOYEE, EMPLOYEES]}
      />
    ),
  },
  {
    path: "/employee/medical-details",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeMedicalDetails />}
        permissions={[ADD_EMPLOYEE, EMPLOYEES]}
      />
    ),
  },
  {
    path: "/employee/avalability",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeAvalability />}
        permissions={[EMPLOYEE_UNAVAILABILITY, EMPLOYEES]}
      />
    ),
  },
  {
    path: "/employee/holiday-absence",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeHolidayAbsence />}
        permissions={[EMPLOYEES, EMPLOYEE_HOLIDAY]}
      />
    ),
  },
  {
    path: "/employee/time-sheet",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeTimeSheet />}
        permissions={[EMPLOYEES, TIME_SHEET]}
      />
    ),
  },
  {
    path: "/employee/view-profile",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeViewProfile />}
        permissions={[EMPLOYEES, EMPLOYEE_READ_ONLY, SHOW_EMPLOYEE_PASSWORD]}
      />
    ),
  },
  {
    path: "/employee/link-to-location",
    element: (
      <RenderPage
        active="staff/hub"
        page={<LinkToLocation />}
        permissions={[EMPLOYEE_LOCATION_LINKING, EMPLOYEES]}
      />
    ),
  },
  {
    path: "/employee/linked-to-location",
    element: (
      <RenderPage
        active="staff/hub"
        page={<LinkedDashboard />}
        permissions={[EMPLOYEES]}
      />
    ),
  },
  {
    path: "/employee/holiday-multiplier",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeHolidayMultiplier />}
        permissions={[EMPLOYEE_HOLIDAY_MULTIPLIER, EMPLOYEES]}
      />
    ),
  },
  {
    path: "/employee/audit",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeAudit />}
        permissions={[EMPLOYEES]}
      />
    ),
  },
  {
    path: "/employee/accrual-tracking",
    element: (
      <RenderPage
        active="staff/hub"
        page={<AccrualTracking />}
        permissions={[EMPLOYEE_ADJUST_ACCRUAL_BALANCE]}
      />
    ),
  },
  {
    path: "/employee/accrual-manual-adjustment",
    element: (
      <RenderPage
        active="staff/hub"
        page={<SetAccrualManualAdjustment />}
        permissions={[EMPLOYEE_ADJUST_ACCRUAL_BALANCE]}
      />
    ),
  },
  {
    path: "/employee/message",
    element: (
      <RenderPage
        active="staff/hub"
        page={<EmployeeMessage />}
        permissions={[EMPLOYEES, MESSAGE_EMPLOYEES]}
      />
    ),
  },
  {
    path: "/client/dashboard",
    element: (
      <RenderPage
        active="clients"
        page={<ClientDashbaord />}
        permissions={[
          CLIENT,
          ACTIVE_BOARD,
          // ACTIVE_BOARD_CUSTOM_TIMES,
          // ACTIVE_BOARD_OVERRIDE,
        ]}
      />
    ),
  },
  {
    path: "/client/add",
    element: (
      <RenderPage
        active="clients"
        page={<AddClient />}
        permissions={[CLIENT]}
      />
    ),
  },
  {
    path: "/client/site",
    element: (
      <RenderPage
        active="clients"
        page={<SiteLocationDashbaord />}
        permissions={[LOCATION_VIEW]}
      />
    ),
  },
  {
    path: "/client/general",
    element: (
      <RenderPage
        active="clients"
        page={<SiteLocationGeneral />}
        permissions={[LOCATION_UPDATE_AND_ADD]}
      />
    ),
  },
  {
    path: "/client/address",
    element: (
      <RenderPage
        active="clients"
        page={<SiteLocationAddress />}
        permissions={[LOCATION_UPDATE_AND_ADD]}
      />
    ),
  },
  {
    path: "/client/operation",
    element: (
      <RenderPage
        active="clients"
        page={<SiteLocationOperation />}
        permissions={[LOCATION_UPDATE_AND_ADD]}
      />
    ),
  },
  {
    path: "/client/certificates",
    element: (
      <RenderPage
        active="clients"
        page={<SiteLocationCertificate />}
        permissions={[LOCATION_UPDATE_AND_ADD]}
      />
    ),
  },
  {
    path: "/client/area",
    element: (
      <RenderPage
        active="clients"
        page={<AreaDashbaord />}
        permissions={[AREA]}
      />
    ),
  },
  {
    path: "/client/area/add",
    element: (
      <RenderPage active="clients" page={<AddArea />} permissions={[AREA]} />
    ),
  },
  {
    path: "/client/service",
    element: (
      <RenderPage
        active="clients"
        page={<ServiceDashbaord />}
        permissions={[
          LOCATION_SERVICES_VIEW,
          LOCATION_SERVICES_ADD_UPDATE,
          SERVICES,
        ]}
      />
    ),
  },
  {
    path: "/client/service/add",
    element: (
      <RenderPage
        active="clients"
        page={<AddService />}
        permissions={[LOCATION_SERVICES_ADD_UPDATE, SERVICES]}
      />
    ),
  },
  {
    path: "/client/service/financial",
    element: (
      <RenderPage
        active="clients"
        page={<ServiceFinacial />}
        permissions={[LOCATION_SERVICES_ADD_UPDATE, SERVICES]}
      />
    ),
  },
  {
    path: "/client/mail-report",
    element: (
      <RenderPage
        active="clients"
        page={<ClientEmailReport />}
        permissions={[LOCATION_EMAIL_REPORT]}
      />
    ),
  },
  {
    path: "/client/location/incident-report",
    element: (
      <RenderPage
        active="clients"
        page={<SiteLocationIncidentReport />}
        permissions={[REPORT_INCIDENT_LOCATION]}
      />
    ),
  },
  {
    path: "/client/location/audit",
    element: (
      <RenderPage
        active="clients"
        page={<SiteLocationAudit />}
        permissions={[LOCATION_AUDIT]}
      />
    ),
  },
  {
    path: "/client/location/audit/general",
    element: (
      <RenderPage active="clients" page={<SiteLocationAuditGeneral />} />
    ),
  },
  {
    path: "/client/location/audit/address",
    element: (
      <RenderPage active="clients" page={<SiteLocationAuditAddress />} />
    ),
  },
  {
    path: "/client/location/audit/operations",
    element: (
      <RenderPage active="clients" page={<SiteLocationAuditOperations />} />
    ),
  },
  {
    path: "/client/location/audit/certificates",
    element: (
      <RenderPage active="clients" page={<SiteLocationAuditCertificates />} />
    ),
  },
  {
    path: "/client/location/audit/link-location",
    element: (
      <RenderPage active="clients" page={<SiteLocationAuditLinkLocation />} />
    ),
  },
  {
    path: "/client/location/timesheet",
    element: (
      <RenderPage
        active="clients"
        page={<LocationTimeshift />}
        permissions={[ACTIVE_BOARD]}
      />
    ),
  },
  {
    path: "/client/location/timesheet-report",
    element: <RenderPage active="clients" page={<ClientTimesheetReport />} />,
  },
  {
    path: "/client/location/roster",
    element: <RenderPage active="clients" page={<ClientRoster />} />,
  },
  {
    path: "/client/location/link-employee",
    element: (
      <RenderPage
        active="clients"
        page={<LinkEmployee />}
        permissions={[EMPLOYEE_LOCATION_LINKING]}
      />
    ),
  },
  {
    path: "/client/location/linked-employee",
    element: (
      <RenderPage
        active="clients"
        page={<LinkedEmployee />}
        permissions={[EMPLOYEE_LOCATION_LINKING]}
      />
    ),
  },
  {
    path: "/client/active-board",
    element: (
      <RenderPage
        active="clients"
        page={<ClientActiveBoard />}
        permissions={[
          ACTIVE_BOARD,
          // ACTIVE_BOARD_CUSTOM_TIMES,
          // ACTIVE_BOARD_OVERRIDE,
        ]}
      />
    ),
  },
  {
    path: "/client/checkpoint",
    element: (
      <RenderPage
        active="clients"
        page={<ClientCheckPoint />}
        permissions={[LOCATION_CHECKPOINT]}
      />
    ),
  },
  {
    path: "/client/checkpoint/general",
    element: (
      <RenderPage
        active="clients"
        page={<CheckpointGeneral />}
        permissions={[LOCATION_CHECKPOINT]}
      />
    ),
  },
  {
    path: "/client/checkpoint/action",
    element: (
      <RenderPage
        active="clients"
        page={<CheckpointAction />}
        permissions={[LOCATION_CHECKPOINT]}
      />
    ),
  },
  {
    path: "/admin/settings",
    element: (
      <RenderPage
        active="admin settings"
        page={<AdminGeneralSettings />}
        permissions={[SETTINGS]}
        activeAdmin="settings"
      />
    ),
  },
  {
    path: "/admin/settings/time-check",
    element: (
      <RenderPage
        active="admin settings"
        page={<AdminTimeCheckSettings />}
        permissions={[SETTINGS]}
        activeAdmin="settings"
      />
    ),
  },
  {
    path: "/admin/settings/manual-check-reason",
    element: (
      <RenderPage
        active="admin settings"
        page={<ManualCheckpointReason />}
        permissions={[SETTINGS]}
        activeAdmin="settings"
      />
    ),
  },
  {
    path: "/admin/settings/shift-generation",
    element: (
      <RenderPage
        active="admin settings"
        page={<ShiftGeneration />}
        permissions={[SETTINGS, SHIFT_GENERATION]}
        activeAdmin="settings"
      />
    ),
  },
  {
    path: "/admin/permissions",
    element: (
      <RenderPage
        active="admin settings"
        page={<AdminPermissionGroup />}
        permissions={[PERMISSION_GROUP, ASSIGN_PERMISSION_GROUP]}
        activeAdmin="permission groups"
      />
    ),
  },
  {
    path: "/admin/web-user-profile",
    element: (
      <RenderPage
        active="admin settings"
        page={<WebUserProfile />}
        permissions={[ADMIN_WEBUSER]}
        activeAdmin="web user profile"
      />
    ),
  },
  {
    path: "/admin/web-user-profile/form",
    element: (
      <RenderPage
        active="admin settings"
        page={<WebUserProfileForm />}
        permissions={[ADMIN_WEBUSER]}
        activeAdmin="web user profile"
      />
    ),
  },
  {
    path: "/staff-hub/grade",
    element: (
      <RenderPage
        active="staff/hub"
        page={<AdminGrade />}
        permissions={[GRADES]}
      />
    ),
  },
  {
    path: "/staff-hub/grade/form",
    element: (
      <RenderPage
        active="staff/hub"
        page={<AdminGradeForm />}
        permissions={[GRADES]}
      />
    ),
  },
  {
    path: "/admin/time-shift-generation",
    element: (
      <RenderPage
        active="admin settings"
        page={<TimeShiftGeneration />}
        permissions={[SHIFT_GENERATION, SETTINGS]}
        activeAdmin="shift generation"
      />
    ),
  },
  {
    path: "/admin/service-group",
    element: (
      <RenderPage
        active="admin settings"
        page={<AdminServiceGroup />}
        permissions={[SERVICE_GROUPS, SERVICES]}
        activeAdmin="service group"
      />
    ),
  },
  {
    path: "/admin/holiday-multiplier",
    element: (
      <RenderPage
        page={<HolidayMultiplier />}
        permissions={[HOLIDAY_MULTIPLIER]}
        active="admin settings"
        activeAdmin="holiday multiplier"
      />
    ),
  },
  {
    path: "/admin/settings/holiday-settings",
    element: (
      <RenderPage
        page={<HolidaySettings />}
        permissions={[SETTINGS]}
        active="admin settings"
        activeAdmin="settings"
      />
    ),
  },
  {
    path: "/admin/activity-logs",
    element: (
      <RenderPage
        page={<ActivityLogs />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/employee/basic-info",
    element: (
      <RenderPage
        page={<ActivityViewProfile />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/employee/contact-info",
    element: (
      <RenderPage
        page={<ActivityEmployeeContactInformation />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/employee/operations",
    element: (
      <RenderPage
        page={<ActivityEmployeeOperationsPage />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/employee/hr",
    element: (
      <RenderPage
        page={<ActivityEmployeeHr />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/employee/bank-details",
    element: (
      <RenderPage
        page={<ActivityEmployeeBankDetails />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/employee/emergency-contact",
    element: (
      <RenderPage
        page={<ActivityEmployeeEmergencyInformation />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/employee/medical-details",
    element: (
      <RenderPage
        page={<ActivityEmployeeMedicalDetailsPage />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/location/audit/general",
    element: (
      <RenderPage
        page={<ActivityLocationAuditGeneral />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/location/address",
    element: (
      <RenderPage
        page={<ActivityLocationAddress />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/location/operation",
    element: (
      <RenderPage
        page={<ActivityLocationOperation />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/location/required/certificate",
    element: (
      <RenderPage
        page={<ActivityLocationRequiredCertificate />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/location/link-location",
    element: (
      <RenderPage
        page={<ActivityLocationAuditLinkLocation />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/general-tab",
    element: (
      <RenderPage
        page={<ActivityGeneralSettingsTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/admin-time-check",
    element: (
      <RenderPage
        page={<ActivityTimeCheckSettingsTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/shift-generation",
    element: (
      <RenderPage
        page={<ActivityShiftGenerationSettingsTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/holiday",
    element: (
      <RenderPage
        page={<ActivityHolidaySettingsTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/client",
    element: (
      <RenderPage
        page={<ActivityClient />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/manual-checkpoint",
    element: (
      <RenderPage
        page={<ActivityManualCheckpointSettingsTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/permission-group",
    element: (
      <RenderPage
        page={<ActivityPermissionTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/certificate-type",
    element: (
      <RenderPage
        page={<CertificateTypeTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/certificate-group",
    element: (
      <RenderPage
        page={<CertificateGroupTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/grade",
    element: (
      <RenderPage
        page={<ActivityGrade />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/service-group",
    element: (
      <RenderPage
        page={<ActivityServiceGroupTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/web-user",
    element: (
      <RenderPage
        page={<ActivityWebUserTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/service-genaral",
    element: (
      <RenderPage
        page={<ActivityGeneralServiceTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/service-financial",
    element: (
      <RenderPage
        page={<ActivityGeneralFinancialTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/area-tab",
    element: (
      <RenderPage
        page={<ActivityAreaTab />}
        active="admin settings"
        activeAdmin="activity logs"
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/time-shift-tab",
    element: (
      <RenderPage
        active="clients"
        page={<ActivityTimeShift />}
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/admin/activity-logs/holiday-rate",
    element: (
      <RenderPage
        active="clients"
        page={<ActivityHolidayRate />}
        permissions={[ACTIVITY_LOG]}
      />
    ),
  },
  {
    path: "/live-dasboard-monitoring",
    element: (
      <RenderPage page={<LiveMonitoringDasboard />} active="dashboard" />
    ),
  },
  {
    path: "/live-location-statistic",
    element: (
      <RenderPage active="dashboard" page={<StatisticalSiteLocation />} />
    ),
  },
  {
    path: "/holiday-absence-summary",
    element: (
      <RenderPage
        active="staff/hub"
        page={<HolidayAbsenceSummary />}
        permissions={[EMPLOYEE_ABSENCE, EMPLOYEES_ABSENCE_READ]}
      />
    ),
  },
  {
    path: "/roster",
    element: (
      <RenderPage
        active="operations"
        page={<Roster />}
        permissions={[
          BROADCAST_ROSTER,
          AUTO_ROSTER_GROUP,
          ROSTER_READ_ONLY,
          ROSTER_SUMMARY,
          ROSTER_SCHEDULE_CONFIRM,
        ]}
      />
    ),
  },
  {
    path: "/reports/checkpoint",
    element: (
      <RenderPage
        active="reports"
        page={<CheckpointsByService />}
        permissions={[CHECK_CALL_REPORT]}
      />
    ),
  },
  {
    path: "/reports/checkpoint/checkins",
    element: <RenderPage active="reports" page={<CheckpointsCheckIns />} />,
  },
  {
    path: "/reports/checkcall-log",
    element: (
      <RenderPage
        active="reports"
        page={<CheckcallReportDashboard />}
        permissions={[CHECK_CALL_REPORT]}
      />
    ),
  },
  {
    path: "/reports/checkcall-log/attempts",
    element: <RenderPage active="reports" page={<CheckcallLogInfo />} />,
  },
  {
    path: "/reports/payroll",
    element: <RenderPage active="reports" page={<PayrollReport />} />,
  },
  {
    path: "/permission-error",
    element: <NoPermissionPage active="dashboard" />,
  },
  {
    path: "/subscribe",
    element: <Subscribe />,
  },
  {
    path: "/subscribe/success",
    element: <SubscribeSuccessful />,
  },
  {
    path: "*",
    element: <h1>404</h1>,
  },
]);

export default router;
