export const UK = "United Kingdom";
export const US = "United States";
export const NG = "Nigeria";
export const ADMIN = "Admin";
export const WEBUSER = "Web User";
export const EMPLOYEE = "Employee";
export const ACTIVE_BOARD = "Active Board";
// export const ACTIVE_BOARD_OVERRIDE = "Active Board Time Override";
// export const ACTIVE_BOARD_CUSTOM_TIMES = "ActiveBoardCustomTimes";
export const ADD_EMPLOYEE = "Add Employee";
export const EMPLOYEES = "Employees";
export const EMPLOYEE_ABSENCE = "Employees - Absences";
export const EMPLOYEES_ABSENCE_READ = "Employees - Absences (Read Only)";
export const EMPLOYEE_ADJUST_ACCRUAL_BALANCE =
  "Employees - Adjust Holiday Accrual Balance";
export const EMPLOYEE_HOLIDAY = "Employees - Holidays";
export const EMPLOYEE_READ_ONLY = "Employees - Read Only";
// export const EMPLOYEE_HR_TAB = "GDPR - Employee HR TAB";
export const CERTIFICATE_EMPLOYEE_DELETE =
  "Certificate - Employee Assigned Delete";
export const AREA = "Areas";
export const AUTO_ROSTER_GROUP = "Auto Roster Groups";
export const BROADCAST_ROSTER = "Broadcast Roster";
export const CERTIFICATE_GROUPS = "Certificate Groups";
export const CERTIFICATE_TYPES = "Certificate Types";
export const CLIENT = "Client";
export const EMPLOYEE_LOCATION_LINKING = "Employee - Location Linking";
export const EMPLOYEE_HOLIDAY_MULTIPLIER = "Employee Holiday Multipliers";
export const EMPLOYEE_UNAVAILABILITY = "Employee Unavailability";
// export const EMPLOYEE_HR_DEFAULT = "Employee HR Defaults";
export const GRADES = "Grades";
export const HOLIDAY_MULTIPLIER = "Holiday Multipliers";
export const LOCATION_UPDATE_AND_ADD = "Location Add/Update";
export const LOCATION_AUDIT = "Location Audit";
export const LOCATION_CHECKPOINT = "Locations - Checkpoints";
export const LOCATION_EMAIL_REPORT = "Locations - Email Report scanners";
export const LOCATION_SERVICES_ADD_UPDATE = "Locations - Services - Add/Update";
export const LOCATION_SERVICES_VIEW = "Locations - Services - View";
export const MESSAGE_EMPLOYEES = "Message Employees";
export const REPORT_INCIDENT_LOCATION = "Report - Incidents By Location";
export const ROSTER_READ_ONLY = "Roster (Read Only)";
export const ROSTER_READ_ONLY_GDCPR = "Roster (Read Only) - GDPR";
export const ROSTER_SUMMARY = "Roster Summary";
export const ROSTER_SCHEDULE_CONFIRM = "Roster - Schedule and Confirm";
export const SERVICE_GROUPS = "Service Groups";
export const SERVICES = "Services";
export const SETTINGS = "Settings";
export const SHIFT_DELETION = "Shift Deletion";
export const SHIFT_GENERATION = "Shift Generation";
export const SHOW_EMPLOYEE_PASSWORD = "Show Employee Password";
export const TIME_SHEET = "Timesheet";
export const ADMIN_WEBUSER = "Web Users";
export const PERMISSION_GROUP = "Permission Groups";
export const ASSIGN_PERMISSION_GROUP = "Permission Groups - Assign";
export const LOCATION_VIEW = "Locations View";
export const LOCATION_TIMESHEET = "Location TimeSheet";
export const CANCELLED = "CANCELLED";
export const SCHEDULED = "SCHEDULED";
export const CONFIRMED = "CONFIRMED";
export const STARTED = "STARTED";
export const ENDED = "ENDED";
export const ACTIVITY_LOG = "Activity Log";
export const CHECK_CALL_REPORT = "Report - Checkcalls";
export const EMAIL = "Email";
export const GOOGLE = "Google";
export const MICROSOFT = "Microsft";
