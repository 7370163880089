import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../Common/PageBackButton";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../utils/localstorage";
import { getRequest } from "../../../../api/request";
import PageLoader from "../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../api/urls";
import { CertificateGroupActivityLog } from "../../../../types-definition/admin";
import DashboardHeader from "../../../Common/DashboardHeader";
import { toastError } from "utils/toast";

const CertificateGroupTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentCertificateGroup, setCurrentCertificateGroup] =
    useState<CertificateGroupActivityLog>({
      active: false,
      certificateName: "",
      description: "",
    });
  const [previousCertificateGroup, setPreviousCertificateGroup] =
    useState<CertificateGroupActivityLog>({
      active: false,
      certificateName: "",
      description: "",
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getCertificateGroupData = async () => {
      // get employee data
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data.newRecord;
        const previousData = response.data.previousRecord;
        setCurrentCertificateGroup({
          active: data.certificateGroup.active,
          certificateName: data.certificateGroup.certificateName,
          description: data.certificateGroup.description,
        });

        setPreviousCertificateGroup({
          active: previousData?.certificateGroup?.active,
          certificateName: previousData?.certificateGroup?.certificateName,
          description: previousData?.certificateGroup?.description,
        });
      } else {
        toastError(response?.data);
      }

      setPageLoading(false);
    };
    getCertificateGroupData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Certificate Group
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Certificate Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentCertificateGroup.certificateName ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Description</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentCertificateGroup.description ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Active</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentCertificateGroup?.active === true && "True"}
                  {currentCertificateGroup?.active === false && "False"}
                  {currentCertificateGroup?.active === undefined && "--"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Certificate Group
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Certificate Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousCertificateGroup.certificateName ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Description</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousCertificateGroup.description ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Active</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousCertificateGroup?.active === true && "True"}
                  {previousCertificateGroup?.active === false && "False"}
                  {previousCertificateGroup?.active === undefined && "--"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateGroupTab;
