import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, postRequest, putRequest } from "api/request";
import { useNavigate } from "react-router-dom";
import {
  deleteCheckpointSection,
  getCheckpointSection,
} from "utils/localstorage";
import {
  CheckpointActionFormData,
  CheckpointActionRequiredFormData,
} from "types-definition/client";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { toastError, toastSuccess } from "utils/toast";
import PageBackButton from "components/Common/PageBackButton";
import CheckpointNavigator from "components/Client/Checkpoint/CheckpointNavigator";
import AddCheckpointActionForm from "components/Client/Checkpoint/AddCheckpointActionForm";
import fieldTextValidator from "utils/validateFieldsWithText";
import PageLoader from "components/Common/PageLoader";

const CheckpointAction = () => {
  const navigate = useNavigate();
  const id = getCheckpointSection();
  const [currentCheckPointId, setCurrentCheckPointId] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [formData, setFormData] = useState<CheckpointActionFormData>({
    action: false,
    actionMandatory: false,
    question: "Is everything Okay?",
    isUpdate: false,
  });
  const [requiredFormData, setRequiredFormData] =
    useState<CheckpointActionRequiredFormData>({
      question: {
        status: false,
        text: "",
      },
    });
  const handleSubmitForm = async () => {
    setRequestLoading(true);
    let requestData;
    if (formData.action) {
      const { status, data } = fieldTextValidator(formData, requiredFormData);
      if (!status) {
        setRequestLoading(false);
        setRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        return;
      }
      requestData = {
        action: "answer_question",
        actionMandatory: formData.actionMandatory,
        question: formData.question,
      };
    } else {
      requestData = {
        action: "no_action",
      };
    }
    let response;
    if (formData.isUpdate) {
      response = await putRequest(
        `/service/checkpoint-action/action/${currentCheckPointId}`,
        requestData,
      );
    } else {
      response = await postRequest(
        `/service/checkpoint-action/${id}`,
        requestData,
      );
    }
    if (response?.success) {
      toastSuccess(
        `Checkpoint Action ${
          !formData.isUpdate ? "Added" : "Modified"
        } successfully`,
      );
      deleteCheckpointSection();
      navigate("/client/checkpoint");
    } else {
      toastError(response?.data);
    }
    setRequestLoading(false);
  };
  const handleFormCancelation = () => {
    navigate("/client/checkpoint");
  };
  useEffect(() => {
    const getCheckpointSectionData = async () => {
      const data = getCheckpointSection();
      if (data) {
        setPageLoading(true);
        const response = await getRequest(`service/checkpoint-action/${data}`);
        if (response?.success && response.data) {
          setFormData({
            action:
              response.data[0]?.action === "answer_question" ? true : false,
            actionMandatory: response.data[0]?.actionMandatory ?? false,
            question: response.data[0]?.question ?? "",
            isUpdate: response.data[0]?.action ? true : false,
          });
          setCurrentCheckPointId(response.data[0]?.id);
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
        setPageLoading(false);
      }
    };
    getCheckpointSectionData();
  }, []);

  return (
    <>
      {pageLoading && <PageLoader />}
      <section>
        <ScrollToTop />
        <DashboardHeader active="clients" />
        <div className={styles.container}>
          <div className={styles.item_container}>
            <PageBackButton
              width="90%"
              paddingTop="0"
              marginBottom="15px"
              to="/client/checkpoint/general"
            />
            <div className={styles.navigation_container}>
              <CheckpointNavigator active="action" />
            </div>
            <div className={styles.item_container_text}>
              <Typography textAlign="left" textWeight="600" textSize="20px">
                Action
              </Typography>
            </div>
            <div className={styles.content_container}>
              <div className={styles.form_container}>
                <AddCheckpointActionForm
                  formData={formData}
                  setFormData={setFormData}
                  requiredFormData={requiredFormData}
                  setRequiredFormData={setRequiredFormData}
                />
                <div className={styles.button_container}>
                  <Button
                    isPrimary={false}
                    backgroundColor={colors.transparent}
                    isPrimaryBorder={true}
                    onClick={handleFormCancelation}
                    text="Back"
                    paddingVertical="7px"
                    width="80px"
                  />
                  <Button
                    width="180px"
                    text="Save"
                    paddingVertical="7px"
                    isPrimary={true}
                    onClick={handleSubmitForm}
                    isLoading={requestLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckpointAction;
