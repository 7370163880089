import React, { useEffect, useState } from "react";
import TextInput from "../../../Form/TextInput";
import styles from "./index.module.css";
import ColourInput from "../../../Form/ColourPicker";
import { GradeFormProps } from "../../../../types-definition/admin";
import Checkbox from "../../../Form/Checkbox";
import { getRequest } from "../../../../api/request";
import { GET_CERTIFICATE_GROUP_URL } from "../../../../api/urls";
import ClientSelectInput from "../../../Form/ClientSelectInput";
import "react-color-palette/css";

const GradeForm: React.FC<GradeFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  const [certificateGroup, setCertificateGroup] = useState<any>([]);
  const [certificate, setCertificate] = useState("");
  useEffect(() => {
    const getCertificateGroup = async () => {
      try {
        const response = await getRequest(`${GET_CERTIFICATE_GROUP_URL}`);
        setCertificateGroup(response.data?.certificateGroups);
      } finally {
      }
    };

    getCertificateGroup();
  }, []);
  return (
    <>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.grade}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, grade: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, grade: false };
            });
          }}
          label="Grade"
          placeholder="Enter Grade"
          error={requiredFormData.grade}
          helperText={requiredFormData.grade ? "Grade is required" : ""}
          id="grade"
        />
        <TextInput
          value={formData.description}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, description: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, description: false };
            });
          }}
          label="Description"
          placeholder="Enter Description"
          error={requiredFormData.description}
          helperText={
            requiredFormData.description ? "Description is required" : ""
          }
          id="description"
        />
      </div>
      <div className={styles.form_flex_container}>
        <div className={styles.colour_input_container}>
          <ColourInput
            value={formData.color}
            label="Colour"
            placeholder="Select Colour"
            setValue={(data: string) => {
              setFormData((prevData) => {
                return { ...prevData, color: data };
              });
              setRequiredFormData((prevData) => {
                return { ...prevData, color: false };
              });
            }}
          />
        </div>
        <div className={styles.certificate_input}>
          <ClientSelectInput
            value={formData.certificateGroup}
            setValue={(name, id) => {
              if (id) {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    certificateGroup: id.toString(),
                    certificateName: name,
                  };
                });
              }
              setCertificate(certificate);
              setRequiredFormData((prevData) => {
                return { ...prevData, certificateGroup: false };
              });
            }}
            label="Certificate Group"
            placeholder="Select Certificate Group"
            client={formData.certificateName}
            options={
              Array.isArray(certificateGroup)
                ? certificateGroup?.map((data: any) => {
                    return {
                      name: data.certificateName,
                      id: data.id,
                    };
                  })
                : []
            }
            error={requiredFormData.certificateGroup}
            helperText={
              requiredFormData.certificateGroup
                ? "Certificate Group is required"
                : ""
            }
            datalistTop="83.5px"
            id="certificateGroup"
          />
        </div>
      </div>
      <div className={styles.Checkbox_container}>
        <Checkbox
          value={formData.active}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, active: data };
            });
          }}
          label={"Active"}
        />
      </div>
    </>
  );
};
export default GradeForm;
