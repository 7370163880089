import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import colors from "../../../theme/colors";
import RosterDayViewTableItem from "../RosterDayViewTableItem";
import { RoasterDayViewTableProps } from "../../../types-definition/roster";
import { useAppSelector } from "../../../redux/hooks";
import {
  getRosterFromDate,
  getRosterServiceData,
} from "../../../redux/slice/roster/rosterSlice";
// import stringToDate from "../../../utils/formatDate";

const RosterDayViewTable: React.FC<RoasterDayViewTableProps> = () => {
  const [dayViewTableData, setDayViewTableData] = useState<
    Array<{ startTime: string; endTime: string }>
  >([]);
  const getRosterCurrentDate = useAppSelector(getRosterFromDate);
  const data = useAppSelector(getRosterServiceData);
  useEffect(() => {
    if (data) {
      const extractTimeFromData = data
        ?.filter((i: any) => {
          return (
            new Date(i.date).getFullYear() ===
              new Date(getRosterCurrentDate).getFullYear() &&
            new Date(i.date).getMonth() ===
              new Date(getRosterCurrentDate).getMonth() &&
            new Date(i.date).getDate() ===
              new Date(getRosterCurrentDate).getDate()
          );
        })
        ?.map((item: any) => {
          return {
            startTime: item.startTime,
            endTime: item.endTime,
          };
        });
      setDayViewTableData(
        extractTimeFromData.filter(
          (i: any, index: number, self: any) =>
            self.findIndex(
              (item: any) =>
                item.startTime === i.startTime && item.endTime === i.endTime,
            ) === index,
        ),
      );
    }
  }, [data, getRosterCurrentDate]);
  return (
    <div className={styles.container}>
      <div className={styles.container_item_header}>
        <div className={styles.th}>
          <Typography
            textAlign="center"
            fontFamily="inter"
            textWeight="600"
            textColor={colors.tableText}
          ></Typography>
        </div>
        <div className={styles.th_color_container}>
          <Typography
            textAlign="center"
            fontFamily="inter"
            textWeight="600"
            textColor={colors.tableText}
          ></Typography>
        </div>
      </div>
      <RosterDayViewTableItem data={dayViewTableData} />
    </div>
  );
};

export default RosterDayViewTable;
