import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import ActiveState from "../../../Common/ActiveState";
import { ClientTimesheetReportData } from "../../../../types-definition/client";
const ClientTimesheetItem: React.FC<ClientTimesheetReportData> = ({
  employeeName,
  manager,
  area,
  location,
  hoursWorked,
  status,
}) => {
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        {/* <CircularName name={`${firstName} ${lastName}`} /> */}
        <Typography
          textAlign="left"
          textWeight="600"
        >{`${employeeName} `}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{manager}</Typography>
      </td>

      <td className={styles.td}>
        <Typography textAlign="left">{area}</Typography>
      </td>

      <td className={styles.td}>
        <Typography textAlign="left">{location}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{hoursWorked}</Typography>
      </td>
      <td className={styles.td}>
        <ActiveState active={true} />
      </td>
    </tr>
  );
};

export default ClientTimesheetItem;
