import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import PageLoader from "components/Common/PageLoader";
import Button from "components/Form/Button";
import colors from "theme/colors";
import AddHolidayAbsence from "components/StaffHub/HolidayAbsence/HolidayAbsenceModal";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  getEmployeeHolidayAbsenceSummaryCounter,
  getShowAddModal,
  getShowConflictModal,
  getShowEditHolidayAbsenceModal,
  setHolidayAbsenceSummaryFormInformation,
  setShowAdd,
} from "../../../redux/slice/HolidayAbsence/holidayAbsenceSlice";
import ShiftConflictedModal from "components/StaffHub/HolidayAbsence/ShiftConflictModal";
import { HolidayAbsenceSummaryData } from "types-definition/StaffHub/holiday";
import HolidayAbsenceTable from "components/StaffHub/HolidayAbsence/HolidayAbsenceTable";
import { getRequest } from "api/request";
import { GET_EMPLOYEE_HOLIDAY_ABSENCE_SUMMARY } from "api/urls";
import EditHolidayAbsence from "components/StaffHub/HolidayAbsence/HolidayAbsenceEditModal";
import HolidayAbsenceDateFilter from "components/StaffHub/HolidayAbsence/HolidayAbsenceDateFilter";
import Typography from "components/Common/Typography";
import stringToDate from "utils/formatDate";
import filterKeysFromArray from "utils/filterKeyFromArray";
import plusIcon from "assets/svg/primary-plus-icon.svg";
import plusWhiteIcon from "assets/svg/plus-icon.svg";
import checkAdminPermission, { checkUserPermission } from "utils/permission";
import { getUserTypes } from "utils/localstorage";
import { EMPLOYEE_ABSENCE } from "types-definition/constants";

const HolidayAbsenceSummary = () => {
  const dispatch = useAppDispatch();
  const showAddModal = useAppSelector(getShowAddModal);
  const showEditModal = useAppSelector(getShowEditHolidayAbsenceModal);
  const showConflictModal = useAppSelector(getShowConflictModal);
  const counter = useAppSelector(getEmployeeHolidayAbsenceSummaryCounter);
  const [requestLoading, setRequestLoading] = useState(false);
  const [data, setData] = useState<Array<HolidayAbsenceSummaryData>>([]);
  const [isHolidayAbsencePermission, setIsHolidayAbsencePermission] =
    useState(false);
  // const [requestedFilteredData, setRequestedFilteredData] = useState<
  //   Array<HolidayAbsenceSummaryData>
  // >([]);
  const [isFilter, setIsFilter] = useState(false);
  const [filteredData, setFilteredData] = useState<
    Array<HolidayAbsenceSummaryData>
  >([]);

  useEffect(() => {
    const getEmployeeHolidayAbsenceSummary = async () => {
      setRequestLoading(true);
      try {
        const response = await getRequest(GET_EMPLOYEE_HOLIDAY_ABSENCE_SUMMARY);

        if (response?.success) {
          // extract All segmentId
          const segmentIds = filterKeysFromArray(response?.data, "segmentId");
          let segmentedData = [];
          for (const segmentId in segmentIds) {
            const data = response?.data.filter((item: any) => {
              return item.segmentId === segmentIds[segmentId];
            });

            if (data.length > 1) {
              segmentedData.push({
                id: segmentIds[segmentId],
                employeeName: `${data[0].employee?.firstName} ${data[0].employee?.lastName}`,
                fromDate: data[0].date,
                toDate: data[data.length - 1].date,
                timeOff: data[0].timeOff,
                holidayType: data[0].holidayType,
                modifiedBy: data[0].modifiedBy,
                updatedAt: data[0].updatedAt,
                status: data[0].status,
                employeeId: data[0].employee.id,
              });
            } else {
              segmentedData.push({
                id: segmentIds[segmentId],
                employeeName: `${data[0].employee?.firstName} ${data[0].employee?.lastName}`,
                fromDate: data[0].date,
                toDate: data[0].date,
                timeOff: data[0].timeOff,
                holidayType: data[0].holidayType,
                modifiedBy: data[0].modifiedBy,
                updatedAt: data[0].updatedAt,
                status: data[0].status,
                employeeId: data[0].employee.id,
              });
            }
          }
          // set and sort the data
          setData(
            segmentedData.sort(
              (a: any, b: any) =>
                new Date(stringToDate(a.fromDate)).getTime() -
                new Date(stringToDate(b.fromDate)).getTime(),
            ),
          );
          const requestedFilteredData = response?.data.filter((item: any) => {
            return item.status === "requested";
          });
          setRequestLoading(false);
        }
      } catch (error) {
        setRequestLoading(false);
      }
    };
    getEmployeeHolidayAbsenceSummary();
  }, [counter]);

  const filterDataByDate = (startDate: string, endDate: string) => {
    const filteredData = data.filter((item) => {
      return (
        new Date(item.fromDate).getTime() <= new Date(endDate).getTime() &&
        new Date(item.fromDate).getTime() >= new Date(startDate).getTime()
      );
    });
    setFilteredData(filteredData);
    setIsFilter(true);
  };

  const clearFilter = () => {
    setIsFilter(false);
  };

  useEffect(() => {
    const getPermission = async () => {
      const data = checkAdminPermission(getUserTypes());
      if (data) {
        setIsHolidayAbsencePermission(false);
      } else {
        const result = await checkUserPermission([EMPLOYEE_ABSENCE]);
        setIsHolidayAbsencePermission(result ? false : true);
      }
    };
    getPermission();
  }, []);

  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="staff/hub" />
        <div className={styles.container}>
          <div className={`${styles.item_container}`}>
            <div className={styles.right_container}>
              <div>
                <Typography
                  textWeight="600"
                  textSize="24px"
                  marginHorizontal="-8px"
                >
                  Holiday & Absence Summary
                </Typography>
              </div>
              <div className={styles.right_container_item}>
                <div className={styles.date_range_dropdown}>
                  <HolidayAbsenceDateFilter
                    filterDateCallBack={filterDataByDate}
                    clearFilterCallBack={clearFilter}
                  />
                </div>
                <Button
                  isPrimary={false}
                  isPrimaryBorder={false}
                  borderColor={
                    !isHolidayAbsencePermission
                      ? colors.primary3
                      : colors.transparent
                  }
                  borderWidth="2px"
                  disabled={isHolidayAbsencePermission}
                  text="Add Holiday/Absence"
                  textColor={
                    !isHolidayAbsencePermission ? colors.primary3 : colors.white
                  }
                  onClick={() => {
                    dispatch(
                      setHolidayAbsenceSummaryFormInformation({
                        description: "",
                        employeeName: "",
                        preferredTime: "",
                        type: "",
                      }),
                    );
                    dispatch(setShowAdd(true));
                  }}
                  left={
                    <img
                      src={
                        !isHolidayAbsencePermission ? plusIcon : plusWhiteIcon
                      }
                      alt="plus-icon"
                    />
                  }
                  width="250px"
                  backgroundColor="transparent"
                  textWeight="600"
                  paddingVertical="6px"
                />
              </div>
            </div>
            <HolidayAbsenceTable data={!isFilter ? data : filteredData} />
          </div>
        </div>
        {showAddModal && <AddHolidayAbsence />}
        {showConflictModal && <ShiftConflictedModal />}
        {showEditModal && <EditHolidayAbsence />}
      </section>
    </>
  );
};

export default HolidayAbsenceSummary;
