import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import cancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import searchIcon from "../../../../assets/svg/search-icon.svg";
import SearchInput from "../SearchInput";
import AvailableLocationItem from "../AvailableLocationItem";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../../api/request";
import AssignedLocationItem from "../AssignedLocationItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toastError, toastSuccess } from "../../../../utils/toast";
import { ServiceLinkingModalProps } from "../../../../types-definition/client";
import { GET_LOCATION_URL } from "../../../../api/urls";
import LoadingIcons from "react-loading-icons";
import colors from "../../../../theme/colors";

const ServiceLinkingModal: React.FC<ServiceLinkingModalProps> = ({
  serviceId,
  showModal,
  serviceName,
}) => {
  const id = serviceId;
  const [monitorUnassignChanges, setMonitorUnassignChanges] = useState(0);
  const [formData, setFormData] = useState({
    availableLocations: "",
    assignedLocations: "",
  });
  const [availableLocations, setAvailableLocations] = useState<Array<any>>([]);
  const [filteredAvailableLocations, setFilteredAvailableLocations] = useState<
    Array<any>
  >([]);
  const [assignedLocations, setAssignedLocations] = useState<Array<any>>([]);
  const [filteredAssignedLocations, setFilteredAssignedLocations] = useState<
    Array<any>
  >([]);
  const [requestLoading, setRequestLoading] = useState(true);

  // Handle Search Bar for Available Permissions
  const handleSearchAvailablePermission = (value: string) => {
    setFilteredAvailableLocations((prevData) => {
      return availableLocations.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      );
    });
  };

  // Handle Search Bar for Assigned Permissions
  const handleSearchAssignedPermission = (value: string) => {
    setFilteredAssignedLocations((prevData) => {
      return assignedLocations.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      );
    });
  };

  // Handle Assigning New Permission
  const handleAssignNewLocation = async (locationId: string) => {
    setRequestLoading(true);
    const response = await postRequest(`service/${id}/location`, {
      locationId,
    });
    if (response?.success) {
      toastSuccess("Location Linked Successfully");
      await getAllLocation();
      // setMonitorUnassignChanges((prevData) => prevData + 1);
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
    setRequestLoading(false);
  };
  const handleUnAssignPermission = async (locationId: string) => {
    setRequestLoading(true);
    const response = await deleteRequest(`service/location/link/${locationId}`);
    if (response?.success) {
      toastSuccess("Location Unlinked Successfully");
      await getAllLocation();
      // setMonitorUnassignChanges((prevData) => prevData + 1);
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
    setRequestLoading(false);
  };

  // Gets the available locatons and assigned locations on modal load
  const getAllLocation = async () => {
    try {
      const response = await getRequest(GET_LOCATION_URL);
      if (response?.success) {
        await getAssignedLocations(response?.data);
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    } catch (error) {
      setRequestLoading(false);
      toastError("An error occurred. Please try again later.");
    }
  };
  // Get Assigned Locations Logic
  const getAssignedLocations = async (data: any) => {
    const locationIds: Array<any> = [];
    for (const index in data) {
      const response = await getRequest(`/service/location/${data[index]?.id}`);
      const locationData = response?.data.find((item: any) => {
        return item?.service?.id === id;
      });
      locationIds.push({
        locationId: locationData?.location?.id,
        linkId: locationData?.id,
      });
    }
    setAvailableLocations(
      data?.filter(
        (item: any) => !locationIds.find((i: any) => i.locationId === item.id),
      ),
    );
    setAssignedLocations(
      data
        ?.filter((item: any) =>
          locationIds.find((i: any) => i.locationId === item.id),
        )
        .map((i: any) => {
          return {
            ...i,
            linkId: locationIds.find((item: any) => item.locationId === i.id)
              ?.linkId,
          };
        }),
    );
    setRequestLoading(false);
  };

  useEffect(() => {
    getAllLocation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Drag and Drop
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }

    if (result.destination.droppableId === "2") {
      const itemIndex = result.draggableId.split("-")[2];
      const data = [...availableLocations].filter(
        (item, index) => index !== parseInt(itemIndex.toString()),
      );
      setAvailableLocations(data);
      handleAssignNewLocation(result.source.index);
      return;
    }
    if (result.destination.droppableId === "1") {
      const itemIndex = result.draggableId.split("-")[2];
      const data = [...assignedLocations].filter(
        (item, index) => index !== parseInt(itemIndex.toString()),
      );
      setAssignedLocations(data);
      handleUnAssignPermission(result.source.index);
      return;
    }
  };
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      dragHandleUsageInstructions="Use the spacebar to lift / drop the draggable item."
    >
      <div className={styles.container}>
        <div className={styles.content_container}>
          <div className={styles.header}>
            <div className={styles.flex_header}>
              <Typography textWeight="600" textSize="18px">
                {serviceName} Linking
              </Typography>
              {requestLoading && (
                <LoadingIcons.TailSpin
                  width={20}
                  height={20}
                  stroke={colors.primary1}
                  className={styles.loader_spinner}
                />
              )}
            </div>
            <img
              src={cancelIcon}
              alt="cancel icon"
              className={styles.cancel_icon}
              onClick={() => showModal()}
            />
          </div>
          <div className={styles.flex_container}>
            <div className={styles.flex_item}>
              <div className={styles.flex_item_header}>
                <Typography textWeight="600" textAlign="left">
                  Available Locations
                </Typography>
              </div>
              <div>
                <SearchInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={formData.availableLocations}
                  setValue={(data) => {
                    setFormData((prevData) => {
                      return { ...prevData, availableLocations: data };
                    });
                    handleSearchAvailablePermission(data);
                  }}
                />
              </div>

              <Droppable droppableId="1">
                {(provided) => (
                  <div
                    className={styles.permission_container}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {!formData.availableLocations
                      ? availableLocations.map((location, index) => (
                          <Draggable
                            draggableId={`draggable-available-${index}`}
                            index={location.id}
                            key={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={styles.draggable_item}
                              >
                                <AvailableLocationItem
                                  id={location.id}
                                  key={index}
                                  text={location.name}
                                  action={async (locationId) => {
                                    await handleAssignNewLocation(locationId);
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))
                      : filteredAvailableLocations.map((location, index) => (
                          <Draggable
                            draggableId={`draggable-available-${index}`}
                            index={location.id}
                            key={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={styles.draggable_item}
                              >
                                <AvailableLocationItem
                                  id={location.id}
                                  text={location.name}
                                  action={async (locationId) => {
                                    await handleAssignNewLocation(locationId);
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className={styles.flex_item}>
              <div className={styles.flex_item_header}>
                <Typography textWeight="600" textAlign="left">
                  Assigned Locations
                </Typography>
              </div>
              <div>
                <SearchInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={formData.assignedLocations}
                  setValue={(data) => {
                    setFormData((prevData) => {
                      return { ...prevData, assignedLocations: data };
                    });
                    handleSearchAssignedPermission(data);
                  }}
                />
              </div>
              <Droppable droppableId="2">
                {(provided) => (
                  <div
                    className={styles.permission_container}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className={styles.permission_container}>
                      {!formData.assignedLocations
                        ? assignedLocations.map((location, index) => (
                            <Draggable
                              draggableId={`draggable-assinged-${index}`}
                              index={location?.linkId}
                              key={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.draggable_item}
                                >
                                  <AssignedLocationItem
                                    id={location?.id}
                                    key={index}
                                    text={location?.name}
                                    action={async (locationId) => {
                                      await handleUnAssignPermission(
                                        location.linkId,
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))
                        : filteredAssignedLocations.map((location, index) => (
                            <Draggable
                              draggableId={`draggable-assinged-${index}`}
                              index={location.id}
                              key={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.draggable_item}
                                >
                                  <AssignedLocationItem
                                    id={location.id}
                                    key={index}
                                    text={location.name}
                                    action={async (locationId) => {
                                      await handleUnAssignPermission(
                                        locationId,
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default ServiceLinkingModal;
