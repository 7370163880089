import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../../Common/PageBackButton";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import { getRequest } from "../../../../../api/request";
import PageLoader from "../../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import { AdminActivityGeneralSettingProps } from "../../../../../types-definition/admin";
import DashboardHeader from "../../../../Common/DashboardHeader";
import { toastError } from "utils/toast";
const ActivityGeneralSettingsTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentGeneralSetting, setCurrentGeneralSetting] =
    useState<AdminActivityGeneralSettingProps>({
      Name: "",
      CompanyPhoneNumber: "",
      CompanyEmailAddress: "",
      ShiftStartProximityRadius: "",
      ShiftEndProximityRadius: "",
      CheckCallProximityRadius: "",
      ProximityRadiusEmail: "",
      AdministratorEmail: "",
    });
  const [previousGeneralSetting, setPreviousGeneralSetting] =
    useState<AdminActivityGeneralSettingProps>({
      Name: "",
      CompanyPhoneNumber: "",
      CompanyEmailAddress: "",
      ShiftStartProximityRadius: "",
      ShiftEndProximityRadius: "",
      CheckCallProximityRadius: "",
      ProximityRadiusEmail: "",
      AdministratorEmail: "",
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getEmployeeData = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data.newRecord;
        const previousData = response.data.previousRecord;
        if (response.data.action === "POST") {
          setCurrentGeneralSetting({
            // name does not exist
            Name: data?.general?.name,
            CompanyPhoneNumber: data?.general.officePhoneNumber,
            CompanyEmailAddress: data?.general.officeEmailAddress,
            ShiftStartProximityRadius: data?.general.shiftStartProximityRadius,
            ShiftEndProximityRadius: data?.general.shiftEndProximityRadius,
            CheckCallProximityRadius: data.general.checkCallProximityRadius,
            ProximityRadiusEmail: data?.general.proximityRadiusEmail,
            // admistrative does not exist
            AdministratorEmail: data?.general?.administratorEmail,
          });
          setPreviousGeneralSetting({
            Name: previousData?.general.name,
            CompanyPhoneNumber: previousData?.general.officePhoneNumber,
            CompanyEmailAddress: previousData?.general.officeEmailAddress,
            ShiftStartProximityRadius:
              previousData?.general.shiftStartProximityRadius,
            ShiftEndProximityRadius:
              previousData?.general.shiftEndProximityRadius,
            CheckCallProximityRadius:
              previousData?.general.checkCallProximityRadius,
            ProximityRadiusEmail: previousData?.general.proximityRadiusEmail,
            AdministratorEmail: previousData?.general.administratorEmail,
          });
        } else {
          setCurrentGeneralSetting({
            // name does not exist
            Name: "hello",
            CompanyPhoneNumber: data.general.officePhoneNumber,
            CompanyEmailAddress: data.general.officeEmailAddress,
            ShiftStartProximityRadius: data.general.shiftStartProximityRadius,
            ShiftEndProximityRadius: data.general.shiftEndProximityRadius,
            CheckCallProximityRadius: data.general.checkCallProximityRadius,
            ProximityRadiusEmail: data.general.proximityRadiusEmail,
            // admistrative does not exist
            AdministratorEmail: "Hello@gmail.com",
          });
          setPreviousGeneralSetting({
            Name: previousData.general.name,
            CompanyPhoneNumber: previousData.general.officePhoneNumber,
            CompanyEmailAddress: previousData.general.officeEmailAddress,
            ShiftStartProximityRadius:
              previousData.general.shiftStartProximityRadius,
            ShiftEndProximityRadius:
              previousData.general.shiftEndProximityRadius,
            CheckCallProximityRadius:
              previousData.general.checkCallProximityRadius,
            ProximityRadiusEmail: previousData.general.proximityRadiusEmail,
            AdministratorEmail: previousData.general.administratorEmail,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getEmployeeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New General Settings
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGeneralSetting.Name ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Company Phone Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGeneralSetting.CompanyPhoneNumber
                    ? currentGeneralSetting.CompanyPhoneNumber
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Company Email Address</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGeneralSetting?.CompanyEmailAddress
                    ? currentGeneralSetting?.CompanyEmailAddress
                    : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Administrator Email</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGeneralSetting.AdministratorEmail
                    ? currentGeneralSetting.AdministratorEmail
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">
                  Shift Start Proximity Radius
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGeneralSetting.ShiftStartProximityRadius}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Shift End Proximity Radius
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGeneralSetting?.ShiftEndProximityRadius
                    ? currentGeneralSetting?.ShiftEndProximityRadius
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Check Call Proximity Radius
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGeneralSetting.CheckCallProximityRadius
                    ? currentGeneralSetting.CheckCallProximityRadius
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Proximity Radius Email</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentGeneralSetting.ProximityRadiusEmail
                    ? currentGeneralSetting.ProximityRadiusEmail
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous General Settings
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGeneralSetting.Name ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Company Phone Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGeneralSetting.CompanyPhoneNumber
                    ? previousGeneralSetting.CompanyPhoneNumber
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Company Email Address</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGeneralSetting?.CompanyEmailAddress
                    ? previousGeneralSetting?.CompanyEmailAddress
                    : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Administrator Email</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGeneralSetting.AdministratorEmail
                    ? previousGeneralSetting.AdministratorEmail
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">
                  Shift Start Proximity Radius
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGeneralSetting.ShiftStartProximityRadius
                    ? previousGeneralSetting.ShiftStartProximityRadius
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Shift End Proximity Radius
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGeneralSetting?.ShiftEndProximityRadius
                    ? previousGeneralSetting?.ShiftEndProximityRadius
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Check Call Proximity Radius
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGeneralSetting.CheckCallProximityRadius
                    ? previousGeneralSetting.CheckCallProximityRadius
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Proximity Radius Email</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousGeneralSetting.ProximityRadiusEmail
                    ? previousGeneralSetting.ProximityRadiusEmail
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityGeneralSettingsTab;
