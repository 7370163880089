import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import TextInput from "../../../../Form/TextInput";
import emailIcon from "../../../../../assets/svg/Mail Icon.svg";
import { Country } from "country-state-city";
import LocationSelectInput from "../../../../Form/LocationSelectInput";
import { EmployeeContactInfoFormPropsUk } from "../../../../../types-definition/StaffHub/employee";
import validatePostCode from "../../../../../utils/validatePostCode";
import EmptyContactInfoFormUk from "../EmptyContactInfoFormUk";
import Button from "../../../../Form/Button";
import { getRequest } from "../../../../../api/request";
import { GET_ADDRESS } from "../../../../../api/urls";
import UkAddressSearchInput from "../../../../Form/UkAddressInputSearch";
import { useAppDispatch } from "../../../../../redux/hooks";
import { setShowFindAddressDropdown } from "../../../../../redux/slice/staff-hub/employeeSlice";
import getLatitudeAndLongitudeFromPostCode from "../../../../../utils/getLatitudeAndLongitudeFromPostCode";

const EmployeeContactInfoFormUk: React.FC<EmployeeContactInfoFormPropsUk> = ({
  formData,
  setFormData,
  currentCountryCode,
  setCurrentCountryCode,
  postCodeOptions,
  handlePostCodeAutoComplete,
  requiredFormData,
  setRequiredFormData,
}) => {
  const [address, setAddress] = useState<Array<any>>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const getAddressDetails = async () => {
      const response = await getRequest(
        `${GET_ADDRESS}?postcode=${formData.postcode}`,
      );
      setAddress(
        response?.data?.suggestions
          ? response?.data?.suggestions?.map((i: any) => {
              return { name: i.address, id: i.id };
            })
          : [],
      );
    };
    getAddressDetails();
  }, [formData.postcode]);
  useEffect(() => {
    if (formData.postcode1 && !formData.latitude && !formData.longitude) {
      const getData = async () => {
        const result = await getLatitudeAndLongitudeFromPostCode(
          formData.postcode1,
        );
        setFormData((prevData) => {
          return {
            ...prevData,
            latitude: result?.latitude,
            longitude: result?.longitude,
          };
        });
      };
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.postcode1]);
  const getAddressDetails = async (id: string) => {
    const response = await getRequest(`${GET_ADDRESS}/${id}`);
    setFormData((prevData) => {
      return {
        ...prevData,
        address1: response?.data?.line_1,
        address2: response?.data?.line_2,
        town: response?.data?.town_or_city,
        county: response?.data?.county,
        postcode: response?.data?.postcode,
        postcode1: response?.data?.postcode,
        longitude: response?.data?.longitude,
        latitude: response?.data?.latitude,
        isPostCodeEntered: true,
      };
    });
    setRequiredFormData((prevData) => {
      return {
        ...prevData,
        address1: false,
        country: false,
        postcode1: false,
        town: false,
      };
    });
  };
  return (
    <>
      {!formData.isPostCodeEntered ? (
        <EmptyContactInfoFormUk
          formData={formData}
          setFormData={setFormData}
          requiredFormData={requiredFormData}
          setRequiredFormData={setRequiredFormData}
        />
      ) : (
        <>
          <div className={styles.postcode_container}>
            <div className={styles.postcode_input_container}>
              <UkAddressSearchInput
                value={formData.postcode}
                setValue={(data, id) => {
                  if (id) {
                    getAddressDetails(id);
                  }
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      postcode: data,
                    };
                  });
                }}
                label="Postcode"
                placeholder="Enter your post code"
                dropdownTop="45px"
                datalistTop="83px"
                emptyOptionText="Search Postcode"
                options={address}
              />
            </div>
            <div className={styles.postcode_button_container}>
              <Button
                isPrimary={true}
                text="Find Address"
                onClick={() => {
                  dispatch(setShowFindAddressDropdown(true));
                }}
                onBlur={() => {
                  setTimeout(() => {
                    dispatch(setShowFindAddressDropdown(false));
                  }, 300);
                }}
                width="200px"
              />
            </div>
          </div>
          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.address1}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, address1: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, address1: false };
                });
              }}
              label="Address Line 1"
              placeholder="Enter Address"
              required={true}
              error={requiredFormData.address1}
              helperText={
                requiredFormData.address1 ? "Address Line 1 is required" : ""
              }
              id="address1"
            />
            <TextInput
              value={formData.address2}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, address2: data };
                })
              }
              label="Address Line 2"
              placeholder="Enter Employee Address"
            />
          </div>

          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.town}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, town: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, town: false };
                });
              }}
              label="Town"
              placeholder="Enter Town"
              required={true}
              error={requiredFormData.town}
              helperText={requiredFormData.town ? "Town is required" : ""}
              id="town"
            />
            <TextInput
              value={formData.county}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, county: data };
                });
              }}
              label="County"
              placeholder="Enter County"
            />
          </div>
          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.postcode1}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, postcode1: data };
                });
                handlePostCodeAutoComplete(data);
                const validateInputPostCode = validatePostCode(data);
                if (!validateInputPostCode && data.length > 0) {
                  setRequiredFormData((prevData) => {
                    return { ...prevData, postcode1: true };
                  });
                  return;
                }
                setRequiredFormData((prevData) => {
                  return { ...prevData, postcode1: false };
                });
              }}
              label="Postcode"
              placeholder="Enter Post Code"
              required={true}
              error={requiredFormData.postcode1}
              helperText={
                requiredFormData.postcode1 ? "Postcode is required" : ""
              }
              id="postcode1"
            />
            <LocationSelectInput
              value={formData.country}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, country: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, country: false };
                });
              }}
              setCode={(code) => {
                setCurrentCountryCode(code);
              }}
              label="Country"
              placeholder="Select Country"
              options={Country.getAllCountries()}
              disabled={true}
              required={true}
              error={requiredFormData.country}
              helperText={requiredFormData.country ? "Country is required" : ""}
              id="country"
              style={{ marginBottom: "8px", marginTop: "5px" }}
            />
          </div>
          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.mobileNumber}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, mobileNumber: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, mobileNumber: false };
                });
              }}
              label="Mobile Number"
              placeholder="Enter Mobile Number"
              required={true}
              error={requiredFormData.mobileNumber}
              helperText={
                requiredFormData.mobileNumber ? "Mobile Number is required" : ""
              }
              id="mobileNumber"
              disabled={true}
            />
            <TextInput
              value={formData.email}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, email: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, email: false };
                });
              }}
              label="Email"
              placeholder="Enter Email"
              required={true}
              error={requiredFormData.email}
              helperText={requiredFormData.email ? "Email is required" : ""}
              right={<img src={emailIcon} alt="icon" width={20} height={20} />}
              id="email"
            />
          </div>
        </>
      )}
    </>
  );
};

export default EmployeeContactInfoFormUk;
