import React from "react";
import styles from "./index.module.css";
import TextInput from "../../../../Form/TextInput";
import { EmployeeHrFormProps } from "../../../../../types-definition/StaffHub/employee";
import { getCountry } from "utils/localstorage";
import { US } from "types-definition/constants";
const EmployeeBankDetailsFormUk: React.FC<EmployeeHrFormProps> = ({
  formData,
  setFormData,
}) => {
  const country = getCountry();
  return (
    <>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.accountName}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, accountName: data };
            })
          }
          label="Account Name"
          placeholder="Enter Account Name"
        />

        <TextInput
          value={formData.bankName}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, bankName: data };
            })
          }
          label="Bank Name"
          placeholder="Enter Bank Name"
        />
      </div>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.accountNumber}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, accountNumber: data };
            })
          }
          label="Account Number"
          placeholder="Enter Account Number"
        />
        {country === US ? (
          <TextInput
            value={formData.routingNumber}
            setValue={(data) =>
              setFormData((prevData) => {
                return { ...prevData, routingNumber: data };
              })
            }
            label="Routing Number"
            placeholder="Enter Routing Number"
          />
        ) : (
          <TextInput
            value={formData.sortCode}
            setValue={(data) =>
              setFormData((prevData) => {
                return { ...prevData, sortCode: data };
              })
            }
            label="Sort Code"
            placeholder="Enter Sort Code"
          />
        )}
      </div>
    </>
  );
};

export default EmployeeBankDetailsFormUk;
