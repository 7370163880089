import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../../Common/PageBackButton";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import { getRequest } from "../../../../../api/request";
import PageLoader from "../../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import { WebUserActivityLog } from "../../../../../types-definition/admin";
import DashboardHeader from "../../../../Common/DashboardHeader";
import { toastError } from "utils/toast";

const ActivityWebUserTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentWebUser, setCurrentWebUser] = useState<WebUserActivityLog>({
    firstName: "--",
    lastName: "--",
    userName: "--",
    position: "--",
    emailAddress: "--",
    telephone: "--",
    password: "--",
    confirmPassword: "--",
    viewableLocations: "--",
    clientPortalUser: false,
    clientName: "--",
    emailConditions: [],
  });
  const [previousWebUser, setPreviousWebUser] = useState<WebUserActivityLog>({
    firstName: "--",
    lastName: "--",
    userName: "--",
    position: "--",
    emailAddress: "--",
    telephone: "--",
    password: "--",
    confirmPassword: "--",
    viewableLocations: "--",
    clientPortalUser: false,
    clientName: "--",
    emailConditions: [],
  });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getEmployeeData = async () => {
      // get employee data
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data.newRecord;
        const previousData = response.data.previousRecord;
        if (response.data.action === "POST") {
          setCurrentWebUser({
            firstName: data?.webUser?.firstName,
            lastName: data?.webUser?.lastName,
            userName: data?.webUser?.username,
            position: data?.webUser?.position,
            emailAddress: data?.webUser?.email,
            telephone: data?.webUser?.phone,
            password: data?.webUser?.password,
            confirmPassword: data?.webUser?.confirmPassword,
            viewableLocations: data?.webUser?.viewableLocations,
            clientName: data?.webUser?.clientName,
            clientPortalUser: data?.webUser?.clientPortalUser,
            emailConditions: data?.webUser?.emailConditions ?? [],
          });
          setPreviousWebUser({
            firstName: previousData?.webUser?.firstName,
            lastName: previousData?.webUser?.lastName,
            userName: previousData?.webUser?.username,
            position: previousData?.webUser?.position,
            emailAddress: previousData?.webUser?.email,
            telephone: previousData?.webUser?.phone,
            password: previousData?.webUser?.password,
            confirmPassword: previousData?.webUser?.confirmPassword,
            viewableLocations: previousData?.webUser?.viewableLocations,
            clientName: previousData?.webUser?.clientName,
            clientPortalUser: previousData?.webUser?.clientPortalUser,
            emailConditions: previousData?.webUser?.emailConditions ?? [],
          });
        } else {
          setCurrentWebUser({
            firstName: data?.webUser?.firstName,
            lastName: data?.webUser?.lastName,
            userName: data?.webUser?.username,
            position: data?.webUser?.position,
            emailAddress: data?.webUser?.email,
            telephone: data?.webUser?.phone,
            password: data?.webUser?.password,
            confirmPassword: data?.webUser?.confirmPassword,
            viewableLocations: data?.webUser?.viewableLocations,
            clientName: data?.webUser?.clientName,
            clientPortalUser: data?.webUser?.clientPortalUser,
            emailConditions: data?.webUser?.emailConditions ?? [],
          });
          setPreviousWebUser({
            firstName: previousData?.webUser?.firstName,
            lastName: previousData?.webUser?.lastName,
            userName: previousData?.webUser?.username,
            position: previousData?.webUser?.position,
            emailAddress: previousData?.webUser?.email,
            telephone: previousData?.webUser?.phone,
            password: previousData?.webUser?.password,
            confirmPassword: previousData?.webUser?.confirmPassword,
            viewableLocations: previousData?.webUser?.viewableLocations,
            clientName: previousData?.webUser?.clientName,
            clientPortalUser: previousData?.webUser?.clientPortalUser,
            emailConditions: previousData?.webUser?.emailConditions ?? [],
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getEmployeeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Web user
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">First Name </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentWebUser.firstName ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Last Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentWebUser?.lastName ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">User Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentWebUser?.userName ?? "--"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Position</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentWebUser.position ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Email Address </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentWebUser.emailAddress ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Telephone</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentWebUser?.telephone ? currentWebUser?.telephone : "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Password </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentWebUser.password ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Viewable Locations </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentWebUser.viewableLocations ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Client Portal User</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentWebUser.clientPortalUser === true && "True"}
                  {currentWebUser.clientPortalUser === undefined && "--"}
                  {currentWebUser.clientPortalUser === false && "False"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Client Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentWebUser.clientName ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Email Conditions</Typography>
                {currentWebUser.emailConditions.length === 0 && (
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    -
                  </Typography>
                )}
                {currentWebUser.emailConditions.map((emailCondition, index) => (
                  <Typography
                    key={index}
                    textAlign="left"
                    textSize="20px"
                    textWeight="600"
                  >
                    {emailCondition}
                  </Typography>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Web user
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">First Name </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousWebUser.firstName ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Last Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousWebUser?.lastName ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">User Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousWebUser?.userName ?? "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Position</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousWebUser.position ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Email Address </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousWebUser.emailAddress ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Telephone</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousWebUser?.telephone
                    ? previousWebUser?.telephone
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Password </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousWebUser.password ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Viewable Locations </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousWebUser.viewableLocations ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Client Portal User</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousWebUser.clientPortalUser === true && "True"}
                  {previousWebUser.clientPortalUser === undefined && "--"}
                  {previousWebUser.clientPortalUser === false && "False"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Client Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousWebUser.clientName ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Email Conditions</Typography>
                {previousWebUser.emailConditions.length === 0 && (
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    --
                  </Typography>
                )}
                {previousWebUser.emailConditions.map(
                  (emailCondition, index) => (
                    <Typography
                      key={index}
                      textAlign="left"
                      textSize="20px"
                      textWeight="600"
                    >
                      {emailCondition}
                    </Typography>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityWebUserTab;
