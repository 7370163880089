import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import cancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getCurrentSelectedId,
  setShowLinkLocationModal,
} from "../../../../redux/slice/admin/adminSlice";
import AvailablePermissionItem from "../../Permission Group/AvailablePermissionItem";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../../api/request";
import { GET_LOCATION_URL, GET_WEB_USER_URL } from "../../../../api/urls";
import AssignedPermissionItem from "../../Permission Group/AssignedPermissionItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toastError } from "../../../../utils/toast";

const LinkWebUserLocationModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(getCurrentSelectedId);
  const [availableLocations, setAvailableLocations] = useState<Array<any>>([]);
  const [assignedLocations, setAssignedLocations] = useState<Array<any>>([]);
  const [monitorDeleteChanges, setMonitorDeleteChanges] = useState(0);

  const handleAssignNewPersmission = async (locationId: string) => {
    const response = await postRequest(
      `settings/web-user/${id}/location/${locationId}`,
    );
    if (response?.success) {
      setAssignedLocations([...response.data.locations]);
      setAvailableLocations((prevData) => {
        return prevData.filter((item) => {
          return response.data.locations.every(
            (i: any) => i.assignedLocationId.toString() !== item.id.toString(),
          );
        });
      });
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };
  const handleUnassignPermission = async (locationId: string) => {
    const response = await deleteRequest(
      `settings/web-user/${id}/location/${locationId}`,
    );
    if (response?.success) {
      await getAllLocations();
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };

  const getUsersLocation = async (data: any) => {
    const response = await getRequest(`${GET_WEB_USER_URL}/${id}`);
    if (response?.success) {
      setAssignedLocations(response?.data.locations);
      setAvailableLocations(
        data.filter((item: any) => {
          return response.data.locations.every(
            (i: any) => i.assignedLocationId.toString() !== item.id.toString(),
          );
        }),
      );
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };

  const getAllLocations = async () => {
    const response = await getRequest(GET_LOCATION_URL);
    if (response?.success) {
      // setAvailableLocations(response?.data);
      await getUsersLocation(response?.data);
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };

  useEffect(() => {
    getAllLocations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Drag and Drop
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }

    if (result.destination.droppableId === "2") {
      const itemIndex = result.draggableId.split("-")[2];
      const data = [...availableLocations].filter(
        (item, index) => index !== parseInt(itemIndex.toString()),
      );
      setAvailableLocations(data);
      handleAssignNewPersmission(result.source.index);
      return;
    }
    if (result.destination.droppableId === "1") {
      const itemIndex = result.draggableId.split("-")[2];
      const data = [...assignedLocations].filter(
        (item, index) => index !== parseInt(itemIndex.toString()),
      );
      setAssignedLocations(data);
      handleUnassignPermission(result.source.index);
      return;
    }
  };
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      dragHandleUsageInstructions="Use the spacebar to lift / drop the draggable item."
    >
      <div className={styles.container}>
        <div className={styles.content_container}>
          <div className={styles.header}>
            <Typography textWeight="600" textSize="18px">
              Link Web User Location
            </Typography>
            <img
              src={cancelIcon}
              alt="cancel icon"
              className={styles.cancel_icon}
              onClick={() =>
                dispatch(setShowLinkLocationModal({ show: false, id: "" }))
              }
            />
          </div>
          <div className={styles.flex_container}>
            <div className={styles.flex_item}>
              <div className={styles.flex_item_header}>
                <Typography textWeight="600" textAlign="left">
                  Available Locations
                </Typography>
              </div>
              <Droppable droppableId="1">
                {(provided) => (
                  <div
                    className={styles.permission_container}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {availableLocations.map((location, index) => (
                      <Draggable
                        draggableId={`draggable-available-${index}`}
                        index={location.id}
                        key={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={styles.draggable_item}
                          >
                            <AvailablePermissionItem
                              key={index}
                              id={location.id}
                              text={location.name}
                              action={async (locationId) => {
                                await handleAssignNewPersmission(locationId);
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className={styles.flex_item}>
              <div className={styles.flex_item_header}>
                <Typography textWeight="600" textAlign="left">
                  Assigned Locations
                </Typography>
              </div>
              <Droppable droppableId="2">
                {(provided) => (
                  <div
                    className={styles.permission_container}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {assignedLocations.map((location, index) => (
                      <Draggable
                        draggableId={`draggable-assinged-${index}`}
                        index={location.id}
                        key={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={styles.draggable_item}
                          >
                            <AssignedPermissionItem
                              key={index}
                              id={location.id}
                              text={location.name}
                              action={async (locationId) => {
                                await handleUnassignPermission(locationId);
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default LinkWebUserLocationModal;
