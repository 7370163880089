import React from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import { EmployeeNavigationState } from "../../../../types-definition/StaffHub/employee";
import colors from "../../../../theme/colors";
import { useNavigate } from "react-router-dom";

const EmployeeNavigation: React.FC<EmployeeNavigationState> = ({ active }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div
        className={styles.container_item_left}
        onClick={() => navigate("/employee/basic-info")}
      >
        <Typography
          textWeight={active === "basic information" ? "600" : "500"}
          fontFamily="inter"
          textColor={
            active === "basic information" ? colors.primary3 : colors.black
          }
        >
          Basic Information
        </Typography>
      </div>
      <div
        className={styles.container_item}
        onClick={() => navigate("/employee/contact-info")}
      >
        <Typography
          textWeight={active === "contact information" ? "600" : "500"}
          fontFamily="inter"
          textColor={
            active === "contact information" ? colors.primary3 : colors.black
          }
        >
          Contact Information
        </Typography>
      </div>
      <div
        className={styles.container_item}
        onClick={() => navigate("/employee/operation")}
      >
        <Typography
          textWeight={active === "operations" ? "600" : "500"}
          fontFamily="inter"
          textColor={active === "operations" ? colors.primary3 : colors.black}
        >
          Operations
        </Typography>
      </div>
      <div
        className={styles.container_item}
        onClick={() => navigate("/employee/hr")}
      >
        <Typography
          textWeight={active === "hr" ? "600" : "500"}
          fontFamily="inter"
          textColor={active === "hr" ? colors.primary3 : colors.black}
        >
          HR
        </Typography>
      </div>
      <div
        className={styles.container_item}
        onClick={() => navigate("/employee/emergency-contact")}
      >
        <Typography
          textWeight={active === "emergency contact" ? "600" : "500"}
          fontFamily="inter"
          textColor={
            active === "emergency contact" ? colors.primary3 : colors.black
          }
        >
          Emergency Contact
        </Typography>
      </div>
      <div
        className={styles.container_item_right}
        onClick={() => navigate("/employee/medical-details")}
      >
        <Typography
          textWeight={active === "medical details" ? "600" : "500"}
          fontFamily="inter"
          textColor={
            active === "medical details" ? colors.primary3 : colors.black
          }
        >
          Medical Details
        </Typography>
      </div>
    </div>
  );
};

export default EmployeeNavigation;
