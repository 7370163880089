import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Typography from "components/Common/Typography";
import size from "theme/size";
import PasswordInput from "components/Form/PasswordInput";
import styles from "./index.module.css";
import Button from "components/Form/Button";
import colors from "theme/colors";
import glixLogo from "assets/svg/glix-logo.svg";
import ResetPasswordSuccessModal from "components/Auth/ResetPasswordSuccessModal";
import { postRequest } from "api/request";
import { RESET_PASSWORD_URL } from "api/urls";
import toast from "react-hot-toast";

const ResetPassword: React.FC = () => {
  const params = useParams();
  const [formData, setFormData] = useState({
    password: "",
    repeat_password: "",
  });
  const [requestLoading, setRequestLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const handleFormSubmission = async () => {
    setRequestLoading(true);
    const response = await postRequest(
      `${RESET_PASSWORD_URL}?token=${params.token}`,
      formData,
    );
    if (response?.success) {
      setShowSuccess(true);
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };

  return (
    <>
      <main>
        <section className={styles.container}>
          <div className={styles.body_container}>
            <div className={styles.image}>
              <img src={glixLogo} alt="logo" width={56} height={56} />
            </div>
            <div>
              <Typography
                textAlign="center"
                textSize={size.lg}
                textWeight="600"
              >
                Reset Password
              </Typography>
              <div className={styles.descriptiontext_container}>
                <Typography
                  textAlign="left"
                  textSize={size.md}
                  textColor={colors.gray1}
                  style={{ width: "350px" }}
                >
                  To reset your password, please enter your new password below.
                  Make sure to choose a strong and unique password to keep your
                  account secure.
                </Typography>
              </div>
            </div>
            <div className={styles.form_container}>
              <PasswordInput
                value={formData.password}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      password: data,
                    };
                  })
                }
                label="New Password"
                placeholder="Enter Password"
                useValidation
              />
              <PasswordInput
                value={formData.repeat_password}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      repeat_password: data,
                    };
                  })
                }
                label="Confirm Password"
                placeholder="Re-enter Password"
              />
              <Button
                text="Reset Password"
                isPrimary={true}
                isLoading={requestLoading}
                onClick={() => {
                  handleFormSubmission();
                  // setShowSuccess(true);
                }}
              />
            </div>
          </div>
        </section>
      </main>
      {showSuccess && <ResetPasswordSuccessModal />}
    </>
  );
};

export default ResetPassword;
