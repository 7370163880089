import React from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import ActivityEmployeeOperations from "components/Admin/Activity Logs/EmployeeActivityLogs/ActivityEmployeeOperations";

const ActivityEmployeeOperationsPage = () => {
  return (
    <div className={styles.main_container}>
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      <ActivityEmployeeOperations />
    </div>
  );
};

export default ActivityEmployeeOperationsPage;
