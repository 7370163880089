import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import primaryCalendarIcon from "../../../../../assets/svg/primary-calendar-icon.svg";
import arrowRightIcon from "../../../../../assets/svg/arrow-right-icon.svg";
import SingleDateItem from "../../../../Common/SingleDateItem";
import { getRequest } from "../../../../../api/request";
import filterKeysFromArray from "../../../../../utils/filterKeyFromArray";
import LoadingIcons from "react-loading-icons";
// import stringToDate from "../../../../../utils/formatDate";
import { EmployeeHolidayHistoryProps } from "../../../../../types-definition/StaffHub/employee";

const EmployeeHolidayHistory: React.FC<EmployeeHolidayHistoryProps> = ({
  holiday,
}) => {
  // const allHolidays = useAppSelector(getEmployeeAbsenceHolidays);
  const [isLoading, setIsLoading] = useState(true);
  const [segmentHolidays, setSegmentHolidays] = useState<Array<any>>([]);

  useEffect(() => {
    setIsLoading(true);
    const segmentIds = filterKeysFromArray(holiday, "segmentId");
    const data: Array<any> = [];
    const getHolidaySegmentDetails = async () => {
      for (const segment in segmentIds) {
        const response = await getRequest(
          `employee/holiday/segment/${segmentIds[segment]}`,
        );
        if (response.data.length > 1) {
          data.push({
            fromDate: response.data[0].date,
            toDate: response.data[response.data.length - 1].date,
            holidayType: response.data[0].holidayType,
            isError:
              response.data[0].holidayType === "AWOL" ||
              response.data[0].holidayType === "NO_SHOW"
                ? true
                : false, // added this line to check if the holiday type is AWOL or not,
            modifiedBy: response.data[0].modifiedBy,
          });
        } else {
          data.push({
            fromDate: response.data[0].date,
            holidayType: response.data[0].holidayType,
            isError:
              response.data[0].holidayType === "AWOL" ||
              response.data[0].holidayType === "NO_SHOW"
                ? true
                : false,

            modifiedBy: response.data[0].modifiedBy,
          });
        }
      }
      setSegmentHolidays(
        data.sort(
          (a, b) =>
            new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime(),
        ),
      );
      setIsLoading(false);
    };
    getHolidaySegmentDetails();
  }, [holiday]);
  return (
    <div className={styles.holiday_history_container}>
      <img src={primaryCalendarIcon} alt="calendar-icon" />
      <Typography textWeight="600" textAlign="left">
        Past Time Off
      </Typography>
      <Typography textWeight="500" textAlign="left" textColor={colors.gray1}>
        This is your time off history. Past absences can’t be edited.
      </Typography>
      <div className={styles.holiday_history_item}>
        {isLoading && (
          <div style={{ textAlign: "center" }}>
            <LoadingIcons.TailSpin
              width={50}
              height={50}
              stroke={colors.primary3}
              style={{ marginLeft: "10px" }}
            />
          </div>
        )}
        {!isLoading &&
          segmentHolidays.map((data, index) => {
            return data.toDate ? (
              <div
                className={styles.holiday_history_item_container}
                key={index}
              >
                <SingleDateItem date={data.fromDate} isError={data.isError} />
                <img src={arrowRightIcon} alt="arrow-right" />
                <SingleDateItem date={data.toDate} isError={data.isError} />
                <div>
                  <Typography textWeight="600" textAlign="left">
                    {data.holidayType}
                  </Typography>
                  <Typography textAlign="left">
                    {new Date(data.toDate).getDate() -
                      new Date(data.fromDate).getDate() +
                      1}{" "}
                    Days {`(${new Date(data.fromDate).getFullYear()})`}
                  </Typography>
                  <Typography textSize="8px" textAlign="left">
                    Allocated by: {data?.modifiedBy}
                  </Typography>
                </div>
              </div>
            ) : (
              <div
                className={styles.holiday_history_item_half_container}
                key={index}
              >
                <SingleDateItem date={data.fromDate} isError={data.isError} />
                <div className={styles.holiday_halfday_item}>
                  <Typography textWeight="600" textAlign="left">
                    {data.holidayType}
                  </Typography>
                  <Typography textAlign="left">
                    Half Day {new Date(data.fromDate).getFullYear()}
                  </Typography>
                  <Typography textSize="10px" textAlign="left">
                    Allocated by: {data?.modifiedBy}
                  </Typography>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EmployeeHolidayHistory;
