import React, { useRef, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import filterIcon from "../../../../assets/svg/filter-icon.svg";
import chevronDownIcon from "../../../../assets/svg/chevron-down.svg";
import colors from "../../../../theme/colors";
import { ServiceFilterProps } from "types-definition/client";

const ServiceTableFilter: React.FC<ServiceFilterProps> = ({
  clearFilter,
  runFilter,
}) => {
  const [currentFilterOption, setcurrentFilterOption] = useState("Filter");
  const [showDropDown, setShowDropDown] = useState(false);
  const [showSubDropDown, setShowSubDropDown] = useState(true);
  const [filterOptions, setFilterOptions] = useState("");
  const FILTEROPTIONS = {
    NAME: { name: "Name", top: "70px" },
    GRADE: { name: "Grade", top: "120px" },
    FROM: { name: "From", top: "180px" },
    TO: { name: "To", top: "230px" },
    WORK_HOURS: { name: "Work Hours", top: "260px" },
    EMPLOYEE: { name: "Employee", top: "300px" },
  };
  const hideAllDropDown = () => {
    // containerRef.current?.blur();
    setShowDropDown(false);
    setShowSubDropDown(false);
  };
  const clearFilterData = () => {
    setcurrentFilterOption("Filter");
  };
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.main}>
      <div
        className={styles.main_container}
        onFocus={() => {
          setShowDropDown(true);
          setShowSubDropDown(true);
        }}
        ref={containerRef}
        onClick={(e) => {
          // e.preventDefault();
          // containerRef.current?
          if (document.activeElement === containerRef.current) {
            containerRef.current?.focus();
          } else {
            containerRef.current?.blur();
            // containerRef.current?.focus();
          }
        }}
        tabIndex={0}
        onBlur={(e) => {
          const event = e;
          requestAnimationFrame(() => {
            if (!event.currentTarget?.contains(document.activeElement)) {
              setShowDropDown(false);
              setShowSubDropDown(false);
            }
          });
        }}
        // tabIndex={1}
      >
        <div className={styles.container}>
          <img src={filterIcon} alt="filter icon" />
          <Typography textColor={colors.gray1}>
            {currentFilterOption}
          </Typography>
        </div>
        {showDropDown && (
          <div className={styles.dropdown_container}>
            <div
              className={`${
                FILTEROPTIONS.NAME.name === filterOptions
                  ? `${styles.sub_dropdown_item} ${styles.sub_dropdown_item_active}`
                  : styles.sub_dropdown_item
              }`}
              onClick={() => {
                setFilterOptions(FILTEROPTIONS.NAME.name);
                setShowSubDropDown(true);
              }}
            >
              <Typography>Name</Typography>
              <img
                src={chevronDownIcon}
                alt="chevron-down"
                className={styles.dropdown_image_item}
              />
            </div>
            <div
              className={`${
                FILTEROPTIONS.GRADE.name === filterOptions
                  ? `${styles.sub_dropdown_item} ${styles.sub_dropdown_item_active}`
                  : styles.sub_dropdown_item
              }`}
              onClick={() => {
                setFilterOptions(FILTEROPTIONS.GRADE.name);
                setShowSubDropDown(true);
              }}
            >
              <Typography>Grade</Typography>
              <img
                src={chevronDownIcon}
                alt="chevron-down"
                className={styles.dropdown_image_item}
              />
            </div>
            <div
              className={`${
                FILTEROPTIONS.FROM.name === filterOptions
                  ? `${styles.sub_dropdown_item} ${styles.sub_dropdown_item_active}`
                  : styles.sub_dropdown_item
              }`}
              onClick={() => {
                setFilterOptions(FILTEROPTIONS.FROM.name);
                setShowSubDropDown(true);
              }}
            >
              <Typography>From</Typography>
              <img
                src={chevronDownIcon}
                alt="chevron-down"
                className={styles.dropdown_image_item}
              />
            </div>
            <div
              className={`${
                FILTEROPTIONS.TO.name === filterOptions
                  ? `${styles.sub_dropdown_item} ${styles.sub_dropdown_item_active}`
                  : styles.sub_dropdown_item
              }`}
              onClick={() => {
                setFilterOptions(FILTEROPTIONS.TO.name);
                setShowSubDropDown(true);
              }}
            >
              <Typography>To</Typography>
              <img
                src={chevronDownIcon}
                alt="chevron-down"
                className={styles.dropdown_image_item}
              />
            </div>
            <div
              className={`${
                FILTEROPTIONS.WORK_HOURS.name === filterOptions
                  ? `${styles.sub_dropdown_item} ${styles.sub_dropdown_item_active}`
                  : styles.sub_dropdown_item
              }`}
              onClick={() => {
                setFilterOptions(FILTEROPTIONS.WORK_HOURS.name);
                setShowSubDropDown(true);
              }}
            >
              <Typography>Work hours</Typography>
              <img
                src={chevronDownIcon}
                alt="chevron-down"
                className={styles.dropdown_image_item}
              />
            </div>
            <div
              className={`${
                FILTEROPTIONS.EMPLOYEE.name === filterOptions
                  ? `${styles.sub_dropdown_item} ${styles.sub_dropdown_item_active}`
                  : styles.sub_dropdown_item
              }`}
              onClick={() => {
                setFilterOptions(FILTEROPTIONS.EMPLOYEE.name);
                setShowSubDropDown(true);
              }}
            >
              <Typography>No of Employee</Typography>
              <img
                src={chevronDownIcon}
                alt="chevron-down"
                className={styles.dropdown_image_item}
              />
            </div>
          </div>
        )}
        {showSubDropDown && (
          <div>
            {filterOptions === FILTEROPTIONS.NAME.name && (
              <div
                className={styles.sub_dropdown_container}
                style={{ top: FILTEROPTIONS.NAME.top }}
              >
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Ascending Order");
                    runFilter("Ascending Order", "Name");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Ascending Order</Typography>
                </div>
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Descending Order");
                    runFilter("Descending Order", "Name");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Descending Order</Typography>
                </div>
              </div>
            )}
            {filterOptions === FILTEROPTIONS.GRADE.name && (
              <div
                className={styles.sub_dropdown_container}
                style={{ top: FILTEROPTIONS.GRADE.top }}
              >
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Ascending Order");
                    runFilter("Ascending Order", "Grade");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Ascending Order</Typography>
                </div>
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Descending Order");
                    runFilter("Descending Order", "Grade");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Descending Order</Typography>
                </div>
              </div>
            )}
            {filterOptions === FILTEROPTIONS.FROM.name && (
              <div
                className={styles.sub_dropdown_container}
                style={{ top: FILTEROPTIONS.FROM.top }}
              >
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Ascending Order");
                    runFilter("Ascending Order", "From");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Ascending Order</Typography>
                </div>
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Descending Order");
                    runFilter("Descending Order", "From");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Descending Order</Typography>
                </div>
              </div>
            )}
            {filterOptions === FILTEROPTIONS.TO.name && (
              <div
                className={styles.sub_dropdown_container}
                style={{ top: FILTEROPTIONS.TO.top }}
              >
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Ascending Order");
                    runFilter("Ascending Order", "To");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Ascending Order</Typography>
                </div>
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Descending Order");
                    runFilter("Descending Order", "To");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Descending Order</Typography>
                </div>
              </div>
            )}
            {filterOptions === FILTEROPTIONS.WORK_HOURS.name && (
              <div
                className={styles.sub_dropdown_container}
                style={{ top: FILTEROPTIONS.WORK_HOURS.top }}
              >
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Ascending Order");
                    runFilter("Ascending Order", "Work Hours");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Ascending Order</Typography>
                </div>
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Descending Order");
                    runFilter("Descending Order", "Work Hours");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Descending Order</Typography>
                </div>
              </div>
            )}
            {filterOptions === FILTEROPTIONS.EMPLOYEE.name && (
              <div
                className={styles.sub_dropdown_container}
                style={{ top: FILTEROPTIONS.EMPLOYEE.top }}
              >
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Ascending Order");
                    runFilter("Ascending Order", "No of Employee");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Ascending Order</Typography>
                </div>
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Descending Order");
                    runFilter("Descending Order", "No of Employee");
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Descending Order</Typography>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {currentFilterOption !== "Filter" && (
        <div
          className={styles.clear_filter}
          onClick={() => {
            clearFilter();
            clearFilterData();
          }}
        >
          <Typography
            textColor={colors.primary3}
            textWeight="600"
            textSize="12px"
          >
            Clear Filters
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ServiceTableFilter;
