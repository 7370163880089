import React from "react";
import { LinkedEmployeesToLocationTableData } from "types-definition/client/linkToLocation";
import colors from "../../../../../theme/colors";
import Typography from "../../../../Common/Typography";
import LinkedEmployeesToLocationTableItem from "../LinkedEmployeesToLocationTableItem";
import styles from "./index.module.css";

const LinkedEmployeesToLocationTable: React.FC<
  LinkedEmployeesToLocationTableData
> = ({ data }) => {
  return (
    <>
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.tr} ${styles.tr_head}`}>
              <th className={styles.th} style={{ width: "15%" }}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="500"
                  marginHorizontal="24px"
                  textSize="12px"
                >
                  Employee Name
                </Typography>
              </th>
              <th className={styles.th} style={{ width: "15%" }}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="500"
                  marginHorizontal="24px"
                  textSize="12px"
                >
                  Grade
                </Typography>
              </th>
              <th className={styles.th} style={{ width: "15%" }}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="500"
                  textColor={colors.tableText}
                  marginHorizontal="24px"
                  textSize="12px"
                >
                  Distance (miles)
                </Typography>
              </th>
              <th className={styles.th} style={{ width: "15%" }}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="500"
                  textColor={colors.tableText}
                  marginHorizontal="24px"
                  textSize="12px"
                >
                  Pay
                </Typography>
              </th>
              <th className={styles.th} style={{ width: "15%" }}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="500"
                  textColor={colors.tableText}
                  marginHorizontal="24px"
                  textSize="12px"
                >
                  Assign
                </Typography>
              </th>
              <th className={styles.th_restriction} style={{ width: "15%" }}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="500"
                  textColor={colors.tableText}
                  marginHorizontal="24px"
                  textSize="12px"
                >
                  Restrictions
                </Typography>
              </th>
              <th className={styles.th} style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <LinkedEmployeesToLocationTableItem {...item} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LinkedEmployeesToLocationTable;
