import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import {
  EmployeeBasicInformationFormData,
  EmployeeBasicInformationRequiredFormData,
  EmployeeIdentificationRequiredBirthCertificateFormDataUkCitizen,
  EmployeeIdentificationRequiredFormDataOthersUkCitizen,
  EmployeeIdentificationRequiredFormDataUkCitizen,
  EmployeeIdentificationRequiredFormDataUkNonCitizen,
} from "../../../types-definition/StaffHub/employee";
import generatePassword from "../../../utils/generatePassword";
interface EmployeeBasicInformation {
  formData: EmployeeBasicInformationFormData;
  requiredFormData: EmployeeBasicInformationRequiredFormData;
  requiredBirthCertificateIdentificationFormDataUk: EmployeeIdentificationRequiredBirthCertificateFormDataUkCitizen;
  requiredIdentificationFormDataUk: EmployeeIdentificationRequiredFormDataUkCitizen;
  requiredIdentificationFormDataNonUk: EmployeeIdentificationRequiredFormDataUkNonCitizen;
  requiredIdentificationFormDataOthersNonUk: EmployeeIdentificationRequiredFormDataOthersUkCitizen;
}

const initialState: EmployeeBasicInformation = {
  formData: {
    usernameSavedInDB: false,
    firstName: "",
    lastName: "",
    username: "",
    password: generatePassword(8),
    gender: "",
    nationality: "",
    dob: "",
    startDate: "",
    endDate: "12/31/2034",
    portalAccess: false,
    active: false,
    photo: "",
    proofOfId: "",
    documentType: "None",
    documentName: "",
    documentNumber: "",
    ukCitizen: true,
    countryOfCitizenship: "",
    issueDate: "",
    expiryDate: "",
    placeOfIssue: "",
    visaType: "",
    visaNumber: "",
    visaStartDate: "",
    visaExpiryDate: "",
    email: "",
    mobileNumber: "",
  },

  requiredFormData: {
    firstName: false,
    lastName: false,
    username: false,
    password: false,
    gender: false,
    nationality: false,
    startDate: false,
    email: false,
    mobileNumber: false,
  },
  requiredBirthCertificateIdentificationFormDataUk: {
    documentType: false,
    documentNumber: false,
    issueDate: false,
  },
  requiredIdentificationFormDataUk: {
    documentType: false,
    documentNumber: false,
    issueDate: false,
    expiryDate: false,
  },
  requiredIdentificationFormDataNonUk: {
    documentType: false,
    documentNumber: false,
    issueDate: false,
    expiryDate: false,
    visaType: false,
    visaNumber: false,
    visaStartDate: false,
    visaExpiryDate: false,
  },
  requiredIdentificationFormDataOthersNonUk: {
    documentName: false,
    documentNumber: false,
    issueDate: false,
    expiryDate: false,
  },
};
const employeeBasicInformation = createSlice({
  name: "employeeBasicInformation",
  initialState,
  reducers: {
    setBasicInformationFormData: (
      state,
      action: PayloadAction<EmployeeBasicInformationFormData>,
    ) => {
      state.formData = action.payload;
    },
    resetBasicInformationFormData: (state) => {
      state.formData = initialState.formData;
    },
    setBasicInformationRequiredFormData: (
      state,
      action: PayloadAction<EmployeeBasicInformationRequiredFormData>,
    ) => {
      state.requiredFormData = action.payload;
    },
    setBasicInformationRequiredIdentificationFormDataBirthCertificateUk: (
      state,
      action: PayloadAction<EmployeeIdentificationRequiredBirthCertificateFormDataUkCitizen>,
    ) => {
      state.requiredBirthCertificateIdentificationFormDataUk = action.payload;
    },
    setBasicInformationRequiredIdentificationFormDataUk: (
      state,
      action: PayloadAction<EmployeeIdentificationRequiredFormDataUkCitizen>,
    ) => {
      state.requiredIdentificationFormDataUk = action.payload;
    },
    setBasicInformationRequiredIdentificationFormDataNonUk: (
      state,
      action: PayloadAction<EmployeeIdentificationRequiredFormDataUkNonCitizen>,
    ) => {
      state.requiredIdentificationFormDataNonUk = action.payload;
    },
    setBasicInformationRequiredIdentificationFormDataOthersNonUk: (
      state,
      action: PayloadAction<EmployeeIdentificationRequiredFormDataOthersUkCitizen>,
    ) => {
      state.requiredIdentificationFormDataOthersNonUk = action.payload;
    },
    resetAllBasicInformationFormData: (state) => {
      state.formData = initialState.formData;
      state.requiredFormData = initialState.requiredFormData;
      state.requiredBirthCertificateIdentificationFormDataUk =
        initialState.requiredBirthCertificateIdentificationFormDataUk;
      state.requiredIdentificationFormDataUk =
        initialState.requiredIdentificationFormDataUk;
      state.requiredIdentificationFormDataNonUk =
        initialState.requiredIdentificationFormDataNonUk;
      state.requiredIdentificationFormDataOthersNonUk =
        initialState.requiredIdentificationFormDataOthersNonUk;
    },
  },
});
export const {
  setBasicInformationFormData,
  resetBasicInformationFormData,
  setBasicInformationRequiredIdentificationFormDataBirthCertificateUk,
  setBasicInformationRequiredFormData,
  setBasicInformationRequiredIdentificationFormDataNonUk,
  setBasicInformationRequiredIdentificationFormDataOthersNonUk,
  setBasicInformationRequiredIdentificationFormDataUk,
  resetAllBasicInformationFormData,
} = employeeBasicInformation.actions;

export const basicInformationFormData = (state: RootState) =>
  state.employeeBasicInfo.formData;
export const basicInformationRequiredFormData = (state: RootState) =>
  state.employeeBasicInfo.requiredFormData;
export const basicInformationRequiredIdentificationFormDataBirthCertificateUk =
  (state: RootState) =>
    state.employeeBasicInfo.requiredBirthCertificateIdentificationFormDataUk;
export const basicInformationRequiredIdentificationFormDataUk = (
  state: RootState,
) => state.employeeBasicInfo.requiredIdentificationFormDataUk;
export const basicInformationIdentificationFormDataNonUk = (state: RootState) =>
  state.employeeBasicInfo.requiredIdentificationFormDataNonUk;
export const basicInformationRequiredIdentificationFormDataOthersNonUk = (
  state: RootState,
) => state.employeeBasicInfo.requiredIdentificationFormDataOthersNonUk;

export default employeeBasicInformation.reducer;
