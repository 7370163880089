import React, { useEffect, useState } from "react";
import Button from "components/Form/Button";
import Typography from "components/Common/Typography";
import AdminSideBar from "components/Admin/SideBar";
import PageLoader from "components/Common/PageLoader";
import SettingsNavigation from "components/Admin/Settings/SettingsNavigation";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import colors from "theme/colors";
import validator from "utils/validateFields";
import {
  AdminSettingHolidaySettingsFormData,
  AdminSettingHolidaySettingsRequiredFormData,
} from "types-definition/admin";
import HolidaySettingsForm from "components/Admin/Settings/HolidaySettingsForm";
import toast from "react-hot-toast";
import { postRequest, getRequest, putRequest } from "api/request";
import { GET_USER_ADMIN_SETTINGS_URL, GET_USER_SETTINGS_URL } from "api/urls";
import { useNavigate } from "react-router-dom";
import scrolltoView from "utils/scrollToView";
import { ADMIN } from "types-definition/constants";
import { getUserTypes } from "utils/localstorage";

const HolidaySettings = () => {
  const navigate = useNavigate();
  const user = getUserTypes();
  const [settingsId, setSettingsId] = useState<string>("");
  const [holidaysettingsId, setHolidaySettingsId] = useState<string>("");
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [requestLoading, setRequestloading] = useState<boolean>(false);
  const [formData, setFormData] = useState<AdminSettingHolidaySettingsFormData>(
    {
      holidayYearStart: "",
      holidayEntitlement: "",
      historicWeeksForPayCalculations: "",
      minimumNoticeForHolidayRequest: "",
      maximumHolidayDuration: "",
      defaultHolidayAccrualPercent: "",
      exceedRemainingHolidayAllowance: false,
      hideHolidayEntitlementForAppPortal: false,
      bankHolidayShiftAutoCancel: false,
      leave: false,
      suspended: false,
      maternity: false,
      sickness: false,
      AWOL: false,
      unpaidLeave: false,
      paternity: false,
      COVID: false,
      compassionate: false,
      accruedWhenAbsent: [],
    },
  );

  const [requiredFormData, setRequiredFormData] =
    useState<AdminSettingHolidaySettingsRequiredFormData>({
      holidayYearStart: false,
      holidayEntitlement: false,
      historicWeeksForPayCalculations: false,
      minimumNoticeForHolidayRequest: false,
      maximumHolidayDuration: false,
      defaultHolidayAccrualPercent: false,
    });
  const handleFormCancelation = async () => {
    navigate("/admin/settings/time-check");
  };
  const handleSubmitForm = async () => {
    const { status, data, id } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      if (id) {
        scrolltoView(id);
      }
      toast.error("Complete all required fields before saving", {
        duration: 4000,
        position: "top-center",
      });
      return;
    } else {
      setRequestloading(true);
      const response = holidaysettingsId
        ? await putRequest(
            `${GET_USER_SETTINGS_URL}/holiday-settings/${holidaysettingsId}`,
            {
              holidayYearStart: formData.holidayYearStart,
              holidayEntitlement: formData.holidayEntitlement,
              historicWeeks: formData.historicWeeksForPayCalculations,
              minDayNotice: formData.minimumNoticeForHolidayRequest,
              maxHolidayDuration: formData.maximumHolidayDuration,
              defaultHolidayAccrualPercent:
                formData.defaultHolidayAccrualPercent,
              exceedRemainingAllowance:
                formData.exceedRemainingHolidayAllowance,
              hideHolidayEntitlement:
                formData.hideHolidayEntitlementForAppPortal,
              bankHolidayShiftAutoCancel: formData.bankHolidayShiftAutoCancel,
              accruedWhenAbsent: formData.accruedWhenAbsent,
            },
          )
        : await postRequest(
            `${GET_USER_SETTINGS_URL}/${settingsId}/holiday-settings`,
            {
              holidayYearStart: formData.holidayYearStart,
              holidayEntitlement: formData.holidayEntitlement,
              historicWeeks: formData.historicWeeksForPayCalculations,
              minDayNotice: formData.minimumNoticeForHolidayRequest,
              maxHolidayDuration: formData.maximumHolidayDuration,
              defaultHolidayAccrualPercent:
                formData.defaultHolidayAccrualPercent,
              exceedRemainingAllowance:
                formData.exceedRemainingHolidayAllowance,
              hideHolidayEntitlement:
                formData.hideHolidayEntitlementForAppPortal,
              bankHolidayShiftAutoCancel: formData.bankHolidayShiftAutoCancel,
              accruedWhenAbsent: formData.accruedWhenAbsent,
            },
          );
      if (response?.success) {
        toast.success("Holiday settings updated successfully", {
          duration: 4000,
          position: "top-center",
        });
        navigate("/admin/settings/shift-generation");
      } else {
        toast.error(response.data, {
          duration: 4000,
          position: "top-center",
        });
      setRequestloading(true);
        return;
      }
      setRequestloading(false);
    }
  };

  const checkIfItemExistInArray = (arr: Array<string>, item: string) => {
    const itemExist = arr.filter((arrayItem) => {
      if (arrayItem === item) {
        return true;
      } else {
        return false;
      }
    });
    return itemExist.length > 0 ? true : false;
  };

  useEffect(() => {
    const getHolidaySettings = async () => {
      // setIsloading(true);
      try {
        let response: any;
        if (user === ADMIN) {
          response = await getRequest(GET_USER_SETTINGS_URL);
        } else {
          response = await getRequest(GET_USER_ADMIN_SETTINGS_URL);
        }
        if (response?.success) {
          setSettingsId(response.data?.id);
          setHolidaySettingsId(response.data?.holidaySetting?.id);
          if (response.data.holidaySetting === null) {
            setIsloading(false);
            return;
          } else {
            setFormData({
              holidayYearStart: response.data.holidaySetting.holidayYearStart,
              holidayEntitlement:
                response.data.holidaySetting.holidayEntitlement,
              historicWeeksForPayCalculations:
                response.data.holidaySetting.historicWeeks,
              minimumNoticeForHolidayRequest:
                response.data.holidaySetting.minDayNotice,
              maximumHolidayDuration:
                response.data.holidaySetting.maxHolidayDuration,
              defaultHolidayAccrualPercent:
                response.data.holidaySetting.defaultHolidayAccrualPercent,
              exceedRemainingHolidayAllowance:
                response.data.holidaySetting.exceedRemainingAllowance,
              hideHolidayEntitlementForAppPortal:
                response.data.holidaySetting.hideHolidayEntitlement,
              bankHolidayShiftAutoCancel:
                response.data.holidaySetting.bankHolidayShiftAutoCancel,
              leave: checkIfItemExistInArray(
                response.data.holidaySetting.accruedWhenAbsent,
                "Leave",
              ),
              suspended: checkIfItemExistInArray(
                response.data.holidaySetting.accruedWhenAbsent,
                "Suspended",
              ),
              maternity: checkIfItemExistInArray(
                response.data.holidaySetting.accruedWhenAbsent,
                "Maternity",
              ),
              sickness: checkIfItemExistInArray(
                response.data.holidaySetting.accruedWhenAbsent,
                "Sickness",
              ),
              AWOL: checkIfItemExistInArray(
                response.data.holidaySetting.accruedWhenAbsent,
                "AWOL",
              ),
              unpaidLeave: checkIfItemExistInArray(
                response.data.holidaySetting.accruedWhenAbsent,
                "UnpaidLeave",
              ),
              paternity: checkIfItemExistInArray(
                response.data.holidaySetting.accruedWhenAbsent,
                "Paternity",
              ),
              COVID: checkIfItemExistInArray(
                response.data.holidaySetting.accruedWhenAbsent,
                "COVID",
              ),
              compassionate: checkIfItemExistInArray(
                response.data.holidaySetting.accruedWhenAbsent,
                "Compassionate",
              ),
              accruedWhenAbsent: response.data.holidaySetting.accruedWhenAbsent,
            });
            setIsloading(false);
          }
        } else {
          setIsloading(false);
          toast.error(response.data, {
            duration: 4000,
            position: "top-center",
          });
          return;
        }
      } catch (error) {
        setIsloading(false);
      }
    };

    getHolidaySettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <AdminSideBar active="settings" />
        <div className={styles.item_container}>
          <SettingsNavigation active="holiday" />
          <div className={styles.content_container}>
            <div className={styles.header_flex_item}>
              <Typography textAlign="left" textWeight="600" textSize="18px">
                Holiday
              </Typography>
            </div>

            <HolidaySettingsForm
              formData={formData}
              setFormData={setFormData}
              setRequiredFormData={setRequiredFormData}
              requiredFormData={requiredFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Back"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={false}
                borderColor={colors.primary3}
                borderWidth="2px"
                textColor={colors.primary3}
                textWeight="600"
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HolidaySettings;
