import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { setReduxCheckcallId } from "redux/slice/report/checkcallLogSlice";
import colors from "theme/colors";
import { CheckcallReportData } from "types-definition/report";
import getNumberPosition from "utils/getNumberPosition";
import { setCheckcallId } from "utils/localstorage";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";

const CheckcallReportTableItem: React.FC<CheckcallReportData> = ({
  id,
  employee,
  attempt,
  location,
  service,
  date,
  answer,
  status,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const statusColor =
    status === "ANSWERED" && answer === "Yes"
      ? colors.successtext
      : (status === "ANSWERED" && answer === "No") || status === "FAILED"
        ? colors.error
        : status === "MISSED"
          ? colors.gray1
          : colors.primary3;

  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        <Typography
          marginHorizontal={"2rem"}
          textWeight="600"
          textSize="12px"
          textColor={statusColor}
          textAlign="left"
        >
          {status}
        </Typography>
      </td>
      <td className={styles.td_address}>
        <Typography textAlign="left">{location.name}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{location.managedBy}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{service.name}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {getNumberPosition(parseInt(attempt))}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {moment(date).format("h:mm A")}
        </Typography>
      </td>
      <td className={styles.td}>
        <div
          onClick={() => {
            dispatch(setReduxCheckcallId(id!));
            setCheckcallId(id!);
            navigate("/reports/checkcall-log/attempts");
          }}
          className={styles.view__badge}
        >
          View Details
        </div>
      </td>
    </tr>
  );
};

export default CheckcallReportTableItem;
