import React from "react";
import styles from "./index.module.css";
import CircularName from "../../../../Common/CircularName";
import Typography from "../../../../Common/Typography";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { MessageEmployeeProps } from "../../../../../types-definition/StaffHub/employee";
import messageIcon from "../../../../../assets/svg/message-icon.svg";
import {
  getSelectedEmployees,
  setEmployeeMessageIndividualId,
  setMessageEmployeeEntryMode,
  setSelectedEmployees,
  setShowSelectDeliveryModeModal,
} from "../../../../../redux/slice/staff-hub/employeeMessageSlice";
import moment from "moment";

const MessageEmployeeTableItem: React.FC<MessageEmployeeProps> = ({
  id,
  name,
  grade,
  displayId,
  lastSeen,
}) => {
  const selectedEmployees = useAppSelector(getSelectedEmployees);
  const dispatch = useAppDispatch();

  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={selectedEmployees.includes(id) ? true : false}
          onChange={() => {
            dispatch(setSelectedEmployees(id));
          }}
        />
        <CircularName name={`${name} ${name}`} />
        <Typography textAlign="left" textWeight="600" textColor="#148A9B">
          {name}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {displayId ? displayId : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{grade ?? "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {lastSeen ? moment(new Date(lastSeen)).format("HH:mm") : "N/A"}
        </Typography>
      </td>
      <td className={`${styles.td} ${styles.td_relative}`}>
        <div
          className={styles.image_container}
          onClick={() => {
            dispatch(setMessageEmployeeEntryMode("individual"));
            dispatch(setEmployeeMessageIndividualId(id));
            dispatch(setShowSelectDeliveryModeModal(true));
          }}
        >
          <img src={messageIcon} alt="message-icon" />
        </div>
      </td>
    </tr>
  );
};

export default MessageEmployeeTableItem;
