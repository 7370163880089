import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { ActiveStateProps } from "../../../../types-definition/common";

const ClientState: React.FC<ActiveStateProps> = ({ active }) => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: active
          ? colors.clientActiveBg
          : colors.clientInactiveBg,
      }}
    >
      <Typography
        textWeight="600"
        textColor={active ? colors.clientActive : colors.clientInactive}
        marginVertical="0px"
      >
        {active ? "Client" : "Staff"}
      </Typography>
    </div>
  );
};

export default ClientState;
