import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { SearchSelectInputProps } from "../../../types-definition/form";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import searchIcon from "../../../assets/svg/search-icon.svg";
import cancelIcon from "../../../assets/svg/cancel-icon.svg";
import colors from "../../../theme/colors";
import toolTipIcon from "../../../assets/svg/tool-tip-input.svg";

const ClientSearchSelectInput: React.FC<SearchSelectInputProps> = ({
  options = [
    { name: "certificate 1", id: "1" },
    { name: "certificate 1", id: "1" },
    { name: "certificate 1", id: "1" },
    { name: "certificate 1", id: "1" },
    { name: "certificate 1", id: "1" },
    { name: "certificate 1", id: "1" },
    { name: "certificate 1", id: "1" },
  ],
  label,
  placeholder,
  value,
  setValue,
  height = "200px",
  width = "600px",
  datalistTop = "84px",
  dropdownTop = "48px",
  disabled = false,
  error = false,
  isToolTip = false,
  toolTipText,
  updateDropDown = () => {},
  helperText,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [localValue, setLocalValue] = useState("");
  const [selectedValues, setSelectedValues] = useState<Array<any>>([]);
  const [inputDimensions, setInputDimensions] = useState({
    paddingBottom: "",
    paddingLeft: "",
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [list, setList] = useState<Array<any>>(
    options.filter((loca: any) => loca.id !== undefined),
  );
  const filterByReference = (arr1: any, arr2: any) => {
    let res = [];
    res = arr1.filter((el: any) => {
      return (
        arr2 &&
        !arr2.find((element: any) => {
          return element.locationId === el.id;
        })
      );
    });
    return res;
  };

  const handleListSelector = (item: any) => {
    setValue(item.name);
    setSelectedValues((prevData) => {
      setValue([...prevData, { name: item.name, locationId: item.id }]);
      return [...prevData, { name: item.name, locationId: item.id }];
    });
    setLocalValue("");
    setIsSelected(true);
    setShowDropDown(false);
    filterByReference(list, selectedValues);
  };
  const handleListRemover = (item: any) => {
    setSelectedValues((prevData) => {
      return prevData.filter((data) => data.id !== item.id);
    });
    setValue(selectedValues);
    setIsSelected(true);
    setShowDropDown(false);
  };
  const filterListFromString = (input: string) => {
    if (input !== "" && input !== undefined) {
      const newList = options.filter((item: any) =>
        item.name.toLowerCase().includes(input.toLowerCase()),
      );
      setLocalValue(input);
      setList(newList);
      setIsSelected(false);
    } else {
      setIsSelected(false);
      setLocalValue("");
      setList(options);
    }
  };
  useEffect(() => {
    if (!isSelected && !showDropDown) {
      setLocalValue("");
      setList(options);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, showDropDown]);
  useEffect(() => {
    // setList(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (containerRef.current?.clientHeight) {
      setInputDimensions((prevData) => {
        return {
          ...prevData,
          paddingBottom:
            containerRef.current!.clientHeight > 38
              ? `${containerRef.current!.clientHeight - 15}px`
              : "",
        };
      });
    }
    if (
      containerRef.current?.clientWidth ||
      containerRef.current?.clientWidth === 0
    ) {
      setInputDimensions((prevData) => {
        if (selectedValues.length > 3) {
          if (selectedValues.length % 3 === 0) {
            return { ...prevData, paddingLeft: "450px" };
          } else if ((selectedValues.length - 1) % 3 === 0) {
            return { ...prevData, paddingLeft: `${450 / 3}px` };
          } else {
            return { ...prevData, paddingLeft: `${300}px` };
          }
        }
        return {
          ...prevData,
          paddingLeft:
            containerRef.current!.clientWidth > 0
              ? `${containerRef.current!.clientWidth}px`
              : "10px",
        };
      });
    }
  }, [selectedValues]);
  useEffect(() => {
    if (selectedValues?.length === 0) {
      if (value === null || value === undefined) {
        setSelectedValues([]);
      } else {
        setSelectedValues(value);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (value.length !== selectedValues.length) {
      setValue(selectedValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);
  return (
    <section className={styles.container} style={{ width }}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {label}
      </Typography>
      <input
        readOnly
        type="text"
        value={localValue}
        placeholder={placeholder}
        className={styles.select}
        ref={inputRef}
        onClick={(e) => {
          if (!showDropDown) {
            setShowDropDown(true);
          }
          // filterListFromString(e.target.value);
        }}
        onFocus={() => {
          updateDropDown();
          setShowDropDown(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 500);
        }}
        style={{
          paddingLeft: inputDimensions.paddingLeft,
          paddingTop: inputDimensions.paddingBottom,
          borderColor: error ? colors.error : colors.gray3,
        }}
        disabled={disabled}
      />
      <div className={styles.helpertext_container}>
        <Typography
          textAlign="left"
          textSize={"10px"}
          textWeight="600"
          textColor={error ? colors.error : colors.primary3}
        >
          {helperText}
        </Typography>
      </div>
      {/* ToolTip Controller */}
      {isToolTip && (
        <div className={styles.tooltip_icon}>
          <img src={toolTipIcon} alt="tooltip" />
        </div>
      )}
      {isToolTip && (
        <div className={styles.tooltip_text_container}>
          <div className={styles.tooltip_text}>
            <Typography textAlign="left" textSize="12px" textWeight="600">
              {toolTipText}
            </Typography>
          </div>
        </div>
      )}
      <div
        className={styles.selected_item_container}
        ref={containerRef}
        onClick={() => {
          if (inputRef.current?.focus) {
            inputRef.current?.focus();
          }
        }}
      >
        {selectedValues &&
          selectedValues.map((item) => {
            return (
              <div
                className={styles.selected_item}
                key={Math.random().toString()}
              >
                <Typography marginHorizontal="5px">{item.name}</Typography>
                <img
                  src={cancelIcon}
                  alt="cancel"
                  className={styles.cancel_image}
                  onClick={() => handleListRemover(item)}
                />
              </div>
            );
          })}
      </div>
      <div
        className={styles.dropdown_container}
        style={{ top: dropdownTop }}
        tabIndex={0}
        onClick={() => {
          !disabled && setShowDropDown(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 400);
        }}
        aria-disabled={disabled}
      >
        <img src={searchIcon} className={styles.dropdown} alt="search icon" />
      </div>
      {showDropDown && (
        <div
          className={styles.datalist}
          style={{
            maxHeight: height,
            top: inputDimensions.paddingBottom
              ? `${inputDimensions.paddingBottom + 30}px`
              : datalistTop,
          }}
        >
          {filterByReference(list, selectedValues).length === 0 ? (
            <p>No data</p>
          ) : (
            filterByReference(list, selectedValues).map(
              (item: any, index: any) => {
                return (
                  <p onClick={() => handleListSelector(item)} key={index}>
                    {item.name}
                  </p>
                );
              },
            )
          )}
        </div>
      )}
    </section>
  );
};

export default ClientSearchSelectInput;
