import { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import Button from "components/Form/Button";
import searchIcon from "assets/svg/search-icon.svg";
import colors from "theme/colors";
import addIcon from "assets/svg/add-icon.svg";
import { useNavigate } from "react-router-dom";
import EmptyItemState from "components/Common/EmptyItemState";
import Table from "components/StaffHub/Employee/Table/EmployeeTable";
import { EmployeeData } from "types-definition/StaffHub/employee";
import { getRequest } from "api/request";
import {
  GET_ALL_EMPLOYEE_URL,
  READ_ONLY_EMPLOYEE,
} from "api/urls";
import PageLoader from "components/Common/PageLoader";
import {
  deleteCurrentEmployee,
  deleteEmployeeId,
  deleteInititalEmployeeAdd,
  getUserTypes,
  setInititalEmployeeAdd,
} from "utils/localstorage";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  resetAllBasicInformationFormData,
  resetBasicInformationFormData,
} from "../../../redux/slice/staff-hub/employeeBasicInfoSlice";
import {
  getEmployeStateCounter,
  getShowDeleteEmployeeModal,
} from "../../../redux/slice/staff-hub/employeeSlice";
import DeleteEmployeeModal from "components/StaffHub/Employee/Table/DeleteEmployeeModal";
import checkAdminPermission, { checkUserPermission } from "utils/permission";
import { ADD_EMPLOYEE } from "types-definition/constants";
import EmployeeTableFilter from "components/StaffHub/Employee/EmployeeTableFilter";
// import { toastError } from "utils/toast";

const StaffHubDashbaord = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showDeleteEmployeeModal = useAppSelector(getShowDeleteEmployeeModal);
  const counter = useAppSelector(getEmployeStateCounter);
  const [isEmployeeAddPermission, setIsEmployeeAddPermission] = useState(true);
  const [employeeData, setEmployeeData] = useState<Array<EmployeeData>>([]);
  const [filteredEmployeeData, setFilteredEmployeeData] = useState<
    Array<EmployeeData>
  >([]);
  const [sortFilter, setSortFilter] = useState("");
  const [sortFilterName, setSortFilterName] = useState("");
  const [isfilter, setIsfilter] = useState(false);
  const [searchedValues, setSearchedValues] = useState("");
  const [requestLoading, setRequestLoading] = useState(true);
  useEffect(() => {
    // display success message
    const getAllEmployee = async () => {
      let response;
      try {
        const userType = getUserTypes();
        if (userType === "Admin") {
          response = await getRequest(GET_ALL_EMPLOYEE_URL);
        }
        if (userType === "Web User" || "EMPLOYEE") {
          response = await getRequest(READ_ONLY_EMPLOYEE);
        }
        if (response) {
          const employeeDataArray: Array<EmployeeData> = [];
          for (const index in response.data) {
            const employeeDataObject = {
              createdAt: response.data[index].basicInformation.createdAt,
              email: response.data[index].contactDetail.email,
              firstName: response.data[index].basicInformation.firstName,
              id: response.data[index].basicInformation.employeeId,
              isActive: response.data[index].basicInformation.isActive,
              lastName: response.data[index].basicInformation.lastName,
              portalAccess: response.data[index].basicInformation.portalAccess,
              pin: response.data[index].operation.pin,
              grade:
                response.data[index].grade.grade &&
                response.data[index].grade.grade,
              postcode: response.data[index].contactDetail.postcode,
              town: response.data[index].contactDetail.town,
              employeeNumber: response.data[index].hr?.employeeNumber,
              username: response.data[index].basicInformation.username,
              employeeId: response.data[index].id,
              payRate: response.data[index].hr
                ? response.data[index]?.hr.personnelRate
                : "",
              holidayCalculationMethod: response.data[index].hr
                ? response.data[index]?.hr.holidayCalculationMethod
                : "",
            };
            employeeDataArray.push(employeeDataObject);
          }
          setEmployeeData(employeeDataArray);
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      } finally {
        setRequestLoading(false);
      }
    };
    getAllEmployee();
    dispatch(resetBasicInformationFormData());
    dispatch(resetAllBasicInformationFormData());
    deleteInititalEmployeeAdd();
    // deleteCurrentEmployee();
    // deleteEmployeeId();
  }, [counter, dispatch]);
  useEffect(() => {
    const getPermission = async () => {
      const data = checkAdminPermission(getUserTypes());
      if (data) {
        setIsEmployeeAddPermission(false);
      } else {
        const result = await checkUserPermission([ADD_EMPLOYEE]);
        setIsEmployeeAddPermission(result ? false : true);
      }
    };
    getPermission();
  }, []);

  // Filter Effect Logic
  useEffect(() => {
    if (sortFilter && searchedValues) {
      const localEmployeeData = [...employeeData];
      setIsfilter(true);
      if (sortFilter === "Ascending Order") {
        setFilteredEmployeeData(
          localEmployeeData
            .sort((a: EmployeeData, b: EmployeeData) => {
              if (sortFilterName === "Grade") {
                return a.grade.localeCompare(b?.grade);
              } else if (sortFilterName === "Town") {
                return a.town.localeCompare(b?.town);
              } else {
                return a.grade.localeCompare(b?.grade);
              }
            })
            .filter((data: EmployeeData) => {
              return (
                data?.firstName
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.lastName
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data.grade
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data?.town
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.postcode
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase())
              );
            }),
        );
      } else if (sortFilter === "Descending Order") {
        setFilteredEmployeeData(
          localEmployeeData
            .sort((a: EmployeeData, b: EmployeeData) => {
              if (sortFilterName === "Grade") {
                return b?.grade?.localeCompare(a?.grade);
              } else if (sortFilterName === "Town") {
                return b?.town?.localeCompare(a?.town);
              } else {
                return b?.grade?.localeCompare(a?.grade);
              }
            })
            .filter((data: EmployeeData) => {
              return (
                data?.firstName
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.lastName
                  ?.toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data?.grade
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.town
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.postcode
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase())
              );
            }),
        );
      } else if (sortFilter === "Active") {
        setFilteredEmployeeData(
          localEmployeeData
            .filter((data: EmployeeData) => data.isActive)
            .filter((data: EmployeeData) => {
              return (
                data?.firstName
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.lastName
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.grade
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.town
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.postcode
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase())
              );
            }),
        );
      } else {
        setFilteredEmployeeData(
          localEmployeeData
            .filter((data: EmployeeData) => !data.isActive)
            .filter((data: EmployeeData) => {
              return (
                data?.firstName
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.lastName
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.grade
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.town
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase()) ||
                data?.postcode
                  ?.toLowerCase()
                  ?.includes(searchedValues.toLowerCase())
              );
            }),
        );
      }
    } else if (searchedValues) {
      setIsfilter(true);
      setFilteredEmployeeData(
        employeeData.filter((data: EmployeeData) => {
          return (
            data.firstName
              ?.toLowerCase()
              .includes(searchedValues.toLowerCase()) ||
            data.lastName
              ?.toLowerCase()
              .includes(searchedValues.toLowerCase()) ||
            data.grade?.toLowerCase().includes(searchedValues.toLowerCase()) ||
            data.town?.toLowerCase().includes(searchedValues.toLowerCase()) ||
            data.postcode?.toLowerCase().includes(searchedValues.toLowerCase())
          );
        }),
      );
    } else if (sortFilter) {
      setIsfilter(true);
      if (sortFilter === "Ascending Order") {
        const localEmployeeData = [...employeeData];
        setFilteredEmployeeData(
          localEmployeeData.sort((a: EmployeeData, b: EmployeeData) => {
            if (sortFilterName === "Grade") {
              return a?.grade?.localeCompare(b?.grade);
            } else if (sortFilterName === "Town") {
              return a?.town?.localeCompare(b?.town);
            } else {
              return a?.grade?.localeCompare(b?.grade);
            }
          }),
        );
      } else if (sortFilter === "Descending Order") {
        const localEmployeeData = [...employeeData];
        setFilteredEmployeeData(
          localEmployeeData.sort((a: EmployeeData, b: EmployeeData) => {
            if (sortFilterName === "Grade") {
              return b?.grade.localeCompare(a?.grade);
            } else if (sortFilterName === "Town") {
              return b?.town.localeCompare(a?.town);
            } else {
              return b?.grade.localeCompare(a?.grade);
            }
          }),
        );
      } else if (sortFilter === "Active") {
        setFilteredEmployeeData(
          employeeData.filter((data: EmployeeData) => data.isActive),
        );
      } else {
        setFilteredEmployeeData(
          employeeData.filter((data: EmployeeData) => !data.isActive),
        );
      }
    } else {
      setIsfilter(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValues, sortFilter]);

  return (
    <>
      <ScrollToTop />
      {requestLoading ? (
        <PageLoader />
      ) : (
        <section>
          <DashboardHeader active="staff/hub" />
          <div className={styles.container}>
            <div
              className={
                employeeData.length <= 5
                  ? `${styles.item_container} ${styles.item_container_empty}`
                  : `${styles.item_container} ${styles.item_container_fill}`
              }
            >
              <div className={styles.flex_item}>
                <div>
                  <Typography textAlign="left" textWeight="900" textSize="20px">
                    Employee Management
                  </Typography>
                  <Typography textAlign="left" textColor={colors.gray1}>
                    Add your employees' to properly manage them
                  </Typography>
                </div>
                <div className={styles.sub_flex_item}>
                  <EmployeeTableFilter
                    clearFilter={() => setSortFilter("")}
                    runFilter={(data, name) => {
                      setSortFilter(data);
                      setSortFilterName(name);
                    }}
                  />
                  <div className={styles.sub_sub_flex_item}>
                    <TextInput
                      left={<img src={searchIcon} alt="search" />}
                      placeholder="Search"
                      value={searchedValues}
                      setValue={(value) => {
                        setSearchedValues(value);
                      }}
                    />
                    <Button
                      left={<img src={addIcon} alt="add" />}
                      marginTop="8px"
                      paddingVertical="7px"
                      width="200px"
                      isPrimary
                      text="Add Employee"
                      disabled={isEmployeeAddPermission}
                      onClick={() => {
                        deleteEmployeeId();
                        deleteCurrentEmployee();
                        setInititalEmployeeAdd("true");
                        navigate("/employee/basic-info");
                      }}
                    />
                  </div>
                </div>
              </div>
              {employeeData?.length === 0 ? (
                <EmptyItemState
                  text="You haven't added any employee yet"
                  location="employee/basic-info"
                  action={() => {
                    setInititalEmployeeAdd("true");
                    navigate("/employee/basic-info");
                  }}
                  label="Add Employee"
                  buttonDisabled={isEmployeeAddPermission}
                />
              ) : (
                <Table data={isfilter ? filteredEmployeeData : employeeData} />
              )}
            </div>
          </div>
        </section>
      )}
      {showDeleteEmployeeModal && <DeleteEmployeeModal />}
    </>
  );
};

export default StaffHubDashbaord;
