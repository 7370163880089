import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const initialState = {
  showModal: false,
  reload: false,
};

const linkTolocationSlice = createSlice({
  name: "linkToLocation",
  initialState,
  reducers: {
    handleShowModal: (state, action) => {
      state.showModal = action.payload.showModal;
    },
    handleReload: (state, action) => {
      state.reload = action.payload.reload;
    },
  },
});

export const { handleShowModal, handleReload } = linkTolocationSlice.actions;
export const getNewState = (state: RootState) => state.linkToLocation.showModal;
export const refetchState = (state: RootState) => state.linkToLocation.reload;
export default linkTolocationSlice.reducer;
