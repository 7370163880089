import React, { useRef } from "react";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import { NumberInputProps } from "../../../types-definition/form";
import arrowUpIcon from "../../../assets/svg/arrow-up-icon.svg";
import arrowDownIcon from "../../../assets/svg/arrow-down-icon.svg";
import styles from "./index.module.css";
import colors from "../../../theme/colors";
import toolTipIcon from "../../../assets/svg/tool-tip-input.svg";

const DecimalNumberInput: React.FC<NumberInputProps> = ({
  placeholder,
  label,
  right,
  left,
  value,
  setValue,
  disabled = false,
  width,
  style,
  rightIconRight = "15px",
  rightIconTop = "14px",
  showRightIcon = true,
  required = false,
  error = false,
  max = 1,
  min = 0,
  helperText,
  isToolTip = false,
  toolTipText,
  id,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <section className={styles.container} style={{ ...style }} id={id}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <div className={styles.inputContainer}>
        <div className={styles.left}>{left}</div>
        <input
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          ref={inputRef}
          className={
            right
              ? `${styles.input} ${styles.right_padding}`
              : left
                ? `${styles.input} ${styles.left_padding}`
                : styles.input
          }
          disabled={disabled}
          type="number"
          onWheel={(e) => {
            // e.preventDefault();
            if (inputRef?.current) inputRef?.current.blur();
          }}
          style={{
            width,
            borderColor: error ? colors.error : colors.gray3,
          }}
          min={min}
          max={max}
          step="0.01"
        />
        {isToolTip && (
          <div className={styles.tooltip_icon}>
            <img src={toolTipIcon} alt="tooltip" />
          </div>
        )}
        <div className={styles.helpertext_container}>
          <Typography
            textAlign="left"
            textSize={"10px"}
            textWeight="600"
            textColor={error ? colors.error : colors.primary3}
          >
            {helperText}
          </Typography>
        </div>
        {showRightIcon && (
          <div
            className={styles.right}
            style={{ top: rightIconTop, right: rightIconRight }}
          >
            {right ? (
              right
            ) : (
              <div className={styles.right_icon_container}>
                <img
                  src={arrowUpIcon}
                  alt="icon"
                  className={styles.right_image_top}
                  tabIndex={1}
                  onClick={() => {
                    const value = parseInt(inputRef?.current?.value || "0");
                    setValue(`${value + 1}`);
                  }}
                />
                <img
                  src={arrowDownIcon}
                  alt="icon"
                  className={styles.right_image_bottom}
                  tabIndex={1}
                  onClick={() => {
                    const value = parseInt(inputRef?.current?.value || "0");
                    if (value > 0) {
                      setValue(`${value - 1}`);
                    } else {
                      setValue("0");
                    }
                  }}
                />
              </div>
            )}
          </div>
        )}
        {isToolTip && (
          <div className={styles.tooltip_text_container}>
            <div className={styles.tooltip_text}>
              <Typography textAlign="left" textSize="12px" textWeight="600">
                {toolTipText}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default DecimalNumberInput;
