import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import cancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getCurrentSelectedId,
  setShowViewClonePermissionGroupModal,
} from "../../../../redux/slice/admin/adminSlice";
import searchIcon from "../../../../assets/svg/search-icon.svg";
import SearchInput from "../SearchInput";
import { getRequest } from "../../../../api/request";
import {
  CLONE_PERMISSION_GROUP_URL,
  GET_PERMISSION_GROUP_URL,
} from "../../../../api/urls";
import colors from "../../../../theme/colors";
import { toastError } from "../../../../utils/toast";

const ViewClonePermissionGroupModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(getCurrentSelectedId);
  const [formData, setFormData] = useState({
    availablePermissions: "",
    assignedPermissions: "",
  });
  const [assignedPermissions, setAssignedPermissions] = useState<Array<any>>(
    [],
  );
  const [filteredAssignedPermissions, setFilteredAssignedPermissions] =
    useState<Array<any>>([]);

  // Handle Search Bar for Assigned Permissions
  const handleSearchAssignedPermission = (value: string) => {
    setFilteredAssignedPermissions((prevData) => {
      return assignedPermissions.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      );
    });
  };

  // Gets the available permissions and assigned permissions on modal load
  useEffect(() => {
    const getAssignedPermission = async () => {
      let response;
      response = await getRequest(`${CLONE_PERMISSION_GROUP_URL}/${id}`);
      response = await getRequest(
        `${GET_PERMISSION_GROUP_URL}/${response.data.permissionGroupId}`,
      );
      if (response?.success) {
        setAssignedPermissions(response?.data.permissions);
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    };
    getAssignedPermission();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600" textSize="18px">
            Permissions
          </Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() =>
              dispatch(
                setShowViewClonePermissionGroupModal({ show: false, id: id }),
              )
            }
          />
        </div>
        <div className={styles.flex_container}>
          <div className={styles.flex_item}>
            <div className={styles.flex_item_header}>
              <Typography textWeight="600" textAlign="left">
                Assigned Permissions
              </Typography>
            </div>
            <div>
              <SearchInput
                left={<img src={searchIcon} alt="search" />}
                placeholder="Search"
                value={formData.assignedPermissions}
                setValue={(data) => {
                  setFormData((prevData) => {
                    return { ...prevData, assignedPermissions: data };
                  });
                  handleSearchAssignedPermission(data);
                }}
              />
            </div>
            <div className={styles.permission_container}>
              {!formData.assignedPermissions
                ? assignedPermissions.map((permission, index) => (
                    <Typography
                      key={index}
                      textColor={colors.primary3}
                      textAlign="left"
                      marginVertical="25px"
                      marginHorizontal="20px"
                    >
                      {permission.name}
                    </Typography>
                  ))
                : filteredAssignedPermissions.map((permission, index) => (
                    <Typography
                      key={index}
                      textColor={colors.primary3}
                      textAlign="left"
                      marginVertical="20px"
                    >
                      {permission.name}
                    </Typography>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewClonePermissionGroupModal;
