import React from "react";
import styles from "./index.module.css";
import { CheckpointItemFormProps } from "../../../../types-definition/client";
import TextInput from "components/Form/TextInput";
import SelectInput from "components/Form/SelectInput";
import Typography from "components/Common/Typography";
import colors from "theme/colors";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getCheckpointFormItem,
  getRequiredCheckpointFormItem,
  setCheckpointItemFormData,
  setRequiredCheckpointItemFormData,
} from "../../../../redux/slice/client/checkpointSlice";
import NumberInput from "components/Form/NumberInput";
import clockIcon from "assets/svg/gray-clock-icon.svg";

const CheckpointItemForm: React.FC<CheckpointItemFormProps> = ({ index }) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(getCheckpointFormItem);
  const requiredFormData = useAppSelector(getRequiredCheckpointFormItem);
  return (
    <div className={styles.container}>
      <div className={styles.text_container}>
        <Typography textSize="18px" textWeight="600" textAlign="left">
          Checkpoint {index}
        </Typography>
      </div>
      <TextInput
        label="Checkpoint Name"
        value={formData.name}
        setValue={(data) => {
          dispatch(
            setCheckpointItemFormData({
              ...formData,
              name: data,
            }),
          );
          dispatch(
            setRequiredCheckpointItemFormData({
              ...requiredFormData,
              name: { status: false, text: "" },
            }),
          );
        }}
        placeholder="Enter name of checkpoint"
        error={requiredFormData.name.status}
        helperText={requiredFormData.name.text}
        id="name"
      />
      <div>
        <div className={styles.flex_item}>
          <NumberInput
            label="Longitude"
            value={formData.longitude}
            setValue={(data) => {
              dispatch(
                setCheckpointItemFormData({
                  ...formData,
                  longitude: data,
                }),
              );
            }}
            placeholder="Enter Longitude"
          />
          <NumberInput
            label="Latitude"
            value={formData.latitude}
            setValue={(data) => {
              dispatch(
                setCheckpointItemFormData({
                  ...formData,
                  latitude: data,
                }),
              );
            }}
            placeholder="Enter Latitude"
          />
        </div>
        <Typography
          textColor={colors.gray1}
          textAlign="left"
          marginVertical="3px"
        >
          For better accuracy, log in to the mobile app and automatically record
          your precise location (longitude & latitude) using your phone's GPS
          when you reach a checkpoint
        </Typography>
      </div>
      <div className={styles.flex_item}>
        <SelectInput
          label="Time"
          value={formData.time}
          setValue={(data) => {
            dispatch(
              setCheckpointItemFormData({
                ...formData,
                time: data,
              }),
            );
            dispatch(
              setRequiredCheckpointItemFormData({
                ...requiredFormData,
                time: { status: false, text: "" },
              }),
            );
          }}
          placeholder="Enter Time"
          dropdownTop="54px"
          datalistTop="91px"
          error={requiredFormData.time.status}
          helperText={requiredFormData.time.text}
          options={[
            "15 Minutes",
            "30 Minutes",
            "1 Hour",
            "1 Hour 15 Minutes",
            "1 Hour 30 Minutes",
            "1 Hour 45 Minutes",
            "2 Hours",
            "2 Hours 15 Minutes",
            "2 Hours 30 Minutes",
            "2 Hours 45 Minutes",
            "3 Hours",
            "3 Hours 15 Minutes",
            "3 Hours 30 Minutes",
            "3 Hours 45 Minutes",
            "4 Hours",
          ]}
          img={<img src={clockIcon} alt="clock-icon" />}
          id="time"
        />
        <SelectInput
          label="Frequency"
          value={formData.frequency}
          setValue={(data) => {
            dispatch(
              setCheckpointItemFormData({
                ...formData,
                frequency: data,
              }),
            );
            dispatch(
              setRequiredCheckpointItemFormData({
                ...requiredFormData,
                frequency: { status: false, text: "" },
              }),
            );
          }}
          placeholder="Select Frequency"
          options={["0", "1", "2", "3", "4", "5", "6", "7", "8"]}
          error={requiredFormData.frequency.status}
          helperText={requiredFormData.frequency.text}
          id="frequency"
        />
      </div>
      <TextInput
        label="Checkpoint Display Title"
        value={formData.title}
        setValue={(data) => {
          dispatch(
            setCheckpointItemFormData({
              ...formData,
              title: data,
            }),
          );
          dispatch(
            setRequiredCheckpointItemFormData({
              ...requiredFormData,
              title: { status: false, text: "" },
            }),
          );
        }}
        error={requiredFormData.title.status}
        helperText={requiredFormData.title.text}
        placeholder="Enter checkpoint display title"
        id="title"
      />
      <TextInput
        label="Checkpoint Display Details"
        value={formData.details}
        setValue={(data) => {
          dispatch(
            setCheckpointItemFormData({
              ...formData,
              details: data,
            }),
          );
          dispatch(
            setRequiredCheckpointItemFormData({
              ...requiredFormData,
              details: { status: false, text: "" },
            }),
          );
        }}
        error={requiredFormData.details.status}
        helperText={requiredFormData.details.text}
        style={{ marginTop: "20px" }}
        placeholder="Enter details, info or instruction for checkpoint"
        id="details"
      />
    </div>
  );
};

export default CheckpointItemForm;
