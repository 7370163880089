import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../../Common/PageBackButton";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import { getRequest } from "../../../../../api/request";
import PageLoader from "../../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import { AdminActivityHolidaySettingProps } from "../../../../../types-definition/admin";
import DashboardHeader from "../../../../Common/DashboardHeader";
import { toastError } from "utils/toast";

const ActivityHolidaySettingsTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentHolidaySetting, setCurrentHolidaySetting] =
    useState<AdminActivityHolidaySettingProps>({
      holidayYearStart: "",
      holidayEntitlement: "",
      historicWeeksForPayCalculations: "",
      minimumDay: "",
      maximumHolidayDuration: "",
      defaultHolidayAccrualPercent: "",
      exceedRemainingHolidayAllowance: "",
      hideHolidayEntitlementForAppPortal: "",
      bankHolidayShiftAutoCancel: "",
      accruedWhenAbsent: [],
    });
  const [previousHolidaySetting, setPreviousHolidaySetting] =
    useState<AdminActivityHolidaySettingProps>({
      holidayYearStart: "",
      holidayEntitlement: "",
      historicWeeksForPayCalculations: "",
      minimumDay: "",
      maximumHolidayDuration: "",
      defaultHolidayAccrualPercent: "",
      exceedRemainingHolidayAllowance: "",
      hideHolidayEntitlementForAppPortal: "",
      bankHolidayShiftAutoCancel: "",
      accruedWhenAbsent: [],
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getHolidayData = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data;
        const previousData = response.data;
        if (response.data.action === "POST") {
          setCurrentHolidaySetting({
            holidayYearStart: data.newRecord?.holidaySettings?.holidayYearStart,
            holidayEntitlement:
              data.newRecord?.holidaySettings?.holidayEntitlement,
            historicWeeksForPayCalculations:
              data.newRecord?.holidaySettings?.historicWeeks,
            minimumDay: data.newRecord?.holidaySettings?.minDayNotice,
            maximumHolidayDuration:
              data.newRecord?.holidaySettings?.maxHolidayDuration,
            defaultHolidayAccrualPercent:
              data.newRecord?.holidaySettings?.defaultHolidayAccrualPercent,
            exceedRemainingHolidayAllowance: `${data.newRecord?.holidaySettings?.exceedRemainingAllowance}`,
            hideHolidayEntitlementForAppPortal: `${data.newRecord?.holidaySettings?.hideHolidayEntitlement}`,
            bankHolidayShiftAutoCancel: `${data.newRecord?.holidaySettings?.bankHolidayShiftAutoCancel}`,
            accruedWhenAbsent:
              data.newRecord?.holidaySettings?.accruedWhenAbsent,
          });
          setPreviousHolidaySetting({
            accruedWhenAbsent: previousData?.holidaySettings?.accruedWhenAbsent,
            holidayYearStart: previousData?.holidaySettings?.holidayYearStart,
            holidayEntitlement:
              previousData?.previousRecord?.holidaySettings?.holidayEntitlement,
            historicWeeksForPayCalculations:
              previousData?.holidaySettings?.historicWeeks,
            minimumDay: previousData?.holidaySettings?.minDayNotice,
            maximumHolidayDuration:
              previousData?.holidaySettings?.maxHolidayDuration,
            defaultHolidayAccrualPercent:
              previousData?.holidaySettings?.defaultHolidayAccrualPercent,
            exceedRemainingHolidayAllowance: `${
              previousData?.holidaySettings?.exceedRemainingAllowance
                ? previousData?.holidaySettings?.exceedRemainingAllowance
                : "-"
            }`,
            hideHolidayEntitlementForAppPortal: `${
              previousData?.holidaySettings?.hideHolidayEntitlement
                ? previousData?.holidaySettings?.hideHolidayEntitlement
                : "-"
            }`,
            bankHolidayShiftAutoCancel: `${
              previousData?.holidaySettings?.bankHolidayShiftAutoCancel
                ? previousData?.holidaySettings?.bankHolidayShiftAutoCancel
                : "-"
            }`,
          });
        } else {
          setCurrentHolidaySetting({
            accruedWhenAbsent:
              data.newRecord?.holidaySettings?.accruedWhenAbsent,
            holidayYearStart: data.newRecord?.holidaySettings?.holidayYearStart,
            holidayEntitlement:
              data.newRecord?.holidaySettings?.holidayEntitlement,
            historicWeeksForPayCalculations:
              data.newRecord?.holidaySettings?.historicWeeks,
            minimumDay: data.newRecord?.holidaySettings?.minDayNotice,
            maximumHolidayDuration:
              data.newRecord?.holidaySettings?.maxHolidayDuration,
            defaultHolidayAccrualPercent:
              data.newRecord?.holidaySettings?.defaultHolidayAccrualPercent,
            exceedRemainingHolidayAllowance: `${data.newRecord?.holidaySettings?.exceedRemainingAllowance}`,
            hideHolidayEntitlementForAppPortal: `${data.newRecord?.holidaySettings?.hideHolidayEntitlement}`,
            bankHolidayShiftAutoCancel: `${data.newRecord?.holidaySettings?.bankHolidayShiftAutoCancel}`,
          });
          setPreviousHolidaySetting({
            accruedWhenAbsent:
              previousData?.previousRecord?.holidaySettings?.accruedWhenAbsent,
            holidayYearStart:
              previousData?.previousRecord?.holidaySettings?.holidayYearStart,
            holidayEntitlement:
              previousData?.previousRecord?.holidaySettings?.holidayEntitlement,
            historicWeeksForPayCalculations:
              previousData?.previousRecord?.holidaySettings?.historicWeeks,
            minimumDay:
              previousData?.previousRecord?.holidaySettings?.minDayNotice,
            maximumHolidayDuration:
              previousData?.previousRecord?.holidaySettings?.maxHolidayDuration,
            defaultHolidayAccrualPercent:
              previousData?.previousRecord?.holidaySettings
                ?.defaultHolidayAccrualPercent,
            exceedRemainingHolidayAllowance: `${
              previousData?.previousRecord?.holidaySettings
                ?.exceedRemainingAllowance
                ? previousData?.previousRecord?.holidaySettings
                    ?.exceedRemainingAllowance
                : "-"
            }`,
            hideHolidayEntitlementForAppPortal: `${previousData?.previousRecord?.holidaySettings?.hideHolidayEntitlement}`,
            bankHolidayShiftAutoCancel: `${previousData?.previousRecord?.holidaySettings?.bankHolidayShiftAutoCancel}`,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getHolidayData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Holiday.
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Holiday Year Start </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentHolidaySetting?.holidayYearStart ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Holiday Entitlement</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentHolidaySetting.holidayEntitlement ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Historic Weeks For Pay Calculations
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentHolidaySetting.historicWeeksForPayCalculations ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Minimum Day</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentHolidaySetting.minimumDay ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Maximum Holiday Duration
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentHolidaySetting.maximumHolidayDuration ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Default Holiday Accrual Percent{" "}
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentHolidaySetting.defaultHolidayAccrualPercent ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Exceed Remaining Holiday Allowance
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentHolidaySetting.exceedRemainingHolidayAllowance ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Hide Holiday Entitlement For App/Portal
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentHolidaySetting.hideHolidayEntitlementForAppPortal ??
                    "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Bank Holiday Shift Auto Cancel
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentHolidaySetting.bankHolidayShiftAutoCancel ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Accrued when Absent</Typography>
                {currentHolidaySetting.accruedWhenAbsent
                  ? currentHolidaySetting.accruedWhenAbsent.map(
                      (item: string) => (
                        <Typography
                          textAlign="left"
                          textSize="20px"
                          textWeight="600"
                        >
                          {item}
                        </Typography>
                      ),
                    )
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Holiday.
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Holiday Year Start </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHolidaySetting?.holidayYearStart ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Holiday Entitlement</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHolidaySetting.holidayEntitlement ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Historic Weeks For Pay Calculations
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHolidaySetting.historicWeeksForPayCalculations ??
                    "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Minimum Day</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHolidaySetting.minimumDay ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Maximum Holiday Duration
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHolidaySetting.maximumHolidayDuration ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Default Holiday Accrual Percent{" "}
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHolidaySetting.defaultHolidayAccrualPercent ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Exceed Remaining Holiday Allowance
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHolidaySetting.exceedRemainingHolidayAllowance}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Hide Holiday Entitlement For App/Portal
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHolidaySetting.hideHolidayEntitlementForAppPortal}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Bank Holiday Shift Auto Cancel
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHolidaySetting.bankHolidayShiftAutoCancel}
                </Typography>
              </div>

              <div>
                <Typography textAlign="left">Accrued when Absent</Typography>
                {previousHolidaySetting.accruedWhenAbsent
                  ? previousHolidaySetting.accruedWhenAbsent.map(
                      (item: string) => (
                        <Typography
                          textAlign="left"
                          textSize="20px"
                          textWeight="600"
                        >
                          {item}
                        </Typography>
                      ),
                    )
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityHolidaySettingsTab;
