import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import cancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getCurrentSelectedId,
  setShowViewWebUserPermissionModal,
} from "../../../../redux/slice/admin/adminSlice";
import searchIcon from "../../../../assets/svg/search-icon.svg";
import colors from "../../../../theme/colors";
import { getRequest } from "../../../../api/request";
import { toastError } from "../../../../utils/toast";

const ViewWebUserPermissionModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(getCurrentSelectedId);
  const [formData, setFormData] = useState({
    availablePermissions: "",
  });
  const [availablePermissions, setAvailablePermissions] = useState<Array<any>>(
    [],
  );

  const [filteredAvailablePermissions, setFilteredAvailablePermissions] =
    useState<Array<any>>([]);

  const handleSearchAvailablePermission = (value: string) => {
    setFilteredAvailablePermissions((prevData) => {
      return availablePermissions.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      );
    });
  };
  useEffect(() => {
    const getPermissions = async () => {
      const response = await getRequest(`/settings/user/${id}/permission`);
      let permissionArray: any = [];
      if (response?.success) {
        response.data.map((permission: any) => {
          if (permission) {
            permissionArray.push(permission);
          }
        });
        setAvailablePermissions(permissionArray);
        // const permissionsResponseData: any = [];
        // for (const index in response.data.permissionGroups) {
        //   permissionsResponseData.push(
        //     ...response.data.permissionGroups[index].permissions,
        //   );
        // }
        // setAvailablePermissions(
        //   permissionsResponseData.filter((item: any, index: any) => {
        //     return (
        //       permissionsResponseData.findIndex((i: any) => {
        //         return i.name.toLowerCase() === item.name.toLowerCase();
        //       }) === index
        //     );
        //   }),
        // );
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    };
    getPermissions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600" textSize="18px">
            View Web User Permissions
          </Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() =>
              dispatch(
                setShowViewWebUserPermissionModal({ show: false, id: "" }),
              )
            }
          />
        </div>
        <div className={styles.search_container}>
          <TextInput
            left={<img src={searchIcon} alt="search" />}
            placeholder="Search"
            value={formData.availablePermissions}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, availablePermissions: data };
              });
              handleSearchAvailablePermission(data);
            }}
          />
        </div>
        <div className={styles.flex_container}>
          <div className={styles.flex_item}>
            <div className={styles.flex_item_header}>
              <Typography textWeight="600" textAlign="left">
                Assigned Web User Permissions
              </Typography>
            </div>
            <div className={styles.permission_container}>
              {!formData.availablePermissions
                ? availablePermissions.map((permission, index) => (
                    <div
                      className={styles.permission_container_item}
                      key={index}
                    >
                      <Typography textColor={colors.primary3} textAlign="left">
                        {permission.name}
                      </Typography>
                    </div>
                  ))
                : filteredAvailablePermissions.map((permission, index) => (
                    <div
                      className={styles.permission_container_item}
                      key={index}
                    >
                      <Typography textColor={colors.primary3} textAlign="left">
                        {permission.name}
                      </Typography>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewWebUserPermissionModal;
