import React from "react";
import TextInput from "../../../Form/TextInput";
import styles from "./index.module.css";
import { CertificateFormDataProps } from "../../../../types-definition/StaffHub/certificateType";
import Typography from "../../../Common/Typography";
import Checkbox from "../../../Form/Checkbox";
import RadioInput from "../../../Form/RadioInput";

const AddCertificateForm: React.FC<CertificateFormDataProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  return (
    <div className={styles.container}>
      <TextInput
        value={formData.name}
        setValue={(data) => {
          setFormData((prevData) => {
            return { ...prevData, name: data };
          });
          setRequiredFormData((prevData) => {
            return { ...prevData, name: false };
          });
        }}
        label="Certificate Name"
        placeholder="Enter Certificate Name"
        error={requiredFormData.name}
        helperText={requiredFormData.name ? "Certificate Name is required" : ""}
        required={true}
      />

      <div className={styles.form_flex_container}>
        <Checkbox
          value={formData.active}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, active: data };
            });
          }}
          label={"Active"}
        />
        <div className={styles.flex_item}>
          <Typography textAlign="left" textWeight="600" textSize="14px">
            Requirement Level *
          </Typography>
          <RadioInput
            label="If required by Service"
            name="plan-type"
            value={formData.ifRequiredByService}
            setValue={(data) =>
              setFormData((prevData) => {
                return {
                  ...prevData,
                  ifRequiredByService: data,
                  allService: !data,
                };
              })
            }
          />
          <RadioInput
            label="All Service"
            name="plan-type"
            value={formData.allService}
            setValue={(data) =>
              setFormData((prevData) => {
                return {
                  ...prevData,
                  allService: data,
                  ifRequiredByService: !data,
                };
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
export default AddCertificateForm;
