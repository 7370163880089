"use client";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Typography from "components/Common/Typography";
import size from "theme/size";
import PasswordInput from "components/Form/PasswordInput";
import TextInput from "components/Form/TextInput";
import SelectInput from "components/Form/SelectInput";
import styles from "./index.module.css";
import Button from "components/Form/Button";
import colors from "theme/colors";
import SignUpHeader from "components/Common/SignUpHeader";
import { Link } from "react-router-dom";
import Checkbox from "components/Form/Checkbox";
import countryList from "country-list";
import emailIcon from "assets/svg/Mail Icon.svg";

const CreateSocialAccount: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: state.email,
    password: "",
    country: "",
  });

  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  return (
    <main>
      <SignUpHeader />
      <section className={styles.container}>
        <div className={styles.body_container}>
          <Typography
            fontFamily="inter"
            textAlign="center"
            textSize={size.lg}
            textWeight="600"
          >
            Create An Account
          </Typography>
          <Typography textAlign="center" fontFamily="inter" textSize={size.md}>
            Enter your credentials to create your account
          </Typography>
          <div className={styles.form_container}>
            <TextInput
              value={formData.name}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, name: data };
                })
              }
              label="Name"
              placeholder="Enter Name"
            />
            <TextInput
              value={formData.email}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, email: data };
                })
              }
              label="Email Address"
              placeholder="Enter Email"
              right={<img src={emailIcon} alt="icon" width={20} height={20} />}
            />
            <PasswordInput
              value={formData.password}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, password: data };
                })
              }
              label="Password"
              placeholder="Enter Password"
            />
            <SelectInput
              value={formData.country}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, password: data };
                })
              }
              label="Country"
              placeholder="Select Country"
              options={countryList.getData().map((data) => data.name)}
            />
            <section>
              <Checkbox
                value={isTermsAgreed}
                setValue={(value) => setIsTermsAgreed(value)}
                label={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      textAlign="left"
                      textSize="16px"
                      marginVertical={0}
                    >
                      By creating an account, I confirm that I have read
                    </Typography>
                    <Typography
                      textAlign="left"
                      textSize="16px"
                      marginVertical={0}
                    >
                      and understood the{" "}
                      <Link
                        to="/"
                        style={{ color: colors.black, fontWeight: "600" }}
                      >
                        Terms and Conditions
                      </Link>
                      &nbsp;and
                    </Typography>
                    <Typography
                      textAlign="left"
                      textSize="16px"
                      marginVertical={0}
                      textWeight="600"
                    >
                      <Link to="/" style={{ color: colors.black }}>
                        Privacy Policy.
                      </Link>
                    </Typography>
                  </div>
                }
              />
            </section>
            <Button
              text="Get Started"
              isPrimary={true}
              onClick={() => navigate("/dashboard")}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default CreateSocialAccount;
