import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { EmailReportData } from "../../../../types-definition/client";
import moment from "moment";
import capitalize from "../../../../utils/capitalize";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import EmailReportTableDropdown from "../EmailReportTableDropdown";

const EmailReportTableItem: React.FC<EmailReportData> = ({
  daysSelected,
  emailAddress,
  frequency,
  lastSendDate,
  reportType,
  sendTime,
  id,
}) => {
  const [showEditDropdown, setshowEditDropdown] = useState(false);

  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left" textWeight="600">
          {reportType}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{capitalize(frequency)}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {daysSelected ? capitalize(daysSelected) : "-"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{sendTime}</Typography>
      </td>

      <td className={styles.td_mail}>
        {emailAddress?.map((email: string, index: number) => (
          <Typography textAlign="left" key={index}>
            {email}
            {index < emailAddress.length - 1 ? ", " : ""}
          </Typography>
        ))}
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {moment(lastSendDate).format("DD/MM/YYYY")}
        </Typography>
      </td>
      <td className={styles.td}>
        <div
          className={`${styles.image_container} ${styles.td_relative}`}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
          onBlur={() => setshowEditDropdown(false)}
          tabIndex={0}
        >
          {showEditDropdown && <EmailReportTableDropdown id={id} />}
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default EmailReportTableItem;
