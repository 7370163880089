import React, { useCallback, useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import EmployeeTimeSheetTable from "components/StaffHub/Employee/Timesheet/EmployeeTimeSheetTable";
import toast from "react-hot-toast";
import { GET_ALL_EMPLOYEE_URL, GET_USER_SETTINGS_URL } from "api/urls";
import { getRequest } from "api/request";
import { getCurrentEmployee, getEmployeeId } from "utils/localstorage";
import SearchEmployee from "components/StaffHub/Employee/SearchEmployee";
import capitalize from "utils/capitalize";
import PageBackButton from "components/Common/PageBackButton";
import { EmployeeTimeSheetData } from "types-definition/StaffHub/employee";
import FilterDateInput from "components/Form/FilterDateInput";
import moment, { Duration } from "moment";
import { sortByProperty } from "utils/reArrangeTheArrayInAccendingOrder";
import { toastError } from "utils/toast";
import { ENDED } from "types-definition/constants";
import { getTimeDifference } from "utils/timeConverter";
import SelectInput from "components/Form/SelectInput";
const EmployeeTimeSheet = () => {
  const [employeeDetails, setEmployeeDetails] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    address: "",
    postCode: "",
    passCode: "",
    grade: "",
    routerMail: "",
    linkedLocation: "",
  });
  const [employeeName, setEmployeeName] = useState({ name: "", username: "" });
  const [filteringDate, setFilteringDate] = useState("");
  const [filteringLabel, setFilteringLabel] = useState("");
  const [filterDateRange, setFilterDateRange] = useState<{
    label?: string;
    from: string;
    to: string;
  }>({
    from: moment().startOf("months").toISOString(),
    to: moment().endOf("months").toISOString(),
  });
  const [trackRequestCounter, setTrackRequestCounter] = useState(0);
  const [employeeId, setEmployeeId] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [employeeSheetData, setEmployeeSheetData] = useState<
    Array<EmployeeTimeSheetData>
  >([]);
  const [filteredEmployeeSheetData, setFilteredEmployeeSheetData] = useState<
    Array<EmployeeTimeSheetData>
  >([]);
  const getEmployeeInfo = async () => {
    const getGradeSlected = async (gradeId: string) => {
      const gradeResponse = await getRequest(`${GET_USER_SETTINGS_URL}/grade`);
      if (gradeResponse.success) {
        const gradeArray = gradeResponse.data.filter((item: any) => {
          if (gradeId === item.id) {
            return { name: item.grade, id: item.id };
          }
        });
        return gradeArray;
      } else {
        toastError(gradeResponse?.data);
      }
    };

    setRequestLoading(true);
    const data = getCurrentEmployee();
    if (data !== undefined && data !== null) {
      const username = JSON.parse(data).name;
      let response: any;
      let locationResponse: any;
      if (employeeName.username) {
        response = await getRequest(
          `${GET_ALL_EMPLOYEE_URL}/${employeeName.username}`,
        );
        locationResponse = await getRequest(
          `${GET_ALL_EMPLOYEE_URL}/${employeeName.username}/assigned-location`,
        );
      } else {
        response = await getRequest(`${GET_ALL_EMPLOYEE_URL}/${username}`);
        locationResponse = await getRequest(
          `${GET_ALL_EMPLOYEE_URL}/${username}/assigned-location`,
        );
      }
      if (response?.success) {
        const grade = await getGradeSlected(response?.data?.operation?.grade);
        setEmployeeDetails((prevData) => {
          return {
            ...prevData,
            name: `${response?.data?.basicInformation.firstName} ${response?.data?.lastName}`,
            email: response?.data?.contactDetail?.email,
            mobileNumber: response?.data?.contactDetail.mobileNumber,
            address: response?.data?.contactDetail?.address1,
            postCode: response?.data?.contactDetail?.postcode,
            passCode: response?.data?.operation?.pin,
            grade: grade ? grade[0].grade : "",
            linkedLocation: locationResponse?.success
              ? locationResponse?.data?.length?.toString()
              : "0",
          };
        });
        setEmployeeName({
          name: `${response?.data?.basicInformation.firstName} ${response?.data?.lastName}`,
          username: response?.data?.basicInformation?.username,
        });
      } else {
        if (response?.data)
          toast.error(response?.data, {
            duration: 4000,
            position: "top-center",
          });
      }
    }
    setRequestLoading(false);
  };

  const getEmployeeTimesheet = async () => {
    setRequestLoading(true);
    let empoyeeId = getEmployeeId();
    if (empoyeeId) {
      empoyeeId = JSON.parse(empoyeeId)?.id;

      try {
        const response = await getRequest(
          `/misc/active-board/employee/${empoyeeId}`,
        );
        if (response.success) {
          let allTimeSheetData = Array.isArray(response?.data)
            ? response.data
                .map((item: any) => {
                  let extractedStartTime;
                  let extractedEndTime;
                  if (
                    moment(
                      moment(item.employeeStartTime).format("HH:mm"),
                      "HH:mm",
                    ).isAfter(moment(item.startTime, "HH:mm"))
                  ) {
                    extractedStartTime = moment(item.employeeStartTime).format(
                      "HH:mm",
                    );
                  } else {
                    extractedStartTime = item.startTime;
                  }
                  // end time
                  const employeeEndTime = moment(item.employeeEndTime).format(
                    "HH:mm",
                  );

                  if (
                    moment(
                      moment(item.employeeEndTime).format("HH:mm"),
                      "HH:mm",
                    ).isAfter(moment(item.endTime, "HH:mm")) ||
                    employeeEndTime === "00:00"
                  ) {
                    extractedEndTime = item.endTime;
                  } else {
                    extractedEndTime = moment(item.employeeEndTime).format(
                      "HH:mm",
                    );
                  }
                  let timeSheetData: EmployeeTimeSheetData = {
                    id: item?.id,
                    employeeStartTime: item?.employeeStartTime
                      ? moment(item?.employeeStartTime).format("HH:mm")
                      : "",
                    employeeEndTime: item?.employeeEndTime
                      ? moment(item?.employeeEndTime).format("HH:mm")
                      : "",
                    hourWorked:
                      item.employeeEndTime && item.employeeStartTime
                        ? getTimeDifference(
                            extractedStartTime,
                            extractedEndTime,
                          )
                        : "",
                    startTime: item?.startTime,
                    stopTime: item?.endTime,
                    status: item?.status === ENDED,
                    day: item?.date,
                    date: item?.date,
                    initialStartTime: item?.startTime,
                  };

                  return timeSheetData;
                })
                .sort(
                  (a: EmployeeTimeSheetData, b: EmployeeTimeSheetData) =>
                    moment(a.date).unix() - moment(b.date).unix(),
                )
            : [];

          setEmployeeSheetData(allTimeSheetData);
          setFilterDateRange({
            from: moment().startOf("months").toISOString(),
            to: moment().endOf("months").toISOString(),
          });
        }
      } catch (error) {}

      setRequestLoading(false);
    }
  };

  const filterDateOptions = [
    {
      value: {
        from: moment().startOf("months").toISOString(),
        to: moment().endOf("months").toISOString(),
      },
      label: "This Month",
    },
    {
      value: {
        from: moment().subtract(1, "months").startOf("months").toISOString(),
        to: moment().subtract(1, "months").endOf("months").toISOString(),
      },
      label: "Last Month",
    },
    {
      value: {
        from: moment()
          .subtract(7 - 1, "days")
          .startOf("days")
          .toISOString(),
        to: moment().endOf("days").toISOString(),
      },
      label: "Last 7 days",
    },
    {
      value: {
        from: moment()
          .subtract(15 - 1, "days")
          .startOf("days")
          .toISOString(),
        to: moment().endOf("days").toISOString(),
      },
      label: "Last 15 days",
    },
    {
      value: {
        from: moment()
          .subtract(30 - 1, "days")
          .startOf("days")
          .toISOString(),
        to: moment().endOf("days").toISOString(),
      },
      label: "Last 30 days",
    },
    {
      value: {
        from: moment()
          .subtract(90 - 1, "days")
          .startOf("days")
          .toISOString(),
        to: moment().endOf("days").toISOString(),
      },
      label: "Last 90 days",
    },
  ];

  useEffect(() => {
    getEmployeeInfo();
    getEmployeeTimesheet();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackRequestCounter, employeeId]);

  useEffect(() => {
    if (employeeSheetData.length > 0) {
      let filteredEmployeeSheetData: any = [];

      const startDate = moment(filterDateRange.from);
      const endDate = moment(filterDateRange.to);

      filteredEmployeeSheetData = employeeSheetData.filter(
        (item: EmployeeTimeSheetData) =>
          moment(item?.date).isSameOrAfter(moment(filterDateRange.from)) &&
          moment(item?.date).isSameOrBefore(moment(filterDateRange.to)),
      );

      setFilteringLabel(
        `${startDate.format("MMM, D YYYY")} - ${endDate.format("MMM, D YYYY")}`,
      );

      return setFilteredEmployeeSheetData(filteredEmployeeSheetData);
    } else {
      setFilteredEmployeeSheetData([]);
    }
  }, [employeeSheetData, filterDateRange.from, filterDateRange.to]);

  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="staff/hub" />
        <div className={styles.container}>
          <div
            className={`${styles.item_container} ${styles.item_container_fill}`}
          >
            <PageBackButton width="95%" paddingTop="20px" marginBottom="0px" />
            <div className={styles.container_item}>
              <div className={styles.container_item_1}>
                <div className={styles.employee_item}>
                  <Typography textAlign="left" textWeight="600" textSize="20px">
                    Employee TimeSheet
                  </Typography>
                  <div className={styles.filter_flex_container}>
                    <SearchEmployee
                      label=""
                      value={employeeName?.name || ""}
                      setValue={(data) => {
                        setEmployeeId(data?.id);
                        setEmployeeName(data);
                        setFilteringDate("");
                        if (data.sendRequest) {
                          setTrackRequestCounter((prevData) => prevData + 1);
                        }
                        return;
                      }}
                      dropdownTop="26px"
                      datalistTop="63px"
                      placeholder="Employee's Name"
                    />
                    {/* <FilterDateInput
                      label=""
                      value={`${filteringDateRange.from} - ${filteringDateRange.to}`}
                      setValue={(data: any) => {
                        setFilteringDate(data);
                      }}
                      dropdownTop="26px"
                      datalistTop="63px"
                      width="220px"
                    /> */}

                    <SelectInput
                      dropdownTop="26px"
                      datalistTop="63px"
                      width="220px"
                      value={filterDateRange.label ?? filteringLabel}
                      setValue={(label) => {
                        let range = filterDateOptions.find(
                          (day) => day.label === label,
                        );
                        range?.value !== undefined &&
                          setFilterDateRange({
                            label: range.label,
                            ...range?.value,
                          });
                      }}
                      label=""
                      placeholder="Jan 2, 2024 - Feb 2, 2024"
                      options={filterDateOptions.map((day) => day.label)}
                    />
                  </div>
                </div>
                <EmployeeTimeSheetTable
                  data={filteredEmployeeSheetData}
                  monthFilterHandler={() => null}
                  currentMonth={filterDateRange}
                  setCurrentMonth={setFilterDateRange}
                />
              </div>
              <div className={styles.container_item_2}>
                <Typography textAlign="left" textWeight="600" textSize="24px">
                  Employee Details
                </Typography>
                <div className={styles.text_flex_container}>
                  <Typography textAlign="left" textWeight="600">
                    Full Name:{" "}
                  </Typography>
                  <Typography
                    textAlign="left"
                    marginVertical="0px"
                    marginHorizontal={10}
                  >
                    {employeeDetails.name ?? "N/A"}
                  </Typography>
                </div>
                <div className={styles.text_flex_container}>
                  <Typography textAlign="left" textWeight="600">
                    Email Address:{" "}
                  </Typography>
                  <Typography
                    textAlign="left"
                    marginVertical="0px"
                    marginHorizontal={10}
                  >
                    {employeeDetails.email ?? "N/A"}
                  </Typography>
                </div>
                <div className={styles.text_flex_container}>
                  <Typography textAlign="left" textWeight="600">
                    Mobile Number:{" "}
                  </Typography>
                  <Typography
                    textAlign="left"
                    marginVertical="0px"
                    marginHorizontal={10}
                  >
                    {employeeDetails.mobileNumber ?? "N/A"}
                  </Typography>
                </div>
                <div className={styles.text_flex_container}>
                  <Typography textAlign="left" textWeight="600">
                    Address Line 1:{" "}
                  </Typography>
                  <Typography
                    textAlign="left"
                    marginVertical="0px"
                    marginHorizontal={10}
                  >
                    {employeeDetails.address ?? "N/A"}
                  </Typography>
                </div>
                <div className={styles.text_flex_container}>
                  <Typography textAlign="left" textWeight="600">
                    Postcode:
                  </Typography>
                  <Typography
                    textAlign="left"
                    marginVertical="0px"
                    marginHorizontal={10}
                  >
                    {employeeDetails.postCode ?? "N/A"}
                  </Typography>
                </div>

                <div className={styles.text_flex_container}>
                  <Typography textAlign="left" textWeight="600">
                    Passcode:{" "}
                  </Typography>
                  <Typography
                    textAlign="left"
                    marginVertical="0px"
                    marginHorizontal={10}
                  >
                    {employeeDetails.passCode ?? "N/A"}
                  </Typography>
                </div>

                <div className={styles.text_flex_container}>
                  <Typography textAlign="left" textWeight="600">
                    Grade:{" "}
                  </Typography>
                  <Typography
                    textAlign="left"
                    marginVertical="0px"
                    marginHorizontal={10}
                  >
                    {employeeDetails.grade
                      ? capitalize(employeeDetails.grade)
                      : "N/A"}
                  </Typography>
                </div>

                <div className={styles.text_flex_container}>
                  <Typography textAlign="left" textWeight="600">
                    Linked Location:{" "}
                  </Typography>
                  <Typography
                    textAlign="left"
                    marginVertical="0px"
                    marginHorizontal={10}
                  >
                    {employeeDetails.linkedLocation ?? "N/A"}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmployeeTimeSheet;
