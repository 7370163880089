import React, { useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import TextInput from "components/Form/TextInput";
import Typography from "components/Common/Typography";
import searchIcon from "assets/svg/search-icon.svg";
import colors from "theme/colors";
import PayrollTable from "components/Report/payroll/PayrollTable";
import ClientSelectInput from "components/Form/ClientSelectInput";
import DateInput from "components/Form/DateInput";
import Button from "components/Form/Button";

const PayrollReport = () => {
  const [requestLoading, setRequestLoading] = useState(false);

  const [searchedValues, setSearchedValues] = useState("");
  return (
    <main>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="reports" />
      <div className={styles.container}>
        <div className={styles.flex_item}>
          <div className={styles.flex_item_text}>
            <Typography textAlign="left" textWeight="900" textSize="20px">
              Payroll Report
            </Typography>
            <Typography textAlign="left" textColor={colors.gray1}>
              Generate comprehensive payroll summaries to track employee shifts
            </Typography>
          </div>
          <div className={styles.sub_flex_item}>
            <TextInput
              left={<img src={searchIcon} alt="search" />}
              placeholder="Search"
              value={searchedValues}
              setValue={(value) => {
                setSearchedValues(value);
              }}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div style={{ flex: 1 }}>
            <ClientSelectInput
              client="All"
              label="Client"
              placeholder="Select Client"
              setValue={(value) => {}}
              value={""}
              datalistTop="89px"
              dropdownTop="48px"
              options={[
                { id: "1", name: "Client 1" },
                { id: "2", name: "Client 2" },
              ]}
              labelColor={colors.gray3}
              width="100%"
            />
          </div>

          <div style={{ flex: 1 }}>
            <ClientSelectInput
              client="All"
              label="Location"
              placeholder="Select Client"
              setValue={(value) => {
                console.log(value);
              }}
              value={""}
              datalistTop="89px"
              dropdownTop="46px"
              options={[
                { id: "1", name: "Client 1" },
                { id: "2", name: "Client 2" },
              ]}
              labelColor={colors.gray3}
              width="100%"
            />
          </div>
          <div style={{ flex: 1 }}>
            <ClientSelectInput
              client="All"
              label="Service"
              placeholder="Select Client"
              setValue={(value) => {
                console.log(value);
              }}
              value={""}
              datalistTop="89px"
              dropdownTop="46px"
              options={[
                { id: "1", name: "Client 1" },
                { id: "2", name: "Client 2" },
              ]}
              labelColor={colors.gray3}
              width="100%"
            />
          </div>

          <div style={{ flex: 1 }}>
            <ClientSelectInput
              client="All"
              label="Employee"
              placeholder="Select Client"
              setValue={(value) => {
                console.log(value);
              }}
              value={""}
              datalistTop="89px"
              dropdownTop="46px"
              options={[
                { id: "1", name: "Client 1" },
                { id: "2", name: "Client 2" },
              ]}
              width="100%"
              labelColor={colors.gray3}
            />
          </div>
          <div style={{ flex: 1 }}>
            {/* <ClientSelectInput
              client="All"
              label="Client"
              placeholder="Select Client"
              setValue={(value) => {
                console.log(value);
              }}
              value={""}
              datalistTop="89px"
              dropdownTop="46px"
              options={[
                { id: "1", name: "Client 1" },
                { id: "2", name: "Client 2" },
              ]}
              width="100%"
            /> */}
            <DateInput
              placeholder=""
              label="Start Date"
              setValue={(data) => {
                console.log(data);
              }}
              value=""
              labelColor={colors.gray3}
            />
          </div>
          <div style={{ flex: 1 }}>
            <DateInput
              placeholder=""
              label="End Date"
              setValue={(data) => {
                console.log(data);
              }}
              value=""
              labelColor={colors.gray3}
            />
          </div>
          <div style={{ flex: 1, marginTop: 24 }}>
            <Button
              isPrimary={true}
              onClick={() => {}}
              text="Generate Report"
              paddingVertical="7px"
              width="100%"
            />
          </div>
        </div>
        <div>
          <PayrollTable data={[]} />
        </div>
      </div>
    </main>
  );
};

export default PayrollReport;
