import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { ShowIdInterface } from "../../../types-definition/common";
// Define the initial state using that type
const initialState = {
  showServiceConfirmationModal: false,
  showDeleteServiceModal: false,
  currentSelectedId: "",
  fetchedServiceCounter: 0,
};

export const serviceSlice = createSlice({
  name: "service",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setShowServiceConfirmationModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showServiceConfirmationModal = action.payload;
    },
    setShowDeleteServiceModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showDeleteServiceModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
    },
    updateFetchedServiceCounter: (state) => {
      state.fetchedServiceCounter += 1;
    },
  },
});

export const {
  setShowServiceConfirmationModal,
  setShowDeleteServiceModal,
  updateFetchedServiceCounter,
} = serviceSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;
// export const IsAuthenticated = (state: RootState) => state.auth.isAuthenticated;

export const getFetchedServiceCounter = (state: RootState) =>
  state.service.fetchedServiceCounter;

export const getShowServiceConfirmationModal = (state: RootState) =>
  state.service.showServiceConfirmationModal;

export const getShowDeleteServiceModal = (state: RootState) =>
  state.service.showDeleteServiceModal;

export const getCurrentSelectedServiceId = (state: RootState) =>
  state.service.currentSelectedId;

export default serviceSlice.reducer;
