import React from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import Button from "../../Form/Button";
import { useNavigate } from "react-router-dom";
import resetSuccessIcon from "../../../assets/svg/reset-success.svg";

const ResetPasswordSuccessModal = () => {
  const navigate = useNavigate();

  return (
    <section className={styles.container}>
      <div className={styles.content_container}>
        <img src={resetSuccessIcon} alt="reset icon" />
        <Typography textAlign="left" textWeight="600" textSize="18px">
          Password Reset Successful!
        </Typography>
        <Typography textAlign="left" marginVertical="15px">
          Your password has been successfully reset. You can now log in to your
          account using your new password.
        </Typography>
        <Button
          onClick={() => navigate("/login")}
          paddingVertical="5px"
          marginVertical="10px"
          text="Log In Now"
          isPrimary={true}
        />
      </div>
    </section>
  );
};

export default ResetPasswordSuccessModal;
