import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import pencilIcon from "../../../../assets/svg/pencil-dark.svg";
import viewProfileIcon from "../../../../assets/svg/user-profile-icon.svg";
import linkLoctionIcon from "../../../../assets/svg/link-location-icon.svg";
import compassIcon from "../../../../assets/svg/compass-icon.svg";
import binIcon from "../../../../assets/svg/bin-icon.svg";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  setShowAssignPermissionModal,
  setShowLinkLocationModal,
  setShowViewWebUserPermissionModal,
} from "../../../../redux/slice/admin/adminSlice";
import { WebUserTableDropdownProps } from "../../../../types-definition/admin";
import { setWebUser } from "../../../../utils/localstorage";
import { useNavigate } from "react-router-dom";
import { setShowDeleteWebUsers } from "../../../../redux/slice/admin/webuserSlice";

const WebUserTableDropdown: React.FC<WebUserTableDropdownProps> = ({
  handleHideModal,
  id,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div className={styles.dropdown_container}>
      <div
        className={styles.dropdown_item}
        onClick={() => {
          dispatch(setShowViewWebUserPermissionModal({ show: true, id: id }));
          handleHideModal();
        }}
      >
        <img
          src={viewProfileIcon}
          alt="view-profile"
          className={styles.dropdown_image_item}
        />
        <Typography
          textAlign="left"
          hoverActive={true}
          hoverColors={colors.primary3}
          paddingVertical="10px"
        >
          View Web User Permissions
        </Typography>
      </div>
      <div
        className={styles.dropdown_item}
        onClick={() => {
          dispatch(setShowLinkLocationModal({ show: true, id: id }));
          handleHideModal();
        }}
      >
        <img
          src={linkLoctionIcon}
          alt="view-profile"
          className={styles.dropdown_image_item}
        />
        <Typography
          textAlign="left"
          hoverActive={true}
          hoverColors={colors.primary3}
          paddingVertical="10px"
        >
          Manage Location
        </Typography>
      </div>
      <div
        className={styles.dropdown_item}
        onClick={() => {
          dispatch(setShowAssignPermissionModal({ show: true, id: id }));
          handleHideModal();
        }}
      >
        <img
          src={compassIcon}
          alt="view-profile"
          className={styles.dropdown_image_item}
        />
        <Typography
          textAlign="left"
          hoverActive={true}
          hoverColors={colors.primary3}
          paddingVertical="10px"
        >
          Assign Permission Group
        </Typography>
      </div>
      <div
        className={styles.dropdown_item}
        onClick={() => {
          setWebUser(id);
          navigate("/admin/web-user-profile/form");
          // dispatch(setShowPermissionGroupDeleteModal({ show: true, id: id }));
          handleHideModal();
        }}
      >
        <img
          src={pencilIcon}
          alt="edit-profile"
          className={styles.dropdown_image_item}
        />
        <Typography
          textAlign="left"
          hoverActive={true}
          hoverColors={colors.primary3}
          paddingVertical="10px"
        >
          Edit Web User
        </Typography>
      </div>
      <div
        className={styles.dropdown_item}
        onClick={() => {
          setWebUser(id);
          dispatch(setShowDeleteWebUsers(true));
          handleHideModal();
        }}
      >
        <img
          src={binIcon}
          alt="view-profile"
          className={styles.dropdown_image_item}
        />
        <Typography
          textAlign="left"
          hoverActive={true}
          hoverColors={colors.primary3}
          paddingVertical="10px"
        >
          Delete Web User
        </Typography>
      </div>
    </div>
  );
};

export default WebUserTableDropdown;
