import ReactPaginate from "react-paginate";
import { PaginationProp } from "../../../types-definition/common";
import Styles from "./index.module.css";
import backwardicon from "../../../assets/svg/backward-icon.svg";
import backwardiconGray from "../../../assets/svg/backward-icon-gray.svg";
import forwardIcon from "../../../assets/svg/forward-icon.svg";
import forwardIconGray from "../../../assets/svg/forward-icon-gray.svg";
import Typography from "../Typography";
import { useState } from "react";
function PaginatedItems({
  itemsPerPage,
  items,
  handlePaginate,
}: PaginationProp) {
  const pageCount = Math.ceil(items.length / itemsPerPage);
  const [page, setPage] = useState<number>(0);
  const handlePageClick = (event: any) => {
    setPage(event.selected);
    handlePaginate(
      event.selected * itemsPerPage,
      event.selected * itemsPerPage + itemsPerPage,
      event.selected + 1,
    );
  };
  return (
    <>
      <div className={Styles.paginate_container}>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <Typography
                text="Next"
                textWeight="600"
                textSize="16px"
                textColor={page < pageCount - 1 ? "#344054" : "#98A2B3"}
              />
              <img
                src={page < pageCount - 1 ? forwardIcon : forwardIconGray}
                className={Styles.forward_icon}
                alt=""
              />
            </span>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            <span>
              <img
                src={page < 1 ? backwardiconGray : backwardicon}
                className={Styles.forward_icon}
                alt=""
              />
              <Typography
                text="Previous"
                textWeight="600"
                textSize="16px"
                textColor={page < 1 ? "#98A2B3" : "#344054"}
              />
            </span>
          }
          renderOnZeroPageCount={null}
          activeClassName={Styles.active}
        />
      </div>
    </>
  );
}

export default PaginatedItems;
