import React from "react";
import Typography from "../../../../../Common/Typography";
import styles from "./index.module.css";
import colors from "../../../../../../theme/colors";
import HolidayMultiplierTableItem from "../HolidayMultiplierTableItem";
import { HolidayMultiplierTableProps } from "../../../../../../types-definition/admin";
const HolidayMultiplierTable: React.FC<HolidayMultiplierTableProps> = ({
  data = [],
}) => {
  return (
    <>
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.tr} ${styles.tr_head}`}>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  marginHorizontal="24px"
                  text="Holiday Date"
                ></Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                  marginHorizontal="24px"
                  text="Holiday Name"
                ></Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                  marginHorizontal="24px"
                  text="Country/Region"
                ></Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                  marginHorizontal="24px"
                  text="Pay Rate Multiplier"
                ></Typography>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <HolidayMultiplierTableItem {...item} key={index} />
            ))}
            {/* <HolidayMultiplierTableItem  /> */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HolidayMultiplierTable;
