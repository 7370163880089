import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import Button from "components/Form/Button";
import searchIcon from "assets/svg/search-icon.svg";
import colors from "theme/colors";
import addIcon from "assets/svg/add-icon.svg";
import { useNavigate } from "react-router-dom";
import EmptyItemState from "components/Common/EmptyItemState";
import SuccessModal from "components/Common/SuccessModal";
import { getRequest } from "api/request";
import { GET_SERVICE_URL, GET_USER_SETTINGS_URL } from "api/urls";
import { ServiceData } from "types-definition/client";
import PageLoader from "components/Common/PageLoader";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import ServiceTable from "components/Client/Service/ServiceTable";
import { deleteService } from "utils/localstorage";
import ServiceTableFilter from "components/Client/Service/ServiceTableFilter";
import { useAppSelector } from "../../../../redux/hooks";
import {
  getFetchedServiceCounter,
  getShowDeleteServiceModal,
} from "../../../../redux/slice/client/serviceSlice";
import DeleteServiceModal from "components/Client/Service/DeleteServiceModal";
import { toastError } from "utils/toast";

const ServiceDashbaord = () => {
  const navigate = useNavigate();
  const counter = useAppSelector(getFetchedServiceCounter);
  const showDeleteModal = useAppSelector(getShowDeleteServiceModal);
  const [showSuccess, setShowSuccess] = useState(false);
  const [serviceData, setServiceData] = useState<Array<ServiceData>>([]);
  const [filteredServiceData, setFilteredServiceData] = useState<
    Array<ServiceData>
  >([]);
  const [sortFilter, setSortFilter] = useState("");
  const [isfilter, setIsfilter] = useState(false);
  const [searchedValues, setSearchedValues] = useState("");
  const [requestLoading, setRequestLoading] = useState(true);
  const [sortFilterName, setSortFilterName] = useState("");
  // const [addServicePermission, setAddServicePermission] = useState(true);

  useEffect(() => {
    const getAllService = async () => {
      try {
        const response = await getRequest(GET_SERVICE_URL);
        if (response?.success) {
          const sortedData = response?.data?.sort((a: any, b: any) => {
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          });
          const serviceDataArray: Array<ServiceData> = [];
          for (const index in sortedData) {
            const serviceDataObject: ServiceData = {
              id: response.data[index].id,
              name: response.data[index].name,
              grade: response.data[index].grade.grade,
              from: response.data[index].from,
              to: response.data[index].to,
              shiftStart: response.data[index].shiftStart,
              shiftEnd: response.data[index].shiftEnd,
              workHours: response.data[index].totalHoursInWeek,
              noOfEmployees: response.data[index].noOfEmployeeRequired,
              invoiceInInfoId: response.data[index].invoiceInfo.id,
              payInfoId: response.data[index].payInfo.id,
            };
            serviceDataArray.push(serviceDataObject);
          }
          setServiceData(serviceDataArray);
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      } finally {
        setRequestLoading(false);
      }
    };
    getAllService();
    deleteService();
  }, [counter]);

  // useEffect(() => {
  //   const getPermission = async () => {
  //     const data = checkAdminPermission(getUserTypes());
  //     if (data) {
  //       setAddServicePermission(false);
  //     } else {
  //       const addServiceResult = await checkUserPermission([
  //         LOCATION_SERVICES_ADD_UPDATE,
  //       ]);
  //       setAddServicePermission(addServiceResult ? false : true);
  //     }
  //   };
  //   getPermission();
  // }, []);

  // Filter Logic
  useEffect(() => {
    if (sortFilter && searchedValues) {
      const localServiceData = [...serviceData];
      setIsfilter(true);
      if (sortFilter === "Ascending Order") {
        setIsfilter(true);
        setFilteredServiceData(
          localServiceData
            .sort((a: ServiceData, b: ServiceData) => {
              if (sortFilterName === "Name") {
                return a.name.localeCompare(b.name);
              } else if (sortFilterName === "Grade") {
                return a.grade.localeCompare(b.grade);
              } else if (sortFilterName === "From") {
                return new Date(a.from).getTime() - new Date(b.from).getTime();
              } else if (sortFilterName === "To") {
                return new Date(a.to).getTime() - new Date(b.to).getTime();
              } else if (sortFilterName === "Work Hours") {
                return parseInt(a.workHours) - parseInt(b.workHours);
              } else if (sortFilterName === "No of Employee") {
                return parseInt(a.noOfEmployees) - parseInt(b.noOfEmployees);
              } else {
                return a.name.localeCompare(b.name);
              }
            })
            .filter((data: ServiceData) => {
              return (
                data.name
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.grade.toLowerCase().includes(searchedValues.toLowerCase())
              );
            }),
        );
      } else if (sortFilter === "Descending Order") {
        setIsfilter(true);
        setFilteredServiceData(
          localServiceData
            .sort((a: ServiceData, b: ServiceData) => {
              if (sortFilterName === "Name") {
                return b.name.localeCompare(a.name);
              } else if (sortFilterName === "Grade") {
                return b.grade.localeCompare(a.grade);
              } else if (sortFilterName === "From") {
                return new Date(b.from).getTime() - new Date(a.from).getTime();
              } else if (sortFilterName === "To") {
                return new Date(b.to).getTime() - new Date(a.to).getTime();
              } else if (sortFilterName === "Work Hours") {
                return parseInt(b.workHours) - parseInt(a.workHours);
              } else if (sortFilterName === "No of Employee") {
                return parseInt(b.noOfEmployees) - parseInt(a.noOfEmployees);
              } else {
                return b.name.localeCompare(a.name);
              }
            })
            .filter((data: ServiceData) => {
              return (
                data.name
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.grade.toLowerCase().includes(searchedValues.toLowerCase())
              );
            }),
        );
      }
    } else if (searchedValues) {
      setIsfilter(true);
      setFilteredServiceData(
        serviceData.filter((data: ServiceData) => {
          return (
            data.name.toLowerCase().includes(searchedValues.toLowerCase()) ||
            data.grade.toLowerCase().includes(searchedValues.toLowerCase())
          );
        }),
      );
    } else if (sortFilter) {
      setIsfilter(true);
      if (sortFilter === "Ascending Order") {
        const localServiceData = [...serviceData];
        setFilteredServiceData(
          localServiceData.sort((a: ServiceData, b: ServiceData) => {
            if (sortFilterName === "Name") {
              return a.name.localeCompare(b.name);
            } else if (sortFilterName === "Grade") {
              return a.grade.localeCompare(b.grade);
            } else if (sortFilterName === "From") {
              return new Date(a.from).getTime() - new Date(b.from).getTime();
            } else if (sortFilterName === "To") {
              return new Date(a.to).getTime() - new Date(b.to).getTime();
            } else if (sortFilterName === "Work Hours") {
              return parseInt(a.workHours) - parseInt(b.workHours);
            } else if (sortFilterName === "No of Employee") {
              return parseInt(a.noOfEmployees) - parseInt(b.noOfEmployees);
            } else {
              return a.name.localeCompare(b.name);
            }
          }),
        );
      } else if (sortFilter === "Descending Order") {
        setIsfilter(true);
        const localServiceData = [...serviceData];
        setFilteredServiceData(
          localServiceData.sort((a: ServiceData, b: ServiceData) => {
            if (sortFilterName === "Name") {
              return b.name.localeCompare(a.name);
            } else if (sortFilterName === "Grade") {
              return b.grade.localeCompare(a.grade);
            } else if (sortFilterName === "From") {
              return new Date(b.from).getTime() - new Date(a.from).getTime();
            } else if (sortFilterName === "To") {
              return new Date(b.to).getTime() - new Date(a.to).getTime();
            } else if (sortFilterName === "Work Hours") {
              return parseInt(b.workHours) - parseInt(a.workHours);
            } else if (sortFilterName === "No of Employee") {
              return parseInt(b.noOfEmployees) - parseInt(a.noOfEmployees);
            } else {
              return b.name.localeCompare(a.name);
            }
          }),
        );
      }
    } else {
      setIsfilter(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValues, sortFilter]);
  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="clients" />
        {showSuccess && (
          <SuccessModal
            text="Client Added successfully"
            cancelFunction={() => {
              setShowSuccess(false);
            }}
          />
        )}
        <div className={styles.container}>
          <div
            className={
              serviceData.length <= 5
                ? `${styles.item_container} ${styles.item_container_empty}`
                : `${styles.item_container} ${styles.item_container_fill}`
            }
          >
            <div className={styles.flex_item}>
              <div>
                <Typography textAlign="left" textWeight="600" textSize="20px">
                  Service
                </Typography>
                <Typography textAlign="left" textColor={colors.gray6}>
                  Organize your employees' work schedules using site locations.
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <ServiceTableFilter
                  runFilter={(data, name) => {
                    setSortFilter(data);
                    setSortFilterName(name);
                  }}
                  clearFilter={() => setSortFilter("")}
                />
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={searchedValues}
                  setValue={(value) => {
                    setSearchedValues(value);
                  }}
                />
                <Button
                  left={<img src={addIcon} alt="add" />}
                  marginHorizontal="10px"
                  marginTop="8px"
                  paddingVertical="7px"
                  width="180px"
                  isPrimary
                  text="Add Service"
                  onClick={() => navigate("/client/service/add")}
                />
              </div>
            </div>
            {serviceData.length === 0 ? (
              <EmptyItemState
                text="You haven't added any service yet"
                location="client/service/add"
                label="Add Service"
              />
            ) : (
              <ServiceTable
                data={isfilter ? filteredServiceData : serviceData}
              />
            )}
          </div>
        </div>
      </section>
      {showDeleteModal && <DeleteServiceModal />}
    </>
  );
};

export default ServiceDashbaord;
