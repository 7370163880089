import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { getRequest } from "../../../../../api/request";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import colors from "../../../../../theme/colors";
import Typography from "../../../../Common/Typography";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import PageBackButton from "../../../../Common/PageBackButton";
import { getGradeSlected } from "utils/getSelectedGrade";
const ActivityEmployeeOperations = () => {
  const id = getActivityBasicInformation();
  const [operations, setOperations] = useState({
    pin: "",
    grade: "",
  });
  const [previousOperations, setPreviousOperations] = useState({
    pin: "",
    grade: "",
  });

  useEffect(() => {
    const getActivityEmployeeContactInformation = async () => {
      try {
        const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
        if (response?.success) {
          const grade = await getGradeSlected(
            response?.data?.newRecord?.employeeOperations?.grade,
          );
          setOperations({
            pin: response?.data?.newRecord?.employeeOperations?.pin,
            grade: grade ? grade.grade : "--",
          });
          setPreviousOperations({
            pin: response?.data?.previousRecord?.employeeOperations?.pin,
            grade: grade ? grade.grade : "--",
          });
        }
      } catch (error) {}
    };
    getActivityEmployeeContactInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Operation Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {operations?.grade ? operations.grade : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Pin</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {operations?.pin ? operations?.pin : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Contact Information */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Operation Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousOperations?.grade ? previousOperations.grade : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Pin</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousOperations?.pin ? previousOperations?.pin : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityEmployeeOperations;
