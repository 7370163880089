import { useEffect, useRef, useState } from "react";
import chevronDownIcon from "../../../../assets/svg/chevron-down-icon.svg";
import downloadIcon from "../../../../assets/svg/download-icon.svg";
import colors from "../../../../theme/colors";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
const ExportCheckcallLogs = () => {
  const exportRef = useRef<HTMLDivElement>(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const exportEsvHandle = async () => {
    return alert("Simulating export");
  };

  const exportExcellHandle = async () => {
    return alert("Simulating export");
  };

  useEffect(() => {
    const onLeaveExport = (e: MouseEvent) => {
      if (
        exportRef.current !== e.target &&
        !exportRef.current?.contains(e.target as Node)
      ) {
        setShowDropDown(false);
      }
    };

    window.addEventListener("click", onLeaveExport);
    return () => window.removeEventListener("click", onLeaveExport);
  }, []);

  return (
    <div className={styles.container} ref={exportRef}>
      <div>
        <div
          className={styles.button_container}
          onClick={() => setShowDropDown((n) => !n)}
        >
          <Typography textColor={colors.primary3} textWeight="600">
            Export
          </Typography>
          <img src={chevronDownIcon} alt="add" />
        </div>
      </div>

      {showDropDown && (
        <div className={styles.dropdown_container}>
          <div
            className={styles.sub_dropdown_item}
            onClick={() => {
              setShowDropDown(false);
              exportEsvHandle();
            }}
          >
            <img
              src={downloadIcon}
              alt="chevron-down"
              className={styles.dropdown_image_item}
            />
            <Typography>Export as CSV</Typography>
          </div>
          {/* <div
            className={styles.sub_dropdown_item}
            onClick={() => {
              setShowDropDown(false);
              // exportEsvHandle();
            }}
          >
            <img
              src={downloadIcon}
              alt="chevron-down"
              className={styles.dropdown_image_item}
            />
            <Typography textAlign="left">Export as PDF</Typography>
          </div> */}
          <div
            className={styles.sub_dropdown_item}
            onClick={() => {
              exportExcellHandle();
              setShowDropDown(false);
            }}
          >
            <img
              src={downloadIcon}
              alt="chevron-down"
              className={styles.dropdown_image_item}
            />
            <Typography>Export as Excel</Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportCheckcallLogs;
