import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import NumberInput from "../../../../../Form/NumberInput";
import Typography from "../../../../../Common/Typography";
import Button from "../../../../../Form/Button";
import cancelIcon from "../../../../../../assets/svg/circle-cancel-icon.svg";
import {
  toggleHolidayMultiplierModal,
  reload,
} from "../../../../../../redux/slice/staff-hub/employeeHolidayMultiplierSlice";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { EDIT_EMPLOYEE_HOLIDAY_MULTIPLIER } from "../../../../../../api/urls";
import {
  HolidayMultiplierFormData,
  HolidayMultiplierRequiredFormData,
} from "../../../../../../types-definition/admin";
import colors from "../../../../../../theme/colors";
import { patchRequest } from "../../../../../../api/request";
import {
  deleteHoliday,
  deleteHolidayPayRate,
  getHolidayPayRate,
  getHoliday,
} from "../../../../../../utils/localstorage";
import { toastError } from "utils/toast";

const HolidayMultiplierModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const [requestLoading, setrequestLoading] = useState(false);
  const [holidayId, setHolidayId] = useState<String>("");
  const payRate = getHolidayPayRate();
  // if(getHolidayPayRate()){
  const [formData, setFormData] = useState<HolidayMultiplierFormData>({
    PayRateMultiplier: `${payRate}`,
  });
  const [requiredFormData, setRequiredFormData] =
    useState<HolidayMultiplierRequiredFormData>({
      PayRateMultiplier: false,
    });

  const handleSubmitForm = async () => {
    setrequestLoading(true);
    const response = await patchRequest(
      `${EDIT_EMPLOYEE_HOLIDAY_MULTIPLIER}/holiday-multiplier/holiday/${holidayId}`,
      {
        payRateMultiplier: Number(formData.PayRateMultiplier),
      },
    );
    if (response?.success) {
      dispatch(
        toggleHolidayMultiplierModal({
          showHolidayMultiplierModal: false,
        }),
      );
      dispatch(reload({ reload: true }));
      setrequestLoading(false);
    } else {
      setrequestLoading(false);
      toastError(response?.data);
    }
    deleteHoliday();
    deleteHolidayPayRate();
  };

  const handleFormCancelation = () => {
    dispatch(
      toggleHolidayMultiplierModal({
        showHolidayMultiplierModal: false,
      }),
    );
    deleteHoliday();
  };

  useEffect(() => {
    const id = getHoliday();
    if (id) {
      setHolidayId(id);
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600">Link Employee To Location</Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={handleFormCancelation}
          />
        </div>
        <NumberInput
          value={formData.PayRateMultiplier}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, PayRateMultiplier: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, PayRateMultiplier: false };
            });
          }}
          label="Pay Rate Multiplier"
          placeholder="1.00"
          error={requiredFormData.PayRateMultiplier}
        />
        <div className={styles.flex_item}>
          <Button
            text="Cancel"
            isPrimary={false}
            onClick={handleFormCancelation}
            backgroundColor={colors.transparent}
            isPrimaryBorder={false}
            borderColor={colors.primary3}
            borderWidth="2px"
            textColor={colors.primary3}
          />
          <Button
            text="Update"
            isPrimary={true}
            onClick={handleSubmitForm}
            isLoading={requestLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default HolidayMultiplierModal;
