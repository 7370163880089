import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  showConfirmResetPasswordModal: false,
  showSuccessResetPasswordModal: false,
};
export const employeeViewProfileSlice = createSlice({
  name: "employeeViewProfile",
  initialState,
  reducers: {
    setShowConfirmResetPasswordModal: (state, action) => {
      state.showConfirmResetPasswordModal = action.payload;
    },
    setShowSuccessResetPasswordModal: (state, action) => {
      state.showSuccessResetPasswordModal = action.payload;
    },
  },
});

export const {
  setShowConfirmResetPasswordModal,
  setShowSuccessResetPasswordModal,
} = employeeViewProfileSlice.actions;
export const getShowConfirmResetPasswordModal = (state: RootState) =>
  state.employeeViewProfile.showConfirmResetPasswordModal;
export const getShowSuccessResetPasswordModal = (state: RootState) =>
  state.employeeViewProfile.showSuccessResetPasswordModal;

export default employeeViewProfileSlice.reducer;
