import Typography from "../../Common/Typography";
import styles from "./index.module.css";
import colors from "../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getRosterCalendarView,
  setRosterCalendarView,
  setRosterFromDate,
} from "../../../redux/slice/roster/rosterSlice";
import { getFirstDayOfDate } from "../../../utils/timeConverter";

const RosterCalendarViewNavigation = () => {
  const active = useAppSelector(getRosterCalendarView);
  const dispatch = useAppDispatch();
  return (
    <div className={styles.container}>
      <div
        className={
          active === "day"
            ? styles.active_container_item_left
            : styles.container_item_left
        }
        onClick={() => {
          dispatch(setRosterCalendarView("day"));
        }}
      >
        <Typography
          textWeight="500"
          fontFamily="inter"
          textColor={active === "day" ? colors.white : colors.black}
        >
          Day
        </Typography>
      </div>
      <div
        className={
          active === "week"
            ? styles.active_container_item
            : styles.container_item
        }
        onClick={() => {
          dispatch(setRosterCalendarView("week"));
          dispatch(setRosterFromDate(getFirstDayOfDate(new Date())));
        }}
      >
        <Typography
          textWeight="500"
          fontFamily="inter"
          textColor={active === "week" ? colors.white : colors.black}
        >
          Week
        </Typography>
      </div>
      <div
        className={
          active === "month"
            ? styles.active_container_item_right
            : styles.container_item_right
        }
        onClick={() => {
          dispatch(setRosterCalendarView("month"));
        }}
      >
        <Typography
          textWeight="500"
          fontFamily="inter"
          textColor={active === "month" ? colors.white : colors.black}
        >
          Month
        </Typography>
      </div>
    </div>
  );
};

export default RosterCalendarViewNavigation;
