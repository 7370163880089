import React from "react";
import styles from "./index.module.css";
import successIcon from "../../../../../assets/svg/success-icon.svg";
import Typography from "../../../../Common/Typography";
import Button from "../../../../Form/Button";
import { useAppDispatch } from "../../../../../redux/hooks";
import { setShowSuccessResetPasswordModal } from "../../../../../redux/slice/staff-hub/employeeViewProfileSlice";
import { getCurrentEmployee } from "../../../../../utils/localstorage";

const EmployeeResetPasswordSuccessModal = () => {
  const dispatch = useAppDispatch();
  const employee = JSON.parse(getCurrentEmployee() ?? "");
  const handleContinue = () => {
    dispatch(setShowSuccessResetPasswordModal(false));
  };
  return (
    <section className={styles.container}>
      <div className={styles.content_container}>
        <img src={successIcon} alt="success-icon" />
        <Typography textAlign="left" textWeight="600" textSize="18px">
          Password Reset Email Sent
        </Typography>
        <Typography textAlign="left" marginVertical="15px">
          A new password has been sent to {employee?.displayName}'s email
          address.
        </Typography>
        <Button
          onClick={handleContinue}
          paddingVertical="5px"
          marginVertical="10px"
          text="Continue"
          isPrimary={true}
        />
      </div>
    </section>
  );
};

export default EmployeeResetPasswordSuccessModal;
