import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import { IncidentReportData } from "../../../../types-definition/client";
import capitalize from "../../../../utils/capitalize";

const IncidentReportTableItem: React.FC<IncidentReportData> = ({
  id,
  client,
  dateAndTime,
  description,
  incidentType,
  locations,
  reporter,
  service,
}) => {
  const [, setshowEditDropdown] = useState(false);

  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left">{capitalize(incidentType)}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {description ? description : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{dateAndTime ?? "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{reporter}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{locations}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{service ?? "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{client}</Typography>
      </td>
      <td
        className={`${styles.td} ${styles.td_relative}`}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        <div
          className={styles.image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default IncidentReportTableItem;
