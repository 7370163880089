import React from "react";
import { Link } from "react-router-dom";
import { LinkComponentProps } from "../../../types-definition/common";
import colors from "../../../theme/colors";

const LinkComponent: React.FC<LinkComponentProps> = ({
  children,
  link,
  showTextDecoration = false,
  textColor = colors.link,
}) => {
  return (
    <Link
      to={link}
      style={{
        textDecoration: showTextDecoration ? "line" : "none",
        textDecorationColor: textColor,
      }}
    >
      {children}
    </Link>
  );
};

export default LinkComponent;
