import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import IncidentReportTableItem from "../IncidentReportTableItem";
import { IncidentReportTableProps } from "../../../../types-definition/client";

const IncidentReportTable: React.FC<IncidentReportTableProps> = ({
  data = [],
}) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Incident Type
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Description
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Date and Time
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Reporter
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Location
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Service
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Clients
              </Typography>
            </th>
            <th className={styles.th_index}></th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return <IncidentReportTableItem {...item} key={item.id} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IncidentReportTable;
