const validatePostCode = (code: string) => {
  const postCode = code.replace(/\s/g, "");
  const regex = new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/);
  if (postCode.length < 6 || postCode.length > 10) {
    return false;
  }
  if (regex.test(postCode) === false) {
    return false;
  }
  if (typeof postCode[0] !== "string") {
    return false;
  }
  return true;
};

export default validatePostCode;
