import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

export interface IncidentReportState {
  showAddReportModal: boolean;
  requestCounter: number;
}

const initialState = {
  showAddReportModal: false,
  requestCounter: 0,
};

export const incidentReportSlice = createSlice({
  name: "incidentReport",
  initialState,
  reducers: {
    setShowIncidentReportModal: (state, action: PayloadAction<boolean>) => {
      state.showAddReportModal = action.payload;
    },
    setIncidentReportRequestCounter: (state) => {
      state.requestCounter += 1;
    },
  },
});

export const { setShowIncidentReportModal, setIncidentReportRequestCounter } =
  incidentReportSlice.actions;

export const getShowIncidentReportModal = (state: RootState) =>
  state.incidentReport.showAddReportModal;

export const getIncidentReportCounter = (state: RootState) =>
  state.incidentReport.requestCounter;

export default incidentReportSlice.reducer;
