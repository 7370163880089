import React, { useEffect, useState } from "react";
import { UkAddressInputSearchProps } from "../../../types-definition/form";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import dropDownIcon from "../../../assets/svg/select-dropdown.svg";
import colors from "../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getEmployeeFindAddressDropdown,
  setShowFindAddressDropdown,
} from "../../../redux/slice/staff-hub/employeeSlice";

const UkAddressSearchInput: React.FC<UkAddressInputSearchProps> = ({
  options = [],
  label,
  placeholder,
  value,
  setValue,
  height = "200px",
  width,
  required = false,
  error = false,
  datalistTop = "91px",
  dropdownTop = "55px",
  helperText,
  emptyOptionText,
  style,
}) => {
  // const [showDropDown, setShowDropDown] = useState(false);
  const dispatch = useAppDispatch();
  const showDropDown = useAppSelector(getEmployeeFindAddressDropdown);
  // Used this variable to prevent restting field when it is prefilled
  // const [isActive, setIsActive] = useState(false);
  // const [isSelected, setIsSelected] = useState(false);
  const [list, setList] = useState<Array<any>>([]);
  const handleListSelector = (item: any) => {
    setValue(value, item.id, item.name);
    // setIsSelected(true);
    dispatch(setShowFindAddressDropdown(false));
    // setShowDropDown(false);
  };
  const filterListFromString = (input: string) => {
    setValue(input);
    // setIsSelected(false);
  };
  // useEffect(() => {
  //   if (!isSelected && !showDropDown && isActive) {
  //     setValue("", "");
  //     setList(options);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSelected, showDropDown]);
  useEffect(() => {
    if (options) {
      setList(options);
    }
  }, [options]);
  return (
    <section className={styles.container} style={style}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        className={styles.select}
        onChange={(e) => {
          if (showDropDown) {
            dispatch(setShowFindAddressDropdown(false));
          }
          filterListFromString(e.target.value);
        }}
        onFocus={() => {
          // setIsActive(true);
        }}
        style={{ width, borderColor: error ? colors.error : colors.gray3 }}
      />

      <div className={styles.helpertext_container}>
        <Typography
          textAlign="left"
          textSize={"10px"}
          textWeight="600"
          textColor={error ? colors.error : colors.primary3}
        >
          {helperText}
        </Typography>
      </div>
      <div
        className={styles.dropdown_container}
        style={{ top: dropdownTop }}
        tabIndex={0}
        onClick={() => {
          // setShowDropDown(true);
          // setIsActive(true);
        }}
      >
        <img
          src={dropDownIcon}
          className={styles.dropdown}
          alt="dropdown icon"
        />
      </div>
      {showDropDown && (
        <div
          className={styles.datalist}
          style={{ maxHeight: height, top: datalistTop }}
        >
          {list.length === 0 ? (
            <p>{emptyOptionText ?? "Type to search Address"}</p>
          ) : (
            list.map((item) => (
              <p onClick={() => handleListSelector(item)} key={item.id}>
                {item.name}
              </p>
            ))
          )}
        </div>
      )}
    </section>
  );
};

export default UkAddressSearchInput;
