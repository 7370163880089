import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import Button from "../../Form/Button";

const ForgotPasswordSuccessModal = () => {
  const openMail = () => {
    window.open("mailto:", "_self");
  };
  return (
    <section className={styles.container}>
      <div className={styles.content_container}>
        <Typography textAlign="left" textWeight="600" textSize="18px">
          Password Reset Email Sent
        </Typography>
        <Typography textAlign="left" marginVertical="15px">
          You’re almost there! Check your email for instructions on resetting
          your password.
        </Typography>
        <Button
          onClick={openMail}
          paddingVertical="5px"
          marginVertical="10px"
          text="Open Email"
          isPrimary={true}
        />
      </div>
    </section>
  );
};

export default ForgotPasswordSuccessModal;
