import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import RosterViewNavigation from "components/Roster/RosterViewNavigation";
import RosterDateNavigation from "components/Roster/RosterDateNavigation";
import RosterTable from "components/Roster/RosterTable";
import AvaialableEmployeeRoster from "components/Roster/AvailableEmployeeRoster";
import EmployeeListRoster from "components/Roster/EmployeeListRoster";
import RosterColorIdentifier from "components/Roster/RosterColorIdentifier";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getIsRosterFocused,
  getRosterAvailabilityDate,
  getRosterCalendarView,
  getRosterFromDate,
  getRosterRequestCounter,
  getRosterServiceId,
  getRosterView,
  setRosterLocationId,
  setRosterServiceData,
} from "../../../../redux/slice/roster/rosterSlice";
import RosterCalendarViewNavigation from "components/Roster/RosterCalendarViewNavigation";
import RosterDayViewTable from "components/Roster/RosterDayViewTable";
import RosterWeekViewTable from "components/Roster/RosterWeekViewTable";
import RosterMonthView from "components/Roster/RosterMonthView";
import { getLocation } from "utils/localstorage";
import filterKeysFromArray from "utils/filterKeyFromArray";
import { getRequest, postRequest } from "api/request";
import { ACTIVE_BOARD, GET_LOCATION_URL } from "api/urls";
import ClientSelectInput from "components/Form/ClientSelectInput";
import PageLoader from "components/Common/PageLoader";
import moment from "moment";

const ClientRoster = () => {
  const dispatch = useAppDispatch();
  const view = useAppSelector(getRosterView);
  const calendarView = useAppSelector(getRosterCalendarView);
  const counter = useAppSelector(getRosterRequestCounter);
  const rosterAvailableDate = useAppSelector(getRosterAvailabilityDate);
  const rosterServiceId = useAppSelector(getRosterServiceId);
  const focused = useAppSelector(getIsRosterFocused);
  const [requestLoading, setRequestLoading] = useState(true);
  const [isRosterLoading, setIsRosterLoading] = useState(false);
  const [locationId, setLocationId] = useState(getLocation() || "");
  const [locationName, setLocationName] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [sectionData, setSectionData] = useState<Array<any>>([]);
  const [availableEmployeeData, setAvailableEmployeeData] = useState<
    Array<{ employeeName: string; employeeId: string }>
  >([]);

  const fromDate = useAppSelector(getRosterFromDate);

  const getActiveBoardByLocation = async (id: string) => {
    setRequestLoading(true);

    let queryFromDate = moment(fromDate, "MM/DD/yyyy").toISOString();
    let queryToDate = moment(fromDate, "MM/DD/yyyy")
      .endOf("week")
      .add(1, "day")
      .toISOString();

    if (view === "service-view") {
      queryToDate = moment(fromDate, "MM/DD/yyyy")
      .endOf("week")
      .add(1, "day")
      .toISOString();
    } else if (calendarView === "day") {
      queryToDate = moment(fromDate, "MM/DD/yyyy")
      .endOf("day")
      .toISOString();
    } else if (calendarView === "month") {
      queryToDate = moment(fromDate, "MM/DD/yyyy")
      .endOf("month")
      .toISOString();
    }

    const response = await getRequest(`${ACTIVE_BOARD}/location/${id}?perPage=9999&from=${queryFromDate}&to=${queryToDate}`);
    // setServiceData(response?.data);
    dispatch(setRosterServiceData(response?.data?.activeBoard));
    let serviceSegment: Array<any> = [];
    if (response?.success) {
      const serviceIds = filterKeysFromArray(response?.data?.activeBoard, "serviceId");
      for (const serviceId of serviceIds) {
        const data = response.data?.activeBoard.filter(
          (i: any) => i.serviceId === serviceId,
        );
        const serviceName = data[0]?.service?.name;
        serviceSegment = [
          ...serviceSegment,
          { shiftName: serviceName, data: data },
        ];
        // getSuggestedEmployee(serviceId);
      }
      setSectionData(serviceSegment);
      setRequestLoading(false);
    }
  };
  const getSuggestedEmployee = async (id: string) => {
    setIsRosterLoading(false);
    const fromDateItem = new Date();
    const response = await postRequest(
      `${ACTIVE_BOARD}/service/${id}/suggest`,
      {
        date: moment.utc(fromDateItem).toISOString(),
      },
    );
    if (response?.success) {
      const removeDuplicatedEmployees = response?.data
        ?.filter(
          (item: any, index: number, array: any) =>
            array.findIndex(
              (i: any) => i?.employee?.id === item?.employee?.id,
            ) === index,
        )
        .map((i: any) => {
          return {
            employeeName: `${i?.employee?.firstName} ${i?.employee?.lastName}`,
            employeeId: i.employee?.id,
          };
        });
      const allAvailableEmployees = [
        ...availableEmployeeData,
        ...removeDuplicatedEmployees,
      ];
      setAvailableEmployeeData(
        allAvailableEmployees.filter(
          (item: any, index: number, array: any) =>
            array.findIndex(
              (i: any) => i?.employee?.id === item?.employee?.id,
            ) === index,
        ),
      );
    }
    setIsRosterLoading(false);
  };
  useEffect(() => {
    const getLocation = async () => {
      const response = await getRequest(GET_LOCATION_URL);
      if (response?.success) {
        const currentLocationData = response?.data?.find(
          (i: any) => i.id === locationId,
        );
        getActiveBoardByLocation(
          currentLocationData?.id ?? response?.data[0]?.id,
        );
        setLocationName(currentLocationData?.name ?? response?.data[0]?.name);
        setLocationData(
          response?.data.map((i: any) => {
            return {
              name: i.name,
              id: i.id,
            };
          }),
        );
      }
    };
    getLocation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (locationId) {
      getActiveBoardByLocation(locationId);
      dispatch(setRosterLocationId(locationId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, counter]);
  useEffect(() => {
    if (rosterAvailableDate && rosterServiceId) {
      getSuggestedEmployee(rosterServiceId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rosterServiceId, focused]);
  return (
    <>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="operations" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_sub_container}>
            <div className={styles.flex_item_container}>
              <div className={styles.roster_navigation_container}>
                <RosterViewNavigation />
                <div className={styles.roster_navigation_flex_container}>
                  <RosterDateNavigation />
                  {view === "calendar-view" && <RosterCalendarViewNavigation />}
                </div>
              </div>
              <div className={styles.select_container}>
                <ClientSelectInput
                  label="Location"
                  value={locationName}
                  setValue={(name, id) => {
                    if (name) {
                      setLocationName(name);
                    }
                    if (id) {
                      setLocationId(id);
                    }
                  }}
                  client={locationName}
                  placeholder="Ebeanor"
                  dropdownTop="48px"
                  datalistTop="83px"
                  options={locationData}
                  readonly
                />
              </div>
            </div>
            <div className={styles.flex_item_container_1}>
              <div className={styles.roster_table}>
                {view === "service-view" ? (
                  <RosterTable data={sectionData} />
                ) : calendarView === "day" ? (
                  <RosterDayViewTable />
                ) : calendarView === "week" ? (
                  <RosterWeekViewTable />
                ) : (
                  <RosterMonthView />
                )}
              </div>
              <div className={styles.employee_table}>
                <AvaialableEmployeeRoster
                  isLoading={isRosterLoading}
                  data={availableEmployeeData}
                />
                <EmployeeListRoster />
              </div>
            </div>
            {view === "service-view" && <RosterColorIdentifier />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientRoster;
