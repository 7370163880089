import React, { useEffect } from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import { RosterTableItemsProps } from "../../../types-definition/roster";
import colors from "../../../theme/colors";
import RosterSingleItem from "../RosterSingleItem";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getRosterFromDate,
  setIsRosterFocused,
} from "../../../redux/slice/roster/rosterSlice";
import moment from "moment";
import {
  CANCELLED,
  CONFIRMED,
  ENDED,
  STARTED,
} from "types-definition/constants";
// import stringToDate from "../../../utils/formatDate";

const RosterTableItem: React.FC<RosterTableItemsProps> = ({
  shiftName,
  data,
}) => {
  const dispatch = useAppDispatch();
  const date = useAppSelector(getRosterFromDate);

  useEffect(() => {
    const roosterItemClick = (event: any) => {
      const activeRoosterItems = document.querySelectorAll(
        "[data-rooster-item]",
      );
      const employeeSuggestions = document.querySelectorAll(
        "[data-rooster-suggestion-for]",
      );

      const clickedRoosterItem = Array.from(activeRoosterItems).find(
        (item: any) =>
          item === event.target || item.contains(event.target as Node),
      );

      const clickedRoosterSuggestion = Array.from(employeeSuggestions).find(
        (item: any) =>
          item === event.target || item.contains(event.target as Node),
      );

      if (!clickedRoosterItem && !clickedRoosterSuggestion) {
        setTimeout(() => dispatch(setIsRosterFocused(false)), 100);
      }
    };

    window.addEventListener("click", roosterItemClick);
    return () => window.removeEventListener("click", roosterItemClick);
  }, [dispatch]);

  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <div className={styles.td_item}>
          <Typography textAlign="center" textColor={colors.primary3}>
            {shiftName}
          </Typography>
        </div>
      </td>
      <td className={styles.td}>
        {data
          .filter((i: any) => {
            return (
              new Date(i.date).getFullYear() === new Date(date).getFullYear() &&
              new Date(i.date).getMonth() === new Date(date).getMonth() &&
              new Date(i.date).getDate() === new Date(date).getDate()
            );
          })
          .map((item, index) => {
            return (
              <div key={index} className={styles.td_item}>
                <RosterSingleItem
                  id={item.id}
                  name={`${item?.employee?.lastName} ${item?.employee?.firstName}`}
                  startTime={item.startTime}
                  endTime={item.endTime}
                  date={item.date}
                  serviceId={item.serviceId}
                  type={
                    item.status === CANCELLED
                      ? "cancelled-shift"
                      : item.status === ENDED
                        ? "completed-shift"
                        : item.status === STARTED
                          ? "in-progress-shift"
                          : item.status === "MISSED"
                            ? "dropped-shift"
                            : item.status === CONFIRMED
                              ? "confirmed-shift"
                              : "scheduled-shift"
                  }
                />
              </div>
            );
          })}
      </td>
      <td className={styles.td}>
        {data
          .filter((i: any) => {
            return (
              new Date(i.date).getFullYear() ===
                new Date(
                  moment(date).add(1, "day").format("MM/DD/YYYY"),
                ).getFullYear() &&
              new Date(i.date).getMonth() ===
                new Date(
                  moment(date).add(1, "day").format("MM/DD/YYYY"),
                ).getMonth() &&
              new Date(i.date).getDate() ===
                new Date(
                  moment(date).add(1, "day").format("MM/DD/YYYY"),
                ).getDate()
            );
          })
          .map((item, index) => {
            return (
              <div key={index} className={styles.td_item}>
                <RosterSingleItem
                  id={item.id}
                  name={`${item?.employee?.lastName} ${item?.employee?.firstName}`}
                  startTime={item.startTime}
                  endTime={item.endTime}
                  date={item.date}
                  serviceId={item.serviceId}
                  type={
                    item.status === CANCELLED
                      ? "cancelled-shift"
                      : item.status === ENDED
                        ? "completed-shift"
                        : item.status === STARTED
                          ? "in-progress-shift"
                          : item.status === "MISSED"
                            ? "dropped-shift"
                            : item.status === CONFIRMED
                              ? "confirmed-shift"
                              : "scheduled-shift"
                  }
                />
              </div>
            );
          })}
      </td>
      <td className={styles.td}>
        {data
          .filter(
            (i: any) =>
              new Date(i.date).getFullYear() ===
                new Date(
                  moment(date).add(2, "days").format("MM/DD/YYYY"),
                ).getFullYear() &&
              new Date(i.date).getMonth() ===
                new Date(
                  moment(date).add(2, "days").format("MM/DD/YYYY"),
                ).getMonth() &&
              new Date(i.date).getDate() ===
                new Date(
                  moment(date).add(2, "days").format("MM/DD/YYYY"),
                ).getDate(),
          )
          .map((item, index) => {
            return (
              <div key={index} className={styles.td_item}>
                <RosterSingleItem
                  id={item.id}
                  name={`${item?.employee?.lastName} ${item?.employee?.firstName}`}
                  startTime={item.startTime}
                  endTime={item.endTime}
                  date={item.date}
                  serviceId={item.serviceId}
                  type={
                    item.status === CANCELLED
                      ? "cancelled-shift"
                      : item.status === ENDED
                        ? "completed-shift"
                        : item.status === STARTED
                          ? "in-progress-shift"
                          : item.status === "MISSED"
                            ? "dropped-shift"
                            : item.status === CONFIRMED
                              ? "confirmed-shift"
                              : "scheduled-shift"
                  }
                />
              </div>
            );
          })}
      </td>
      <td className={styles.td}>
        {data
          .filter(
            (i: any) =>
              new Date(i.date).getFullYear() ===
                new Date(
                  moment(date).add(3, "days").format("MM/DD/YYYY"),
                ).getFullYear() &&
              new Date(i.date).getMonth() ===
                new Date(
                  moment(date).add(3, "days").format("MM/DD/YYYY"),
                ).getMonth() &&
              new Date(i.date).getDate() ===
                new Date(
                  moment(date).add(3, "days").format("MM/DD/YYYY"),
                ).getDate(),
          )
          .map((item, index) => {
            return (
              <div key={index} className={styles.td_item}>
                <RosterSingleItem
                  id={item.id}
                  name={`${item?.employee?.lastName} ${item?.employee?.firstName}`}
                  startTime={item.startTime}
                  endTime={item.endTime}
                  date={item.date}
                  serviceId={item.serviceId}
                  type={
                    item.status === CANCELLED
                      ? "cancelled-shift"
                      : item.status === ENDED
                        ? "completed-shift"
                        : item.status === STARTED
                          ? "in-progress-shift"
                          : item.status === "MISSED"
                            ? "dropped-shift"
                            : item.status === CONFIRMED
                              ? "confirmed-shift"
                              : "scheduled-shift"
                  }
                />
              </div>
            );
          })}
      </td>
      <td className={styles.td}>
        {data
          .filter(
            (i: any) =>
              new Date(i.date).getFullYear() ===
                new Date(
                  moment(date).add(4, "days").format("MM/DD/YYYY"),
                ).getFullYear() &&
              new Date(i.date).getMonth() ===
                new Date(
                  moment(date).add(4, "days").format("MM/DD/YYYY"),
                ).getMonth() &&
              new Date(i.date).getDate() ===
                new Date(
                  moment(date).add(4, "days").format("MM/DD/YYYY"),
                ).getDate(),
          )
          .map((item, index) => {
            return (
              <div key={index} className={styles.td_item}>
                <RosterSingleItem
                  id={item.id}
                  name={`${item?.employee?.lastName} ${item?.employee?.firstName}`}
                  startTime={item.startTime}
                  endTime={item.endTime}
                  date={item.date}
                  serviceId={item.serviceId}
                  type={
                    item.status === CANCELLED
                      ? "cancelled-shift"
                      : item.status === ENDED
                        ? "completed-shift"
                        : item.status === STARTED
                          ? "in-progress-shift"
                          : item.status === "MISSED"
                            ? "dropped-shift"
                            : item.status === CONFIRMED
                              ? "confirmed-shift"
                              : "scheduled-shift"
                  }
                />
              </div>
            );
          })}
      </td>

      <td className={styles.td}>
        {data
          .filter(
            (i: any) =>
              new Date(i.date).getFullYear() ===
                new Date(
                  moment(date).add(5, "days").format("MM/DD/YYYY"),
                ).getFullYear() &&
              new Date(i.date).getMonth() ===
                new Date(
                  moment(date).add(5, "days").format("MM/DD/YYYY"),
                ).getMonth() &&
              new Date(i.date).getDate() ===
                new Date(
                  moment(date).add(5, "days").format("MM/DD/YYYY"),
                ).getDate(),
          )
          .map((item, index) => {
            return (
              <div key={index} className={styles.td_item}>
                <RosterSingleItem
                  id={item.id}
                  name={`${item?.employee?.lastName} ${item?.employee?.firstName}`}
                  startTime={item.startTime}
                  endTime={item.endTime}
                  date={item.date}
                  serviceId={item.serviceId}
                  type={
                    item.status === CANCELLED
                      ? "cancelled-shift"
                      : item.status === ENDED
                        ? "completed-shift"
                        : item.status === STARTED
                          ? "in-progress-shift"
                          : item.status === "MISSED"
                            ? "dropped-shift"
                            : item.status === CONFIRMED
                              ? "confirmed-shift"
                              : "scheduled-shift"
                  }
                />
              </div>
            );
          })}
      </td>

      <td className={styles.td}>
        {data
          .filter(
            (i: any) =>
              new Date(i.date).getFullYear() ===
                new Date(
                  moment(date).add(6, "days").format("MM/DD/YYYY"),
                ).getFullYear() &&
              new Date(i.date).getMonth() ===
                new Date(
                  moment(date).add(6, "days").format("MM/DD/YYYY"),
                ).getMonth() &&
              new Date(i.date).getDate() ===
                new Date(
                  moment(date).add(6, "days").format("MM/DD/YYYY"),
                ).getDate(),
          )
          .map((item, index) => {
            return (
              <div key={index} className={styles.td_item}>
                <RosterSingleItem
                  id={item.id}
                  name={`${item?.employee?.lastName} ${item?.employee?.firstName}`}
                  startTime={item.startTime}
                  endTime={item.endTime}
                  date={item.date}
                  serviceId={item.serviceId}
                  type={
                    item.status === CANCELLED
                      ? "cancelled-shift"
                      : item.status === ENDED
                        ? "completed-shift"
                        : item.status === STARTED
                          ? "in-progress-shift"
                          : item.status === "MISSED"
                            ? "dropped-shift"
                            : item.status === CONFIRMED
                              ? "confirmed-shift"
                              : "scheduled-shift"
                  }
                />
              </div>
            );
          })}
      </td>
    </tr>
  );
};

export default RosterTableItem;
