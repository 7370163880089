import { useEffect, useState } from "react";
import styles from "./index.module.css";
import SiteLocationNavigation from "components/Client/Site Location/SiteLocationNavigation";
import DashboardHeader from "components/Common/DashboardHeader";
import Typography from "components/Common/Typography";
import Checkbox from "components/Form/Checkbox";
import Button from "components/Form/Button";
import colors from "theme/colors";
import SearchSelectInput from "components/Form/SearchSelectInput";
import RadioInput from "components/Form/RadioInput";
import { getCertificate, getLocation, getUserTypes } from "utils/localstorage";
import { getRequest, patchRequest } from "api/request";
import { CREATE_LOCATION_URL, GET_LOCATION_URL } from "api/urls";
import { ServiceCertificate } from "types-definition/client";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { toastError, toastSuccess } from "utils/toast";
import { CERTIFICATE_TYPE_URL } from "api/urls";
import { updateSetupComplete } from "utils/setupWizard";
import PageLoader from "components/Common/PageLoader";
import CertificateSearchSelectInput from "components/Form/CertificateSearchSelectInput";

const SiteLocationCertificate = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    applyCertToService: false,
    requiredCert: [],
    mustHaveAllCerts: true,
    mustPossesAtLeastOneCert: false,
  });

  const [certificateType, setCertificateType] = useState<Array<any>>([]);
  const [fullCertificateTypeData, setFullCertificateTypeData] = useState<
    Array<any>
  >([]);
  const [requiredFormData, setRequiredFormData] = useState<ServiceCertificate>({
    requiredCert: false,
  });
  const [requestLoading, setRequestLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const handleSubmitForm = async () => {
    if (formData.requiredCert.length < 1 && formData.applyCertToService) {
      setRequiredFormData((prevData) => {
        return { ...prevData, requiredCert: true };
      });
      toast.error("Please select a certificate type", {
        duration: 4000,
        position: "top-center",
      });
    } else {
      try {
        setRequestLoading(true);
        const locationId = getLocation();
        const certificateId = getCertificate();
        const response = await patchRequest(
          `${CREATE_LOCATION_URL}/${locationId}/required-cert/${certificateId}`,
          formData,
        );
        if (response?.success) {
          toastSuccess("Client Site Certificate Updated");
          const setUpComplete = await updateSetupComplete(
            "12",
            false,
            "location",
          );

          const userType = getUserTypes();
          if (!setUpComplete && userType !== "Web User") {
            navigate("/setup-wizard");
          } else {
            navigate(`/client/site`);
          }
        } else {
          toastError(response?.data);
        }
      } finally {
        setRequestLoading(false);
      }
    }
  };

  const handleFormCancelation = () => {
    navigate("/client/site");
  };
  useEffect(() => {
    const getCertificateInformation = async () => {
      const data = getLocation();
      if (data) {
        const response = await getRequest(`${GET_LOCATION_URL}/${data}`);
        if (response?.success && response.data?.locationRequiredCertificate) {
          setFormData({
            applyCertToService:
              response.data.locationRequiredCertificate.applyCertToService ??
              false,
            requiredCert:
              response.data.locationRequiredCertificate.requiredCert ?? [],
            mustHaveAllCerts:
              response.data.locationRequiredCertificate.mustHaveAllCerts ??
              true,
            mustPossesAtLeastOneCert:
              response.data.locationRequiredCertificate
                .mustPossesAtLeastOneCert === "false"
                ? false
                : response.data.locationRequiredCertificate
                      .mustPossesAtLeastOneCert
                  ? response.data.locationRequiredCertificate
                      .mustPossesAtLeastOneCert
                  : false,
          });
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      }
    };
    getCertificateInformation();
  }, []);

  useEffect(() => {
    const getCertificateType = async () => {
      const response = await getRequest(`${CERTIFICATE_TYPE_URL}`);
      if (response?.success) {
        setCertificateType(
          response?.data?.certificateTypes?.map((item: any) => {
            return item.id;
          }),
        );
        setFullCertificateTypeData(response?.data?.certificateTypes);
        setPageLoading(false);
      }
    };
    getCertificateType();
  }, []);

  return (
    <section>
      <ScrollToTop />
      {pageLoading ? (
        <PageLoader />
      ) : (
        <div>
          <DashboardHeader active="clients" />
          <div className={styles.container}>
            <div className={styles.item_container}>
              <div className={styles.item_container_text}>
                <Typography textAlign="left" textWeight="600" textSize="24px">
                  Add Site Location
                </Typography>
              </div>
              <SiteLocationNavigation active="required certificate" />
              <div className={styles.content_container}>
                <div className={styles.checkbox_container}>
                  <Checkbox
                    label="Apply Certificates to Services"
                    value={formData.applyCertToService}
                    setValue={(data) => {
                      setFormData((prevData) => {
                        setCertificateType([...certificateType]);
                        return { ...prevData, applyCertToService: data };
                      });
                      if (data === false) {
                        setRequiredFormData({ requiredCert: false });
                      }
                    }}
                  />
                </div>
                <CertificateSearchSelectInput
                  label="Certificate Type"
                  placeholder=""
                  onFocus={() => {
                    setCertificateType([...certificateType]);
                  }}
                  certificateTypes={fullCertificateTypeData}
                  value={formData.requiredCert}
                  setValue={(data) => {
                    setFormData((prevData) => {
                      return { ...prevData, requiredCert: data };
                    });
                    setRequiredFormData({ requiredCert: false });
                  }}
                  options={certificateType}
                  disabled={!formData.applyCertToService}
                  error={requiredFormData.requiredCert}
                  helperText={
                    requiredFormData.requiredCert
                      ? "Certificate Type is required"
                      : ""
                  }
                />
                <div className={styles.form_flex_container}>
                  <div>
                    <Typography
                      textWeight="600"
                      textColor={
                        !formData.applyCertToService
                          ? colors.gray1
                          : colors.black
                      }
                      textSize="17px"
                    >
                      Certificate options *
                    </Typography>
                  </div>
                  <div className={styles.radio_container}>
                    <RadioInput
                      label="Must have all certificates"
                      name="option"
                      value={formData.mustHaveAllCerts}
                      setValue={(data) =>
                        setFormData((prevData) => {
                          return { ...prevData, mustHaveAllCerts: data };
                        })
                      }
                      disabled={formData.applyCertToService}
                    />
                    <div className={styles.flex_item}>
                      {" "}
                      <RadioInput
                        label=""
                        name="option"
                        value={formData.mustPossesAtLeastOneCert}
                        setValue={(data) =>
                          setFormData((prevData) => {
                            return {
                              ...prevData,
                              mustPossesAtLeastOneCert: data,
                            };
                          })
                        }
                        disabled={formData.applyCertToService}
                      />
                      <Typography
                        textAlign="left"
                        textWeight="400"
                        textSize="14px"
                        style={{
                          marginTop: "-2px",
                          width: "230px",
                        }}
                      >
                        Must possess at least one of the certificates
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={styles.button_container}>
                  <Button
                    width="200px"
                    text="Cancel"
                    isPrimary={false}
                    onClick={handleFormCancelation}
                    marginHorizontal="10px"
                    backgroundColor={colors.transparent}
                    isPrimaryBorder={true}
                  />
                  <Button
                    width="200px"
                    text="Save"
                    isPrimary={true}
                    onClick={handleSubmitForm}
                    isLoading={requestLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default SiteLocationCertificate;
