import { getRequest } from "../api/request";
import { GET_ADDRESS } from "../api/urls";

const getLatitudeAndLongitudeFromPostCode = async (postCode: string) => {
  const response = await getRequest(`${GET_ADDRESS}?postcode=${postCode}`);
  const suggestion = response?.data?.suggestions[0];
  if (suggestion?.id) {
    const response = await getRequest(`${GET_ADDRESS}/${suggestion.id}`);
    if (response?.data && response?.success) {
      return {
        latitude: response?.data?.latitude,
        longitude: response?.data?.longitude,
      };
    }
  }

  return response?.data;
};
export default getLatitudeAndLongitudeFromPostCode;
