import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { ServiceTableProps } from "../../../../types-definition/client";
import ServiceTableItem from "../ServiceTableItem";

const ServiceTable: React.FC<ServiceTableProps> = ({ data = [] }) => {
  return (
    <>
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.tr} ${styles.tr_head}`}>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Name
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Grade
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  From
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  To
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Shift Start
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Shift End
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Work Hours
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  No Of Employees
                </Typography>
              </th>
              <th className={styles.th_index}></th>
            </tr>
          </thead>
          {/* <EmployeeTableItem /> */}
          <tbody>
            {data?.map((item, index) => {
              return <ServiceTableItem {...item} key={index} />;
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ServiceTable;
