import React from "react";
import Typography from "../../../Common/Typography";
import deleteIcon from "../../../../assets/svg/error-icon.svg";
import styles from "./index.module.css";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch } from "../../../../redux/hooks";
import { deleteRequest } from "../../../../api/request";
import { DELETE_CERTIFICATE_GROUP_URL } from "../../../../api/urls";
import toast from "react-hot-toast";
import { getCertificateGroup } from "../../../../utils/localstorage";
import {
  updateCertificateGroupCounter,
  updateShowDeleteCertificateGroupModal,
} from "../../../../redux/slice/staff-hub/certificateGroupSlice";
import { toastSuccess } from "../../../../utils/toast";

const DeleteCertificateGroup: React.FC = () => {
  const dispatch = useAppDispatch();
  const id = getCertificateGroup();
  const deleteAction = async () => {
    const response = await deleteRequest(
      `${DELETE_CERTIFICATE_GROUP_URL}/${id}`,
    );
    if (response?.success) {
      toastSuccess("Certificate Group Deleted");
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    dispatch(updateShowDeleteCertificateGroupModal(false));
    dispatch(updateCertificateGroupCounter());
  };
  const cancelAction = () => {
    dispatch(updateShowDeleteCertificateGroupModal(false));
  };
  return (
    <div className={styles.container}>
      <img src={deleteIcon} alt="cancel icon" className={styles.cancelImage} />
      <Typography textWeight="600" textSize="18px" textAlign="left">
        Delete Record
      </Typography>
      <div className={styles.mid_text}>
        <Typography textColor={colors.gray4} textAlign="left">
          Are you sure you want to delete this record?
        </Typography>
        <Typography
          textColor={colors.gray4}
          textAlign="left"
          marginVertical="-8px"
        >
          This action cannot be undone.
        </Typography>
      </div>
      <div className={styles.button_container}>
        <Button
          width="160px"
          text="Cancel"
          isPrimary={false}
          onClick={cancelAction}
          backgroundColor={colors.transparent}
          isPrimaryBorder={true}
        />
        <Button
          width="160px"
          text="Delete"
          backgroundColor={colors.error}
          textColor={colors.white}
          isPrimary={false}
          onClick={deleteAction}
        />
      </div>
    </div>
  );
};

export default DeleteCertificateGroup;
