import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import moment from "moment";
import { getFirstDayOfDate } from "../../../utils/timeConverter";

interface InitialStateInterface {
  fromDate: string;
  avalabilityDate: string;
  toDate: string;
  view: "service-view" | "calendar-view";
  calendarView: "day" | "month" | "week";
  locationId: string;
  serviceId: string;
  serviceData: Array<any>;
  counter: number;
  activeRosterSingleItem: string;
  isRosterFocused: boolean;
}

const initialState: InitialStateInterface = {
  fromDate: moment().startOf("week").add(1, "day").format("MM/DD/yyyy"),
  avalabilityDate: "",
  toDate: moment(new Date()).add(5, "days").format("MM/DD/yyyy"),
  view: "service-view",
  calendarView: "day",
  locationId: "",
  serviceId: "",
  serviceData: [],
  counter: 0,
  activeRosterSingleItem: "",
  isRosterFocused: false,
};

export const rosterSlice = createSlice({
  name: "roster",
  initialState,
  reducers: {
    setRosterFromDate: (state, action) => {
      state.fromDate = action.payload;
    },
    setRosterToDate: (state, action) => {
      state.toDate = action.payload;
    },
    setRosterAvailabilityDate: (state, action) => {
      state.avalabilityDate = action.payload;
    },
    setRosterView: (
      state,
      action: PayloadAction<"service-view" | "calendar-view">,
    ) => {
      state.view = action.payload;
    },
    setRosterCalendarView: (
      state,
      action: PayloadAction<"day" | "month" | "week">,
    ) => {
      state.calendarView = action.payload;
    },
    setRosterLocationId: (state, action: PayloadAction<string>) => {
      state.locationId = action.payload;
    },
    setRosterServiceId: (state, action: PayloadAction<string>) => {
      state.serviceId = action.payload;
    },
    setRosterServiceData: (state, action: PayloadAction<Array<any>>) => {
      state.serviceData = action.payload;
    },
    setRosterRequestCounter: (state) => {
      state.counter = state.counter + 1;
    },
    setActiveRosterSingleItem: (state, action: PayloadAction<string>) => {
      state.activeRosterSingleItem = action.payload;
    },
    setIsRosterFocused: (state, action: PayloadAction<boolean>) => {
      state.isRosterFocused = action.payload;
    },
  },
});
export const {
  setRosterFromDate,
  setRosterToDate,
  setRosterView,
  setRosterCalendarView,
  setRosterLocationId,
  setRosterServiceId,
  setRosterServiceData,
  setRosterRequestCounter,
  setActiveRosterSingleItem,
  setRosterAvailabilityDate,
  setIsRosterFocused,
} = rosterSlice.actions;

export const getRosterFromDate = (state: RootState) => state.roster.fromDate;
export const getRosterToDate = (state: RootState) => state.roster.toDate;
export const getRosterView = (state: RootState) => state.roster.view;
export const getRosterCalendarView = (state: RootState) =>
  state.roster.calendarView;
export const getRosterLocationId = (state: RootState) =>
  state.roster.locationId;
export const getRosterServiceId = (state: RootState) => state.roster.serviceId;
export const getRosterServiceData = (state: RootState) =>
  state.roster.serviceData;
export const getRosterRequestCounter = (state: RootState) =>
  state.roster.counter;
export const getActiveRosterSingleItem = (state: RootState) =>
  state.roster.activeRosterSingleItem;
export const getRosterAvailabilityDate = (state: RootState) =>
  state.roster.avalabilityDate;
export const getIsRosterFocused = (state: RootState) =>
  state.roster.isRosterFocused;

export default rosterSlice.reducer;
