import React from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import { EmployeeAuditTableProps } from "../../../../../types-definition/StaffHub/employee";
import EmployeeAuditTableItem from "../EmployeeAuditTableItem";

const EmployeeAuditTable: React.FC<EmployeeAuditTableProps> = ({
  data = [
    {
      action: "Update",
      category: "Employee",
      changedAt: "01/02/2024",
    },
  ],
}) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Category
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Changed At
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Action
              </Typography>
            </th>
            <th className={styles.th_index}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textColor={colors.tableText}
              ></Typography>
            </th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data.map((item: any, index: number) => {
            return <EmployeeAuditTableItem {...item} key={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeAuditTable;
