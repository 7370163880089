import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import emailIcon from "../../../../assets/svg/Mail Icon.svg";
import Checkbox from "../../../Form/Checkbox";
import { SiteLocationAddressFormProps } from "../../../../types-definition/client";
import SelectInput from "../../../Form/SelectInput";
import { Country } from "country-state-city";
import AddressSearchInput from "../../../Form/AddressInputSearch";
import { getCountry } from "../../../../utils/localstorage";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const SiteLocationAddressForm: React.FC<SiteLocationAddressFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  const [address, setAddress] = useState<Array<any>>([]);
  const country = getCountry();
  const isoCode = Country.getAllCountries().find((i) => i.name === country)
    ?.isoCode;
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: "AIzaSyAM8qnCnyXYgNppoFnjGxQrV-dYo5_1OpQ",
      options: {
        componentRestrictions: {
          country: isoCode?.toLowerCase(),
        },
      },
    });
  useEffect(() => {
    setAddress(placePredictions.map((i) => i.description));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlacePredictionsLoading]);

  return (
    <div className={styles.container}>
      <div className={styles.form_flex_container}>
        <AddressSearchInput
          value={formData.address1}
          setValue={(data) => {
            getPlacePredictions({ input: data.value });
            if (data.action) {
              setFormData((prevData) => {
                const length = data.value.split(",").length;
                return {
                  ...prevData,
                  city: data.value.split(",")[length - 2],
                  state: data.value.split(",")[length - 2],
                  country: data.value.split(",")[length - 1],
                };
              });
            }
            setFormData((prevData) => {
              return { ...prevData, address1: data.value };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, address1: false };
            });
          }}
          label="Address Line 1"
          placeholder="Enter Address"
          required={true}
          error={requiredFormData.address1}
          helperText={requiredFormData.address1 ? "Address is required" : ""}
          options={address}
          style={{ marginTop: "10px", marginBottom: "10px" }}
          id="address1"
        />
        <TextInput
          value={formData.address2}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, address2: data };
            })
          }
          label="Address Line 2"
          placeholder="Enter Address"
        />
      </div>

      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.city}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, city: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, city: false };
            });
          }}
          label="City"
          placeholder="Enter City"
          required={true}
          error={requiredFormData.city}
          helperText={requiredFormData.city ? "City is required" : ""}
          id="city"
        />
        <TextInput
          value={formData.state}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, state: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, state: false };
            });
          }}
          label="State"
          placeholder="Select State"
          required={true}
          error={requiredFormData.state}
          helperText={requiredFormData.state ? "State is required" : ""}
          id="state"
        />
      </div>
      <div className={styles.form_flex_container}>
        <SelectInput
          value={formData.country}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, country: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, country: false };
            });
          }}
          label="Country"
          placeholder="Select Country"
          options={Country.getAllCountries().map((data) => data.name)}
          required={true}
          error={requiredFormData.country}
          helperText={requiredFormData.country ? "Country is required" : ""}
          id="country"
        />
      </div>

      <div className={styles.form_flex_container}>
        <Checkbox
          marginVertical="20px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Head Office
              </Typography>
            </div>
          }
          value={formData.headOffice}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, headOffice: data };
            })
          }
        />
      </div>
      <div className={styles.sub_content_container}>
        <Typography textAlign="left" textWeight="600" textSize="24px">
          Client Contact Information
        </Typography>
      </div>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.firstName}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, firstName: data };
            })
          }
          label="First Name"
          placeholder="Enter Client First Name"
        />
        <TextInput
          value={formData.lastName}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, lastName: data };
            })
          }
          label="Last Name"
          placeholder="Enter Client Last Name"
        />
      </div>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.email}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, email: data };
            })
          }
          label="Email Address"
          placeholder="Enter Email Address"
          right={<img src={emailIcon} alt="icon" width={20} height={20} />}
        />
        <TextInput
          value={formData.mobile}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, mobile: data };
            })
          }
          label="Mobile Number"
          placeholder="Enter Mobile Number"
        />
      </div>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.phone}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, phone: data };
            })
          }
          label="Telephone Number"
          placeholder="Enter Telephone Number"
        />
      </div>
    </div>
  );
};

export default SiteLocationAddressForm;
