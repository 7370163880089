import React from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import ActiveState from "../../../../Common/ActiveState";
import { EmployeeTimeSheetData } from "../../../../../types-definition/StaffHub/employee";
import infoCircleIcon from "../../../../../assets/svg/info-circle-icon.svg";
import moment from "moment";
import { getTimeDifference } from "utils/timeConverter";

const EmployeeTimeSheetTableItem: React.FC<EmployeeTimeSheetData> = ({
  startTime,
  status,
  stopTime,
  employeeStartTime,
  employeeEndTime,
  hourWorked,
  id,
  day,
  date,
}) => {
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left" textWeight="600">
          {moment.utc(moment(date)).format("D")}{" "}
          {moment.utc(moment(date)).format("MMMM, dddd, Do").split(",")[0]}
        </Typography>
        <Typography textAlign="left">
          {moment.utc(moment(date)).format("MMMM, dddd, Do").split(",")[1]}
        </Typography>
      </td>
      <td className={styles.td_info}>
        <Typography textAlign="left">
          {employeeStartTime ? employeeStartTime : "N/A"}
        </Typography>
        <div className={styles.info_container}>
          <img
            src={infoCircleIcon}
            alt="info-circle-icon"
            className={styles.info_icon}
          />
          <div className={styles.info_text}>
            <Typography textAlign="left" textWeight="600">
              Scheduled Shift Start Time: {startTime}{" "}
              {parseInt(`${startTime.split(":")[0]}`) > 12 ? "PM" : "AM"}
            </Typography>
            <Typography textAlign="left" textWeight="600">
              Employee Start Time:{" "}
              {employeeStartTime ? employeeStartTime : "N/A"}
              {employeeStartTime
                ? parseInt(`${employeeStartTime.split(":")[0]}`) > 12
                  ? "PM"
                  : "AM"
                : ""}
            </Typography>
          </div>
        </div>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {employeeEndTime ? employeeEndTime : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{hourWorked || "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <ActiveState active={status} />
      </td>
    </tr>
  );
};

export default EmployeeTimeSheetTableItem;
