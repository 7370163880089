import capitalize from "./capitalize";
import convertFieldNameToText from "./convertFieldNameToText";

const fieldTextValidator = (formData: any, requiredFormData: any) => {
  let firstErrorId;
  let result: { status: boolean; data: any; id?: string } = {
    status: true,
    data: {},
  };
  for (const key in formData) {
    if (requiredFormData.hasOwnProperty(key)) {
      if (!formData[key]) {
        if (!firstErrorId) {
          firstErrorId = key;
        }
        result = {
          status: false,
          data: {
            ...result.data,
            [key]: {
              status: true,
              text: `${capitalize(convertFieldNameToText(key))} is required`,
            },
          },
          id: firstErrorId,
        };
      } else {
        result = {
          status: result.status,
          data: {
            ...result.data,
            [key]: {
              status: false,
              text: "",
            },
          },
          id: firstErrorId,
        };
      }
    }
  }
  return result;
};

export default fieldTextValidator;
