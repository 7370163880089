import React from "react";
import styles from "./index.module.css";
import calendarIcon from "../../../assets/svg/calendar-icon.svg";
import chevronLeft from "../../../assets/svg/chevron-left.svg";
import chevronRight from "../../../assets/svg/chevron-right.svg";
import Typography from "../../Common/Typography";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getRosterCalendarView,
  getRosterFromDate,
  getRosterView,
  setRosterFromDate,
} from "../../../redux/slice/roster/rosterSlice";

const RosterDateNavigation = () => {
  const dispatch = useAppDispatch();
  const fromDate = useAppSelector(getRosterFromDate);
  const fromDateAsDate = new Date(fromDate);
  const view = useAppSelector(getRosterView);
  const calendarView = useAppSelector(getRosterCalendarView);
  const handleDateUpdate = (action: "increase" | "decrease") => {
    if (action === "increase") {
      if (fromDateAsDate.getMonth() === 11) {
        dispatch(
          setRosterFromDate(
            moment(new Date(fromDateAsDate.getFullYear() + 1, 0, 1)).format(
              "MM/DD/yyyy",
            ),
          ),
        );
      } else {
        dispatch(
          setRosterFromDate(
            moment(
              new Date(
                fromDateAsDate.getFullYear(),
                fromDateAsDate.getMonth() + 1,
                1,
              ),
            ).format("MM/DD/yyyy"),
          ),
        );
      }
    } else {
      if (fromDateAsDate.getMonth() === 0) {
        dispatch(
          setRosterFromDate(
            moment(new Date(fromDateAsDate.getFullYear() - 1, 11, 1)).format(
              "MM/DD/yyyy",
            ),
          ),
        );
      } else {
        dispatch(
          setRosterFromDate(
            moment(
              new Date(
                fromDateAsDate.getFullYear(),
                fromDateAsDate.getMonth() - 1,
                1,
              ),
            ).format("MM/DD/yyyy"),
          ),
        );
      }
    }
  };
  return (
    <div className={styles.container}>
      <img src={calendarIcon} alt="calendar-icon" />
      {view === "service-view" ? (
        <div className={styles.date_container}>
          <Typography>
            {moment(new Date(fromDate)).format("DD MMM YYYY")} -{" "}
            {moment(new Date(fromDate)).add(5, "days").format("DD MMM YYYY")}
          </Typography>
        </div>
      ) : calendarView === "day" ? (
        <div className={styles.date_single_container}>
          <Typography>{moment(fromDate).format("DD MMM YYYY")}</Typography>
        </div>
      ) : calendarView === "week" ? (
        <div className={styles.date_container}>
          <Typography>
            {moment(fromDate).format("DD MMM YYYY")} -{" "}
            {moment(fromDate).add(5, "days").format("DD MMM YYYY")}
          </Typography>
        </div>
      ) : (
        <div className={styles.date_single_container}>
          <Typography>
            {moment(new Date(fromDate)).format("MMM YYYY")}
          </Typography>
        </div>
      )}
      <div className={styles.img_container}>
        <img
          src={chevronLeft}
          alt="chevron-left"
          className={styles.chevron_left}
          onClick={() => {
            if (view === "service-view") {
              dispatch(
                setRosterFromDate(
                  moment(new Date(fromDate))
                    .subtract(7, "days")
                    .format("MM/DD/yyyy"),
                ),
              );

              
            } else if (calendarView === "day") {
              dispatch(
                setRosterFromDate(
                  moment(fromDate).subtract(1, "day").format("MM/DD/yyyy"),
                ),
              );
            } else if (calendarView === "month") {
              handleDateUpdate("decrease");
            } else {
              dispatch(
                setRosterFromDate(
                  moment(fromDate).subtract(7, "days").format("MM/DD/yyyy"),
                ),
              );
            }
          }}
        />
        <img
          src={chevronRight}
          alt="chevron-right"
          className={styles.chevron_right}
          onClick={() => {
            if (view === "service-view") {
              dispatch(
                setRosterFromDate(
                  moment(fromDate).add(7, "days").format("MM/DD/yyyy"),
                ),
              );
            } else if (calendarView === "day") {
              dispatch(
                setRosterFromDate(
                  moment(fromDate).add(1, "day").format("MM/DD/yyyy"),
                ),
              );
            } else if (calendarView === "month") {
              handleDateUpdate("increase");
            } else {
              dispatch(
                setRosterFromDate(
                  moment(fromDate).add(7, "days").format("MM/DD/yyyy"),
                ),
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default RosterDateNavigation;
