import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "components/Common/PageBackButton";
import Typography from "components/Common/Typography";
import colors from "../../../../theme/colors";
import {
  getActivityBasicInformation,
  getLocationAudit,
} from "../../../../utils/localstorage";
import { getRequest } from "../../../../api/request";
import PageLoader from "components/Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../api/urls";
import { LocationRequiredCertificate } from "../../../../types-definition/admin";
import DashboardHeader from "components/Common/DashboardHeader";
import { toastError } from "utils/toast";
const SiteLocationAuditCertificates = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [
    currentLocationRequiredCertificate,
    setCurrentLocationRequiredCertificate,
  ] = useState<LocationRequiredCertificate>({
    mustPossesAtLeastOneCert: false,
    mustHaveAllCerts: false,
    applyCertificatestoServices: null,
    certificateType: [],
    applyCertToService: null,
  });
  const [
    previousLocationRequiredCertificate,
    setPreviousLocationRequiredCertificate,
  ] = useState<LocationRequiredCertificate>({
    mustPossesAtLeastOneCert: false,
    mustHaveAllCerts: false,
    applyCertificatestoServices: null,
    certificateType: [],
    applyCertToService: null,
  });
  const id = getLocationAudit();
  useEffect(() => {
    const getLocationRequiredCertificate = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data;
        const previousData = response.data;
        if (response.data.action === "POST") {
          setCurrentLocationRequiredCertificate({
            mustHaveAllCerts:
              data?.newRecord?.requiredCertificates?.mustHaveAllCerts,
            mustPossesAtLeastOneCert:
              data?.newRecord?.requiredCertificates?.mustPossesAtLeastOneCert,
            certificateType:
              data?.newRecord?.requiredCertificates?.requiredCert,
            applyCertToService:
              data?.newRecord?.requiredCertificates?.applyCertToService,
          });
          setPreviousLocationRequiredCertificate({
            mustHaveAllCerts:
              previousData?.previousRecord?.requiredCertificates
                ?.mustHaveAllCerts,
            mustPossesAtLeastOneCert:
              previousData?.previousRecord?.requiredCertificates
                ?.mustPossesAtLeastOneCert,
            certificateType:
              previousData?.previousRecord?.requiredCertificates?.requiredCert,
            applyCertToService:
              previousData?.previousRecord?.requiredCertificates
                ?.applyCertToService,
          });
        } else {
          setCurrentLocationRequiredCertificate({
            mustHaveAllCerts:
              data?.newRecord?.requiredCertificates?.mustHaveAllCerts,
            mustPossesAtLeastOneCert:
              data?.newRecord?.requiredCertificates?.mustPossesAtLeastOneCert,
            certificateType:
              data?.newRecord?.requiredCertificates?.requiredCert,
            applyCertToService:
              data?.newRecord?.requiredCertificates?.applyCertToService,
          });
          setPreviousLocationRequiredCertificate({
            mustHaveAllCerts:
              previousData?.previousRecord?.requiredCertificates
                ?.mustHaveAllCerts,
            mustPossesAtLeastOneCert:
              previousData?.previousRecord?.requiredCertificates
                ?.mustPossesAtLeastOneCert,
            certificateType:
              previousData?.previousRecord?.requiredCertificates?.requiredCert,
            applyCertToService:
              previousData?.previousRecord?.requiredCertificates
                ?.applyCertToService,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getLocationRequiredCertificate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="clients" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/client/location/audit" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Add Site Location Required Certificate
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Apply Certificates to Services{" "}
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationRequiredCertificate.applyCertToService ===
                    true && "True"}
                  {currentLocationRequiredCertificate.applyCertToService ===
                    false && "False"}
                  {currentLocationRequiredCertificate.applyCertToService ===
                    null && "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Certificate Type</Typography>
                {currentLocationRequiredCertificate.certificateType
                  ? currentLocationRequiredCertificate.certificateType.map(
                      (item, index) => (
                        <Typography
                          key={index}
                          textAlign="left"
                          textSize="20px"
                          textWeight="600"
                        >
                          {item}
                        </Typography>
                      ),
                    )
                  : ""}
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  All Certificate Required
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationRequiredCertificate.mustHaveAllCerts ===
                    true && "True"}
                  {currentLocationRequiredCertificate.mustHaveAllCerts ===
                    false && "False"}
                  {currentLocationRequiredCertificate.mustHaveAllCerts ===
                    null && "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Atleast One Certificate Required
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationRequiredCertificate.mustPossesAtLeastOneCert ===
                    "true" && "True"}
                  {currentLocationRequiredCertificate.mustPossesAtLeastOneCert ===
                    "false" && "False"}
                  {!currentLocationRequiredCertificate.mustHaveAllCerts && "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* previouse Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Add Site Location Required Certificate
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Apply Certificates to Services{" "}
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationRequiredCertificate.applyCertToService ===
                    true && "True"}
                  {previousLocationRequiredCertificate.applyCertToService ===
                    false && "False"}
                  {previousLocationRequiredCertificate.applyCertToService ===
                    null && "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Certificate Type</Typography>
                {previousLocationRequiredCertificate.certificateType
                  ? previousLocationRequiredCertificate.certificateType.map(
                      (item, index) => (
                        <Typography
                          key={index}
                          textAlign="left"
                          textSize="20px"
                          textWeight="600"
                        >
                          {item}
                        </Typography>
                      ),
                    )
                  : ""}
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  All Certificate Required
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationRequiredCertificate.mustHaveAllCerts ===
                    true && "True"}
                  {previousLocationRequiredCertificate.mustHaveAllCerts ===
                    false && "False"}
                  {previousLocationRequiredCertificate.mustHaveAllCerts ===
                    null && "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Atleast One Certificate Required
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationRequiredCertificate.mustPossesAtLeastOneCert ===
                    "true" && "True"}
                  {previousLocationRequiredCertificate.mustPossesAtLeastOneCert ===
                    "false" && "False"}
                  {!previousLocationRequiredCertificate.mustHaveAllCerts && "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteLocationAuditCertificates;
