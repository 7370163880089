import React, { useState, useEffect } from "react";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import Typography from "components/Common/Typography";
import styles from "./index.module.css";
import colors from "theme/colors";
import TextInput from "components/Form/TextInput";
import searchIcon from "assets/svg/search-icon.svg";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import {
  getNewState,
  refetchState,
} from "../../../../redux/slice/staff-hub/linkToLocationSlice";
import EmptyItemState from "components/Common/EmptyItemState";
import { handleReload } from "../../../../redux/slice/staff-hub/linkToLocationSlice";
import { getRequest } from "api/request";
import { GET_ALL_EMPLOYEE_URL } from "api/urls";
import { getLocation, getLocationName } from "utils/localstorage";
import PageBackButton from "components/Common/PageBackButton";
import LinkEmployeesToLocationModal from "components/Client/Site Location/LinkEmployeesToLocation/LinkEmployeesToLocationModal";
import LinkedEmployeesToLocationTable from "components/Client/Site Location/LinkEmployeesToLocation/LinkedEmployeesToLocationTable";
import LInkEmployeesToLocationHeader from "components/Client/Site Location/LinkEmployeesToLocation/LInkEmployeesToLocationHeader";
import { LinkedEmployeesToLocationTableItemData } from "types-definition/client/linkToLocation";

const LinkedEmployee = () => {
  // Localstorage data
  const locationName = getLocationName();

  // Redux states and hooks
  const dispatch = useAppDispatch();
  const showModal = useAppSelector(getNewState);
  const reload = useAppSelector(refetchState);

  // React useStates
  const [assignedEmployees, setAssignedEmployees] = useState<
    Array<LinkedEmployeesToLocationTableItemData>
  >([]);
  const [filteredAssignedEmployees, setFilteredAssignedEmployees] = useState<
    Array<LinkedEmployeesToLocationTableItemData>
  >([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const getLinkedEmployees = async () => {
      setRequestLoading(true);
      const getCurrentLocationId = getLocation();
      const { data: getAllEmployees } = await getRequest(GET_ALL_EMPLOYEE_URL);

      const linkedEmployees = getAllEmployees
        ?.filter(
          (employee: any) =>
            employee?.locations
              ?.map((location: any) => location.locationId)
              ?.includes(getCurrentLocationId),
        )
        ?.map((employee: any) => {
          const currentAssignedLocation = employee?.locations.find(
            (location: any) => location.locationId === getCurrentLocationId,
          );

          return {
            id: currentAssignedLocation?.id,
            employeeUsername: employee?.username,
            employeeId: employee?.id,
            employeeName: `${employee?.firstName} ${employee?.lastName}`,
            grade: employee?.grade?.grade,
            distance: currentAssignedLocation?.distance,
            payOption: currentAssignedLocation?.payOption,
            payValue: currentAssignedLocation?.payValue,
            restriction: currentAssignedLocation?.restrict,
          };
        });

      setSearch("");
      setAssignedEmployees(linkedEmployees);
      setFilteredAssignedEmployees(linkedEmployees);
      setRequestLoading(false);
    };

    dispatch(handleReload({ reload: false }));
    getLinkedEmployees();
  }, [reload, dispatch]);

  return (
    <>
      {requestLoading ? (
        <PageLoader />
      ) : (
        <section>
          <ScrollToTop />
          <DashboardHeader active="clients" />
          <div className={styles.container}>
            <div className={styles.item_container}>
              <PageBackButton
                width="95%"
                paddingTop="20px"
                marginBottom="20px"
              />
              <div className={styles.flex_item}>
                <div className={styles.item}>
                  <div className={styles.sub_flex_item}>
                    <Typography
                      text="Link Employee To Location"
                      textAlign="left"
                      textWeight="600"
                      textSize="20px"
                    ></Typography>
                    {/* /employee name would be changed */}
                    <Typography
                      textAlign="left"
                      textColor={colors.primary3}
                      textWeight="600"
                      textSize="20px"
                    >
                      {locationName}
                    </Typography>
                  </div>
                  <LInkEmployeesToLocationHeader state="assigned" />
                </div>
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={search}
                  setValue={(data) => {
                    setSearch(data);
                    if (data) {
                      setFilteredAssignedEmployees(
                        assignedEmployees.filter(
                          (employee) =>
                            employee.employeeName
                              .toLowerCase()
                              .search(data.toLowerCase()) !== -1 ||
                            employee.grade
                              .toLowerCase()
                              .search(data.toLowerCase()) !== -1,
                        ),
                      );
                    } else {
                      setFilteredAssignedEmployees(assignedEmployees);
                    }
                  }}
                />
              </div>
              {assignedEmployees.length < 1 ? (
                <div className={styles.emptyspace}>
                  <EmptyItemState
                    text="You haven't assigned any employee to available location yet"
                    location="client/location/link-employee"
                    label="Assign Employees"
                  />
                </div>
              ) : (
                <LinkedEmployeesToLocationTable
                  data={filteredAssignedEmployees}
                />
              )}
              <div>{showModal && <LinkEmployeesToLocationModal />}</div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default LinkedEmployee;
