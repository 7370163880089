import React from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import colors from "../../../theme/colors";
import { RosterWeekViewTableProps } from "../../../types-definition/roster";
import { useAppSelector } from "../../../redux/hooks";
import {
  getRosterFromDate,
  getRosterServiceData,
} from "../../../redux/slice/roster/rosterSlice";
import moment from "moment";
import RosterWeekViewTableItem from "../RosterWeekViewTableItem";

import { getTimeLength, getTimeTopLength } from "../../../utils/timeConverter";

const RosterWeekViewTable: React.FC<RosterWeekViewTableProps> = () => {
  const data = useAppSelector(getRosterServiceData);
  const date = useAppSelector(getRosterFromDate);
  const filterDayOneItem = data
    .filter(
      (i: any) =>
        new Date(i.date).getFullYear() === new Date(date).getFullYear() &&
        new Date(i.date).getMonth() === new Date(date).getMonth() &&
        new Date(i.date).getDate() === new Date(date).getDate(),
    )
    .filter(
      (i: any, index: number, self: any) =>
        self.findIndex(
          (item: any) =>
            item.startTime === i.startTime && item.endTime === i.endTime,
        ) === index,
    );

  // Day Two
  const filterDayTwoItem = data
    .filter(
      (i: any) =>
        new Date(i.date).getFullYear() ===
          new Date(
            moment(date).add(1, "day").format("MM-DD-YYYY"),
          ).getFullYear() &&
        new Date(i.date).getMonth() ===
          new Date(
            moment(date).add(1, "day").format("MM-DD-YYYY"),
          ).getMonth() &&
        new Date(i.date).getDate() ===
          new Date(moment(date).add(1, "day").format("MM-DD-YYYY")).getDate(),
    )
    .filter(
      (i: any, index: number, self: any) =>
        self.findIndex(
          (item: any) =>
            item.startTime === i.startTime && item.endTime === i.endTime,
        ) === index,
    );

  // Day Two
  const filterDayThreeItem = data
    .filter(
      (i: any) =>
        new Date(i.date).getFullYear() ===
          new Date(
            moment(date).add(2, "day").format("MM-DD-YYYY"),
          ).getFullYear() &&
        new Date(i.date).getMonth() ===
          new Date(
            moment(date).add(2, "day").format("MM-DD-YYYY"),
          ).getMonth() &&
        new Date(i.date).getDate() ===
          new Date(moment(date).add(2, "day").format("MM-DD-YYYY")).getDate(),
    )
    .filter(
      (i: any, index: number, self: any) =>
        self.findIndex(
          (item: any) =>
            item.startTime === i.startTime && item.endTime === i.endTime,
        ) === index,
    ); // Day Two
  const filterDayFourItem = data
    .filter(
      (i: any) =>
        new Date(i.date).getFullYear() ===
          new Date(
            moment(date).add(3, "day").format("MM-DD-YYYY"),
          ).getFullYear() &&
        new Date(i.date).getMonth() ===
          new Date(
            moment(date).add(3, "day").format("MM-DD-YYYY"),
          ).getMonth() &&
        new Date(i.date).getDate() ===
          new Date(moment(date).add(3, "day").format("MM-DD-YYYY")).getDate(),
    )
    .filter(
      (i: any, index: number, self: any) =>
        self.findIndex(
          (item: any) =>
            item.startTime === i.startTime && item.endTime === i.endTime,
        ) === index,
    ); // Day Two
  const filterDayFiveItem = data
    .filter(
      (i: any) =>
        new Date(i.date).getFullYear() ===
          new Date(
            moment(date).add(4, "day").format("MM-DD-YYYY"),
          ).getFullYear() &&
        new Date(i.date).getMonth() ===
          new Date(
            moment(date).add(4, "day").format("MM-DD-YYYY"),
          ).getMonth() &&
        new Date(i.date).getDate() ===
          new Date(moment(date).add(4, "day").format("MM-DD-YYYY")).getDate(),
    )
    .filter(
      (i: any, index: number, self: any) =>
        self.findIndex(
          (item: any) =>
            item.startTime === i.startTime && item.endTime === i.endTime,
        ) === index,
    );

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              ></Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(date).format("ddd DD MMM")}
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(date).add(1, "day").format("ddd DD MMM")}
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(date).add(2, "days").format("ddd DD MMM")}
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(date).add(3, "days").format("ddd DD MMM")}
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(date).add(4, "days").format("ddd DD MMM")}
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          <RosterWeekViewTableItem />
        </tbody>
      </table>
      <div className={styles.day_one_container}>
        {filterDayOneItem.map((i: any) => {
          return (
            <div
              className={styles.active}
              style={{
                marginTop: getTimeTopLength(i.startTime),
                height: getTimeLength(i.startTime, i.endTime),
              }}
            ></div>
          );
        })}
      </div>
      <div className={styles.day_two_container}>
        {filterDayTwoItem.map((i: any) => {
          return (
            <div
              className={styles.active}
              style={{
                marginTop: getTimeTopLength(i.startTime),
                height: getTimeLength(i.startTime, i.endTime),
              }}
            ></div>
          );
        })}
      </div>
      <div className={styles.day_three_container}>
        {filterDayThreeItem.map((i: any) => {
          return (
            <div
              className={styles.active}
              style={{
                marginTop: getTimeTopLength(i.startTime),
                height: getTimeLength(i.startTime, i.endTime),
              }}
            ></div>
          );
        })}
      </div>
      <div className={styles.day_four_container}>
        {filterDayFourItem.map((i: any) => {
          return (
            <div
              className={styles.active}
              style={{
                marginTop: getTimeTopLength(i.startTime),
                height: getTimeLength(i.startTime, i.endTime),
              }}
            ></div>
          );
        })}
      </div>
      <div className={styles.day_five_container}>
        {filterDayFiveItem.map((i: any) => {
          return (
            <div
              className={styles.active}
              style={{
                marginTop: getTimeTopLength(i.startTime),
                height: getTimeLength(i.startTime, i.endTime),
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default RosterWeekViewTable;
