import { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import filterIcon from "../../../../assets/svg/filter-icon.svg";
import chevronDownIcon from "../../../../assets/svg/chevron-down.svg";
import colors from "../../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getRequest } from "../../../../api/request";
import { CERTIFICATE_TYPE_URL } from "../../../../api/urls";
import {
  getSearchedCertificateTypeValue,
  setCertificateTypeData,
  updateCertificateTypeSearchEmpty,
  updateCertificateTypeSearchFetching,
} from "redux/slice/staff-hub/certificateTypeSlice";

const CertificateTypeTableFilter = () => {
  const dispatch = useAppDispatch();
  const searchedValue = useAppSelector(getSearchedCertificateTypeValue);
  const [currentFilterOption, setcurrentFilterOption] = useState("Filter");

  const [showDropDown, setShowDropDown] = useState(false);
  const [showSubDropDown, setShowSubDropDown] = useState(true);
  const [filterOptions, setFilterOptions] = useState("");
  const FILTEROPTIONS = {
    STATUS: { name: "Status", top: "70px" },
  };

  const ORDEROPTIONS: any = {
    Active: true,
    Inactive: false,
  };
  const hideAllDropDown = () => {
    // containerRef.current?.blur();
    setShowDropDown(false);
    setShowSubDropDown(false);
  };
  const clearFilter = async () => {
    dispatch(updateCertificateTypeSearchFetching(true));
    dispatch(updateCertificateTypeSearchEmpty(false));
    setcurrentFilterOption("Filter");
    setFilterOptions("");
    const response = await getRequest(CERTIFICATE_TYPE_URL);
    if (response?.success) {
      dispatch(
        setCertificateTypeData(
          response?.data?.certificateTypes.map((i: any) => {
            return {
              id: i?.id,
              name: i?.certificateName,
              //this should be updated
              no: "0",
              active: i?.active,
              requirementLevel: i?.requirementLevel,
            };
          }),
        ),
      );
    }
    dispatch(updateCertificateTypeSearchFetching(false));
  };

  const filterCertificateType = async (
    filter: string,
    searchedValue: string,
  ) => {
    dispatch(updateCertificateTypeSearchFetching(true));
    if (filterOptions === FILTEROPTIONS.STATUS.name) {
      let response;
      if (searchedValue) {
        response = await getRequest(
          `${CERTIFICATE_TYPE_URL}?filterBy=${
            ORDEROPTIONS[filter] ? "active" : "inactive"
          }&search=${searchedValue}`,
        );
      } else {
        response = await getRequest(
          `${CERTIFICATE_TYPE_URL}?filterBy=${
            ORDEROPTIONS[filter] ? "active" : "inactive"
          }`,
        );
      }
      if (response?.success) {
        if (response?.data?.certificateTypes?.length === 0) {
          dispatch(updateCertificateTypeSearchEmpty(true));
        } else {
          dispatch(updateCertificateTypeSearchEmpty(false));
        }
        dispatch(
          setCertificateTypeData(
            response?.data?.certificateTypes.map((i: any) => {
              return {
                id: i?.id,
                name: i?.certificateName,
                //this should be updated
                no: "0",
                active: i?.active,
                requirementLevel: i?.requirementLevel,
              };
            }),
          ),
        );
      }
      dispatch(updateCertificateTypeSearchFetching(false));
    } else {
      const response = await getRequest(
        `${CERTIFICATE_TYPE_URL}?search=${searchedValue}`,
      );
      if (response?.success) {
        if (response?.data?.certificateTypes?.length === 0) {
          dispatch(updateCertificateTypeSearchEmpty(true));
        } else {
          dispatch(updateCertificateTypeSearchEmpty(false));
        }
        dispatch(
          setCertificateTypeData(
            response?.data?.certificateTypes.map((i: any) => {
              return {
                id: i?.id,
                name: i?.certificateName,
                //this should be updated
                no: "0",
                active: i?.active,
                requirementLevel: i?.requirementLevel,
              };
            }),
          ),
        );
      }
      dispatch(updateCertificateTypeSearchFetching(false));
    }
  };
  const resetCertificateType = async () => {
    dispatch(updateCertificateTypeSearchFetching(true));
    if (currentFilterOption === "Filter") {
      const response = await getRequest(CERTIFICATE_TYPE_URL);
      if (response?.success) {
        dispatch(
          setCertificateTypeData(
            response?.data?.certificateTypes.map((i: any) => {
              return {
                id: i?.id,
                name: i?.certificateName,
                //this should be updated
                no: "0",
                active: i?.active,
                requirementLevel: i?.requirementLevel,
              };
            }),
          ),
        );
      }
      dispatch(updateCertificateTypeSearchFetching(false));
    } else {
      filterCertificateType(currentFilterOption, searchedValue);
      dispatch(updateCertificateTypeSearchFetching(false));
    }
  };
  useEffect(() => {
    setShowDropDown(false);
    setShowSubDropDown(false);
    if (searchedValue !== "") {
      filterCertificateType(currentFilterOption, searchedValue);
    } else {
      resetCertificateType();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue]);
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.main}>
      <div
        className={styles.main_container}
        onFocus={() => {
          setShowDropDown(true);
          setShowSubDropDown(true);
        }}
        ref={containerRef}
        onClick={(e) => {
          // e.preventDefault();
          // containerRef.current?
          if (document.activeElement === containerRef.current) {
            containerRef.current?.focus();
          } else {
            containerRef.current?.blur();
            // containerRef.current?.focus();
          }
        }}
        tabIndex={0}
        onBlur={(e) => {
          const event = e;
          requestAnimationFrame(() => {
            if (!event.currentTarget?.contains(document.activeElement)) {
              setShowDropDown(false);
              setShowSubDropDown(false);
            }
          });
        }}
        // tabIndex={1}
      >
        <div className={styles.container}>
          <img src={filterIcon} alt="filter icon" />
          <Typography textColor={colors.gray1}>
            {currentFilterOption}
          </Typography>
        </div>
        {showDropDown && (
          <div className={styles.dropdown_container}>
            <div
              className={`${
                FILTEROPTIONS.STATUS.name === filterOptions
                  ? `${styles.sub_dropdown_item} ${styles.sub_dropdown_item_active}`
                  : styles.sub_dropdown_item
              }`}
              onClick={() => {
                setFilterOptions(FILTEROPTIONS.STATUS.name);
                setShowSubDropDown(true);
              }}
            >
              <Typography textAlign="left">Status</Typography>
              <img
                src={chevronDownIcon}
                alt="chevron-down"
                className={styles.dropdown_image_item}
              />
            </div>
          </div>
        )}
        {showSubDropDown && (
          <div>
            {filterOptions === FILTEROPTIONS.STATUS.name && (
              <div
                className={styles.sub_dropdown_container}
                style={{ top: FILTEROPTIONS.STATUS.top }}
              >
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Active");
                    filterCertificateType("Active", searchedValue);
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Active</Typography>
                </div>
                <div
                  className={styles.sub_dropdown_item}
                  onClick={() => {
                    setcurrentFilterOption("Inactive");
                    filterCertificateType("Inactive", searchedValue);
                    hideAllDropDown();
                    containerRef.current?.blur();
                  }}
                >
                  <Typography>Inactive</Typography>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {currentFilterOption !== "Filter" && (
        <div className={styles.clear_filter} onClick={() => clearFilter()}>
          <Typography
            textColor={colors.primary3}
            textWeight="600"
            textSize="12px"
          >
            Clear Filters
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CertificateTypeTableFilter;
