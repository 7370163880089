import React, { useEffect, useReducer, useState } from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import colors from "../../../theme/colors";
import glixLogo from "../../../assets/svg/glix-logo.svg";
import dropdownIcon from "../../../assets/svg/button-icon.svg";
import dropdownIconActive from "../../../assets/svg/button-icon-active.svg";
import notificationIcon from "../../../assets/svg/notification-01.svg";
import settingsIcon from "../../../assets/svg/setting-01.svg";
import helpIcon from "../../../assets/svg/help-circle.svg";
import avatar from "../../../assets/svg/Avatars.svg";
import { DashboardProps } from "../../../types-definition/common";
import { useNavigate } from "react-router-dom";
import DashboardHeaderDropdown from "../DashboardHeaderDropdown";
import HandleIdelogin from "../HandleIdleLogin";
import HandleAutoLogout from "../HandleAutoLogout";
import AuthSidebar from "../AuthSideBar";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getShowAuthSideBar,
  setShowAuthSideBar,
} from "../../../redux/slice/common/sidebarSlice";
import { getData, getSetupComplete, getUserTypes } from "utils/localstorage";
import { toastError } from "utils/toast";
import { getRequest, postRequest } from "api/request";
import { ADMIN } from "types-definition/constants";

const DashboardHeader: React.FC<DashboardProps> = ({
  active,
  disableAuth,
  disableSubscription = false,
}) => {
  const [setUpCompleted, setSetupCompleted] = useState(false);
  const navigate = useNavigate();
  const dispatchRedux = useAppDispatch();
  const showAuthSideBar = useAppSelector(getShowAuthSideBar);
  const actions = {
    SHOW_HR_DROPDOWN: "SHOW_HR_DROPDOWN",
    HIDE_HR_DROPDOWN: "HIDE_HR_DROPDOWN",
    SHOW_CLIENT_DROPDOWN: "SHOW_CLIENT_DROPDOWN",
    HIDE_CLIENT_DROPDOWN: "HIDE_CLIENT_DROPDOWN",
    SHOW_ADMIN_DROPDOWN: "SHOW_ADMIN_DROPDOWN",
    HIDE_ADMIN_DROPDOWN: "HIDE_ADMIN_DROPDOWN",
    SHOW_OPERATION_DROPDOWN: "SHOW_OPERATION_DROPDOWN",
    HIDE_OPERATION_DROPDOWN: "HIDE_OPERATION_DROPDOWN",
    SHOW_REPORT_DROPDOWN: "SHOW_REPORT_DROPDOWN",
    HIDE_REPORT_DROPDOWN: "HIDE_REPORT_DROPDOWN",
    CLOSE_ALL_DROPDOWN: "CLOSE_ALL_DROPDOWN",
  };
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case actions.SHOW_HR_DROPDOWN: {
        return {
          ...state,
          showHrDropdown: true,
          showAdminSettingsDropdown: false,
          showClientsDropdown: false,
          showOperationsDropdown: false,
          showReportsDropdown: false,
        };
      }
      case actions.HIDE_HR_DROPDOWN: {
        return {
          ...state,
          showHrDropdown: false,
        };
      }
      case actions.SHOW_CLIENT_DROPDOWN: {
        return {
          ...state,
          showOperationsDropdown: false,
          showHrDropdown: false,
          showAdminSettingsDropdown: false,
          showClientsDropdown: true,
          showReportsDropdown: false,
        };
      }
      case actions.SHOW_ADMIN_DROPDOWN: {
        return {
          ...state,
          showHrDropdown: false,
          showAdminSettingsDropdown: true,
          showClientsDropdown: false,
          showOperationsDropdown: false,
          showReportsDropdown: false,
        };
      }
      case actions.SHOW_OPERATION_DROPDOWN: {
        return {
          showHrDropdown: false,
          showAdminSettingsDropdown: false,
          showClientsDropdown: false,
          showOperationsDropdown: true,
          showReportsDropdown: false,
        };
      }
      case actions.SHOW_REPORT_DROPDOWN: {
        return {
          showHrDropdown: false,
          showAdminSettingsDropdown: false,
          showClientsDropdown: false,
          showOperationsDropdown: false,
          showReportsDropdown: true,
        };
      }

      case actions.HIDE_OPERATION_DROPDOWN: {
        return {
          ...state,
          showOperationsDropdown: false,
        };
      }
      case actions.CLOSE_ALL_DROPDOWN: {
        return {
          ...state,
          showHrDropdown: false,
          showAdminSettingsDropdown: false,
          showClientsDropdown: false,
          showOperationsDropdown: false,
          showReportsDropdown: false,
        };
      }
    }
  };
  const [state, dispatch] = useReducer(reducer, {
    showHrDropdown: false,
    showAdminSettingsDropdown: false,
    showClientsDropdown: false,
    showOperationsDropdown: false,
    showReportsDropdown: false,
  });
  const HR_DROPDOWN_DATA = [
    {
      label: "Certificate Type",
      location: "staff-hub/certificate-type",
    },
    {
      label: "Certificate Group",
      location: "staff-hub/certificate-group",
    },
    {
      label: "Grade",
      location: "staff-hub/grade",
    },
    { label: "Employee", location: "staff-hub" },

    { label: "Message Employee", location: "employee/message" },
    {
      label: "Holiday & Absence Summary",
      location: "holiday-absence-summary",
    },
  ];
  const CLIENT_DROPDOWN_DATA = [
    { label: "Client", location: "client/dashboard" },
    { label: "Area", location: "client/area" },
    { label: "Location", location: "client/site" },
    {
      label: "Services",
      location: "client/service",
    },
    {
      label: "Checkpoint",
      location: "client/checkpoint",
    },
  ];
  const OPERATION_DROPDOWN_DATA = [{ label: "Roster", location: "roster" }];
  const REPORTS_DROPDOWN_DATA = [
    { label: "Checkcall Report", location: "reports/checkcall-log" },
    { label: "Checkpoint Report", location: "reports/checkpoint" },
    { label: "Payroll Report", location: "reports/payroll" },
  ];

  useEffect(() => {
    const checkSetUpComplete = getSetupComplete();
    if (checkSetUpComplete) {
      setSetupCompleted(JSON.parse(checkSetUpComplete).completed);
    }
  }, []);

  useEffect(() => {
    if (!disableAuth) {
      const user = getData();
      if (!user) {
        navigate("/login");
      }
      if (!disableSubscription) {
        if (getUserTypes() === ADMIN) {
          getRequest("/auth/me").then((response) => {
            if (!response?.data?.subscription) {
              toastError("Subscription Expired");
              return navigate("/subscribe");
            }
            if (
              response?.data?.subscription[0]?.status === "trialing" ||
              response?.data?.subscription[0]?.status === "active" ||
              response?.data?.subscription?.status === "active"
            ) {
              console.log(response?.data);
              console.log(response?.data?.subscription[0]?.status);
              console.log(response?.data?.subscription?.status);
            } else {
              console.log(response?.data);
              console.log(response?.data?.subscription[0]?.status);
              console.log(response?.data?.subscription?.status);

              toastError("Subscription Expired");
              navigate("/subscribe");
            }
          });
        } else {
          postRequest("/auth/admin", {
            adminId: JSON.parse(getData()!)?.settingsId,
          }).then((response) => {
            if (!response?.data?.subscription) {
              toastError("Subscription Expired");
              return navigate("/subscribe");
            }
            if (
              response?.data?.subscription[0]?.status === "trialing" ||
              response?.data?.subscription[0]?.status === "active" ||
              response?.data?.subscription?.status === "active"
            ) {
            } else {
              console.log(response?.data);
              console.log(response?.data?.subscription[0]?.status);
              console.log(response?.data?.subscription?.status);

              toastError("Subscription Expired");
              navigate("/subscribe");
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HandleAutoLogout />
      <HandleIdelogin />
      {showAuthSideBar && (
        <div>
          <AuthSidebar />
        </div>
      )}
      <section className={styles.container}>
        <img src={glixLogo} alt="glix logo" width={56} height={56} />
        <div className={`${styles.flex_item} ${styles.header_flex_item}`}>
          {!setUpCompleted && (
            <div
              className={styles.link_container}
              onClick={() => {
                navigate("/setup-wizard");
              }}
            >
              <Typography
                textColor={
                  active === "setupWizard" ? colors.primary3 : colors.gray1
                }
                textSize="16px"
                textWeight="600"
              >
                Setup Wizard
              </Typography>
            </div>
          )}

          <div
            className={
              setUpCompleted
                ? styles.link_container
                : styles.link_container_disabled
            }
            onClick={() => {
              !setUpCompleted && toastError("Complete setup wizard to proceed");
              setUpCompleted && navigate("/dashboard");
            }}
          >
            <Typography
              textColor={
                active === "dashboard" ? colors.primary3 : colors.gray1
              }
              textSize="16px"
              textWeight="600"
            >
              Dashboard
            </Typography>
          </div>
          <div
            className={
              setUpCompleted
                ? styles.link_container
                : styles.link_container_disabled
            }
            onClick={() => {
              !setUpCompleted && toastError("Complete setup wizard to proceed");
              setUpCompleted && navigate("/admin/settings");
            }}
          >
            <Typography
              textColor={
                active === "admin settings" ? colors.primary3 : colors.gray1
              }
              textSize="16px"
              textWeight="600"
            >
              Admin Settings
            </Typography>
          </div>
          <div
            className={
              setUpCompleted
                ? styles.link_dropdown_container
                : styles.link_dropdown_container_disabled
            }
            tabIndex={0}
            onBlur={() => dispatch({ type: actions.HIDE_HR_DROPDOWN })}
            onClick={() => {
              !setUpCompleted && toastError("Complete setup wizard to proceed");
              setUpCompleted && dispatch({ type: actions.SHOW_HR_DROPDOWN });
            }}
          >
            <Typography
              textColor={
                active === "staff/hub" ? colors.primary3 : colors.gray1
              }
              textSize="16px"
              textWeight="600"
            >
              Staff Hub
            </Typography>
            {active === "staff/hub" ? (
              <img
                src={dropdownIconActive}
                alt="dropdown icon"
                width={25}
                height={25}
                className={styles.icon_button}
              />
            ) : (
              <img
                src={dropdownIcon}
                alt="dropdown icon"
                width={30}
                height={30}
                className={styles.icon_button}
              />
            )}
            {state?.showHrDropdown && (
              <DashboardHeaderDropdown
                closeDropDowns={() => {
                  dispatch({ type: actions.CLOSE_ALL_DROPDOWN });
                }}
                width="240px"
                data={HR_DROPDOWN_DATA}
              />
            )}
          </div>
          <div
            className={
              setUpCompleted
                ? styles.link_dropdown_container
                : styles.link_dropdown_container_disabled
            }
            tabIndex={0}
            onBlur={() => dispatch({ type: actions.HIDE_CLIENT_DROPDOWN })}
            onClick={() => {
              !setUpCompleted && toastError("Complete setup wizard to proceed");
              setUpCompleted &&
                dispatch({ type: actions.SHOW_CLIENT_DROPDOWN });
            }}
          >
            <Typography
              textColor={active === "clients" ? colors.primary3 : colors.gray1}
              textSize="16px"
              textWeight="600"
            >
              Clients
            </Typography>
            {active === "clients" ? (
              <img
                src={dropdownIconActive}
                alt="dropdown icon"
                width={26}
                height={26}
                className={styles.icon_button}
              />
            ) : (
              <img
                src={dropdownIcon}
                alt="dropdown icon"
                width={30}
                height={30}
                className={styles.icon_button}
              />
            )}
            {state?.showClientsDropdown && (
              <DashboardHeaderDropdown
                closeDropDowns={() =>
                  dispatch({ type: actions.CLOSE_ALL_DROPDOWN })
                }
                data={CLIENT_DROPDOWN_DATA}
              />
            )}
          </div>
          <div
            className={
              setUpCompleted
                ? styles.link_dropdown_container
                : styles.link_dropdown_container_disabled
            }
            tabIndex={0}
            onBlur={() => dispatch({ type: actions.HIDE_CLIENT_DROPDOWN })}
            onClick={() => {
              !setUpCompleted && toastError("Complete setup wizard to proceed");
              setUpCompleted &&
                dispatch({ type: actions.SHOW_OPERATION_DROPDOWN });
            }}
          >
            <Typography
              textColor={
                active === "operations" ? colors.primary3 : colors.gray1
              }
              textSize="16px"
              textWeight="600"
            >
              Operations
            </Typography>
            {active === "operations" ? (
              <img
                src={dropdownIconActive}
                alt="dropdown icon"
                width={26}
                height={26}
                className={styles.icon_button}
              />
            ) : (
              <img
                src={dropdownIcon}
                alt="dropdown icon"
                width={30}
                height={30}
                className={styles.icon_button}
              />
            )}
            {state?.showOperationsDropdown && (
              <DashboardHeaderDropdown
                closeDropDowns={() =>
                  dispatch({ type: actions.CLOSE_ALL_DROPDOWN })
                }
                data={OPERATION_DROPDOWN_DATA}
              />
            )}
          </div>

          <div
            className={
              setUpCompleted
                ? styles.link_dropdown_container
                : styles.link_dropdown_container_disabled
            }
            tabIndex={0}
            onBlur={() => dispatch({ type: actions.HIDE_REPORT_DROPDOWN })}
            onClick={() => {
              !setUpCompleted && toastError("Complete setup wizard to proceed");
              setUpCompleted &&
                dispatch({ type: actions.SHOW_REPORT_DROPDOWN });
            }}
          >
            <Typography
              textColor={active === "reports" ? colors.primary3 : colors.gray1}
              textSize="16px"
              textWeight="600"
            >
              Reports
            </Typography>
            {active === "reports" ? (
              <img
                src={dropdownIconActive}
                alt="dropdown icon"
                width={26}
                height={26}
                className={styles.icon_button}
              />
            ) : (
              <img
                src={dropdownIcon}
                alt="dropdown icon"
                width={30}
                height={30}
                className={styles.icon_button}
              />
            )}
            {state?.showReportsDropdown && (
              <DashboardHeaderDropdown
                closeDropDowns={() =>
                  dispatch({ type: actions.CLOSE_ALL_DROPDOWN })
                }
                data={REPORTS_DROPDOWN_DATA}
              />
            )}
          </div>

          {/* <div className={styles.link_container}>
            <Typography
              textColor={active === "reports" ? colors.primary3 : colors.gray1}
              textSize="16px"
              textWeight="600"
            >
              Reports
            </Typography>
          </div> */}
        </div>
        <div className={styles.flex_item}>
          <div className={styles.link_container}>
            <img
              src={notificationIcon}
              alt="notification icon"
              width={32}
              height={32}
            />
          </div>

          <div className={styles.link_container}>
            <img
              src={settingsIcon}
              alt="settings icon"
              width={32}
              height={32}
            />
          </div>

          <div className={styles.link_container}>
            <img src={helpIcon} alt="help icon" width={32} height={32} />
          </div>

          <div
            className={styles.last_link_container}
            onClick={() => dispatchRedux(setShowAuthSideBar(true))}
          >
            <img src={avatar} alt="avatar" width={32} height={32} />
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardHeader;
