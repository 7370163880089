import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import {
  YearMonthCalendarProps,
  WeekData,
} from "../../../../../types-definition/common";
import colors from "../../../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  getEmployeeAbsenceHolidays,
  setShowAddHolidayModal,
} from "../../../../../redux/slice/staff-hub/employeeSlice";

const YearMonthCalendar: React.FC<YearMonthCalendarProps> = ({
  currentDate,
}) => {
  const dispatch = useAppDispatch();
  const holidays = useAppSelector(getEmployeeAbsenceHolidays);
  const [date, setDate] = useState(new Date());
  const year = date.getFullYear();
  const month = date.getMonth();
  const [firstWeek, setfirstWeek] = useState<Array<WeekData>>([]);
  const [secondWeek, setsecondWeek] = useState<Array<WeekData>>([]);
  const [thirdWeek, setthirdWeek] = useState<Array<WeekData>>([]);
  const [fourthWeek, setfourthWeek] = useState<Array<WeekData>>([]);
  const [fifthWeek, setfifthWeek] = useState<Array<WeekData>>([]);
  const [sixthWeek, setsixthWeek] = useState<Array<WeekData>>([]);
  // Array of month names
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    // Get the first day of the month
    const dayone = new Date(year, month, 1).getDay();

    // Get the last date of the month
    const lastdate = new Date(year, month + 1, 0).getDate();

    // Get the day of the last date of the month
    const dayend = new Date(year, month, lastdate).getDay();

    // Get the last date of the previous month
    const monthlastdate = new Date(year, month, 0).getDate();
    if (dayone === 0) {
      const firstweek: WeekData[] = [];
      for (let i = 0; i <= 6; i++) {
        if (i < 6) {
          firstweek.push({
            // text: monthlastdate - (monthlastdate + 6 - i - 1),
            text: monthlastdate - (6 - i),
            active: false,
            date: "",
          });
        } else {
          firstweek.push({
            text: 1,
            active: true,
            date: new Date(year, month, 1),
          });
        }
      }
      setfirstWeek(firstweek);

      const secondweek: WeekData[] = [];
      for (let i = 2; i <= 8; i++) {
        secondweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setsecondWeek(secondweek);
      const thirdweek: WeekData[] = [];
      for (let i = 9; i <= 15; i++) {
        thirdweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setthirdWeek(thirdweek);
      const fourthweek: WeekData[] = [];
      for (let i = 16; i <= 22; i++) {
        fourthweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setfourthWeek(fourthweek);
      const fifthweek: WeekData[] = [];
      for (let i = 23; i <= 29; i++) {
        fifthweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setfifthWeek(fifthweek);
      const sixthweek: WeekData[] = [];
      for (let i = 30; i <= 36; i++) {
        if (i > lastdate) {
          sixthweek.push({ text: i - lastdate, active: false, date: "" });
        } else {
          sixthweek.push({
            text: i,
            active: true,
            date: new Date(year, month, i),
          });
        }
      }
      setsixthWeek(sixthweek);
    } else {
      const firstweek: WeekData[] = [];
      for (let i = 1; i <= 7; i++) {
        if (i < dayone) {
          firstweek.push({
            text: monthlastdate - (dayone - i - 1),
            active: false,
            date: "",
          });
        } else {
          firstweek.push({
            text: i - dayone + 1,
            active: true,
            date: new Date(year, month, i - dayone + 1),
          });
        }
      }
      setfirstWeek(firstweek);

      const secondweek: WeekData[] = [];
      for (let i = 9 - dayone; i <= 15 - dayone; i++) {
        secondweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setsecondWeek(secondweek);
      const thirdweek: WeekData[] = [];
      for (let i = 16 - dayone; i <= 22 - dayone; i++) {
        thirdweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setthirdWeek(thirdweek);
      const fourthweek: WeekData[] = [];
      for (let i = 23 - dayone; i <= 29 - dayone; i++) {
        fourthweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setfourthWeek(fourthweek);
      const fifthweek: WeekData[] = [];
      if (dayend === 0) {
        for (let i = 30 - dayone; i <= 36 - dayone; i++) {
          fifthweek.push({
            text: i,
            active: true,
            date: new Date(year, month, i),
          });
        }
        setfifthWeek(fifthweek);
      } else {
        if (dayone === 0 || (dayone === 6 && lastdate === 31)) {
          for (let i = 30 - dayone; i <= 36 - dayone; i++) {
            if (i > lastdate) {
              fifthweek.push({ text: i - lastdate, active: false, date: "" });
            } else {
              fifthweek.push({
                text: i,
                active: true,
                date: new Date(year, month, i),
              });
            }
          }
          setfifthWeek(fifthweek);
          const sixthweek: WeekData[] = [];
          for (let i = 37 - dayone; i <= 43 - dayone; i++) {
            if (i > lastdate) {
              sixthweek.push({ text: i - lastdate, active: false, date: "" });
            } else {
              sixthweek.push({
                text: i,
                active: true,
                date: new Date(year, month, i),
              });
            }
          }
          setsixthWeek(sixthweek);
        } else {
          if (lastdate - dayone + 7 - (30 - dayone) < 6) {
            for (let i = 30 - dayone; i <= lastdate - dayone + 2; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          } else if (lastdate - dayone + 7 - (30 - dayone) < 7) {
            for (let i = 30 - dayone; i < lastdate - dayone + 8; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          } else if (lastdate - dayone + 7 - (30 - dayone) > 7) {
            for (let i = 30 - dayone; i < lastdate - dayone + 6; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          } else {
            for (let i = 30 - dayone; i < lastdate - dayone + 7; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          }
          setfifthWeek(fifthweek);
          setsixthWeek([]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);
  useEffect(() => {
    setDate(new Date(currentDate));
  }, [currentDate]);
  // const handleDateUpdate = (action: "increase" | "decrease") => {
  //   if (action === "increase") {
  //     if (month === 11) {
  //       setDate(new Date(year + 1, 0, 1));
  //     } else {
  //       setDate(new Date(year, month + 1, 1));
  //     }
  //   } else {
  //     if (month === 0) {
  //       setDate(new Date(year - 1, 11, 1));
  //     } else {
  //       setDate(new Date(year, month - 1, 1));
  //     }
  //   }
  // };
  return (
    <div className={styles.container}>
      <Typography textAlign="left">{months[month]}</Typography>
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tr}>
              <th className={styles.th}>
                <Typography textWeight="600">Mo</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Tu</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">We</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Th</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Fr</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Sat</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Sun</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.tr}>
              {firstWeek.map((item, index) => {
                const data = holidays.find(
                  (i: any) =>
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      new Date(i.date).getFullYear(),
                      new Date(i.date).getMonth(),
                      new Date(i.date).getDate(),
                    ).getTime(),
                );
                return (
                  <td
                    className={
                      data?.holidayType === "AWOL" ||
                      data?.holidayType === "NO_SHOW"
                        ? `${styles.td} ${styles.td_error}`
                        : data
                          ? `${styles.td} ${styles.td_active}`
                          : styles.td
                    }
                    key={index}
                    onClick={() => {
                      item.date &&
                        dispatch(
                          setShowAddHolidayModal({
                            show: true,
                            minDate: `${new Date(item.date).getFullYear()}-${
                              new Date(item.date).getMonth() + 1 > 9
                                ? new Date(item.date).getMonth() + 1
                                : `0${new Date(item.date).getMonth() + 1}`
                            }-${
                              new Date(item.date).getDate() > 9
                                ? new Date(item.date).getDate()
                                : `0${new Date(item.date).getDate()}`
                            }`,
                          }),
                        );
                    }}
                  >
                    <Typography
                      textColor={
                        data
                          ? colors.white
                          : item.active
                            ? colors.black
                            : colors.gray1
                      }
                      marginVertical="0px"
                    >
                      {item.text}
                    </Typography>
                  </td>
                );
              })}
            </tr>
            <tr className={styles.tr_space}></tr>
            <tr>
              {secondWeek.map((item, index) => {
                const data = holidays.find(
                  (i: any) =>
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      new Date(i.date).getFullYear(),
                      new Date(i.date).getMonth(),
                      new Date(i.date).getDate(),
                    ).getTime(),
                );
                return (
                  <td
                    className={
                      data?.holidayType === "AWOL" ||
                      data?.holidayType === "NO_SHOW"
                        ? `${styles.td} ${styles.td_error}`
                        : data
                          ? `${styles.td} ${styles.td_active}`
                          : styles.td
                    }
                    key={index}
                    onClick={() => {
                      item.date &&
                        dispatch(
                          setShowAddHolidayModal({
                            show: true,
                            minDate: `${new Date(item.date).getFullYear()}-${
                              new Date(item.date).getMonth() + 1 > 9
                                ? new Date(item.date).getMonth() + 1
                                : `0${new Date(item.date).getMonth() + 1}`
                            }-${
                              new Date(item.date).getDate() > 9
                                ? new Date(item.date).getDate()
                                : `0${new Date(item.date).getDate()}`
                            }`,
                          }),
                        );
                    }}
                  >
                    <Typography
                      textColor={
                        data
                          ? colors.white
                          : item.active
                            ? colors.black
                            : colors.gray1
                      }
                      marginVertical="0px"
                    >
                      {item.text}
                    </Typography>
                  </td>
                );
              })}
            </tr>
            <tr className={styles.tr_space}></tr>
            <tr>
              {thirdWeek.map((item, index) => {
                const data = holidays.find(
                  (i: any) =>
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      new Date(i.date).getFullYear(),
                      new Date(i.date).getMonth(),
                      new Date(i.date).getDate(),
                    ).getTime(),
                );
                return (
                  <td
                    className={
                      data?.holidayType === "AWOL" ||
                      data?.holidayType === "NO_SHOW"
                        ? `${styles.td} ${styles.td_error}`
                        : data
                          ? `${styles.td} ${styles.td_active}`
                          : styles.td
                    }
                    key={index}
                    onClick={() => {
                      item.date &&
                        dispatch(
                          setShowAddHolidayModal({
                            show: true,
                            minDate: `${new Date(item.date).getFullYear()}-${
                              new Date(item.date).getMonth() + 1 > 9
                                ? new Date(item.date).getMonth() + 1
                                : `0${new Date(item.date).getMonth() + 1}`
                            }-${
                              new Date(item.date).getDate() > 9
                                ? new Date(item.date).getDate()
                                : `0${new Date(item.date).getDate()}`
                            }`,
                          }),
                        );
                    }}
                  >
                    <Typography
                      textColor={
                        data
                          ? colors.white
                          : item.active
                            ? colors.black
                            : colors.gray1
                      }
                      marginVertical="0px"
                    >
                      {item.text}
                    </Typography>
                  </td>
                );
              })}
            </tr>
            <tr className={styles.tr_space}></tr>
            <tr>
              {fourthWeek.map((item, index) => {
                const data = holidays.find(
                  (i: any) =>
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      new Date(i.date).getFullYear(),
                      new Date(i.date).getMonth(),
                      new Date(i.date).getDate(),
                    ).getTime(),
                );
                return (
                  <td
                    className={
                      data?.holidayType === "AWOL" ||
                      data?.holidayType === "NO_SHOW"
                        ? `${styles.td} ${styles.td_error}`
                        : data
                          ? `${styles.td} ${styles.td_active}`
                          : styles.td
                    }
                    key={index}
                    onClick={() => {
                      item.date &&
                        dispatch(
                          setShowAddHolidayModal({
                            show: true,
                            minDate: `${new Date(item.date).getFullYear()}-${
                              new Date(item.date).getMonth() + 1 > 9
                                ? new Date(item.date).getMonth() + 1
                                : `0${new Date(item.date).getMonth() + 1}`
                            }-${
                              new Date(item.date).getDate() > 9
                                ? new Date(item.date).getDate()
                                : `0${new Date(item.date).getDate()}`
                            }`,
                          }),
                        );
                    }}
                  >
                    <Typography
                      textColor={
                        data
                          ? colors.white
                          : item.active
                            ? colors.black
                            : colors.gray1
                      }
                      marginVertical="0px"
                      paddingVertical="-30px"
                      style={{ paddingLeft: "3px", paddingRight: "3px" }}
                    >
                      {item.text}
                    </Typography>
                  </td>
                );
              })}
            </tr>
            <tr className={styles.tr_space}></tr>
            <tr>
              {fifthWeek.map((item, index) => {
                const data = holidays.find(
                  (i: any) =>
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      new Date(i.date).getFullYear(),
                      new Date(i.date).getMonth(),
                      new Date(i.date).getDate(),
                    ).getTime(),
                );
                return (
                  <td
                    className={
                      data?.holidayType === "AWOL" ||
                      data?.holidayType === "NO_SHOW"
                        ? `${styles.td} ${styles.td_error}`
                        : data
                          ? `${styles.td} ${styles.td_active}`
                          : styles.td
                    }
                    key={index}
                    onClick={() => {
                      item.date &&
                        dispatch(
                          setShowAddHolidayModal({
                            show: true,
                            minDate: `${new Date(item.date).getFullYear()}-${
                              new Date(item.date).getMonth() + 1 > 9
                                ? new Date(item.date).getMonth() + 1
                                : `0${new Date(item.date).getMonth() + 1}`
                            }-${
                              new Date(item.date).getDate() > 9
                                ? new Date(item.date).getDate()
                                : `0${new Date(item.date).getDate()}`
                            }`,
                          }),
                        );
                    }}
                  >
                    <Typography
                      textColor={
                        data
                          ? colors.white
                          : item.active
                            ? colors.black
                            : colors.gray1
                      }
                      marginVertical="0px"
                    >
                      {item.text}
                    </Typography>
                  </td>
                );
              })}
            </tr>
            <tr className={styles.tr_space}></tr>
            <tr>
              {sixthWeek.map((item, index) => {
                const data = holidays.find(
                  (i: any) =>
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      new Date(i.date).getFullYear(),
                      new Date(i.date).getMonth(),
                      new Date(i.date).getDate(),
                    ).getTime(),
                );
                return (
                  <td
                    className={
                      data?.holidayType === "AWOL" ||
                      data?.holidayType === "NO_SHOW"
                        ? `${styles.td} ${styles.td_error}`
                        : data
                          ? `${styles.td} ${styles.td_active}`
                          : styles.td
                    }
                    key={index}
                    onClick={() => {
                      item.date &&
                        dispatch(
                          setShowAddHolidayModal({
                            show: true,
                            minDate: `${new Date(item.date).getFullYear()}-${
                              new Date(item.date).getMonth() + 1 > 9
                                ? new Date(item.date).getMonth() + 1
                                : `0${new Date(item.date).getMonth() + 1}`
                            }-${
                              new Date(item.date).getDate() > 9
                                ? new Date(item.date).getDate()
                                : `0${new Date(item.date).getDate()}`
                            }`,
                          }),
                        );
                    }}
                  >
                    <Typography
                      textColor={
                        data
                          ? colors.white
                          : item.active
                            ? colors.black
                            : colors.gray1
                      }
                      marginVertical="0px"
                      paddingVertical="0px"
                    >
                      {item.text}
                    </Typography>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default YearMonthCalendar;
