import React from "react";
import { ViewUploadedItemProps } from "../../../types-definition/common";
import Typography from "../../Common/Typography";
import cancelCircleIcon from "../../../assets/svg/cancel-circle-icon.svg";
import styles from "./index.module.css";

const ViewUploadedItem: React.FC<ViewUploadedItemProps> = ({
  title,
  imageURl,
  cancelDisplay,
  path,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Typography textWeight="600">{title}</Typography>
        <img
          src={cancelCircleIcon}
          alt="cancel icon"
          className={styles.cancelImage}
          onClick={cancelDisplay}
        />
      </div>
      <img
        src={imageURl ? imageURl : URL.createObjectURL(path)}
        alt="file icon"
        className={styles.image}
      />
    </div>
  );
};

export default ViewUploadedItem;
