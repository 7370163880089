import React, { useState } from "react";
import Typography from "components/Common/Typography";
import size from "theme/size";
import TextInput from "components/Form/TextInput";
import styles from "./index.module.css";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { Link } from "react-router-dom";
import emailIcon from "assets/svg/Mail Icon.svg";
import glixLogo from "assets/svg/glix-logo.svg";
import ForgotPasswordSuccessModal from "components/Auth/ForgotPasswordSuccessModal";
import { postRequest } from "api/request";
import { FORGOT_PASSWORD_URL } from "api/urls";
import toast from "react-hot-toast";

const ForgotPassword: React.FC = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);

  const handleFormSubmission = async () => {
    // setShowSuccess(true);
    setRequestLoading(true);
    const response = await postRequest(FORGOT_PASSWORD_URL, formData);
    if (response?.success) {
      setShowSuccess(true);
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };
  return (
    <>
      <section className={styles.container}>
        <div className={styles.body_container}>
          <div className={styles.image}>
            <img src={glixLogo} alt="logo" width={56} height={56} />
          </div>
          <Typography textAlign="center" textSize={size.lg} textWeight="600">
            Forgot Your Password
          </Typography>
          <Typography
            textAlign="left"
            textSize={size.md}
            textColor={colors.gray1}
            style={{ width: "90%", margin: "auto" }}
          >
            Please enter your email address to reset your password
          </Typography>
          <div className={styles.form_container}>
            <TextInput
              value={formData.email}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, email: data };
                })
              }
              label="Email Address"
              placeholder="Enter Email"
              right={<img src={emailIcon} alt="icon" width={20} height={20} />}
            />
            <Button
              text="Reset Password"
              isPrimary={true}
              isLoading={requestLoading}
              onClick={() => {
                handleFormSubmission();
              }}
            />
          </div>
          <div className={styles.bottomText}>
            <Typography textColor={colors.gray1}>
              Take me back to &nbsp;
            </Typography>
            <Link to="/login" style={{ textDecoration: "none" }}>
              <Typography
                textWeight="600"
                style={{
                  background: "linear-gradient(#006093, #00dcda)",
                  WebkitTextFillColor: "transparent",
                  WebkitBackgroundClip: "text",
                }}
              >
                Log In
              </Typography>
            </Link>
          </div>
        </div>
      </section>
      {showSuccess && <ForgotPasswordSuccessModal />}
    </>
  );
};

export default ForgotPassword;
