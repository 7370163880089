import React from "react";
import NumberInput from "../../../Form/NumberInput";
import { ShiftGenerationProps } from "../../../../types-definition/admin";
import colors from "../../../../theme/colors";
import Checkbox from "../../../Form/Checkbox";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";

const ShiftGenerationForm: React.FC<ShiftGenerationProps> = ({
  formData,
  setFormData,
  setRequiredFormData,
  requiredFormData,
}) => {
  return (
    <>
      <Typography textAlign="left" textWeight="600" textColor={colors.gray5}>
        Generation Time 09:00PM - 02:00AM
      </Typography>
      <NumberInput
        label="Days To Generate Ahead"
        placeholder=""
        setValue={(data) => {
          setFormData((prevData) => {
            return { ...prevData, daysToGenerateAhead: data };
          });
          setRequiredFormData((prevData) => {
            return { ...prevData, daysToGenerateAhead: false };
          });
        }}
        value={formData.daysToGenerateAhead}
        error={requiredFormData.daysToGenerateAhead}
        helperText={
          requiredFormData.daysToGenerateAhead
            ? "Days To Generate Ahead is required"
            : ""
        }
      />

      <div className={styles.checkbox_container}>
        <Checkbox
          marginVertical="20px"
          value={formData.emailNotification}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, emailNotification: data };
            });
          }}
          label={
            <Typography
              textAlign="left"
              textWeight="500"
              marginHorizontal="-10px"
              textColor={colors.gray5}
            >
              Email Notification
            </Typography>
          }
          toolTipBottom="-75px"
          isToolTip
          toolTipText="Sends an email to the assigned Web User overseeing a Location when the automated system is unable to generate a suitable suggestion"
        />
      </div>
    </>
  );
};

export default ShiftGenerationForm;
