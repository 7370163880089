import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import Button from "../../Form/Button";
import { useNavigate } from "react-router-dom";

const VerifyMailModal = () => {
  const navigate = useNavigate();
  return (
    <section className={styles.container}>
      <div className={styles.content_container}>
        <Typography textAlign="left" textWeight="600" textSize="18px">
          Confirm Your Email and Get Started with Glix
        </Typography>
        <Typography textAlign="left" marginVertical="15px">
          A link has been sent to your email, please click on the link to
          activate your account and enjoy the Glix experience.
        </Typography>
        <Button
          onClick={() => navigate("/login")}
          paddingVertical="5px"
          marginVertical="10px"
          text="Verify Email"
          isPrimary={true}
        />
      </div>
    </section>
  );
};

export default VerifyMailModal;
