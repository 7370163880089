import { getCompanyName } from "./localstorage";
const getCompanyCode = () => {
  const companyName = getCompanyName();
  if (companyName) {
    let name: Array<string> = [];
    const companyNameStringArray = companyName.split(" ");
    companyNameStringArray.forEach((item) => {
      name.push(item.slice(0, 1));
    });
    return name.join(" ").replace(/\s/g, "").toUpperCase();
  } else {
    return "";
  }
};
export default getCompanyCode;
