import React, { useEffect, useState } from "react";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import { PasswordInputProps } from "../../../types-definition/form";
import styles from "./index.module.css";
import eyeClosedIcon from "../../../assets/svg/eye-slash.svg";
import eyeOpenIcon from "../../../assets/svg/eye-open.svg";
import colors from "../../../theme/colors";

const PasswordInput: React.FC<PasswordInputProps> = ({
  placeholder,
  label,
  right,
  value,
  setValue,
  useValidation = false,
  width,
  required = false,
  error = false,
  style,
  helperText,
  id,
  readOnly=false
}) => {
  const [showPassword, setShowpassword] = useState(false);
  const [validationStatus, setValidationStatus] = useState({
    minLenght: false,
    capitalLetter: false,
    number: false,
    specialCharacter: false,
  });
  const validatePassword = (value: string) => {
    setValue(value);
    if (value.length >= 8) {
      setValidationStatus((prevData) => {
        return { ...prevData, minLenght: true };
      });
    } else {
      setValidationStatus((prevData) => {
        return { ...prevData, minLenght: false };
      });
    }
    if (/[A-Z]/.test(value)) {
      setValidationStatus((prevData) => {
        return { ...prevData, capitalLetter: true };
      });
    } else {
      setValidationStatus((prevData) => {
        return { ...prevData, capitalLetter: false };
      });
    }
    if (/[0-9]/.test(value)) {
      setValidationStatus((prevData) => {
        return { ...prevData, number: true };
      });
    } else {
      setValidationStatus((prevData) => {
        return { ...prevData, number: false };
      });
    }

    // eslint-disable-next-line no-useless-escape
    if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
      setValidationStatus((prevData) => {
        return { ...prevData, specialCharacter: true };
      });
    } else {
      setValidationStatus((prevData) => {
        return { ...prevData, specialCharacter: false };
      });
    }
  };

  // Runs Validation Everytime value changes
  useEffect(() => {
    if (useValidation) {
      validatePassword(value?.toString() || "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <section className={styles.container} style={{ ...style }} id={id}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <div className={styles.inputContainer}>
        <input
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            // if (useValidation) {
            //   validatePassword(e.target.value);
            // } else {
            //   setValue(e.target.value);
            // }
            setValue(e.target.value);
          }}
          type={showPassword ? "text" : "password"}
          className={`${styles.input} ${styles.right_padding}`}
          style={{
            width,
            borderColor: error ? colors.error : colors.gray3,
          }}
          readOnly={readOnly}
        />
        <div className={styles.helpertext_container}>
          <Typography
            textAlign="left"
            textSize={"10px"}
            textWeight="600"
            textColor={error ? colors.error : colors.primary3}
          >
            {helperText}
          </Typography>
        </div>
        <div
          className={styles.right}
          onClick={() => setShowpassword((passwordValue) => !passwordValue)}
        >
          {!showPassword ? (
            <img src={eyeClosedIcon} alt="eyeicon" width={20} height={20} />
          ) : (
            <img src={eyeOpenIcon} alt="eyeicon" width={20} height={20} />
          )}
        </div>
      </div>
      {useValidation && (
        <div className={styles.validationlist_container}>
          <ul className={styles.ul}>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={
                validationStatus.minLenght ? colors.primary2 : colors.gray1
              }
            >
              <li className={styles.li}>Minimum of 8 characters</li>
            </Typography>

            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={
                validationStatus.capitalLetter ? colors.primary2 : colors.gray1
              }
            >
              <li className={styles.li}>At least one capital letter</li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={
                validationStatus.specialCharacter
                  ? colors.primary2
                  : colors.gray1
              }
            >
              <li className={styles.li}>At least one special character</li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={
                validationStatus.number ? colors.primary2 : colors.gray1
              }
            >
              <li className={styles.li}>At least one number</li>
            </Typography>
          </ul>
        </div>
      )}
    </section>
  );
};

export default PasswordInput;
