import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShowIdInterface } from "../../../types-definition/common";
import type { RootState } from "../../store";

// Define the initial state using that type
const initialState = {
  showPermissionGroupModal: false,
  showPermissionGroupDeleteModal: false,
  showEditPermissionGroupModal: false,
  showViewPermissionGroupModal: false,
  showClonePermissionGroupModal: false,
  showViewClonePermissionGroupModal: false,
  fetchedPermissionGroupCounter: 0,
  currentSelectedId: "",
  searchedValue: "",
  isCloned: false,
  showAssignPermissionModal: false,
  showLinkLocationModal: false,
  showViewWebUserPermissionModal: false,
};

export const adminSlice = createSlice({
  name: "admin",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setShowPermissionGroup: (state, action: PayloadAction<boolean>) => {
      state.showPermissionGroupModal = action.payload;
    },
    setShowPermissionGroupDeleteModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showPermissionGroupDeleteModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
      if (action.payload.cloned) {
        state.isCloned = action.payload.cloned;
      }
    },
    setShowPermissionGroupEditModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showEditPermissionGroupModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
      if (action.payload.cloned) {
        state.isCloned = action.payload.cloned;
      }
    },
    setShowPermissionGroupViewModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showViewPermissionGroupModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
      if (action.payload.cloned) {
        state.isCloned = action.payload.cloned;
      }
    },
    setShowClonePermissionGroupModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showClonePermissionGroupModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
      if (action.payload.cloned) {
        state.isCloned = action.payload.cloned;
      }
    },
    setShowViewClonePermissionGroupModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showViewClonePermissionGroupModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
    },
    updateFetchedPermissionGroupCounter: (state) => {
      state.fetchedPermissionGroupCounter =
        state.fetchedPermissionGroupCounter + 1;
    },
    setShowAssignPermissionModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showAssignPermissionModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
      if (action.payload.cloned) {
        state.isCloned = action.payload.cloned;
      }
    },
    setShowLinkLocationModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showLinkLocationModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
      if (action.payload.cloned) {
        state.isCloned = action.payload.cloned;
      }
    },
    setShowViewWebUserPermissionModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showViewWebUserPermissionModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
      if (action.payload.cloned) {
        state.isCloned = action.payload.cloned;
      }
    },
    setSearchedWebUserValue: (state, action: PayloadAction<string>) => {
      state.searchedValue = action.payload;
    },
  },
});

export const {
  setShowPermissionGroup,
  setShowPermissionGroupDeleteModal,
  setShowPermissionGroupEditModal,
  setShowPermissionGroupViewModal,
  setShowClonePermissionGroupModal,
  updateFetchedPermissionGroupCounter,
  setShowAssignPermissionModal,
  setShowLinkLocationModal,
  setShowViewWebUserPermissionModal,
  setShowViewClonePermissionGroupModal,
  setSearchedWebUserValue,
} = adminSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;
// export const IsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const getShowPermissionModal = (state: RootState) =>
  state.admin.showPermissionGroupModal;
export const getShowPermissionDeleteModal = (state: RootState) =>
  state.admin.showPermissionGroupDeleteModal;
export const getShowPermissionEditModal = (state: RootState) =>
  state.admin.showEditPermissionGroupModal;
export const getShowPermissionViewModal = (state: RootState) =>
  state.admin.showViewPermissionGroupModal;
export const getShowPermissionCloneModal = (state: RootState) =>
  state.admin.showClonePermissionGroupModal;
export const getFetchedPermissionGroupCounter = (state: RootState) =>
  state.admin.fetchedPermissionGroupCounter;
export const getCurrentSelectedId = (state: RootState) =>
  state.admin.currentSelectedId;
export const getShowAssignPermissionModal = (state: RootState) =>
  state.admin.showAssignPermissionModal;
export const getShowLinkLocationModal = (state: RootState) =>
  state.admin.showLinkLocationModal;
export const getShowViewWebUserPermissionModal = (state: RootState) =>
  state.admin.showViewWebUserPermissionModal;
export const getIsCloned = (state: RootState) => state.admin.isCloned;
export const getShowViewClonePermissionGroupModal = (state: RootState) =>
  state.admin.showViewClonePermissionGroupModal;
export const getSearchedWebUserValue = (state: RootState) =>
  state.admin.searchedValue;

export default adminSlice.reducer;
