import Typography from "../Typography";
import styles from "./index.module.css";
import glixLogo from "../../../assets/svg/glix-logo.svg";
import colors from "../../../theme/colors";
const LiveLocationMonitoring = () => {
  return (
    <div className={styles.header}>
      <div className={styles.flex_item}>
        <img src={glixLogo} alt="glix logo" width={56} height={56} />

        <Typography
          marginHorizontal="0px"
          textSize="22px"
          textWeight="600"
          textAlign="left"
          fontFamily="inter"
          textColor={colors.black}
          marginVertical="0px"
        >
          Live Location Monitoring Dashboard
        </Typography>
      </div>
    </div>
  );
};

export default LiveLocationMonitoring;
