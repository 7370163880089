import React from "react";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import { useNavigate } from "react-router-dom";
import successIcon from "../../../assets/svg/success-modal-icon.svg";
import { useAppDispatch } from "redux/hooks";
import { showWizardModal } from "redux/slice/WizardSetup";
import { updateSetupComplete } from "utils/setupWizard";
const WizardModal: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        {" "}
        <img src={successIcon} alt="" />
        <Typography textSize="16px" textWeight="600" textAlign="left">
          Congratulations! Setup Complete 🚀
        </Typography>
        <Typography
          textSize="14px"
          textWeight="500"
          textAlign="left"
          textColor="#7E8494"
          marginVertical="14px"
        >
          You have successfully completed your Glix workforce setup. Your system
          is now configured and ready to use.
        </Typography>
        <Button
          text="Explore Glix"
          isPrimary
          onClick={async () => {
            const setUpComplete = await updateSetupComplete(
              "14",
              true,
              "holiday",
            );

            if (!setUpComplete) {
              navigate("/dashboard");
            }
            dispatch(showWizardModal({ showModal: false }));
          }}
        />
      </div>
    </div>
  );
};

export default WizardModal;
