import React from "react";
import Typography from "../../../Common/Typography";
import GradeTableItem from "../GradeTableItem";
import styles from "./index.module.css";
import colors from "../../../../theme/colors";
import { GradeTableProps } from "../../../../types-definition/admin";

const GradeTable: React.FC<GradeTableProps> = ({ data = [] }) => {
  return (
    <>
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.tr} ${styles.tr_head}`}>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Name
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Description
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Colour
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Certificate Group
                </Typography>
              </th>
              <th className={styles.th_index}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <GradeTableItem {...item} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GradeTable;
