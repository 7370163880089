import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { HolidayMultiplierTableProps } from "../../../../types-definition/admin";
import colors from "../../../../theme/colors";
import HolidayMultiplierTableItem from "../HolidayMultiplierTableItem";

const HolidayMultiplierTable: React.FC<HolidayMultiplierTableProps> = ({
  data = [],
}) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr}`}>
            <th className={styles.th_head}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
                style={{ flex: 1 }}
              >
                Holiday Date
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Holiday Name
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Country/Region
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Pay Rate Multiplier
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Charge Rate Multiplier
              </Typography>
            </th>
            <th className={styles.th_rate}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Rate Multiplier
              </Typography>
            </th>
            <th className={styles.th_index}></th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return <HolidayMultiplierTableItem {...item} key={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default HolidayMultiplierTable;
