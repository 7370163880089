import React from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import { RosterWeekViewTableItemsProps } from "../../../types-definition/roster";
import { timeHourlyArray } from "../../../utils/timeArray";

const RosterWeekViewTableItem: React.FC<RosterWeekViewTableItemsProps> = () => {
  return (
    <>
      {timeHourlyArray.map((hourlyItem) => {
        return (
          <tr className={styles.tr}>
            <td className={styles.td}>
              <div className={styles.td_item}>
                <Typography textAlign="center">{hourlyItem}</Typography>
              </div>
            </td>
            <td className={styles.td}></td>
            <td className={styles.td}></td>
            <td className={styles.td}></td>
            <td className={styles.td}></td>
            <td className={styles.td}></td>
          </tr>
        );
      })}
    </>
  );
};

export default RosterWeekViewTableItem;
