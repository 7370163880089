import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import { CircularNameProps } from "../../../types-definition/common";

const CircularName: React.FC<CircularNameProps> = ({ name }) => {
  const text = name.split(" ");
  return (
    <div className={styles.container}>
      <Typography textWeight="600">{`${text[0][0]}${text[1][0]}`}</Typography>
    </div>
  );
};

export default CircularName;
