import React from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import arrowLeftIcon from "../../../assets/svg/arrow-left.svg";
import Typography from "../Typography";
import { PageBackButtonProps } from "../../../types-definition/common";
import {
  deleteCurrentLocation,
  getCurrentLocation,
} from "../../../utils/localstorage";

const PageBackButton: React.FC<PageBackButtonProps> = ({
  width = "95%",
  paddingTop = "40px",
  marginBottom = "-30px",
  useStoredLocationUrl = false,
  to,
}) => {
  const navigate = useNavigate();
  const storedLocationUrl = getCurrentLocation();
  return (
    <div
      className={styles.container}
      style={{ width, paddingTop, marginBottom }}
    >
      <div
        className={styles.back_button_container}
        onClick={() => {
          if (to) {
            navigate(to);
          } else if (useStoredLocationUrl && storedLocationUrl) {
            deleteCurrentLocation();
            navigate(storedLocationUrl);
          } else {
            navigate(-1);
          }
        }}
      >
        <img src={arrowLeftIcon} alt="arrow-right-icon" />
        <Typography textWeight="600" textSize="18px">
          Back
        </Typography>
      </div>
    </div>
  );
};

export default PageBackButton;
