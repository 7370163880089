import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import Button from "components/Form/Button";
import searchIcon from "assets/svg/search-icon.svg";
import colors from "theme/colors";
import addIcon from "assets/svg/add-icon.svg";
import { useNavigate } from "react-router-dom";
import EmptyItemState from "components/Common/EmptyItemState";
import SuccessModal from "components/Common/SuccessModal";
import SiteLocationTable from "components/Client/Site Location/SiteLocationTable";
import { deleteSiteLocation, getData, getUserTypes } from "utils/localstorage";
import { SiteLocationData } from "types-definition/client";
import { getRequest } from "api/request";
import { useAppSelector } from "../../../../redux/hooks";
import { GET_LOCATION_URL, GET_WEB_USER_URL, SERVICE_LOCATION } from "api/urls";
import PageLoader from "components/Common/PageLoader";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { getNewLocationState } from "../../../../redux/slice/client/locationSlice";
import DeleteLocationModal from "components/Client/Site Location/DeleteLocationModal";
import SiteLocationTableFilter from "components/Client/Site Location/SiteLocationTableFilter";
import checkAdminPermission, { checkUserPermission } from "utils/permission";
import { LOCATION_UPDATE_AND_ADD } from "types-definition/constants";

const SiteLocationDashbaord = () => {
  const navigate = useNavigate();
  const showAddModal = useAppSelector(getNewLocationState);
  const [filteredLocationData, setFilteredLocationData] = useState<
    Array<SiteLocationData>
  >([]);
  const [sortFilter, setSortFilter] = useState("");
  const [sortFilterName, setSortFilterName] = useState("");
  const [isfilter, setIsfilter] = useState(false);
  const [searchedValues, setSearchedValues] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [locationData, setLocationData] = useState<Array<SiteLocationData>>([]);
  const [requestLoading, setRequestLoading] = useState(true);
  const [isLocationPermission, setIsLocationPermission] = useState(true);
  useEffect(() => {
    const getAllSiteLocation = async () => {
      try {
        const response = await getRequest(GET_LOCATION_URL);

        if (response?.success) {
          const locationDataArray: Array<SiteLocationData> = [];
          for (const index in response.data) {
            const serviceLocationResponse = await getRequest(
              `${SERVICE_LOCATION}/${response.data[index].id}`,
            );
            // const managedByLocationResponse = await getRequest(
            //   `${GET_WEB_USER_URL}/${response.data[index].managedBy}`,
            // );
            const managedBy = JSON.parse(response.data[index].managedBy || "{}")
              ?.name;

            const locationDataObject: SiteLocationData = {
              id: response.data[index].id,
              name: response.data[index].name,
              active: response.data[index].active,
              address: response.data[index].address.address1,
              client: response.data[index].client,
              managedBy: managedBy ? managedBy : JSON.parse(getData()!).name,
              services: serviceLocationResponse?.data?.filter(
                (serviceInformation: any) =>
                  Object.entries(serviceInformation.service).length !== 0,
              ).length,
              locations: response.data[index].region,
              areaName: response.data[index].areaName,
              clientName: response.data[index].clientName,
            };
            locationDataArray.push(locationDataObject);
          }
          setLocationData(locationDataArray);
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      } finally {
        setRequestLoading(false);
      }
    };
    getAllSiteLocation();
  }, [showAddModal]);

  useEffect(() => {
    deleteSiteLocation();
  }, []);

  useEffect(() => {
    const getPermission = async () => {
      const data = checkAdminPermission(getUserTypes());
      if (data) {
        setIsLocationPermission(false);
      } else {
        const result = await checkUserPermission([LOCATION_UPDATE_AND_ADD]);
        setIsLocationPermission(result ? false : true);
      }
    };
    getPermission();
  }, []);

  // Filter Logic
  useEffect(() => {
    if (sortFilter && searchedValues) {
      const localLocationData = [...locationData];
      setIsfilter(true);
      if (sortFilter === "Ascending Order") {
        setIsfilter(true);
        setFilteredLocationData(
          localLocationData
            .sort((a: SiteLocationData, b: SiteLocationData) => {
              if (sortFilterName === "Name") {
                return a.name.localeCompare(b.name);
              } else if (sortFilterName === "Client") {
                return a.clientName.localeCompare(b.clientName);
              } else if (sortFilterName === "Service") {
                return parseInt(a.services) - parseInt(b.services);
              } else if (sortFilterName === "Managed By") {
                return a.managedBy.localeCompare(b.managedBy);
              } else {
                return parseInt(a.name) - parseInt(b.name);
              }
            })
            .filter((data: SiteLocationData) => {
              return (
                data.name
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.areaName
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.clientName
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.address
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase())
              );
            }),
        );
      } else if (sortFilter === "Descending Order") {
        setIsfilter(true);
        setFilteredLocationData(
          localLocationData
            .sort((a: any, b: any) => {
              if (sortFilterName === "Name") {
                return b.name.localeCompare(a.name);
              } else if (sortFilterName === "Client") {
                return b.clientName.localeCompare(a.clientName);
              } else if (sortFilterName === "Service") {
                return parseInt(b.services) - parseInt(a.services);
              } else if (sortFilterName === "Managed By") {
                return a.managedBy.localeCompare(b.managedBy);
              } else {
                return parseInt(b.name) - parseInt(a.name);
              }
            })
            .filter((data: SiteLocationData) => {
              return (
                data.name
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.areaName
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.clientName
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.address
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.managedBy
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase())
              );
            }),
        );
      } else if (sortFilter === "Active") {
        setIsfilter(true);
        const localLocationData = [...locationData];
        setFilteredLocationData(
          localLocationData
            .filter((item: SiteLocationData) => {
              return item.active;
            })
            .filter((data: SiteLocationData) => {
              return (
                data.name
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.areaName
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.clientName
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.address
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.managedBy
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase())
              );
            }),
        );
      } else {
        setIsfilter(true);
        const localLocationData = [...locationData];
        setFilteredLocationData(
          localLocationData
            .filter((item: SiteLocationData) => {
              return !item.active;
            })
            .filter((data: SiteLocationData) => {
              return (
                data.name
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.areaName
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.clientName
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.address
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.managedBy
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase())
              );
            }),
        );
      }
    } else if (searchedValues) {
      setIsfilter(true);
      setFilteredLocationData(
        locationData.filter((data: SiteLocationData) => {
          return (
            data.name.toLowerCase().includes(searchedValues.toLowerCase()) ||
            data.areaName
              .toLowerCase()
              .includes(searchedValues.toLowerCase()) ||
            data.clientName
              .toLowerCase()
              .includes(searchedValues.toLowerCase()) ||
            data.address.toLowerCase().includes(searchedValues.toLowerCase()) ||
            data.managedBy.toLowerCase().includes(searchedValues.toLowerCase())
          );
        }),
      );
    } else if (sortFilter) {
      setIsfilter(true);
      if (sortFilter === "Ascending Order") {
        const localLocationData = [...locationData];
        setFilteredLocationData(
          localLocationData.sort((a: SiteLocationData, b: SiteLocationData) => {
            if (sortFilterName === "Name") {
              return a.name.localeCompare(b.name);
            } else if (sortFilterName === "Client") {
              return a.clientName.localeCompare(b.clientName);
            } else if (sortFilterName === "Service") {
              return parseInt(a.services) - parseInt(b.services);
            } else if (sortFilterName === "Managed By") {
              return a.managedBy.localeCompare(b.managedBy);
            } else {
              return parseInt(a.name) - parseInt(b.name);
            }
          }),
        );
      } else if (sortFilter === "Descending Order") {
        setIsfilter(true);
        const localLocationData = [...locationData];
        setFilteredLocationData(
          localLocationData.sort((a: SiteLocationData, b: SiteLocationData) => {
            if (sortFilterName === "Name") {
              return b.name.localeCompare(a.name);
            } else if (sortFilterName === "Client") {
              return b.clientName.localeCompare(a.clientName);
            } else if (sortFilterName === "Service") {
              return parseInt(b.services) - parseInt(a.services);
            } else if (sortFilterName === "Managed By") {
              return b.managedBy.localeCompare(a.managedBy);
            } else {
              return parseInt(b.name) - parseInt(a.name);
            }
          }),
        );
      } else if (sortFilter === "Active") {
        setIsfilter(true);
        const localLocationData = [...locationData];
        setFilteredLocationData(
          localLocationData.filter((item: SiteLocationData) => {
            return item.active === true;
          }),
        );
      } else {
        setIsfilter(true);
        const localLocationData = [...locationData];
        setFilteredLocationData(
          localLocationData.filter((item: SiteLocationData) => {
            return item.active === false;
          }),
        );
      }
    } else {
      setIsfilter(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValues, sortFilter]);

  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="clients" />
        {showSuccess && (
          <SuccessModal
            text="Client Site Location Added successfully"
            cancelFunction={() => {
              setShowSuccess(false);
            }}
          />
        )}
        <div className={styles.container}>
          <div
            className={
              locationData.length <= 5
                ? `${styles.item_container} ${styles.item_container_empty}`
                : `${styles.item_container} ${styles.item_container_fill}`
            }
          >
            <div className={styles.flex_item}>
              <div>
                <Typography textAlign="left" textWeight="600" textSize="20px">
                  Site Location Management
                </Typography>
                <Typography textAlign="left" textColor={colors.gray1}>
                  Organize your employees' work schedules using site locations.
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <SiteLocationTableFilter
                  runFilter={(data, name) => {
                    setSortFilter(data);
                    setSortFilterName(name);
                  }}
                  clearFilter={() => setSortFilter("")}
                />
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={searchedValues}
                  setValue={(value) => {
                    setSearchedValues(value);
                  }}
                />
                <Button
                  left={<img src={addIcon} alt="add" />}
                  marginHorizontal="10px"
                  marginTop="8px"
                  paddingVertical="7px"
                  width="200px"
                  isPrimary
                  disabled={isLocationPermission}
                  text="Add Site Location"
                  onClick={() => navigate("/client/general")}
                />
              </div>
            </div>
            {locationData.length === 0 ? (
              <EmptyItemState
                text="You haven't added any site location yet"
                location="client/general"
                label="Add Site Location"
              />
            ) : (
              <SiteLocationTable
                data={isfilter ? filteredLocationData : locationData}
              />
            )}
          </div>
        </div>
      </section>
      {showAddModal && <DeleteLocationModal />}
    </>
  );
};

export default SiteLocationDashbaord;
