import React, { useState } from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import HolidayAbssenceDropdown from "../HolidayDropdown";
import { HolidayAbsenceSummaryData } from "../../../../types-definition/StaffHub/holiday";
import moment from "moment";
import HolidayAbsenceStatus from "../HolidayAbsenceStatus";
import capitalize from "../../../../utils/capitalize";

const HolidayAbsenceTableItem: React.FC<HolidayAbsenceSummaryData> = ({
  id,
  employeeName,
  fromDate,
  toDate,
  timeOff,
  holidayType,
  modifiedBy,
  updatedAt,
  status,
  employeeId,
}) => {
  const [showDropDown, setshowDropdown] = useState(false);
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left">{employeeName}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{holidayType}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {timeOff === "allDay" ? "All Day" : "Half Day"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {moment(new Date(fromDate)).format("DD/MM/YYYY")}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {moment(new Date(toDate)).format("DD/MM/YYYY")}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{modifiedBy}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {moment(new Date(updatedAt)).format("DD/MM/YYYY")}
        </Typography>
      </td>
      <td className={styles.td}>
        <HolidayAbsenceStatus status={capitalize(status)} />
      </td>
      <td className={styles.td}>
        <div
          className={`${styles.image_container} ${styles.td_relative}`}
          onClick={() => setshowDropdown((prevData) => !prevData)}
          onBlur={() => setshowDropdown(false)}
          tabIndex={0}
        >
          {showDropDown && (
            <HolidayAbssenceDropdown id={id} employeeId={employeeId} />
          )}
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};
export default HolidayAbsenceTableItem;
