import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../Common/PageBackButton";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../utils/localstorage";
import { getRequest } from "../../../../api/request";
import PageLoader from "../../../Common/PageLoader";
import {
  GET_LOCATION_URL,
  GET_SERVICE_URL,
  GET_SYSTEM_ACTIVITY,
} from "../../../../api/urls";
import { TimeShiftGeneration } from "../../../../types-definition/admin";
import DashboardHeader from "../../../Common/DashboardHeader";
import { toastError } from "utils/toast";
import moment from "moment";
const ActivityTimeShift = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentTimeShift, setCurrentTimeShift] = useState<TimeShiftGeneration>(
    {
      to: "",
      from: "",
      location: [],
      service: [],
    },
  );

  const [previousTimeShift, setPreviousTimeShift] =
    useState<TimeShiftGeneration>({
      to: "",
      from: "",
      location: [],
      service: [],
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getTimeShift = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const locationId = response.data.newRecord.timeShift[0]
          ? response.data.newRecord.timeShift[0].locations
          : "";
        let serviceId = response.data.newRecord.timeShift[0]
          ? response.data.newRecord.timeShift[0].services
          : "";
        const serviceResponse = await getRequest(
          `${GET_SERVICE_URL}/${serviceId}`,
        );
        const locationRes = await getRequest(
          `${GET_LOCATION_URL}/${locationId}`,
        );
        const location = locationRes.data.name;
        const service = serviceResponse.data.name;
        const data = response.data;
        if (response.data.action === "POST") {
          if (response.data.newRecord.timeShift[0]) {
            setCurrentTimeShift({
              from: data?.newRecord?.timeShift[0].from,
              to: data?.newRecord?.timeShift[0].to,
              service: service,
              location: location,
            });
            setPreviousTimeShift({
              from: data?.previousRecord?.timeShift[0].from,
              to: data?.previousRecord?.timeShift[0].to,
              service: service,
              location: location,
            });
          }
        } else {
          if (response.data.newRecord.timeShift[0]) {
            setCurrentTimeShift({
              from: response?.data?.newRecord?.timeShift[0].from,
              to: response?.data?.newRecord?.timeShift[0].to,
              service: service,
              location: location,
            });
            setPreviousTimeShift({
              from: response?.data?.previousRecord?.timeShift[0].from,
              to: response?.data?.previousRecord?.timeShift[0].to,
              service: service,
              location: location,
            });
          }
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getTimeShift();
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New TimeShift
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">From</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentTimeShift.from
                    ? moment(currentTimeShift.from).format("DD/MM/YY")
                    : "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">To</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentTimeShift.to
                    ? moment(currentTimeShift.to).format("DD/MM/YY")
                    : "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Location</Typography>

                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentTimeShift.location}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Service</Typography>

                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentTimeShift.service}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous TimeShift
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">From</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousTimeShift.from
                    ? moment(previousTimeShift.from).format("DD/MM/YY")
                    : "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">To</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousTimeShift.to
                    ? moment(previousTimeShift.to).format("DD/MM/YY")
                    : "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Location</Typography>

                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousTimeShift.location}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Service</Typography>

                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousTimeShift.service}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityTimeShift;
