import { handleShowModal } from "redux/slice/staff-hub/linkToLocationSlice";
import { LinkEmployeesToLocationTableItemData } from "types-definition/client/linkToLocation";
import {
  getLocation,
  getLocationName,
  setCurrentEmployee,
  setLocationDetails,
} from "utils/localstorage";
import { useAppDispatch } from "../../../../../redux/hooks";
import Typography from "../../../../Common/Typography";
import styles from "./index.module.css";

const LinkEmployeesToLocationTableItem: React.FC<
  LinkEmployeesToLocationTableItemData
> = ({
  employeeId,
  employeeName,
  employeeUsername,
  grade,
  distance,
  payRate,
}) => {
  const dispatch = useAppDispatch();

  const handleLinking = (id: string) => {
    setCurrentEmployee(
      JSON.stringify({
        userName: employeeUsername,
        displayName: employeeName,
        employeeId: employeeId,
        payRate: payRate,
      }),
    );

    setLocationDetails(
      JSON.stringify({
        distance,
        location: getLocationName(),
        locationId: getLocation(),
      }),
    );

    dispatch(handleShowModal({ showModal: true }));
  };

  return (
    <>
      <tr className={styles.tr}>
        <td className={`${styles.td} ${styles.td_name}`}>
          <Typography
            textAlign="left"
            marginHorizontal="20px"
            marginVertical="12px"
          >
            {employeeName}
          </Typography>
        </td>
        <td className={styles.td}>
          <Typography
            textAlign="left"
            marginHorizontal="20px"
            marginVertical="12px"
          >
            {grade}
          </Typography>
        </td>
        <td className={styles.td}>
          <Typography
            textAlign="left"
            marginHorizontal="20px"
            marginVertical="12px"
          >
            {parseFloat(distance) < 1 ? "less than 1 mile" : distance}
          </Typography>
        </td>
        <td className={styles.td}>
          <div className={styles.flex_item}>
            <div onClick={() => handleLinking(employeeId)}>
              <Typography
                textWeight="600"
                textColor="#148A9b"
                style={{
                  cursor: "pointer",
                }}
              >
                Link
              </Typography>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default LinkEmployeesToLocationTableItem;
