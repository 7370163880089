import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import { Link } from "react-router-dom";
import glixLogo from "../../../assets/svg/glix-logo.svg";
import { SignUpHeaderProps } from "../../../types-definition/common";
import colors from "../../../theme/colors";
const SignUpHeader: React.FC<SignUpHeaderProps> = ({ showLogin = true }) => {
  return (
    <section className={styles.container}>
      <img src={glixLogo} alt="glix logo" width={56} height={56} />
      {showLogin && (
        <div className={styles.text_container}>
          <Typography>Already have an account? &nbsp;</Typography>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Typography
              textWeight="600"
              // style={{
              //   background: "linear-gradient(#006093, #00dcda)",
              //   WebkitTextFillColor: "transparent",
              //   WebkitBackgroundClip: "text",
              // }}
              textColor={colors.primary3}
            >
              Log In
            </Typography>
          </Link>
        </div>
      )}
    </section>
  );
};

export default SignUpHeader;
