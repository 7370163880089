import React, { useState } from "react";
import style from "./index.module.css";
import YearMonthCalendar from "../YearMonthCalendar";
import Typography from "../../../../Common/Typography";
import chevronLeftIcon from "../../../../../assets/svg/chevron-left.svg";
import chevronRightIcon from "../../../../../assets/svg/chevron-right.svg";

const YearCalendar = () => {
  const [date, setDate] = useState(new Date());
  const handleDateUpdate = (action: "decrease" | "increase") => {
    if (action === "decrease") {
      setDate(
        new Date(date.getFullYear() - 1, date.getMonth(), date.getDate()),
      );
    } else {
      setDate(
        new Date(date.getFullYear() + 1, date.getMonth(), date.getDate()),
      );
    }
  };
  return (
    <div className={style.container}>
      <div className={style.toptext}>
        <img
          src={chevronLeftIcon}
          alt="left-icon"
          onClick={() => handleDateUpdate("decrease")}
        />
        <Typography textWeight="600" textSize="16px">
          {date.getFullYear()}
        </Typography>
        <img
          src={chevronRightIcon}
          alt="right-icon"
          onClick={() => handleDateUpdate("increase")}
        />
      </div>
      <div className={style.item_container}>
        <YearMonthCalendar currentDate={`${date.getFullYear()}-01-01`} />
        <YearMonthCalendar currentDate={`${date.getFullYear()}-02-01`} />
        <YearMonthCalendar currentDate={`${date.getFullYear()}-03-01`} />
        <YearMonthCalendar currentDate={`${date.getFullYear()}-04-01`} />
      </div>
      <div className={style.item_container}>
        <YearMonthCalendar currentDate={`${date.getFullYear()}-05-01`} />
        <YearMonthCalendar currentDate={`${date.getFullYear()}-06-01`} />
        <YearMonthCalendar currentDate={`${date.getFullYear()}-07-01`} />
        <YearMonthCalendar currentDate={`${date.getFullYear()}-08-01`} />
      </div>
      <div className={style.item_container}>
        <YearMonthCalendar currentDate={`${date.getFullYear()}-09-01`} />
        <YearMonthCalendar currentDate={`${date.getFullYear()}-10-01`} />
        <YearMonthCalendar currentDate={`${date.getFullYear()}-11-01`} />
        <YearMonthCalendar currentDate={`${date.getFullYear()}-12-01`} />
      </div>
    </div>
  );
};

export default YearCalendar;
