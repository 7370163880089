import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import cancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getCurrentSelectedId,
  setShowPermissionGroupViewModal,
} from "../../../../redux/slice/admin/adminSlice";
import searchIcon from "../../../../assets/svg/search-icon.svg";
import SearchInput from "../SearchInput";
import AvailablePermissionItem from "../AvailablePermissionItem";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../../api/request";
import {
  GET_ALL_PERMISSION_URL,
  GET_PERMISSION_GROUP_URL,
} from "../../../../api/urls";
import AssignedPermissionItem from "../AssignedPermissionItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toastError } from "../../../../utils/toast";
import capitalize from "utils/capitalize";
import { EditPermissionGroupModalProps } from "types-definition/admin";
import { filterDependentPermission } from "utils/filterDependentPermission";
import { filterNeededPermissions } from "utils/filterNeededPermission";
const EditPermissionGroupModal = ({
  showDependentPermissionModal,
}: EditPermissionGroupModalProps) => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(getCurrentSelectedId);
  const [monitorUnassignChanges, setMonitorUnassignChanges] = useState(0);
  const [formData, setFormData] = useState({
    availablePermissions: "",
    assignedPermissions: "",
  });
  const [availablePermissions, setAvailablePermissions] = useState<Array<any>>(
    [],
  );
  const [filteredAvailablePermissions, setFilteredAvailablePermissions] =
    useState<Array<any>>([]);
  const [assignedPermissions, setAssignedPermissions] = useState<Array<any>>(
    [],
  );

  const [filteredAssignedPermissions, setFilteredAssignedPermissions] =
    useState<Array<any>>([]);

  // Handle Search Bar for Available Permissions
  const handleSearchAvailablePermission = (value: string) => {
    setFilteredAvailablePermissions((prevData) => {
      return availablePermissions.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      );
    });
  };

  // Handle Search Bar for Assigned Permissions
  const handleSearchAssignedPermission = (value: string) => {
    setFilteredAssignedPermissions((prevData) => {
      return assignedPermissions.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      );
    });
  };

  // Handle Assigning New Permission
  const handleAssignNewPersmission = async (
    permissionId: string,
    permissionName: string,
  ) => {
    const permissionDependent = filterDependentPermission(
      availablePermissions,
      permissionName,
    );

    if (permissionDependent.length > 1) {
      showDependentPermissionModal(permissionDependent, permissionId);
    } else {
      const response = await postRequest(
        `settings/permission/${permissionId}/permission-group/${id}`,
      );
      if (response?.success) {
        setAvailablePermissions((prevData) => {
          return prevData.filter(
            (item) =>
              item.name.toLowerCase() !== response?.data.name.toLowerCase(),
          );
        });
        setAssignedPermissions((prevData) => {
          return [...prevData, response?.data];
        });
        setMonitorUnassignChanges((prevData) => prevData + 1);
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    }
  };
  const handleUnAssignPermission = async (permissionId: string) => {
    const response = await deleteRequest(
      `settings/permission/${permissionId}/permission-group/${id}`,
    );
    if (response?.success) {
      setMonitorUnassignChanges((prevData) => prevData + 1);
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };

  // Gets the available permissions and assigned permissions on modal load
  useEffect(() => {
    const getAssignedPermission = async () => {
      const response = await getRequest(`${GET_PERMISSION_GROUP_URL}/${id}`);
      if (response?.success) {
        setAssignedPermissions(response?.data?.permissionGroup?.permissions);
        if (formData.assignedPermissions) {
          setFilteredAssignedPermissions(
            response?.data?.permissionGroup?.permission?.filter((item: any) =>
              item.name
                .toLowerCase()
                .includes(formData?.assignedPermissions?.toLowerCase()),
            ),
          );
        }
        getAllPermissions(response?.data?.permissionGroup?.permissions);
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    };

    getAssignedPermission();

    const getAllPermissions = async (data: any) => {
      const response = await getRequest(GET_ALL_PERMISSION_URL);
      if (response?.success) {
        // fetch and filter the available permissions
        const result = response?.data.filter((item: any) =>
          data.every(
            (i: any) => i.name.toLowerCase() !== item.name.toLowerCase(),
          ),
        );
        const neededPermission = filterNeededPermissions(result);
        setAvailablePermissions(neededPermission);

        if (formData.availablePermissions) {
          filterNeededPermissions(
            response?.data
              .filter((item: any) =>
                data.every(
                  (i: any) => i.name.toLowerCase() !== item.name.toLowerCase(),
                ),
              )
              .filter((item: any) =>
                item.name
                  .toLowerCase()
                  .includes(formData?.availablePermissions?.toLowerCase()),
              ),
          );
          const filterNeededPermission = filterNeededPermissions(
            response?.data
              .filter((item: any) =>
                data.every(
                  (i: any) => i.name.toLowerCase() !== item.name.toLowerCase(),
                ),
              )
              .filter((item: any) =>
                item.name
                  .toLowerCase()
                  .includes(formData?.availablePermissions?.toLowerCase()),
              ),
          );
          setFilteredAvailablePermissions(filterNeededPermission);
        }
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitorUnassignChanges]);

  // Drag and Drop
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }
    if (result.destination.droppableId === "2") {
      const permissionId = result.draggableId.split("-")[2];
      const permissionName = result.draggableId.split("-")[3];
      const data = [...availablePermissions].filter(
        (item, index) => item.id !== permissionId,
      );

      setAvailablePermissions(data);
      handleAssignNewPersmission(permissionId, permissionName);
      return;
    }
    if (result.destination.droppableId === "1") {
      const permissionId = result.draggableId.split("-")[2];
      const data = [...assignedPermissions].filter(
        (item, index) => item.id === permissionId,
      );
      setAssignedPermissions(data);
      handleUnAssignPermission(permissionId);
      return;
    }
  };
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      dragHandleUsageInstructions="Use the spacebar to lift / drop the draggable item."
    >
      <div className={styles.container}>
        <div className={styles.content_container}>
          <div className={styles.header}>
            <Typography textWeight="600" textSize="18px">
              Permissions
            </Typography>
            <img
              src={cancelIcon}
              alt="cancel icon"
              className={styles.cancel_icon}
              onClick={() =>
                dispatch(
                  setShowPermissionGroupViewModal({ show: false, id: "" }),
                )
              }
            />
          </div>
          <div className={styles.flex_container}>
            <div className={styles.flex_item}>
              <div className={styles.flex_item_header}>
                <Typography textWeight="600" textAlign="left">
                  Available Permissions
                </Typography>
              </div>
              <div>
                <SearchInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={formData.availablePermissions}
                  setValue={(data) => {
                    setFormData((prevData) => {
                      return { ...prevData, availablePermissions: data };
                    });
                    handleSearchAvailablePermission(data);
                  }}
                />
              </div>
              <Droppable droppableId="1">
                {(provided) => (
                  <div
                    className={styles.permission_container}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {!formData.availablePermissions
                      ? availablePermissions.map((permission, index) => (
                          <Draggable
                            draggableId={`draggable-available-${permission.id}-${permission.name}`}
                            index={index}
                            key={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={styles.draggable_item}
                              >
                                <AvailablePermissionItem
                                  id={permission.id}
                                  key={index}
                                  text={capitalize(permission.name)}
                                  action={async (permissionId) => {
                                    await handleAssignNewPersmission(
                                      permissionId,
                                      permission.name,
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))
                      : filteredAvailablePermissions.map(
                          (permission, index) => (
                            <Draggable
                              draggableId={`draggable-available-${permission.id}-${permission.name}`}
                              index={index}
                              key={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.draggable_item}
                                >
                                  <AvailablePermissionItem
                                    id={permission.id}
                                    text={capitalize(permission.name)}
                                    action={async (permissionId) => {
                                      await handleAssignNewPersmission(
                                        permissionId,
                                        permission.name,
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ),
                        )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className={styles.flex_item}>
              <div className={styles.flex_item_header}>
                <Typography textWeight="600" textAlign="left">
                  Assigned Permissions
                </Typography>
              </div>
              <div>
                <SearchInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={formData.assignedPermissions}
                  setValue={(data) => {
                    setFormData((prevData) => {
                      return { ...prevData, assignedPermissions: data };
                    });
                    handleSearchAssignedPermission(data);
                  }}
                />
              </div>
              <Droppable droppableId="2">
                {(provided) => (
                  <div
                    className={styles.permission_container}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className={styles.permission_container}>
                      {!formData.assignedPermissions
                        ? assignedPermissions?.map((permission, index) => (
                            <Draggable
                              draggableId={`draggable-assinged-${permission.id}-${permission?.name}`}
                              index={index}
                              key={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.draggable_item}
                                >
                                  <AssignedPermissionItem
                                    id={permission?.id}
                                    key={index}
                                    text={capitalize(permission?.name)}
                                    action={async (permissionId) => {
                                      await handleUnAssignPermission(
                                        permissionId,
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))
                        : filteredAssignedPermissions?.map(
                            (permission, index) => (
                              <Draggable
                                draggableId={`draggable-assinged-${permission.id}-${permission.name}`}
                                index={index}
                                key={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={styles.draggable_item}
                                  >
                                    <AssignedPermissionItem
                                      id={permission.id}
                                      key={index}
                                      text={capitalize(permission.name)}
                                      action={async (permissionId) => {
                                        await handleUnAssignPermission(
                                          permissionId,
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ),
                          )}
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default EditPermissionGroupModal;
