import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../../Common/PageBackButton";
import Typography from "components/Common/Typography";
import colors from "../../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import { getRequest } from "../../../../../api/request";
import PageLoader from "../../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import { GeneralServiceActivityLogsprops } from "../../../../../types-definition/admin";
import DashboardHeader from "../../../../Common/DashboardHeader";
import { toastError } from "utils/toast";
import moment from "moment";
import { getGradeSlected } from "utils/getSelectedGrade";

const ActivityGeneralServiceTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentServiceGeneral, setCurrentServiceGeneral] =
    useState<GeneralServiceActivityLogsprops>({
      serviceName: "",
      abbreviation: "",
      active: null,
      grade: "",
      noofEmployeesRequired: "",
      autogenerateShift: null,
      autoBookOn: null,
      overrideProximitySettings: null,
      autoBookOff: null,
      shiftEndProximityRadius: null,
      checkCallProximityRadius: "",
      proximityRadiusEmails: "",
      autoRosterGroup: "",
      planType: { requireConfirmationstring: null, openShift: null },
      excludePublicHolidays: null,
      from: "",
      to: "",
      shiftStart: "",
      shiftEnd: "",
      shiftRotationPeriod: "",
      frequency: "",
      daysofWeek: ["Monday", "Tuesday"],
      totalHoursInWeek: "",
      serviceGroup: "",
    });
  const [previousServiceGeneral, setPreviousServiceGeneral] =
    useState<GeneralServiceActivityLogsprops>({
      serviceName: "",
      abbreviation: "",
      active: null,
      grade: "",
      noofEmployeesRequired: "",
      autogenerateShift: null,
      autoBookOn: null,
      overrideProximitySettings: null,
      autoBookOff: null,
      shiftEndProximityRadius: null,
      checkCallProximityRadius: "",
      proximityRadiusEmails: "",
      autoRosterGroup: "",
      planType: { requireConfirmationstring: null, openShift: null },
      excludePublicHolidays: null,
      from: "",
      to: "",
      shiftStart: "",
      shiftEnd: "",
      shiftRotationPeriod: "",
      frequency: "",
      daysofWeek: ["Monday", "Tuesday"],
      totalHoursInWeek: "",
      serviceGroup: "",
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getServiceData = async () => {
      // get employee data
      try {
        const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
        if (response?.success) {
          const data = response.data.newRecord;
          const previousData = response.data.previousRecord;
          if (response.data.action === "CREATE") {
            const grade = await getGradeSlected(data.service?.grade);
            setCurrentServiceGeneral({
              serviceName: data?.service?.name,
              abbreviation: data.service?.abbreviation,
              active: data.service?.active ? data.service?.active : "--",
              grade: grade ? grade.grade : "--",
              noofEmployeesRequired: data.service?.noOfEmployeeRequired,
              autogenerateShift: data.service?.autoGenerateShift,
              autoBookOn: data.service?.autoBookOn,
              overrideProximitySettings:
                data.service?.overrideProximitySettings,
              autoBookOff: data.service?.autoBookOff,
              shiftEndProximityRadius: data.service?.shiftEndProximityRadius,
              checkCallProximityRadius: data.service?.checkCallProximityRadius,
              proximityRadiusEmails: data.service?.proximityRadiusEmail,
              autoRosterGroup: data.service?.autoRoasterGroup,
              planType: { requireConfirmationstring: false, openShift: false },
              excludePublicHolidays: data.service?.excludePublicHolidays,
              from: data.service?.from,
              to: data.service?.to,
              shiftStart: data.service?.shiftStart,
              shiftEnd: data.service?.shiftEnd,
              shiftRotationPeriod: data.service?.shiftRotationPeriod,
              frequency: data.service?.frequency,
              daysofWeek: data.service?.daysOfWeek,
              totalHoursInWeek: data.service?.totalHoursInWeek,
              serviceGroup: data.service?.serviceGroup,
            });
            if (previousData !== null) {
              setPreviousServiceGeneral({
                serviceName: previousData?.service?.name,
                abbreviation: previousData?.service?.abbreviation,
                active: previousData?.service?.active
                  ? previousData?.service?.active
                  : "--",
                grade: grade ? grade.grade : "--",
                noofEmployeesRequired:
                  previousData?.service?.noOfEmployeeRequired,
                autogenerateShift: previousData?.service?.autoGenerateShift,
                autoBookOn: previousData?.service?.autoBookOn,
                overrideProximitySettings:
                  previousData.service?.overrideProximitySettings,
                autoBookOff: previousData?.service?.autoBookOff,
                shiftEndProximityRadius:
                  previousData?.service?.shiftEndProximityRadius,
                checkCallProximityRadius:
                  previousData?.service?.checkCallProximityRadius,
                proximityRadiusEmails:
                  previousData?.service?.proximityRadiusEmail,
                autoRosterGroup: previousData?.service?.autoRoasterGroup,
                planType: {
                  requireConfirmationstring: false,
                  openShift: false,
                },
                excludePublicHolidays:
                  previousData?.service?.excludePublicHolidays,
                from: previousData?.service?.from,
                to: previousData?.service?.to,
                shiftStart: previousData?.service?.shiftStart,
                shiftEnd: previousData?.service?.shiftEnd,
                shiftRotationPeriod: previousData?.service?.shiftRotationPeriod,
                frequency: previousData?.service?.frequency,
                daysofWeek: previousData?.service?.daysOfWeek,
                totalHoursInWeek: previousData?.service?.totalHoursInWeek,
                serviceGroup: previousData?.service?.serviceGroup,
              });
            }
          } else {
            const grade = data.service
              ? await getGradeSlected(data.service?.grade)
              : "";
            const preGrade = previousData.service
              ? await getGradeSlected(previousData.service?.grade)
              : "";
            setCurrentServiceGeneral({
              serviceName: data?.service?.name,
              abbreviation: data.service?.abbreviation,
              active: data.service?.active,
              grade: grade ? grade.grade : "--",
              noofEmployeesRequired: data.service?.noOfEmployeeRequired,
              autogenerateShift: data.service?.autoGenerateShift,
              autoBookOn: data.service?.autoBookOn,
              overrideProximitySettings:
                data.service?.overrideProximitySettings,
              autoBookOff: data.service?.autoBookOff,
              shiftEndProximityRadius: data.service?.shiftEndProximityRadius,
              checkCallProximityRadius: data.service?.checkCallProximityRadius,
              proximityRadiusEmails: data.service?.proximityRadiusEmail,
              autoRosterGroup: data.service?.autoRoasterGroup,
              planType: { requireConfirmationstring: false, openShift: false },
              excludePublicHolidays: data.service?.excludePublicHolidays,
              from: data.service?.from,
              to: data.service?.to,
              shiftStart: data.service?.shiftStart,
              shiftEnd: data.service?.shiftEnd,
              shiftRotationPeriod: data.service?.shiftRotationPeriod,
              frequency: data.service?.frequency,
              daysofWeek: data.service?.daysOfWeek,
              totalHoursInWeek: data.service?.totalHoursInWeek,
              serviceGroup: data.service?.serviceGroup,
            });
            setPreviousServiceGeneral({
              serviceName: previousData?.service?.name,
              abbreviation: previousData.service?.abbreviation,
              active: previousData.service?.active
                ? previousData.service?.active
                : "",
              grade: preGrade ? preGrade.grade : "--",
              noofEmployeesRequired: previousData.service?.noOfEmployeeRequired,
              autogenerateShift: previousData.service?.autoGenerateShift,
              autoBookOn: previousData.service?.autoBookOn,
              overrideProximitySettings:
                previousData.service?.overrideProximitySettings,
              autoBookOff: previousData.service?.autoBookOff,
              shiftEndProximityRadius:
                previousData.service?.shiftEndProximityRadius,
              checkCallProximityRadius:
                previousData.service?.checkCallProximityRadius,
              proximityRadiusEmails: previousData.service?.proximityRadiusEmail,
              autoRosterGroup: previousData.service?.autoRoasterGroup,
              planType: { requireConfirmationstring: false, openShift: false },
              excludePublicHolidays:
                previousData.service?.excludePublicHolidays,
              from: previousData.service?.from,
              to: previousData.service?.to,
              shiftStart: previousData.service?.shiftStart,
              shiftEnd: previousData.service?.shiftEnd,
              shiftRotationPeriod: previousData.service?.shiftRotationPeriod,
              frequency: previousData.service?.frequency,
              daysofWeek: previousData.service?.daysOfWeek,
              totalHoursInWeek: previousData.service?.totalHoursInWeek,
              serviceGroup: previousData.service?.serviceGroup,
            });
          }
        } else {
          toastError(response?.data);
        }
        setPageLoading(false);
      } catch (erro) {
        console.error(erro);
      }
    };
    getServiceData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Service General.
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Service Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.serviceName ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Abbreviation</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.abbreviation ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Active </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {`${currentServiceGeneral.active}` ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.grade ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  No of Employees Required
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.noofEmployeesRequired ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Auto--generate shift</Typography>
                <Typography textAlign="left" textSize="20px" textWeight=" 600">
                  {currentServiceGeneral.autogenerateShift === true &&
                    `${true}`}
                  {currentServiceGeneral.autogenerateShift === false &&
                    `${false}`}
                  {currentServiceGeneral.autogenerateShift === null && "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Auto Book On</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {/* {`${currentServiceGeneral.autoBookOn}` ?? "--"} */}
                  {currentServiceGeneral.autoBookOn === true && `${true}`}
                  {currentServiceGeneral.autoBookOn === false && `${false}`}
                  {currentServiceGeneral.autoBookOn === null && "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Override Proximity Settings
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.overrideProximitySettings === true &&
                    `${true}`}
                  {currentServiceGeneral.overrideProximitySettings === false &&
                    `${false}`}
                  {currentServiceGeneral.overrideProximitySettings === null &&
                    "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Auto Book Off</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {`${currentServiceGeneral.autoBookOff}`}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Shift Start Proximity Radius{" "}
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.shiftEndProximityRadius ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Shift End Proximity Radius{" "}
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.shiftEndProximityRadius ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Check Call Proximity Radius
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.checkCallProximityRadius ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Plan Type</Typography>
              </div>
              <div>
                <Typography textAlign="left">Auto Roster Group</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.planType.requireConfirmationstring ??
                    "--"}
                  {previousServiceGeneral.planType.requireConfirmationstring ===
                    true && "true"}
                  {previousServiceGeneral.planType.requireConfirmationstring ===
                    false && "false"}
                  {previousServiceGeneral.planType.requireConfirmationstring ===
                    null && "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left" marginHorizontal="10px">
                  Open Shift
                </Typography>
                <Typography
                  textAlign="left"
                  textSize="20px"
                  textWeight="600"
                  marginHorizontal="10px"
                >
                  {currentServiceGeneral.planType.requireConfirmationstring ??
                    "--"}
                  {currentServiceGeneral.planType.requireConfirmationstring ===
                    true && "true"}
                  {currentServiceGeneral.planType.requireConfirmationstring ===
                    false && "false"}
                  {currentServiceGeneral.planType.requireConfirmationstring ===
                    null && "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">From </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {moment(currentServiceGeneral.from).format("YY/MM/DD") ??
                    "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">to</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {moment(currentServiceGeneral.to).format("YY/MM/DD") ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Shift Start</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.shiftStart ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Shift End</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.shiftEnd ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Shift Rotation Period</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.shiftRotationPeriod ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Frequency</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.frequency ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Days of Week</Typography>
                {currentServiceGeneral.daysofWeek ? (
                  currentServiceGeneral.daysofWeek.map(
                    (item: string, index: number) => (
                      <Typography
                        key={index}
                        textAlign="left"
                        textSize="20px"
                        textWeight="600"
                      >
                        {item}
                      </Typography>
                    ),
                  )
                ) : (
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    {"--"}
                  </Typography>
                )}
              </div>
              <div>
                <Typography textAlign="left">Total Hours in Week</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.totalHoursInWeek ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Service Group</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.serviceGroup ?? "--"}
                </Typography>
              </div>
              {/* <div>
                <Typography textAlign="left">Total Hours in Week</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentServiceGeneral.reason4Description ?? "--"}
                </Typography>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Service General.
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Service Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.serviceName
                    ? previousServiceGeneral.serviceName
                    : "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Abbreviation</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.abbreviation
                    ? previousServiceGeneral.abbreviation
                    : "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Active </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.active ?? "--"}
                  {previousServiceGeneral.active === true && "true"}
                  {previousServiceGeneral.active === false && "false"}
                  {previousServiceGeneral.active === null && "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.grade
                    ? previousServiceGeneral.grade
                    : "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  No of Employees Required
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.noofEmployeesRequired
                    ? previousServiceGeneral.noofEmployeesRequired
                    : "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Auto--generate shift</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.autogenerateShift ?? "--"}
                  {previousServiceGeneral.autoBookOn === true && `${true}`}
                  {previousServiceGeneral.autoBookOn === false && `${false}`}
                  {previousServiceGeneral.autoBookOn === null && "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Auto Book On</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.autoBookOn === true && `${true}`}
                  {previousServiceGeneral.autoBookOn === false && `${false}`}
                  {previousServiceGeneral.autoBookOn === null && "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Override Proximity Settings
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.overrideProximitySettings === true &&
                    `${true}`}
                  {previousServiceGeneral.overrideProximitySettings === false &&
                    `${false}`}
                  {previousServiceGeneral.overrideProximitySettings === null &&
                    "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Auto Book Off</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.autoBookOff}
                  {previousServiceGeneral.autoBookOff === true && `${true}`}
                  {previousServiceGeneral.autoBookOff === false && `${false}`}
                  {previousServiceGeneral.autoBookOff === null && "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Shift Start Proximity Radius{" "}
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.shiftEndProximityRadius
                    ? previousServiceGeneral.shiftEndProximityRadius
                    : "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">
                  Shift End Proximity Radius{" "}
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.shiftEndProximityRadius
                    ? previousServiceGeneral.shiftEndProximityRadius
                    : "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Check Call Proximity Radius
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.checkCallProximityRadius
                    ? previousServiceGeneral.checkCallProximityRadius
                    : "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Plan Type</Typography>
              </div>
              <div>
                <Typography textAlign="left">Auto Roster Group</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.planType.requireConfirmationstring ===
                    true && "true"}
                  {previousServiceGeneral.planType.requireConfirmationstring ===
                    false && "false"}
                  {previousServiceGeneral.planType.requireConfirmationstring ===
                    null && "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left" marginHorizontal="10px">
                  Open Shift
                </Typography>
                <Typography
                  textAlign="left"
                  textSize="20px"
                  textWeight="600"
                  marginHorizontal="10px"
                >
                  {previousServiceGeneral.planType.requireConfirmationstring ??
                    "--"}
                  {previousServiceGeneral.planType.requireConfirmationstring ===
                    true && "true"}
                  {previousServiceGeneral.planType.requireConfirmationstring ===
                    false && "false"}
                  {previousServiceGeneral.planType.requireConfirmationstring ===
                    null && "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">From </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.from
                    ? moment(previousServiceGeneral.from).format("YY/MM/DD")
                    : "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">to</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.to
                    ? moment(previousServiceGeneral.to).format("YY/MM/DD")
                    : "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Shift Start</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.shiftStart
                    ? previousServiceGeneral.shiftStart
                    : "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Shift End</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.shiftEnd
                    ? previousServiceGeneral.shiftEnd
                    : "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Shift Rotation Period</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.shiftRotationPeriod
                    ? previousServiceGeneral.shiftRotationPeriod
                    : "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Frequency</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.frequency
                    ? previousServiceGeneral.frequency
                    : "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Days of Week</Typography>
                {previousServiceGeneral.daysofWeek ? (
                  previousServiceGeneral.daysofWeek.map(
                    (item: string, index: number) => (
                      <Typography
                        key={index}
                        textAlign="left"
                        textSize="20px"
                        textWeight="600"
                      >
                        {item}
                      </Typography>
                    ),
                  )
                ) : (
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    {"--"}
                  </Typography>
                )}
              </div>
              <div>
                <Typography textAlign="left">Total Hours in Week</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.totalHoursInWeek
                    ? previousServiceGeneral.totalHoursInWeek
                    : "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Service Group</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.serviceGroup
                    ? previousServiceGeneral.serviceGroup
                    : "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Total Hours in Week</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousServiceGeneral.totalHoursInWeek
                    ? previousServiceGeneral.totalHoursInWeek
                    : "--"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityGeneralServiceTab;
