import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../../Common/PageBackButton";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import { getRequest } from "../../../../../api/request";
import PageLoader from "../../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import { AdminActivityShiftGenerationSettingProps } from "../../../../../types-definition/admin";
import DashboardHeader from "../../../../Common/DashboardHeader";
import { toastError } from "utils/toast";

const ActivityShiftGenerationSettingsTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentShiftGenerationSetting, setCurrentShiftGenerationSetting] =
    useState<AdminActivityShiftGenerationSettingProps>({
      daysToGenerateAhead: "",
      emailNotification: false,
    });
  const [previousShiftGenerationSetting, setPreviousShiftGenerationSetting] =
    useState<AdminActivityShiftGenerationSettingProps>({
      daysToGenerateAhead: "",
      emailNotification: false,
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getshiftGenerationData = async () => {
      // get employee data
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data.newRecord;
        const previousData = response.data.previousRecord;
        if (response.data.action === "POST") {
          // setCurrentShiftGenerationSetting({
          //   emailNotification: Boolean(
          //     data?.holidaySettings?.emailNotification,
          //   ),
          //   daysToGenerateAhead: data?.holidaySettings?.daysToGenerateAhead,
          // });
          // setPreviousShiftGenerationSetting({
          //   emailNotification: previousData?.holidaySettings?.emailNotification,
          //   daysToGenerateAhead:
          //     previousData?.holidaySettings?.daysToGenerateAhead,
          // });
        } else {
          setCurrentShiftGenerationSetting({
            emailNotification: Boolean(
              data?.holidaySettings?.emailNotification,
            ),
            daysToGenerateAhead: data?.holidaySettings?.daysToGenerateAhead,
          });
          setPreviousShiftGenerationSetting({
            emailNotification: previousData?.holidaySettings?.emailNotification,
            daysToGenerateAhead:
              previousData?.holidaySettings?.daysToGenerateAhead,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getshiftGenerationData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Shift Generation
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Days To Generate Ahead</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentShiftGenerationSetting.daysToGenerateAhead ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Email Notification</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentShiftGenerationSetting.emailNotification === true &&
                    "True"}
                  {currentShiftGenerationSetting.emailNotification === false &&
                    "False"}
                  {currentShiftGenerationSetting.emailNotification === null &&
                    "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Shift Generation
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Days To Generate Ahead</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousShiftGenerationSetting.daysToGenerateAhead ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Email Notification</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousShiftGenerationSetting.emailNotification === true &&
                    "True"}
                  {previousShiftGenerationSetting.emailNotification === false &&
                    "False"}
                  {previousShiftGenerationSetting.emailNotification === null &&
                    "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityShiftGenerationSettingsTab;
