import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import {
  CheckpointItemFormData,
  CheckpointItemRequiredFormData,
} from "types-definition/client";

interface InitialState {
  checkpoints: Array<CheckpointItemFormData>;
  loadedCheckpoints: Array<CheckpointItemFormData>;
  numberOfCheckpoints: number;
  checkpointForm: CheckpointItemFormData;
  requiredCheckpointForm: CheckpointItemRequiredFormData;
  isInitialEmpty: boolean;
  showDeleteModal: boolean;
  currentSelectedId: string;
  checkpointCounter: number;
}

const initialState: InitialState = {
  checkpoints: [],
  loadedCheckpoints: [],
  numberOfCheckpoints: 0,
  checkpointForm: {
    details: "",
    frequency: "",
    latitude: "",
    longitude: "",
    name: "",
    time: "",
    title: "",
  },
  requiredCheckpointForm: {
    details: {
      status: false,
      text: "",
    },
    frequency: {
      status: false,
      text: "",
    },
    name: {
      status: false,
      text: "",
    },
    time: {
      status: false,
      text: "",
    },
    title: {
      status: false,
      text: "",
    },
  },
  isInitialEmpty: true,
  showDeleteModal: false,
  currentSelectedId: "",
  checkpointCounter: 0,
};

export const chcekpointSlice = createSlice({
  name: "checkpoint",
  initialState,
  reducers: {
    setCheckpoints: (state, action: PayloadAction<CheckpointItemFormData>) => {
      state.checkpoints.push(action.payload);
    },
    removeCheckPoint: (state, action: PayloadAction<number>) => {
      state.checkpoints.splice(action.payload, 1);
    },
    clearCheckPoint: (state) => {
      state.checkpoints = [];
    },
    setLoadedCheckpoints: (
      state,
      action: PayloadAction<CheckpointItemFormData>,
    ) => {
      state.loadedCheckpoints.push(action.payload);
    },
    addToNumberOfCheckpoints: (state) => {
      state.numberOfCheckpoints += 1;
    },
    resetNumberofCheckpoints: (state) => {
      state.numberOfCheckpoints = 0;
    },
    setCheckpointItemFormData: (
      state,
      action: PayloadAction<CheckpointItemFormData>,
    ) => {
      state.checkpointForm = action.payload;
    },
    setRequiredCheckpointItemFormData: (
      state,
      action: PayloadAction<CheckpointItemRequiredFormData>,
    ) => {
      state.requiredCheckpointForm = action.payload;
    },
    clearCheckpointItemFormData: (state) => {
      state.checkpointForm = {
        details: "",
        frequency: "",
        latitude: "",
        longitude: "",
        name: "",
        time: "",
        title: "",
      };
    },
    clearCheckpointRequiredItemFormData: (state) => {
      state.requiredCheckpointForm = {
        details: {
          status: false,
          text: "",
        },
        frequency: {
          status: false,
          text: "",
        },
        // latitude: {
        //   status: false,
        //   text: "",
        // },
        // longitude: {
        //   status: false,
        //   text: "",
        // },
        name: {
          status: false,
          text: "",
        },
        time: {
          status: false,
          text: "",
        },
        title: {
          status: false,
          text: "",
        },
      };
    },
    setCheckpointInitialEmpty: (state, action: PayloadAction<boolean>) => {
      state.isInitialEmpty = action.payload;
    },
    setShowDeleteCheckPointModal: (
      state,
      action: PayloadAction<{ id: string; show: boolean }>,
    ) => {
      state.showDeleteModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
    },
    updateCheckpointRequestCounter: (state) => {
      state.checkpointCounter += 1;
    },
  },
});

export const {
  setCheckpoints,
  clearCheckPoint,
  addToNumberOfCheckpoints,
  setCheckpointItemFormData,
  setRequiredCheckpointItemFormData,
  clearCheckpointItemFormData,
  clearCheckpointRequiredItemFormData,
  setCheckpointInitialEmpty,
  removeCheckPoint,
  resetNumberofCheckpoints,
  setLoadedCheckpoints,
  setShowDeleteCheckPointModal,
  updateCheckpointRequestCounter,
} = chcekpointSlice.actions;

export const getCheckpoints = (state: RootState) =>
  state.checkpoints.checkpoints;
export const getNumberOfCheckpoints = (state: RootState) =>
  state.checkpoints.numberOfCheckpoints;
export const getCheckpointFormItem = (state: RootState) =>
  state.checkpoints.checkpointForm;
export const getRequiredCheckpointFormItem = (state: RootState) =>
  state.checkpoints.requiredCheckpointForm;
export const getCheckpointInitialEmpty = (state: RootState) =>
  state.checkpoints.isInitialEmpty;
export const getLoadedCheckpoints = (state: RootState) =>
  state.checkpoints.loadedCheckpoints;
export const getShowDeleteCheckpointModal = (state: RootState) =>
  state.checkpoints.showDeleteModal;
export const getCurrentSelectedCheckpointId = (state: RootState) =>
  state.checkpoints.currentSelectedId;
export const getCheckpointRequestCounter = (state: RootState) =>
  state.checkpoints.checkpointCounter;
export default chcekpointSlice.reducer;
