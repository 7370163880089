import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import cancelIcon from "../../../../assets/svg/circle-cancel-icon.svg";
import Checkbox from "../../../Form/Checkbox";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getCurrentSelectedHolidayAbsenceEmployeeId,
  getCurrentSelectedHolidayAbsenceId,
  getHolidayAbsenceSummaryApprove,
  getHolidayAbsenceSummaryDate,
  getHolidayAbsenceSummaryFormInformation,
  getHolidayAbsenceSummaryFromDate,
  getHolidayAbsenceSummaryToDate,
  setShowAdd,
  setShowConflictModal,
  updateAbsenceHolidayCounter,
} from "../../../../redux/slice/HolidayAbsence/holidayAbsenceSlice";
import { patchRequest, postRequest, putRequest } from "../../../../api/request";
import { toastError, toastSuccess } from "../../../../utils/toast";
import moment from "moment";
import convertTimeTo12hours from "../../../../utils/convertTime";
// import stringToDate from "../../../../utils/formatDate";
import LoadingIcons from "react-loading-icons";

const ShiftConflictedModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(getCurrentSelectedHolidayAbsenceId);
  const employeeId = useAppSelector(getCurrentSelectedHolidayAbsenceEmployeeId);
  const fromDate = useAppSelector(getHolidayAbsenceSummaryFromDate);
  const toDate = useAppSelector(getHolidayAbsenceSummaryToDate);
  const date = useAppSelector(getHolidayAbsenceSummaryDate);
  const approve = useAppSelector(getHolidayAbsenceSummaryApprove);
  const formDataInformation = useAppSelector(
    getHolidayAbsenceSummaryFormInformation,
  );
  const [holidayClashes, setHolidayClashes] = useState<
    Array<{ date: string; startTime: string; endTime: string }>
  >([]);
  const [holidayClashItem, setHolidayClashItem] = useState<
    Array<{ id: string }>
  >([]);
  const [requestLoading, setrequestLoading] = useState(false);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [formData, setFormData] = useState({
    active: true,
  });
  const handleSubmitForm = async () => {
    if (!formData.active) {
      dispatch(setShowConflictModal(false));
      dispatch(setShowAdd(true));
      return;
    }
    setrequestLoading(true);
    for (const index in holidayClashItem) {
      const response = await putRequest(
        `misc/active-board/cancel/${holidayClashItem[index].id}`,
      );
      if (!response?.success) {
        toastError(response?.data);
      }
    }
    if (fromDate) {
      const holidays: Array<any> = [];
      const fromDateItem = new Date(fromDate);
      const toDateItem = new Date(toDate);
      for (let i = fromDateItem; i <= toDateItem; i.setDate(i.getDate() + 1)) {
        const holiday = {
          // date: moment(new Date(i)).format("DD/MM/YYYY"),
          date: moment.utc(new Date(i)).toISOString(),
          holidayType: formDataInformation.type,
          description: formDataInformation.description,
          timeOff: "allDay",
        };
        holidays.push(holiday);
      }
      let response;
      if (approve) {
        response = await putRequest(
          `employee/${employeeId}/holiday/segment/${id}`,
          holidays,
        );
      } else {
        response = await postRequest(
          `employee/${employeeId}/holiday`,
          holidays,
        );
      }
      if (response?.success) {
        if (!approve) {
          setrequestLoading(false);
          toastSuccess("Time Off request created successfully");
          dispatch(updateAbsenceHolidayCounter());
          dispatch(setShowConflictModal(false));
        } else {
          handleApproveHoliday();
        }
      } else {
        toastError(response?.data);
        setrequestLoading(false);
      }
    } else {
      let response;
      if (approve) {
        response = await putRequest(
          `employee/${employeeId}/holiday/segment/${id}`,
          [
            {
              holidayType: formDataInformation.type,
              timeOff: "halfDay",
              // date: moment(new Date(date)).format("DD/MM/YYYY"),
              preferredTime: formDataInformation.preferredTime,
              description: formDataInformation.description,
              date: moment.utc(new Date(date)).toISOString(),
            },
          ],
        );
      } else {
        response = await postRequest(`employee/${employeeId}/holiday`, [
          {
            holidayType: formDataInformation.type,
            timeOff: "halfDay",
            // date: moment(new Date(date)).format("DD/MM/YYYY"),
            preferredTime: formDataInformation.preferredTime,
            description: formDataInformation.description,
            date: moment.utc(new Date(date)).toISOString(),
          },
        ]);
      }
      if (response?.success) {
        if (!approve) {
          setrequestLoading(false);
          toastSuccess("Time Off request created successfully");
          dispatch(updateAbsenceHolidayCounter());
          dispatch(setShowConflictModal(false));
        } else {
          handleApproveHoliday();
        }
      } else {
        setrequestLoading(false);
        toastError(response?.data);
      }
    }
  };
  useEffect(() => {
    const getShiftConflict = async () => {
      setIsResponseLoading(true);
      if (date) {
        const response = await postRequest(
          "misc/active-board/holiday-conflict",
          {
            employeeId: employeeId,
            date: moment.utc(new Date(date)).toISOString(),
          },
        );
        setHolidayClashItem((prevData) => {
          return [
            ...prevData,
            ...response?.data?.map((item: any) => {
              return { id: item.id };
            }),
          ];
        });
        if (response?.success) {
          const data = response?.data;
          const calculatedHolidayClashes: Array<any> = [];
          for (const index in data) {
            calculatedHolidayClashes.push({
              startTime: data[index].startTime,
              endTime: data[index].endTime,
              date: data[index].date,
            });
          }
          setHolidayClashes((prevData) => {
            return [...prevData, ...calculatedHolidayClashes];
          });
        } else {
          toastError(response?.data);
        }
      } else {
        for (
          let i = new Date(fromDate);
          i <= new Date(toDate);
          i.setDate(i.getDate() + 1)
        ) {
          const response = await postRequest(
            "misc/active-board/holiday-conflict",
            {
              employeeId: employeeId,
              date: moment.utc(new Date(i)).toISOString(),
            },
          );
          if (response?.success) {
            setHolidayClashItem((prevData) => {
              return [
                ...prevData,
                ...response?.data?.map((item: any) => {
                  return { id: item.id };
                }),
              ];
            });
            const data = response?.data;
            const calculatedHolidayClashes: Array<any> = [];
            for (const index in data) {
              calculatedHolidayClashes.push({
                startTime: data[index].startTime,
                endTime: data[index].endTime,
                date: data[index].date,
              });
            }
            setHolidayClashes((prevData) => {
              return [...prevData, ...calculatedHolidayClashes];
            });
          } else {
            toastError(response?.data);
          }
        }
      }
      setIsResponseLoading(false);
      setrequestLoading(false);
    };
    getShiftConflict();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleApproveHoliday = async () => {
    const response = await patchRequest(
      `/employee/${employeeId}/holiday/segment/${id}/approve`,
    );
    if (response?.success) {
      setrequestLoading(false);
      toastSuccess("Holiday Approved successfully");
      dispatch(updateAbsenceHolidayCounter());
      dispatch(setShowConflictModal(false));
    } else {
      toastError(response?.data);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600" textSize="18px">
            Shift Conflict Detected
          </Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() => dispatch(setShowConflictModal(false))}
          />
        </div>
        <div className={styles.form_container}>
          <Typography
            textAlign="left"
            fontFamily="inter"
            textWeight="600"
            textColor={colors.tableText}
          >
            The following shifts clash with your requested time off:
          </Typography>
          <ul className={styles.ul}>
            {holidayClashes.map((clash: any, index: number) => {
              return (
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="500"
                  textColor={colors.tableText}
                  key={index}
                >
                  <li className={styles.li}>
                    {moment(clash.date).format("MMM D")}{" "}
                    {convertTimeTo12hours(clash.startTime)} -{" "}
                    {convertTimeTo12hours(clash.endTime)}
                  </li>
                </Typography>
              );
            })}
          </ul>
          {isResponseLoading && (
            <div className={styles.loading_icon}>
              <LoadingIcons.TailSpin
                width={40}
                height={40}
                stroke={colors.primary1}
                className={styles.loader_spinner}
              />
            </div>
          )}
          <Typography
            textAlign="left"
            fontFamily="inter"
            textWeight="600"
            textColor={colors.tableText}
            marginVertical="20px"
          >
            Options:
          </Typography>
          <Checkbox
            value={formData.active}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, active: true };
              });
            }}
            label={"Continue and Remove Employee from Shift"}
            marginVertical="10px"
          />
          <Checkbox
            value={!formData.active}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, active: false };
              });
            }}
            label={"Cancel and Choose Different Dates"}
            marginVertical="10px"
          />
        </div>
        <div className={styles.button_container}>
          <Button
            text="Cancel"
            isPrimary={false}
            onClick={() => {
              dispatch(setShowConflictModal(false));
            }}
            marginHorizontal="10px"
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
            paddingVertical="5px"
          />
          <Button
            text="Save"
            isPrimary={true}
            onClick={handleSubmitForm}
            isLoading={requestLoading}
            paddingVertical="5px"
          />
        </div>
      </div>
    </div>
  );
};

export default ShiftConflictedModal;
