const showSelectedLetter = (
  word: string,
  firstSplit: number,
  lastSplit: number,
) => {
  const firstPart = word.slice(0, firstSplit);
  const middlePart = word.slice(firstSplit, word.length - lastSplit);
  const lastPart = word.slice(word.length - lastSplit);
  return firstPart + "*".repeat(middlePart.length) + lastPart;
};

export default showSelectedLetter;
