import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import EmployeeNavigation from "components/StaffHub/Employee/EmployeeNavigation";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { Country } from "country-state-city";
import { getRequest, patchRequest } from "api/request";
import { GET_ALL_EMPLOYEE_URL, UPDATE_EMERGENCY_CONTACT_URL } from "api/urls";
import { getCountry, getCurrentEmployee } from "utils/localstorage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EmployeeEmergencyContactForm from "components/StaffHub/Employee/EmergencyContact/EmployeeEmergencyContactForm";
import EmployeeEmergencyContactFormUk from "components/StaffHub/Employee/EmergencyContact/EmployeeEmergencyContactFormUk";
import toast from "react-hot-toast";
import { UK } from "types-definition/constants";
import ScrollToTop from "components/Common/ScrollToTop";
import { EmployeeEmergencyContactFormData } from "types-definition/StaffHub/employee";
import PageLoader from "components/Common/PageLoader";
import { toastSuccess } from "utils/toast";

const EmployeeEmergencyContact = () => {
  const navigate = useNavigate();
  const country = getCountry();
  const [formData, setFormData] = useState<EmployeeEmergencyContactFormData>({
    contactName: "",
    relationship: "",
    postcode: "",
    address1: "",
    address2: "",
    town: "",
    telephone: "",
    email: "",
    country: "",
    state: "",
    isPostCodeEntered: false,
    postcode1: "",
  });
  const [requestLoading, setRequestLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [currentCountryCode, setCurrentCountryCode] = useState<string>("");
  const [postCodeOptions, setPostCodeOptions] = useState([]);

  const handleSubmitForm = async () => {
    setRequestLoading(true);
    if (getCurrentEmployee() === null) {
      toast.error("Employee Record Not Found", {
        duration: 4000,
        position: "top-center",
      });
      navigate("/staff-hub");
    }
    const data = JSON.parse(getCurrentEmployee()!);
    const form = {
      contactName: formData.contactName,
      relationship: formData.relationship,
      country: formData.country,
      state: formData.state,
      address1: formData.address1,
      address2: formData.address2,
      telephone: formData.telephone,
      email: formData.email,
    };
    const ukForm = {
      contactName: formData.contactName,
      relationship: formData.relationship,
      postcode: formData.postcode1,
      address1: formData.address1,
      address2: formData.address2,
      town: formData.town,
      telephone: formData.telephone,
      email: formData.email,
    };
    const response =
      country === UK
        ? await patchRequest(
            `${UPDATE_EMERGENCY_CONTACT_URL}/${data.name}`,
            ukForm,
          )
        : await patchRequest(
            `${UPDATE_EMERGENCY_CONTACT_URL}/${data.name}`,
            form,
          );
    if (response?.success) {
      toastSuccess("Emergency Contact Updated");
      navigate(`/employee/medical-details`);
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };
  const handlePostCodeAutoComplete = async (text: string) => {
    if (text) {
      try {
        const response = await axios.get(
          `https://api.postcodes.io/postcodes/${text}/autocomplete`,
        );
        const data = response.data;
        if (data.result !== null) {
          setPostCodeOptions(data.result);
        } else {
          setPostCodeOptions([]);
        }
      } catch (error) {
        setPostCodeOptions([]);
      }
    }
  };
  const handleFormCancelation = () => {
    setFormData((prevData) => {
      return {
        ...prevData,
        contactName: "",
        relationship: "",
        postcode: "",
        address1: "",
        address2: "",
        town: "",
        telephone: "",
        email: "",
      };
    });
  };

  // get and prefills country
  useEffect(() => {
    const country = getCountry();
    if (country) {
      setFormData((prevData) => {
        return { ...prevData, country: country };
      });
      const index = Country.getAllCountries().findIndex(
        (data) => data.name === country,
      );
      setCurrentCountryCode(Country.getAllCountries()[index].isoCode);
    }
  }, [formData.country]);
  useEffect(() => {
    // prefill if information is available
    const getEmployee = async () => {
      try {
        const data = getCurrentEmployee();
        if (data !== undefined && data !== null) {
          const username = JSON.parse(data).name;
          const response = await getRequest(
            `${GET_ALL_EMPLOYEE_URL}/${username}`,
          );
          if (response?.success) {
            setFormData((prevData) => {
              return {
                ...prevData,
                address1: response.data.emergencyContact.address1 ?? "",
                address2: response.data.emergencyContact.address2 ?? "",
                contactName: response.data.emergencyContact.contactName ?? "",
                email: response.data.emergencyContact.email ?? "",
                postcode: response.data.emergencyContact.postcode ?? "",
                relationship: response.data.emergencyContact.relationship ?? "",
                telephone: response.data.emergencyContact.telephone ?? "",
                town: response.data.emergencyContact.town ?? "",
                state: response.data.emergencyContact.state ?? "",
                postcode1: response.data.emergencyContact.postcode ?? "",
                isPostCodeEntered: response.data.emergencyContact.postcode
                  ? true
                  : false,
              };
            });
          } else {
            if (response?.data)
              toast.error(response?.data, {
                duration: 4000,
                position: "top-center",
              });
          }
        }
      } finally {
        setIsPageLoading(false);
      }
    };
    getEmployee();
  }, []);
  return (
    <>
      {isPageLoading && <PageLoader />}
      <section>
        <ScrollToTop />
        <DashboardHeader active="staff/hub" />
        <div className={styles.container}>
          <div className={styles.item_container}>
            <div className={styles.item_container_text}>
              <Typography textAlign="left" textWeight="600" textSize="24px">
                Add Employee
              </Typography>
            </div>
            <EmployeeNavigation active="emergency contact" />
            <div className={styles.content_container}>
              {country !== UK ? (
                <EmployeeEmergencyContactForm
                  formData={formData}
                  setFormData={setFormData}
                  currentCountryCode={currentCountryCode}
                  setCurrentCountryCode={setCurrentCountryCode}
                />
              ) : (
                <EmployeeEmergencyContactFormUk
                  formData={formData}
                  setFormData={setFormData}
                  handlePostCodeAutoComplete={handlePostCodeAutoComplete}
                  postCodeOptions={postCodeOptions}
                />
              )}
              {country === UK && formData.isPostCodeEntered && (
                <div className={styles.button_container}>
                  <Button
                    width="200px"
                    text="Cancel"
                    isPrimary={false}
                    onClick={handleFormCancelation}
                    marginHorizontal="10px"
                    backgroundColor={colors.transparent}
                    isPrimaryBorder={true}
                  />
                  <Button
                    width="200px"
                    text="Save"
                    isPrimary={true}
                    onClick={handleSubmitForm}
                    isLoading={requestLoading}
                  />
                </div>
              )}
              {country !== UK && (
                <div className={styles.button_container}>
                  <Button
                    width="200px"
                    text="Cancel"
                    isPrimary={false}
                    onClick={handleFormCancelation}
                    marginHorizontal="10px"
                    backgroundColor={colors.transparent}
                    isPrimaryBorder={true}
                  />
                  <Button
                    width="200px"
                    text="Save"
                    isPrimary={true}
                    onClick={handleSubmitForm}
                    isLoading={requestLoading}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {country === UK && !formData.isPostCodeEntered && (
          <div className={styles.container}>
            <div className={styles.item_container}>
              <div className={styles.form_flex_container}>
                <TextInput
                  value={formData.contactName}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return { ...prevData, contactName: data };
                    })
                  }
                  label="Contact Name"
                  placeholder="Enter Contact Name"
                />
              </div>
              <div className={styles.form_flex_container}>
                <TextInput
                  value={formData.telephone}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return { ...prevData, telephone: data };
                    })
                  }
                  label="Telephone"
                  placeholder="Enter Telephone Number"
                />
              </div>
              <div className={styles.form_flex_container}>
                <TextInput
                  value={formData.email}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return { ...prevData, email: data };
                    })
                  }
                  label="Email Address"
                  placeholder="Enter Email Adress"
                />
              </div>
              <div className={styles.bottom_button_container}>
                <Button
                  width="200px"
                  text="Cancel"
                  isPrimary={false}
                  onClick={handleFormCancelation}
                  marginHorizontal="10px"
                  backgroundColor={colors.transparent}
                  isPrimaryBorder={true}
                />
                <Button
                  width="200px"
                  text="Save"
                  isPrimary={true}
                  onClick={handleSubmitForm}
                  isLoading={requestLoading}
                />
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default EmployeeEmergencyContact;
