import moment from "moment";
import React from "react";
import colors from "theme/colors";
import { CheckpointDataReduxInteface } from "types-definition/report";
import { getCheckpointReportData } from "utils/localstorage";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";

const CheckInsTableItem: React.FC<any> = ({
  id,
  employee,
  date,
  startTime,
  endTime,
  checkinHistory,
}) => {

  const checkpointData: CheckpointDataReduxInteface = JSON.parse(
    getCheckpointReportData() || "{}",
  );

  const statusColors: any = {
    COMPLETED: colors.successtext,
    MISSED: colors.error,
    QUEUED: colors.gray3,
  };

  // Get GRADE
  // useEffect(() => {
  //   const getGrade = async () => {
  //     const response = await getRequest(`/settings/grade/${grade}`);
  //     setGradeName(response?.data?.grade);
  //   };

  //   getGrade();
  // }, [grade]);

  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left">{employee?.name}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{checkpointData?.serviceGradeName}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{moment(date).format("LL")}</Typography>
        <Typography textAlign="left">
          {startTime} - {endTime}
        </Typography>
      </td>
      {checkinHistory.map(
        (checkin: any, index: React.Key | null | undefined) => (
          <td key={index} className={styles.td}>
            <Typography
              textAlign="left"
              textWeight="bold"
              textColor={statusColors[checkin?.status]}
            >
              {checkin?.status}
            </Typography>
            <Typography textAlign="left">
              {checkin?.completedAt
                ? moment(checkin?.completedAt).format("HH:mm")
                : "N/A"}
            </Typography>
          </td>
        ),
      )}
    </tr>
  );
};

export default CheckInsTableItem;
