import { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import circleCancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Button from "../../../Form/Button";
import TextInput from "../../../Form/TextInput";
import {
  getCurrentHolidaySelectedId,
  setShowEditHolidayMultiplierModal,
  updateFetchedHolidayMultiplierCounter,
} from "../../../../redux/slice/admin/holidayMultiplierSlice";
import NumberInput from "../../../Form/NumberInput";
import { getRequest, putRequest } from "../../../../api/request";
import { UPDATE_HOLIDAY_MULTIPLIER_URL } from "../../../../api/urls";
import {
  AddHolidayMultiplierFormData,
  AddHolidayMultiplierRequiredFormData,
} from "../../../../types-definition/admin";
import DateInput from "../../../Form/DateInput";
import { getCountry, getSetupComplete } from "../../../../utils/localstorage";
import { toastError, toastSuccess } from "../../../../utils/toast";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { US } from "types-definition/constants";
import { showWizardModal } from "redux/slice/WizardSetup";

const EditHolidayMultiplierModal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const country = getCountry();
  const id = useAppSelector(getCurrentHolidaySelectedId);
  const [requestLoading, setRequestLoading] = useState(false);
  const [formData, setFormData] = useState<AddHolidayMultiplierFormData>({
    holidayName: "",
    holidayDate: "",
    chargeRateMultiplier: "1.00",
    payRateMultiplier: "1.00",
  });
  const [requiredFormData, setRequiredFormData] =
    useState<AddHolidayMultiplierRequiredFormData>({
      holidayName: {
        status: false,
        text: "",
      },
      holidayDate: {
        status: false,
        text: "",
      },
    });

  const handleFormSubmit = async () => {
    setRequestLoading(true);
    const response = await putRequest(
      `settings/holiday-multiplier/holiday/${id}`,
      {
        payRateMultiplier: formData.payRateMultiplier,
        chargeRateMultiplier: formData.chargeRateMultiplier,
        holidayName: formData.holidayName,
        holidayDate: moment
          .utc(
            moment.utc(new Date(formData.holidayDate)).format("DD/MM/YYYY"),
            "DD/MM/YYYY",
          )
          .add(1, "days")
          .toISOString(),
        // holidayDate: moment.utc(new Date(formData.holidayDate)).toISOString(),
      },
    );
    if (response?.success) {
      toastSuccess("Holiday Multiplier Updated Successfully");
      dispatch(setShowEditHolidayMultiplierModal({ show: false, id: "" }));
      dispatch(updateFetchedHolidayMultiplierCounter());
      const setUpStep = getSetupComplete();
      if (setUpStep) {
        if (!JSON.parse(setUpStep)?.completed) {
          dispatch(showWizardModal({ showModal: true }));
          navigate("/setup-wizard");
        }
      }
    }
    setRequestLoading(false);
  };
  useEffect(() => {
    const getHolidayDetails = async () => {
      const response = await getRequest(
        `${UPDATE_HOLIDAY_MULTIPLIER_URL}/${id}`,
      );
      if (response?.success) {
        setFormData((prevData) => {
          return {
            ...prevData,
            holidayName: response.data.holidayName,
            holidayDate: response.data.holidayDate,
            chargeRateMultiplier: response.data.chargeRateMultiplier,
            payRateMultiplier: response.data.payRateMultiplier,
          };
        });
      } else {
        toastError(response?.data);
      }
    };
    getHolidayDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.flex_container}>
        <Typography textWeight="600" textSize="20px">
          Edit Rate Multiplier
        </Typography>
        <img
          src={circleCancelIcon}
          alt="cancel-icon"
          onClick={() =>
            dispatch(setShowEditHolidayMultiplierModal({ show: false, id: "" }))
          }
        />
      </div>
      <div className={styles.form_container}>
        <TextInput
          label="Holiday Name"
          placeholder="Enter Holiday Name"
          value={formData.holidayName}
          setValue={(data) => {
            setFormData({ ...formData, holidayName: data });
            setRequiredFormData((prevData) => {
              return { ...prevData, holidayName: { status: false, text: "" } };
            });
          }}
          error={requiredFormData.holidayName.status}
          helperText={requiredFormData.holidayName.text}
          required
        />
        <DateInput
          value={formData.holidayDate}
          setValue={(data) => {
            setFormData({
              ...formData,
              holidayDate: data,
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, holidayDate: { status: false, text: "" } };
            });
          }}
          placeholder="Select Date"
          label="Holiday Date"
          required
          error={requiredFormData.holidayDate.status}
          helperText={requiredFormData.holidayDate.text}
          style={{ marginTop: "25px", marginBottom: "25px" }}
        />
        <NumberInput
          label="Pay Rate Multiplier"
          placeholder="Enter Pay Rate Multiplier"
          value={formData.payRateMultiplier}
          setValue={(data) => {
            return setFormData({ ...formData, payRateMultiplier: data });
          }}
        />
        <NumberInput
          value={formData.chargeRateMultiplier}
          setValue={(data) => {
            return setFormData({
              ...formData,
              chargeRateMultiplier: data,
            });
          }}
          label="Charge Rate Multiplier"
          placeholder="Enter Charge Rate Multiplier"
          style={{ marginTop: "25px", marginBottom: "25px" }}
        />
      </div>
      <div className={styles.button_container}>
        <Button
          text="Cancel"
          isPrimary={false}
          isPrimaryBorder
          backgroundColor="transparent"
          onClick={() =>
            dispatch(setShowEditHolidayMultiplierModal({ show: false, id: "" }))
          }
          marginHorizontal="5px"
        />
        <Button
          text="Save"
          isLoading={requestLoading}
          isPrimary
          marginHorizontal="5px"
          onClick={handleFormSubmit}
        />
      </div>
    </section>
  );
};

export default EditHolidayMultiplierModal;
