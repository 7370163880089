import React, { useRef, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import calendarIcon from "../../../../assets/svg/calendar-icon.svg";
import cancelIcon from "../../../../assets/svg/cancel-icon.svg";
import colors from "../../../../theme/colors";
import DateInput from "../../../Form/DateInput";
import Button from "../../../Form/Button";
import { HolidayDateFilterProps } from "../../../../types-definition/StaffHub/holiday";

const HolidayAbsenceDateFilter: React.FC<HolidayDateFilterProps> = ({
  filterDateCallBack,
  clearFilterCallBack,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [dateFilterForm, setDateFilterForm] = useState({
    startDate: "",
    endDate: "",
  });
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.main}>
      <div className={styles.main_container} ref={containerRef}>
        <div
          className={styles.container}
          onClick={(e) => {
            setShowDropDown(true);
          }}
        >
          <img src={calendarIcon} alt="calendar icon" />
          <Typography textColor={colors.gray5}>Date Range</Typography>
        </div>
        {showDropDown && (
          <div className={styles.dropdown_container}>
            <div className={styles.sub_dropdown_item}>
              <Typography>Filters</Typography>
              <img
                src={cancelIcon}
                alt="cancel-icon"
                onClick={() => setShowDropDown(false)}
                className={styles.dropdown_image_item}
              />
            </div>
            <div className={styles.date_input_container}>
              <DateInput
                placeholder="Start Date"
                label="Start Date"
                value={dateFilterForm.startDate}
                setValue={(value) =>
                  setDateFilterForm({ ...dateFilterForm, startDate: value })
                }
              />
            </div>
            <div className={styles.date_input_container}>
              <DateInput
                placeholder="End Date"
                label="End Date"
                value={dateFilterForm.endDate}
                setValue={(value) =>
                  setDateFilterForm({ ...dateFilterForm, endDate: value })
                }
              />
            </div>
            {dateFilterForm.startDate && dateFilterForm.endDate && (
              <div className={styles.button_input_container}>
                <Button
                  text="Filter"
                  onClick={() => {
                    filterDateCallBack(
                      dateFilterForm.startDate,
                      dateFilterForm.endDate,
                    );
                    setShowDropDown(false);
                  }}
                  isPrimary
                  paddingVertical="4px"
                />
              </div>
            )}
          </div>
        )}
      </div>
      {dateFilterForm.startDate && dateFilterForm.endDate && (
        <div
          className={styles.clear_filter}
          onClick={() => {
            setDateFilterForm({ startDate: "", endDate: "" });
            clearFilterCallBack();
          }}
        >
          <Typography
            textColor={colors.primary3}
            textWeight="600"
            textSize="12px"
          >
            Clear Filters
          </Typography>
        </div>
      )}
    </div>
  );
};

export default HolidayAbsenceDateFilter;
