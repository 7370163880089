import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TableEditDropdown from "../../../Common/TableEditDropdown";
import tableSelectorIcon from "../../../../assets/svg/pencil.svg";
import {
  setCheckpointData,
  setCheckpointSection,
} from "../../../../utils/localstorage";
import { useNavigate } from "react-router-dom";
import { CheckpointData } from "../../../../types-definition/client";
import { useAppDispatch } from "../../../../redux/hooks";
import { setShowDeleteCheckPointModal } from "../../../../redux/slice/client/checkpointSlice";

const CheckpointTableItem: React.FC<CheckpointData> = ({
  client,
  id,
  location,
  service,
  totalCheckpoint,
  sectionId,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        {/* <CircularName name={`${name} ${name}`} /> */}
        <Typography textAlign="left" textWeight="500">
          {location}
        </Typography>
      </td>
      <td className={styles.td_mail}>
        <Typography textAlign="left" textWeight="500">
          {totalCheckpoint ? totalCheckpoint : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textWeight="500">
          {service}
        </Typography>
      </td>
      <td className={styles.td}>{client}</td>
      <td
        className={`${styles.td} ${styles.td_relative}`}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        {showEditDropdown && (
          <TableEditDropdown
            data={[
              {
                label: "Edit",
                action: () => {
                  setCheckpointData(id);
                  setCheckpointSection(sectionId);
                  navigate("/client/checkpoint/general");
                },
              },
              {
                label: "Delete",
                action: () => {
                  setshowEditDropdown(false);
                  dispatch(
                    setShowDeleteCheckPointModal({ id: sectionId, show: true }),
                  );
                },
              },
            ]}
          />
        )}
        <div
          className={styles.image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default CheckpointTableItem;
