import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import PageLoader from "components/Common/PageLoader";
import DashboardHeader from "components/Common/DashboardHeader";
import TextInput from "components/Form/TextInput";
import Typography from "components/Common/Typography";
import colors from "theme/colors";
import searchIcon from "assets/svg/search-icon.svg";
import ClientBoardInformation from "components/Client/Client/ClientBoardInformation";
import ClientShiftNavigation from "components/Client/Client/ClientShiftNavigation";
import SelectInput from "components/Form/SelectInput";
import Button from "components/Form/Button";
import refreshIcon from "assets/svg/refresh-icon.svg";
import ClientEmployeeShiftTable from "components/Client/Client/ClientEmployeeShiftTable";
import ClientEmployeeShiftNavigation from "components/Client/Client/ClientEmployeeShiftNavigation";
import ClientShiftTable from "components/Client/Client/ClientShiftTable";
import PageBackButton from "components/Common/PageBackButton";
import { getRequest } from "api/request";
import { getClient, getClientName } from "utils/localstorage";
import { toastError } from "utils/toast";
import ClientActiveBoardFilter from "components/Client/Client/ClientActiveBoardFilter";
import ClientCancelledShiftTable from "components/Client/Client/ClientCancelledShiftTable";
import moment from "moment";
import {
  getCurrentDateWithoutTimeAdded,
  getTimeDifference,
  isShiftTimeWithinRange,
} from "utils/timeConverter";
import stringToDate from "utils/formatDate";
import { CANCELLED, CONFIRMED } from "types-definition/constants";

const ClientActiveBoard = () => {
  const clientName = getClientName();
  const [requestLoading, setRequestLoading] = useState(true);
  const [employeeCount, setEmployeeCount] = useState({
    total: 0,
    active: 0,
  });
  const [allShiftData, setAllShiftData] = useState<Array<any>>([]);
  const [recentShiftData, setRecentShiftData] = useState<Array<any>>([]);
  const [employeeShiftData, setEmployeeShiftData] = useState<Array<any>>([]);
  const [shiftData, setShiftData] = useState<Array<any>>([]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [timelineSelector, setTimeSelector] = useState("2 hours");
  const [startTime, setStartTime] = useState(
    `${moment().add(2, "hours").format("HH")}:00`,
  );
  const clientId = getClient();
  const [clientEmployeeNavigationOption, setClientEmployeeNavigationOption] =
    useState<"expected-employee-shift" | "recently-active-employee">(
      "expected-employee-shift",
    );

  const [clientShiftNavigationOption, setClientShiftNavigationOption] =
    useState<"unconfirmed-shift" | "canceled-shift">("unconfirmed-shift");

  useEffect(() => {
    const getClientActiveBoard = async () => {
      const keys: Array<string> = [];
      let totalEmployee = 0;
      let activeEmployee = 0;
      setRequestLoading(true);
      const recentResponse = await getRequest(
        `misc/active-board/client/${clientId}`,
      );
      const response = await getRequest(
        `misc/active-board/client/${clientId}?startTime=${startTime}`,
      );
      if (response?.success && recentResponse?.success) {
        // Get total Employee Count
        recentResponse?.data
          ?.filter((item: any) => {
            return (
              new Date(getCurrentDateWithoutTimeAdded()).getTime() ===
              new Date(stringToDate(item.date)).getTime()
            );
          })
          .forEach((item: any) => {
            if (keys.includes(item.employee.id)) return;
            keys.push(item.employee?.id);
            totalEmployee += 1;
            if (item.employeeStartTime) activeEmployee += 1;
          });
        setEmployeeCount({ total: totalEmployee, active: activeEmployee });

        // Filter and Set Shift Data
        setAllShiftData(
          response?.data?.filter((item: any) => {
            return (
              new Date(getCurrentDateWithoutTimeAdded()).getTime() ===
              new Date(stringToDate(item.date)).getTime()
            );
          }),
        );

        setRecentShiftData(
          recentResponse?.data?.filter((item: any) => {
            return (
              new Date(getCurrentDateWithoutTimeAdded()).getTime() ===
              new Date(stringToDate(item.date)).getTime()
            );
          }),
        );

        // Set Employee Shift Data Based on Navigation Option
        if (clientEmployeeNavigationOption === "expected-employee-shift") {
          setEmployeeShiftData(
            response?.data
              ?.filter(
                (item: any) =>
                  item.status === CONFIRMED &&
                  new Date(getCurrentDateWithoutTimeAdded()).getTime() ===
                    new Date(stringToDate(item.date)).getTime(),
              )
              .map((i: any) => {
                return {
                  expectedTime: i.startTime,
                  endTime: i.endTime,
                  location: i.location?.name,
                  name: `${i.employee?.firstName} ${i.employee?.lastName}`,
                  services: i.service?.name,
                  type: i.type,
                  id: i.id,
                  cancelled: i.status === CANCELLED,
                  confirmed: i.status === CONFIRMED,
                };
              }),
          );
        } else {
          setEmployeeShiftData(
            recentResponse?.data
              ?.filter(
                (item: any) =>
                  item.status === CONFIRMED &&
                  new Date(getCurrentDateWithoutTimeAdded()).getTime() ===
                    new Date(stringToDate(item.date)).getTime() &&
                  item.employeeStartTime,
              )
              .map((i: any) => {
                return {
                  expectedTime: i.startTime,
                  endTime: i.endTime,
                  location: i.location?.name,
                  name: `${i.employee?.firstName} ${i.employee?.lastName}`,
                  services: i.service?.name,
                  type: i.type,
                  id: i.id,
                  cancelled: i.status === CANCELLED,
                  confirmed: i.status === CONFIRMED,
                };
              }),
          );
        }
        setShiftData(
          response?.data
            ?.filter((item: any) => item.status !== CONFIRMED)
            .map((i: any) => {
              return {
                startTime: i.startTime,
                location: i.location?.name,
                type: i.type,
                duration: getTimeDifference(i.startTime, i.endTime),
                date: i.date,
                cancelledBy: i.cancelledBy,
              };
            }),
        );
        setRequestLoading(false);
      } else {
        toastError(response?.data);
        setRequestLoading(false);
      }
    };
    getClientActiveBoard();
  }, [startTime, clientId, refreshCounter]);
  useEffect(() => {
    if (clientShiftNavigationOption === "unconfirmed-shift") {
      setShiftData(
        allShiftData
          ?.filter((item: any) => item.status !== CONFIRMED)
          .map((i: any) => {
            return {
              startTime: i.startTime,
              location: i.location?.name,
              duration: getTimeDifference(i.startTime, i.endTime),
              date: i.date,
            };
          }),
      );
    } else {
      setShiftData(
        allShiftData
          ?.filter((item: any) => item.status === CANCELLED)
          .map((i: any) => {
            return {
              startTime: i.startTime,
              endTime: i.endTime,
              date: i.date,
              duration: getTimeDifference(i.startTime, i.endTime),
              location: i.location?.name,
              name: `${i.employee?.firstName} ${i.employee?.lastName}`,
              services: i.service?.name,
              cancelledBy: i.cancelledByName,
              id: i.id,
            };
          }),
      );
    }
  }, [clientShiftNavigationOption, allShiftData]);
  useEffect(() => {
    if (clientEmployeeNavigationOption === "expected-employee-shift") {
      setEmployeeShiftData(
        allShiftData
          ?.filter((item: any) => item.status !== CONFIRMED)
          .map((i: any) => {
            return {
              expectedTime: i.startTime,
              endTime: i.endTime,
              location: i.location?.name,
              name: `${i.employee?.firstName} ${i.employee?.lastName}`,
              services: i.service?.name,
              type: i.type,
              id: i.id,
              cancelled: i.status === CANCELLED,
              confirmed: i.status === CONFIRMED,
            };
          }),
      );
    } else {
      //
      setEmployeeShiftData(
        recentShiftData
          ?.filter(
            (item: any) => item.status === CONFIRMED && item.employeeStartTime,
          )
          ?.filter((item: any) => {
            return isShiftTimeWithinRange(
              item.employeeStartTime,
              "2 hours",
              moment.utc().toISOString(),
            );
          })
          .map((i: any) => {
            return {
              expectedTime: i.startTime,
              endTime: i.endTime,
              location: i.location?.name,
              name: `${i.employee?.firstName} ${i.employee?.lastName}`,
              services: i.service?.name,
              type: i.type,
              id: i.id,
              cancelled: i.status === CANCELLED,
              confirmed: i.status === CONFIRMED,
            };
          }),
      );
    }
  }, [clientEmployeeNavigationOption]);
  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="clients" />
        <div className={styles.container}>
          <div className={styles.item_container}>
            <PageBackButton width="95%" paddingTop="0" marginBottom="0px" />
            <div className={styles.flex_item}>
              <div>
                <Typography textAlign="left" textWeight="600" textSize="24px">
                  {`${clientName}'s`} Active Board
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <ClientActiveBoardFilter />
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value=""
                  setValue={() => {}}
                />
              </div>
            </div>
            <div className={styles.info_board}>
              <ClientBoardInformation
                text="Assigned Employees"
                number={employeeCount.total.toString()}
              />
              <ClientBoardInformation
                text="Active Employees"
                number={employeeCount.active.toString()}
              />
              <ClientBoardInformation
                text="Inactive Employees"
                number={(employeeCount.total - employeeCount.active).toString()}
              />
            </div>
            <ClientEmployeeShiftNavigation
              active={clientEmployeeNavigationOption}
              setActive={setClientEmployeeNavigationOption}
            />
            <div className={styles.select_input_container}>
              <div className={styles.select_input_item}>
                <SelectInput
                  value={timelineSelector}
                  setValue={(data) => {
                    setTimeSelector(data);
                    // extracting the hour(number) from the string of data
                    const hour = data.match(/\d+/g);
                    const currentTime = moment();
                    const newTime = currentTime.add(hour[0], "hours");

                    setStartTime(`${newTime.format("HH")}:00`);
                  }}
                  label="Timeline Selector"
                  placeholder="Select Timeline"
                  dropdownTop="48px"
                  datalistTop="84px"
                  options={[
                    "1 hour",
                    "2 hours",
                    "3 hours",
                    "4 hours",
                    "5 hours",
                    "6 hours",
                    "7 hours",
                  ]}
                  height="100px"
                />
              </div>
              <div className={styles.button_container}>
                <Button
                  isPrimary={false}
                  backgroundColor={colors.transparent}
                  isPrimaryBorder={true}
                  onClick={() => {
                    // window.location.reload();
                    setRefreshCounter(refreshCounter + 1);
                  }}
                  left={<img src={refreshIcon} alt="refresh-icon" />}
                  width="150px"
                  text="Refresh"
                  textColor={colors.primary3}
                  textWeight="600"
                  paddingVertical="8px"
                />
              </div>
            </div>
            <ClientEmployeeShiftTable data={employeeShiftData} />
            <div className={styles.shift_details_text}>
              <Typography textSize="20px" textWeight="600" textAlign="left">
                Shift Details
              </Typography>
            </div>
            <ClientShiftNavigation
              active={clientShiftNavigationOption}
              setActive={setClientShiftNavigationOption}
            />
            {clientShiftNavigationOption === "unconfirmed-shift" ? (
              <ClientShiftTable data={shiftData} />
            ) : (
              <ClientCancelledShiftTable data={shiftData} />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientActiveBoard;
