import React from "react";
import Typography from "../../../../Common/Typography";
import primaryInfoIcon from "../../../../../assets/svg/primary-info.svg";
import styles from "./index.module.css";
import Button from "../../../../Form/Button";
import colors from "../../../../../theme/colors";
import Checkbox from "../../../../Form/Checkbox";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  getDeliveryModeState,
  setDeliveryMode,
  setShowSelectDeliveryModeModal,
  setShowSendMessageModal,
} from "../../../../../redux/slice/staff-hub/employeeMessageSlice";
import { toastError } from "../../../../../utils/toast";

const MessageDeliveryModeModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const deliveryMode = useAppSelector(getDeliveryModeState);
  const handleContinue = () => {
    if (deliveryMode.length === 0) {
      toastError("Please select a delivery mode");
      return;
    }
    dispatch(setShowSelectDeliveryModeModal(false));
    dispatch(setShowSendMessageModal(true));
  };
  return (
    <div className={styles.main_container}>
      <div className={styles.container}>
        <img
          src={primaryInfoIcon}
          alt="cancel icon"
          className={styles.cancelImage}
        />
        <Typography textWeight="500" textSize="20px" textAlign="left">
          Choose the preferred delivery method for your message:
        </Typography>
        <div className={styles.checkbox_container}>
          <Checkbox
            label={
              <Typography
                marginVertical="0px"
                marginHorizontal="-5px"
                textWeight="500"
              >
                Send to Email
              </Typography>
            }
            value={deliveryMode.includes("mail") ? true : false}
            setValue={(data) => {
              dispatch(setDeliveryMode("mail"));
            }}
            marginVertical="20px"
          />
          <Checkbox
            label={
              <Typography
                marginVertical="0px"
                marginHorizontal="-5px"
                textWeight="500"
              >
                Send to Mobile App
              </Typography>
            }
            value={deliveryMode.includes("app") ? true : false}
            setValue={(data) => {
              dispatch(setDeliveryMode("app"));
            }}
            marginVertical="20px"
          />
        </div>
        <div className={styles.button_container}>
          <Button
            width="100%"
            text="Cancel"
            textColor={colors.primary3}
            isPrimary={false}
            isPrimaryBorder={true}
            backgroundColor={colors.transparent}
            textWeight="500"
            onClick={() => {
              dispatch(setShowSelectDeliveryModeModal(false));
            }}
            isLoading={false}
            borderWidth="1px"
            paddingVertical="6px"
          />
          <Button
            text="Continue"
            textColor={colors.white}
            isPrimary={true}
            textWeight="500"
            onClick={() => {
              handleContinue();
            }}
            isLoading={false}
            paddingVertical="6px"
          />
        </div>
      </div>
    </div>
  );
};

export default MessageDeliveryModeModal;
