import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import colors from "../../../theme/colors";
import moment from "moment";
import { SingleDateItemProps } from "../../../types-definition/common";
import stringToDate from "../../../utils/formatDate";

const SingleDateItem: React.FC<SingleDateItemProps> = ({
  date,
  isError = false,
}) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.month_container}
        style={{ backgroundColor: isError ? colors.error : colors.primary4 }}
      >
        <Typography
          textWeight="600"
          textSize="14px"
          textColor={colors.white}
          marginVertical="0px"
          fontFamily="poppins"
        >
          {moment(new Date(date)).format("MMM")}
        </Typography>
      </div>
      <div className={styles.day_container}>
        <Typography textWeight="600" textSize="16px" marginVertical="0px">
          {moment(new Date(date)).format("DD")}
        </Typography>
      </div>
    </div>
  );
};

export default SingleDateItem;
