import React from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import { ManualCheckpointReasonProps } from "../../../../types-definition/admin";
import ManualCheckpointInput from "../ManualCheckpointReasonsInput";
import plusIcon from "../../../../assets/svg/plus-green-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  addNewCheckPointReasons,
  getCheckPointReasons,
} from "../../../../redux/slice/admin/manualCheckpointReasons";

const ManualCheckpointReasonForm: React.FC<
  ManualCheckpointReasonProps
> = () => {
  const dispatch = useAppDispatch();
  const checkpointReasons = useAppSelector(getCheckPointReasons);
  return (
    <>
      {checkpointReasons.map((_, index) => {
        return (
          <div className={styles.sub_item_container} key={index}>
            <div className={styles.sub_content_container}>
              <ManualCheckpointInput
                reason={_}
                index={index}
                key={index}
                item={index}
              />
            </div>
          </div>
        );
      })}
      <div
        className={styles.flex_item}
        onClick={() => dispatch(addNewCheckPointReasons())}
      >
        <img src={plusIcon} alt="" className={styles.plusIcon} />
        <Typography
          textAlign="left"
          textWeight="600"
          textColor="#148A9b"
          style={{
            cursor: "pointer",
          }}
        >
          Add Checkpoint Reason
        </Typography>
      </div>
    </>
  );
};

export default ManualCheckpointReasonForm;
