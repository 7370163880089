import React from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import { LocationTimesheetData } from "../../../../../types-definition/StaffHub/employee";
import moment from "moment";

const LocationTimesheetItem: React.FC<LocationTimesheetData> = ({
  employeeName,
  grade,
  dateAndTime,
  employeeStartTime,
  employeeEndTime,
  totalWorkHours,
  ended,
  hourWorked,
}) => {
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td}`}>
        <Typography
          textAlign="left"
          textWeight="600"
        >{`${employeeName} `}</Typography>
      </td>
      <td className={styles.td_employee_no}>
        <Typography textAlign="left">{grade}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textWeight="600">
          {moment(dateAndTime).format("MMMM D, YYYY")}
        </Typography>

        <Typography textAlign="left">{hourWorked}</Typography>
      </td>

      <td className={styles.td}>
        <Typography textAlign="left">{employeeStartTime}</Typography>
      </td>

      <td className={styles.td}>
        <Typography textAlign="left">{employeeEndTime}</Typography>
      </td>
    </tr>
  );
};

export default LocationTimesheetItem;
