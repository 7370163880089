import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import colors from "../../../theme/colors";
import { DashboardItemProps } from "types-definition/common";
import { useNavigate } from "react-router-dom";
const DashboardCard: React.FC<DashboardItemProps> = ({ label, icon, link }) => {
  const navigate = useNavigate();
  return (
    <section
      className={styles.container}
      onClick={() => {
        navigate(link);
      }}
    >
      <div className={styles.item}>
        <img src={icon} className={styles.card_icon} alt="" />
        <Typography
          marginHorizontal="30px"
          textSize="16px"
          textWeight="600"
          textAlign="center"
          fontFamily="inter"
          textColor={colors.black}
          marginVertical="20px"
        >
          {label}
        </Typography>
      </div>
    </section>
  );
};

export default DashboardCard;
