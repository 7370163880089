import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { CertificateTableData } from "types-definition/StaffHub/certificateType";

interface CertificateTypeSliceInterface {
  newState: boolean;
  isFetching: boolean;
  isSearchEmpty: boolean;
  searchedValue: string;
  certificateType: Array<CertificateTableData>;
  counter: number;
}

const initialState: CertificateTypeSliceInterface = {
  newState: false,
  searchedValue: "",
  isSearchEmpty: false,
  isFetching: false,
  certificateType: [],
  counter: 0,
};

export const certificateTypeSlice = createSlice({
  name: "certificateType",
  initialState,
  reducers: {
    updateCertificateState: (state, action) => {
      state.newState = action.payload;
    },
    setCertificateTypeData: (
      state,
      action: PayloadAction<Array<CertificateTableData>>,
    ) => {
      state.certificateType = action.payload;
    },
    updateCertificateTypeSearchFetching: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isFetching = action.payload;
    },
    updateCertificateTypeSearchEmpty: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isSearchEmpty = action.payload;
    },
    setSearchedCertificateTypeValue: (state, action: PayloadAction<string>) => {
      state.searchedValue = action.payload;
    },
    updateCertificateTypeCounter: (state) => {
      state.counter += 1;
    },
  },
});

export const {
  updateCertificateState,
  setCertificateTypeData,
  updateCertificateTypeCounter,
  updateCertificateTypeSearchEmpty,
  updateCertificateTypeSearchFetching,
  setSearchedCertificateTypeValue,
} = certificateTypeSlice.actions;
export const getUpdatedState = (state: RootState) =>
  state.certificateType.newState;
export const getCertificateTypeData = (state: RootState) =>
  state.certificateType.certificateType;
export const getCertificateTypeCounter = (state: RootState) =>
  state.certificateType.counter;
export const getCertificateTypeSearchFetching = (state: RootState) =>
  state.certificateType.isFetching;
export const getCertificateTypeSearchEmpty = (state: RootState) =>
  state.certificateType.isSearchEmpty;
export const getSearchedCertificateTypeValue = (state: RootState) =>
  state.certificateType.searchedValue;
export default certificateTypeSlice.reducer;
