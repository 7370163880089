import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { AvailablePermissionItemProps } from "../../../types-definition/admin";
import Typography from "../../Common/Typography";
import colors from "../../../theme/colors";
import plusIcon from "../../../assets/svg/plus-icon.svg";
import checkAdminPermission from "utils/permission";
import { getUserTypes } from "utils/localstorage";
// import { ROSTER_SCHEDULE_CONFIRM } from "types-definition/constants";
import { useAppSelector } from "redux/hooks";
import { getActiveRosterSingleItem } from "redux/slice/roster/rosterSlice";

const AvailableEmployeeRosterItem: React.FC<AvailablePermissionItemProps> = ({
  action,
  text,
  id,
}) => {
  const activeRoosterItem = useAppSelector(getActiveRosterSingleItem);
  const [rosterPermission, setRosterPermission] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPermission = async () => {
      const data = checkAdminPermission(getUserTypes());
      if (data) {
        setRosterPermission(false);
      } else {
        // const result = await checkUserPermission([ROSTER_SCHEDULE_CONFIRM]);
        // setRosterPermission(false : true);
        setRosterPermission(false);
      }
    };

    getPermission();
  }, []);
  return (
    <div
      className={styles.container}
      data-rooster-suggestion-for={activeRoosterItem}
    >
      <Typography textColor={colors.primary3}>{text}</Typography>
      <div>
        <img
          src={plusIcon}
          alt="plus-icon"
          className={
            rosterPermission ? styles.plus_delete_icon : styles.plusIcon
          }
          onClick={async () => {
            if (!rosterPermission) {
              if (isLoading) return null;

              setIsLoading(true);
              await action(id);
              setIsLoading(false);
            }
          }}
        />
      </div>
    </div>
  );
};

export default AvailableEmployeeRosterItem;
