import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  clientCounter: 0,
  showClientDeletemodal: false,
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    updateClientCounter: (state) => {
      state.clientCounter += 1;
    },
    setShowClientDeletemodal: (state, action) => {
      state.showClientDeletemodal = action.payload;
    },
  },
});

export const { updateClientCounter, setShowClientDeletemodal } =
  clientSlice.actions;
export const getClientCounter = (state: RootState) =>
  state.client.clientCounter;
export const getClientDeleteModal = (state: RootState) =>
  state.client.showClientDeletemodal;
export default clientSlice.reducer;
