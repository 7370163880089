import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import colors from "../../../theme/colors";
import { DashboardTopCardProps } from "types-definition/common";
import { useNavigate } from "react-router-dom";

const DashboardTopCard: React.FC<DashboardTopCardProps> = ({
  label,
  name,
  total,
  link,
}) => {
  const navigate = useNavigate();

  return (
    <section
      className={styles.container}
      onClick={() => {
        navigate(`${link}`);
      }}
    >
      <div className={styles.item}>
        <Typography
          marginHorizontal="30px"
          textSize="16px"
          textWeight="600"
          textAlign="left"
          fontFamily="inter"
          textColor="#667185"
        >
          {label}
        </Typography>
        <Typography
          marginHorizontal="30px"
          textSize="28px"
          textWeight="600"
          textAlign="left"
          fontFamily="inter"
          textColor={colors.black}
        >
          {total}
        </Typography>
        <Typography
          marginHorizontal="30px"
          textSize="14px"
          textWeight="500"
          textAlign="left"
          fontFamily="inter"
          textColor="#98A2B3"
        >
          {name}
        </Typography>
      </div>
    </section>
  );
};

export default DashboardTopCard;
