import React, { useEffect, useState } from "react";

import DashboardMonitorCard from "components/Common/DashboardMonitorCard";
import styles from "./index.module.css";
import { DashboardMonitorCardProps } from "types-definition/common";
import Typography from "components/Common/Typography";
import colors from "theme/colors";
import LiveLocationMonitoring from "components/Common/LiveMonitoringHeader";
import { getData } from "utils/localstorage";
import { getRequest } from "api/request";
import filterKeysFromArray from "utils/filterKeyFromArray";
import { ACTIVE_BOARD } from "api/urls";
import stringToDate from "utils/formatDate";
import PageLoader from "components/Common/PageLoader";
import { ENDED, STARTED } from "types-definition/constants";
import moment from "moment";

const LiveMonitoringDasboard = () => {
      const date = moment().toISOString();
  const [data, setData] = useState<Array<DashboardMonitorCardProps>>([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    const getLiveMonitoringInformation = async () => {
      const userData = getData();
      if (userData) {
        const response = await getRequest(`${ACTIVE_BOARD}?date=${date}`);
        if (response?.success) {
          const locationData: Array<DashboardMonitorCardProps> = [];
          const locationIds = filterKeysFromArray(response?.data, "locationId");
          for (const locationId of locationIds) {
            const location = response?.data.filter(
              (item: any) => item.locationId === locationId,
            );
            const totalEmployeeData = location.filter((item: any) => {
              return (
                moment
                  .utc(moment.utc(item.date).format("DD/MM/YYYY"), "DD/MM/YYYY")
                  .valueOf() ===
                moment
                  .utc(
                    moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
                      "DD/MM/YYYY",
                    ),
                    "DD/MM/YYYY",
                  )
                  .valueOf()
              );
            });
            const employeeClockedIn = totalEmployeeData.filter((item: any) => {
              return item.status === STARTED  || item.status === ENDED;
            });
            locationData.push({
              id: locationId,
              siteName: location[0]?.location?.name,
              AssignedEmployee: totalEmployeeData.length,
              active: employeeClockedIn.length,
              inActive:
                totalEmployeeData.length - employeeClockedIn.length
              ,
              location: location,
            });
          }
          setData(locationData);
        } else {
        }
        setIsPageLoading(false);
      }
    };
    getLiveMonitoringInformation();

    const interval = setInterval(() => {
      getLiveMonitoringInformation();
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <section className={styles.section}>
          <div className={styles.container}>
            <LiveLocationMonitoring />
            <div className={styles.body_container}>
              <div className={styles.body_header}>
                <Typography
                  marginHorizontal="0px"
                  textSize="22px"
                  textWeight="600"
                  textAlign="left"
                  fontFamily="inter"
                  textColor={colors.black}
                  marginVertical="0px"
                >
                  Live Location Monitoring
                </Typography>
              </div>
              <div className={styles.body_content}>
                <div className={styles.grid_item}>
                  {data.map((item, index) => (
                    <DashboardMonitorCard key={index} {...item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default LiveMonitoringDasboard;
