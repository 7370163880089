import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../Common/PageBackButton";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../utils/localstorage";
import { getRequest } from "../../../../api/request";
import PageLoader from "../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../api/urls";
import { LocationAddressActivityLogs } from "../../../../types-definition/admin";
import DashboardHeader from "../../../Common/DashboardHeader";
import { toastError } from "utils/toast";
const ActivityLocationAddress = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentLocationAddress, setCurrentLocationAddress] =
    useState<LocationAddressActivityLogs>({
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      headOffice: false,
      firstName: "",
      lastName: "",
      emailAddrsess: "",
      mobileNumber: "",
      telephoneNumber: "",
    });
  const [previousLocationAddress, setPreviousLocationAddress] =
    useState<LocationAddressActivityLogs>({
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      headOffice: false,
      firstName: "",
      lastName: "",
      emailAddrsess: "",
      mobileNumber: "",
      telephoneNumber: "",
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getLocationAddress = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data;
        const previousData = response.data;
        if (response.data.action === "POST") {
          setCurrentLocationAddress({
            addressLine1: data?.newRecord?.address?.address1,
            addressLine2: data?.newRecord?.address?.address2,
            city: data?.newRecord?.address?.city,
            state: data?.newRecord?.address?.state,
            country: data?.newRecord?.address?.country,
            headOffice: data?.newRecord?.address?.headOffice,
            firstName: data?.newRecord?.address?.firstName,
            lastName: data?.newRecord?.address?.lastName,
            emailAddrsess: data?.newRecord?.address?.emailAddrsess,
            mobileNumber: data?.newRecord?.address?.mobileNumber,
            telephoneNumber: data?.newRecord?.address?.telephoneNumber,
          });
          setPreviousLocationAddress({
            addressLine1: previousData?.previousRecord?.address?.address1,
            addressLine2: previousData?.previousRecord?.address?.address2,
            city: previousData?.previousRecord?.address?.city,
            state: previousData?.previousRecord?.address?.state,
            country: previousData?.previousRecord?.address?.country,
            headOffice: previousData?.previousRecord?.address?.headOffice,
            firstName: previousData?.previousRecord?.address?.firstName,
            lastName: previousData?.previousRecord?.address?.lastName,
            emailAddrsess: previousData?.previousRecord?.address?.emailAddrsess,
            mobileNumber: previousData?.previousRecord?.address?.mobileNumber,
            telephoneNumber:
              previousData?.previousRecord?.address?.telephoneNumber,
          });
        } else {
          setCurrentLocationAddress({
            addressLine1: data?.newRecord?.address?.address1,
            addressLine2: data?.newRecord?.address?.address2,
            city: data?.newRecord?.address?.city,
            state: data?.newRecord?.address?.state,
            country: data?.newRecord?.address?.country,
            headOffice: data?.newRecord?.address?.headOffice,
            firstName: data?.newRecord?.address?.firstName,
            lastName: data?.newRecord?.address?.lastName,
            emailAddrsess: data?.newRecord?.address?.emailAddrsess,
            mobileNumber: data?.newRecord?.address?.mobileNumber,
            telephoneNumber: data?.newRecord?.address?.telephoneNumber,
          });
          setPreviousLocationAddress({
            addressLine1: previousData?.previousRecord?.address?.address1,
            addressLine2: previousData?.previousRecord?.address?.address2,
            city: previousData?.previousRecord?.address?.city,
            state: previousData?.previousRecord?.address?.state,
            country: previousData?.previousRecord?.address?.country,
            headOffice: previousData?.previousRecord?.address?.headOffice,
            firstName: previousData?.previousRecord?.address?.firstName,
            lastName: previousData?.previousRecord?.address?.lastName,
            emailAddrsess: previousData?.previousRecord?.address?.emailAddrsess,
            mobileNumber: previousData?.previousRecord?.address?.mobileNumber,
            telephoneNumber:
              previousData?.previousRecord?.address?.telephoneNumber,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getLocationAddress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Add Site Location
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Address Line 1 </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationAddress.addressLine1
                    ? currentLocationAddress.addressLine1
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Address Line 2</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationAddress.addressLine2
                    ? currentLocationAddress.addressLine2
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">City </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationAddress?.city
                    ? currentLocationAddress?.city
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">State </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationAddress?.state
                    ? currentLocationAddress?.state
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Country </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationAddress?.country
                    ? currentLocationAddress?.country
                    : "-"}
                </Typography>
              </div>
            </div>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Client Contact Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">First Name </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationAddress?.firstName
                    ? currentLocationAddress?.firstName
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Last Name </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationAddress?.lastName
                    ? currentLocationAddress?.lastName
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Email Address </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationAddress?.emailAddrsess
                    ? currentLocationAddress?.emailAddrsess
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Mobile Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationAddress?.mobileNumber
                    ? currentLocationAddress?.mobileNumber
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Telephone Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationAddress?.telephoneNumber
                    ? currentLocationAddress?.telephoneNumber
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* previouse Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Add Site Location
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Address Line 1 </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationAddress.addressLine1
                    ? previousLocationAddress.addressLine1
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Address Line 2</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationAddress.addressLine2
                    ? previousLocationAddress.addressLine2
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">City </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationAddress?.city
                    ? previousLocationAddress?.city
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">State </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationAddress?.state
                    ? previousLocationAddress?.state
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Country </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationAddress?.country
                    ? previousLocationAddress?.country
                    : "-"}
                </Typography>
              </div>
            </div>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Client Contact Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">First Name </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationAddress?.firstName
                    ? previousLocationAddress?.firstName
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Last Name </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationAddress?.lastName
                    ? previousLocationAddress?.lastName
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Email Address </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationAddress?.emailAddrsess
                    ? previousLocationAddress?.emailAddrsess
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Mobile Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationAddress?.mobileNumber
                    ? previousLocationAddress?.mobileNumber
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Telephone Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationAddress?.telephoneNumber
                    ? previousLocationAddress?.telephoneNumber
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityLocationAddress;
