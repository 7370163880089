const formatCurrency = (currency: number) => {
  return currency.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

const getCurrencyFormat = (formattedData: any): string => {
  let currencyFormat: string;
  if (formattedData === 0) {
    currencyFormat = "0";
  } else if (
    formattedData.includes(".") &&
    formattedData.split(".")[1].length === 0
  ) {
    currencyFormat = `${
      formatCurrency(parseFloat(formattedData)).split(".")[0]
    }.`;
  } else if (
    formattedData.includes(".") &&
    formattedData.split(".")[1].length === 1
  ) {
    currencyFormat = `${
      formatCurrency(parseFloat(formattedData)).split(".")[0]
    }.${formattedData.split(".")[1]}`;
  } else if (
    formattedData.includes(".") &&
    formattedData.split(".")[1].length > 1
  ) {
    currencyFormat = `${
      formatCurrency(parseFloat(formattedData)).split(".")[0]
    }.${formattedData.split(".")[1][0]}${formattedData.split(".")[1][1]}`;
  } else {
    currencyFormat = formatCurrency(parseFloat(formattedData)).split(".")[0];
  }
  return currencyFormat;
};

export { formatCurrency, getCurrencyFormat };
