import Typography from "components/Common/Typography";
import { useEffect, useRef } from "react";
import colors from "theme/colors";
import styles from "./index.module.css";

import closeIcon from "assets/svg/cancel-icon.svg";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getEmployeeContact,
  setShowEmployeeContactModal,
} from "redux/slice/common/statisticsSlice";

const EmployeeContactModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const employeeContact = useAppSelector(getEmployeeContact);
  const modalContainerRef = useRef<HTMLDivElement>(null);

  const closeModal = () => {
    dispatch(setShowEmployeeContactModal(false));
  };

  useEffect(() => {
    const modalContainer = modalContainerRef.current;
    const handleCloseModal = (event: MouseEvent) => {
      if (event.target === modalContainer) {
        closeModal();
      }
    };

    modalContainer?.addEventListener("click", handleCloseModal);

    return () => modalContainer?.removeEventListener("click", handleCloseModal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section ref={modalContainerRef} className={styles.container}>
        <div className={styles.content_container}>
          <div onClick={closeModal}>
            <img
              src={closeIcon}
              alt="close-icon"
              style={{
                position: "absolute",
                top: "2rem",
                right: "2rem",
                cursor: "pointer",
              }}
            />
          </div>

          <Typography textAlign="center" textWeight="600" textSize="24px">
            Employee Details
          </Typography>
          <Typography
            textSize="14px"
            textAlign="left"
            marginVertical="15px"
            textColor={colors.gray1}
          >
            Employee Name: {employeeContact.name}
          </Typography>
          <Typography
            textSize="14px"
            textAlign="left"
            marginVertical="15px"
            textColor={colors.gray1}
          >
            Phone Number: {employeeContact.mobileNumber}
          </Typography>
          <Typography
            textSize="14px"
            textAlign="left"
            marginVertical="15px"
            textColor={colors.gray1}
          >
            Email Address: {employeeContact.email}
          </Typography>
        </div>
      </section>
    </>
  );
};

export default EmployeeContactModal;
