import React, { useState, useEffect } from "react";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import searchIcon from "assets/svg/search-icon.svg";
import styles from "./index.module.css";
import { getRequest } from "api/request";
import { getData } from "utils/localstorage";
import toast from "react-hot-toast";
import { AUDIT_USER_URL } from "api/urls";
import { getEmployeeId } from "utils/localstorage";
import EmployeeAuditTable from "components/StaffHub/Employee/Audit/EmployeeAuditTable";
import TableFilter from "components/Common/TableFilter";
import PageBackButton from "components/Common/PageBackButton";
import colors from "theme/colors";
import { EmployeeAuditProps } from "types-definition/StaffHub/employee";

const EmployeeAudit: React.FC = () => {
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [employeeAudit, setEmployeeAudit] = useState<Array<EmployeeAuditProps>>(
    [],
  );

  useEffect(() => {
    const id = getEmployeeId();
    const user = JSON.parse(getData()!);
    if (id) {
      const getEmployeeAudit = async () => {
        setRequestLoading(true);

        const response = await getRequest(`${AUDIT_USER_URL}/${user?.id}`);
        if (response?.success) {
          setEmployeeAudit(
            response.data
              .filter((i: any) => i.action !== "GET")
              .map((i: any) => {
                return {
                  action: i.action,
                  category: i.server,
                  changedAt: i.createdAt,
                  changedBy: i.user.name,
                };
              }),
          );
        } else {
          if (response?.data) {
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
          }
        }
        setRequestLoading(false);
      };
      getEmployeeAudit();
    }
  }, []);

  return (
    <>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="staff/hub" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton width="90%" marginBottom="-45px" paddingTop="30px" />
          <div className={styles.flex_item}>
            <div>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                text="Holiday Multiplier"
                textSize="24px"
              >
                Audit
              </Typography>
              <Typography
                textSize="14px"
                textAlign="left"
                fontFamily="inter"
                textWeight="400"
                textColor={colors.gray6}
              >
                Track all actions performed within an employee's profile.
              </Typography>
            </div>

            <div className={styles.flex_filter_container}>
              <TableFilter />
              <TextInput
                left={<img src={searchIcon} alt="search" />}
                width={"280px"}
                placeholder="Search"
                value={search}
                setValue={(data) => {
                  setSearch(data);
                }}
              />
            </div>
          </div>
          <div className={styles.table}>
            <EmployeeAuditTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeAudit;
