import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import TextInput from "../../../../Form/TextInput";
import cancelIcon from "../../../../../assets/svg/circle-cancel-icon.svg";
import Checkbox from "../../../../Form/Checkbox";
import Button from "../../../../Form/Button";
import colors from "../../../../../theme/colors";
import DateInput from "../../../../Form/DateInput";
import { useAppDispatch } from "../../../../../redux/hooks";
import { AddAccrualAdjustmentFormData } from "types-definition/StaffHub/employee";
import { toggleAccrualModal } from "../../../../../redux/slice/staff-hub/employeeAccrualSettingSlice";
import DecimalNumberInput from "components/Form/DecimalNumberInput";
import { getRequest, patchRequest, postRequest } from "api/request";
import { toastSuccess } from "utils/toast";
import { CREATE_EMPLOYEE_URL } from "api/urls";
import {
  deleteManualAdjustmentId,
  getEmployeeId,
  getManualAdjustmentId,
} from "utils/localstorage";
import { EMPLOYEE } from "types-definition/constants";

const AddManualAdjustment: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsloading] = useState(false);
  const [manualAdjustmentId, setManualAdjustmentId] = useState("");
  const [formData, setFormData] = useState<AddAccrualAdjustmentFormData>({
    accrualCharge: "",
    adjustmentReason: "",
    applyOn: "",
    HasExpiration: false,
    expirationDate: "",
  });

  const addAdjustment = async () => {
    const id = getEmployeeId();
    if (id) {
      try {
        setIsloading(true);
        if (!manualAdjustmentId) {
          const response = formData.HasExpiration
            ? await postRequest(
                `${CREATE_EMPLOYEE_URL}/${id}/manual-adjustment`,
                {
                  accrualCharge: formData.accrualCharge,
                  reason: formData.adjustmentReason,
                  hasExpiration: formData.HasExpiration,
                  dateApplied: formData.applyOn,
                  expirationDate: formData.expirationDate,
                },
              )
            : await postRequest(
                `${CREATE_EMPLOYEE_URL}/${id}/manual-adjustment`,
                {
                  accrualCharge: formData.accrualCharge,
                  reason: formData.adjustmentReason,
                  hasExpiration: formData.HasExpiration,
                  dateApplied: formData.applyOn,
                },
              );
          response && setIsloading(false);
          if (response.success) {
            deleteManualAdjustmentId();
            toastSuccess(`Adjustment added successfully`);
            return response;
          }
        } else {
          const response = formData.HasExpiration
            ? await patchRequest(
                `${CREATE_EMPLOYEE_URL}/manual-adjustment/${manualAdjustmentId}`,
                {
                  accrualCharge: formData.accrualCharge,
                  reason: formData.adjustmentReason,
                  hasExpiration: formData.HasExpiration,
                  dateApplied: formData.applyOn,
                  expirationDate: formData.expirationDate,
                },
              )
            : await patchRequest(
                `${CREATE_EMPLOYEE_URL}/${id}/manual-adjustment`,
                {
                  accrualCharge: formData.accrualCharge,
                  reason: formData.adjustmentReason,
                  hasExpiration: formData.HasExpiration,
                  dateApplied: formData.applyOn,
                },
              );

          if (response.success) {
            deleteManualAdjustmentId();

            toastSuccess(`Edited successfully`);
          }
          return response;
        }
      } catch (error) {}
    }
  };
  const getManualAdjustment = async () => {
    const adjustmentId = getManualAdjustmentId();
    if (adjustmentId) {
      setManualAdjustmentId(adjustmentId);
      try {
        const response = await getRequest(
          `${EMPLOYEE}/manual-adjustment/${adjustmentId}`,
        );

        if (response.success) {
          setFormData({
            accrualCharge: response.data.accrualCharge,
            adjustmentReason: response.data.reason,
            applyOn: response.data.dateApplied,
            HasExpiration: response.data.expirationDate ? true : false,
            expirationDate: response.data.expirationDate,
          });
        }
      } catch (error) {}
    }
  };
  useEffect(() => {
    getManualAdjustment();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600" marginHorizontal="0">
            Add Accrual Adjustment
          </Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() => {
              dispatch(toggleAccrualModal({ showModal: false }));
              deleteManualAdjustmentId();
            }}
          />
        </div>
        <div className={styles.form_container}>
          <div className={styles.form_container_input}>
            <DecimalNumberInput
              placeholder=""
              label="Accrual Charge (Minutes per Hour)"
              value={formData.accrualCharge}
              setValue={(data: string) => {
                setFormData((prevData) => {
                  return { ...prevData, accrualCharge: data };
                });
              }}
            />
            <Typography
              textWeight="400"
              marginHorizontal="0"
              textSize="12px"
              textAlign="left"
              textColor="#667185"
            >
              Default rate: For every hour worked, an employee accrues 7.242
              minutes of holiday time.
            </Typography>
          </div>
          <div className={styles.form_container_input}>
            <DateInput
              placeholder="16/01/2024"
              label="Apply On"
              value={formData.applyOn}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, applyOn: data };
                });
              }}
            />
          </div>
          <div className={styles.form_container_input}>
            <TextInput
              placeholder="Enter Adjustment Reason"
              label="Adjustment Reason"
              value={formData.adjustmentReason}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, adjustmentReason: data };
                });
              }}
            />
          </div>
          <div className={styles.form_container_checkbox}>
            <Checkbox
              value={formData.HasExpiration}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, HasExpiration: data };
                });
              }}
              marginVertical="15px"
              label={
                <Typography textWeight="600" marginHorizontal="5px">
                  Has Expiration
                </Typography>
              }
            />
          </div>
          {formData.HasExpiration && (
            <div className={styles.form_container_input}>
              <DateInput
                placeholder="16/01/2024"
                label="Expiration Date"
                value={formData.expirationDate}
                setValue={(data) => {
                  setFormData((prevData) => {
                    return { ...prevData, expirationDate: data };
                  });
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.button_container}>
          <Button
            text="Cancel"
            isPrimary={false}
            onClick={() => {
              dispatch(toggleAccrualModal({ showModal: false }));
              deleteManualAdjustmentId();
            }}
            marginHorizontal="10px"
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
          />
          <Button
            text="Update"
            isLoading={isLoading}
            isPrimary={true}
            onClick={async () => {
              const response: any = await addAdjustment();
              if (response.success) {
                dispatch(toggleAccrualModal({ showModal: false }));
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddManualAdjustment;
