import React from "react";
import { DashboardMonitorCardProps } from "../../../types-definition/common";
import styles from "./index.module.css";
import Typography from "../Typography";
import colors from "../../../theme/colors";
import { useNavigate } from "react-router-dom";
import { setLiveLocationData, setLiveLocationId } from "utils/localstorage";
const DashboardMonitorCard: React.FC<DashboardMonitorCardProps> = ({
  id,
  AssignedEmployee,
  active,
  inActive,
  siteName,
  location,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => {
          setLiveLocationData(JSON.stringify(location));
          setLiveLocationId(id);
          navigate("/live-location-statistic");
        }}
        className={styles.container}
        style={{
          backgroundColor:
            parseInt(active) >= (parseInt(AssignedEmployee) * 70) / 100
              ? "#20AF0B"
              : parseInt(active) < (parseInt(AssignedEmployee) * 70) / 100 &&
                  parseInt(active) >= (parseInt(AssignedEmployee) * 30) / 100
                ? "#F3A218"
                : "#D42620",
        }}
      >
        <div className={styles.flex_item}>
          <Typography
            marginHorizontal="0px"
            textSize="22px"
            textWeight="600"
            textAlign="left"
            fontFamily="inter"
            textColor={colors.white}
            marginVertical="0px"
          >
            {AssignedEmployee}
          </Typography>

          <div className={styles.employeesStat}>
            <div
              className={styles.assigned_employee}
              style={{
                backgroundColor: "#20AF0B",
              }}
            >
              <Typography
                marginHorizontal="0px"
                textSize="14px"
                textWeight="600"
                textAlign="left"
                fontFamily="inter"
                textColor={colors.white}
                marginVertical="1px"
              >
                {active}
              </Typography>
            </div>
            <div>
              <div className={styles.assigned_employee}>
                <Typography
                  marginHorizontal="0px"
                  textSize="14px"
                  textWeight="600"
                  textAlign="left"
                  fontFamily="inter"
                  textColor={colors.white}
                  marginVertical="1px"
                >
                  {inActive}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <Typography
          marginHorizontal="0px"
          textSize="14px"
          textWeight="500"
          textAlign="left"
          fontFamily="inter"
          textColor={colors.white}
          marginVertical="0px"
        >
          {siteName}
        </Typography>
      </div>

      {/* {parseFloat(active) < 80 && parseFloat(active) > 29 && (
        <div
          className={styles.container}
          style={{
            backgroundColor: "#F3A218",
          }}
        >
          <div className={styles.flex_item}>
            <Typography
              marginHorizontal="0px"
              textSize="22px"
              textWeight="600"
              textAlign="left"
              fontFamily="inter"
              textColor={colors.black}
              marginVertical="0px"
            >
              {AssignedEmployee}
            </Typography>
            <div
              className={styles.assigned_employee}
              style={{ backgroundColor: "#20AF0B" }}
            >
              <Typography
                marginHorizontal="0px"
                textSize="14px"
                textWeight="600"
                textAlign="left"
                fontFamily="inter"
                textColor={colors.black}
                marginVertical="1px"
              >
                {active}
              </Typography>
            </div>
          </div>
          <div className={styles.flex_right}>
            <div className={styles.active_container}>
              {" "}
              <Typography
                marginHorizontal="0px"
                textSize="14px"
                textWeight="600"
                textAlign="left"
                fontFamily="inter"
                textColor={colors.black}
                marginVertical="1px"
              >
                {active}
              </Typography>
            </div>
          </div>
          <Typography
            marginHorizontal="0px"
            textSize="14px"
            textWeight="600"
            textAlign="left"
            fontFamily="inter"
            textColor={colors.black}
            marginVertical="0px"
          >
            {siteName}
          </Typography>
        </div>
      )} */}

      {/* {parseFloat(active) > 80 && (
        <div
          className={styles.container}
          style={{
            backgroundColor: "#20AF0B",
          }}
        >
          <div className={styles.flex_item}>
            <Typography
              marginHorizontal="0px"
              textSize="22px"
              textWeight="600"
              textAlign="left"
              fontFamily="inter"
              textColor={colors.black}
              marginVertical="0px"
            >
              {AssignedEmployee}
            </Typography>
          </div>
          <Typography
            marginHorizontal="0px"
            textSize="14px"
            textWeight="600"
            textAlign="left"
            fontFamily="inter"
            textColor={colors.black}
            marginVertical="0px"
          >
            {siteName}
          </Typography>
        </div>
      )} */}
    </>
  );
};
export default DashboardMonitorCard;
