import React, { useState, useEffect } from "react";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import Typography from "components/Common/Typography";
import styles from "./index.module.css";
import colors from "theme/colors";
import TextInput from "components/Form/TextInput";
import searchIcon from "assets/svg/search-icon.svg";
import LinkedToLocationTable from "components/StaffHub/Employee/LinkToLocation/LinkedToLocationTable";
import LinkToLocationModal from "components/StaffHub/Employee/LinkToLocation/LinkToLocationModal";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import {
  getNewState,
  refetchState,
} from "../../../../redux/slice/staff-hub/linkToLocationSlice";
import LInkToLocationHeader from "components/StaffHub/Employee/LinkToLocation/link-to-location-header";
import { LinkedToLocationTableItemData } from "types-definition/StaffHub/employee/linkToLocation";
import EmptyItemState from "components/Common/EmptyItemState";
import { handleReload } from "../../../../redux/slice/staff-hub/linkToLocationSlice";
import { getRequest } from "api/request";
import { GET_LINK_EMPLOYEE_TO_LOCATION } from "api/urls";
import { getCurrentEmployee } from "utils/localstorage";
import toast from "react-hot-toast";
import PageBackButton from "components/Common/PageBackButton";

const LinkedDashboard = () => {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector(getNewState);
  const reload = useAppSelector(refetchState);
  const [employeeName, setEmployeeName] = useState("");
  const [asisignedData, setAssignedData] = useState<
    Array<LinkedToLocationTableItemData>
  >([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [filterData, setFilterData] = useState<
    Array<LinkedToLocationTableItemData>
  >([]);
  const [search, setSeach] = useState("");
  useEffect(() => {
    const getCurrentEmployeeLocation = async () => {
      const employeeDetails = getCurrentEmployee();
      if (employeeDetails) {
        setRequestLoading(true);
        setEmployeeName(JSON.parse(employeeDetails).displayName);
        try {
          const response = await getRequest(
            `${GET_LINK_EMPLOYEE_TO_LOCATION}/${
              JSON.parse(employeeDetails).name
            }/assigned-location`,
          );
          if (response?.success) {
            const responseData: Array<LinkedToLocationTableItemData> = [];
            for (const index in response.data) {
              const data = {
                id: response.data[index].id,
                locationId: response.data[index]?.location?.id,
                location: response.data[index]?.location?.name,
                distance: response.data[index].distance,
                payOPtion: response.data[index].payOption,
                assign: "",
                payValue: response.data[index].payValue,
                restriction: response.data[index].restrict,
              };
              responseData.push(data);
            }
            setAssignedData(responseData);
            setFilterData(responseData.slice(0, 5));
            setRequestLoading(false);
          } else {
            toast.error(response.data, {
              duration: 4000,
              position: "top-center",
            });
          }
        } finally {
        }
      }
    };
    dispatch(handleReload({ reload: false }));
    getCurrentEmployeeLocation();
  }, [reload, dispatch]);

  return (
    <>
      {requestLoading ? (
        <PageLoader />
      ) : (
        <section>
          <ScrollToTop />
          <DashboardHeader active="staff/hub" />
          <div className={styles.container}>
            <div className={styles.item_container}>
              <PageBackButton
                width="95%"
                paddingTop="20px"
                marginBottom="20px"
              />
              <div className={styles.flex_item}>
                <div className={styles.item}>
                  <div className={styles.sub_flex_item}>
                    <Typography
                      text="Link Employee To Location"
                      textAlign="left"
                      textWeight="600"
                      textSize="20px"
                    ></Typography>
                    {/* /employee name would be changed */}
                    <Typography
                      textAlign="left"
                      textColor={colors.primary3}
                      textWeight="600"
                    >
                      {employeeName}
                    </Typography>
                  </div>
                  <LInkToLocationHeader location="Assigned Locations" />
                </div>
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={search}
                  setValue={(data) => {
                    setSeach(data);
                    if (data) {
                      setFilterData(
                        filterData.filter((prevData) =>
                          prevData.location
                            .toLowerCase()
                            .startsWith(data.toLowerCase()),
                        ),
                      );
                    } else {
                      setFilterData(asisignedData);
                    }
                  }}
                />
              </div>
              {asisignedData.length < 1 ? (
                <div className={styles.emptyspace}>
                  <EmptyItemState
                    text="You haven't Assign any available ocation yet"
                    location="employee/link-to-location"
                    label="Assign Location"
                  />
                </div>
              ) : (
                <LinkedToLocationTable data={filterData} />
              )}
              <div>{showModal && <LinkToLocationModal />}</div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default LinkedDashboard;
