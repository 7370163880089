import React from "react";
import styles from "./index.module.css";
import TextInput from "../../../../../components/Form/TextInput";

import { EmployeeIdentificationFormPropsUk } from "../../../../../types-definition/StaffHub/employee";
import DateInput from "../../../../Form/DateInput";
import SelectInput from "../../../../Form/SelectInput";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  basicInformationFormData,
  basicInformationRequiredIdentificationFormDataBirthCertificateUk,
  basicInformationRequiredIdentificationFormDataOthersNonUk,
  basicInformationRequiredIdentificationFormDataUk,
  setBasicInformationFormData,
  setBasicInformationRequiredIdentificationFormDataBirthCertificateUk,
  setBasicInformationRequiredIdentificationFormDataOthersNonUk,
  setBasicInformationRequiredIdentificationFormDataUk,
} from "../../../../../redux/slice/staff-hub/employeeBasicInfoSlice";
import NumberInputWithoutInput from "../../../../Form/NumberInputWithoutIcon";

const EmployeeIdentificationFormUk: React.FC<
  EmployeeIdentificationFormPropsUk
> = () => {
  const formData = useAppSelector(basicInformationFormData);

  const requiredFormData = useAppSelector(
    basicInformationRequiredIdentificationFormDataUk,
  );
  const requiredBirthCertificateFormData = useAppSelector(
    basicInformationRequiredIdentificationFormDataBirthCertificateUk,
  );
  const requiredOthersFormData = useAppSelector(
    basicInformationRequiredIdentificationFormDataOthersNonUk,
  );
  const dispatch = useAppDispatch();
  return (
    <>
      <div className={styles.form_flex_container}>
        <SelectInput
          label="Document Type"
          placeholder="Select Document Type"
          value={formData.documentType}
          setValue={(data) => {
            dispatch(
              setBasicInformationFormData({
                ...formData,
                documentType: data,
              }),
            );
            dispatch(
              setBasicInformationRequiredIdentificationFormDataUk({
                ...requiredFormData,
                documentType: false,
              }),
            );
          }}
          options={[
            "None",
            "Passport",
            "Driving License",
            "Birth Certificate",
            "Others",
          ]}
          error={requiredFormData.documentType}
          readonly={true}
        />
      </div>
      {formData.documentType === "Others" && (
        <>
          <TextInput
            label="Document Name"
            placeholder="Document Name"
            value={formData.documentName}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  documentName: data,
                }),
              );
              dispatch(
                setBasicInformationRequiredIdentificationFormDataOthersNonUk({
                  ...requiredOthersFormData,
                  documentName: false,
                }),
              );
            }}
            required={true}
            error={requiredOthersFormData.documentName}
            id="documentName"
          />
        </>
      )}
      {formData.documentType !== "None" && (
        <div>
          <div className={styles.form_flex_container}>
            <NumberInputWithoutInput
              label="Document Number"
              placeholder="Enter Document Number"
              value={formData.documentNumber}
              setValue={(data) => {
                dispatch(
                  setBasicInformationFormData({
                    ...formData,
                    documentNumber: data,
                  }),
                );
                dispatch(
                  setBasicInformationRequiredIdentificationFormDataUk({
                    ...requiredFormData,
                    documentNumber: false,
                  }),
                );
                dispatch(
                  setBasicInformationRequiredIdentificationFormDataBirthCertificateUk(
                    {
                      ...requiredBirthCertificateFormData,
                      documentNumber: false,
                    },
                  ),
                );
              }}
              required={true}
              error={
                requiredFormData.documentNumber ||
                requiredBirthCertificateFormData.documentNumber
              }
              disabled={formData.documentType === "None"}
              right={null}
              id="documentNumber"
            />
            <DateInput
              value={formData.issueDate}
              setValue={(data) => {
                dispatch(
                  setBasicInformationFormData({
                    ...formData,
                    issueDate: data,
                  }),
                );
                dispatch(
                  setBasicInformationRequiredIdentificationFormDataUk({
                    ...requiredFormData,
                    issueDate: false,
                  }),
                );
                dispatch(
                  setBasicInformationRequiredIdentificationFormDataOthersNonUk({
                    ...requiredOthersFormData,
                    issueDate: false,
                  }),
                );
                dispatch(
                  setBasicInformationRequiredIdentificationFormDataUk({
                    ...requiredFormData,
                    issueDate: false,
                  }),
                );
                dispatch(
                  setBasicInformationRequiredIdentificationFormDataBirthCertificateUk(
                    {
                      ...requiredBirthCertificateFormData,
                      issueDate: false,
                    },
                  ),
                );
              }}
              label="Issue Date"
              placeholder="Select Date"
              required={true}
              error={
                requiredFormData.issueDate ||
                requiredOthersFormData.issueDate ||
                requiredBirthCertificateFormData.issueDate
              }
              disabled={formData.documentType === "None"}
              id="issueDate"
            />
            {formData.documentType !== "Birth Certificate" && (
              <DateInput
                value={formData.expiryDate}
                setValue={(data) => {
                  dispatch(
                    setBasicInformationFormData({
                      ...formData,
                      expiryDate: data,
                    }),
                  );
                  dispatch(
                    setBasicInformationRequiredIdentificationFormDataUk({
                      ...requiredFormData,
                      expiryDate: false,
                    }),
                  );
                  dispatch(
                    setBasicInformationRequiredIdentificationFormDataOthersNonUk(
                      {
                        ...requiredOthersFormData,
                        expiryDate: false,
                      },
                    ),
                  );
                  dispatch(
                    setBasicInformationRequiredIdentificationFormDataUk({
                      ...requiredFormData,
                      expiryDate: false,
                    }),
                  );
                }}
                min={formData.issueDate}
                validateMin={true}
                label="Expiry Date"
                placeholder="Select Date"
                error={
                  requiredFormData.expiryDate ||
                  requiredOthersFormData.expiryDate
                }
                disabled={formData.documentType === "Birth Certificate"}
                required={true}
                id="expiryDate"
              />
            )}
          </div>

          <div className={styles.form_flex_container}>
            <TextInput
              label="Place of Issue"
              placeholder="Enter Place of Issue"
              value={formData.placeOfIssue}
              setValue={(data) => {
                dispatch(
                  setBasicInformationFormData({
                    ...formData,
                    placeOfIssue: data,
                  }),
                );
              }}
              disabled={formData.documentType === "None"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeIdentificationFormUk;
