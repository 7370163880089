import React, { useState } from "react";
import Typography from "../../../Common/Typography";
import deleteIcon from "../../../../assets/svg/error-icon.svg";
import styles from "./index.module.css";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch } from "../../../../redux/hooks";
import { deleteRequest } from "../../../../api/request";
import { GET_LOCATION_URL } from "../../../../api/urls";
import toast from "react-hot-toast";
import { updateLocationTableState } from "../../../../redux/slice/client/locationSlice";
import { deleteLocation, getLocation } from "../../../../utils/localstorage";
const DeleteLocationModal: React.FC = () => {
  const [requestLoading, setRequestLoading] = useState(false);
  const dispatch = useAppDispatch();
  const deleteAction = async () => {
    setRequestLoading(true);
    const response = await deleteRequest(
      `${GET_LOCATION_URL}/${getLocation()}`,
    );
    if (response.success) {
      toast.success("Location deleted successfully", {
        duration: 4000,
        position: "top-center",
      });
      dispatch(updateLocationTableState(false));
      deleteLocation();
      setRequestLoading(false);
    } else {
      toast.success(response.data, {
        duration: 4000,
        position: "top-center",
      });
      setRequestLoading(false);
    }
  };
  const cancelAction = () => {
    deleteLocation();
    dispatch(updateLocationTableState(false));
  };
  return (
    <div className={styles.container}>
      <img src={deleteIcon} alt="cancel icon" className={styles.cancelImage} />
      <Typography textWeight="600" textSize="18px" textAlign="left">
        Delete Location
      </Typography>
      <div className={styles.mid_text}>
        <Typography textColor={colors.gray4} textAlign="left">
          Are you sure you want to delete this location?
        </Typography>
        <Typography
          textColor={colors.gray4}
          textAlign="left"
          marginVertical="-8px"
        >
          This action cannot be undone.
        </Typography>
      </div>
      <div className={styles.button_container}>
        <Button
          width="160px"
          text="Cancel"
          isPrimary={false}
          onClick={cancelAction}
          backgroundColor={colors.transparent}
          isPrimaryBorder={true}
        />
        <Button
          width="160px"
          text="Delete"
          backgroundColor={colors.error}
          textColor={colors.white}
          isPrimary={false}
          onClick={deleteAction}
          isLoading={requestLoading}
        />
      </div>
    </div>
  );
};

export default DeleteLocationModal;
