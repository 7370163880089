import { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { useAppDispatch } from "../../../../redux/hooks";
import Button from "../../../Form/Button";
import TextInput from "../../../Form/TextInput";
import RadioInput from "../../../Form/RadioInput";
import cancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import LongTextInput from "../../../Form/LongTextInput";
import { handleModal } from "../../../../redux/slice/client/shiftNote";
import {
  ShiftNoteFormData,
  ShiftNoteRequiredFormData,
} from "../../../../types-definition/client";
import { getRequest, postRequest } from "../../../../api/request";
import { CLIENT_SHIFT_NOTE } from "../../../../api/urls";
import { SERVICE_LOCATION } from "../../../../api/urls";
import { getLocation } from "../../../../utils/localstorage";
import toast from "react-hot-toast";
import moment from "moment";
import ClientSelectInput from "../../../Form/ClientSelectInput";
import fieldTextValidator from "../../../../utils/validateFieldsWithText";
// import
const ShiftNoteModal = () => {
  // const showAddModal = useAppSelector(getShowAddModal);
  const dispatch = useAppDispatch();
  const [requestLoading, setRequestLoading] = useState(false);
  const [newServiceIdArray, setNewServiceIdArray] = useState<Array<String>>([]);
  const [allServiceIdArray, setAllServiceIdArray] = useState<Array<String>>([]);
  const [locationShitNote, setLocationShiftNote] = useState<any>([]);
  const [serviceoptions, setServiceOptions] = useState<Array<any>>([]);
  const locationId = getLocation();
  const [formData, setFormData] = useState<ShiftNoteFormData>({
    title: "",
    details: "",
    locationSpecific: false,
    ServiceShiftSpecific: false,
    service: "",
  });
  const [client, setClient] = useState("");
  const [clientError, setClientError] = useState<boolean>(false);
  const [requiredFormData, setRequiredFormData] =
    useState<ShiftNoteRequiredFormData>({ title: false, details: false });
  const handleFormSubmit = async () => {
    const { data, status } = fieldTextValidator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
    } else {
      if (newServiceIdArray.length > 0) {
        try {
          setRequestLoading(true);
          const response = await postRequest(CLIENT_SHIFT_NOTE, {
            title: formData.title,
            details: formData.details,
            locationSpecific: formData.locationSpecific,
            serviceSpecific: formData.ServiceShiftSpecific,
            location: locationId,
            // One service is assigned for now, we would ignore other service
            service: formData.ServiceShiftSpecific
              ? newServiceIdArray[0]
              : newServiceIdArray[0],
          });
          if (response.success) {
            toast.success("Shift note added successfully.", {
              duration: 4000,
              position: "top-center",
            });
            dispatch(handleModal({ showModal: false }));
            setRequestLoading(false);
          } else {
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
            setRequestLoading(false);
          }
        } finally {
        }
      } else {
        setClientError(true);
        toast.error("Select a service", {
          duration: 4000,
          position: "top-center",
        });
      }
    }
  };

  useEffect(() => {
    const getAllServices = async () => {
      const response = await getRequest(`${SERVICE_LOCATION}/${locationId}`);
      if (response?.success) {
        const serviceArray = await response.data.map((service: any) => {
          return service.serviceId;
        });
        setAllServiceIdArray(serviceArray);
        setNewServiceIdArray(serviceArray);
      }
    };

    getAllServices();
    const getAllShiftNote = async () => {
      const response = await getRequest(`/client/shift-note`);
      if (response.success) {
        const locationShiftNote = response.data.filter(
          (item: any) => item.locationId === locationId,
        );
        if (locationShiftNote.length > 0) {
          setFormData({
            title: locationShiftNote[locationShiftNote.length - 1].title,
            details: locationShiftNote[locationShiftNote.length - 1].details,
            ServiceShiftSpecific:
              locationShiftNote[locationShiftNote.length - 1].serviceSpecific,
            locationSpecific:
              locationShiftNote[locationShiftNote.length - 1].locationSpecific,
            service: locationShiftNote[locationShiftNote.length - 1].service,
          });
          setClient(locationShiftNote[locationShiftNote.length - 1].service);
        }
        setLocationShiftNote(locationShiftNote);
      } else {
        toast.error(response?.data, {
          duration: 4000,
          position: "top-center",
        });
      }
    };

    getAllShiftNote();
    const getAllServiceLinkedToService = async () => {
      const response = await getRequest(`${SERVICE_LOCATION}/${locationId}`);
      if (response?.success) {
        setServiceOptions(
          response.data.map((item: any) => {
            return { name: item?.service?.name, id: item?.service?.id };
          }),
        );
      }
    };
    getAllServiceLinkedToService();
  }, [locationId]);
  return (
    <>
      <section className={styles.container}>
        <div className={styles.content_container}>
          <div className={styles.header}>
            <Typography textWeight="600" textSize="20px">
              Add Shift Note Client
            </Typography>
            <img
              src={cancelIcon}
              alt="cancel icon"
              className={styles.cancel_icon}
              onClick={() => {
                dispatch(handleModal({ showModal: false }));
              }}
            />
          </div>
          <div className={styles.form_container}>
            <TextInput
              value={formData.title}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, title: data };
                });

                setRequiredFormData((prevData) => {
                  return { ...prevData, title: false };
                });
              }}
              label="Title"
              placeholder="Enter Title"
              error={requiredFormData.title}
            />
            <LongTextInput
              label="Details"
              value={formData.details}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, details: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, details: false };
                });
              }}
              placeholder="Enter shift note details"
              error={requiredFormData.details}
            />
            <div className={styles.flex_container}>
              <div className={styles.flex_item}>
                <div className={styles.sub_flex}>
                  <Typography textWeight="600" textSize="16px">
                    Updated By:
                  </Typography>
                  <Typography
                    textWeight="500"
                    textSize="16px"
                    marginHorizontal="5px"
                  >
                    {locationShitNote.length < 1
                      ? ""
                      : locationShitNote[0].user}
                  </Typography>
                </div>
                <div className={styles.sub_flex}>
                  <Typography textWeight="600" textSize="16px">
                    Updated At:
                  </Typography>
                  <Typography
                    textWeight="500"
                    textSize="16px"
                    marginHorizontal="5px"
                  >
                    {locationShitNote.length < 1
                      ? ""
                      : moment(locationShitNote[0].updatedAt).format(
                          "DD/MM/YY",
                        )}
                  </Typography>
                </div>
              </div>
              <div className={styles.flex_item}>
                <RadioInput
                  label="Location Specific"
                  name="plan-type"
                  value={!formData.ServiceShiftSpecific}
                  setValue={(data) => {
                    setNewServiceIdArray(allServiceIdArray);
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        ServiceShiftSpecific: false,
                        service: newServiceIdArray,
                      };
                    });
                  }}
                  marginVertical="0"
                />
                <RadioInput
                  label="Service/Shift Specific"
                  name="plan-type"
                  value={formData.ServiceShiftSpecific}
                  setValue={(data) => {
                    setNewServiceIdArray([]);
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        ServiceShiftSpecific: true,
                      };
                    });
                  }}
                  marginVertical="0"
                />
              </div>
            </div>
            {formData.ServiceShiftSpecific && (
              <ClientSelectInput
                value={formData.service}
                setValue={(name, id) => {
                  if (id) {
                    setFormData((prevData) => {
                      return { ...prevData, service: [id] };
                    });
                    setNewServiceIdArray([id]);
                  }
                  setClient(name);
                  setRequiredFormData((prevData) => {
                    return { ...prevData, service: false };
                  });
                }}
                label="Client"
                dropdownTop="48px"
                placeholder="Select Client"
                options={[...serviceoptions]}
                client={client}
                required={true}
                error={clientError}
              />
            )}
            <div className={styles.button_container}>
              <Button
                text="Update"
                isLoading={requestLoading}
                isPrimary
                marginHorizontal="5px"
                onClick={handleFormSubmit}
                marginTop="20px"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShiftNoteModal;
