import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, patchRequest, postRequest } from "api/request";
import ClientNavigation from "components/Client/Service/ServiceNavigation";
import {
  CREATE_SERVICE_URL,
  GET_SERVICE_URL,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_URL,
} from "api/urls";
import { useNavigate } from "react-router-dom";
import {
  ServiceFormData,
  ServiceRequiredFormData,
} from "types-definition/client";
import { getData, getService } from "utils/localstorage";
import toast from "react-hot-toast";
import validator from "utils/validateFields";
import AddServiceForm from "components/Client/Service/AddServiceForm";
import ScrollToTop from "components/Common/ScrollToTop";
import { setService } from "utils/localstorage";
import scrolltoView from "utils/scrollToView";
import { updateSetupComplete } from "utils/setupWizard";
import returnChangedValuesInObject from "utils/returnChangedValuesInObject";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getShowServiceConfirmationModal,
  setShowServiceConfirmationModal,
} from "redux/slice/client/serviceSlice";
import ServiceConfirmationModal from "components/Common/ServiceConfirmationModal";
import moment from "moment";
import { getGradeSlected } from "utils/getSelectedGrade";
import { toastSuccess } from "utils/toast";

const AddService = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ServiceFormData>({
    name: "",
    abbreviation: "",
    active: true,
    grade: "",
    gradeId: "",
    noOfEmployeeRequired: "",
    autoGenerateShift: true,
    overRideProximity: false,
    autoBookOn: false,
    autoBookOff: false,
    shiftStartProximityRadius: "",
    shiftEndProximityRadius: "",
    shiftStart: "",
    shiftEnd: "",
    checkCallProximityRadius: "",
    proximityRadiusEmail: "",
    autoRosterGroup: "",
    fromData: "",
    toDate: "",
    excludePublicHoliday: false,
    shiftRotationPeriod: "7",
    frequency: "",
    dayOfWeek: "",
    dayOfMonth: "",
    totalHoursInWeek: "",
    serviceGroup: "",
    daysOfWeek: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    openShift: false,
    requireConfirmation: true,
    isDayOfTheWeek: true,
    weekOfMonth: "",
    monthDayOfWeek: "",
    sentFrequency: "weekly",
    checkCall: false,
    activateCheckCall: false,
    frequencyOfCheckcall: "",
    question: "Is everything Okay?",
    allowClockInOutsideProximityRadius: false,
  });

  const [proximityDefaultSetting, setProximityDefaultSetting] = useState({
    shiftStartProximityRadius: "",
    shiftEndProximityRadius: "",
    checkCallProximityRadius: "",
    proximityRadiusEmail: "",
  });

  const [apiDefaultFormData, setApiDefaultFormData] =
    useState<ServiceFormData>(formData);
  const [requiredFormData, setRequiredFormData] =
    useState<ServiceRequiredFormData>({
      name: false,
      grade: false,
      noOfEmployeeRequired: false,
      shiftStartProximityRadius: false,
      shiftEndProximityRadius: false,
      shiftStart: false,
      shiftEnd: false,
      checkCallProximityRadius: false,
      proximityRadiusEmail: false,
      autoRosterGroup: false,
      fromData: false,
      toDate: false,
      shiftRotationPeriod: false,
      frequency: false,
    });
  const [requestLoading, setRequestLoading] = useState(false);

  const dispatch = useAppDispatch();
  const showServiceConfirmationModal = useAppSelector(
    getShowServiceConfirmationModal,
  );

  const handleSubmitForm = async () => {
    const { status, data, id } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      if (id) {
        scrolltoView(id);
      }
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    if (
      [
        formData.daysOfWeek.sunday && "Sunday",
        formData.daysOfWeek.monday && "Monday",
        formData.daysOfWeek.tuesday && "Tuesday",
        formData.daysOfWeek.wednesday && "Wednesday",
        formData.daysOfWeek.thursday && "Thursday",
        formData.daysOfWeek.friday && "Friday",
        formData.daysOfWeek.saturday && "Saturday",
      ].filter(Boolean).length === 0 &&
      formData.frequency !== "Every Month" &&
      formData.frequency !== "Every 2 Months"
    ) {
      toast.error("Select atleast one day of the week", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    setRequestLoading(true);

    const createApiBody = (body: ServiceFormData) => {
      let data: any = {
        name: body.name,
        abbreviation: body.abbreviation,
        active: body.active,
        grade: body.gradeId,
        noOfEmployeeRequired: body.noOfEmployeeRequired,
        autoGenerateShift: body.autoGenerateShift,
        autoBookOn: body.autoBookOn,
        autoBookOff: body.autoBookOff,
        overrideProximitySettings: body.overRideProximity,
        shiftStartProximityRadius: body.shiftStartProximityRadius,
        shiftEndProximityRadius: body.shiftEndProximityRadius,
        checkCallProximityRadius: body.checkCallProximityRadius,
        proximityRadiusEmail: body.proximityRadiusEmail,
        planType: body.openShift ? "open shift" : "require confirmation",
        shiftRotationPeriod: parseInt(body.shiftRotationPeriod),
        frequency: body.frequency,
        totalHoursInWeek: body.totalHoursInWeek,
        serviceGroup: body.serviceGroup,
        excludePublicHolidays: body.excludePublicHoliday,
        allowClockInOutsideProximityRadius:
          body.allowClockInOutsideProximityRadius,
        from: moment.utc(body.fromData).toISOString(),
        to: moment.utc(body.toDate).toISOString(),
        shiftStart: body.shiftStart,
        shiftEnd: body.shiftEnd,
        autoRoasterGroup:
          body.autoRosterGroup === "Auto-generate and Confirm"
            ? "auto generate and confirm"
            : body.autoRosterGroup === "Auto-generate and Schedule"
              ? "auto generate and schedule"
              : body.autoRosterGroup === "Generate and Confirm"
                ? "generate and confirm"
                : "",
        daysOfWeek: [
          body.daysOfWeek.sunday && "sunday",
          body.daysOfWeek.monday && "monday",
          body.daysOfWeek.tuesday && "tuesday",
          body.daysOfWeek.wednesday && "wednesday",
          body.daysOfWeek.thursday && "thursday",
          body.daysOfWeek.friday && "friday",
          body.daysOfWeek.saturday && "saturday",
        ].filter(Boolean),
        addCheckCall: body.checkCall,
        activateCheckCall: body.activateCheckCall,
        checkCallFrequency: body.frequencyOfCheckcall,
        question: body.question,
      };
      if (
        body.frequency === "Every Month" ||
        body.frequency === "Every 2 Months"
      ) {
        delete data.daysOfWeek;
        if (body.isDayOfTheWeek) {
          data = {
            ...data,
            daysOfTheWeek: body.isDayOfTheWeek,
            dayOfTheWeekForDaysOfTheWeek: body.dayOfWeek,
            weekOfTheMonth: body.weekOfMonth,
            // dayOfMonth: !body.isDayOfTheWeek,
          };
        } else {
          data = {
            ...data,
            dayOfTheWeekForDayOfTheMonth: body.monthDayOfWeek,
            dayOfTheMonth: !body.isDayOfTheWeek,
            // daysOfTheWeek: false,
            // weekOfTheMonth: "",
          };
        }
      }
      return data;
    };

    try {
      const serviceId = getService();
      let response: any;
      if (serviceId) {
        const patchReqBody = returnChangedValuesInObject(
          createApiBody(formData),
          createApiBody(apiDefaultFormData),
        );
        if (patchReqBody && Object.entries(patchReqBody).length === 0) {
          toastSuccess(`Service information saved without changes`);
          navigate(`/client/service/financial`);
          return;
        }

        if (patchReqBody?.daysOfWeek) {
          patchReqBody.frequency = formData.frequency;
        }

        if (!showServiceConfirmationModal) {
          dispatch(setShowServiceConfirmationModal(true));
          return;
        }

        response = await patchRequest(
          `${CREATE_SERVICE_URL}/${serviceId}`,
          patchReqBody,
        );
      } else {
        response = await postRequest(
          CREATE_SERVICE_URL,
          createApiBody(formData),
        );
      }

      if (response?.success) {
        toastSuccess(
          `Service ${
            serviceId ? "Updated Successfully" : "Created Successfully"
          }`,
        );
        setApiDefaultFormData((prevData) => ({
          ...prevData,
          ...formData,
        }));
        setService(response.data.id);
        updateSetupComplete("13", false, "");
        navigate(`/client/service/financial`);
      } else {
        toast.error(response?.data.toString(), {
          duration: 4000,
          position: "top-center",
        });
      }
    } finally {
      setRequestLoading(false);
    }
  };
  const handleFormCancelation = () => {
    navigate(`/client/service`);
  };

  useEffect(() => {
    const getServiceData = async () => {
      const data = getService();
      if (data !== undefined && data !== null) {
        const response = await getRequest(`${GET_SERVICE_URL}/${data}`);
        const grade = await getGradeSlected(response.data.grade);
        if (response?.success) {
          const serviceFormData = {
            name: response.data.name ?? "",
            abbreviation: response.data.abbreviation,
            active: response.data.active ?? false,
            grade: grade ? grade.grade : "",
            gradeId: grade ? grade.id : "",
            noOfEmployeeRequired: response.data.noOfEmployeeRequired ?? "",
            autoGenerateShift: response.data.autoGenerateShift ?? false,
            overRideProximity: response.data.overrideProximitySettings ?? false,
            autoBookOn: response.data.autoBookOn ?? false,
            autoBookOff: response.data.autoBookOff ?? false,
            shiftStartProximityRadius:
              response?.data?.shiftStartProximityRadius ?? "",
            shiftEndProximityRadius:
              response?.data?.shiftEndProximityRadius ?? "",
            shiftStart: response.data.shiftStart ?? "",
            shiftEnd: response.data.shiftEnd ?? "",
            checkCallProximityRadius:
              response.data.checkCallProximityRadius ?? "",
            proximityRadiusEmail: response.data.proximityRadiusEmail ?? "",
            autoRosterGroup:
              response.data.autoRoasterGroup === "auto generate and confirm"
                ? "Auto-generate and Confirm"
                : response.data.autoRoasterGroup ===
                    "auto generate and schedule"
                  ? "Auto-generate and Schedule"
                  : response.data.autoRoasterGroup === "generate and confirm"
                    ? "Generate and Confirm"
                    : "",
            fromData: response.data.from ?? "",
            toDate: response.data.to ?? "",
            excludePublicHoliday: response.data.excludePublicHolidays ?? false,
            shiftRotationPeriod: response.data.shiftRotationPeriod ?? "",
            frequency: response.data.frequency ?? "",
            // dayOfWeek: response.data.dayOfWeek ?? "",
            totalHoursInWeek: response.data.totalHoursInWeek ?? "",
            serviceGroup: response.data.serviceGroup ?? "",
            daysOfWeek: {
              monday: response.data.daysOfWeek?.includes("monday"),
              tuesday: response.data.daysOfWeek?.includes("tuesday"),
              wednesday: response.data.daysOfWeek?.includes("wednesday"),
              thursday: response.data.daysOfWeek?.includes("thursday"),
              friday: response.data.daysOfWeek?.includes("friday"),
              saturday: response.data.daysOfWeek?.includes("saturday"),
              sunday: response.data.daysOfWeek?.includes("sunday"),
            },
            openShift: response.data.planType === "open shift",
            requireConfirmation:
              response.data.planType === "require confirmation",
            isDayOfTheWeek: response.data.daysOfTheWeek ? true : false,
            dayOfWeek: response.data.dayOfTheWeekForDaysOfTheWeek ?? "",
            weekOfMonth: response.data.weekOfTheMonth ?? "",
            monthDayOfWeek: response.data.dayOfTheWeekForDayOfTheMonth ?? "",
            checkCall: response.data.addCheckCall ?? false,
            activateCheckCall: response.data.activateCheckCall ?? false,
            frequencyOfCheckcall: response.data.checkCallFrequency ?? "",
            allowClockInOutsideProximityRadius: JSON.parse(
              response.data.allowClockInOutsideProximityRadius,
            ),
            question: response.data.question ?? "",
          };

          setFormData((prevData) => {
            return {
              ...prevData,
              ...serviceFormData,
            };
          });

          setApiDefaultFormData((prevData) => ({
            ...prevData,
            ...serviceFormData,
          }));

          setRequestLoading(false);
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      } else {
        const getUserData = getData();
        if (getUserData) {
          const { id } = JSON.parse(getUserData);
          const response = await getRequest(`${GET_USER_SETTINGS}/${id}`);
          if (response.success) {
            setFormData((prevData) => {
              return {
                ...prevData,
                shiftStartProximityRadius: response?.data
                  ?.shiftStartProximityRadius
                  ? response?.data?.shiftStartProximityRadius
                  : "",
                shiftEndProximityRadius: response?.data?.shiftEndProximityRadius
                  ? response?.data?.shiftEndProximityRadius
                  : "",
                checkCallProximityRadius: response?.data
                  ?.checkCallProximityRadius
                  ? response?.data?.checkCallProximityRadius
                  : "",
                proximityRadiusEmail: response?.data?.proximityRadiusEmail
                  ? response?.data?.proximityRadiusEmail
                  : "",
              };
            });

            setProximityDefaultSetting((prevData) => {
              return {
                ...prevData,
                shiftStartProximityRadius:
                  response?.data?.shiftStartProximityRadius,
                shiftEndProximityRadius:
                  response?.data?.shiftEndProximityRadius,
                checkCallProximityRadius:
                  response?.data?.checkCallProximityRadius,
                proximityRadiusEmail: response?.data?.proximityRadiusEmail,
              };
            });
          }
        }
      }
    };
    getServiceData();
  }, []);

  useEffect(() => {
    const serviceId = getService();
    
    if (!serviceId) {
      if (formData.overRideProximity) {
        setFormData((prevData) => {
          return {
            ...prevData,
            shiftStartProximityRadius: "",
            shiftEndProximityRadius: "",
            checkCallProximityRadius: "",
            proximityRadiusEmail: "",
          };
        });
      } else {
        setFormData((prevData) => {
          return {
            ...prevData,
            shiftStartProximityRadius:
              proximityDefaultSetting?.shiftStartProximityRadius,
            shiftEndProximityRadius:
              proximityDefaultSetting?.shiftEndProximityRadius,
            checkCallProximityRadius:
              proximityDefaultSetting?.checkCallProximityRadius,
            proximityRadiusEmail: proximityDefaultSetting?.proximityRadiusEmail,
          };
        });
      }
    }
    // formData
  }, [formData.overRideProximity]);
  // "Auto-generate and Confirm",
  //  "Auto-generate and Schedule",
  //  "Generate and Confirm",
  useEffect(() => {
    const getUserSettings = async () => {
      const response = await getRequest(GET_USER_SETTINGS_URL);
      if (response?.success) {
        setFormData((prevData) => {
          return {
            ...prevData,
            shiftEndProximityRadius: response?.data?.shiftEndProximityRadius
              ? response?.data?.shiftEndProximityRadius
              : "",
            shiftStartProximityRadius: response?.data?.shiftStartProximityRadius
              ? response?.data?.shiftStartProximityRadius
              : "",
          };
        });
        setRequestLoading(false);
      }
    };
    getUserSettings();
  }, []);

  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="clients" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.content_container}>
            <Typography
              textAlign="left"
              textWeight="600"
              textSize="24px"
              marginVertical="16px"
            >
              Add Service
            </Typography>
            <div style={{ marginBottom: "24px" }}>
              <ClientNavigation active="general" />
            </div>
            <AddServiceForm
              formData={formData}
              setFormData={setFormData}
              requiredFormData={requiredFormData}
              setRequiredFormData={setRequiredFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Cancel"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={true}
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>

            {showServiceConfirmationModal && (
              <ServiceConfirmationModal
                service={formData.name}
                onCancel={() => {
                  dispatch(setShowServiceConfirmationModal(false));
                }}
                onConfirm={() => {
                  handleSubmitForm();
                  dispatch(setShowServiceConfirmationModal(false));
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddService;
