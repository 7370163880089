import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import {
  getShowModal,
  getShowSkipPermissionModal,
  handleShowSkipPermissionModal,
  showWizardModal,
} from "redux/slice/WizardSetup";
import WizardModal from "../SetupWizardModal";
import classes from "./index.module.css";
import SkipPermissionGroup from "../SkipPermissionModal";
const Step = ({ step, data }: any) => {
  const showModal = useAppSelector(getShowModal);
  const showSkipPermissionGroupModal = useAppSelector(
    getShowSkipPermissionModal,
  );
  const stepsRef = useRef<HTMLDivElement[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pushStepRef = (el: HTMLDivElement) => {
    if (el && !stepsRef.current.includes(el)) return stepsRef.current.push(el);
  };

  useEffect(() => {
    if (step) {
      let currentElement = stepsRef.current[step - 1];
      if (currentElement) {
        currentElement.style.scrollMarginTop = "2rem";
        currentElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [step]);

  return (
    <div>
      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 1 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 1 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}>Settings</p>
          <p className={classes.step_desc}>
            Configure system-wide settings such as working hours, overtime
            rules, and general preferences.
          </p>
          <button
            disabled={
              data.filter((item: any) => item.name === "settings").length
                ? true
                : false
            }
            className={`${classes.step_button} ${
              step > 0 && classes.completed_button
            }`}
            onClick={() => {
              navigate("/admin/settings");
            }}
          >
            {step > 1 ? "Completed" : "Complete Setup"}
          </button>
        </div>
      </div>
      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 2 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 2 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}>Permission Group</p>
          <p className={classes.step_desc}>
            Define permission groups to control access levels and privileges for
            different user roles within the system.
          </p>
          <div>
            <button
              disabled={step > 1 ? false : true}
              className={`${classes.step_button} ${
                step > 1 && classes.completed_button
              }`}
              onClick={() => {
                navigate("/admin/permissions");
              }}
            >
              {step > 2 ? "Completed" : "Complete Setup"}
            </button>
            {step === 2 && (
              <button
                disabled={step > 1 ? false : true}
                className={`${classes.skip_button} ${
                  step > 1 && classes.skip_disabled
                } `}
                onClick={() => {
                  dispatch(
                    handleShowSkipPermissionModal({
                      showSkipPermissionModal: true,
                    }),
                  );
                }}
              >
                Skip
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 3 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 3 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}>Web User Profile</p>
          <p className={classes.step_desc}>
            Set up user profiles for employees to manage their personal
            information, contact details, and login credentials.
          </p>
          <button
            disabled={step > 2 ? false : true}
            className={`${classes.step_button} ${
              step > 2 && classes.completed_button
            }`}
            onClick={() => {
              navigate("/admin/web-user-profile");
            }}
          >
            {step > 3 ? "Completed" : "Complete Setup"}
          </button>
        </div>
      </div>

      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 4 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 4 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}> Certificate Type</p>
          <p className={classes.step_desc}>
            Define different types of certificates or qualifications required
            for specific job roles or tasks.
          </p>
          <button
            disabled={step > 3 ? false : true}
            className={`${classes.step_button} ${
              step > 3 && classes.completed_button
            }`}
            onClick={() => {
              navigate("/staff-hub/certificate-type");
            }}
          >
            {step > 4 ? "Completed" : "Complete Setup"}
          </button>
        </div>
      </div>
      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 5 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 5 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}> Certificate Group </p>
          <p className={classes.step_desc}>
            Group related certificates or qualifications together for easier
            management and tracking.
          </p>
          <button
            disabled={step > 4 ? false : true}
            className={`${classes.step_button} ${
              step > 4 && classes.completed_button
            }`}
            onClick={() => {
              navigate("/staff-hub/certificate-group");
            }}
          >
            {step > 5 ? "Completed" : "Complete Setup"}
          </button>
        </div>
      </div>
      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 6 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 6 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}>Grade</p>
          <p className={classes.step_desc}>
            Create and assign grades or levels to employees based on skills,
            experience, or performance.
          </p>
          <button
            disabled={step > 5 ? false : true}
            className={`${classes.step_button} ${
              step > 5 && classes.completed_button
            }`}
            onClick={() => {
              navigate("/staff-hub/grade");
            }}
          >
            {step > 6 ? " Completed" : " Complete Setup"}
          </button>
        </div>
      </div>
      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 7 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 7 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}> Employee</p>
          <p className={classes.step_desc}>
            Add and manage employee profiles, including personal details, job
            roles, and work schedules.
          </p>
          <button
            disabled={step > 6 ? false : true}
            className={`${classes.step_button} ${
              step > 6 && classes.completed_button
            }`}
            onClick={() => {
              navigate("/staff-hub");
            }}
          >
            {step > 7 ? " Completed" : " Complete Setup"}
          </button>
        </div>
      </div>
      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 8 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 8 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}>Client</p>
          <p className={classes.step_desc}>
            Add and manage client profiles, including contact information,
            location, and service agreements.
          </p>
          <button
            className={`${classes.step_button} ${
              step > 7 && classes.completed_button
            }`}
            disabled={step > 7 ? false : true}
            onClick={() => {
              navigate("/client/dashboard");
            }}
          >
            {step > 8 ? " Completed" : " Complete Setup"}
          </button>
        </div>
      </div>
      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 9 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 9 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}>Area</p>
          <p className={classes.step_desc}>
            Define geographic areas or regions for better organization and
            assignment of tasks or services.
          </p>
          <button
            disabled={step > 8 ? false : true}
            className={`${classes.step_button} ${
              step > 8 && classes.completed_button
            }`}
            onClick={() => {
              navigate("/client/area");
            }}
          >
            {step > 9 ? " Completed" : " Complete Setup"}
          </button>
        </div>
      </div>
      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 10 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 10 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}>Service Group</p>
          <p className={classes.step_desc}>
            Group related services together for easier management and
            organization.
          </p>
          <button
            disabled={step > 9 ? false : true}
            className={`${classes.step_button} ${
              step > 9 && classes.completed_button
            }`}
            onClick={() => {
              navigate("/admin/service-group");
            }}
          >
            {step > 10 ? "Completed" : "Complete Setup"}
          </button>
        </div>
      </div>
      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 11 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 11 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}>Location </p>
          <p className={classes.step_desc}>
            Set up physical locations or facilities where work or services are
            performed.
          </p>
          <button
            disabled={step > 10 ? false : true}
            className={`${classes.step_button} ${
              step > 10 && classes.completed_button
            }`}
            onClick={() => {
              navigate("/client/site");
            }}
          >
            {step > 11 ? "Completed" : "Complete Setup"}
          </button>
        </div>
      </div>
      <div className={classes.step} ref={pushStepRef}>
        <div
          className={`${classes.stroke} ${step > 12 && classes.completed}`}
        ></div>
        <div
          className={`${classes.dot} ${step > 12 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}>Services</p>
          <p className={classes.step_desc}>
            Define the services offered by your organization, including
            descriptions, pricing, and associated tasks.
          </p>
          <button
            disabled={step > 11 ? false : true}
            className={`${classes.step_button} ${
              step > 11 && classes.completed_button
            }`}
            onClick={() => {
              navigate("/client/service");
            }}
          >
            {step > 12 ? "Completed" : "Complete Setup"}
          </button>
        </div>
      </div>
      <div className={classes.step} ref={pushStepRef}>
        <div className={`${classes.last_stroke}`}></div>
        <div
          className={`${classes.dot} ${step > 13 && classes.completed}`}
        ></div>
        <div className={classes.step_details}>
          <p className={classes.step_title}>Holiday Rate</p>
          <p className={classes.step_desc}>
            Establish holiday rates for employees, specifying compensation or
            bonuses for working on holidays.
          </p>
          <button
            disabled={step > 12 ? false : true}
            className={`${classes.step_button} ${
              step > 12 && classes.completed_button
            }`}
            onClick={() => {
              navigate("/admin/holiday-multiplier");
            }}
          >
            {step > 13 ? "Completed" : "  Complete Setup"}
          </button>
          <button
            disabled={step > 12 ? false : true}
            className={`${classes.skip_button} ${
              step > 12 && classes.skip_disabled
            } `}
            onClick={() => {
              dispatch(showWizardModal({ showModal: true }));
            }}
          >
            Skip
          </button>
        </div>
      </div>
      {showModal && <WizardModal />}
      {showSkipPermissionGroupModal && <SkipPermissionGroup />}
    </div>
  );
};

export default Step;
