const generatePassword = (length: number) => {
  let result = "";
  const firstChar = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const letters = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";
  const specialCharacters = "!@#$%^&*_+";
  const lettersLength = letters.length;
  for (let i = 0; i < length; i++) {
    if (i === 0) {
      result += firstChar.charAt(Math.floor(Math.random() * firstChar.length));
    } else if (i === length - 1) {
      result += specialCharacters.charAt(
        Math.floor(Math.random() * specialCharacters.length),
      );
    } else if (i === length - 2) {
      result += numbers.charAt(Math.floor(Math.random() * numbers.length));
    } else {
      result += letters.charAt(Math.floor(Math.random() * lettersLength));
    }
  }
  return result;
};

export default generatePassword;
