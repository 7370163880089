import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import AdminSideBar from "components/Admin/SideBar";
import Typography from "components/Common/Typography";
import { WebUserProfileData } from "types-definition/admin";
import Button from "components/Form/Button";
import { GET_WEB_USER_URL } from "api/urls";
import { getRequest } from "api/request";
import { useNavigate } from "react-router-dom";
import addIcon from "assets/svg/add-icon.svg";
import WebUserProfileTable from "components/Admin/Web User/WebUserProfileTable";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getSearchedWebUserValue,
  getShowAssignPermissionModal,
  getShowLinkLocationModal,
  getShowViewWebUserPermissionModal,
  setSearchedWebUserValue,
} from "../../../redux/slice/admin/adminSlice";
import AssignWebUserPermissionModal from "components/Admin/Web User/AssignWebUserPermissionModal";
import LinkWebUserLocationModal from "components/Admin/Web User/LinkWebUserLocation";
import ViewWebUserPermissionModal from "components/Admin/Web User/ViewWebUserPermissionModal";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import TextInput from "components/Form/TextInput";
import searchIcon from "assets/svg/search-icon.svg";
import WebUserTableFilter from "components/Admin/Web User/WebUserTableFilter";
import AdminEmptyItemState from "components/Common/AdminEmptyStateItem";
import { deleteWebUser } from "utils/localstorage";
import LoadingIcons from "react-loading-icons";
import colors from "theme/colors";
import {
  getShowDeleteWebUserModal,
  getWebUserCounter,
  getWebUserSearchEmpty,
  getWebUserSearchFetching,
  getWebUsers,
  setWebUsers,
} from "../../../redux/slice/admin/webuserSlice";
import DeleteWebUserModal from "components/Admin/Web User/DeleteWebUserModal";
import { toastError } from "utils/toast";

const WebUserProfile: React.FC = () => {
  const navigate = useNavigate();
  const showAssignPermissionModal = useAppSelector(
    getShowAssignPermissionModal,
  );
  const searchedValue = useAppSelector(getSearchedWebUserValue);
  const showLinkLocationModal = useAppSelector(getShowLinkLocationModal);
  const showViewWebUserPermissionModal = useAppSelector(
    getShowViewWebUserPermissionModal,
  );
  const showDeleteWebUserModal = useAppSelector(getShowDeleteWebUserModal);
  const isSearchEmpty = useAppSelector(getWebUserSearchEmpty);
  const searchLoading = useAppSelector(getWebUserSearchFetching);
  const [requestLoading, setRequestLoading] = useState(false);
  const webUsers = useAppSelector(getWebUsers);
  const counter = useAppSelector(getWebUserCounter);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const getUserDetails = async () => {
      setRequestLoading(true);
      const response = await getRequest(GET_WEB_USER_URL);
      if (response?.success) {
        const responseData: Array<WebUserProfileData> = [];
        for (const index in response?.data.webUsers) {
          const data = {
            id: response?.data.webUsers[index]?.id,
            fullName: `${response?.data.webUsers[index]?.firstName} ${response?.data.webUsers[index]?.lastName}`,
            clientName: response?.data.webUsers[index]?.clientName,
            clientId: response?.data.webUsers[index]?.clientId,
            userName: response?.data.webUsers[index]?.username,
            email: response?.data.webUsers[index]?.email,
            active: response?.data.webUsers[index]?.active,
            status: response?.data.webUsers[index]?.active,
            clientPortalUser: response?.data.webUsers[index]?.clientPortalUser
              ? true
              : false,
          };
          responseData.push(data);
        }
        dispatch(setWebUsers(responseData));
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
      setRequestLoading(false);
    };
    deleteWebUser();
    getUserDetails();
  }, [counter]);
  return (
    <>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <AdminSideBar active="web user profile" />
        <div className={styles.item_container}>
          <div className={styles.flex_item}>
            <div>
              <Typography textAlign="left" textWeight="600" textSize="20px">
                Web User profile
              </Typography>
            </div>
            <div className={styles.sub_flex_item}>
              <WebUserTableFilter />
              <TextInput
                left={<img src={searchIcon} alt="search" />}
                placeholder="Search"
                value={searchedValue}
                setValue={(data) => {
                  dispatch(setSearchedWebUserValue(data));
                }}
              />
              <Button
                left={<img src={addIcon} alt="add" />}
                marginHorizontal="10px"
                marginTop="8px"
                paddingVertical="10px"
                width="200px"
                isPrimary
                text="Add Web User"
                onClick={() => {
                  navigate("/admin/web-user-profile/form");
                }}
              />
            </div>
          </div>
          {searchLoading ? (
            <div>
              <LoadingIcons.TailSpin
                width={50}
                height={50}
                stroke={colors.primary1}
                color={colors.primary1}
                className={styles.loader_spinner}
              />
            </div>
          ) : isSearchEmpty ? (
            <></>
          ) : webUsers.length === 0 ? (
            <AdminEmptyItemState
              text="You haven't added any web user yet."
              text2="A web user profile is created for individuals who need access to the workforce management portal. This profile enables them to execute specific tasks or view customized reports that are pertinent to their role."
              location="admin/web-user-profile/form"
              label="Add Web User"
              style={{ left: "50%", top: "45%" }}
              text2Width="500px"
            />
          ) : (
            <WebUserProfileTable data={webUsers} />
          )}
        </div>
      </div>
      {showAssignPermissionModal && <AssignWebUserPermissionModal />}
      {showLinkLocationModal && <LinkWebUserLocationModal />}
      {showViewWebUserPermissionModal && <ViewWebUserPermissionModal />}
      {showDeleteWebUserModal && <DeleteWebUserModal />}
    </>
  );
};

export default WebUserProfile;
