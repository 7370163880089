import Badge from "components/Common/Badge";
import DashboardHeader from "components/Common/DashboardHeader";
import HorizontalRule from "components/Common/HorizontalRule";
import PageBackButton from "components/Common/PageBackButton";
import Typography from "components/Common/Typography";
import React, { useEffect, useState } from "react";
import colors from "../../../../theme/colors";
import {
  getAllCheckcallLog,
  getCheckcallId,
} from "../../../../utils/localstorage";
import styles from "./index.module.css";
import { useAppSelector } from "redux/hooks";
import {
  getReduxCheckcallId,
  getCheckcallLogData,
} from "redux/slice/report/checkcallLogSlice";
import moment from "moment";
import { CheckcallReportData } from "types-definition/report";
import getNumberPosition from "utils/getNumberPosition";

interface CheckcallLogInterface {
  employeeName: string;
  location: string;
  service: string;
  checkcallTime: string;
  attempt: string;
  responseStatus: string;
  responseTime: number;
  verificationMethod: string;
  verificationStatus: string;
  message: string;
  status: CheckcallReportData["status"];
}

const CheckcallLogInfo = () => {
  const allCheckcallLog: CheckcallReportData[] = JSON.parse(
    getAllCheckcallLog() || "[]",
  );
  const cachedCheckcallId = getCheckcallId();
  const [employeeCheckcallLogs, setEmployeeCheckcallLogs] = useState<
    CheckcallLogInterface[]
  >([]);

  const checkcallLogData = useAppSelector(getCheckcallLogData);
  const checkcallId = useAppSelector(getReduxCheckcallId);

  useEffect(() => {
    let employeeLog = (
      checkcallLogData.length !== 0 ? checkcallLogData : allCheckcallLog
    )
      .filter((item) => item.id === (checkcallId || cachedCheckcallId))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) // ASC ORDER: DATE A < DATE B
      .map((item, index) => ({
        employeeName: item.employee.name,
        location: item.location.name,
        service: item.service.name,
        checkcallTime: moment(item.date).format("h:mm A"),
        attempt: item.attempt,
        responseStatus:
          item.answer?.toLowerCase() === "yes"
            ? "ok"
            : item.answer?.toLowerCase() === "no"
              ? "not ok"
              : "",
        responseTime: 0,
        verificationMethod: "",
        verificationStatus: "",
        message: item.details,
        status: item.status,
      }));

    setEmployeeCheckcallLogs(employeeLog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkcallLogData, checkcallId]);

  return (
    <>
      {/* {pageLoading && <PageLoader />} */}
      <DashboardHeader active="reports" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton
            marginBottom="0"
            paddingTop="0px"
            to="/reports/checkcall-log"
          />
          <div className={styles.item_container_text}>
            <HorizontalRule borderColor={"#E7E8EB"} />

            <div className={styles.item_heading}>
              <Typography
                textColor={colors.black}
                textSize="24px"
                textWeight="600"
                textAlign="left"
              >
                {employeeCheckcallLogs[0]?.employeeName || "N/A"}
              </Typography>

              {employeeCheckcallLogs[0]?.status === "SENT" ? (
                <Badge state="info" text="Sent" />
              ) : employeeCheckcallLogs[0]?.status === "MISSED" ? (
                <Badge state="unknown" text="Missed" />
              ) : employeeCheckcallLogs[0]?.status === "ANSWERED" &&
                employeeCheckcallLogs.find(
                  (item) => item.responseStatus === "ok",
                ) ? (
                <Badge state="success" text="Answered: YES" />
              ) : employeeCheckcallLogs[0]?.status === "ANSWERED" &&
                employeeCheckcallLogs.find(
                  (item) => item.responseStatus === "not ok",
                ) ? (
                <Badge state="danger" text="Answered: NO" />
              ) : (
                <Badge state="danger" text="Failed" />
              )}

              {/* {employeeCheckcallLogs.find(
                (item) => item.responseStatus === "ok",
              ) ? (
                <Badge state="success" text="Accepted" />
              ) : employeeCheckcallLogs.find((item) => !item.responseStatus) ? (
                <Badge state="unknown" text="Missed" />
              ) : (
                <Badge state="danger" text="Rejected" />
              )} */}
            </div>

            <HorizontalRule borderColor={"#E7E8EB"} />

            <div className={styles.item_container_grid}>
              <div className={styles.item_fullspan}>
                <Typography textAlign="left" textSize="16px" textWeight="600">
                  Checkcall Information
                </Typography>
              </div>

              {employeeCheckcallLogs.map((log, index, array) => (
                <React.Fragment key={index}>
                  <div
                    className={`${styles.item_container_grid} ${styles.item_fullspan}`}
                  >
                    <div>
                      <Typography textAlign="left" textSize="10px">
                        Location
                      </Typography>
                      <Typography
                        textAlign="left"
                        textSize="16px"
                        textWeight="600"
                      >
                        {log.location || "N/A"}
                      </Typography>
                    </div>
                    <div>
                      <Typography textAlign="left" textSize="10px">
                        Service
                      </Typography>
                      <Typography
                        textAlign="left"
                        textSize="16px"
                        textWeight="600"
                      >
                        {log.service || "N/A"}
                      </Typography>
                    </div>
                    <div>
                      <Typography textAlign="left" textSize="10px">
                        Checkcall Time
                      </Typography>
                      <Typography
                        textAlign="left"
                        textSize="16px"
                        textWeight="600"
                      >
                        {log.checkcallTime || "N/A"}
                      </Typography>
                    </div>
                    <div>
                      <Typography textAlign="left" textSize="10px">
                        Attempt
                      </Typography>
                      <Typography
                        textAlign="left"
                        textSize="16px"
                        textWeight="600"
                      >
                        {getNumberPosition(parseInt(log.attempt)) || "N/A"}
                      </Typography>
                    </div>
                    <div>
                      <Typography textAlign="left" textSize="10px">
                        Response Status
                      </Typography>
                      <Badge
                        state={
                          log.responseStatus === "ok" ? "success" : "danger"
                        }
                        paddingHorizontal="2rem"
                        text={log.responseStatus || "N/A"}
                      />
                    </div>
                    <div>
                      <Typography textAlign="left" textSize="10px">
                        Response Time
                      </Typography>
                      <Typography
                        textAlign="left"
                        textSize="16px"
                        textWeight="600"
                      >
                        {log.responseTime || "N/A"}
                      </Typography>
                    </div>
                    <div>
                      <Typography textAlign="left" textSize="10px">
                        Verification Method
                      </Typography>
                      <Typography
                        textAlign="left"
                        textSize="16px"
                        textWeight="600"
                      >
                        {log.verificationMethod || "N/A"}
                      </Typography>
                    </div>
                    <div>
                      <Typography textAlign="left" textSize="10px">
                        Verification Status
                      </Typography>
                      <Badge
                        state={
                          log.verificationStatus === "success"
                            ? "success"
                            : "danger"
                        }
                        paddingHorizontal="2rem"
                        text={log.verificationStatus || "N/A"}
                      />
                    </div>
                    {log.responseStatus === "not ok" && (
                      <div className={styles.item_fullspan}>
                        <Typography textAlign="left" textSize="10px">
                          Message
                        </Typography>
                        <Typography
                          textAlign="left"
                          textSize="16px"
                          textWeight="600"
                        >
                          {log.message || "N/A"}
                        </Typography>
                      </div>
                    )}
                  </div>

                  {index + 1 !== array.length && (
                    <div
                      style={{ padding: "2rem 0" }}
                      className={styles.item_fullspan}
                    >
                      <HorizontalRule borderColor={"#E7E8EB"} />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckcallLogInfo;
