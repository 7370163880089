import React from "react";
import TextInput from "../../../../Form/TextInput";
import { EmployeeMedicalDetailsFormProps } from "../../../../../types-definition/StaffHub/employee";
const EmployeeMedicalDetailsForm: React.FC<EmployeeMedicalDetailsFormProps> = ({
  formData,
  setFormData,
}) => {
  return (
    <>
      <div>
        <TextInput
          value={formData.medicalDetails}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, medicalDetails: data };
            })
          }
          label="Medical Details"
          placeholder="Enter Medical Details"
        />
      </div>
    </>
  );
};

export default EmployeeMedicalDetailsForm;
