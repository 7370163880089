import React from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import colors from "../../../../theme/colors";
import { CheckpointNavigatorProps } from "types-definition/client";
import { useNavigate } from "react-router-dom";

const CheckpointNavigator: React.FC<CheckpointNavigatorProps> = ({
  active,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div
        className={styles.container_item_left}
        style={{
          backgroundColor: active === "general" ? colors.primary3 : "",
        }}
        onClick={() => navigate("/client/checkpoint/general")}
      >
        <Typography
          textWeight={"600"}
          fontFamily="inter"
          textColor={active === "general" ? colors.white : colors.gray3}
          textSize="14px"
        >
          General
        </Typography>
      </div>
      <div
        className={styles.container_item_right}
        onClick={() => navigate("/client/checkpoint/action")}
        style={{
          backgroundColor: active === "action" ? colors.primary3 : "",
        }}
      >
        <Typography
          textWeight={"600"}
          fontFamily="inter"
          textColor={active === "action" ? colors.white : colors.gray3}
          textSize="14px"
        >
          Action
        </Typography>
      </div>
    </div>
  );
};

export default CheckpointNavigator;
