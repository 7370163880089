import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  showDeleteModal: false,
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    updateLocationTableState: (state, action) => {
      state.showDeleteModal = action.payload;
    },
  },
});
export const { updateLocationTableState } = locationSlice.actions;

export const getNewLocationState = (state: RootState) =>
  state.location.showDeleteModal;
export default locationSlice.reducer;
