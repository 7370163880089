import React from "react";
import styles from "./index.module.css";
import { DividerProps } from "../../../types-definition/common";
const Divider: React.FC<DividerProps> = ({ width, color = "#F0F2F5" }) => {
  return (
    <div className={styles.divider} style={{ width, backgroundColor: color }} />
  );
};

export default Divider;
