import React, { useState, useEffect } from "react";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import searchIcon from "assets/svg/search-icon.svg";
import HolidayMultiplierTable from "components/StaffHub/Employee/LinkToLocation/HolidayMultiplier/HolidayMultiplierTable";
import HolidayMultiplierModal from "components/StaffHub/Employee/LinkToLocation/HolidayMultiplier/HolidayMultiplierModal";
import {
  employeeHolidayMultiplierState,
  reloadState,
} from "../../../../redux/slice/staff-hub/employeeHolidayMultiplierSlice";
import { reload } from "../../../../redux/slice/staff-hub/employeeHolidayMultiplierSlice";
import styles from "./index.module.css";
import { useAppSelector } from "../../../../redux/hooks";
import { getRequest } from "api/request";
import { setSettings } from "utils/localstorage";
import toast from "react-hot-toast";
import { GET_EMPLOYEE_HOLIDAY_MULTIPLIER } from "api/urls";
import { getEmployeeId } from "utils/localstorage";
import { HolidayMultiplerData } from "types-definition/admin";
import { useAppDispatch } from "../../../../redux/hooks";
import { getCurrentEmployee } from "utils/localstorage";
import colors from "theme/colors";
import PageBackButton from "components/Common/PageBackButton";
const HolidayMultiplier: React.FC = () => {
  const dispatch = useAppDispatch();
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [search, setSeach] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [holidayMultiplier, setHolidayMultiplier] = useState<
    Array<HolidayMultiplerData>
  >([]);
  const [filterHolidayMultiplier, setFIlterHolidayMultiplier] = useState<
    Array<HolidayMultiplerData>
  >([]);
  const showModal = useAppSelector(employeeHolidayMultiplierState);
  const updateState = useAppSelector(reloadState);

  useEffect(() => {
    const employeeDetails = getCurrentEmployee();

    if (employeeDetails) {
      setEmployeeName(JSON.parse(employeeDetails).displayName);
    }
    const id = getEmployeeId();
    if (id) {
      const getEmployeeHolidayMultiplier = async () => {
        setRequestLoading(true);
        const response = await getRequest(
          `${GET_EMPLOYEE_HOLIDAY_MULTIPLIER}/${id}/holiday-multiplier`,
        );
        if (response?.success) {
          setSettings(response.data.id);
          const responseData: Array<HolidayMultiplerData> = [];
          for (const index in response?.data) {
            const data = {
              id: response.data[index].id,
              holidayDate: response.data[index].holidayDate,
              holidayName: response.data[index].holidayName,
              country: response.data[index].country,
              payRateMultiplier: response.data[index].payRateMultiplier,
              chargeRateMultiplier: response.data[index].chargeRateMultiplier,
              flag: response.data[index].image,
            };
            responseData.push(data);
          }
          setHolidayMultiplier(responseData);
          setFIlterHolidayMultiplier(responseData);
        } else {
          if (response?.data) {
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
          }
        }
        setRequestLoading(false);
      };
      getEmployeeHolidayMultiplier();
    }
    dispatch(reload({ reload: false }));
  }, [updateState, dispatch]);

  return (
    <>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="staff/hub" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton width="90%" paddingTop="020px" marginBottom="15px" />

          <div className={styles.flex_item}>
            <div>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                text="Holiday Multiplier"
                textSize="24px"
              ></Typography>
              <Typography
                textSize="14px"
                textAlign="left"
                fontFamily="inter"
                textWeight="400"
                textColor={colors.gray6}
              >
                {employeeName}
              </Typography>
            </div>

            <div>
              <TextInput
                left={<img src={searchIcon} alt="search" />}
                width={"280px"}
                placeholder="Search"
                value={search}
                setValue={(data) => {
                  setSeach(data);
                  if (data) {
                    setFIlterHolidayMultiplier(
                      holidayMultiplier.filter((prevData) =>
                        prevData.holidayName
                          .toLowerCase()
                          .includes(data.toLowerCase()),
                      ),
                    );
                  } else {
                    setFIlterHolidayMultiplier(holidayMultiplier);
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.table}>
            <HolidayMultiplierTable data={filterHolidayMultiplier} />
          </div>
        </div>
      </div>
      {showModal && <HolidayMultiplierModal />}
    </>
  );
};

export default HolidayMultiplier;
