import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import emailIcon from "../../../../assets/svg/Mail Icon.svg";
import Checkbox from "../../../Form/Checkbox";
import SelectInput from "../../../Form/SelectInput";
import { SiteLocationAddressFormPropsUk } from "../../../../types-definition/client";
import { Country } from "country-state-city";
import validatePostCode from "../../../../utils/validatePostCode";
import EmptySiteLocationAddressFormUk from "../EmptySiteLocationAddressFormUk";
import Button from "../../../Form/Button";
import UkAddressSearchInput from "../../../Form/UkAddressInputSearch";
import { getRequest } from "../../../../api/request";
import { GET_ADDRESS } from "../../../../api/urls";
import { useAppDispatch } from "../../../../redux/hooks";
import { setShowFindAddressDropdown } from "../../../../redux/slice/staff-hub/employeeSlice";
import { getCountry } from "../../../../utils/localstorage";
import getLatitudeAndLongitudeFromPostCode from "../../../../utils/getLatitudeAndLongitudeFromPostCode";

const SiteLocationAddressFormUk: React.FC<SiteLocationAddressFormPropsUk> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  const [address, setAddress] = useState<Array<any>>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const getPostCodeDetails = async () => {
      const response = await getRequest(
        `${GET_ADDRESS}?postcode=${formData.postcode}`,
      );
      setAddress(
        response?.data?.suggestions
          ? response?.data?.suggestions?.map((i: any) => {
              return { name: i.address, id: i.id };
            })
          : [],
      );
    };
    getPostCodeDetails();
  }, [formData.postcode]);
  const getAddressDetails = async (id: string) => {
    const response = await getRequest(`${GET_ADDRESS}/${id}`);
    setFormData((prevData) => {
      return {
        ...prevData,
        address1: response?.data?.line_1,
        address2: response?.data?.line_2,
        city: response?.data?.town_or_city,
        latitude: response?.data?.latitude,
        longitude: response?.data.longitude,
        country: getCountry()!,
        postcode: response?.data?.postcode,
        postcode1: response?.data?.postcode,
        isPostCodeEntered: true,
      };
    });
    setRequiredFormData((prevData) => {
      return {
        ...prevData,
        postcode1: false,
        address1: false,
        country: false,
        city: false,
      };
    });
  };

  useEffect(() => {
    if (formData.postcode1 && !formData.latitude && !formData.longitude) {
      const getData = async () => {
        const result = await getLatitudeAndLongitudeFromPostCode(
          formData.postcode1,
        );
        setFormData((prevData) => {
          return {
            ...prevData,
            latitude: result?.latitude ?? "",
            longitude: result?.longitude ?? "",
          };
        });
      };
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.postcode1]);
  return (
    <>
      {!formData.isPostCodeEntered ? (
        <EmptySiteLocationAddressFormUk
          formData={formData}
          setFormData={setFormData}
        />
      ) : (
        <>
          <div className={styles.postcode_container}>
            <div className={styles.postcode_input_container}>
              <UkAddressSearchInput
                value={formData.postcode}
                setValue={(data, id, address) => {
                  if (id) {
                    getAddressDetails(id);
                  }
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      postcode: data,
                      addressId: id ?? "",
                    };
                  });
                }}
                label="Postcode"
                placeholder="ABC - 1234"
                dropdownTop="45px"
                datalistTop="83px"
                emptyOptionText="Search Postcode"
                options={address}
              />
            </div>
            <div className={styles.postcode_button_container}>
              <Button
                isPrimary={true}
                text="Find Address"
                onClick={() => {
                  dispatch(setShowFindAddressDropdown(true));
                }}
                onBlur={() => {
                  setTimeout(() => {
                    dispatch(setShowFindAddressDropdown(false));
                  }, 300);
                }}
                width="150px"
              />
            </div>
          </div>
          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.address1}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, address1: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, address1: false };
                });
              }}
              label="Address Line 1"
              placeholder="Enter Address"
              required={true}
              error={requiredFormData.address1}
              helperText={
                requiredFormData.address1 ? "Address 1 is Required" : ""
              }
              style={{ marginTop: "10px", marginBottom: "10px" }}
              id="address1"
            />
            <TextInput
              value={formData.address2}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, address2: data };
                })
              }
              label="Address Line 2"
              placeholder="Enter Address"
            />
          </div>
          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.city}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, city: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, city: false };
                });
              }}
              label="City"
              placeholder="Enter City"
              required={true}
              error={requiredFormData.city}
              helperText={requiredFormData.city ? "City is Required" : ""}
              id="city"
            />
            <SelectInput
              value={formData.country}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, country: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, country: false };
                });
              }}
              label="Country"
              placeholder="Select Country"
              required={true}
              options={Country.getAllCountries().map((i) => i.name)}
              error={requiredFormData.country}
              helperText={requiredFormData.country ? "Country is Required" : ""}
              id="country"
            />
          </div>
          <div>
            <TextInput
              value={formData.postcode1}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, postcode1: data };
                });
                const validateInputPostCode = validatePostCode(data);
                if (!validateInputPostCode && data.length > 0) {
                  setRequiredFormData((prevData) => {
                    return { ...prevData, postcode1: true };
                  });
                  return;
                }
                setRequiredFormData((prevData) => {
                  return { ...prevData, postcode1: false };
                });
              }}
              label="Postcode"
              placeholder="Enter Postcode"
              required={true}
              error={requiredFormData.postcode1}
              helperText={
                requiredFormData.postcode1 ? "Postcode is Required" : ""
              }
              id="postcode"
            />
          </div>

          <div className={styles.form_flex_container}>
            <Checkbox
              marginVertical="20px"
              label={
                <div>
                  <Typography
                    textAlign="left"
                    textWeight="600"
                    marginHorizontal="-10px"
                  >
                    Head Office
                  </Typography>
                </div>
              }
              value={formData.headOffice}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, headOffice: data };
                })
              }
            />
          </div>
          <div className={styles.sub_content_container}>
            <Typography textAlign="left" textWeight="600" textSize="24px">
              Client Contact Information
            </Typography>
          </div>
          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.firstName}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, firstName: data };
                })
              }
              label="First Name"
              placeholder="Enter Client First Name"
            />
            <TextInput
              value={formData.lastName}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, lastName: data };
                })
              }
              label="Last Name"
              placeholder="Enter Client Last Name"
            />
          </div>
          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.email}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, email: data };
                })
              }
              label="Email Address"
              placeholder="Enter Email Address"
              right={<img src={emailIcon} alt="icon" width={20} height={20} />}
            />
            <TextInput
              value={formData.mobile}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, mobile: data };
                })
              }
              label="Mobile Number"
              placeholder="Enter Mobile Number"
            />
          </div>
          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.phone}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, phone: data };
                })
              }
              label="Telephone Number"
              placeholder="Enter Telephone Number"
            />
          </div>
        </>
      )}
    </>
  );
};

export default SiteLocationAddressFormUk;
