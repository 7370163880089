import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { getRequest } from "../../../../../api/request";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import colors from "../../../../../theme/colors";
import Typography from "../../../../Common/Typography";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import PageBackButton from "../../../../Common/PageBackButton";

const ActivityEmployeeBankDetailsNonUk = () => {
  const id = getActivityBasicInformation();
  const [bankDetails, setBankDetails] = useState({
    accountNumber: "",
    accountName: "",
    bankName: "",
  });
  const [previousBankDetails, setPreviousBankDetails] = useState({
    accountNumber: "",
    accountName: "",
    bankName: "",
  });
  useEffect(() => {
    const getActivityEmployeeContactInformation = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        setBankDetails({
          accountName: response?.data?.newRecord?.bankDetails?.accountName,
          accountNumber: response?.data?.newRecord?.bankDetails?.accountNumber,
          bankName: response?.data?.newRecord?.bankDetails?.bankName,
        });
        setPreviousBankDetails({
          accountName: response?.data?.previousRecord?.bankDetails?.accountName,
          accountNumber:
            response?.data?.previousRecord?.bankDetails?.accountNumber,
          bankName: response?.data?.previousRecord?.bankDetails?.bankName,
        });
      }
    };
    getActivityEmployeeContactInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Bank Details
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Bank Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {bankDetails.bankName ? bankDetails.bankName : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Account Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {bankDetails.accountNumber ? bankDetails?.accountNumber : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Account Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {bankDetails.accountName ? bankDetails.accountName : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Contact Information */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Bank Details
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Bank Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousBankDetails.bankName
                    ? previousBankDetails.bankName
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Account Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousBankDetails.accountNumber
                    ? previousBankDetails?.accountNumber
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Account Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousBankDetails.accountName
                    ? previousBankDetails.accountName
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityEmployeeBankDetailsNonUk;
