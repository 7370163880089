import React from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import { ClientNavigationProps } from "../../../../types-definition/admin";
import colors from "../../../../theme/colors";
import { useNavigate } from "react-router-dom";

const ClientNavigation: React.FC<ClientNavigationProps> = ({ active }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div
        className={styles.container_item_left}
        onClick={() => navigate("/client/service/add")}
      >
        <Typography
          textWeight={active === "general" ? "600" : "500"}
          fontFamily="inter"
          textColor={active === "general" ? colors.primary3 : colors.black}
        >
          General
        </Typography>
      </div>
      <div
        className={styles.container_item_right}
        onClick={() => navigate("/client/service/financial")}
      >
        <Typography
          textWeight={active === "financial" ? "600" : "500"}
          fontFamily="inter"
          textColor={active === "financial" ? colors.primary3 : colors.black}
        >
          Financial
        </Typography>
      </div>
    </div>
  );
};

export default ClientNavigation;
