import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import EmployeeNavigation from "components/StaffHub/Employee/EmployeeNavigation";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, patchRequest } from "api/request";
import { Country } from "country-state-city";
import { GET_ALL_EMPLOYEE_URL, UPDATE_CONTACT_DETAILS_URL } from "api/urls";
import { useNavigate } from "react-router-dom";
import {
  getCountry,
  getCurrentEmployee,
  setCurrentEmployee,
} from "utils/localstorage";
import axios from "axios";
import {
  EmployeeContactInformationFormData,
  EmployeeRequiredContactInformationFormDataUk,
  EmployeeRequiredContactInformationFormData,
} from "types-definition/StaffHub/employee";
import toast from "react-hot-toast";
import EmployeeContactInfoForm from "components/StaffHub/Employee/ContactInformation/EmployeeContactInfoForm";
import EmployeeContactInfoFormUk from "components/StaffHub/Employee/ContactInformation/EmployeeContactInfoFormUk";
import { UK } from "types-definition/constants";
import validator from "utils/validateFields";
import ScrollToTop from "components/Common/ScrollToTop";
import PageLoader from "components/Common/PageLoader";
import TextInput from "components/Form/TextInput";
import emailIcon from "assets/svg/Mail Icon.svg";
import { toastSuccess } from "utils/toast";
import scrolltoView from "utils/scrollToView";

const EmployeeContactInfo = () => {
  const navigate = useNavigate();
  const country = getCountry();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [currentCountryCode, setCurrentCountryCode] = useState<string>("");
  // const [currentStateCode, setCurrentStateCode] = useState("");
  const [formData, setFormData] = useState<EmployeeContactInformationFormData>({
    address1: "",
    address2: "",
    postcode: "",
    postcode1: "",
    country: "",
    county: "",
    state: "",
    town: "",
    mobileNumber: "",
    email: "",
    zipCode: "",
    isPostCodeEntered: false,
    addressId: "",
    latitude: "",
    longitude: "",
  });
  const [requiredFormDataUk, setRequiredFormDataUk] =
    useState<EmployeeRequiredContactInformationFormDataUk>({
      address1: false,
      postcode1: false,
      country: false,
      town: false,
      mobileNumber: false,
      email: false,
    });
  const [requiredFormData, setRequiredFormData] =
    useState<EmployeeRequiredContactInformationFormData>({
      address1: false,
      country: false,
      state: false,
      town: false,
      mobileNumber: false,
      email: false,
    });

  const [requestLoading, setRequestLoading] = useState(false);
  const [postCodeOptions, setPostCodeOptions] = useState<Array<any>>([]);
  const handleSubmitForm = async () => {
    if (country === UK) {
      const { status, data, id } = validator(formData, requiredFormDataUk);
      if (!status) {
        setRequiredFormDataUk((prevData) => {
          return { ...prevData, ...data };
        });
        if (id) {
          scrolltoView(id);
        }
        toast.error("Fill Required Fields", {
          duration: 4000,
          position: "top-center",
        });
        return;
      }
    } else {
      const { status, data, id } = validator(formData, requiredFormData);
      if (!status) {
        setRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        if (id) {
          scrolltoView(id);
        }
        toast.error("Fill Required Fields", {
          duration: 4000,
          position: "top-center",
        });
        return;
      }
    }

    const ukForm = {
      address1: formData.address1,
      address2: formData.address2,
      postcode: formData.postcode1,
      country: formData.country,
      county: formData.county,
      latitude: formData.latitude.toString(),
      longitude: formData.longitude.toString(),
      town: formData.town,
      mobileNumber: formData.mobileNumber,
      email: formData.email,
    };
    const form = {
      address1: formData.address1,
      address2: formData.address2,
      country: formData.country,
      state: formData.state,
      town: formData.town,
      mobileNumber: formData.mobileNumber,
      email: formData.email,
      zipCode: formData.zipCode,
    };
    setRequestLoading(true);
    if (getCurrentEmployee() === null) {
      toast.error("Employee Record Not Found", {
        duration: 4000,
        position: "top-center",
      });
      navigate("/staff-hub");
      return;
    }
    const data = JSON.parse(getCurrentEmployee()!);
    const response = await patchRequest(
      `${UPDATE_CONTACT_DETAILS_URL}/${data.name}`,
      country === UK ? ukForm : form,
    );
    if (response?.success) {
      setCurrentEmployee(
        JSON.stringify({ name: data.name, country: formData.country }),
      );
      toastSuccess("Employee Contact Information Updated");
      navigate(`/employee/operation`);
    } else {
      toast.error(response.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };
  const handlePostCodeAutoComplete = async (text: string) => {
    if (text) {
      try {
        const response = await axios.get(
          `https://api.postcodes.io/postcodes/${text}/autocomplete`,
        );
        const data = response.data;
        if (data.result !== null) {
          setPostCodeOptions(data.result);
        } else {
          setPostCodeOptions([]);
        }
      } catch (error) {
        setPostCodeOptions([]);
      }
    }
  };
  const handleFormCancelation = () => {
    navigate("/staff-hub");
  };
  // get and prefills country
  useEffect(() => {
    const country = getCountry();
    if (country) {
      setFormData((prevData) => {
        return { ...prevData, country: country };
      });
      const index = Country.getAllCountries().findIndex(
        (data) => data.name === country,
      );
      setCurrentCountryCode(Country.getAllCountries()[index]?.isoCode);
    }
  }, [formData.country]);
  useEffect(() => {
    // prefill data if found
    const getEmployee = async () => {
      try {
        setIsPageLoading(true);
        const data = getCurrentEmployee();
        if (data !== undefined && data !== null) {
          const username = JSON.parse(data).name;
          const response = await getRequest(
            `${GET_ALL_EMPLOYEE_URL}/${username}`,
          );

          if (response?.success) {
            setFormData({
              address1: response.data.contactDetail.address1 ?? "",
              address2: response.data.contactDetail.address2 ?? "",
              country: response.data.contactDetail.country ?? "",
              email: response.data.email ?? "",
              postcode: response.data.contactDetail.postcode ?? "",
              postcode1: response.data.contactDetail.postcode ?? "",
              state: response.data.contactDetail.state ?? "",
              county: response.data.contactDetail.county ?? "",
              mobileNumber: response.data.mobileNumber ?? "",
              town: response.data.contactDetail.town ?? "",
              zipCode: response.data.contactDetail.zipCode ?? "",
              latitude: response.data.contactDetail.latitude ?? "",
              longitude: response.data.contactDetail.longitude ?? "",
              isPostCodeEntered: response.data.contactDetail.postcode
                ? true
                : false,
            });
          } else {
            if (response?.data)
              toast.error(response?.data, {
                duration: 4000,
                position: "top-center",
              });
          }
        }
      } finally {
        setIsPageLoading(false);
      }
    };
    getEmployee();
  }, []);
  return (
    <>
      {isPageLoading && <PageLoader />}
      <section>
        <ScrollToTop />
        <DashboardHeader active="staff/hub" />
        <div className={styles.container}>
          <div className={styles.item_container}>
            <div className={styles.item_container_text}>
              <Typography textAlign="left" textWeight="600" textSize="24px">
                Add Employee
              </Typography>
            </div>
            <EmployeeNavigation active="contact information" />
            <div className={styles.content_container}>
              {country !== UK ? (
                <EmployeeContactInfoForm
                  formData={formData}
                  setFormData={setFormData}
                  requiredFormData={requiredFormData}
                  setRequiredFormData={setRequiredFormData}
                  handlePostCodeAutoComplete={handlePostCodeAutoComplete}
                  postCodeOptions={postCodeOptions}
                  currentCountryCode={currentCountryCode}
                  setCurrentCountryCode={setCurrentCountryCode}
                />
              ) : (
                <EmployeeContactInfoFormUk
                  formData={formData}
                  setFormData={setFormData}
                  handlePostCodeAutoComplete={handlePostCodeAutoComplete}
                  requiredFormData={requiredFormDataUk}
                  setRequiredFormData={setRequiredFormDataUk}
                  postCodeOptions={postCodeOptions}
                  currentCountryCode={currentCountryCode}
                  setCurrentCountryCode={setCurrentCountryCode}
                />
              )}
              {country === UK && formData.isPostCodeEntered && (
                <div className={styles.button_container}>
                  <Button
                    width="200px"
                    text="Cancel"
                    isPrimary={false}
                    onClick={handleFormCancelation}
                    marginHorizontal="10px"
                    backgroundColor={colors.transparent}
                    isPrimaryBorder={true}
                  />
                  <Button
                    width="200px"
                    text="Save"
                    isPrimary={true}
                    onClick={handleSubmitForm}
                    isLoading={requestLoading}
                  />
                </div>
              )}
              {country !== UK && (
                <div className={styles.button_container}>
                  <Button
                    width="200px"
                    text="Cancel"
                    isPrimary={false}
                    onClick={handleFormCancelation}
                    marginHorizontal="10px"
                    backgroundColor={colors.transparent}
                    isPrimaryBorder={true}
                  />
                  <Button
                    width="200px"
                    text="Save"
                    isPrimary={true}
                    onClick={handleSubmitForm}
                    isLoading={requestLoading}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {country === UK && !formData.isPostCodeEntered && (
          <div className={styles.container}>
            <div className={styles.item_container}>
              <div className={styles.form_flex_container}>
                <TextInput
                  value={formData.mobileNumber}
                  setValue={(data) => {
                    setFormData((prevData) => {
                      return { ...prevData, mobileNumber: data };
                    });
                    setRequiredFormData((prevData) => {
                      return { ...prevData, mobileNumber: false };
                    });
                  }}
                  label="Mobile Number"
                  placeholder="Enter Mobile Number"
                  required={true}
                  error={requiredFormData.mobileNumber}
                />
              </div>
              <div className={styles.form_flex_container}>
                <TextInput
                  value={formData.email}
                  setValue={(data) => {
                    setFormData((prevData) => {
                      return { ...prevData, email: data };
                    });
                    setRequiredFormData((prevData) => {
                      return { ...prevData, email: false };
                    });
                  }}
                  label="Email"
                  placeholder="Enter Email"
                  required={true}
                  error={requiredFormData.email}
                  right={
                    <img src={emailIcon} alt="icon" width={20} height={20} />
                  }
                />
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default EmployeeContactInfo;
