import React, { useState, useEffect } from "react";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import searchIcon from "assets/svg/search-icon.svg";
import styles from "./index.module.css";
import PageBackButton from "components/Common/PageBackButton";
import colors from "theme/colors";
import SiteLocationAuditTable from "components/Client/Site Location/SiteLocationAuditTable";
import {
  deleteLocationAudit,
  getLocation,
  getLocationName,
} from "utils/localstorage";
import { getRequest } from "api/request";
import { GET_ACTIVITY_FOR } from "api/urls";
import { toastError } from "utils/toast";
import { SiteLocationAuditProps } from "types-definition/client";
import SiteLocationAuditTableFilter from "components/Client/Site Location/SiteLocationAuditTableFilter";

const SiteLocationAudit: React.FC = () => {
  const locationName = getLocationName();
  const id = getLocation();
  const [siteLocationAuditData, setSiteLocationAuditData] = useState<
    Array<SiteLocationAuditProps>
  >([]);
  const [filteredSiteLocationAuditData, setFilteredSiteLocationAuditData] =
    useState<Array<SiteLocationAuditProps>>([]);
  const [searchedValues, setSearchedValues] = useState("");

  const [sortFilter, setSortFilter] = useState("");
  const [sortFilterName, setSortFilterName] = useState("");

  useEffect(() => {
    if (id) {
      const getLocationActivity = async () => {
        const response = await getRequest(`${GET_ACTIVITY_FOR}/${id}`);
        if (response?.success) {
          let parsedData = response?.data
            .sort(
              (a: any, b: any) =>
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime(),
            )
            .map((item: any) => {
              return {
                category: item?.category,
                changedAt: item?.updatedAt,
                changedBy: item?.user?.name,
                action: item?.action,
                id: item?.id,
                details: item.newRecord,
              };
            });

          setSiteLocationAuditData(parsedData);
          setFilteredSiteLocationAuditData(parsedData);

          deleteLocationAudit();
        } else {
          toastError(response?.data);
        }
      };
      getLocationActivity();
    }
  }, [id]);

  // Filter Logic
  useEffect(() => {
    let internalFilteredData = [...siteLocationAuditData];

    if (searchedValues) {
      internalFilteredData = internalFilteredData.filter(
        (data: SiteLocationAuditProps) => {
          return (
            data.action.toLowerCase().includes(searchedValues.toLowerCase()) ||
            data.category
              .toLowerCase()
              .includes(searchedValues.toLowerCase()) ||
            data.changedBy.toLowerCase().includes(searchedValues.toLowerCase())
          );
        },
      );
    }

    if (sortFilter === "Ascending Order") {
      internalFilteredData.sort(
        (a: SiteLocationAuditProps, b: SiteLocationAuditProps) => {
          if (sortFilterName === "Action") {
            return a.action.localeCompare(b.action);
          } else if (sortFilterName === "Category") {
            return a.category.localeCompare(b.category);
          } else if (sortFilterName === "Changed By") {
            return a.changedBy.localeCompare(b.changedBy);
          } else if (sortFilterName === "Changed At") {
            return (
              new Date(a.changedAt).getTime() - new Date(b.changedAt).getTime()
            );
          } else {
            return 0;
          }
        },
      );
    }

    if (sortFilter === "Descending Order") {
      internalFilteredData.sort(
        (a: SiteLocationAuditProps, b: SiteLocationAuditProps) => {
          if (sortFilterName === "Action") {
            return b.action.localeCompare(a.action);
          } else if (sortFilterName === "Category") {
            return b.category.localeCompare(a.category);
          } else if (sortFilterName === "Changed By") {
            return b.changedBy.localeCompare(a.changedBy);
          } else if (sortFilterName === "Changed At") {
            return (
              new Date(b.changedAt).getTime() - new Date(a.changedAt).getTime()
            );
          } else {
            return 0;
          }
        },
      );
    }

    setFilteredSiteLocationAuditData(internalFilteredData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValues, sortFilter]);

  return (
    <>
      <ScrollToTop />
      <DashboardHeader active="clients" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton
            width="90%"
            marginBottom="-45px"
            paddingTop="30px"
            to="/client/site"
          />
          <div className={styles.flex_item}>
            <div>
              <div className={styles.text_flex_item}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textSize="24px"
                >
                  Audit -&nbsp;
                </Typography>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textSize="24px"
                  textColor={colors.primary3}
                >
                  {locationName}
                </Typography>
              </div>
              <Typography
                textSize="14px"
                textAlign="left"
                fontFamily="inter"
                textWeight="400"
                textColor={colors.gray6}
              >
                Track all actions performed within a location.
              </Typography>
            </div>

            <div className={styles.flex_filter_container}>
              <SiteLocationAuditTableFilter
                runFilter={(data, name) => {
                  setSortFilter(data);
                  setSortFilterName(name);
                }}
                clearFilter={() => setSortFilter("")}
              />
              <TextInput
                left={<img src={searchIcon} alt="search" />}
                width={"280px"}
                placeholder="Search"
                value={searchedValues}
                setValue={(data) => {
                  setSearchedValues(data);
                }}
              />
            </div>
          </div>
          <div className={styles.table}>
            <SiteLocationAuditTable data={filteredSiteLocationAuditData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteLocationAudit;
