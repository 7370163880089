import React, { useState } from "react";
import styles from "./index.module.css";
import dropDownIcon from "assets/svg/select-dropdown.svg";
import minusCircleIcon from "assets/svg/minus-circle-icon.svg";
import Typography from "components/Common/Typography";
import colors from "theme/colors";
import { FilledCheckpointFormProps } from "types-definition/client";
import { useAppDispatch } from "../../../../redux/hooks";
import { removeCheckPoint } from "../../../../redux/slice/client/checkpointSlice";

const FilledCheckpointForm: React.FC<FilledCheckpointFormProps> = ({
  details,
  frequency,
  latitude,
  longitude,
  name,
  time,
  title,
  index,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useAppDispatch();
  return (
    <div className={styles.main_container}>
      <div className={styles.container}>
        <div
          className={styles.item_container}
          onClick={() => setShowDetails((prevData) => !prevData)}
        >
          <Typography textSize="18px" textWeight="600">
            {name ? name : "N/A"}
          </Typography>
          <img
            src={dropDownIcon}
            alt="dropdown"
            className={styles.dropdown_image}
          />
        </div>
        {showDetails && (
          <div className={styles.text_details_container}>
            <div>
              <Typography
                textSize="18px"
                textAlign="left"
                textWeight="600"
                textColor={colors.primary3}
              >
                Longitude
              </Typography>
              <Typography textColor={colors.primary3} textAlign="left">
                {longitude ? longitude : "N/A"}
              </Typography>
            </div>
            <div>
              <Typography
                textSize="18px"
                textAlign="left"
                textWeight="600"
                textColor={colors.primary3}
              >
                Latitude
              </Typography>
              <Typography textAlign="left" textColor={colors.primary3}>
                {latitude ? latitude : "N/A"}
              </Typography>
            </div>
            <div>
              <Typography
                textSize="18px"
                textAlign="left"
                textWeight="600"
                textColor={colors.primary3}
              >
                Time
              </Typography>
              <Typography textAlign="left" textColor={colors.primary3}>
                {time ? time : "N/A"}
              </Typography>
            </div>
            <div>
              <Typography
                textSize="18px"
                textAlign="left"
                textWeight="600"
                textColor={colors.primary3}
              >
                Frequency
              </Typography>
              <Typography textAlign="left" textColor={colors.primary3}>
                {frequency ? frequency : "N/A"}
              </Typography>
            </div>
            <div>
              <Typography
                textSize="18px"
                textAlign="left"
                textWeight="600"
                textColor={colors.primary3}
              >
                Display Title
              </Typography>
              <Typography textAlign="left" textColor={colors.primary3}>
                {title ? title : "N/A"}
              </Typography>
            </div>
            <div>
              <Typography
                textSize="18px"
                textAlign="left"
                textWeight="600"
                textColor={colors.primary3}
              >
                Display Details
              </Typography>
              <Typography textAlign="left" textColor={colors.primary3}>
                {details ? details : "N/A"}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div
        className={styles.flex_item}
        onClick={() => {
          dispatch(removeCheckPoint(index));
        }}
      >
        <img src={minusCircleIcon} alt="dropdown" />
        <Typography
          textSize="12px"
          textWeight="600"
          textColor={colors.primary3}
        >
          Remove Checkpoint
        </Typography>
      </div>
    </div>
  );
};

export default FilledCheckpointForm;
