import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShowIdInterface } from "../../../types-definition/common";
import type { RootState } from "../../store";

// Define the initial state using that type
const initialState = {
  showEditHolidayMultiplierModal: false,
  showAddHolidayMultiplierModal: false,
  showUpdateAllHolidayRateModal: false,
  showEditHolidayRateMultiplierModal: false,
  showDeleteHolidayMultiplierModal: false,
  fetchedHolidayMultiplierCounter: 0,
  currentSelectedId: "",
};

export const holidayMultiplierSlice = createSlice({
  name: "holidayMultiplier",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setShowAddHolidayMultiplierModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showAddHolidayMultiplierModal = action.payload;
    },
    setShowEditHolidayMultiplierModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showEditHolidayMultiplierModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
    },
    setShowEditRateHolidayMultiplierModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showEditHolidayRateMultiplierModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
    },
    setShowUpdateAllHolidayMultiplierModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showUpdateAllHolidayRateModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
    },
    setShowDeleteHolidayMultiplierModal: (
      state,
      action: PayloadAction<ShowIdInterface>,
    ) => {
      state.showDeleteHolidayMultiplierModal = action.payload.show;
      state.currentSelectedId = action.payload.id;
    },
    updateFetchedHolidayMultiplierCounter: (state) => {
      state.fetchedHolidayMultiplierCounter += 1;
    },
  },
});

export const {
  setShowAddHolidayMultiplierModal,
  setShowEditHolidayMultiplierModal,
  setShowEditRateHolidayMultiplierModal,
  setShowUpdateAllHolidayMultiplierModal,
  setShowDeleteHolidayMultiplierModal,
  updateFetchedHolidayMultiplierCounter,
} = holidayMultiplierSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;
// export const IsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const getShowAddHolidayMutiplier = (state: RootState) =>
  state.holidayMultiplier.showAddHolidayMultiplierModal;

export const getShowEditHolidayMutiplier = (state: RootState) =>
  state.holidayMultiplier.showEditHolidayMultiplierModal;

export const getShowUpdateAllHolidayMutiplier = (state: RootState) =>
  state.holidayMultiplier.showUpdateAllHolidayRateModal;

export const getHolidayMutiplierCounter = (state: RootState) =>
  state.holidayMultiplier.fetchedHolidayMultiplierCounter;

export const getShowEditHolidayRateMutiplier = (state: RootState) =>
  state.holidayMultiplier.showEditHolidayRateMultiplierModal;

export const getShowDeleteHolidayMutiplier = (state: RootState) =>
  state.holidayMultiplier.showDeleteHolidayMultiplierModal;

export const getCurrentHolidaySelectedId = (state: RootState) =>
  state.holidayMultiplier.currentSelectedId;

export default holidayMultiplierSlice.reducer;
