import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import EmployeeNavigation from "components/StaffHub/Employee/EmployeeNavigation";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, patchRequest, putRequest } from "api/request";
import {
  GET_ALL_EMPLOYEE_URL,
  UPDATE_BANK_DETAILS_URL,
  UPDATE_HR_URL,
} from "api/urls";
import { getCountry, getCurrentEmployee } from "utils/localstorage";
import { useNavigate } from "react-router-dom";
import {
  EmployeeHrFormData,
  EmployeeRequiredHrFormData,
  EmployeeRequiredHrFormDataUk,
} from "types-definition/StaffHub/employee";
import EmployeeHrForm from "components/StaffHub/Employee/Hr/EmployeeHrForm";
import EmployeeHrFormUk from "components/StaffHub/Employee/Hr/EmployeeHrFormUk";
import EmployeeBankDetailsForm from "components/StaffHub/Employee/BankDetails/EmployeeBankDetailsForm";
import EmployeeBankDetailsFormUk from "components/StaffHub/Employee/BankDetails/EmployeeBankDetailsFormUk";
import toast from "react-hot-toast";
import { NG, UK, US } from "types-definition/constants";
import validator from "utils/validateFields";
import ScrollToTop from "components/Common/ScrollToTop";
import { toastError, toastSuccess } from "utils/toast";
import scrolltoView from "utils/scrollToView";

const EmployeeHr = () => {
  const navigate = useNavigate();
  const country = getCountry();
  const [formData, setFormData] = useState<EmployeeHrFormData>({
    employeeNo: "",
    nin: "",
    tin: "",
    ssn: "",
    payGrade: "",
    gradeId: "",
    payrollGroup: "",
    holidayMethod: "",
    bankName: "",
    accountNumber: "",
    accountName: "",
    sortCode: "",
    personnelRate: "",
    holidayRate: "",
    contractHours: "",
    contractedHoursPeriod: "",
    routingNumber: "",
  });
  const [requiredFormData, setRequiredFormData] =
    useState<EmployeeRequiredHrFormData>({
      payGrade: false,
      payrollGroup: false,
      holidayMethod: false,
    });
  const [requiredFormDataUk, setRequiredFormDataUk] =
    useState<EmployeeRequiredHrFormDataUk>({
      payGrade: false,
      payrollGroup: false,
      contractedHoursPeriod: false,
      personnelRate: false,
      holidayMethod: false,
    });
  const [requestLoading, setRequestLoading] = useState(false);
  const handleSubmitForm = async () => {
    if (country !== NG) {
      const { status, data, id } = validator(formData, requiredFormDataUk);
      if (!status) {
        setRequiredFormDataUk((prevData) => {
          return { ...prevData, ...data };
        });
        if (id) {
          scrolltoView(id);
        }
        toast.error("Fill required fields", {
          duration: 4000,
          position: "top-center",
        });
        return;
      }
    } else {
      const { status, data, id } = validator(formData, requiredFormData);
      if (!status) {
        setRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        if (id) {
          scrolltoView(id);
        }

        toast.error("Fill required fields", {
          duration: 4000,
          position: "top-center",
        });
        return;
      }
    }
    if (getCurrentEmployee() === null) {
      toast.error("Employee Record Not Found", {
        duration: 4000,
        position: "top-center",
      });
      navigate("/staff-hub");
      return;
    }
    setRequestLoading(true);
    const data = JSON.parse(getCurrentEmployee()!);
    const ukHrForm = {
      employeeNumber: formData.employeeNo,
      nin: formData.nin,
      payGrade: formData.payGrade,
      payrollGroup: formData.payrollGroup,
      holidayCalculationMethod:
        formData.holidayMethod === "Entitlement Days"
          ? "entitlement"
          : "accrued",
      personnelRate: formData.personnelRate,
      holidayRate: formData.holidayRate,
      contractHours: formData.contractHours,
      contractedHoursPeriod: formData.contractedHoursPeriod,
    };

    const usHrForm = {
      employeeNumber: formData.employeeNo,
      ssn: formData.ssn,
      payGrade: formData.payGrade,
      payrollGroup: formData.payrollGroup,
      holidayCalculationMethod:
        formData.holidayMethod === "Entitlement Days"
          ? "entitlement"
          : "accrued",
      personnelRate: formData.personnelRate,
      holidayRate: formData.holidayRate,
      contractHours: formData.contractHours,
      contractedHoursPeriod: formData.contractedHoursPeriod,
    };

    const hrForm = {
      employeeNumber: formData.employeeNo,
      tin: formData.tin,
      payGrade: formData.payGrade,
      payrollGroup: formData.payrollGroup,
      holidayCalculationMethod:
        formData.holidayMethod === "Entitlement Days"
          ? "entitlement"
          : "accrued",
    };

    const ukBankDetailsForm = {
      bankName: formData.bankName,
      accountNumber: formData.accountNumber,
      accountName: formData.accountName,
      sortCode: formData.sortCode,
    };

    const usBankDetailsForm = {
      bankName: formData.bankName,
      accountNumber: formData.accountNumber,
      accountName: formData.accountName,
      routingNumber: formData.routingNumber,
    };

    const bankDetailsForm = {
      bankName: formData.bankName,
      accountNumber: formData.accountNumber,
      accountName: formData.accountName,
    };
    let response;
    if (country === UK) {
      response = await putRequest(`${UPDATE_HR_URL}/${data.name}`, ukHrForm);
    } else if (country === US) {
      response = await putRequest(`${UPDATE_HR_URL}/${data.name}`, usHrForm);
    } else {
      response = await putRequest(`${UPDATE_HR_URL}/${data.name}`, hrForm);
    }
    if (response?.success) {
      if (country !== NG) {
        const response = await patchRequest(
          `${UPDATE_BANK_DETAILS_URL}/${data.name}`,
          country === US ? usBankDetailsForm : ukBankDetailsForm,
        );
        if (response?.success) {
          toastSuccess("HR Details Updated");
          navigate(`/employee/emergency-contact`);
        } else {
          toastError(response?.data);
        }
      } else {
        const response = await patchRequest(
          `${UPDATE_BANK_DETAILS_URL}/${data.name}`,
          bankDetailsForm,
        );
        if (response?.success) {
          toastSuccess("HR Details updated");
          navigate(`/employee/emergency-contact`);
        } else {
          toastError(response?.data);
        }
      }
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };
  const handleFormCancelation = () => {
    navigate("/staff-hub");
  };
  useEffect(() => {
    const getEmployee = async () => {
      try {
        const data = getCurrentEmployee();

        if (data !== undefined && data !== null) {
          const username = JSON.parse(data).name;
          const response = await getRequest(
            `${GET_ALL_EMPLOYEE_URL}/${username}`,
          );

          if (response?.success) {
            setFormData({
              accountName: response.data.bankDetail.accountName ?? "",
              accountNumber: response.data.bankDetail.accountNumber ?? "",
              bankName: response.data.bankDetail.bankName ?? "",
              payGrade: response.data.hr.payGrade ?? "",
              gradeId: "",
              payrollGroup: response.data.hr.payrollGroup ?? "",
              employeeNo: response.data.hr.employeeNumber?.toString() ?? "",
              nin: response.data.hr.nin ?? "",
              tin: response.data.hr.tin ?? "",
              ssn: response.data.hr.ssn ?? "",
              contractedHoursPeriod:
                response.data.hr.contractedHoursPeriod ?? "",
              contractHours: response.data.hr.contractHours ?? "",
              holidayRate: response.data.hr.holidayRate ?? "",
              personnelRate: response.data.hr.personnelRate ?? "",
              sortCode: response.data.bankDetail.sortCode ?? "",
              routingNumber: response.data.bankDetail.routingNumber ?? "",
              holidayMethod:
                response.data.hr.holidayCalculationMethod === "entitlement"
                  ? "Entitlement Days"
                  : response.data.hr.holidayCalculationMethod === "accrued"
                    ? "Accrued Holidays"
                    : "",
            });
          } else {
            if (response?.data)
              toast.error(response?.data, {
                duration: 4000,
                position: "top-center",
              });
          }
        }
      } finally {
      }
    };
    getEmployee();
  }, []);
  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="staff/hub" />
      <div className={styles.container}>
        {country !== NG ? (
          <>
            <div className={styles.item_container}>
              <div className={styles.item_container_text}>
                <Typography textAlign="left" textWeight="600" textSize="24px">
                  Add Employee
                </Typography>
              </div>
              <EmployeeNavigation active="hr" />
              <div className={styles.content_container}>
                <EmployeeHrFormUk
                  formData={formData}
                  setFormData={setFormData}
                  requiredFormData={requiredFormDataUk}
                  setRequiredFormData={setRequiredFormDataUk}
                />
              </div>
            </div>
            <div className={styles.sub_item_container}>
              <div className={styles.sub_content_container}>
                <Typography textAlign="left" textWeight="600" textSize="24px">
                  Bank Details
                </Typography>
                <EmployeeBankDetailsFormUk
                  formData={formData}
                  setFormData={setFormData}
                  requiredFormData={requiredFormData}
                  setRequiredFormData={setRequiredFormData}
                />
                <div className={styles.button_container}>
                  <Button
                    width="200px"
                    text="Cancel"
                    isPrimary={false}
                    onClick={handleFormCancelation}
                    marginHorizontal="10px"
                    backgroundColor={colors.transparent}
                    isPrimaryBorder={true}
                  />
                  <Button
                    width="200px"
                    text="Save"
                    isPrimary={true}
                    onClick={handleSubmitForm}
                    isLoading={requestLoading}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.item_container}>
              <div className={styles.item_container_text}>
                <Typography textAlign="left" textWeight="600" textSize="24px">
                  Add Employee
                </Typography>
              </div>
              <EmployeeNavigation active="hr" />
              <div className={styles.sub_content_container}>
                <EmployeeHrForm
                  formData={formData}
                  setFormData={setFormData}
                  requiredFormData={requiredFormData}
                  setRequiredFormData={setRequiredFormData}
                />
              </div>
            </div>
            <div className={styles.sub_item_container}>
              <div className={styles.sub_content_container}>
                <Typography textAlign="left" textWeight="600" textSize="24px">
                  Bank Details
                </Typography>
                <EmployeeBankDetailsForm
                  formData={formData}
                  setFormData={setFormData}
                  requiredFormData={requiredFormData}
                  setRequiredFormData={setRequiredFormData}
                />
                <div className={styles.button_container}>
                  <Button
                    width="200px"
                    text="Cancel"
                    isPrimary={false}
                    onClick={handleFormCancelation}
                    marginHorizontal="10px"
                    backgroundColor={colors.transparent}
                    isPrimaryBorder={true}
                  />
                  <Button
                    width="200px"
                    text="Save"
                    isPrimary={true}
                    onClick={handleSubmitForm}
                    isLoading={requestLoading}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default EmployeeHr;
