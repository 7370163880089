import React from "react";
import { LinkedToLocationPayOptionProps } from "../../../types-definition/StaffHub/employee/linkToLocation";
import styles from "./index.module.css";
const LinkedToLocationPayOption: React.FC<LinkedToLocationPayOptionProps> = ({
  payOption,
}) => {
  return (
    <>
      {payOption === "customPay" && (
        <p className={`${styles.pay} ${styles.custom_pay}`}>Custom Pay</p>
      )}
      {payOption === "personalPay" && (
        <p className={`${styles.personal_pay} ${styles.pay}`}>Personal Pay</p>
      )}
      {payOption === "servicePay" && (
        <p className={`${styles.service_pay} ${styles.pay}`}>Service Pay</p>
      )}
    </>
  );
};

export default LinkedToLocationPayOption;
