import { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import Button from "components/Form/Button";
import searchIcon from "assets/svg/search-icon.svg";
import colors from "theme/colors";
import addIcon from "assets/svg/add-icon.svg";
import { useNavigate } from "react-router-dom";
import EmptyItemState from "components/Common/EmptyItemState";
import ClientTable from "components/Client/Client/ClientTable";
import { getRequest } from "api/request";
import { GET_ALL_CLIENTS_URL, GET_LOCATION_URL } from "api/urls";
import { ClientData } from "types-definition/client";
import PageLoader from "components/Common/PageLoader";
import { deleteClient, deleteClientName } from "utils/localstorage";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { useAppSelector } from "../../../../redux/hooks";
import {
  getClientCounter,
  getClientDeleteModal,
} from "../../../../redux/slice/client/clientSlice";
import ClientTableFilter from "components/Client/Client/ClientTableFilter";
import DeleteClientModal from "components/Client/Client/DeleteClientModal";

const ClientDashbaord = () => {
  const navigate = useNavigate();
  const showDeleteModal = useAppSelector(getClientDeleteModal);
  const [clientData, setClientData] = useState<Array<ClientData>>([]);
  const [filteredClientData, setFilteredClientData] = useState<
    Array<ClientData>
  >([]);
  const [sortFilter, setSortFilter] = useState("");
  const [sortFilterName, setSortFilterName] = useState("");
  const [isfilter, setIsfilter] = useState(false);
  const [searchedValues, setSearchedValues] = useState("");
  const [requestLoading, setRequestLoading] = useState(true);
  const counter = useAppSelector(getClientCounter);

  useEffect(() => {
    const getAllClient = async () => {
      try {
        const response = await getRequest(GET_ALL_CLIENTS_URL);
        const locationResponse = await getRequest(GET_LOCATION_URL);
        if (response?.success && locationResponse?.success) {
          const clientDataArray: Array<ClientData> = [];
          for (const index in response.data.clients) {
            // extract client table data
            const employeeDataObject = {
              id: response.data.clients[index].id,
              name: response.data.clients[index].clientName,
              code: response.data.clients[index].clientCode,
              description: response.data.clients[index].description,
              // total number should be updated
              locations: locationResponse?.data.filter(
                (item: any) =>
                  item.clientId === response.data.clients[index].id,
              ).length,
            };
            clientDataArray.push(employeeDataObject);
          }
          setClientData(clientDataArray);
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      } finally {
        setRequestLoading(false);
      }
    };
    getAllClient();
    // Deletes any selected client
    deleteClient();
    deleteClientName();
  }, [counter]);

  // Filter Effect Logic
  useEffect(() => {
    if (sortFilter && searchedValues) {
      const localClientData = [...clientData];
      setIsfilter(true);
      if (sortFilter === "Ascending Order") {
        setFilteredClientData(
          localClientData
            .sort((a: ClientData, b: ClientData) => {
              if (sortFilterName === "Name") {
                return a.name.localeCompare(b.name);
              } else if (sortFilterName === "Code") {
                return a.code.localeCompare(b.code);
              } else {
                return parseInt(a.locations) - parseInt(b.locations);
              }
            })
            .filter((data: ClientData) => {
              return (
                data.name
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.description
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.code.toLowerCase().includes(searchedValues.toLowerCase())
              );
            }),
        );
      } else {
        setFilteredClientData(
          localClientData
            .sort((a: ClientData, b: ClientData) => {
              if (sortFilterName === "Name") {
                return b.name.localeCompare(a.name);
              } else if (sortFilterName === "Code") {
                return b.code.localeCompare(a.code);
              } else {
                return parseInt(b.locations) - parseInt(a.locations);
              }
            })
            .filter((data: ClientData) => {
              return (
                data.name
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.description
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.code.toLowerCase().includes(searchedValues.toLowerCase())
              );
            }),
        );
      }
    } else if (searchedValues) {
      setIsfilter(true);
      setFilteredClientData(
        clientData.filter((data: ClientData) => {
          return (
            data.name.toLowerCase().includes(searchedValues.toLowerCase()) ||
            data.description
              .toLowerCase()
              .includes(searchedValues.toLowerCase()) ||
            data.code.toLowerCase().includes(searchedValues.toLowerCase())
          );
        }),
      );
    } else if (sortFilter) {
      setIsfilter(true);
      if (sortFilter === "Ascending Order") {
        const localClientData = [...clientData];
        setFilteredClientData(
          localClientData.sort((a: ClientData, b: ClientData) => {
            if (sortFilterName === "Name") {
              return a.name.localeCompare(b.name);
            } else if (sortFilterName === "Code") {
              return a.code.localeCompare(b.code);
            } else {
              return parseInt(a.locations) - parseInt(b.locations);
            }
          }),
        );
      } else {
        const localClientData = [...clientData];
        setFilteredClientData(
          localClientData.sort((a: ClientData, b: ClientData) => {
            if (sortFilterName === "Name") {
              return b.name.localeCompare(a.name);
            } else if (sortFilterName === "Code") {
              return b.code.localeCompare(a.code);
            } else {
              return parseInt(b.locations) - parseInt(a.locations);
            }
          }),
        );
      }
    } else {
      setIsfilter(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValues, sortFilter]);

  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="clients" />
        <div className={styles.container}>
          <div
            className={
              clientData.length <= 5
                ? `${styles.item_container} ${styles.item_container_empty}`
                : `${styles.item_container} ${styles.item_container_fill}`
            }
          >
            <div className={styles.flex_item}>
              <div>
                <Typography textAlign="left" textWeight="600" textSize="20px">
                  Client Management
                </Typography>
                <Typography textAlign="left" textColor={colors.gray1}>
                  Add your employee to properly manage them
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <ClientTableFilter
                  clearFilter={() => setSortFilter("")}
                  runFilter={(data, name) => {
                    setSortFilter(data);
                    setSortFilterName(name);
                  }}
                />
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={searchedValues}
                  setValue={(value) => {
                    setSearchedValues(value);
                  }}
                />
                <Button
                  left={<img src={addIcon} alt="add" />}
                  marginHorizontal="10px"
                  marginTop="8px"
                  paddingVertical="7px"
                  width="200px"
                  isPrimary
                  text="Add Client"
                  onClick={() => navigate("/client/add")}
                />
              </div>
            </div>
            {clientData.length === 0 ? (
              <EmptyItemState
                text="You haven't added any client yet"
                location="client/add"
                label="Add Client"
              />
            ) : (
              <ClientTable data={isfilter ? filteredClientData : clientData} />
            )}
          </div>
        </div>
      </section>
      {showDeleteModal && <DeleteClientModal />}
    </>
  );
};

export default ClientDashbaord;
