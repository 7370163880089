import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import { WeekData } from "../../../types-definition/common";
import colors from "../../../theme/colors";
import { useAppSelector } from "../../../redux/hooks";
import { RosterMonthlyViewProps } from "../../../types-definition/roster";
import {
  getRosterFromDate,
  getRosterServiceData,
} from "../../../redux/slice/roster/rosterSlice";

const RosterMonthView: React.FC<RosterMonthlyViewProps> = () => {
  const data = useAppSelector(getRosterServiceData);
  const rosterDate = useAppSelector(getRosterFromDate);
  const date = new Date(useAppSelector(getRosterFromDate));
  const year = date.getFullYear();
  const month = date.getMonth();
  const [firstWeekData, setfirstWeekData] = useState<Array<WeekData>>([]);
  const [secondWeekData, setsecondWeekData] = useState<Array<WeekData>>([]);
  const [thirdWeekData, setthirdWeekData] = useState<Array<WeekData>>([]);
  const [fourthWeekData, setfourthWeekData] = useState<Array<WeekData>>([]);
  const [fifthWeekData, setfifthWeekData] = useState<Array<WeekData>>([]);
  const [sixthWeekData, setsixthWeekData] = useState<Array<WeekData>>([]);

  // Calendar Logic
  useEffect(() => {
    // Get the first day of the month
    const dayone = new Date(year, month, 1).getDay();

    // Get the last date of the month
    const lastdate = new Date(year, month + 1, 0).getDate();

    // Get the day of the last date of the month
    const dayend = new Date(year, month, lastdate).getDay();

    // Get the last date of the previous month
    const monthlastdate = new Date(year, month, 0).getDate();
    if (dayone === 0) {
      const firstweek: WeekData[] = [];
      for (let i = 0; i <= 6; i++) {
        if (i < 6) {
          firstweek.push({
            // text: monthlastdate - (monthlastdate + 6 - i - 1),
            text: monthlastdate - (6 - i),
            active: false,
            date: "",
          });
        } else {
          firstweek.push({
            text: 1,
            active: true,
            date: new Date(year, month),
          });
        }
      }
      setfirstWeekData(firstweek);

      const secondweek: WeekData[] = [];
      for (let i = 2; i <= 8; i++) {
        secondweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setsecondWeekData(secondweek);
      const thirdweek: WeekData[] = [];
      for (let i = 9; i <= 15; i++) {
        thirdweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setthirdWeekData(thirdweek);
      const fourthweek: WeekData[] = [];
      for (let i = 16; i <= 22; i++) {
        fourthweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setfourthWeekData(fourthweek);
      const fifthweek: WeekData[] = [];
      for (let i = 23; i <= 29; i++) {
        fifthweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setfifthWeekData(fifthweek);
      const sixthweek: WeekData[] = [];
      for (let i = 30; i <= 36; i++) {
        if (i > lastdate) {
          sixthweek.push({
            text: i - lastdate,
            active: false,
            date: "",
          });
        } else {
          sixthweek.push({
            text: i,
            active: true,
            date: new Date(year, month, i),
          });
        }
      }
      setsixthWeekData(sixthweek);
    } else {
      const firstweek: WeekData[] = [];
      for (let i = 1; i <= 7; i++) {
        if (i < dayone) {
          firstweek.push({
            text: monthlastdate - (dayone - i - 1),
            active: false,
            date: "",
          });
        } else {
          firstweek.push({
            text: i - dayone + 1,
            active: true,
            date: new Date(year, month, i - dayone + 1),
          });
        }
      }
      setfirstWeekData(firstweek);

      const secondweek: WeekData[] = [];
      for (let i = 9 - dayone; i <= 15 - dayone; i++) {
        secondweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setsecondWeekData(secondweek);
      const thirdweek: WeekData[] = [];
      for (let i = 16 - dayone; i <= 22 - dayone; i++) {
        thirdweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setthirdWeekData(thirdweek);
      const fourthweek: WeekData[] = [];
      for (let i = 23 - dayone; i <= 29 - dayone; i++) {
        fourthweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setfourthWeekData(fourthweek);
      const fifthweek: WeekData[] = [];
      if (dayend === 0) {
        for (let i = 30 - dayone; i <= 36 - dayone; i++) {
          fifthweek.push({
            text: i,
            active: true,
            date: new Date(year, month, i),
          });
        }
        setfifthWeekData(fifthweek);
      } else {
        if (dayone === 0 || (dayone === 6 && lastdate === 31)) {
          for (let i = 30 - dayone; i <= 36 - dayone; i++) {
            if (i > lastdate) {
              fifthweek.push({
                text: i - lastdate,
                active: false,
                date: "",
              });
            } else {
              fifthweek.push({
                text: i,
                active: true,
                date: new Date(year, month, i),
              });
            }
          }
          setfifthWeekData(fifthweek);
          const sixthweek: WeekData[] = [];
          for (let i = 37 - dayone; i <= 43 - dayone; i++) {
            if (i > lastdate) {
              sixthweek.push({ text: i - lastdate, active: false, date: "" });
            } else {
              sixthweek.push({
                text: i,
                active: true,
                date: new Date(year, month, i),
              });
            }
          }
          setsixthWeekData(sixthweek);
        } else {
          if (lastdate - dayone + 7 - (30 - dayone) < 6) {
            for (let i = 30 - dayone; i <= lastdate - dayone + 2; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          }
          if (lastdate - dayone + 7 - (30 - dayone) < 7) {
            for (let i = 30 - dayone; i < lastdate - dayone + 8; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          } else if (lastdate - dayone + 7 - (30 - dayone) > 7) {
            for (let i = 30 - dayone; i < lastdate - dayone + 6; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          } else {
            for (let i = 30 - dayone; i < lastdate - dayone + 7; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          }
          setfifthWeekData(fifthweek);
          setsixthWeekData([]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rosterDate]);
  return (
    <div className={styles.container}>
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tr}>
              <th className={styles.th}>
                <Typography textWeight="600">Monday</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Tuesday</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Wednesday</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Thursday</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Friday</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Saturday</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Sunday</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {firstWeekData.map((item, index) => {
                const date = data.filter((i: any) => {
                  return (
                    new Date(i.date).getFullYear() ===
                      new Date(item.date).getFullYear() &&
                    new Date(i.date).getMonth() ===
                      new Date(item.date).getMonth() &&
                    new Date(i.date).getDate() === new Date(item.date).getDate()
                  );
                });
                return (
                  <td className={styles.td} key={index}>
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>
                    {date.map((item: any, index: number) => {
                      return (
                        <>
                          {index < 5 && (
                            <div
                              className={styles.active_container}
                              style={{ bottom: `${15 * index}px` }}
                            ></div>
                          )}
                        </>
                      );
                    })}
                  </td>
                );
              })}
            </tr>
            <tr>
              {secondWeekData.map((item, index) => {
                const date = data.filter((i: any) => {
                  return (
                    new Date(i.date).getFullYear() ===
                      new Date(item.date).getFullYear() &&
                    new Date(i.date).getMonth() ===
                      new Date(item.date).getMonth() &&
                    new Date(i.date).getDate() === new Date(item.date).getDate()
                  );
                });
                return (
                  <td className={styles.td} key={index}>
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>
                    {date.map((item: any, index: number) => {
                      return (
                        <>
                          {index < 5 && (
                            <div
                              className={styles.active_container}
                              style={{ bottom: `${15 * index}px` }}
                            ></div>
                          )}
                        </>
                      );
                    })}
                  </td>
                );
              })}
            </tr>
            <tr>
              {thirdWeekData.map((item, index) => {
                const date = data.filter((i: any) => {
                  return (
                    new Date(i.date).getFullYear() ===
                      new Date(item.date).getFullYear() &&
                    new Date(i.date).getMonth() ===
                      new Date(item.date).getMonth() &&
                    new Date(i.date).getDate() === new Date(item.date).getDate()
                  );
                });
                return (
                  <td className={styles.td} key={index}>
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>
                    {date.map((item: any, index: number) => {
                      return (
                        <>
                          {index < 5 && (
                            <div
                              className={styles.active_container}
                              style={{ bottom: `${15 * index}px` }}
                            ></div>
                          )}
                        </>
                      );
                    })}
                  </td>
                );
              })}
            </tr>
            <tr>
              {fourthWeekData.map((item, index) => {
                const date = data.filter((i: any) => {
                  return (
                    new Date(i.date).getFullYear() ===
                      new Date(item.date).getFullYear() &&
                    new Date(i.date).getMonth() ===
                      new Date(item.date).getMonth() &&
                    new Date(i.date).getDate() === new Date(item.date).getDate()
                  );
                });
                return (
                  <td className={styles.td} key={index}>
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>
                    {date.map((item: any, index: number) => {
                      return (
                        <>
                          {index < 5 && (
                            <div
                              className={styles.active_container}
                              style={{ bottom: `${15 * index}px` }}
                            ></div>
                          )}
                        </>
                      );
                    })}
                  </td>
                );
              })}
            </tr>
            <tr>
              {fifthWeekData.map((item, index) => {
                const date = data.filter((i: any) => {
                  return (
                    new Date(i.date).getFullYear() ===
                      new Date(item.date).getFullYear() &&
                    new Date(i.date).getMonth() ===
                      new Date(item.date).getMonth() &&
                    new Date(i.date).getDate() === new Date(item.date).getDate()
                  );
                });
                return (
                  <td className={styles.td} key={index}>
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>

                    {date.map((item: any, index: number) => {
                      return (
                        <>
                          {index < 5 && (
                            <div
                              className={styles.active_container}
                              style={{ bottom: `${15 * index}px` }}
                            ></div>
                          )}
                        </>
                      );
                    })}
                  </td>
                );
              })}
            </tr>
            <tr>
              {sixthWeekData.map((item, index) => {
                const date = data.filter((i: any) => {
                  return (
                    new Date(i.date).getFullYear() ===
                      new Date(item.date).getFullYear() &&
                    new Date(i.date).getMonth() ===
                      new Date(item.date).getMonth() &&
                    new Date(i.date).getDate() === new Date(item.date).getDate()
                  );
                });
                return (
                  <td className={styles.td} key={index}>
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>
                    {date.map((item: any, index: number) => {
                      return (
                        <>
                          {index < 5 && (
                            <div
                              className={styles.active_container}
                              style={{ bottom: `${15 * index}px` }}
                            ></div>
                          )}
                        </>
                      );
                    })}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RosterMonthView;
