import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import successIcon from "../../../assets/svg/success-modal-icon.svg";
import {
  handleShowSkipPermissionModal,
  showWizardModal,
} from "redux/slice/WizardSetup";
import { updateSetupComplete } from "utils/setupWizard";
import { useDispatch } from "react-redux";
const SkipPermissionGroup: React.FC = ({}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(false);
  const handleSubmit = async () => {
    setIsloading(true);
    const setUpComplete = await updateSetupComplete(
      "3",
      false,
      "permissiongroup",
    );
    if (!setUpComplete) {
      setIsloading(false);
      dispatch(
        handleShowSkipPermissionModal({ showSkipPermissionModal: false }),
      );
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <img src={successIcon} alt="" />
        <Typography textSize="16px" textWeight="600" textAlign="left">
          Skip Permission group
        </Typography>
        <Typography
          textSize="14px"
          textWeight="500"
          textAlign="left"
          textColor="#7E8494"
          marginVertical="14px"
        >
          Skipping the Permission group would add the system's default
          permission groups which can be updated later.
        </Typography>
        <Button
          text="Continue"
          isPrimary
          onClick={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default SkipPermissionGroup;
