import ScrollToTop from "components/Common/ScrollToTop";
import SignUpHeader from "components/Common/SignUpHeader";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import TextInput from "components/Form/TextInput";
import React, { useState } from "react";
import size from "theme/size";
import styles from "./index.module.css";
import { useAppDispatch } from "redux/hooks";
import { setShowRequirePasscode } from "redux/slice/auth/authSlice";
import { toastError, toastSuccess } from "utils/toast";
import { setShowPasscode } from "utils/localstorage";

const RequirePasscode: React.FC = () => {
  const [accessCode, setAccessCode] = useState<string>("");

  const dispatch = useAppDispatch();

  const handleFormSubmission = async () => {
    if (accessCode === "GLX-77891-24") {
      toastSuccess("Access code correct");
      setShowPasscode(false);
      dispatch(setShowRequirePasscode(false));
      return;
    }
    return toastError("Wrong access code!");
  };

  return (
    <>
      <ScrollToTop />
      <main>
        <SignUpHeader showLogin={false} />
        <section className={styles.container}>
          <div className={styles.body_container}>
            <Typography
              fontFamily="inter"
              textAlign="center"
              textSize={size.lg}
              textWeight="600"
            >
              Access GLIX
            </Typography>
            <Typography
              textAlign="center"
              fontFamily="inter"
              textSize={size.md}
            >
              To access Glix, please input correct Access Code
            </Typography>
            <div className={styles.form_container}>
              <TextInput
                placeholder="Access code"
                value={accessCode}
                setValue={(value) => setAccessCode(value)}
              />
              <Button
                text="Access Glix"
                isPrimary={true}
                isLoading={false}
                onClick={() => {
                  handleFormSubmission();
                }}
              />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default RequirePasscode;
