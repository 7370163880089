import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

interface initialStateInterface {
  requestLoading: boolean;
  numberOfCheckPointReasons: number;
  manualCheckPointReasonsCounter: number;
  checkPointReasons: Array<{
    id?: string;
    editing?: boolean;
    reasons?: string;
    description?: string;
    placeholderReason?: string;
    placeholderDescription?: string;
  }>;
}

const initialState: initialStateInterface = {
  numberOfCheckPointReasons: 0,
  checkPointReasons: [],
  requestLoading: false,
  manualCheckPointReasonsCounter: 0,
};

export const manualCheckPointReasonSlice = createSlice({
  name: "manualCheckPointReasons",
  initialState,
  reducers: {
    setNumberOfCheckPointReasons: (state, action) => {
      state.numberOfCheckPointReasons =
        action.payload.numberOfCheckPointReasons;
    },
    updateCheckPointReasons: (
      state,
      action: PayloadAction<{
        index: number;
        reason?: string;
        description?: string;
        id?: string;
        editing?: boolean;
      }>,
    ) => {
      state.checkPointReasons[action.payload.index] = {
        reasons: action.payload.reason,
        description: action.payload.description,
        id: action.payload.id,
        editing: action.payload.editing,
      };
    },

    addNewCheckPointReasons: (state) => {
      state.checkPointReasons.push({ reasons: "", description: "" });
    },
    addNewCheckPointReasonsWithValues: (
      state,
      action: PayloadAction<{
        id?: string;
        reason?: string;
        description?: string;
        placeholderReason?: string;
        placeholderDescription?: string;
      }>,
    ) => {
      state.checkPointReasons.push({
        id: action.payload.id ?? "",
        reasons: action.payload.reason,
        description: action.payload.description,
        placeholderReason: action.payload.placeholderReason,
        placeholderDescription: action.payload.placeholderDescription,
      });
    },
    deleteCheckPointReasons: (state, action: PayloadAction<number>) => {
      state.checkPointReasons.splice(action.payload, 1);
    },
    clearCheckPointReasons: (state) => {
      state.checkPointReasons = [];
    },
    setManualCheckPointReasonRequestLoading: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.requestLoading = action.payload;
    },
    updateCheckPointReasonsCounter: (state) => {
      state.manualCheckPointReasonsCounter += 1;
    },
  },
});
export const {
  setNumberOfCheckPointReasons,
  updateCheckPointReasons,
  addNewCheckPointReasons,
  addNewCheckPointReasonsWithValues,
  deleteCheckPointReasons,
  clearCheckPointReasons,
  setManualCheckPointReasonRequestLoading,
  updateCheckPointReasonsCounter,
} = manualCheckPointReasonSlice.actions;

export const getNumberOfCheckPointReasons = (state: RootState) =>
  state.manualCheckPointReasons.numberOfCheckPointReasons;

export const getCheckPointReasons = (state: RootState) =>
  state.manualCheckPointReasons.checkPointReasons;

export const getManualCheckPointReasonRequestLoading = (state: RootState) =>
  state.manualCheckPointReasons.requestLoading;

export const getManualCheckPointReasonsCounter = (state: RootState) =>
  state.manualCheckPointReasons.manualCheckPointReasonsCounter;

export default manualCheckPointReasonSlice.reducer;
