import React, { useRef, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import filterIcon from "../../../../assets/svg/filter-icon.svg";
import chevronDownIcon from "../../../../assets/svg/chevron-down.svg";
import colors from "../../../../theme/colors";
import { SiteLocationAuditFilterProps } from "types-definition/client";

const SiteLocationAuditTableFilter: React.FC<SiteLocationAuditFilterProps> = ({
  clearFilter,
  runFilter,
}) => {
  const [currentFilterOption, setcurrentFilterOption] = useState("Filter");
  const [showDropDown, setShowDropDown] = useState(false);
  const [showSubDropDown, setShowSubDropDown] = useState(true);
  const [filterOptions, setFilterOptions] = useState("");
  const FILTEROPTIONS = {
    // "Category" | "Action" | "Service" | "Changed At"
    CATEGORY: { name: "Category", top: "70px" },
    ACTION: { name: "Action", top: "120px" },
    CHANGEDBY: { name: "Changed By", top: "180px" },
    CHANGED_AT: { name: "Changed At", top: "230px" },
  };
  const hideAllDropDown = () => {
    // containerRef.current?.blur();
    setFilterOptions("");
    setShowDropDown(false);
    setShowSubDropDown(false);
  };
  const clearFilterData = () => {
    setcurrentFilterOption("Filter");
  };
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.main}>
      <div
        className={styles.main_container}
        onFocus={() => {
          setShowDropDown(true);
          setShowSubDropDown(true);
        }}
        ref={containerRef}
        onClick={(e) => {
          // e.preventDefault();
          // containerRef.current?
          if (document.activeElement === containerRef.current) {
            containerRef.current?.focus();
          } else {
            containerRef.current?.blur();
            // containerRef.current?.focus();
          }
        }}
        tabIndex={0}
        onBlur={(e) => {
          const event = e;
          requestAnimationFrame(() => {
            if (!event.currentTarget?.contains(document.activeElement)) {
              setShowDropDown(false);
              setShowSubDropDown(false);
            }
          });
        }}
        // tabIndex={1}
      >
        <div className={styles.container}>
          <img src={filterIcon} alt="filter icon" />
          <Typography textColor={colors.gray1}>
            {currentFilterOption}
          </Typography>
        </div>
        {showDropDown && (
          <div className={styles.dropdown_container}>
            {Object.entries(FILTEROPTIONS).map(([name, options], index) => (
              <div
                key={index}
                className={`${
                  options.name === filterOptions
                    ? `${styles.sub_dropdown_item} ${styles.sub_dropdown_item_active}`
                    : styles.sub_dropdown_item
                }`}
                onClick={() => {
                  setFilterOptions(options.name);
                  setShowSubDropDown(true);
                }}
              >
                <Typography>{options.name}</Typography>
                <img
                  src={chevronDownIcon}
                  alt="chevron-down"
                  className={styles.dropdown_image_item}
                />
              </div>
            ))}
          </div>
        )}
        {showSubDropDown && (
          <div>
            {Object.entries(FILTEROPTIONS).map(
              ([name, options], index) =>
                filterOptions === options.name && (
                  <div
                    key={index}
                    className={styles.sub_dropdown_container}
                    style={{ top: options.top }}
                  >
                    <div
                      className={styles.sub_dropdown_item}
                      onClick={() => {
                        setcurrentFilterOption("Ascending Order");
                        runFilter("Ascending Order", options.name as any);
                        hideAllDropDown();
                        containerRef.current?.blur();
                      }}
                    >
                      <Typography>Ascending Order</Typography>
                    </div>
                    <div
                      className={styles.sub_dropdown_item}
                      onClick={() => {
                        setcurrentFilterOption("Descending Order");
                        runFilter("Descending Order", options.name as any);
                        hideAllDropDown();
                        containerRef.current?.blur();
                      }}
                    >
                      <Typography>Descending Order</Typography>
                    </div>
                  </div>
                ),
            )}
          </div>
        )}
      </div>
      {currentFilterOption !== "Filter" && (
        <div
          className={styles.clear_filter}
          onClick={() => {
            clearFilter();
            clearFilterData();
          }}
        >
          <Typography
            textColor={colors.primary3}
            textWeight="600"
            textSize="12px"
          >
            Clear Filters
          </Typography>
        </div>
      )}
    </div>
  );
};

export default SiteLocationAuditTableFilter;
