import React from "react";
import Typography from "../../Common/Typography";
import styles from "./index.module.css";
import { CheckboxProps } from "../../../types-definition/form";
import colors from "../../../theme/colors";
import toolTipIcon from "../../../assets/svg/tool-tip-input.svg";

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  width = "100%",
  checkboxWidth = 20,
  value,
  setValue,
  marginVertical,
  border = false,
  disabled = false,
  isToolTip = false,
  toolTipText,
  toolTipBottom = "-55px",
}) => {
  return (
    <div
      className={
        border ? `${styles.container} ${styles.border}` : styles.container
      }
      style={{
        width,
        marginTop: marginVertical,
        marginBottom: marginVertical,
        padding: border ? "10px" : "0",
      }}
    >
      <input
        type="checkbox"
        onChange={(e) => setValue(e.target.checked)}
        checked={value}
        style={{
          width: checkboxWidth,
          height: checkboxWidth,
          borderColor: colors.gray1,
          marginRight: "20px",
          accentColor: colors.primary3,
        }}
        disabled={disabled}
      />
      {isToolTip ? (
        <div className={styles.tooltip_container}>
          <div className={styles.label_container}>{label}</div>
          <div className={styles.tooltip_icon}>
            <img src={toolTipIcon} alt="tooltip" />
          </div>
        </div>
      ) : (
        <div className={styles.label_container}>{label}</div>
      )}
      {isToolTip && (
        <div
          className={styles.tooltip_text_container}
          style={{ bottom: toolTipBottom }}
        >
          <div className={styles.tooltip_text}>
            <Typography textAlign="left" textSize="12px" textWeight="600">
              {toolTipText}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkbox;
