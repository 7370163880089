import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../Common/PageBackButton";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../utils/localstorage";
import { getRequest } from "../../../../api/request";
import PageLoader from "../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../api/urls";
import { ClientActivityLogsProps } from "../../../../types-definition/admin";
import DashboardHeader from "../../../Common/DashboardHeader";
import { toastError } from "utils/toast";
const ActivityClient = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentClient, setCurrentClient] = useState<ClientActivityLogsProps>({
    client: "",
    description: "",
    active: null,
  });
  const [previousClient, setPreviousClient] = useState<ClientActivityLogsProps>(
    {
      client: "",
      description: "",
      active: null,
    },
  );
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getClient = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data;
        const previousData = response.data;
        if (response.data.action === "POST") {
          setCurrentClient({
            client: data?.newRecord?.client?.clientName,
            description: data?.newRecord?.client?.description,
            active: data?.newRecord?.client?.active,
          });

          setPreviousClient({
            client: previousData?.previousRecord?.client?.clientName,
            description: previousData?.previousRecord?.client?.description,
            active: previousData?.previousRecord?.client?.active,
          });
        } else {
          setCurrentClient({
            client: data?.newRecord?.client?.body?.clientName,
            description: data?.newRecord?.client?.body?.description,
            active: data?.newRecord?.client?.body?.active,
          });
          setPreviousClient({
            client: previousData?.previousRecord?.client?.clientName,
            description: previousData?.previousRecord?.client?.description,
            active: previousData?.previousRecord?.client?.active,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getClient();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Client
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Client</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentClient.client ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Description</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentClient.description ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Active</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentClient?.active === true ||
                    (currentClient?.active === "true" && "True")}
                  {currentClient?.active === false ||
                    (currentClient?.active === "false" && "False")}
                  {currentClient?.active === null && "--"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Client
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Client</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousClient.client ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Description</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousClient.description ?? "--"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Active</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousClient?.active === true ||
                    (previousClient?.active === "true" && "True")}
                  {previousClient?.active === false ||
                    (previousClient?.active === "false" && "False")}
                  {previousClient?.active === null && "--"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityClient;
