import React from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import { HolidayAbsenceStatusProps } from "../../../../types-definition/StaffHub/holiday";
import colors from "../../../../theme/colors";

const HolidayAbsenceStatus: React.FC<HolidayAbsenceStatusProps> = ({
  status,
}) => {
  return (
    <>
      {status === "Completed" && (
        <div
          className={styles.container}
          style={{
            backgroundColor: colors.successBg,
          }}
        >
          <Typography
            textAlign="left"
            textWeight="600"
            textColor={colors.successtext}
          >
            {status}
          </Typography>
        </div>
      )}
      {status === "Requested" && (
        <div
          className={styles.container}
          style={{
            backgroundColor: "#FEF6E7",
          }}
        >
          <Typography textAlign="left" textWeight="600" textColor="#865503">
            {status}
          </Typography>
        </div>
      )}
      {status === "Rejected" && (
        <div
          className={styles.container}
          style={{
            backgroundColor: colors.errorBg,
          }}
        >
          <Typography
            textAlign="left"
            textWeight="600"
            textColor={colors.error}
          >
            {status}
          </Typography>
        </div>
      )}
      {status === "In-Progress" && (
        <div
          className={styles.container}
          style={{
            backgroundColor: "#E3EFFC",
          }}
        >
          <Typography textAlign="left" textWeight="600" textColor="#04326B">
            {status}
          </Typography>
        </div>
      )}
      {status === "Approved" && (
        <div
          className={styles.container}
          style={{
            backgroundColor: colors.successBg,
          }}
        >
          <Typography
            textAlign="left"
            textWeight="600"
            textColor={colors.successtext}
          >
            {status}
          </Typography>
        </div>
      )}
    </>
  );
};

export default HolidayAbsenceStatus;
