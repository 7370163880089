import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  showTimeShiftGenerationModal: false,
  updateState: false,
  delete: false,
};

export const timeShiftgenerationSlice = createSlice({
  name: "timeShiftGeneration",
  initialState,
  reducers: {
    updateTimeShiftGenerationModal: (state, action) => {
      state.showTimeShiftGenerationModal =
        action.payload.showTimeShiftGenerationModal;
    },
    updateStateHandler: (state, action) => {
      state.updateState = action.payload.updateState;
    },
    deleteHandler: (state, action) => {
      state.delete = action.payload.delete;
    },
  },
});

export const {
  updateTimeShiftGenerationModal,
  updateStateHandler,
  deleteHandler,
} = timeShiftgenerationSlice.actions;
export const showModal = (state: RootState) =>
  state.timeShiftGeneration.showTimeShiftGenerationModal;
export const getUpdatedState = (state: RootState) =>
  state.timeShiftGeneration.updateState;
export const getDeleteState = (state: RootState) =>
  state.timeShiftGeneration.delete;
export default timeShiftgenerationSlice.reducer;
