import React from "react";
import Typography from "../../../../Common/Typography";
import styles from "./index.module.css";
import colors from "../../../../../theme/colors";
import LinkedToLocationTableItem from "../LinkedToLocationTableItem";
import { LinkedToLocationTableData } from "../../../../../types-definition/StaffHub/employee/linkToLocation";

const LinkedToLocationTable: React.FC<LinkedToLocationTableData> = ({
  data,
}) => {
  return (
    <>
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.tr} ${styles.tr_head}`}>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  marginHorizontal="24px"
                >
                  Location
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                  marginHorizontal="24px"
                >
                  Distance (miles)
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                  marginHorizontal="24px"
                >
                  Pay
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                  marginHorizontal="24px"
                >
                  Assign
                </Typography>
              </th>
              <th className={styles.th_restriction}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                  marginHorizontal="24px"
                >
                  Restrictions
                </Typography>
              </th>
              <th className={styles.th}></th>
              <th className={styles.th_index}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <LinkedToLocationTableItem {...item} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LinkedToLocationTable;
