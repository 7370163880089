import React, { useState, useEffect } from "react";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import searchIcon from "assets/svg/search-icon.svg";
import styles from "./index.module.css";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TableFilter from "components/Common/TableFilter";
import Button from "components/Form/Button";
import MessageEmployeeTable from "components/StaffHub/Employee/Message/MessageEmployeeTable";
import MessageDeliveryModeModal from "components/StaffHub/Employee/Message/MessageDeliveryModeModal";
import {
  getSelectedEmployees,
  getShowSelectDeliveryModeModal,
  getShowSendMessageModal,
  setMessageEmployeeEntryMode,
  setShowSelectDeliveryModeModal,
} from "../../../../redux/slice/staff-hub/employeeMessageSlice";
import SendMessageModal from "components/StaffHub/Employee/Message/SendMessageModal";
import { getRequest } from "api/request";
import { toastError } from "utils/toast";
import { GET_ALL_EMPLOYEE_URL } from "api/urls";
import { MessageEmployeeProps } from "types-definition/StaffHub/employee";

const EmployeeMessage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [search, setSeach] = useState("");
  const [employeeMessage, setEmployeeMessage] = useState<
    Array<MessageEmployeeProps>
  >([]);
  const selectedEmployees = useAppSelector(getSelectedEmployees);
  const showMessageModeModal = useAppSelector(getShowSelectDeliveryModeModal);
  const showSendMessageModal = useAppSelector(getShowSendMessageModal);
  const handleMessageEmployee = () => {
    if (selectedEmployees.length === 0) {
      toastError("Please select an employee");
      return;
    } else {
      dispatch(setMessageEmployeeEntryMode("group"));
      dispatch(setShowSelectDeliveryModeModal(true));
    }
  };
  useEffect(() => {
    const getAllEmployee = async () => {
      setRequestLoading(true);
      const response = await getRequest(`${GET_ALL_EMPLOYEE_URL}`);
      if (response?.success) {
        setEmployeeMessage(
          response?.data.map((i: any) => {
            return {
              client: "Gyzer Techechnologies",
              grade: i?.grade?.grade ?? "N/A",
              id: i.id,
              displayId: i.hr?.employeeNumber,
              lastSeen: i.lastSeen,
              location: i?.contactDetail.town,
              name: `${i.firstName} ${i.lastName}`,
            };
          }),
        );
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
      setRequestLoading(false);
    };
    getAllEmployee();
  }, []);

  return (
    <>
      {showSendMessageModal && <SendMessageModal />}
      {showMessageModeModal && <MessageDeliveryModeModal />}
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="staff/hub" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.flex_item}>
            <div>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                text="Holiday Multiplier"
                textSize="24px"
              >
                Message Employee
              </Typography>
            </div>

            <div className={styles.flex_filter_container}>
              <TableFilter />
              <TextInput
                left={<img src={searchIcon} alt="search" />}
                width={"280px"}
                placeholder="Search"
                value={search}
                setValue={(data) => {
                  setSeach(data);
                }}
              />
              <Button
                isPrimary
                onClick={() => {
                  handleMessageEmployee();
                }}
                isPrimaryBorder={false}
                text="Send Message"
                paddingVertical="8px"
                marginTop="4px"
              />
            </div>
          </div>
          <div className={styles.table}>
            <MessageEmployeeTable data={employeeMessage} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeMessage;
