import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../Common/PageBackButton";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../utils/localstorage";
import { getRequest } from "../../../../api/request";
import PageLoader from "../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../api/urls";
import { AreaActivityLogsprops } from "../../../../types-definition/admin";
import DashboardHeader from "../../../Common/DashboardHeader";
import { toastError } from "utils/toast";

const ActivityAreaTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentArea, setCurrentArea] = useState<AreaActivityLogsprops>({
    areaName: "",
    description: "",
  });
  const [previousArea, setPreviousArea] = useState<AreaActivityLogsprops>({
    areaName: "",
    description: "",
  });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getAreaData = async () => {
      // get employee data
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data.newRecord;
        const previousData = response.data.previousRecord;
        if (response.data.action === "POST") {
          setCurrentArea({
            areaName: data?.area?.areaName,
            description: data?.area?.description,
          });
          setPreviousArea({
            areaName: previousData?.area?.areaName,
            description: previousData?.area?.description,
          });
        } else {
          setCurrentArea({
            areaName: data?.area?.areaName,
            description: data?.area?.description,
          });
          setPreviousArea({
            areaName: previousData?.area?.areaName,
            description: previousData?.area?.description,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getAreaData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Area
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Area Name </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentArea.areaName ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Description</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentArea?.description ?? "--"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Area
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Area Name </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousArea.areaName ?? "--"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Description</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousArea?.description ?? "--"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityAreaTab;
