import React, { useEffect, useState } from "react";
import { SelectInputProps } from "../../../types-definition/form";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import dropDownIcon from "../../../assets/svg/select-dropdown.svg";
import colors from "../../../theme/colors";

const AddressSearchInput: React.FC<SelectInputProps> = ({
  options = [],
  label,
  placeholder,
  value,
  setValue,
  height = "200px",
  width,
  required = false,
  error = false,
  datalistTop = "91px",
  dropdownTop = "55px",
  helperText,
  emptyOptionText,
  style,
  id,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [list, setList] = useState<Array<string>>([]);
  const handleListSelector = (item: string) => {
    setValue({ value: item, action: true });
    setIsSelected(true);
    setShowDropDown(false);
  };
  const filterListFromString = (input: string) => {
    setValue({ value: input, action: false });
    setIsSelected(false);
  };
  useEffect(() => {
    if (!isSelected && !showDropDown) {
      setValue({ value: "", action: false });
      setList(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, showDropDown]);
  useEffect(() => {
    // if (options.l)
    setList(options);
  }, [options]);
  return (
    <section className={styles.container} style={style} id={id}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        className={styles.select}
        onChange={(e) => {
          if (!showDropDown) {
            setShowDropDown(true);
          }
          filterListFromString(e.target.value);
        }}
        onFocus={() => setShowDropDown(true)}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 400);
        }}
        style={{ width, borderColor: error ? colors.error : colors.gray3 }}
      />

      <div className={styles.helpertext_container}>
        <Typography
          textAlign="left"
          textSize={"10px"}
          textWeight="600"
          textColor={error ? colors.error : colors.primary3}
        >
          {helperText}
        </Typography>
      </div>
      <div
        className={styles.dropdown_container}
        style={{ top: dropdownTop }}
        tabIndex={0}
        onClick={() => setShowDropDown(true)}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 500);
        }}
      >
        <img
          src={dropDownIcon}
          className={styles.dropdown}
          alt="dropdown icon"
        />
      </div>
      {showDropDown && (
        <div
          className={styles.datalist}
          style={{ maxHeight: height, top: datalistTop }}
        >
          {list.length === 0 ? (
            <p>{emptyOptionText ?? "Type to search Address"}</p>
          ) : (
            list.map((item) => (
              <p onClick={() => handleListSelector(item)} key={item}>
                {item}
              </p>
            ))
          )}
        </div>
      )}
    </section>
  );
};

export default AddressSearchInput;
