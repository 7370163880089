import React from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import { getCountry } from "utils/localstorage";
import { UK } from "types-definition/constants";
import ActivityEmployeeContactInformationUk from "components/Admin/Activity Logs/EmployeeActivityLogs/ActivityEmployeeContactInfoUk";
import ActivityEmployeeContactInformationNonUk from "components/Admin/Activity Logs/EmployeeActivityLogs/ActivityEmployeeContactInfoNonUk";

const ActivityEmployeeContactInformation = () => {
  const country = getCountry();

  return (
    <div className={styles.main_container}>
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      {country === UK ? (
        <ActivityEmployeeContactInformationUk />
      ) : (
        <ActivityEmployeeContactInformationNonUk />
      )}
    </div>
  );
};

export default ActivityEmployeeContactInformation;
