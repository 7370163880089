import React from "react";
import colors from "../../../../theme/colors";
import Typography from "../../../Common/Typography";
import CheckInsTableItem from "../CheckInsTableItem";
import styles from "./index.module.css";

const CheckInsTable: React.FC<{
  checkInsTime: Array<string>,
  data: Array<any>;
}> = ({ checkInsTime, data = [] }) => {

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={`${styles.th} ${styles.th_sticky}`}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Employee
              </Typography>
            </th>
            <th className={`${styles.th} ${styles.th_sticky}`}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Grade
              </Typography>
            </th>
            <th className={`${styles.th} ${styles.th_sticky}`}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Shift Date
              </Typography>
            </th>
            {checkInsTime.map((time, index) => (
              <th className={styles.th} key={index}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  CheckIn {index + 1}
                  <br />
                  <small style={{ fontWeight: "normal" }}>{time}</small>
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: number) => {
            return (
              <CheckInsTableItem
                {...item}
                key={item.id}
                checkInsTime={checkInsTime}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CheckInsTable;
