import React from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import { EmployeeTableProps } from "../../../../../types-definition/StaffHub/employee";
import colors from "../../../../../theme/colors";
import EmployeeTableItem from "../EmployeeTableItem";

const EmployeeTable: React.FC<EmployeeTableProps> = ({ data = [] }) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Employee Name
              </Typography>
            </th>
            <th className={styles.th_employee_no}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Employee Number
              </Typography>
            </th>
            <th className={styles.th_id}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Grade
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Postcode
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Town
              </Typography>
            </th>
            <th className={styles.th_status}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Status
              </Typography>
            </th>
            <th className={styles._index}></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return <EmployeeTableItem {...item} key={item.id} index={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTable;
