import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest } from "api/request";
import ClientNavigation from "components/Client/Service/ServiceNavigation";
import { putRequest } from "api/request";
import { GET_SERVICE_URL } from "api/urls";
import { useNavigate } from "react-router-dom";
import { getService, getUserTypes } from "utils/localstorage";
import toast from "react-hot-toast";
import validator from "utils/validateFields";
import ScrollToTop from "components/Common/ScrollToTop";
import FinancialForm from "components/Client/Service/PayInfo";
import InvoiceForm from "components/Client/Service/InvioceInfo";
import {
  PayRollFormData,
  InvioceRequiredFormData,
} from "types-definition/client";
import {
  ServiceInvoiceFormData,
  PayRollRequiredFormData,
  InvioceWithTrueFormData,
} from "types-definition/client";
import { InvioceWithTrueRequiredData } from "types-definition/client";
import { deleteService } from "utils/localstorage";
import { toastError, toastSuccess } from "utils/toast";
import scrolltoView from "utils/scrollToView";
import { updateSetupComplete } from "utils/setupWizard";
import { formatCurrency } from "utils/currencyFormatter";

const ServiceFinacial = () => {
  const [payrollId, setPayRollId] = useState("");
  const [invioceId, setInvioceId] = useState("");
  // const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [requestInvioceLoading, setRequestInvioceLoading] =
    useState<boolean>(false);
  const [payRollFormData, setpayRollFormData] = useState<PayRollFormData>({
    payRate: "",
    minimumPaidHours: "",
    breakTimeDuration: "30",
    breakTimePaid: false,
    includeinPayroll: true,
  });
  const [payrollRequiredFormData, setPayrollRequiredFormData] =
    useState<PayRollRequiredFormData>({
      payRate: false,
      minimumPaidHours: false,
      breakTimeDuration: false,
    });

  const [invioceRequiredFormData, setInvioceRequiredFormData] =
    useState<InvioceRequiredFormData>({
      chargeRate: false,
      minimumChargeHours: false,
      fixedCostCharge: false,
    });
  const [invioceWithTrueRequiredFormData, setInvioceWithTrueRequiredFormData] =
    useState<InvioceWithTrueRequiredData>({
      fixedCostCharge: false,
    });

  const [invioceFormData, setinvioceFormData] =
    useState<ServiceInvoiceFormData>({
      fixedCost: false,
      breakTimeCharged: false,
      includeinInvoice: true,
      minimumChargeHours: "",
      chargeRate: "",
    });

  const [invioceFormWithTrue, setInvioceFormWithTrue] =
    useState<InvioceWithTrueFormData>({
      fixedCost: true,
      fixedCostCharge: "",
      includeinInvoice: false,
    });

  const checkInvioceForm = () => {
    const { status, data, id } = !invioceFormData.fixedCost
      ? validator(invioceFormData, invioceRequiredFormData)
      : validator(invioceFormWithTrue, invioceWithTrueRequiredFormData);
    if (!status) {
      setInvioceRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      setInvioceWithTrueRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      if (id) {
        scrolltoView(id);
      }
      return status;
    } else {
      return status;
    }
  };

  const handleSubmitInvioceForm = async () => {
    try {
      setRequestInvioceLoading(true);
      let response;
      if (!invioceFormData.fixedCost) {
        response = await putRequest(
          `${GET_SERVICE_URL}/invoice-info/${invioceId}`,
          {
            fixedCost: invioceFormData.fixedCost,
            chargeRate: invioceFormData.chargeRate?.replace(/,/g, ""),
            minChargeHours: invioceFormData.minimumChargeHours,
            breakTimeCharged: invioceFormData.breakTimeCharged,
            includeInInvoice: invioceFormData.includeinInvoice,
          },
        );
      } else {
        response = await putRequest(
          `${GET_SERVICE_URL}/invoice-info/${invioceId}`,
          {
            fixedCost: invioceFormData.fixedCost,
            fixedCostCharge: invioceFormWithTrue.fixedCostCharge?.replace(
              /,/g,
              "",
            ),
            includeInInvoice: invioceFormData.includeinInvoice,
          },
        );
      }
      if (response?.success) {
        setRequestInvioceLoading(false);
        navigate("/client/service");
        deleteService();
        return;
      } else {
        toast.error(response?.data, {
          duration: 4000,
          position: "top-center",
        });
      }
    } finally {
    }
  };

  const handleSubmitPayRollForm = async () => {
    const checkInvioce = checkInvioceForm();
    const { status, data, id } = validator(
      payRollFormData,
      payrollRequiredFormData,
    );
    if (!status || !checkInvioce) {
      setPayrollRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      if (id) {
        scrolltoView(id);
      }
      toast.error("Complete all required fields before saving", {
        duration: 4000,
        position: "top-center",
      });
      return;
    } else {
      try {
        // setRequestLoading(true);
        handleSubmitInvioceForm();
        const response = await putRequest(
          `${GET_SERVICE_URL}/pay-info/${payrollId}`,
          {
            payRate: payRollFormData.payRate?.replace(/,/g, ""),
            minPaidHours: payRollFormData.minimumPaidHours,
            breakTimeDuration: payRollFormData.breakTimeDuration,
            breakTimePaid: payRollFormData.breakTimePaid,
            includeInPayroll: payRollFormData.includeinPayroll,
          },
        );
        if (response?.success) {
          toastSuccess("Service Added Successfully");
          // setRequestLoading(false);
          const setUpComplete = await updateSetupComplete(
            "13",
            false,
            "service",
          );

          const userType = getUserTypes();
          if (!setUpComplete && userType !== "Web User") {
            navigate("/setup-wizard");
          } else {
            navigate("/client/service");
          }
          return;
        } else {
          toastError(response?.data);
        }
      } finally {
      }
    }
  };

  const handleInvioceFormCancelation = () => {
    navigate("/client/service");
    deleteService();
  };

  useEffect(() => {
    const serviceId = getService();
    const handleGetService = async () => {
      try {
        const serviceResponse = await getRequest(
          `${GET_SERVICE_URL}/${serviceId}`,
        );
        if (!serviceResponse.success) {
          toast.error("Create a service", {
            duration: 4000,
            position: "top-center",
          });
          navigate("/client/service/add");
        } else {
          setInvioceId(serviceResponse.data.invoiceInfo.id);
          setPayRollId(serviceResponse.data.payInfo.id);
          const payrollResponse = await getRequest(
            `${GET_SERVICE_URL}/pay-info/${serviceResponse.data.payInfo.id}`,
          );
          if (payrollResponse.success) {
            setpayRollFormData({
              payRate: payrollResponse.data.payRate
                ? formatCurrency(parseFloat(payrollResponse.data.payRate))
                : "",
              minimumPaidHours: payrollResponse.data.minPaidHours || "",
              breakTimeDuration: payrollResponse.data.breakTimeDuration || "30",
              breakTimePaid: payrollResponse.data.breakTimePaid || false,
              includeinPayroll: payrollResponse.data.includeInPayroll || true,
            });
          } else {
          }
          const invioceResponse = await getRequest(
            `${GET_SERVICE_URL}/invoice-info/${serviceResponse.data.invoiceInfo.id}`,
          );
          if (invioceResponse.success) {
            setinvioceFormData({
              fixedCost: invioceResponse.data.fixedCost || false,
              breakTimeCharged: invioceResponse.data.breakTimeCharged || false,
              includeinInvoice: invioceResponse.data.includeInInvoice || true,
              minimumChargeHours: invioceResponse.data.minChargeHours || "",
              chargeRate: invioceResponse.data.chargeRate
                ? formatCurrency(parseFloat(invioceResponse.data.chargeRate))
                : "",
            });
            setInvioceFormWithTrue({
              fixedCostCharge: invioceResponse.data.fixedCostCharge
                ? formatCurrency(
                    parseFloat(invioceResponse.data.fixedCostCharge),
                  )
                : "",
              includeinInvoice: invioceResponse.data.includeInInvoice || false,
              fixedCost: invioceResponse.data.fixedCost,
            });
          }
          if (serviceResponse.success) {
          } else {
            toast.error(serviceResponse?.data, {
              duration: 4000,
              position: "top-center",
            });
          }
        }
      } finally {
      }
    };
    handleGetService();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="clients" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.content_container}>
            <Typography
              textAlign="left"
              textWeight="600"
              textSize="24px"
              marginVertical="16px"
            >
              Add Service
            </Typography>
            <div style={{ marginBottom: "24px" }}>
              <ClientNavigation active="financial" />
            </div>
            <Typography
              textAlign="left"
              textWeight="600"
              textSize="24px"
              marginVertical="4px"
            >
              Pay Info
            </Typography>
            <FinancialForm
              setpayRollFormData={setpayRollFormData}
              payRollFormData={payRollFormData}
              setPayrollRequiredFormData={setPayrollRequiredFormData}
              payrollRequiredFormData={payrollRequiredFormData}
            />
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.content_container}>
            <Typography
              textAlign="left"
              textWeight="600"
              textSize="24px"
              marginVertical="4px"
            >
              Invoice info
            </Typography>
            <InvoiceForm
              setinvioceFormData={setinvioceFormData}
              invioceFormData={invioceFormData}
              setInvioceRequiredFormData={setInvioceRequiredFormData}
              InvioceRequiredFormData={invioceRequiredFormData}
              invoiceWithTrue={invioceFormWithTrue}
              setInvioceWithTrue={setInvioceFormWithTrue}
              setInvioceWithTrueRequiredFormData={
                setInvioceWithTrueRequiredFormData
              }
              invioceWithTrueRequiredFormData={invioceWithTrueRequiredFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Cancel"
                isPrimary={false}
                onClick={handleInvioceFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={true}
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={() => {
                  handleSubmitPayRollForm();
                }}
                isLoading={requestInvioceLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceFinacial;
