import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  areaCounter: 0,
  showAreaDeletemodal: false,
  currentSelectedId: "",
};

export const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    updateAreaCounter: (state) => {
      state.areaCounter += 1;
    },
    setShowAreaDeletemodal: (state, action) => {
      state.showAreaDeletemodal = action.payload;
    },
    setCurrentAreaSelectedId: (state, action) => {
      state.currentSelectedId = action.payload;
    },
  },
});

export const {
  setShowAreaDeletemodal,
  updateAreaCounter,
  setCurrentAreaSelectedId,
} = areaSlice.actions;
export const getAreaCounter = (state: RootState) => state.area.areaCounter;
export const getShowAreaDeleteModal = (state: RootState) =>
  state.area.showAreaDeletemodal;
export const getCurrentAreaSelectedId = (state: RootState) =>
  state.area.currentSelectedId;
export default areaSlice.reducer;
