import React, { useEffect, useState } from "react";
import questionMarkIcon from "../../../../../assets/svg/question-mark-icon.svg";
import NumberInput from "../../../../Form/NumberInput";
import { EmployeeOperationFormProps } from "../../../../../types-definition/StaffHub/employee";
import { getRequest } from "../../../../../api/request";
import { GET_GRADE_URL } from "../../../../../api/urls";
import { toastError } from "../../../../../utils/toast";
import ClientSelectInput from "components/Form/ClientSelectInput";

const EmployeeOperationForm: React.FC<EmployeeOperationFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  const [grades, setGrades] = useState([]);
  useEffect(() => {
    const getAllGrades = async () => {
      const response = await getRequest(GET_GRADE_URL);
      if (response?.success) {
        setGrades(
          response.data.map((data: any) => {
            return { name: data.grade, id: data.id };
          }),
        );
      } else {
        toastError(response?.data);
      }
    };
    getAllGrades();
  }, []);
  return (
    <>
      <div>
        <ClientSelectInput
          value={formData.grade}
          client={formData.grade}
          dropdownTop="48px"
          datalistTop="84px"
          readonly
          setValue={(name, id) => {
            if (name && id) {
              setFormData((prevData) => {
                return { ...prevData, grade: name, gradeId: id };
              });
              setRequiredFormData((prevData) => {
                return { ...prevData, grade: false };
              });
            }
          }}
          label="Grade"
          placeholder="Select Grade"
          required={true}
          error={requiredFormData.grade}
          helperText={requiredFormData.grade ? "Grade is required" : ""}
          options={[...grades]}
        />
      </div>
    </>
  );
};

export default EmployeeOperationForm;
