const validateInsuranceNumber = (insuranceNumber: string) => {
  if (parseInt(insuranceNumber[0])) {
    return {
      error: true,
      message: "Insurance number must start with a letter",
    };
  }
  if (parseInt(insuranceNumber[1])) {
    return {
      error: true,
      message: "Insurance number must start with two letters",
    };
  }
  if (insuranceNumber.length !== 9) {
    return {
      error: true,
      message: "Insurance number must be 9 characters long",
    };
  }
  if (parseInt(insuranceNumber[insuranceNumber.length - 1])) {
    return {
      error: true,
      message: "Insurance number must end with a letter",
    };
  }
  return {
    error: false,
    message: "",
  };
};

export default validateInsuranceNumber;
