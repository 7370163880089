import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import cancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

import AvailablePermissionItem from "../../Permission Group/AvailablePermissionItem";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../../api/request";
import { GET_SERVICE_GROUP_URL, GET_SERVICE_URL } from "../../../../api/urls";
import AssignedPermissionItem from "../../Permission Group/AssignedPermissionItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  getCurrentSelectedId,
  setShowLinkServicesModal,
} from "../../../../redux/slice/client/serviceGroupSlice";
import { toastError } from "../../../../utils/toast";

const LinkServices: React.FC = () => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(getCurrentSelectedId);
  const [availableServices, setAvailableServices] = useState<Array<any>>([]);
  const [assignedServices, setAssignedServices] = useState<Array<any>>([]);
  const [monitorChanges, setMonitorChanges] = useState(0);
  const handleAssignNewService = async (serviceId: string) => {
    const response = await postRequest(
      `service/${serviceId}/service-group/${id}`,
    );
    if (response?.success) {
      // setAssignedServices([...response.data.locations]);
      // setAvailableServices((prevData) => {
      //   return prevData.filter((item) => {
      //     return response.data.locations.every(
      //       (i: any) => i.assignedLocationId.toString() !== item.id.toString(),
      //     );
      //   });
      // });
      await getAllServices();
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };
  const handleUnassignService = async (serviceId: string) => {
    const response = await deleteRequest(`service/un-assign/${serviceId}`);
    if (response?.success) {
      await getAllServices();
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };

  const getUsersServices = async (data: any) => {
    const response = await getRequest(
      `${GET_SERVICE_GROUP_URL}/${id}/assigned-services`,
    );
    if (response?.success) {
      setAssignedServices(response?.data);
      setAvailableServices(
        data.filter((item: any) => {
          return response?.data.every((i: any) => i.serviceId !== item.id);
        }),
      );
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };

  const getAllServices = async () => {
    const response = await getRequest(GET_SERVICE_URL);
    if (response?.success) {
      // setAvailableLocations(response?.data);
      await getUsersServices(response?.data);
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };

  useEffect(() => {
    getAllServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Drag and Drop
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }

    if (result.destination.droppableId === "2") {
      const itemIndex = result.draggableId.split("-")[2];
      const data = [...availableServices].filter(
        (item, index) => index !== parseInt(itemIndex.toString()),
      );
      setAvailableServices(data);
      handleAssignNewService(result.source.index);
      return;
    }
    if (result.destination.droppableId === "1") {
      const itemIndex = result.draggableId.split("-")[2];
      const data = [...assignedServices].filter(
        (item, index) => index !== parseInt(itemIndex.toString()),
      );
      setAssignedServices(data);
      handleUnassignService(result.source.index);
      return;
    }
  };
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      dragHandleUsageInstructions="Use the spacebar to lift / drop the draggable item."
    >
      <div className={styles.container}>
        <div className={styles.content_container}>
          <div className={styles.header}>
            <Typography textWeight="600" textSize="18px">
              Add Service to Service Group
            </Typography>
            <img
              src={cancelIcon}
              alt="cancel icon"
              className={styles.cancel_icon}
              onClick={() =>
                dispatch(setShowLinkServicesModal({ show: false, id: "" }))
              }
            />
          </div>
          <div className={styles.flex_container}>
            <div className={styles.flex_item}>
              <div className={styles.flex_item_header}>
                <Typography textWeight="600" textAlign="left">
                  Available Services
                </Typography>
              </div>
              <Droppable droppableId="1">
                {(provided) => (
                  <div
                    className={styles.permission_container}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {availableServices.map((service, index) => (
                      <Draggable
                        draggableId={`draggable-available-${index}`}
                        index={service.id}
                        key={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={styles.draggable_item}
                          >
                            <AvailablePermissionItem
                              key={index}
                              id={service.id}
                              text={service.name}
                              action={async (serviceId) => {
                                await handleAssignNewService(serviceId);
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className={styles.flex_item}>
              <div className={styles.flex_item_header}>
                <Typography textWeight="600" textAlign="left">
                  Added Services
                </Typography>
              </div>
              <Droppable droppableId="2">
                {(provided) => (
                  <div
                    className={styles.permission_container}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {assignedServices.map((service, index) => (
                      <Draggable
                        draggableId={`draggable-assinged-${index}`}
                        index={service.id}
                        key={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={styles.draggable_item}
                          >
                            <AssignedPermissionItem
                              key={index}
                              id={service.id}
                              text={service.serviceName}
                              action={async (serviceId) => {
                                await handleUnassignService(serviceId);
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default LinkServices;
