import React from "react";
import Typography from "../../../../Common/Typography";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";

interface LInkEmployeesToLocationHeaderInterface {
  state: "available" | "assigned";
}

const LInkEmployeesToLocationHeader: React.FC<
  LInkEmployeesToLocationHeaderInterface
> = ({ state }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.flex_item}>
      <div
        className={`${styles.location} ${
          state === "available" && styles.active_location
        }`}
        onClick={() => {
          navigate("/client/location/link-employee");
        }}
      >
        <Typography
          textColor={`${state === "available" && "#FFFFFF"}`}
          // text="Available Locations"
          textAlign="left"
          textWeight="500"
          textSize="14px"
          marginHorizontal="12px"
          style={{ cursor: "pointer" }}
        >
          {/* Available Locations */}
          Available Employees
        </Typography>
      </div>
      <div
        className={`${styles.location} ${
          state === "assigned" && styles.active_location
        }`}
        onClick={() => {
          navigate("/client/location/linked-employee");
        }}
      >
        <Typography
          textColor={`${state === "assigned" && "#FFFFFF"}`}
          // text="Assigned Locations"
          text="Assigned Employees"
          textAlign="left"
          textWeight="500"
          textSize="14px"
          marginHorizontal="12px"
        />
      </div>
    </div>
  );
};

export default LInkEmployeesToLocationHeader;
