import React, { useEffect, useState } from "react";
import { SelectInputProps } from "../../../types-definition/form";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import dropDownIcon from "../../../assets/svg/select-dropdown.svg";
import colors from "../../../theme/colors";

const FullWidthSelectInput: React.FC<SelectInputProps> = ({
  options = [],
  label,
  placeholder,
  value,
  setValue,
  height = "200px",
  width,
  required = false,
  error = false,
  disabled = false,
  readonly = false,
  style,
  helperText,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  // Used this variable to prevent restting field when it is prefilled
  const [isActive, setIsActive] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [list, setList] = useState<Array<string>>([]);
  const handleListSelector = (item: string) => {
    setValue(item);
    setIsSelected(true);
    setShowDropDown(false);
  };
  const filterListFromString = (input: string) => {
    if (input !== "" && input !== undefined) {
      const newList = options.filter((item) =>
        item.toLowerCase().includes(input.toLowerCase()),
      );
      setValue(input);
      setList(newList);
      setIsSelected(false);
    } else {
      setIsSelected(false);
      setValue("");
      setList(options);
    }
  };
  useEffect(() => {
    // Clears the field when it is not not selected and dropdown is not open
    if (!isSelected && !showDropDown && isActive) {
      setValue("");
      setList(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, showDropDown]);
  useEffect(() => {
    // Sets the list to options on first render
    if (list.length === 0) {
      setList(options);
    }
    // setList(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  return (
    <section className={styles.container} style={{ ...style }}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        className={styles.select}
        onChange={(e) => {
          if (!showDropDown) {
            setShowDropDown(true);
          }
          filterListFromString(e.target.value);
        }}
        onFocus={() => {
          setShowDropDown(true);
          setIsActive(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 500);
        }}
        style={{ width, borderColor: error ? colors.error : colors.gray3 }}
        disabled={disabled}
        readOnly={readonly}
      />
      <div className={styles.helpertext_container}>
        <Typography
          textAlign="left"
          textSize={"10px"}
          textWeight="600"
          textColor={error ? colors.error : colors.primary3}
        >
          {helperText}
        </Typography>
      </div>
      <div
        className={styles.dropdown_container}
        tabIndex={0}
        onClick={() => {
          setShowDropDown(true);
          setIsActive(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 400);
        }}
      >
        <img
          src={dropDownIcon}
          className={styles.dropdown}
          alt="dropdown icon"
        />
      </div>
      {showDropDown && (
        <div className={styles.datalist} style={{ maxHeight: height }}>
          {list.length === 0 ? (
            <p>No data</p>
          ) : (
            list.map((item) => (
              <p onClick={() => handleListSelector(item)} key={item}>
                {item}
              </p>
            ))
          )}
        </div>
      )}
    </section>
  );
};

export default FullWidthSelectInput;
