import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { ActivityLogTableProps } from "../../../../types-definition/admin";
import colors from "../../../../theme/colors";
import ActivityLogTableItem from "../ActivityLogTableItem";

const ActivityLogTable: React.FC<ActivityLogTableProps> = ({ data = [] }) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Timestamp
              </Typography>
            </th>
            <th className={styles.th_user}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                User
              </Typography>
            </th>
            <th className={styles.th_user}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Category
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Action
              </Typography>
            </th>
            <th className={styles.th_details}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Details
              </Typography>
            </th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return <ActivityLogTableItem {...item} key={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ActivityLogTable;
