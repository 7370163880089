import React, { useState } from "react";
import Typography from "../../../Common/Typography";
import deleteIcon from "../../../../assets/svg/error-icon.svg";
import styles from "./index.module.css";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch } from "../../../../redux/hooks";
import { deleteRequest } from "../../../../api/request";
import { toastSuccess } from "utils/toast";
import { DELETE_GRADE_URL } from "../../../../api/urls";
import { deleteGrade, getGrade } from "../../../../utils/localstorage";
import {
  updateGradeTableState,
  deleteGradeHandler,
} from "../../../../redux/slice/admin/gradeSlice";
const DeleteGradeModal: React.FC = () => {
  const [requestLoading, setRequestLoading] = useState(false);
  const dispatch = useAppDispatch();
  const id = getGrade();
  const deleteHandler = async () => {
    setRequestLoading(true);
    const response = await deleteRequest(`${DELETE_GRADE_URL}/${id}`);
    if (response?.success) {
      toastSuccess("Grade Deleted Successfully");
      dispatch(deleteGradeHandler({ gradeDeleted: false }));
      deleteGrade();
      dispatch(updateGradeTableState({ showModal: false }));
      setRequestLoading(false);
    } else {
    }
  };
  const cancelAction = () => {
    dispatch(updateGradeTableState({ showModal: false }));
    deleteGrade();
  };
  return (
    <div className={styles.container}>
      <img src={deleteIcon} alt="cancel icon" className={styles.cancelImage} />
      <Typography textWeight="600" textSize="18px" textAlign="left">
        Delete Grade
      </Typography>
      <div className={styles.mid_text}>
        <Typography textColor={colors.gray4} textAlign="left">
          Are you sure you want to delete this grade?
        </Typography>
        <Typography
          textColor={colors.gray4}
          textAlign="left"
          marginVertical="-8px"
        >
          This action cannot be undone.
        </Typography>
      </div>
      <div className={styles.button_container}>
        <Button
          width="160px"
          text="Cancel"
          isPrimary={false}
          onClick={cancelAction}
          backgroundColor={colors.transparent}
          isPrimaryBorder={true}
        />
        <Button
          width="160px"
          text="Delete"
          backgroundColor={colors.error}
          textColor={colors.white}
          isPrimary={false}
          onClick={deleteHandler}
          isLoading={requestLoading}
        />
      </div>
    </div>
  );
};

export default DeleteGradeModal;
