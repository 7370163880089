import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../../Common/PageBackButton";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import EmployeeUploadedItem from "../../../../StaffHub/Employee/EmployeeUploadedItem";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import { getRequest } from "../../../../../api/request";
import { toastError } from "../../../../../utils/toast";
import capitalize from "../../../../../utils/capitalize";
import moment from "moment";
import PageLoader from "../../../../Common/PageLoader";
import ConfirmIdentityModal from "../../../../StaffHub/Employee/ViewProfile/ConfirmIdentityModal";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import { ActivityBasicInformationProps } from "../../../../../types-definition/admin";

const ActivityBasicInformationNonUk = () => {
  const [showDOB, setShowDOB] = useState<boolean>(false);
  const [, setShowTIN] = useState<boolean>(false);
  const [dataToBeViewed, setDataToBeViewed] = useState<
    | "Password"
    | "TIN"
    | "NIN"
    | "Date Of Birth"
    | "Document Number"
    | "Visa Number"
    | "Certificate Number"
  >("Password");
  const [showConfirmIdentityModal, setShowConfirmIdentityModal] =
    useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [employeeData, setEmployeeData] =
    useState<ActivityBasicInformationProps>({
      firstName: "",
      lastName: "",
      username: "",
      dob: "",
      gender: "",
      photo: "",
      proofOfId: "",
      startDate: "",
      nationality: "",
      updatedAt: "",
    });
  const [previousEmployeeData, setPreviousEmployeeData] =
    useState<ActivityBasicInformationProps>({
      firstName: "",
      lastName: "",
      username: "",
      dob: "",
      gender: "",
      photo: "",
      proofOfId: "",
      startDate: "",
      nationality: "",
      updatedAt: "",
    });
  const handleShowData = () => {
    if (dataToBeViewed === "Date Of Birth") {
      setShowDOB(true);
    } else if (dataToBeViewed === "TIN") {
      setShowTIN(true);
    }
    setShowConfirmIdentityModal(false);
  };
  const id = getActivityBasicInformation();

  useEffect(() => {
    const getEmployeeData = async () => {
      // get employee data
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data.newRecord;
        const previousData = response.data.previousRecord;

        if (response.data.action === "CREATE") {
          setEmployeeData({
            firstName: data?.basicInfo?.firstName,
            lastName: data?.basicInfo?.lastName,
            username: data?.basicInfo?.username,
            startDate: data?.basicInfo?.startDate,
            nationality: data?.basicInfo?.nationality,
            dob: data?.basicInfo?.dob,
            photo: data?.photo,
            proofOfId: data?.proofOfId,
            gender: data?.basicInfo.gender,
            updatedAt: response?.data?.updatedAt,
          });

          setPreviousEmployeeData({
            firstName: previousData?.basicInfo?.firstName,
            lastName: previousData?.basicInfo?.lastName,
            username: previousData?.basicInfo?.username,
            startDate: previousData?.basicInfo?.startDate,
            nationality: previousData?.basicInfo?.nationality,
            dob: previousData?.basicInfo?.dob,
            photo: previousData?.employeePhoto,
            proofOfId: previousData?.employeeProofOfId,
            gender: previousData?.basicInfo?.gender,
            updatedAt: response?.data.updatedAt,
          });
        }
        if (response.data.action === "DELETE") {
          setPreviousEmployeeData({
            firstName: previousData?.firstName,
            lastName: previousData?.lastName,
            username: previousData?.username,
            startDate: previousData?.startDate,
            nationality: previousData?.nationality,
            dob: previousData?.dob,
            photo: previousData,
            proofOfId: previousData,
            gender: previousData?.gender,
            updatedAt: response?.data.updatedAt,
          });
        } else {
          setEmployeeData({
            firstName: data?.body?.firstName,
            lastName: data?.body?.lastName,
            username: data?.body?.username,
            startDate: data?.body?.startDate,
            nationality: data?.body?.nationality,
            dob: data?.body?.dob,
            photo: data?.files?.photo ? data?.files?.photo[0] : "",
            proofOfId: data?.files?.proofOfId ? data?.files?.proofOfId[0] : "",
            gender: data?.body?.gender,
            updatedAt: response?.data?.updatedAt,
          });
          setPreviousEmployeeData({
            firstName: previousData?.basicInformation?.firstName,
            lastName: previousData?.basicInformation?.lastName,
            username: previousData?.basicInformation?.username,
            startDate: previousData?.basicInformation?.startDate,
            nationality: previousData?.basicInformation?.nationality,
            dob: previousData?.basicInformation?.dob,
            photo: previousData?.employeePhoto,
            proofOfId: previousData?.employeeProofOfId,
            gender: previousData?.basicInformation?.gender,
            updatedAt: response?.data.updatedAt,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getEmployeeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      {showConfirmIdentityModal && (
        <ConfirmIdentityModal
          dataViewed={dataToBeViewed}
          cancelFunction={() => setShowConfirmIdentityModal(false)}
          successfunction={handleShowData}
        />
      )}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Basic Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">First Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.firstName ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Last Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.lastName ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Username</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData?.username ?? "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Password</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  **************
                </Typography>
                {/* <div
                  className={styles.password_button}
                  onClick={() => {
                    dispatch(setShowConfirmResetPasswordModal(true));
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    Reset Password
                  </Typography>
                </div> */}
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Date Of Birth</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {showDOB && employeeData?.dob
                    ? moment(employeeData?.dob).format("DD/MM/YYYY")
                    : !employeeData?.dob && showDOB
                      ? "-"
                      : "**/**/****"}
                </Typography>
                <div
                  className={styles.view_dob_button}
                  onClick={() => {
                    if (!showDOB) {
                      setShowConfirmIdentityModal(true);
                      setDataToBeViewed("Date Of Birth");
                    } else {
                      setShowDOB(false);
                    }
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    {showDOB ? "Hide" : "View"}
                  </Typography>
                </div>
              </div>
              <div>
                <Typography textAlign="left">Start Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData?.startDate
                    ? moment(new Date(employeeData?.startDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Gender</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.gender ? capitalize(employeeData.gender) : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Nationality</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {employeeData.nationality ? employeeData.nationality : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <EmployeeUploadedItem
                label="Photo"
                value={{
                  originalName:
                    employeeData?.photo?.originalName ??
                    employeeData?.photo?.originalname,
                  lastModifiedDate: new Date(
                    employeeData?.photo?.updatedAt ?? employeeData.updatedAt,
                  ),
                  size: employeeData?.photo?.size ?? 10000000,
                  URL:
                    employeeData?.photo?.photo ?? employeeData?.photo?.location,
                }}
                width="350px"
              />
              <EmployeeUploadedItem
                label="Proof Of Id"
                value={{
                  originalName:
                    employeeData?.proofOfId?.originalName ??
                    employeeData?.proofOfId?.originalname,
                  lastModifiedDate: new Date(
                    employeeData?.proofOfId?.updatedAt ??
                      employeeData.updatedAt,
                  ),
                  size: employeeData?.proofOfId?.size ?? 10000000,
                  URL:
                    employeeData?.proofOfId?.proofOfId ??
                    employeeData?.proofOfId?.location,
                }}
                width="350px"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Basic Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">First Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.firstName ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Last Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.lastName ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Username</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData?.username ?? "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Password</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  **************
                </Typography>
                {/* <div
                  className={styles.password_button}
                  onClick={() => {
                    dispatch(setShowConfirmResetPasswordModal(true));
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    Reset Password
                  </Typography>
                </div> */}
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Date Of Birth</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {showDOB && previousEmployeeData?.dob
                    ? moment(previousEmployeeData?.dob).format("DD/MM/YYYY")
                    : !previousEmployeeData?.dob && showDOB
                      ? "-"
                      : "**/**/****"}
                </Typography>
                <div
                  className={styles.view_dob_button}
                  onClick={() => {
                    if (!showDOB) {
                      setShowConfirmIdentityModal(true);
                      setDataToBeViewed("Date Of Birth");
                    } else {
                      setShowDOB(false);
                    }
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    {showDOB ? "Hide" : "View"}
                  </Typography>
                </div>
              </div>
              <div>
                <Typography textAlign="left">Start Date</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData?.startDate
                    ? moment(new Date(previousEmployeeData?.startDate)).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Gender</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.gender
                    ? capitalize(previousEmployeeData.gender)
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Nationality</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousEmployeeData.nationality
                    ? previousEmployeeData.nationality
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <EmployeeUploadedItem
                label="Photo"
                value={{
                  originalName: previousEmployeeData?.photo?.originalName,
                  lastModifiedDate: new Date(
                    previousEmployeeData?.photo?.updatedAt,
                  ),
                  size: previousEmployeeData?.photo?.size ?? 10000000,
                  URL: previousEmployeeData?.photo?.photo,
                }}
                width="350px"
              />
              <EmployeeUploadedItem
                label="Proof Of Id"
                value={{
                  originalName: previousEmployeeData?.proofOfId?.originalName,
                  lastModifiedDate: new Date(
                    previousEmployeeData?.proofOfId?.updatedAt,
                  ),
                  size: previousEmployeeData?.proofOfId?.size ?? 10000000,
                  URL: previousEmployeeData?.proofOfId?.proofOfId,
                }}
                width="350px"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityBasicInformationNonUk;
