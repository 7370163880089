import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import cancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getCurrentSelectedId,
  setShowAssignPermissionModal,
} from "../../../../redux/slice/admin/adminSlice";
import AvailablePermissionItem from "../../Permission Group/AvailablePermissionItem";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../../api/request";
import {
  GET_DEFAULT_PERMISSION_GROUP_URL,
  GET_PERMISSION_GROUP_URL,
} from "../../../../api/urls";
import AssignedPermissionItem from "../../Permission Group/AssignedPermissionItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toastError } from "../../../../utils/toast";

const AssignWebUserPermissionModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(getCurrentSelectedId);
  const [availablePermissions, setAvailablePermissions] = useState<Array<any>>(
    [],
  );
  const [assignedPermissions, setAssignedPermissions] = useState<Array<any>>(
    [],
  );
  const [monitorDeleteChnages, setMonitorDeleteChnages] = useState(0);
  const handleAssignNewPersmission = async (permissionId: string) => {
    const response = await postRequest(
      `/settings/user/${id}/permission-group/${permissionId}`,
    );
    if (response?.success) {
      await getPermissionData();
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };
  const handleUnassignPermission = async (permissionId: any) => {
    const response = await deleteRequest(
      `/settings/user/${id}/permission-group/${permissionId}`,
    );
    if (response?.success) {
      await getPermissionData();
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };

  // Get Users Permissions
  const getUsersPermissionGroup = async (data: any) => {
    const response = await getRequest(`/settings/user/${id}/permission-group`);
    if (response?.success) {
      setAssignedPermissions(response.data);

      setAvailablePermissions(
        data.filter((item: any) => {
          return response.data.every(
            (i: any) => i.permissionGroupId.toString() !== item.id.toString(),
          );
        }),
      );
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };

  // Get All Permission Groups Available
  const getPermissionData = async () => {
    const response = await getRequest(GET_PERMISSION_GROUP_URL);
    const defaultPermissionResresponse = await getRequest(
      `${GET_DEFAULT_PERMISSION_GROUP_URL}`,
    );

    if (response?.success && defaultPermissionResresponse.data) {
      const allPermissions = [
        ...response.data,
        ...defaultPermissionResresponse.data,
      ];
      await getUsersPermissionGroup(allPermissions);
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };

  useEffect(() => {
    getPermissionData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Drag and Drop
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }

    if (result.destination.droppableId === "2") {
      const itemIndex = result.draggableId.split("-")[2];
      const data = [...availablePermissions].filter(
        (item, index) => index !== parseInt(itemIndex.toString()),
      );
      setAvailablePermissions(data);
      handleAssignNewPersmission(result.source.index);
      return;
    }
    if (result.destination.droppableId === "1") {
      const itemIndex = result.draggableId.split("-")[2];
      const data = [...assignedPermissions].filter(
        (item, index) => index !== parseInt(itemIndex.toString()),
      );
      setAssignedPermissions(data);
      handleUnassignPermission(result.source.index);
      return;
    }
  };
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      dragHandleUsageInstructions="Use the spacebar to lift / drop the draggable item."
    >
      <div className={styles.container}>
        <div className={styles.content_container}>
          <div className={styles.header}>
            <Typography textWeight="600" textSize="18px">
              Assigning Permissions Group
            </Typography>
            <img
              src={cancelIcon}
              alt="cancel icon"
              className={styles.cancel_icon}
              onClick={() =>
                dispatch(setShowAssignPermissionModal({ show: false, id: "" }))
              }
            />
          </div>
          <div className={styles.flex_container}>
            <div className={styles.flex_item}>
              <div className={styles.flex_item_header}>
                <Typography textWeight="600" textAlign="left">
                  Available Permissions Group
                </Typography>
              </div>

              <Droppable droppableId="1">
                {(provided) => (
                  <div
                    className={styles.permission_container}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {availablePermissions.map((permission, index) => {
                      return (
                        <Draggable
                          draggableId={`draggable-available-${index}`}
                          index={permission.id}
                          key={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={styles.draggable_item}
                            >
                              <AvailablePermissionItem
                                id={permission.id}
                                key={index}
                                text={permission.name}
                                action={async (permissionId) => {
                                  await handleAssignNewPersmission(
                                    permission.id,
                                  );
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className={styles.flex_item}>
              <div className={styles.flex_item_header}>
                <Typography textWeight="600" textAlign="left">
                  Assigned Permissions Group
                </Typography>
              </div>
              <Droppable droppableId="2">
                {(provided) => (
                  <div
                    className={styles.permission_container}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {assignedPermissions.map((permission, index) => {
                      return (
                        <Draggable
                          draggableId={`draggable-assinged-${index}`}
                          index={permission.permissionGroupId}
                          key={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={styles.draggable_item}
                            >
                              <AssignedPermissionItem
                                id={permission?.permissionGroupId}
                                key={index}
                                text={permission.permissionGroup.name}
                                action={async (permissionId) => {
                                  await handleUnassignPermission(
                                    permission.permissionGroupId,
                                  );
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default AssignWebUserPermissionModal;
