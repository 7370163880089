import React from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import colors from "../../../theme/colors";
import RosterTableItem from "../RosterTableItem";
import { RoasterTableProps } from "../../../types-definition/roster";
import moment from "moment";
import { useAppSelector } from "../../../redux/hooks";
import { getRosterFromDate } from "../../../redux/slice/roster/rosterSlice";

const RosterTable: React.FC<RoasterTableProps> = ({ data = [] }) => {
  const date = useAppSelector(getRosterFromDate);
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              ></Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(new Date(date)).format("ddd DD MMM")}
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(new Date(date)).add(1, "day").format("ddd DD MMM")}
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(new Date(date)).add(2, "days").format("ddd DD MMM")}
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(new Date(date)).add(3, "days").format("ddd DD MMM")}
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(new Date(date)).add(4, "days").format("ddd DD MMM")}
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(new Date(date)).add(5, "days").format("ddd DD MMM")}
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                {moment(new Date(date)).add(6, "days").format("ddd DD MMM")}
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return <RosterTableItem {...item} key={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RosterTable;
