import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import { TableEditProps } from "../../../types-definition/common";
import colors from "../../../theme/colors";

const TableEditDropdown: React.FC<TableEditProps> = ({ data }) => {
  return (
    <div className={styles.dropdown_container}>
      {data.map((item, index) => {
        return (
          <div
            className={styles.dropdown_item}
            key={item.label}
            onClick={item.action}
          >
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              {item.label}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default TableEditDropdown;
