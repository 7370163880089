import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import tableSelectorIcon from "../../../../../assets/svg/table-selector.svg";
import { EmployeeAccrualTrackingData } from "../../../../../types-definition/StaffHub/employee";
import colors from "../../../../../theme/colors";
import moment from "moment";
import TableEditDropdown from "components/Common/TableEditDropdown";
import {
  toggleAccrualModal,
  togglemanualAdjustmentDeleteModal,
} from "redux/slice/staff-hub/employeeAccrualSettingSlice";
import { useAppDispatch } from "redux/hooks";
import { setManualAdjustment } from "utils/localstorage";
const ManualAccrualAjustmentTableItem: React.FC<
  EmployeeAccrualTrackingData
> = ({
  id,
  dateCreated,
  accrualChange,
  reason,
  dateApplied,
  applicationStatus,
  expirationDate,
  createdBy,
}) => {
  const dispatch = useAppDispatch();
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  return (
    <tr className={styles.tr}>
      <td className={` ${styles.td}`}>
        {/* <CircularName name={`${firstName} ${lastName}`} /> */}
        <Typography textAlign="left">{`${
          moment(dateCreated).format("MMMM D, YYYY").split(",")[0]
        }, `}</Typography>

        <Typography textAlign="left">{`${
          moment(dateCreated).format("MMMM D, YYYY").split(",")[1]
        } `}</Typography>
      </td>
      <td className={styles.td_employee_no}>
        <Typography textAlign="left" textWeight="600">
          {accrualChange}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{reason}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{`${
          moment(dateApplied).format("MMMM D, YYYY").split(",")[0]
        }, `}</Typography>
        <Typography textAlign="left">{`${
          moment(dateApplied).format("MMMM D, YYYY").split(",")[1]
        } `}</Typography>
      </td>
      <td className={styles.td}>
        {applicationStatus === "Applied" ? (
          <div
            className={styles.application_status}
            style={{
              backgroundColor: "#E7F6EC",
            }}
          >
            <Typography
              textAlign="left"
              marginVertical="3px"
              textWeight="600"
              textColor={colors.successtext}
            >
              {applicationStatus}
            </Typography>
          </div>
        ) : (
          <div
            className={styles.application_status}
            style={{
              backgroundColor: "#FBEAE9",
            }}
          >
            <Typography
              textAlign="left"
              marginVertical="3px"
              textWeight="600"
              textColor={colors.error}
            >
              {applicationStatus}
            </Typography>
          </div>
        )}
      </td>
      <td className={styles.td}>
        {expirationDate !== "null" ? (
          <div>
            <Typography textAlign="left">{`${
              moment(expirationDate).format("MMMM D, YYYY").split(",")[0]
            }, `}</Typography>
            <Typography textAlign="left">{`${
              moment(expirationDate).format("MMMM D, YYYY").split(",")[1]
            } `}</Typography>
          </div>
        ) : (
          "null"
        )}
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{createdBy}</Typography>
      </td>
      <td className={styles.td}>
        <div
          className={`${styles.image_container} ${styles.td_relative}`}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
          onBlur={() => setshowEditDropdown(false)}
          tabIndex={0}
        >
          {/* {showEditDropdown && (
            <EmployeeTableDropdown
              username={username}
              name={`${firstName} ${lastName}`}
              id={id.toString()}
              employeeId={`${employeeId}`}
              payRate={payRate ? payRate : "0"}
            />
          )} */}
          <img src={tableSelectorIcon} alt="selector-icon" />
          {showEditDropdown && (
            <TableEditDropdown
              data={[
                {
                  label: "Edit",
                  action: () => {
                    setManualAdjustment(id);
                    dispatch(toggleAccrualModal({ showModal: true }));
                    // dispatch(setShowDeleteServiceGroupModal({ show: true, id }));
                    // handleHideModal();
                  },
                },
                {
                  label: "Delete",
                  action: () => {
                    setManualAdjustment(id);
                    dispatch(
                      togglemanualAdjustmentDeleteModal({
                        showDeleteManualadjustmentModal: true,
                      }),
                    );
                    // dispatch(setShowDeleteServiceGroupModal({ show: true, id }));
                    // handleHideModal();
                  },
                },
              ]}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default ManualAccrualAjustmentTableItem;
