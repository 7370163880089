import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import Checkbox from "../../../Form/Checkbox";
import NumberInput from "../../../Form/NumberInput";
import { SiteLocationOperationFormProps } from "../../../../types-definition/client";
const SiteLocationOperationForm: React.FC<SiteLocationOperationFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  return (
    <>
      <div>
        <TextInput
          value={formData.smartFormAlert}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, smartFormAlert: data };
            })
          }
          label="SmartForm Alert"
          placeholder="example@gmail.com,example2@gmail.com"
        />
      </div>

      <div className={styles.form_flex_container}>
        <Checkbox
          marginVertical="30px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Allow Any Grade
              </Typography>
            </div>
          }
          value={formData.allowAnyGrades}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, allowAnyGrades: data };
            })
          }
          border={true}
        />
        <Checkbox
          marginVertical="30px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Allow Log Entries
              </Typography>
            </div>
          }
          value={formData.allowLogEntries}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, allowLogEntries: data };
            })
          }
          border={true}
        />
        <Checkbox
          marginVertical="30px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Enable Geo Fence
              </Typography>
            </div>
          }
          value={formData.enableGeoFence}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, enableGeoFence: data };
            })
          }
          border={true}
        />
      </div>

      <div>
        <NumberInput
          placeholder="0"
          label="Geo Fence Distance (meters)"
          value={formData.geoFenceDistance}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, geoFenceDistance: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, geoFenceDistance: false };
            });
          }}
          rightIconRight={"20px"}
          rightIconTop={"12px"}
          required={true}
          error={requiredFormData.geoFenceDistance}
          helperText={
            requiredFormData.geoFenceDistance
              ? "Geo Fence Distance is Required"
              : ""
          }
        />
      </div>
    </>
  );
};

export default SiteLocationOperationForm;
