import React, { useLayoutEffect, useState } from "react";
import styles from "./index.module.css";
import { getRequest } from "api/request";
import { MICROSOFT_SUCCESS_URL } from "api/urls";
import {
  createSocialProvider,
  saveData,
  saveUserTypes,
  setCountry,
  setSetupComplete,
  setUserIdle,
} from "utils/localstorage";
import UpdateSocialAccountModal from "components/Auth/UpdateSocialAccountModal";
import DashboardLongItem from "components/Common/DashboardLongItem";
import DashboardItem from "components/Common/DashboardItem";
import DashboardHeader from "components/Common/DashboardHeader";
import { toastError } from "utils/toast";
import capitalize from "utils/capitalize";
import { useNavigate } from "react-router-dom";
import PageLoader from "components/Common/PageLoader";
import { MICROSOFT } from "types-definition/constants";

const MicrosoftSuccess: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useLayoutEffect(() => {
    const getUserData = async () => {
      const response = await getRequest(MICROSOFT_SUCCESS_URL);
      if (response?.success) {
        const dataToSave = {
          id: response.data.id,
          name: `${response.data.firstName} ${response.data.lastName}`,
          email: response.data.email,
          token: response.accessToken,
          refreshToken: response.refreshToken,
        };
        setUserIdle(String(false));
        createSocialProvider(MICROSOFT);
        saveData(JSON.stringify(dataToSave));
        saveUserTypes(capitalize(response?.data?.role));
        setSetupComplete(
          JSON.stringify({
            completed: response?.data?.setupCompleted,
            step:
              response?.data?.setupStep === "0"
                ? "1"
                : response?.data?.setupStep,
          }),
        );
        if (response?.data?.country) {
          setCountry(response?.data?.country);
          if (
            response?.data?.setupStep &&
            parseFloat(response?.data?.setupStep) <= 13
          ) {
            navigate("/setup-wizard");
            // return;
          } else {
            navigate("/dashboard");
          }
        }
        // else {
        //   navigate("/update-account");
        // }
        setIsLoading(false);
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
        setIsLoading(false);
      }
    };
    getUserData();
  }, []);
  return (
    <>
      {isLoading && <PageLoader />}
      <main className={styles.container}>
        <DashboardHeader active="dashboard" disableAuth />
        <section className={styles.item_container}>
          <div className={styles.item1}>
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
          </div>
          <div className={styles.item2}>
            <DashboardLongItem />
          </div>
        </section>
      </main>

      <UpdateSocialAccountModal />
    </>
  );
};

export default MicrosoftSuccess;
