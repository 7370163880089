import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import EmptyItemState from "components/Common/EmptyItemState";
import { getLocation } from "utils/localstorage";
import { IncidentReportData } from "types-definition/client";
import { getRequest } from "api/request";
import { INCIDENT_REPORTS } from "api/urls";
import PageLoader from "components/Common/PageLoader";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import IncidentReportTable from "components/Client/Site Location/IncidentReportTable";
import AddIncidentReportModal from "components/Client/Site Location/AddIncidentReportModal";
import {
  getIncidentReportCounter,
  getShowIncidentReportModal,
  setShowIncidentReportModal,
} from "../../../../redux/slice/client/incidentReport";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import PageBackButton from "components/Common/PageBackButton";

const SiteLocationIncidentReport = () => {
  const dispatch = useAppDispatch();
  const id = getLocation();
  const showIssueReportModal = useAppSelector(getShowIncidentReportModal);
  const requestCounter = useAppSelector(getIncidentReportCounter);
  const [locationData, setLocationData] = useState<Array<IncidentReportData>>(
    [],
  );
  const [requestLoading, setRequestLoading] = useState(false);
  useEffect(() => {
    const getAllIncidentReport = async () => {
      try {
        const response = await getRequest(INCIDENT_REPORTS);
        if (response?.success) {
          setLocationData(
            response?.data
              ?.filter((item: any) => item.locationId === id)
              ?.map((item: any) => {
                return {
                  id: item.id,
                  incidentType: item.type,
                  description: item.description,
                  dateAndTime: item.incidentTime,
                  reporter: item.reporter,
                  locations: item.location,
                  service: item.service,
                  client: item.client,
                };
              }),
          );
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      } finally {
        setRequestLoading(false);
      }
    };
    getAllIncidentReport();
  }, [requestCounter, id]);

  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="clients" />
        <div className={styles.container}>
          <div
            className={
              locationData.length <= 5
                ? `${styles.item_container} ${styles.item_container_empty}`
                : `${styles.item_container} ${styles.item_container_fill}`
            }
          >
            <div className={styles.item_container}>
              <PageBackButton />
              <div className={styles.flex_item}>
                <div>
                  <Typography textAlign="left" textWeight="600" textSize="20px">
                    Incident Report
                  </Typography>
                </div>
                <div className={styles.sub_flex_item}>
                  <Button
                    marginHorizontal="10px"
                    marginTop="8px"
                    paddingVertical="7px"
                    width="200px"
                    isPrimary
                    text="Make a Report"
                    onClick={() => dispatch(setShowIncidentReportModal(true))}
                  />
                </div>
              </div>
              {locationData.length === 0 ? (
                <EmptyItemState
                  text="You haven't added any Report yet"
                  action={() => dispatch(setShowIncidentReportModal(true))}
                  location=""
                  label="Make a Report"
                />
              ) : (
                <IncidentReportTable data={locationData} />
              )}
            </div>
          </div>
        </div>
      </section>
      {showIssueReportModal && <AddIncidentReportModal />}
    </>
  );
};

export default SiteLocationIncidentReport;
