import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import Button from "../../../Form/Button";
import { useAppDispatch } from "../../../../redux/hooks";
import { setShowConfirmmailModal } from "../../../../redux/slice/admin/webuserSlice";
import colors from "../../../../theme/colors";
import { ConfirmWebUserMailProps } from "../../../../types-definition/admin";

const ConfirmMailModal: React.FC<ConfirmWebUserMailProps> = ({
  handleSubmit,
  email,
}) => {
  const dispatch = useAppDispatch();
  return (
    <section className={styles.container}>
      <div className={styles.content_container}>
        <Typography textWeight="600" textSize="18px">
          Confirm your Email Address
        </Typography>
        <div className={styles.midtext_container}>
          <Typography
            textWeight="300"
            textAlign="center"
            style={{ marginBottom: "15px" }}
          >
            {email}
          </Typography>
          <Typography textAlign="center" textColor={colors.gray3}>
            Is this email address correct?
          </Typography>
          <Typography textAlign="center" textColor={colors.gray3}>
            Please verify before proceeding.
          </Typography>
        </div>

        <div className={styles.button_container}>
          <Button
            onClick={() => {
              dispatch(setShowConfirmmailModal(false));
            }}
            paddingVertical="5px"
            marginVertical="10px"
            text="No"
            isPrimaryBorder={true}
            isPrimary={false}
            backgroundColor="transparent"
            marginHorizontal="5px"
          />
          <Button
            onClick={() => {
              handleSubmit();
              dispatch(setShowConfirmmailModal(false));
            }}
            paddingVertical="5px"
            marginVertical="10px"
            text="Yes, continue"
            isPrimary={true}
            marginHorizontal="5px"
          />
        </div>
      </div>
    </section>
  );
};

export default ConfirmMailModal;
