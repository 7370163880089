import React from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import { SettingsNavigationProps } from "../../../../types-definition/admin";
import colors from "../../../../theme/colors";
import { useNavigate } from "react-router-dom";

const SettingsNavigation: React.FC<SettingsNavigationProps> = ({ active }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div
        className={styles.container_item_left}
        onClick={() => navigate("/admin/settings")}
      >
        <Typography
          textWeight={active === "general" ? "600" : "500"}
          fontFamily="inter"
          textColor={active === "general" ? colors.primary3 : colors.black}
        >
          General
        </Typography>
      </div>
      <div
        className={styles.container_item}
        onClick={() => navigate("/admin/settings/time-check")}
      >
        <Typography
          textWeight={active === "time check" ? "600" : "500"}
          fontFamily="inter"
          textColor={active === "time check" ? colors.primary3 : colors.black}
        >
          Time Check
        </Typography>
      </div>
      <div
        className={styles.container_item}
        onClick={() => navigate("/admin/settings/holiday-settings")}
      >
        <Typography
          textWeight={active === "holiday" ? "600" : "500"}
          fontFamily="inter"
          textColor={active === "holiday" ? colors.primary3 : colors.black}
        >
          Holiday
        </Typography>
      </div>
      <div
        className={styles.container_item}
        onClick={() => navigate("/admin/settings/shift-generation")}
      >
        <Typography
          textWeight={active === "shift generation" ? "600" : "500"}
          fontFamily="inter"
          textColor={
            active === "shift generation" ? colors.primary3 : colors.black
          }
        >
          Shift Generation
        </Typography>
      </div>
      <div
        className={styles.container_item_right}
        onClick={() => navigate("/admin/settings/manual-check-reason")}
      >
        <Typography
          textWeight={active === "manual checkpoint reasons" ? "600" : "500"}
          fontFamily="inter"
          textColor={
            active === "manual checkpoint reasons"
              ? colors.primary3
              : colors.black
          }
        >
          Manual Checkpoint Reasons
        </Typography>
      </div>
    </div>
  );
};

export default SettingsNavigation;
