import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import ActiveState from "../../../Common/ActiveState";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import serviceGroupIcon from "../../../../assets/svg/service-group-icon.svg";
import TableEditDropdown from "../../../Common/TableEditDropdown";
import { ServiceGroupData } from "../../../../types-definition/admin";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  setShowAddServiceGroupModal,
  setShowLinkServicesModal,
  setShowDeleteServiceGroupModal,
} from "../../../../redux/slice/client/serviceGroupSlice";
import { setServiceGroup } from "../../../../utils/localstorage";
const ServiceGroupTableItem: React.FC<ServiceGroupData> = ({
  id,
  active,
  name,
}) => {
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  const dispatch = useAppDispatch();

  const handleHideModal = () => {
    setshowEditDropdown(false);
  };
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        <Typography textAlign="left">{name}</Typography>
      </td>
      <td className={styles.td}>
        <ActiveState active={active} />
      </td>
      <td className={styles.td}>
        <img
          src={serviceGroupIcon}
          alt="service-group-icon"
          className={styles.link_image}
          onClick={() => {
            dispatch(setShowLinkServicesModal({ show: true, id }));
          }}
        />
      </td>
      <td
        className={`${styles.td} ${styles.td_relative}`}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        {showEditDropdown && (
          <TableEditDropdown
            data={[
              {
                label: "Edit",
                action: () => {
                  setServiceGroup(id);
                  dispatch(setShowAddServiceGroupModal(true));
                  handleHideModal();
                },
              },
              {
                label: "Delete",
                action: () => {
                  dispatch(setShowDeleteServiceGroupModal({ show: true, id }));
                  handleHideModal();
                },
              },
            ]}
          />
        )}
        <div
          className={styles.image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default ServiceGroupTableItem;
