import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import EmployeeTimeSheetTableItem from "../EmployeeHolidayListViewTableItem";
import moment from "moment";
import chevronLeftIcon from "../../../../../assets/svg/chevron-left.svg";
import chevronRightIcon from "../../../../../assets/svg/chevron-right.svg";
import { EmployeeHolidayListViewTableInterface } from "../../../../../types-definition/StaffHub/employee";

const EmployeeHolidayListViewTable: React.FC<
  EmployeeHolidayListViewTableInterface
> = ({ data = [] }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const handleDateUpdate = (action: "increase" | "decrease") => {
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    if (action === "increase") {
      if (month === 11) {
        setCurrentDate(new Date(year + 1, 0, 1));
      } else {
        setCurrentDate(new Date(year, month + 1, 1));
      }
    } else {
      if (month === 0) {
        setCurrentDate(new Date(year - 1, 11, 1));
      } else {
        setCurrentDate(new Date(year, month - 1, 1));
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.toptext}>
        <img
          src={chevronLeftIcon}
          alt="left-icon"
          onClick={() => handleDateUpdate("decrease")}
        />
        <Typography textWeight="600" textSize="16px">
          {moment(currentDate).format("MMMM YYYY")}
        </Typography>
        <img
          src={chevronRightIcon}
          alt="right-icon"
          onClick={() => handleDateUpdate("increase")}
        />
      </div>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                From
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                To
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Time Off
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Holiday Type
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Status
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Modified By
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Modified At
              </Typography>
            </th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data.map((item: any, index: number) => {
            if (
              new Date(item.fromDate).getMonth() === currentDate.getMonth() &&
              new Date(item.fromDate).getFullYear() ===
                currentDate.getFullYear()
            ) {
              return (
                <EmployeeTimeSheetTableItem
                  key={index}
                  from={item.fromDate}
                  to={item.toDate}
                  modifiedAt={item.modifiedAt}
                  timeOff={item.timeOff}
                  holidayType={item.holidayType}
                  isError={item.isError}
                />
              );
            } else {
              return <></>;
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeHolidayListViewTable;
