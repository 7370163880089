import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import LogoutWarningModal from "../LogoutWarningModal";
import {
  getData,
  getLogoutIdle,
  getUserIdle,
  getUserTypes,
  setUserIdle,
} from "../../../utils/localstorage";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getLogoutTimerUpdateCounter,
  updateLogoutTimerCounter,
} from "../../../redux/slice/common/logoutTimerSlice";
import { postRequest } from "../../../api/request";
import { LOGIN_USER_URL, LOGIN_WEB_USER } from "../../../api/urls";
import toast from "react-hot-toast";
import { ADMIN, WEBUSER } from "../../../types-definition/constants";

const HandleIdleLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const user = JSON.parse(getData() ?? "{}");
  const idleStateCounter = useAppSelector(getLogoutTimerUpdateCounter);

  useEffect(() => {}, [idleStateCounter]);

  const onIdle = () => {
    if (getLogoutIdle() === "true") {
      return;
    }
    setUserIdle(String(true));
    dispatch(updateLogoutTimerCounter());
  };

  useIdleTimer({
    onIdle,
    timeout: 900000,
    throttle: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "click",
      "dblclick",
      "keypress",
      "scroll",
    ],
  });
  const loginAction = async () => {
    setRequestLoading(true);
    const userType = getUserTypes();

    if (userType === ADMIN) {
      const response = await postRequest(LOGIN_USER_URL, {
        email: user?.email,
        password: password,
        rememberMe: false,
      });
      if (response?.success) {
        setUserIdle(String(false));
        dispatch(updateLogoutTimerCounter());
        setPassword("");
      } else {
        toast.error(response?.data, {
          duration: 4000,
          position: "top-center",
        });
      }
    } else if (userType === WEBUSER) {
      const response = await postRequest(LOGIN_WEB_USER, {
        identifier: user?.email,
        password: password,
        rememberMe: false,
      });
      if (response?.success) {
        setUserIdle(String(false));
        dispatch(updateLogoutTimerCounter());
        setPassword("");
      } else {
        toast.error(response?.data, {
          duration: 4000,
          position: "top-center",
        });
      }
    }
    setRequestLoading(false);
    return;
  };
  
  return (
    <div>
      {getUserIdle() === "true" && (
        <LogoutWarningModal
          loginFunction={loginAction}
          password={password}
          setPassword={setPassword}
          requestLoading={requestLoading}
        />
      )}
    </div>
  );
};
export default HandleIdleLogin;
