import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import ActiveState from "../../../Common/ActiveState";
import TableEditDropdown from "../../../Common/TableEditDropdown";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import { setArea } from "../../../../utils/localstorage";
import { useNavigate } from "react-router-dom";
import { AreaData } from "../../../../types-definition/client";
import { useAppDispatch } from "redux/hooks";
import {
  setCurrentAreaSelectedId,
  setShowAreaDeletemodal,
} from "redux/slice/client/areaSlice";

const AreaTableItem: React.FC<AreaData> = ({
  id,
  active,
  description,
  locations,
  name,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        {/* <CircularName name={`${name} ${name}`} /> */}
        <Typography textAlign="left" textColor="#148A9B">
          {name}
        </Typography>
      </td>
      <td className={styles.td_mail}>
        <Typography textAlign="left">
          {description ? description : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {locations?.filter((item: any) => item.areaId === id)?.length ?? 0}
        </Typography>
      </td>
      <td className={styles.td}>
        <ActiveState active={active} />
      </td>
      <td
        className={`${styles.td} ${styles.td_relative}`}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        {showEditDropdown && (
          <TableEditDropdown
            data={[
              {
                label: "Edit",
                action: () => {
                  setArea(id);
                  navigate("/client/area/add");
                },
              },
              {
                label: "Delete",
                action: () => {
                  setshowEditDropdown(false);
                  dispatch(setCurrentAreaSelectedId(id));
                  dispatch(setShowAreaDeletemodal(true));
                },
              },
            ]}
          />
        )}
        <div
          className={styles.image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default AreaTableItem;
