import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import uploadIcon from "../../../assets/svg/upload-icon.svg";
import Typography from "../../Common/Typography";
import { UploadItemProps } from "../../../types-definition/common";
import { useDropzone } from "react-dropzone";
import colors from "../../../theme/colors";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import viewIcon from "../../../assets/svg/view-icon.svg";
import moment from "moment";
import ViewUploadedItem from "../ViewUploadedItem";
import DeleteUploadedItem from "../DeleteUploadedItemModal";

const UploadItem: React.FC<UploadItemProps> = ({
  setValue,
  label,
  width,
  value,
  required = false,
  disabled = false,
  handleDeleteItem,
}) => {
  const [isDragFocused, setIsDragFocused] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [showDeleteFileModal, setShowDeleteModal] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentImage, setCurrentImage] = useState<any>(null);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFile) => {
      setValue(acceptedFile[0]);
      setIsDragFocused(false);
    },
    onDragEnter: () => {
      setIsDragFocused(true);
    },
    onDragLeave: () => {
      setIsDragFocused(false);
    },
    accept: { "image/*": [] },
  });
  useEffect(() => {
    if (value) {
      setCurrentImage(value);
    } else {
      setCurrentImage(null);
    }
  }, [value]);
  const handleDeleteFile = () => {
    setValue("");
    if (handleDeleteItem) {
      handleDeleteItem();
    }
    setShowDeleteModal(false);
  };
  return (
    <>
      <div className={styles.main_container}>
        <Typography textAlign="left" textWeight="600">
          {required ? `${label} *` : label}
        </Typography>
        <div
          className={
            !isDragFocused
              ? styles.container
              : `${styles.container} ${styles.container_expand}`
          }
          // {...getRootProps()}
        >
          {!disabled && (
            <input
              type="file"
              hidden
              ref={inputRef}
              accept="image/*"
              onChange={(e) => {
                setCurrentImage(e.target.files![0]);
                setValue(e.target.files![0]);
              }}
              {...getInputProps()}
            />
          )}
          {currentImage === null ? (
            <>
              {disabled ? (
                <div className={styles.content} style={{ width }}>
                  <img
                    src={uploadIcon}
                    alt="upload icon"
                    style={
                      currentImage !== null
                        ? { width: 80, height: 80 }
                        : { width: 50, height: 50 }
                    }
                  />
                  <div className={styles.flex_text_disabled}>
                    <Typography
                      textWeight="600"
                      // style={{
                      //   background: "linear-gradient(#006093, #00dcda)",
                      //   WebkitTextFillColor: "transparent",
                      //   WebkitBackgroundClip: "text",
                      // }}
                      textColor={colors.primary3}
                    >
                      Click to upload &nbsp;
                    </Typography>
                    <Typography>or drag and drop</Typography>
                  </div>
                  <Typography>SVG, PNG, JPG or GIF (max. 800x400px)</Typography>
                </div>
              ) : (
                <div
                  className={styles.content}
                  style={{ width }}
                  {...getRootProps()}
                >
                  <img
                    src={uploadIcon}
                    alt="upload icon"
                    style={
                      currentImage !== null
                        ? { width: 80, height: 80 }
                        : { width: 50, height: 50 }
                    }
                  />
                  <div
                    className={styles.flex_text}
                    onClick={() => {
                      if (inputRef.current) {
                        inputRef.current.click();
                      }
                    }}
                  >
                    <Typography
                      textWeight="600"
                      // style={{
                      //   background: "linear-gradient(#006093, #00dcda)",
                      //   WebkitTextFillColor: "transparent",
                      //   WebkitBackgroundClip: "text",
                      // }}
                      textColor={colors.primary3}
                    >
                      Click to upload &nbsp;
                    </Typography>
                    <Typography>or drag and drop</Typography>
                  </div>
                  <Typography>SVG, PNG, JPG or GIF (max. 800x400px)</Typography>
                </div>
              )}
            </>
          ) : (
            <div className={styles.content_filled_container}>
              <Typography fontFamily="inter" textWeight="600">
                {currentImage.name}
              </Typography>
              <Typography fontFamily="inter" marginVertical="-6px">
                {currentImage.size / 1000}KB .{" "}
                {moment(currentImage.lastModifiedDate).format("LL")}
              </Typography>
              <div className={styles.content_filled}>
                {!disabled && (
                  <div
                    className={styles.action_item}
                    onClick={() => setShowDeleteModal(true)}
                  >
                    <img src={deleteIcon} alt="delete icon" />
                    <Typography textColor={colors.error}>
                      Clear Upload
                    </Typography>
                  </div>
                )}
                <div
                  className={styles.action_item}
                  onClick={() => setShowFile(true)}
                >
                  <img src={viewIcon} alt="delete icon" />
                  <Typography textColor={colors.primary3}>
                    View Upload
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showFile && (
        <ViewUploadedItem
          title={currentImage.name}
          imageURl={currentImage.URL}
          path={currentImage}
          cancelDisplay={() => setShowFile(false)}
        />
      )}
      {showDeleteFileModal && (
        <DeleteUploadedItem
          deleteAction={handleDeleteFile}
          cancelAction={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

export default UploadItem;
