import React from "react";
import Typography from "../../Common/Typography";
import styles from "./index.module.css";
import colors from "../../../theme/colors";
import { EmployeeListNavigationProps } from "../../../types-definition/roster";

const EmployeeListNavigation: React.FC<EmployeeListNavigationProps> = ({
  active,
  setActive,
}) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.container_item_left}
        style={{
          backgroundColor:
            active === "unscheduled-employees" ? colors.primary5 : "",
        }}
        onClick={() => setActive("unscheduled-employees")}
      >
        <Typography
          textWeight={active === "unscheduled-employees" ? "600" : "500"}
          fontFamily="inter"
          textColor={
            active === "unscheduled-employees" ? colors.primary3 : colors.black
          }
          textSize="10px"
        >
          Unscheduled Employees
        </Typography>
      </div>
      <div
        className={styles.container_item_right}
        onClick={() => setActive("canceled-employees")}
        style={{
          backgroundColor:
            active === "canceled-employees" ? colors.primary5 : "",
        }}
      >
        <Typography
          textWeight={active === "canceled-employees" ? "600" : "500"}
          fontFamily="inter"
          textColor={
            active === "canceled-employees" ? colors.primary3 : colors.black
          }
          textSize="10px"
        >
          Canceled Employees
        </Typography>
      </div>
    </div>
  );
};

export default EmployeeListNavigation;
