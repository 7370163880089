import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import EmployeeListNavigation from "../EmployeeListNavigator";
import { EmployeeListRosterProps } from "../../../types-definition/roster";
import { getRequest } from "../../../api/request";
import { GET_ALL_EMPLOYEE_URL } from "../../../api/urls";
import { getRosterServiceData } from "../../../redux/slice/roster/rosterSlice";
import { useAppSelector } from "../../../redux/hooks";
import blackArrowRightIcon from "../../../assets/svg/black-arrow-right-icon.svg";
import { CANCELLED } from "types-definition/constants";

const EmployeeListRoster: React.FC<EmployeeListRosterProps> = () => {
  const [activeState, setActiveState] = useState<
    "unscheduled-employees" | "canceled-employees"
  >("unscheduled-employees");
  const data = useAppSelector(getRosterServiceData);
  const [employeeData, setEmployeeData] = useState<
    Array<{ employeeName: string; employeeId: string }>
  >([]);
  useEffect(() => {
    switchEmployeeList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeState, data]);
  const getAllEmployees = async () => {
    const response = await getRequest(GET_ALL_EMPLOYEE_URL);
    if (response?.success) {
      return response.data.map((item: any) => {
        return {
          employeeId: item.id,
          employeeName: `${item.firstName} ${item.lastName}`,
        };
      });
    }
  };
  const switchEmployeeList = async () => {
    const allEmployees = await getAllEmployees();
    if (allEmployees) {
      let employeeSegment: Array<any> = [];
      if (activeState === "unscheduled-employees") {
        for (const employee in allEmployees) {
          if (
            data.filter(
              (i: any) => i.employeeId === allEmployees[employee].employeeId,
            ).length === 0
          ) {
            employeeSegment = [
              ...employeeSegment,
              {
                employeeName: allEmployees[employee].employeeName,
                employeeId: allEmployees[employee].employeeId,
              },
            ];
          }
        }
      } else {
        for (const employee in allEmployees) {
          if (
            data.filter(
              (i: any) =>
                i.employeeId === allEmployees[employee].employeeId &&
                i.status === CANCELLED,
            ).length > 0
          ) {
            employeeSegment = [
              ...employeeSegment,
              {
                employeeName: allEmployees[employee].employeeName,
                employeeId: allEmployees[employee].employeeId,
              },
            ];
          }
        }
      }
      setEmployeeData(employeeSegment);
    }
  };
  return (
    <div className={styles.flex_container}>
      <div className={styles.flex_item}>
        <div className={styles.flex_item_header}>
          <Typography textWeight="600" textAlign="left">
            Employee List
          </Typography>
          <img src={blackArrowRightIcon} alt="black-arrow-right-icon" />
        </div>
        <EmployeeListNavigation
          active={activeState}
          setActive={(data) => {
            setActiveState(data);
          }}
        />
        <div className={styles.list_container}>
          {employeeData?.map((item, index) => (
            <div className={styles.list_container_item} key={index}>
              <Typography textAlign="left">{item.employeeName}</Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmployeeListRoster;
