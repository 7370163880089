import React from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import { UK } from "types-definition/constants";
import { getCountry } from "utils/localstorage";
import ActivityEmployeeBankDetailsUk from "components/Admin/Activity Logs/EmployeeActivityLogs/ActivityEmployeeBankDetailsUk";
import ActivityEmployeeBankDetailsNonUk from "components/Admin/Activity Logs/EmployeeActivityLogs/ActivityEmployeeBankDetailsNonUk";

const ActivityEmployeeBankDetails = () => {
  const country = getCountry();
  return (
    <div className={styles.main_container}>
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      {country === UK ? (
        <ActivityEmployeeBankDetailsUk />
      ) : (
        <ActivityEmployeeBankDetailsNonUk />
      )}
    </div>
  );
};

export default ActivityEmployeeBankDetails;
