import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../Common/PageBackButton";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../utils/localstorage";
import { getRequest } from "../../../../api/request";
import PageLoader from "../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../api/urls";
import { LocationOperation } from "../../../../types-definition/admin";
import DashboardHeader from "../../../Common/DashboardHeader";
import { toastError } from "utils/toast";
const ActivityLocationOperation = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentLocationOperation, setCurrentLocationOperation] =
    useState<LocationOperation>({
      smartFormAlert: [],
      allowAnyGrade: null,
      allowLogEntries: null,
      enableGeoFence: null,
      geoFenceDistance: "",
    });
  const [previousLocationOperation, setPreviousLocationOperation] =
    useState<LocationOperation>({
      smartFormAlert: [],
      allowAnyGrade: null,
      allowLogEntries: null,
      enableGeoFence: null,
      geoFenceDistance: "",
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getLocationOperation = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data;
        const previousData = response.data;
        if (response.data.action === "POST") {
          setCurrentLocationOperation({
            smartFormAlert: data?.newRecord?.locationOperations?.smartFormAlert,
            allowAnyGrade: data?.newRecord?.locationOperations?.allowAnyGrades,
            allowLogEntries:
              data?.newRecord?.locationOperations?.allowLogEntries,
            enableGeoFence: data?.newRecord?.locationOperations?.enableGeoFence,
            geoFenceDistance:
              data?.newRecord?.locationOperations?.geoFenceDistance,
          });
          setPreviousLocationOperation({
            smartFormAlert:
              previousData?.previousRecord?.locationOperations?.smartFormAlert,
            allowAnyGrade:
              previousData?.previousRecord?.locationOperations?.allowAnyGrades,
            allowLogEntries:
              previousData?.previousRecord?.locationOperations?.allowLogEntries,
            enableGeoFence:
              previousData?.previousRecord?.locationOperations?.enableGeoFence,
            geoFenceDistance:
              previousData?.previousRecord?.locationOperations
                ?.geoFenceDistance,
          });
        } else {
          setCurrentLocationOperation({
            smartFormAlert: data?.newRecord?.locationOperations?.smartFormAlert,
            allowAnyGrade: data?.newRecord?.locationOperations?.allowAnyGrades,
            allowLogEntries:
              data?.newRecord?.locationOperations?.allowLogEntries,
            enableGeoFence: data?.newRecord?.locationOperations?.enableGeoFence,
            geoFenceDistance:
              data?.newRecord?.locationOperations?.geoFenceDistance,
          });
          setPreviousLocationOperation({
            smartFormAlert:
              previousData?.previousRecord?.locationOperations?.smartFormAlert,
            allowAnyGrade:
              previousData?.previousRecord?.locationOperations?.allowAnyGrades,
            allowLogEntries:
              previousData?.previousRecord?.locationOperations?.allowLogEntries,
            enableGeoFence:
              previousData?.previousRecord?.locationOperations?.enableGeoFence,
            geoFenceDistance:
              previousData?.previousRecord?.locationOperations
                ?.geoFenceDistance,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getLocationOperation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Add Site Location Operation
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">SmartForm Alert </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationOperation.smartFormAlert
                    ? currentLocationOperation.smartFormAlert
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Allow Any Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationOperation.allowAnyGrade === true && "True"}
                  {currentLocationOperation.allowAnyGrade === false && "False"}
                  {currentLocationOperation.allowAnyGrade === null && "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Allow Log Entries</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationOperation.allowLogEntries === true && "True"}
                  {currentLocationOperation.allowLogEntries === false &&
                    "False"}
                  {currentLocationOperation.allowLogEntries === null && "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Enable Geo Fence</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationOperation.enableGeoFence === true && "True"}
                  {currentLocationOperation.enableGeoFence === false && "False"}
                  {currentLocationOperation.enableGeoFence === null && "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Geo Fence Distance</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentLocationOperation.geoFenceDistance
                    ? currentLocationOperation.geoFenceDistance
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* previouse Record */}

      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Add Site Location Operation
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">SmartForm Alert </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationOperation.smartFormAlert
                    ? previousLocationOperation.smartFormAlert
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Allow Any Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationOperation.allowAnyGrade === true && "True"}
                  {previousLocationOperation.allowAnyGrade === false && "False"}
                  {previousLocationOperation.allowAnyGrade === null && "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Allow Log Entries</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationOperation.allowLogEntries === true && "True"}
                  {previousLocationOperation.allowLogEntries === false &&
                    "False"}
                  {previousLocationOperation.allowLogEntries === null && "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Enable Geo Fence</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationOperation.enableGeoFence === true && "True"}
                  {previousLocationOperation.enableGeoFence === false &&
                    "False"}
                  {previousLocationOperation.enableGeoFence === null && "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Geo Fence Distance</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationOperation.geoFenceDistance
                    ? previousLocationOperation.geoFenceDistance
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityLocationOperation;
