import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { getRequest } from "../../../../../api/request";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import colors from "../../../../../theme/colors";
import Typography from "../../../../Common/Typography";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import PageBackButton from "../../../../Common/PageBackButton";

const ActivityEmployeeMedicalDetails = () => {
  const id = getActivityBasicInformation();
  const [medicalDetails, setMedicalDetails] = useState("");
  const [previousMedicalDetails, setPreviousMedicalDetails] = useState("");
  useEffect(() => {
    const getActivityEmployeeContactInformation = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        setMedicalDetails(
          response?.data?.newRecord?.medicalDetails?.medicalDetails,
        );
        setPreviousMedicalDetails(
          response?.data?.previousRecord?.medicalDetails?.medicalDetails,
        );
      }
    };
    getActivityEmployeeContactInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Medical Details
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Medical Details</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {medicalDetails ? medicalDetails : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Contact Information */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Medical Details
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Medical Details</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousMedicalDetails ? previousMedicalDetails : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityEmployeeMedicalDetails;
