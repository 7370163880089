import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import { PayrollReportData } from "types-definition/report";

const PayrollTableItem: React.FC<PayrollReportData> = ({
  amountEarned,
  grade,
  name,
  payOption,
  workedHours,
}) => {
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        {/* <CircularName name={`${firstName} ${lastName}`} /> */}
        <Typography textAlign="left">{name}</Typography>
      </td>

      <td className={styles.td}>
        <Typography textAlign="left">{grade ? `${grade}` : "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {payOption ? payOption : "N/A"}
        </Typography>
      </td>

      <td className={styles.td}>
        <Typography textAlign="left">
          {workedHours ? workedHours : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {amountEarned ? amountEarned : "N/A"}
        </Typography>
      </td>
    </tr>
  );
};

export default PayrollTableItem;
