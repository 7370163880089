import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { CertificateGroupTableProps } from "../../../../types-definition/StaffHub/certificateGroup";
import colors from "../../../../theme/colors";
import CertificateTableItem from "../CertificateTableItem";

const CertificateGroupTable: React.FC<CertificateGroupTableProps> = ({
  data = [
    {
      id: "1",
      name: "Certificate 1",
      description: "Certificate 1",
      active: true,
    },
  ],
}) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Certificate Name
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Description
              </Typography>
            </th>

            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Status
              </Typography>
            </th>
            <th className={styles.th_link}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="500"
                textColor={colors.tableText}
              ></Typography>
            </th>
            <th className={styles.th_index}></th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return <CertificateTableItem {...item} key={item.name} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CertificateGroupTable;
