import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import ActiveState from "../../../Common/ActiveState";
import TableEditDropdown from "../../../Common/TableEditDropdown";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import certificateGroupIcon from "../../../../assets/svg/certificate-group-icon.svg";
import { CertificateGroupData } from "../../../../types-definition/StaffHub/certificateGroup";
import { setCertificateGroup } from "../../../../utils/localstorage";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  updateShowAssignCertificateModal,
  updateShowDeleteCertificateGroupModal,
} from "../../../../redux/slice/staff-hub/certificateGroupSlice";

const CertificateGroupTableItem: React.FC<CertificateGroupData> = ({
  id,
  active,
  description,
  name,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  // const handleEditEmployee = () => {
  //   setCurrentEmployee(JSON.stringify({ name: username }));
  //   navigate("/employee/basic-info");
  // };
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        {/* <CircularName name={`${name} ${name}`} /> */}
        <Typography textAlign="left" textWeight="600">
          {name}
        </Typography>
      </td>
      <td className={styles.td_mail}>
        <Typography textAlign="left">
          {description ? description : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <ActiveState active={active} />
      </td>
      <td className={styles.td}>
        <img
          src={certificateGroupIcon}
          alt="certificate-group-icon"
          className={styles.link_image}
          onClick={() => {
            setCertificateGroup(id);
            dispatch(updateShowAssignCertificateModal(true));
          }}
        />
      </td>
      <td
        className={`${styles.td} ${styles.td_relative}`}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        {showEditDropdown && (
          <TableEditDropdown
            data={[
              {
                label: "Edit",
                action: () => {
                  setCertificateGroup(id);
                  navigate("/staff-hub/certificate-group/form");
                },
              },
              {
                label: "Delete",
                action: () => {
                  setCertificateGroup(id);
                  setshowEditDropdown(false);
                  dispatch(updateShowDeleteCertificateGroupModal(true));
                  navigate("/staff-hub/certificate-group/");
                },
              },
            ]}
          />
        )}
        <div
          className={styles.image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default CertificateGroupTableItem;
