import React, { useState, useEffect } from "react";
import LiveLocationMonitoring from "components/Common/LiveMonitoringHeader";
import styles from "./index.module.css";
import backIcon from "assets/svg/arrow-left.svg";
import Typography from "components/Common/Typography";
import StatisticLocationTable from "components/Common/StatisticLocationTable";
import colors from "theme/colors";
import { StatisticLocationTableData } from "types-definition/common";
import { useNavigate } from "react-router-dom";
import {
  getLiveLocationId,
  getUserTypes,
} from "utils/localstorage";
import moment from "moment";
import { ADMIN, STARTED } from "types-definition/constants";
import {
  ACTIVE_BOARD,
  GET_USER_ADMIN_SETTINGS_URL,
  GET_USER_SETTINGS_URL,
} from "api/urls";
import { getRequest } from "api/request";
import PageLoader from "components/Common/PageLoader";
import EmployeeContactModal from "components/Common/EmployeeContactModal";
import { useAppSelector } from "redux/hooks";
import {
  getShowSelectDeliveryModeModal,
  getShowSendMessageModal,
} from "redux/slice/staff-hub/employeeMessageSlice";
import SendMessageModal from "components/StaffHub/Employee/Message/SendMessageModal";
import MessageDeliveryModeModal from "components/StaffHub/Employee/Message/MessageDeliveryModeModal";
import { getData } from "utils/localstorage";
import { getShowEmployeeContactModal } from "redux/slice/common/statisticsSlice";

const StatisticalSiteLocation = () => {
  const navigate = useNavigate();
  const liveLocationId = getLiveLocationId();
  const user = getUserTypes();
  const [locationName, setLocationName] = useState("");
  const [employeeCount, setEmployeeCount] = useState({
    assigned: 0,
    active: 0,
  });

  const [data, setData] = useState<Array<StatisticLocationTableData>>([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const showEmployeeContactModal = useAppSelector(getShowEmployeeContactModal);
  const showMessageModeModal = useAppSelector(getShowSelectDeliveryModeModal);
  const showSendMessageModal = useAppSelector(getShowSendMessageModal);
  useEffect(() => {
    const getLocationData = async () => {
      const userData = getData();
        const date = moment().toISOString();
      if (userData) {
        const id = JSON.parse(userData).id;
        const response = await getRequest(`${ACTIVE_BOARD}?date=${date}`);
        if (response?.success) {
          const locationData = response?.data.filter(
            (item: any) => item.locationId === liveLocationId,
          );
          if (locationData.length > 0) {
            let response: any;
            if (user === ADMIN) {
              response = await getRequest(GET_USER_SETTINGS_URL);
            } else {
              response = await getRequest(GET_USER_ADMIN_SETTINGS_URL);
            }
            const timeAfterShiftStart = parseInt(
              response?.data?.afterShiftStart ?? "15",
            );
            setLocationName(locationData[0]?.location?.name);
            const totalEmployeeData = locationData.filter((item: any) => {
              return (
                moment
                  .utc(moment.utc(item.date).format("DD/MM/YYYY"), "DD/MM/YYYY")
                  .valueOf() ===
                moment
                  .utc(
                    moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
                      "DD/MM/YYYY",
                    ),
                    "DD/MM/YYYY",
                  )
                  .valueOf()
              );
            });
            const employeeClockedIn = totalEmployeeData.filter((item: any) => {
              return item.status === STARTED;
            });
            setEmployeeCount({
              assigned: totalEmployeeData.length,
              active: employeeClockedIn.length,
            });
            const checkEmployeeIsLate = (
              employeeStartTime: string,
              startTime: string,
            ) => {
              const arrivalTimeInHour = employeeStartTime
                ? moment(new Date(employeeStartTime), "HH:mm").format("HH:mm")
                : null;
              const time =
                moment(arrivalTimeInHour, "HH:mm").diff(
                  moment(startTime, "HH:mm"),
                ) / 60000;
              let status: "early" | "delayed" | "late" =
                time <= 0
                  ? "early"
                  : time > 0 && time <= timeAfterShiftStart
                    ? "delayed"
                    : "late";
              return {
                status,
                diff: time,
              };
            };
            setData(
              totalEmployeeData
                .filter((i: any) => i.employee?.id)
                .map((item: any) => {
                  let isEmployeeLate: "early" | "delayed" | "late" =
                    checkEmployeeIsLate(
                      item.employeeStartTime,
                      item.startTime,
                    ).status;
                  let indicators = {
                    early: "#148A9B",
                    delayed: "#F3A218",
                    late: "#D42620",
                  };
                  return {
                    employee: {
                      id: item.employee.id,
                      firstName: item.employee.firstName,
                      lastName: item.employee.lastName,
                      mobileNumber: item.employee.mobileNumber,
                      email: item.employee.email,
                      username: item.employee.username,
                    },
                    grade: item.service.grade ?? "N/A",
                    arrivalTime: item.employeeStartTime,
                    scheduledTime: item.startTime,
                    indicator: indicators[isEmployeeLate],
                    serviceName:item.service.name
                  };
                })
                .sort((firstItem: any, secondItem: any) => {
                  let { diff: firstEmployeeTimeDiff } = checkEmployeeIsLate(
                    firstItem.arrivalTime,
                    firstItem.scheduledTime,
                  );
                  let { diff: secondEmployeeTimeDiff } = checkEmployeeIsLate(
                    secondItem.arrivalTime,
                    secondItem.scheduledTime,
                  );
                  if (
                    isNaN(firstEmployeeTimeDiff) &&
                    isNaN(secondEmployeeTimeDiff)
                  ) {
                    return 0;
                  }
                  if (
                    isNaN(firstEmployeeTimeDiff) &&
                    secondEmployeeTimeDiff >= 0
                  ) {
                    return -1;
                  }
                  if (
                    firstEmployeeTimeDiff >= 0 &&
                    isNaN(secondEmployeeTimeDiff)
                  ) {
                    return 1;
                  }
                  return secondEmployeeTimeDiff - firstEmployeeTimeDiff;
                }),
            );
          }
        } else {
        }
      }

      setIsPageLoading(false);
    };

    getLocationData();

    const interval = setInterval(() => {
      getLocationData();
    }, 10000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <section className={styles.section}>
          <div className={styles.container}>
            <LiveLocationMonitoring />
            <div className={styles.flex_item}>
              <img
                src={backIcon}
                onClick={() => {
                  navigate("/dashboard");
                }}
                alt="back-icon"
              />
              <div>
                <Typography
                  marginHorizontal="0px"
                  textSize="22px"
                  textWeight="600"
                  textAlign="left"
                  fontFamily="inter"
                  textColor={colors.black}
                  marginVertical="0px"
                >
                  Statistics of Site Location
                </Typography>
                <em>
                  <Typography
                    marginHorizontal="0px"
                    textSize="14px"
                    textWeight="600"
                    textAlign="left"
                    fontFamily="inter"
                    textColor={colors.black}
                    marginVertical="10px"
                  >
                    {locationName}
                  </Typography>
                </em>
              </div>
            </div>
            <div className={styles.flex_container}>
              <div className={styles.employee_box}>
                <Typography
                  marginHorizontal="0px"
                  textSize="16px"
                  textWeight="500"
                  textAlign="left"
                  fontFamily="inter"
                  textColor="#98A2B3"
                  marginVertical="0px"
                >
                  Assigned Employees
                </Typography>
                <Typography
                  marginHorizontal="0px"
                  textSize="24px"
                  textWeight="600"
                  textAlign="left"
                  fontFamily="inter"
                  textColor={colors.black}
                  marginVertical="10px"
                >
                  {employeeCount.assigned}
                </Typography>
              </div>
              <div className={styles.employee_box}>
                <Typography
                  marginHorizontal="0px"
                  textSize="16px"
                  textWeight="500"
                  textAlign="left"
                  fontFamily="inter"
                  textColor="#98A2B3"
                  marginVertical="0px"
                >
                  Active Employees
                </Typography>
                <Typography
                  marginHorizontal="0px"
                  textSize="24px"
                  textWeight="600"
                  textAlign="left"
                  fontFamily="inter"
                  textColor={colors.black}
                  marginVertical="10px"
                >
                  {employeeCount.active}
                </Typography>
              </div>
              <div className={styles.employee_box}>
                <Typography
                  marginHorizontal="0px"
                  textSize="16px"
                  textWeight="500"
                  textAlign="left"
                  fontFamily="inter"
                  textColor="#98A2B3"
                  marginVertical="0px"
                >
                  Inactive Employees
                </Typography>
                <Typography
                  marginHorizontal="0px"
                  textSize="24px"
                  textWeight="600"
                  textAlign="left"
                  fontFamily="inter"
                  textColor={colors.black}
                  marginVertical="10px"
                >
                  {employeeCount.assigned - employeeCount.active}
                </Typography>
              </div>
            </div>

            <div className={styles.right_flex}>
              <div className={styles.right_flex_container}>
                <div className={styles.sub_right_flex_container}>
                  <div
                    className={styles.indicator}
                    style={{
                      backgroundColor: "#148A9B",
                    }}
                  ></div>
                  <Typography
                    marginHorizontal="0px"
                    textSize="12px"
                    textWeight="600"
                    textAlign="left"
                    fontFamily="inter"
                    marginVertical="0px"
                  >
                    On-time
                  </Typography>
                </div>
                <div className={styles.sub_right_flex_container}>
                  <div
                    className={styles.indicator}
                    style={{
                      backgroundColor: "#F3A218",
                    }}
                  ></div>
                  <Typography
                    marginHorizontal="0px"
                    textSize="12px"
                    textWeight="600"
                    textAlign="left"
                    fontFamily="inter"
                    marginVertical="0px"
                  >
                    Delayed
                  </Typography>
                </div>
                <div className={styles.sub_right_flex_container}>
                  <div
                    className={styles.indicator}
                    style={{
                      backgroundColor: "#D42620",
                    }}
                  ></div>
                  <Typography
                    marginHorizontal="0px"
                    textSize="12px"
                    textWeight="600"
                    textAlign="left"
                    fontFamily="inter"
                    marginVertical="0px"
                  >
                    Late
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <StatisticLocationTable data={data} />
              {showEmployeeContactModal && <EmployeeContactModal />}
              {showSendMessageModal && <SendMessageModal />}
              {showMessageModeModal && <MessageDeliveryModeModal />}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default StatisticalSiteLocation;
