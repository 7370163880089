import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import TextInput from "../../../../Form/TextInput";
import PasswordInput from "../../../../Form/PasswordInput";
import SelectInput from "../../../../Form/SelectInput";
import DateInput from "../../../../Form/DateInput";
import UploadItem from "../../../../Form/UploadItem";
import Checkbox from "../../../../Form/Checkbox";
import { EmployeeBasicInfoFormProps } from "../../../../../types-definition/StaffHub/employee";
import UsernameInput from "../../../../Form/UsernameInput";
import {
  getCountry,
  getCurrentEmployee,
} from "../../../../../utils/localstorage";
import { SEARCH_EMPLOYEE } from "../../../../../api/urls";
import { getRequest } from "../../../../../api/request";
import EmployeeNavigation from "../../EmployeeNavigation";
import Button from "../../../../Form/Button";
import colors from "../../../../../theme/colors";
import generateRandomNumbers from "../../../../../utils/generateRondomNumber";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  basicInformationFormData,
  basicInformationRequiredFormData,
  setBasicInformationFormData,
  setBasicInformationRequiredFormData,
} from "../../../../../redux/slice/staff-hub/employeeBasicInfoSlice";
import PageBackButton from "../../../../Common/PageBackButton";
import getCompanyCode from "../../../../../utils/generateCompanyCode";
import NationalityInput from "components/Form/NationnalityInput";
import { NG, US } from "types-definition/constants";

const EmployeeBasicInfoForm: React.FC<EmployeeBasicInfoFormProps> = ({
  requestLoading,
  handleFormCancelation,
  handleSubmit,
}) => {
  const [usernameState, setUsernameState] = useState({
    error: false,
    loading: false,
    message: "",
  });
  const [editing, setEditing] = useState(false);
  const formData = useAppSelector(basicInformationFormData);
  const requiredFormData = useAppSelector(basicInformationRequiredFormData);
  const dispatch = useAppDispatch();
  const country = getCountry();
  // const [filterdNationality, setFilteredNationality] =
  //   useState(filterCountry());
  useEffect(() => {
    if (getCurrentEmployee()) return;
    const validateUsername = async () => {
      if (formData.username) {
        setUsernameState((prevData) => {
          return {
            ...prevData,
            loading: true,
            message: "Checking avalability",
          };
        });
        const response = await getRequest(
          `${SEARCH_EMPLOYEE}/${formData.username}`,
        );
        if (response?.data) {
          setUsernameState((prevData) => {
            return {
              ...prevData,
              error: true,
              message: "Username already exists",
              loading: false,
            };
          });
          if (!editing) {
            // if (formData.usernameSavedInDB) return;
            dispatch(
              setBasicInformationFormData({
                ...formData,
                username: !response.data
                  ? `${formData.firstName.toLowerCase()}${formData.lastName.toLowerCase()}`
                  : `${formData.firstName.toLowerCase()}${formData.lastName.toLowerCase()}`.replace(
                      /\s/g,
                      "",
                    ),
              }),
            );
            dispatch(
              setBasicInformationRequiredFormData({
                ...requiredFormData,
                username: false,
              }),
            );
          }
        } else {
          setUsernameState((prevData) => {
            return { ...prevData, error: false, message: "", loading: false };
          });
        }
        setUsernameState((prevData) => {
          return { ...prevData, loading: false };
        });
      }
    };
    validateUsername();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.username]);

  return (
    <div className={styles.item_container}>
      <PageBackButton
        width="90%"
        paddingTop="0"
        marginBottom="24px"
        useStoredLocationUrl={true}
      />
      <Typography
        textAlign="left"
        textWeight="600"
        textSize="24px"
        style={{
          margin: "8px auto 24px auto",
          width: "90%",
        }}
      >
        Add Employee
      </Typography>
      <EmployeeNavigation active="basic information" />
      <div className={styles.content_container}>
        <div className={styles.form_flex_container}>
          <TextInput
            value={formData.firstName}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  firstName: data,
                }),
              );
              dispatch(
                setBasicInformationRequiredFormData({
                  ...requiredFormData,
                  firstName: false,
                }),
              );
            }}
            label="First Name"
            placeholder="Enter Employee First Name"
            required={true}
            error={requiredFormData.firstName}
            helperText={
              requiredFormData.firstName ? "First Name is required" : ""
            }
            id="firstName"
          />
          <TextInput
            value={formData.lastName}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  lastName: data,
                }),
              );
              dispatch(
                setBasicInformationRequiredFormData({
                  ...requiredFormData,
                  lastName: false,
                }),
              );
            }}
            label="Last Name"
            placeholder="Enter Employee Last Name"
            required={true}
            error={requiredFormData.lastName}
            helperText={
              requiredFormData.lastName ? "Last Name is required" : ""
            }
            id="lastName"
          />
          <UsernameInput
            value={formData.username}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  username: data,
                }),
              );

              dispatch(
                setBasicInformationRequiredFormData({
                  ...requiredFormData,
                  username: false,
                }),
              );
            }}
            keyDown={() => {
              setEditing(true);
            }}
            onblur={() => {
              setEditing(false);
            }}
            label="Username"
            placeholder="Enter Username"
            required={true}
            onFocus={() => {
              if (formData.firstName && formData.lastName) {
                dispatch(
                  setBasicInformationFormData({
                    ...formData,
                    username:
                      `${formData.firstName.toLowerCase()}${formData.lastName.toLowerCase()}${getCompanyCode()?.toUpperCase()}${generateRandomNumbers()}`.replace(
                        /\s/g,
                        "",
                      ),
                  }),
                );
              }
            }}
            isLoading={usernameState.loading}
            error={requiredFormData.username || usernameState.error}
            helperText={
              usernameState.message
                ? usernameState.message
                : requiredFormData.username
                  ? "Username is required"
                  : ""
            }
            disabled={getCurrentEmployee() ? true : false}
            id="username"
          />
        </div>

        <div className={styles.form_flex_container}>
          <PasswordInput
            value={formData.password}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  password: data,
                }),
              );
              dispatch(
                setBasicInformationRequiredFormData({
                  ...requiredFormData,
                  password: false,
                }),
              );
            }}
            label="Password"
            placeholder="Enter Password"
            required={true}
            error={requiredFormData.password}
            helperText={requiredFormData.password ? "Password is required" : ""}
            id="password"
          />
          <SelectInput
            value={formData.gender}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  gender: data,
                }),
              );
              dispatch(
                setBasicInformationRequiredFormData({
                  ...requiredFormData,
                  gender: false,
                }),
              );
            }}
            label="Gender"
            placeholder="Select Gender"
            options={
              country !== NG
                ? [
                    "Male",
                    "Female",
                    "Non-binary",
                    "Genderquuer",
                    "Genderfluid",
                    "Agender",
                    "Transgender",
                    "Two-spirit",
                    "Bigender",
                    "Gender nonconforming",
                    "Pangender",
                    "Androgynous",
                    "Demigirl",
                    "Gender questioning",
                  ]
                : ["Male", "Female"]
            }
            required={true}
            error={requiredFormData.gender}
            helperText={requiredFormData.gender ? "Gender is required" : ""}
            id="gender"
          />
          <NationalityInput
            value={formData.nationality}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  nationality: data,
                }),
              );
              dispatch(
                setBasicInformationRequiredFormData({
                  ...requiredFormData,
                  nationality: false,
                }),
              );
            }}
            label="Nationality"
            placeholder="Select Nationality"
            required={true}
            error={requiredFormData.nationality}
            helperText={
              requiredFormData.nationality ? "Nationality is required" : ""
            }
            id="nationality"
          />
        </div>

        <div className={styles.form_flex_container}>
          <DateInput
            value={formData.dob}
            setValue={(data) =>
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  dob: data,
                }),
              )
            }
            label="Date Of Birth"
            placeholder="Select Date of Birth"
            max={18}
          />
          <DateInput
            value={formData.startDate}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  startDate: data,
                }),
              );

              dispatch(
                setBasicInformationRequiredFormData({
                  ...requiredFormData,
                  startDate: false,
                }),
              );
            }}
            label="Start Date"
            placeholder="Enter Start Date"
            required={true}
            error={requiredFormData.startDate}
            helperText={
              requiredFormData.startDate ? "Start Date is required" : ""
            }
            id="startDate"
          />
          <DateInput
            value={formData.endDate}
            setValue={(data) =>
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  endDate: data,
                }),
              )
            }
            min={formData.startDate}
            validateMin={true}
            label="End Date"
            placeholder="Enter End Date"
          />
        </div>

        <div className={styles.form_flex_container}>
          <TextInput
            value={formData.mobileNumber}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  mobileNumber: data,
                }),
              );
              dispatch(
                setBasicInformationRequiredFormData({
                  ...requiredFormData,
                  mobileNumber: false,
                }),
              );
            }}
            label="Mobile Number"
            placeholder="Enter Mobile Number"
            required={true}
            error={requiredFormData.mobileNumber}
            helperText={
              requiredFormData.mobileNumber ? "Mobile number is required" : ""
            }
            id="mobile_Number"
          />
          <TextInput
            value={formData.email}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  email: data,
                }),
              );
              dispatch(
                setBasicInformationRequiredFormData({
                  ...requiredFormData,
                  email: false,
                }),
              );
            }}
            label="Email"
            placeholder="Enter Email"
            required={true}
            error={requiredFormData.email}
            helperText={requiredFormData.email ? "Email is required" : ""}
            id="email"
          />
        </div>
        <div className={styles.form_flex_container}>
          <UploadItem
            label="Photo"
            setValue={(data) =>
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  photo: data,
                }),
              )
            }
            value={formData.photo}
          />
        </div>
        <div className={styles.form_flex_container}>
          <UploadItem
            label="Proof of ID"
            setValue={(data) =>
              // setFormData((prevData) => {
              //   return { ...prevData, proofOfId: data };
              // })
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  proofOfId: data,
                }),
              )
            }
            value={formData.proofOfId}
          />
        </div>
        <div className={styles.form_flex_container}>
          <Checkbox
            marginVertical="30px"
            label={
              <div>
                <Typography
                  textAlign="left"
                  textWeight="600"
                  marginHorizontal="-10px"
                >
                  Portal Access
                </Typography>
              </div>
            }
            value={formData.portalAccess}
            setValue={(data) =>
              // setFormData((prevData) => {
              //   return { ...prevData, portalAccess: data };
              // })
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  portalAccess: data,
                }),
              )
            }
          />
          <Checkbox
            marginVertical="30px"
            label={
              <div>
                <Typography
                  textAlign="left"
                  textWeight="600"
                  marginHorizontal="-10px"
                >
                  Active
                </Typography>
              </div>
            }
            value={formData.active}
            setValue={(data) =>
              // setFormData((prevData) => {
              //   return { ...prevData, active: data };
              // })
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  active: data,
                }),
              )
            }
          />
        </div>
        <div className={styles.button_container}>
          <Button
            width="200px"
            text="Cancel"
            isPrimary={false}
            onClick={handleFormCancelation}
            marginHorizontal="10px"
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
          />
          <Button
            width="200px"
            text="Save"
            isPrimary={true}
            onClick={handleSubmit}
            isLoading={requestLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeeBasicInfoForm;
