import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import circleCancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import Checkbox from "../../../Form/Checkbox";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateShowAssignCertificateModal } from "../../../../redux/slice/staff-hub/certificateGroupSlice";
import Button from "../../../Form/Button";
import { getRequest, postRequest } from "../../../../api/request";
import toast from "react-hot-toast";
import { getCertificateGroup } from "../../../../utils/localstorage";
import {
  CERTIFICATE_TYPE_URL,
  CREATE_CERTIFICATE_GROUP_URL,
  GET_CERTIFICATE_GROUP_URL,
} from "../../../../api/urls";
import { toastSuccess } from "../../../../utils/toast";

const AssignCertificateModal = () => {
  const dispatch = useAppDispatch();
  const id = getCertificateGroup();
  const [certificateTypes, setCertificateTypes] = useState<Array<any>>([]);
  useEffect(() => {
    getAllCertificateTypes();
    getCertificateGroupData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [requestLoading, setRequestLoading] = useState(false);
  const [formData, setFormData] = useState<Array<string>>([]);
  const getAllCertificateTypes = async () => {
    const response = await getRequest(CERTIFICATE_TYPE_URL);
    if (response?.success) {
      setCertificateTypes(response?.data?.certificateTypes);
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
  };
  const getCertificateGroupData = async () => {
    setRequestLoading(true);
    const response = await getRequest(
      `${GET_CERTIFICATE_GROUP_URL}/${id}/assigned-certificate-type`,
    );
    if (response?.success) {
      // setFormData(response?.data.certificateTypes);
      setFormData(response?.data?.map((item: any) => item.certificateTypeId));
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };

  const handleFormSubmit = async () => {
    setRequestLoading(true);
    const response = await postRequest(
      `${CREATE_CERTIFICATE_GROUP_URL}/${id}/re-assign-certificate-type`,
      formData,
    );
    if (response?.success) {
      toastSuccess("Certificate Linked Successfully");
      dispatch(updateShowAssignCertificateModal(false));
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };

  return (
    <section className={styles.container}>
      <div className={styles.flex_container}>
        <Typography textWeight="600" textSize="20px">
          Assign Certificate Type
        </Typography>
        <img
          src={circleCancelIcon}
          alt="cancel-icon"
          onClick={() => dispatch(updateShowAssignCertificateModal(false))}
        />
      </div>
      <div className={styles.checkbox_flex_container}>
        <div>
          {certificateTypes.map((item, index) => {
            if (index % 2 === 0) {
              return (
                <Checkbox
                  key={index}
                  value={formData.includes(item.id)}
                  label={
                    <Typography
                      textWeight="500"
                      marginHorizontal="-10px"
                      style={{ textOverflow: "ellipsis" }}
                    >
                      {item.certificateName}
                    </Typography>
                  }
                  setValue={() => {
                    if (formData.includes(item.id)) {
                      setFormData((prevData) => {
                        return prevData.filter((data) => data !== item.id);
                      });
                    } else {
                      setFormData((prevData) => {
                        return [...prevData, item.id];
                      });
                    }
                  }}
                  marginVertical="5px"
                />
              );
            } else {
              return null;
            }
          })}
        </div>
        <div>
          {certificateTypes?.map((item, index) => {
            if (index % 2 !== 0) {
              return (
                <Checkbox
                  key={index}
                  value={formData.includes(item.id)}
                  label={
                    <Typography
                      textWeight="500"
                      marginHorizontal="-10px"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        // width: "auto",
                        height: "20px",
                      }}
                    >
                      {item.certificateName}
                    </Typography>
                  }
                  setValue={() => {
                    if (formData.includes(item.id)) {
                      setFormData((prevData) => {
                        return prevData.filter((data) => data !== item.id);
                      });
                    } else {
                      setFormData((prevData) => {
                        return [...prevData, item.id];
                      });
                    }
                  }}
                  marginVertical="5px"
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      <div className={styles.button_container}>
        <Button
          text="Save"
          isLoading={requestLoading}
          isPrimary
          onClick={handleFormSubmit}
        />
      </div>
    </section>
  );
};

export default AssignCertificateModal;
