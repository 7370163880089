import React from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import { RosterDayViewTableItemsProps } from "../../../types-definition/roster";
import { timeHourlyArray } from "../../../utils/timeArray";
import { getTimeLength, getTimeTopLength } from "../../../utils/timeConverter";

const RosterDayViewTableItem: React.FC<RosterDayViewTableItemsProps> = ({
  data,
}) => {
  return (
    <div className={styles.main_container}>
      <>
        {timeHourlyArray.map((i) => {
          return (
            <>
              <div className={styles.container}>
                <div className={styles.text}>
                  <Typography textAlign="left">{i}</Typography>
                </div>
              </div>
            </>
          );
        })}
      </>
      <div className={styles.active_container}>
        {data.map((i) => {
          return (
            <div
              className={styles.active}
              style={{
                marginTop: getTimeTopLength(i.startTime),
                height: getTimeLength(i.startTime, i.endTime),
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default RosterDayViewTableItem;
