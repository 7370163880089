import React, { useEffect, useMemo, useState } from "react";
import { CountrySelectInputProps } from "../../../types-definition/form";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import selectIcon from "../../../assets/svg/select-dropdown.svg";
import colors from "../../../theme/colors";

const CountrySelectInput: React.FC<CountrySelectInputProps> = ({
  options = [],
  label,
  placeholder,
  value,
  setValue,
  height = "200px",
  width,
  datalistTop = "91px",
  dropdownTop = "55px",
  required = false,
  error = false,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [list, setList] = useState<Array<any>>([]);
  const handleListSelector = (item: string) => {
    setValue(item);
    setIsSelected(true);
    setShowDropDown(false);
  };
  const filterListFromString = (input: string) => {
    if (input !== "" && input !== undefined) {
      const newList = options.filter((item) =>
        item.data.toLowerCase().includes(input.toLowerCase()),
      );
      setValue(input);
      setList(newList);
      setIsSelected(false);
    } else {
      setIsSelected(false);
      setValue("");
      setList(options);
    }
  };
  useEffect(() => {
    if (!isSelected && !showDropDown) {
      setValue("");
      setList(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, showDropDown]);
  useMemo(() => {
    setList(options);
  }, [options]);
  return (
    <section className={styles.container}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <input
        type="text"
        value={value.data}
        placeholder={placeholder}
        className={styles.select}
        onChange={(e) => {
          if (!showDropDown) {
            setShowDropDown(true);
          }
          filterListFromString(e.target.value);
        }}
        onFocus={() => setShowDropDown(true)}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 500);
        }}
        readOnly
        style={{ width, borderColor: error ? colors.error : colors.gray3 }}
      />
      <div
        className={styles.dropdown_container}
        style={{ top: dropdownTop }}
        tabIndex={0}
        onClick={() => setShowDropDown(true)}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 400);
        }}
      >
        <img src={selectIcon} className={styles.dropdown} alt="dropdown icon" />
      </div>
      <img src={value.flag} alt="flag" className={styles.country_flag} />
      {showDropDown && (
        <div
          className={styles.datalist}
          style={{ maxHeight: height, top: datalistTop }}
        >
          {list.length === 0 ? (
            <p>No data</p>
          ) : (
            list.map((item) => (
              <p onClick={() => handleListSelector(item.data)} key={item.data}>
                {item.data}
              </p>
            ))
          )}
        </div>
      )}
    </section>
  );
};

export default CountrySelectInput;
