import React, { useEffect, useState } from "react";
import LinkComponent from "../../Common/LinkComponent";
import colors from "../../../theme/colors";
import Typography from "../../Common/Typography";
import styles from "./index.module.css";
import adminIcon from "../../../assets/svg/admin-icon.svg";
import { AdminSideBarProps } from "../../../types-definition/admin";
import { getRequest } from "../../../api/request";
import {
  GET_USER_ADMIN_SETTINGS_URL,
  GET_USER_SETTINGS_URL,
} from "../../../api/urls";
import {
  getSetupComplete,
  getUserTypes,
  setSettings,
} from "../../../utils/localstorage";
import { toastError } from "../../../utils/toast";
import { ADMIN } from "types-definition/constants";

const AdminSideBar: React.FC<AdminSideBarProps> = ({ active }) => {
  const [setupCompleted, setSetupCompleted] = useState({
    completed: false,
    step: 0,
  });
  const user = getUserTypes();
  useEffect(() => {
    const setUserSettingsId = async () => {
      if (user === ADMIN) {
        const response = await getRequest(GET_USER_SETTINGS_URL);
        if (response?.success) {
          setSettings(response.data.id);
        } else {
          toastError(response?.data);
        }
      } else {
        const response = await getRequest(GET_USER_ADMIN_SETTINGS_URL);
        if (response?.success) {
          setSettings(response.data.id);
        } else {
          if (
            response?.data.includes(
              "You do not have permission to access this resource",
            )
          )
            return;
          toastError(response?.data);
        }
      }
    };

    setUserSettingsId();
  }, [user]);

  const toastIncompleteError = () => {
    toastError("Complete setup wizard to proceed");
  };

  const doNothing = () => {};

  useEffect(() => {
    const checkSetUpComplete = getSetupComplete();
    if (checkSetUpComplete) {
      setSetupCompleted({
        completed: JSON.parse(checkSetUpComplete).completed,
        step: parseFloat(JSON.parse(checkSetUpComplete).step),
      });
    }
  }, []);
  return (
    <div className={styles.sidebar_container}>
      <div className={styles.admintext_container}>
        <img src={adminIcon} alt="admin-icon" className={styles.admin_icon} />
        <Typography textAlign="left" textWeight="600" textColor={colors.gray5}>
          Administrator
        </Typography>
      </div>
      <div
        onClick={() => {
          setupCompleted.step > 0 ? doNothing() : toastIncompleteError();
        }}
      >
        <LinkComponent link={setupCompleted.step> 0 ? "/admin/settings" : ""}>
          <div
            className={
              active === "settings"
                ? `${styles.sidebar_item} ${styles.sidebar_item_active}`
                : styles.sidebar_item
            }
          >
            <Typography
              textAlign="left"
              textWeight={active === "settings" ? "600" : "500"}
              textColor={active === "settings" ? colors.primary3 : colors.gray5}
            >
              Settings
            </Typography>
          </div>
        </LinkComponent>
      </div>

      <div
        className={
          active === "subscription"
            ? `${styles.sidebar_item} ${styles.sidebar_item_active}`
            : styles.sidebar_item
        }
      >
        <Typography
          textAlign="left"
          textWeight={active === "subscription" ? "600" : "500"}
          textColor={active === "subscription" ? colors.primary3 : colors.gray5}
        >
          Subscription
        </Typography>
      </div>
      <div
        onClick={() => {
          setupCompleted.step > 1 ? doNothing() : toastIncompleteError();
        }}
      >
        {" "}
        <LinkComponent
          link={setupCompleted.step > 1 ? "/admin/permissions" : ""}
        >
          <div
            className={
              active === "permission groups"
                ? `${styles.sidebar_item} ${styles.sidebar_item_active}`
                : styles.sidebar_item
            }
          >
            <Typography
              textAlign="left"
              textWeight={active === "permission groups" ? "600" : "500"}
              textColor={
                active === "permission groups" ? colors.primary3 : colors.gray5
              }
            >
              Permission Groups
            </Typography>
          </div>
        </LinkComponent>
      </div>

      <div
        onClick={() => {
          setupCompleted.step > 2 ? doNothing() : toastIncompleteError();
        }}
      >
        <LinkComponent
          link={ setupCompleted.step > 2 ? "/admin/web-user-profile" : ""}
        >
          <div
            className={
              active === "web user profile"
                ? `${styles.sidebar_item} ${styles.sidebar_item_active}`
                : styles.sidebar_item
            }
          >
            <Typography
              textAlign="left"
              textWeight={active === "web user profile" ? "600" : "500"}
              textColor={
                active === "web user profile" ? colors.primary3 : colors.gray5
              }
            >
              Web User Profile
            </Typography>
          </div>
        </LinkComponent>
      </div>

      {/* <LinkComponent link="/admin/grade">
        <div
          className={
            active === "grade"
              ? `${styles.sidebar_item} ${styles.sidebar_item_active}`
              : styles.sidebar_item
          }
        >
          <Typography
            textAlign="left"
            textWeight={active === "grade" ? "600" : "500"}
            textColor={active === "grade" ? colors.primary3 : colors.gray5}
          >
            Grade
          </Typography>
        </div>
      </LinkComponent> */}
      <div
        onClick={() => {
          setupCompleted.step > 12 ? doNothing() : toastIncompleteError();
        }}
      >
        {" "}
        <LinkComponent
          link={setupCompleted.step > 12 ? "/admin/holiday-multiplier" : ""}
        >
          <div
            className={
              active === "holiday multiplier"
                ? `${styles.sidebar_item} ${styles.sidebar_item_active}`
                : styles.sidebar_item
            }
          >
            <Typography
              textAlign="left"
              textWeight={active === "holiday multiplier" ? "600" : "500"}
              textColor={
                active === "holiday multiplier" ? colors.primary3 : colors.gray5
              }
            >
              Holiday Rate
            </Typography>
          </div>
        </LinkComponent>
      </div>

      <div
        onClick={() => {
          setupCompleted.step > 9 ? doNothing() : toastIncompleteError();
        }}
      >
        {" "}
        <LinkComponent
          link={ setupCompleted.step > 9 ? "/admin/service-group" : ""}
        >
          <div
            className={
              active === "service group"
                ? `${styles.sidebar_item} ${styles.sidebar_item_active}`
                : styles.sidebar_item
            }
          >
            <Typography
              textAlign="left"
              textColor={
                active === "service group" ? colors.primary3 : colors.gray5
              }
            >
              Service Group
            </Typography>
          </div>
        </LinkComponent>
      </div>
      <div
        onClick={() => {
          !setupCompleted.completed ? toastIncompleteError() : doNothing();
        }}
      >
      
        <LinkComponent
          link={setupCompleted.completed ? "/admin/time-shift-generation" : ""}
        >
          <div
            className={
              active === "shift generation"
                ? `${styles.sidebar_item} ${styles.sidebar_item_active}`
                : styles.sidebar_item
            }
          >
            <Typography
              textAlign="left"
              textWeight={active === "shift generation" ? "600" : "500"}
              textColor={
                active === "shift generation" ? colors.primary3 : colors.gray5
              }
            >
              Time Shift Generation
            </Typography>
          </div>
        </LinkComponent>
      </div>
      <div
        onClick={() => {
          !setupCompleted.completed ? toastIncompleteError() : doNothing();
        }}
      >
        <LinkComponent
          link={setupCompleted.completed ? "/admin/activity-logs" : ""}
        >
          <div
            className={
              active === "activity logs"
                ? `${styles.sidebar_item} ${styles.sidebar_item_active}`
                : styles.sidebar_item
            }
          >
            <Typography
              textAlign="left"
              textWeight={active === "activity logs" ? "600" : "500"}
              textColor={
                active === "activity logs" ? colors.primary3 : colors.gray5
              }
            >
              Activity Log
            </Typography>
          </div>
        </LinkComponent>
      </div>
    </div>
  );
};

export default AdminSideBar;
