import React, { useState } from "react";
import Typography from "../../../../Common/Typography";
import deleteIcon from "../../../../../assets/svg/error-icon.svg";
import styles from "./index.module.css";
import Button from "../../../../Form/Button";
import colors from "../../../../../theme/colors";
import { useAppDispatch } from "../../../../../redux/hooks";
import { deleteRequest } from "../../../../../api/request";
import { CREATE_EMPLOYEE_URL } from "../../../../../api/urls";
import toast from "react-hot-toast";
import {
  deleteCurrentEmployee,
  deleteEmployeeId,
  getEmployeeId,
} from "../../../../../utils/localstorage";
import {
  setShowDeleteEmployeeModal,
  updateEmployeStateCounter,
} from "../../../../../redux/slice/staff-hub/employeeSlice";
import { toastSuccess } from "../../../../../utils/toast";

const DeleteEmployeeModal: React.FC = () => {
  const [requestLoading, setRequestLoading] = useState(false);
  const dispatch = useAppDispatch();
  const id = getEmployeeId();
  const deleteAction = async () => {
    setRequestLoading(true);
    const response = await deleteRequest(`${CREATE_EMPLOYEE_URL}/${id}`);
    if (response?.success) {
      toastSuccess("Employee Deleted");
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    deleteEmployeeId();
    deleteCurrentEmployee();
    setRequestLoading(false);
    dispatch(setShowDeleteEmployeeModal(false));
    dispatch(updateEmployeStateCounter());
  };
  const cancelAction = () => {
    dispatch(setShowDeleteEmployeeModal(false));
  };
  return (
    <div className={styles.container}>
      <img src={deleteIcon} alt="cancel icon" className={styles.cancelImage} />
      <Typography textWeight="600" textSize="18px" textAlign="left">
        Delete Employee
      </Typography>
      <div className={styles.mid_text}>
        <Typography textColor={colors.gray4} textAlign="left">
          Are you sure you want to delete this record?
        </Typography>
        <Typography
          textColor={colors.gray4}
          textAlign="left"
          marginVertical="-8px"
        >
          This action cannot be undone.
        </Typography>
      </div>
      <div className={styles.button_container}>
        <Button
          width="160px"
          text="Cancel"
          isPrimary={false}
          onClick={cancelAction}
          backgroundColor={colors.transparent}
          isPrimaryBorder={true}
        />
        <Button
          width="160px"
          text="Delete"
          backgroundColor={colors.error}
          textColor={colors.white}
          isPrimary={false}
          onClick={deleteAction}
          isLoading={requestLoading}
        />
      </div>
    </div>
  );
};

export default DeleteEmployeeModal;
