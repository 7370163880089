import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { getRequest } from "../../../../../api/request";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import colors from "../../../../../theme/colors";
import Typography from "../../../../Common/Typography";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import PageBackButton from "../../../../Common/PageBackButton";
import showSelectedLetter from "../../../../../utils/showSelectedLetters";
import ConfirmIdentityModal from "../../../../StaffHub/Employee/ViewProfile/ConfirmIdentityModal";

const ActivityEmployeeHrNonUk = () => {
  const id = getActivityBasicInformation();
  const [showTIN, setShowTIN] = useState(false);
  const [showConfirmIdentityModal, setShowConfirmIdentityModal] =
    useState(false);

  const [hr, setHr] = useState({
    employeeNumber: "",
    tin: "",
    payGrade: "",
    payrollGroup: "",
  });
  const [previousHr, setPreviousHr] = useState({
    employeeNumber: "",
    tin: "",
    payGrade: "",
    payrollGroup: "",
  });
  useEffect(() => {
    const getActivityEmployeeContactInformation = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        setHr({
          employeeNumber: response?.data?.newRecord?.hrRecord?.employeeNumber,
          tin: response?.data?.newRecord?.hrRecord?.tin,
          payGrade: response?.data?.newRecord?.hrRecord?.payGrade,
          payrollGroup: response?.data?.hrRecord?.newRecord?.payrollGroup,
        });
        setPreviousHr({
          employeeNumber:
            response?.data?.previousRecord?.hrRecord?.employeeNumber,
          tin: response?.data?.previousRecord?.hrRecord?.tin,
          payGrade: response?.data?.previousRecord?.hrRecord?.payGrade,
          payrollGroup: response?.data?.previousRecord?.hrRecord?.payrollGroup,
        });
      }
    };
    getActivityEmployeeContactInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              HR Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Employee Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.employeeNumber ? hr.employeeNumber : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">
                  Tax Identification Number
                </Typography>
                <div className={styles.flex_container_button}>
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    {showTIN && hr.tin
                      ? hr?.tin
                      : showTIN && !hr.tin
                        ? "-"
                        : hr.tin
                          ? showSelectedLetter(hr.tin, 3, 2)
                          : "-"}
                  </Typography>
                  <div
                    className={styles.flex_container_button_item}
                    onClick={() => {
                      if (!showTIN) {
                        setShowConfirmIdentityModal(true);
                        // setDataToBeViewed("TIN");
                      } else {
                        setShowTIN(false);
                      }
                    }}
                  >
                    <Typography
                      textColor={colors.primary3}
                      paddingVertical="-5px"
                      marginVertical="0px"
                      textSize="12px"
                      textWeight="600"
                    >
                      {showTIN ? "Hide" : "View"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Pay Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.payGrade ? hr.payGrade : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Payroll Group</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.payrollGroup ? hr.payrollGroup : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Contact Information */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous HR Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Employee Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHr.employeeNumber ? previousHr.employeeNumber : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">
                  Tax Identification Number
                </Typography>
                <div className={styles.flex_container_button}>
                  <Typography textAlign="left" textSize="20px" textWeight="600">
                    {showTIN && previousHr.tin
                      ? previousHr?.tin
                      : showTIN && !previousHr.tin
                        ? "-"
                        : previousHr.tin
                          ? showSelectedLetter(previousHr.tin, 3, 2)
                          : "-"}
                  </Typography>
                  <div
                    className={styles.flex_container_button_item}
                    onClick={() => {
                      if (!showTIN) {
                        setShowConfirmIdentityModal(true);
                        // setDataToBeViewed("TIN");
                      } else {
                        setShowTIN(false);
                      }
                    }}
                  >
                    <Typography
                      textColor={colors.primary3}
                      paddingVertical="-5px"
                      marginVertical="0px"
                      textSize="12px"
                      textWeight="600"
                    >
                      {showTIN ? "Hide" : "View"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Pay Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHr.payGrade ? previousHr.payGrade : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Payroll Group</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousHr.payrollGroup ? previousHr.payrollGroup : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmIdentityModal && (
        <ConfirmIdentityModal
          dataViewed="NIN"
          cancelFunction={() => setShowConfirmIdentityModal(false)}
          successfunction={() => {
            setShowTIN(true);
            setShowConfirmIdentityModal(false);
          }}
        />
      )}
    </>
  );
};

export default ActivityEmployeeHrNonUk;
