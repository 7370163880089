import searchIcon from "assets/svg/search-icon.svg";
import LInkEmployeesToLocationHeader from "components/Client/Site Location/LinkEmployeesToLocation/LInkEmployeesToLocationHeader";
import LinkEmployeesToLocationModal from "components/Client/Site Location/LinkEmployeesToLocation/LinkEmployeesToLocationModal";
import LinkEmployeesToLocationTable from "components/Client/Site Location/LinkEmployeesToLocation/LinkEmployeesToLocationTable";
import DashboardHeader from "components/Common/DashboardHeader";
import EmptyItemState from "components/Common/EmptyItemState";
import PageBackButton from "components/Common/PageBackButton";
import PageLoader from "components/Common/PageLoader";
import PaginatedItems from "components/Common/Pagination";
import ScrollToTop from "components/Common/ScrollToTop";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import { useEffect, useState } from "react";
import colors from "theme/colors";
import { LinkEmployeesToLocationTableItemData } from "types-definition/client/linkToLocation";
import { getLocation, getLocationName } from "utils/localstorage";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getNewState,
  handleReload,
  refetchState,
} from "../../../../redux/slice/staff-hub/linkToLocationSlice";
import styles from "./index.module.css";
import { GET_ALL_EMPLOYEE_URL } from "api/urls";
import { getRequest } from "api/request";

const LinkEmployee = () => {
  // Redux states and hooks
  const dispatch = useAppDispatch();
  const reload = useAppSelector(refetchState);
  const showModal = useAppSelector(getNewState);

  // Localstorage data
  const locationName = getLocationName();

  // React useStates
  const [search, setSearch] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [availableEmployees, setAvailableEmployees] = useState<
    Array<LinkEmployeesToLocationTableItemData>
  >([]);
  const [filteredAvailableEmployees, setFilteredAvailableEmployees] = useState<
    Array<LinkEmployeesToLocationTableItemData>
  >([]);
  const [totalPage, setTotalPage] = useState<number>(0);

  const handlePaginate = (start: number, end: number) => {
    const currentPage = availableEmployees.slice(start, end);
    setFilteredAvailableEmployees(currentPage);
  };

  useEffect(() => {
    const getUnlinkedEmployees = async () => {
      setRequestLoading(true);
      const getCurrentLocationId = getLocation();
      const { data: getAllEmployees } = await getRequest(GET_ALL_EMPLOYEE_URL);

      const unlinkedEmployees = await Promise.all(
        getAllEmployees
          ?.filter(
            (employee: any) =>
              !employee?.locations
                .map((location: any) => location.locationId)
                .includes(getCurrentLocationId),
          )
          ?.map(async (employee: any) => {
            const { data: getAllLocations } = await getRequest(
              `/employee/${employee?.username}/location`,
            );

            const distance = Number(
              Array.isArray(getAllLocations) &&
                getAllLocations?.find(
                  (location: any) =>
                    location.locationId === getCurrentLocationId,
                )?.distance,
            );

            const distanceInMiles = ((distance * 0.62) / 1000).toFixed(2);
            return {
              id: getAllLocations.id, // Does nothing but could not let id be empty
              employeeUsername: employee?.username,
              employeeId: employee?.id,
              employeeName: `${employee?.firstName} ${employee?.lastName}`,
              grade: employee?.grade?.grade,
              distance: distanceInMiles,
              payRate: employee?.hr?.personnelRate ?? 0,
            };
          }),
      );

      setSearch("");
      setAvailableEmployees(unlinkedEmployees);
      setFilteredAvailableEmployees(unlinkedEmployees);
      setRequestLoading(false);
    };

    dispatch(handleReload({ reload: false }));
    getUnlinkedEmployees();
  }, [reload, dispatch]);

  return (
    <>
      {requestLoading ? (
        <PageLoader />
      ) : (
        <section>
          <ScrollToTop />
          <DashboardHeader active="clients" />
          <div className={styles.container}>
            <div className={styles.item_container}>
              <PageBackButton
                width="95%"
                paddingTop="0px"
                marginBottom="20px"
              />
              <div className={styles.flex_item}>
                <div className={styles.item}>
                  <div className={styles.sub_flex_item}>
                    <Typography
                      text="Link Employee To Location"
                      textAlign="left"
                      textWeight="600"
                      textSize="20px"
                    />
                    <Typography
                      textAlign="left"
                      textColor={colors.primary3}
                      textWeight="600"
                      textSize="20px"
                    >
                      {locationName}
                    </Typography>
                  </div>
                  <LInkEmployeesToLocationHeader state="available" />
                </div>
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={search}
                  setValue={(data) => {
                    setSearch(data);
                    if (data) {
                      setFilteredAvailableEmployees(
                        availableEmployees.filter(
                          (employee) =>
                            employee.employeeName
                              .toLowerCase()
                              .search(data.toLowerCase()) !== -1 ||
                            employee.grade
                              .toLowerCase()
                              .search(data.toLowerCase()) !== -1,
                        ),
                      );
                    } else {
                      setFilteredAvailableEmployees(availableEmployees);
                    }
                  }}
                />
              </div>
              {filteredAvailableEmployees.length < 1 ? (
                <div className={styles.emptyspace}>
                  <EmptyItemState
                    text="No employee avaliable for linking"
                    location="employee/basic-info"
                    label="Add Employees"
                  />
                </div>
              ) : (
                <LinkEmployeesToLocationTable
                  data={filteredAvailableEmployees}
                />
              )}
              {totalPage > 5 && (
                <div className={styles.pagination}>
                  <PaginatedItems
                    itemsPerPage={5}
                    items={availableEmployees}
                    handlePaginate={handlePaginate}
                  />
                </div>
              )}
              {showModal && <LinkEmployeesToLocationModal />}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default LinkEmployee;
