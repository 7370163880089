import React from "react";
import Typography from "../../../../../Common/Typography";
import pencil from "../../../../../../assets/svg/pencile-primary.svg";
import styles from "./index.module.css";
import moment from "moment";
import { toggleHolidayMultiplierModal } from "../../../../../../redux/slice/staff-hub/employeeHolidayMultiplierSlice";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { HolidayMultiplerData } from "../../../../../../types-definition/admin";
import { setHoliday } from "../../../../../../utils/localstorage";
import { setHolidayPayRate } from "../../../../../../utils/localstorage";
const HolidayMultiplierTableItem: React.FC<HolidayMultiplerData> = ({
  id,
  holidayDate,
  holidayName,
  payRateMultiplier,
  country,
  chargeRateMultiplier,
}) => {
  const dispatch = useAppDispatch();
  return (
    <tr>
      <td className={styles.td}>
        <Typography
          textAlign="left"
          marginHorizontal="24px"
          marginVertical="16px"
          textWeight="500"
        >
          {moment(holidayDate).format("D MMMM")}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography
          textAlign="left"
          marginHorizontal="24px"
          marginVertical="16px"
          textWeight="500"
        >
          {holidayName}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography
          textAlign="left"
          marginHorizontal="24px"
          marginVertical="16px"
          textWeight="500"
        >
          {country}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography
          textAlign="left"
          marginHorizontal="24px"
          marginVertical="16px"
          textWeight="500"
        >
          {payRateMultiplier}
        </Typography>
      </td>
      <td>
        <div
          onClick={() => {
            dispatch(
              toggleHolidayMultiplierModal({
                showHolidayMultiplierModal: true,
              }),
            );
            setHoliday(id);
            setHolidayPayRate(payRateMultiplier);
          }}
          className={styles.pay_multiplier_icon}
        >
          <img src={pencil} alt="" />
        </div>
      </td>
    </tr>
  );
};

export default HolidayMultiplierTableItem;
