import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import viewProfileIcon from "../../../../../assets/svg/user-profile-icon.svg";
import editProfileIcon from "../../../../../assets/svg/edit-icon.svg";
import binIcon from "../../../../../assets/svg/bin-icon.svg";
import switchHorizontalIcon from "../../../../../assets/svg/switch-horizontal.svg";
import calendarPlusIcon from "../../../../../assets/svg/calendar-plus-icon.svg";
import calenderIcon from "../../../../../assets/svg/calendar-icon.svg";
import clockIcon from "../../../../../assets/svg/clock-icon.svg";
import accrualIcon from "../../../../../assets/svg/accrual-icon.svg";
import auditIcon from "../../../../../assets/svg/audit-icon.svg";
import wristwatchIcon from "../../../../../assets/svg/wristwatch-icon.svg";
import {
  getData,
  getUserTypes,
  setCurrentEmployee,
  setEmployeeId,
  setHolidayMethod,
} from "../../../../../utils/localstorage";
import { useNavigate } from "react-router-dom";
import { EmployeeTableDropdownProps } from "../../../../../types-definition/StaffHub/employee";
import { useAppDispatch } from "../../../../../redux/hooks";
import { setShowDeleteEmployeeModal } from "../../../../../redux/slice/staff-hub/employeeSlice";
import checkAdminPermission from "utils/permission";
import {
  ADD_EMPLOYEE,
  ADMIN,
  EMPLOYEES,
  EMPLOYEES_ABSENCE_READ,
  EMPLOYEE_ABSENCE,
  EMPLOYEE_ADJUST_ACCRUAL_BALANCE,
  EMPLOYEE_HOLIDAY_MULTIPLIER,
  EMPLOYEE_LOCATION_LINKING,
  EMPLOYEE_READ_ONLY,
  EMPLOYEE_UNAVAILABILITY,
  TIME_SHEET,
} from "types-definition/constants";
import { getRequest } from "api/request";
// import { setEmployeeId } from "../../../../utils/localstorage";

const EmployeeTableDropdown: React.FC<EmployeeTableDropdownProps> = ({
  name,
  username,
  id,
  payRate,
  employeeId,
  holidayCalculationMethod,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = JSON.parse(getData() ?? "");
  const handleViewEmployee = () => {
    setCurrentEmployee(JSON.stringify({ name: username, displayName: name }));
    setEmployeeId(employeeId);
    navigate("/employee/view-profile");
  };
  const handleEditEmployee = () => {
    setCurrentEmployee(JSON.stringify({ name: username }));
    navigate("/employee/basic-info");
  };
  const handleTimeSheet = () => {
    setEmployeeId(JSON.stringify({ id: id }));
    setCurrentEmployee(JSON.stringify({ name: username }));
    navigate("/employee/time-sheet");
  };

  const handleAvalability = () => {
    setCurrentEmployee(JSON.stringify({ name: username, displayName: name }));
    setEmployeeId(employeeId);
    navigate("/employee/avalability");
  };

  const handleLinkToLocation = () => {
    setCurrentEmployee(
      JSON.stringify({
        name: username,
        displayName: name,
        employeeId: id,
        payRate: payRate,
      }),
    );
    setEmployeeId(employeeId);
    navigate("/employee/link-to-location");
  };

  const handleHolidayMultiplier = () => {
    setCurrentEmployee(JSON.stringify({ name: username, displayName: name }));
    setEmployeeId(employeeId);
    navigate("/employee/holiday-multiplier");
  };

  const handleHolidayAbsence = () => {
    setCurrentEmployee(
      JSON.stringify({ name: username, displayName: name, employeeId: id }),
    );
    setEmployeeId(id);
    navigate("/employee/holiday-absence");
  };

  const handleDeleteEmployee = () => {
    setCurrentEmployee(
      JSON.stringify({ name: username, displayName: name, employeeId: id }),
    );
    setEmployeeId(employeeId);
    dispatch(setShowDeleteEmployeeModal(true));
  };

  const handleAuditEmployee = () => {
    setCurrentEmployee(
      JSON.stringify({ name: username, displayName: name, employeeId: id }),
    );
    setEmployeeId(id);
    navigate("/employee/audit");
  };

  // Permission Handler
  const [linkToLocationPermission, setLinkToLocationPermission] =
    useState(true);
  const [addEmployee, setAddEmployee] = useState(true);
  const [holidayMultiplierPermission, setHolidayMultiplierPermission] =
    useState(true);
  const [availability, setAvailability] = useState(true);
  const [accrualPermission, setAccrualPermission] = useState(true);
  const [holidayAbsencePermission, setHolidayAbsencePermission] =
    useState(true);
  const [viewEmployeeDetailsPermissions, setViewEmployeeDetailsPermissions] =
    useState(true);
  const [timeSheetPermission, setTimeSheetPermission] = useState(true);
  const [employeePermission, setEmployeePermission] = useState(true);
  useEffect(() => {
    const getPermission = async () => {
      const data = checkAdminPermission(getUserTypes());
      if (data) {
        setLinkToLocationPermission(false);
        setHolidayMultiplierPermission(false);
        setAvailability(false);
        setAddEmployee(false);
        setAccrualPermission(false);
        setHolidayAbsencePermission(false);
        setViewEmployeeDetailsPermissions(false);
        setTimeSheetPermission(false);
        setEmployeePermission(false);
      } else {
        const response = await getRequest(
          `/settings/user/${user?.id}/permission`,
        );

        const permissions: Array<string> = response?.data.map(
          (item: any) => item.name,
        );
        const result = permissions?.some((p) =>
          [EMPLOYEE_LOCATION_LINKING].includes(p),
        );
        const addEMployeePermission = permissions?.some((p) =>
          [ADD_EMPLOYEE].includes(p),
        );
        const employeePermission = permissions?.some((p) =>
          [EMPLOYEES].includes(p),
        );
        const holidayMultiplierResult = permissions?.some((p) =>
          [EMPLOYEE_HOLIDAY_MULTIPLIER].includes(p),
        );
        const holidayAvailability = permissions?.some((p) =>
          [EMPLOYEE_UNAVAILABILITY].includes(p),
        );
        const accrualPermission = permissions?.some((p) =>
          [EMPLOYEE_ADJUST_ACCRUAL_BALANCE].includes(p),
        );
        const holidayAbsencePermission = permissions?.some((p) =>
          [EMPLOYEES_ABSENCE_READ].includes(p),
        );
        const viewEmployeePermission = permissions?.some((p) =>
          [EMPLOYEE_READ_ONLY, EMPLOYEES].includes(p),
        );

        const timeSheetPermission = permissions?.some((p) =>
          [TIME_SHEET].includes(p),
        );
        setEmployeePermission(employeePermission ? false : true);
        setAddEmployee(addEMployeePermission ? false : true);
        setLinkToLocationPermission(result ? false : true);
        setHolidayMultiplierPermission(holidayMultiplierResult ? false : true);
        setAvailability(holidayAvailability ? false : true);
        setAccrualPermission(accrualPermission ? false : true);
        setHolidayAbsencePermission(holidayAbsencePermission ? false : true);
        setViewEmployeeDetailsPermissions(
          viewEmployeePermission ? false : true,
        );
        setTimeSheetPermission(timeSheetPermission ? false : true);
      }
    };
    getPermission();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div
        className={styles.dropdown_container}
        style={{ top: getUserTypes() === ADMIN ? "-250px" : "-100px" }}
      >
        {!viewEmployeeDetailsPermissions && (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              handleViewEmployee();
            }}
          >
            <img
              src={viewProfileIcon}
              alt="view-profile"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              View Profile
            </Typography>
          </div>
        )}
        {!addEmployee && (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              handleEditEmployee();
            }}
          >
            <img
              src={editProfileIcon}
              alt="view-profile"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              Edit Employee
            </Typography>
          </div>
        )}

        {!linkToLocationPermission && (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              // dispatch(setShowClonePermissionGroupModal({ show: true, id: id }));
              // handleHideModal();
              handleLinkToLocation();
            }}
          >
            <img
              src={switchHorizontalIcon}
              alt="switch-horizontal"
              className={styles.dropdown_image_item}
            />

            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              Link to Location
            </Typography>
          </div>
        )}
        {!availability && (
          <div className={styles.dropdown_item} onClick={handleAvalability}>
            <img
              src={clockIcon}
              alt="clock-icon"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              Availability
            </Typography>
          </div>
        )}
        {!timeSheetPermission && (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              handleTimeSheet();
            }}
          >
            <img
              src={wristwatchIcon}
              alt="clock-icon"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              TimeSheet
            </Typography>
          </div>
        )}
        {!holidayAbsencePermission && (
          <div className={styles.dropdown_item} onClick={handleHolidayAbsence}>
            <img
              src={calenderIcon}
              alt="calender-icon"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              Holiday & Absence
            </Typography>
          </div>
        )}
        {!holidayMultiplierPermission && (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              handleHolidayMultiplier();
            }}
          >
            <img
              src={calendarPlusIcon}
              alt="calendar-plus-icon"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              Holiday Multiplier
            </Typography>
          </div>
        )}
        {!accrualPermission && (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              setCurrentEmployee(
                JSON.stringify({
                  name: username,
                  displayName: name,
                  employeeId: id,
                }),
              );
              setHolidayMethod(holidayCalculationMethod);
              setEmployeeId(employeeId);
              navigate("/employee/accrual-tracking");
            }}
          >
            <img
              src={accrualIcon}
              alt="accrual-icon"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              Accrual Tracking
            </Typography>
          </div>
        )}
        {!employeePermission && (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              handleAuditEmployee();
            }}
          >
            <img
              src={auditIcon}
              alt="audit-icon"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              Audit
            </Typography>
          </div>
        )}

        {!addEmployee && (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              handleDeleteEmployee();
            }}
          >
            <img
              src={binIcon}
              alt="view-profile"
              className={styles.dropdown_image_item}
            />
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              Delete Employee
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

export default EmployeeTableDropdown;
