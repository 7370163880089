import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import { useAppDispatch } from "../../../../../redux/hooks";
import { handleReload } from "../../../../../redux/slice/staff-hub/linkToLocationSlice";
import LinkedToLocationPayOption from "../../../../Common/LinkedToLocationPayOption";
import { LinkedToLocationTableItemData } from "../../../../../types-definition/StaffHub/employee/linkToLocation";
import { deleteRequest, patchRequest } from "../../../../../api/request";
import { DELETE_EMPLOYEE_TO_LOCATION } from "../../../../../api/urls";
import unRestricIcon from "../../../../../assets/svg/unrestrict-icon.svg";
import { getEmployeeId } from "../../../../../utils/localstorage";
import { PATCH_EMPLOYEE_TO_LOCATION } from "../../../../../api/urls";
import { toastError, toastSuccess } from "../../../../../utils/toast";
const LinkedToLocationTableItem: React.FC<LinkedToLocationTableItemData> = ({
  id,
  location,
  distance,
  payOPtion,
  assign,
  restriction,
  payValue,
  locationId,
}) => {
  const employeeId = getEmployeeId();
  const dispatch = useAppDispatch();
  const handleUnlink = async () => {
    try {
      const response = await deleteRequest(
        `${DELETE_EMPLOYEE_TO_LOCATION}/location/${id}`,
      );
      dispatch(handleReload({ reload: true }));
      if (response?.success) {
        toastSuccess("Employee Unlinked Successfully");
      }
    } finally {
    }
  };

  const restrictLocation = async () => {
    try {
      const response = await patchRequest(
        `${PATCH_EMPLOYEE_TO_LOCATION}/${employeeId}/location/${locationId}/restrict/${id}`,
      );
      if (response?.success) {
        toastSuccess("Location Restricted Successfully");
        dispatch(handleReload({ reload: true }));
      } else {
        toastError(response?.data);
      }
    } finally {
    }
  };

  const handleUnRestrict = async () => {
    try {
      const response = await patchRequest(
        `${DELETE_EMPLOYEE_TO_LOCATION}/${employeeId}/location/${locationId}/un-restrict/${id}`,
      );
      dispatch(handleReload({ reload: true }));
      if (response?.success) {
        toastSuccess("Employee Unrestricted Successfully");
      }
    } finally {
    }
  };

  return (
    <>
      <tr className={styles.tr}>
        <td className={`${styles.td} ${styles.td_name}`}>
          <Typography
            textAlign="left"
            marginHorizontal="24px"
            marginVertical="12px"
            textWeight="600"
          >
            {location}
          </Typography>
        </td>
        <td className={styles.td}>
          <Typography
            textAlign="left"
            marginHorizontal="24px"
            marginVertical="12px"
          >
            {parseFloat(distance) < 1 ? "Less than 1 mile" : distance}
          </Typography>
        </td>
        <td className={styles.td}>
          <Typography
            textAlign="left"
            marginHorizontal="24px"
            marginVertical="12px"
          >
            {payValue}
          </Typography>
        </td>
        <td className={styles.td}>
          <LinkedToLocationPayOption payOption={payOPtion} />
        </td>
        <td className={styles.td}>
          {restriction ? (
            <img
              src={unRestricIcon}
              alt="icon"
              style={{
                marginLeft: "24px",
              }}
            />
          ) : (
            <div
              onClick={() => {
                restrictLocation();
              }}
              style={{ width: "100%" }}
            >
              <Typography
                textWeight="600"
                textColor="#d42620"
                textAlign="left"
                marginHorizontal="24px"
                style={{
                  cursor: "pointer",
                }}
              >
                Restrict
              </Typography>
            </div>
          )}
        </td>
        <td className={styles.td}>
          <div className={styles.flex_item}>
            {!restriction ? (
              <div
                onClick={() => {
                  handleUnlink();
                }}
              >
                <Typography
                  textWeight="600"
                  textColor="#148A9b"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Unlink
                </Typography>
              </div>
            ) : (
              <div
                onClick={() => {
                  handleUnRestrict();
                }}
              >
                <Typography
                  textWeight="600"
                  textColor="#148A9b"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Unrestrict
                </Typography>
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default LinkedToLocationTableItem;
