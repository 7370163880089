import React from "react";
import styles from "./index.module.css";
import TextInput from "../../../Form/TextInput";
import { AdminGeneralSettingsFormProps } from "../../../../types-definition/admin";
import Checkbox from "../../../Form/Checkbox";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";

const SettingsGeneralForm: React.FC<AdminGeneralSettingsFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return (
    <div className={styles.container}>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.name}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, name: data };
            })
          }
          label="Name"
          placeholder="Enter Name"
          disabled={true}
          required={true}
        />
        <TextInput
          value={formData.officePhoneNumber}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, officePhoneNumber: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, officePhoneNumber: false };
            });
          }}
          error={requiredFormData.officePhoneNumber}
          helperText={
            requiredFormData.officePhoneNumber
              ? "Office Phone Number is required"
              : ""
          }
          label="Company Phone Number"
          placeholder="Enter Company Phone Number"
          required={true}
        />
      </div>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.officeEmailAddress}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, officeEmailAddress: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, officeEmailAddress: false };
            });
          }}
          error={requiredFormData.officeEmailAddress}
          helperText={
            requiredFormData.officeEmailAddress
              ? "Office Email Address is required"
              : ""
          }
          label="Company Email Address"
          placeholder="Enter Company Email Address"
          required={true}
        />
        <TextInput
          value={formData.administratorEmail}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, administratorEmail: data };
            })
          }
          label="Administrator Email"
          placeholder="Enter Administrator Email"
          disabled={true}
          required={true}
        />
      </div>

      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.shiftStartProximityRadius}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, shiftStartProximityRadius: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, shiftStartProximityRadius: false };
            });
          }}
          label="Shift Start Proximity Radius (metres)"
          placeholder="Enter Shift Start Proximity Radius"
          isToolTip
          toolTipText="Sets maximum distance for clocking in via the app. Exceeding this distance prevents check-in and alerts the manager."
          required={true}
          error={requiredFormData.shiftStartProximityRadius}
        />
        <TextInput
          value={formData.shiftEndProximityRadius}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, shiftEndProximityRadius: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, shiftEndProximityRadius: false };
            });
          }}
          label="Shift End Proximity Radius (metres)"
          placeholder="Enter End Shift Proximity Radius"
          isToolTip
          required={true}
          toolTipText="Defines the allowed distance for clocking out. Alerts manager by email if this limit is exceeded and reports clock out location"
          error={requiredFormData.shiftEndProximityRadius}
        />
      </div>

      <div className={styles.form_flex_container}>
        {/* <Checkbox
          marginVertical="20px"
          value={formData.autoDisplayOfTagInfo}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, autoDisplayOfTagInfo: data };
            })
          }
          label={
            <Typography
              textAlign="left"
              textWeight="500"
              marginHorizontal="-10px"
              textColor={colors.gray5}
            >
              Auto Display of Tag Info
            </Typography>
          }
          isToolTip
          toolTipText="Automatically shows extra NFC tag details upon scanning, requiring employee acknowledgment"
        /> */}
        <Checkbox
          marginVertical="20px"
          value={formData.randomiseEmployeePasscode}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, randomiseEmployeePasscode: data };
            })
          }
          label={
            <Typography
              textAlign="left"
              textWeight="500"
              marginHorizontal="-10px"
              textColor={colors.gray5}
            >
              Randomise Employee Passcode
            </Typography>
          }
          isToolTip
          toolTipText="Automatically generates random PINs for new employee accounts for enhanced security."
        />
      </div>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.checkCallProximityRadius}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, checkCallProximityRadius: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, checkCallProximityRadius: false };
            });
          }}
          label="Check Call Proximity Radius (metres)"
          placeholder="Enter Check Call Proximity Radius"
          isToolTip
          toolTipText="Triggers an email alert if check-ins occur outside a set proximity, indicating location and distance."
          error={requiredFormData.checkCallProximityRadius}
          required={true}
        />
        <TextInput
          required={true}
          value={formData.proximityRadiusEmail}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, proximityRadiusEmail: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, proximityRadiusEmail: false };
            });
          }}
          error={requiredFormData.proximityRadiusEmail}
          label="Proximity Radius Email"
          placeholder="Enter Proximity Radius Email"
          isToolTip
          toolTipText="Set additional email recipients for alerts on radius breaches. Separate emails with commas. (e.g.,  john.doe@example.com, jane.smith@example.com.)"
        />
      </div>
    </div>
  );
};

export default SettingsGeneralForm;
