import { useEffect, useRef, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import colors from "theme/colors";
import { getRequest } from "api/request";
import { ACTIVE_BOARD } from "api/urls";
import PageLoader from "components/Common/PageLoader";
import { getClient, getClientName } from "utils/localstorage";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import SelectInput from "components/Form/SelectInput";
import ClientTimesheetReportTable from "components/Client/Client/ClientTimesheetReportTable";
import { ClientTimesheetReportData } from "types-definition/client";
import moment from "moment";
import PageBackButton from "components/Common/PageBackButton";
import searchIcon from "assets/svg/search-icon.svg";
import { toastError } from "utils/toast";
import ClientSelectInput from "components/Form/ClientSelectInput";
import { compareHoursWorked, getTimeDifference } from "utils/timeConverter";
import stringToDate from "utils/formatDate";
import ClientTimeSheetTableFilter from "components/Client/Client/ClientTimeSheetTableFilter";
import { ENDED, STARTED } from "types-definition/constants";

const ClientTimesheetReport = () => {
  const clientName = getClientName();
  const clientId = getClient();

  const [sortFilter, setSortFilter] = useState("");
  const [sortFilterName, setSortFilterName] = useState("");
  const [isfilter, setIsfilter] = useState(false);
  const [searchedValues, setSearchedValues] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [locationData, setLocationData] = useState<Array<any>>([]);
  const [date, setDate] = useState<{ name: string; value: string }>({
    name: "",
    value: "",
  });
  const [location, setLocation] = useState("");
  const [day, setDay] = useState(0);
  const [clientTimesheetData, setClientTimesheetData] = useState<
    Array<ClientTimesheetReportData>
  >([]);
  const [allClientTimesheetData, setAllClientTimesheetData] = useState<
    Array<ClientTimesheetReportData>
  >([]);
  const [filterClientTimesheetData, setFilterClientTimesheetData] = useState<
    Array<ClientTimesheetReportData>
  >([]);
  useEffect(() => {
    const getAllLocations = async () => {
      const response = await getRequest(`client/${clientId}/location`);
      if (response?.success) {
        setLocationData(
          response?.data?.map((item: any) => {
            return {
              id: item.id,
              name: item.name,
            };
          }),
        );
      } else {
        toastError(response?.data);
      }
    };
    getAllLocations();
  }, []);

  const getClientData = async (days: number, location: string) => {
    try {
      setRequestLoading(true);
      const response = await getRequest(
        `${ACTIVE_BOARD}/client/${clientId}?from=${moment()
          .subtract(days, "days")
          .toISOString()}&to=${moment().toISOString()}`,
      );
      if (response?.success) {
        const data = response?.data
          .filter((item: any) => {
            return item.location.name === location && item.status === ENDED;
          })
          .sort(
            (a: any, b: any) => moment(b?.date).unix() - moment(a?.date).unix(),
          );

        const clientDataArray: Array<ClientTimesheetReportData> = [];
        for (const index in data) {
          let extractedStartTime;
          let extractedEndTime;
          if (
            moment(
              moment(data[index].employeeStartTime).format("HH:mm"),
              "HH:mm",
            ).isAfter(moment(data[index].startTime, "HH:mm"))
          ) {
            extractedStartTime = moment(data[index].employeeStartTime).format(
              "HH:mm",
            );
          } else {
            extractedStartTime = data[index].startTime;
          }
          // end time
          const employeeEndTime = moment(data[index].employeeEndTime).format(
            "HH:mm",
          );

          if (
            moment(
              moment(data[index].employeeEndTime).format("HH:mm"),
              "HH:mm",
            ).isAfter(moment(data[index].endTime, "HH:mm")) ||
            employeeEndTime === "00:00"
          ) {
            extractedEndTime = data[index].endTime;
          } else {
            extractedEndTime = moment(data[index].employeeEndTime).format(
              "HH:mm",
            );
          }
          const employeeDataObject = {
            employeeName: data[index].employee?.firstName
              ? `${data[index].employee?.firstName} ${data[index].employee?.lastName}`
              : "N/A",
            manager: data[index]?.location?.managedBy
              ? JSON.parse(data[index]?.location?.managedBy).name
              : "N/A",
            area: data[index]?.location?.areaName || "N/A",
            location: data[index].location?.name,
            date: data[index].date,
            hoursWorked:
              data[index].employeeStartTime && data[index].employeeEndTime
                ? getTimeDifference(extractedStartTime, extractedEndTime)
                : "N/A",
            status: data[index].status,
            started:
              data[index].status === STARTED || data[index].status === ENDED,
            ended: data[index].status === ENDED,
          };
          clientDataArray.push(employeeDataObject);
        }
       
        setClientTimesheetData(
          clientDataArray.sort(
            (a, b) =>
              new Date(stringToDate(a.date)).getTime() -
              new Date(stringToDate(b.date)).getTime(),
          ),
        );
        // setAllClientTimesheetData(
        //   response?.data
        //     ?.sort(
        //       (a: any, b: any) =>
        //         new Date(stringToDate(a.date)).getTime() -
        //         new Date(stringToDate(b.date)).getTime(),
        //     )
        //     .map((data: any) => {
        //       return {
        //         employeeName: data?.employee?.firstName
        //           ? `${data?.employee?.firstName} ${data?.employee?.lastName}`
        //           : "N/A",
        //         manager: data?.location?.managedBy
        //           ? JSON.parse(data?.location?.managedBy).name
        //           : "N/A",
        //         area: data?.location?.areaName ?? "N/A",
        //         location: data?.location?.name,
        //         date: data.date,
        //         hoursWorked:
        //           data.employeeStartTime && data.employeeEndTime
        //             ? getTimeDifference(
        //                 moment(data.employeeStartTime).format("HH:mm"),
        //                 moment(data.employeeEndTime).format("HH:mm"),
        //               )
        //             : "N/A",
        //         status: data.status,
        //         started: data.status === STARTED || data.status === ENDED,
        //         ended: data.status === ENDED,
        //       };
        //     }),
        // );
      } else {
        if (response?.data)
          toast.error(response?.data, {
            duration: 4000,
            position: "top-center",
          });
      }
    } finally {
      setRequestLoading(false);
    }
  };

  // useEffect(() => {
  //   getClientData();
  // }, [clientId]);

  const filterByLocationName = (name: string) => {
    setLocation(name);
    if (name !== "" && day) {
      getClientData(day, name);
      // if (date.value) {
      //   const day = parseInt(date.value.split(" ")[1]);
      //   const response = allClientTimesheetData.filter((item) => {
      //     return (
      //       item.location === name &&
      //       item.status === ENDED &&
      //       moment(item.date).isBetween(
      //         moment().subtract(day, "days"),
      //         moment(),
      //       )
      //     );
      //   });
      //   setClientTimesheetData(response);
      // } else {
      //   const response = allClientTimesheetData.filter((item) => {
      //     return (
      //       item.location === name &&
      //       item.status === ENDED &&
      //       new Date(item.date).getTime() ===
      //         new Date(new Date().setHours(0, 0, 0, 0)).getTime()
      //     );
      //   });
      //   setClientTimesheetData(response);
      // }
    }
  };

  const handleDateFilter = (data: string) => {
    const day = parseInt(data.split(" ")[1]);
    setDay(day);
    setDate({
      name: `${moment()
        .subtract(day, "days")
        .format("MMM D, YYYY")} - ${moment().format("MMM D, YYYY")}`,
      value: data,
    });
    if (location) {
      getClientData(day, location);
    }

    // setClientTimesheetData(
    //   allClientTimesheetData.filter((item) => {
    //     return (
    //       (location ? item.location === location : true) &&
    //       item.status === ENDED &&
    //       moment(item.date).isBetween(moment().subtract(day, "days"), moment())
    //     );
    //   }),
    // );
  };

  const clearFilter = () => {
    setDate({ name: "", value: "" });
    setClientTimesheetData(
      allClientTimesheetData?.filter(
        (i: any) =>
          i.status === ENDED &&
          new Date(stringToDate(i.date)).getTime() ===
            new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
      ),
    );
  };

  // Clear Location Filter

  const clearLocationFilter = () => {
    setLocation("");
    if (date.value) {
      handleDateFilter(date.value);
    } else {
      setClientTimesheetData(
        allClientTimesheetData?.filter(
          (i: any) =>
            i.status === ENDED &&
            new Date(stringToDate(i.date)).getTime() ===
              new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
        ),
      );
    }
  };

  // Filter Effect Logic
  useEffect(() => {
    if (sortFilter && searchedValues) {
      const localClientData = [...clientTimesheetData];
      setIsfilter(true);
      if (sortFilter === "Ascending Order") {
        setFilterClientTimesheetData(
          localClientData
            .sort(
              (a: ClientTimesheetReportData, b: ClientTimesheetReportData) => {
                if (sortFilterName === "EmployeeName") {
                  return a.employeeName.localeCompare(b.employeeName);
                } else if (sortFilterName === "Area") {
                  return a.area.localeCompare(b.area);
                } else if (sortFilterName === "Location") {
                  return a.location.localeCompare(b.location);
                } else if (sortFilterName === "Manager") {
                  return a.manager.localeCompare(b.manager);
                } else {
                  return compareHoursWorked(a.hoursWorked, b.hoursWorked);
                }
              },
            )
            .filter((data: ClientTimesheetReportData) => {
              return (
                data.area
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.employeeName
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.location
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.manager
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase())
              );
            }),
        );
      } else {
        setFilterClientTimesheetData(
          localClientData
            .sort(
              (a: ClientTimesheetReportData, b: ClientTimesheetReportData) => {
                if (sortFilterName === "EmployeeName") {
                  return b.employeeName.localeCompare(a.employeeName);
                } else if (sortFilterName === "Area") {
                  return b.area.localeCompare(a.area);
                } else if (sortFilterName === "Location") {
                  return b.location.localeCompare(a.location);
                } else if (sortFilterName === "Manager") {
                  return b.manager.localeCompare(a.manager);
                } else {
                  return compareHoursWorked(b.hoursWorked, a.hoursWorked);
                }
              },
            )
            .filter((data: ClientTimesheetReportData) => {
              return (
                data.area
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.employeeName
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.location
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase()) ||
                data.manager
                  .toLowerCase()
                  .includes(searchedValues.toLowerCase())
              );
            }),
        );
      }
    } else if (searchedValues) {
      setIsfilter(true);
      setFilterClientTimesheetData(
        clientTimesheetData.filter((data: ClientTimesheetReportData) => {
          return (
            data.area.toLowerCase().includes(searchedValues.toLowerCase()) ||
            data.employeeName
              .toLowerCase()
              .includes(searchedValues.toLowerCase()) ||
            data.location
              .toLowerCase()
              .includes(searchedValues.toLowerCase()) ||
            data.manager.toLowerCase().includes(searchedValues.toLowerCase())
          );
        }),
      );
    } else if (sortFilter) {
      setIsfilter(true);
      if (sortFilter === "Ascending Order") {
        const localClientData = [...clientTimesheetData];
        setFilterClientTimesheetData(
          localClientData.sort(
            (a: ClientTimesheetReportData, b: ClientTimesheetReportData) => {
              if (sortFilterName === "EmployeeName") {
                return a.employeeName.localeCompare(b.employeeName);
              } else if (sortFilterName === "Area") {
                return a.area.localeCompare(b.area);
              } else if (sortFilterName === "Location") {
                return a.location.localeCompare(b.location);
              } else if (sortFilterName === "Manager") {
                return a.manager.localeCompare(b.manager);
              } else {
                return compareHoursWorked(a.hoursWorked, b.hoursWorked);
              }
            },
          ),
        );
      } else {
        const localClientData = [...clientTimesheetData];
        setFilterClientTimesheetData(
          localClientData.sort(
            (a: ClientTimesheetReportData, b: ClientTimesheetReportData) => {
              if (sortFilterName === "EmployeeName") {
                return b.employeeName.localeCompare(a.employeeName);
              } else if (sortFilterName === "Area") {
                return b.area.localeCompare(a.area);
              } else if (sortFilterName === "Location") {
                return b.location.localeCompare(a.location);
              } else if (sortFilterName === "Manager") {
                return b.manager.localeCompare(a.manager);
              } else {
                return compareHoursWorked(b.hoursWorked, a.hoursWorked);
              }
            },
          ),
        );
      }
    } else {
      setIsfilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValues, sortFilter]);

  return (
    <>
      <ScrollToTop />
      {requestLoading ? (
        <PageLoader />
      ) : (
        <section>
          <DashboardHeader active="clients" />
          <div className={styles.container}>
            <div
              className={`${styles.item_container} ${styles.item_container_fill}`}
            >
              <PageBackButton width="90%" paddingTop="0" marginBottom="20px" />

              <div className={styles.flex_item}>
                <div>
                  <div className={styles.sub_flex_item}>
                    <Typography
                      textAlign="left"
                      textWeight="900"
                      textSize="20px"
                      textColor={colors.primary3}
                    >
                      {`${clientName}'s`} Timesheet Report - &nbsp;
                    </Typography>
                    <Typography
                      textAlign="left"
                      textWeight="900"
                      textSize="20px"
                    >
                      All Employees
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "250px",
                    }}
                    className={styles.sub_flex_item}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                      }}
                      className={styles.date_filter_container}
                    >
                      {" "}
                      <ClientSelectInput
                        datalistTop="54px"
                        dropdownTop="16px"
                        value={location}
                        client={location}
                        setValue={(name, id) => {
                          filterByLocationName(name);
                        }}
                        label=""
                        placeholder="Location Name"
                        options={locationData}
                      />
                      {location && (
                        <div
                          className={styles.clear_filter}
                          onClick={clearLocationFilter}
                        >
                          <Typography
                            textColor={colors.primary3}
                            textWeight="600"
                            textSize="12px"
                          >
                            Clear Filter
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className={styles.date_filter_container}>
                      {" "}
                      <SelectInput
                        datalistTop="54px"
                        dropdownTop="16px"
                        width="230px"
                        value={date.name}
                        setValue={(data) => {
                          handleDateFilter(data);
                        }}
                        readonly
                        label=""
                        placeholder={`${moment()
                          .subtract(30, "days")
                          .format("MMM D, YYYY")} - ${moment().format(
                          "MMM D, YYYY",
                        )}`}
                        options={[
                          "Last 15 days",
                          "Last 30 days",
                          "Last 90 days",
                        ]}
                      />
                      {date.name && (
                        <div
                          className={styles.clear_filter}
                          onClick={() => clearFilter()}
                        >
                          <Typography
                            textColor={colors.primary3}
                            textWeight="600"
                            textSize="12px"
                          >
                            Clear Filter
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.sub_flex_item}>
                  {/* <TableFilter /> */}
                  <ClientTimeSheetTableFilter
                    clearFilter={() => setSortFilter("")}
                    runFilter={(data, name) => {
                      setSortFilter(data);
                      setSortFilterName(name);
                    }}
                  />
                  <div className={styles.sub_sub_flex_item}>
                    <TextInput
                      left={<img src={searchIcon} alt="search" />}
                      placeholder="Search"
                      value={searchedValues}
                      setValue={(value) => {
                        setSearchedValues(value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <ClientTimesheetReportTable
                data={
                  isfilter ? filterClientTimesheetData : clientTimesheetData
                }
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ClientTimesheetReport;
