import { getRequest } from "api/request";
import { toastError } from "./toast";
import { GET_GRADE_URL } from "api/urls";

export const getGradeSlected = async (gradeId: string) => {
  const response = await getRequest(`${GET_GRADE_URL}/${gradeId}`);
  if (response.success) {
    return response.data;
  } else {
    toastError(response?.data);
  }
};
