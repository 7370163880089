import React from "react";
import { CheckcallReportTableProps } from "types-definition/report";
import colors from "../../../../theme/colors";
import Typography from "../../../Common/Typography";
import CheckcallReportTableItemGroup from "../CheckcallReportTableItemGroup";
import styles from "./index.module.css";

const CheckcallReportTable: React.FC<CheckcallReportTableProps> = ({
  data = [],
}) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Employee Name
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Location
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Location Manager
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Services
              </Typography>
            </th>
            <th className={styles.th} style={{ width: "8%" }}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Attempt
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                CheckCall Date
              </Typography>
            </th>
            <th className={styles.th_index}></th>
          </tr>
        </thead>
        <tbody>
          {/* {data?.map((item, index) => {
            return <CheckcallReportTableItem {...item} key={item.id} />;
          })} */}

          {data?.map((groupedCheckcall, index) => {
            return (
              <CheckcallReportTableItemGroup
                groupId={groupedCheckcall[0]}
                checkcalls={groupedCheckcall[1]}
                key={groupedCheckcall[0]}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CheckcallReportTable;
