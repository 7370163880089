import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import activeEditIcon from "../../../../assets/svg/active-edit-icon.svg";
import { HolidayMultiplerData } from "../../../../types-definition/admin";
import colors from "../../../../theme/colors";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  setShowDeleteHolidayMultiplierModal,
  setShowEditHolidayMultiplierModal,
  setShowEditRateHolidayMultiplierModal,
} from "../../../../redux/slice/admin/holidayMultiplierSlice";
import TableEditDropdown from "../../../Common/TableEditDropdown";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import moment from "moment";

const HolidayMultiplierTableItem: React.FC<HolidayMultiplerData> = ({
  id,
  holidayName,
  holidayDate,
  country,
  payRateMultiplier,
  chargeRateMultiplier,
  flag,
  isDefault = false,
}) => {
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const handleHideModal = () => {
    setshowEditDropdown(false);
  };
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography
          textAlign="left"
          textColor={colors.tableText}
          style={{ flex: 1 }}
        >
          {moment(holidayDate).format("DD/MM/YYYY")}
        </Typography>
      </td>
      <td className={styles.td_mail}>
        <Typography textAlign="left">{holidayName ?? "N/A"}</Typography>
      </td>

      <td className={styles.td_flex}>
        <img src={flag} alt="country" className={styles.country_flag} />
        <Typography textAlign="left">{country}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{payRateMultiplier}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{chargeRateMultiplier}</Typography>
      </td>
      <td className={`${styles.td} ${styles.td_relative}`} tabIndex={0}>
        <div
          className={styles.image_container}
          onClick={() =>
            dispatch(setShowEditRateHolidayMultiplierModal({ show: true, id }))
          }
        >
          <img src={activeEditIcon} alt="selector-icon" />
        </div>
      </td>
      <td
        className={`${styles.td} ${styles.td_relative}`}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        {showEditDropdown &&
          (!isDefault ? (
            <TableEditDropdown
              data={[
                {
                  label: "Edit",
                  action: () => {
                    dispatch(
                      setShowEditHolidayMultiplierModal({ show: true, id }),
                    );
                    handleHideModal();
                  },
                },
                {
                  label: "Delete",
                  action: () => {
                    dispatch(
                      setShowDeleteHolidayMultiplierModal({ show: true, id }),
                    );
                    handleHideModal();
                  },
                },
              ]}
            />
          ) : (
            <TableEditDropdown
              data={[
                {
                  label: "Edit",
                  action: () => {
                    dispatch(
                      setShowEditHolidayMultiplierModal({ show: true, id }),
                    );
                    handleHideModal();
                  },
                },
              ]}
            />
          ))}
        <div
          className={styles.selector_image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default HolidayMultiplierTableItem;
