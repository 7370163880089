import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { setCheckpointData } from "redux/slice/report/checkpointReportSlice";
import { CheckpointReportData } from "types-definition/report";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import { setCheckpointReportData as setLocalStorageCheckpointData } from "utils/localstorage";
import { getRequest } from "api/request";

const CheckpointReportTableItem: React.FC<CheckpointReportData> = ({
  id,
  checkpointName,
  frequency,
  time,
  service,
  location,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [gradeName, setGradeName] = useState(service?.grade);

  useEffect(() => {
      const getGrade = async () => {
      const response = await getRequest(`/settings/grade/${service?.grade}`);
      setGradeName(response?.data?.grade);
    };

    getGrade();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left">{checkpointName}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{frequency}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{time}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{service.name}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{location.name}</Typography>
      </td>
      <td className={styles.td}>
        <div
          onClick={() => {
            const data = {
              checkpointId: id,
              checkpointName,
              serviceId: service.id,
              serviceName: service.name,
              serviceGradeName: gradeName,
              shiftStart: service.shiftStart,
              locationId: location.id,
              locationName: location.name,
              frequency: frequency,
              interval: time,
            };
            dispatch(setCheckpointData(data));
            setLocalStorageCheckpointData(JSON.stringify(data));
            navigate("/reports/checkpoint/checkins");
          }}
          className={styles.view__badge}
        >
          View Check-ins
        </div>
      </td>
    </tr>
  );
};

export default CheckpointReportTableItem;
