import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import chevronLeftIcon from "../../../../../assets/svg/chevron-left.svg";
import chevronRightIcon from "../../../../../assets/svg/chevron-right.svg";
import { WeekData } from "../../../../../types-definition/common";
import plusIcon from "../../../../../assets/svg/plus-icon.svg";
import minusIcon from "../../../../../assets/svg/cancel-icon.svg";
import clockIcon from "../../../../../assets/svg/clock-white-icon.svg";
import colors from "../../../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  getEmployeeAvalabilityCounter,
  setShowAvalabilityModal,
  setShowDeleteAvailabilityModal,
} from "../../../../../redux/slice/staff-hub/employeeSlice";
import { getRequest } from "../../../../../api/request";
import { getEmployeeId } from "../../../../../utils/localstorage";
import toast from "react-hot-toast";
import Button from "../../../../Form/Button";
import convertTimeTo12hours from "../../../../../utils/convertTime";

const MonthCalendar: React.FC = () => {
  //   const date = new Date("2023-11-01");
  const dispatch = useAppDispatch();
  const id = getEmployeeId();
  const counter = useAppSelector(getEmployeeAvalabilityCounter);
  const [date, setDate] = useState(new Date());
  const [unavaialableDates, setUnavaialableDates] = useState<Array<any>>([]);
  const year = date.getFullYear();
  const month = date.getMonth();
  const [firstWeek, setfirstWeek] = useState<Array<WeekData>>([]);
  const [secondWeek, setsecondWeek] = useState<Array<WeekData>>([]);
  const [thirdWeek, setthirdWeek] = useState<Array<WeekData>>([]);
  const [fourthWeek, setfourthWeek] = useState<Array<WeekData>>([]);
  const [fifthWeek, setfifthWeek] = useState<Array<WeekData>>([]);
  const [sixthWeek, setsixthWeek] = useState<Array<WeekData>>([]);
  // Array of month names
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //   const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  useEffect(() => {
    // Get the first day of the month
    const dayone = new Date(year, month, 1).getDay();

    // Get the last date of the month
    const lastdate = new Date(year, month + 1, 0).getDate();

    // Get the day of the last date of the month
    const dayend = new Date(year, month, lastdate).getDay();

    // Get the last date of the previous month
    const monthlastdate = new Date(year, month, 0).getDate();
    if (dayone === 0) {
      const firstweek: WeekData[] = [];
      for (let i = 0; i <= 6; i++) {
        if (i < 6) {
          firstweek.push({
            // text: monthlastdate - (monthlastdate + 6 - i - 1),
            text: monthlastdate - (6 - i),
            active: false,
            date: "",
          });
        } else {
          firstweek.push({
            text: 1,
            active: true,
            date: new Date(year, month),
          });
        }
      }
      setfirstWeek(firstweek);

      const secondweek: WeekData[] = [];
      for (let i = 2; i <= 8; i++) {
        secondweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setsecondWeek(secondweek);
      const thirdweek: WeekData[] = [];
      for (let i = 9; i <= 15; i++) {
        thirdweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setthirdWeek(thirdweek);
      const fourthweek: WeekData[] = [];
      for (let i = 16; i <= 22; i++) {
        fourthweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setfourthWeek(fourthweek);
      const fifthweek: WeekData[] = [];
      for (let i = 23; i <= 29; i++) {
        fifthweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setfifthWeek(fifthweek);
      const sixthweek: WeekData[] = [];
      for (let i = 30; i <= 36; i++) {
        if (i > lastdate) {
          sixthweek.push({
            text: i - lastdate,
            active: false,
            date: "",
          });
        } else {
          sixthweek.push({
            text: i,
            active: true,
            date: new Date(year, month, i),
          });
        }
      }
      setsixthWeek(sixthweek);
    } else {
      const firstweek: WeekData[] = [];
      for (let i = 1; i <= 7; i++) {
        if (i < dayone) {
          firstweek.push({
            text: monthlastdate - (dayone - i - 1),
            active: false,
            date: "",
          });
        } else {
          firstweek.push({
            text: i - dayone + 1,
            active: true,
            date: new Date(year, month, i - dayone + 1),
          });
        }
      }
      setfirstWeek(firstweek);

      const secondweek: WeekData[] = [];
      for (let i = 9 - dayone; i <= 15 - dayone; i++) {
        secondweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setsecondWeek(secondweek);
      const thirdweek: WeekData[] = [];
      for (let i = 16 - dayone; i <= 22 - dayone; i++) {
        thirdweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setthirdWeek(thirdweek);
      const fourthweek: WeekData[] = [];
      for (let i = 23 - dayone; i <= 29 - dayone; i++) {
        fourthweek.push({
          text: i,
          active: true,
          date: new Date(year, month, i),
        });
      }
      setfourthWeek(fourthweek);
      const fifthweek: WeekData[] = [];
      if (dayend === 0) {
        for (let i = 30 - dayone; i <= 36 - dayone; i++) {
          fifthweek.push({
            text: i,
            active: true,
            date: new Date(year, month, i),
          });
        }
        setfifthWeek(fifthweek);
      } else {
        if (dayone === 0 || (dayone === 6 && lastdate === 31)) {
          for (let i = 30 - dayone; i <= 36 - dayone; i++) {
            if (i > lastdate) {
              fifthweek.push({
                text: i - lastdate,
                active: false,
                date: "",
              });
            } else {
              fifthweek.push({
                text: i,
                active: true,
                date: new Date(year, month, i),
              });
            }
          }
          setfifthWeek(fifthweek);
          const sixthweek: WeekData[] = [];
          for (let i = 37 - dayone; i <= 43 - dayone; i++) {
            if (i > lastdate) {
              sixthweek.push({ text: i - lastdate, active: false, date: "" });
            } else {
              sixthweek.push({
                text: i,
                active: true,
                date: new Date(year, month, i),
              });
            }
          }
          setsixthWeek(sixthweek);
        } else {
          if (lastdate - dayone + 7 - (30 - dayone) < 6) {
            for (let i = 30 - dayone; i <= lastdate - dayone + 2; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          }
          if (lastdate - dayone + 7 - (30 - dayone) < 7) {
            for (let i = 30 - dayone; i < lastdate - dayone + 8; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          } else if (lastdate - dayone + 7 - (30 - dayone) > 7) {
            for (let i = 30 - dayone; i < lastdate - dayone + 6; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          } else {
            for (let i = 30 - dayone; i < lastdate - dayone + 7; i++) {
              if (i > lastdate) {
                fifthweek.push({ text: i - lastdate, active: false, date: "" });
              } else {
                fifthweek.push({
                  text: i,
                  active: true,
                  date: new Date(year, month, i),
                });
              }
            }
          }
          setfifthWeek(fifthweek);
          setsixthWeek([]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);
  const handleDateUpdate = (action: "increase" | "decrease") => {
    if (action === "increase") {
      if (month === 11) {
        setDate(new Date(year + 1, 0, 1));
      } else {
        setDate(new Date(year, month + 1, 1));
      }
    } else {
      if (month === 0) {
        setDate(new Date(year - 1, 11, 1));
      } else {
        setDate(new Date(year, month - 1, 1));
      }
    }
  };

  const handleSetToday = () => {
    setDate(new Date());
  };

  useEffect(() => {
    getUnavailableDates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  const getUnavailableDates = async () => {
    const response = await getRequest(`/employee/${id}/unavailability`);
    if (response?.success) {
      setUnavaialableDates(response.data);
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
  };
  const handleDeleteUnavalibity = async (id: string, segmentId: string) => {
    dispatch(setShowDeleteAvailabilityModal({ show: true, id, segmentId }));
  };
  return (
    <div className={styles.container}>
      <div className={styles.button_container}>
        <Button
          isPrimary={false}
          backgroundColor={colors.transparent}
          onClick={handleSetToday}
          text="Today"
          textColor={colors.primary3}
          borderColor={colors.primary3}
          borderWidth="2px"
          width="100px"
          paddingVertical="5px"
          marginVertical="0px"
        />
      </div>
      <div className={styles.toptext}>
        <img
          src={chevronLeftIcon}
          alt="left-icon"
          onClick={() => handleDateUpdate("decrease")}
        />
        <Typography textWeight="600" textSize="16px">
          {months[month]} {year}
        </Typography>
        <img
          src={chevronRightIcon}
          alt="right-icon"
          onClick={() => handleDateUpdate("increase")}
        />
      </div>
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tr}>
              <th className={styles.th}>
                <Typography textWeight="600">Mo</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Tu</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">We</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Th</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Fr</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Sat</Typography>
              </th>
              <th className={styles.th}>
                <Typography textWeight="600">Sun</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {firstWeek.map((item, index) => {
                const date = unavaialableDates.find((i: any) => {
                  return (
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      parseInt(i.year),
                      parseInt(i.month) - 1,
                      parseInt(i.day),
                    ).getTime()
                  );
                });
                return (
                  <td
                    className={
                      date ? `${styles.td_active} ${styles.td}` : styles.td
                    }
                    key={index}
                  >
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>
                    {/* Show button only when month calendar item date is valid */}
                    {/* Loops through the available dates and checks if date is available to displau plut or remove icon */}
                    {item.date &&
                      (date ? (
                        <img
                          src={minusIcon}
                          className={styles.crossIcon}
                          alt="add-icon"
                          onClick={() => {
                            handleDeleteUnavalibity(date.id, date.segmentId);
                          }}
                        />
                      ) : (
                        <img
                          src={plusIcon}
                          className={styles.addIcon}
                          alt="add-icon"
                          onClick={() =>
                            dispatch(
                              setShowAvalabilityModal({
                                show: true,
                                minDate: `${new Date(
                                  item.date,
                                ).getFullYear()}-${
                                  new Date(item.date).getMonth() + 1 > 9
                                    ? new Date(item.date).getMonth() + 1
                                    : `0${new Date(item.date).getMonth() + 1}`
                                }-${
                                  new Date(item.date).getDate() > 9
                                    ? new Date(item.date).getDate()
                                    : `0${new Date(item.date).getDate()}`
                                }`,
                              }),
                            )
                          }
                        />
                      ))}

                    {/* Loops through the available dates and checks if date is available to display info */}
                    {date && (
                      <div className={styles.td_details}>
                        <img src={clockIcon} alt="clock-icon" />
                        <div>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            {convertTimeTo12hours(date.fromTime)} -{" "}
                            {convertTimeTo12hours(date.toTime)}
                          </Typography>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            Not Available
                          </Typography>
                        </div>
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              {secondWeek.map((item, index) => {
                const date = unavaialableDates.find((i: any) => {
                  return (
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      parseInt(i.year),
                      parseInt(i.month) - 1,
                      parseInt(i.day),
                    ).getTime()
                  );
                });
                return (
                  <td
                    className={
                      date ? `${styles.td_active} ${styles.td}` : styles.td
                    }
                    key={index}
                  >
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>
                    {/* Loops through the available dates and checks if date is available to displau plut or remove icon */}
                    {date ? (
                      <img
                        src={minusIcon}
                        className={styles.crossIcon}
                        alt="add-icon"
                        onClick={() => {
                          handleDeleteUnavalibity(date.id, date.segmentId);
                        }}
                      />
                    ) : (
                      <img
                        src={plusIcon}
                        className={styles.addIcon}
                        alt="add-icon"
                        onClick={() =>
                          dispatch(
                            setShowAvalabilityModal({
                              show: true,
                              minDate: `${new Date(item.date).getFullYear()}-${
                                new Date(item.date).getMonth() + 1 > 9
                                  ? new Date(item.date).getMonth() + 1
                                  : `0${new Date(item.date).getMonth() + 1}`
                              }-${
                                new Date(item.date).getDate() > 9
                                  ? new Date(item.date).getDate()
                                  : `0${new Date(item.date).getDate()}`
                              }`,
                            }),
                          )
                        }
                      />
                    )}
                    {/* Loops through the available dates and checks if date is available to display info */}
                    {date && (
                      <div className={styles.td_details}>
                        <img src={clockIcon} alt="clock-icon" />
                        <div>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            {convertTimeTo12hours(date.fromTime)} -{" "}
                            {convertTimeTo12hours(date.toTime)}
                          </Typography>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            Not Available
                          </Typography>
                        </div>
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              {thirdWeek.map((item, index) => {
                const date = unavaialableDates.find((i: any) => {
                  return (
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      parseInt(i.year),
                      parseInt(i.month) - 1,
                      parseInt(i.day),
                    ).getTime()
                  );
                });
                return (
                  <td
                    className={
                      date ? `${styles.td_active} ${styles.td}` : styles.td
                    }
                    key={index}
                  >
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>
                    {/* Loops through the available dates and checks if date is available to displau plut or remove icon */}
                    {date ? (
                      <img
                        src={minusIcon}
                        className={styles.crossIcon}
                        alt="add-icon"
                        onClick={() => {
                          handleDeleteUnavalibity(date.id, date.segmentId);
                        }}
                      />
                    ) : (
                      <img
                        src={plusIcon}
                        className={styles.addIcon}
                        alt="add-icon"
                        onClick={() =>
                          dispatch(
                            setShowAvalabilityModal({
                              show: true,
                              minDate: `${new Date(item.date).getFullYear()}-${
                                new Date(item.date).getMonth() + 1 > 9
                                  ? new Date(item.date).getMonth() + 1
                                  : `0${new Date(item.date).getMonth() + 1}`
                              }-${
                                new Date(item.date).getDate() > 9
                                  ? new Date(item.date).getDate()
                                  : `0${new Date(item.date).getDate()}`
                              }`,
                            }),
                          )
                        }
                      />
                    )}
                    {date && (
                      <div className={styles.td_details}>
                        <img src={clockIcon} alt="clock-icon" />
                        <div>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            {convertTimeTo12hours(date.fromTime)} -{" "}
                            {convertTimeTo12hours(date.toTime)}
                          </Typography>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            Not Available
                          </Typography>
                        </div>
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              {fourthWeek.map((item, index) => {
                const date = unavaialableDates.find((i: any) => {
                  return (
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      parseInt(i.year),
                      parseInt(i.month) - 1,
                      parseInt(i.day),
                    ).getTime()
                  );
                });
                return (
                  <td
                    className={
                      date ? `${styles.td_active} ${styles.td}` : styles.td
                    }
                    key={index}
                  >
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>
                    {/* Loops through the available dates and checks if date is available to displau plut or remove icon */}
                    {date ? (
                      <img
                        src={minusIcon}
                        className={styles.crossIcon}
                        alt="add-icon"
                        onClick={() => {
                          handleDeleteUnavalibity(date.id, date.segmentId);
                        }}
                      />
                    ) : (
                      <img
                        src={plusIcon}
                        className={styles.addIcon}
                        alt="add-icon"
                        onClick={() =>
                          dispatch(
                            setShowAvalabilityModal({
                              show: true,
                              minDate: `${new Date(item.date).getFullYear()}-${
                                new Date(item.date).getMonth() + 1 > 9
                                  ? new Date(item.date).getMonth() + 1
                                  : `0${new Date(item.date).getMonth() + 1}`
                              }-${
                                new Date(item.date).getDate() > 9
                                  ? new Date(item.date).getDate()
                                  : `0${new Date(item.date).getDate()}`
                              }`,
                            }),
                          )
                        }
                      />
                    )}
                    {date && (
                      <div className={styles.td_details}>
                        <img src={clockIcon} alt="clock-icon" />
                        <div>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            {convertTimeTo12hours(date.fromTime)} -{" "}
                            {convertTimeTo12hours(date.toTime)}
                          </Typography>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            Not Available
                          </Typography>
                        </div>
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              {fifthWeek.map((item, index) => {
                const date = unavaialableDates.find((i: any) => {
                  return (
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      parseInt(i.year),
                      parseInt(i.month) - 1,
                      parseInt(i.day),
                    ).getTime()
                  );
                });
                return (
                  <td
                    className={
                      date ? `${styles.td_active} ${styles.td}` : styles.td
                    }
                    key={index}
                  >
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>
                    {/* Loops through the available dates and checks if date is available to displau plut or remove icon */}
                    {item.date &&
                      (date ? (
                        <img
                          src={minusIcon}
                          className={styles.crossIcon}
                          alt="add-icon"
                          onClick={() => {
                            handleDeleteUnavalibity(date.id, date.segmentId);
                          }}
                        />
                      ) : (
                        <img
                          src={plusIcon}
                          className={styles.addIcon}
                          alt="add-icon"
                          onClick={() =>
                            dispatch(
                              setShowAvalabilityModal({
                                show: true,
                                minDate: `${new Date(
                                  item.date,
                                ).getFullYear()}-${
                                  new Date(item.date).getMonth() + 1 > 9
                                    ? new Date(item.date).getMonth() + 1
                                    : `0${new Date(item.date).getMonth() + 1}`
                                }-${
                                  new Date(item.date).getDate() > 9
                                    ? new Date(item.date).getDate()
                                    : `0${new Date(item.date).getDate()}`
                                }`,
                              }),
                            )
                          }
                        />
                      ))}
                    {date && (
                      <div className={styles.td_details}>
                        <img src={clockIcon} alt="clock-icon" />
                        <div>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            {convertTimeTo12hours(date.fromTime)} -{" "}
                            {convertTimeTo12hours(date.toTime)}
                          </Typography>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            Not Available
                          </Typography>
                        </div>
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              {sixthWeek.map((item, index) => {
                const date = unavaialableDates.find((i: any) => {
                  return (
                    new Date(
                      new Date(item.date).getFullYear(),
                      new Date(item.date).getMonth(),
                      new Date(item.date).getDate(),
                    ).getTime() ===
                    new Date(
                      parseInt(i.year),
                      parseInt(i.month) - 1,
                      parseInt(i.day),
                    ).getTime()
                  );
                });
                return (
                  <td
                    className={
                      date ? `${styles.td_active} ${styles.td}` : styles.td
                    }
                    key={index}
                  >
                    <Typography
                      textColor={item.active ? colors.black : colors.gray1}
                    >
                      {item.text}
                    </Typography>
                    {/* Loops through the available dates and checks if date is available to displau plut or remove icon */}
                    {item.date &&
                      (date ? (
                        <img
                          src={minusIcon}
                          className={styles.crossIcon}
                          alt="add-icon"
                          onClick={() => {
                            handleDeleteUnavalibity(date.id, date.segmentId);
                          }}
                        />
                      ) : (
                        <img
                          src={plusIcon}
                          className={styles.addIcon}
                          alt="add-icon"
                          onClick={() =>
                            dispatch(
                              setShowAvalabilityModal({
                                show: true,
                                minDate: `${new Date(
                                  item.date,
                                ).getFullYear()}-${
                                  new Date(item.date).getMonth() + 1 > 9
                                    ? new Date(item.date).getMonth() + 1
                                    : `0${new Date(item.date).getMonth() + 1}`
                                }-${
                                  new Date(item.date).getDate() > 9
                                    ? new Date(item.date).getDate()
                                    : `0${new Date(item.date).getDate()}`
                                }`,
                              }),
                            )
                          }
                        />
                      ))}
                    {date && (
                      <div className={styles.td_details}>
                        <img src={clockIcon} alt="clock-icon" />
                        <div>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            {convertTimeTo12hours(date.fromTime)} -{" "}
                            {convertTimeTo12hours(date.toTime)}
                          </Typography>
                          <Typography
                            textColor={colors.white}
                            marginVertical="0px"
                            textSize="12px"
                          >
                            Not Available
                          </Typography>
                        </div>
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MonthCalendar;
