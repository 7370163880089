import React, { useRef, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import TextInput from "../../../../Form/TextInput";
import cancelIcon from "../../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  getDeliveryModeState,
  getEmployeeIndividualId,
  getMessageEmployeeEntryMode,
  getSelectedEmployees,
  setShowSendMessageModal,
} from "../../../../../redux/slice/staff-hub/employeeMessageSlice";
import Button from "../../../../Form/Button";
import LongTextInput from "../../../../Form/LongTextInput";
import { postRequest } from "../../../../../api/request";
import { MESSAGE_EMPLOYEE } from "../../../../../api/urls";
import attachmentIcon from "../../../../../assets/svg/attachment-icon.svg";
import { toastError, toastSuccess } from "../../../../../utils/toast";
import colors from "../../../../../theme/colors";
import {
  EmployeeSendMessageRequiredFormData,
  SendMessageFormData,
} from "../../../../../types-definition/StaffHub/employee";
import fieldTextValidator from "../../../../../utils/validateFieldsWithText";
import scrolltoView from "utils/scrollToView";

const SendMessageModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedEmployees = useAppSelector(getSelectedEmployees);
  const messageDeliveryMode = useAppSelector(getDeliveryModeState);
  const messageEmployeeEntrymode = useAppSelector(getMessageEmployeeEntryMode);
  const selectedEmployeeId = useAppSelector(getEmployeeIndividualId);
  const inputRef = useRef<HTMLInputElement>(null);
  const [requestLoading, setRequestLoading] = useState(false);
  const [formData, setFormData] = useState<SendMessageFormData>({
    subject: "",
    message: "",
    fileSelected: "",
  });
  const [requiredFormData, setRequiredFormData] =
    useState<EmployeeSendMessageRequiredFormData>({
      message: {
        status: false,
        text: "",
      },
      subject: {
        status: false,
        text: "",
      },
    });
  const handleMessageEmployee = async () => {
    setRequestLoading(true);
    const { data, status, id } = fieldTextValidator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      if (id) {
        scrolltoView(id);
      }
      setRequestLoading(false);
      toastError("Fill required details");
      return;
    }
    const requestData = new FormData();
    if (formData.fileSelected) {
      requestData.append("files", formData.fileSelected);
    }
    requestData.append("message", formData.message);
    requestData.append("subject", formData.subject);
    requestData.append(
      "employees",
      messageEmployeeEntrymode === "group"
        ? JSON.stringify(selectedEmployees)
        : JSON.stringify([selectedEmployeeId]),
    );
    if (messageDeliveryMode.includes("mail")) {
      requestData.append("email", "true");
    }
    if (messageDeliveryMode.includes("app")) {
      requestData.append("app", "true");
    }
    // {
    //   messagePayload: {
    //     subject: formData.subject,
    //     message: formData.message,
    //   },
    //   employees:
    //     messageEmployeeEntrymode === "group"
    //       ? selectedEmployees
    //       : [selectedEmployeeId],
    //   method: {
    //     email: messageDeliveryMode.includes("mail") ? true : false,
    //     app: messageDeliveryMode.includes("app") ? true : false,
    //   },
    // }
    const response = await postRequest(MESSAGE_EMPLOYEE, requestData);
    if (response?.success) {
      dispatch(setShowSendMessageModal(false));
      toastSuccess("Message Sent Successfully");
    } else {
      toastError(response?.data);
    }
    setRequestLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600" textSize="18px">
            New Message
          </Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() => dispatch(setShowSendMessageModal(false))}
          />
        </div>
        <div className={styles.form_container}>
          <div className={styles.form_item_container}>
            <TextInput
              label="Subject"
              value={formData.subject}
              setValue={(data) => {
                setFormData({ ...formData, subject: data });
                setRequiredFormData((prevData) => {
                  return { ...prevData, subject: { status: false, text: "" } };
                });
              }}
              placeholder=""
              helperText={requiredFormData.subject.text}
              error={requiredFormData.subject.status}
              id="subject"
            />
          </div>

          <div className={styles.form_item_container}>
            <LongTextInput
              label="Message"
              value={formData.message}
              setValue={(data) => {
                setFormData({ ...formData, message: data });
                setRequiredFormData((prevData) => {
                  return { ...prevData, message: { status: false, text: "" } };
                });
              }}
              placeholder="Enter Message"
              helperText={requiredFormData.message.text}
              error={requiredFormData.message.status}
              id="message"
            />
          </div>

          <input
            type="file"
            hidden
            ref={inputRef}
            onChange={(e) => {
              const extension = e.target.files![0].name.replace(/^.*\./, "");
              if (
                extension.toLowerCase() !== "jpg" &&
                extension.toLowerCase() !== "jpeg" &&
                extension.toLowerCase() !== "png" &&
                extension.toLowerCase() !== "svg"
              ) {
                toastError("Only Image upload supported");
                return;
              }
              setFormData({ ...formData, fileSelected: e.target.files![0] });
            }}
          />
          <div
            className={styles.file_input}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click();
              }
            }}
          >
            <img src={attachmentIcon} alt="attachment-icon" />
            <Typography textColor={colors.gray3}>
              {formData.fileSelected
                ? formData.fileSelected.name
                : "Select File"}
            </Typography>
          </div>
          <Button
            isPrimary
            text="Send Message"
            isLoading={requestLoading}
            onClick={handleMessageEmployee}
          />
        </div>
      </div>
    </div>
  );
};

export default SendMessageModal;
