import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { ClientEmployeeShiftData } from "../../../../types-definition/client";

import colors from "../../../../theme/colors";
import convertTimeTo12hours from "utils/convertTime";

const ClientEmployeeShiftTableItem: React.FC<ClientEmployeeShiftData> = ({
  id,
  endTime,
  expectedTime,
  location,
  name,
  services,
  type,
}) => {
  return (
    <tr className={styles.tr}>
      <td className={styles.td_index}></td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {expectedTime ? convertTimeTo12hours(expectedTime) : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {endTime ? convertTimeTo12hours(endTime) : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {location}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray1}>
          {services}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.primary3}>
          {name}
        </Typography>
      </td>
    </tr>
  );
};

export default ClientEmployeeShiftTableItem;
