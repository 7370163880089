import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slice/auth/authSlice";
import employeeSlice from "./slice/staff-hub/employeeSlice";
import adminSlice from "./slice/admin/adminSlice";
import gradeSlice from "./slice/admin/gradeSlice";
import certificateGroupSlice from "./slice/staff-hub/certificateGroupSlice";
import serviceGroupSlice from "./slice/client/serviceGroupSlice";
import showTimeShiftGenerationModal from "./slice/admin/timeShiftGeneration";
import certificateType from "./slice/staff-hub/certificateTypeSlice";
import webuserSlice from "./slice/admin/webuserSlice";
import linkTolocationSlice from "./slice/staff-hub/linkToLocationSlice";
import holidayMultiplierSlice from "./slice/admin/holidayMultiplierSlice";
import employeeHolidayMultiplier from "./slice/staff-hub/employeeHolidayMultiplierSlice";
import clientSlice from "./slice/client/clientSlice";
import employeeBasicInfoSlice from "./slice/staff-hub/employeeBasicInfoSlice";
import manualCheckPointReasonSlice from "./slice/admin/manualCheckpointReasons";
import logoutTimerSlice from "./slice/common/logoutTimerSlice";
import sidebarSlice from "./slice/common/sidebarSlice";
import employeeAccrualModalSlice from "./slice/staff-hub/employeeAccrualSettingSlice";
import employeeMessageSlice from "./slice/staff-hub/employeeMessageSlice";
import emailReportSlice from "./slice/client/emailReportSlice";
import employeeViewProfileSlice from "./slice/staff-hub/employeeViewProfileSlice";
import rosterSlice from "./slice/roster/rosterSlice";
import holidayAbsenceSlice from "./slice/HolidayAbsence/holidayAbsenceSlice";
import incidentReportSlice from "./slice/client/incidentReport";
import ShiftNote from "./slice/client/shiftNote";
import locationSlice from "./slice/client/locationSlice";
import checkpointSlice from "./slice/client/checkpointSlice";
import serviceSlice from "./slice/client/serviceSlice";
import areaSlice from "./slice/client/areaSlice";
import wizardSlice from "./slice/WizardSetup";
import checkcallLogSlice from "./slice/report/checkcallLogSlice";
import statisticsSlice from "./slice/common/statisticsSlice";
import checkpointReportSlice from "./slice/report/checkpointReportSlice";
const store = configureStore({
  reducer: {
    auth: authSlice,
    employee: employeeSlice,
    admin: adminSlice,
    grade: gradeSlice,
    certificateGroup: certificateGroupSlice,
    serviceGroup: serviceGroupSlice,
    timeShiftGeneration: showTimeShiftGenerationModal,
    certificateType: certificateType,
    webuserSlice: webuserSlice,
    linkToLocation: linkTolocationSlice,
    holidayMultiplier: holidayMultiplierSlice,
    employeeHolidayMultiplier: employeeHolidayMultiplier,
    client: clientSlice,
    employeeBasicInfo: employeeBasicInfoSlice,
    manualCheckPointReasons: manualCheckPointReasonSlice,
    logoutTimer: logoutTimerSlice,
    sidebar: sidebarSlice,
    accrual: employeeAccrualModalSlice,
    employeeMessage: employeeMessageSlice,
    emailReport: emailReportSlice,
    employeeViewProfile: employeeViewProfileSlice,
    roster: rosterSlice,
    holidayAbsence: holidayAbsenceSlice,
    incidentReport: incidentReportSlice,
    shiftNote: ShiftNote,
    location: locationSlice,
    checkpoints: checkpointSlice,
    service: serviceSlice,
    area: areaSlice,
    wizard: wizardSlice,
    checkcallLog: checkcallLogSlice,
    checkpointReport: checkpointReportSlice,
    statistics: statisticsSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
