import React from "react";
import Typography from "../../Common/Typography";
import styles from "./index.module.css";
import colors from "../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getRosterView,
  setRosterFromDate,
  setRosterView,
} from "../../../redux/slice/roster/rosterSlice";
import { getFirstDayOfDate } from "../../../utils/timeConverter";

const RosterViewNavigation = () => {
  const active = useAppSelector(getRosterView);
  const dispatch = useAppDispatch();
  return (
    <div className={styles.container}>
      <div
        className={
          active === "service-view"
            ? styles.active_container_item
            : styles.container_item_left
        }
        onClick={() => {
          dispatch(setRosterView("service-view"));
          dispatch(setRosterFromDate(getFirstDayOfDate(new Date())));
        }}
      >
        <Typography
          textWeight="500"
          fontFamily="inter"
          textColor={active === "service-view" ? colors.white : colors.black}
        >
          Service View
        </Typography>
      </div>
      <div
        className={
          active === "calendar-view"
            ? styles.active_container_item_right
            : styles.container_item_right
        }
        onClick={() => {
          dispatch(setRosterView("calendar-view"));
        }}
      >
        <Typography
          textWeight="500"
          fontFamily="inter"
          textColor={active === "calendar-view" ? colors.white : colors.black}
        >
          Calendar View
        </Typography>
      </div>
    </div>
  );
};

export default RosterViewNavigation;
