import React from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import circleCancelIcon from "../../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import Button from "../../../../Form/Button";
import {
  getAvalabilityType,
  setAvalabilityType,
  setShowAvalabilityTypeModal,
} from "../../../../../redux/slice/staff-hub/employeeSlice";
import RadioInput from "../../../../Form/RadioInput";

const EmployeeAvalabilityTypeModal = () => {
  const dispatch = useAppDispatch();
  const avalabilityType = useAppSelector(getAvalabilityType);

  return (
    <section className={styles.container}>
      <div className={styles.flex_container}>
        <Typography textWeight="600" textSize="20px">
          Select Availability Type
        </Typography>
        <img
          src={circleCancelIcon}
          alt="cancel-icon"
          onClick={() => dispatch(setShowAvalabilityTypeModal(false))}
        />
      </div>
      <div className={styles.form_container}>
        <RadioInput
          label="One Time Availability"
          value={avalabilityType === "one-time" ? true : false}
          setValue={(data) => {
            if (data) {
              dispatch(setAvalabilityType("one-time"));
            }
          }}
          name="avalability-type"
        />
        <RadioInput
          label="Recurring Avalability"
          value={avalabilityType === "recurring" ? true : false}
          setValue={(data) => {
            if (data) {
              dispatch(setAvalabilityType("recurring"));
            }
          }}
          name="avalability-type"
        />
      </div>

      <div className={styles.button_container}>
        <Button
          text="Cancel"
          isPrimary={false}
          isPrimaryBorder
          backgroundColor="transparent"
          onClick={() => dispatch(setShowAvalabilityTypeModal(false))}
          marginHorizontal="5px"
        />
        <Button
          text="Continue"
          isPrimary
          marginHorizontal="5px"
          onClick={() => dispatch(setShowAvalabilityTypeModal(true))}
        />
      </div>
    </section>
  );
};

export default EmployeeAvalabilityTypeModal;
