import React from "react";
import { ProgressBar } from "../../../types-definition/common";

import styles from "./index.module.css";
const ColorStroke: React.FC<ProgressBar> = ({ color = "primary" }) => {
  return (
    <div
      className={styles.colour_stroke}
      style={{
        backgroundColor: `${color}`,
      }}
    ></div>
  );
};

export default ColorStroke;
