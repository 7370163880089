import { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import EmployeeNavigation from "components/StaffHub/Employee/EmployeeNavigation";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, putRequest } from "api/request";
import { getCountry, getCurrentEmployee } from "utils/localstorage";
import { useNavigate } from "react-router-dom";
import {
  CERTIFICATE_TYPE_URL,
  GET_ALL_EMPLOYEE_URL,
  UPDATE_OPERATIONS_URL,
} from "api/urls";
import {
  EmployeeOperationFormData,
  EmployeeRequiredOperationFormData,
} from "types-definition/StaffHub/employee";
import EmployeeOperationForm from "components/StaffHub/Employee/Operations/EmployeeOperationForm";
import EmployeeCertification from "components/StaffHub/Employee/Certification/EmployeeCertification";
import toast from "react-hot-toast";
import { NG, UK } from "types-definition/constants";
import validator from "utils/validateFields";
import AddEmployeeCertification from "components/StaffHub/Employee/Certification/AddEmployeeCertificationForm";
import ScrollToTop from "components/Common/ScrollToTop";
import {
  getCertification,
  setCertification,
} from "../../../../redux/slice/staff-hub/employeeSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { toastSuccess } from "utils/toast";
import scrolltoView from "utils/scrollToView";
import { CertificateTableData } from "types-definition/StaffHub/certificateType";
import { getGradeSlected } from "utils/getSelectedGrade";

const EmployeeOperations = () => {
  const navigate = useNavigate();
  const country = getCountry();
  const dispatch = useAppDispatch();
  const certifications = useAppSelector(getCertification);
  const [certificateType, setCertificateType] = useState<any>([]);
  const [formData, setFormData] = useState<EmployeeOperationFormData>({
    grade: "",
    gradeId: "",
  });
  const [requiredFormData, setRequiredFormData] =
    useState<EmployeeRequiredOperationFormData>({
      grade: false,
    });

  const [showNewCertificate, setShowNewertificate] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const handleSubmitForm = async () => {
    setRequestLoading(true);
    if (getCurrentEmployee() === null) {
      toast.error("Employee Record Not Found", {
        duration: 4000,
        position: "top-center",
      });
      navigate("/staff-hub");
    }
    const { status, data, id } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      if (id) {
        scrolltoView(id);
      }
      setRequestLoading(false);
      return;
    }
    if (getCurrentEmployee() === null) {
      toast.error("Employee Record Not Found", {
        duration: 4000,
        position: "top-center",
      });
      navigate("/staff-hub");
      return;
    }

    const userData = JSON.parse(getCurrentEmployee()!);
    const response = await putRequest(
      `${UPDATE_OPERATIONS_URL}/${userData.name}`,
      { grade: formData.gradeId },
    );
    if (response?.success) {
      toastSuccess("Operation Data Updated");
      navigate(`/employee/hr`);
    } else {
      toast.error(response?.data.toString(), {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };
  const handleFormCancelation = () => {
    navigate("/staff-hub");
  };

  useEffect(() => {
    const getEmployee = async () => {
      try {
        const data = getCurrentEmployee();
        if (data !== undefined && data !== null) {
          const username = JSON.parse(data).name;

          const response = await getRequest(
            `${GET_ALL_EMPLOYEE_URL}/${username}`,
          );
          if (response?.success) {
            const grade = response.data.operation.grade
              ? await getGradeSlected(response.data.operation.grade)
              : "";
            setFormData({
              grade: grade.grade ? grade.grade : "",
              gradeId: grade.grade ? grade.id : "",
            });
            dispatch(setCertification(response.data.certifications));
          } else {
            if (response?.data)
              toast.error(response?.data, {
                duration: 4000,
                position: "top-center",
              });
          }
        }
      } finally {
      }
    };
    getEmployee();
    setFormData((prevData) => {
      const generatedCode = Math.floor(1000 + Math.random() * 9000);
      return { ...prevData, pin: generatedCode.toString() };
    });
  }, [dispatch]);

  const handleAddNewCertificate = () => {
    setShowNewertificate(true);
  };

  useEffect(() => {
    const getCertificateType = async () => {
      setRequestLoading(true);
      const response = await getRequest(CERTIFICATE_TYPE_URL);
      if (response?.success) {
        const responseData: Array<CertificateTableData> = [];
        for (const index in response?.data?.certificateTypes) {
          const data = {
            id: response?.data?.certificateTypes[index]?.id,
            name: response?.data?.certificateTypes[index]?.certificateName,
            no: "0",
            active: response?.data?.certificateTypes[index]?.active,
            requirementLevel:
              response?.data?.certificateTypes[index]?.requirementLevel,
          };
          responseData.push(data);
        }

        setCertificateType(responseData);
      }
      setRequestLoading(false);
    };
    getCertificateType();
  }, []);
  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="staff/hub" />
      <div className={styles.container}>
        {country === NG ? (
          <div className={styles.item_container}>
            <div className={styles.item_container_text}>
              <Typography textAlign="left" textWeight="600" textSize="24px">
                Add Employee
              </Typography>
            </div>
            <EmployeeNavigation active="operations" />
            <div className={styles.content_container}>
              <EmployeeOperationForm
                formData={formData}
                setFormData={setFormData}
                requiredFormData={requiredFormData}
                setRequiredFormData={setRequiredFormData}
              />
              <div className={styles.button_container}>
                <Button
                  width="200px"
                  text="Cancel"
                  isPrimary={false}
                  onClick={handleFormCancelation}
                  marginHorizontal="10px"
                  backgroundColor={colors.transparent}
                  isPrimaryBorder={true}
                />
                <Button
                  width="200px"
                  text="Save"
                  isPrimary={true}
                  onClick={handleSubmitForm}
                  isLoading={requestLoading}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.item_container}>
              <div className={styles.item_container_text}>
                <Typography textAlign="left" textWeight="600" textSize="24px">
                  Add Employee
                </Typography>
              </div>
              <EmployeeNavigation active="operations" />
              <div className={styles.content_container}>
                <EmployeeOperationForm
                  formData={formData}
                  setFormData={setFormData}
                  requiredFormData={requiredFormData}
                  setRequiredFormData={setRequiredFormData}
                />
              </div>
            </div>
            <div className={styles.sub_item_container}>
              <div className={styles.sub_content_container}>
                <div className={styles.top_text_description}>
                  <Typography textAlign="left" textWeight="600" textSize="24px">
                    Certification
                  </Typography>
                  <div
                    className={styles.add_new_text}
                    onClick={handleAddNewCertificate}
                  >
                    <Typography
                      textWeight="600"
                      style={{
                        background: "linear-gradient(#006093, #00dcda)",
                        WebkitTextFillColor: "transparent",
                        WebkitBackgroundClip: "text",
                      }}
                      textSize="16px"
                    >
                      + Add New
                    </Typography>
                  </div>
                </div>
                {certifications.map((data, index) => {
                  if (index === 0) {
                    return (
                      <EmployeeCertification
                        cerficateData={data}
                        certificateType={certificateType.map(
                          (cerificate: any) => {
                            return { id: cerificate.id, name: cerificate.name };
                          },
                        )}
                      />
                    );
                  } else {
                    return <></>;
                  }
                })}
              </div>
            </div>
            {certifications.map((data, index) => {
              if (index !== 0) {
                return (
                  <div className={styles.sub_item_container} key={index}>
                    <div className={styles.sub_content_container}>
                      <EmployeeCertification
                        cerficateData={data}
                        certificateType={certificateType.map(
                          (cerificate: any) => {
                            return { id: cerificate.id, name: cerificate.name };
                          },
                        )}
                      />
                    </div>
                  </div>
                );
              } else {
                return <></>;
              }
            })}
            {/* {Array.apply(0, Array(numbersOfCheckpointReasons)).map((data, index) => {
              return (
                <div className={styles.sub_item_container} key={index}>
                  <div className={styles.sub_content_container}>
                    <EmployeeCertification />
                  </div>
                </div>
              );
            })} */}
            <div className={styles.button__bottom_container}>
              <Button
                width="200px"
                text="Cancel"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={true}
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </>
        )}
      </div>
      {showNewCertificate && (
        <AddEmployeeCertification
          certificateType={certificateType.map((cerificate: any) => {
            return { id: cerificate.id, name: cerificate.name };
          })}
          cancelFunction={() => setShowNewertificate(false)}
        />
      )}
    </section>
  );
};

export default EmployeeOperations;
