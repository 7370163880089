import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import binIcon from "../../../../assets/svg/bin-icon.svg";
import switchHorizontalIcon from "../../../../assets/svg/switch-horizontal.svg";
import editProfileIcon from "../../../../assets/svg/edit-icon.svg";
import timesheetIcon from "../../../../assets/svg/timesheet.svg";
import reportIcon from "../../../../assets/svg/report.svg";
import shiftnote from "../../../../assets/svg/shift_note.svg";
import eyeIcon from "../../../../assets/svg/eye-open.svg";
import auditIcon from "../../../../assets/svg/audit-icon.svg";
import { useNavigate } from "react-router-dom";
import { handleModal } from "../../../../redux/slice/client/shiftNote";
import { useAppDispatch } from "../../../../redux/hooks";
import { SitLocationDropDownProps } from "../../../../types-definition/client";
import {
  getData,
  getUserTypes,
  setLocation,
  setLocationName,
} from "../../../../utils/localstorage";

import { getRequest } from "../../../../api/request";
import { updateLocationTableState } from "../../../../redux/slice/client/locationSlice";
import checkAdminPermission from "utils/permission";
import {
  EMPLOYEE_LOCATION_LINKING,
  LOCATION_AUDIT,
  LOCATION_EMAIL_REPORT,
  LOCATION_UPDATE_AND_ADD,
  REPORT_INCIDENT_LOCATION,
  ROSTER_READ_ONLY,
  LOCATION_TIMESHEET,
} from "types-definition/constants";

const SitLocationDropDown: React.FC<SitLocationDropDownProps> = ({
  locationId,
  locationName,
  handleCloseDropDown,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(getData() ?? "");
  const handleDelete = async () => {};
  const [auditPermission, setAuditPermission] = useState(true);
  const [timeSheetPermission, setTimesheetPermission] = useState(true);
  const [addLocationPermission, setAddLocationPermission] = useState(true);
  const [linkeEmPloyeeTolocation, setLinkEmployeeToLocationPermission] =
    useState(true);
  const [addLocationEmailPermission, setAddLocationEmailPermission] =
    useState(true);
  const [incidentReportPermission, setIncidentReportPermission] =
    useState(true);
  const [shiftNotePermission, setShiftNotePermission] = useState(true);
  const [rosterPermission, setRoasterPermmision] = useState(true);

  useEffect(() => {
    const getPermission = async () => {
      const data = checkAdminPermission(getUserTypes());
      if (data) {
        setTimesheetPermission(false);
        setAuditPermission(false);
        setIncidentReportPermission(false);
        setAddLocationPermission(false);
        setAddLocationEmailPermission(false);
        setLinkEmployeeToLocationPermission(false);
        setShiftNotePermission(false);
        setRoasterPermmision(false);
      } else {
        const response = await getRequest(
          `/settings/user/${user?.id}/permission`,
        );
        const permissions: Array<string> = response?.data.map(
          (item: any) => item.name,
        );
        const auditResult = permissions?.some((p) =>
          [LOCATION_AUDIT].includes(p),
        );

        const incidentReportResult = permissions?.some((p) =>
          [REPORT_INCIDENT_LOCATION].includes(p),
        );
        const addLocationPermissionCheck = permissions?.some((p) =>
          [LOCATION_UPDATE_AND_ADD].includes(p),
        );

        const addLocationEmailPermissionCheck = permissions?.some((p) =>
          [LOCATION_EMAIL_REPORT].includes(p),
        );

        const addLinkEmployeeToLocationPermissionCheck = permissions?.some(
          (p) => [EMPLOYEE_LOCATION_LINKING].includes(p),
        );

        const viewRosterPermissionCheck = permissions?.some((p) =>
          [ROSTER_READ_ONLY].includes(p),
        );

        const timeSheetPermissionCheck = permissions?.some((p) =>
          [LOCATION_TIMESHEET].includes(p),
        );

        setLinkEmployeeToLocationPermission(
          addLinkEmployeeToLocationPermissionCheck ? false : true,
        );
        setRoasterPermmision(viewRosterPermissionCheck ? false : true);
        setTimesheetPermission(timeSheetPermissionCheck ? false : true);
        setAuditPermission(auditResult ? false : true);
        setAddLocationPermission(addLocationPermissionCheck ? false : true);
        setIncidentReportPermission(incidentReportResult ? false : true);
        setAddLocationEmailPermission(
          addLocationEmailPermissionCheck ? false : true,
        );
      }
    };
    getPermission();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.dropdown_container}>
      {!addLocationPermission && (
        <div
          className={styles.dropdown_item}
          onClick={() => {
            navigate("/client/general");
            setLocation(locationId);
          }}
        >
          <img src={editProfileIcon} alt="profile-icon" />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Edit Location
          </Typography>
        </div>
      )}
      {!addLocationEmailPermission && (
        <div
          className={styles.dropdown_item}
          onClick={() => {
            navigate("/client/mail-report");
            setLocation(locationId);
          }}
        >
          <img src={reportIcon} alt="report-icon" />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Email Report
          </Typography>
        </div>
      )}
      {!shiftNotePermission && (
        <div
          className={styles.dropdown_item}
          onClick={() => {
            setLocation(locationId);
            setLocationName(locationName!);
            dispatch(handleModal({ showModal: true }));
            handleCloseDropDown();
          }}
        >
          <img src={shiftnote} alt="timesheet-icon" />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Add Shift Notes
          </Typography>
        </div>
      )}
      {!timeSheetPermission && (
        <div
          className={styles.dropdown_item}
          onClick={() => {
            navigate("/client/location/timesheet");
            setLocation(locationId);
            setLocationName(locationName!);
          }}
        >
          <img src={timesheetIcon} alt="timesheet-icon" />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Time Sheet Report
          </Typography>
        </div>
      )}
      {!incidentReportPermission && (
        <div
          className={styles.dropdown_item}
          onClick={() => {
            navigate("/client/location/incident-report");
            setLocation(locationId);
            setLocationName(locationName!);
          }}
        >
          <img src={timesheetIcon} alt="timesheet-icon" />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Incident Report
          </Typography>
        </div>
      )}
      {!auditPermission && (
        <div
          className={styles.dropdown_item}
          onClick={() => {
            navigate("/client/location/audit");
            setLocation(locationId);
            setLocationName(locationName!);
          }}
        >
          <img src={auditIcon} alt="audit-icon" />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Audit Location
          </Typography>
        </div>
      )}
      {!linkeEmPloyeeTolocation && (
        <div
          className={styles.dropdown_item}
          onClick={() => {
            navigate("/client/location/link-employee");
            setLocation(locationId);
            setLocationName(locationName!);
          }}
        >
          <img
            src={switchHorizontalIcon}
            alt="switch-horizontal"
            width={18}
            className={styles.dropdown_image_item}
          />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Link Employee
          </Typography>
        </div>
      )}
      {!rosterPermission && (
        <div
          className={styles.dropdown_item}
          onClick={() => {
            navigate("/client/location/roster");
            setLocation(locationId);
          }}
        >
          <img src={eyeIcon} alt="eye-icon" width={18} />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            View Location Roster
          </Typography>
        </div>
      )}
      {!addLocationPermission && (
        <div
          className={styles.dropdown_item}
          onClick={() => {
            handleDelete();
            setLocation(locationId);
            dispatch(updateLocationTableState(true));
          }}
        >
          <img src={binIcon} alt="bin-icon" />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Delete Location
          </Typography>
        </div>
      )}
    </div>
  );
};

export default SitLocationDropDown;
