import React from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import { RosterSingleDataProps } from "../../../types-definition/roster";
import colors from "../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getActiveRosterSingleItem,
  getIsRosterFocused,
  setActiveRosterSingleItem,
  setIsRosterFocused,
  setRosterAvailabilityDate,
  setRosterServiceId,
} from "../../../redux/slice/roster/rosterSlice";
// import stringToDate from "../../../utils/formatDate";
import moment from "moment";

const RosterSingleItem: React.FC<RosterSingleDataProps> = ({
  id,
  name,
  startTime,
  endTime,
  type,
  date,
  serviceId,
}) => {
  const dispatch = useAppDispatch();
  const activeId = useAppSelector(getActiveRosterSingleItem);
  const focused = useAppSelector(getIsRosterFocused);

  return (
    <div
      style={{
        backgroundColor:
          type === "cancelled-shift"
            ? "#FB3A33"
            : type === "completed-shift"
              ? colors.successtext
              : type === "confirmed-shift"
                ? colors.primary3
                : type === "dropped-shift"
                  ? colors.error
                  : type === "in-progress-shift"
                    ? "#3146FF"
                    : type === "unconfirmed-shift"
                      ? "#F3A218"
                      : "#475367",
      }}
      className={`${
        activeId === id && focused
          ? `${styles.container} ${styles.active_border}`
          : styles.container
      }`}
      tabIndex={0}
      onClick={() => {
        dispatch(setActiveRosterSingleItem(id));
        dispatch(setRosterServiceId(serviceId));
        dispatch(setRosterAvailabilityDate(moment.utc(date).toISOString()));
        dispatch(setIsRosterFocused(true));
      }}
      // onBlur={() => {
      //   setTimeout(() => dispatch(setIsRosterFocused(false)), 400);
      // }}
      data-rooster-item={id}
    >
      <Typography textAlign="center" textColor={colors.white}>
        {name}
      </Typography>
      <Typography textAlign="center" textColor={colors.white}>
        {startTime} - {endTime}
      </Typography>
    </div>
  );
};

export default RosterSingleItem;
