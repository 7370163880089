import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import AdminSideBar from "components/Admin/SideBar";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import { getRequest } from "api/request";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import colors from "theme/colors";
import HolidayMultiplierTable from "components/Admin/Holiday Multiplier/HolidayMultiplierTable";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getShowEditHolidayMutiplier,
  getShowAddHolidayMutiplier,
  setShowAddHolidayMultiplierModal,
  getHolidayMutiplierCounter,
  getShowEditHolidayRateMutiplier,
  getShowDeleteHolidayMutiplier,
} from "../../../redux/slice/admin/holidayMultiplierSlice";
import EditHolidayMutiplierModal from "components/Admin/Holiday Multiplier/EditHolidayMultiplierModal";
import EditHolidayRateMutiplierModal from "components/Admin/Holiday Multiplier/EditHolidayRateMultiplier";
import AddHolidayRateMultiplier from "components/Admin/Holiday Multiplier/AddHolidayMultiplier";
import { HolidayMultiplerData } from "types-definition/admin";
import CountrySelectInput from "components/Form/CountrySelectInput";
import { getSettings } from "utils/localstorage";
import DeleteHolidayMultiplier from "components/Admin/Holiday Multiplier/DeleteHolidayMultiplier";
import { toastError } from "utils/toast";
import moment from "moment";
import { US } from "types-definition/constants";
import capitalize from "utils/capitalize";

const HolidayMultiplier: React.FC = () => {
  const dispatch = useAppDispatch();
  const showAddHolidayMultiplierModal = useAppSelector(
    getShowAddHolidayMutiplier,
  );
  const showEditHolidayMultiplierModal = useAppSelector(
    getShowEditHolidayMutiplier,
  );
  const showEditHolidayRateMultiplierModal = useAppSelector(
    getShowEditHolidayRateMutiplier,
  );
  const showDeleteHolidayMultiplier = useAppSelector(
    getShowDeleteHolidayMutiplier,
  );
  const counter = useAppSelector(getHolidayMutiplierCounter);
  const [currentCountryData, setCurrentCountryData] = useState({
    flag: "",
    data: "",
  });
  const [countryData, setcountryData] = useState<Array<any>>([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [holidayMultiplier, setHolidayMultiplier] = useState<
    Array<HolidayMultiplerData>
  >([]);
  useEffect(() => {
    const getUserDetails = async () => {
      setRequestLoading(true);
      const settingsId = getSettings();
      const response = await getRequest(
        `/settings/${settingsId}/holiday-multiplier`,
      );
      if (response?.success) {
        setcountryData([
          {
            data: response?.data[0]?.country
              ? capitalize(response?.data[0]?.country)
              : "",
            flag: response?.data[0]?.image,
          },
        ]);
        setCurrentCountryData({
          data: response?.data[0]?.country
            ? capitalize(response?.data[0]?.country)
            : "",
          flag: response?.data[0]?.image,
        });
        setHolidayMultiplier(
          response?.data.map((item: any) => {
            return {
              id: item?.id,
              holidayDate: item?.holidayDate,
              holidayName: item?.holidayName,
              country: capitalize(item?.country),
              payRateMultiplier: item?.payRateMultiplier,
              chargeRateMultiplier: item?.chargeRateMultiplier,
              flag: item?.image,
              isDefault: item?.defaultHoliday,
            };
          }),
        );
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
      setRequestLoading(false);
    };
    getUserDetails();
  }, [counter]);
  return (
    <>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <AdminSideBar active="holiday multiplier" />
        <div
          className={
            holidayMultiplier.length <= 5
              ? `${styles.item_container} ${styles.item_container_empty}`
              : `${styles.item_container} ${styles.item_container_fill}`
          }
        >
          <div className={styles.flex_item}>
            <div>
              <Typography textAlign="left" textWeight="600" textSize="20px">
                Holiday Multiplier
              </Typography>
            </div>
            <div className={styles.select_container}>
              <CountrySelectInput
                setValue={() => {}}
                value={{
                  data: currentCountryData.data,
                  flag: currentCountryData.flag,
                }}
                label=""
                dropdownTop="18px"
                datalistTop="55px"
                placeholder="Select Country"
                options={countryData}
              />
            </div>
          </div>
          <div className={styles.button_container}>
            <Button
              isPrimary={false}
              isPrimaryBorder={false}
              borderColor={colors.primary3}
              borderWidth="2px"
              text="Add Missing Multipliers"
              textColor={colors.primary3}
              onClick={() => {
                dispatch(setShowAddHolidayMultiplierModal(true));
              }}
              width="250px"
              backgroundColor="transparent"
              textWeight="600"
              paddingVertical="8px"
            />
          </div>
          <div>
            <HolidayMultiplierTable data={holidayMultiplier} />
          </div>
        </div>
      </div>
      {showEditHolidayMultiplierModal && <EditHolidayMutiplierModal />}
      {showEditHolidayRateMultiplierModal && <EditHolidayRateMutiplierModal />}
      {showAddHolidayMultiplierModal && <AddHolidayRateMultiplier />}
      {showDeleteHolidayMultiplier && <DeleteHolidayMultiplier />}
    </>
  );
};

export default HolidayMultiplier;
