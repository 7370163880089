import toast from "react-hot-toast";
import colors from "../theme/colors";

export const toastError = (message?: string) => {
  toast.dismiss();
  toast.error(message ?? "An error ocurred", {
    duration: 4000,
    position: "top-center",
    style: {
      maxWidth: "1000px",
      color: colors.error,
      backgroundColor: colors.errorBg,
    },
  });
};

export const toastSuccess = (message: string) => {
  toast.dismiss();
  toast.success(message, {
    duration: 4000,
    position: "top-center",
    style: {
      maxWidth: "1000px",
      color: colors.successtext,
      backgroundColor: colors.successBg,
    },
  });
};
