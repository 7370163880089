import React from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import { CurrentLocationTimesheetFilterProps } from "../../../../types-definition/StaffHub/employee";

const CurrentLocationTimesheetFilter: React.FC<
  CurrentLocationTimesheetFilterProps
> = ({ hours, billableHour, date }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography textAlign="left" textWeight="600" textSize="16px">
          Current Filter Summary
        </Typography>
        <Typography
          textAlign="left"
          textWeight="400"
          textSize="12px"
          textColor="#667185"
        >
          {date}
        </Typography>
      </div>

      <div className={styles.body}>
        <div className={styles.flex_container}>
          <div className={styles.flex_item}>
            <Typography
              textAlign="center"
              textWeight="600"
              textSize="20px"
              marginVertical="1px"
            >
              {hours}
            </Typography>
            <Typography
              textAlign="center"
              textWeight="600"
              textSize="12px"
              marginVertical="5"
            >
              HOURS
            </Typography>
          </div>
          <div className={styles.flex_item}>
            <Typography
              textAlign="center"
              textWeight="600"
              textSize="20px"
              marginVertical="1px"
            >
              {billableHour}
            </Typography>
            <Typography
              textAlign="center"
              textWeight="600"
              textSize="12px"
              marginVertical="5"
            >
              BILLABLE HOURS
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentLocationTimesheetFilter;
