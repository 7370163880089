import React from "react";
import { LinkEmployeesToLocationTableData } from "types-definition/client/linkToLocation";
import colors from "../../../../../theme/colors";
import Typography from "../../../../Common/Typography";
import LinkEmployeesToLocationTableItem from "../LinkEmployeesToLocationTableItem";
import styles from "./index.module.css";
const LinkEmployeesToLocationTable: React.FC<
  LinkEmployeesToLocationTableData
> = ({ data }) => {
  return (
    <>
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.tr} ${styles.tr_head}`}>
              <th
                className={styles.th}
                style={{
                  width: "30%",
                }}
              >
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="500"
                  marginHorizontal="20px"
                  textSize="12px"
                >
                  Employee Name
                </Typography>
              </th>
              <th
                className={styles.th}
                style={{
                  width: "30%",
                }}
              >
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="500"
                  marginHorizontal="20px"
                  textSize="12px"
                >
                  Grade
                </Typography>
              </th>
              <th
                className={styles.th}
                style={{
                  width: "20%",
                }}
              >
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="500"
                  textColor={colors.tableText}
                  marginHorizontal="20px"
                  textSize="12px"
                >
                  Distance (miles)
                </Typography>
              </th>
              <th
                className={styles.th_index}
                style={{
                  width: "20%",
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <LinkEmployeesToLocationTableItem {...item} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LinkEmployeesToLocationTable;
