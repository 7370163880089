export default function groupBy<T>(
  array: T[],
  callbackFn: (item: T) => string,
): Record<string, T[]> {
  const group: Record<string, T[]> = {};

  for (let checkcall of array) {
    let key = callbackFn(checkcall);

    // if(typeof key === "undefined"){
    //   throw new Error(`Key is undefined`);
    // }

    if (!group[key]) {
      group[key] = [];
    }

    group[key] = [...group[key], checkcall];
  }

  return group;
}
