import React from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import ActivityEmployeeMedicalDetails from "components/Admin/Activity Logs/EmployeeActivityLogs/ActivityEmployeeMedicalDetails";

const ActivityEmployeeMedicalDetailsPage = () => {
  return (
    <div className={styles.main_container}>
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      <ActivityEmployeeMedicalDetails />
    </div>
  );
};

export default ActivityEmployeeMedicalDetailsPage;
