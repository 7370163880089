import { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import circleCancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Button from "../../../Form/Button";
import {
  getCurrentHolidaySelectedId,
  setShowEditRateHolidayMultiplierModal,
  updateFetchedHolidayMultiplierCounter,
} from "../../../../redux/slice/admin/holidayMultiplierSlice";
import NumberInput from "../../../Form/NumberInput";
import Checkbox from "../../../Form/Checkbox";
import { getRequest, patchRequest } from "../../../../api/request";
import { UPDATE_HOLIDAY_MULTIPLIER_URL } from "../../../../api/urls";
import { getSettings } from "../../../../utils/localstorage";
import { toastError, toastSuccess } from "../../../../utils/toast";

const EditHolidayRateMultiplierModal = () => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(getCurrentHolidaySelectedId);
  const [requestLoading, setRequestLoading] = useState(false);
  const [formData, setFormData] = useState({
    payRateMultiplier: "1.00",
    chargeRateMultiplier: "1.00",
    applyToAll: false,
  });

  const handleFormSubmit = async () => {
    setRequestLoading(true);
    const settingsId = getSettings();
    const response = await patchRequest(
      `settings/${settingsId}/holiday-multiplier/holiday/${id}/rate`,
      {
        payRateMultiplier: formData.payRateMultiplier,
        chargeRateMultiplier: formData.chargeRateMultiplier,
        applyRateToAll: formData.applyToAll,
      },
    );
    if (response?.success) {
      toastSuccess("Holiday Rate Multiplier Updated Successfully");
    } else {
      toastError(response?.data);
    }
    setRequestLoading(false);
    dispatch(setShowEditRateHolidayMultiplierModal({ show: false, id: "" }));
    dispatch(updateFetchedHolidayMultiplierCounter());
  };

  useEffect(() => {
    const getHolidayDetails = async () => {
      const response = await getRequest(
        `${UPDATE_HOLIDAY_MULTIPLIER_URL}/${id}`,
      );
      if (response?.success) {
        setFormData((prevData) => {
          return {
            ...prevData,
            payRateMultiplier: response.data.payRateMultiplier,
            chargeRateMultiplier: response.data.chargeRateMultiplier,
          };
        });
      } else {
        toastError(response?.data);
      }
    };
    getHolidayDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.flex_container}>
        <Typography textWeight="600" textSize="20px">
          Edit Rate Multiplier
        </Typography>
        <img
          src={circleCancelIcon}
          alt="cancel-icon"
          onClick={() =>
            dispatch(
              setShowEditRateHolidayMultiplierModal({ show: false, id: "" }),
            )
          }
        />
      </div>
      <div className={styles.form_container}>
        <NumberInput
          label="Pay Rate Multiplier"
          placeholder="1.00"
          value={formData.payRateMultiplier}
          setValue={(data) => {
            return setFormData({ ...formData, payRateMultiplier: data });
          }}
        />
        <NumberInput
          value={formData.chargeRateMultiplier}
          setValue={(data) => {
            return setFormData({
              ...formData,
              chargeRateMultiplier: data,
            });
          }}
          placeholder="1.00"
          label="Charge Rate Multiplier"
          style={{ marginTop: "25px", marginBottom: "25px" }}
        />
        <Checkbox
          value={formData.applyToAll}
          setValue={(data) => {
            return setFormData({ ...formData, applyToAll: data });
          }}
          label={<Typography>Apply rate to all Holidays</Typography>}
        />
      </div>
      <div className={styles.button_container}>
        <Button
          text="Cancel"
          isPrimary={false}
          isPrimaryBorder
          backgroundColor="transparent"
          onClick={() =>
            dispatch(
              setShowEditRateHolidayMultiplierModal({ show: false, id: "" }),
            )
          }
          marginHorizontal="5px"
        />
        <Button
          text="Save"
          isLoading={requestLoading}
          isPrimary
          marginHorizontal="5px"
          onClick={handleFormSubmit}
        />
      </div>
    </section>
  );
};

export default EditHolidayRateMultiplierModal;
