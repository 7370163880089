import DashboardHeader from "components/Common/DashboardHeader";
import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../api/request";
import { GET_USER_DETAILS_URL } from "api/urls";
import { setCompanyName } from "utils/localstorage";
import rocketIcon from "../../assets/svg/rocket-icon.svg";
import PageLoader from "components/Common/PageLoader";
import Step from "components/SetupWizard/SetUpWizardStep";
import Typography from "components/Common/Typography";
import { getWizard } from "utils/setupWizard";
import { getSetupComplete } from "utils/localstorage";
import { useAppSelector } from "redux/hooks";
import { getShowSkipPermissionModal } from "redux/slice/WizardSetup";

const SetUpWizard: React.FC = () => {
  const showSkipPermissionGroupModal = useAppSelector(
    getShowSkipPermissionModal,
  );
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [step, setStep] = useState(1);
  const [arrayStep, setArrayStep] = useState([]);

  const getStep = async () => {
    try {
      setRequestLoading(true);
      const response = await getWizard();
      if (response.success) {
        setArrayStep(response.data);
        response.data.forEach((step: any) => {
          if (step.name === "settings") {
            setStep(2);
          } else if (step.name === "permissiongroup") {
            setStep(3);
          } else if (step.name === "webuser") {
            setStep(4);
          } else if (step.name === "certificatetype") {
            setStep(5);
          } else if (step.name === "certificategroup") {
            setStep(6);
          } else if (step.name === "grade") {
            setStep(7);
          } else if (step.name === "employee") {
            setStep(8);
          } else if (step.name === "client") {
            setStep(9);
          } else if (step.name === "area") {
            setStep(10);
          } else if (step.name === "servicegroup") {
            setStep(11);
          } else if (step.name === "location") {
            setStep(12);
          } else if (step.name === "service") {
            setStep(13);
          } else if (step.name === "holiday") {
            setStep(14);
          }
        });

        setRequestLoading(false);
      } else {
        setRequestLoading(false);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getStep();
  }, [showSkipPermissionGroupModal]);

  const navigate = useNavigate();
  useEffect(() => {
    const setUpStep = getSetupComplete();
    if (setUpStep) {
      setStep(Number(JSON.parse(setUpStep).step));
    }
    const verifyInfoCompletion = async () => {
      const response = await getRequest(GET_USER_DETAILS_URL);
      if (response?.success) {
        if (response.data?.user?.country === null) {
          navigate("/update-account");
        }
        if (response.data?.user?.company?.companyName === null) {
          navigate("/create-company");
        }
        setCompanyName(response?.data?.user?.company?.companyName);
      }
    };

    verifyInfoCompletion();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={styles.container}>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="setupWizard" />
      <section className={styles.item_container}>
        <div
          style={{
            margin: "4rem 0",
          }}
        >
          <div className={styles.rocket_card}>
            <img src={rocketIcon} alt="rocket-icon" />
          </div>
          <Typography textSize="20px" textWeight="600" textAlign="left">
            Setup Wizard
          </Typography>
          <Typography textSize="16px" textWeight="400" textAlign="left">
            Welcome to the setup wizard! Follow the steps below to configure the
            system for your needs.
          </Typography>
        </div>
        <Step step={step} data={arrayStep} />
        <div style={{ marginBottom: "4rem" }}></div>
      </section>
    </main>
  );
};

export default SetUpWizard;
