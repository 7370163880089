import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { getRequest } from "../../../../../api/request";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import colors from "../../../../../theme/colors";
import Typography from "../../../../Common/Typography";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import PageBackButton from "../../../../Common/PageBackButton";
import showSelectedLetter from "../../../../../utils/showSelectedLetters";
import ConfirmIdentityModal from "../../../../StaffHub/Employee/ViewProfile/ConfirmIdentityModal";

const ActivityEmployeeHrUk = () => {
  const id = getActivityBasicInformation();
  const [showNIN, setShowNIN] = useState(false);
  const [showConfirmIdentityModal, setShowConfirmIdentityModal] =
    useState(false);

  const [hr, setHr] = useState({
    employeeNumber: "",
    nin: "",
    payGrade: "",
    payrollGroup: "",
    personnelRate: "",
    holidayRate: "",
    contractHours: "",
    contractedHoursPeriod: "",
  });
  const [, setPreviousHr] = useState({
    employeeNumber: "",
    nin: "",
    payGrade: "",
    payrollGroup: "",
    personnelRate: "",
    holidayRate: "",
    contractHours: "",
    contractedHoursPeriod: "",
  });
  useEffect(() => {
    const getActivityEmployeeContactInformation = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        setHr({
          employeeNumber: response?.data?.newRecord?.hrRecord?.employeeNumber,
          nin: response?.data?.newRecord?.hrRecord?.nin,
          payGrade: response?.data?.newRecord?.hrRecord?.payGrade,
          payrollGroup: response?.data?.newRecord?.hrRecord?.payrollGroup,
          personnelRate: response?.data?.newRecord?.hrRecord?.personnelRate,
          holidayRate: response?.data?.newRecord?.hrRecord?.holidayRate,
          contractHours: response?.data?.newRecord?.hrRecord?.contractHours,
          contractedHoursPeriod:
            response?.data?.newRecord?.hrRecord?.contractedHoursPeriod,
        });
        setPreviousHr({
          employeeNumber:
            response?.data?.previousRecord?.hrRecord?.employeeNumber,
          nin: response?.data?.previousRecord?.hrRecord?.nin,
          payGrade: response?.data?.previousRecord?.hrRecord?.payGrade,
          payrollGroup: response?.data?.previousRecord?.hrRecord?.payrollGroup,
          personnelRate:
            response?.data?.previousRecord?.hrRecord?.personnelRate,
          holidayRate: response?.data?.previousRecord?.hrRecord?.holidayRate,
          contractHours:
            response?.data?.previousRecord?.hrRecord?.contractHours,
          contractedHoursPeriod:
            response?.data?.previousRecord?.hrRecord?.contractedHoursPeriod,
        });
      }
    };
    getActivityEmployeeContactInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              HR Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Employee Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.employeeNumber ? hr.employeeNumber : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">
                  National Identification Number
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {showNIN && hr.nin
                    ? hr?.nin
                    : showNIN && !hr.nin
                      ? "-"
                      : hr.nin
                        ? showSelectedLetter(hr.nin, 3, 2)
                        : "-"}
                </Typography>
                <div
                  className={styles.tin_button}
                  onClick={() => {
                    if (!showNIN) {
                      setShowConfirmIdentityModal(true);
                      // setDataToBeViewed("NIN");
                    } else {
                      setShowNIN(false);
                    }
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    {showNIN ? "Hide" : "View"}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Pay Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.payGrade ? hr.payGrade : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Payroll Group</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.payrollGroup ? hr.payrollGroup : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Personal Rate</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.personnelRate ? hr.personnelRate : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Holiday Rate</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.holidayRate ? hr.holidayRate : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Contract Hours</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.contractHours ? hr.contractHours : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Contracted Hours Period
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.contractedHoursPeriod ? hr.contractedHoursPeriod : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Contact Information */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous HR Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Employee Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.employeeNumber ? hr.employeeNumber : "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">
                  National Identification Number
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {showNIN && hr.nin
                    ? hr?.nin
                    : showNIN && !hr.nin
                      ? "-"
                      : hr.nin
                        ? showSelectedLetter(hr.nin, 3, 2)
                        : "-"}
                </Typography>
                <div
                  className={styles.tin_button}
                  onClick={() => {
                    if (!showNIN) {
                      setShowConfirmIdentityModal(true);
                      // setDataToBeViewed("NIN");
                    } else {
                      setShowNIN(false);
                    }
                  }}
                >
                  <Typography
                    textColor={colors.primary3}
                    paddingVertical="-5px"
                    marginVertical="0px"
                    textSize="12px"
                    textWeight="600"
                  >
                    {showNIN ? "Hide" : "View"}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Pay Grade</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.payGrade ? hr.payGrade : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Payroll Group</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.payrollGroup ? hr.payrollGroup : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Personal Rate</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.personnelRate ? hr.personnelRate : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Holiday Rate</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.holidayRate ? hr.holidayRate : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Contract Hours</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.contractHours ? hr.contractHours : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">
                  Contracted Hours Period
                </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {hr.contractedHoursPeriod ? hr.contractedHoursPeriod : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmIdentityModal && (
        <ConfirmIdentityModal
          dataViewed="NIN"
          cancelFunction={() => setShowConfirmIdentityModal(false)}
          successfunction={() => {
            setShowNIN(true);
            setShowConfirmIdentityModal(false);
          }}
        />
      )}
    </>
  );
};

export default ActivityEmployeeHrUk;
