import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import colors from "theme/colors";
import { getRequest } from "api/request";
import { ACTIVE_BOARD, GET_ALL_EMPLOYEE_URL } from "api/urls";
import PageLoader from "components/Common/PageLoader";
import {
  deleteCurrentEmployee,
  deleteEmployeeId,
  getLocation,
  getLocationName,
} from "utils/localstorage";
import PageBackButton from "components/Common/PageBackButton";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { CurrentLocationTimesheetFilterProps } from "types-definition/StaffHub/employee";
import SelectInput from "components/Form/SelectInput";
import LocationTimesheetTable from "components/Client/Site Location/LocationTimesheetTable";
import { LocationTimesheetData } from "types-definition/StaffHub/employee";
import CurrentLocationTimesheetFilter from "components/Client/Site Location/CurrentFilterSummary";
import moment from "moment";
import searchIcon from "assets/svg/search-icon.svg";
import ClientTimeShiftReportFilter from "components/Client/ClientTimeSheetReport";
import { sortByProperty } from "utils/reArrangeTheArrayInAccendingOrder";
import { getTimeDifference } from "utils/timeConverter";
import { ENDED, STARTED } from "types-definition/constants";

const LocationTimeshift = () => {
  const dateRanges = [
    {
      value: "",
      label: "All Time",
    },
    {
      value: 3,
      label: "Last 3 days",
    },
    {
      value: 7,
      label: "Last 7 days",
    },
    {
      value: 15,
      label: "Last 15 days",
    },
    {
      value: 30,
      label: "Last 30 days",
    },
    {
      value: 90,
      label: "Last 90 days",
    },
  ];

  const locationID = getLocation();
  const locationName = getLocationName();
  const [sortFilter, setSortFilter] = useState("");
  const [sortFilterName, setSortFilterName] = useState("");
  const [requestLoading, setRequestLoading] = useState(true);
  const [customRangeDate, setCustomRangeDate] = useState<string>("");
  const [currentDate] = useState(new Date());
  const [, setAllDays] = useState<Array<Date>>([]);
  const [search, setSearch] = useState("");
  const [locationTimesheetData, setLocationTimesheetData] = useState<
    Array<LocationTimesheetData>
  >([]);
  const [filterdLocationTimesheetData, setFilteredLocationTimesheetData] =
    useState<Array<LocationTimesheetData>>([]);
  const [
    currentLocationTimesheetFilterData,
    setCurrentLocationTimesheetFilterData,
  ] = useState<CurrentLocationTimesheetFilterProps>({
    hours: "0",
    billableHour: "0",
    date: "From 2 Jan to 2 Feb 2024",
  });

  // GET location timesheet from API
  useEffect(() => {
    const getEmployeeTimesheet = async () => {
      try {
        const response: any = await getRequest(
          `${ACTIVE_BOARD}/location/${locationID}?ended=true`,
        );
        if (response?.success) {
          const timesheetData: any = [];

          if (Array.isArray(response?.data)) {
            let gradeCache: any = {};

            console.time("performance");
            for (let timesheet of response?.data) {
              const gradeResponse =
                gradeCache?.[timesheet?.service?.grade] ||
                (await getRequest(
                  `/settings/grade/${timesheet?.service?.grade}`,
                ));

              gradeCache = {
                ...gradeCache,
                [timesheet?.service?.grade]: gradeResponse,
              };

              let timesheetItem = {
                employeeName: `${timesheet.employee.lastName} ${timesheet.employee.firstName}`,
                grade: gradeResponse?.data?.grade,
                dateAndTime: moment(timesheet.date).toDate(),
                startTime: timesheet.startTime,
                endTime: timesheet.endTime,
                employeeStartTime:
                  timesheet.status === STARTED || timesheet.status === ENDED
                    ? moment(timesheet.employeeStartTime).format("HH:mm")
                    : "-",
                employeeEndTime:
                  timesheet.status === ENDED
                    ? moment(timesheet.employeeEndTime).format("HH:mm")
                    : "-",
                date: timesheet.date,
                started:
                  timesheet.status === STARTED || timesheet.status === ENDED,
                ended: timesheet.status === ENDED,
                hourWorked:
                  timesheet.employeeEndTime && timesheet.employeeStartTime
                    ? getTimeDifference(
                        moment(timesheet.employeeStartTime).format("HH:mm"),
                        moment(timesheet.employeeEndTime).format("HH:mm"),
                      )
                    : "",
                totalWorkHours:
                  timesheet.status === ENDED &&
                  moment(timesheet.endTime, "h:mm").diff(
                    moment(timesheet.startTime, "h:mm"),
                    "hours",
                  ),
                status: timesheet.status,
              };

              // return timesheetItem;
              timesheetData.push(timesheetItem);
            }
            console.timeEnd("performance");
          }

          setLocationTimesheetData(timesheetData);
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setRequestLoading(false);
      }
    };

    getEmployeeTimesheet();
  }, [locationID]);

  // Implement more advanced filtering query that
  // takes into factor custom date range filtering and
  // search querying

  const filteringQuery = () => {
    const DATE_FORMAT = "DD/MM/YYY";
    let defaultData = locationTimesheetData.sort((a, b) => {
      return (
        moment(b.dateAndTime, DATE_FORMAT).unix() -
        moment(a.dateAndTime, DATE_FORMAT).unix()
      );
    });

    if (customRangeDate) {
      const endOfSpecificDay = moment()
        .endOf("days")
        .subtract(Number(customRangeDate), "days");

      const endOfToday = moment().endOf("days");

      const dateRangingTimesheet = locationTimesheetData.filter((data) => {
        const isDateAfterEndOfSpecificDay = moment(
          data.dateAndTime,
          DATE_FORMAT,
        ).isAfter(endOfSpecificDay);
        const isDateBeforeEndToday = moment(
          data.dateAndTime,
          DATE_FORMAT,
        ).isBefore(endOfToday);

        return isDateAfterEndOfSpecificDay && isDateBeforeEndToday;
      });

      defaultData = dateRangingTimesheet;
    }

    let trimmedSearch = search.trim();
    if (trimmedSearch) {
      defaultData = defaultData.filter(
        (item) =>
          item.employeeName
            .toLocaleLowerCase()
            .includes(trimmedSearch.toLocaleLowerCase()) ||
          item.grade
            .toLocaleLowerCase()
            .includes(trimmedSearch.toLocaleLowerCase()),
      );
    }
    setFilteredLocationTimesheetData(defaultData);
  };

  useEffect(() => {
    filteringQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customRangeDate, search, locationTimesheetData]);

  // Showing current filter summary
  useEffect(() => {
    const totalHours =
      filterdLocationTimesheetData.reduce((accumulator, timesheet) => {
        const totalWorkHours = moment(timesheet.endTime, "HH:mm").diff(
          moment(timesheet.startTime, "HH:mm"),
          "minutes",
        );

        return (accumulator += totalWorkHours);
      }, 0) / 60;

    const totalBillableHours =
      filterdLocationTimesheetData.reduce((accumulator, timesheet) => {
        let startTime = moment(
          timesheet.employeeStartTime,
          "HH:mm",
        ).isSameOrBefore(moment(timesheet.startTime, "HH:mm"))
          ? moment(timesheet.startTime, "HH:mm")
          : moment(timesheet.employeeStartTime, "HH:mm");

        let endTime = moment(timesheet.employeeEndTime, "HH:mm").isSameOrAfter(
          moment(timesheet.endTime, "HH:mm"),
        )
          ? moment(timesheet.endTime, "HH:mm")
          : moment(timesheet.employeeEndTime, "HH:mm");

        let totalWorkHours =
          timesheet.status === ENDED ? endTime.diff(startTime, "minutes") : 0;

        return (accumulator += totalWorkHours);
      }, 0) / 60;

    setCurrentLocationTimesheetFilterData({
      hours: parseFloat(totalHours.toFixed(1)).toLocaleString(),
      billableHour: parseFloat(totalBillableHours.toFixed(1)).toLocaleString(),
      date: customRangeDate
        ? `${moment()
            .subtract(Number(customRangeDate) - 1, "days")
            .format("D MMM")} to ${moment().format("D MMM YYYY")}`
        : `All Time`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterdLocationTimesheetData]);

  useEffect(() => {
    const getAllEmployee = async () => {
      try {
        const response = await getRequest(GET_ALL_EMPLOYEE_URL);
        if (response?.success) {
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      } finally {
        setRequestLoading(false);
      }
    };

    getAllEmployee();
    deleteCurrentEmployee();
    deleteEmployeeId();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllDays();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const getAllDays = () => {
    const lastDayInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0,
    ).getDate();
    const days: Array<Date> = [];
    for (let i = 1; i <= lastDayInMonth; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        i,
      );
      days.push(date);
    }
    setAllDays(days);
  };

  const handleFilter = (name: string, order: string) => {
    let sortedArray: LocationTimesheetData[] = [];
    if (name === "Name" && order === "Ascending Order") {
      sortedArray = locationTimesheetData.sort(
        (a: LocationTimesheetData, b: LocationTimesheetData) =>
          a.employeeName.localeCompare(b.employeeName),
      );
    } else if (name === "Name" && order === "Descending Order") {
      sortedArray = locationTimesheetData.sort(
        (a: LocationTimesheetData, b: LocationTimesheetData) =>
          b.employeeName.localeCompare(a.employeeName),
      );
    } else if (name === "Grade" && order === "Ascending Order") {
      sortedArray = locationTimesheetData.sort(
        (a: LocationTimesheetData, b: LocationTimesheetData) =>
          a.grade.localeCompare(b.grade),
      );
    } else if (name === "Grade" && order === "Descending Order") {
      sortedArray = locationTimesheetData.sort(
        (a: LocationTimesheetData, b: LocationTimesheetData) =>
          b.grade.localeCompare(a.grade),
      );
    } else if (name === "From" && order === "Ascending Order") {
      sortedArray = locationTimesheetData.sort(
        (a: any, b: any) => Number(new Date(a.date)) - Number(new Date(b.date)),
      );
    } else if (name === "From" && order === "Descending Order") {
      sortedArray = locationTimesheetData.sort(
        (a: any, b: any) => Number(new Date(b.date)) - Number(new Date(a.date)),
      );
    } else if (name === "To" && order === "Ascending Order") {
      sortedArray = locationTimesheetData.sort(
        (a: any, b: any) =>
          parseInt(a.startTime.split(":")[0]) -
          parseInt(b.startTime.split(":")[0]),
      );
    } else if (name === "To" && order === "Descending Order") {
      sortedArray = locationTimesheetData.sort(
        (a: any, b: any) =>
          parseInt(b.startTime.split(":")[0]) -
          parseInt(a.startTime.split(":")[0]),
      );
    } else if (name === "Work Hours" && order === "Ascending Order") {
      sortedArray = locationTimesheetData.sort(
        (a: any, b: any) =>
          parseInt(a.endTime.split(":")[0]) - parseInt(b.endTime.split(":")[0]),
      );
    } else if (name === "Work Hours" && order === "Descending Order") {
      sortedArray = locationTimesheetData.sort(
        (a: any, b: any) =>
          parseInt(b.endTime.split(":")[0]) - parseInt(a.endTime.split(":")[0]),
      );
    }

    setFilteredLocationTimesheetData(sortedArray);
  };

  return (
    <>
      <ScrollToTop />
      {requestLoading ? (
        <PageLoader />
      ) : (
        <section>
          <DashboardHeader active="clients" />
          <div className={styles.container}>
            <div
              className={`${styles.item_container} ${styles.item_container_fill}`}
            >
              <PageBackButton width="90%" paddingTop="0" marginBottom="10px" />
              <div className={styles.flex_item}>
                <div>
                  <div className={styles.sub_flex_item}>
                    <Typography
                      textAlign="left"
                      textWeight="900"
                      textSize="20px"
                    >
                      Location Timesheet Report -&nbsp;
                    </Typography>
                    <Typography
                      textAlign="left"
                      textWeight="900"
                      textSize="20px"
                      textColor={colors.primary3}
                    >
                      {locationName}
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "250px",
                    }}
                  >
                    {" "}
                    <SelectInput
                      datalistTop="55px"
                      dropdownTop="17px"
                      value={
                        customRangeDate
                          ? dateRanges.find(
                              (day) => day.value.toString() === customRangeDate,
                            )?.label
                          : ""
                      }
                      setValue={(label) => {
                        let value = dateRanges.find(
                          (day) => day.label === label,
                        )?.value;
                        value !== undefined &&
                          setCustomRangeDate(value.toString());
                      }}
                      label=""
                      placeholder="Jan 2, 2024 - Feb 2, 2024"
                      options={dateRanges.map((day) => day.label)}
                    />
                  </div>
                </div>

                <div className={styles.sub_flex_item}>
                  <ClientTimeShiftReportFilter
                    runFilter={(data, name) => {
                      setSortFilter(data);
                      setSortFilterName(name);
                      handleFilter(name, data);
                    }}
                    clearFilter={() => {}}
                  />
                  <div className={styles.sub_sub_flex_item}>
                    <TextInput
                      left={<img src={searchIcon} alt="search" />}
                      placeholder="Search"
                      value={search}
                      setValue={(data) => {
                        setSearch(data);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.top_flex_container}>
                <CurrentLocationTimesheetFilter
                  {...currentLocationTimesheetFilterData}
                />
              </div>
              <LocationTimesheetTable
                data={filterdLocationTimesheetData.filter(
                  (item: any) => item.status === ENDED,
                )}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default LocationTimeshift;
