import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "components/Common/PageBackButton";
import Typography from "components/Common/Typography";
import colors from "theme/colors";
import { getRequest } from "api/request";
import { GET_SYSTEM_ACTIVITY } from "api/urls";
import { getLocationAudit } from "utils/localstorage";
import { toastError } from "utils/toast";
import DashboardHeader from "components/Common/DashboardHeader";
import ScrollToTop from "components/Common/ScrollToTop";

const SiteLocationAuditGeneral = () => {
  const id = getLocationAudit();
  const [locationData, setLocationData] = useState({
    name: "",
    area: "",
    client: "",
    region: "",
    shortName: "",
    managedBy: "",
    code: "",
  });
  const [previousLocationData, setPreviousLocationData] = useState({
    name: "",
    area: "",
    client: "",
    region: "",
    shortName: "",
    managedBy: "",
    code: "",
  });
  useEffect(() => {
    const getLocationActivityDetails = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response?.data?.newRecord?.location;
        const previousData = response?.data?.previousRecord?.location;
        setLocationData({
          name: data.name,
          area: data.areaName,
          client: data.clientName,
          region: data.region,
          shortName: data.shortName,
          managedBy: "",
          code: data.code,
        });
        setPreviousLocationData({
          name: previousData?.name,
          area: previousData?.areaName,
          client: previousData?.clientName,
          region: previousData?.region,
          shortName: previousData?.shortName,
          managedBy: "",
          code: previousData?.code,
        });
      } else {
        toastError(response?.data);
      }
    };
    getLocationActivityDetails();
  }, [id]);
  return (
    <>
      <ScrollToTop />
      <DashboardHeader active="clients" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/client/location/audit" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Location Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Location Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {locationData.name ? locationData.name : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Code</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {locationData.code ? locationData.code : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Short Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {locationData?.shortName ? locationData.shortName : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Region</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {locationData?.region ? locationData?.region : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Area</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {locationData?.area ? locationData.area : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Client</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {locationData?.client ? locationData?.client : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Location Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Location Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationData.name ? previousLocationData.name : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Code</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationData.code ? previousLocationData.code : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Short Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationData?.shortName
                    ? previousLocationData.shortName
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Region</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationData?.region
                    ? previousLocationData?.region
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Area</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationData?.area ? previousLocationData.area : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Client</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationData?.client
                    ? previousLocationData?.client
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteLocationAuditGeneral;
