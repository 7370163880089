import React, { useEffect, useState } from "react";
import { RenderPageProps } from "types-definition/auth";
import { getUserTypes } from "utils/localstorage";
import NoPermissionPage from "../no-permission";
import checkAdminPermission, { checkUserPermission } from "utils/permission";
import PageLoader from "components/Common/PageLoader";
import NoAdminPermissionPage from "../no-admin-permission";
import { toastError } from "utils/toast";
import { ADMIN } from "types-definition/constants";

const RenderPage: React.FC<RenderPageProps> = ({
  page,
  permissions,
  active,
  activeAdmin,
}) => {
  const userType = getUserTypes();
  // const [userType, setUserType] = useState(getUserTypes());
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [IsPermissionPageAvailable, setIsPermissionPageAvailable] =
    useState(false);
  // useEffect(() => {
  //   setUserType(getUserTypes());
  // }, [getUserTypes()]);
  useEffect(() => {
    const getPermission = async () => {
      if (getUserTypes() === ADMIN || getUserTypes() === null) {
        setIsPageLoading(false);
        return;
      }
      if (permissions && permissions?.length >= 0) {
        setIsPageLoading(true);
        const isPermissionAvailable = await checkUserPermission(permissions);
        setIsPermissionPageAvailable(isPermissionAvailable ? true : false);
        if (!isPermissionAvailable) {
          toastError(
            "It seems you do not have any permissions to view the necessary page. Please contact your admin to assign you to a permission group",
          );
        }
        setIsPageLoading(false);
      } else {
        setIsPermissionPageAvailable(false);
        setIsPageLoading(false);
      }
    };
    getPermission();
  }, [permissions]);
  return (
    <>
      {checkAdminPermission(userType) ? (
        page
      ) : isPageLoading ? (
        <PageLoader />
      ) : IsPermissionPageAvailable ? (
        page
      ) : active === "admin settings" ? (
        <NoAdminPermissionPage activeAdmin={activeAdmin ?? "settings"} />
      ) : (
        <NoPermissionPage active={active} />
      )}
    </>
  );
};

export default RenderPage;
