import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import filterIcon from "../../../assets/svg/filter-icon.svg";
import colors from "../../../theme/colors";

const TableFilter = () => {
  return (
    <div className={styles.container}>
      <img src={filterIcon} alt="filter icon" />
      <Typography textColor={colors.gray1}>Filter</Typography>
    </div>
  );
};

export default TableFilter;
