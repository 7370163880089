import {
  ACTIVE_BOARD,
  ACTIVITY_LOG,
  // ACTIVE_BOARD_CUSTOM_TIMES,
  // ACTIVE_BOARD_OVERRIDE,
  ADD_EMPLOYEE,
  ADMIN_WEBUSER,
  AREA,
  ASSIGN_PERMISSION_GROUP,
  AUTO_ROSTER_GROUP,
  // AUTO_ROSTER_GROUP,
  BROADCAST_ROSTER,
  CERTIFICATE_EMPLOYEE_DELETE,
  CERTIFICATE_GROUPS,
  CERTIFICATE_TYPES,
  CLIENT,
  EMPLOYEES,
  EMPLOYEES_ABSENCE_READ,
  EMPLOYEE_ABSENCE,
  EMPLOYEE_ADJUST_ACCRUAL_BALANCE,
  EMPLOYEE_HOLIDAY,
  EMPLOYEE_HOLIDAY_MULTIPLIER,
  // EMPLOYEE_HR_DEFAULT,
  // EMPLOYEE_HR_TAB,
  EMPLOYEE_LOCATION_LINKING,
  EMPLOYEE_READ_ONLY,
  EMPLOYEE_UNAVAILABILITY,
  GRADES,
  HOLIDAY_MULTIPLIER,
  LOCATION_AUDIT,
  LOCATION_CHECKPOINT,
  LOCATION_EMAIL_REPORT,
  LOCATION_SERVICES_ADD_UPDATE,
  LOCATION_SERVICES_VIEW,
  LOCATION_UPDATE_AND_ADD,
  LOCATION_VIEW,
  MESSAGE_EMPLOYEES,
  PERMISSION_GROUP,
  REPORT_INCIDENT_LOCATION,
  ROSTER_READ_ONLY,
  ROSTER_SCHEDULE_CONFIRM,
  ROSTER_SUMMARY,
  SERVICES,
  SERVICE_GROUPS,
  SETTINGS,
  SHIFT_DELETION,
  SHIFT_GENERATION,
  SHOW_EMPLOYEE_PASSWORD,
  TIME_SHEET,
  LOCATION_TIMESHEET,
  ADMIN,
  EMPLOYEE,
  WEBUSER,
  CHECK_CALL_REPORT,
} from "types-definition/constants";

export const filterNeededPermissions = (data: any[]) => {
  const result = data.filter(
    (item: any) =>
      item?.name === ADMIN ||
      item?.name === WEBUSER ||
      item?.name === EMPLOYEE ||
      item?.name === ACTIVE_BOARD ||
      item?.name === ADD_EMPLOYEE ||
      item?.name === EMPLOYEES ||
      item?.name === EMPLOYEE_ABSENCE ||
      item?.name === EMPLOYEES_ABSENCE_READ ||
      item?.name === EMPLOYEE_ADJUST_ACCRUAL_BALANCE ||
      item?.name === EMPLOYEE_HOLIDAY ||
      item?.name === EMPLOYEE_READ_ONLY ||
      item?.name === CERTIFICATE_EMPLOYEE_DELETE ||
      item?.name === AREA ||
      item?.name === AUTO_ROSTER_GROUP ||
      item?.name === BROADCAST_ROSTER ||
      item?.name === CERTIFICATE_TYPES ||
      item?.name === CLIENT ||
      item?.name === EMPLOYEE_LOCATION_LINKING ||
      item?.name === EMPLOYEE_HOLIDAY_MULTIPLIER ||
      item?.name === EMPLOYEE_UNAVAILABILITY ||
      item?.name === GRADES ||
      item?.name === HOLIDAY_MULTIPLIER ||
      item?.name === LOCATION_UPDATE_AND_ADD ||
      item?.name === LOCATION_AUDIT ||
      item?.name === LOCATION_CHECKPOINT ||
      item?.name === LOCATION_EMAIL_REPORT ||
      item?.name === LOCATION_SERVICES_ADD_UPDATE ||
      item?.name === LOCATION_SERVICES_VIEW ||
      item?.name === MESSAGE_EMPLOYEES ||
      item?.name === REPORT_INCIDENT_LOCATION ||
      item?.name === ROSTER_READ_ONLY ||
      item?.name === ROSTER_SUMMARY ||
      item?.name === ROSTER_SCHEDULE_CONFIRM ||
      item?.name === SERVICE_GROUPS ||
      item?.name === SERVICES ||
      item?.name === SETTINGS ||
      item?.name === SHIFT_DELETION ||
      item?.name === SHIFT_GENERATION ||
      item?.name === SHOW_EMPLOYEE_PASSWORD ||
      item?.name === TIME_SHEET ||
      item?.name === SHOW_EMPLOYEE_PASSWORD ||
      item?.name === ADMIN_WEBUSER ||
      item?.name === PERMISSION_GROUP ||
      item?.name === LOCATION_VIEW ||
      item?.name === LOCATION_TIMESHEET ||
      item?.name === ACTIVITY_LOG ||
      item?.name === CERTIFICATE_GROUPS ||
      item?.name === CHECK_CALL_REPORT ||
      item?.name === ACTIVITY_LOG,
  );

  return result;
};
