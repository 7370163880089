import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "components/Common/PageBackButton";
import Typography from "components/Common/Typography";
import colors from "theme/colors";
import { getRequest } from "api/request";
import { GET_SYSTEM_ACTIVITY } from "api/urls";
import { getLocationAudit } from "utils/localstorage";
import { toastError } from "utils/toast";
import DashboardHeader from "components/Common/DashboardHeader";
import ScrollToTop from "components/Common/ScrollToTop";
import capitalize from "utils/capitalize";

const SiteLocationAuditLinkLocation = () => {
  const id = getLocationAudit();
  const [locationData, setLocationData] = useState({
    clientName: "",
    distance: "",
    locationName: "",
    payOption: "",
    payValue: "",
    employeeId: "",
  });
  const [previousLocationData, setPreviousLocationData] = useState({
    clientName: "",
    distance: "",
    locationName: "",
    payOption: "",
    payValue: "",
    employeeId: "",
  });
  useEffect(() => {
    const getLocationActivityDetails = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response?.data.newRecord["employee-location"];
        const previousData =
          response?.data.previousRecord?.["employee-location"];
        setLocationData({
          clientName: data.clientName,
          distance: data.distance,
          locationName: data.locationName,
          payOption: data.payOption,
          payValue: data.payValue,
          employeeId: data.employeeId,
        });
        setPreviousLocationData({
          clientName: previousData?.clientName,
          distance: previousData?.distance,
          locationName: previousData?.locationName,
          payOption: previousData?.payOption,
          payValue: previousData?.payValue,
          employeeId: previousData?.employeeId,
        });
      } else {
        toastError(response?.data);
      }
    };
    getLocationActivityDetails();
  }, [id]);
  return (
    <>
      <ScrollToTop />
      <DashboardHeader active="clients" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/client/location/audit" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Location Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Client Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {locationData.clientName ? locationData.clientName : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Location Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {locationData.locationName ? locationData.locationName : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Pay Option</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {locationData.payOption
                    ? capitalize(locationData.payOption)
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Pay Value</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {locationData.payValue ? locationData.payValue : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Distance</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {locationData.distance ? locationData.distance : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Location Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Client Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationData.clientName
                    ? previousLocationData.clientName
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Location Name</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationData.locationName
                    ? previousLocationData.locationName
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Pay Option</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationData.payOption
                    ? previousLocationData.payOption
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Pay Value</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationData.payValue
                    ? previousLocationData.payValue
                    : "-"}
                </Typography>
              </div>
            </div>

            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Distance</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousLocationData.distance
                    ? previousLocationData.distance
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteLocationAuditLinkLocation;
