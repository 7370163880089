import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { WebUserProfileTableProps } from "../../../../types-definition/admin";
import colors from "../../../../theme/colors";
import WebUserProfileTableItem from "../WebUserProfileTableItem";

const WebUserProfileTable: React.FC<WebUserProfileTableProps> = ({
  data = [],
}) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Full Name
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Username
              </Typography>
            </th>
            <th className={styles.th_mail}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Email Address
              </Typography>
            </th>
            <th className={styles.th_client}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Client Name
              </Typography>
            </th>
            <th className={styles.th_portal}>
              <Typography
                textAlign="center"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Portal Users
              </Typography>
            </th>
            <th className={styles.th_status}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Status
              </Typography>
            </th>
            <th className={styles.th_index}></th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return <WebUserProfileTableItem {...item} key={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WebUserProfileTable;
