import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import Checkbox from "../../../Form/Checkbox";
import NumberInput from "../../../Form/NumberInput";
import { ServiceFormProps } from "../../../../types-definition/client";
import SelectInput from "../../../Form/SelectInput";
import RadioInput from "../../../Form/RadioInput";
import timeArray from "../../../../utils/timeArray";
import DateInput from "../../../Form/DateInput";
import FullWidthSelectInput from "../../../Form/FullWidthSelectInput";
import { getRequest } from "../../../../api/request";
import {
  GET_SERVICE_GROUP_URL,
  GET_USER_SETTINGS_URL,
} from "../../../../api/urls";
import { toastError } from "utils/toast";
import ClientSelectInput from "components/Form/ClientSelectInput";
import { getTimeDifferenceMultiplication } from "utils/timeConverter";
import { getService } from "utils/localstorage";

const AddServiceForm: React.FC<ServiceFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  const [grade, setGrade] = useState([]);
  const [serviceGroup, setServiceGroup] = useState<Array<any>>([]);
  const [timeShiftEndArray, setTimeShiftEndArray] = useState(timeArray);

  const serviceId = getService();

  useEffect(() => {
    const getGrade = async () => {
      const gradeResponse = await getRequest(`${GET_USER_SETTINGS_URL}/grade`);
      if (gradeResponse.success) {
        const gradeArray = gradeResponse.data.map((item: any) => {
          return { name: item.grade, id: item.id };
        });
        setGrade(gradeArray);
      } else {
        toastError(gradeResponse?.data);
      }
    };
    const getAllServiceGroup = async () => {
      const response = await getRequest(GET_SERVICE_GROUP_URL);
      if (response?.success) {
        const data: Array<any> = [];
        for (const index in response?.data) {
          data.push({
            ...response?.data[index],
          });
        }
        setServiceGroup(data);
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    };
    getAllServiceGroup();
    getGrade();
  }, []);

  useEffect(() => {
    setTimeShiftEndArray(
      timeArray.filter(
        (_, index, array) => array.indexOf(formData.shiftStart) < index,
      ),
    );
  }, [formData.shiftStart]);

  useEffect(() => {
    const count = Object.values(formData.daysOfWeek).filter((item) => {
      return item === true;
    }).length;
    if (
      formData.noOfEmployeeRequired &&
      formData.shiftEnd &&
      formData.shiftStart
    ) {
      if (parseInt(formData.noOfEmployeeRequired) >= 1) {
        setFormData((prevData) => {
          return {
            ...prevData,
            totalHoursInWeek: getTimeDifferenceMultiplication(
              formData.shiftStart,
              formData.shiftEnd,
              parseInt(formData.noOfEmployeeRequired) * count,
            ),
          };
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.noOfEmployeeRequired,
    formData.daysOfWeek,
    formData.shiftStart,
    formData.shiftEnd,
  ]);
  return (
    <>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.name}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, name: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, name: false };
            });
          }}
          label="Service Name"
          placeholder="Enter Service Name"
          required={true}
          error={requiredFormData.name}
          helperText={requiredFormData.name ? "Service Name is required" : ""}
          id="name"
        />
        <TextInput
          value={formData.abbreviation}
          setValue={(data) => {
            if (data.length <= 4) {
              setFormData((prevData) => {
                return { ...prevData, abbreviation: data };
              });
            }
          }}
          label="Abbreviation"
          placeholder="Enter Abbreviation"
        />
        <Checkbox
          marginVertical="20px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Active
              </Typography>
            </div>
          }
          value={formData.active}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, active: data };
            })
          }
        />
      </div>
      <div className={styles.form_flex_container}>
        <ClientSelectInput
          value={formData.grade}
          client={formData.grade}
          dropdownTop="55px"
          datalistTop="92px"
          setValue={(name, id, data) => {
            if (name && id) {
              setFormData((prevData) => {
                return { ...prevData, grade: name, gradeId: id };
              });
              setRequiredFormData((prevData) => {
                return { ...prevData, grade: false };
              });
            }
          }}
          label="Grade"
          placeholder="Select Grade"
          required={true}
          error={requiredFormData.grade}
          helperText={requiredFormData.grade ? "Grade is required" : ""}
          options={[...grade]}
        />
        <SelectInput
          value={formData.noOfEmployeeRequired}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, noOfEmployeeRequired: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, noOfEmployeeRequired: false };
            });
          }}
          label="No of Employees Required"
          placeholder="Select"
          required={true}
          error={requiredFormData.noOfEmployeeRequired}
          helperText={
            requiredFormData.noOfEmployeeRequired
              ? "No of Employees Required is required"
              : ""
          }
          options={Array.from(Array(20).keys()).map((item) =>
            (item + 1).toString(),
          )}
          id="noOfEmployeeRequired"
        />
      </div>
      <div className={styles.form_flex_container}>
        <Checkbox
          marginVertical="10px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Auto-generate shift
              </Typography>
            </div>
          }
          value={formData.autoGenerateShift}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, autoGenerateShift: data };
            })
          }
        />
        <Checkbox
          marginVertical="10px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Auto Book On
              </Typography>
            </div>
          }
          value={formData.autoBookOn}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, autoBookOn: data };
            })
          }
        />
      </div>

      <div className={styles.form_flex_container}>
        <Checkbox
          marginVertical="10px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Auto Book Off
              </Typography>
            </div>
          }
          value={formData.autoBookOff}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, autoBookOff: data };
            })
          }
        />
      </div>

      <div className={styles.form_flex_container}>
        <NumberInput
          value={formData.shiftStartProximityRadius}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, shiftStartProximityRadius: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, shiftStartProximityRadius: false };
            });
          }}
          disabled={formData.overRideProximity ? false : true}
          label="Shift Start Proximity Radius (Meter)"
          placeholder="Enter Shift Start Proximity Radius (Meter)"
          required={true}
          error={requiredFormData.shiftStartProximityRadius}
          helperText={
            requiredFormData.shiftStartProximityRadius
              ? "Shift Start Proximity Radius is required"
              : ""
          }
          id="shiftStartProximityRadius"
        />
        <NumberInput
          disabled={formData.overRideProximity ? false : true}
          value={formData.shiftEndProximityRadius}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, shiftEndProximityRadius: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, shiftEndProximityRadius: false };
            });
          }}
          label="Shift End Proximity Radius (Meter)"
          placeholder="Enter Shift End Proximity Radius (Meter)"
          required={true}
          error={requiredFormData.shiftEndProximityRadius}
          helperText={
            requiredFormData.shiftEndProximityRadius
              ? "Shift End Proximity Radius is required"
              : ""
          }
          id="shiftEndProximityRadius"
        />
      </div>

      <div className={styles.form_flex_container}>
        <NumberInput
          disabled={formData.overRideProximity ? false : true}
          value={formData.checkCallProximityRadius}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, checkCallProximityRadius: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, checkCallProximityRadius: false };
            });
          }}
          label="Check Call Proximity Radius (Meter)"
          placeholder="Enter Check Call Proximity Radius (Meter)"
          required={true}
          error={requiredFormData.checkCallProximityRadius}
          helperText={
            requiredFormData.checkCallProximityRadius
              ? "Check Call Proximity Radius is required"
              : ""
          }
          id="checkCallProximityRadius"
        />
        <TextInput
          disabled={formData.overRideProximity ? false : true}
          value={formData.proximityRadiusEmail}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, proximityRadiusEmail: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, proximityRadiusEmail: false };
            });
          }}
          label="Proximity Radius Emails"
          placeholder="Enter Proximity Radius Emails"
          required={true}
          error={requiredFormData.proximityRadiusEmail}
          helperText={
            requiredFormData.proximityRadiusEmail
              ? "Proximity Radius Emails is required"
              : ""
          }
          id="proximityRadiusEmail"
        />
      </div>
      <div className={styles.form_flex_container}>
        <Checkbox
          marginVertical="10px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Override Default Proximity Settings
              </Typography>
            </div>
          }
          value={formData.overRideProximity}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, overRideProximity: data };
            });
          }}
        />
      </div>

      <div className={styles.form_flex_container}>
        <SelectInput
          value={formData.autoRosterGroup}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, autoRosterGroup: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, autoRosterGroup: false };
            });
          }}
          label="Auto Roster Group"
          placeholder="Select Auto Roster Group"
          required={true}
          error={requiredFormData.autoRosterGroup}
          helperText={
            requiredFormData.autoRosterGroup
              ? "Auto Roster Group is required"
              : ""
          }
          options={[
            "Auto-generate and Confirm",
            "Auto-generate and Schedule",
            "Generate and Confirm",
          ]}
          id="autoRosterGroup"
        />
        <Checkbox
          marginVertical="10px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Allow Clock-in Outside Priximity Radius
              </Typography>
            </div>
          }
          value={formData.allowClockInOutsideProximityRadius}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, allowClockInOutsideProximityRadius: data };
            });
          }}
        />
      </div>
      <div className={styles.form_flex_container}>
        {/* <div className={styles.radio_flex_container}> */}
        {/* <div className={styles.radio_form_container}>
            <div>
              <Typography
                textWeight="600"
                textSize="17px"
                marginVertical="20px"
              >
                Plan Type
              </Typography>
            </div>
            <div className={styles.radio_container}>
              <RadioInput
                label="Require Confirmation"
                name="plan-type"
                value={formData.requireConfirmation}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      requireConfirmation: data,
                      openShift: !data,
                    };
                  })
                }
              />
              <RadioInput
                label="Open Shift"
                name="plan-type"
                value={formData.openShift}
                setValue={(data) =>
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      openShift: data,
                      requireConfirmation: !data,
                    };
                  })
                }
              />
            </div>
          </div> */}
        {/* </div> */}
        <Checkbox
          marginVertical="10px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Exclude Public Holidays
              </Typography>
            </div>
          }
          value={formData.excludePublicHoliday}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, excludePublicHoliday: data };
            })
          }
        />
      </div>
      <div className={styles.form_flex_container}>
        <DateInput
          value={formData.fromData}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, fromData: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, fromData: false };
            });
          }}
          label="From"
          placeholder="Select Date"
          required={true}
          error={requiredFormData.fromData}
          helperText={requiredFormData.fromData ? "From Date is required" : ""}
          id="from"
        />
        <DateInput
          value={formData.toDate}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, toDate: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, toDate: false };
            });
          }}
          label="To"
          validateMin={true}
          min={formData.fromData}
          placeholder="Select Date"
          required={true}
          error={requiredFormData.toDate}
          helperText={requiredFormData.toDate ? "To Date is required" : ""}
          id="to"
        />
      </div>
      <div className={styles.form_flex_container}>
        <SelectInput
          value={formData.shiftStart}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, shiftStart: data, shiftEnd: "" };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, shiftStart: false };
            });
          }}
          label="Shift Start"
          placeholder="Select"
          required={true}
          error={requiredFormData.shiftStart}
          helperText={
            requiredFormData.shiftStart ? "Shift Start is required" : ""
          }
          options={timeArray}
          id="shiftStart"
        />
        <SelectInput
          value={formData.shiftEnd}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, shiftEnd: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, shiftEnd: false };
            });
          }}
          label="Shift End"
          placeholder="Select"
          required={true}
          error={requiredFormData.shiftEnd}
          helperText={requiredFormData.shiftEnd ? "Shift End is required" : ""}
          options={timeShiftEndArray}
          id="shiftEnd"
          reloadOptions
        />
      </div>

      <div className={styles.form_flex_container}>
        <NumberInput
          value={formData.shiftRotationPeriod}
          setValue={(data) => {
            if (parseInt(data) <= 28 || !data) {
              setFormData((prevData) => {
                return { ...prevData, shiftRotationPeriod: data };
              });
            }
            setRequiredFormData((prevData) => {
              return { ...prevData, shiftRotationPeriod: false };
            });
          }}
          label="Shift Rotation Period"
          placeholder="Enter Shift Rotation Period"
          required={true}
          min={1}
          max={28}
          error={requiredFormData.shiftRotationPeriod}
          helperText={
            requiredFormData.shiftRotationPeriod
              ? "Shift Rotation Period is required"
              : ""
          }
          id="shiftRotationPeriod"
        />
        <SelectInput
          value={formData.frequency}
          setValue={(data) => {
            setFormData((prevData) => {
              return {
                ...prevData,
                frequency: data,
              };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, frequency: false };
            });
          }}
          required
          label="Frequency"
          placeholder="Select Frequency"
          options={[
            "Every Week",
            "Every 2 Weeks",
            "Every 3 Weeks",
            "Every 4 Weeks",
            "Every Month",
            "Every 2 Months",
          ]}
          error={requiredFormData.frequency}
          helperText={requiredFormData.frequency ? "Frequency is required" : ""}
          id="frequency"
        />
      </div>
      <div className={styles.form_flex_container_days}>
        {formData.frequency !== "Every Month" &&
          formData.frequency !== "Every 2 Months" && (
            <div
              className={styles.checkbox_form_container}
              style={{ flex: 1, width: "50%" }}
            >
              <div>
                <Typography textWeight="600" textSize="17px">
                  Days of Week
                </Typography>
              </div>
              <div className={styles.radio_container}>
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Monday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.monday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, monday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Tuesday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.tuesday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, tuesday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Wednesday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.wednesday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, wednesday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Thursday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.thursday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, thursday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Friday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.friday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, friday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Saturday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.saturday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, saturday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="10px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        marginHorizontal="-10px"
                      >
                        Sunday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.sunday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, sunday: data },
                      };
                    })
                  }
                />
              </div>
            </div>
          )}
        {formData.frequency === "Every Week" ||
        formData.frequency === "Every 2 Weeks" ||
        formData.frequency === "Every 3 Weeks" ||
        formData.frequency === "Every 4 Weeks" ||
        formData.frequency === "" ? (
          <div style={{ flex: 1 }}>
            {!formData.checkCall && (
              <div style={{ flex: 1 }}>
                {!formData.checkCall && (
                  <Typography textAlign="left" textWeight="600" textSize="16px">
                    Do you want to add a checkcall?
                  </Typography>
                )}
                {!formData.checkCall && (
                  <RadioInput
                    label="Yes"
                    name="Yes"
                    value={formData.checkCall}
                    setValue={(data) => {
                      setFormData((prevData) => {
                        return {
                          ...prevData,
                          checkCall: true,
                        };
                      });
                    }}
                    labelWeight="600"
                    style={{ marginBottom: "8px", marginTop: "10px" }}
                  />
                )}
                {!formData.checkCall && (
                  <RadioInput
                    label="No"
                    name="No"
                    value={!formData.checkCall}
                    setValue={(data) => {
                      setFormData((prevData) => {
                        return {
                          ...prevData,
                          checkCall: false,
                        };
                      });
                    }}
                    labelWeight="600"
                    style={{ marginBottom: "8px", marginTop: "8px" }}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {formData.checkCall && (
        <div className={styles.checkcall_container}>
          {formData.frequency === "Every Week" ||
          formData.frequency === "Every 2 Weeks" ||
          formData.frequency === "Every 3 Weeks" ||
          formData.frequency === "Every 4 Weeks" ||
          formData.frequency === "" ? (
            <div className={styles.form_flex_container}>
              <div className={styles.checkcall_flex_item}>
                <Typography textAlign="left" textWeight="600" textSize="16px">
                  Do you want to add a checkcall?
                </Typography>
                {formData.checkCall && (
                  <RadioInput
                    label="Yes"
                    name="Yes"
                    value={formData.checkCall}
                    setValue={(data) => {
                      setFormData((prevData) => {
                        return {
                          ...prevData,
                          checkCall: true,
                        };
                      });
                    }}
                    labelWeight="600"
                    style={{ marginBottom: "8px", marginTop: "10px" }}
                  />
                )}
                {formData.checkCall && (
                  <RadioInput
                    label="No"
                    name="No"
                    value={!formData.checkCall}
                    setValue={(data) => {
                      setFormData((prevData) => {
                        return {
                          ...prevData,
                          checkCall: false,
                        };
                      });
                    }}
                    labelWeight="600"
                    style={{ marginBottom: "8px", marginTop: "8px" }}
                  />
                )}

                {formData.checkCall && (
                  <Checkbox
                    marginVertical="10px"
                    label={
                      <div>
                        <Typography
                          textAlign="left"
                          textWeight="600"
                          marginHorizontal="-10px"
                        >
                          Activate Checkcall
                        </Typography>
                      </div>
                    }
                    value={formData.activateCheckCall}
                    setValue={(data) =>
                      setFormData((prevData) => {
                        return {
                          ...prevData,
                          activateCheckCall: data,
                        };
                      })
                    }
                  />
                )}
              </div>
              {formData.checkCall && (
                <div className={styles.checkcall_flex_item}>
                  <SelectInput
                    dropdownTop="48px"
                    value={formData.frequencyOfCheckcall}
                    setValue={(data) => {
                      setFormData((prevData) => {
                        return { ...prevData, frequencyOfCheckcall: data };
                      });
                      // setRequiredFormData((prevData) => {
                      //   return { ...prevData, autoRosterGroup: false };
                      // });
                    }}
                    label="Frequency of Checkcall"
                    placeholder="Select Frequency"
                    required={true}
                    error={requiredFormData.autoRosterGroup}
                    options={[
                      "00:01",
                      "00:15",
                      "00:30",
                      "00:45",
                      "01:00",
                      "01:15",
                      "01:30",
                      "01:45",
                      "02:00",
                      "02:15",
                      "02:30",
                      "02:45",
                      "03:00",
                      "03:30",
                      "03:45",
                      "04:00",
                    ]}
                    datalistTop="83px"
                  />
                  <TextInput
                    value={formData.question}
                    setValue={(data) => {
                      setFormData((prevData) => {
                        return { ...prevData, question: data };
                      });
                    }}
                    label="Question"
                    placeholder="Is everything okay?"
                  />
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
      {formData.frequency !== "Every Month" &&
        formData.frequency !== "Every 2 Months" && (
          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.totalHoursInWeek}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, totalHoursInWeek: data };
                });
              }}
              label="Total Hours in Week"
              placeholder="0"
              disabled={true}
            />
            <ClientSelectInput
              value={formData.serviceGroup}
              client={formData.serviceGroup}
              datalistTop="92px"
              dropdownTop="56px"
              setValue={(name, id) => {
                setFormData((prevData) => {
                  return { ...prevData, serviceGroup: name };
                });
              }}
              label="Service Group"
              placeholder="Select Service Group"
              options={serviceGroup.map((item: any) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
            />
          </div>
        )}

      {formData.frequency === "Every Month" ||
      formData.frequency === "Every 2 Months" ? (
        <>
          <div className={styles.radio_container}>
            <RadioInput
              label="Days of the week"
              name="days-of-week"
              value={formData.isDayOfTheWeek}
              setValue={(data) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    isDayOfTheWeek: data,
                  };
                });
              }}
              labelWeight="600"
              style={{ marginBottom: "-20px", marginTop: "10px" }}
            />
            <FullWidthSelectInput
              value={formData.weekOfMonth}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, weekOfMonth: data };
                });
              }}
              label="Week of the Month"
              placeholder="Select Week of the Month"
              options={["First", "Second", "Third", "Fourth"]}
              disabled={!formData.isDayOfTheWeek}
            />
            <FullWidthSelectInput
              value={formData.dayOfWeek}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, dayOfWeek: data };
                });
              }}
              label="Day of the Week"
              placeholder="Select Day of the Week"
              options={[
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ]}
              disabled={!formData.isDayOfTheWeek}
              style={{ marginTop: "-10px" }}
            />
          </div>
          <div className={styles.radio_container}>
            <RadioInput
              label="Days of the Month"
              name="days-of-week"
              value={!formData.isDayOfTheWeek}
              setValue={(data) =>
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    isDayOfTheWeek: !data,
                  };
                })
              }
              labelWeight="600"
              style={{ marginBottom: "-20px", marginTop: "-15px" }}
            />
            <FullWidthSelectInput
              value={formData.monthDayOfWeek}
              setValue={(data) => {
                if (data) {
                  setFormData((prevData) => {
                    return { ...prevData, monthDayOfWeek: data };
                  });
                }
              }}
              label="Day of the Month"
              placeholder="Select Day of the Month"
              options={Array.from(Array(31).keys()).map((item) =>
                (item + 1).toString(),
              )}
              disabled={formData.isDayOfTheWeek}
            />

            {formData.frequency === "Every Month" ||
            formData.frequency === "Every 2 Months" ? (
              <div className={styles.form_flex_container}>
                {formData.checkCall && (
                  <div className={styles.checkcall_flex_item}>
                    <Typography
                      textAlign="left"
                      textWeight="600"
                      textSize="16px"
                    >
                      Do you want to add a checkcall?
                    </Typography>
                    {formData.checkCall && (
                      <RadioInput
                        label="Yes"
                        name="Yes"
                        value={formData.checkCall}
                        setValue={(data) => {
                          setFormData((prevData) => {
                            return {
                              ...prevData,
                              checkCall: true,
                            };
                          });
                        }}
                        labelWeight="600"
                        style={{ marginBottom: "8px", marginTop: "10px" }}
                      />
                    )}
                    {formData.checkCall && (
                      <RadioInput
                        label="No"
                        name="No"
                        value={!formData.checkCall}
                        setValue={(data) => {
                          setFormData((prevData) => {
                            return {
                              ...prevData,
                              checkCall: false,
                            };
                          });
                        }}
                        labelWeight="600"
                        style={{ marginBottom: "8px", marginTop: "8px" }}
                      />
                    )}

                    {formData.checkCall && (
                      <Checkbox
                        marginVertical="10px"
                        label={
                          <div>
                            <Typography
                              textAlign="left"
                              textWeight="600"
                              marginHorizontal="-10px"
                            >
                              Activate Checkcall
                            </Typography>
                          </div>
                        }
                        value={formData.activateCheckCall}
                        setValue={(data) =>
                          setFormData((prevData) => {
                            return {
                              ...prevData,
                              activateCheckCall: data,
                            };
                          })
                        }
                      />
                    )}
                  </div>
                )}
                {formData.checkCall && (
                  <div className={styles.checkcall_flex_item}>
                    <SelectInput
                      dropdownTop="48px"
                      value={formData.frequencyOfCheckcall}
                      setValue={(data) => {
                        setFormData((prevData) => {
                          return { ...prevData, frequencyOfCheckcall: data };
                        });
                        // setRequiredFormData((prevData) => {
                        //   return { ...prevData, autoRosterGroup: false };
                        // });
                      }}
                      label="Frequency of Checkcall"
                      placeholder="Select Frequency"
                      required={true}
                      error={requiredFormData.autoRosterGroup}
                      options={[
                        "00:15",
                        "00:30",
                        "00:45",
                        "01:00",
                        "01:15",
                        "01:30",
                        "01:45",
                        "01:45",
                        "01:45",
                        "02:00",
                        "02:15",
                        "02:30",
                        "02:45",
                        "03:00",
                        "03:30",
                        "03:45",
                        "04:00",
                      ]}
                    />
                    <TextInput
                      value={formData.question}
                      setValue={(data) => {
                        setFormData((prevData) => {
                          return { ...prevData, question: data };
                        });
                      }}
                      label="Question"
                      placeholder="Is everything okay?"
                    />
                  </div>
                )}
              </div>
            ) : (
              <div></div>
            )}

            {formData.frequency === "Every Month" ||
            formData.frequency === "Every 2 Months" ? (
              <div>
                {!formData.checkCall && (
                  <div style={{ flex: 1 }}>
                    {!formData.checkCall && (
                      <Typography
                        textAlign="left"
                        textWeight="600"
                        textSize="16px"
                      >
                        Do you want to add a checkcall?
                      </Typography>
                    )}
                    {!formData.checkCall && (
                      <RadioInput
                        label="Yes"
                        name="Yes"
                        value={formData.checkCall}
                        setValue={(data) => {
                          setFormData((prevData) => {
                            return {
                              ...prevData,
                              checkCall: true,
                            };
                          });
                        }}
                        labelWeight="600"
                        style={{ marginBottom: "8px", marginTop: "10px" }}
                      />
                    )}
                    {!formData.checkCall && (
                      <RadioInput
                        label="No"
                        name="No"
                        value={!formData.checkCall}
                        setValue={(data) => {
                          setFormData((prevData) => {
                            return {
                              ...prevData,
                              checkCall: false,
                            };
                          });
                        }}
                        labelWeight="600"
                        style={{ marginBottom: "8px", marginTop: "8px" }}
                      />
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div></div>
            )}

            <ClientSelectInput
              value={formData.serviceGroup}
              client={formData.serviceGroup}
              datalistTop="83px"
              dropdownTop="48px"
              setValue={(name, id) => {
                setFormData((prevData) => {
                  return { ...prevData, serviceGroup: name };
                });
              }}
              label="Service Group"
              placeholder="Select Service Group"
              options={serviceGroup.map((item: any) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AddServiceForm;
