import moment from "moment";
import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getActiveCheckcallGroupIds,
  setActiveCheckcallGroupIds,
} from "redux/slice/report/checkcallLogSlice";
import { CheckcallReportTableItemGroupInterface } from "types-definition/report";
import dropdownIcon from "../../../../assets/svg/button-icon.svg";
import Typography from "../../../Common/Typography";
import CheckcallReportTableItem from "../CheckcallReportTableItem";
import styles from "./index.module.css";

const CheckcallReportTableItemGroup: React.FC<
  CheckcallReportTableItemGroupInterface
> = ({ groupId, checkcalls }) => {
  const dispatch = useAppDispatch();

  const activeCheckcallGroupIds = useAppSelector(getActiveCheckcallGroupIds);

  const handleShowDropdown = () => {
    if (!activeCheckcallGroupIds.includes(groupId)) {
      dispatch(
        setActiveCheckcallGroupIds([...activeCheckcallGroupIds, groupId]),
      );
    } else {
      dispatch(
        setActiveCheckcallGroupIds(
          activeCheckcallGroupIds.filter((id) => id !== groupId),
        ),
      );
    }
  };

  return (
    <>
      <tr className={styles.tr}>
        <td className={`${styles.td_flex} ${styles.td}`}>
          <Typography textAlign="left">
            {checkcalls[0]?.employee?.name}
          </Typography>
        </td>
        <td className={styles.td_address}>
          <Typography textAlign="left"></Typography>
        </td>
        <td className={styles.td}>
          <Typography textAlign="left"></Typography>
        </td>
        <td className={styles.td}>
          <Typography textAlign="left"></Typography>
        </td>
        <td className={styles.td}>
          <Typography textAlign="left"></Typography>
        </td>
        <td
          className={styles.td}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Typography textAlign="left">
            {moment(checkcalls[0]?.date).format("DD/MM/YYYY")}
          </Typography>
          <div className={styles.checkcall__count}>{checkcalls.length}</div>
        </td>
        <td
          className={styles.td}
          onClick={handleShowDropdown}
          title={
            !activeCheckcallGroupIds.includes(groupId) ? "Expand" : "Collapse"
          }
        >
          <Typography
            textAlign="center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={dropdownIcon}
              alt="dropdown icon"
              width={20}
              height={20}
              style={{
                rotate: activeCheckcallGroupIds.includes(groupId)
                  ? "180deg"
                  : "0deg",
              }}
              className={styles.dropdown__icon}
            />
          </Typography>
        </td>
      </tr>

      {activeCheckcallGroupIds.includes(groupId) &&
        checkcalls?.map((singleCheckcall, index) => (
          <CheckcallReportTableItem
            {...singleCheckcall}
            key={singleCheckcall.id}
          />
        ))}
    </>
  );
};

export default CheckcallReportTableItemGroup;
