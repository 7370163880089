import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "../../../../components/Common/DashboardHeader";
import AdminSideBar from "../../../../components/Admin/SideBar";
import Typography from "../../../../components/Common/Typography";
import { GET_SYSTEM_USER_ACTIVITY } from "../../../../api/urls";
import { getRequest } from "../../../../api/request";
import PaginatedItems from "components/Common/Pagination";
import PageLoader from "../../../../components/Common/PageLoader";
import ScrollToTop from "../../../../components/Common/ScrollToTop";
import TextInput from "../../../../components/Form/TextInput";
import searchIcon from "../../../../assets/svg/search-gray-icon.svg";
import {
  deleteWebUser,
  getData,
  getUserTypes,
  setFileterActivityLogsByDate,
} from "../../../../utils/localstorage";
import colors from "../../../../theme/colors";
import { toastError } from "../../../../utils/toast";
import ActivityLogDateFilter from "../../../../components/Admin/Activity Logs/ActivityLogDateFilter";
import ActivityLogTable from "../../../../components/Admin/Activity Logs/ActivityLogTable";
import ExportActivityLogs from "../../../../components/Admin/Activity Logs/ExportActivityLogs";
import moment from "moment";
import { ADMIN } from "types-definition/constants";

const ActivityLogs: React.FC = () => {
  const data = JSON.parse(getData()! ?? "{}");
  const [requestLoading, setRequestLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [activityLogData, setActivityLogData] = useState<Array<any>>([]);
  const [totalItems, setTotalItems] = useState<any>([]);
  const [searchedValue, setSearchValue] = useState<any>("");
  const [filterDate, setFilterDate] = useState<any>({ from: "", to: "" });
  const [page, setPage] = useState<any>("");
  const [filterActivityLogData, setFilterActivityLogData] = useState<
    Array<any>
  >([]);
  const handlePaginate = (start: number, end: number, event?: any) => {
    setPage(event);
  };
  const user = getUserTypes();
  const getActivityLogs = async (from?: String, to?: String) => {
    !searchedValue && setRequestLoading(true);
    const response = await getRequest(
      `${GET_SYSTEM_USER_ACTIVITY}/${
        user === ADMIN ? data.id : data.adminId
      }?search=${searchedValue}&page=${page}&rom=${from}&to${to}&perPage=10`,
    );
    if (response?.success) {
      const data = response.data.activities
        .sort(
          (a: any, b: any) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        )
        .map((item: any) => {
          let action: string = "";
          if (item?.action === "CREATE" || "POST") {
            action = "CREATE";
          }
          if (item?.action === "UPDATE") {
            action = "UPDATE";
          }
          if (item?.action === "DELETE") {
            action = "DELETE";
          } else {
            // Do nothing
          }
          return {
            id: item.id,
            timestamp: moment(item.updatedAt).format("MMMM DD, YYYY h:mm A"),
            action: action,
            details: item?.newRecord,
            previousDetails: item?.previousRecord,
            user: item?.user?.name,
            category: item?.category,
          };
        });

      const numbersArray = Array.from(
        { length: response.data.totalItems },
        (_, index) => index + 1,
      );
      setTotalItems(numbersArray);
      setActivityLogData(data);
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
    setRequestLoading(false);
  };

  useEffect(() => {
    deleteWebUser();
    getActivityLogs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue, page]);

  const filterByDate = (startDate: string, endDate: string) => {
    getActivityLogs(
      moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    );
    setFilterDate({
      from: moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      to: moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    });

    setFileterActivityLogsByDate(
      JSON.stringify({
        from: moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        to: moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      }),
    );
  };

  const filterBySearch = (data: String) => {
    setFileterActivityLogsByDate(
      JSON.stringify({
        search: data,
        from: moment(filterDate.from).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        to: moment(filterDate.to).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      }),
    );
    setSearchValue(data);
  };

  const clearFilter = () => {
    setIsFilter(false);
    setFilterActivityLogData([]);
  };

  return (
    <>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <AdminSideBar active="activity logs" />
        <div
          className={
            isFilter && filterActivityLogData.length <= 5
              ? styles.item_container_empty
              : isFilter && filterActivityLogData.length > 5
                ? styles.item_container_fill
                : activityLogData.length <= 5
                  ? styles.item_container_empty
                  : styles.item_container_fill
          }
        >
          <div className={styles.flex_item}>
            <div>
              <Typography textAlign="left" textWeight="600" textSize="20px">
                Activity Log
              </Typography>
              <Typography textAlign="left" textColor={colors.gray1}>
                View log of all activities performed within the system
              </Typography>
            </div>
            <div className={styles.sub_flex_item}>
              <ActivityLogDateFilter
                setDate={filterByDate}
                clearFilterCallback={clearFilter}
              />
              <TextInput
                left={<img src={searchIcon} alt="search" />}
                placeholder="Search"
                value={searchedValue}
                setValue={(data) => {
                  filterBySearch(data);
                }}
                borderColor={colors.gray8}
              />
              <ExportActivityLogs />
            </div>
          </div>
          {activityLogData.length === 0 ? (
            <div className={styles.empty_container}>
              <Typography marginVertical="10px" paddingVertical="20px">
                No activty found
              </Typography>
            </div>
          ) : (
            <ActivityLogTable data={activityLogData} />
          )}
          <div className={styles.pagination_container}>
            <PaginatedItems
              items={totalItems}
              itemsPerPage={10}
              handlePaginate={handlePaginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityLogs;
