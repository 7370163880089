import React from "react";
import Typography from "../../../Common/Typography";
import TimeShiftGenerationTableItem from "../TimeShiftGenerationTableItem";
import styles from "./index.module.css";
import colors from "../../../../theme/colors";
import { TimeShiftGenerationProps } from "../../../../types-definition/admin";

const TimeShiftGenerationTable: React.FC<TimeShiftGenerationProps> = ({
  data,
}) => {
  return (
    <>
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.tr} ${styles.tr_head}`}>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Requested By
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Requested At
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  From
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  To
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Action
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Type
                </Typography>
              </th>
              <th className={styles.th}>
                <Typography
                  textAlign="left"
                  fontFamily="inter"
                  textWeight="600"
                  textColor={colors.tableText}
                >
                  Affected
                </Typography>
              </th>
              <th className={styles.th_index}></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <TimeShiftGenerationTableItem {...item} key={item.id} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TimeShiftGenerationTable;
