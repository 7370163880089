const colors = {
  primary1: "#006093",
  primary2: "#00DCDA",
  primary3: "#148A9B",
  primary4: "#3c9eac",
  primary5: "#E8F3F5",
  secondary1: "#1671D9",
  white: "#FFFFFF",
  transparent: "transparent",
  black: "#101928",
  gray1: "#667185",
  gray2: "#F0F2F5",
  gray3: "#98A2B3",
  gray4: "#7E8494",
  gray5: "#344054",
  gray6: "#A0AEC0",
  gray7: "#E5E5E5",
  gray8: "#D0D5DD",
  error: "#D42620",
  errorBg: "#FBEAE9",
  tableText: "#292D32",
  successtext: "#0F973D",
  successBg: "#e7f6ec",
  warningBg: "#FEF6E7",
  warningText: "#865503",
  progressText: "#04326B",
  progressBg: "#E3EFFC",
  primary_linear: "linear-gradient(rgb(0, 96, 147), rgb(0, 220, 218))",
  link: "#1371FD",
  clientInactiveBg: "#E8F3F5",
  clientInactive: "#0C545F",
  clientActiveBg: "#FEF6E7",
  clientActive: "#FF5C1A",
  rosterWarning: "#F3A218",
  rosterSchedule: "#475367",
  rosterCancelled: "#FB3A33",
  rosterInpogress: "#3146FF",
};

export default colors;
