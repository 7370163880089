"use client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "components/Common/Typography";
import size from "theme/size";
import TextInput from "components/Form/TextInput";
import styles from "./index.module.css";
import Button from "components/Form/Button";
import SignUpHeader from "components/Common/SignUpHeader";
import { getRequest, patchRequest } from "api/request";
import { CREATE_COMPANY_URL, GET_USER_DETAILS_URL } from "api/urls";
import { setShowGlixed } from "../../../redux/slice/auth/authSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { toastError } from "utils/toast";
import validator from "utils/validateFields";
import { getSetupComplete, setSetupComplete } from "utils/localstorage";

const CreateCompany: React.FC = () => {
  const setUpStep = getSetupComplete();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    companyName: "",
  });
  const [requiredFormData, setRequiredFormData] = useState({
    companyName: false,
  });
  const [requestLoading, setRequestLoading] = useState(false);
  const handleCreateCompany = async () => {
    setRequestLoading(true);
    const { data, status } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData(data as any);
      setRequestLoading(false);
      toastError("Fill required field");
      return;
    }

    const response = await patchRequest(CREATE_COMPANY_URL, formData);
    if (response?.success) {
      dispatch(setShowGlixed(true));
      if (setUpStep) {
        if (JSON.parse(setUpStep).step === "1") {
          setSetupComplete(
            JSON.stringify({
              completed: JSON.parse(setUpStep).completed,
              step: "1",
            }),
          );
          navigate("/setup-wizard");
        } else if (parseFloat(JSON.parse(setUpStep).step) > 13) {
          navigate("/dashboard");
        } else {
          setSetupComplete(
            JSON.stringify({
              completed: JSON.parse(setUpStep).completed,
              step: "1",
            }),
          );
          navigate("/setup-wizard");
        }
      }
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
    setRequestLoading(false);
  };

  const checkCompanyName = async () => {
    const response = await getRequest(GET_USER_DETAILS_URL);
    if (response?.success) {
      // if (response.data?.company?.companyName) {
      //   navigate("/dashboard");
      // }
    } else {
      if (response?.data) {
        toastError(response?.data);
      }
    }
  };
  
  useEffect(() => {
    checkCompanyName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main>
      <SignUpHeader showLogin={false} />
      <section className={styles.container}>
        <div className={styles.body_container}>
          <Typography
            fontFamily="inter"
            textAlign="center"
            textSize={size.lg}
            textWeight="600"
          >
            Create Company
          </Typography>
          <div className={styles.form_container}>
            <TextInput
              label="Company Name"
              value={formData.companyName}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, companyName: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, companyName: false };
                });
              }}
              placeholder="Enter Company Name"
              error={requiredFormData.companyName}
              helperText={
                requiredFormData.companyName ? "Company Name is required" : ""
              }
              style={{ marginBottom: "20px" }}
            />
            <Button
              text="Create Company"
              isPrimary={true}
              isLoading={requestLoading}
              // onClick={() => navigate("/dashboard")}
              onClick={handleCreateCompany}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default CreateCompany;
