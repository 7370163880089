import React, { useState } from "react";
import styles from "./index.module.css";
import FullWidthSelectInput from "../../../../Form/FullWidthSelectInput";
import {
  AddEmployeeCertificationFormProps,
  CertificationData,
} from "../../../../../types-definition/StaffHub/employee";
import { CertificationRequiredData } from "../../../../../types-definition/StaffHub/certificateGroup";
import TextInput from "../../../../Form/TextInput";
import DateInput from "../../../../Form/DateInput";
import Button from "../../../../Form/Button";
import colors from "../../../../../theme/colors";
import UploadItem from "../../../../Form/UploadItem";
import { postRequest } from "../../../../../api/request";
import { UPDATE_CERTIFICATE_URL } from "../../../../../api/urls";
import { getCurrentEmployee } from "../../../../../utils/localstorage";
import toast from "react-hot-toast";
import validator from "../../../../../utils/validateFields";
import { useAppDispatch } from "../../../../../redux/hooks";
import { addNewCertification } from "../../../../../redux/slice/staff-hub/employeeSlice";
import { toastSuccess } from "../../../../../utils/toast";
import ClientSelectInput from "components/Form/ClientSelectInput";

const AddEmployeeCertification: React.FC<AddEmployeeCertificationFormProps> = ({
  cancelFunction,
  certificateType,
}) => {
  const dispatch = useAppDispatch();
  const [localCertificateData, setLocalCertificateData] =
    useState<CertificationData>({
      certificate: "",
      certificateNumber: "",
      certificateType: "",
      certificateTypeName: "",
      id: "",
      expiryDate: "",
      startDate: "",
    });
  const [requiredLocalCertificateData, setRequiredLocalCertificateData] =
    useState<CertificationRequiredData>({
      certificateType: false,
      expiryDate: false,
      startDate: false,
    });
  const [requestLoading, setRequestLoading] = useState(false);
  const handleCertificateUpload = async () => {
    const { status, data } = validator(
      localCertificateData,
      requiredLocalCertificateData,
    );
    if (!status) {
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      setRequiredLocalCertificateData((prevData) => {
        return { ...prevData, ...data };
      });
      return;
    }
    setRequestLoading(true);
    const requestData = new FormData();
    requestData.append("certificateType", localCertificateData.certificateType);
    requestData.append(
      "certificateNumber",
      localCertificateData.certificateNumber,
    );
    requestData.append("startDate", localCertificateData.startDate);
    requestData.append("expiryDate", localCertificateData.expiryDate);
    if (localCertificateData.certificate instanceof File) {
      requestData.append("certificate", localCertificateData.certificate);
    }
    if (getCurrentEmployee() === null) {
      toast.error("Employee Record Not Found", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    const userData = JSON.parse(getCurrentEmployee()!);
    const response = await await postRequest(
      `${UPDATE_CERTIFICATE_URL}/${userData.name}`,
      requestData,
    );
    if (response?.success) {
      dispatch(addNewCertification(response.data));
      cancelFunction();
      toastSuccess("Certificate Uploaded");
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };
  // const data: CertificationData = {};
  return (
    <div className={styles.container}>
      <div className={styles.item_container}>
        <>
          <div>
            <ClientSelectInput
              value={localCertificateData.certificateTypeName}
              client={localCertificateData.certificateTypeName}
              setValue={(name, id) => {
                setLocalCertificateData((prevData) => {
                  return {
                    ...prevData,
                    certificateType: id ?? "",
                    certificateTypeName: name,
                  };
                });
                setRequiredLocalCertificateData((prevData) => {
                  return { ...prevData, certificateType: false };
                });
              }}
              datalistTop="83px"
              dropdownTop="48px"
              label="Certificate Type"
              placeholder="Certificate Type"
              options={certificateType}
              required={true}
              error={requiredLocalCertificateData.certificateType}
              helperText={
                requiredLocalCertificateData.certificateType
                  ? "Certificate Type Required"
                  : ""
              }
              readonly
            />
            <UploadItem
              label="Certificate"
              value={localCertificateData.certificate}
              setValue={(data) =>
                setLocalCertificateData((prevData) => {
                  return { ...prevData, certificate: data };
                })
              }
            />
          </div>
          <div className={styles.form_flex_container}>
            <TextInput
              value={localCertificateData.certificateNumber}
              setValue={(data) =>
                setLocalCertificateData((prevData) => {
                  return { ...prevData, certificateNumber: data };
                })
              }
              label="Certificate Number"
              placeholder="Enter Certificate Number"
            />
            <DateInput
              value={localCertificateData.startDate}
              setValue={(data) => {
                setLocalCertificateData((prevData) => {
                  return { ...prevData, startDate: data };
                });
                setRequiredLocalCertificateData((prevData) => {
                  return { ...prevData, startDate: false };
                });
              }}
              label="Start Date"
              placeholder="Select Date"
              required={true}
              error={requiredLocalCertificateData.startDate}
              helperText={
                requiredLocalCertificateData.startDate
                  ? "Start Date Required"
                  : ""
              }
            />
            <DateInput
              value={localCertificateData.expiryDate}
              setValue={(data) => {
                setLocalCertificateData((prevData) => {
                  return { ...prevData, expiryDate: data };
                });
                setRequiredLocalCertificateData((prevData) => {
                  return { ...prevData, expiryDate: false };
                });
              }}
              min={localCertificateData.startDate}
              validateMin={true}
              label="Expiry Date"
              placeholder="Select Date"
              required={true}
              error={requiredLocalCertificateData.expiryDate}
              helperText={
                requiredLocalCertificateData.expiryDate
                  ? "Expiry Date Required"
                  : ""
              }
            />
          </div>
          <div className={styles.button_container}>
            <Button
              width="200px"
              text="Cancel"
              isPrimary={false}
              onClick={cancelFunction}
              marginHorizontal="10px"
              backgroundColor={colors.transparent}
              isPrimaryBorder={true}
            />
            <Button
              width="200px"
              text="Save"
              isPrimary={true}
              onClick={handleCertificateUpload}
              isLoading={requestLoading}
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default AddEmployeeCertification;
