import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  showModal: false,
  gradeDeleted: false,
};

export const gradeSlice = createSlice({
  name: "grade",
  initialState,
  reducers: {
    updateGradeTableState: (state, action) => {
      state.showModal = action.payload.showModal;
    },
    deleteGradeHandler: (state, action) => {
      state.gradeDeleted = action.payload.gradeDeleted;
    },
  },
});
export const { updateGradeTableState, deleteGradeHandler } = gradeSlice.actions;

export const showModal = (state: RootState) => state.grade.showModal;
export const refreshDeleteGrade = (state: RootState) => state.grade.showModal;
export default gradeSlice.reducer;
