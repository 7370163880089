import React from "react";
import Typography from "../Typography";
import styles from "./index.module.css";
import colors from "../../../theme/colors";
import elementDescriptionIcon from "../../../assets/svg/elements-empty.svg";
import primaryPlusIcon from "../../../assets/svg/primary-plus-icon.svg";
import { useNavigate } from "react-router-dom";
import { EmptyItemStateProps } from "../../../types-definition/common";

const AdminEmptyItemState: React.FC<EmptyItemStateProps> = ({
  text,
  text2,
  text2Width = "680px",
  location,
  label,
  action,
  style,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.content_empty} style={style}>
      <img src={elementDescriptionIcon} alt="icon" width={40} height={40} />

      <div className={styles.midcontent_container}>
        <Typography
          textColor={colors.gray6}
          textWeight="500"
          style={{ marginBottom: "20px" }}
          textSize="16px"
        >
          {text}
        </Typography>
        <Typography
          textColor={colors.gray6}
          textWeight="500"
          style={{ width: text2Width, lineHeight: "20px" }}
          textAlign="left"
        >
          {text2}
        </Typography>
      </div>
      <div
        className={styles.pointer_cursor}
        onClick={action ? () => action() : () => navigate(`/${location}`)}
      >
        <img src={primaryPlusIcon} alt="plus-icon" />
        <Typography
          textWeight="600"
          style={{
            background: "linear-gradient(#006093, #00dcda)",
            WebkitTextFillColor: "transparent",
            WebkitBackgroundClip: "text",
          }}
        >
          {" "}
          {label}{" "}
        </Typography>
      </div>
    </div>
  );
};

export default AdminEmptyItemState;
