import React, { useState } from "react";
import styles from "./index.module.css";
import chevronDownIcon from "../../../../assets/svg/chevron-down-icon.svg";
import downloadIcon from "../../../../assets/svg/download-icon.svg";
import colors from "../../../../theme/colors";
import Typography from "../../../Common/Typography";
import {
  deleteFileterActivityLogsByDate,
  getData,
  getFileterActivityLogsByDate,
} from "utils/localstorage";
import { USER_ACTIVITY } from "api/urls";
const ExportActivityLogs = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  // const containerRef = useRef<HTMLDivElement>(null);
  const exportEsvHandle = async () => {
    const filter = getFileterActivityLogsByDate();

    const userDetails = getData();
    let csvEndpoint;
    if (userDetails && filter) {
      const { to, from, search } = JSON.parse(filter);
      const userId = JSON.parse(userDetails).id;
      csvEndpoint =
        search &&
        `https://app.glixapp.com/api/v1${USER_ACTIVITY}/${userId}/csv?search=${search}`;
      csvEndpoint =
        from &&
        `https://app.glixapp.com/api/v1${USER_ACTIVITY}/${userId}/csv?to=${to}&=${from}`;
      window.open(csvEndpoint, "_blank");
      // deleteFileterActivityLogsByDate();
    } else {
      if (userDetails && !filter) {
        const userId = JSON.parse(userDetails).id;
        const csvEndpoint: string = `https://app.glixapp.com/api/v1${USER_ACTIVITY}/${userId}/csv`;
        window.open(csvEndpoint, "_blank");
        deleteFileterActivityLogsByDate();
      }
    }
  };

  const exportExcellHandle = async () => {
    const filter = getFileterActivityLogsByDate();
    const userDetails = getData();
    let excelEndpoint;
    if (userDetails && filter) {
      const { to, from, search } = JSON.parse(filter);
      const userId = JSON.parse(userDetails).id;
      excelEndpoint =
        search &&
        `https://app.glixapp.com/api/v1${USER_ACTIVITY}/${userId}/excel?search=${search}`;
      excelEndpoint =
        from &&
        `https://app.glixapp.com/api/v1${USER_ACTIVITY}/${userId}/excel?to=${to}&=${from}`;
      window.open(excelEndpoint, "_blank");
      // deleteFileterActivityLogsByDate();
    } else {
      if (userDetails && !filter) {
        const userId = JSON.parse(userDetails).id;
        const excelEndpoint: string = `https://app.glixapp.com/api/v1${USER_ACTIVITY}/${userId}/excel`;
        window.open(excelEndpoint, "_blank");
        deleteFileterActivityLogsByDate();
      }
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div
          className={styles.button_container}
          onClick={() => {
            setShowDropDown(true);
          }}
        >
          <Typography textColor={colors.primary3} textWeight="600">
            Export
          </Typography>
          <img src={chevronDownIcon} alt="add" />
        </div>
      </div>
      {showDropDown && (
        <div className={styles.dropdown_container}>
          <div
            className={styles.sub_dropdown_item}
            onClick={() => {
              setShowDropDown(false);
              exportEsvHandle();
            }}
          >
            <img
              src={downloadIcon}
              alt="chevron-down"
              className={styles.dropdown_image_item}
            />
            <Typography>Export as CSV</Typography>
          </div>
          {/* <div
            className={styles.sub_dropdown_item}
            onClick={() => {
              setShowDropDown(false);
              // exportEsvHandle();
            }}
          >
            <img
              src={downloadIcon}
              alt="chevron-down"
              className={styles.dropdown_image_item}
            />
            <Typography textAlign="left">Export as PDF</Typography>
          </div> */}
          <div
            className={styles.sub_dropdown_item}
            onClick={() => {
              exportExcellHandle();
              setShowDropDown(false);
            }}
          >
            <img
              src={downloadIcon}
              alt="chevron-down"
              className={styles.dropdown_image_item}
            />
            <Typography>Export as Excel</Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportActivityLogs;
