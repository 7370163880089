import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import moment from "moment";
import { SiteLocationAuditProps } from "../../../../types-definition/client";
import colors from "../../../../theme/colors";
import { useNavigate } from "react-router-dom";
import { setLocationAudit } from "../../../../utils/localstorage";
import capitalize from "../../../../utils/capitalize";

const SiteLocationAuditTableItem: React.FC<SiteLocationAuditProps> = ({
  action,
  category,
  changedAt,
  changedBy,
  id,
  details,
}) => {
  const navigate = useNavigate();
  const handleViewDetails = () => {
    if (details?.location) {
      navigate("/client/location/audit/general");
    }
    if (details?.address) {
      navigate("/client/location/audit/address");
    }
    if (details?.locationOperations) {
      navigate("/client/location/audit/operations");
    }
    if (details?.requiredCertificates) {
      navigate("/client/location/audit/certificates");
    }

    if (details?.["employee-location"]) {
      navigate("/client/location/audit/link-location");
    }
  };
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left">
          {capitalize(category?.toLocaleUpperCase())}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {moment(new Date(changedAt)).format("LLL")}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{changedBy ?? "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {/* {action === "POST"
            ? "Create"
            : action === "DELETE"
              ? "Delete"
              : "Update"} */}

          {action}
        </Typography>
      </td>
      <td
        className={styles.td_details}
        onClick={() => {
          setLocationAudit(id);
          handleViewDetails();
        }}
      >
        <Typography textAlign="left" textColor={colors.primary3}>
          View Details
        </Typography>
      </td>
    </tr>
  );
};

export default SiteLocationAuditTableItem;
