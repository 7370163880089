import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../../Common/PageBackButton";
import Typography from "components/Common/Typography";
import colors from "../../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import { getRequest } from "../../../../../api/request";
import PageLoader from "../../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import DashboardHeader from "../../../../Common/DashboardHeader";
import { toastError } from "utils/toast";

const ActivityManualCheckpointSettingsTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentManualChecpoint, setcurrentManualChecpoint] = useState<any>([]);
  const [previousManualChecpoint, setPreviousManualChecpoint] = useState<any>(
    [],
  );
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getEmployeeData = async () => {
      // get employee data
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        if (response.data.action === "POST") {
          setcurrentManualChecpoint(
            response?.data?.newRecord?.manualCheckpoint,
          );
          setPreviousManualChecpoint(response?.data?.previousRecord);
        } else {
          setcurrentManualChecpoint([
            response?.data?.newRecord?.manualCheckpointReasons,
          ]);
          setPreviousManualChecpoint([
            response?.data?.previousRecord?.manualCheckpointReasons,
          ]);

          // setEmployeeData({
          //   firstName: data?.body?.firstName,
          //   lastName: data?.body.lastName,
          //   username: data?.body.username,
          //   startDate: data?.body.startDate,
          //   nationality: data?.body.nationality,
          //   dob: data?.body.dob,
          //   photo: data?.files?.photo ? data?.files?.photo[0] : "",
          //   proofOfId: data?.files?.proofOfId ? data?.files?.proofOfId[0] : "",
          //   gender: data?.body?.gender,
          //   updatedAt: response?.data.updatedAt,
          // });
          // setPreviousEmployeeData({
          //   firstName: previousData?.basicInformation?.firstName,
          //   lastName: previousData?.basicInformation?.lastName,
          //   username: previousData?.basicInformation?.username,
          //   startDate: previousData?.basicInformation?.startDate,
          //   nationality: previousData?.basicInformation?.nationality,
          //   dob: previousData?.basicInformation?.dob,
          //   photo: previousData?.employeePhoto,
          //   proofOfId: previousData?.employeeProofOfId,
          //   gender: previousData?.basicInformation?.gender,
          //   updatedAt: response?.data.updatedAt,
          // });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getEmployeeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Manual checkpoint Reason.
            </Typography>
            {currentManualChecpoint &&
              currentManualChecpoint.map((item: any, index: number) => {
                return (
                  <div className={styles.item_container_text_flex} key={index}>
                    <div>
                      <Typography textAlign="left">
                        Reason {index + 1}
                      </Typography>
                      <Typography
                        textAlign="left"
                        textSize="20px"
                        textWeight="600"
                      >
                        {item.reason}
                      </Typography>
                    </div>
                    <div>
                      <Typography textAlign="left">
                        Reason {index + 1} Description
                      </Typography>
                      <Typography
                        textAlign="left"
                        textSize="20px"
                        textWeight="600"
                      >
                        {item.reasonDescription ?? "-"}
                      </Typography>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Manual checkpoint Reason.
            </Typography>
            {previousManualChecpoint
              ? previousManualChecpoint.map((item: any, index: number) => {
                  return (
                    <div
                      className={styles.item_container_text_flex}
                      key={index}
                    >
                      <div>
                        <Typography textAlign="left">
                          Reason {index + 1}
                        </Typography>
                        <Typography
                          textAlign="left"
                          textSize="20px"
                          textWeight="600"
                        >
                          {item.reason}
                        </Typography>
                      </div>
                      <div>
                        <Typography textAlign="left">
                          Reason {index + 1} Description
                        </Typography>
                        <Typography
                          textAlign="left"
                          textSize="20px"
                          textWeight="600"
                        >
                          {item.reasonDescription ?? "-"}
                        </Typography>
                      </div>
                    </div>
                  );
                })
              : currentManualChecpoint.map((item: any, index: number) => {
                  return (
                    <div className={styles.item_container_text_flex}>
                      <div>
                        <Typography textAlign="left">
                          Reason {index + 1}
                        </Typography>
                        <Typography
                          textAlign="left"
                          textSize="20px"
                          textWeight="600"
                        >
                          --
                        </Typography>
                      </div>
                      <div>
                        <Typography textAlign="left">
                          Reason {index + 1} Description
                        </Typography>
                        <Typography
                          textAlign="left"
                          textSize="20px"
                          textWeight="600"
                        >
                          --
                        </Typography>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityManualCheckpointSettingsTab;
