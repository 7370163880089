const countries = [
  { isoCode: "AD", name: "Andorra", nationality: "Andorran" },
  { isoCode: "AE", name: "United Arab Emirates", nationality: "Emirati" },
  { isoCode: "AF", name: "Afghanistan", nationality: "Afghan" },
  {
    isoCode: "AG",
    name: "Antigua and Barbuda",
    nationality: "Antiguan, Barbudan",
  },
  { isoCode: "AI", name: "Anguilla", nationality: "Anguillian" },
  { isoCode: "AL", name: "Albania", nationality: "Albanian" },
  { isoCode: "AM", name: "Armenia", nationality: "Armenian" },
  // { isoCode: "AN", name: "Netherlands Antilles", nationality: "Dutch" },
  { isoCode: "AO", name: "Angola", nationality: "Angolan" },
  { isoCode: "AQ", name: "Antarctica", nationality: "???" },
  { isoCode: "AR", name: "Argentina", nationality: "Argentinean" },
  // { isoCode: "AS", name: "American Samoa", nationality: "American Samoan" },
  { isoCode: "AT", name: "Austria", nationality: "Austrian" },
  { isoCode: "AU", name: "Australia", nationality: "Australian" },
  { isoCode: "AW", name: "Aruba", nationality: "Aruban" },
  // { isoCode: "AX", name: "Åland Islands", nationality: "Swedish" },
  { isoCode: "AZ", name: "Azerbaijan", nationality: "Azerbaijani" },
  {
    isoCode: "BA",
    name: "Bosnia and Herzegovina",
    nationality: "Bosnian, Herzegovinian",
  },
  { isoCode: "BB", name: "Barbados", nationality: "Barbadian" },
  { isoCode: "BD", name: "Bangladesh", nationality: "Bangladeshi" },
  { isoCode: "BE", name: "Belgium", nationality: "Belgian" },
  { isoCode: "BF", name: "Burkina Faso", nationality: "Burkinabe" },
  { isoCode: "BG", name: "Bulgaria", nationality: "Bulgarian" },
  { isoCode: "BH", name: "Bahrain", nationality: "Bahraini" },
  { isoCode: "BI", name: "Burundi", nationality: "Burundian" },
  { isoCode: "BJ", name: "Benin", nationality: "Beninese" },
  {
    isoCode: "BL",
    name: "Saint Barthélemy",
    nationality: "Saint Barthélemy Islander",
  },
  { isoCode: "BM", name: "Bermuda", nationality: "Bermudian" },
  { isoCode: "BN", name: "Brunei", nationality: "Bruneian" },
  { isoCode: "BO", name: "Bolivia", nationality: "Bolivian" },
  // { isoCode: "BQ", name: "British Antarctic Territory", nationality: "Dutch" },
  { isoCode: "BR", name: "Brazil", nationality: "Brazilian" },
  { isoCode: "BS", name: "Bahamas", nationality: "Bahamian" },
  { isoCode: "BT", name: "Bhutan", nationality: "Bhutanese" },
  { isoCode: "BV", name: "Bouvet Island", nationality: "???" },
  { isoCode: "BW", name: "Botswana", nationality: "Motswana" },
  { isoCode: "BY", name: "Belarus", nationality: "Belarusian" },
  { isoCode: "BZ", name: "Belize", nationality: "Belizean" },
  { isoCode: "CA", name: "Canada", nationality: "Canadian" },
  {
    isoCode: "CC",
    name: "Cocos [Keeling] Islands",
    nationality: "Cocos Islander",
  },
  { isoCode: "CD", name: "Congo - Kinshasa", nationality: "Congolese" },
  {
    isoCode: "CF",
    name: "Central African Republic",
    nationality: "Central African",
  },
  // { isoCode: "CG", name: "Congo - Brazzaville", nationality: "Congolese" },
  { isoCode: "CH", name: "Switzerland", nationality: "Swiss" },
  { isoCode: "CI", name: "Côte d’Ivoire", nationality: "Ivorian" },
  { isoCode: "CK", name: "Cook Islands", nationality: "Cook Islander" },
  { isoCode: "CL", name: "Chile", nationality: "Chilean" },
  { isoCode: "CM", name: "Cameroon", nationality: "Cameroonian" },
  { isoCode: "CN", name: "China", nationality: "Chinese" },
  { isoCode: "CO", name: "Colombia", nationality: "Colombian" },
  { isoCode: "CR", name: "Costa Rica", nationality: "Costa Rican" },
  {
    isoCode: "CS",
    name: "Serbia and Montenegro",
    nationality: "Montenegrins, Serbs",
  },
  { isoCode: "CT", name: "Canton and Enderbury Islands", nationality: "???" },
  { isoCode: "CU", name: "Cuba", nationality: "Cuban" },
  { isoCode: "CV", name: "Cape Verde", nationality: "Cape Verdian" },
  { isoCode: "CW", name: "Curaçao", nationality: "Curaçaoan" },
  { isoCode: "CX", name: "Christmas Island", nationality: "Christmas Island" },
  { isoCode: "CY", name: "Cyprus", nationality: "Cypriot" },
  { isoCode: "CZ", name: "Czech Republic", nationality: "Czech" },
  { isoCode: "DE", name: "Germany", nationality: "German" },
  { isoCode: "DJ", name: "Djibouti", nationality: "Djibouti" },
  { isoCode: "DK", name: "Denmark", nationality: "Danish" },
  { isoCode: "DM", name: "Dominica", nationality: "Dominican" },
  // { isoCode: "DO", name: "Dominican Republic", nationality: "Dominican" },
  { isoCode: "DZ", name: "Algeria", nationality: "Algerian" },
  { isoCode: "EC", name: "Ecuador", nationality: "Ecuadorean" },
  { isoCode: "EE", name: "Estonia", nationality: "Estonian" },
  { isoCode: "EG", name: "Egypt", nationality: "Egyptian" },
  { isoCode: "EH", name: "Western Sahara", nationality: "Sahrawi" },
  { isoCode: "ER", name: "Eritrea", nationality: "Eritrean" },
  { isoCode: "ES", name: "Spain", nationality: "Spanish" },
  { isoCode: "ET", name: "Ethiopia", nationality: "Ethiopian" },
  { isoCode: "FI", name: "Finland", nationality: "Finnish" },
  { isoCode: "FJ", name: "Fiji", nationality: "Fijian" },
  { isoCode: "FK", name: "Falkland Islands", nationality: "Falkland Islander" },
  { isoCode: "FM", name: "Micronesia", nationality: "Micronesian" },
  { isoCode: "FO", name: "Faroe Islands", nationality: "Faroese" },
  // {
  //   isoCode: "FQ",
  //   name: "French Southern and Antarctic Territories",
  //   nationality: "???",
  // },
  { isoCode: "FR", name: "France", nationality: "French" },
  { isoCode: "FX", name: "Metropolitan France", nationality: "???" },
  { isoCode: "GA", name: "Gabon", nationality: "Gabonese" },
  { isoCode: "GB", name: "United Kingdom", nationality: "British" },
  { isoCode: "GD", name: "Grenada", nationality: "Grenadian" },
  { isoCode: "GE", name: "Georgia", nationality: "Georgian" },
  // { isoCode: "GF", name: "French Guiana", nationality: "???" },
  // { isoCode: "GG", name: "Guernsey", nationality: "Channel Islander" },
  { isoCode: "GH", name: "Ghana", nationality: "Ghanaian" },
  { isoCode: "GI", name: "Gibraltar", nationality: "Gibraltar" },
  { isoCode: "GL", name: "Greenland", nationality: "Greenlandic" },
  { isoCode: "GM", name: "Gambia", nationality: "Gambian" },
  { isoCode: "GN", name: "Guinea", nationality: "Guinean" },
  { isoCode: "GP", name: "Guadeloupe", nationality: "Guadeloupian" },
  {
    isoCode: "GQ",
    name: "Equatorial Guinea",
    nationality: "Equatorial Guinean",
  },
  { isoCode: "GR", name: "Greece", nationality: "Greek" },
  {
    isoCode: "GS",
    name: "South Georgia and the South Sandwich Islands",
    nationality: "South Georgia and the South Sandwich Islander",
  },
  { isoCode: "GT", name: "Guatemala", nationality: "Guatemalan" },
  { isoCode: "GU", name: "Guam", nationality: "Guamanian" },
  { isoCode: "GW", name: "Guinea-Bissau", nationality: "Guinea-Bissauan" },
  { isoCode: "GY", name: "Guyana", nationality: "Guyanese" },
  // { isoCode: "HK", name: "Hong Kong SAR China", nationality: "Chinese" },
  {
    isoCode: "HM",
    name: "Heard Island and McDonald Islands",
    nationality: "Heard and McDonald Islander",
  },
  { isoCode: "HN", name: "Honduras", nationality: "Honduran" },
  { isoCode: "HR", name: "Croatia", nationality: "Croatian" },
  { isoCode: "HT", name: "Haiti", nationality: "Haitian" },
  { isoCode: "HU", name: "Hungary", nationality: "Hungarian" },
  { isoCode: "ID", name: "Indonesia", nationality: "Indonesian" },
  { isoCode: "IE", name: "Ireland", nationality: "Irish" },
  { isoCode: "IL", name: "Israel", nationality: "Israeli" },
  { isoCode: "IM", name: "Isle of Man", nationality: "Manx" },
  { isoCode: "IN", name: "India", nationality: "Indian" },
  // {
  //   isoCode: "IO",
  //   name: "British Indian Ocean Territory",
  //   nationality: "Indian",
  // },
  { isoCode: "IQ", name: "Iraq", nationality: "Iraqi" },
  { isoCode: "IR", name: "Iran", nationality: "Iranian" },
  { isoCode: "IS", name: "Iceland", nationality: "Icelander" },
  { isoCode: "IT", name: "Italy", nationality: "Italian" },
  { isoCode: "JE", name: "Jersey", nationality: "Channel Islander" },
  { isoCode: "JM", name: "Jamaica", nationality: "Jamaican" },
  { isoCode: "JO", name: "Jordan", nationality: "Jordanian" },
  { isoCode: "JP", name: "Japan", nationality: "Japanese" },
  { isoCode: "JT", name: "Johnston Island", nationality: "???" },
  { isoCode: "KE", name: "Kenya", nationality: "Kenyan" },
  { isoCode: "KG", name: "Kyrgyzstan", nationality: "Kirghiz" },
  { isoCode: "KH", name: "Cambodia", nationality: "Cambodian" },
  { isoCode: "KI", name: "Kiribati", nationality: "I-Kiribati" },
  { isoCode: "KM", name: "Comoros", nationality: "Comoran" },
  {
    isoCode: "KN",
    name: "Saint Kitts and Nevis",
    nationality: "Kittian and Nevisian",
  },
  { isoCode: "KP", name: "North Korea", nationality: "North Korean" },
  { isoCode: "KR", name: "South Korea", nationality: "South Korean" },
  { isoCode: "KW", name: "Kuwait", nationality: "Kuwaiti" },
  { isoCode: "KY", name: "Cayman Islands", nationality: "Caymanian" },
  { isoCode: "KZ", name: "Kazakhstan", nationality: "Kazakhstani" },
  { isoCode: "LA", name: "Laos", nationality: "Laotian" },
  { isoCode: "LB", name: "Lebanon", nationality: "Lebanese" },
  { isoCode: "LC", name: "Saint Lucia", nationality: "Saint Lucian" },
  { isoCode: "LI", name: "Liechtenstein", nationality: "Liechtensteiner" },
  { isoCode: "LK", name: "Sri Lanka", nationality: "Sri Lankan" },
  { isoCode: "LR", name: "Liberia", nationality: "Liberian" },
  { isoCode: "LS", name: "Lesotho", nationality: "Mosotho" },
  { isoCode: "LT", name: "Lithuania", nationality: "Lithuanian" },
  { isoCode: "LU", name: "Luxembourg", nationality: "Luxembourger" },
  { isoCode: "LV", name: "Latvia", nationality: "Latvian" },
  { isoCode: "LY", name: "Libya", nationality: "Libyan" },
  { isoCode: "MA", name: "Morocco", nationality: "Moroccan" },
  { isoCode: "MC", name: "Monaco", nationality: "Monegasque" },
  { isoCode: "MD", name: "Moldova", nationality: "Moldovan" },
  { isoCode: "ME", name: "Montenegro", nationality: "Montenegrin" },
  { isoCode: "MF", name: "Saint Martin", nationality: "Saint Martin Islander" },
  { isoCode: "MG", name: "Madagascar", nationality: "Malagasy" },
  { isoCode: "MH", name: "Marshall Islands", nationality: "Marshallese" },
  { isoCode: "MI", name: "Midway Islands", nationality: "???" },
  { isoCode: "MK", name: "Macedonia", nationality: "Macedonian" },
  { isoCode: "ML", name: "Mali", nationality: "Malian" },
  { isoCode: "MM", name: "Myanmar [Burma]", nationality: "Myanmar" },
  { isoCode: "MN", name: "Mongolia", nationality: "Mongolian" },
  // { isoCode: "MO", name: "Macau SAR China", nationality: "Chinese" },
  // { isoCode: "MP", name: "Northern Mariana Islands", nationality: "American" },
  // { isoCode: "MQ", name: "Martinique", nationality: "French" },
  { isoCode: "MR", name: "Mauritania", nationality: "Mauritanian" },
  { isoCode: "MS", name: "Montserrat", nationality: "Montserratian" },
  { isoCode: "MT", name: "Malta", nationality: "Maltese" },
  { isoCode: "MU", name: "Mauritius", nationality: "Mauritian" },
  { isoCode: "MV", name: "Maldives", nationality: "Maldivan" },
  { isoCode: "MW", name: "Malawi", nationality: "Malawian" },
  { isoCode: "MX", name: "Mexico", nationality: "Mexican" },
  { isoCode: "MY", name: "Malaysia", nationality: "Malaysian" },
  { isoCode: "MZ", name: "Mozambique", nationality: "Mozambican" },
  { isoCode: "NA", name: "Namibia", nationality: "Namibian" },
  { isoCode: "NC", name: "New Caledonia", nationality: "New Caledonian" },
  //   { isoCode: "NE", name: "Niger", nationality: "Nigerian" },
  { isoCode: "NF", name: "Norfolk Island", nationality: "Norfolk Islander" },
  { isoCode: "NG", name: "Nigeria", nationality: "Nigerian" },
  { isoCode: "NI", name: "Nicaragua", nationality: "Nicaraguan" },
  { isoCode: "NL", name: "Netherlands", nationality: "Dutch" },
  { isoCode: "NO", name: "Norway", nationality: "Norwegian" },
  { isoCode: "NP", name: "Nepal", nationality: "Nepalese" },
  { isoCode: "NQ", name: "Dronning Maud Land", nationality: "???" },
  { isoCode: "NR", name: "Nauru", nationality: "Nauruan" },
  { isoCode: "NT", name: "Neutral Zone", nationality: "???" },
  { isoCode: "NU", name: "Niue", nationality: "Niuean" },
  { isoCode: "NZ", name: "New Zealand", nationality: "New Zealander" },
  { isoCode: "OM", name: "Oman", nationality: "Omani" },
  { isoCode: "PA", name: "Panama", nationality: "Panamanian" },
  {
    isoCode: "PC",
    name: "Pacific Islands Trust Territory",
    nationality: "???",
  },
  { isoCode: "PE", name: "Peru", nationality: "Peruvian" },
  // { isoCode: "PF", name: "French Polynesia", nationality: "French Polynesian" },
  { isoCode: "PG", name: "Papua New Guinea", nationality: "Papua New Guinean" },
  { isoCode: "PH", name: "Philippines", nationality: "Filipino" },
  { isoCode: "PK", name: "Pakistan", nationality: "Pakistani" },
  { isoCode: "PL", name: "Poland", nationality: "Polish" },
  {
    isoCode: "PM",
    name: "Saint Pierre and Miquelon",
    nationality: "Saint Pierrais and Miquelonnais",
  },
  { isoCode: "PN", name: "Pitcairn Islands", nationality: "Pitcairn Islander" },
  { isoCode: "PR", name: "Puerto Rico", nationality: "Puerto Rican" },
  {
    isoCode: "PS",
    name: "Palestinian Territories",
    nationality: "Palestinian",
  },
  { isoCode: "PT", name: "Portugal", nationality: "Portuguese" },
  {
    isoCode: "PU",
    name: "U.S. Miscellaneous Pacific Islands",
    nationality: "???",
  },
  { isoCode: "PW", name: "Palau", nationality: "Palauan" },
  { isoCode: "PY", name: "Paraguay", nationality: "Paraguayan" },
  { isoCode: "PZ", name: "Panama Canal Zone", nationality: "???" },
  { isoCode: "QA", name: "Qatar", nationality: "Qatari" },
  { isoCode: "QO", name: "Outlying Oceania", nationality: "???" },
  { isoCode: "RE", name: "Réunion", nationality: "Réunionese" },
  { isoCode: "RO", name: "Romania", nationality: "Romanian" },
  { isoCode: "RS", name: "Serbia", nationality: "Serbian" },
  { isoCode: "RU", name: "Russia", nationality: "Russian" },
  { isoCode: "RW", name: "Rwanda", nationality: "Rwandan" },
  { isoCode: "SA", name: "Saudi Arabia", nationality: "Saudi Arabian" },
  { isoCode: "SB", name: "Solomon Islands", nationality: "Solomon Islander" },
  { isoCode: "SC", name: "Seychelles", nationality: "Seychellois" },
  { isoCode: "SD", name: "Sudan", nationality: "Sudanese" },
  { isoCode: "SE", name: "Sweden", nationality: "Swedish" },
  { isoCode: "SG", name: "Singapore", nationality: "Singaporean" },
  { isoCode: "SH", name: "Saint Helena", nationality: "Saint Helenian" },
  { isoCode: "SI", name: "Slovenia", nationality: "Slovenian" },
  // { isoCode: "SJ", name: "Svalbard and Jan Mayen", nationality: "Norwegian" },
  { isoCode: "SK", name: "Slovakia", nationality: "Slovak" },
  { isoCode: "SL", name: "Sierra Leone", nationality: "Sierra Leonean" },
  { isoCode: "SM", name: "San Marino", nationality: "Sammarinese" },
  { isoCode: "SN", name: "Senegal", nationality: "Senegalese" },
  { isoCode: "SO", name: "Somalia", nationality: "Somali" },
  { isoCode: "SR", name: "Suriname", nationality: "Surinamer" },
  { isoCode: "SS", name: "South Sudan", nationality: "South Sudanese" },
  { isoCode: "ST", name: "São Tomé and Príncipe", nationality: "São Toméan" },
  { isoCode: "SU", name: "USSR", nationality: "???" },
  { isoCode: "SV", name: "El Salvador", nationality: "Salvadoran" },
  { isoCode: "SX", name: "Sint Maarten", nationality: "Sint Maarten Islander" },
  { isoCode: "SY", name: "Syria", nationality: "Syrian" },
  { isoCode: "SZ", name: "Swaziland", nationality: "Swazi" },
  { isoCode: "TA", name: "Tristan da Cunha", nationality: "???" },
  {
    isoCode: "TC",
    name: "Turks and Caicos Islands",
    nationality: "Turks and Caicos Islander",
  },
  { isoCode: "TD", name: "Chad", nationality: "Chadian" },
  // { isoCode: "TF", name: "French Southern Territories", nationality: "???" },
  { isoCode: "TG", name: "Togo", nationality: "Togolese" },
  { isoCode: "TH", name: "Thailand", nationality: "Thai" },
  { isoCode: "TJ", name: "Tajikistan", nationality: "Tadzhik" },
  { isoCode: "TK", name: "Tokelau", nationality: "Tokelauan" },
  { isoCode: "TL", name: "Timor-Leste", nationality: "Timorese" },
  { isoCode: "TM", name: "Turkmenistan", nationality: "Turkmen" },
  { isoCode: "TN", name: "Tunisia", nationality: "Tunisian" },
  { isoCode: "TO", name: "Tonga", nationality: "Tongan" },
  { isoCode: "TR", name: "Turkey", nationality: "Turkish" },
  {
    isoCode: "TT",
    name: "Trinidad and Tobago",
    nationality: "Trinidadian, Tobagonian",
  },
  { isoCode: "TV", name: "Tuvalu", nationality: "Tuvaluan" },
  { isoCode: "TW", name: "Taiwan", nationality: "Taiwanese" },
  { isoCode: "TZ", name: "Tanzania", nationality: "Tanzanian" },
  { isoCode: "UA", name: "Ukraine", nationality: "Ukrainian" },
  { isoCode: "UG", name: "Uganda", nationality: "Ugandan" },
  { isoCode: "UM", name: "U.S. Minor Outlying Islands", nationality: "???" },
  { isoCode: "US", name: "United States", nationality: "American" },
  { isoCode: "UY", name: "Uruguay", nationality: "Uruguayan" },
  { isoCode: "UZ", name: "Uzbekistan", nationality: "Uzbekistani" },
  { isoCode: "VA", name: "Vatican City", nationality: "???" },
  {
    isoCode: "VC",
    name: "Saint Vincent and the Grenadines",
    nationality: "Saint Vincentian",
  },
  { isoCode: "VD", name: "North Vietnam", nationality: "???" },
  { isoCode: "VE", name: "Venezuela", nationality: "Venezuelan" },
  {
    isoCode: "VG",
    name: "British Virgin Islands",
    nationality: "British Virgin Islander",
  },
  // {
  //   isoCode: "VI",
  //   name: "U.S. Virgin Islands",
  //   nationality: "American Virgin Islander",
  // },
  { isoCode: "VN", name: "Vietnam", nationality: "Vietnamese" },
  { isoCode: "VU", name: "Vanuatu", nationality: "Vanuatuan" },
  {
    isoCode: "WF",
    name: "Wallis and Futuna",
    nationality: "Wallisian, Futunan",
  },
  { isoCode: "WK", name: "Wake Island", nationality: "???" },
  { isoCode: "WS", name: "Samoa", nationality: "Samoan" },
  {
    isoCode: "YD",
    name: "People's Democratic Republic of Yemen",
    nationality: "???",
  },
  { isoCode: "YE", name: "Yemen", nationality: "Yemeni" },
  { isoCode: "YT", name: "Mayotte", nationality: "Mahoran" },
  { isoCode: "ZA", name: "South Africa", nationality: "South African" },
  { isoCode: "ZM", name: "Zambia", nationality: "Zambian" },
  { isoCode: "ZW", name: "Zimbabwe", nationality: "Zimbabwean" },
];

export const filterCountry = () => {
  return countries.filter((country) => country.nationality !== "???");
};

export const europeanCountries = [
  "Albania",
  "Andorra",
  "Armenia",
  "Austria",
  "Azerbaijan",
  "Belarus",
  "Belgium",
  "Bosnia and Herzegovina",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Kosovo",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Malta",
  "Moldova",
  "Monaco",
  "Montenegro",
  "The Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "San Marino",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Turkey",
  "Ukraine",
  "United Kingdom",
  "Vatican City",
];