import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import DateInput from "../../../Form/DateInput";
import cancelIcon from "../../../../assets/svg/cancel-icon.svg";
import {
  TimeShiftGenerationRequiredData,
  TimeShiftGenerationFormData,
} from "../../../../types-definition/admin";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  updateTimeShiftGenerationModal,
  updateStateHandler,
} from "../../../../redux/slice/admin/timeShiftGeneration";
import validator from "../../../../utils/validateFields";
import { postRequest, getRequest, patchRequest } from "../../../../api/request";
import {
  GET_SERVICE_LINK_TO_LOCATION,
  TIME_SHIFT_GENERATION,
} from "../../../../api/urls";
import {
  getTimeShiftGeneration,
  removeTimeShiftGeneration,
} from "../../../../utils/localstorage";
import toast from "react-hot-toast";
import { getSettings } from "../../../../utils/localstorage";
import moment from "moment";
import ClientSearchSelectInput from "components/Form/SearchClientSelectInput";
import { TimeShiftGenerationModalProps } from "../../../../types-definition/admin";
import { toastError, toastSuccess } from "../../../../utils/toast";

const AddTimeShiftsGenerationModal: React.FC<TimeShiftGenerationModalProps> = ({
  locations,
  serviceLinkedToLocation,
}) => {
  const settingsId = getSettings();
  const id = getTimeShiftGeneration();
  const dispatch = useAppDispatch();
  const [requestLoading, setrequestLoading] = useState(false);
  const [locationId, setLocationId] = useState("");
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const date = new Date().getDate();
  const [formData, setFormData] = useState<TimeShiftGenerationFormData>({
    generateRoster: true,
    deleteRoster: false,
    from: `${new Date(year, month, date)}`,
    to: `${new Date(year, month, date + 14)}`,
    location: [],
    service: [],
    locationName: "",
    serviceName: "",
  });

  const [services, setServices] = useState<Array<any>>([]);
  const [requiredFormData, setRequiredFormData] =
    useState<TimeShiftGenerationRequiredData>({
      from: false,
      to: false,
      location: false,
      service: false,
      locationName: false,
      serviceName: false,
    });

  const handleSubmitForm = async () => {
    const { status, data } = validator(formData, requiredFormData);
    if (formData.location.length > 1) {
      toastError("Cannot generate shift for more than one location.");
    } else {
      if (!status) {
        setRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        toastError("Complete all required fields before saving");
        return;
      } else {
        setrequestLoading(true);
        const locationIds = [formData.location[0].locationId];
        const servicesIds = formData.service.map((service: any) => {
          return service.locationId;
        });

        const response = id
          ? await patchRequest(`${TIME_SHIFT_GENERATION}/time-shift/${id}`, {
              from: moment
                .utc(
                  moment
                    .utc(formData.from, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
                    .format("DD/MM/YYYY"),
                  "DD/MM/YYYY",
                )
                .toISOString(),
              to: moment
                .utc(
                  moment
                    .utc(formData.to, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
                    .format("DD/MM/YYYY"),
                  "DD/MM/YYYY",
                )
                .toISOString(),
              location: locationIds,
              service: servicesIds,
            })
          : await postRequest(
              `${TIME_SHIFT_GENERATION}/${settingsId}/time-shift`,
              {
                from: moment
                  .utc(
                    moment
                      .utc(
                        formData.from,
                        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)",
                      )
                      .format("DD/MM/YYYY"),
                    "DD/MM/YYYY",
                  )
                  .toISOString(),
                to: moment
                  .utc(
                    moment
                      .utc(formData.to, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
                      .format("DD/MM/YYYY"),
                    "DD/MM/YYYY",
                  )
                  .toISOString(),
                location: locationIds,
                service: servicesIds,
              },
            );
        if (response?.success) {
          removeTimeShiftGeneration();
          toastSuccess("Time Shift Created");
          dispatch(
            updateTimeShiftGenerationModal({
              showTimeShiftGenerationModal: false,
            }),
          );
          dispatch(
            updateStateHandler({
              updateState: true,
            }),
          );
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
        setrequestLoading(false);
      }
      removeTimeShiftGeneration();
    }
  };
  const getServicesLinkedToSelectedLocation = async () => {
    const serviceWithLocationArray: any = [];
    const response = await getRequest(`${GET_SERVICE_LINK_TO_LOCATION}`);
    const vaalidServiceLinked = await response.data.filter(
      (item: any) => item.location.id !== undefined,
    );
    vaalidServiceLinked.forEach((item: any) => {
      const SeteviceWithLocation = {
        locationId: item?.location.id,
        locationName: item?.name,
        id: item?.service.id,
        name: item?.service.name,
      };
      serviceWithLocationArray.push(SeteviceWithLocation);
    });
    setServices(serviceWithLocationArray);
  };
  const getTimeShiftCertificate = async () => {
    if (id) {
      const response = await getRequest(
        `${TIME_SHIFT_GENERATION}/time-shift/${id}`,
      );
      if (response?.success) {
        setFormData({
          generateRoster: true,
          deleteRoster: true,
          from: response.data.to,
          to: response.data.to,
          location: [
            {
              name: response.data.locations.name,
              locationId: response.data.locations.id,
            },
          ],
          service: [
            {
              name: response.data.services.name,
              locationId: response.data.services.id,
            },
          ],
          locationName: "set",
          serviceName: "set",
        });
      }
    }
  };
  useEffect(() => {
    getTimeShiftCertificate();
    getServicesLinkedToSelectedLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600">Time Shifts Generation</Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() => {
              dispatch(updateTimeShiftGenerationModal(false));
              removeTimeShiftGeneration();
            }}
          />
        </div>
        <div className={styles.form_container}>
          <DateInput
            value={formData.from}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, from: data };
              });
              setRequiredFormData((prevData) => {
                return { ...prevData, from: false };
              });
            }}
            label="From"
            placeholder="Select Date"
            error={requiredFormData.from}
            helperText={requiredFormData.from ? "From Date is required" : ""}
          />
          <DateInput
            value={formData.to}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, to: data };
              });
              setRequiredFormData((prevData) => {
                return { ...prevData, to: false };
              });
            }}
            label="To"
            placeholder="Select Date"
            error={requiredFormData.to}
            helperText={requiredFormData.to ? "To Date is required" : ""}
          />

          <ClientSearchSelectInput
            label="Location"
            width="100%"
            value={formData.location}
            setValue={(data) => {
              setLocationId(data.length > 0 ? data[0].locationId : "");
              setFormData((prevData) => {
                return {
                  ...prevData,
                  location: data,
                  locationName: "set",
                };
              });
              setRequiredFormData((prevData) => {
                return { ...prevData, locationName: false };
              });
            }}
            options={[...locations]}
            error={requiredFormData.locationName}
            helperText={
              requiredFormData.locationName ? "Location is required" : ""
            }
          />
          {formData.location.length > 1 && (
            <ClientSearchSelectInput
              width="100%"
              label="Service"
              value={formData.service}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, service: data, serviceName: "set" };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, serviceName: false };
                });
              }}
              options={services.filter(
                (serv) =>
                  serv.id !== undefined && serv.locationId === locationId,
              )}
              error={requiredFormData.serviceName}
              helperText={
                requiredFormData.serviceName ? "Service is required" : ""
              }
              disabled={true}
              isToolTip
              toolTipText="If only one location is selected, then you can filter which Services within that location to generate for. If left blank all Services within the location will be generated for"
            />
          )}
          {formData.location.length < 1 && (
            <ClientSearchSelectInput
              width="100%"
              label="Service"
              value={formData.service}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, service: data, serviceName: "set" };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, serviceName: false };
                });
              }}
              options={services.filter(
                (serv) =>
                  serv.id !== undefined && serv.locationId === locationId,
              )}
              error={requiredFormData.serviceName}
              helperText={
                requiredFormData.serviceName ? "Service is required" : ""
              }
              disabled={
                formData.location.length < 1 || formData.location.length > 1
                  ? true
                  : false
              }
              isToolTip
              toolTipText="If only one location is selected, then you can filter which Services within that location to generate for. If left blank all Services within the location will be generated for"
            />
          )}

          {formData.location.length > 0 && formData.location.length < 2 && (
            <ClientSearchSelectInput
              width="100%"
              label="Service"
              value={formData.service}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, service: data, serviceName: "set" };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, serviceName: false };
                });
              }}
              options={services.filter(
                (serv) =>
                  serv.id !== undefined && serv.locationId === locationId,
              )}
              error={requiredFormData.serviceName}
              helperText={
                requiredFormData.serviceName ? "Service is required" : ""
              }
              disabled={formData.location.length < 1 ? true : false}
              isToolTip
              toolTipText="If only one location is selected, then you can filter which Services within that location to generate for. If left blank all Services within the location will be generated for"
            />
          )}

          <div className={styles.form_flex_container}></div>
        </div>
        <div className={styles.button_container}>
          <Button
            text="Cancel"
            isPrimary={false}
            onClick={() => {
              dispatch(updateTimeShiftGenerationModal(false));
              removeTimeShiftGeneration();
            }}
            marginHorizontal="10px"
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
          />
          <Button
            text="Generate Shift"
            isPrimary={true}
            onClick={handleSubmitForm}
            isLoading={requestLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddTimeShiftsGenerationModal;
