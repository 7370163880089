import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import UploadItem from "../../../Form/UploadItem";
import Checkbox from "../../../Form/Checkbox";
import { ClientFormProps } from "../../../../types-definition/client";
const AddClientForm: React.FC<ClientFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormFData,
}) => {
  return (
    <>
      <div>
        <TextInput
          value={formData.clientName}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, clientName: data };
            });
            setRequiredFormFData((prevData) => {
              return { ...prevData, clientName: false };
            });
          }}
          label="Client Name"
          placeholder="Enter Client Name"
          required={true}
          error={requiredFormData.clientName}
          helperText={
            requiredFormData.clientName ? "Client Name is required" : ""
          }
          id="clientName"
        />
      </div>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.description}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, description: data };
            })
          }
          label="Description"
          placeholder="Enter Description"
        />
        <Checkbox
          marginVertical="70px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Active
              </Typography>
            </div>
          }
          value={formData.active}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, active: data };
            })
          }
        />
      </div>
      <div>
        <UploadItem
          label="Sign-in Logo"
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, signInLogo: data };
            })
          }
          value={formData.signInLogo}
        />
        <UploadItem
          label="Smart Form Logo"
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, smartFormLogo: data };
            })
          }
          value={formData.smartFormLogo}
        />
        <UploadItem
          label="Smart Form Header"
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, smartFormHeader: data };
            })
          }
          value={formData.smartFormHeader}
        />
      </div>
    </>
  );
};

export default AddClientForm;
