import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import cancelIcon from "../../../../assets/svg/cancel-icon.svg";
import { AdminAddPermissoinGroupFormData } from "../../../../types-definition/admin";
import Checkbox from "../../../Form/Checkbox";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getCurrentSelectedId,
  setShowPermissionGroupEditModal,
  updateFetchedPermissionGroupCounter,
} from "../../../../redux/slice/admin/adminSlice";
import { getRequest, patchRequest } from "../../../../api/request";
import { GET_PERMISSION_GROUP_URL } from "../../../../api/urls";
import { toastError, toastSuccess } from "../../../../utils/toast";

const UpdatePermissionGroupModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(getCurrentSelectedId);
  const [requestLoading, setRequestLoading] = useState(false);
  const [formData, setFormData] = useState<AdminAddPermissoinGroupFormData>({
    name: "",
    description: "",
    active: true,
  });
  const handleFormCancelation = () => {
    dispatch(setShowPermissionGroupEditModal({ show: false, id: "" }));
  };
  const handleSubmitForm = async () => {
    setRequestLoading(true);
    const response = await patchRequest(`${GET_PERMISSION_GROUP_URL}/${id}`, {
      name: formData.name,
      description: formData.description,
      active: formData.active,
    });
    if (response?.success) {
      toastSuccess("Permission Group Updated");
    } else {
      if (response?.data) toastError(response?.data);
    }
    setRequestLoading(false);
    dispatch(setShowPermissionGroupEditModal({ show: false, id: "" }));
    dispatch(updateFetchedPermissionGroupCounter());
  };
  useEffect(() => {
    const getPermissionGroupInfo = async () => {
      const response = await getRequest(`${GET_PERMISSION_GROUP_URL}/${id}`);
      if (response?.success) {
        setFormData(response?.data.permissionGroup);
      }
    };
    getPermissionGroupInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography>Edit Permission Group</Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() =>
              dispatch(setShowPermissionGroupEditModal({ show: false, id: "" }))
            }
          />
        </div>
        <div className={styles.form_container}>
          <TextInput
            placeholder="Enter Permission name"
            label="Name"
            value={formData.name}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, name: data };
              });
            }}
          />
          <TextInput
            placeholder="Write a description for this permission group"
            label="Description"
            value={formData.description}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, description: data };
              });
            }}
          />
          <Checkbox
            value={formData.active}
            setValue={(data) => {
              setFormData((prevData) => {
                return { ...prevData, active: data };
              });
            }}
            label={"Active"}
          />
        </div>
        <div className={styles.button_container}>
          <Button
            text="Cancel"
            isPrimary={false}
            onClick={handleFormCancelation}
            marginHorizontal="10px"
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
          />
          <Button
            text="Update Permission Group"
            isPrimary={true}
            onClick={handleSubmitForm}
            isLoading={requestLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdatePermissionGroupModal;
