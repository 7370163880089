import React, { useRef } from "react";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import { NumberInputProps } from "../../../types-definition/form";
import styles from "./index.module.css";
import colors from "../../../theme/colors";

const NumberInputWithoutIcon: React.FC<NumberInputProps> = ({
  placeholder,
  label,
  right,
  left,
  value,
  setValue,
  disabled = false,
  width,
  style,
  rightIconRight = "15px",
  rightIconTop = "14px",
  showRightIcon = true,
  required = false,
  error = false,
  max = 100000000,
  min = 0,
  helperText,
  id,
  readOnly = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const exceptThisSymbols = ["e", "E", "-", "."];
  return (
    <section className={styles.container} style={{ ...style }} id={id}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <div className={styles.inputContainer}>
        <div className={styles.left}>{left}</div>
        <input
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onKeyDown={(evt) =>
            exceptThisSymbols.includes(evt.key) && evt.preventDefault()
          }
          ref={inputRef}
          className={
            right
              ? `${styles.input} ${styles.right_padding}`
              : left
                ? `${styles.input} ${styles.left_padding}`
                : styles.input
          }
          disabled={disabled}
          type="number"
          onWheel={(e) => {
            // e.preventDefault();
            if (inputRef?.current) inputRef?.current.blur();
          }}
          style={{
            width,
            borderColor: error ? colors.error : colors.gray3,
          }}
          min={min}
          max={max}
          readOnly={readOnly}
        />
        <div className={styles.helpertext_container}>
          <Typography
            textAlign="left"
            textSize={"10px"}
            textWeight="600"
            textColor={error ? colors.error : colors.primary3}
          >
            {helperText}
          </Typography>
        </div>
        {showRightIcon && (
          <div
            className={styles.right}
            style={{ top: rightIconTop, right: rightIconRight }}
          ></div>
        )}
      </div>
    </section>
  );
};

export default NumberInputWithoutIcon;
