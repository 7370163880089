import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import Button from "components/Form/Button";
import searchIcon from "assets/svg/search-icon.svg";
import colors from "theme/colors";
import addIcon from "assets/svg/add-icon.svg";
import { useNavigate } from "react-router-dom";
import EmptyItemState from "components/Common/EmptyItemState";
import AreaTable from "components/Client/Area/AreaTable";
import { getRequest } from "api/request";
import { GET_AREA_URL } from "api/urls";
import { AreaData } from "types-definition/client";
import PageLoader from "components/Common/PageLoader";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { deleteArea } from "utils/localstorage";
import AreaTableFilter from "components/Client/Area/AreaTableFilter";
import { useAppSelector } from "redux/hooks";
import {
  getAreaCounter,
  getShowAreaDeleteModal,
} from "redux/slice/client/areaSlice";
import DeleteAreaModal from "components/Client/Area/DeleteAreaModal";

const AreaDashbaord = () => {
  const navigate = useNavigate();
  const counter = useAppSelector(getAreaCounter);
  const showDeleteModal = useAppSelector(getShowAreaDeleteModal);
  const [areaData, setAreaData] = useState<Array<AreaData>>([]);
  const [filteredAreaData, setFilteredAreaData] = useState<Array<AreaData>>([]);
  const [isfilter, setIsfilter] = useState(false);
  const [sortFilter, setSortFilter] = useState("");
  const [searchedValues, setSearchedValues] = useState("");
  const [requestLoading, setRequestLoading] = useState(true);
  useEffect(() => {
    const getAllArea = async () => {
      try {
        const response = await getRequest(GET_AREA_URL);
        if (response?.success) {
          const areaDataArray: Array<AreaData> = [];
          for (const index in response.data) {
            // extract area table data
            const areaDataObject: AreaData = {
              id: response.data[index].id,
              name: response.data[index].areaName,
              active: response.data[index].active,
              description: response.data[index].description,
              locations: "5",
            };
            areaDataArray.push(areaDataObject);
          }
          setAreaData(areaDataArray);
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });
        }
      } finally {
        setRequestLoading(false);
      }
    };
    getAllArea();
    deleteArea();
  }, [counter]);

  // Filter Logic
  useEffect(() => {
    if (sortFilter && searchedValues) {
      const localAreaData = [...areaData];
      setIsfilter(true);
      setFilteredAreaData(
        localAreaData
          .sort((a: any, b: any) => {
            if (sortFilter === "Ascending Order") {
              return a.name.localeCompare(b.name);
            } else {
              return b.name.localeCompare(a.name);
            }
          })
          .filter((data: AreaData) => {
            return (
              data.name.toLowerCase().includes(searchedValues.toLowerCase()) ||
              data.description
                .toLowerCase()
                .includes(searchedValues.toLowerCase())
            );
          }),
      );
    } else if (searchedValues) {
      setIsfilter(true);
      setFilteredAreaData(
        areaData.filter((data: AreaData) => {
          return (
            data.name.toLowerCase().includes(searchedValues.toLowerCase()) ||
            data.description
              .toLowerCase()
              .includes(searchedValues.toLowerCase())
          );
        }),
      );
    } else if (sortFilter) {
      setIsfilter(true);
      if (sortFilter === "Ascending Order") {
        const localAreaData = [...areaData];
        setFilteredAreaData(
          localAreaData.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
          }),
        );
      } else {
        const localAreaData = [...areaData];
        setFilteredAreaData(
          localAreaData.sort((a: any, b: any) => {
            return b.name.localeCompare(a.name);
          }),
        );
      }
    } else {
      setIsfilter(false);
    }
  }, [searchedValues, sortFilter, areaData]);

  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="clients" />
        <div className={styles.container}>
          <div
            className={
              areaData.length <= 5
                ? `${styles.item_container} ${styles.item_container_empty}`
                : `${styles.item_container} ${styles.item_container_fill}`
            }
          >
            <div className={styles.flex_item}>
              <div>
                <Typography textAlign="left" textWeight="600" textSize="20px">
                  Area
                </Typography>
                <Typography textAlign="left" textColor={colors.gray1}>
                  Organize your employees&apos; work schedules using site
                  locations.
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <AreaTableFilter
                  runFilter={(data) => {
                    setSortFilter(data);
                  }}
                  clearFilter={() => setSortFilter("")}
                />
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={searchedValues}
                  setValue={(value) => {
                    setSearchedValues(value);
                  }}
                />
                <Button
                  left={<img src={addIcon} alt="add" />}
                  marginHorizontal="10px"
                  marginTop="8px"
                  paddingVertical="7px"
                  width="120px"
                  isPrimary
                  text="Area"
                  onClick={() => navigate("/client/area/add")}
                />
              </div>
            </div>
            {areaData.length === 0 ? (
              <EmptyItemState
                text="You haven't added any area yet"
                location="client/area/add"
                label="Add Area"
              />
            ) : (
              <AreaTable data={isfilter ? filteredAreaData : areaData} />
            )}
          </div>
        </div>
      </section>
      {showDeleteModal && <DeleteAreaModal />}
    </>
  );
};

export default AreaDashbaord;
