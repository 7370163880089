import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import addIcon from "assets/svg/add-icon.svg";
import EmptyItemState from "components/Common/EmptyItemState";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import EmailReportTable from "components/Client/EmailReport/EmailReportTable";
import AddEmailReportModal from "components/Client/EmailReport/AddMailReportModal";
import {
  getFetchReportCounter,
  getShowAddMailReportModal,
  getShowDeleteMailReportModal,
  setShowAddReportModal,
} from "../../../redux/slice/client/emailReportSlice";
import { toastError } from "utils/toast";
import { getLocation } from "utils/localstorage";
import { getRequest } from "api/request";
import DeleteMailReportModal from "components/Client/EmailReport/DeleteMailReportModal";
import PageBackButton from "components/Common/PageBackButton";

const ClientEmailReport = () => {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector(getShowAddMailReportModal);
  const showDeleteModal = useAppSelector(getShowDeleteMailReportModal);
  const counter = useAppSelector(getFetchReportCounter);
  const id = getLocation();
  // const [showSuccess, setShowSuccess] = useState(false);
  const [emailReport, setEmailReport] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  // const counter = useAppSelector(getClientCounter);
  useEffect(() => {
    const getMailReport = async () => {
      setRequestLoading(true);
      const response = await getRequest(`client/location/${id}/email-report`);
      if (response?.success) {
        setEmailReport(
          response.data.map((i: any) => {
            return {
              daysSelected: i.daysOfWeek?.join(", "),
              emailAddress: i.emailAddresses,
              frequency: i.frequency,
              lastSendDate: i.updatedAt,
              reportType: i.reportType,
              sendTime: i.sendTime,
              id: i.id,
            };
          }),
        );
        // dispatch(updateClientCounter(response.data.length));
        // setClients(response.data);
      } else {
        toastError(response?.data);
      }
      setRequestLoading(false);
    };
    getMailReport();
  }, [counter, id]);
  // useEffect(() => {}, [showSuccess]);

  return (
    <div>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="clients" />
        <div className={styles.container}>
          <div
            className={
              emailReport.length <= 5
                ? `${styles.item_container} ${styles.item_container_empty}`
                : `${styles.item_container} ${styles.item_container_fill}`
            }
          >
            <PageBackButton width="95%" paddingTop="20px" marginBottom="0px" />
            <div className={styles.flex_item}>
              <div>
                <Typography textAlign="left" textWeight="600" textSize="20px">
                  Send Email Report
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <Button
                  left={<img src={addIcon} alt="add" />}
                  marginHorizontal="10px"
                  marginTop="8px"
                  paddingVertical="7px"
                  width="200px"
                  isPrimary
                  text="Add Email Report"
                  onClick={() =>
                    dispatch(setShowAddReportModal({ show: true }))
                  }
                />
              </div>
            </div>
            {emailReport.length === 0 ? (
              <EmptyItemState
                text="No Email Report Created Yet"
                location=""
                action={() => dispatch(setShowAddReportModal({ show: true }))}
                label="Add Email Report"
              />
            ) : (
              <EmailReportTable data={emailReport} />
            )}
          </div>
        </div>
      </section>
      {showModal && <AddEmailReportModal />}
      {showDeleteModal && <DeleteMailReportModal />}
    </div>
  );
};

export default ClientEmailReport;
