import React from "react";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import TextInput from "../../Form/TextInput";
import SelectInput from "../../Form/SelectInput";
import colors from "../../../theme/colors";
import emailIcon from "../../../assets/svg/Mail Icon.svg";
import { Link } from "react-router-dom";
import { CreateAccountFormProps } from "../../../types-definition/common";
import Checkbox from "../../Form/Checkbox";
import { Country } from "country-state-city";
import PasswordInput from "../../Form/PasswordInput";

const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
  isTermsAgreed,
  setIsTermsAgreed,
}) => {
  return (
    <>
      <div className={styles.input_container}>
        <TextInput
          value={formData.firstName}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, firstName: data };
            });
            setRequiredFormData((prevData) => {
              return {
                ...prevData,
                firstName: { status: false, text: "" },
              };
            });
          }}
          label="First Name"
          placeholder="Enter First Name"
          error={requiredFormData.firstName.status}
          helperText={requiredFormData.firstName.text}
          id="firstName"
        />
      </div>
      <div className={styles.input_container}>
        <TextInput
          value={formData.lastName}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, lastName: data };
            });
            setRequiredFormData((prevData) => {
              return {
                ...prevData,
                lastName: { status: false, text: "" },
              };
            });
          }}
          label="Last Name"
          placeholder="Enter Last Name"
          error={requiredFormData.lastName.status}
          helperText={requiredFormData.lastName.text}
          id="lastName"
        />
      </div>

      <div className={styles.input_container}>
        <TextInput
          value={formData.email}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, email: data };
            });
            setRequiredFormData((prevData) => {
              return {
                ...prevData,
                email: { status: false, text: "" },
              };
            });
          }}
          label="Email Address"
          placeholder="Enter Email"
          right={<img src={emailIcon} alt="icon" width={20} height={20} />}
          error={requiredFormData.email.status}
          helperText={requiredFormData.email.text}
          id="email"
        />
      </div>
      <div className={styles.input_container}>
        <PasswordInput
          value={formData.password}
          setValue={(data) => {
            setFormData((prevData) => {
              return {
                ...prevData,
                password: data,
                repeat_password: data,
              };
            });
            setRequiredFormData((prevData) => {
              return {
                ...prevData,
                password: { status: false, text: "" },
              };
            });
          }}
          label="Password"
          placeholder="Enter Password"
          useValidation={true}
          error={requiredFormData.password.status}
          helperText={requiredFormData.password.text}
          id="password"
        />
      </div>
      <div className={styles.input_container}>
        <SelectInput
          value={formData.country}
          setValue={(data) => {
            setFormData((prevData) => {
              return {
                ...prevData,
                country: data,
                countryCode:
                  Country.getAllCountries().find(
                    (country) => country.name === data,
                  )?.isoCode || "",
              };
            });
            setRequiredFormData((prevData) => {
              return {
                ...prevData,
                country: { status: false, text: "" },
              };
            });
          }}
          label="Country"
          placeholder="Select Country"
          datalistTop="84px"
          dropdownTop="47px"
          options={Country.getAllCountries().map((data) => data.name)}
          error={requiredFormData.country.status}
          helperText={requiredFormData.country.text}
          id="country"
        />
      </div>
      <section>
        <Checkbox
          value={isTermsAgreed}
          setValue={(value) => setIsTermsAgreed(value)}
          label={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography textAlign="left" textSize="16px" marginVertical={0}>
                By creating an account, I confirm that I have read
              </Typography>
              <Typography textAlign="left" textSize="16px" marginVertical={0}>
                and understood the{" "}
                <Link
                  to="/terms-condition"
                  style={{ color: colors.black, fontWeight: "600" }}
                >
                  Terms and Conditions
                </Link>
                &nbsp;and
              </Typography>
              <Typography
                textAlign="left"
                textSize="16px"
                marginVertical={0}
                textWeight="600"
              >
                <Link to="/privacy-policy" style={{ color: colors.black }}>
                  Privacy Policy
                </Link>
              </Typography>
            </div>
          }
        />
      </section>
    </>
  );
};

export default CreateAccountForm;
