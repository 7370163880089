import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  showSideBar: false,
  showSignUpModal: false,
  showCancelSubscriptionModal: false,
  showCancellationFeedbackModal: false,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setShowAuthSideBar: (state, action: PayloadAction<boolean>) => {
      state.showSideBar = action.payload;
    },
    setShowSignUpModal: (state, action: PayloadAction<boolean>) => {
      state.showSignUpModal = action.payload;
    },
    setShowCancelSubscriptionModal: (state, action: PayloadAction<boolean>) => {
      state.showCancelSubscriptionModal = action.payload;
    },
    setShowCancellationFeedbackModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showCancellationFeedbackModal = action.payload;
    },
  },
});
export const {
  setShowAuthSideBar,
  setShowSignUpModal,
  setShowCancelSubscriptionModal,
  setShowCancellationFeedbackModal,
} = sidebarSlice.actions;

export const getShowAuthSideBar = (state: RootState) =>
  state.sidebar.showSideBar;
export const getShowSignUpModal = (state: RootState) =>
  state.sidebar.showSignUpModal;
export const getShowCancelSubscriptionModal = (state: RootState) =>
  state.sidebar.showCancelSubscriptionModal;
export const getShowCancellationFeedbackModal = (state: RootState) =>
  state.sidebar.showCancellationFeedbackModal;

export default sidebarSlice.reducer;
