import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import circleCancelIcon from "../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Button from "../../../Form/Button";
import SelectInput from "../../../Form/SelectInput";
import TextInput from "../../../Form/TextInput";
import fieldTextValidator from "../../../../utils/validateFieldsWithText";
import {
  EmailReportFormData,
  EmailReportRequiredFormData,
  EmailReportRequiredMonthlyFormData,
} from "../../../../types-definition/client";
import {
  getCurrentMailReportId,
  setShowAddReportModal,
  updateFetchReportCounter,
} from "../../../../redux/slice/client/emailReportSlice";
import Checkbox from "../../../Form/Checkbox";
import clockIcon from "../../../../assets/svg/gray-clock-icon.svg";
import timeArray from "../../../../utils/timeArray";
import { getRequest, postRequest, putRequest } from "../../../../api/request";
import { toastError, toastSuccess } from "../../../../utils/toast";
import { getLocation } from "../../../../utils/localstorage";
import capitalize from "../../../../utils/capitalize";

const AddEmailReportModal = () => {
  const dispatch = useAppDispatch();
  const id = getLocation();
  const mailReportId = useAppSelector(getCurrentMailReportId);

  const [requestLoading, setRequestLoading] = useState(false);
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formData, setFormData] = useState<EmailReportFormData>({
    active: true,
    reportType: "",
    frequency: "",
    daysOfWeek: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    sendTime: "",
    emailAddress: "",
    dataExtractPeriod: "",
  });

  const [requiredFormData, setRequiredFormData] =
    useState<EmailReportRequiredFormData>({
      reportType: {
        status: false,
        text: "",
      },
      frequency: { status: false, text: "" },
      sendTime: { status: false, text: "" },
      emailAddress: { status: false, text: "" },
    });

  const [requiredFormDataMonthly, setRequiredFormDataMonthly] =
    useState<EmailReportRequiredMonthlyFormData>({
      reportType: {
        status: false,
        text: "",
      },
      frequency: { status: false, text: "" },
      sendTime: { status: false, text: "" },
      emailAddress: { status: false, text: "" },
      dataExtractPeriod: { status: false, text: "" },
    });

  const handleFormSubmit = async () => {
    setRequestLoading(true);
    if (formData.frequency !== "Monthly") {
      const { status, data } = fieldTextValidator(formData, requiredFormData);
      if (!status) {
        setRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        setRequestLoading(false);
        return;
      }
      let response;
      if (mailReportId) {
        response = await putRequest(
          `/client/location/email-report/${mailReportId}`,
          {
            active: formData.active,
            reportType: formData.reportType,
            sendTime: formData.sendTime,
            frequency: formData.frequency.toLowerCase(),
            daysOfWeek: [
              formData.daysOfWeek.sunday && "sunday",
              formData.daysOfWeek.monday && "monday",
              formData.daysOfWeek.tuesday && "tuesday",
              formData.daysOfWeek.wednesday && "wednesday",
              formData.daysOfWeek.thursday && "thursday",
              formData.daysOfWeek.friday && "friday",
              formData.daysOfWeek.saturday && "saturday",
            ].filter(Boolean),
            emailAddresses: formData.emailAddress
              .split(",")
              .map((i) => i.trim()),
          },
        );
      } else {
        response = await postRequest(`/client/location/${id}/email-report`, {
          active: formData.active,
          reportType: formData.reportType,
          sendTime: formData.sendTime,
          frequency: formData.frequency.toLowerCase(),
          daysOfWeek: [
            formData.daysOfWeek.sunday && "sunday",
            formData.daysOfWeek.monday && "monday",
            formData.daysOfWeek.tuesday && "tuesday",
            formData.daysOfWeek.wednesday && "wednesday",
            formData.daysOfWeek.thursday && "thursday",
            formData.daysOfWeek.friday && "friday",
            formData.daysOfWeek.saturday && "saturday",
          ].filter(Boolean),
          emailAddresses: formData.emailAddress.split(","),
        });
      }
      if (response?.success) {
        toastSuccess(
          mailReportId
            ? "Email Report Updated Successfully"
            : "Email Report Added Successfully",
        );
        setRequestLoading(false);
        dispatch(updateFetchReportCounter());
        dispatch(setShowAddReportModal({ show: false }));
      } else {
        toastError(response?.data);
        setRequestLoading(false);
      }
    } else {
      const { status, data } = fieldTextValidator(
        formData,
        requiredFormDataMonthly,
      );
      if (!status) {
        setRequiredFormDataMonthly((prevData) => {
          return { ...prevData, ...data };
        });
        setRequestLoading(false);
        return;
      }
      const response = await postRequest(
        `/client/location/${id}/email-report`,
        {
          active: formData.active,
          reportType: formData.reportType,
          sendTime: formData.sendTime,
          frequency: formData.frequency.toLowerCase(),
          dataExtractPeriod: formData.dataExtractPeriod,
          emailAddresses: formData.emailAddress.split(","),
        },
      );
      if (response?.success) {
        toastSuccess(
          mailReportId
            ? "Email Report Updated Successfully"
            : "Email Report Added Successfully",
        );
        dispatch(updateFetchReportCounter());
        dispatch(setShowAddReportModal({ show: false }));
        setRequestLoading(false);
      } else {
        toastError(response?.data);
        setRequestLoading(false);
      }
    }
  };

  useEffect(() => {
    const getMailReportDetails = async () => {
      const response = await getRequest(
        `/client/location/email-report/${mailReportId}`,
      );
      if (response?.success) {
        setFormData({
          sendTime: response?.data?.sendTime,
          active: response?.data?.active,
          reportType: response?.data?.reportType,
          frequency: capitalize(response?.data?.frequency),
          daysOfWeek: {
            monday: response?.data?.daysOfWeek?.includes("monday"),
            tuesday: response?.data?.daysOfWeek?.includes("tuesday"),
            wednesday: response?.data?.daysOfWeek?.includes("wednesday"),
            thursday: response?.data?.daysOfWeek?.includes("thursday"),
            friday: response?.data?.daysOfWeek?.includes("friday"),
            saturday: response?.data?.daysOfWeek?.includes("saturday"),
            sunday: response?.data?.daysOfWeek?.includes("sunday"),
          },
          emailAddress: response?.data?.emailAddresses.join(","),
          dataExtractPeriod: response?.data?.dataExtractPeriod,
        });
      } else {
        toastError(response?.data);
      }
    };
    if (mailReportId) {
      getMailReportDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className={styles.container}>
        <div className={styles.flex_container}>
          <Typography textWeight="600" textSize="20px">
            Add Email Report
          </Typography>
          <img
            src={circleCancelIcon}
            alt="cancel-icon"
            onClick={() => {
              dispatch(setShowAddReportModal({ show: false }));
            }}
          />
        </div>
        <div className={styles.form_container}>
          <Checkbox
            marginVertical="10px"
            label={
              <div>
                <Typography
                  textAlign="left"
                  textWeight="600"
                  marginHorizontal="-10px"
                >
                  Active
                </Typography>
              </div>
            }
            value={formData.active}
            setValue={(data) =>
              setFormData((prevData) => {
                return {
                  ...prevData,
                  active: data,
                };
              })
            }
          />
          <SelectInput
            label="Report Type"
            placeholder="Select Report Type"
            value={formData.reportType}
            setValue={(data) => {
              setFormData({ ...formData, reportType: data });
              setRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  reportType: { status: false, text: "" },
                };
              });
              setRequiredFormDataMonthly((prevData) => {
                return {
                  ...prevData,
                  reportType: { status: false, text: "" },
                };
              });
            }}
            helperText={
              requiredFormData.reportType.text ||
              requiredFormDataMonthly.reportType.text
            }
            error={
              requiredFormData.reportType.status ||
              requiredFormDataMonthly.reportType.status
            }
            dropdownTop="46px"
            datalistTop="84px"
            options={["Timesheet Report", "Checkpoint Report"]}
          />
          <SelectInput
            label="Frequency"
            placeholder="Select Frequency Type"
            value={formData.frequency}
            setValue={(data) => {
              setFormData({ ...formData, frequency: data });
              setRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  frequency: { status: false, text: "" },
                };
              });
              setRequiredFormDataMonthly((prevData) => {
                return {
                  ...prevData,
                  frequency: { status: false, text: "" },
                };
              });
            }}
            helperText={
              requiredFormData.frequency.text ||
              requiredFormDataMonthly.frequency.text
            }
            error={
              requiredFormData.frequency.status ||
              requiredFormDataMonthly.frequency.status
            }
            dropdownTop="46px"
            datalistTop="84px"
            options={["Weekly", "Monthly"]}
          />

          {formData.frequency === "Weekly" && (
            <div>
              <div>
                <Typography
                  textWeight="600"
                  textSize="17px"
                  marginVertical="20px"
                  textAlign="left"
                  style={{ marginBottom: "-20px" }}
                >
                  Days of Week
                </Typography>
              </div>
              <div className={styles.radio_container}>
                <Checkbox
                  marginVertical="5px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="500"
                        marginHorizontal="-10px"
                      >
                        Monday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.monday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, monday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="5px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="500"
                        marginHorizontal="-10px"
                      >
                        Tuesday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.tuesday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, tuesday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="5px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="500"
                        marginHorizontal="-10px"
                      >
                        Wednesday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.wednesday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, wednesday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="5px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="500"
                        marginHorizontal="-10px"
                      >
                        Thursday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.thursday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, thursday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="5px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="500"
                        marginHorizontal="-10px"
                      >
                        Friday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.friday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, friday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="5px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="500"
                        marginHorizontal="-10px"
                      >
                        Saturday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.saturday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, saturday: data },
                      };
                    })
                  }
                />
                <Checkbox
                  marginVertical="5px"
                  label={
                    <div>
                      <Typography
                        textAlign="left"
                        textWeight="500"
                        marginHorizontal="-10px"
                      >
                        Sunday
                      </Typography>
                    </div>
                  }
                  value={formData.daysOfWeek.sunday}
                  setValue={(data) =>
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        daysOfWeek: { ...prevData.daysOfWeek, sunday: data },
                      };
                    })
                  }
                />
              </div>
            </div>
          )}
          {formData.frequency === "Monthly" && (
            <SelectInput
              placeholder="Select Data Extract Period"
              value={formData.dataExtractPeriod}
              label="Data Extract Period"
              setValue={(data) => {
                setFormData({ ...formData, dataExtractPeriod: data });
                setRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    dataExtractPeriod: { status: false, text: "" },
                  };
                });
              }}
              helperText={requiredFormDataMonthly.dataExtractPeriod.text}
              error={requiredFormDataMonthly.dataExtractPeriod.status}
              dropdownTop="46px"
              datalistTop="84px"
              options={["Current Month", "Previous Month"]}
            />
          )}
          <SelectInput
            placeholder="00:00"
            value={formData.sendTime}
            label="Send Time"
            setValue={(data) => {
              setFormData({ ...formData, sendTime: data });
              setRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  sendTime: { status: false, text: "" },
                };
              });
              setRequiredFormDataMonthly((prevData) => {
                return {
                  ...prevData,
                  sendTime: { status: false, text: "" },
                };
              });
            }}
            helperText={
              requiredFormData.sendTime.text ||
              requiredFormDataMonthly.sendTime.text
            }
            error={
              requiredFormData.sendTime.status ||
              requiredFormDataMonthly.sendTime.status
            }
            dropdownTop="46px"
            datalistTop="84px"
            options={timeArray}
            img={<img src={clockIcon} alt="clock-icon" />}
            height="150px"
          />
          <TextInput
            label="Email Address"
            placeholder="Enter Email Addresses"
            value={formData.emailAddress}
            setValue={(data) => {
              setFormData({ ...formData, emailAddress: data });
              setRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  emailAddress: { status: false, text: "" },
                };
              });
              setRequiredFormDataMonthly((prevData) => {
                return {
                  ...prevData,
                  emailAddress: { status: false, text: "" },
                };
              });
            }}
            helperText={
              requiredFormData.emailAddress.text ||
              requiredFormDataMonthly.emailAddress.text
            }
            error={
              requiredFormData.emailAddress.status ||
              requiredFormDataMonthly.emailAddress.status
            }
          />
        </div>
        <div className={styles.button_container}>
          <Button
            text="Cancel"
            isPrimary={false}
            isPrimaryBorder
            backgroundColor="transparent"
            onClick={() => {
              dispatch(setShowAddReportModal({ show: false }));
            }}
            marginHorizontal="5px"
          />
          <Button
            text={mailReportId ? "Update" : "Save"}
            isLoading={requestLoading}
            isPrimary
            marginHorizontal="5px"
            onClick={handleFormSubmit}
          />
        </div>
      </section>
    </>
  );
};

export default AddEmailReportModal;
