import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import cancelIcon from "../../../../assets/svg/cancel-icon.svg";
import {
  AdminAddPermissoinGroupFormData,
  AdminAddPermissoinGroupRequiredFormData,
} from "../../../../types-definition/admin";
import Checkbox from "../../../Form/Checkbox";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  setShowPermissionGroup,
  updateFetchedPermissionGroupCounter,
} from "../../../../redux/slice/admin/adminSlice";
import { postRequest } from "../../../../api/request";
import { CREATE_PERMISSION_GROUP_URL } from "../../../../api/urls";
import { toastError, toastSuccess } from "../../../../utils/toast";
import { useNavigate } from "react-router-dom";
import fieldTextValidator from "utils/validateFieldsWithText";
import { updateSetupComplete } from "utils/setupWizard";

const AddPermissionGroupModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [requestLoading, setrequestLoading] = useState(false);
  const [formData, setFormData] = useState<AdminAddPermissoinGroupFormData>({
    name: "",
    description: "",
    active: true,
  });
  const [requiredFormData, setRequiredFormData] =
    useState<AdminAddPermissoinGroupRequiredFormData>({
      name: false,
    });
  const handleSubmitForm = async () => {
    setrequestLoading(true);
    const { data, status } = fieldTextValidator(formData, requiredFormData);
    if (!status) {
      setrequestLoading(false);
      toastError("Fill all required fields");
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      return;
    }
    const response = await postRequest(CREATE_PERMISSION_GROUP_URL, formData);
    if (response?.success) {
      toastSuccess("Permission Group Created");
      dispatch(setShowPermissionGroup(false));
      dispatch(updateFetchedPermissionGroupCounter());
      const setUpComplete = await updateSetupComplete(
        "3",
        false,
        "permissiongroup",
      );
      if (!setUpComplete) {
        navigate("/setup-wizard");
      }
    } else {
      if (response?.data) toastError(response?.data);
    }
    setrequestLoading(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600">New Permission Group</Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() => dispatch(setShowPermissionGroup(false))}
          />
        </div>
        <div className={styles.form_container}>
          <div className={styles.form_container_input}>
            <TextInput
              placeholder="Enter Permission name"
              label="Name"
              value={formData.name}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, name: data };
                });
                setRequiredFormData((prevData) => {
                  return { ...prevData, name: false };
                });
              }}
              error={requiredFormData.name}
              helperText={
                requiredFormData.name ? "Permission Name is required" : ""
              }
            />
          </div>

          <div className={styles.form_container_input}>
            <TextInput
              placeholder="Write a description for this permission group"
              label="Description"
              value={formData.description}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, description: data };
                });
              }}
            />
          </div>
          <div className={styles.form_container_checkbox}>
            <Checkbox
              value={formData.active}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, active: data };
                });
              }}
              label={"Active"}
            />
          </div>
        </div>
        <div className={styles.button_container}>
          <Button
            text="Cancel"
            isPrimary={false}
            onClick={() => dispatch(setShowPermissionGroup(false))}
            marginHorizontal="10px"
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
          />
          <Button
            text="Save Permission Group"
            isPrimary={true}
            onClick={handleSubmitForm}
            isLoading={requestLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddPermissionGroupModal;
