import React from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import { getCountry } from "utils/localstorage";
import { UK } from "types-definition/constants";
import ActivityEmployeeHrUk from "components/Admin/Activity Logs/EmployeeActivityLogs/ActivityEmployeeHrUk";
import ActivityEmployeeHrNonUk from "components/Admin/Activity Logs/EmployeeActivityLogs/ActivityEmployeeHrNonUk";

const ActivityEmployeeHr = () => {
  const country = getCountry();
  return (
    <div className={styles.main_container}>
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      {country === UK ? <ActivityEmployeeHrUk /> : <ActivityEmployeeHrNonUk />}
    </div>
  );
};

export default ActivityEmployeeHr;
