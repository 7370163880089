import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import AdminSideBar from "components/Admin/SideBar";
import Typography from "components/Common/Typography";
import SettingsNavigation from "components/Admin/Settings/SettingsNavigation";
import {
  TimeCheckRequiredFormData,
  AdminTimeCheckSettingsFormData,
} from "types-definition/admin";
import Button from "components/Form/Button";
import colors from "theme/colors";
import {
  TIME_CHECK,
  GET_USER_SETTINGS_URL,
  GET_USER_ADMIN_SETTINGS_URL,
} from "api/urls";
import { getRequest, patchRequest } from "api/request";
import { useNavigate } from "react-router-dom";
import SettingsTimeCheckForm from "components/Admin/Settings/SettingsTimeCheckForm";
import { toastError, toastSuccess } from "utils/toast";
import fieldTextValidator from "utils/validateFieldsWithText";
import { getUserTypes } from "utils/localstorage";
import { ADMIN } from "types-definition/constants";

const AdminTimeCheckSettings: React.FC = () => {
  const navigate = useNavigate();
  const user = getUserTypes();
  const [timeCheckpointId, setTimeCheckpointId] = useState<number>();
  const [formData, setFormData] = useState<AdminTimeCheckSettingsFormData>({
    beforeShiftStart: "",
    afterShiftStart: "",
    beforeShiftEnd: "",
    afterShiftEnd: "",
    preCheckpointTime: "",
    postCheckpointTime: "",
  });

  const [requiredFormData, setRequiredFormData] =
    useState<TimeCheckRequiredFormData>({
      beforeShiftStart: {
        status: false,
        text: "",
      },
      afterShiftStart: {
        status: false,
        text: "",
      },
      beforeShiftEnd: {
        status: false,
        text: "",
      },
      afterShiftEnd: {
        status: false,
        text: "",
      },
      preCheckpointTime: {
        status: false,
        text: "",
      },
      postCheckpointTime: {
        status: false,
        text: "",
      },
    });

  const [requestLoading, setRequestLoading] = useState(false);
  const handleFormCancelation = () => {
    navigate("/admin/settings");
  };

  const handleSubmitForm = async () => {
    const { status, data } = fieldTextValidator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      return;
    }
    setRequestLoading(true);
    try {
      const response = await patchRequest(`${TIME_CHECK}/${timeCheckpointId}`, {
        beforeShiftStart: formData.beforeShiftStart,
        afterShiftStart: formData.afterShiftStart,
        beforeShiftEnd: formData.beforeShiftEnd,
        afterShiftEnd: formData.afterShiftEnd,
        preCheckpointTime: formData.preCheckpointTime,
        postCheckpointTime: formData.postCheckpointTime,
      });
      if (response?.success) {
        toastSuccess("Time Check Set Successfully");
        navigate("/admin/settings/holiday-settings");
      } else {
        toastError(response?.data);
      }
    } finally {
      setRequestLoading(false);
    }
  };

  useEffect(() => {
    const getUserDetails = async () => {
      let response: any;
      if (user === ADMIN) {
        response = await getRequest(GET_USER_SETTINGS_URL);
      } else {
        response = await getRequest(GET_USER_ADMIN_SETTINGS_URL);
      }
      if (response?.success) {
        setTimeCheckpointId(response?.data.timeCheck.id);
        setFormData((prevData) => {
          return {
            ...prevData,
            beforeShiftStart: response.data.timeCheck.beforeShiftStart ?? "15",
            afterShiftStart: response.data.timeCheck.afterShiftStart ?? "15",
            beforeShiftEnd: response.data.timeCheck.beforeShiftEnd ?? "15",
            afterShiftEnd: response.data.timeCheck.afterShiftEnd ?? "15",
            preCheckpointTime:
              response.data.timeCheck.preCheckpointTime ?? "15",
            postCheckpointTime:
              response.data.timeCheck.postCheckpointTime ?? "15",
          };
        });
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    };
    getUserDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <AdminSideBar active="settings" />
        <div className={styles.item_container}>
          <SettingsNavigation active="time check" />
          <div className={styles.content_container}>
            <Typography textAlign="left" textWeight="600" textSize="18px">
              Time Check
            </Typography>
            <SettingsTimeCheckForm
              formData={formData}
              setFormData={setFormData}
              requiredFormData={requiredFormData}
              setRequiredFormData={setRequiredFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Back"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={false}
                borderColor="#148A9B"
                borderWidth="2px"
                textColor="#148A9B"
                textWeight="600"
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTimeCheckSettings;
