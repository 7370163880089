import React from "react";
import { useIdleTimer } from "react-idle-timer";
import {
  deleteSelectedAutoLogoutData,
  getLogoutIdle,
  getUserIdle,
  setLogoutIdle,
  setUserIdle,
} from "../../../utils/localstorage";
import { useAppDispatch } from "../../../redux/hooks";
import { updateLogoutTimerCounter } from "../../../redux/slice/common/logoutTimerSlice";
import SessionExpiredModal from "../SessionExpiredModal";

const HandleAutoLogout: React.FC = () => {
  const dispatch = useAppDispatch();

  const onIdle = () => {
    if (getUserIdle() === "true") {
      setUserIdle(String(false));
    }
    deleteSelectedAutoLogoutData();
    setLogoutIdle(String(true));
    dispatch(updateLogoutTimerCounter());
    // start();
  };

  useIdleTimer({
    onIdle,
    timeout: 28800000,
    throttle: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "click",
      "dblclick",
      "keypress",
      "scroll",
    ],
  });
  return <div>{getLogoutIdle() === "true" && <SessionExpiredModal />}</div>;
};
export default HandleAutoLogout;
