import React from "react";
import Typography from "../../../Common/Typography";
import Checkbox from "../../../Form/Checkbox";
import NumberInput from "../../../Form/NumberInput";
import { InvioceProps } from "../../../../types-definition/client";
import TextInput from "components/Form/TextInput";
import { getCurrencyFormat } from "utils/currencyFormatter";

const InvoiceForm: React.FC<InvioceProps> = ({
  invioceFormData,
  setinvioceFormData,
  setInvioceRequiredFormData,
  InvioceRequiredFormData,
  invoiceWithTrue,
  setInvioceWithTrue,
  invioceWithTrueRequiredFormData,
  setInvioceWithTrueRequiredFormData,
}) => {
  return (
    <>
      <Checkbox
        marginVertical="10px"
        label={
          <div>
            <Typography
              textAlign="left"
              textWeight="600"
              marginHorizontal="-10px"
            >
              Fixed Cost
            </Typography>
          </div>
        }
        value={invioceFormData.fixedCost}
        setValue={(data) =>
          setinvioceFormData((prevData) => {
            return { ...prevData, fixedCost: data };
          })
        }
      />

      {!invioceFormData.fixedCost && (
        <TextInput
          value={invioceFormData.chargeRate}
          setValue={(data) => {
            let formattedData = data ? data.replace(/,/g, "") : 0;
            const regex = /^[0-9.]*$/;
            if (!regex.test(formattedData)) {
              return;
            }
            let currencyFormat = getCurrencyFormat(formattedData);
            setinvioceFormData((prevData) => {
              return { ...prevData, chargeRate: currencyFormat };
            });
            setInvioceRequiredFormData((prevData) => {
              return { ...prevData, chargeRate: false };
            });
          }}
          label="Charge Rate"
          placeholder="00"
          required={true}
          error={InvioceRequiredFormData.chargeRate}
          helperText={
            InvioceRequiredFormData.chargeRate ? "Charge Rate is required" : ""
          }
          id="chargeRate"
        />
      )}
      {!invioceFormData.fixedCost && (
        <NumberInput
          value={invioceFormData.minimumChargeHours}
          setValue={(data) => {
            setinvioceFormData((prevData) => {
              return { ...prevData, minimumChargeHours: data };
            });
            setInvioceRequiredFormData((prevData) => {
              return { ...prevData, minimumChargeHours: false };
            });
          }}
          label="Minimum Charge Hours"
          placeholder="00"
          required={true}
          error={InvioceRequiredFormData.minimumChargeHours}
          helperText={
            InvioceRequiredFormData.minimumChargeHours
              ? "Minimum Charge Hours is required"
              : ""
          }
          id="minimumChargeHours"
        />
      )}

      {!invioceFormData.fixedCost && (
        <Checkbox
          marginVertical="10px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Break Time Charged
              </Typography>
            </div>
          }
          value={invioceFormData.breakTimeCharged}
          setValue={(data) =>
            setinvioceFormData((prevData) => {
              return { ...prevData, breakTimeCharged: data };
            })
          }
        />
      )}

      {!invioceFormData.fixedCost && (
        <Checkbox
          marginVertical="10px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Include in Invoice
              </Typography>
            </div>
          }
          value={invioceFormData.includeinInvoice}
          setValue={(data) =>
            setinvioceFormData((prevData) => {
              return { ...prevData, includeinInvoice: data };
            })
          }
        />
      )}

      {invioceFormData.fixedCost && (
        <TextInput
          value={invoiceWithTrue.fixedCostCharge}
          setValue={(data) => {
            let formattedData = data ? data.replace(/,/g, "") : 0;
            const regex = /^[0-9.]*$/;
            if (!regex.test(formattedData)) {
              return;
            }
            let currencyFormat = getCurrencyFormat(formattedData);
            setInvioceWithTrue((prevData) => {
              return {
                ...prevData,
                fixedCostCharge: currencyFormat,
              };
            });
            setInvioceWithTrueRequiredFormData((prevData) => {
              return { ...prevData, fixedCostCharge: false };
            });
          }}
          label="Fixed Cost Charge"
          placeholder="00"
          required={true}
          error={invioceWithTrueRequiredFormData.fixedCostCharge}
          helperText={
            invioceWithTrueRequiredFormData.fixedCostCharge
              ? "Fixed Cost Charge is required"
              : ""
          }
          id="fixedCostCharge"
        />
      )}

      {invioceFormData.fixedCost && (
        <Checkbox
          marginVertical="10px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Include in Invoice
              </Typography>
            </div>
          }
          value={invoiceWithTrue.includeinInvoice}
          setValue={(data) =>
            setInvioceWithTrue((prevData) => {
              return { ...prevData, includeinInvoice: data };
            })
          }
        />
      )}
    </>
  );
};

export default InvoiceForm;
