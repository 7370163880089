import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import AdminSideBar from "components/Admin/SideBar";
import Typography from "components/Common/Typography";
import SettingsNavigation from "components/Admin/Settings/SettingsNavigation";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { GET_USER_ADMIN_SETTINGS_URL, GET_USER_SETTINGS_URL } from "api/urls";
import { getRequest, postRequest } from "api/request";
import { useNavigate } from "react-router-dom";
import ManualCheckpointReasonForm from "components/Admin/Settings/ManualCheckpointReasonForm";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  addNewCheckPointReasonsWithValues,
  clearCheckPointReasons,
  getCheckPointReasons,
  getManualCheckPointReasonRequestLoading,
  getManualCheckPointReasonsCounter,
  setManualCheckPointReasonRequestLoading,
  updateCheckPointReasonsCounter,
} from "../../../redux/slice/admin/manualCheckpointReasons";
import { getSettings, getUserTypes } from "utils/localstorage";
import PageLoader from "components/Common/PageLoader";
import toolTipIcon from "assets/svg/tool-tip-input.svg";
import { toastError, toastSuccess } from "utils/toast";
import { ADMIN } from "types-definition/constants";
import { updateSetupComplete } from "utils/setupWizard";

const ManualCheckpointReason: React.FC = () => {
  const id = getSettings();
  const user = getUserTypes();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const manualCheckpointReasons = useAppSelector(getCheckPointReasons);
  const requestLoading = useAppSelector(
    getManualCheckPointReasonRequestLoading,
  );

  const counter = useAppSelector(getManualCheckPointReasonsCounter);
  const handleFormCancelation = () => {
    navigate("/admin/settings/shift-generation");
  };

  const handleSubmitForm = async () => {
    dispatch(setManualCheckPointReasonRequestLoading(true));
    const formData = manualCheckpointReasons.filter(
      (i: any) => !i.id && i.reasons,
    );

    const dataIfEmpty = manualCheckpointReasons
      .map((item: any) => ({
        reason: item.placeholderReason,
        reasonDescription: item.placeholderDescription,
      }))
      .filter((item: any) => item.reason);
    const data =
      dataIfEmpty.length > 0 && formData.length === 0
        ? dataIfEmpty
        : formData.map((item: any) => ({
            reason: item.reasons,
            reasonDescription: item.description,
          }));

    if (data) {
      try {
        const response = await postRequest(
          `settings/${id}/manual-checkpoint-reasons`,
          data,
        );
        if (response?.success) {
          dispatch(clearCheckPointReasons());
          dispatch(updateCheckPointReasonsCounter());
          toastSuccess("Manual Checkpoint Reason Updated Successfully");
          const setUpComplete = await updateSetupComplete(
            "2",
            false,
            "settings",
          );
          if (!setUpComplete) {
            navigate("/setup-wizard");
          }
        } else {
          toastError(response?.data);
        }
      } finally {
        dispatch(setManualCheckPointReasonRequestLoading(false));
      }
    }
  };

  useEffect(() => {
    const getUserDetails = async () => {
      setIsLoading(true);
      let response: any;
      if (user === ADMIN) {
        response = await getRequest(GET_USER_SETTINGS_URL);
      } else {
        response = await getRequest(GET_USER_ADMIN_SETTINGS_URL);
      }
      if (response?.success) {
        if (response.data.manualCheckPointReasons.length === 0) {
          dispatch(clearCheckPointReasons());
          dispatch(
            addNewCheckPointReasonsWithValues({
              placeholderReason: "Tag Missing",
              placeholderDescription:
                "The tag was missing from the expected location.",
            }),
          );
          dispatch(
            addNewCheckPointReasonsWithValues({
              placeholderReason: "Tag damaged",
              placeholderDescription:
                "The tag was damaged and the expected location was unable to scan.",
            }),
          );
          dispatch(
            addNewCheckPointReasonsWithValues({
              placeholderReason: "Tag inaccessible",
              placeholderDescription:
                "The tag was not accessible - e.g. locked door.",
            }),
          );
          dispatch(
            addNewCheckPointReasonsWithValues({
              placeholderReason: "Out of time",
              placeholderDescription:
                "Employee states they did not have time to scan this checkpoint.",
            }),
          );
        } else {
          dispatch(clearCheckPointReasons());
          response.data.manualCheckPointReasons.map((item: any) => {
            return dispatch(
              addNewCheckPointReasonsWithValues({
                id: item.id,
                reason: item.reason,
                description: item.reasonDescription ?? "",
              }),
            );
          });
        }
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
      setIsLoading(false);
    };
    getUserDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (
    <>
      {isLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <AdminSideBar active="settings" />
        <div className={styles.item_container}>
          <SettingsNavigation active="manual checkpoint reasons" />
          <div className={styles.content_container}>
            <div className={styles.header_flex_item}>
              <Typography textAlign="left" textWeight="600" textSize="18px">
                Manual Checkpoint Reasons
              </Typography>
              <img
                src={toolTipIcon}
                alt="tool-tip-icon"
                className={styles.tooltip_icon}
              />
              <div className={styles.tooltip_text_container}>
                <div className={styles.tooltip_text}>
                  <Typography textAlign="left" textSize="12px" textWeight="600">
                    When you encounter a checkpoint that needs manual
                    completion, the reasons will be conveniently listed as
                    selectable options in the manual completion form on your
                    device. The corresponding descriptions for each reason will
                    be shown in the shift comments section, providing clarity
                    and ease of use
                  </Typography>
                </div>
              </div>
            </div>
            <ManualCheckpointReasonForm />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Back"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={false}
                borderColor={colors.primary3}
                borderWidth="2px"
                textColor={colors.primary3}
                textWeight="600"
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManualCheckpointReason;
