import React, { useState } from "react";
import styles from "./index.module.css";
import CircularName from "../../../Common/CircularName";
import Typography from "../../../Common/Typography";
import tableSelectorIcon from "../../../../assets/svg/table-selector.svg";
import { ClientData } from "../../../../types-definition/client";
import ClientDropdown from "../ClientDropdown";

const ClientTableItem: React.FC<ClientData> = ({
  id,
  name,
  code,
  description,
  locations,
}) => {
  const [showEditDropdown, setshowEditDropdown] = useState(false);
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <div className={`${styles.td_flex}`}>
          <CircularName name={`${name} ${name}`} />
          <Typography textAlign="left" textWeight="600" textColor="#148A9B">
            {name}
          </Typography>
        </div>
      </td>
      <td className={styles.td_mail}>
        <Typography textAlign="left">{code ? code : "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {description ? description : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {locations ? locations : "N/A"}
        </Typography>
      </td>
      <td
        className={`${styles.td} ${styles.td_relative}`}
        onBlur={() => setshowEditDropdown(false)}
        tabIndex={0}
      >
        {showEditDropdown && (
          <ClientDropdown
            setShowDropDown={setshowEditDropdown}
            clientId={id ? id : ""}
            clientName={name}
          />
        )}
        <div
          className={styles.image_container}
          onClick={() => setshowEditDropdown((prevData) => !prevData)}
        >
          <img src={tableSelectorIcon} alt="selector-icon" />
        </div>
      </td>
    </tr>
  );
};

export default ClientTableItem;
