import React, { useEffect, useState } from "react";
import { LocationSelectInputProps } from "../../../types-definition/form";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import dropDownIcon from "../../../assets/svg/select-dropdown.svg";
import colors from "../../../theme/colors";

const LocationSelectInput: React.FC<LocationSelectInputProps> = ({
  options = [],
  label,
  placeholder,
  value,
  setValue,
  setCode,
  code,
  height = "200px",
  width,
  disabled = false,
  required = false,
  error = false,
  top,
  style,
  helperText,
  id,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [list, setList] = useState<any>([]);
  const handleListSelector = (item: any) => {
    setValue(item.name);
    if (setCode) {
      setCode(item.isoCode);
    }
    setIsSelected(true);
    setShowDropDown(false);
  };
  const filterListFromString = (input: string) => {
    if (input !== "" && input !== undefined) {
      const newList = options.filter((item: any) =>
        item.name.toLowerCase().includes(input.toLowerCase()),
      );
      setValue(input);
      setList(newList);
      setIsSelected(false);
      return;
    } else {
      setIsSelected(false);
      setValue("");
      setList(options);
      return;
    }
  };
  useEffect(() => {
    if (!isSelected && !showDropDown && isActive) {
      setValue("");
      setList(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, showDropDown]);
  useEffect(() => {
    setList(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);
  return (
    <section className={styles.container} style={style} id={id}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        className={styles.select}
        onChange={(e) => {
          if (!showDropDown) {
            setShowDropDown(true);
          }
          filterListFromString(e.target.value);
        }}
        onFocus={() => {
          setIsActive(true);
          setShowDropDown(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 500);
        }}
        style={{
          width,
          borderColor: error ? colors.error : colors.gray3,
        }}
        disabled={disabled}
      />
      <div className={styles.helpertext_container}>
        <Typography
          textAlign="left"
          textSize={"10px"}
          textWeight="600"
          textColor={error ? colors.error : colors.primary3}
        >
          {helperText}
        </Typography>
      </div>
      <div
        className={styles.dropdown_container}
        tabIndex={0}
        onClick={() => {
          if (!disabled) {
            setIsActive(true);
            setShowDropDown(true);
          }
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 400);
        }}
      >
        <img
          src={dropDownIcon}
          className={styles.dropdown}
          alt="dropdown icon"
        />
      </div>
      {showDropDown && (
        <div className={styles.datalist} style={{ maxHeight: height, top }}>
          {list.length === 0 ? (
            <p>No data</p>
          ) : (
            list.map((item: any) => (
              <p onClick={() => handleListSelector(item)} key={item.name}>
                {item.name}
              </p>
            ))
          )}
        </div>
      )}
    </section>
  );
};

export default LocationSelectInput;
