import { getRequest } from "api/request";
import { getData, getReloadCount, setReloadCount } from "./localstorage";

const user = JSON.parse(getData()!);

const checkAdminPermission = (permission?: string | null) => {
  if (permission === "Admin") {
    return true;
  } else {
    return false;
  }
};

export const checkUserPermission = async (permission?: Array<string>) => {
  if (
    user?.id === undefined &&
    (getReloadCount() === "0" || getReloadCount() === null)
  ) {
    window.location.reload();
    setReloadCount();
  }
  const response = await getRequest(`/settings/user/${user?.id}/permission`);
  const permissions: Array<string> = response?.data?.map(
    (item: any) => item.name,
  );
  return permission && permissions?.some((p) => permission?.includes(p));
};

export default checkAdminPermission;
