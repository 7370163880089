import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { EmployeeAccrualModal } from "../../../types-definition/StaffHub/employee";
const initialState: EmployeeAccrualModal = {
  showModal: false,
  showDeleteAccrualModal: false,
  showDeleteManualadjustmentModal: false,
};
const employeeAccrualModalSlice = createSlice({
  name: "accrual",
  initialState,
  reducers: {
    toggleAccrualModal: (state, action) => {
      state.showModal = action.payload.showModal;
    },
    toggleAccrualDeleteModal: (state, action) => {
      state.showDeleteAccrualModal = action.payload.showDeleteAccrualModal;
    },
    togglemanualAdjustmentDeleteModal: (state, action) => {
      state.showDeleteManualadjustmentModal =
        action.payload.showDeleteManualadjustmentModal;
    },
  },
});
export const {
  toggleAccrualModal,
  toggleAccrualDeleteModal,
  togglemanualAdjustmentDeleteModal,
} = employeeAccrualModalSlice.actions;
export const showAccrualModalState = (state: RootState) =>
  state.accrual.showModal;
export const setShowDeleteAccrualState = (state: RootState) =>
  state.accrual.showDeleteAccrualModal;
export const setShowDeleteManualAdjustmentState = (state: RootState) =>
  state.accrual.showDeleteManualadjustmentModal;
export default employeeAccrualModalSlice.reducer;
