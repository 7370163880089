import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";

import { PermissionGroupDataTableProps } from "../../../../types-definition/admin";
import colors from "../../../../theme/colors";
import PermisionGroupTableItem from "../PermissionTableItem";

const PermissionGroupTable: React.FC<PermissionGroupDataTableProps> = ({
  data = [],
}) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th_name}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Name
              </Typography>
            </th>
            <th className={styles.th_desc}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Description
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Status
              </Typography>
            </th>
            <th className={styles.th_index}></th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return <PermisionGroupTableItem {...item} key={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PermissionGroupTable;
