import React, { useLayoutEffect, useState } from "react";
import Typography from "components/Common/Typography";
import size from "theme/size";
import styles from "./index.module.css";
import Button from "components/Form/Button";
import SignInButton from "components/Form/SignInButton";
import Divider from "components/Common/Divider";
import colors from "theme/colors";
import SignUpHeader from "components/Common/SignUpHeader";
import googleIcon from "assets/svg/Google-Icon.svg";
import microsoftIcon from "assets/svg/Microsoft-svg.svg";
import VerifyMailModal from "components/Auth/VerifyMailModal";
import { postRequest } from "api/request";
import {
  BASE_URL,
  GOOGLE_AUTH_URL,
  MICROSOFT_AUTH_URL,
  REGISTER_USER_URL,
} from "api/urls";
import {
  CreateAccountFormData,
  CreateAccountRequiredFormData,
} from "types-definition/common";
import CreateAccountForm from "components/Auth/CreateAcoountForm";
import ScrollToTop from "components/Common/ScrollToTop";
import { toastError } from "utils/toast";
import fieldTextValidator from "utils/validateFieldsWithText";
import scrolltoView from "utils/scrollToView";
import RequirePasscode from "components/Auth/RequirePasscode";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setShowRequirePasscode,
  showRequirePasscode,
} from "redux/slice/auth/authSlice";
import { getShowPasscode } from "utils/localstorage";

const CreateAccount: React.FC = () => {
  const [formData, setFormData] = useState<CreateAccountFormData>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repeat_password: "",
    country: "",
    countryCode: "",
  });
  const [requiredFormData, setRequiredFormData] =
    useState<CreateAccountRequiredFormData>({
      firstName: {
        status: false,
        text: "",
      },
      lastName: {
        status: false,
        text: "",
      },
      email: {
        status: false,
        text: "",
      },
      password: {
        status: false,
        text: "",
      },
      country: {
        status: false,
        text: "",
      },
    });
  const [showSuccess, setShowSuccess] = useState(false);
  const [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const handleFormSubmission = async () => {
    try {
      setRequestLoading(true);
      const { data, status, id } = fieldTextValidator(
        formData,
        requiredFormData,
      );
      if (!status) {
        setRequiredFormData((prevData) => ({ ...prevData, ...data }));
        toastError("Fill all required fields");
        if (id) {
          scrolltoView(id);
        }
        return;
      }
      const response = await postRequest(REGISTER_USER_URL, formData);
      if (response?.success) {
        setShowSuccess(true);
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    } finally {
      setRequestLoading(false);
    }
  };
  const openGoogleUrl = () => {
    window.open(`${BASE_URL}${GOOGLE_AUTH_URL}`, "_self");
  };
  const openMicrosoftUrl = () => {
    window.open(`${BASE_URL}${MICROSOFT_AUTH_URL}`, "_self");
  };

  // Require Access Code=
  const dispatch = useAppDispatch();
  const localShowPasscode: boolean = JSON.parse(getShowPasscode() || "true");
  const showRequirePasscodeComponent = useAppSelector(showRequirePasscode);

  useLayoutEffect(() => {
    dispatch(setShowRequirePasscode(localShowPasscode));
  }, []);

  return (
    <>
      <ScrollToTop />
      {showRequirePasscodeComponent ? (
        <RequirePasscode />
      ) : (
        <>
          <main>
            <SignUpHeader />
            <section className={styles.container}>
              <div className={styles.body_container}>
                <Typography
                  fontFamily="inter"
                  textAlign="center"
                  textSize={size.lg}
                  textWeight="600"
                >
                  Create An Account
                </Typography>
                <Typography
                  textAlign="center"
                  fontFamily="inter"
                  textSize={size.md}
                >
                  Enter your credentials to create your account
                </Typography>
                <div className={styles.form_container}>
                  <CreateAccountForm
                    formData={formData}
                    setFormData={setFormData}
                    requiredFormData={requiredFormData}
                    setRequiredFormData={setRequiredFormData}
                    isTermsAgreed={isTermsAgreed}
                    setIsTermsAgreed={setIsTermsAgreed}
                  />
                  <Button
                    text="Get Started"
                    isPrimary={true}
                    isLoading={requestLoading}
                    onClick={() => {
                      handleFormSubmission();
                    }}
                    disabled={!isTermsAgreed}
                  />
                </div>
                <section className={styles.bottom_container}>
                  <div className={styles.divider_container}>
                    <Divider width="45%" />
                    <Typography textColor={colors.gray1}>Or</Typography>
                    <Divider width="45%" />
                  </div>
                  <div>
                    <SignInButton
                      text="Sign up with Google"
                      onClick={openGoogleUrl}
                      image={googleIcon}
                    />
                    <SignInButton
                      text="Sign up with Microsoft"
                      onClick={openMicrosoftUrl}
                      image={microsoftIcon}
                    />
                  </div>
                </section>
              </div>
            </section>
          </main>
          {showSuccess && <VerifyMailModal />}
        </>
      )}
    </>
  );
};

export default CreateAccount;
