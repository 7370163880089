import { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import colors from "theme/colors";
import { getRequest, patchRequest, postRequest } from "api/request";
import { CREATE_CLIENT_URL, GET_ALL_CLIENTS_URL } from "api/urls";
import { useNavigate } from "react-router-dom";

import {
  ClientRequiredFormData,
  ClientFormData,
} from "types-definition/client";
import AddClientForm from "components/Client/Client/AddClientForm";
import { getClient, getUserTypes } from "utils/localstorage";
import toast from "react-hot-toast";
import validator from "utils/validateFields";
import ScrollToTop from "components/Common/ScrollToTop";
import { toastError, toastSuccess } from "utils/toast";
import PageBackButton from "components/Common/PageBackButton";
import scrolltoView from "utils/scrollToView";
import { updateSetupComplete } from "utils/setupWizard";
const AddClient = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ClientFormData>({
    clientName: "",
    description: "",
    active: true,
    signInLogo: "",
    smartFormLogo: "",
    smartFormHeader: "",
  });
  const [requiredFormData, setRequiredFormData] =
    useState<ClientRequiredFormData>({
      clientName: false,
    });
  const [requestLoading, setRequestLoading] = useState(false);
  const handleSubmitForm = async () => {
    const { status, data, id } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      if (id) {
        scrolltoView(id);
      }
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    setRequestLoading(true);
    const clientId = getClient();
    try {
      const data = new FormData();
      data.append("clientName", formData.clientName);
      data.append("description", formData.description);
      data.append("active", formData.active.toString());
      if (formData.signInLogo instanceof File) {
        data.append("signInLogo", formData.signInLogo);
      }
      if (formData.smartFormHeader instanceof File) {
        data.append("smartFormHeader", formData.smartFormHeader);
      }
      if (formData.smartFormLogo instanceof File) {
        data.append("smartFormLogo", formData.smartFormLogo);
      }
      let response;
      if (!clientId) {
        response = await postRequest(CREATE_CLIENT_URL, data);
      } else {
        response = await patchRequest(`${CREATE_CLIENT_URL}/${clientId}`, data);
      }
      if (response?.success) {
        if (clientId) {
          toastSuccess("Client Updated");
        } else {
          toastSuccess("Client Created");
        }
        const setUpComplete = await updateSetupComplete("9", false, "client");

        const userType = getUserTypes();
        if (!setUpComplete && userType !== "Web User") {
          navigate("/setup-wizard");
        } else {
          navigate(`/client/dashboard`);
        }
      } else {
        toastError(response?.data);
      }
    } finally {
      setRequestLoading(false);
    }
  };
  const handleFormCancelation = () => {
    navigate("/client/dashboard");
  };
  useEffect(() => {
    const getAllClient = async () => {
      const clientId = getClient();
      try {
        if (clientId) {
          const response = await getRequest(
            `${GET_ALL_CLIENTS_URL}/${clientId}`,
          );
          if (response?.success) {
            setFormData({
              active: response.data?.active === false ? false : true,
              clientName: response.data?.clientName ?? "",
              description: response.data?.description ?? "",
              signInLogo: response.data.signinLogo.signInLogo
                ? {
                    name: response.data.signinLogo.originalName,
                    URL: response.data.signinLogo.signInLogo,
                    size: response.data.signinLogo.size,
                    lastModifiedDate: response.data.signinLogo.createdAt,
                  }
                : "",
              smartFormHeader: response.data?.smartFormHeader.smartFormHeader
                ? {
                    name: response.data.smartFormHeader.originalName,
                    URL: response.data.smartFormHeader.smartFormHeader,
                    size: response.data.smartFormHeader.size,
                    lastModifiedDate: response.data.smartFormHeader.createdAt,
                  }
                : "",
              smartFormLogo: response.data?.smartFormLogo.smartFormLogo
                ? {
                    name: response.data.smartFormLogo.originalName,
                    URL: response.data.smartFormLogo.smartFormLogo,
                    size: response.data.smartFormLogo.size,
                    lastModifiedDate: response.data.smartFormLogo.createdAt,
                  }
                : "",
            });
          } else {
            if (response?.data)
              toast.error(response?.data, {
                duration: 4000,
                position: "top-center",
              });
          }
        }
      } finally {
        setRequestLoading(false);
      }
    };
    getAllClient();
  }, []);
  return (
    <section>
      <ScrollToTop />
      <DashboardHeader active="clients" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton width="90%" paddingTop="20px" marginBottom="0" />
          <div className={styles.content_container}>
            <Typography textAlign="left" textWeight="700" textSize="30px">
              Add Client
            </Typography>
            <Typography
              textAlign="left"
              textWeight="600"
              textSize="24px"
              marginVertical="20px"
            >
              General Information
            </Typography>
            <AddClientForm
              formData={formData}
              setFormData={setFormData}
              requiredFormData={requiredFormData}
              setRequiredFormFData={setRequiredFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Cancel"
                isPrimary={false}
                onClick={handleFormCancelation}
                marginHorizontal="10px"
                backgroundColor={colors.transparent}
                isPrimaryBorder={true}
              />
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddClient;
