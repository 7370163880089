const stringToDate = (date: string) => {
  const parts = date?.split("/");

  // Note: JavaScript uses 0-based indexing for months, so we subtract 1 from the month
  if (parts) {
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    // Create a new Date object using the extracted parts
    const convertedDate = new Date(year, month, day);

    return convertedDate;
  } else {
    return date;
  }
};

export default stringToDate;
