import React from "react";
import { LogoutWarningModalProps } from "../../../types-definition/common";
import Typography from "../Typography";
import primaryInfoIcon from "../../../assets/svg/primary-info.svg";
import styles from "./index.module.css";
import Button from "../../Form/Button";
import colors from "../../../theme/colors";
import PasswordInput from "../../Form/PasswordInput";
import {
  getData,
  getSocialProvider,
  logoutUser,
  setUserIdle,
} from "../../../utils/localstorage";
import { EMAIL, GOOGLE } from "types-definition/constants";
import SignInButton from "components/Form/SignInButton";
import { BASE_URL, GOOGLE_AUTH_URL, MICROSOFT_AUTH_URL } from "api/urls";
import googleIcon from "assets/svg/Google-Icon.svg";
import microsoftIcon from "assets/svg/Microsoft-svg.svg";
import { useAppDispatch } from "redux/hooks";
import { updateLogoutTimerCounter } from "redux/slice/common/logoutTimerSlice";
import { useNavigate } from "react-router-dom";

const LogoutWarningModal: React.FC<LogoutWarningModalProps> = ({
  password,
  setPassword,
  loginFunction,
  requestLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = JSON.parse(getData()!);
  const PROVIDER = getSocialProvider();
  const openGoogleUrl = () => {
    window.open(`${BASE_URL}${GOOGLE_AUTH_URL}`, "_self");
  };
  const openMicrosoftUrl = () => {
    window.open(`${BASE_URL}${MICROSOFT_AUTH_URL}`, "_self");
  };
  const logoutUserfunction = () => {
    logoutUser();
    setUserIdle(String(false));
    dispatch(updateLogoutTimerCounter());
    navigate("/login")
  };
  return (
    <div className={styles.main_container}>
      <div className={styles.container}>
        <img
          src={primaryInfoIcon}
          alt="cancel icon"
          className={styles.cancelImage}
        />
        <Typography textWeight="600" textSize="18px" textAlign="left">
          Hi {name?.name},
        </Typography>
        <div className={styles.mid_text}>
          <Typography textColor={colors.gray4} textAlign="left">
            For your security, please verify your login to continue, as you've
            been inactive for a while.
          </Typography>
        </div>
        {PROVIDER === EMAIL ? (
          <>
            <PasswordInput
              value={password}
              setValue={(data) => {
                setPassword(data);
              }}
              placeholder="Enter Password"
              label="Password"
            />
            <div className={styles.button_container}>
              <Button
                width="100%"
                text="Stay Logged In"
                textColor={colors.white}
                isPrimary={true}
                textWeight="500"
                onClick={loginFunction}
                isLoading={requestLoading}
              />
            </div>
          </>
        ) : PROVIDER === GOOGLE ? (
          <div className={styles.button_container}>
            <SignInButton
              text="Stay Logged In with Google"
              onClick={openGoogleUrl}
              image={googleIcon}
            />
          </div>
        ) : (
          <div className={styles.button_container}>
            <SignInButton
              text="Stay Logged In with Microsoft"
              onClick={openMicrosoftUrl}
              image={microsoftIcon}
            />
          </div>
        )}
        <div className={styles.logout_modal}>
          <Button
            isPrimary={false}
            backgroundColor={colors.white}
            borderColor={colors.primary3}
            paddingVertical="5px"
            borderWidth="1px"
            onClick={logoutUserfunction}
            text="Logout"
            textColor={colors.primary3}
          />
        </div>
      </div>
    </div>
  );
};

export default LogoutWarningModal;
