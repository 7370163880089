import styles from "./index.module.css";
import Typography from "../Typography";
import colors from "../../../theme/colors";
import Divider from "../Divider";
import elementDescriptionIcon from "assets/svg/elements-empty.svg";
import expandIcon from "../../../assets/svg/arrow-expand.svg";
import DashboardMonitorCard from "../DashboardMonitorCard";
import { DashboardMonitorCardProps } from "../../../types-definition/common";

const DashboardLongItem: React.FC<{
  data?: Array<DashboardMonitorCardProps>;
}> = ({ data }) => {
  return (
    <section className={styles.container}>
      <div className={styles.flex_item}>
        <Typography
          marginHorizontal="0px"
          textSize="16px"
          textWeight="600"
          textAlign="left"
          fontFamily="inter"
          textColor={colors.black}
          marginVertical="20px"
        >
          Live Location Monitoring
        </Typography>
        <img
          alt=""
          src={expandIcon}
          onClick={() => {
            window.open("/live-dasboard-monitoring", "_blank");
          }}
          className={styles.expand_icon}
        />
      </div>
      <Divider width="100%" color="#D0D5DD" />
      {data && data?.length > 0 ? (
        <div className={styles.grid_item}>
          {data.map((item, index) => (
            <DashboardMonitorCard {...item} key={index} />
          ))}
        </div>
      ) : (
        <div className={styles.content}>
          <img src={elementDescriptionIcon} alt="icon" width={40} height={40} />
          <div className={styles.midcontent_container}>
            <Typography textColor={colors.gray1}>
              No Attendance Record Found
            </Typography>
          </div>
        </div>
      )}
    </section>
  );
};

export default DashboardLongItem;
