import ScrollToTop from "components/Common/ScrollToTop";
import SignUpHeader from "components/Common/SignUpHeader";
import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import Button from "components/Form/Button";
import paymentSuccess from "assets/svg/payment-success.svg";
import {
  getData,
  getPaystackId,
  getSetupComplete,
  setCompanyName,
  setSetupComplete,
} from "utils/localstorage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getRequest, postRequest } from "api/request";
import { GET_USER_DETAILS_URL } from "api/urls";

const SubscribeSuccessful = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const setUpStep = getSetupComplete();
  const navigate = useNavigate();

  useEffect(() => {
    const txRef = searchParams.get("trxref");
    console.log(txRef);
    if (txRef) {
      postRequest(`/billing/paystack/subscription/verify/${txRef}`, {
        productId: getPaystackId(),
      }).then((response) => {
        console.log(response);
      });
    }
  }, []);

  const handleContinue = async () => {
    setIsLoading(true);
    try {
      const response = await getRequest(GET_USER_DETAILS_URL);
      if (response?.success) {
        if (response?.data?.user?.country === null) {
          return navigate("/update-account");
        }
        if (
          response?.data?.user?.company?.companyName === null ||
          response?.data?.user?.company?.companyName === undefined
        ) {
          return navigate("/create-company");
        }
        setCompanyName(response?.data?.user?.company?.companyName);
      }
      if (setUpStep) {
        if (JSON.parse(setUpStep).step === "1") {
          setSetupComplete(
            JSON.stringify({
              completed: JSON.parse(setUpStep).completed,
              step: "1",
            }),
          );
          navigate("/setup-wizard");
        } else if (parseFloat(JSON.parse(setUpStep).step) > 13) {
          navigate("/dashboard");
        } else {
          setSetupComplete(
            JSON.stringify({
              completed: JSON.parse(setUpStep).completed,
              step: "1",
            }),
          );
          navigate("/setup-wizard");
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const user = getData();
    if (!user) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <ScrollToTop />
      <SignUpHeader showLogin={false} />
      <div className={styles.container}>
        <div className={styles.subscription_container}>
          <div className={styles.subscription_text_container}>
            {/* <div className={styles.flex_1}> */}
            <Typography textSize="16px">Plan</Typography>
            {/* </div> */}
            {/* <div className={styles.flex_1}> */}
            <Typography textSize="16px" style={{ marginLeft: "40px" }}>
              Pay
            </Typography>
            {/* </div> */}
            {/* <div className={styles.flex_1}> */}
            <Typography textSize="16px">Confirmation</Typography>
            {/* </div> */}
          </div>
          <div className={styles.subscription_bar_container}>
            <div className={styles.subscription_bar}></div>
            <div className={styles.subscription_item_container}>
              <div className={styles.subscription_rounded_item_active}></div>
              <div className={styles.subscription_rounded_item_active}></div>
              <div className={styles.subscription_rounded_item_active}></div>
            </div>
            <div className={styles.subscription_bar_active_2}></div>
          </div>
        </div>

        <div className={styles.success_container}>
          <div className={styles.payment_icon}>
            <img src={paymentSuccess} alt="payment icon" />
          </div>
          <Typography textSize="36px" textWeight="600">
            Payment Successful
          </Typography>
          <Button
            paddingVertical="5px"
            text="Continue"
            isPrimary
            isLoading={isLoading}
            onClick={handleContinue}
            //   width="200px"
            marginTop="60px"
          />
        </div>
      </div>
    </>
  );
};

export default SubscribeSuccessful;
