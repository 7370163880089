import React from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import MessageEmployeeTableItem from "../MessageEmployeeTableItem";
import { MessageEmployeeTableProps } from "../../../../../types-definition/StaffHub/employee";
import { useAppDispatch } from "../../../../../redux/hooks";
import { setSelectedEmployees } from "../../../../../redux/slice/staff-hub/employeeMessageSlice";

const MessageEmployeeTable: React.FC<MessageEmployeeTableProps> = ({
  data = [],
}) => {
  const dispatch = useAppDispatch();
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th_head}>
              <input
                type="checkbox"
                className={styles.checkbox}
                onClick={() => {
                  data.forEach((item) => {
                    dispatch(setSelectedEmployees(item.id));
                  });
                }}
              />
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Employee Name
              </Typography>
            </th>
            <th className={styles.th_id}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                ID
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Grade
              </Typography>
            </th>
            <th className={styles.th_last_seen}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Last Seen
              </Typography>
            </th>
            <th className={styles.th_index}></th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return <MessageEmployeeTableItem {...item} key={item.id} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MessageEmployeeTable;
