import React from "react";
import NumberInputWithoutIcon from "../../../Form/NumberInputWithoutIcon";
import colors from "../../../../theme/colors";
import Checkbox from "../../../Form/Checkbox";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { AdminSettingHolidaySettingsFormProps } from "../../../../types-definition/admin";
import SelectInput from "../../../Form/SelectInput";

const HolidaySettingsForm: React.FC<AdminSettingHolidaySettingsFormProps> = ({
  formData,
  setFormData,
  setRequiredFormData,
  requiredFormData,
}) => {
  const monthsOfTheYear: Array<string> = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const checkIfItemExistInArray = (arr: Array<string>, item: string) => {
    const itemExist = arr.filter((arrayItem) => {
      if (arrayItem === item) {
        return true;
      } else {
        return false;
      }
    });
    const newArr =
      itemExist.length > 0
        ? arr.filter((arrayItem) => arrayItem !== item)
        : [...arr, item];
    return newArr;
  };

  return (
    <>
      <SelectInput
        label="Holiday Year Start"
        placeholder="Input
        Select Holiday Year Start"
        setValue={(data) => {
          setFormData((prevData) => {
            return { ...prevData, holidayYearStart: data };
          });
          setRequiredFormData((prevData) => {
            return { ...prevData, holidayYearStart: false };
          });
        }}
        value={formData.holidayYearStart}
        error={requiredFormData.holidayYearStart}
        helperText={
          requiredFormData.holidayYearStart
            ? "Holiday Year Start is required"
            : ""
        }
        required={true}
        options={monthsOfTheYear}
        dropdownTop="47px"
        datalistTop="83px"
        id="holidayYearStart"
        // id={Object.keys(formData).find(formData.holidayYearStart)}
      />
      <NumberInputWithoutIcon
        label="Holiday Entitlement"
        placeholder="0"
        setValue={(data) => {
          setFormData((prevData) => {
            return { ...prevData, holidayEntitlement: data };
          });
          setRequiredFormData((prevData) => {
            return { ...prevData, holidayEntitlement: false };
          });
        }}
        value={formData.holidayEntitlement}
        error={requiredFormData.holidayEntitlement}
        helperText={
          requiredFormData.holidayEntitlement
            ? "Holiday Entitlement is required"
            : ""
        }
        required={true}
        id="holidayEntitlement"
      />
      <NumberInputWithoutIcon
        label="Historic Weeks For Pay Calculations"
        placeholder="0"
        setValue={(data) => {
          setFormData((prevData) => {
            return { ...prevData, historicWeeksForPayCalculations: data };
          });
          setRequiredFormData((prevData) => {
            return { ...prevData, historicWeeksForPayCalculations: false };
          });
        }}
        value={formData.historicWeeksForPayCalculations}
        error={requiredFormData.historicWeeksForPayCalculations}
        helperText={
          requiredFormData.historicWeeksForPayCalculations
            ? "Historic Weeks For Pay Calculations is required"
            : ""
        }
        required={true}
        id="historicWeeksForPayCalculations"
      />
      <NumberInputWithoutIcon
        label="Minimum Day(s) Notice For Holiday Request"
        placeholder="0"
        setValue={(data) => {
          setFormData((prevData) => {
            return { ...prevData, minimumNoticeForHolidayRequest: data };
          });
          setRequiredFormData((prevData) => {
            return { ...prevData, minimumNoticeForHolidayRequest: false };
          });
        }}
        value={formData.minimumNoticeForHolidayRequest}
        error={requiredFormData.minimumNoticeForHolidayRequest}
        helperText={
          requiredFormData.minimumNoticeForHolidayRequest
            ? "Minimum Day(s) Notice For Holiday Request is required"
            : ""
        }
        required={true}
        id="minimumNoticeForHolidayRequest"
      />
      <NumberInputWithoutIcon
        label="Maximum Holiday Duration"
        placeholder="0"
        setValue={(data) => {
          setFormData((prevData) => {
            return { ...prevData, maximumHolidayDuration: data };
          });
          setRequiredFormData((prevData) => {
            return { ...prevData, maximumHolidayDuration: false };
          });
        }}
        value={formData.maximumHolidayDuration}
        error={requiredFormData.maximumHolidayDuration}
        helperText={
          requiredFormData.maximumHolidayDuration
            ? "Maximum Holiday Duration is required"
            : ""
        }
        required={true}
        id="maximumHolidayDuration"
      />
      <NumberInputWithoutIcon
        label="Default Holiday Accrual Percent"
        placeholder="0"
        setValue={(data) => {
          setFormData((prevData) => {
            return { ...prevData, defaultHolidayAccrualPercent: data };
          });
          setRequiredFormData((prevData) => {
            return { ...prevData, defaultHolidayAccrualPercent: false };
          });
        }}
        value={formData.defaultHolidayAccrualPercent}
        error={requiredFormData.defaultHolidayAccrualPercent}
        helperText={
          requiredFormData.defaultHolidayAccrualPercent
            ? "Default Holiday Accrual Percent is required"
            : ""
        }
        required={true}
        id="defaultHolidayAccrualPercent"
      />

      <Checkbox
        marginVertical="20px"
        value={formData.exceedRemainingHolidayAllowance}
        setValue={(data) => {
          setFormData((prevData) => {
            return { ...prevData, exceedRemainingHolidayAllowance: data };
          });
        }}
        label={
          <Typography
            textAlign="left"
            textWeight="500"
            marginHorizontal="-10px"
            textColor={colors.gray5}
          >
            Exceed Remaining Holiday Allowance
          </Typography>
        }
      />
      <Checkbox
        marginVertical="20px"
        value={formData.hideHolidayEntitlementForAppPortal}
        setValue={(data) => {
          setFormData((prevData) => {
            return { ...prevData, hideHolidayEntitlementForAppPortal: data };
          });
        }}
        label={
          <Typography
            textAlign="left"
            textWeight="500"
            marginHorizontal="-10px"
            textColor={colors.gray5}
          >
            Hide Holiday Entitlement For App/Portal
          </Typography>
        }
      />
      <Checkbox
        marginVertical="20px"
        value={formData.bankHolidayShiftAutoCancel}
        setValue={(data) => {
          setFormData((prevData) => {
            return { ...prevData, bankHolidayShiftAutoCancel: data };
          });
        }}
        label={
          <Typography
            textAlign="left"
            textWeight="500"
            marginHorizontal="-10px"
            textColor={colors.gray5}
          >
            Bank Holiday Shift Auto Cancel
          </Typography>
        }
      />

      <div className={styles.flex_container}>
        <div className={styles.flex_item}>
          <Typography
            textAlign="left"
            textWeight="600"
            textColor={colors.gray5}
          >
            Accrued when Absent
          </Typography>

          <div>
            <Checkbox
              marginVertical="0"
              value={formData.leave}
              setValue={(data) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    leave: data,
                    accruedWhenAbsent: checkIfItemExistInArray(
                      formData.accruedWhenAbsent,
                      "Leave",
                    ),
                  };
                });
              }}
              label={
                <Typography
                  textAlign="left"
                  textWeight="500"
                  marginHorizontal="-10px"
                  textColor={colors.gray5}
                >
                  Leave
                </Typography>
              }
            />
            <Checkbox
              marginVertical="3px"
              value={formData.suspended}
              setValue={(data) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    suspended: data,
                    accruedWhenAbsent: checkIfItemExistInArray(
                      formData.accruedWhenAbsent,
                      "Suspended",
                    ),
                  };
                });
              }}
              label={
                <Typography
                  textAlign="left"
                  textWeight="500"
                  marginHorizontal="-10px"
                  textColor={colors.gray5}
                >
                  Suspended
                </Typography>
              }
            />
            <Checkbox
              marginVertical="3px"
              value={formData.maternity}
              setValue={(data) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    maternity: data,
                    accruedWhenAbsent: checkIfItemExistInArray(
                      formData.accruedWhenAbsent,
                      "Maternity",
                    ),
                  };
                });
              }}
              label={
                <Typography
                  textAlign="left"
                  textWeight="500"
                  marginHorizontal="-10px"
                  textColor={colors.gray5}
                >
                  Maternity
                </Typography>
              }
            />
            <Checkbox
              marginVertical="3px"
              value={formData.sickness}
              setValue={(data) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    sickness: data,
                    accruedWhenAbsent: checkIfItemExistInArray(
                      formData.accruedWhenAbsent,
                      "Sickness",
                    ),
                  };
                });
              }}
              label={
                <Typography
                  textAlign="left"
                  textWeight="500"
                  marginHorizontal="-10px"
                  textColor={colors.gray5}
                >
                  Sickness{" "}
                </Typography>
              }
            />
            <Checkbox
              marginVertical="3px"
              value={formData.AWOL}
              setValue={(data) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    AWOL: data,
                    accruedWhenAbsent: checkIfItemExistInArray(
                      formData.accruedWhenAbsent,
                      "AWOL",
                    ),
                  };
                });
              }}
              label={
                <Typography
                  textAlign="left"
                  textWeight="500"
                  marginHorizontal="-10px"
                  textColor={colors.gray5}
                >
                  AWOL
                </Typography>
              }
            />
            <Checkbox
              marginVertical="3px"
              value={formData.unpaidLeave}
              setValue={(data) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    unpaidLeave: data,
                    accruedWhenAbsent: checkIfItemExistInArray(
                      formData.accruedWhenAbsent,
                      "UnpaidLeave",
                    ),
                  };
                });
              }}
              label={
                <Typography
                  textAlign="left"
                  textWeight="500"
                  marginHorizontal="-10px"
                  textColor={colors.gray5}
                >
                  Unpaid Leave
                </Typography>
              }
            />
            <Checkbox
              marginVertical="5px"
              value={formData.paternity}
              setValue={(data) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    paternity: data,
                    accruedWhenAbsent: checkIfItemExistInArray(
                      formData.accruedWhenAbsent,
                      "Paternity",
                    ),
                  };
                });
              }}
              label={
                <Typography
                  textAlign="left"
                  textWeight="500"
                  marginHorizontal="-10px"
                  textColor={colors.gray5}
                >
                  Paternity
                </Typography>
              }
            />
            <Checkbox
              marginVertical="3px"
              value={formData.COVID}
              setValue={(data) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    COVID: data,
                    accruedWhenAbsent: checkIfItemExistInArray(
                      formData.accruedWhenAbsent,
                      "COVID",
                    ),
                  };
                });
              }}
              label={
                <Typography
                  textAlign="left"
                  textWeight="500"
                  marginHorizontal="-10px"
                  textColor={colors.gray5}
                >
                  COVID
                </Typography>
              }
            />
            <Checkbox
              marginVertical="3px"
              value={formData.compassionate}
              setValue={(data) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    compassionate: data,
                    accruedWhenAbsent: checkIfItemExistInArray(
                      formData.accruedWhenAbsent,
                      "Compassionate",
                    ),
                  };
                });
              }}
              label={
                <Typography
                  textAlign="left"
                  textWeight="500"
                  marginHorizontal="-10px"
                  textColor={colors.gray5}
                >
                  Compassionate
                </Typography>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HolidaySettingsForm;
