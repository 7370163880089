import React from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import DashboardItem from "components/Common/DashboardItem";
import DashboardLongItem from "components/Common/DashboardLongItem";
import UpdateAccountForm from "components/Auth/UpdateAccountForm";

const UpdateSocialAccount: React.FC = () => {
  return (
    <>
      <main className={styles.container}>
        <DashboardHeader active="dashboard" disableSubscription={true} />
        <section className={styles.item_container}>
          <div className={styles.item1}>
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
            <DashboardItem />
          </div>
          <div className={styles.item2}>
            <DashboardLongItem />
          </div>
        </section>
      </main>

      <UpdateAccountForm />
    </>
  );
};

export default UpdateSocialAccount;
