import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PageBackButton from "../../../../Common/PageBackButton";
import Typography from "../../../../Common/Typography";
import colors from "../../../../../theme/colors";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import { getRequest } from "../../../../../api/request";
import PageLoader from "../../../../Common/PageLoader";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import { AdminActivityTimeCheckSettingProps } from "../../../../../types-definition/admin";
import DashboardHeader from "../../../../Common/DashboardHeader";
import { toastError } from "utils/toast";

const ActivityTimeCheckSettingsTab = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentTimeCheckSetting, setCurrentTimeCheckSetting] =
    useState<AdminActivityTimeCheckSettingProps>({
      beforeShiftStart: "",
      afterShiftStart: "",
      beforeShiftEnd: "",
      afterShiftEnd: "",
      preCheckpointTime: "",
      postCheckpointTime: "",
    });
  const [previousTimeCheckSetting, setPreviousTimeCheckSetting] =
    useState<AdminActivityTimeCheckSettingProps>({
      beforeShiftStart: "",
      afterShiftStart: "",
      beforeShiftEnd: "",
      afterShiftEnd: "",
      preCheckpointTime: "",
      postCheckpointTime: "",
    });
  const id = getActivityBasicInformation();
  useEffect(() => {
    const getTimeCheckData = async () => {
      // get employee data
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        const data = response.data.newRecord;
        const previousData = response.data.previousRecord;

        if (response.data.action === "POST") {
          setCurrentTimeCheckSetting({
            beforeShiftStart: data.timeCheck?.beforeShiftStart,
            afterShiftStart: data.timeCheck?.afterShiftStart,
            beforeShiftEnd: data.timeCheck?.beforeShiftEnd,
            afterShiftEnd: data.timeCheck?.afterShiftEnd,
            preCheckpointTime: data.timeCheck?.preCheckpointTime,
            postCheckpointTime: data?.timeCheck.postCheckpointTime,
          });

          setPreviousTimeCheckSetting({
            beforeShiftStart: previousData.timeCheck?.beforeShiftStart,
            afterShiftStart: previousData.timeCheck?.afterShiftStart,
            beforeShiftEnd: previousData.timeCheck?.beforeShiftEnd,
            afterShiftEnd: previousData.timeCheck?.afterShiftEnd,
            preCheckpointTime: previousData.timeCheck?.preCheckpointTime,
            postCheckpointTime: previousData.timeCheck?.postCheckpointTime,
          });
        } else {
          setCurrentTimeCheckSetting({
            beforeShiftStart: data.timeCheck.beforeShiftStart,
            afterShiftStart: data.timeCheck.afterShiftStart,
            beforeShiftEnd: data.timeCheck.beforeShiftEnd,
            afterShiftEnd: data.timeCheck.afterShiftEnd,
            preCheckpointTime: data.timeCheck.preCheckpointTime,
            postCheckpointTime: data.timeCheck.postCheckpointTime,
          });
          setPreviousTimeCheckSetting({
            beforeShiftStart: previousData.timeCheck?.beforeShiftStart,
            afterShiftStart: previousData.timeCheck?.afterShiftStart,
            beforeShiftEnd: previousData.timeCheck?.beforeShiftEnd,
            afterShiftEnd: previousData.timeCheck?.afterShiftEnd,
            preCheckpointTime: previousData.timeCheck?.preCheckpointTime,
            postCheckpointTime: previousData.timeCheck?.postCheckpointTime,
          });
        }
      } else {
        toastError(response?.data);
      }
      setPageLoading(false);
    };
    getTimeCheckData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pageLoading && <PageLoader />}
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Time Check Settings
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Before-Shift Start</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentTimeCheckSetting.beforeShiftStart ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">After-Shift Start</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentTimeCheckSetting?.afterShiftStart ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Before-Shift End</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentTimeCheckSetting?.beforeShiftStart ?? "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">After-Shift End</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentTimeCheckSetting.afterShiftStart
                    ? currentTimeCheckSetting.afterShiftStart
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Pre Checkpoint Time </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentTimeCheckSetting.preCheckpointTime}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Post Checkpoint Time</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {currentTimeCheckSetting?.postCheckpointTime
                    ? currentTimeCheckSetting?.postCheckpointTime
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Record */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Time Check Settings
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Before-Shift Start</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousTimeCheckSetting.beforeShiftStart ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">After-Shift Start</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousTimeCheckSetting?.afterShiftStart ?? "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Before-Shift End</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousTimeCheckSetting?.beforeShiftStart ?? "-"}
                </Typography>
              </div>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">After-Shift End</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousTimeCheckSetting.afterShiftStart
                    ? previousTimeCheckSetting.afterShiftStart
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div className={styles.item_container_text_relative}>
                <Typography textAlign="left">Pre Checkpoint Time </Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousTimeCheckSetting.preCheckpointTime
                    ? previousTimeCheckSetting.preCheckpointTime
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Post Checkpoint Time</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousTimeCheckSetting?.postCheckpointTime
                    ? previousTimeCheckSetting?.postCheckpointTime
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityTimeCheckSettingsTab;
