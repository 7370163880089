import React from "react";
import { DeleteUploadedItemProps } from "../../../../types-definition/common";
import Typography from "../../../Common/Typography";
import deleteIcon from "../../../../assets/svg/error-icon.svg";
import styles from "./index.module.css";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";

const DeleteManualCheckPointReasonModal: React.FC<DeleteUploadedItemProps> = ({
  deleteAction,
  cancelAction,
  requestLoading = false,
}) => {
  return (
    <div className={styles.container}>
      <img src={deleteIcon} alt="cancel icon" className={styles.cancelImage} />
      <Typography textWeight="600" textSize="18px" textAlign="left">
        Delete Item
      </Typography>
      <div className={styles.mid_text}>
        <Typography textColor={colors.gray4} textAlign="left">
          Are you sure you want to delete this item?
        </Typography>
        <Typography
          textColor={colors.gray4}
          textAlign="left"
          marginVertical="-8px"
        >
          This action cannot be undone.
        </Typography>
      </div>
      <div className={styles.button_container}>
        <Button
          width="160px"
          text="Cancel"
          isPrimary={false}
          onClick={cancelAction}
          backgroundColor={colors.transparent}
          isPrimaryBorder={true}
        />
        <Button
          width="160px"
          text="Yes"
          isLoading={requestLoading}
          backgroundColor={colors.error}
          textColor={colors.white}
          isPrimary={false}
          onClick={deleteAction}
        />
      </div>
    </div>
  );
};

export default DeleteManualCheckPointReasonModal;
