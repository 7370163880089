import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { CertificateSearchSelectInputProps } from "../../../types-definition/form";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import searchIcon from "../../../assets/svg/search-icon.svg";
import cancelIcon from "../../../assets/svg/cancel-icon.svg";
import colors from "../../../theme/colors";

const CertificateSearchSelectInput: React.FC<
  CertificateSearchSelectInputProps
> = ({
  options = [],
  certificateTypes = [],
  label,
  placeholder,
  value,
  setValue,
  height = "200px",
  width = "600px",
  datalistTop = "84px",
  dropdownTop = "48px",
  disabled = false,
  error = false,
  onFocus = () => {},
  helperText,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [localValue, setLocalValue] = useState("");
  const [selectedValues, setSelectedValues] = useState<Array<any>>([]);
  const [inputDimensions, setInputDimensions] = useState({
    paddingBottom: "",
    paddingLeft: "",
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [list, setList] = useState<Array<string>>(options);
  const [fullList, setFullList] = useState(certificateTypes);
  // remove the selected item from option
  const filterAlreadySelected = (arr1: any, arr2: any) => {
    let res = [];
    res = arr1.filter((el: any) => {
      return !arr2.find((element: any) => {
        return element === el;
      });
    });
    return res;
  };
  const handleListSelector = (item: string) => {
    // selectedValues.map((previousSelct) => {});
    setSelectedValues((prevData) => {
      return [...prevData, item];
    });
    setValue([...selectedValues, item]);
    setLocalValue("");
    setIsSelected(true);
    setShowDropDown(false);
  };
  const handleListRemover = (item: string) => {
    const newSelectedValues = selectedValues.filter((data) => data !== item);
    setSelectedValues((prevData) => {
      return prevData.filter((data) => data !== item);
    });
    setValue(newSelectedValues);
    setIsSelected(true);
    setShowDropDown(false);
  };
  const filterListFromString = (input: string) => {
    if (input !== "" && input !== undefined) {
      const newList = options.filter((item) =>
        item.toLowerCase().includes(input.toLowerCase()),
      );

      setLocalValue(input);
      setList(newList);
      setIsSelected(false);
    } else {
      setIsSelected(false);
      setLocalValue("");
      setList(options);
    }
  };
  useEffect(() => {
    if (!isSelected && !showDropDown) {
      setLocalValue("");
      setList(options);
      setFullList(certificateTypes);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, showDropDown]);
  useEffect(() => {
    setList(options);
  }, [options]);
  useEffect(() => {
    setFullList(certificateTypes);
  }, [certificateTypes]);
  useEffect(() => {
    if (
      containerRef.current?.clientHeight ||
      containerRef.current?.clientHeight === 0
    ) {
      setInputDimensions((prevData) => {
        return {
          ...prevData,
          paddingBottom:
            containerRef.current!.clientHeight > 38
              ? `${containerRef.current!.clientHeight - 15}px`
              : selectedValues.length > 0
                ? "38px"
                : "18px",
        };
      });
    }
    if (
      containerRef.current?.clientWidth ||
      containerRef.current?.clientWidth === 0
    ) {
      setInputDimensions((prevData) => {
        if (selectedValues.length > 3) {
          if (selectedValues.length % 3 === 0) {
            return { ...prevData, paddingLeft: "450px" };
          } else if ((selectedValues.length - 1) % 3 === 0) {
            return { ...prevData, paddingLeft: `${450 / 3}px` };
          } else {
            return { ...prevData, paddingLeft: `${300}px` };
          }
        }
        return {
          ...prevData,
          paddingLeft:
            containerRef.current!.clientWidth > 0
              ? `${containerRef.current!.clientWidth}px`
              : selectedValues.length > 0
                ? "148px"
                : "10px",
        };
      });
    }
  }, [selectedValues]);
  useEffect(() => {
    if (selectedValues?.length === 0) {
      if (value === null || value === undefined) {
        setSelectedValues([]);
      } else {
        setSelectedValues(value);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (value.length !== selectedValues.length) {
      setValue(selectedValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);
  return (
    <section className={styles.container} style={{ width }}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {label}
      </Typography>
      <input
        type="text"
        value={localValue}
        placeholder={placeholder}
        className={styles.select}
        ref={inputRef}
        onChange={(e) => {
          if (!showDropDown) {
            setShowDropDown(true);
          }
          filterListFromString(e.target.value);
        }}
        onFocus={() => {
          onFocus();
          setShowDropDown(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 500);
        }}
        style={{
          paddingLeft: inputDimensions.paddingLeft,
          paddingTop: inputDimensions.paddingBottom,
          borderColor: error ? colors.error : colors.gray3,
        }}
        disabled={disabled}
      />
      <div className={styles.helpertext_container}>
        <Typography
          textAlign="left"
          textSize={"10px"}
          textWeight="600"
          textColor={error ? colors.error : colors.primary3}
        >
          {helperText}
        </Typography>
      </div>
      <div
        className={styles.selected_item_container}
        ref={containerRef}
        onClick={() => {
          if (inputRef.current?.focus) {
            inputRef.current?.focus();
          }
        }}
      >
        {selectedValues.map((item, index) => {
          return (
            <div
              className={styles.selected_item}
              key={Math.random().toString()}
              style={{
                display: `${value.length < 1 && "none"}`,
              }}
            >
              <Typography marginHorizontal="5px" key={index}>
                {fullList.find((data) => data.id === item).certificateName}
              </Typography>
              <img
                src={cancelIcon}
                alt="cancel"
                className={styles.cancel_image}
                onClick={() => handleListRemover(item)}
              />
            </div>
          );
        })}
      </div>
      <div
        className={styles.dropdown_container}
        style={{ top: dropdownTop }}
        tabIndex={0}
        onClick={() => !disabled && setShowDropDown(true)}
        onBlur={() => {
          setTimeout(() => {
            setShowDropDown(false);
          }, 400);
        }}
        aria-disabled={disabled}
      >
        <img src={searchIcon} className={styles.dropdown} alt="search icon" />
      </div>
      {showDropDown && (
        <div
          className={styles.datalist}
          style={{
            maxHeight: height,
            top: inputDimensions.paddingBottom
              ? `${inputDimensions.paddingBottom + 30}px`
              : datalistTop,
          }}
        >
          {list.length === 0 ? (
            <p>No data</p>
          ) : (
            filterAlreadySelected(list, selectedValues).map((item: string) => (
              <p onClick={() => handleListSelector(item)} key={item}>
                {fullList.find((data) => data.id === item).certificateName}
              </p>
            ))
          )}
        </div>
      )}
    </section>
  );
};

export default CertificateSearchSelectInput;
