import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const initialState = {
  logoutUpdateTimerCounter: 0,
};

export const logoutTimerSlice = createSlice({
  name: "logoutTimer",
  initialState,
  reducers: {
    updateLogoutTimerCounter: (state) => {
      state.logoutUpdateTimerCounter += 1;
    },
  },
});
export const { updateLogoutTimerCounter } = logoutTimerSlice.actions;

export const getLogoutTimerUpdateCounter = (state: RootState) =>
  state.logoutTimer.logoutUpdateTimerCounter;
export default logoutTimerSlice.reducer;
