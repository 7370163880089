import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import TextInput from "../../../../../components/Form/TextInput";
import { Country, State } from "country-state-city";
import LocationSelectInput from "../../../../../components/Form/LocationSelectInput";
import { EmployeeEmergencyFormProps } from "../../../../../types-definition/StaffHub/employee";
import AddressSearchInput from "../../../../Form/AddressInputSearch";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getCountry } from "../../../../../utils/localstorage";
const EmployeeEmergencyContactForm: React.FC<EmployeeEmergencyFormProps> = ({
  formData,
  setFormData,
  currentCountryCode,
  setCurrentCountryCode,
}) => {
  const [address, setAddress] = useState<Array<any>>([]);
  const country = getCountry();
  const isoCode = Country.getAllCountries().find((i) => i.name === country)
    ?.isoCode;
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesAutocompleteService({
      apiKey: "AIzaSyAM8qnCnyXYgNppoFnjGxQrV-dYo5_1OpQ",
      options: {
        componentRestrictions: {
          country: isoCode?.toLowerCase(),
        },
      },
    });
  useEffect(() => {
    setAddress(placePredictions.map((i) => i.description));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlacePredictionsLoading]);
  return (
    <div className={styles.container}>
      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.contactName}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, contactName: data };
            })
          }
          label="Contact Name"
          placeholder="Enter Contact Name"
        />
        <TextInput
          value={formData.relationship}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, relationship: data };
            })
          }
          label="Relationship"
          placeholder="Enter Relationship"
        />
      </div>

      <div className={styles.form_flex_container}>
        <AddressSearchInput
          value={formData.address1}
          setValue={(data) => {
            getPlacePredictions({ input: data.value });
            if (data.action) {
              setFormData((prevData) => {
                const length = data.value.split(",").length;
                return {
                  ...prevData,
                  state: data.value.split(",")[length - 2],
                  country: data.value.split(",")[length - 1],
                };
              });
            }
            setFormData((prevData) => {
              return { ...prevData, address1: data.value };
            });
          }}
          label="Address 1"
          placeholder="Enter Address"
          options={address}
        />
        <TextInput
          value={formData.address2}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, address2: data };
            })
          }
          label="Address 2"
          placeholder="Enter Address"
        />
      </div>

      <div className={styles.form_flex_container}>
        <LocationSelectInput
          value={formData.state}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, state: data };
            })
          }
          code={currentCountryCode}
          label="State"
          placeholder="Select State"
          setCode={() => {}}
          options={State.getStatesOfCountry(currentCountryCode)}
        />

        <LocationSelectInput
          value={formData.country}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, country: data };
            })
          }
          setCode={(code) => {
            setCurrentCountryCode(code);
          }}
          label="Country"
          placeholder="Select Country"
          options={Country.getAllCountries()}
          disabled={true}
        />
      </div>

      <div className={styles.form_flex_container}>
        <TextInput
          value={formData.telephone}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, telephone: data };
            })
          }
          label="Telephone"
          placeholder="Enter Telephone Number"
        />
        <TextInput
          value={formData.email}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, email: data };
            })
          }
          label="Email"
          placeholder="Enter Email"
        />
      </div>
    </div>
  );
};

export default EmployeeEmergencyContactForm;
