import React from "react";
import Typography from "../../../Common/Typography";
import size from "../../../../theme/size";
import { TextInputProps } from "../../../../types-definition/form";
import styles from "./index.module.css";
import colors from "../../../../theme/colors";

const SearchInput: React.FC<TextInputProps> = ({
  placeholder,
  label,
  right,
  left,
  value,
  setValue,
  disabled = false,
  width,
  required = false,
  error = false,
}) => {
  return (
    <section className={styles.container}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <div className={styles.inputContainer}>
        <div className={styles.left}>{left}</div>
        <input
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          className={
            right
              ? `${styles.input} ${styles.right_padding}`
              : left
                ? `${styles.input} ${styles.left_padding}`
                : styles.input
          }
          disabled={disabled}
          // type=""
          style={{
            width,
            borderColor: error ? colors.error : colors.gray3,
          }}
        />
        <div className={styles.right}>{right}</div>
      </div>
    </section>
  );
};

export default SearchInput;
