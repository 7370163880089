import React from "react";
import Typography from "../Typography";
import { CustomListProps } from "types-definition/common";
import listIcon from "assets/svg/list-icon.svg";

const CustomList: React.FC<CustomListProps> = ({
  text,
  gap = "10px",
  width = "250px",
  lineHeight,
  alignItem,
}) => {
  return (
    <div style={{ display: "flex", gap, alignItems: alignItem }}>
      <img src={listIcon} alt="check" width={20} height={20} />
      <div style={{ width, marginTop: "-8px" }}>
        <Typography textColor="#21212199" textAlign="left">
          {text}
        </Typography>
      </div>
    </div>
  );
};

export default CustomList;
