import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import colors from "../../../../theme/colors";
import { ClientBoardInformationProps } from "../../../../types-definition/client";

const ClientBoardInformation: React.FC<ClientBoardInformationProps> = ({
  number,
  text,
}) => {
  return (
    <div className={styles.container}>
      <Typography textSize="13px" textAlign="left" textColor={colors.gray3}>
        {text}
      </Typography>
      <Typography textWeight="600" textSize="20px" textAlign="left">
        {number}
      </Typography>
    </div>
  );
};

export default ClientBoardInformation;
