import React, { useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import circleCancelIcon from "../../../../../assets/svg/cancel-circle-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import Button from "../../../../Form/Button";
import DateInput from "../../../../Form/DateInput";
import {
  getAddHolidayModalMinDate,
  setShowAddHolidayModal,
  updateEmployeeHolidayAbsenceCounter,
} from "../../../../../redux/slice/staff-hub/employeeSlice";
import SelectInput from "../../../../Form/SelectInput";
import TextInput from "../../../../Form/TextInput";
import RadioInput from "../../../../Form/RadioInput";
import {
  EmployeeHolidayAbsenceAllDayRequiredFormData,
  EmployeeHolidayAbsenceFormData,
  EmployeeHolidayAbsenceHalfDayRequiredFormData,
} from "../../../../../types-definition/StaffHub/employee";
import fieldTextValidator from "../../../../../utils/validateFieldsWithText";
import { postRequest } from "../../../../../api/request";
import { getEmployeeId } from "../../../../../utils/localstorage";
import moment from "moment";
import { toastError, toastSuccess } from "../../../../../utils/toast";

const EmployeeAddHolidayModal = () => {
  const dispatch = useAppDispatch();
  const id = getEmployeeId();
  const minDate = useAppSelector(getAddHolidayModalMinDate);

  const [requestLoading, setRequestLoading] = useState(false);
  const [formData, setFormData] = useState<EmployeeHolidayAbsenceFormData>({
    fromDate: minDate,
    toDate: "",
    holidayType: "",
    description: "",
    allDay: true,
    preferredTime: "",
    date: minDate,
  });

  const [allDayRequiredFormData, setAllDayRequiredFormData] =
    useState<EmployeeHolidayAbsenceAllDayRequiredFormData>({
      fromDate: {
        status: false,
        text: "",
      },
      toDate: {
        status: false,
        text: "",
      },
      holidayType: {
        status: false,
        text: "",
      },
    });

  const [halfDayRequiredFormData, setHalfDayRequiredFormData] =
    useState<EmployeeHolidayAbsenceHalfDayRequiredFormData>({
      date: {
        status: false,
        text: "",
      },
      preferredTime: {
        status: false,
        text: "",
      },
      holidayType: {
        status: false,
        text: "",
      },
    });
  const handleApprove = async () => {
    setRequestLoading(true);
    const holidays: Array<any> = [];
    const fromDate = new Date(formData.fromDate);
    const toDate = new Date(formData.toDate);
    const fromDateData = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate(),
    );
    const toDateData = new Date(
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate(),
    );
    if (formData.allDay) {
      for (let i = fromDateData; i <= toDateData; i.setDate(i.getDate() + 1)) {
        const holiday = {
          // date: moment(new Date(i)).format("DD/MM/YYYY"),
          holidayType: formData.holidayType,
          description: formData.description,
          timeOff: "allDay",
          date: moment.utc(new Date(i)).toISOString(),
        };
        holidays.push(holiday);
      }
      const response = await postRequest(`employee/${id}/holiday`, holidays);
      if (response?.success) {
        toastSuccess("Time Off request approved successfully");
        dispatch(updateEmployeeHolidayAbsenceCounter());
        dispatch(setShowAddHolidayModal({ show: false, minDate: "" }));
      } else {
        if (response?.data) toastError(response?.data);
        setRequestLoading(false);
        return;
      }
    } else {
      const response = await postRequest(`employee/${id}/holiday`, [
        {
          holidayType: formData.holidayType,
          timeOff: "halfDay",
          // date: moment(new Date(formData.date)).format("DD/MM/YYYY"),
          preferredTime: formData.preferredTime,
          description: formData.description,
          date: moment.utc(new Date(formData.date)).toISOString(),
        },
      ]);
      if (response?.success) {
        toastSuccess("Time Off request approved successfully");
      } else {
        if (response?.data) toastError(response?.data);
        setRequestLoading(false);
        return;
      }
    }
    setRequestLoading(false);
    dispatch(updateEmployeeHolidayAbsenceCounter());
    dispatch(setShowAddHolidayModal({ show: false, minDate: "" }));
  };
  const handleFormSubmit = async () => {
    if (formData.allDay) {
      const { status, data } = fieldTextValidator(
        formData,
        allDayRequiredFormData,
      );
      if (!status) {
        setAllDayRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        setRequestLoading(false);
        return;
      }
      handleApprove();
    } else {
      const { status, data } = fieldTextValidator(
        formData,
        halfDayRequiredFormData,
      );
      if (!status) {
        setHalfDayRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        setRequestLoading(false);
        return;
      }
      handleApprove();
    }
  };

  return (
    <>
      <section className={styles.container}>
        <div className={styles.flex_container}>
          <Typography textWeight="600" textSize="20px">
            Add Holiday
          </Typography>
          <img
            src={circleCancelIcon}
            alt="cancel-icon"
            onClick={() =>
              dispatch(setShowAddHolidayModal({ show: false, minDate: "" }))
            }
          />
        </div>
        <div className={styles.form_container}>
          <SelectInput
            label="Holiday Type"
            placeholder="Select Holiday Type"
            value={formData.holidayType}
            setValue={(data) => {
              setFormData({ ...formData, holidayType: data });
              setAllDayRequiredFormData((prevData) => {
                return {
                  ...prevData,
                  holidayType: { status: false, text: "" },
                };
              });
            }}
            helperText={allDayRequiredFormData.holidayType.text}
            error={allDayRequiredFormData.holidayType.status}
            dropdownTop="46px"
            datalistTop="84px"
            options={[
              "Leave",
              "Suspended",
              "Maternity",
              "Sickness",
              "AWOL",
              "Unpaid Leave",
              "Paternity",
              "COVID",
              "Other",
              "Compassionate",
            ]}
          />
          <TextInput
            value={formData.description}
            setValue={(data) => {
              return setFormData({ ...formData, description: data });
            }}
            placeholder="Enter Description"
            label="Description"
            style={{ marginTop: "10px" }}
          />
          <div>
            <Typography textAlign="left">Time Off</Typography>
            <div className={styles.radio_container}>
              <RadioInput
                label={"All Day"}
                name="time"
                value={formData.allDay}
                setValue={() => {
                  return setFormData({
                    ...formData,
                    allDay: !formData.allDay,
                  });
                }}
              />
              <RadioInput
                label={"Half Day"}
                name="time"
                value={!formData.allDay}
                setValue={() => {
                  return setFormData({
                    ...formData,
                    allDay: !formData.allDay,
                  });
                }}
              />
            </div>
          </div>
          {formData.allDay ? (
            <div className={styles.form_item}>
              <DateInput
                placeholder={new Date().toLocaleDateString()}
                value={formData.fromDate}
                label="From"
                setValue={(data) => {
                  setFormData({ ...formData, fromDate: data });
                  setAllDayRequiredFormData((prevData) => {
                    return {
                      ...prevData,
                      fromDate: { status: false, text: "" },
                    };
                  });
                }}
                min={minDate}
                validateMin={minDate ? true : false}
                helperText={allDayRequiredFormData.fromDate.text}
                error={allDayRequiredFormData.fromDate.status}
                style={{ width: "200px" }}
              />
              <DateInput
                placeholder={new Date().toLocaleDateString()}
                value={formData.toDate}
                label="To"
                setValue={(data) => {
                  setFormData({ ...formData, toDate: data });
                  setAllDayRequiredFormData((prevData) => {
                    return {
                      ...prevData,
                      toDate: { status: false, text: "" },
                    };
                  });
                }}
                validateMin
                // min Date calculated for next day
                min={formData.fromDate}
                helperText={allDayRequiredFormData.toDate.text}
                error={allDayRequiredFormData.toDate.status}
                style={{ width: "200px" }}
              />
            </div>
          ) : (
            <div>
              <SelectInput
                placeholder="Select Preferred Time"
                value={formData.preferredTime}
                label="Preferred Time"
                setValue={(data) => {
                  setFormData({ ...formData, preferredTime: data });
                  setHalfDayRequiredFormData((prevData) => {
                    return {
                      ...prevData,
                      preferredTime: { status: false, text: "" },
                    };
                  });
                }}
                helperText={halfDayRequiredFormData.preferredTime.text}
                error={halfDayRequiredFormData.preferredTime.status}
                dropdownTop="46px"
                datalistTop="84px"
                options={["AM", "PM"]}
              />
              <DateInput
                placeholder=""
                value={formData.date}
                label="Date"
                setValue={(data) => {
                  setFormData({ ...formData, date: data });
                  setHalfDayRequiredFormData((prevData) => {
                    return {
                      ...prevData,
                      date: { status: false, text: "" },
                    };
                  });
                }}
                helperText={halfDayRequiredFormData.date.text}
                error={halfDayRequiredFormData.date.status}
              />
            </div>
          )}
        </div>
        <div className={styles.button_container}>
          <Button
            text="Cancel"
            isPrimary={false}
            isPrimaryBorder
            backgroundColor="transparent"
            onClick={() =>
              dispatch(setShowAddHolidayModal({ show: false, minDate: "" }))
            }
            marginHorizontal="5px"
          />
          <Button
            text="Save"
            isLoading={requestLoading}
            isPrimary
            marginHorizontal="5px"
            onClick={handleFormSubmit}
          />
        </div>
      </section>
    </>
  );
};

export default EmployeeAddHolidayModal;
