import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { CheckcallReportData } from "types-definition/report";
const initialState = {
  checkcallLogData: [] as CheckcallReportData[],
  filteredCheckcallLogData: [] as CheckcallReportData[],
  checkcallId: "",
  activeCheckcallGroupIds: [] as string[],
};

export const checkcallLogSlice = createSlice({
  name: "checkpointReport",
  initialState,
  reducers: {
    setReduxCheckcallId: (state, action: PayloadAction<string>) => {
      state.checkcallId = action.payload;
    },
    setCheckcallLogData: (
      state,
      action: PayloadAction<CheckcallReportData[]>,
    ) => {
      state.checkcallLogData = action.payload;
    },
    setActiveCheckcallGroupIds: (state, action: PayloadAction<string[]>) => {
      state.activeCheckcallGroupIds = action.payload;
    },
  },
});

export const {
  setReduxCheckcallId,
  setCheckcallLogData,
  setActiveCheckcallGroupIds,
} = checkcallLogSlice.actions;

export const getReduxCheckcallId = (state: RootState) =>
  state.checkcallLog.checkcallId;

export const getCheckcallLogData = (state: RootState) =>
  state.checkcallLog.checkcallLogData;

export const getActiveCheckcallGroupIds = (state: RootState) =>
  state.checkcallLog.activeCheckcallGroupIds;

export default checkcallLogSlice.reducer;
