import colors from "theme/colors";
import styles from "./index.module.css";

interface BadgeProps {
  borderWidth?: "1px" | "2px" | "4px";
  borderColor?: string;
  textColor?: string;
  fontSize?: string;
  fontWeight?: string;
  background?: string;
  paddingVertical?: string;
  paddingHorizontal?: string;
  text?: React.ReactNode;
  textTransform?: string;
  state: "success" | "danger" | "warning" | "unknown" | "info";
}

const Badge: React.FC<BadgeProps> = ({
  borderWidth = "1px",
  borderColor = colors.successtext,
  textColor = colors.successtext,
  fontSize = "14px",
  fontWeight = "500",
  background = "transparent",
  paddingVertical = ".125rem",
  paddingHorizontal = "1rem",
  text = "Accepted",
  state,
  // textTransform = "capitalize"
}) => {
  return (
    <div
      style={{
        borderWidth,
        borderStyle: "solid",
        borderColor:
          state === "success"
            ? colors.successtext
            : state === "danger"
              ? colors.error
              : state === "unknown"
                ? colors.gray3
                : state === "info"
                  ? colors.primary3
                  : state === "warning"
                    ? colors.warningText
                    : borderColor,
        color:
          state === "success"
            ? colors.successtext
            : state === "danger"
              ? colors.error
              : state === "unknown"
                ? colors.gray3
                : state === "info"
                  ? colors.primary3
                  : state === "warning"
                    ? colors.warningText
                    : textColor,
        fontSize,
        fontWeight,
        background,
        textTransform: "capitalize",
        paddingTop: paddingVertical,
        paddingBottom: paddingVertical,
        paddingLeft: paddingHorizontal,
        paddingRight: paddingHorizontal,
      }}
      className={styles.badge}
    >
      {text}
    </div>
  );
};

export default Badge;
