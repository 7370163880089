import React, { useState } from "react";
import { TimeInputProps } from "../../../types-definition/form";
import styles from "./index.module.css";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";
import clockIcon from "../../../assets/svg/clock-icon.svg";
import colors from "../../../theme/colors";

const TimeInput: React.FC<TimeInputProps> = ({
  options = [],
  label,
  placeholder,
  value,
  setValue,
  height = "200px",
  width,
  datalistTop = "55px",
  dropdownTop = "18px",
  required = false,
  error = false,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [time, setTime] = useState({
    hour: "",
    minute: "",
    ampm: "",
  });
  const hours = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const minutes = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    ...Array.from({ length: 59 }, (_, i) => (i + 1).toString()),
  ];

  const ampm = ["AM", "PM"];

  return (
    <section className={styles.container} style={{ width }}>
      <Typography textAlign="left" textSize={size.md} textWeight="600">
        {required ? `${label} *` : label}
      </Typography>
      <div
        tabIndex={0}
        onClick={() => setShowDropDown(true)}
        onBlur={(event) => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            setTimeout(() => {
              setShowDropDown(false);
            }, 300);
          }
        }}
      >
        <input
          type="text"
          value={value}
          placeholder={placeholder}
          className={styles.select}
          onChange={(e) => {
            if (!showDropDown) {
              // setShowDropDown(true);
            }
            // filterListFromString(e.target.value);
          }}
          style={{ borderColor: error ? colors.error : colors.gray3 }}
          readOnly
        />
        <div className={styles.dropdown_container} style={{ top: dropdownTop }}>
          <img
            src={clockIcon}
            className={styles.dropdown}
            alt="dropdown icon"
          />
        </div>
        {showDropDown && (
          <div
            className={styles.datalist}
            style={{ maxHeight: height, top: datalistTop }}
          >
            <div
              className={styles.datalist_item}
              style={{ height: height, width: "30px" }}
            >
              {hours.map((item) => {
                return (
                  <p
                    key={item}
                    className={
                      time.hour === item ? styles.active_datalist_item : ""
                    }
                    onClick={() => {
                      setTime({ ...time, hour: item });
                      if (time.minute && time.ampm) {
                        setValue(`${item}:${time.minute} ${time.ampm}`);
                      }
                    }}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
            <div
              className={styles.datalist_item}
              style={{ height: height, width: "30px" }}
            >
              {minutes.map((item) => {
                return (
                  <p
                    key={item}
                    className={
                      time.minute === item ? styles.active_datalist_item : ""
                    }
                    onClick={() => {
                      setTime({ ...time, minute: item });
                      if (time.hour && time.ampm) {
                        setValue(`${time.hour}:${item} ${time.ampm}`);
                      }
                    }}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
            <div className={styles.datalist_item}>
              {ampm.map((item) => {
                return (
                  <p
                    key={item}
                    className={
                      time.ampm === item ? styles.active_datalist_item : ""
                    }
                    onClick={() => {
                      setTime({ ...time, ampm: item });
                      if (time.hour && time.minute) {
                        setValue(`${time.hour}:${time.minute} ${item}`);
                      }
                    }}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default TimeInput;
