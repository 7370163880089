import eyeOpenIcon from "assets/svg/eye-open.svg";
import messageIcon from "assets/svg/message-icon.svg";
import React from "react";
import {
  setEmployeeContact,
  setShowEmployeeContactModal,
} from "redux/slice/common/statisticsSlice";
import { StatisticEmployee } from "types-definition/common";
import { useAppDispatch } from "../../../redux/hooks";
import colors from "../../../theme/colors";
import Typography from "../../Common/Typography";
import styles from "./index.module.css";
import {
  setEmployeeMessageIndividualId,
  setMessageEmployeeEntryMode,
  setShowSelectDeliveryModeModal,
} from "redux/slice/staff-hub/employeeMessageSlice";
// import { setEmployeeId } from "../../../../utils/localstorage";

const StatisticTableDropdown: React.FC<StatisticEmployee> = ({
  id,
  firstName,
  lastName,
  mobileNumber,
  email,
}) => {
  const dispatch = useAppDispatch();

  const handleMessageEmployee = () => {
    dispatch(setMessageEmployeeEntryMode("individual"));
    dispatch(setEmployeeMessageIndividualId(id));
    dispatch(setShowSelectDeliveryModeModal(true));
  };

  return (
    <>
      <div
        className={styles.dropdown_container}
        // style={{ top: getUserTypes() === ADMIN ? "-250px" : "-100px" }}
      >
        <div
          className={styles.dropdown_item}
          onClick={() => {
            dispatch(
              setEmployeeContact({
                id,
                name: `${firstName} ${lastName}`,
                email,
                mobileNumber,
              }),
            );
            dispatch(setShowEmployeeContactModal(true));
          }}
        >
          <img
            src={eyeOpenIcon}
            alt="audit-icon"
            className={styles.dropdown_image_item}
          />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            View Contact
          </Typography>
        </div>
        <div className={styles.dropdown_item} onClick={handleMessageEmployee}>
          <img
            src={messageIcon}
            alt="view-profile"
            className={styles.dropdown_image_item}
          />
          <Typography
            textAlign="left"
            hoverActive={true}
            hoverColors={colors.primary3}
            paddingVertical="10px"
          >
            Message Employee
          </Typography>
        </div>
      </div>
    </>
  );
};

export default StatisticTableDropdown;
