import React from "react";
import { SignInButtonProps } from "../../../types-definition/form";
import styles from "./index.module.css";
import colors from "../../../theme/colors";
import Typography from "../../Common/Typography";
import size from "../../../theme/size";

const SignInButton: React.FC<SignInButtonProps> = ({
  text,
  onClick,
  backgroundColor = colors.white,
  image,
}) => {
  return (
    <button
      className={styles.button}
      style={{ backgroundColor }}
      onClick={onClick}
    >
      <div className={styles.buttonText}>
        <img
          src={image}
          alt="icon"
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
        />
        <Typography
          textColor={colors.black}
          textSize={size.sm}
          textWeight="500"
        >
          {text}
        </Typography>
      </div>
    </button>
  );
};

export default SignInButton;
