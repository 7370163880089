import { useState } from "react";
import TextInput from "../../../Form/TextInput";
import styles from "./index.module.css";
import cancelicon from "../../../../assets/svg/cancel-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  deleteCheckPointReasons,
  getCheckPointReasons,
  updateCheckPointReasons,
  updateCheckPointReasonsCounter,
} from "../../../../redux/slice/admin/manualCheckpointReasons";
import { deleteRequest } from "../../../../api/request";
import { MANUAL_CHECKPOINT_REASON } from "../../../../api/urls";
import DeleteManualCheckPointReasonModal from "../DeleteManualCheckpointReasonModal";
import { toastError } from "../../../../utils/toast";

const ManualCheckpointInput = ({ reason, index }: any) => {
  const [showCancel, setShowCacel] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const manualCheckPointReason = useAppSelector(getCheckPointReasons);
  const dispatch = useAppDispatch();
  const handleDelete = async () => {
    setRequestLoading(true);
    const manaulCheckPointReasonsCopy = [...manualCheckPointReason];
    const id = manaulCheckPointReasonsCopy[index].id;
    if (id) {
      const response = await deleteRequest(`${MANUAL_CHECKPOINT_REASON}/${id}`);
      if (response?.success) {
        dispatch(deleteCheckPointReasons(index));
        dispatch(updateCheckPointReasonsCounter());
      } else {
        toastError(response?.data);
      }
    } else {
      dispatch(deleteCheckPointReasons(index));
    }
    setRequestLoading(false);
    setShowDeleteModal(false);
  };
  return (
    <>
      <div
        className={styles.form_flex_container}
        onMouseEnter={() => {
          setShowCacel(true);
        }}
        onMouseLeave={() => {
          setShowCacel(false);
        }}
      >
        <TextInput
          label={`Reason ${index + 1}`}
          placeholder={
            manualCheckPointReason[index].placeholderReason ??
            "Enter Checkpoint Reason"
          }
          setValue={(data) => {
            dispatch(
              updateCheckPointReasons({
                editing: true,
                index,
                reason: data,
                id: reason.id,
                description: manualCheckPointReason[index].description,
              }),
            );
          }}
          value={manualCheckPointReason[index].reasons ?? ""}
          // value=""
          // value={propertyNames[0]}
        />
        <TextInput
          label={`Reason ${index + 1}  Description`}
          placeholder={
            manualCheckPointReason[index].placeholderDescription ??
            "Enter Description"
          }
          setValue={(data) => {
            dispatch(
              updateCheckPointReasons({
                index,
                editing: true,
                reason: manualCheckPointReason[index].reasons,
                id: reason.id,
                description: data,
              }),
            );
          }}
          value={manualCheckPointReason[index].description ?? ""}
        />
        {showCancel && manualCheckPointReason[index]?.id && (
          <img
            src={cancelicon}
            alt=""
            className={styles.cancelIcon}
            onClick={() => {
              if (manualCheckPointReason[index].id) {
                setShowDeleteModal(true);
              } else {
                dispatch(deleteCheckPointReasons(index));
              }
            }}
          />
        )}
      </div>
      {showDeleteModal && (
        <DeleteManualCheckPointReasonModal
          cancelAction={() => {
            setShowDeleteModal(false);
          }}
          deleteAction={handleDelete}
          requestLoading={requestLoading}
        />
      )}
    </>
  );
};
export default ManualCheckpointInput;
