import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { AreaTableProps } from "../../../../types-definition/client";
import colors from "../../../../theme/colors";
import AreaTableItem from "../AreaTableItem";
import { getRequest } from "../../../../api/request";
import { GET_LOCATION_URL } from "../../../../api/urls";

const AreaTable: React.FC<AreaTableProps> = ({ data }) => {
  const [location, setLocation] = useState([]);
  useEffect(() => {
    const getAllLocations = async () => {
      const response = await getRequest(`${GET_LOCATION_URL}`);
      if (response?.success) {
        setLocation(response.data);
      } else {
      }
    };
    getAllLocations();
  }, []);
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.tr} ${styles.tr_head}`}>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Name
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Description
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Location
              </Typography>
            </th>
            <th className={styles.th}>
              <Typography
                textAlign="left"
                fontFamily="inter"
                textWeight="600"
                textColor={colors.tableText}
              >
                Status
              </Typography>
            </th>
            <th className={styles.th_index}></th>
          </tr>
        </thead>
        {/* <EmployeeTableItem /> */}
        <tbody>
          {data?.map((item, index) => {
            return (
              <AreaTableItem {...item} locations={location} key={item.name} />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AreaTable;
