import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "components/Common/DashboardHeader";
import Button from "components/Form/Button";
import Typography from "components/Common/Typography";
import { getRequest } from "api/request";
import GradeTable from "components/StaffHub/Grade/GradeTable";
import addIcon from "assets/svg/add-icon.svg";
import { POST_GRADE_URL } from "api/urls";
import style from "./index.module.css";
import { AdminGradeItemData } from "types-definition/admin";
import PageLoader from "components/Common/PageLoader";
import ScrollToTop from "components/Common/ScrollToTop";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  showModal,
  refreshDeleteGrade,
  deleteGradeHandler,
} from "../../../redux/slice/admin/gradeSlice";
import searchIcon from "assets/svg/search-icon.svg";
import DeleteGradeModal from "components/StaffHub/Grade/GradeDeleteModal";
import TableFilter from "components/Common/TableFilter";
import TextInput from "components/Form/TextInput";
import EmptyItemState from "components/Common/EmptyItemState";
import { deleteGrade } from "utils/localstorage";

const AdminGrade: React.FC = () => {
  const [gradeData, setGradeData] = useState<Array<AdminGradeItemData>>([]);
  const isShowModal = useAppSelector(showModal);
  const refreshTable = useAppSelector(refreshDeleteGrade);
  const navigate = useNavigate();
  const [requestLoading, setRequestLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getGrade = async () => {
      setRequestLoading(true);
      const response = await getRequest(POST_GRADE_URL);
      if (response?.success) {
        const responseData: Array<AdminGradeItemData> = [];
        for (const index in response?.data) {
          const data = {
            id: response?.data[index]?.id,
            grade: response?.data[index]?.grade,
            description: response?.data[index]?.description,
            color: response?.data[index]?.color,
            certificateGroup: response?.data[index]?.certificateGroupName,
            certificateGroupId: response?.data[index]?.certificateGroup,
            active: response?.data[index]?.active,
          };
          responseData.push(data);
        }
        deleteGrade();
        setGradeData(responseData);
      }
      setRequestLoading(false);
    };
    dispatch(deleteGradeHandler({ gradeDeleted: false }));
    getGrade();
  }, [refreshTable, dispatch]);

  return (
    <>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="staff/hub" />
      <div className={style.container}>
        <div className={style.item_container}>
          <div className={style.flex_item}>
            <Typography textAlign="left" textWeight="600" textSize="20px">
              Grade
            </Typography>
            <div className={style.button_flex_container}>
              <TableFilter />
              <TextInput
                left={<img src={searchIcon} alt="search" />}
                placeholder="Search"
                value=""
                setValue={() => {}}
              />
              <Button
                left={<img src={addIcon} alt="add" />}
                marginHorizontal="10px"
                marginTop="8px"
                paddingVertical="10px"
                width="150px"
                isPrimary
                text="Grade"
                onClick={() => navigate("/staff-hub/grade/form")}
              />
            </div>
          </div>

          {gradeData.length > 0 && <GradeTable data={gradeData} />}
          {gradeData.length === 0 && (
            <div className={style.empty_container}>
              <EmptyItemState
                text="You haven’t added any grades yet"
                text2="The Grade functionality empowers you to set and define different job roles within your company. This enables the assignment of a specific grade to an employee profile during its creation, aligning with the employee's role or function within the company, such as Cleaner or Chef."
                location="staff-hub/grade/form"
                label="Add Grade"
                textAlign="left"
              />
            </div>
          )}
        </div>
        {isShowModal && <DeleteGradeModal />}
      </div>
    </>
  );
};
export default AdminGrade;
