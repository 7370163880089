import { getRequest, postRequest, patchRequest } from "api/request";
import { getData, getSetupComplete, setSetupComplete } from "./localstorage";
export const createWizard = async (name: string, completed: boolean) => {
  try {
    const response = await postRequest(`/settings/set-up`, {
      name: name,
      completed: completed,
    });
    return response;
  } catch (error) {}
};

export const updateWizard = async (name: string, completed: boolean) => {
  let userId;
  const userData = getData();
  if (userData) {
    userId = JSON.parse(userData).id;
  }
  try {
    const response = await patchRequest(`/settings/set-up/${userId}`, {
      name: name,
      completed: completed,
    });
    return response;
  } catch (error) {}
};

export const getWizard = async () => {
  try {
    const userData = JSON.parse(getData()!);
    let response;
    response = userData?.token ? await getRequest(`/settings/set-up`) : "";
    return response;
  } catch (error) {}
};

export const updateSetupComplete = async (
  step: string,
  complete: boolean,
  name: string,
) => {
  const completedStep = await getWizard();
  let checkStepComplete = [];
  if (name) {
    checkStepComplete = completedStep?.data.filter(
      (item: any) => item.name === name,
    );
  }
  const setUpStep = getSetupComplete();
  if (checkStepComplete.length < 1) {
    if (setUpStep) {
      createWizard(name, true);
      if (!JSON.parse(setUpStep)?.completed) {
        try {
          setSetupComplete(
            JSON.stringify({
              completed: complete,
              step:
                parseFloat(step) >= parseFloat(JSON.parse(setUpStep)?.step)
                  ? step
                  : JSON.parse(setUpStep)?.step,
            }),
          );
          // const userData = JSON.parse(getData()!);
          let response;
          response =
            parseFloat(step) < 14
              ? await patchRequest(`/auth/setup`, {
                  setupStep: step,
                  setupCompleted: false,
                })
              : await patchRequest(`/auth/setup`, {
                  setupStep: step,
                  setupCompleted: true,
                });
        } catch (error) {}
      }
    }
    return false;
  } else {
    return true;
  }
};
