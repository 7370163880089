import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { getRequest } from "../../../../../api/request";
import { GET_SYSTEM_ACTIVITY } from "../../../../../api/urls";
import colors from "../../../../../theme/colors";
import Typography from "../../../../Common/Typography";
import { getActivityBasicInformation } from "../../../../../utils/localstorage";
import PageBackButton from "../../../../Common/PageBackButton";

const ActivityEmployeeContactInformationNonUk = () => {
  const id = getActivityBasicInformation();
  const [contactDetails, setContactDetails] = useState({
    addressLine1: "",
    addressLine2: "",
    town: "",
    state: "",
    zipCode: "",
    country: "",
    mobileNumber: "",
    email: "",
    county: "",
  });
  const [previousContactDetails, setPreviousContactDetails] = useState({
    addressLine1: "",
    addressLine2: "",
    town: "",
    state: "",
    zipCode: "",
    country: "",
    mobileNumber: "",
    email: "",
    county: "",
  });
  useEffect(() => {
    const getActivityEmployeeContactInformation = async () => {
      const response = await getRequest(`${GET_SYSTEM_ACTIVITY}/${id}`);
      if (response?.success) {
        setContactDetails({
          addressLine1: response?.data?.newRecord?.contactDetails?.address1,
          addressLine2: response?.data?.newRecord?.contactDetails?.address2,
          town: response?.data?.newRecord?.contactDetails?.town,
          state: response?.data?.newRecord?.contactDetails?.state,
          zipCode: response?.data?.newRecord?.contactDetails?.zipcode,
          country: response?.data?.newRecord?.contactDetails?.country,
          mobileNumber: response?.data?.newRecord?.contactDetails?.mobileNumber,
          email: response?.data?.newRecord?.contactDetails?.email,
          county: response?.data?.newRecord?.contactDetails?.county,
        });
        setPreviousContactDetails({
          addressLine1:
            response?.data?.previousRecord?.contactDetails?.address1,
          addressLine2:
            response?.data?.previousRecord?.contactDetails?.address2,
          town: response?.data?.previousRecord?.contactDetails?.town,
          state: response?.data?.previousRecord?.contactDetails?.state,
          zipCode: response?.data?.previousRecord?.contactDetails?.zipcode,
          country: response?.data?.previousRecord?.contactDetails?.country,
          mobileNumber:
            response?.data?.previousRecord?.contactDetails?.mobileNumber,
          email: response?.data?.previousRecord?.contactDetails?.email,
          county: response?.data?.previousRecord?.contactDetails?.county,
        });
      }
    };
    getActivityEmployeeContactInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.item_container}>
          <PageBackButton paddingTop="0px" to="/admin/activity-logs" />
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              New Contact Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Address Line 1</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.addressLine1
                    ? contactDetails.addressLine1
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Address Line 2</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.addressLine2
                    ? contactDetails.addressLine2
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Town</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.town ? contactDetails.town : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">State</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.state ? contactDetails.state : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Zip Code</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.zipCode ? contactDetails.zipCode : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Country</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.country ? contactDetails.country : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Mobile Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.mobileNumber
                    ? contactDetails.mobileNumber
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Email</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {contactDetails.email ? contactDetails.email : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Previous Contact Information */}
      <div className={styles.container}>
        <div className={styles.item_container}>
          <div className={styles.item_container_text}>
            <Typography
              textColor={colors.primary3}
              textSize="24px"
              textWeight="600"
              textAlign="left"
            >
              Previous Contact Information
            </Typography>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Address Line 1</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.addressLine1
                    ? previousContactDetails.addressLine1
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Address Line 2</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.addressLine2
                    ? previousContactDetails.addressLine2
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Town</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.town
                    ? previousContactDetails.town
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">County</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.county
                    ? previousContactDetails.county
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Post Code</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.zipCode
                    ? previousContactDetails.zipCode
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Country</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.country
                    ? previousContactDetails.country
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={styles.item_container_text_flex}>
              <div>
                <Typography textAlign="left">Mobile Number</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.mobileNumber
                    ? previousContactDetails.mobileNumber
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography textAlign="left">Email</Typography>
                <Typography textAlign="left" textSize="20px" textWeight="600">
                  {previousContactDetails.email
                    ? previousContactDetails.email
                    : "-"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityEmployeeContactInformationNonUk;
