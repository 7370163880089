import React from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import colors from "../../../../theme/colors";
import pencil from "../../../../assets/svg/pencil.svg";
import gray_bin_icon from "../../../../assets/svg/gray-bin-icon.svg";
import { setGrade } from "../../../../utils/localstorage";
import { AdminGradeItemData } from "../../../../types-definition/admin";
import ColorStroke from "../../../Common/ProgressBar";
import LinkComponent from "../../../Common/LinkComponent";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateGradeTableState } from "../../../../redux/slice/admin/gradeSlice";
import { setCertificateGroupId } from "../../../../utils/localstorage";

const GradeTableItem: React.FC<AdminGradeItemData> = ({
  id,
  grade,
  description,
  color,
  certificateGroup,
  certificateGroupId,
  active,
}) => {
  const dispatch = useAppDispatch();

  const setGradeHandle = () => {
    setCertificateGroupId(certificateGroupId);
    setGrade(`${id}`);
  };
  return (
    <tr className={styles.tr}>
      <td className={`${styles.td_flex} ${styles.td}`}>
        <Typography textAlign="left" textColor={colors.primary3}>
          {grade}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray3}>
          {description}
        </Typography>
      </td>
      <td className={styles.td}>
        <ColorStroke color={color} />
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textColor={colors.gray3}>
          {certificateGroup}
        </Typography>
      </td>
      <td className={`${styles.td} ${styles.td_relative}`} tabIndex={0}>
        <div className={styles.flex_container}>
          <div
            className={styles.image_container}
            onClick={() => {
              setGradeHandle();
            }}
          >
            <LinkComponent link="/staff-hub/grade/form/">
              <img
                src={pencil}
                alt="selector-icon"
                className={styles.editIcon}
              />
            </LinkComponent>
          </div>
          <div
            onClick={() => {
              setGradeHandle();
              dispatch(updateGradeTableState({ showModal: true }));
            }}
          >
            <img
              src={gray_bin_icon}
              alt="selector-icon"
              className={styles.binIcon}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};
export default GradeTableItem;
