const key = "user";
const userType = "userType";
const currentEmployee = "employee";
// const employeeId = "employeeId";
const country = "country";
const area = "area";
const address = "address";
const operation = "operation";
const certificate = "certificate";
const location = "location";
const locationName = "locationName";
const locationAudit = "locatonAudit";
const clientContact = "client-contact";
const client = "client";
const clientName = "clientName";
const service = "service";
const grade = "grade";
const certificateGroup = "certificate-group";
const timeShiftGeneration = "time-shift-generation";
const serviceGroup = "service-group";
const settings = "settings";
const employeeId = "employeeId";
const holidayId = "holidayId";
const holidayPayRate = "holidayPayRate";
const employeeUserName = "employeeUserName";
const locationdetails = "locationdetails";
const isUserIdle = "isUserIdle";
const isLogoutIdle = "isLogoutIdle";
const webUser = "webUser";
const currentLocation = "currentLocation";
const companyName = "companyName";
const activityBasicInformation = "activityBasicInformation";
const checkpointSection = "checkpointSection";
const checkpointData = "checkpointData";
const reloadCount = "reloadCount";
const filterActivityLogs = "filterActivityLogs";
const wizardStep = "wizardStep";
const setUpcompleted = "setUpcompleted";
const liveLocationdata = "liveLocationdata";
const holidayMthod = "holidayMthod";
const accrualId = "accrualId";
const manualAdjustment = "manualAdjustment";
const allCheckcallLog = "allCheckcallLog";
const checkcallId = "checkcallId";
const liveLocationId = "liveLocationId";
const showPasscode = "showPasscode";
const initialEmployeeAdd = "inititalEmployeeAdd";
const checkpointReportData = "checkpointReportData";
const socialProvider = "socialProvider";
const paystackId = "paystackId";

export const saveData = (token: string) => {
  const savedItem = localStorage.setItem(key, token);
  return savedItem;
};

export const getData = () => {
  const item = localStorage.getItem(key);
  return item;
};

export const deleteData = () => {
  setShowPasscode(true);
  const savedItem = localStorage.removeItem(key);
  return savedItem;
};

export const saveUserTypes = (data: string) => {
  const savedItem = localStorage.setItem(userType, data);
  return savedItem;
};

export const getUserTypes = () => {
  const item = localStorage.getItem(userType);
  return item;
};

export const deleteUserTypes = () => {
  const savedItem = localStorage.removeItem(userType);
  return savedItem;
};

export const getCurrentEmployee = () => {
  const item = localStorage.getItem(currentEmployee);
  return item;
};

export const setCurrentEmployee = (data: string) => {
  const savedItem = localStorage.setItem(currentEmployee, data);
  return savedItem;
};

export const deleteCurrentEmployee = () => {
  const savedItem = localStorage.removeItem(currentEmployee);
  return savedItem;
};

export const getEmployeeId = () => {
  const item = localStorage.getItem(employeeId);
  return item;
};

export const setEmployeeId = (data: string) => {
  const savedItem = localStorage.setItem(employeeId, data);
  return savedItem;
};

export const deleteEmployeeId = () => {
  const savedItem = localStorage.removeItem(employeeId);
  return savedItem;
};

export const getCountry = () => {
  const item = localStorage.getItem(country);
  return item;
};

export const setCountry = (data: string) => {
  const savedItem = localStorage.setItem(country, data);
  return savedItem;
};

export const deleteCountry = () => {
  const savedItem = localStorage.removeItem(country);
  return savedItem;
};

export const getArea = () => {
  const item = localStorage.getItem(area);
  return item;
};

export const setArea = (data: string) => {
  const savedItem = localStorage.setItem(area, data);
  return savedItem;
};

export const getGrade = () => {
  const item = localStorage.getItem(grade);
  return item;
};

export const setGrade = (data: string) => {
  const savedItem = localStorage.setItem(grade, data);
  return savedItem;
};

export const deleteGrade = () => {
  const savedItem = localStorage.removeItem(grade);
  return savedItem;
};
export const getCertificateGroupId = () => {
  const item = localStorage.getItem(grade);
  return item;
};

export const setCertificateGroupId = (data: string) => {
  const savedItem = localStorage.setItem(grade, data);
  return savedItem;
};

export const deleteCertificateGroupId = () => {
  const savedItem = localStorage.removeItem(grade);
  return savedItem;
};

export const deleteArea = () => {
  const savedItem = localStorage.removeItem(area);
  return savedItem;
};

export const getAddress = () => {
  const item = localStorage.getItem(address);
  return item;
};

export const setAddress = (data: string) => {
  if (data) {
    const savedItem = localStorage.setItem(address, data);
    return savedItem;
  }
};

export const deleteAdress = () => {
  const savedItem = localStorage.removeItem(address);
  return savedItem;
};

export const getCertificate = () => {
  const item = localStorage.getItem(certificate);
  return item;
};

export const setCertificate = (data: string) => {
  if (data) {
    const savedItem = localStorage.setItem(certificate, data);
    return savedItem;
  }
};

export const deleteCertificate = () => {
  const savedItem = localStorage.removeItem(certificate);
  return savedItem;
};

export const getOperation = () => {
  const item = localStorage.getItem(operation);
  return item;
};

export const setOperation = (data: string) => {
  if (data) {
    const savedItem = localStorage.setItem(operation, data);
    return savedItem;
  }
};

export const deleteOperation = () => {
  const savedItem = localStorage.removeItem(operation);
  return savedItem;
};

export const getLocation = () => {
  const item = localStorage.getItem(location);
  return item;
};

export const setLocation = (data: string) => {
  const savedItem = localStorage.setItem(location, data);
  return savedItem;
};

export const deleteLocation = () => {
  const savedItem = localStorage.removeItem(location);
  return savedItem;
};

export const getLocationName = () => {
  const item = localStorage.getItem(locationName);
  return item;
};

export const setLocationName = (data: string) => {
  const savedItem = localStorage.setItem(locationName, data);
  return savedItem;
};

export const deleteLocationName = () => {
  const savedItem = localStorage.removeItem(locationName);
  return savedItem;
};

export const getLocationAudit = () => {
  const item = localStorage.getItem(locationAudit);
  return item;
};

export const setLocationAudit = (data: string) => {
  const savedItem = localStorage.setItem(locationAudit, data);
  return savedItem;
};

export const deleteLocationAudit = () => {
  const savedItem = localStorage.removeItem(locationAudit);
  return savedItem;
};

export const getClientcontact = () => {
  const item = localStorage.getItem(clientContact);
  return item;
};

export const setClientContact = (data: string) => {
  if (data) {
    const savedItem = localStorage.setItem(clientContact, data);
    return savedItem;
  }
};

export const deleteClientContact = () => {
  const savedItem = localStorage.removeItem(clientContact);
  return savedItem;
};

export const deleteSiteLocation = () => {
  deleteAdress();
  deleteCertificate();
  deleteClientContact();
  deleteLocation();
  deleteLocationName();
  deleteOperation();
};

export const getClient = () => {
  const item = localStorage.getItem(client);
  return item;
};

export const setClient = (data: string) => {
  if (data) {
    const savedItem = localStorage.setItem(client, data);
    return savedItem;
  }
};

export const deleteClient = () => {
  const savedItem = localStorage.removeItem(client);
  return savedItem;
};

export const getClientName = () => {
  const item = localStorage.getItem(clientName);
  return item;
};

export const setClientName = (data: string) => {
  if (data) {
    const savedItem = localStorage.setItem(clientName, data);
    return savedItem;
  }
};

export const deleteClientName = () => {
  const savedItem = localStorage.removeItem(clientName);
  return savedItem;
};

export const getService = () => {
  const item = localStorage.getItem(service);
  return item;
};

export const setService = (data: string) => {
  if (data) {
    const savedItem = localStorage.setItem(service, data);
    return savedItem;
  }
};

export const deleteService = () => {
  const savedItem = localStorage.removeItem(service);
  return savedItem;
};

export const getCertificateGroup = () => {
  const item = localStorage.getItem(certificateGroup);
  return item;
};

export const setCertificateGroup = (data: string) => {
  if (data) {
    const savedItem = localStorage.setItem(certificateGroup, data);
    return savedItem;
  }
};

export const deleteCertificateGroup = () => {
  const savedItem = localStorage.removeItem(certificateGroup);
  return savedItem;
};

export const setTimeShiftGeneration = (data: string) => {
  if (data) {
    const savedItem = localStorage.setItem(timeShiftGeneration, data);
    return savedItem;
  }
};
export const getTimeShiftGeneration = () => {
  const item = localStorage.getItem(timeShiftGeneration);
  return item;
};

export const removeTimeShiftGeneration = () => {
  const item = localStorage.removeItem(timeShiftGeneration);
  return item;
};

export const setServiceGroup = (data: string) => {
  if (data) {
    const savedItem = localStorage.setItem(serviceGroup, data);
    return savedItem;
  }
};
export const getServiceGroup = () => {
  const item = localStorage.getItem(serviceGroup);
  return item;
};

export const deleteServiceGroup = () => {
  const item = localStorage.removeItem(serviceGroup);
  return item;
};

export const setSettings = (data: string) => {
  if (data) {
    const savedItem = localStorage.setItem(settings, data);
    return savedItem;
  }
};
export const getSettings = () => {
  const item = localStorage.getItem(settings);
  return item;
};

export const deleteSettings = () => {
  const item = localStorage.removeItem(settings);
  return item;
};

export const setHoliday = (data: string) => {
  const savedItem = localStorage.setItem(holidayId, data);
  return savedItem;
};

export const getHoliday = () => {
  const item = localStorage.getItem(holidayId);
  return item;
};

export const deleteHoliday = () => {
  const item = localStorage.removeItem(holidayId);
  return item;
};

export const setHolidayPayRate = (data: string) => {
  const savedItem = localStorage.setItem(holidayPayRate, data);
  return savedItem;
};

export const getHolidayPayRate = () => {
  const item = localStorage.getItem(holidayPayRate);
  return item;
};

export const deleteHolidayPayRate = () => {
  const item = localStorage.removeItem(holidayPayRate);
  return Number(item);
};

export const setEmployeeUserName = (data: string) => {
  const savedItem = localStorage.setItem(employeeUserName, data);
  return savedItem;
};

export const getEmployeeUserName = () => {
  const item = localStorage.getItem(employeeUserName);
  return item;
};

export const deleteEmployeeUserName = () => {
  const item = localStorage.removeItem(employeeUserName);
  return Number(item);
};

export const setLocationDetails = (data: string) => {
  const item = localStorage.setItem(locationdetails, data);
  return item;
};

export const getLocationDetails = () => {
  const item = localStorage.getItem(locationdetails);
  return item;
};

export const deleteLocationDetails = () => {
  const item = localStorage.removeItem(locationdetails);
  return item;
};

export const setUserIdle = (data: string) => {
  const item = localStorage.setItem(isUserIdle, data);
  return item;
};

export const getUserIdle = () => {
  const item = localStorage.getItem(isUserIdle);
  return item;
};

export const deleteUserIdle = () => {
  const item = localStorage.removeItem(isUserIdle);
  return item;
};

export const setWebUser = (data: string) => {
  const item = localStorage.setItem(webUser, data);
  return item;
};

export const getWebUser = () => {
  const item = localStorage.getItem(webUser);
  return item;
};

export const deleteWebUser = () => {
  const item = localStorage.removeItem(webUser);
  return item;
};

export const setCurrentLocation = (data: string) => {
  const item = localStorage.setItem(currentLocation, data);
  return item;
};

export const getCurrentLocation = () => {
  const item = localStorage.getItem(currentLocation);
  return item;
};

export const deleteCurrentLocation = () => {
  const item = localStorage.removeItem(currentLocation);
  return item;
};

export const setCompanyName = (data: string) => {
  const item = localStorage.setItem(companyName, data);
  return item;
};

export const getCompanyName = () => {
  const item = localStorage.getItem(companyName);
  return item;
};

export const setLogoutIdle = (data: string) => {
  const item = localStorage.setItem(isLogoutIdle, data);
  return item;
};

export const getLogoutIdle = () => {
  const item = localStorage.getItem(isLogoutIdle);
  return item;
};

export const deleteLogoutIdle = () => {
  const item = localStorage.removeItem(isLogoutIdle);
  return item;
};

export const setActivityBasicInformation = (data: string) => {
  const item = localStorage.setItem(activityBasicInformation, data);
  return item;
};

export const getActivityBasicInformation = () => {
  const item = localStorage.getItem(activityBasicInformation);
  return item;
};

export const deleteActivityBasicInformation = () => {
  const item = localStorage.removeItem(activityBasicInformation);
  return item;
};

export const setCheckpointSection = (data: string) => {
  const item = localStorage.setItem(checkpointSection, data);
  return item;
};

export const getCheckpointSection = () => {
  const item = localStorage.getItem(checkpointSection);
  return item;
};

export const deleteCheckpointSection = () => {
  const item = localStorage.removeItem(checkpointSection);
  return item;
};

export const setCheckpointData = (data: string) => {
  const item = localStorage.setItem(checkpointData, data);
  return item;
};

export const getCheckpointData = () => {
  const item = localStorage.getItem(checkpointData);
  return item;
};

export const deleteCheckpointData = () => {
  const item = localStorage.removeItem(checkpointData);
  return item;
};

export const setReloadCount = () => {
  const storedItem = localStorage.getItem(reloadCount);
  const item = localStorage.setItem(
    reloadCount,
    storedItem ? (Number(storedItem) + 1).toString() : (1).toString(),
  );
  return item;
};

export const getReloadCount = () => {
  const item = localStorage.getItem(reloadCount);
  return item;
};

export const setReloadCountToZero = () => {
  const item = localStorage.setItem(reloadCount, "0");
  return item;
};

export const deleteReloadCount = () => {
  const item = localStorage.removeItem(reloadCount);
  return item;
};

export const setFileterActivityLogsByDate = (data: string) => {
  const item = localStorage.setItem(filterActivityLogs, data);
  return item;
};

export const getFileterActivityLogsByDate = () => {
  const item = localStorage.getItem(filterActivityLogs);
  return item;
};

export const deleteFileterActivityLogsByDate = () => {
  const item = localStorage.removeItem(filterActivityLogs);
  return item;
};

export const setWizardStep = (data: string) => {
  const item = localStorage.setItem(wizardStep, data);
  return item;
};

export const getWizardStep = () => {
  const item = localStorage.getItem(wizardStep);
  if (item) {
    return parseFloat(item);
  }
};

export const setSetupComplete = (data: string) => {
  const item = localStorage.setItem(setUpcompleted, data);
  return item;
};

export const getSetupComplete = () => {
  const item = localStorage.getItem(setUpcompleted);
  if (item) {
    return item;
  }
};
export const deleteSetupComplete = () => {
  const item = localStorage.removeItem(setUpcompleted);
  return item;
};
export const setHolidayMethod = (data: string) => {
  const item = localStorage.setItem(holidayMthod, data);
  return item;
};

export const getHolidayMethod = () => {
  const item = localStorage.getItem(holidayMthod);
  if (item) {
    return item;
  }
};
export const deleteHolidayMethod = () => {
  const item = localStorage.removeItem(holidayMthod);
  return item;
};

export const setLiveLocationData = (data: string) => {
  const item = localStorage.setItem(liveLocationdata, data);
  return item;
};

export const getLiveLocationData = () => {
  const item = localStorage.getItem(liveLocationdata);
  if (item) {
    return item;
  }
};
export const deleteLiveLocationData = () => {
  const item = localStorage.removeItem(liveLocationdata);
  return item;
};
export const setAccrualId = (data: string) => {
  const item = localStorage.setItem(accrualId, data);
  return item;
};

export const getAccrualId = () => {
  const item = localStorage.getItem(accrualId);
  if (item) {
    return item;
  }
};
export const setManualAdjustment = (data: string) => {
  const item = localStorage.setItem(manualAdjustment, data);
  return item;
};

export const getManualAdjustmentId = () => {
  const item = localStorage.getItem(manualAdjustment);
  if (item) {
    return item;
  }
};

export const deleteManualAdjustmentId = () => {
  const item = localStorage.removeItem(manualAdjustment);
  return item;
};

export const getAllCheckcallLog = () => {
  const item = localStorage.getItem(allCheckcallLog);
  if (item) {
    return item;
  }
};

export const setAllCheckcallLog = (data: any) => {
  const item = localStorage.setItem(allCheckcallLog, data);
  return item;
};

export const getCheckcallId = () => {
  const item = localStorage.getItem(checkcallId);
  return item;
};

export const setCheckcallId = (data: string) => {
  const savedItem = localStorage.setItem(checkcallId, data);
  return savedItem;
};

export const getLiveLocationId = () => {
  const item = localStorage.getItem(liveLocationId);
  if (item) {
    return item;
  }
};

export const setLiveLocationId = (data: any) => {
  const item = localStorage.setItem(liveLocationId, data);
  return item;
};

export const getShowPasscode = () => {
  const item = localStorage.getItem(showPasscode);
  if (item) {
    return item;
  }
};

export const setShowPasscode = (data: any) => {
  const item = localStorage.setItem(showPasscode, data);
  return item;
};

export const getInitialEmployeeAdd = () => {
  const item = localStorage.getItem(initialEmployeeAdd);
  if (item) {
    return item;
  }
};

export const setInititalEmployeeAdd = (data: any) => {
  const item = localStorage.setItem(initialEmployeeAdd, data);
  return item;
};

export const deleteInititalEmployeeAdd = () => {
  const item = localStorage.removeItem(initialEmployeeAdd);
  return item;
};

export const setCheckpointReportData = (data: string) => {
  const item = localStorage.setItem(checkpointReportData, data);
  return item;
};

export const getCheckpointReportData = () => {
  const item = localStorage.getItem(checkpointReportData);
  return item;
};

export const deleteCheckpointReportData = () => {
  const item = localStorage.removeItem(checkpointReportData);
  return item;
};

export const createSocialProvider = (data: string) => {
  const item = localStorage.setItem(socialProvider, data);
  return item;
};

export const getSocialProvider = () => {
  const item = localStorage.getItem(socialProvider);
  return item;
};

export const deleteSocialProvider = () => {
  localStorage.removeItem(socialProvider);
  return;
};

export const createPaystackId = (data: string) => {
  const item = localStorage.setItem(paystackId, data);
  return item;
};

export const getPaystackId = () => {
  const item = localStorage.getItem(paystackId);
  return item;
};

export const deleteSelectedAutoLogoutData = () => {
  deleteData();
  deleteEmployeeId();
  deleteAdress();
  deleteArea();
  deleteCertificate();
  deleteCertificateGroup();
  deleteCertificateGroupId();
  deleteClient();
  deleteGrade();
  deleteClientContact();
  deleteHoliday();
  deleteHolidayPayRate();
  deleteLocation();
  deleteLocationDetails();
  deleteOperation();
  deleteService();
  deleteSettings();
  deleteEmployeeUserName();
  deleteCountry();
  deleteCurrentEmployee();
  deleteServiceGroup();
  deleteSocialProvider();
  return;
};

export const logoutUser = () => {
  localStorage.clear();
  setShowPasscode(true);
  return;
};
