import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import Button from "components/Form/Button";
import addIcon from "assets/svg/add-icon.svg";
import { getRequest } from "api/request";
import PageBackButton from "components/Common/PageBackButton";
import { GET_ALL_EMPLOYEE_URL, GET_USER_SETTINGS_URL } from "api/urls";
import PageLoader from "../../.././../components/Common/PageLoader";
import { getCurrentEmployee, getHolidayMethod } from "utils/localstorage";
import toast from "react-hot-toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { EmployeeAccrualTrackingData } from "types-definition/StaffHub/employee";
import AddManualAdjustment from "components/StaffHub/Employee/EmployeeAcrrual/AddManualAdjustmentModal";
import {
  toggleAccrualModal,
  showAccrualModalState,
  setShowDeleteManualAdjustmentState,
} from "../../../../redux/slice/staff-hub/employeeAccrualSettingSlice";
import { EmployeeAccuralCurrentYearAcomplishmentProps } from "types-definition/StaffHub/employee";
import { getEmployeeId } from "utils/localstorage";
import { sumPropertyInAnArray } from "utils/calculateAccrual";
import ManualAccrualAdjustmentTable from "components/StaffHub/Employee/EmployeeAcrrual/ManualAdjustmentTable";
import DeleteManualAdjustmentModal from "components/StaffHub/Employee/EmployeeAcrrual/DeleteManualAccrualAdjustment";
import AccrualTrackingFilter from "components/StaffHub/Employee/EmployeeAcrrual/AccrualFIlter";

const SetAccrualManualAdjustment = () => {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector(showAccrualModalState);
  const showDeleteModal = useAppSelector(setShowDeleteManualAdjustmentState);
  const [requestLoading, setRequestLoading] = useState(true);
  const [, setEmployeeId] = useState("");
  const [holidayMethod, setHolidayMethod] = useState("");
  const [, setEntitlement] = useState("");
  const [filterField, setFilterField] = useState("");
  const [, setAccruadHour] = useState("0");
  const [sortField, setSortFiled] = useState({ name: "", data: "" });
  const [employeeDetails, setEmployeeDetails] = useState<any>({});
  const [manualAdjustmentData, setManualAdjustmentData] = useState<
    Array<EmployeeAccrualTrackingData>
  >([]);
  const [filteredManualAdjustmentData, setFilteredManualAdjustmentData] =
    useState<Array<EmployeeAccrualTrackingData>>([]);
  const [, setEmployeeAccuralCurrentYearAcomplishmentData] =
    useState<EmployeeAccuralCurrentYearAcomplishmentProps>({
      entitled: "",
      available: "",
      taken: "0",
      date: "",
      holidayType: "",
    });

  const [showSuccess, setShowSuccess] = useState(false);

  const getAllEmployeeManualAdjustMent = async () => {
    const id = getEmployeeId();
    if (id) {
      setEmployeeId(id);
    }
    try {
      const response = await getRequest(
        `${GET_ALL_EMPLOYEE_URL}/${id}/manual-adjustment`,
      );
      if (response?.success) {
        let accrualTrackingData: Array<EmployeeAccrualTrackingData> = [];
        response.data.length > 0 &&
          setAccruadHour(sumPropertyInAnArray(response.data, "accruedDays"));
        response.data.forEach((item: any) => {
          const accrualTrackingItem = {
            id: item.id,
            dateCreated: item.createdAt,
            accrualChange: item.accrualCharge,
            accruedDays: "",
            reason: item.reason,
            dateApplied: item.dateApplied,
            applicationStatus:
              item.status === "NOT_APPLIED" ? "Not Applied" : "Applied",
            expirationDate: item.expirationDate ? item.expirationDate : "null",
            createdBy: item.role === "admin" ? "Admin" : item.role,
          };
          accrualTrackingData.push(accrualTrackingItem);
        });
        setManualAdjustmentData(accrualTrackingData);
        setFilteredManualAdjustmentData(accrualTrackingData);
      } else {
        if (response?.data)
          toast.error(response?.data, {
            duration: 4000,
            position: "top-center",
          });
      }
    } finally {
      setRequestLoading(false);
    }
  };

  const getSettings = async () => {
    try {
      const holidayMethod = getHolidayMethod();
      if (holidayMethod) {
        setHolidayMethod(holidayMethod);
        const settingRes = await getRequest(GET_USER_SETTINGS_URL);
        if (settingRes.success) {
          setEmployeeAccuralCurrentYearAcomplishmentData({
            entitled: settingRes.data.holidaySetting.holidayEntitlement,
            available: "0",
            taken: "0",
            date: "",
            holidayType: holidayMethod,
          });
        }
        setEntitlement(settingRes.data.holidaySetting.holidayEntitlement);
      }
    } catch (error) {}
  };
  const getEmployeeHoliday = async () => {
    const empoloyeeId = getEmployeeId();
    try {
      setHolidayMethod(holidayMethod);
      const response = await getRequest(
        `${GET_ALL_EMPLOYEE_URL}/${empoloyeeId}/holiday`,
      );
      if (response.data) {
        const apprvedHoliday = response.data.filter(
          (hol: any) => hol.status === "approved",
        );
        const holidayTaken = apprvedHoliday.length;
        setEmployeeAccuralCurrentYearAcomplishmentData({
          entitled: "",
          available: "",
          taken: holidayTaken,
          date: "",
          holidayType: "",
        });
      }
    } catch (error) {}
  };

  const getEmployeeInfo = () => {
    const checkForEmployee = getCurrentEmployee();
    if (checkForEmployee) {
      setEmployeeDetails({
        userName: JSON.parse(checkForEmployee).name,
        displayName: JSON.parse(checkForEmployee).displayName,
        employeeId: JSON.parse(checkForEmployee).employeeId,
      });
    }
  };

  const handleFilteringByString = () => {
    setFilteredManualAdjustmentData(
      manualAdjustmentData.filter(
        (item) =>
          item.reason.toLowerCase().includes(filterField.toLowerCase()) ||
          item.applicationStatus
            .toLowerCase()
            .includes(filterField.toLowerCase()),
      ),
    );
  };

  useEffect(() => {
    const sortAccrualData = () => {
      let sortedArray: EmployeeAccrualTrackingData[] = [];
      if (sortField.name === "Expiration_Date") {
        if (sortField.data === "Ascending Order") {
          sortedArray = manualAdjustmentData.sort((a: any, b: any) => {
            return (
              new Date(a.expirationDate).getTime() -
              new Date(b.expirationDate).getTime()
            );
          });
        } else {
          sortedArray = manualAdjustmentData.sort((a: any, b: any) => {
            return (
              new Date(b.expirationDate).getTime() -
              new Date(a.expirationDate).getTime()
            );
          });
        }
      } else if (sortField.name === "Date_applied") {
        if (sortField.data === "Ascending Order") {
          sortedArray = manualAdjustmentData.sort((a: any, b: any) => {
            return (
              new Date(a.expirationDate).getTime() -
              new Date(b.expirationDate).getTime()
            );
          });
        } else {
          sortedArray = manualAdjustmentData.sort((a: any, b: any) => {
            return (
              new Date(b.expirationDate).getTime() -
              new Date(a.expirationDate).getTime()
            );
          });
        }
      } else if (sortField.name === "Date_Created") {
        if (sortField.data === "Ascending Order") {
          sortedArray = manualAdjustmentData.sort((a: any, b: any) => {
            return (
              new Date(a.expirationDate).getTime() -
              new Date(b.expirationDate).getTime()
            );
          });
        } else {
          sortedArray = manualAdjustmentData.sort((a: any, b: any) => {
            return (
              new Date(b.expirationDate).getTime() -
              new Date(a.expirationDate).getTime()
            );
          });
        }
      } else if (sortField.name === "Accrual_Charge") {
        if (sortField.data === "Ascending Order") {
          sortedArray = manualAdjustmentData.sort((a: any, b: any) => {
            return parseInt(a.accrualChange) - parseInt(b.accrualChange);
          });
        } else {
          sortedArray = manualAdjustmentData.sort((a: any, b: any) => {
            return parseInt(b.accrualChange) - parseInt(a.accrualChange);
          });
        }
      } else if (sortField.name === "Application_status") {
        if (sortField.data === "Not Applied") {
          sortedArray = manualAdjustmentData.filter(
            (item: any) => item.applicationStatus === "Not Applied",
          );
        } else {
          sortedArray = manualAdjustmentData.filter(
            (item: any) => item.applicationStatus === "Applied",
          );
        }
      } else {
      }
      setFilteredManualAdjustmentData(sortedArray);
    };

    sortAccrualData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField.data, sortField.name]);

  useEffect(() => {
    getEmployeeInfo();
    getEmployeeHoliday();
    getAllEmployeeManualAdjustMent();
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestLoading, showDeleteModal, showModal]);
  useEffect(() => {
    let timeout: any;
    if (showSuccess) {
      timeout = setTimeout(() => setShowSuccess(false), 5000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [showSuccess]);

  return (
    <>
      <ScrollToTop />
      {requestLoading ? (
        <PageLoader />
      ) : (
        <section>
          <DashboardHeader active="staff/hub" />
          <div className={styles.container}>
            <div
              className={`${styles.item_container} ${styles.item_container_fill}`}
            >
              <PageBackButton paddingTop="20px" width="90%" />
              <div className={styles.flex_item}>
                <div>
                  <Typography textAlign="left" textWeight="900" textSize="20px">
                    Accrual Tracking - {employeeDetails.displayName}
                  </Typography>
                </div>
                <div className={styles.sub_flex_item}>
                  <div className={styles.table_filter}>
                    <AccrualTrackingFilter
                      clearFilter={() => {}}
                      runFilter={(data, name) => {
                        setSortFiled({ name, data });
                      }}
                    />
                  </div>
                  <div className={styles.sub_sub_flex_item}>
                    <TextInput
                      placeholder="Search"
                      value={filterField}
                      setValue={(data) => {
                        setFilterField(data);
                        handleFilteringByString();
                      }}
                    />
                    <Button
                      left={<img src={addIcon} alt="add" />}
                      marginTop="8px"
                      paddingVertical="7px"
                      width="270px"
                      isPrimary
                      text="Add Manual Adjustment"
                      onClick={() => {
                        dispatch(toggleAccrualModal({ showModal: true }));
                      }}
                    />
                  </div>
                </div>
              </div>
              {manualAdjustmentData?.length < 1 ? (
                <div></div>
              ) : (
                <ManualAccrualAdjustmentTable
                  data={filteredManualAdjustmentData}
                />
              )}
            </div>
          </div>
        </section>
      )}
      {showDeleteModal && <DeleteManualAdjustmentModal />}

      {showModal && <AddManualAdjustment />}
    </>
  );
};

export default SetAccrualManualAdjustment;
