import { getRequest } from "api/request";
import { GET_SERVICE_URL } from "api/urls";
import searchIcon from "assets/svg/search-icon.svg";
import DashboardHeader from "components/Common/DashboardHeader";
import PageLoader from "components/Common/PageLoader";
import PaginatedItems from "components/Common/Pagination";
import ScrollToTop from "components/Common/ScrollToTop";
import Typography from "components/Common/Typography";
import TextInput from "components/Form/TextInput";
import CheckcallLogFilterOptions from "components/Report/CheckcallReportLog/CheckcallLogFilterOptions";
import CheckcallReportTable from "components/Report/CheckcallReportLog/CheckcallReportTable";
import ExportCheckcallLogs from "components/Report/CheckcallReportLog/ExportCheckcallLogs";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getCheckcallLogData,
  setActiveCheckcallGroupIds,
  setCheckcallLogData,
} from "redux/slice/report/checkcallLogSlice";
import colors from "theme/colors";
import { ServiceData } from "types-definition/client";
import { CheckcallReportData } from "types-definition/report";
import { setAllCheckcallLog } from "utils/localstorage";
import styles from "./index.module.css";
import groupBy from "utils/groupBy";

const CheckcallReportDashboard = () => {
  const [searchedValues, setSearchedValues] = useState("");
  const [requestLoading, setRequestLoading] = useState(true);
  const [serviceData, setServiceData] = useState<any>([]);
  const [filteredCheckcallLogData, setFilteredCheckcallLogData] = useState<
    CheckcallReportData[]
  >([]);
  const initialFilter = {
    startDate: "",
    endDate: "",
    service: "",
    checkcallStatus: "",
    responseStatus: "",
    verificationMethod: "",
  };

  const [filter, setFilter] = useState(initialFilter);
  const checkcallLogData = useAppSelector(getCheckcallLogData);
  const dispatch = useAppDispatch();

  const [pagination, setPagination] = useState<any>({
    start: 0,
    end: 10,
    event: 1,
  });
  const handlePaginate = (start: number, end: number, event?: any) => {
    setPagination({
      start,
      end,
      event,
    });
  };

  useEffect(() => {
    setRequestLoading(true);

    console.time("performance");

    const getAllService = async () => {
      try {
        const response = await getRequest(GET_SERVICE_URL);
        if (response?.success) {
          const sortedData = response?.data?.sort();

          const serviceDataArray: Array<ServiceData> = [];
          for (const index in sortedData) {
            // extract area table data
            const serviceDataObject: ServiceData = {
              id: response.data[index].id,
              name: response.data[index].name,
              grade: response.data[index].grade,
              from: response.data[index].from,
              to: response.data[index].to,
              shiftStart: response.data[index].shiftStart,
              shiftEnd: response.data[index].shiftEnd,
              workHours: response.data[index].totalHoursInWeek,
              noOfEmployees: response.data[index].noOfEmployeeRequired,
              invoiceInInfoId: response.data[index].invoiceInfo.id,
              payInfoId: response.data[index].payInfo.id,
            };
            serviceDataArray.push(serviceDataObject);
          }

          return serviceDataArray;
        } else {
          if (response?.data)
            toast.error(response?.data, {
              duration: 4000,
              position: "top-center",
            });

          return [];
        }
      } finally {
      }
    };

    const getServiceCheckcall = async () => {
      const services = await getAllService();
      setServiceData(services);

      const response = await Promise.all(
        services.map(
          async (item: any) =>
            await getRequest(`service/${item.id}/check-call/response`),
        ),
      );

      let mappedResponse = response
        ?.flatMap((res) => (res?.success ? res?.data : []))
        ?.filter(
          (item) =>
            ["MISSED", "ANSWERED"].includes(item?.status) &&
            Object.entries(item?.employee).length > 0 &&
            Object.entries(item?.location).length > 0,
        )
        ?.map((item: any) => ({
          id: item?.id,
          attempt: item?.attempt,
          employee: {
            id: item?.employee?.id,
            name: `${item?.employee?.firstName} ${item?.employee?.lastName}`,
          },
          location: {
            id: item?.location?.id,
            name: item?.location?.name,
            managedBy: JSON.parse(item?.location?.managedBy || "")?.name,
          },
          service: {
            id: item?.service?.id,
            name: item?.service?.name,
          },
          question: item?.question,
          answer: item?.answer,
          details: item?.details,
          date: item?.createdAt,
          status: item?.status,
        }))
        .sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
        ); // ASC ORDER: DATE A < DATE B

      setRequestLoading(false);
      dispatch(setCheckcallLogData(mappedResponse));
      setAllCheckcallLog(JSON.stringify(mappedResponse));

      console.timeEnd("performance");
    };

    getServiceCheckcall();
  }, [dispatch]);

  const applyFilter = (filterArgs = filter) => {
    let filteredData = checkcallLogData;

    if (searchedValues) {
      filteredData = filteredData.filter(
        (item) =>
          item?.employee?.name?.toLowerCase().search(searchedValues) !== -1 ||
          item?.location?.name?.toLowerCase().search(searchedValues) !== -1 ||
          item?.location?.managedBy?.toLowerCase().search(searchedValues) !==
            -1,
      );
    }

    if (filterArgs.startDate) {
      filteredData = filteredData.filter((item) => {
        return moment(item.date).isSameOrAfter(
          moment(filterArgs.startDate).startOf("day"),
        );
      });
    }

    if (filterArgs.endDate) {
      filteredData = filteredData.filter((item) => {
        return moment(item.date).isSameOrBefore(
          moment(filterArgs.endDate).endOf("day"),
        );
      });
    }

    if (filterArgs.service) {
      filteredData = filteredData.filter(
        (item) => item.service.name === filterArgs.service,
      );
    }

    if (filterArgs.checkcallStatus) {
      let answer = filterArgs.checkcallStatus === "accepted" ? "yes" : "no";
      filteredData = filteredData.filter(
        (item) => item.answer?.toLowerCase() === answer,
      );
    }

    if (filterArgs.responseStatus) {
      let answer = filterArgs.responseStatus === "ok" ? "yes" : "no";
      filteredData = filteredData.filter(
        (item) => item.answer?.toLowerCase() === answer,
      );
    }

    if (filterArgs.verificationMethod) {
      filteredData = filteredData.filter(
        (item) => item.answer?.toLowerCase() === filterArgs.verificationMethod,
      );
    }

    dispatch(setActiveCheckcallGroupIds([]));
    setFilteredCheckcallLogData(filteredData);
    setPagination({ start: 0, end: 10, event: 1 });
  };

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkcallLogData, searchedValues]);

  return (
    <>
      <ScrollToTop />
      {requestLoading && <PageLoader />}
      <section>
        <DashboardHeader active="reports" />
        <div className={styles.container}>
          <div
            className={
              checkcallLogData?.length! <= 5
                ? `${styles.item_container} ${styles.item_container_empty}`
                : `${styles.item_container} ${styles.item_container_fill}`
            }
          >
            <div className={styles.flex_item}>
              <div>
                <Typography textAlign="left" textWeight="600" textSize="20px">
                  Checkcall Report Log
                </Typography>
                <Typography textAlign="left" textColor={colors.gray1}>
                  View log of all checkcalls performed within the system
                </Typography>
              </div>
              <div className={styles.sub_flex_item}>
                <CheckcallLogFilterOptions
                  services={serviceData}
                  filter={filter}
                  setFilter={setFilter}
                  applyFilter={applyFilter}
                />
                <TextInput
                  left={<img src={searchIcon} alt="search" />}
                  placeholder="Search"
                  value={searchedValues}
                  setValue={(value) => {
                    setSearchedValues(value);
                  }}
                />
                <ExportCheckcallLogs />
              </div>
            </div>
            {filteredCheckcallLogData?.length === 0 ? (
              <div className={styles.empty_container}>
                <Typography marginVertical="5rem" paddingVertical="20px">
                  No checkcall report found
                </Typography>
              </div>
            ) : (
              <>
                <CheckcallReportTable
                  data={
                    Object.entries(
                      groupBy(
                        filteredCheckcallLogData,
                        (checkcall) =>
                          `${checkcall?.employee?.id}||${moment(
                            checkcall?.date,
                          ).format("DD/MM/YYYY")}`,
                      ),
                    )?.slice(pagination.start, pagination.end)!
                  }
                />

                <div className={styles.pagination_container}>
                  <PaginatedItems
                    items={
                      Object.entries(
                        groupBy(
                          filteredCheckcallLogData,
                          (checkcall) =>
                            `${checkcall?.employee?.id}||${moment(
                              checkcall?.date,
                            ).format("DD/MM/YYYY")}`,
                        ),
                      )!
                    }
                    itemsPerPage={10}
                    handlePaginate={handlePaginate}
                    checkFirstPage={pagination.event}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckcallReportDashboard;
