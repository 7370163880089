import React from "react";
import Typography from "../../../Common/Typography";
import styles from "./index.module.css";
import { SiteLocationNavigationState } from "../../../../types-definition/client";
import colors from "../../../../theme/colors";
import { useNavigate } from "react-router-dom";

const SiteLocationNavigation: React.FC<SiteLocationNavigationState> = ({
  active,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div
        className={styles.container_item_left}
        onClick={() => navigate("/client/general")}
      >
        <Typography
          textWeight={active === "general" ? "600" : "500"}
          fontFamily="inter"
          textColor={active === "general" ? colors.primary3 : colors.black}
        >
          General
        </Typography>
      </div>
      <div
        className={styles.container_item}
        onClick={() => navigate("/client/address")}
      >
        <Typography
          textWeight={active === "address" ? "600" : "500"}
          fontFamily="inter"
          textColor={active === "address" ? colors.primary3 : colors.black}
        >
          Address
        </Typography>
      </div>
      <div
        className={styles.container_item}
        onClick={() => navigate("/client/operation")}
      >
        <Typography
          textWeight={active === "operation" ? "600" : "500"}
          fontFamily="inter"
          textColor={active === "operation" ? colors.primary3 : colors.black}
        >
          Operation
        </Typography>
      </div>
      <div
        className={styles.container_item_right}
        onClick={() => navigate("/client/certificates")}
      >
        <Typography
          textWeight={active === "required certificate" ? "600" : "500"}
          fontFamily="inter"
          textColor={
            active === "required certificate" ? colors.primary3 : colors.black
          }
        >
          Required Certificates
        </Typography>
      </div>
    </div>
  );
};

export default SiteLocationNavigation;
