import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import FullWidthSelectInput from "../../../../Form/FullWidthSelectInput";
import {
  CertificationData,
  EditEmployeeCertificationFormProps,
} from "../../../../../types-definition/StaffHub/employee";
import { CertificationRequiredData } from "../../../../../types-definition/StaffHub/certificateGroup";
import TextInput from "../../../../Form/TextInput";
import DateInput from "../../../../Form/DateInput";
import Button from "../../../../Form/Button";
import colors from "../../../../../theme/colors";
import UploadItem from "../../../../Form/UploadItem";
import {
  deleteRequest,
  getRequest,
  patchRequest,
} from "../../../../../api/request";
import { GET_CERTIFICATE_URL } from "../../../../../api/urls";
import { getCurrentEmployee } from "../../../../../utils/localstorage";
import toast from "react-hot-toast";
import validator from "../../../../../utils/validateFields";
import { useAppDispatch } from "../../../../../redux/hooks";
import { updateCertification } from "../../../../../redux/slice/staff-hub/employeeSlice";
import { toastSuccess } from "../../../../../utils/toast";
import ClientSelectInput from "components/Form/ClientSelectInput";

const EditEmployeeCertification: React.FC<
  EditEmployeeCertificationFormProps
> = ({ cancelFunction, certificateData, certificateType }) => {
  const dispatch = useAppDispatch();
  const [localCertificateData, setLocalCertificateData] =
    useState<CertificationData>({
      certificate: certificateData.certificate,
      certificateNumber: certificateData.certificateNumber,
      certificateType: certificateData.certificateType,
      certificateTypeName:
        certificateType.find(
          (certificate) => certificate.id === certificateData.certificateType,
        )?.name || "",
      id: certificateData.id,
      expiryDate: certificateData.expiryDate,
      startDate: certificateData.startDate,
    });
  const [requiredLocalCertificateData, setRequiredLocalCertificateData] =
    useState<CertificationRequiredData>({
      certificateType: false,
      expiryDate: false,
      startDate: false,
    });
  const [requestLoading, setRequestLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      if (certificateData) {
        setLocalCertificateData(certificateData);
        const response = await getRequest(
          `${GET_CERTIFICATE_URL}/${certificateData?.id}`,
        );
        if (response?.success) {
          setLocalCertificateData((prevData) => {
            return {
              ...prevData,
              certificate: response.data?.certificate
                ? {
                    URL: response.data.certificate,
                    size: response.data.size,
                    lastModifiedDate: response.data.createdAt,
                    name: response.data.originalName,
                    id: response.data.id,
                  }
                : "",
            };
          });
        } else {
          setLocalCertificateData((prevData) => {
            return { ...prevData };
          });
        }
      }
    };
    getData();
  }, [certificateData]);
  const handleCertificateUpload = async () => {
    const { status, data } = validator(
      localCertificateData,
      requiredLocalCertificateData,
    );
    if (!status) {
      toast.error("Fill required fields", {
        duration: 4000,
        position: "top-center",
      });
      setRequiredLocalCertificateData((prevData) => {
        return { ...prevData, ...data };
      });
      return;
    }
    setRequestLoading(true);
    const requestData = new FormData();
    requestData.append("certificateType", localCertificateData.certificateType);
    requestData.append(
      "certificateNumber",
      localCertificateData.certificateNumber,
    );
    requestData.append("startDate", localCertificateData.startDate);
    requestData.append("expiryDate", localCertificateData.expiryDate);
    if (localCertificateData.certificate instanceof File) {
      requestData.append("certificate", localCertificateData.certificate);
    }
    if (getCurrentEmployee() === null) {
      toast.error("Employee Record Not Found", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    const userData = JSON.parse(getCurrentEmployee()!);
    const response = await await patchRequest(
      `employee/${userData.name}/certification/${localCertificateData.id}`,
      requestData,
    );
    if (response?.success) {
      dispatch(
        updateCertification({ id: response.data.id, data: response.data }),
      );
      cancelFunction();
      toastSuccess("Certificate Edited");
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };
  const deleteCertificate = async () => {
    const userData = JSON.parse(getCurrentEmployee()!);
    const response = await deleteRequest(
      `employee/${userData.name}/certification/${localCertificateData.id}/certificate/${localCertificateData.certificate.id}`,
    );
    if (response?.success) {
      toastSuccess("Certificate Image Deleted");
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
  };
  // const data: CertificationData = {};
  return (
    <div className={styles.container}>
      <div className={styles.item_container}>
        <>
          <div>
            <ClientSelectInput
              value={localCertificateData.certificateTypeName}
              client={localCertificateData.certificateTypeName}
              setValue={(name, id) => {
                if (id && name) {
                  setLocalCertificateData((prevData) => {
                    return {
                      ...prevData,
                      certificateType: id,
                      certificateTypeName: name,
                    };
                  });
                }
                setRequiredLocalCertificateData((prevData) => {
                  return { ...prevData, certificateType: false };
                });
              }}
              datalistTop="83px"
              dropdownTop="48px"
              label="Certificate Type"
              placeholder="Select Certificate"
              options={certificateType}
              required={true}
              error={requiredLocalCertificateData.certificateType}
            />
            <UploadItem
              label="Certificate"
              value={localCertificateData.certificate}
              setValue={(data) => {
                setLocalCertificateData((prevData) => {
                  return { ...prevData, certificate: data };
                });
              }}
              handleDeleteItem={deleteCertificate}
            />
          </div>
          <div className={styles.form_flex_container}>
            <TextInput
              value={localCertificateData.certificateNumber}
              setValue={(data) =>
                setLocalCertificateData((prevData) => {
                  return { ...prevData, certificateNumber: data };
                })
              }
              label="Certificate Number"
              placeholder="Enter Certificate Number"
            />
            <DateInput
              value={localCertificateData.startDate}
              setValue={(data) => {
                setLocalCertificateData((prevData) => {
                  return { ...prevData, startDate: data };
                });
                setRequiredLocalCertificateData((prevData) => {
                  return { ...prevData, startDate: false };
                });
              }}
              label="Start Date"
              placeholder="Select Date"
              required={true}
              error={requiredLocalCertificateData.startDate}
            />
            <DateInput
              value={localCertificateData.expiryDate}
              setValue={(data) => {
                setLocalCertificateData((prevData) => {
                  return { ...prevData, expiryDate: data };
                });
                setRequiredLocalCertificateData((prevData) => {
                  return { ...prevData, expiryDate: false };
                });
              }}
              min={localCertificateData.startDate}
              validateMin={true}
              label="Expiry Date"
              placeholder="Select Date"
              required={true}
              error={requiredLocalCertificateData.expiryDate}
            />
          </div>
          <div className={styles.button_container}>
            <Button
              width="200px"
              text="Cancel"
              isPrimary={false}
              onClick={cancelFunction}
              marginHorizontal="10px"
              backgroundColor={colors.transparent}
              isPrimaryBorder={true}
            />
            <Button
              width="200px"
              text="Update"
              isPrimary={true}
              onClick={handleCertificateUpload}
              isLoading={requestLoading}
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default EditEmployeeCertification;
