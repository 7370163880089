import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import { ActivityLogData } from "../../../../types-definition/admin";
import colors from "../../../../theme/colors";
import { useNavigate } from "react-router-dom";
import { setActivityBasicInformation } from "../../../../utils/localstorage";

const ActivityLogTableItem: React.FC<ActivityLogData> = ({
  id,
  timestamp,
  action,
  details,
  previousDetails,
  user,
  category,
}) => {
  const navigate = useNavigate();
  const handleViewDetails = () => {
    if (
      details?.employee ||
      details?.basicInfo ||
      previousDetails?.basicInformation
    ) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/employee/basic-info");
    }
    if (details?.contactDetails) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/employee/contact-info");
    }
    if (details?.employeeOperations) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/employee/operations");
    }
    if (details?.bankDetails) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/employee/bank-details");
    }
    if (details?.hrRecord) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/employee/hr");
    }
    if (details?.emergencyContact) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/employee/emergency-contact");
    }
    if (details?.medicalDetails) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/employee/medical-details");
    }
    if (details?.location) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/location/audit/general");
    }
    if (details?.address) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/location/address");
    }
    if (details?.locationOperations) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/location/operation");
    }
    if (details?.requiredCertificates) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/location/required/certificate");
    }
    if (details["employee-location"]) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/location/link-location");
    }
    if (details?.grade) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/grade");
    }
    if (details?.invoiceInfo) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/service-genaral");
    }
    if (details?.general) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/general-tab");
    }
    if (details?.timeCheck) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/admin-time-check");
    }
    if (details?.holidaySettings?.holidayYearStart) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/holiday");
    }
    if (details?.permissionGroup) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/permission-group");
    }
    if (details?.certificateType) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/certificate-type");
    }
    if (details?.certificateGroup) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/certificate-group");
    }
    if (details?.holidaySettings?.daysToGenerateAhead) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/shift-generation");
    }
    if (details?.service) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/service-genaral");
    }
    if (details?.payInfo || details?.invoiceInfo) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/service-financial");
    }
    if (details?.webUser) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/web-user");
    }
    if (details?.manualCheckpoint || details?.manualCheckpointReasons) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/manual-checkpoint");
    }
    if (details?.timeShift || details.timeShift === 0) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/time-shift-tab");
    }
    if (details?.serviceGroup) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/service-group");
    }
    if (details?.holidayMultiplier) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/holiday-rate");
    }
    if (details?.area) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/area-tab");
    }
    if (details?.client) {
      setActivityBasicInformation(id);
      navigate("/admin/activity-logs/client");
    }
  };
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left" textSize="14px">
          {timestamp}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textSize="14px">
          {user ?? "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textSize="14px">
          {category ?? "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left" textSize="14px">
          {action}
        </Typography>
      </td>
      <td className={styles.td_details} onClick={handleViewDetails}>
        <Typography
          textAlign="left"
          textSize="14px"
          textColor={colors.primary3}
        >
          View Details
        </Typography>
      </td>
    </tr>
  );
};

export default ActivityLogTableItem;
