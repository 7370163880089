import React from "react";
import styles from "./index.module.css";
import Typography from "../Typography";
import { DashboardDropdownProps } from "../../../types-definition/common";
import { useNavigate } from "react-router-dom";
import colors from "../../../theme/colors";
import { deleteCurrentEmployee } from "../../../utils/localstorage";

const DashboardHeaderDropdown: React.FC<DashboardDropdownProps> = ({
  data,
  width = "180px",
  closeDropDowns,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.dropdown_container} style={{ width }}>
      {data.map((item) => {
        return (
          <div
            className={styles.dropdown_item}
            onClick={() => {
              if (item.location === "staff-hub") {
                deleteCurrentEmployee();
              }
              navigate(`/${item.location}`);
              closeDropDowns();
            }}
            key={item.label}
          >
            <Typography
              textAlign="left"
              hoverActive={true}
              hoverColors={colors.primary3}
              paddingVertical="10px"
            >
              {item.label}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default DashboardHeaderDropdown;
