import React from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import Checkbox from "../../../Form/Checkbox";
import { AreaFormProps } from "../../../../types-definition/client";

const AddAreaForm: React.FC<AreaFormProps> = ({
  formData,
  setFormData,
  requiredFormData,
  setRequiredFormData,
}) => {
  return (
    <>
      <div className={styles.input_container}>
        <TextInput
          value={formData.areaName}
          setValue={(data) => {
            setFormData((prevData) => {
              return { ...prevData, areaName: data };
            });
            setRequiredFormData((prevData) => {
              return { ...prevData, areaName: false };
            });
          }}
          label="Area Name"
          placeholder="Enter Area Name"
          required={true}
          error={requiredFormData.areaName}
          helperText={requiredFormData.areaName ? "Area Name is required" : ""}
        />
      </div>
      <div>
        <TextInput
          value={formData.description}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, description: data };
            })
          }
          label="Description"
          placeholder="Enter Description"
        />
        <Checkbox
          marginVertical="20px"
          label={
            <div>
              <Typography
                textAlign="left"
                textWeight="600"
                marginHorizontal="-10px"
              >
                Active
              </Typography>
            </div>
          }
          value={formData.active}
          setValue={(data) =>
            setFormData((prevData) => {
              return { ...prevData, active: data };
            })
          }
        />
      </div>
    </>
  );
};

export default AddAreaForm;
