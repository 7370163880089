import React from "react";
import { HorizontalRuleProps } from "../../../types-definition/common";

const HorizontalRule: React.FC<HorizontalRuleProps> = ({
  borderColor = "#E5E5E5",
  width = "100%",
  marginBottom = "0px",
  marginTop = "0px",
}) => {
  return (
    <div
      style={{
        width,
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor,
        marginTop,
        marginBottom,
      }}
    ></div>
  );
};

export default HorizontalRule;
