import React, { useLayoutEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../../Common/Typography";
import { EmployeeHolidayListViewTableProps } from "../../../../../types-definition/StaffHub/employee";
import { getData } from "../../../../../utils/localstorage";
import capitalize from "../../../../../utils/capitalize";
import moment from "moment";
import AbsenceState from "../AbsenceState";
// import stringToDate from "../../../../../utils/formatDate";

const EmployeeHolidayListViewTableItem: React.FC<
  EmployeeHolidayListViewTableProps
> = ({ holidayType, modifiedType, from, modifiedAt, timeOff, to, isError }) => {
  const [holidayStatus, setHolidayStatus] = useState<
    "Completed" | "Requested" | "Rejected" | "In-Progress" | "Approved"
  >("Completed");
  const user = JSON.parse(getData() || "{}");
  const date = new Date();
  const currentDate = new Date(moment(date).format("L"));
  useLayoutEffect(() => {
    if (isError) {
      setHolidayStatus("Rejected");
    } else if (
      to &&
      currentDate.getTime() > new Date(from).getTime() &&
      currentDate.getTime() < new Date(to).getTime()
    ) {
      setHolidayStatus("In-Progress");
    } else if (
      to &&
      currentDate.getTime() < new Date(from).getTime() &&
      currentDate.getTime() < new Date(to).getTime()
    ) {
      setHolidayStatus("Approved");
    } else if (
      to &&
      currentDate.getTime() > new Date(from).getTime() &&
      currentDate.getTime() > new Date(to).getTime()
    ) {
      setHolidayStatus("Completed");
    } else if (currentDate.getTime() === new Date(from).getTime()) {
      setHolidayStatus("In-Progress");
    } else if (currentDate.getTime() < new Date(from).getTime()) {
      setHolidayStatus("Completed");
    } else if (currentDate.getTime() > new Date(from).getTime()) {
      setHolidayStatus("Approved");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>
        <Typography textAlign="left">
          {moment(from).format("DD/MM/YYYY")}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {to
            ? moment(to).format("DD/MM/YYYY")
            : moment(from).format("DD/MM/YYYY")}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {timeOff === "allDay" ? "Full Day" : "Half Day"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">{holidayType ?? "N/A"}</Typography>
      </td>
      <td className={styles.td}>
        <AbsenceState state={holidayStatus} />
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {modifiedType
            ? modifiedType
            : user?.name
              ? capitalize(user?.name)
              : "N/A"}
        </Typography>
      </td>
      <td className={styles.td}>
        <Typography textAlign="left">
          {moment(modifiedAt).format("DD/MM/YYYY") ?? "N/A"}
        </Typography>
      </td>
    </tr>
  );
};

export default EmployeeHolidayListViewTableItem;
