import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { WebUserProfileData } from "../../../types-definition/admin";

interface WebUserSliceInterface {
  showConfirmMailModal: boolean;
  isFetching: boolean;
  isSearchEmpty: boolean;
  webUsers: Array<WebUserProfileData>;
  showDeleteWebUserModal: boolean;
  counter: number;
}
const initialState: WebUserSliceInterface = {
  showConfirmMailModal: false,
  isFetching: false,
  isSearchEmpty: false,
  webUsers: [],
  showDeleteWebUserModal: false,
  counter: 0,
};

export const webuserSlice = createSlice({
  name: "webuser",
  initialState,
  reducers: {
    setShowConfirmmailModal: (state, action: PayloadAction<boolean>) => {
      state.showConfirmMailModal = action.payload;
    },
    setWebUsers: (state, action: PayloadAction<Array<WebUserProfileData>>) => {
      state.webUsers = action.payload;
    },
    setShowDeleteWebUsers: (state, action: PayloadAction<boolean>) => {
      state.showDeleteWebUserModal = action.payload;
    },
    updateWebUserSearchFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    updateWebUserSearchEmpty: (state, action: PayloadAction<boolean>) => {
      state.isSearchEmpty = action.payload;
    },
    updateWebUserCounter: (state) => {
      state.counter += 1;
    },
  },
});
export const {
  setShowConfirmmailModal,
  setShowDeleteWebUsers,
  updateWebUserSearchFetching,
  updateWebUserSearchEmpty,
  updateWebUserCounter,
  setWebUsers,
} = webuserSlice.actions;

export const getShowConfirmMailModal = (state: RootState) =>
  state.webuserSlice.showConfirmMailModal;

export const getWebUserSearchFetching = (state: RootState) =>
  state.webuserSlice.isFetching;

export const getWebUserSearchEmpty = (state: RootState) =>
  state.webuserSlice.isSearchEmpty;

export const getWebUsers = (state: RootState) => state.webuserSlice.webUsers;

export const getShowDeleteWebUserModal = (state: RootState) =>
  state.webuserSlice.showDeleteWebUserModal;

export const getWebUserCounter = (state: RootState) =>
  state.webuserSlice.counter;

export default webuserSlice.reducer;
