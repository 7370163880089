import React, { useEffect, useState } from "react";
import DashboardHeader from "components/Common/DashboardHeader";
import styles from "./index.module.css";
import Typography from "components/Common/Typography";
import ScrollToTop from "components/Common/ScrollToTop";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getEmployeeholidayAbsenceCounter,
  getShowAddHolidayModal,
  setEmployeeAbsenceHolidays,
  setShowAddHolidayModal,
} from "../../../../redux/slice/staff-hub/employeeSlice";
import HorizontalRule from "components/Common/HorizontalRule";
import Button from "components/Form/Button";
import colors from "theme/colors";
import EmployeeHolidayTimeOff from "components/StaffHub/Employee/HolidayAbscence/EmployeeHolidayTimeOff";
import EmployeeHolidayHistory from "components/StaffHub/Employee/HolidayAbscence/EmployeeHolidayHistory";
import HolidayAbsenceMonthCalendar from "components/StaffHub/Employee/HolidayAbscence/HolidayAbsenceMonthCalendar";
import EmployeeAddHolidayModal from "components/StaffHub/Employee/HolidayAbscence/EmployeeAddHolidayModal";
import YearCalendar from "components/StaffHub/Employee/HolidayAbscence/YearCalendar";
import {
  getCurrentEmployee,
  getEmployeeId,
  getUserTypes,
} from "utils/localstorage";
import { getRequest } from "api/request";
import toast from "react-hot-toast";
import SelectInput from "components/Form/SelectInput";
import EmployeeHolidayListViewTable from "components/StaffHub/Employee/HolidayAbscence/EmployeeHolidayListViewTable";
import filterKeysFromArray from "utils/filterKeyFromArray";
// import stringToDate from "utils/formatDate";
import LoadingIcons from "react-loading-icons";
import PageBackButton from "components/Common/PageBackButton";
import { EMPLOYEE_ABSENCE } from "types-definition/constants";
import checkAdminPermission from "utils/permission";
import { getData } from "utils/localstorage";

const EmployeeHolidayAbsence = () => {
  const user = JSON.parse(getData() ?? "");
  const dispatch = useAppDispatch();
  const counter = useAppSelector(getEmployeeholidayAbsenceCounter);
  const showAddHolidayModal = useAppSelector(getShowAddHolidayModal);
  const [activeDateTab, setactiveDateTab] = useState<"month" | "year">("month");
  const [inputView, setInputView] = useState<"calendar-view" | "list-view">(
    "calendar-view",
  );
  const [isListViewLoading, setisListViewLoading] = useState<boolean>(true);
  const [listViewData, setListViewData] = useState<Array<any>>([]);
  const employee = JSON.parse(getCurrentEmployee()!);
  const [holidays, setHolidays] = useState<any>([]);
  const [addHolidayPermission, setAddHolidayPermission] = useState(true);
  const id = getEmployeeId();
  const getAllHolidays = async () => {
    const response = await getRequest(`employee/${id}/holiday`);
    if (response?.success) {
      dispatch(setEmployeeAbsenceHolidays(response.data));
      setHolidays(response.data);
      getHolidaySegmentDetails(response.data);
    } else {
      toast.error(response?.data, {
        duration: 4000,
        position: "top-center",
      });
    }
  };
  const getHolidaySegmentDetails = async (allHolidays: any) => {
    const segmentIds = filterKeysFromArray(allHolidays, "segmentId");
    const data: Array<any> = [];
    for (const segment in segmentIds) {
      const response = await getRequest(
        `employee/holiday/segment/${segmentIds[segment]}`,
      );
      if (response.data.length > 1) {
        data.push({
          fromDate: response.data[0].date,
          toDate: response.data[response.data.length - 1].date,
          holidayType: response.data[0].holidayType,
          isError:
            response.data[0].holidayType === "AWOL" ||
            response.data[0].holidayType === "NO_SHOW"
              ? true
              : false, // added this line to check if the holiday type is AWOL or not,
          timeOff: response.data[0].timeOff,
          modifiedAt: response.data[0].updatedAt,
          modifiedBy: response.data[0].modifiedBy,
        });
      } else {
        data.push({
          fromDate: response.data[0].date,
          holidayType: response.data[0].holidayType,
          isError:
            response.data[0].holidayType === "AWOL" ||
            response.data[0].holidayType === "NO_SHOW"
              ? true
              : false,
          timeOff: response.data[0].timeOff,
          modifiedAt: response.data[0].updatedAt,
          modifiedBy: response.data[0].modifiedBy,
        });
      }
    }
    setisListViewLoading(false);
    setListViewData(
      data.sort(
        (a, b) =>
          new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime(),
      ),
    );
  };
  useEffect(() => {
    getAllHolidays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  useEffect(() => {
    const getPermission = async () => {
      const data = checkAdminPermission(getUserTypes());
      if (data) {
        setAddHolidayPermission(false);
      } else {
        const response = await getRequest(
          `/settings/user/${user?.id}/permission`,
        );

        const permissions: Array<string> = response?.data.map(
          (item: any) => item.name,
        );
        const result = permissions?.some((p) => [EMPLOYEE_ABSENCE].includes(p));

        setAddHolidayPermission(result ? false : true);
      }
    };
    getPermission();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ScrollToTop />
      <section>
        <DashboardHeader active="staff/hub" />
        <div className={styles.container}>
          <div
            className={`${styles.item_container} ${styles.item_container_fill}`}
          >
            <PageBackButton width="95%" marginBottom="0" paddingTop="20px" />
            <div className={styles.toptext_container_flex_item}>
              <div className={styles.view_input_container}>
                <SelectInput
                  value={
                    inputView === "calendar-view"
                      ? "Calendar View"
                      : "List View"
                  }
                  placeholder="Calendar View"
                  setValue={(data) => {
                    if (data === "Calendar View") {
                      setInputView("calendar-view");
                    } else if (data === "List View") {
                      setInputView("list-view");
                    }
                  }}
                  label=""
                  options={["Calendar View", "List View"]}
                  datalistTop="54px"
                  dropdownTop="18px"
                  width="250px"
                  borderColor={colors.gray7}
                />
              </div>
              <div className={styles.toptext_container}>
                <div className={styles.toptext_container_item}>
                  <Typography
                    textAlign="center"
                    textWeight="600"
                    textSize="16px"
                  >
                    Holiday & Absence -&nbsp;
                  </Typography>
                  <Typography
                    textAlign="center"
                    textWeight="600"
                    textSize="16px"
                    textColor={colors.primary3}
                  >
                    {employee?.displayName}
                  </Typography>
                </div>
                <HorizontalRule />
              </div>
            </div>
            <div className={styles.container_item}>
              {inputView === "calendar-view" ? (
                <div className={styles.calendar_container}>
                  <div className={styles.date_navigation_container}>
                    <div
                      className={
                        activeDateTab === "month"
                          ? styles.date_navigation_item_active
                          : styles.date_navigation_item
                      }
                      onClick={() => setactiveDateTab("month")}
                    >
                      <Typography
                        textWeight="600"
                        marginVertical="0px"
                        textColor={
                          activeDateTab === "month"
                            ? colors.white
                            : colors.black
                        }
                      >
                        Monthly
                      </Typography>
                    </div>
                    <div
                      className={
                        activeDateTab === "year"
                          ? styles.date_navigation_item_active
                          : styles.date_navigation_item
                      }
                      onClick={() => setactiveDateTab("year")}
                    >
                      <Typography
                        textWeight="600"
                        marginVertical="0px"
                        textColor={
                          activeDateTab === "year" ? colors.white : colors.black
                        }
                      >
                        Yearly
                      </Typography>
                    </div>
                  </div>
                  {inputView === "calendar-view" ? (
                    <div>
                      {activeDateTab === "month" ? (
                        <HolidayAbsenceMonthCalendar />
                      ) : (
                        <YearCalendar />
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className={styles.list_view_container}>
                  {!isListViewLoading ? (
                    <EmployeeHolidayListViewTable data={listViewData} />
                  ) : (
                    <LoadingIcons.TailSpin
                      width={70}
                      height={70}
                      stroke={colors.primary1}
                      className={styles.loader_spinner}
                    />
                  )}
                </div>
              )}
              <div className={styles.description_container}>
                {!addHolidayPermission && (
                  <Button
                    text="+ Add Holiday"
                    borderColor={colors.primary3}
                    borderWidth="2px"
                    textColor={colors.primary3}
                    backgroundColor="transparent"
                    isPrimary={false}
                    isPrimaryBorder={false}
                    paddingVertical="7px"
                    onClick={() => {
                      dispatch(
                        setShowAddHolidayModal({
                          show: true,
                          minDate: "",
                        }),
                      );
                    }}
                  />
                )}

                <EmployeeHolidayTimeOff />
                <div>
                  <EmployeeHolidayHistory holiday={holidays} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showAddHolidayModal && <EmployeeAddHolidayModal />}
    </>
  );
};

export default EmployeeHolidayAbsence;
