import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import TextInput from "../../../../../components/Form/TextInput";
import { EmployeeEmergencyFormUkProps } from "../../../../../types-definition/StaffHub/employee";
import validatePostCode from "../../../../../utils/validatePostCode";
import EmptyEmergencyAddressFormUk from "../EmptyEmergencyAddressFormUk";
import Button from "../../../../Form/Button";
import { getRequest } from "../../../../../api/request";
import { GET_ADDRESS } from "../../../../../api/urls";
import { useAppDispatch } from "../../../../../redux/hooks";
import UkAddressSearchInput from "../../../../Form/UkAddressInputSearch";
import { setShowFindAddressDropdown } from "../../../../../redux/slice/staff-hub/employeeSlice";

const EmployeeEmergencyContactFormUk: React.FC<
  EmployeeEmergencyFormUkProps
> = ({
  formData,
  setFormData,
  handlePostCodeAutoComplete,
  postCodeOptions,
}) => {
  const [showPostCodeError, setShowPostCodeError] = useState(false);
  const [address, setAddress] = useState<Array<any>>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const getPostCodeDetails = async () => {
      const response = await getRequest(
        `${GET_ADDRESS}?postcode=${formData.postcode}`,
      );
      setAddress(
        response?.data?.suggestions
          ? response?.data?.suggestions?.map((i: any) => {
              return { name: i.address, id: i.id };
            })
          : [],
      );
    };
    getPostCodeDetails();
  }, [formData.postcode]);
  const getAddressDetails = async (id: string) => {
    const response = await getRequest(`${GET_ADDRESS}/${id}`);
    setFormData((prevData) => {
      return {
        ...prevData,
        address1: response?.data?.line_1,
        address2: response?.data?.line_2,
        town: response?.data?.town_or_city,
        postcode: response?.data?.postcode,
        postcode1: response?.data?.postcode,
        isPostCodeEntered: true,
      };
    });
  };

  return (
    <>
      {!formData.isPostCodeEntered ? (
        <EmptyEmergencyAddressFormUk
          formData={formData}
          setFormData={setFormData}
        />
      ) : (
        <>
          <div className={styles.postcode_container}>
            <div className={styles.postcode_input_container}>
              <UkAddressSearchInput
                value={formData.postcode}
                setValue={(data, id) => {
                  if (id) {
                    getAddressDetails(id);
                  }
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      postcode: data,
                    };
                  });
                }}
                label="Postcode"
                placeholder="Enter your post code"
                dropdownTop="45px"
                datalistTop="83px"
                emptyOptionText="Search Postcode"
                options={address}
              />
            </div>
            <div className={styles.postcode_button_container}>
              <Button
                isPrimary={true}
                text="Find Address"
                onClick={() => {
                  dispatch(setShowFindAddressDropdown(true));
                }}
                onBlur={() => {
                  setTimeout(() => {
                    dispatch(setShowFindAddressDropdown(false));
                  }, 300);
                }}
                width="200px"
              />
            </div>
          </div>
          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.contactName}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, contactName: data };
                })
              }
              label="Contact Name"
              placeholder="Enter Contact Name"
            />
            <TextInput
              value={formData.relationship}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, relationship: data };
                })
              }
              label="Relationship"
              placeholder="Enter Relationship"
            />
          </div>

          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.address1}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, address1: data };
                })
              }
              label="Address 1"
              placeholder="Enter Address"
            />
            <TextInput
              value={formData.address2}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, address2: data };
                })
              }
              label="Address 2"
              placeholder="Enter Address"
            />
          </div>

          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.town}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, town: data };
                })
              }
              label="Town"
              placeholder="Enter Town"
            />
            <TextInput
              value={formData.postcode1}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, postcode1: data };
                });
                handlePostCodeAutoComplete(data);
                const validateInputPostCode = validatePostCode(data);
                if (!validateInputPostCode && data.length > 0) {
                  setShowPostCodeError(true);
                  return;
                }
                setShowPostCodeError(false);
              }}
              label="Postcode"
              placeholder="Enter Post Code"
              error={showPostCodeError}
            />
          </div>

          <div className={styles.form_flex_container}>
            <TextInput
              value={formData.telephone}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, telephone: data };
                })
              }
              label="Telephone"
              placeholder="Enter Telephone Number"
            />
            <TextInput
              value={formData.email}
              setValue={(data) =>
                setFormData((prevData) => {
                  return { ...prevData, email: data };
                })
              }
              label="Email"
              placeholder="Enter Email"
            />
          </div>
        </>
      )}
    </>
  );
};

export default EmployeeEmergencyContactFormUk;
