import React from "react";
import styles from "./index.module.css";
import ScrollToTop from "components/Common/ScrollToTop";
import DashboardHeader from "components/Common/DashboardHeader";
import EmployeeViewProfileUk from "components/StaffHub/Employee/ViewProfile/ViewProfileUk";
import EmployeeResetPasswordSuccessModal from "components/StaffHub/Employee/ViewProfile/ResetPasswordSuccessModal";
import { useAppSelector } from "../../../../redux/hooks";
import {
  getShowConfirmResetPasswordModal,
  getShowSuccessResetPasswordModal,
} from "../../../../redux/slice/staff-hub/employeeViewProfileSlice";
import EmployeeResetPasswordConfirmModal from "components/StaffHub/Employee/ViewProfile/ResetPasswordConfirmModal";
import { getCountry } from "utils/localstorage";
import { UK } from "types-definition/constants";
import EmployeeViewProfileNonUk from "components/StaffHub/Employee/ViewProfile/Viewprofile";

const EmployeeViewProfile = () => {
  const showSuccessModal = useAppSelector(getShowSuccessResetPasswordModal);
  const showConfirmModal = useAppSelector(getShowConfirmResetPasswordModal);
  const country = getCountry();

  return (
    <div className={styles.container}>
      <ScrollToTop />
      <DashboardHeader active="staff/hub" />
      {country === UK ? (
        <div className={styles.container}>
          <EmployeeViewProfileUk />
        </div>
      ) : (
        <EmployeeViewProfileNonUk />
      )}
      {showSuccessModal && <EmployeeResetPasswordSuccessModal />}
      {showConfirmModal && <EmployeeResetPasswordConfirmModal />}
    </div>
  );
};

export default EmployeeViewProfile;
