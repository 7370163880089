export const BASE_URL = "https://app.glixapp.com/api/v1";
export const REGISTER_USER_URL = "/auth/sign-up";
export const VERIFY_MAIL_URL = "/auth";
export const LOGIN_USER_URL = "/auth/login";
export const LOGOUT_USER_URL = "/auth/logout";
export const GOOGLE_AUTH_URL = "/auth/google-auth";
export const MICROSOFT_AUTH_URL = "/auth/microsoft-auth";
export const GOOGLE_SUCCESS_URL = "/auth/google-success";
export const MICROSOFT_SUCCESS_URL = "/auth/microsoft-success";
export const USER_UPDATE_URL = "/auth/user";
export const UPDATE_USER_COUNTRY_URL = "/auth/country";
export const FORGOT_PASSWORD_URL = "/auth/forgot-password";
export const RESET_PASSWORD_URL = "/auth/reset-password";
export const CREATE_COMPANY_URL = "/auth/company";
export const GET_ALL_EMPLOYEE_URL = "/employee";
export const READ_ONLY_EMPLOYEE = "/employee/read-only";
export const CREATE_EMPLOYEE_URL = "/employee";
export const GET_EMPLOYEE_TIMESHEET_URL = "/misc/active-board/employee";
export const GET_EMPLOYEE_COUNT = "/employee/count";
export const UPDATE_CONTACT_DETAILS_URL = "/employee/contact-details";
export const UPDATE_OPERATIONS_URL = "/employee/operations";
export const UPDATE_HR_URL = "/employee/hr";
export const UPDATE_EMERGENCY_CONTACT_URL = "/employee/emergency-contact";
export const UPDATE_MEDICAL_DETAILS = "/employee/medical-details";
export const UPDATE_BANK_DETAILS_URL = "/employee/bank-details";
export const UPDATE_CERTIFICATE_URL = "/employee/certification";
export const GET_CERTIFICATE_URL = "/employee/certificate";
export const CREATE_CLIENT_URL = "/client";
export const GET_ALL_CLIENTS_URL = "/client";
export const CREATE_LOCATION_URL = "/client/location";
export const GET_LOCATION_URL = "/client/location";
export const GET_LOCATION_COUNT_URL = "/client/location/count";
export const CREATE_AREA_URL = "/client/area";
export const GET_AREA_URL = "/client/area";
export const CREATE_CONTACT_ADDRESS_URL = "/address";
export const CREATE_CLIENT_CONTACT_PERSON_URL = "/client/contact-person";
export const SEARCH_EMPLOYEE = "/employee/search";
export const UPDATE_EMPLOYEE_IDENTIFICATION_URL =
  "/employee/identification-document";
export const UPDATE_EMPLOYEE_BASICINFO_URL = "/employee/basic-info";
export const UPDATE_EMPLOYEE_VISA_URL = "/employee/visa-information";
export const CREATE_SERVICE_URL = "/service";
export const GET_SERVICE_COUNT_URL = "/service";
export const GET_CLIENT_COUNT = "/client/count";
export const GET_SERVICE_URL = "/service";
export const GET_USER_DETAILS_URL = "/auth/me";
export const GET_USER_SETTINGS_URL = "/settings";
export const GET_USER_ADMIN_SETTINGS_URL = "/settings/admin";
export const UPDATE_USER_DETAILS = "/settings";
export const UPDATE_ADMIN_USER_DETAILS = "/settings/admin";
export const TIME_CHECK = "/settings/time-check";
export const MANUAL_CHECKPOINT_REASON = "/settings/manual-checkpoint-reasons";
export const GET_PERMISSION_GROUP_URL = "/settings/permission-group";
export const GET_DEFAULT_PERMISSION_GROUP_URL =
  "/settings/permission-group/default";
export const CREATE_PERMISSION_GROUP_URL = "/settings/permission-group";
export const DELETE_PERMISSION_GROUP_URL = "/settings/permission-group";
export const CREATE_WEB_USER_URL = "/settings/web-user";
export const GET_WEB_USER_URL = "/settings/web-user";
export const GET_ALL_PERMISSION_URL = "/auth/permissions";
export const POST_GRADE_URL = "/settings/grade";
export const GET_GRADE_URL = "/settings/grade";
export const PATCH_GRADE_URL = "/settings/grade";
export const EDIT_GRADE_URL = "/settings/grade";
export const DELETE_GRADE_URL = "/settings/grade";
export const CLONE_PERMISSION_GROUP_URL = "/settings/clone/permission-group";
export const UNASSIGN_WEBUSER_PERMISSION_URL =
  "/settings/web-user-permission-group";
export const CREATE_CERTIFICATE_GROUP_URL = "/certificate/certificate-group";
export const GET_CERTIFICATE_GROUP_URL = "/certificate/certificate-group";
export const DELETE_CERTIFICATE_GROUP_URL = "/certificate/certificate-group";
export const CERTIFICATE_TYPE_URL = "/certificate/certificate-type";
export const DELETE_CERTIFICATE_TYPE_URL = "/certificate/certificate-type";
export const EDIT_CERTIFICATE_TYPE_URL = "/certificate/certificate-type";
export const ADMIN_SHIFT_GENERATION = "/settings/shift-generation";
export const CREATE_SERVICE_GROUP_URL = "/service/service-group";
export const GET_SERVICE_GROUP_URL = "/service/service-group";
export const DELETE_SERVICE_GROUP_URL = "/service/service-group";
export const TIME_SHIFT_GENERATION = "/settings";
export const SEARCH_WEB_USER_URL = "/settings/web-user/username";
export const GET_HOLIDAY_MULTIPLIER_URL = "/settings/holiday-multiplier";
export const UPDATE_HOLIDAY_MULTIPLIER_URL =
  "/settings/holiday-multiplier/holiday";
export const GET_EMPLOYEE_HOLIDAY_MULTIPLIER = "/employee";
export const EDIT_EMPLOYEE_HOLIDAY_MULTIPLIER = "/employee";
export const GET_LINK_EMPLOYEE_TO_LOCATION = "/employee";
export const POST_LINK_EMPLOYEE_TO_LOCATION = "/employee";
export const PATCH_EMPLOYEE_TO_LOCATION = "/employee";
export const DELETE_EMPLOYEE_TO_LOCATION = "/employee";
export const GET_ADDRESS = "/misc/address";
export const GET_SERVICE_LINK_TO_LOCATION = "/service/location";
export const AUDIT_USER_URL = "/audit/user";
export const CLIENT_MAIL_REPORT_URL = "/client/location/email-report";
export const GET_SYSTEM_ACTIVITY = "/activity";
export const GET_SYSTEM_USER_ACTIVITY = "/activity/user";
export const MESSAGE_EMPLOYEE = "/employee/message";
export const LOGIN_WEB_USER = "/auth/web-user/login";
export const GET_ACTIVITY_FOR = "/activity/for";
export const GET_EMPLOYEE_HOLIDAY_ABSENCE_SUMMARY = "/employee/holiday/summary";
export const ACTIVE_BOARD = "/misc/active-board";
export const CLIENT_INCIDENT_REPORT = "/client/incident-report";
export const SERVICE_LOCATION = "/service/location";
export const INCIDENT_REPORTS = "/client/incident-report";
export const CLIENT_SHIFT_NOTE = "/client/shift-note";
export const SERVICE_CHECKPOINT = "/service/checkpoint";
export const USER_ACTIVITY = "/activity/user";
export const DELETE_ACCRUAL = "/employee/accrual";
export const GET_USER_SETTINGS = "/settings/user";
