import React, { useEffect, useState } from "react";
import { TypographyProps } from "types-definition/common";
import styles from "./index.module.css";
import colors from "theme/colors";
import size from "theme/size";

const Typography: React.FC<TypographyProps> = ({
  text,
  textAlign = "center",
  textColor = colors.black,
  textSize = size.md,
  children,
  textWeight = "400",
  marginVertical = "8px",
  marginHorizontal = 0,
  paddingVertical,
  fontFamily = "poppins",
  style,
  hoverActive = false,
  hoverColors,
}) => {
  const [currentColor, setCurrentColor] = useState<string>(textColor);
  useEffect(() => {
    setCurrentColor(textColor);
  }, [textColor]);
  return (
    <p
      style={{
        textAlign: textAlign,
        color: currentColor,
        fontSize: textSize,
        fontWeight: textWeight,
        marginTop: marginVertical,
        marginBottom: marginVertical,
        marginRight: marginHorizontal,
        marginLeft: marginHorizontal,
        paddingTop: paddingVertical,
        paddingBottom: paddingVertical,
        ...style,
      }}
      className={
        fontFamily === "inter"
          ? styles.text_inter
          : fontFamily === "nunito"
            ? styles.text_nunito
            : styles.text_poppins
      }
      onMouseEnter={() => hoverActive && setCurrentColor(hoverColors)}
      onMouseLeave={() => hoverActive && setCurrentColor(textColor)}
    >
      {children ?? text}
    </p>
  );
};

export default Typography;
