import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Typography from "../../../Common/Typography";
import TextInput from "../../../Form/TextInput";
import cancelIcon from "../../../../assets/svg/circle-cancel-icon.svg";
import Button from "../../../Form/Button";
import colors from "../../../../theme/colors";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getCurrentSelectedHolidayAbsenceEmployeeId,
  getCurrentSelectedHolidayAbsenceId,
  getEmployeeHolidayEditAbsenceSummaryCounter,
  setCurrentSelectedAbsenceHolidayEmployeeId,
  setHolidayAbscenceSummaryApprove,
  setHolidayAbscenceSummaryDate,
  setHolidayAbscenceSummaryFromToDate,
  setHolidayAbsenceSummaryFormInformation,
  setShowConflictModal,
  setShowEditAbsenceHolidayModal,
  updateAbsenceHolidayCounter,
} from "../../../../redux/slice/HolidayAbsence/holidayAbsenceSlice";
import DateInput from "../../../Form/DateInput";
import { getRequest, patchRequest, putRequest } from "../../../../api/request";
import { toastError, toastSuccess } from "../../../../utils/toast";
import SelectInput from "../../../Form/SelectInput";
import RadioInput from "../../../Form/RadioInput";
import {
  EditHolidayAbsenceAllDayRequiredFormData,
  EditHolidayAbsenceFormData,
  EditHolidayAbsenceHalfDayRequiredFormData,
} from "../../../../types-definition/StaffHub/holiday";
import fieldTextValidator from "../../../../utils/validateFieldsWithText";
import moment from "moment";
// import stringToDate from "../../../../utils/formatDate";

const EditHolidayAbsence: React.FC = () => {
  const dispatch = useAppDispatch();
  const [requestLoading, setRequestLoading] = useState(false);
  const id = useAppSelector(getCurrentSelectedHolidayAbsenceId);
  const employeeId = useAppSelector(getCurrentSelectedHolidayAbsenceEmployeeId);
  const counter = useAppSelector(getEmployeeHolidayEditAbsenceSummaryCounter);
  const [formData, setFormData] = useState<EditHolidayAbsenceFormData>({
    holidayType: "",
    description: "",
    allDay: true,
    fromDate: "",
    toDate: "",
    date: "",
    preferredTime: "",
  });
  const [allDayRequiredFormData, setAllDayRequiredFormData] =
    useState<EditHolidayAbsenceAllDayRequiredFormData>({
      fromDate: {
        status: false,
        text: "",
      },
      toDate: {
        status: false,
        text: "",
      },
      holidayType: {
        status: false,
        text: "",
      },
    });

  const [halfDayRequiredFormData, setHalfDayRequiredFormData] =
    useState<EditHolidayAbsenceHalfDayRequiredFormData>({
      date: {
        status: false,
        text: "",
      },
      preferredTime: {
        status: false,
        text: "",
      },
      holidayType: {
        status: false,
        text: "",
      },
    });
  const handleSubmitForm = async () => {
    setRequestLoading(true);
    // validator
    if (formData.allDay) {
      const { data, status } = fieldTextValidator(
        formData,
        allDayRequiredFormData,
      );
      if (!status) {
        setAllDayRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        setRequestLoading(false);
        return;
      }
    } else {
      const { data, status } = fieldTextValidator(
        formData,
        halfDayRequiredFormData,
      );
      if (!status) {
        setHalfDayRequiredFormData((prevData) => {
          return { ...prevData, ...data };
        });
        setRequestLoading(false);
        return;
      }
    }

    // Request to the server
    const holidays: Array<any> = [];
    const fromDate = new Date(formData.fromDate);
    const toDate = new Date(formData.toDate);
    const fromDateData = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate(),
    );
    const toDateData = new Date(
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate(),
    );
    if (formData.allDay) {
      for (let i = fromDateData; i <= toDateData; i.setDate(i.getDate() + 1)) {
        const holiday = {
          date: moment(new Date(i)).format("DD/MM/YYYY"),
          holidayType: formData.holidayType,
          description: formData.description,
          timeOff: "allDay",
          isoDate: moment.utc(new Date(i)).toISOString(),
        };
        holidays.push(holiday);
      }
      const response = await putRequest(
        `employee/${employeeId}/holiday/segment/${id}`,
        holidays,
      );
      if (response?.success) {
        handleApproveHoliday();
      } else {
        if (response?.data?.includes("Holiday shift clash at")) {
          dispatch(setCurrentSelectedAbsenceHolidayEmployeeId(employeeId));
          dispatch(setHolidayAbscenceSummaryApprove(true));
          dispatch(
            setHolidayAbscenceSummaryFromToDate({
              fromDate: formData.fromDate,
              toDate: formData.toDate,
            }),
          );
          dispatch(
            setHolidayAbsenceSummaryFormInformation({
              type: formData.holidayType,
              description: formData.description,
              preferredTime: formData.preferredTime,
              employeeName: "",
            }),
          );
          dispatch(setShowConflictModal(true));
          dispatch(setShowEditAbsenceHolidayModal(false));
        } else if (response?.data) toastError(response?.data);
        setRequestLoading(false);
        return;
      }
    } else {
      const response = await putRequest(
        `employee/${employeeId}/holiday/segment/${id}`,
        [
          {
            holidayType: formData.holidayType,
            timeOff: "halfDay",
            // date: moment(new Date(formData.date)).format("DD/MM/YYYY"),
            preferredTime: formData.preferredTime,
            description: formData.description,
            date: moment.utc(new Date(formData.date)).toISOString(),
          },
        ],
      );
      if (response?.success) {
        handleApproveHoliday();
      } else {
        if (response?.data?.includes("Holiday shift clash at")) {
          dispatch(setCurrentSelectedAbsenceHolidayEmployeeId(employeeId));
          dispatch(setHolidayAbscenceSummaryApprove(true));
          dispatch(setHolidayAbscenceSummaryDate(formData.date));
          dispatch(
            setHolidayAbsenceSummaryFormInformation({
              type: formData.holidayType,
              description: formData.description,
              preferredTime: formData.preferredTime,
              employeeName: "",
            }),
          );
          dispatch(setShowConflictModal(true));
          dispatch(setShowEditAbsenceHolidayModal(false));
        } else if (response?.data) toastError(response?.data);
        setRequestLoading(false);
        return;
      }
    }
    setRequestLoading(false);
  };
  useEffect(() => {
    const getHolidayDetails = async () => {
      const response = await getRequest(`/employee/holiday/segment/${id}`);
      if (response?.success) {
        const data = response?.data;
        if (data[0]?.timeOff === "allDay") {
          setFormData((prevData) => {
            return {
              ...prevData,
              allDay: true,
              holidayType: data[0].holidayType,
              description: data[0].description ?? "",
              fromDate: moment(data[0].date).format("YYYY-MM-DD"),
              toDate: moment(data[response.data.length - 1].date).format(
                "YYYY-MM-DD",
              ),
            };
          });
        } else {
          setFormData((prevData) => {
            return {
              ...prevData,
              allDay: false,
              holidayType: data[0].holidayType,
              description: data[0].description ?? "",
              date: moment(data[0].date).format("YYYY-MM-DD"),
              preferredTime: data[0].preferredTime,
            };
          });
        }
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
    };
    getHolidayDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);
  const handleApproveHoliday = async () => {
    const response = await patchRequest(
      `/employee/${employeeId}/holiday/segment/${id}/approve`,
    );
    if (response?.success) {
      toastSuccess("Holiday Editted successfully");
      dispatch(updateAbsenceHolidayCounter());
      dispatch(setShowEditAbsenceHolidayModal(false));
    } else {
      toastError(response?.data);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.content_container}>
        <div className={styles.header}>
          <Typography textWeight="600">Edit Holiday</Typography>
          <img
            src={cancelIcon}
            alt="cancel icon"
            className={styles.cancel_icon}
            onClick={() => dispatch(setShowEditAbsenceHolidayModal(false))}
          />
        </div>
        <div className={styles.form_container}>
          <div className={styles.form_container_input}>
            <SelectInput
              placeholder="
              Select Holiday Type"
              dropdownTop="48px"
              label="Holiday Type"
              datalistTop="83px"
              value={formData.holidayType}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, holidayType: data };
                });
                setAllDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    holidayType: { status: false, text: "" },
                  };
                });
                setHalfDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    holidayType: { status: false, text: "" },
                  };
                });
              }}
              helperText={
                allDayRequiredFormData.holidayType.text ||
                halfDayRequiredFormData.holidayType.text
              }
              error={
                allDayRequiredFormData.holidayType.status ||
                halfDayRequiredFormData.holidayType.status
              }
              options={[
                "Leave",
                "Suspended",
                "Maternity",
                "Sickness",
                "AWOL",
                "Unpaid Leave",
                "Paternity",
                "COVID",
                "Other",
                "Compassionate",
              ]}
            />

            <TextInput
              placeholder="Enter Description"
              label="Description"
              value={formData.description}
              setValue={(data) => {
                setFormData((prevData) => {
                  return { ...prevData, description: data };
                });
              }}
            />
          </div>
          <Typography
            textAlign="left"
            fontFamily="inter"
            textWeight="600"
            textColor={colors.tableText}
            marginVertical="0px"
          >
            Time Off
          </Typography>
          <div className={styles.form_container_checkbox_flex}>
            <div>
              {" "}
              <RadioInput
                label="All Day"
                name="plan-type"
                value={formData.allDay}
                setValue={() =>
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      allDay: true,
                    };
                  })
                }
              />
            </div>

            <RadioInput
              width="150px"
              label="Half Day"
              name=""
              value={!formData.allDay}
              setValue={() =>
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    allDay: false,
                  };
                })
              }
            />
          </div>
        </div>
        {formData.allDay ? (
          <div className={styles.flex_item}>
            <DateInput
              value={formData.fromDate}
              setValue={(data: any) => {
                setFormData((prevData) => {
                  return { ...prevData, fromDate: data };
                });
                setAllDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    fromDate: { status: false, text: "" },
                  };
                });
              }}
              label="Date"
              placeholder=""
              helperText={allDayRequiredFormData.fromDate.text}
              error={allDayRequiredFormData.fromDate.status}
            />
            <DateInput
              value={formData.toDate}
              setValue={(data: any) => {
                setFormData((prevData) => {
                  return { ...prevData, toDate: data };
                });

                setAllDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    toDate: { status: false, text: "" },
                  };
                });
              }}
              label="To"
              placeholder=""
              width={"auto"}
              helperText={allDayRequiredFormData.toDate.text}
              error={allDayRequiredFormData.toDate.status}
            />
          </div>
        ) : (
          <div className={styles.form_item}>
            <SelectInput
              placeholder="Select Preferred Time"
              value={formData.preferredTime}
              label="Preferred Time"
              setValue={(data) => {
                setFormData({ ...formData, preferredTime: data });
                setHalfDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    preferredTime: { status: false, text: "" },
                  };
                });
              }}
              helperText={halfDayRequiredFormData.preferredTime.text}
              error={halfDayRequiredFormData.preferredTime.status}
              dropdownTop="46px"
              datalistTop="84px"
              options={["AM", "PM"]}
            />
            <DateInput
              placeholder=""
              value={formData.date}
              label="Date"
              setValue={(data) => {
                setFormData({ ...formData, date: data });
                setHalfDayRequiredFormData((prevData) => {
                  return {
                    ...prevData,
                    date: { status: false, text: "" },
                  };
                });
              }}
              helperText={halfDayRequiredFormData.date.text}
              error={halfDayRequiredFormData.date.status}
            />
          </div>
        )}
        <div className={styles.button_container}>
          <Button
            text="Cancel"
            isPrimary={false}
            onClick={() => dispatch(setShowEditAbsenceHolidayModal(false))}
            backgroundColor={colors.transparent}
            isPrimaryBorder={true}
          />
          <Button
            text="Save"
            isPrimary={true}
            onClick={handleSubmitForm}
            isLoading={requestLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default EditHolidayAbsence;
